<template>
  <section class="section">
    <div class="contain">
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <div class="control">
                <input type="text" class="input" placeholder="Search by customer" v-model="searchText">
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <div class="control">
                  <datepicker v-model="startDate" input-class="input" placeholder="Start Date"></datepicker>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <div class="control">
                  <datepicker v-model="endDate" input-class="input" placeholder="End Date"></datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="control is-pulled-left">
                <div class="select is-fullwidth">
                  <select v-model="refundType">
                    <option value="unpaid">Unpaid</option>
                    <option value="paid">Paid</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column is-8">
              <p class='has-text-centered'>
                <button class='button is-primary' :disabled="refundType === 'paid' || checkedRows.length === 0" @click.stop="submitRefunds">
                  Pay ${{ totalRefund }}
                </button>
              </p>
            </div>
            <div class="column">
              <div class="control is-pulled-right">
                <div class="select is-fullwidth">
                  <select v-model="perPage">
                    <option value="10">10 per page</option>
                    <option value="25">25 per page</option>
                    <option value="50">50 per page</option>
                    <option value="100">100 per page</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <center class="has-text-centered">
          <b-table
            :data="filteredRefunds"
            :paginated="true"
            :per-page="perPage"
            :checked-rows.sync="checkedRows"
            :is-row-checkable="(row) => row.customer.email && row.confirmed"
            :checkable="refundType === 'unpaid'"
            :current-page.sync="currentPage"
            :pagination-simple="false"
            :default-sort-direction="defaultSortOrder"
            default-sort="id">
            <template slot-scope="props">
              <b-table-column field="id" label="Refund ID" sortable numeric width="80">{{ props.row.id }}</b-table-column>
              <b-table-column field="customer.email" label="Customer Email" sortable width="250">{{ props.row.customer.email }}</b-table-column>
              <b-table-column field="purchase.servicePackage" label="Product" sortable>{{ serviceLabel(props.row.purchase.servicePackage) }}</b-table-column>
              <b-table-column field="refundAmount" label="Refund ($)" sortable numeric width="100">{{ props.row.refundAmount | round(2) }}</b-table-column>
              <b-table-column field="description" label="Description" width="400">{{ props.row.description }}</b-table-column>
              <b-table-column field="status" label="Status" sortable  width="100">{{ props.row.status }}</b-table-column>
              <b-table-column field="createdAt" label="Created" sortable  width="180">{{ toLocalDateString(props.row.createdAt) }}</b-table-column>
              <b-table-column :visible="refundType === 'paid'" field="paidAt" label="Paid Date" sortable  width="180">{{ toLocalDateString(props.row.paidAt) }}</b-table-column>
              <b-table-column :visible="refundType === 'paid'" field="paidBy" label="Paid By" sortable  width="200">{{ props.row.paidBy }}</b-table-column>
            </template>
          </b-table>
          </center>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { toLocalDateString, toLocalDate } from '@/utils'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { mixin as BaseMixin } from '@/components/mixin'
export default {
  components: { Datepicker },
  mixins: [ServiceMixin, BaseMixin],
  data () {
    return {
      refundType: 'unpaid',
      allRefunds: [],
      searchText: '',
      perPage: 50,
      currentPage: 1,
      defaultSortOrder: 'asc',
      startDate: null,
      endDate: null,
      checkedRows: []
    }
  },
  computed: {
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    totalRefund () {
      let total = total = this.checkedRows.reduce((acc, refund) => acc + refund.refundAmount, 0)
      return total
    },
    filteredRefunds () {
      if (!this.allRefunds) {
        return []
      }
      let refunds = this.allRefunds.slice()
      if (this.refundType === 'unpaid') {
        refunds = refunds.filter((r) => r.status !== 'SUCCESS')
      } else if(this.refundType === 'paid') {
        refunds = refunds.filter((r) => r.status === 'SUCCESS')
      }

      if (this.startDate) {
        let startDate = toLocalDate(this.startDate)
        refunds = refunds.filter((r) => {
          let createdAt = toLocalDate(r.createdAt)
          let paidAt = toLocalDate(r.paidAt)
          return (createdAt.isValid() && createdAt >= startDate)
            || (this.refundType === 'paid' && paidAt.isValid() && paidAt >= startDate)
        })
      }

      if (this.endDate) {
        let endDate = toLocalDate(this.endDate)
        refunds = refunds.filter((r) => {
          let createdAt = toLocalDate(r.createdAt)
          let paidAt = toLocalDate(r.paidAt)
          return (createdAt.isValid() && createdAt <= endDate)
            || (this.refundType === 'paid' && paidAt.isValid() && paidAt <= endDate)
        })
      }

      if (this.searchText) {
        let searchText = this.searchText.toLowerCase()
        refunds = refunds.filter((r) => r.customer.email.toLowerCase().includes(searchText))
      }
      return refunds
    }
  },
  methods: {
    toLocalDateString (timestamp) {
      return toLocalDateString(timestamp)
    },
    submitRefunds () {
      console.log(this.checkedRows)
      this.$store.dispatch('executePaypalRefund', { refunds: this.checkedRows })
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            return this.fetchRefunds()
          }
        })
    },
    fetchRefunds () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchPaypalRefunds')
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.allRefunds = response.data.refunds
          }
          return 1
        })
        .catch((err) => {
          console.log('Error fetching paypal refunds ', err)
        })
        .finally(() => this.$store.commit('hideLoading'))
    }
  },
  beforeMount () {
    return this.fetchRefunds()
  }
}
</script>

<style lang="scss">
.table-mobile-sort {
  display: none;
}
</style>