<template>
  <div>
    <div class="mt-10 location-lang-domain-area">
      <div v-if="isForPageLevel">
        <div class="card-small-title mb-5">
          Target url
        </div>
        <v-text-field
          dense
          outlined
          :value="targetUrl"
          color="greenColor"
          readonly
        />
      </div>
      <div>
        <div class="card-small-title mb-5">
          Select location
        </div>
        <v-autocomplete outlined dense hide-details="auto"
          :items="availableLocations"
          item-text="location_name"
          item-value="location_code"
          :value="config.wizardLocation"
          @input="value => setConfig('wizardLocation', value)"
          color="grayColor">
        </v-autocomplete>
        <div class="mt-3 d-flex align-center gap5">
          <vue-toggles
            :value="config.wizardIsCountryEnabled"
            @click="() => setConfig('wizardIsCountryEnabled', !config.wizardIsCountryEnabled)"
            width="40" height="20"
            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
            :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
          />
          <div class="mainColor--text font-weight-bold">Enable country</div>
        </div>
      </div>
      <div>
        <div class="card-small-title mb-5">
          Select language
        </div>
        <v-autocomplete outlined dense hide-details
          :items="targetLanguages"
          :value="config.wizardLanguage"
          @input="value => setConfig('wizardLanguage', value)"
          item-text="language_name"
          item-value="language_code"
          color="grayColor">
        </v-autocomplete>
      </div>
      <div>
        <div class="card-small-title mb-5">
          Add Domain name
        </div>
        <domain-selector
          :baseDomain="campaign?.targetUrl || ''"
          :initialDomain="config.wizardDomain || campaign?.targetUrl || config.wizardPODomain"
          @onChangeDomain="(d) => {
            googleSiteUrl = d;
            setConfig('wizardDomain', d);
          }"
        />
      </div>
      <div>
        <div class="card-small-title mb-5">
          Select period
        </div>
        <v-autocomplete outlined dense hide-details
          :items="availablePeriods"
          :value="config.wizardPeriod"
          @change="(value) => setConfig('wizardPeriod', availablePeriods.find(period => period.value === value))"
          item-text="label"
          color="grayColor">
        </v-autocomplete>
      </div>
    </div>
    <div class="mt-10 small-width-area">
      <div class="card-small-title mb-5">
        Select URLs to Analyze
      </div>
      <div>Tip: You can match URLs using wildcards (*). Insert a query (e.g. https://www.pageoptimizer.pro/blog/*), then hit <em>Enter.</em></div>
      <v-combobox
        ref="urlsCombobox"
        multiple
        outlined
        hide-details
        dense
        chips
        deletable-chips
        small-chips
        :items="config.wizardPageUrls"
        item-text="url"
        item-value="url"
        v-model="config.wizardSelectedPageUrls"
        :filter="urlInputCustomFilter"
        :search-input.sync="urlInputSearchValue"
        color="green7Color"
      >
        <template v-slot:selection="data">
          <v-chip
            class="my-2"
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="removeUrl(data.item, true)"
            @click:close="removeUrl(data.item)"
          >
            {{ data.item }}
          </v-chip>
        </template>
      </v-combobox>
    </div>
    <div class="mt-10 small-width-area">
      <div class="d-flex align-center gap10">
        <div class="card-small-title">
          Exclude branded keywords
        </div>
        <span class="input-count-info">{{ brandedTerms.length }} / 10</span>
        <div class="flex-grow-1 d-flex justify-end">
          <vue-toggles
            :value="config.wizardIsBrandedEnabled"
            @click="() => setConfig('wizardIsBrandedEnabled', !config.wizardIsBrandedEnabled)"
            width="40" height="20"
            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
            :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
          />
        </div>
      </div>
      <v-textarea outlined
        color="greenColor"
        class="mt-3"
        :value="config.wizardBrandedText"
        @input="value => setConfig('wizardBrandedText', value)"
      />
    </div>
    <div class="mt-5 d-flex align-center gap10">
      <v-btn rounded
        color="profileAnchorColor"
        class="text-none whiteColor--text"
        :disabled="!canProceed || config.wizardIsCalculating"
        @click="onStart"
        >
        <span>Get data</span>
      </v-btn>
    </div>
  </div>
  </template>
  
<script>
import { gapi } from 'gapi-script';
import dataForSeoLocations from '@/consts/dataforseo_locations.json';
import languages from '@/consts/dataforseo_languages.json';

import DomainSelector from '@/components/Campaigns/DomainSelector.vue';
import { getCountryCode_ISO3 } from '@/utils/search-console-helper.js';
import { getPathNameFromUrl, wildcardMatchString } from "@/utils";
import { DATE_RANGE_OPTIONS, getOptionDate } from '@/components/Campaigns/keyword-wizard/DateRangeSelect.vue';
import { groupBy, forEach, sumBy, maxBy, chain, filter } from "lodash"

let google_console_data_aggregated = []

export default {
  props: [
    'isForPageLevel',
    'selectedScenario'
  ],
  components: {
    DomainSelector
  },
  data () {
    return {
      googleSiteUrl: '',
      loadedGoogleConsoleDataAggregated: false,
      urlInputSearchValue: '',
    }
  },
  computed: {
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    wizardIsCalculating() {
      return this.config.wizardIsCalculating
    },
    availableLocations () {
      return dataForSeoLocations.locations;
    },
    targetLanguages () {
      return languages;
    },
    availablePeriods () {
      return DATE_RANGE_OPTIONS
    },
    googleUser () {
      return this.$store.state.googleUser
    },
    campaign () {
      return this.$store.state.currentCampaign
    },
    targetUrl () {
      if (this.campaign) {
        return this.campaign.targetUrl
      }
      return ''
    },
    canProceed () {
      return this.googleUser && this.googleSiteUrl
    },
    brandedTerms () {
      return this.config.wizardBrandedText.replace(/\n/g, ',').split(',').reduce((acc, cur) => {
        if (cur && cur.trim()) {
          acc.push(cur.trim())
        }
        return acc
      }, [])
    },
    wizardLanguage () {
      return this.config.wizardLanguage
    },
    wizardLocation () {
      return this.config.wizardLocation
    },
  },
  watch: {
    urlInputSearchValue: function() {
      // on url search value change, make sure no dropdown option is highlighted
      // this is so when user hits Enter, only the search input value is selected
      // without this fix, any previously selected option, would be re-selected as well
      this.$refs.urlsCombobox.setMenuIndex(-1)
    }
  },
  methods: {
    getPathNameFromUrl,
    removeUrl(urlToRemove, keepInSearchInput = false) {
      this.setConfig('wizardSelectedPageUrls', this.config.wizardSelectedPageUrls.filter(row => urlToRemove !== row))
      if (keepInSearchInput){
        this.$nextTick().then(() => {
          this.urlInputSearchValue = urlToRemove
        })
      }
    },
    urlInputCustomFilter (item, queryText, itemText) {
      return (
        queryText.includes('*')
          ?wildcardMatchString(itemText, queryText)                             // match using wildcard
          :itemText.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) // ordinary matching
      )
    },
    setConfig(key, value) {
      this.$store.commit("setInsightKeywordsConfig", { key, value });
    },
    getConsoleDataAggregated (startRow = 0, client_side_task_id = null) {
      
      const rowLimit = 25000
      const range = getOptionDate(this.config.wizardPeriod)
      const endDate = range.endDate
      const startDate = range.startDate

      let filterList = []
      if (this.config.wizardIsCountryEnabled) {
          filterList.push({
              "dimension": "COUNTRY",
              "expression": getCountryCode_ISO3(this.config.wizardLocation)
          })
      }
      if (this.config.wizardIsBrandedEnabled) {
          this.brandedTerms.slice(0, 10).map((keyword) => {
              filterList.push({
                  "dimension": "QUERY",
                  "operator": "excludingRegex",
                  "expression": `(?i)${keyword}`
              })
          })
      }

      const queryParams = {
        "siteUrl": this.googleSiteUrl,
        "resource": {
            "startDate": startDate,
            "endDate": endDate,
            "dimensionFilterGroups": [
                {
                    "filters": filterList
                }
            ],
            "dimensions": [
                "QUERY",
                "PAGE"
            ],
            "rowLimit": rowLimit,
            "startRow": startRow,
        }
      }

      gapi.client.webmasters.searchanalytics.query(queryParams)
        .then((response) => {
          if (response.status == 200) {
            if (response.result?.rows) {
              google_console_data_aggregated = [...google_console_data_aggregated, ...response.result.rows]
              if (response.result?.rows.length == rowLimit) {
                  this.getConsoleDataAggregated(startRow + rowLimit + 1, client_side_task_id)
              } else {
                // calculate cannibalization metrics to results
                let results = this.calcForKeywordCannibalization()
                // calculate total impressions for every page (needed for default sort when showing data to user)
                results = this.calcPageMetrics(results)

                // save data in backend, then show it to user
                return this.$store.dispatch('saveKeywordWizardResult', {
                  campaignId: this.campaign.id,
                  locationCode: this.config.wizardLocation,
                  languageCode: this.config.wizardLanguage,
                  tabType: this.selectedScenario,
                  result: results,
                })
                  .then((response) => {
                    if (response.data && response.data.status == 'SUCCESS') {
                      this.setConfig('wizardResults', results);
                      this.setConfig('wizardResultsHistoryMetadata', response.data.wizardData);
                      this.setConfig('wizardPageUrls', chain(results).map('page').uniq().value());
                      this.$notify({
                        group: 'info', type: 'success',
                        text: 'Successfully calculated all the data.'
                      })
                      this.$store.commit('updateClientSideAsyncTaskStatus', {
                        'id': client_side_task_id,
                        'task_status': {
                          'status': 'SUCCESS',
                          'msg': 'Completed',
                          'value': 100
                        },
                      })
                    } else {
                      this.$notify({
                        group: 'info', type: 'error',
                        text: 'Could not save results.'
                      })
                      this.$store.commit('updateClientSideAsyncTaskStatus', {
                        'id': client_side_task_id,
                        'task_status': {
                          'status': 'FAILURE',
                          'value': 100,
                          'msg': 'Could not save results.'
                        },
                      })
                    }
                  })
                  .finally(() => {
                    this.setConfig('wizardIsCalculating', false);
                    if (this.config.wizardInterval) {
                      clearInterval(this.config.wizardInterval)
                      this.setConfig('wizardInterval', null)
                    }
                  })
              }
            }
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: 'no history found'
            });
            this.$store.commit('updateClientSideAsyncTaskStatus', {
              'id': client_side_task_id,
              'task_status': {
                'status': 'FAILURE',
                'value': 100,
                'msg': 'no history found',
              },
            })
            this.setConfig('wizardIsCalculating', false);
            if (this.config.wizardInterval) {
              clearInterval(this.config.wizardInterval)
              this.setConfig('wizardInterval', null)
            }
          }
        },
        (err) => {
          console.error("Execute error", err);
          this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to get analystic data'
          });
          this.$store.commit('updateClientSideAsyncTaskStatus', {
            'id': client_side_task_id,
            'task_status': {
              'status': 'FAILURE',
              'value': 100,
              'msg': 'Failed to get analystic data',
            },
          })

          this.setConfig('wizardIsCalculating', false);
          if (this.config.wizardInterval) {
            clearInterval(this.config.wizardInterval)
            this.setConfig('wizardInterval', null)
          }
        });
    },
    calcForKeywordCannibalization () {
      let data = google_console_data_aggregated.map((d, index) => {
        return {
          ...d,
          keyword: d.keys[0],
          clicks: d.clicks,
          page: d.keys[1],
          ctr: Number(Number(d.ctr * 100).toFixed(2)),
          position: Number(Number(d.position).toFixed(2)),
          index: index,
        }
      })

      data.sort((a, b) => {
        if (a.impressions == b.impressions) {
          if (a.keyword < b.keyword) return -1;
          if (a.keyword > b.keyword) return 1;
        } else {
          return Number(b.impressions) - Number(a.impressions);
        }
      });
      const dataByKeywords = groupBy(data, "keyword");

      forEach(dataByKeywords, (records) => {
        const count = records.length;
        if (count == 1) return;

        const avg = sumBy(records, "impressions") / count;

        forEach(records.slice(1), (el) => {
          const { impressions, index } = el;
          const diff = (avg - impressions) / avg;
          try {
            data[index]['cannibalizedKeywords'] = records.map(r => {
              return {
                page: r.page,
                keyword: r.keyword,
                clicks: r.clicks,
                impressions: r.impressions,
                ctr: r.ctr,
                position: r.position,
              }
            })
          } catch (eee) {
            console.log('Error on cannibalizedKeywords calculation', eee);
          }

          /**highlight with red above avg */
          if (impressions > avg) {
            return data[index]['keywordCannibalizationText'] = "high";
          }
          /**highlight with orange diff <= 25% */
          /**highlight with green diff > 25% */
          data[index]['keywordCannibalizationText'] = diff <= 0.25 ? "medium" : "low";
        })
      })

      if (this.isForPageLevel) {
        data = data.filter((d) => {
          return getPathNameFromUrl(d.page) == getPathNameFromUrl(this.targetUrl)
        })
      }
      console.log('datadatadata', data)
      return data;
    },
    /**
     * Adds additional per page metrics/aggregations to every row in the data
     */
    calcPageMetrics (data) {
      const dataByPage = groupBy(data, "page");

      const metricsPerPage = Object.fromEntries(
        Object.entries(dataByPage).map(([key, records]) => [key, {
          totalPageImpressions: sumBy(records, "impressions"),
          maxPagePosition: maxBy(records, "position").position,
        }])
      )

      return data.map(row => (
        {...row, ...metricsPerPage[row['page']]}
      ))
    },
    onStart() {
      this.setConfig('wizardResults', null)
      this.setConfig('wizardResultsHistoryMetadata', null)

      this.setConfig('wizardIsCalculating', true)

      const client_side_task_id = Math.floor(Math.random() * Date.now());
      this.$store.commit('registerNewClientSideAsyncTask', {
        'id': client_side_task_id,
        'type': 'KeywordWizardResearch.form',
        'task_status': {
          'status': 'PROGRESS',
          'msg': 'Processing',
          'value': 5
        },
      })

      this.setConfig('wizardInterval', setInterval(() => {
        this.$store.commit('updateClientSideAsyncTaskStatus', {
          'id': client_side_task_id,
          'task_status': {
            'status': 'PROGRESS',
            value: Math.min(this.$store.getters.clientSideAsyncTaskById(client_side_task_id).task_status.value + 1, 100),  // TODO
            msg: 'Processing...'
          },
        })
      }, 1000))

      google_console_data_aggregated = []

      this.getConsoleDataAggregated(0, client_side_task_id)
    },
  },
  mounted () {
    if (this.campaign) {
      if (!this.config.wizardLanguage) {
        this.setConfig('wizardLanguage', this.campaign.languageCode)
      }
      if(!this.config.wizardLocation) {
        this.setConfig('wizardLocation', parseInt(this.campaign.locationCode))
      }
    }
    if (!this.googleSiteUrl && this.config.wizardDomain) {
      this.googleSiteUrl = this.config.wizardDomain
    }
    if (!this.config.wizardPeriod) this.setConfig('wizardPeriod', this.availablePeriods[1])  // to give default value from the computed property
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../../keyword-research.scss";
</style>
