<template>
    <v-card class="white-common-box" v-if="aiRun">
        <div class="pa-4">
            <v-row>
                <v-col cols="6" lg="8" sm="12">
                    <div class="font-large">Credits used in this article</div>
                </v-col>
                <v-col cols="6" lg="4" sm="12">
                    <div :class="`text-right font-bold clickable ${$store.getters.isDemoUser ? 'grayColor--text' : 'link1Color--text'}`"
                        @click="() => $store.getters.isDemoUser ? null : $router.push(`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`)">
                        Buy credits <v-icon small :color="$store.getters.isDemoUser ? 'grayColor' : 'link1Color'">
                            mdi-arrow-right
                        </v-icon>
                    </div>
                </v-col>
            </v-row>
            <div class="notify-label" v-if="aiRun.useMyOwnApiKey">
                This article uses your own api key.
            </div>
            <div class="mt-4">
                <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">Remaining free regenerate credits</div>
                    <div class="font-weight-bold">{{ remainedFreeRegenerate }}</div>
                </div>
                <div class="slider-view" :style="{ 'grid-template-columns': `${remainedFreeRegenerate}fr ${5 - remainedFreeRegenerate}fr`}">
                    <div class="fill" :class="{ 'used-almost': remainedFreeRegenerate <= 2 }"></div>
                    <div class="unfill"></div>
                    <div class="thumb" :style="{ 'left': `${ remainedFreeRegenerate * 100 / 5 }%` }"></div>
                </div>
            </div>
            <div class="mt-4">
                <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">Generate content <template v-if="!aiRun.useMyOwnApiKey">(4 credits per 1k words)</template></div>
                    <div class="font-weight-bold">{{ articleCredits }} ({{ numberOfWords }})</div>
                </div>
            </div>
            <div class="mt-4">
                <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">Rich content (FAQ, Table, Lists)</div>
                    <div class="font-weight-bold">{{ richContentCreditsForArticle }}</div>
                </div>
            </div>
            <div class="mt-4" v-if="separateSearchEngineTitleAndTitle">
                <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">Regenerate run - Search Engine Title</div>
                    <div class="font-weight-bold">{{ titleRegenerateCount }}</div>
                </div>
            </div>
            <div class="mt-4">
                <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">Regenerate run - {{ pageTitlelabel }}</div>
                    <div class="font-weight-bold">{{ pageTitleRegenerateCount }}</div>
                </div>
            </div>
            <div class="mt-4">
                <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">Regenerate run - Sub-headings (H2 and H3)</div>
                    <div class="font-weight-bold">{{ subHeadingsRegenerateCount }}</div>
                </div>
            </div>
            <div class="mt-4">
                <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">Regenerate run - Main content</div>
                    <div class="font-weight-bold">{{ paragraphRegenerateCount }}</div>
                </div>
            </div>
            <div class="mt-4">
                <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">Regenerate run - Rich content</div>
                    <div class="font-weight-bold">{{ contentPromptSignalRegenerateCount }}</div>
                </div>
            </div>
            <div class="mt-4">
                <div class="d-flex justify-center align-end">
                    <div class="mr-5">
                        <div>
                            Credits used
                        </div>
                        <div class="input-container">
                            <input class="custom-input" v-model="totalCreditsSpent" disabled/>
                            <img src="/static/db.png" />
                        </div>
                    </div>
                    <div>
                        <div>
                            Credit balance
                        </div>
                        <div class="input-container">
                            <input class="custom-input" disabled :value="ownerAvailableCredits"/>
                            <img src="/static/db.png" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </v-card>
</template>

<script>

export default {
    props: [
        'current', 'target'
    ],
    components: {
    },
    data() {
        return {
            slider_options: {
                dotSize: 16,
                width: 'auto',
                height: 4,
                min: 0,
                max: 100,
                interval: 1,
                dotStyle: { border: '1px solid #D2D3D9' },
                processStyle: { background: '#FF5700' },
            },
        };
    },
    computed: {

        user () {
            return this.$store.state.user;
        },
        isSubAccountRelationSelected () {
            return !!this.$store.state.selectedSubAccountRelation
        },
        ownerUser () {
            return this.$store.state.selectedSubAccountRelation || this.user
        },
        ownerAvailableCredits () {
            if (this.user) {
                return this.ownerUser.monthlyCredits + this.ownerUser.remainedUniversalCredits
            }
            return 0
        },

        pageTitlelabel () {
            if (this.separateSearchEngineTitleAndTitle) {
                return 'Page title'
            } else {
                return 'Search engine title and page title'
            }
        },

        aiRun () {
            return this.$store.state.currentAIRun
        },
        separateSearchEngineTitleAndTitle () {
            if (!this.aiRun) return false;
            return this.aiRun.isSeparateTitleAndPagetitle;
        },
        remainedFreeRegenerate () {
            if (!this.aiRun) return 0;
            return this.aiRun.remainedFreeRegenerate
        },
        numberOfWords () {
            if (!this.aiRun) return 0;
            return this.aiRun.wordCount;
        },
        articleCredits () {
            if (!this.aiRun) return 0;
            return this.aiRun.articleCredits;
        },
        richContentCreditsForArticle () {
            if (!this.aiRun) return 0;
            return this.aiRun.richContentCreditsForArticle;
        },
        titleRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.titleRegenerateCount;
        },
        pageTitleRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.pageTitleRegenerateCount;
        },
        subHeadingsRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.subHeadingsRegenerateCount;
        },
        paragraphRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.paragraphRegenerateCount;
        },
        contentPromptSignalRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.contentPromptSignalRegenerateCount;
        },
        totalCreditsSpent () {
            return this.articleCredits + this.richContentCreditsForArticle + this.titleRegenerateCount + this.pageTitleRegenerateCount + this.subHeadingsRegenerateCount + this.paragraphRegenerateCount + this.contentPromptSignalRegenerateCount
        }

    },
    mounted() {

    }
};
</script>
<style lang="scss" scoped>
.font-large {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}

.link {
    color: #0089ED;
}

.input-container {
    position: relative;

    img {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 10px;
        top: 8px;
    }

    .custom-input {
        background-color: #F2F6FF;
        height: 35px;
        border-radius: 5px;
        border: 1px solid #DCDDEB;
        padding-left: 2rem;
        width: 100%;
        font-weight: bold;
    }
}


.font-bold {
    font-weight: bold;
}

.slider-view {
    margin: 10px 0px;
    position: relative;
    display: grid;
    align-items: center;

    .fill, .unfill {
        height: 6px;
        border-radius: 6px;
    }
    .fill {
        background: var(--v-green7Color-base);
        &.used-almost {
            background: var(--v-profileAnchorColor-base);
        }
    }
    .unfill {
        background: var(--v-grayColor-base);        
    }
    .thumb {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: var(--v-whiteColor-base);
        border: 1px solid var(--v-grayColor-base);
    }
}
</style>