<template>
<div class="bar-chart" style="background-color: white;">
  <canvas :id="id" style="width: 100%;" height="300"></canvas>
</div>
</template>

<script>
import Chart from 'chart.js'
import { smallUniqueId } from '@/utils'
export default {
  props: ['keywordsOptimizations', 'variationsOptimizations'],
  data () {
    return {
      id: smallUniqueId(),
      chart: null,
      underOptimized: 'rgb(207, 140, 142)',
      overOptimized: 'rgb(240, 194, 145)'
    }
  },
  mounted () {
    const under = [this.keywordsOptimizations[0], this.variationsOptimizations[0]]
    const over = [this.keywordsOptimizations[1], this.variationsOptimizations[1]]
    setTimeout(() => {
      const el = document.getElementById(this.id)
      const options = {
        type: 'bar',
        data: {
          labels: ['Exact Match', 'Keyword Variations'],
          datasets: [{
            label: 'Under Optimized',
            backgroundColor: this.underOptimized,
            data: under
          }, {
            label: 'Over Optimized',
            backgroundColor: this.overOptimized,
            data: over
          }]
        },
        options: {
          responsive: true,
          legend: { possition: 'top' },
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero:true
                  }
              }]
          }
        }
      }
      this.chart = new Chart(el, options)
    }, 400)
  }
}
</script>

<style>
  
</style>