<template>
<div>
  <div class="field has-addons" v-if="todoTabType=='checklist'">
    <div class="control is-expanded"></div>
    <div class="control">
      <button class="button ready-button" @click="$parent.typeOfArchiveAll = 2; $parent.dispConfirmArchiveAllModal = true;">Archive All Structure Tasks</button>
    </div>
  </div>
  <div class="columns">
    <div class="column is-12">
      <div class="hscroll-container">
        <table class="signal-table">
          <thead>
            <tr :class="{'todo-list-table': todoTabType == 'simple_list'}">
              <th class="fixed-width">Tag</th>
              <template v-if="todoTabType=='checklist'">
                <th>Lowest</th>
                <th>Highest</th>
                <th>Average</th>
                <th v-if="!pageNotBuilt">Current</th>
              </template>
              <th class="recommendations">Recommendation</th>
              <template v-if="todoTabType=='checklist'">
                <th>Notes</th>
                <th>Status</th>
                <th>Archive</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tagCount, i) in tagCounts" :key="`tag-total-row-${i}`">
              <td class="signal-td">
                <i class="material-icons info-icon-size"
                  v-tooltip.right="{ content: tagExplainData[tagCount.tagLabel] ? tagExplainData[tagCount.tagLabel] : '' , classes: 'custom-tooltip' }" >info</i>
                {{ (tagCount.checklistId==0 || tagCount.isAutoChecklist)  ? '*' : '' }}
                {{ tagCount.tagLabel }}
              </td>
              <template v-if="todoTabType=='checklist'">
                <td>{{ tagCount.min }}</td>
                <td>{{ tagCount.max }}</td>
                <td>{{ tagCount.mean | round(2) }}</td>
                <td v-if="!pageNotBuilt">{{ tagCount.signalCnt }}</td>
              </template>
              <td v-if="!pageNotBuilt" class="recommendations">{{ tagCount.comment }}<span :class="getMarkTypeStyle(tagCount.comment)"></span></td>
              <td v-if="pageNotBuilt" class="recommendations">{{ tagCount.comment }}</td>
              <template v-if="todoTabType=='checklist'">
                <td>
                    <i class="fa fa-sticky-note-o" aria-hidden="true" @click="dispNotesForChecklist(tagCount.checklistId)" style="cursor: pointer;"></i>
                </td>
                <td>
                  <div class="control">
                    <label class="radio">
                      <input type="radio" :name="tagCount.checklistId" :checked="tagCount.isCompleted == 1" @click="updateChecklistState(1, tagCount)">
                      Complete
                    </label>
                    <label class="radio">
                      <input type="radio" :name="tagCount.checklistId" :checked="tagCount.isCompleted == 0" @click="updateChecklistState(0, tagCount)">
                      Incomplete
                    </label>
                    <label class="radio">
                      <input type="radio" :name="tagCount.checklistId" :checked="tagCount.isCompleted == 2" @click="updateChecklistState(2, tagCount)">
                      Ignore
                    </label>
                  </div>
                </td>
                <td>
                  <button class="button is-success" @click="archiveChecklistItem(tagCount)">Archive</button>
                </td>
              </template>
            </tr>
            <tr v-if="tagCounts.length == 0">
              <td colspan="1000" class="has-text-centered">There are no current tasks for your Page Structure Suggestions</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { mixin } from '@/components/mixin'
import explainData from './TagTotalsExplainData'

export default {
  mixins: [mixin],
  props: ['todoTabType', 'tagCounts', 'pageNotBuilt', 'pageId'],
  data () {
    return {
      searchTerm: '',
      tagExplainData: {},
    }
  },
  methods: {
    /** 
     * type: 0: keyword, 1: variation, 2: page structure
     * dispType: regular, focus, max
     * signal : H1, H2 ......
    */
    addToChecklist (row) {
      let type = 2
      let dispType = ''
      // if (row['checklistId']) {
      //   if (!window.confirm('Are you sure you want to change your selection?')) { return }
      // }
      let data = {
        pageId: this.pageId,
        recommendationType: 'pageStructure',
        type,
        dispType,
        signal: row.tagLabel
      }
      console.log(data)
      this.$store.commit('showLoading')
      return this.$store.dispatch('addOneToCheckList', data)
      .then(response => {
        if (response.data && response.data.status === 'SUCCESS') {
          console.log('successfully added!')
        }
        if (response.data.page) {
          this.$store.commit('setPage', response.data.page)
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    dispNotesForChecklist (checklistId) {
      this.$parent.selectedChecklistId = checklistId
      this.$parent.showNoteModal = true      
    },
    updateChecklistState (status, row) {
      let type = 2 // checklist type for page structure
      let checklistId = row.checklistId

      let data = {}

      if (!checklistId) {
        data = {
          type: type,
          dispType: '',
          recommendationType: 'pageStructure',
          pageId: this.pageId,
          signal: row.tagLabel
        }
      }

      data = {
        ...data,
        id: checklistId,
        isCompleted: status
      }
      this.$store.commit('showLoading')
      return this.$store.dispatch('updateChecklistState', data)
        .then(response => {
          if (response.data && response.data.status === 'SUCCESS') {
            console.log('successfully added!')
          }
          return this.$parent.loadSites()
        })
    },
    archiveChecklistItem (row) {
      let type = 2 // checklist type for page structure
      let checklistId = row.checklistId

      let data = { 
        id: checklistId,
        isAutoRecommendtaionArchive: 0
      }
      if (!checklistId) {
        data = {
          ...data,
          type: type,
          dispType: '',
          recommendationType: 'pageStructure',
          pageId: this.pageId,
          signal: row.tagLabel,
          isAutoRecommendtaionArchive: 1
        }
      }

      if (!window.confirm('Do you want to archive this page structure signal')) { return }
      this.$store.commit('showLoading')
      return this.$store.dispatch('archiveOneFromCheckList', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            return this.$parent.loadSites()
          } else {
            this.$store.commit('hideLoading')
          }
        })
        .catch(() => {
          this.$store.commit('hideLoading')
        })
    },
    checkColor (comment) {
      if (this.pageNotBuilt) {
        return 'white'
      }
      let color = ''
      if (comment.indexOf('Leave As Is') > -1) {
        color = '#b8e1cc'
      } else if (comment.indexOf('Increase') > -1 || comment.toLowerCase().indexOf('add') > -1) {
        color = '#cf8c8e'
      } else {
        color = '#f0c291'
      }
      return color;
    },


    
    
    
    
    
    
    
    
    getMarkTypeStyle (comment) {
      if (this.pageNotBuilt) {
        return {}
      }
      if (comment.indexOf('Leave As Is') > -1) {
        return { 'signal-mark': true, 'good-marktype': true }
      } else if (comment.indexOf('Reduce') > -1 || comment.indexOf('reducing') > -1) {
        return { 'signal-mark': true, 'mid-marktype': true }
      } else {
        return { 'signal-mark': true, 'high-marktype': true }
      }
    },

  },
  mounted() {
    this.tagExplainData = explainData
  }
}
</script>

<style scoped>
.search-input {
  width: 50% !important;
}

.recommendations {
  max-width: 400px;
}
</style>