<template>
<div>
  <section>
    <div class="container">
      <v-card class="white-common-box pa-12 mt-10">
        <div class="text-center">
          <img src="/static/check-email.png" class="check-email-img" />
        </div>
        <div class="is-size-4 font-weight-bold mainColor--text text-center my-2">
          Check your email to complete registration
        </div>
        <div v-if="expiredEmailLink">
          <div class="is-size-4 redColor--text text-center">Sorry, your session has expired. Please try again. </div>
          <div class="restart-link">
            <a @click="restartSignup">
              Click here to restart the registration process.
            </a>
          </div>
        </div>
        <div v-else>
          <div class="text-center mainColor--text my-5">Time left to complete registration:</div>
          <div class="countdown" id="countdown">
          </div>
          <div>
            <div class="mainColor--text explain-text mb-2">
              You will receive a confirmation email at the provided address of <b>{{ dispEmail }}</b>.
            </div>
            <div class="mainColor--text explain-text mb-2">
              Please click the link in the confirmation email to approve your payment and registration process.
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <!-- <div class="hero is-fullheight white-background">
      <div class="hero-body">
      </div>
    </div> -->
  </section>
</div>
</template>

<script>
// import servicePackages from '@/components/service_package_constants'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import {
  PRICE_OF_EACH_VERSIONING, CURRENT_YEARLY_PLANS,
} from "@/components/plans_constants"
export default {
  mixins: [ ServiceMixin ],
  data () {
    return {
      expiredEmailLink: false,
      email: '',
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    dispEmail () {
      if (this.email) {
        return this.email
      }
      if (this.user) {
        return this.user.email
      }
      return '';
    }
  },
  methods: {
    restartSignup () {
      // this.$router.push(``)
      window.open('https://www.pageoptimizer.pro/pricing', '_self')
    },
    updateCountdown(targetTime) {
      let now = new Date();
      let timeLeft = targetTime - now;
      console.log('Time left to complete registration:', timeLeft)

      if (timeLeft <= 0) {
          document.getElementById('countdown').innerText = 'Time is up!';
      } else {
          let minutes = Math.floor(timeLeft / (1000 * 60));
          let seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
          document.getElementById('countdown').innerText = `${minutes}m ${seconds}s`;
      }
    },
    fireBeginCheckoutGtagEvent (p) {
      try {
        let data = {
          event: "begin_checkout",
          ecommerce: {
            value: PRICE_OF_EACH_VERSIONING[p.servicePackage],
            currency: "USD",
            items: [
                {
                    item_id: p.servicePackage,
                    item_name: this.serviceLabel(p.servicePackage),
                    item_category: CURRENT_YEARLY_PLANS.includes(p.servicePackage) ? 'Yearly' : 'Monthly',
                    price: p.credits,
                    discount: PRICE_OF_EACH_VERSIONING[p.servicePackage] - p.credits,
                }
            ]}
        }
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push(data);
      } catch (e) {
        console.log('error on sending g tag event begin_checkout')
      }
    },
  },
  mounted() {
    // Check for auth token and set it
    // if auth token and userId and purchaseId then fetch user data
    //   - find matching purchase
    //     if paypal then 
    //        - redirect to paypal subscription approval url
    if (this.$route.query.expired) {
      this.expiredEmailLink = true;
      return;
    }

    if (this.$route.query.userId) {
      this.$store.commit('setUser', { id: parseInt(this.$route.query.userId) })
    }
    if (this.$route.query.token) {
      this.$store.commit('setJwtToken', { jwt: this.$route.query.token })
    }

    if (this.$route.query.createdAt) {
      let createdAt = this.$route.query.createdAt
      this.email = this.$route.query.email
      let targetTime = new Date(createdAt.replace(' ', 'T') + 'Z');
      targetTime = new Date(targetTime.getTime() + 10 * 60000); // to add 10 mins
      // Update the countdown every second until the target time is reached
      let interval = setInterval(() => {
          this.updateCountdown(targetTime);
          let now = new Date();
          if (targetTime - now <= 0) {
              clearInterval(interval);
          }
      }, 1000);
    }


    if (this.isAuthenticated) {
      return this.$store.dispatch('loadUser').then((response) => {
        this.$store.commit('setUser', response.data.user)
        if (this.$store.state.user.accountType == 'sub_account') {
          return this.$router.push(`/useraccount/${this.$store.state.user.id}`)
        }
        else if (this.$store.state.user.purchases.length) {
          const purchase = this.$store.state.user.purchases[0]
          if (!purchase.subscriptionId && ['paypal', 'stripe'].includes(purchase.paymentMethod) && purchase.approvalUrl) {
            this.fireBeginCheckoutGtagEvent(purchase)
            setTimeout(() => { // give time to fire g tag event to secure
              window.open(purchase.approvalUrl, '_self')              
            },50);
            
          } else {
            // probably need to make sure the purchase.status is active or trialing and let the user 
            // know the purchase was a success then redirect to sites
            return this.$router.push('/sites')
          }
        }
      })
    }
    
    if (gtag) { // for google analytics
      console.log('sending google anylytics event')
      gtag('event', 'RegisteredforPOPapp', {'event_category' : 'registeredapp', 'event_label' : 'sucreg'});
    }
  }
}
</script>

<style scoped lang="scss">

.explain-text {
  font-size: 1rem;
  text-align: center;
}

.check-email-img {
  min-width: 200px;
  width: 15vw;
}

.restart-link {
  margin-top: 20px;
  text-align: center;
  a {
    font-size: 1.1rem;
    text-decoration: underline;
    color: var(--v-mainColor-base);
  }
}
.countdown {
  padding: 10px 20px;
  font-size: 25px;
  margin-bottom: 20px;
  color: var(--v-red11Color-base);
  font-weight: 800;
  text-align: center;
}
</style>