<template>
  <v-card :class="`mx-auto border-full demo-plan-card ${item.title === 'Unlimited' ? 'dark-card' : ''}`" max-width="300">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="d-flex items-center">
          <img
            class="small-img"
            src="https://assets-global.website-files.com/636d46826480fa5819504cd6/65304153fc40c5886e561398_Union.svg"
            loading="lazy"
            alt=""
          />
          <div :class="`overline ml-1 ${item.title === 'Unlimited' ? 'white-color' : ''}`">SINGLE USER PLAN</div>
        </div>
        <v-list-item-title :class="`head-bold ${colorClass} ${item.title === 'Unlimited' ? 'white-color' : ''}`">{{
          item.title.toUpperCase()
        }}</v-list-item-title>
        <v-list-item-subtitle class="price-parent"
          ><span :class="`dollar-bold ${colorClass} ${item.title === 'Unlimited' ? 'white-color' : ''}`">$</span
          ><span :class="`price-bold ${colorClass} ${item.title === 'Unlimited' ? 'white-color' : ''}`">{{ item.price }}</span
          ><span :class="`month-bold ${colorClass} ${item.title === 'Unlimited' ? 'white-color' : ''}`">/month</span></v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
    <a :href="`https://app.pageoptimizer.pro${item.link}`" target="_blank" class="white-color start-btn">Get Started</a>

    <v-card-subtitle :class="`padding-custom center-text ${item.title === 'Unlimited' ? 'white-color' : `${colorClass} fw-400`}`"> 7-day refund guarantee** </v-card-subtitle>

    <v-list dense shaped :class="item.title === 'Unlimited' ? 'dark-card' : ''">
      <v-list-item
        v-for="(description, index) in item.description"
        :key="index"
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="green">mdi-check</v-icon>
        </v-list-item-icon>
        <v-list-item-content :class="item.title === 'Unlimited' ? 'white-color' : `${colorClass} fw-400`">
          <template v-if="description === 'POP AI Writer'">
            <div class="d-flex align-center">
              <span>{{ description }}</span>
              <span class="new-badge">NEW</span>
            </div>
          </template>
          <span v-else>{{ description }}</span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: ["item"],
  computed: {
    colorClass() {
      return this.$vuetify.theme.dark ? "white-color" : "black-color"
    }
  }
};
</script>

<style scoped>
.border-full {
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
  height: 550px;
  padding-left: 10px;
  padding-right: 10px;
}
.small-img {
  height: 10px;
  width: 10px;
}
.center-text {
  text-align: center;
}
.items-center {
  align-items: center;
}
.head-bold {
  font-family: Inter, sans-serif;
  letter-spacing: 1px;
  font-size: 16px !important;
  font-weight: 900;
  color: black;
  margin-bottom: .625rem !important;
}
.price-bold {
  font-family: Inter, sans-serif;
  color: black !important;
  font-size: 32px !important;
  font-weight: 900;
}
.dollar-bold {
  font-family: Inter, sans-serif;
  color: black !important;
  font-size: 18px !important;
  font-weight: 900;
}
.month-bold {
  font-family: Inter, sans-serif;
  color: black !important;
  font-size: 14px !important;
  font-weight: 500;
}
.price-parent {
  padding-top: 10px;
}
.start-btn {
  background-color: #ff7a00;
  text-align: center;
  mix-blend-mode: normal;
  align-self: stretch;
  margin-right: 0;
  padding: .5rem;
  font-size: 18px;
  transition: all .4s;
  display: block;
  border-radius: 10px;
  font-weight: 600;
  font-family: Inter, sans-serif;
}
.fw-400 {
  font-weight: 400 !important;
}
.black-color {
  color: black !important;
}
.white-color {
  color: white !important;
}
.new-badge {
  background-color: #dbffe3;
  color: #12b972;
  border: 1px solid #b8eed8;
  border-radius: 5px;
  padding: 2px;
  margin-left: 4px;
}
.dark-card {
  background-color: #0c2318 !important;
}
.demo-plan-card {
  height: 100%;
  max-height: 510px;
}

.padding-custom {
  padding: .625rem 0 0 0;
}

@media (min-width: 720px) {
  .price-bold {
    font-size: 28px !important;
  }
}
@media (min-width: 1080px) {
  .head-bold {
    margin-bottom: .7rem !important;
  }
}
@media (min-width: 1840px) {
  .padding-custom {
  padding: .625rem;
}
  .price-bold {
    font-size: 40px !important;
  }
  .start-btn {
    padding: .75rem;
  }
}


</style>
