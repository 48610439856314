<template>
  <div>
    <section>
      <div class="hero is-fullheight">
        <div class="hero-body">
          <div class="container mt20">
            <div class="columns">
              <div class="column is-12-tablet is-6-desktop is-6-widescreen">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { DEMO_SESSION_KEYS } from "@/components/PageRun/constants";

export default {
  methods: {
    getToken() {
      if (!DEMO_USER_EMAILS.length) return

      this.$store.commit('showLoading')

      let data = {
        email: DEMO_USER_EMAILS[0],
      }

      return this.$store.dispatch('demo_user_token', data)
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {

            if (response.data.action == 'LOGIN_CODE_NEEDED') {
              this.show2FaModal = true;
              this.$store.commit('hideLoading')
              return;
            }

            this.$store.commit('setCurrentSubAccount', '')
            this.$store.commit('setJwtToken', { jwt: response.data.token, is_demo: true })
            this.$store.commit('setUser', response.data.user)
            this.$store.commit('hideLoading')
            this.$store.dispatch('fetchGoogleSearchLocations')
            segmentTrack('login', {
              email: response.data.user.email,
              userID: response.data.user.id
            })
            sendUserInfoToAppcues(response.data.user);
            if (this.$route.query.redirect) {
              return this.$router.push(this.$route.query.redirect)
            } else {
              // Set theme to light
              this.$vuetify.theme.dark = false;
              let settings = Object.assign({}, this.user.settings)
              settings['isDarkMode'] = isDarkMode;
              this.$store.dispatch('updateUserSettings', settings)
              return this.$router.push('/sites')
            }
          } else {
            return this.$router.push('/login')
          }
        })
        .catch(_ => {
          return this.$router.push('/sites')
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
  },
  mounted() {
    Object.values(DEMO_SESSION_KEYS).forEach(key => sessionStorage.removeItem(key))
    this.getToken();
  }
}
</script>

<style scoped></style>
