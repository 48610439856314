<template>
    <div class="d-flex mt-3" v-if="isAiTab">
      <div v-if="!currentPage.lastAiRunInfo?.aiScore" class="score-chart-box mt-3">
        <radial-progress-bar 
          :diameter="70" 
          :completed-steps="0" 
          :total-steps="100" 
          :strokeWidth="8" 
          startColor="#40c8f9"
          stopColor="#40c8f9" 
          innerStrokeColor="#eeeeee" 
          strokeLinecap="square"
        >
          <div class="has-text-weight-bold" :style="{ 'color': '#40c8f9' }">N/A</div>
        </radial-progress-bar>
        <div class="score-label">
          <span>Optimization Score</span>
          <div class="score-explain">
            Optimization Score is currently unavailable.
            To view your score, please finalize the creation of your AI writer report.
          </div>
        </div>
      </div>
      <div v-else class="d-flex mt-3">
        <div class="score-chart-box mt-3">
          <radial-progress-bar
          v-if="$store.getters.isDemoUser"
          :diameter="70" 
          :completed-steps="16" 
          :total-steps="100"
          :strokeWidth="8"
          :startColor="`${pageScoreTintColor(16)}AA`"
          :stopColor="`${pageScoreTintColor(16)}55`"
          :innerStrokeColor="`${pageScoreTintColor(16)}22`" 
          strokeLinecap="square"
        >
          <div class="has-text-weight-bold" 
            :style="{ 'color': pageScoreTintColor(16) }"
          >
            {{ 16 | round(1) }}
          </div>
        </radial-progress-bar>
        <radial-progress-bar 
          v-else
          :diameter="70" 
          :completed-steps="currentPage.lastAiRunInfo?.aiScore" 
          :total-steps="100"
          :strokeWidth="8"
          :startColor="`${pageScoreTintColor(currentPage.lastAiRunInfo?.aiScore || 0)}AA`"
          :stopColor="`${pageScoreTintColor(currentPage.lastAiRunInfo?.aiScore || 0)}55`"
          :innerStrokeColor="`${pageScoreTintColor(currentPage.lastAiRunInfo?.aiScore || 0)}22`" 
          strokeLinecap="square"
        >
          <div class="has-text-weight-bold" 
            :style="{ 'color': pageScoreTintColor(currentPage.lastAiRunInfo?.aiScore) }"
          >
            {{ (currentPage.lastAiRunInfo?.aiScore || 0 ) | round(1) }}
          </div>
        </radial-progress-bar>
          
          <div class="score-label">
            <div v-html="getStrategyScoreType(currentPage, 'aiWriter')"></div>
            <div class="mt5">
              <span class="grey--text text--darken-1 last-report">
                Last report: {{currentPage.lastPageRunInfo ? _.utc(currentPage.lastPageRunInfo.createdAt).format('MMM DD, YYYY') : 'None'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3" v-else>
      <div v-if="!(currentPage?.lastPageRunInfo && currentPage.lastPageRunInfo?.pageNotBuilt)">
        <div v-if="getStrategyScore(currentPage)">
          <div class="score-chart-box">
            <radial-progress-bar :diameter="70" :completed-steps="getStrategyScore(currentPage)" :total-steps="100"
              :strokeWidth="8" :startColor="`${pageScoreTintColor(getStrategyScore(currentPage))}AA`"
              :stopColor="`${pageScoreTintColor(getStrategyScore(currentPage))}55`"
              :innerStrokeColor="`${pageScoreTintColor(getStrategyScore(currentPage))}22`" strokeLinecap="square">
              <div class="has-text-weight-bold" :style="{ 'color': pageScoreTintColor(getStrategyScore(currentPage)) }">
                {{ getStrategyScore(currentPage) | round(1) }}
              </div>
            </radial-progress-bar>
            <div class="score-label">
              <div v-html="getStrategyScoreType(currentPage, '')"></div>
              <div class="mt5">
                <span class="grey--text text--darken-1 last-report">
                  Last report: {{ currentPage.lastPageRunInfo ? _.utc(currentPage.lastPageRunInfo.createdAt).format('MMM DD, YYYY') :
                    'None' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt3" v-else>
        <div class="score-chart-box">
          <radial-progress-bar :diameter="70" :completed-steps="0" :total-steps="100" :strokeWidth="8" startColor="#40c8f9"
            stopColor="#40c8f9" :innerStrokeColor="'#eeeeee'" strokeLinecap="square">
            <div class="has-text-weight-bold" :style="{ 'color': '#40c8f9' }">N/A</div>
          </radial-progress-bar>
          <div class="score-label">
            <span>Optimization Score</span>
            <div class="score-explain">No Optimization Score just yet. After creating your content, re-run
              POP with that content to see your score.</div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { mixin } from '@/components/mixin'
import RadialProgressBar from 'vue-radial-progress'
import moment from 'moment'
export default {
  mixins: [mixin],
  components: {RadialProgressBar},
  props: ["currentPage", "currentToolTab"],
  computed: {
    isAiTab(){
      return this.currentToolTab === "ai-writer"
    },
    _(){
      return moment
    },
  },
}
</script>