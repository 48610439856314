<template>
<div class="pie-chart">
  <canvas :id="id" style="width: 100%; height: 300px;"></canvas>
</div>
</template>

<script>
import Chart from 'chart.js'
import { smallUniqueId } from '@/utils'
export default {
  props: ['labels', 'values'],
  data () {
    return {
      id: smallUniqueId(),
      chart: null
    }
  },
  methods: {
    update (labels, values) {
      this.chart.data.datasets[0].data = values
      this.chart.data.labels = labels
      this.chart.update()
    }
  },
  beforeMount () {
    setTimeout(() => {
      const el = document.getElementById(this.id)
      const values = this.values
      const labels = this.labels
      this.chart = new Chart(el, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: values,
            backgroundColor: [
              "rgb(54, 162, 235)", // blue:
              "rgb(75, 192, 192)", // green: 
              "rgb(255, 159, 64)", // orange: 
              "rgb(201, 203, 207)", // grey: 
              "rgb(153, 102, 255)", // purple: 
              "rgb(255, 99, 132)", // red: 
              "rgb(255, 205, 86)", // yellow: 
              "rgb(0, 0, 128)" // extension color
            ]
          }]
        },
        options: { responsive: true }
      })
    }, 100)
  }
}
</script>

<style scoped>
  
</style>