export const toolTabs = [
  {
    key: 'on-page-optimization',
    label: 'On Page Optimization'
  },
  {
    key: 'ai-writer',
    label: 'AI Writer'
  },
  {
    key: 'silos',
    label: 'Silos',
    requiresDevModePermission: 'silo'
  },
  {
    key: 'keyword-research',
    label: 'Keyword Research',
    isSpecialUserOnly: true,
  },
]

export const DEV_MODE_TOOLS = [
  {
    label: 'Keyword strategy - All tools',
    key: 'keyword-wizard',
  },
  {
    label: 'Keyword strategy - New Content only',
    key: 'keyword-wizard/new-content',
  },
  {
    label: 'Keyword strategy - Existing Content only',
    key: 'keyword-wizard/existing-content',
  },
  {
    label: 'Silo',
    key: 'silo',
  },
  {
    label: 'Keyword insight',
    key: 'keyword-insight',
  },
  {
    label: 'Keyword gap',
    key: 'keyword-gap',
  },
  {
    label: 'Keyword tracking',
    key: 'keyword-tracking',
  },
]
