<template>
<div class="play-with-ai-root">
  <div class="white-common-box ma-5 pa-5">
    <div class="page-title">Have fun with AI</div>
    <v-textarea outlined rows="10"
      label="Please input your prompt here"
      class="mt-5"
      color="greenColor"
      v-model="prompt">
    </v-textarea>
    <div class="text-right">
      <v-btn rounded
        color="profileAnchorColor"
        class="text-none whiteColor--text"
        :disabled="!prompt"
        @click="getAnswerWithAI">
        <span>Try it out</span>
      </v-btn>
    </div>
    <div class="result-area" v-if="aiAnswer || aiError">
      <div class="mb-2 font-weight-bold">AI Answer</div>
      <div v-html="aiAnswerForRender"></div>
      <hr />
      <div class="mb-2 font-weight-bold">AI Error</div>
      <div>{{ aiError }}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      prompt: '',
      aiAnswer: '',
      aiError: '',
    }
  },
  computed: {
    aiAnswerForRender () {
      return this.aiAnswer.replaceAll('\n', '<br />')
    },
  },
  methods: {
    getAnswerWithAI () {
      this.$store.commit('showLoading')
      this.$store.dispatch('playWithOpenAI', {
        'prompt': this.prompt,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.aiAnswer = response.data.aiAnswer
          this.aiError = response.data.aiError
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Error'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.play-with-ai-root {
  background: var(--v-mainBgColor-base);
}
.page-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
}

.result-area {
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  border: 2px solid var(--v-grayColor-base);
  color: var(--v-mainColor-base);
  overflow-y: auto;
  max-height: 600px;
}
</style>