<template>
  <div class="px-2">
    <div class="d-flex align-center mt-3 mb-5">
      <div class="flex-grow-1 report-one-page-name gap10">
        <span>Google EEAT</span>
        <span class="material-icons video-tutorial-icon video-tutorial-icon-size" @click="() => {
          $store.commit('setCurrentVideoTutorialToDisplay', {
            title: 'How To Use E-E-A-T',
            videoLink: 'https://www.youtube.com/embed/dqw4Kog1LJI?si=da3-qiL0mGWTN4_k',
            description: 'This video shows where you can find the E-E-A-T Signals checkbox, and how to read the E-E-A-T Dashboard inside PageOptimizer Pro',
            time: '2m 13s'
          });
        }">
          videocam
        </span>
      </div>
      <div>
        <div class="how-to-use-this-dashboard" @click="openHowtoUseGuide">
          <img src="/static/cap.svg" />
          <span>How to use this dashboard?</span>
        </div>
        <div class="mt-2 d-flex justify-end">
          <table-docx-pdf-download
            :data="getValidSchemas(mainPageSchemaTypes).concat(missingSignals)"
            :description-obj="signalExplainData"
            :target-url="extractHostnameWithProtocol(targetURL)"
          />
        </div>
      </div>
    </div>
    <div class="dashboard-text mb-2">
      <div class="mb-2">
        <span>
          EEAT are signals Google uses to gauge trust and authority of your site. If your page/site doesn’t include enough of these signals, you run the risk of dropping in rank.          
        </span>
      </div>
      <div>
        <span>
          Base EEAT will show you which EEAT signals are absent from your page and which signals your competitors have. Run this tool as a bare minimum for EEAT optimization.
        </span>
      </div>
    </div>
    <div v-if="!isEatUsedInReport" class="nlp-or-eeat-is-not-used-box">
      <div class="demo-area">
        <img src="/static/eeat-demo.svg" class="demo-img" />
      </div>
      <div class="mainColor--text" style="max-width: 550px;">
        <div class="re-run-for-eeat">Re-run POP to get your EEAT report</div>
        <div class="mt-5 mainColor--text">
          Please re-run POP and we will create your EEAT report. This will use 2 POP Credits. You can also purchase more POP Credits on any plan and use them to run various POP features such as EEAT.
        </div>
        <div class="mt-5 d-flex align-center gap10">
          <v-btn rounded small color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            v-if="!currentUser.isVendor" @click="rerunAnalysisForThisReport">
            <i class="fa fa-refresh"></i>
            <span class='has-text-centered'>Re-run</span>
          </v-btn>
          <v-btn rounded small outlined color="mainColor" class="button-with-icon mainColor--text text-none"
            :to="`/useraccount/${currentUser.id}?mainTab=billing-and-payments&subTab=buy-credits`">
            <i class="fa fa-money"></i>
            <span class="">Buy credits</span>
          </v-btn>
        </div>
        <!-- <a @click="gotoProfile" class="profileAnchorColor--text">Check out plans here</a> -->
      </div>
    </div>
    <template v-else>
      <v-card class="is-fullheight d-flex flex-column white-common-box pa-5 mainColor--text" v-if="eatStatus !== 'SUCCESS'">
        <template v-if="isEatUsedInReport">
          <div v-if="eatStatus === 'PENDING' || eatStatus === 'PROCESSING'">
            Depending on the size of your target page and the size of your competitor pages, EEAT analysis can take up
            to an additional 5 minutes. We pop up a notification when your EEAT analysis is completed.
          </div>
          <div v-else-if="eatStatus === 'FAILURE'">
            Unfortunately we were not able to complete the EEAT analysis. <br />
            The failure is due to something expected. Please contact support with this Report ID so we can investigate
            the issue.
          </div>
        </template>
        <template v-else>
          <div>
            * Please rerun to see this data <br />
            ( On the rerun, select "I need to make some changes" and then check the box for EEAT to be analyzed. )
          </div>
        </template>
      </v-card>
      <div class="columns" v-if="eatStatus === 'SUCCESS'">
        <div class="column is-one-third" v-if="!pageNotBuilt">
          <v-card class="is-fullheight d-flex flex-column white-common-box">
            <div class="pa-5">
              <div>
                <div>
                  <template>
                    <div class="mb-2">
                      <span class="box-title mr-2">Your Page</span>
                    </div>
                    <a :href="targetURL" target='_blank' class="competitor-link-custom ">{{ targetURL }}</a>
                    <div class="color-explain my-5">
                      <!-- <div><span class="is-faded-green-bg"></span>This EEAT signal is present on your page</div>
                      <div><span class="is-faded-red-bg"></span>This EEAT signal is absent on your page</div> -->
                    </div>
                    <div class="flex-container">
                      <v-menu v-for="(schema, idx) in getValidSchemas(mainPageSchemaTypes)" :key="'main-signal-popup' + idx"
                        :activator="`#main-signal-menu-${idx}`" bottom offset-y>
                        <div class="v-menu-as-tooltip">
                          <div class="title-text">{{ schema.signal }}</div>
                          <div class="mt-3">{{ signalExplainData[schema.signal] }}</div>
                        </div>
                      </v-menu>
                      <table class="table is-fullwidth">
                        <tbody>
                          <tr v-for="(schema, idx) in getValidSchemas(mainPageSchemaTypes)" :key="idx"
                            :class="{ 'is-odd-row': idx % 2 === 0, 'rounded-row': true }">
                            <td class="is-vcentered tooltip-target dotted-text" :id="`main-signal-menu-${idx}`"
                              :key="'main-signal-item' + idx" style="width: 60%">
                              <a class="font-design"> {{ schema.signal }}</a>
                            </td>
                            <td class="is-vcentered">
                              <span class="signal-element"
                                :class="{ 'text-design-present': true, 'highlight': schema.signal === highlightSignal }">Present</span>
                            </td>
                            <td class="is-vcentered" style="width: 20%" />
                          </tr>
                          <tr v-for="(schema, idx) in missingSignals" :key="'missing-signal-item' + idx"
                            :class="{ 'is-odd-row': idx % 2 === 0, 'rounded-row': true }">
                            <td class="is-vcentered tooltip-target dotted-text" :id="`missing-signal-menu-${idx}`"
                              :key="'main-signal-item' + idx" style="width: 60%">
                              <a class="font-design"> {{ schema.signal }}</a>
                            </td>
                            <td class="is-vcentered" style="width: 20%">
                              <span class="signal-element" :class="{ 'text-design-absent': true }">Absent</span>
                            </td>
                            <td class="is-vcentered" style="width: 20%">
                              <v-btn v-if="isInChecklist(schema)" :disabled="$store.getters.isDemoUser" rounded x-small outlined color="greenColor"
                                class="button-with-icon text-none add-to-color">
                                <span class="material-icons">add_task</span>
                                <span>Added to to-do list</span>
                              </v-btn>
                              <v-btn v-else :disabled="$store.getters.isDemoUser" rounded x-small color="profileAnchorColor"
                                class="button-with-icon text-none whiteColor--text" @click="() => addToChecklist(schema)">
                                <span class="material-icons">add_task</span>
                                <span>Add to to-do list</span>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <div v-for="(schema, idx) in missingSignals" :key="'missing-signal-item' + idx"
                        :id="`missing-signal-menu-${idx}`" class="signal-element" :class="{ 'is-faded-red-bg': true }">
                        <span class="tooltip-target">
                          {{ schema.signal }}
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </div> -->
                      <v-menu v-for="(schema, idx) in missingSignals" :key="'missing-signal-popup' + idx"
                        :activator="`#missing-signal-menu-${idx}`" bottom offset-y>
                        <div class="v-menu-as-tooltip">
                          <div class="title-text">{{ schema.signal }}</div>
                          <div class="mt-3">{{ signalExplainData[schema.signal] }}</div>
                          <!-- <div class="mt-3">
                            <v-btn v-if="isInChecklist(schema)" rounded x-small outlined color="greenColor"
                              class="button-with-icon text-none">
                              <span class="material-icons">add_task</span>
                              <span>Added to to-do list</span>
                            </v-btn>
                            <v-btn v-else rounded x-small color="profileAnchorColor"
                              class="button-with-icon text-none whiteColor--text" @click="() => addToChecklist(schema)">
                              <span class="material-icons">add_task</span>
                              <span>Add to to-do list</span>
                            </v-btn>
                          </div> -->
                        </div>
                      </v-menu>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="column"> <!-- Adjust the width as needed -->
          <div>
            <div>
              <div v-if="isEatUsedInReport">
                <div class="columns is-multiline">
                  <div class="column is-one-third" v-for="(c, idx) in competitors" :key="c.id">
                    <v-card class="is-fullheight d-flex flex-column white-common-box">
                      <div class="pa-5">
                        <div class="mb-2">
                          <span class="box-title">Competitor {{ idx + 1 }}</span>
                        </div>
                        <a :href="c.url" target='_blank' class="competitor-link-custom">{{ c.url }}</a>
                        <div class="my-5 mainColor--text">
                          <!-- This competitor has the following EEAT signals on their page: -->
                        </div>
                        <div class="flex-container">
                          <div v-for="(schema, idx) in getValidSchemas(c.eatSchemaTypes)" :key="`comp_item_${c.id}_${idx}`"
                            :id="`competitor_${c.id}-${idx}`" class="signal-element font16-design">
                            <span class="tooltip-target">
                              {{ schema.signal }}
                              <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </span>
                          </div>
                          <v-menu v-for="(schema, idx) in getValidSchemas(c.eatSchemaTypes)"
                            :key="`comp_popup_${c.id}_${idx}`" :activator="`#competitor_${c.id}-${idx}`" bottom offset-y>
                            <div class="v-menu-as-tooltip">
                              <div class="title-text">{{ schema.signal }}</div>
                              <div class="mt-3">{{ signalExplainData[schema.signal] }}</div>
                            </div>
                          </v-menu>
                          <!-- <v-popover
                      v-for="(schema, idx) in getValidSchemas(c.eatSchemaTypes)" :key="`${c.id}_${idx}`"
                      offset="10"
                      class="signal-element"
                    >
                      <span
                        class="tooltip-target"
                      >
                        {{ schema.signal }} <span v-if="yesNoSignals.includes(schema.signal)">{{ schema.result ? ': Yes' : ': No' }}</span>
                        <span v-if="isNumber(schema.result)">( {{ schema.result }} )</span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                      <template slot="popover">
                      <div>
                        <div class="is-size-4 has-text-weight-bold">{{ schema.signal }}</div>
                        {{ signalExplainData[schema.signal] }}
                      </div>
                      </template>
                    </v-popover> -->
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { mixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'

import explainData from '@/components/PageRun/eat-data/EatSchemaExplanation'
import TableDocxPdfDownload from './TableDocxPdfDownload.vue'

let linksCountSignal = [
  'Total Internal Links',
  'Internal links without 404',
  'Internal Broken links',
]

let yesNoSignals = [
  'Internal Link'
]

export default {
  mixins: [mixin, ServiceMixin],
  props: [
    'pageId', 'targetURL', 'pageNotBuilt', 'mainPageSchemaTypes', 'commonEatSchemaTypes', 'competitors', 'checkLists',
    'eatStatus', 'isEatCalculationUsed',
    'devLog',
  ],
  components: { TableDocxPdfDownload },
  data() {
    return {


      highlightSignal: '', // on mousehover to display it. not used now.
      signalExplainData: {},
    }
  },
  computed: {
    isEatUsedInReport () {
      return this.isEatCalculationUsed;
    },
    yesNoSignals() {
      return yesNoSignals
    },
    currentUser() {
      return this.$store.state.user;
    },
    ownerUser() {
      return this.$store.state.selectedSubAccountRelation || this.currentUser
    },
    filteredCommonEatSchemas() {
      let result = this.commonEatSchemaTypes.filter(item => {
        return item.count > 0
      })
      result.sort((a, b) => {
        if (a.count > b.count) return -1
        if (a.count < b.count) return 1
        if (a.count === b.count) return 0
      })
      return result
    },
    missingSignals() {
      const signalsCurrentPageHas = this.getValidSchemas(this.mainPageSchemaTypes).map(item => item.signal)

      return this.commonEatSchemaTypes.filter(item => {
        if (linksCountSignal.includes(item.signal)) {
          return false;
        }
        if (signalsCurrentPageHas.includes(item.signal)) {
          return false;
        }
        if (item.count <= 1) {
          return false;
        }
        return true;
      })
    },
    isAvailableEAT() {
      // return (this.$store.state.selectedSubAccountRelation || this.isAgencyUnlimitedUser)
      return this.ownerUser.remainedEATUsage > 0
    }
  },
  methods: {
    getHostName(url) {
      if (url.indexOf("//") > -1) {
        return url.split("/")[2];
      } else {
        return url.split("/")[0];
      }
    },
    extractHostnameWithProtocol(url) {
      let hostname = this.getHostName(url);
      if (!url.includes(hostname)) hostname = this.getHostName(url); // this validated domain is different to user inputed, then we will use user inputted
      if (url.toLowerCase().startsWith("https")) {
        return "https://" + hostname;
      } else {
        return "http://" + hostname;
      }
    },
    isInChecklist(eatItem) {
      let found = this.checkLists.find(item => item['signal'] == eatItem['signal'])
      return found
    },
    openHowtoUseGuide() {
      window.open('https://help.pageoptimizer.pro/article/pop-google-eeat-dashboard', '_blank')
    },
    gotoProfile() {
      const userId = this.currentUser ? this.currentUser.id : '';
      if (userId) {
        this.$router.push(`/useraccount/${userId}`);
      } else {
        alert('Please login');
      }
    },
    isNumber(n) {
      return !isNaN(parseFloat(n)) && !isNaN(n - 0)
    },
    getValidSchemas(schemas) {
      // console.log('get eeat schema for valid', schemas)
      if (!Array.isArray(schemas)) return []
      return schemas.filter(item => {
        if (item.result === true) return true
        if (this.isNumber(item.result) && item.result > 0) return true
        return false
      })
    },
    isTargetHasIt(signal) {
      let found = this.mainPageSchemaTypes.find(item => item.signal === signal)
      if (found && found['result']) {
        return true
      }
      return false
    },
    isCommonSignal(signal) {
      // console.log('checking if it\'s common signal', signal)
      let found = this.commonEatSchemaTypes.find(item => item.signal === signal)
      if (found && found['count'] >= 1) {
        return true
      }
      return false
    },
    isAverageSignal(signal) {
      return linksCountSignal.includes(signal)
    },
    haveTobeRed(signal) {
      if (linksCountSignal.includes(signal)) {
        return false
      } else {
        let found = this.commonEatSchemaTypes.find(item => item.signal === signal)
        let mainPageCount = this.mainPageSchemaTypes.find(item => item.signal === signal)['result']
        if (!found) {
          return true;
        } else {
          return false;
        }
        // console.log('nope', found['count'], mainPageCount)
        // return mainPageCount <= found['count']
      }
    },
    addToChecklist(row) {
      let data = {
        pageId: this.pageId,
        recommendationType: 'EEAT',
        type: 6,
        dispType: '',
        signal: row.signal
      }
      console.log(data)
      this.$store.commit('showLoading')
      return this.$store.dispatch('addOneToCheckList', data)
        .then(response => {
          if (response.data && response.data.status === 'SUCCESS') {
            console.log('successfully added!')
          }
          if (response.data.page) {
            this.$store.commit('setPage', response.data.page)
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
  },
  beforeMount() {
    this.signalExplainData = explainData
  }
}

</script>


<style scoped lang="scss">

.box-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--v-mainColor-base);
}

.target-has-it {
  color: #00cd3b;
}

.target-has-not-it {
  color: rgb(255, 44, 44);
}

.common-signal {
  color: #00cd3b;
}

.signal-element {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 5px;
  // background: rgb(126, 126, 126);
  background: #E6E9F0;
  color: white;
  margin: 5px;
}

.highlight {
  position: relative;
  border: 1px solid #94d0f6;
  /* transform: scale(1.3); */
  background: var(--v-link7Color-base);
  transition: .6s;
}

.is-faded-green-bg {
  background: #62c57d;
  color: white;
}

.is-faded-red-bg {
  background-color: var(--v-red3Color-base);
  color: white;
}


.is-odd-row {
  background-color: #F3F4FA;
}

.table.is-fullwidth tbody tr {
  border-radius: 10px;
  /* Adjust the value to your desired corner radius */
}

.dotted-text {
  text-decoration: underline dashed
}

.text-design-present {
  background-color: #D6FBEE;
  color: #25A97A;
  font-weight: 700;
  font-size: 14px;
}

.text-design-absent {
  background-color: #FBE8E8;
  color: #FF3939;
  font-weight: 700;
  font-size: 14px;
}

.font-design {
  color: #014751;
  font-weight: 700;
  font-size: 14px;
}

.font16-design {
  font-size: 16px;
  font-weight: 700;
  color: #014751;
}

.competitor-link-custom {
  color: #138EFF;
  font-weight: 800;
  font-size: 14px;
}

.dashboard-text {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--v-mainColor-base);
  padding-bottom: 16px;
}

.custom-width{
  width: 22px !important;
}
.add-to-color{
  color: #25A97A !important;
  background-color: #D6FBEE;
}
</style>