<template>
<div class="sub-account-log-component">
  <v-card class="is-fullheight flex flex-column white-common-box pa-0">
    <!-- <table>
      <thead>
        <tr>
          <th>Date & Time</th>
          <th>Sub-account user email</th>
          <th>Sub-account user action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(log, idx) in logs" :key="idx">
          <td>{{ toLocalDateTimeString(log.createdAt) }}</td>
          <td>{{ log.subAccountEmail }}</td>
          <td>{{ log.action }}</td>
        </tr>
      </tbody>
    </table> -->
    <div class="hscroll-container">
      <div class="mx-2 my-5">
        <v-text-field outlined x-small hide-details="auto"
          v-model="searchText"
          label="Filter log"
          @keyup.enter="() => {
            loadData()
          }"
          color="greenColor"></v-text-field>
      </div>
      <b-table
        class="subaccount-log-table"
        :data="records"
        :loading="isLoading"
        :paginated="totalRows > 0"
        :total="totalRows"
        :per-page="perPage"
        @page-change="onPageChange"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort">
        <template slot-scope="props">
          <b-table-column field="createdAt" label="Date & Time" class="text-left">
            {{ toLocalDateTimeString(props.row.createdAt) }}
          </b-table-column>
          <b-table-column field="subAccountEmail" label="Sub-account user email">
            {{ props.row.subAccountEmail }}
          </b-table-column>
          <b-table-column field="action" label="Sub-account user action">
            {{ props.row.action | capitalize }}
          </b-table-column>
        </template>
      </b-table>
    </div>
  </v-card>
</div>
</template>

<script>
import { toLocalDateTimeString } from '@/utils/index.js';

export default {
  name: 'SubAccountsActivityLog',
  data () {
    return {
      isLoading: false,
      totalRows: 0,
      records: [],

      currentPage: 1,
      perPage: 10, // no pagination for now for the total credits
      sortField: "runId",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      searchText: "",
    }
  },
  computed: {
    // logs () {
    //   return [...Array(5).fill(0).map(x => {
    //     return {
    //       createdAt: '2023-08-30 17:01:04',
    //       'subAccountEmail': 'test@gmail.com',
    //       'action': 'did something ...'
    //     }
    //   })]
    // }
  },
  methods: {
    toLocalDateTimeString (d) {
      return toLocalDateTimeString(d)
    },
    loadData() {
      // let startDate = moment(this.dates[0]).startOf('day').toDate();
      // let endDate = moment(this.dates[1]).endOf('day').toDate();

      if (this.isLoading) return;
      this.isLoading = true;
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.searchText}`,
        // `startDate=${startDate.getTime()}`,
        // `endDate=${endDate.getTime()}`,
      ].join("&");

      this.$store.dispatch('getSubAccountActivityLogs', { params })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          console.log(response.data)
          this.totalRows = response.data.totalRecords;
          this.records = response.data.records;
        } else {
          this.totalRows = 0;
          this.records = [];
        }
      })
      .finally(() => {
        this.isLoading = false;
      })
    },
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="scss">
.sub-account-log-component {
  table {
    .th-wrap {
      font-weight: 500 !important;
    }
    th, td {
      color: var(--v-mainColor-base);
    }
    th {
      padding: 10px 13px;
      border-bottom: 1.5px solid var(--v-grayColor-base) !important;
    }
    td {
      padding: 13px 13px;
    }
    tbody tr:not(:last-child) td {
      border-bottom: 1.5px solid var(--v-grayColor-base) !important;
    }
  }
}
</style>