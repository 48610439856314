<template>
<v-menu open-on-hover offset-y>
  <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
    <a class="action-icon-btn" v-bind="menuAttrs" v-on="menuOn">
      <i class="material-icons-outlined">download_for_offline</i>
    </a>
  </template>
  <v-list>
    <v-list-item @click="downloadSchema">
      <v-list-item-title>JSON</v-list-item-title>
    </v-list-item>
    <v-list-item @click="generatePDF">
      <v-list-item-title>PDF</v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>
</template>

<script>
import { jsPDF } from "jspdf";
import moment from "moment";
import pdfHeaderLogo from '../../../public/static/schema-page-run-pdf-logo.png';
import bulbIcon from '../../../public/static/bulb-icon.png';
import { getReportHeaderImage } from "../../utils/download-reports";

function getBase64FromUrl(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

export default {
  props: ['getPDFData'],
  methods: {
    downloadSchema() {
      this.$emit("downloadJSON");
    },
    generatePDF() {
      getReportHeaderImage(this.$store, "schema").then((responseData) => {
        const doc = new jsPDF();
        doc.setDrawColor(0);
        doc.setFillColor(1, 35, 35);
        const startX = 14;
        let currentY = 10;
        doc.addImage(responseData.headerImg, 'png', startX, currentY, 182, 71);
        currentY += 70;
  
        const bullets = [
          'POP analyzes the schema used on top competing pages.',
          'We then filter and build the optimal custom schema for you to add to the page.',
          '',
        ];
  
        let rows = [
          [
            {
              content: '',
              styles: {
                minCellWidth: 40,
                minCellHeight: 20,
                cellPadding: 1,
                halign: 'right',
                valign: 'middle',
                fillColor: '#FF5700',
                textColor: '#FFFFFF',
              },
            },
            {
              content: 'Add Schema to this page!',
              styles: {
                minCellWidth: 40,
                minCellHeight: 20,
                cellPadding: 1,
                halign: 'left',
                valign: 'middle',
                fillColor: '#FF5700',
                fontSize: 22.65,
                fontStyle: 'bold',
                textColor: '#FFFFFF',
              },
            },
          ],
          [
            {
              colSpan: 2,
              content: bullets.map((bullet) => `${bullet}`).join('\n'),
              styles: {
                halign: 'center',
                fillColor: '#FFEDDD',
                fontSize: 12,
                lineHeight: 20,
                fontStyle: 'normal',
                textColor: '#000000',
              },
            },
          ],
        ];
  
        // Define the styles for the table
        let tableOptions = {
          startY: currentY + 8, // You can adjust this to place the table where you want in the PDF
          tableLineColor: '#FF5700',
          tableLineWidth: 0.5,
          styles: {
            cellPadding: 5,
            valign: 'middle',
            fontSize: 12,
            textColor: [0, 0, 0], // Black text color
          },
          body: rows,
          didDrawCell: (imageSrc => function (data) {
            const doc = data.doc;
            
            if (data.row.index === 0 && data.column.index === 0) {
              const { x, y, width, height } = data.cell;
              const imgWidth = height - 4;
              const imgX = x + width - imgWidth - 2;
              doc.addImage(imageSrc, 'png', imgX + 8, y + 4, 10, 10);
            }
  
            if (data.row.index === 1 && data.column.index === 0) {
              // First part of the text
              const textBeforeLink = 'For instructions on how to publish schema to your page, ';
              const linkText = 'click here.';
              const linkY = data.cell.y + data.cell.height - 6;
              const cellWidth = data.cell.width;
              const totalTextWidth = doc.getTextWidth(textBeforeLink) + doc.getTextWidth(linkText);
              const centeredX = data.cell.x + (cellWidth - totalTextWidth) / 2; // Center the total text width
  
              // Add the first part of the text
              doc.text(textBeforeLink, centeredX, linkY); 
  
              // Calculate the width of the text before the link
              const linkStartX = centeredX + doc.getTextWidth(textBeforeLink); 
              
              // Add the link text
              doc.setTextColor(255, 87, 0);
              doc.text(linkText, linkStartX, linkY); 
  
              const underlineY = linkY + 1;
              const linkWidth = doc.getTextWidth(linkText);
              const lineHeight = 0.5;
              doc.setDrawColor(255, 87, 0);
              doc.setLineWidth(lineHeight);
              doc.line(linkStartX, underlineY, linkStartX + linkWidth, underlineY);
  
              // Create the clickable link
              doc.link(linkStartX, linkY - 10, doc.getTextWidth(linkText), 10, { url: 'https://help.pageoptimizer.pro/article/how-to-add-pageoptimizer-pros-schema-to-your-pages' }); // Replace with your actual URL
            }
          })(this.bulbIcon),
        };
  
        doc.autoTable(tableOptions);
  
        rows = [
          [
            {
              content: "Organization Schema",
              styles: {
                fillColor: '#FFEDDD',
                fontSize: 22.65,
                fontStyle: 'bold',
                textColor: '#000000',
              },
            },
          ],
          [
            {
              content: this.getPDFData(),
              styles: {
                fillColor: '#FFEDDD',
                fontSize: 12,
                textColor: '#000000',
              },
            },
          ],
        ];
  
        tableOptions = {
          tableLineColor: '#FF5700',
          tableLineWidth: 0.5,
          styles: {
            cellPadding: 5,
            valign: 'middle',
            fontSize: 12,
            textColor: [0, 0, 0], // Black text color
          },
          body: rows,
        };
  
        doc.autoTable(tableOptions);
  
        const timestamp = moment().format('YYYY_M_D_h_mm_ss');
        doc.save(`google_json_${timestamp}.pdf`);
      })
    },
  },
  data() {
    return {
      reportLogo: null,
      bulbIcon: null,
    };
  },
  mounted() {
    getBase64FromUrl(pdfHeaderLogo, (base64) => {
      this.reportLogo = base64;
    });
    getBase64FromUrl(bulbIcon, (base64) => {
      this.bulbIcon = base64;
    });
  },
};
</script>

<style scoped lang="scss">
.action-icon-btn {
  background: var(--v-lightYellowColor-base);
  display: inline-flex;
  align-items: center;
  padding: 5px;

  & > i {
    color: var(--v-profileAnchorColor-base);
  }
}
</style>
