<template>
<div>

</div>
</template>

<script>
import moment from 'moment';


export const DATE_RANGE_OPTIONS = [
  {
    label: 'Last 7 days',
    value: 'last_7_days',
  },
  {
    label: 'Last 28 days',
    value: 'last_28_days',
  },
  {
    label: "Last 3 months",
    value: 'last_3_months',
  },
  {
    label: "Last 6 months",
    value: 'last_6_months',
  },
  {
    label: "Last 12 months",
    value: 'last_12_months',
  },
  {
    label: "Last 16 months",
    value: 'last_16_months',
  },
  // {
  //   label: "Custom",
  //   value: 'custom',
  // },
]


export const getOptionDate = (option) => {
  let endDate = ''
  let startDate = ''
  switch (option.value) {
    case 'last_7_days':
      endDate = moment().format('YYYY-MM-DD')
      startDate = moment().add(-7, 'days').format('YYYY-MM-DD')
      return {
        startDate,
        endDate
      }
    case 'last_28_days':
      endDate = moment().format('YYYY-MM-DD')
      startDate = moment().add(-28, 'days').format('YYYY-MM-DD')
      return {
        startDate,
        endDate
      }
    case 'last_3_months':
      endDate = moment().format('YYYY-MM-DD')
      startDate = moment().add(-3, 'months').format('YYYY-MM-DD')
      return {
        startDate,
        endDate
      }
    case 'last_6_months':
      endDate = moment().format('YYYY-MM-DD')
      startDate = moment().add(-6, 'months').format('YYYY-MM-DD')
      return {
        startDate,
        endDate
      }
    case 'last_12_months':
      endDate = moment().format('YYYY-MM-DD')
      startDate = moment().add(-12, 'months').format('YYYY-MM-DD')
      return {
        startDate,
        endDate
      }
    case 'last_16_months':
      endDate = moment().format('YYYY-MM-DD')
      startDate = moment().add(-16, 'months').format('YYYY-MM-DD')
      return {
        startDate,
        endDate
      }
    case 'custom':
      endDate = option.start_date
      startDate = option.end_date
      return {
        startDate,
        endDate
      }
    default:
      return null
      break;
  }
}

export default {
  data () {
    return {
      startDate: '',
      endDate: '',
    }
  }

}
</script>

<style lang="scss" scoped>

</style>