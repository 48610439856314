<template>
  <div class="cancel-subscription-confirm-page">
    <div class="">
      <div class="mt20">
        <div class="font-large mb10">
          We are sorry to see you go.
          <b>Are you sure you want to cancel?</b>
        </div>
        <div class="font-small content-width">
          <p>
            <b>Important:</b>
            If you cancel your POP subscription, please note you'll retain the ability to create new projects, pages, and re-run reports until your current subscription period expires.
            Additionally, if you've purchased credits without an active subscription, you can still create projects and runs using these credits.
          </p>
        </div>
        <div class="content-area content-width">
          <div class="downgrade-box border text-left">
            <div class="is-size-2 text-left">
              <img src="/static/alert.png" width="105" />
            </div>
            <div class="font-large mt10 mb10">
              <b>Protect your sites with POP Watchdog</b>
            </div>
            <div class="is-size-6 ">
              Get alerts when competitors make changes. POP Watchdog tells you what to fix to reclaim your positions.
            </div>
            <div class="flex align-center mt10 border p20">
              <div class="icon-div">
                <img src="/static/guard.png" />
              </div>
              <div class="pl20 is-size-6">
                <div class="mb5 has-text-weight-bold is-size-6">
                  <!-- Downgrade from ${{ activeSubscription.credits }} / month to $15 / month -->
                  Free trial $0 / 45 days, Then $15 / month.
                </div>
                <div class="mb20 has-text-weight-bold is-size-6">
                  <!-- Downgrade from ${{ activeSubscription.credits }} / month to $15 / month -->
                  Cancel anytime.
                </div>
                Downgrade to POP Watchdog for <span class="blackColor--text bold"><b>$15 / month</b></span> and let POP
                automatically rerun reports for you to identify which variables
                changed and how to act on those changes to stay on top.
              </div>
            </div>
            <div class="pt30">
              <div class="btnContainer mb10 ">
                <div style="flex-grow: 1; align-items: center; display: flex;">
                  <span class="material-icons-outlined">credit_card</span>
                  <span class="font-medium ml10">Credit Card</span>
                </div>
                <button class="downgrade-btn" @click="submit">
                  Downgrade
                </button>
              </div>
              <!-- <div class="btnContainer ">
                <div style="flex-grow: 1; align-items: center; display: flex;">
                  <img src="/static/paypal.png" width="25" />
                  <span class="font-medium ml10">PayPal</span>
                </div>
                <button class="downgrade-btn" @click="submitForPayPal">
                  Downgrade
                </button>
              </div> -->
            </div>
          </div>
          <div class="bottom-buttons mt10">
            <button class="never-mind-btn" @click="neverMindClick">
              <span class="material-icons-outlined">arrow_back</span>
              <span class="font-weight-bold"> Nevermind! Take me back to my account</span>
            </button>
            <button class="still-want-cancel-btn" @click="gotoFeedbackPage">I still want to cancel</button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal" :class="{ 'is-active': showConfirmUseExistingPayment }">
      <div class="modal-background" @click.stop="onCancelNewPurchase"></div>
      <div class="modal-content" style="min-width: 50%;">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="title-font mainColor--text is-size-40">
              Payment Source Already On File
            </div>
            <div class="body-font is-size-6 has-text-grey is-italic mt-5">
              Looks like we already have a payment source on file for you ending in {{ preferredCard ? ( preferredCard.last4 || '' ) : '' }}
            </div>
            <div class="control flex mt-5">
              <div class="flex-grow-1"></div>
              <v-btn rounded color="profileAnchorColor" class="button-with-icon text-none whiteColor--text"
                @click.stop="onConfirmUseExistingCard">Use Card On File</v-btn>
              <v-btn rounded color="profileAnchorColor" class="button-with-icon text-none whiteColor--text"
                @click.stop="onUseDifferentCard">Enter Different Card</v-btn>
              <v-btn rounded color="grayColor" class="button-with-icon text-none white--text"
                @click.stop="onCancelNewPurchase">Cancel</v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- <button class="modal-close is-large" aria-label="close" @click.stop="onCancelNewPurchase"></button> -->
    </div>

    <div class="modal" :class="{ 'is-active': showErrorModal }">
      <div class="modal-background" @click.stop="clearErrorModal"></div>
      <div class="modal-content">
        <div class="box modal-container has-text-centered">
          <p class="title">Transaction failure</p>
          <br>
          <p class="subtitle" v-if="!errorMsg">Please verify your payment method is valid.</p>
          <p class="subtitle" v-if="errorMsg">{{ errorMsg }}</p>
          <div class="field">
            <div class="control">
              <button class="button is-primary" @click.stop="clearErrorModal">Back to Purchases</button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click.stop="clearErrorModal"></button>
    </div>

    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background" @click.stop="showModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container has-text-centered">
          <p class="title">Purchase Complete</p>
          <p class="subtitle">{{ successMsg }}</p>
          <p class="subtitle" v-if="!successMsg">Please click the button below to go to projects page and run a page
            analysis</p>
          <div class="field">
            <div class="control">
              <button class="button is-primary" @click.stop="goToSites">Go to Projects</button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click.stop="showModal = false"></button>
    </div>

  </div>
</template>

<script>

import { doStripeCheckout, segmentTrack, segmentPage } from '@/utils'
import servicePackages from '@/components/service_package_constants'

export default {
  name: "CancelSubscriptionConfirm",
  computed: {
    user() {
      return this.$store.state.user;
    },
    activeSubscription() {
      return this.user.purchases.find(
        p => p.id == this.user.activeSubscription
      );
    },
    watchDogServicePackage() {
      return servicePackages['SERVICE_PKG_Z']
    },
    selectedServicePkg() {
      if (this.watchDogServicePackage) {
        return this.watchDogServicePackage.code
      } else {
        return ''
      }
    },
    serviceDescription() {
      return this.watchDogServicePackage ? this.watchDogServicePackage.description : '';
    },
    credits() {
      return this.watchDogServicePackage ? this.watchDogServicePackage.price : 0;
    }
  },
  data() {
    return {
      showModal: false,
      errorMsg: '',
      successMsg: '',
      showErrorModal: false,

      isDowngradeByStripe: false,
      confirmedUseExistingPayment: false,
      showConfirmUseExistingPayment: false,
      useDifferentCard: false,
      preferredCard: '',

      promoCode: '',
    };
  },
  methods: {
    clearErrorModal () {
      this.errorMsg = ''
      this.showErrorModal = false
    },
    goToSites() {
      this.showModal = false
      this.$router.push('/sites')
    },
    neverMindClick() {
      this.$store.commit('showLoading');
      this.$store.dispatch('neverMindClicked')
        .then(response => {

        })
        .finally(() => {
          this.$store.commit('hideLoading');
          // this.$router.go(-1);
          this.goToProfilePage();
        })
    },
    goToProfilePage() {
      this.$router.push(`/useraccount/${this.user.id}`);
    },
    gotoFeedbackPage() {
      this.$router.push(`/tell-us-your-experience`);
    },
    onConfirmUseExistingCard() {
      this.confirmedUseExistingPayment = true
      this.hideConfirmUseExistingCardDialog()
      this.submit()
    },
    onUseDifferentCard() {
      this.$router.push(`/useraccount/${this.user.id}?mainTab=billing-and-payments&subTab=credit-cards`)
      // this.useDifferentCard = true
      // this.hideConfirmUseExistingCardDialog()
      // this.submit()
    },
    onCancelNewPurchase() {
      this.confirmedUseExistingPayment = false
      this.useDifferentCard = false
      this.hideConfirmUseExistingCardDialog()
    },
    hideConfirmUseExistingCardDialog() {
      this.showConfirmUseExistingPayment = false
    },
    submit() {
      this.isDowngradeByStripe = true
      const order = {
        description: this.serviceDescription,
        servicePackage: this.selectedServicePkg,
        amount: this.credits
      }
      if (this.credits < 10) {
        return
      }
      let promise
      if (this.user.hasPaymentSource && !this.useDifferentCard) {
        if (!this.confirmedUseExistingPayment) {
          this.showConfirmUseExistingPayment = true
          return;
        }
        this.$store.commit('showLoading')
        promise = this.$store.dispatch('createPurchase', {
          userId: this.user.id,
          servicePackage: this.selectedServicePkg,
          stripeToken: '',
          paymentService: 'stripe',
          description: order.description,
          promoCode: this.promoCode.trim()
        })
      } else {
        this.$refs.noStripeCardModalRef.showModalAction();
        return;
        // promise = doStripeCheckout(this.user.email, order)
        //   .then((response) => {
        //     if (response.data && response.data.status === 'CLOSED') {
        //       return new Promise((resolve, reject) => {
        //         resolve(response)
        //       })
        //     } else {
        //       this.$store.commit('showLoading')
        //       return this.$store.dispatch('createPurchase', {
        //         userId: this.user.id,
        //         servicePackage: this.selectedServicePkg,
        //         stripeToken: response.stripeToken,
        //         paymentService: 'stripe',
        //         description: order.description,
        //         promoCode: this.promoCode.trim()
        //       })
        //     }
        //   })
      }
      promise.then((response) => {
        if (response.data.status === 'FAILURE') {
          this.errorMsg = response.data.msg
          this.showErrorModal = true
        } else if (response.data.status === 'SUCCESS') {
          this.showModal = true
          this.successMsg = response.data.msg
          this.$store.commit('setUser', response.data.user)
          segmentTrack('purchase', {
            paymentService: 'stripe',
            email: response.data.user.email,
            userID: response.data.user.id,
            servicePackage: this.selectedServicePkg,
            promoCode: this.promoCode.trim()
          })
        } else if (response.data.status === 'CLOSED') {
          this.errorMsg = response.data.errorMsg
        }
        return 1
      })
        .catch((err) => {
          this.errorMsg = "Transaction denied"
          console.log('stripe error', err)
        })
        .finally(() => this.$store.commit('hideLoading'))
    },

    submitForPayPal() {
      this.isDowngradeByStripe = false
      this.$store.commit('showLoading')
      return this.$store.dispatch('createPurchase', {
        userId: this.user.id,
        servicePackage: this.selectedServicePkg,
        paymentService: 'paypal',
        promoCode: this.promoCode.trim()
      })
        .then((response) => {
          if (response.data.status === 'FAILURE') {
            this.errorMsg = response.data.msg
            this.showErrorModal = true
          } else {
            this.$store.commit('setUser', response.data.user)
            segmentTrack('purchase', {
              paymentService: 'paypal',
              email: response.data.user.email,
              userID: response.data.user.email.id,
              servicePackage: this.selectedServicePkg,
              promoCode: this.promoCode.trim()
            })
            window.open(response.data.approvalUrl, '_self')
          }
        })
        .catch((err) => {
          this.errorMsg = "Transaction denied"
          console.log('stripe error', err)
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
  },
  mounted () {
    if (this.user.hasPaymentSource) {
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchPaymentSources').then((response) => {
        if (response.data.status == 'SUCCESS') {
          const preferredCard = response.data.cards.find(card => card.preferred)
          this.preferredCard = preferredCard
        } else {
          console.log('error fetching cards')
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    }
  }
};
</script>

<style scoped lang="scss">
* {
  font-size: 1rem;
}

.font-large,
*.font-large * {
  font-size: 24px;
  line-height: 2rem;
}

.font-medium {
  font-size: 20px;
}

.font-small,
*.font-small * {
  font-size: 16px;
}

.content-width {
  width: 796px;
  margin: auto;
}

.btnContainer {
  display: flex;
  align-items: center;
  background: var(--v-gray33Color-base);
  border-radius: 10px;
  padding: 1rem 2rem;
  .material-icons-outlined {
    font-size: 1.5rem;
    color: var(--v-mainColor-base);
  }
}

.cancel-subscription-confirm-page {
  margin: 2% auto 0 auto;
  text-align: center;
}


.border {
  border: 1px solid #E4E4E4;
  border-radius: 8px;
}

.content-area {
  padding: 2rem 0rem 2rem 0rem;
  color: var(--v-mainColor-base) !important;

  .downgrade-box {
    padding: 2rem;
    background-color: var(--v-cardBg-base);

    .downgrade-btn {
      background: #014751;
      border-radius: 30px;
      color: white;
      width: 115px;
      font-size: 1rem;
      padding: 0.6rem 1rem;
      cursor: pointer;
      font-weight: bold;
    }
  }

  .note {
    font-size: 0.65rem;
    margin: 1.5rem 0;
    padding: 0 2rem;
    text-align: center;
  }

  .bottom-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .never-mind-btn {
      padding: 1rem 0rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      .material-icons-outlined {
        font-size: 1.2rem;
        color: var(--v-mainColor-base);
      }
    }

    .still-want-cancel-btn {
      background-color: #FF5700;
      color: white;
      font-weight: bold;
      border-radius: 2rem;
      text-underline-offset: 3px;
      padding: 1.4rem 2rem;
    }
  }
}

@media only screen and (max-width: 700px) {
  .cancel-subscription-confirm-page {
    width: 100vw;
  }
  .content-width {
    width: 100%;
  }
  .bottom-buttons {
    flex-direction: column;
  }
}</style>
