<template>
  <div id="content-brief">
    <div v-if="!hasContentBriefData">
      <div class="box is-size-5">
        Please rerun to see this data.
        <div>Select "make some changes" and re-run all the steps</div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-center mt-3 mb-5">
        <div class="flex-grow-1 report-one-page-name gap10">
          <span>Content brief</span>
          <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
            @click="() => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: 'How to Read the Content Brief',
                videoLink: 'https://www.youtube.com/embed/c5gp5EJdWRw?si=qBubSPJMjyGSmaqK',
                description: 'In this video we detail how to read the Content Brief. Going over what is, and is not scored as well as how to interpret the Target Range Column.',
                time: '2m 55s'
              });
            }">
            videocam
          </span>
        </div>
      </div>
      <div class="top-section">
        <div class="flex-grow-1">
          <v-card class="is-fullheight d-flex flex-column white-common-box">
            <div class="pl-5 pr-5 pt-5 mainColor--text">
              <div class="mt10 d-flex align-start">
                <span class="section-title">Summary</span>
                <span class="ml20 is-size-6 flex-grow-1">
                  Keyword: <span class="ml5 font-weight-bold">{{ keyword }}</span>
                </span>
                <div>
                  <v-btn icon small :id="`dot-menu-for-summary`">
                    <span class="material-icons-outlined mainColor--text">more_horiz</span>
                  </v-btn>
                  <v-menu 
                    :activator="`#dot-menu-for-summary`"
                    bottom offset-y>
                    <v-list>
                      <v-list-item link
                        v-if="secretKey"
                        v-clipboard:copy="secretKey"
                        v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
                        v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })">
                        <v-list-item-title
                          class="mainColor--text">
                          Copy secret key
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="downloadContentBrief('excel')">
                        <v-list-item-title>Download Excel</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="downloadContentBrief('pdf')">
                        <v-list-item-title>Download PDF</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div class="mt10">
                Slug:
                <a
                  class="title-font has-text-grey is-underline word-break-all"
                  @click="openUrlInNewTab(url)"
                  >{{ getSubFolderPartFromUrl(url) }}</a
                >
              </div>
              <div class="mt10">
                Report ID: {{ pageRunId }}&nbsp;&nbsp;&nbsp;
                Time: {{ getDateAsLocalString(createdAt) }}
              </div>
            </div>
            <div class="gray-1-bar mt-10"></div>
            <div class="pt-7 pl-5 pr-5 pb-5">
              <div class="d-flex flex-wrap flex-col-gap-5 flex-row-gap-5">
                <v-btn rounded :x-small="$vuetify.breakpoint.lgAndDown"
                  color="profileAnchorColor"
                  @click.stop="() => openFullPageEditor('AI_MODE')"
                  class="button-with-icon whiteColor--text text-none"
                  data-tooltip="Open Content editor"
                >
                  <span class="material-icons-outlined">open_in_new</span>
                  <span>Write with AI Writer</span>
                </v-btn>
                <v-btn :disabled="$store.getters.isDemoUser" rounded outlined :x-small="$vuetify.breakpoint.lgAndDown"
                  color="mainColor"
                  class="button-with-icon text-none"
                  @click.stop="() => enterPageSetting(pageRun)"
                  data-tooltip="Open Watch dog Setting"
                >
                  <span class="material-icons-outlined">verified_user</span>
                  <span>Protect this page with Watchdog</span>
                </v-btn>
                <v-btn :disabled="$store.getters.isDemoUser" rounded outlined :x-small="$vuetify.breakpoint.lgAndDown"
                  color="mainColor"
                  @click.stop="() => openFullPageEditor()"
                  class="button-with-icon text-none"
                  data-tooltip="Open Content editor"
                >
                  <i class="fa fa-external-link" aria-hidden="true"></i>
                  <span>Content Editor</span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
        <div>
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 mainColor--text">
              <div class="section-title">
                Content Brief<br/>
                Optimization Score
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons info-icon-size mainColor--text" v-bind="attrs" v-on="on">info</i>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    Improve this score by getting each section into the target range. The goal is to get to 100%
                  </div>
                </v-tooltip>
              </div>
            </div>
            <div class="pl-5 pr-5 flex-grow-1 flex align-center">
              <radial-progress-bar
                :diameter="60"
                :completed-steps="pageNotBuilt ? 0 : score"
                :total-steps="100"
                :strokeWidth="6"
                :startColor="pageScoreTintColor(score)"
                :stopColor="pageScoreTintColor(score)"
                :innerStrokeColor="'#eeeeee'"
                strokeLinecap="square"
              >
              </radial-progress-bar>
              <div class="ml10 big-number">
                <template v-if="pageNotBuilt">N/A</template>
                <template v-else>{{ score | round(1) }}</template>
              </div>
            </div>
            <div class="gray-1-bar mt-3 mb-3"></div>
            <div class="mainColor--text px-5 pb-5">
              <div class="d-flex align-center">
                <div class="font-weight-bold" style="font-size: 0.75rem;">Consider over-optimization</div>
                <span class="material-icons video-tutorial-icon video-tutorial-icon-size ml-1"
                  @click="() => {
                    $store.commit('setCurrentVideoTutorialToDisplay', {
                      title: 'The Over Optimization Toggle',
                      videoLink: 'https://www.youtube.com/embed/66rPs5TFcHc?si=X4fuPjUjLCYhNI_j',
                      description: 'In this video Kyle goes over the brand new Over Optimization Toggle feature. What it does, and when you should be using it.',
                      time: '2m 26s'
                    });
                  }">
                  videocam
                </span>
              </div>
              <div class="mt-2 d-flex">
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="d-flex align-center">
                      <vue-toggles
                        :disabled="$store.getters.isDemoUser"
                        :value="pageRun && pageRun.contentBriefScoringMethod ? true : false"
                        width="40" height="20"
                        :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                        :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                        @click="() => toggleContentBriefScoringMethodAction(!pageRun.contentBriefScoringMethod)"
                        >
                      </vue-toggles>
                      <div class="font-weight-bold ml-2">
                        {{ pageRun.contentBriefScoringMethod ? 'On' : 'Off' }}
                      </div>
                    </div>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    Over-optimization for individual terms can be problematic, though not all the time.  If you have reached a score of 100% in the Content Brief and your page is still stuck, toggle ON and check for over-optimized terms.  
                    <br /><br />
                    BE CAREFUL, removing terms can be a high risk, high reward tactic.  Make note of what you remove and where you removed it from in case your page drops and you need to reinsert those terms.
                  </div>
                </v-tooltip>
              </div>
            </div>
          </v-card>
        </div>
        <div>
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 mainColor--text">
              <div class="section-title">
                Current Word<br/>
                Count
              </div>
            </div>
            <div class="pl-5 pr-5 flex-grow-1 d-flex flex-column justify-center">
              <div>
                <div class="flex align-baseline justify-space-between mainColor--text mb15">
                  <div class="big-number">{{ pageNotBuilt ? 'N/A' : wordCountData[0] }}</div>
                  <div class="nowrap">Target: {{ wordCountData[1] }}</div>
                  <v-tooltip top color="transparent" v-if="wordCountData[0] > wordCountData[1]">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons info-icon-size mainColor--text" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      Your current word count is above the suggested target word count. <br/>
                      To start, we recommend keeping your word count as is and optimize your page with the suggestions below rather than decreasing your word count and deoptimizing your page.
                    </div>
                  </v-tooltip>
                </div>
                <v-progress-linear
                  background-color="grayColor"
                  :color="pageScoreTintColor(( pageNotBuilt ? 0 : wordCountData[0] ) * 100 / wordCountData [1])"
                  :value="( pageNotBuilt ? 0 : wordCountData[0] ) * 100 / wordCountData [1]"
                  
                  ></v-progress-linear>
              </div>
            </div>
            <div class="gray-1-bar my-3"></div>
            <div class="pb-5 px-5 mainColor--text d-flex align-center" style="min-height: 72px;">
              <div class="flex-grow-1">
                <div class="d-flex align-center gap10">
                  <div class="font-weight-bold" style="font-size: 0.75rem;">Custom word count</div>
                  <vue-toggles
                    :disabled="$store.getters.isDemoUser"
                    :value="showCustomWordCountUI"
                    width="40" height="20"
                    :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                    :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                    @click="() => {
                      if (showCustomWordCountUI) {
                        saveCustomWordCountAction(false)
                        .then(() => {
                          showCustomWordCountUI = false;
                        })
                      } else {
                        showCustomWordCountUI = !showCustomWordCountUI
                      }
                      
                    }">
                  </vue-toggles>
                </div>
                <div v-if="showCustomWordCountUI" class="d-flex align-center gap10 mt-1">
                  <input type="number" class="custom-word-input" v-model.number="customWordCountInputed" />
                  <v-btn x-small rounded
                    :disabled="customWordCountInputed <= 0"
                    color="profileAnchorColor" class="text-none whiteColor--text"
                    @click="() => {
                      saveCustomWordCountAction(true)
                    }">
                    <span>Save</span>
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- <div class="pa-5 mainColor--text d-flex align-center">
              {{ pageNotBuilt ? '' : wordCountData[2] }}
              <v-tooltip top color="transparent" v-if="wordCountData[2].startsWith('Leave')">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size mainColor--text ml-2" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  Your page is over the recommended target word count. For now, leave as is and make sure your important terms are in the correct range for this word count.
                </div>
              </v-tooltip>
            </div> -->
          </v-card>
        </div>
        <div>
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 mainColor--text">
              <div class="section-title">
                Page<br/>
                Sections
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons info-icon-size mainColor--text" v-bind="attrs" v-on="on">info</i>
                  </template>
                  <div class="white-bg-tooltip" style="max-width: 400px;">
                    These are different sections on a page, often broken up by a subheading, such as H2 or H3.
                  </div>
                </v-tooltip>
              </div>
            </div>
            <div class="pl-5 pr-2 flex-grow-1 d-flex flex-wrap align-center mainColor--text">
              <div class="big-number mr5">{{ subHeadingsTarget }}</div>
              <div class="nowrap flex-grow-1">sections recommended</div>
            </div>
            <div class="pa-5 mainColor--text">
              On this page. use around {{ subHeadingsTarget }} page sections.
            </div>              
          </v-card>
        </div>
      </div>

      <div class="sort-filter-block flex">
        <div>
          <span class="view-type-selector">
            <span class="is-size-7 mainColor--text">View as</span>
            <i
              class="material-icons"
              :class="{ 'is-active': termDispType == 'table' }"
              @click="termDispType = 'table'"
              >format_list_bulleted</i
            >
            <i
              class="material-icons"
              :class="{ 'is-active': termDispType == 'list' }"
              @click="termDispType = 'list'"
              >apps</i
            >
          </span>
        </div>
        <div
          class="flex-grow-1 is-size-7 pl10 mainColor--text has-text-right">
          Sort by:
          <select v-model="sortMethod" class="is-size-7 mainColor--text">
            <option value="importance">Most Important</option>
            <option value="deficit">Deficit</option>
            <option value="alphabetic">Alphabetic</option>
            <option value="status">Status</option>
          </select>
        </div>
      </div>

      <div class="content-brief-box">
        <div class="d-flex">
          <!-- <hr class="flex-grow-1" style="margin: 0.6rem 0;"/> -->
          <div v-if="isCollapseSearchEngineTitleArea"
            class="section-title flex-grow-1">
            Search Engine Title
          </div>
          <div v-else class="flex-grow-1 cb-section-top">
            <div>
              <span class="section-title">Search Engine Title</span>
              <v-tooltip bottom color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  In your website editor, this is your &lt;title&gt; tag, sometimes called the meta title.
                </div>
              </v-tooltip>
            </div>
            <div class="d-flex">
              <span class="term-range-size mainColor--text d-flex align-center">
                <template v-if="termDispType == 'list'">
                  ( {{ titleTotal["current"] }} / {{ titleTotal["min"] }} - {{ titleTotal["max"] }} )
                </template>
                <template v-else>
                  <span class="darkGrayColor--text">Important Terms Range:&nbsp;</span>({{ titleTotal["min"] }} - {{ titleTotal["max"] }})
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="ml-2 material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      For best SEO results, aim for the middle of this range
                    </div>
                  </v-tooltip>
                  <span class="darkGrayColor--text ml-3">Current Count:&nbsp;</span> {{ titleTotal["current"] }}
                </template>
              </span>
              <v-tooltip left color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="stoplights-box"
                    :style="{ background: getStopLightColorForSection('title') }"
                    v-bind="attrs"
                    v-on="on"
                  ></span>
                </template>
                <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForSection('title'))}}</div>
              </v-tooltip>
            </div>
            <div>
              <!-- Complete / Incomplete -->
              <div class="control darkGrayColor--text">
                <label class="radio">
                  <input
                    type="radio"
                    name="title_status"
                    :disabled="isDemo"
                    :checked="contentBriefStatus['title'] == 'Completed'"
                    @click.stop="
                      updateContentBriefSectionStatus('title', 'Completed')
                    "
                  />
                  Complete
                </label>
                <label class="radio">
                  <input
                    type="radio"
                    name="title_status"
                    :disabled="isDemo"
                    :checked="contentBriefStatus['title'] == 'InCompleted'"
                    @click.stop="
                      updateContentBriefSectionStatus('title', 'InCompleted')
                    "
                  />
                  Incomplete
                </label>
                <span
                  v-if="showNoSecondaryKeywordsInSection('title')"
                  class="no-secondary-keywords ml20">Secondary keywords found.
                </span>
              </div>
            </div>
            <div class="text-right">
              <a class="disp-note-btn text--darken-2" @click.stop="isDemo ? null : displayNoteModalFor('title')">
                <img src="/static/note-icon.png" />
                <span>Notes</span>
              </a>
            </div>
          </div>
          <a
            v-if="!isCollapseSearchEngineTitleArea"
            @click.stop="isCollapseSearchEngineTitleArea = true">
            <span class="material-icons fold_icon">unfold_less</span>
          </a>
          <a v-else @click.stop="isCollapseSearchEngineTitleArea = false">
            <span class="material-icons fold_icon">unfold_more</span>
          </a>
        </div>
        <div
          v-if="!isCollapseSearchEngineTitleArea"
          class="mb10"
        >
          <span v-show="showSearchTitleExplainText"
            class="has-text-grey has-text-weight-light text-subtitle-2 is-italic">
            Make sure that your title contains your important terms in the
            suggested range. You do not need to use every important term. You
            should select the ones you like and use them to reach the target
            range for the section.<br/>
            Use your competitor titles in Content prompts for inspiration.<br/>
            Your Search Engine Title, the title that search engines see, and
            your H1, the title that humans see, should be as identical as
            possible.
          </span>
          <a
            class="show-hide-link"
            @click.stop="showSearchTitleExplainText = !showSearchTitleExplainText"
            >{{ showSearchTitleExplainText ? "Hide" : "More info" }}</a
          >
        </div>
        <div v-if="!isCollapseSearchEngineTitleArea">
          <div v-if="termDispType == 'list'">
            <span
              v-for="(item, idx) in titleData"
              :key="idx"
              class="content-brief-tag"
              :style="{
                'border-color': getStopLightColorForItem('title', item)
              }"
            >
              <span>{{ item.term.phrase }}</span>
              <span
                >{{ item.contentBrief.current }} /
                {{
                  item.term.type == "keyword"
                    ? Math.round(
                        item.contentBrief.target *
                          TARGET_MIN_PERCENTAGE["title"]
                      ) +
                      " - " +
                      item.contentBrief.target
                    : item.contentBrief.targetMin +
                      " - " +
                      item.contentBrief.targetMax
                }}</span
              >
            </span>
          </div>
          <div v-else-if="termDispType == 'table'">
            <table class="signal-table" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th style="width: 45%;">Important Term</th>
                  <th>Current Usage</th>
                  <th>Target Range</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in titleData" :key="idx">
                  <td>
                    <input :disabled="isDemo" type="checkbox" class="term-checkbox"
                      v-model="selectedCBItems"
                      :value="{
                        sectionName: 'title',
                        item: item
                      }"
                       />
                    {{ item.term.phrase }}
                  </td>
                  <td>{{ item.contentBrief.current }}</td>
                  <td>
                    <span class="target-range">
                      {{
                        item.term.type == "keyword"
                          ? Math.round(
                              item.contentBrief.target *
                                TARGET_MIN_PERCENTAGE["title"]
                            ) +
                            " - " +
                            item.contentBrief.target
                          : item.contentBrief.targetMin +
                            " - " +
                            item.contentBrief.targetMax
                      }}
                    </span>
                    <v-tooltip left color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="stoplights-box"
                          :style="{ background: getStopLightColorForItem('title', item) }"
                          v-bind="attrs"
                          v-on="on"
                        ></span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForItem('title', item))}}</div>
                    </v-tooltip>
                    <span
                      v-if="item.term.type == 'keyword'"
                      class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Using your exact keyword is critical to SEO success"
                      >Must Use
                    </span>
                    <span
                      v-if="isSecondaryKeyword(item.term.phrase)"
                      class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Use your secondary keyword"
                      >Secondary KW
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="my-2">
              <div class="terms-removed-by-you">
                Terms Removed by you
              </div>
              <div>
                <div
                  class="removed-cb-term mt5 mr10"
                  v-for="(item, idx) in excludedTermsForTitle"
                  :key="idx"
                >
                  <div class="phrase">
                    {{ item.term.phrase }}
                  </div>
                  <button
                    class="recover-btn"
                    @click="() => removeOrRecoverTerm('recover', item, 'title')"
                  >
                    <i class="material-icons">replay</i>
                    <span>Recover</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            Unsupported View Type
          </div>
          <div>
            <a class="download-link" @click.stop="isDemo ? null : downloadAsTxt('title')"
              >Download as .txt</a
            >
          </div>
        </div>
      </div>
      <div class="content-brief-box">
        <div class="d-flex">
          <!-- <hr class="flex-grow-1" style="margin: 0.6rem 0;"/> -->
          <div v-if="isCollapsePageTitleArea"
            class="section-title flex-grow-1"         >
            Page Title
          </div>
          <div v-else class="flex-grow-1 cb-section-top"
          >
            <div>
              <span class="section-title">Page Title</span>
              <v-tooltip bottom color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  In your website editor this is your H1 tag.
                </div>
              </v-tooltip>
            </div>
            <div class="d-flex">
              <span class="term-range-size mainColor--text d-flex align-center">
                <template v-if="termDispType == 'list'">
                  ( {{ pageTitleTotal["current"] }} / {{ pageTitleTotal["min"] }} - {{ pageTitleTotal["max"] }} )
                </template>
                <template v-else>
                  <span class="darkGrayColor--text">Important Terms Range:&nbsp;</span>({{ pageTitleTotal["min"] }} - {{ pageTitleTotal["max"] }})
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="ml-2 material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      For best SEO results, aim for the middle of this range
                    </div>
                  </v-tooltip>
                  <span class="darkGrayColor--text ml-3">Current Count:&nbsp;</span> {{ pageTitleTotal["current"] }}
                </template>
              </span>
              <v-tooltip left color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="stoplights-box"
                    :style="{ background: getStopLightColorForSection('pageTitle') }"
                    v-bind="attrs"
                    v-on="on"
                  ></span>
                </template>
                <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForSection('pageTitle'))}}</div>
              </v-tooltip>
            </div>
            <div>
              <!-- Complete / Incomplete -->
              <div class="control darkGrayColor--text">
                <label class="radio">
                  <input
                    type="radio"
                    name="pageTitle_status"
                    :disabled="isDemo"
                    :checked="contentBriefStatus['pageTitle'] == 'Completed'"
                    @click.stop="
                      updateContentBriefSectionStatus('pageTitle', 'Completed')
                    "
                  />
                  Complete
                </label>
                <label class="radio">
                  <input
                    type="radio"
                    name="pageTitle_status"
                    :disabled="isDemo"
                    :checked="contentBriefStatus['pageTitle'] == 'InCompleted'"
                    @click.stop="
                      updateContentBriefSectionStatus('pageTitle', 'InCompleted')
                    "
                  />
                  Incomplete
                </label>
                <span
                  v-if="showNoSecondaryKeywordsInSection('pageTitle')"
                  class="no-secondary-keywords ml20">Secondary keywords found.
                </span>
              </div>
            </div>
            <div class="text-right">
              <a
                class="disp-note-btn text--darken-2"
                @click.stop="isDemo ? null : displayNoteModalFor('pageTitle')"
              >
                <img src="/static/note-icon.png" />
                <span>Notes</span>
              </a>
            </div>
          </div>
          <a
            v-if="!isCollapsePageTitleArea"
            @click.stop="isCollapsePageTitleArea = true"
            ><span class="material-icons fold_icon">unfold_less</span></a
          >
          <a v-else @click.stop="isCollapsePageTitleArea = false"
            ><span class="material-icons fold_icon">unfold_more</span></a
          >
        </div>
        <div
          v-if="!isCollapsePageTitleArea"
          class="mb10"
        >
          <span v-show="showPageTitleExplainText"
            class="has-text-grey has-text-weight-light text-subtitle-2 is-italic">>
            Make sure that your page title contains your important terms in the
            suggested range. You do not need to use every important term. You
            should select the ones you like and use them to reach the target
            range for the section.<br/>
            Use your competitor titles in the Content prompts for inspiration.<br/>
            Your Search Engine title, the title that search engines see, and
            your H1, the title that humans see, should be as identical as
            possible. You should only have one H1, one title visible on your
            page.
          </span>
          <a
            class="show-hide-link"
            @click.stop="showPageTitleExplainText = !showPageTitleExplainText"
            >{{ showPageTitleExplainText ? "Hide" : "More info" }}</a
          >
        </div>
        <div v-if="!isCollapsePageTitleArea">
          <div v-if="termDispType == 'list'">
            <span
              v-for="(item, idx) in pageTitleData"
              :key="idx"
              class="content-brief-tag"
              :style="{
                'border-color': getStopLightColorForItem('pageTitle', item)
              }"
            >
              <span>{{ item.term.phrase }}</span>
              <span
                >{{ item.contentBrief.current }} /
                {{
                  item.term.type == "keyword"
                    ? Math.round(
                        item.contentBrief.target *
                          TARGET_MIN_PERCENTAGE["pageTitle"]
                      ) +
                      " - " +
                      item.contentBrief.target
                    : item.contentBrief.targetMin +
                      " - " +
                      item.contentBrief.targetMax
                }}</span
              >
            </span>
          </div>
          <div v-else-if="termDispType == 'table'">
            <table class="signal-table" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th style="width: 45%;">Important Term</th>
                  <th>Current Usage</th>
                  <th>Target Range</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in pageTitleData" :key="idx">
                  <td>
                    <input :disabled="isDemo" type="checkbox" class="term-checkbox"
                      v-model="selectedCBItems"
                      :value="{
                        sectionName: 'pageTitle',
                        item: item
                      }"
                       />
                    {{ item.term.phrase }}
                  </td>
                  <td>{{ item.contentBrief.current }}</td>
                  <td>
                    <span class="target-range">
                      {{
                        item.term.type == "keyword"
                          ? Math.round(
                              item.contentBrief.target *
                                TARGET_MIN_PERCENTAGE["pageTitle"]
                            ) +
                            " - " +
                            item.contentBrief.target
                          : item.contentBrief.targetMin +
                            " - " +
                            item.contentBrief.targetMax
                      }}
                    </span>
                    <v-tooltip left color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="stoplights-box"
                          :style="{ background: getStopLightColorForItem('pageTitle', item) }"
                          v-bind="attrs"
                          v-on="on"
                        ></span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForItem('pageTitle', item))}}</div>
                    </v-tooltip>
                    <br v-if="showAsMobileView" />
                    <span
                      v-if="item.term.type == 'keyword'"
                      class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Using your exact keyword is critical to SEO success"
                      >Must Use
                    </span>
                    <span
                      v-if="isSecondaryKeyword(item.term.phrase)"
                      class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Use your secondary keyword"
                      >Secondary KW
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <div class="terms-removed-by-you">
                Terms Removed by You
              </div>
              <div class="my-2">
                <div
                  class="removed-cb-term mt5 mr10"
                  v-for="(item, idx) in excludedTermsForPageTitle"
                  :key="idx"
                >
                  <div class="phrase">
                    {{ item.term.phrase }}
                  </div>
                  <button
                    class="recover-btn"
                    @click="
                      () => removeOrRecoverTerm('recover', item, 'pageTitle')
                    "
                  >
                    Recover
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            Unsupported View Type
          </div>
          <div
            v-if="!hasPageTitleData"
            class="notification is-warning is-italic"
          >
            Please Re-Run and select "Make Changes" to see this new feature.
          </div>
          <div>
            <a class="download-link" @click.stop="isDemo ? null : downloadAsTxt('pageTitle')"
              >Download as .txt</a
            >
          </div>
        </div>
      </div>
      <div class="content-brief-box">
        <div class="d-flex">
          <div v-if="isCollapseSubHeadingsArea"
            class="section-title flex-grow-1"
          >
            SubHeadings
          </div>
          <div v-else class="flex-grow-1 cb-section-top">
            <div>
              <span class="section-title">SubHeadings</span>
              <v-tooltip bottom color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  In your website editor these are H2 or H3 tags
                </div>
              </v-tooltip>
            </div>
            <div class="d-flex">
              <span class="term-range-size mainColor--text d-flex align-center">
                <template v-if="termDispType == 'list'">
                  ( {{ subTitleTotal["current"] }} / {{ subTitleTotal["min"] }} - {{ subTitleTotal["max"] }} )
                </template>
                <template v-else>
                  <span class="darkGrayColor--text">Important Terms Range:&nbsp;</span>({{ subTitleTotal["min"] }} - {{ subTitleTotal["max"] }})
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="ml-2 material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      For best SEO results, aim for the middle of this range
                    </div>
                  </v-tooltip>
                  <span class="darkGrayColor--text ml-3">Current Count:&nbsp;</span>{{ subTitleTotal["current"] }}
                </template>
              </span>
              
              <v-tooltip left color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="stoplights-box"
                    :style="{ background: getStopLightColorForSection('subHeadings') }"
                    v-bind="attrs"
                    v-on="on"
                  ></span>
                </template>
                <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForSection('subHeadings'))}}</div>
              </v-tooltip>
            </div>
            <div>
              <!-- Complete / Incomplete -->
              <div class="control darkGrayColor--text">
                <label class="radio">
                  <input
                    type="radio"
                    name="subheadings_status"
                    :disabled="isDemo"
                    :checked="contentBriefStatus['subHeadings'] == 'Completed'"
                    @click.stop="
                      updateContentBriefSectionStatus(
                        'subHeadings',
                        'Completed'
                      )
                    "
                  />
                  Complete
                </label>
                <label class="radio">
                  <input
                    type="radio"
                    name="subheadings_status"
                    :disabled="isDemo"
                    :checked="
                      contentBriefStatus['subHeadings'] == 'InCompleted'
                    "
                    @click.stop="
                      updateContentBriefSectionStatus(
                        'subHeadings',
                        'InCompleted'
                      )
                    "
                  />
                  Incomplete
                </label>
                <span
                  v-if="showNoSecondaryKeywordsInSection('subHeadings')"
                  class="no-secondary-keywords ml20">Secondary keywords found.
                </span>
              </div>
            </div>
            <div class="text-right">
              <a
                class="disp-note-btn text--darken-2"
                @click.stop="isDemo ? null : displayNoteModalFor('subHeadings')"
              >
                <img src="/static/note-icon.png" />
                <span>Notes</span>
              </a>
            </div>
          </div>
          <a
            v-if="!isCollapseSubHeadingsArea"
            @click.stop="isCollapseSubHeadingsArea = true"
            ><span class="material-icons fold_icon">unfold_less</span></a
          >
          <a v-else @click.stop="isCollapseSubHeadingsArea = false"
            ><span class="material-icons fold_icon">unfold_more</span></a
          >
        </div>
        <div
          v-if="!isCollapseSubHeadingsArea"
          class="mb10"
        >
          <span v-show="showSubtitlesExplainText"
            class="has-text-grey has-text-weight-light text-subtitle-2 is-italic">
            No matter how many subheadings/subtitles are on your page makes sure
            those subheadings/subtitles include the important terms below. You
            do not have to use every important term. You do not have to get
            every important term inside its individual target range.<br />
            The goal is to use as many of the important terms below as needed so
            that the Subheadings Section as a whole gets inside the section's
            target range.<br />
            Use the relevant related keywords at the bottom of the dashboard for
            inspiration on your subtitles.
            <b>
              If you see competitor brand names in this list we highly
              recommend that you re-run and remove them.
            </b>
          </span>
          <a
            class="show-hide-link"
            @click.stop="showSubtitlesExplainText = !showSubtitlesExplainText"
            >{{ showSubtitlesExplainText ? "Hide" : "More info" }}</a
          >
        </div>
        <div class="" v-if="!isCollapseSubHeadingsArea">
          <div v-if="termDispType == 'list'">
            <span
              v-for="(item, idx) in subHeadingsData"
              :key="idx"
              class="content-brief-tag"
              :style="{
                'border-color': getStopLightColorForItem('subHeadings', item)
              }"
            >
              <span>{{ item.term.phrase }}</span>
              <span
                >{{ item.contentBrief.current }} /
                {{
                  item.term.type == "keyword"
                    ? Math.round(
                        item.contentBrief.target *
                          TARGET_MIN_PERCENTAGE["subHeadings"]
                      ) +
                      " - " +
                      item.contentBrief.target
                    : item.contentBrief.targetMin +
                      " - " +
                      item.contentBrief.targetMax
                }}</span
              >
            </span>
          </div>
          <div v-else-if="termDispType == 'table'">
            <table class="signal-table" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th style="width: 45%;">Important Term</th>
                  <th>Current Usage</th>
                  <th>Target Range</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in subHeadingsData" :key="idx">
                  <td>
                    <input :disabled="isDemo" type="checkbox" class="term-checkbox"
                      v-model="selectedCBItems"
                      :value="{
                        sectionName: 'subHeadings',
                        item: item
                      }"
                       />
                    {{ item.term.phrase }}
                  </td>
                  <td>{{ item.contentBrief.current }}</td>
                  <td>
                    <span class="target-range">
                      {{
                        item.term.type == "keyword"
                          ? Math.round(
                              item.contentBrief.target *
                                TARGET_MIN_PERCENTAGE["subHeadings"]
                            ) +
                            " - " +
                            item.contentBrief.target
                          : item.contentBrief.targetMin +
                            " - " +
                            item.contentBrief.targetMax
                      }}
                    </span>
                    
                    <v-tooltip left color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="stoplights-box"
                          :style="{ background: getStopLightColorForItem('subHeadings', item) }"
                          v-bind="attrs"
                          v-on="on"
                        ></span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForItem('subHeadings', item))}}</div>
                    </v-tooltip>
                    <span
                      v-if="
                        item.term.type == 'keyword' &&
                          item.contentBrief.target > 0
                      "
                      class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Using your exact keyword is critical to SEO success"
                      >Must Use
                    </span>
                    <span
                      v-if="isSecondaryKeyword(item.term.phrase)"
                      class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Use your secondary keyword"
                      >Secondary KW
                    </span>
                    <span
                      v-if="isNLPTerm(item.term.phrase)"
                      class="cb-tag nlp-term clickable tooltip is-tooltip-left"
                      data-tooltip="Entities from POP's Natural Language Processing list"
                      >NLP
                    </span>
                    <span
                      v-if="item.term.isGoogleNLPTerm"
                      class="cb-tag google-nlp-term clickable tooltip is-tooltip-left"
                      data-tooltip="Entities from Google's Natural Language Processing list"
                      >Google NLP
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <div class="terms-removed-by-you">
                Terms Removed by You
              </div>
              <div class="my-2">
                <div
                  class="removed-cb-term mt5 mr10"
                  v-for="(item, idx) in excludedTermsForSubHeadings"
                  :key="idx"
                >
                  <div class="phrase" :disabled="true">
                    {{ item.term.phrase }}
                  </div>
                  <button
                    class="recover-btn"
                    @click="
                      () => removeOrRecoverTerm('recover', item, 'subHeadings')
                    "
                  >
                    Recover
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            Unsupported View Type
          </div>
          <div>
            <a class="download-link" @click.stop="isDemo ? null : downloadAsTxt('subHeadings')"
              >Download as .txt</a
            >
          </div>
        </div>
      </div>
      <div class="content-brief-box">
        <div class="d-flex">
          <div
            v-if="isCollapseMainContentArea"
            class="section-title flex-grow-1"
          >
            Main Content
          </div>
          <div
            v-else
            class="flex-grow-1 cb-section-top"
          >
            <div>
              <span class="section-title">Main Content</span>
              <v-tooltip bottom color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  Any text on your page that is in a paragraph, list, or table.
                </div>
              </v-tooltip>
            </div>
            <div class="d-flex">
              <span class="term-range-size mainColor--text d-flex align-center">
                <template v-if="termDispType == 'list'">
                  ( {{ contentTotal["current"] }} / {{ contentTotal["min"] }} -
                  {{ contentTotal["max"] }} )
                </template>
                <template v-else>
                  <span class="darkGrayColor--text">Important Terms Range:&nbsp;</span>({{ contentTotal["min"] }} - {{ contentTotal["max"] }}) 
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="ml-2 material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      For best SEO results, aim for the middle of this range
                    </div>
                  </v-tooltip>
                  <span class="darkGrayColor--text ml-3">Current Count:&nbsp;</span>{{ contentTotal["current"] }}
                </template>
              </span>
              
              <v-tooltip left color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="stoplights-box"
                    :style="{ background: getStopLightColorForSection('p') }"
                    v-bind="attrs"
                    v-on="on"
                  ></span>
                </template>
                <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForSection('p'))}}</div>
              </v-tooltip>
            </div>
            <div>
              <!-- complete / incomplete -->
              <div class="control darkGrayColor--text">
                <label class="radio">
                  <input
                    type="radio"
                    name="maincontent_status"
                    :disabled="isDemo"
                    :checked="contentBriefStatus['p'] == 'Completed'"
                    @click.stop="
                      updateContentBriefSectionStatus('p', 'Completed')
                    "
                  />
                  Complete
                </label>
                <label class="radio">
                  <input
                    type="radio"
                    name="maincontent_status"
                    :disabled="isDemo"
                    :checked="contentBriefStatus['p'] == 'InCompleted'"
                    @click.stop="
                      updateContentBriefSectionStatus('p', 'InCompleted')
                    "
                  />
                  Incomplete
                </label>
                <span
                  v-if="showNoSecondaryKeywordsInSection('p')"
                  class="no-secondary-keywords ml20">Secondary keywords found.
                </span>
              </div>
            </div>
            <div class="text-right">
              <a class="disp-note-btn text--darken-2" @click.stop="isDemo ? null : displayNoteModalFor('p')">
                <img src="/static/note-icon.png" />
                <span>Notes</span>
              </a>
            </div>
          </div>
          <a
            v-if="!isCollapseMainContentArea"
            @click.stop="isCollapseMainContentArea = true"
            ><span class="material-icons fold_icon">unfold_less</span></a
          >
          <a v-else @click.stop="isCollapseMainContentArea = false"
            ><span class="material-icons fold_icon">unfold_more</span></a
          >
        </div>
        <div v-if="!isCollapseMainContentArea"
          class="mb10">
          <span v-show="showMainContentExplainText"
            class="has-text-grey has-text-weight-light text-subtitle-2 is-italic">
            Use the suggested terms below to optimize the main content on your
            page. You do not have to use every important term. You do not have
            to get every important term inside its individual target range.<br />
            The goal is to use as many of the important terms below as needed so
            that the Main Content Section as a whole gets inside the section's
            target range.<br />
            A good approach is to provide information that answers the questions
            your readers have about your target keyword. It's often good to
            write your main content first and then edit for the important terms.
            Use the related questions at the bottom of the dashboard for
            inspiration on the questions your readers may need answered.
            <b>
              If you see competitor brand names in this list we highly
              recommend that you re-run and remove them.
            </b>
          </span>
          <a
            class="show-hide-link"
            @click.stop="
              showMainContentExplainText = !showMainContentExplainText
            "
            >{{ showMainContentExplainText ? "Hide" : "More info" }}</a
          >
        </div>
        <div class="!isCollapseMainContentArea">
          <div v-if="termDispType == 'list'">
            <span
              v-for="(item, idx) in bodyContentData"
              :key="idx"
              class="content-brief-tag"
              :style="{ 'border-color': getStopLightColorForItem('p', item) }"
            >
              <span>{{ item.term.phrase }}</span>
              <span
                >{{ item.contentBrief.current }} /
                {{
                  item.term.type == "keyword"
                    ? Math.round(
                        item.contentBrief.target * TARGET_MIN_PERCENTAGE["p"]
                      ) +
                      " - " +
                      item.contentBrief.target
                    : item.contentBrief.targetMin +
                      " - " +
                      item.contentBrief.targetMax
                }}</span
              >
            </span>
          </div>
          <div v-else-if="termDispType == 'table'">
            <table class="signal-table" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th style="width: 45%;">Important Term</th>
                  <th>Current Usage</th>
                  <th>Target Range</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in bodyContentData" :key="idx">
                  <td>
                    <input :disabled="isDemo" type="checkbox" class="term-checkbox"
                      v-model="selectedCBItems"
                      :value="{
                        sectionName: 'p',
                        item: item
                      }"
                       />
                    {{ item.term.phrase }}
                  </td>
                  <td>{{ item.contentBrief.current }}</td>
                  <td>
                    <span class="target-range">
                      {{
                        item.term.type == "keyword"
                          ? Math.round(
                              item.contentBrief.target *
                                TARGET_MIN_PERCENTAGE["p"]
                            ) +
                            " - " +
                            item.contentBrief.target
                          : item.contentBrief.targetMin +
                            " - " +
                            item.contentBrief.targetMax
                      }}
                    </span>
                    
                    <v-tooltip left color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="stoplights-box"
                          :style="{ background: getStopLightColorForItem('p', item) }"
                          v-bind="attrs"
                          v-on="on"
                        ></span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForItem('p', item))}}</div>
                    </v-tooltip>
                    <br v-if="showAsMobileView" />
                    <span
                      v-if="
                        item.term.type == 'keyword' &&
                          item.contentBrief.target > 0
                      "
                      class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Using your exact keyword is critical to SEO success"
                      >Must Use
                    </span>
                    <span
                      v-if="isSecondaryKeyword(item.term.phrase)"
                      class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Use your secondary keyword"
                      >Secondary KW
                    </span>
                    <span
                      v-if="isNLPTerm(item.term.phrase)"
                      class="cb-tag nlp-term clickable tooltip is-tooltip-left"
                      data-tooltip="Entities from POP's Natural Language Processing list"
                      >NLP
                    </span>
                    <span
                      v-if="isGoogleNLPTerm(item.term.phrase)"
                      class="cb-tag google-nlp-term clickable tooltip is-tooltip-left"
                      data-tooltip="Entities from Google's Natural Language Processing list"
                      >Google NLP
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!isFromExtension && !showAsMobileView">
              <div class="terms-removed-by-you">
                Terms Removed by You
              </div>
              <div class="my-2">
                <div
                  class="removed-cb-term mt5 mr10"
                  v-for="(item, idx) in excludedTermsForP"
                  :key="idx"
                >
                  <div class="phrase">
                    {{ item.term.phrase }}
                  </div>
                  <button
                    class="recover-btn"
                    @click="() => removeOrRecoverTerm('recover', item, 'p')"
                  >
                    Recover
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            Unsupported View Type
          </div>
          <div>
            <a class="download-link" @click.stop="isDemo ? null : downloadAsTxt('p')"
              >Download as .txt</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showNoteModal }">
      <div class="modal-background" @click="hideNoteModalAction"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="d-flex">
            <p class="title-font is-size-5" style="flex-grow: 1;">
              Notes (
              {{ selectedSecitonDisplayName }}
              )
            </p>
            <i
              class="fa fa-close"
              @click="hideNoteModalAction"
              style="cursor: pointer; font-size: 1.5rem;"
            ></i>
          </div>
          <div>
            <textarea
              class="textarea"
              v-model="noteText"
              placeholder="Write Note here.."
              :rows="2"
            >
            </textarea>
            <div v-if="currentNote" class="mt5">
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon text-none white--text"
                :disabled="noteText.trim() == ''"
                @click="updateNote"
              >
                Update Note
              </v-btn>
              <v-btn rounded
                color="grayColor"
                class="button-with-icon text-none white--text ml10"
                @click="cancelEditNoteMode">
                Cancel
              </v-btn>
            </div>
            <div v-else class="mt15">
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon text-none white--text"
                @click="addNote"
                :disabled="noteText.trim() == ''"
              >
                Add note
            </v-btn>
            </div>
          </div>
          <div
            v-if="currentContentBriefNotes.length > 0"
            class="mt5 box" style="max-height: 400px; overflow: auto;">
            <article
              class="message is-info"
              v-for="row in currentContentBriefNotes"
              :key="row.id"
            >
              <div class="message-header">
                <p>
                  {{ row.createdAt }}
                  <span v-if="row.isEdited" class="is-italic is-size-7">
                    ( edited: {{ row.editedAt }} )</span
                  >
                </p>
                <div>
                  <button
                    class="button is-small"
                    aria-label="delete"
                    @click="setEditNoteMode(row)"
                    v-if="!row.isAutoNote"
                  >
                    Edit
                  </button>
                  <button
                    v-if="!hideActionButtons"
                    class="button is-small is-dark"
                    aria-label="delete"
                    @click="deleteNote(row.id)"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div class="message-body">
                <p v-html="row.note.replace(/\n/g, '<br/>')"></p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      :class="{ 'is-active': showRemoveRecoverTermConfirmModal }"
    >
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="title-font is-size-4 mb20">Confirm</div>
            <div class="subtitle">
              {{
                selectedCBData && selectedCBData.action == "remove"
                  ? "Do you really want to remove the term?"
                  : "Do you really want to recover the term?"
              }}
            </div>
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="donotShowAgainWarningForRemoveRecoverCBTerm"
              />
              Please do not show this warning again.
            </label>
          </div>
          <div class="has-background-light has-text-right mt-5">
            <button
              class="button ready-button"
              @click="performRemoveRecoverAction"
              :disabled="!selectedCBData"
            >
              {{
                selectedCBData && selectedCBData.action == "remove"
                  ? "Remove it"
                  : "Recover it"
              }}
            </button>
            <button
              class="button is-light"
              @click="() => (showRemoveRecoverTermConfirmModal = false)"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <page-setting-modal ref="pageSettingModal" :disableDeletePage="true" />

    <div v-if="selectedCBItems.length > 0" class="action-bar">
      <div class="left-area">
        {{ selectedCBItems.length }}
      </div>
      <div class="middle-area">
        <div class="item-selected-text">
          {{ selectedCBItems.length > 1 ? 'items': 'item' }} selected
        </div>
        <div
          class="remove-btn"
          @click="removeTerms">
          <span class="material-icons-outlined">delete_sweep</span>
          <span>Remove</span>
        </div>
        
      </div>
      <div class="right-area">
        <span class="material-icons close-button"
          @click="() =>{
            selectedCBItems = []
          }">
          close
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mixin as cb_mixin } from "./cb_mixin";
import { StopLightBGColorOptions } from '@/components/PageRun/ContentBrief/content_brief_constants.js'
import RadialProgressBar from "vue-radial-progress";
import PageSettingModal from "@/components/PageSettingModal.vue";
import { TARGET_MIN_PERCENTAGE } from "@/components/PageRun/constants";

let stateColors = [
  "#FE525C",
  "#FD777F",
  "#FCA2A8",
  "#96E8B3",
  "#64DF90",
  "#96E8B3",
  "#FCD4A8",
  "#FDC17F",
  "#FEB15C"
];
let borderColors = [
  "#FF1F2D",
  "#FF1F2D",
  "#FF1F2D",
  "#00CD49",
  "#00CD49",
  "#00CD49",
  "#FF9B2D",
  "#FF9B2D",
  "#FF9B2D"
];

let stoplightColor = [
  "#FF1F2D",
  "#FF6266",
  "#FF8F91",
  "#22DC7C",
  "#00CD49",
  "#22DC7C",
  "#FF9B2D",
  "#FFB95A",
  "#FF9B2D"
];

let TYPE_ORDER = { keyword: 0, variation: 1, lsi: 2 };

export default {
  props: [
    "selectedMainTab",
    "createdAt",
    "pageRun",
    "url",
    "keyword",
    "secondaryKeywords",
    "lsaPhrases",
    "pageRunId",
    "pageNotBuilt",
    "page",
    "ignoreSpaces",
    "willUseSecretKey",
    "secretKey",
    "contentBrief",
    "runStrategies",
    "tagTotalData",
    "wordCountData",
    "sectionCount",
    "gCompetitors",
    "relatedSearches",
    "relatedQuestions",
    "contentBriefFile",
    "isFromExtension",
    "showAsMobileView",
    "hideActionButtons",
    "excludedTermsByUser",
  ], // showAsMobileView : this will be true if this component will display inside extension modal or source editor modal
  mixins: [cb_mixin],
  components: {
    RadialProgressBar,
    PageSettingModal
  },
  data() {
    return {
      expandedQuestion: "",
      sortMethod: "importance",
      showMorePageStructure: false,
      showMoreGCompetitors: false,
      showMoreRelatedSearches: false,
      showMoreRelatedQuestions: false,

      showSearchTitleExplainText: false,
      showPageTitleExplainText: false,
      showSubtitlesExplainText: false,
      showMainContentExplainText: false,

      termDispType: "table", // table/list
      isCollapseSummaryArea: false,
      isCollapseSearchEngineTitleArea: false,
      isCollapsePageTitleArea: false,
      isCollapseSubHeadingsArea: false,
      isCollapseMainContentArea: false,

      showNoteModal: false,
      selectedSection: "title", // title/subHeadings/p
      noteText: "",
      notes: [],
      currentNote: null, // in create mode this will be null, in edit mode this will be note

      TARGET_MIN_PERCENTAGE: TARGET_MIN_PERCENTAGE,

      showRemoveRecoverTermConfirmModal: false,
      donotShowAgainWarningForRemoveRecoverCBTerm: false,
      selectedCBData: null,
      selectedCBItems: [],

      showCustomWordCountUI: false,
      customWordCountInputed: 0,
    };
  },
  computed: {
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    user() {
      return this.$store.state.user;
    },

    nlpTerms() {
      let terms = this.lsaPhrases.reduce((rlt, item) => {
        if (item.isNLPTerm) {
          rlt.push(item.phrase);
        }
        return rlt;
      }, []);
      return terms;
    },
    googleNlpTerms() {
      let terms = this.lsaPhrases.reduce((rlt, item) => {
        if (item.isGoogleNLPTerm) {
          rlt.push(item.phrase);
        }
        return rlt;
      }, []);
      return terms;
    },
    contentBriefStatus() {
      let status = {};
      if (this.page && this.page.contentBriefStatus) {
        status = this.page.contentBriefStatus;
      }
      if (!status["title"]) status["title"] = "InCompleted";
      if (!status["pageTitle"]) status["pageTitle"] = "InCompleted";
      if (!status["subHeadings"]) status["subHeadings"] = "InCompleted";
      if (!status["p"]) status["p"] = "InCompleted";
      return status;
    },
    currentContentBriefNotes() {
      return this.notes.filter(item => item.type == this.selectedSection);
    },
    cleanedContentBrief() {
      if (this.contentBrief) {
        return this.contentBrief;
      }
      let found = this.runStrategies.find(
        item => item.dashboardType == "contentBrief"
      );

      if (found) {
        return found;
      }

      return {};
    },
    hasContentBriefData() {
      return (
        !!this.cleanedContentBrief &&
        Object.keys(this.cleanedContentBrief).length > 0
      );
    },
    hasPageTitleData() {
      return !!this.cleanedContentBrief["pageTitle"];
    },

    titleData() {
      return this.sortContentBriefData("title", this.cleanedContentBrief["title"]);
    },
    pageTitleData() {
      return this.sortContentBriefData(
        "pageTitle",
        this.cleanedContentBrief["pageTitle"] || []
      );
    },
    subHeadingsData() {
      return this.sortContentBriefData("subHeadings", this.cleanedContentBrief["subHeadings"]);
    },
    /**
     * Function Name: bodyContentData
     * if user is in Pro tab, then limit main content size to 30
     * else, display all the elements.
     */
    bodyContentData() {
      if (
        this.cleanedContentBrief["p"].length > 50 &&
        this.selectedMainTab == "standard"
      ) {
        return this.sortContentBriefData(
          "p",
          this.cleanedContentBrief["p"].slice(0, 50)
        );
      } else {
        return this.sortContentBriefData("p", this.cleanedContentBrief["p"]);
      }
    },
    titleTotal() {
      return this.cleanedContentBrief["titleTotal"];
    },
    pageTitleTotal() {
      return (
        this.cleanedContentBrief["pageTitleTotal"] || {
          current: 0,
          min: 0,
          max: 0
        }
      );
    },
    subTitleTotal() {
      return this.cleanedContentBrief["subHeadingsTotal"];
    },
    contentTotal() {
      return this.cleanedContentBrief["pTotal"];
    },
    excludedTermsForTitle() {
      return this.excludedTermsByUser.title || [];
    },
    excludedTermsForPageTitle() {
      return this.excludedTermsByUser.pageTitle || [];
    },
    excludedTermsForSubHeadings() {
      return this.excludedTermsByUser.subHeadings || [];
    },
    excludedTermsForP() {
      return this.excludedTermsByUser.p || [];
    },
    score() {
      return this.cleanedContentBrief["pageScore"]["pageScore"];
    },
    subHeadingsTarget() {
      return this.sectionCount;
    },

    uniqueUrlForEditor() {
      if (!this.secretKey) return "";
      let routeData = this.$router.resolve({
        name: "FullPageSourceEditor",
        query: { secretKey: this.secretKey }
      });
      return window.location.origin + "/" + routeData.href;
    },
    selectedSecitonDisplayName() {
      switch (this.selectedSection) {
        case "title":
          return "Search Engine Title";
        case "pageTitle":
          return "Page Title";
        case "subHeadings":
          return "SubHeadings";
        case "p":
          return "Main Content";
        default:
          return "";
      }
    },
    scoreStatusMsg () {
      return this.scoreToText(this.score)
    }
  },
  // watch: {

  // },
  methods: {
    enterPageSetting(pageRun) {
      // console.log(this.page)
      // console.log(this.$refs.pageSettingModal)
      this.$refs.pageSettingModal.show(this.page, null, '', pageRun);
    },
    isSecondaryKeyword(term) {
      let secondaryKeywords = this.secondaryKeywords || [];
      return secondaryKeywords.includes(term);
    },
    isNLPTerm(term) {
      return (this.nlpTerms || []).includes(term);
    },
    isGoogleNLPTerm(term) {
      return (this.googleNlpTerms || []).includes(term);
    },
    performRemoveRecoverAction() {
      if (!this.selectedCBData) {
        console.log("no any item selected");
        return;
      }
      this.showRemoveRecoverTermConfirmModal = false;
      console.log("Selected data", this.selectedCBData);
      switch (this.selectedCBData.action) {
        case "remove":
          this.removeTerm();
          break;
        case "recover":
          this.recoverTerm();
          break;
        default:
          break;
      }
    },
    removeOrRecoverTerm(action, item, sectionName) {
      let doNotNeedDisplayConfirmModal;

      this.selectedCBData = {
        action,
        item,
        sectionName
      };
      switch (action) {
        case "remove":
          doNotNeedDisplayConfirmModal =
            this.user.settings.dontDisplayCBRemoveConfirmModal || false;
          if (doNotNeedDisplayConfirmModal) {
            setTimeout(() => {
              this.performRemoveRecoverAction();
            }, 100);
          } else {
            this.donotShowAgainWarningForRemoveRecoverCBTerm = false;
            this.showRemoveRecoverTermConfirmModal = true;
          }
          break;
        case "recover":
          doNotNeedDisplayConfirmModal =
            this.user.settings.dontDisplayCBRecoverConfirmModal || false;
          if (doNotNeedDisplayConfirmModal) {
            setTimeout(() => {
              this.performRemoveRecoverAction();
            }, 100);
          } else {
            this.donotShowAgainWarningForRemoveRecoverCBTerm = false;
            this.showRemoveRecoverTermConfirmModal = true;
          }
          break;
        default:
          break;
      }
    },
    removeTerm() {
      let { item, sectionName } = this.selectedCBData;
      let data = {
        siteId: this.page.siteId,
        pageId: this.page.id,
        pageRunId: this.pageRunId,
        sectionName,
        termData: item
      };
      if (this.donotShowAgainWarningForRemoveRecoverCBTerm) {
        data["doNotDisplayWarningAgain"] = true;
      }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("removeTermFromCB", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$notify({
              group: 'info', type: 'success', text: 'Success!'
            });
            if (response.data.pageRun) {
              this.$store.commit("updatePageRun", response.data.pageRun);
            }
            if (response.data.user) {
              this.$store.commit("setUser", response.data.user);
            }
          } else {
            this.$notify({
              group: 'info', type: 'error', text: 'Failed to remove term.'
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    recoverTerm() {
      let { item, sectionName } = this.selectedCBData;
      let data = {
        siteId: this.page.siteId,
        pageId: this.page.id,
        pageRunId: this.pageRunId,
        sectionName,
        termData: item
      };

      if (this.donotShowAgainWarningForRemoveRecoverCBTerm) {
        data["doNotDisplayWarningAgain"] = true;
      }

      this.$store.commit("showLoading");
      this.$store
        .dispatch("recoverTermForCB", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$notify({
              group: 'info', type: 'success', text: 'Success!'
            });
            if (response.data.pageRun) {
              this.$store.commit("updatePageRun", response.data.pageRun);
            }
            if (response.data.user) {
              this.$store.commit("setUser", response.data.user);
            }
          } else {
            this.$notify({
              group: 'info', type: 'error', text: 'Failed to recover term.'
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    removeTerms() {
      if (this.selectedCBItems.length == 0) {
        this.$notify({
          group: 'info', type: 'warning',
          text: 'Please select terms you want to remove.'
        })
        return;
      }
      let data = {
        siteId: this.page.siteId,
        pageId: this.page.id,
        pageRunId: this.pageRunId,
        selectedTerms: this.selectedCBItems,
      };
      if (this.donotShowAgainWarningForRemoveRecoverCBTerm) {
        data["doNotDisplayWarningAgain"] = true;
      }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("removeTermsFromCB", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.selectedCBItems = []
            this.$notify({
              group: 'info', type: 'success',
              title: 'Alert', text: 'Success!'
            });
            if (response.data.pageRun) {
              this.$store.commit("updatePageRun", response.data.pageRun);
            }
            if (response.data.user) {
              this.$store.commit("setUser", response.data.user);
            }
          } else {
            this.$notify({
              group: 'info', type: 'error',
              title: 'Alert', text: 'Failed to remove term.'
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    toggleContentBriefScoringMethodAction (scoringMethod) {
      let data = {
        pageRunId: this.pageRunId,
        scoringMethod: scoringMethod ? 1 : 0,
      }
      this.$store.commit('showLoading');
      this.$store.dispatch('toggleScoringMethodOfCB', data)
      .then(response => {
        this.$store.commit('hideLoading');
        if (response.data && response.data.status == "SUCCESS") {
          if (response.data.pageRun) {
            this.$store.commit("updatePageRun", response.data.pageRun);
          }
          this.$emit('actionForUpdateParent') // This is for admin area
        } else {
          this.$notify({
              group: 'info', type: 'error',
              title: 'Alert', text: 'Failed to switch the scoring method.'
            });
        }
      })
      .catch(() => {
        this.$store.commit('hideLoading');
      })
    },
    saveCustomWordCountAction (useCustomWordCount) {
      let data = {
        pageRunId: this.pageRunId,
        useCustomWordCount: useCustomWordCount,
        customWordCount: this.customWordCountInputed,
      }
      this.$store.commit('showLoading');
      return this.$store.dispatch('setContentBriefCustomWordCount', data)
          .then(response => {
            this.$store.commit('hideLoading');
            if (response.data && response.data.status == "SUCCESS") {
              if (response.data.pageRun) {
                this.$store.commit("updatePageRun", response.data.pageRun);
              }
              this.$emit('actionForUpdateParent') // This is for admin area
            } else {
              this.$notify({
                  group: 'info', type: 'error',
                  title: 'Alert', text: 'Failed to save custom word count for the content brief'
                });
            }
          })
          .catch(() => {
            this.$store.commit('hideLoading');
          })
    },
    getDateAsLocalString(dateStr) {
      return new Date(dateStr.replace(" ", "T") + "Z").toLocaleString();
    },
    getSubFolderPartFromUrl(url) {
      if (this.pageNotBuilt) {
        return "N / A";
      }
      if (!url || url.length <= 8) {
        return "";
      }
      let idx = url.indexOf("/", 8);
      let rlt = url.substr(idx).trim();

      if (idx == -1 || rlt == "/" || rlt == "") {
        return "Homepage";
      }
      return rlt;
    },
    openUrlInNewTab(url) {
      window.open(url, "_blank");
    },
    updateContentBriefSectionStatus(sectionName, status) {
      // this.contentBriefStatus[sectionName])
      if (this.contentBriefStatus[sectionName] == status) {
        return;
      }
      let data = {
        willUseSecretKey: this.willUseSecretKey,
        secretKey: this.secretKey,
        pageId: this.page.id,
        sectionName,
        status
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("updateContentBriefStatus", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            if (response.data.page) {
              if (this.isFromExtension) {
                this.$emit("updateCurrentPage", response.data.page);
              } else {
                this.$store.commit("setPage", response.data.page);
              }
            }
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    displayNoteModalFor(sectionName) {
      this.selectedSection = sectionName;
      this.showNoteModal = true;
    },
    hideNoteModalAction() {
      this.showNoteModal = false;
      this.currentNote = null;
    },
    cancelEditNoteMode() {
      this.currentNote = null;
    },
    addNote() {
      let data = {
        willUseSecretKey: this.willUseSecretKey,
        secretKey: this.secretKey,
        pageRunId: this.pageRunId,
        type: this.selectedSection,
        note: this.noteText
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("createContentBriefNote", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            let note = response.data.note;
            this.notes = [note, ...this.notes];
            this.noteText = "";
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    setEditNoteMode(note) {
      this.currentNote = note;
      this.noteText = note.note;
    },
    updateNote() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("updateContentBriefNote", {
          willUseSecretKey: this.willUseSecretKey,
          secretKey: this.secretKey,
          noteId: this.currentNote.id,
          note: this.noteText
        })
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            let updatedNote = response.data.note;
            this.notes = this.notes.map(item => {
              if (item.id == updatedNote.id) {
                return updatedNote;
              }
              return item;
            });
            this.noteText = "";
            this.currentNote = null;
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    deleteNote(noteId) {
      if (!window.confirm("Do you want to delte this note ?")) {
        return;
      }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("deleteContentBriefNote", noteId)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            let updatedNote = response.data.note;
            this.notes = this.notes.filter(item => {
              if (item.id == noteId) {
                return false;
              }
              return true;
            });
            this.noteText = "";
            this.currentNote = null;
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },

    sortContentBriefData(contentType, contentBriefData) {
      let data = contentBriefData;
      const colors = Object.keys(StopLightBGColorOptions).map(key=>StopLightBGColorOptions[key]).sort((a,b)=>(a.order - b.order)).map(c=>c.color)

      data.sort((a, b) => {
        if (this.sortMethod == "status") {
          const acolorIndex = colors.indexOf(this.getStopLightColorForItem(contentType, a))
          const bcolorIndex = colors.indexOf(this.getStopLightColorForItem(contentType, b))
          return acolorIndex - bcolorIndex
        }
        if (this.sortMethod == "alphabetic") {
          if (a.term.phrase < b.term.phrase) return -1;
          else if (a.term.phrase > b.term.phrase) return 1;
          return 0;
        } else {
          if (a.term.type == b.term.type) {
            if (this.sortMethod == "importance") {
              if (a.term.weight < b.term.weight) return 1;
              else if (a.term.weight > b.term.weight) return -1;
              return 0;
            } else if (this.sortMethod == "deficit") {
              if (a.term.weight < b.term.weight) return -1;
              else if (a.term.weight > b.term.weight) return 1;
              return 0;
            }
          } else {
            if (this.sortMethod == "importance") {
              if (TYPE_ORDER[`${a.term.type}`] < TYPE_ORDER[`${b.term.type}`])
                return -1;
              if (TYPE_ORDER[`${a.term.type}`] > TYPE_ORDER[`${b.term.type}`])
                return 1;
              return 0;
            } else if (this.sortMethod == "deficit") {
              if (TYPE_ORDER[`${a.term.type}`] < TYPE_ORDER[`${b.term.type}`])
                return 1;
              if (TYPE_ORDER[`${a.term.type}`] > TYPE_ORDER[`${b.term.type}`])
                return -1;
              return 0;
            }
          }
        }
      });
      return data;
    },
    downloadAsTxt(contentBriefType) {
      let fileName = "";
      let data = "";
      switch (contentBriefType) {
        case "title":
          this.titleData.map(item => {
            data += `${item.term.phrase} ( ${item.contentBrief.current} / ${
              item.term.type == "keyword"
                ? item.contentBrief.target
                : item.contentBrief.targetMin +
                  " - " +
                  item.contentBrief.targetMax
            } )\r\n`;
          });
          fileName = `ContentBrief_${contentBriefType}.txt`;
          break;
        case "pageTitle":
          this.pageTitleData.map(item => {
            data += `${item.term.phrase} ( ${item.contentBrief.current} / ${
              item.term.type == "keyword"
                ? item.contentBrief.target
                : item.contentBrief.targetMin +
                  " - " +
                  item.contentBrief.targetMax
            } )\r\n`;
          });
          fileName = `ContentBrief_${contentBriefType}.txt`;
          break;
        case "subHeadings":
          fileName = `ContentBrief_${contentBriefType}.txt`;
          this.subHeadingsData.map(item => {
            data += `${item.term.phrase} ( ${item.contentBrief.current} / ${
              item.term.type == "keyword"
                ? item.contentBrief.target
                : item.contentBrief.targetMin +
                  " - " +
                  item.contentBrief.targetMax
            } )\r\n`;
          });
          break;
        case "p":
          fileName = `ContentBrief_BodyContent.txt`;
          this.bodyContentData.map(item => {
            data += `${item.term.phrase} ( ${item.contentBrief.current} / ${
              item.term.type == "keyword"
                ? item.contentBrief.target
                : item.contentBrief.targetMin +
                  " - " +
                  item.contentBrief.targetMax
            } )\r\n`;
          });
          break;
      }
      if (data) {
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(data)
        );
        element.setAttribute("download", fileName);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    },

    pageScoreTintColor(score) {
      let value = score ? score : 0;
      if (value >= 0 && value <= 69) {
        return "#FF0000";
      } else if (value > 69 && value <= 74) {
        return "#FF9C00";
      } else if (value > 74 && value <= 80) {
        return "#FFC000";
      } else if (value > 80 && value <= 83) {
        return "#8CCFB5";
      } else if (value > 83 && value <= 85) {
        return "#78C8A5";
      } else if (value > 85 && value <= 88) {
        return "#5EC198";
      } else if (value > 88 && value <= 90) {
        return "#00B482";
      } else if (value > 90 && value <= 93) {
        return "#00925D";
      } else if (value > 93 && value <= 95) {
        return "#008452";
      } else if (value > 95) {
        return "#006A42";
      }
      return "#000000";
    },
    getBlockBGAndBorder(type, item) {
      return ["transparent", borderColors[8]];

      // let { current, target, targetMin, targetMax } = item.contentBrief;

      // let targetValue = 0;
      // if (type == "keyword") {
      //   targetValue = target;
      // } else {
      //   if (targetMin > 0) targetValue = targetMin;
      //   else targetValue = targetMax / 2;
      // }

      // if (targetValue == 0) return stateColors[4];
      // let value = (current * 100.0) / targetValue;

      // if (value >= 0 && value <= 20) {
      //   return [stateColors[0], borderColors[0]];
      // } else if (value > 20 && value <= 40) {
      //   return [stateColors[1], borderColors[1]];
      // } else if (value > 40 && value <= 60) {
      //   return [stateColors[2], borderColors[2]];
      // } else if (value > 60 && value <= 80) {
      //   return [stateColors[3], borderColors[3]];
      // } else if (value > 80 && value <= 100) {
      //   return [stateColors[4], borderColors[4]];
      // } else if (value > 100 && value <= 120) {
      //   return [stateColors[5], borderColors[5]];
      // } else if (value > 120 && value <= 140) {
      //   return [stateColors[6], borderColors[6]];
      // } else if (value > 140 && value <= 160) {
      //   return [stateColors[7], borderColors[7]];
      // } else if (value > 160) {
      //   return [stateColors[8], borderColors[8]];
      // }
    },
    occurrencesInString(str, findingWord) {
      if (this.ignoreSpaces) {
        return (str.match(new RegExp(findingWord, "gi")) || []).length;
      } else {
        return (str.match(new RegExp(`\\b${findingWord}\\b`, "gi")) || [])
          .length;
      }
    },
    needsToDisplayUseAlone(sectionName, item) {
      let data = [];
      switch (sectionName) {
        case "title":
          data = this.pageTitleData;
          break;
        case "subHeadings":
          data = this.subHeadingsData;
          break;
        case "p":
          data = this.bodyContentData;
          break;
        default:
          break;
      }
      let rlt = false;
      data.map(obj => {
        if (
          obj.term.phrase != item.term.phrase &&
          this.occurrencesInString(obj.term.phrase, item.term.phrase)
        ) {
          rlt = true;
        }
      });
      return rlt;
    },
    getTooltipForColor(color) {
      switch (color) {
        case StopLightBGColorOptions.red.color:
          return 'You are below the target range for this term.  We recommend increasing the use of this term to get into the target range. '
        case StopLightBGColorOptions.yellow.color:
          return 'You are below the target range for this term.  We recommend increasing the use of this term to get into the target range. '
        case StopLightBGColorOptions.green.color:
          return 'You are in the target range for this term.  We recommend reaching at least the mid-point of all target ranges for best results.'
        case StopLightBGColorOptions.purple.color:
          return 'While you are over the target range for this term, we do not recommend reducing this term until you have increased the use of other terms first'
        default:
          return ''
      }
    },
    getStopLightColorForSection(contentBriefType) {
      let current = 0,
        targetMin = 0,
        targetMax = 0;
      switch (contentBriefType) {
        case "title":
          current = this.titleTotal["current"];
          targetMin = this.titleTotal["min"];
          targetMax = this.titleTotal["max"];
          break;
        case "pageTitle":
          current = this.pageTitleTotal["current"];
          targetMin = this.pageTitleTotal["min"];
          targetMax = this.pageTitleTotal["max"];
          break;
        case "subHeadings":
          current = this.subTitleTotal["current"];
          targetMin = this.subTitleTotal["min"];
          targetMax = this.subTitleTotal["max"];
          break;
        case "p":
          current = this.contentTotal["current"];
          targetMin = this.contentTotal["min"];
          targetMax = this.contentTotal["max"];
          break;
        default:
          break;
      }
      return this.getStopLightBGColor(
        contentBriefType,
        current,
        targetMin,
        targetMax,
        true,
        this.pageRun?.contentBriefScoringMethod
      );
    },
    showNoSecondaryKeywordsInSection (contentBriefType) {
      if (this.secondaryKeywords && this.secondaryKeywords.length == 0) {
        return false;
      }

      let data = [];
      switch (contentBriefType) {
        case "title":
          data = this.titleData;
          break;
        case "pageTitle":
          data = this.pageTitleData;
          break;
        case "subHeadings":
          data = this.subHeadingsData;
          break;
        case "p":
          data = this.bodyContentData;
          break;
        default:
          break;
      }
      let terms = data.map(item => item.term.phrase);
      for (const keyword of this.secondaryKeywords) {
        if (terms.includes(keyword)) {
          return false;
        }
      }
      return true;
    },
    loadNotes() {
      this.$store
        .dispatch("getContentBriefNotes", {
          pageRunId: this.pageRunId,
          willUseSecretKey: this.willUseSecretKey,
          secretKey: this.secretKey
        })
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.notes = response.data.notes;
          }
        });
    },
    downloadContentBrief(downloadType) {
      switch (downloadType) {
        case "excel":
        case "pdf":
          this.$store
            .dispatch("downloadContentBrief", { downloadType, pageRunId: this.pageRunId })
            .then(response => {
              if (response.data && response.data.status == "SUCCESS") {
                const { contentBriefDataFile } = response.data;
                const fileName = contentBriefDataFile[downloadType]
                const downloadUrl = `${API_URL}/download/?file=${
                  fileName
                }`;
                this.downloadFile(downloadUrl);
              } else {
                console.log("failed to make content brief");
              }
            })
            .catch(() => {
              console.log("error check server side");
            });
            break;
        default:
          break;
      }
    },
  },
  mounted() {
    if (!this.isFromExtension || this.willUseSecretKey) {
      this.loadNotes();
    }
    // console.log("page run for current content brief", this.pageRun)
    if (this.pageRun) {
      this.showCustomWordCountUI = this.pageRun.contentBriefUseCustomWordCount == 1 ? true : false;
      if (this.showCustomWordCountUI) {
        this.customWordCountInputed = this.pageRun.contentBriefCustomWordCount
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./content-brief.scss";
</style>
