<template>
  <div class="modal" :class="{'is-active': showModal}">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="title-font is-size-4 mb20">Summary Setting</div>
          <div class="columns mt-4">
            <div class="column">
              <div class="is-size-6 has-text-grey">
                Agency Users can only pick own summary day.
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="card">
                <div class="card-content">
                  <div class="content">
                    Please pick your summary report day
                    <div class="select is-small is-fullwidth">
                      <select v-model="dayOfSummary">
                        <option v-for="(day, idx) in dates"
                          :key="idx"
                          :value="day">
                          {{ day }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="control flex mt20">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded
              :disabled="!canSaveSetting"
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click="saveSummaryReportSetting"
            >
              <i class="fa fa-floppy-o mr5"></i>
              <span>Save Changes</span>
          </v-btn>
            
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GuardSummaryModal',
  components: { },
  computed: {
    canSaveSetting () {
      if (this.dayOfSummary > 0 && this.dayOfSummary <= 28) {
        return true;
      }
      return false;
    },
    dates () {
      let d = []
      for (let i = 1; i <= 28; i++) {
        d.push(i)
      }
      return d
    },
    user () {
      return this.$store.state.user
    }
  },
  data () {
    return {
      showModal: false,
      dayOfSummary: 0,
    }
  },
  methods: {
    show () {
      this.dayOfSummary = this.user.summaryDaySetting > 0 ? this.user.summaryDaySetting : 0,
      this.showModal = true;
    },
    saveSummaryReportSetting () {
      this.$store.commit('showLoading');
      this.$store.dispatch('updateSummaryReportDay', {
        day: this.dayOfSummary,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$store.commit('setUser', response.data.user);
          this.$notify({ group: 'info', type: 'success', text: 'Successfully saved!' })
        } else {
          this.$notify({ group: 'info', type: 'error', text: 'Failed to save your summary setting.' })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    }
  }
}

</script>