<template>
  <div class="modal" :class="{'is-active': showNow}">
    <div class="modal-background" @click="closeEditor"></div>
    <div class="modal-content" style="width: 95vw;">
      <div class="signal-explain-ballon" v-if="signalExplainData[ballonSignal]">
        <div class="has-text-right"><span class="material-icons clickable" @click.stop="ballonSignal=''">highlight_off</span></div>
        <div v-html="signalExplainData[ballonSignal]"></div>
      </div>
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div class="columns">
            <div class="column is-7">
              <div class="d-flex">
                <div>
                  <img v-if="isExpressEditor" src="/static/POP_pro.png" width="150" />
                  <img v-else src="/static/POP@4x.png" width="100" />
                </div>
                <div class="flex-grow-1">
                  <div class="is-size-4 title-font has-text-centered mainColor--text">
                    Content Editor
                  </div>
                  <div class="is-size-6 is-italic pl20 pr20 mainColor--text">
                    - To pull in your source code to the editor automatically,  please use the POP Chrome Extension.
                    <a class="get-it-here" target="_blank" href="https://pageoptimizer.pro/pageoptimizer-pro-chrome-extension-guide/">Download</a>
                    <br/>
                    - Changes to content are only reflected here.  You are not updating the content on your site directly from this editor
                  </div>
                </div>
                </div>
            </div>
            <div class="column is-5">
              <div v-if="isExpressEditor">
                <div class="title-font is-size-4 has-text-centered mainColor--text">Instructions for use</div>
                <div class="mainColor--text">
                  <a class="is-italic has-text-weight-bold is-info" href="https://pageoptimizer.pro/pop-express-content-editor-guide/" target="_blank">Click here</a> 
                  to read a brief tutorial on best use of POP Express Content Editor
                </div>
              </div>
              <div v-show="isCalculating" class="calc-loader">
                <fulfilling-square-spinner
                  :animation-duration="4000"
                  :size="30"
                  color="#FF9B2D"
                />
              </div>
              <div>
                <div class="is-size-6 is-italic mainColor--text">
                  Please click button below to update checklist with edited source code.
                </div>
                <div class="text-center mt-3">
                  <v-btn rounded
                    :disabled="isCalculating" @click="calculateEditedSource"
                    color="profileAnchorColor"
                    class="button-with-icon text-none whiteColor--text">
                    Update Checklist
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-desktop" style="max-height:60vh; overflow-y: auto;">
            <div class="column is-7">
              <!-- <vue-mce v-model="originHtmlSource" :config="wysiwygConfig" /> -->
              <tinymce-editor v-model="originHtmlSource" :api-key="API_KEY" :init="wysiwygConfig"></tinymce-editor>
            </div>
            <div class="column is-5" style="overflow-y: auto;">
              <div v-if="dispResultType == 'signals'">
                <div class="gray2Color--text mb-2">* - Automatically added recommendations</div>
                <div v-if="signalType=='keyword'">
                  <span class="variation-tag">{{ keyword }}</span>
                </div>
                <div v-else-if="signalType=='variations'">
                  <span class="selection-tag blue-active-tag mr10" @click="showVariation=!showVariation">{{ showVariation ? 'Hide Variations' : 'Show Variations' }}</span>
                  <span class="selection-tag blue-active-tag mr10" @click="showWeights=!showWeights">{{ showWeights ? 'Hide Weights' : 'Show Weights' }}</span>
                  <div v-if="showVariation">
                    <span v-for="(item, idx) in variations" :key="idx" class="variation-tag">
                      {{ item.phrase ? item.phrase : item }} <span v-if="showWeights && item.phrase">&nbsp;( {{ item.weight.toFixed(2) }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                    </span>
                  </div>
                </div>
                <div v-else-if="signalType=='lsi_terms'">
                  <span class="has-text-weight-bold">LSI:</span>
                  <span class="selection-tag blue-active-tag mr10" @click="showLSI=!showLSI">
                    {{ showLSI ? 'Hide LSI' : 'Show LSI' }}
                  </span>
                  <span class="selection-tag blue-active-tag mr10" @click="showWeights=!showWeights">
                    {{ showWeights ? 'Hide Weights' : 'Show Weights' }}
                  </span>
                  <div v-if="showLSI">
                    <span v-for="(item, idx) in filteredLsaPhrases" :key="idx" class="variation-tag">
                      {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ item.weight.toFixed(2) }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                    </span>
                  </div>
                </div>
                <div class="accordion-wrapper">
                  <section class="accordions">
                    <article class="accordion" :class="{ 'is-active': signalType=='keyword' }">
                      <div class="accordion-header toggle flex" @click="changeSignalType('keyword')">
                        <p class="flex-grow-1 title-font pad5">1. Exact Keyword</p>
                        <i v-if="signalType=='keyword'" class="material-icons">keyboard_arrow_up</i>
                        <i v-else class="material-icons">keyboard_arrow_down</i>
                      </div>
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <div v-if="signalType=='keyword'">
                            <source-editor-checklist
                              :pageId="page.id"
                              @setBallonSignal="setBallonSignal"
                              :signalType="signalType"
                              :pageNotBuilt="pageNotBuilt"
                              :checklist="checklist"
                              :isExpressEditor="isExpressEditor"
                            />
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="accordion" :class="{ 'is-active': signalType=='variations' }">
                      <div class="accordion-header toggle flex" @click="changeSignalType('variations')">
                        <p class="flex-grow-1 title-font pad5">2. Variation Words</p>
                        <i v-if="signalType=='variations'" class="material-icons">keyboard_arrow_up</i>
                        <i v-else class="material-icons">keyboard_arrow_down</i>
                      </div>
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <div v-if="signalType=='variations'">
                            <source-editor-checklist
                              :pageId="page.id"
                              @setBallonSignal="setBallonSignal"
                              :signalType="signalType"
                              :pageNotBuilt="pageNotBuilt"
                              :checklist="checklist"
                              :isExpressEditor="isExpressEditor"
                            />
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="accordion" :class="{ 'is-active': signalType=='lsi_terms' }">
                      <div class="accordion-header toggle flex" @click="changeSignalType('lsi_terms')">
                        <p class="flex-grow-1 title-font pad5">3. LSI</p>
                        <i v-if="signalType=='lsi_terms'" class="material-icons">keyboard_arrow_up</i>
                        <i v-else class="material-icons">keyboard_arrow_down</i>
                      </div>
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <div v-if="signalType=='lsi_terms'">
                            <source-editor-checklist
                              :pageId="page.id"
                              @setBallonSignal="setBallonSignal"
                              :signalType="signalType"
                              :pageNotBuilt="pageNotBuilt"
                              :checklist="checklist"
                              :isExpressEditor="isExpressEditor"
                            />
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="accordion" :class="{ 'is-active': signalType=='pageStructure' }">
                      <div class="accordion-header toggle flex" @click="changeSignalType('pageStructure')">
                        <p class="flex-grow-1 title-font pad5">4. Page Structure</p>
                        <i v-if="signalType=='pageStructure'" class="material-icons">keyboard_arrow_up</i>
                        <i v-else class="material-icons">keyboard_arrow_down</i>
                      </div>
                      <div class="accordion-body">
                        <div class="accordion-content">
                          <source-editor-checklist
                            :pageId="page.id"
                            @setBallonSignal="setBallonSignal"
                            :signalType="signalType"
                            :pageNotBuilt="pageNotBuilt"
                            :checklist="checklist"
                            :isExpressEditor="isExpressEditor"
                          />
                        </div>
                      </div>
                    </article>
                  </section>
                </div>
              </div>
              <div v-else>
                <content-brief
                  :pageRun="pageRun"  
                  :createdAt="pageRun.createdAt"
                  :url="pageRun.url ? pageRun.url : page.url"
                  :keyword="keyword"
                  :secondaryKeywords="pageRun.secondaryKeywords"
                  :lsaPhrases="pageRun.lsaPhrases"
                  :pageRunId="pageRunId"
                  :pageNotBuilt="pageNotBuilt"
                  :page="page"
                  :ignoreSpaces="pageRun.ignoreSpaces"
                  :willUseSecretKey="false"
                  :secretKey="pageRun.secretKey"
                  :contentBrief="cleanedContentBrief"
                  :runStrategies="pageRun.runStrategies"
                  :tagTotalData="pageRun.tagCounts"
                  :wordCountData="[currentWordCount, contentBriefGoalWordCount, getWordCountRecommendation(currentWordCount, contentBriefGoalWordCount)]"
                  :sectionCount="pageRun.subHeadingsCount"
                  :gCompetitors="pageRun.gCompetitors"
                  :relatedSearches="pageRun.relatedSearches"
                  :relatedQuestions="pageRun.relatedQuestions"
                  :isFromExtension="false"
                  :showAsMobileView="true"
                  @updateCurrentPage="() => {}"
                  /> <!-- isFromExtension=true to make content brief responsive -->
              </div>
              <div class="mt-3">
                <v-btn rounded block
                  color="profileAnchorColor"
                  class="button-with-icon text-none whiteColor--text mb-1" @click.stop="rerunWithEditedSource">
                  Rerun with Edited Source
                  <i class="fa fa-arrow-right"></i>
                </v-btn>
                <v-btn rounded block
                  color="profileAnchorColor"
                  class="button-with-icon text-none whiteColor--text mb-1" @click.stop="downloadHtml">
                  Download Edited Code
                  <i class="fa fa-html5"></i>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="closeEditor"
      ><i class="material-icons">close</i></button>
    </div>
  </div>
</template>

<script>

import _ from 'lodash'
import { mixin } from '@/components/mixin'
import { mixin as ExportMixin } from '@/components/export_mixin'
import { mixin as ContentBriefMixin } from '@/components/content_brief_mixin'
import { FulfillingSquareSpinner } from 'epic-spinners'
import Editor from '@tinymce/tinymce-vue';
import SourceEditorChecklist from '@/components/PageRun/SourceEditorChecklist.vue'
import ContentBrief from '@/components/PageRun/ContentBrief/ContentBrief.vue'

export default {
  props: [
    'signalExplainData',
    'pageRunId', 'checklist', 'pageNotBuilt', 'keyword', 'variations', 'lsaPhrases', 'signalType', 'rerunLink',
    'updateSignalType',
  ],
  mixins: [mixin, ExportMixin, ContentBriefMixin],
  components: {
    FulfillingSquareSpinner,
    'tinymce-editor': Editor, // <- Important part
    SourceEditorChecklist,
    ContentBrief
  },
  computed: {
    currentWordCount () {
      if (this.editedCurrentWordCount > 0) return this.editedCurrentWordCount
      let pageRun = this.pageRun
      if (pageRun) {
        const wordCount = pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return wordCount.signalData.targetKeywordCnt
      }
      return 0
    },
    avgCompetitorWordCount () {
      let pageRun = this.pageRun
      if (pageRun) {
        const wordCount = pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return wordCount.signalData.competitorsAvg
      }
      return 0
    },
    contentBriefGoalWordCount () {
      return this.calcContentBriefGoalWordCount(this.currentWordCount, this.avgCompetitorWordCount)    
    },
    variationsStringArr: function() {
      if (this.variations.length > 0 && this.variations[0].phrase) {
        return this.variations.map(v => v.phrase)
      }
      return this.variations
    },
    lsiStringArr: function () {
      return this.lsaPhrases.map(item => item.phrase)
    },
    filteredLsaPhrases () {
      if (this.isExpressEditor) {
        return this.lsaPhrases.slice(0, 10)
      } else {
        return this.lsaPhrases
      }
    },
    dispResultType () { // signals/contentBrief
      let setting = this.pageStrategySetting
      if (setting && setting.dashboardType) {
        return setting.dashboardType
      }
      return 'signals'
    }
  },
  data() {
    return {
      API_KEY: 'lsma73xyw3emf5gmdaqeotkqmruiprcn5jpi8nwupyyvubvp',
      originHtmlSource: '',
      wysiwygConfig: {
        height: '57vh',
        inline: false,
        // theme: 'modern',
        fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 34px 38px 42px 48px 54px 60px",
        plugins: 'print preview fullpage powerpaste searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount tinymcespellchecker a11ychecker mediaembed linkchecker textpattern help',
        // removed premium plugins  linkchecker mediaembed a11ychecker advcode
        // plugins: 'print preview code fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern help',
        toolbar1: 'code fullscreen | formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
        image_advtab: true,
        // templates: [
        // { title: 'Test template 1', content: 'Test 1' },
        // { title: 'Test template 2', content: 'Test 2' }
        // ],
        content_css: [
          '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
          '//www.tinymce.com/css/codepen.min.css'
        ],
        menubar: true,
        branding: false,
      },
      showNow: false,
      isCalculating: false,
      
      showVariation: true,
      showLSI: true,
      showWeights: false,

      isExpressEditor: false,
      isNeededCalc: false,
      ballonSignal: '',

      cleanedContentBrief: [],
      editedCurrentWordCount: 0,
    };
  },
  watch: {
  },
  methods: {
    setBallonSignal (signal) {
      this.ballonSignal = signal
    },
    closeEditor () {
      if (this.signalType == 'pageStructure') {
        this.changeSignalType('keyword')
      }
      // this is needed to update checklist that updated from the editor modal checklist
      this.$store.commit('clearEditorArchivedList')
      if (this.$store.state.editorArchivedList.length > 0) {
        this.$parent.loadSites()
      }
      this.showNow = false
    },
    changeSignalType (type) {
      if (this.signalType == type) { return }
      this.$emit('updateSignalType', type)
    },
    calculateEditedSource () {
      this.$store.commit('setSeoSourceCode', this.originHtmlSource)
      let data = {
        pageRunId: this.pageRunId,
        html: this.originHtmlSource,
        keyword: this.keyword,
        variations: this.variationsStringArr,
        lsiPhrases: this.lsiStringArr,
        ignoreSpaces: this.pageRun.ignoreSpaces ? 1 : 0
      }
      switch(this.dispResultType) {
        case 'signals':
          this.isCalculating = true
          this.$store.dispatch('getHtmlCountData', data)
          .then(res => {
            console.log(res.data)
            if (res.data && res.data.status === 'SUCCESS') {
              let wordCounts = res.data.wordCounts
              let emCounts = res.data.emCounts
              let lsiEmCounts = res.data.lsiEmCounts
              let tagCounts = res.data.tagCounts
              // console.log('SEO EDITED DATA', wordCounts, emCounts)
              this.$store.commit('setEditedHtmlCountData', {
                wordCounts: wordCounts ? wordCounts : {},
                emCounts: emCounts ? emCounts : {},
                tagCounts: tagCounts ? tagCounts : {},
                lsiEmCounts: lsiEmCounts ? lsiEmCounts : {},
              })
            }
          })
          .catch(error => { })
          .finally(() => {
            this.isCalculating = false
          })
          break;
        case 'contentBrief':
          this.isCalculating = true
          this.$store.dispatch('getChecklistForExtension', data)
          .then(res => {
            console.log(res.data)
            if (res.data && res.data.status === 'SUCCESS') {
              this.cleanedContentBrief = res.data.result.cleanedContentBrief
              this.editedCurrentWordCount = res.data.result.editedCurrentWordCount
            }
          })
          .catch(error => { })
          .finally(() => {
            this.isCalculating = false
          })
          break;
        default:
          return;
      }
    },
    show (sourceCode, isExpressEditor=false) {
      this.isExpressEditor = isExpressEditor
      this.$store.commit('setEditorType', isExpressEditor)

      if (sourceCode === '') {
        this.originHtmlSource = '<html><body></body></html>'
      } else {
        this.originHtmlSource = sourceCode
      }
      this.showNow = true;
      this.calculateEditedSource()
    },
    rerunWithEditedSource () {
      this.$store.commit('setSeoSourceCode', this.originHtmlSource)
      setTimeout(() => {
        this.$router.push(this.rerunLink)
      }, 100);
    },
    downloadHtml () {
      const now = new Date()
      const fileName = "EditedCode_" + now.getUTCFullYear() + "_" + (now.getUTCMonth()+1) + "_"+ now.getUTCDate() + ".html"
      // console.log(typeof(this.originHtmlSource))
      this.exportToHtml(this.originHtmlSource, fileName)
    },
    getWordCountRecommendation(currentWordCount, goalWordCount) {
      return this.calcWordCountRecommendation(currentWordCount, goalWordCount)
    },
    clearData () {
      this.cleanedContentBrief = []
      this.editedCurrentWordCount = 0
    }
  },
  mounted () {
    this.clearData()
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  .accordion-content {
    background: white;
  }
  .accordion-wrapper {
    /* min-height: 60vh; */
    max-height: 60vh;
    overflow-y: auto;
    padding: 0;
  }
  .calc-loader {
    position: absolute;
    right: 40px;
    top: 40px;
  }
}

.signal-explain-ballon {
  position: absolute;
  box-sizing: border-box;
  z-index: 99;

  background: rgb(228, 228, 228);
  box-shadow: 0px 0px 8px black;
  border-radius: 5px;
  padding: 15px;

  left: 10%;
  width: 80%;
  max-height: 90%;
  top: 50%;
  transform: translateY(-50%);
  overflow-y: auto;
  
}
</style>