<template>
  <div class="px-2">
    <div class="d-flex align-center mt-3 mb-5">
      <div class="flex-grow-1 report-one-page-name gap10">
        <span>NextGen EEAT</span>
        <span
          class="material-icons video-tutorial-icon video-tutorial-icon-size"
          @click="
            () => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: 'How to Improve Your EEAT in 2024 - NextGen EEAT',
                videoLink:
                  'https://www.youtube.com/embed/zsSNLFw7eSU?si=yhFhStsJAn7x_j3m',
                description:
                  'In this video, the POP inventor Kyle Roof will tell you what NextGen EEAT is, how to set it up, and how to improve each of the EEAT signals: Expertise, Experience, Authority, and Trust.',
                time: '16m 21s',
              });
            }
          "
        >
          videocam
        </span>
      </div>
      <!-- <div class="how-to-use-this-dashboard" @click="openHowtoUseGuide">
      <img src="/static/cap.svg" />
      <span>How to use this dashboard?</span>
    </div> -->
    </div>
    <div class="dashboard-text mb-2">
      <div class="mb-2">
        <span>
          NextGen EEAT is an advanced EEAT tool that uses AI to review the
          quality of your content. In addition determining if you have EEAT
          signals present, NextGen uses an 80 point evaluation system based on
          the Search Quality Rater Guidelines to rate the quality of your
          content.
        </span>
      </div>
      <div class="mb-2">
        <span
          >This tool will help you determine if you have written what Google
          considers to be "good" content.</span
        >
      </div>
    </div>
    <div v-if="hasEatSignalData">
      <div class="eat-type-selector">
        <div
          :class="{ selected: selectedEatType == 'expertise' }"
          @click="
            () => {
              selectedEatType = 'expertise';
            }
          "
        >
          <div class="d-flex align-center justify-space-between">
            <span class="material-icons-outlined main-icon">school</span>
            <span
              class="material-icons video-tutorial-icon video-tutorial-icon-size"
              @click="
                () => {
                  $store.commit('setCurrentVideoTutorialToDisplay', {
                    title: 'How to Improve Expertise',
                    videoLink:
                      'https://www.youtube.com/embed/AKDsHD4FB8k?si=iInAD1loEkw9HXkY',
                    description: '',
                    time: '3m 28s',
                  });
                }
              "
            >
              videocam
            </span>
          </div>
          <div class="type-name">Expertise</div>
          <v-tooltip top color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                v-bind="attrs"
                v-on="on"
                rounded
                height="8"
                color="profileAnchorColor"
                :value="(expertiseYesSignalCount * 100) / 20"
              >
              </v-progress-linear>
            </template>
            <div class="white-bg-tooltip small-tooltip">
              {{ expertiseYesSignalCount }} / 20
            </div>
          </v-tooltip>
          <div class="signals-present">Signals present</div>
        </div>
        <div
          :class="{ selected: selectedEatType == 'experience' }"
          @click="
            () => {
              selectedEatType = 'experience';
            }
          "
        >
          <div class="d-flex align-center justify-space-between">
            <span class="material-icons-outlined main-icon">thumb_up</span>
            <span
              class="material-icons video-tutorial-icon video-tutorial-icon-size"
              @click="
                () => {
                  $store.commit('setCurrentVideoTutorialToDisplay', {
                    title: 'How to Improve Experience',
                    videoLink:
                      'https://www.youtube.com/embed/G6xnJs6ncgs?si=lYI4msDdOWcxPyLS',
                    description: '',
                    time: '3m 12s',
                  });
                }
              "
            >
              videocam
            </span>
          </div>
          <div class="type-name">Experience</div>
          <v-tooltip top color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                v-bind="attrs"
                v-on="on"
                rounded
                height="8"
                color="profileAnchorColor"
                :value="(experienceYesSignalCount * 100) / 20"
              >
              </v-progress-linear>
            </template>
            <div class="white-bg-tooltip small-tooltip">
              {{ experienceYesSignalCount }} / 20
            </div>
          </v-tooltip>

          <div class="signals-present">Signals present</div>
        </div>
        <div
          :class="{ selected: selectedEatType == 'authority' }"
          @click="
            () => {
              selectedEatType = 'authority';
            }
          "
        >
          <div class="d-flex align-center justify-space-between">
            <span class="material-icons-outlined main-icon">new_releases</span>
            <span
              class="material-icons video-tutorial-icon video-tutorial-icon-size"
              @click="
                () => {
                  $store.commit('setCurrentVideoTutorialToDisplay', {
                    title: 'How to Improve Authority',
                    videoLink:
                      'https://www.youtube.com/embed/LcSa8QR70yw?si=bWrjpeUy4bvLsxJ0',
                    description: '',
                    time: '3m 01s',
                  });
                }
              "
            >
              videocam
            </span>
          </div>
          <div class="type-name">Authority</div>
          <v-tooltip top color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                v-bind="attrs"
                v-on="on"
                rounded
                height="8"
                color="profileAnchorColor"
                :value="(authorityYesSignalCount * 100) / 20"
              >
              </v-progress-linear>
            </template>
            <div class="white-bg-tooltip small-tooltip">
              {{ authorityYesSignalCount }} / 20
            </div>
          </v-tooltip>

          <div class="signals-present">Signals present</div>
        </div>
        <div
          :class="{ selected: selectedEatType == 'trust' }"
          @click="
            () => {
              selectedEatType = 'trust';
            }
          "
        >
          <div class="d-flex align-center justify-space-between">
            <span class="material-icons-outlined main-icon">security</span>
            <span
              class="material-icons video-tutorial-icon video-tutorial-icon-size"
              @click="
                () => {
                  $store.commit('setCurrentVideoTutorialToDisplay', {
                    title: 'How to Improve Trust',
                    videoLink:
                      'https://www.youtube.com/embed/OjFhj05WfAQ?si=8s5PIRz28SYN6Evs',
                    description: '',
                    time: '3m 13s',
                  });
                }
              "
            >
              videocam
            </span>
          </div>
          <div class="type-name">Trust</div>
          <v-tooltip top color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                v-bind="attrs"
                v-on="on"
                rounded
                height="8"
                color="profileAnchorColor"
                :value="(trustYesSignalCount * 100) / 20"
              >
              </v-progress-linear>
            </template>
            <div class="white-bg-tooltip small-tooltip">
              {{ trustYesSignalCount }} / 20
            </div>
          </v-tooltip>

          <div class="signals-present">Signals present</div>
        </div>
      </div>
      <div>
        <div class="eat-data-wrapper mt-5">
          <div
            v-for="(item, idx) in signalsToRender.slice(
              0,
              displayCount[selectedEatType]
            )"
            :key="item.q"
            class="one-eat-row"
          >
            <div class="top-part">
              <span class="material-icons yes-icon" v-if="item.hasSignal"
                >check_circle</span
              >
              <span class="material-icons no-icon" v-else>cancel</span>
              <div class="question">
                {{ item.q }}
                <div
                  class="expanded-area"
                  v-if="expandedQuestions.includes(item.q)"
                >
                  {{ item.answer }}
                </div>
              </div>
              <div>
                <div class="expand">
                  <span
                    class="material-icons"
                    v-if="expandedQuestions.includes(item.q)"
                    @click="() => expandOrCollapseItem(item)"
                    >remove</span
                  >
                  <span
                    class="material-icons"
                    v-else
                    @click="() => expandOrCollapseItem(item)"
                    >add</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex mt-3 mb-5">
            <span
              v-if="signalsToRender.length > displayCount[selectedEatType]"
              class="show-more-btn"
              @click="
                () => {
                  displayCount[selectedEatType] += 10;
                }
              "
            >
              Show 10 more signals...
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="nlp-or-eeat-is-not-used-box">
      <div class="demo-area">
        <img src="/static/next-gen-eeat-demo.png" class="demo-img" />
      </div>
      <div class="mainColor--text" style="max-width: 650px">
        <div class="re-run-for-eeat">
          Re-run POP to get your EEAT report (powered by AI)
        </div>
        <div class="mt-5 mainColor--text">
          Please re-run POP and we will create your AI EEAT report. This will
          use 2 POP Credits. You can also purchase more POP Credits on any plan
          and use them to run various POP features such as EEAT.
        </div>
        <div class="mt-5 d-flex align-center gap10">
          <v-btn
            rounded
            small
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            v-if="!currentUser.isVendor"
            @click="rerunAnalysisForThisReport"
          >
            <i class="fa fa-refresh"></i>
            <span class="has-text-centered">Re-run</span>
          </v-btn>
          <v-btn
            rounded
            small
            outlined
            color="mainColor"
            class="button-with-icon mainColor--text text-none"
            :to="`/useraccount/${currentUser.id}?mainTab=billing-and-payments&subTab=buy-credits`"
          >
            <span class="">Buy more credits</span>
          </v-btn>
        </div>
        <!-- <a @click="gotoProfile" class="profileAnchorColor--text">Check out plans here</a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/components/mixin";

export default {
  props: ["nextGenEatSchemas"],
  mixins: [mixin],
  data() {
    return {
      selectedEatType: "expertise", // expertise, experience, authority, trust
      expandedQuestions: [],

      displayCount: {
        expertise: 10,
        experience: 10,
        authority: 10,
        trust: 10,
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    },
    ownerUser() {
      return this.$store.state.selectedSubAccountRelation || this.currentUser;
    },
    hasEatSignalData() {
      if (this.nextGenEatSchemas && this.nextGenEatSchemas.result) {
        return true;
      }
      return false;
    },
    expertiseYesSignalCount() {
      return this.getYesSignalCountForType("expertise");
    },
    experienceYesSignalCount() {
      return this.getYesSignalCountForType("experience");
    },
    authorityYesSignalCount() {
      return this.getYesSignalCountForType("authority");
    },
    trustYesSignalCount() {
      return this.getYesSignalCountForType("trust");
    },

    expertiseSignals() {
      return this.getEatDataForType("expertise");
    },
    experienceSignals() {
      return this.getEatDataForType("experience");
    },
    authoritySignals() {
      return this.getEatDataForType("authority");
    },
    trustSignals() {
      return this.getEatDataForType("trust");
    },
    signalsToRender() {
      return this.getEatDataForType(this.selectedEatType);
    },
  },
  methods: {
    getEatDataForType(type) {
      if (!this.nextGenEatSchemas) {
        return [];
      }
      return (this.nextGenEatSchemas?.result || []).filter((item) => {
        return item.type == type;
      });
      // .sort((a, b) => {
      //   if (a.hasSignal && !b.hasSignal) return 1;
      //   if (!a.hasSignal && b.hasSignal) return -1;
      //   return 0;
      // })
    },
    getYesSignalCountForType(type) {
      return this.nextGenEatSchemas?.result?.filter((item) => {
        return item.hasSignal && item.type == type;
      }).length;
    },
    expandOrCollapseItem(item) {
      if (this.expandedQuestions.includes(item.q)) {
        this.expandedQuestions = this.expandedQuestions.filter(
          (q) => q != item.q
        );
      } else {
        this.expandedQuestions.push(item.q);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.eat-type-selector {
  display: inline-flex;
  gap: 15px;
  & > div {
    padding: 20px;
    min-width: 220px;
    border-radius: 10px;
    border: 0.5px solid var(--v-grayColor-base);
    background-color: var(--v-whiteColor-base);
    cursor: pointer;
    .main-icon {
      font-size: 2rem;
      color: var(--v-profileAnchorColor-base);
    }
    .type-name {
      margin-top: 20px;
      margin-bottom: 12px;
      font-size: 1.3rem;
      line-height: 100%;
      font-weight: 700;
      color: var(--v-mainColor-base);
    }
    .signals-present {
      margin-top: 5px;
      color: var(--v-mainColor-base);
      font-size: 0.7rem;
      font-weight: 700;
    }

    &.selected {
      border: 2px solid var(--v-profileAnchorColor-base);
    }
  }
}

.eat-data-wrapper {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .one-eat-row {
    border-radius: 7.5px;
    border: 0.5px solid var(--v-grayColor-base);
    background-color: var(--v-whiteColor-base);
    padding: 10px 15px;
    .top-part {
      display: flex;
      // align-items: center;
      gap: 10px;
      .yes-icon {
        font-size: 1.6rem;
        color: var(--v-green7Color-base);
      }
      .no-icon {
        font-size: 1.6rem;
        color: var(--v-red3Color-base);
      }
      .question {
        flex-grow: 1;
        font-weight: 700;
        font-size: 1rem;
        color: var(--v-mainColor-base);
        .expanded-area {
          margin-top: 10px;
          font-size: 1rem;
          color: var(--v-mainColor-base);
          font-weight: 400;
        }
      }
      .expand {
        display: flex;
        align-items: center;
        border: 1.6px solid var(--v-profileAnchorColor-base);
        border-radius: 2px;
        span {
          font-size: 1.3rem;
          color: var(--v-profileAnchorColor-base);
        }
        cursor: pointer;
      }
    }
  }

  .show-more-btn {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    color: var(--v-profileAnchorColor-base);
  }
}
</style>
