<template>
<div class="mt-5">
  <table class="checklist-table">
    <thead>
      <tr>
        <th>Signal</th>
        <th>Current count</th>
        <!-- <th v-if="!pageNotBuilt">Current(From Report)</th> -->
        <th>Target count</th>
        <!-- <th v-if="isThisExtensionEmbedPage">Notes</th> -->
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, idx) in checklistForRender" :key="idx">
        <td>
          <!-- {{ (item.checklistId == 0 || item.isAutoChecklist) ? '* ' : '' }} -->
          {{ item.signal }}
          <span class="material-icons link15Color--text" @click.stop="showBallonForSignal(item.signal)">
            info
          </span>
        </td>
        <td>
          {{ item.editedCount }}
          <i class="checklist-item" :style="{ background: getStateColor(item.editedCount, item.target)}" aria-hidden="true"></i>
        </td>
        <!-- <td v-if="!pageNotBuilt">{{ item.current }}</!-->
        <td>{{ item.target }}</td>
        <!-- <td v-if="isThisExtensionEmbedPage">
          <a @click.stop="showNoteModalAction(item)" class="note-button">
            <span class="material-icons">note_add</span>
          </a>
        </td> -->
      </tr>
    </tbody>
  </table>





  <!-- <div class="modal" :class="{'is-active': showNoteModal}">
    <div class="modal-background" @click="showNoteModal=false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="d-flex">
          <p class="title" style="flex-grow: 1;">Notes ( {{ selectedChecklistItem ? selectedChecklistItem.signal : '' }} )</p>
          <i class="fa fa-close" @click="showNoteModal=false" style="cursor: pointer; font-size: 1.5rem;"></i>
        </div>
        <div>
          <textarea class="textarea" v-model="newNote" placeholder="Write Note here.." :rows="2">
          </textarea>
          <div v-if="isNoteEditMode" class="mt5">
            <button class="button is-primary" @click="updateChecklistNote(2, selectedChecklistNote.id)" :disabled="newNote.trim() == ''">Update Note</button>
            <button class="button is-light" @click="cancelEditNoteMode">Cancel</button> 
          </div>
          <div v-else class="mt5">
            <button class="button is-primary mt5" @click="updateChecklistNote(1)" :disabled="!selectedChecklistItem || newNote.trim() == ''">Add Note</button>
          </div>
        </div>
        <div class="mt5 box" style="max-height: 320px; overflow: auto;">
          <article class="message" :class="{'is-info': row.isAutoNote != 1, 'is-warning': row.isAutoNote == 1}" v-for="row in currentChecklistNotes" :key="row.id">
            <div class="message-header">
              <p>
                {{ row.createdAt }}<br/>
                <span v-if="row.isEdited" class="is-italic is-size-7"> ( edited: {{ row.editedAt }} )</span>
              </p>
              <div>
                <button class="button is-small" aria-label="delete" @click="setEditNoteMode(row)" v-if="!row.isAutoNote">Edit</button>
                <button class="button is-small is-dark" aria-label="delete" @click="updateChecklistNote(3, row.id)">Delete</button>
              </div>
            </div>
            <div class="message-body">
              <p v-html="row.note.replace(/\n/g, '<br/>')"></p>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div> -->
</div>
</template>

<script>

let stateColors = [
  '#FF1F2D', '#FF6266', '#FF8F91',
  '#22DC7C', '#00CD49', '#22DC7C',
  '#FF9B2D', '#FFB95A', '#FF9B2D'
]

export default {
  props: [
    'pageId',

    'signalType',
    'pageNotBuilt',
    'checklist',
    // 'isExpressEditor',
    'isThisExtensionEmbedPage',
    'willUseSecretKey',
    'secretKey',

    'setBallonSignal',
  ],
  computed: {
    checklistForRender: function () {
      return this.checklist
    },
    hasAutoChecklist: function () {
      return this.checklistForRender.filter(item => item.isAutoChecklist).length > 0
    },
    // currentChecklistNotes: function () {
    //   if (this.selectedChecklistItem && this.selectedChecklistItem.checklistId) {
    //     let found = this.checklist.find(item => item.checklistId == this.selectedChecklistItem.checklistId)
    //     if (found && found.notes) {
    //       return found.notes
    //     }
    //   }
    //   return []
      
    // }
  },
  data () {
    return {
      showNoteModal: false,
      selectedChecklistItem: null,


      newNote: '',
      isNoteEditMode: false,
      selectedChecklistNote: null,
    }
  },
  methods:  {
    // showNoteModalAction (item) {
    //   this.selectedChecklistItem = item
    //   this.showNoteModal = true      
    // },
    getStateColor(current, target) {
      if (target == 0) return stateColors[4]
      let value = current * 100.0 / target

      if (value >= 0 && value <=20) {
        return stateColors[0]
      } else if (value > 20 && value <= 40) {
        return stateColors[1]
      } else if (value > 40 && value <= 60) {
        return stateColors[2]
      } else if (value > 60 && value <= 80) {
        return stateColors[3]
      } else if (value > 80 && value <= 100) {
        return stateColors[4]
      } else if (value > 100 && value <= 120) {
        return stateColors[5]
      } else if (value > 120 && value <= 140) {
        return stateColors[6]
      } else if (value > 140 && value <= 160) {
        return stateColors[7]
      } else if (value > 160) {
        return stateColors[8]
      }
    },

    showBallonForSignal(signal) {
      console.log('displaying tooltip for signal' + signal)
      this.$emit('setBallonSignal', signal)
    },

    archiveChecklistItem(checklistItem) {

      let data = {
        id: checklistItem.checklistId,
        type: checklistItem.type,
        dispType: checklistItem.dispType,
        recommendationType: checklistItem.recommendationType,
        signal: checklistItem.signal,
        isAutoRecommendtaionArchive: checklistItem.checklistId==0 ? 1 : 0,
        pageId: this.pageId,
      }
      console.log(data)

      if (!window.confirm('Do you want to archive this signal')) { return }
      this.$store.commit('showLoading')
      return this.$store.dispatch('archiveOneFromCheckList', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            // deleted item to the list
            this.$store.commit('addEditorArchivedItem', { 'signal': checklistItem.signal, 'type': checklistItem.type })
            this.$store.commit('hideLoading')
          } else {
            this.$store.commit('hideLoading')
          }
        })
        .catch(() => {
          this.$store.commit('hideLoading')
        })
    },
    setEditNoteMode (item) {
      this.selectedChecklistNote = item
      this.newNote = this.selectedChecklistNote.note
      this.isNoteEditMode = true
    },
    cancelEditNoteMode () {
      this.newNote = ''
      this.selectedChecklistNote = null
      this.isNoteEditMode = false
    },
    updateChecklistItemState (item, status) {
      if (!window.confirm(`Are you sure you want to mark this task as ${ status==1 ? 'complete' : 'incomplete'}?`)) {
        return
      }
      let data = {
        pageId: this.pageId,
        willUseSecretKey: this.willUseSecretKey,
        secretKey: this.secretKey,
        checklistId: item.checklistId,
        isCompleted: status,
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('updateChecklistStateFromExtension', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          item.isCompleted = status;
          if (response.data.note) {
            item.notes.unshift(response.data.note)
          }     
        }
      })
      .catch(error => {

      })
      .finally(() => this.$store.commit('hideLoading'))

    },
    updateChecklistNote(mode=1, checklistNoteId=null) { // mode  1 create, 2: update, 3: delete
      let item = this.selectedChecklistItem
      let data = {
        pageId: this.pageId,
        willUseSecretKey: this.willUseSecretKey,
        secretKey: this.secretKey,
        checklistId: item.checklistId,
        note: this.newNote,
        mode: mode,
        checklistNoteId: checklistNoteId
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('updateChecklistNoteFromExtension', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          let note = response.data.note
          if (mode == 1 && note) {
            item.notes.unshift(note)
            this.newNote = ''
          }
          if (mode == 2 && note) {
            item.notes = item.notes.map(row => {
              if (row.id == checklistNoteId) {
                return note
              }
              return row
            })
            this.isNoteEditMode = false
            this.newNote = ''
          }
          if (mode == 3) {
            item.notes = item.notes.filter(row => row.id !=checklistNoteId)
          }
        }
      })
      .catch(error => {

      })
      .finally(() => this.$store.commit('hideLoading'))
    }
  }
}

</script>


<style lang="scss" scoped>

.checklist-table {
  width: 100%;
  span.material-icons {
    font-size: 0.95rem;
    cursor: pointer;
  }
  thead tr {
    th {
      background: var(--v-pink1Color-base);
      color: var(--v-mainColor-base);
      padding: 10px;
      font-weight: 700;
    }
    th:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    th:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  tbody {
    td {
      padding: 10px;
      color: var(--v-mainColor-base);
    }
    tr:nth-child(even) {
      td {
        background: var(--v-pink1Color-base);
      }
    }
  }
  
}

.check-mark {
  font-size: 0.8rem;
}

.checklist-item {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.empty-list-msg {
  color: #00cd3b;
}

.note-button {
  background: var(--v-lightYellowColor-base);
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  span {
    font-size: 1.7rem;
    color: var(--v-profileAnchorColor-base);
  }
}

.custom-small-tooltip:hover::before {
  min-width: 15rem;
}
</style>