<template>
<div class="three-rows-grid pt5">
    <div class="wow_disaled slideInLeft">
        <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="$emit('goprevpage')">keyboard_backspace</i>
    </div> 
    <div>
        <div class="full-width-container">
            <div class="level1-size title-font mb20 wow_disaled slideInLeft" data-wow-delay="0s">
                Check your competitor list. Add or remove as needed (you must select at least 3 competitors)
            </div>

            <div class="wow_disaled slideInLeft" data-wow-delay="0.3s">
                <textarea class="textarea competitor-input-textarea"
                    v-model="thisCompetitorUrls" @keyup="handleCompetitorUrlsChange" rows="6"
                    @input="(e) => adjustTextAreaHeight(e.target)"
                    placeholder="Competitors (Up to 10, one per line, include http/https)"></textarea>
            </div>

            <div class="my-5">
                <v-btn outlined rounded
                    color="mainColor"
                    class="button-with-icon text-none" @click="showGoogleResults">
                    Show Google results
                </v-btn>
                <v-btn outlined rounded
                    color="mainColor"
                    class="button-with-icon text-none ml-3" @click="showManualCopyPasteArea = !showManualCopyPasteArea">
                    {{ showManualCopyPasteArea ? 'Hide' : 'Show' }} Manual Copy & Paste
                </v-btn>
            </div>

            <div class="columns is-desktop" v-show="showManualCopyPasteArea && !validatedCompetitorUrls.length">
                <div class="column is-12">
                <section class="accordions">
                    <!-- <h2>Competitor Urls</h2> -->
                    <article
                    v-for="(url, idx) in competitorUrlsArray"
                    :key="idx"
                    class="accordion"
                    :class="{ 'is-active': url === activeManualCompetitorSource }"
                    >
                    <div class="accordion-header toggle" @click="$emit('togglecompetitorsourcearea', url)">
                        <div class="columns" style="width: 100%;">
                            <div class="column is-8 whiteColor--text word-wrap">
                                {{ url }}
                            </div>
                            <div class="column">
                                <!-- <input type="checkbox" class="checkbox manual_copy_source_checkbox" :id="`manualcopy_${idx}`" @click="(e) => { toggleUrlIsInManualCopySourceList(e, url) }" /> -->
                                <input type="checkbox" class="checkbox manual_copy_source_checkbox" :id="`manualcopy_checkbox_${url}`" @click="(e) => toggleurlinmanuallist(e, url)" />
                                <label :for="`manualcopy_checkbox_${url}`" class="whiteColor--text">Manually Copy & Paste Source Code</label>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-body">
                        <div class="accordion-content">
                            <div class="d-flex align-center">
                                <div class="flex-grow-1">
                                    <template v-if="manualCompetitorUrlsSources && manualCompetitorUrlsSources[activeManualCompetitorSource]">
                                        {{ manualCompetitorUrlsSources[activeManualCompetitorSource].slice(0, 300) }}
                                        {{ manualCompetitorUrlsSources[activeManualCompetitorSource].length > 300 ? '...' : '' }}
                                    </template>
                                    <template v-else>
                                        Input the html code
                                    </template>
                                </div>
                                <div>
                                    <v-btn rounded small color="profileAnchorColor"
                                        class="whiteColor--text text-none"
                                        @click="openAddManualHtmlPopup">
                                        <span>Input html</span>
                                    </v-btn>
                                </div>
                            </div>
                        <!-- <textarea class="textarea manual-copy-textarea" rows="5"
                            :id="`manualcopy_textarea_${url}`"
                            v-if="manualCopySourceCompetitorUrls.includes(url)"
                            :placeholder="
`1. Go to: view-source:https://www.mydomain.com/my-target-page
2. Copy everything that appears on that page
3. Paste what you copied into this box
`"
                            @input="(e) => {
                                $store.commit('showLoading')
                                $emit('changemanualcompetitorsource', { e:e, url: url, done: () => {
                                    $store.commit('hideLoading')
                                }})
                            }"
                        >
                        </textarea> -->
                        </div>
                    </div>
                    </article>
                </section>
                </div>
            </div>
            <div class="control mb5">
                <div v-if="competitorsUrlValidationError">
                    <div style="color: red;">{{ competitorsUrlValidationError }}</div>
                    <div v-for="(invalidUrl, idx) in invalidCompetitorUrls" :key="idx" style="color: red;">{{ invalidUrl }}</div>
                    <div class="has-text-link">URLs can't be crawled for several reasons.  The most common reason is that the site you are trying to crawl is blocking bots.  To use this competitor URL, you'll need to manually copy and paste the source code into the tool.</div>
                </div>
                <div v-if="competitorUrlsMissingHttpError">
                    <div style="color: red;">{{ competitorUrlsMissingHttpError }}</div>
                    <div v-for="(invalidUrl, idx) in missingHttpCompetitorUrls" :key="idx" style="color: red;">{{ invalidUrl }}</div>
                </div>
                <div v-if="missingPastingManualCompetitorUrlSources">
                    <div v-html="missingPastingManualCompetitorUrlSources" style="color: red;"></div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class='has-text-centered font-weight-bold profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</div>
        <div class="is-bottom-right">
            <v-tooltip text="You must select at least 3 competitors">
                <template v-slot:activator="{ props }">
                    <v-btn rounded
                        v-bind="props"
                        color="profileAnchorColor"
                        :disabled="!advanceable"
                        :loading="submittingRun || submittingValidation"
                        class="button-with-icon whiteColor--text text-none"
                        @click="$emit('gonextpage')">
                        <span>Next</span>
                        <i class="material-icons">arrow_forward</i>
                    </v-btn>
                </template>
            </v-tooltip>
            <span v-if="advanceable && isAlreadyDoneValidatingUrl">
                &nbsp;&nbsp;
                <v-tooltip text="You must select at least 3 competitors">
                    <template v-slot:activator="{ props }">
                        <v-btn rounded
                            v-bind="props"
                            color="profileAnchorColor"
                            :disabled="!advanceable"
                            :loading="submittingRun || submittingValidation"
                            class="button-with-icon whiteColor--text text-none"
                            @click="$emit('gonextpage')">
                            <span>Next</span>
                            <i class="material-icons">arrow_forward</i>
                        </v-btn>
                    </template>
                </v-tooltip>
            </span>
        </div>
    </div>



    <div class="modal" :class="{ 'is-active': showManualCopyHtmlModal }">
        <div class="modal-background"></div>
        <div class="modal-content" style="min-width: 80vw;">
            <div class="box modal-container">
                <div>
                    <div class="title-font is-size-4">Input html for url {{ activeManualCompetitorSource }}</div>
                    <div class="mt-5">
                        <v-textarea outlined hide-details="auto"
                            label="Please input the html code here"
                            rows="10"
                            color="greenColor"
                            class="mt-5"
                            v-model="editingHtmlCode">
                        </v-textarea>
                    </div>
                    <div class="text-right mt-5">
                        <v-btn rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="emitEditedHtmltoParent"
                        >
                            <span>Save</span>
                        </v-btn>
                    </div>
                </div>
            </div>
            <button class="custom-modal-close-btn" aria-label="close" @click="showManualCopyHtmlModal = false"><i class="material-icons">close</i></button>
        </div>
    </div>
</div>
</template>

<script>

export default {
    props: ['competitorUrls', 'competitorUrlsArray', 'validatedCompetitorUrls', 'submittingRun', 'submittingValidation',
        'activeManualCompetitorSource', 'manualCopySourceCompetitorUrls', 'competitorsUrlValidationError', 'invalidCompetitorUrls',
        'competitorUrlsMissingHttpError', 'missingHttpCompetitorUrls', 'missingPastingManualCompetitorUrlSources',
        'manualCompetitorUrlsSources',
        'advanceable', 'isAlreadyDoneValidatingUrl', 'errorMsg', 'googleSearchResults', 'setErrorMsg'],
    data () {
        return {
            showManualCopyPasteArea: false,
            thisCompetitorUrls: this.competitorUrls,

            showManualCopyHtmlModal: false,
            editingHtmlCode: '',
            
        }
    },
    computed: {
        hasGoogleSearchResults () {
            return this.googleSearchResults.length > 0
        }
    },
    watch: {
        'competitorUrls': function(urls) {
            this.thisCompetitorUrls = urls
            this.$nextTick(() => {
                this.adjustTextAreaHeight(document.querySelector('.competitor-input-textarea'))
            })
        },
    },
    methods: {
        setErrorMessage(message) {
            this.errorMsg = message
        },
        showGoogleResults () {
            if (this.hasGoogleSearchResults) {
                this.$emit('showgoogleresults')
            } else {
                this.$notify({
                    group: 'info', type: 'error',
                    text: "We couldn't find any SERP result on target keyword, please add your competitors manually to proceed with POP run."
                })
                this.setErrorMessage("We couldn't find any SERP result on target keyword, please add your competitors manually to proceed with POP run.")
            }
        },
        adjustTextAreaHeight (target) {
            target.style.height = '';
            target.style.height = target.scrollHeight + 'px'
        },
        openAddManualHtmlPopup () {
            if (this.manualCompetitorUrlsSources) {
                this.editingHtmlCode = this.manualCompetitorUrlsSources[this.activeManualCompetitorSource] || ''
            } else {
                this.editingHtmlCode = ''
            }
            this.showManualCopyHtmlModal = true;
        },
        emitEditedHtmltoParent () {
            this.$store.commit('showLoading')
            this.$emit('changemanualcompetitorsource', {
                url: this.activeManualCompetitorSource,
                htmlCode: this.editingHtmlCode,
                done: () => {
                    this.$store.commit('hideLoading')
                    this.showManualCopyHtmlModal = false;
                }
            })
        },
        handleCompetitorUrlsChange (e) {
            this.$emit('competitorurlschange', this.thisCompetitorUrls)
        },
        toggleurlinmanuallist(e, url) {
            console.log('toggle is calling')
            this.$emit('toggleurlinmanuallist', { event: e, url: url })
        },
        updateManualSourceCodeArea () {
            console.log('hkg very basic test', this.manualCompetitorUrlsSources)
            if (this.manualCompetitorUrlsSources) {
                let keys = Object.keys(this.manualCompetitorUrlsSources)
                for (let key of keys) {
                    let url = key
                    this.showManualCopyPasteArea = true;

                    let checkbox_element = document.getElementById(`manualcopy_checkbox_${url}`)
                    console.log('founded checkbox in competitors', checkbox_element)
                    if (checkbox_element) {
                        console.log('making checked', checkbox_element)
                        checkbox_element.checked = true;
                    }

                    // let element = document.getElementById(`manualcopy_textarea_${url}`)
                    // console.log('founded text area for source in competitors', element)
                    // if (element) {
                    //     element.value = this.manualCompetitorUrlsSources[`${url}`]
                    // }
                }
            }
        }
    },
    mounted () {
        setTimeout(() => {
            this.updateManualSourceCodeArea()
            this.adjustTextAreaHeight(document.querySelector('.competitor-input-textarea'))
        }, 500);
    }

}
</script>

<style lang="scss" scoped>
div {
    color: var(--v-mainColor-base);
}
</style>