const explainData = {
  "Privacy Policy":
    "A link on your target page to a Privacy Policy page. (A link that contains the word “Privacy” in an A tag, usually in the footer of the page)",

  "Terms of Service":
    "A link on your target page to the Terms of Service page. (A link that contains the word “term” or “terms” in an A tag.  This type of link is usually in the footer of the page).",

  "Return Policy":
    "A link on your target page to a page that contains a return policy.  (A link that contains the words “return,“returns,” “return policy,” in an A tag.  This type of link is usually found in the footer of the page).",

  "Customer Service Page":
    "A link on your target page to a Customer Service Page. (A link that contains the words “customer service,” “contact us,” “help,” or “FAQ” in an A tag. This type of link is usually found in the footer of the page).",

  "Meet The Team Page":
    "A link on your target page to a Meet The Team page. (A link that contains the word “team,” “meet us,” “meet the team,” “who we are,” “the crew,” “crew,” “our people,” “people,” “our story,” or “profiles,” in an A tag.  This link usually appears in the header of the page).",

  "Awards/Won link displayed":
    "A link on your target page to a page that discusses an award won.  (A link that contains the words “award,” “awards,” or “won,” in an A tag.  This type of link is usually found in the footer of the page).  This link can go to an internal or external page.",

  "On shopping sites display payment and exchange policies":
    "A link on your target page that contains the words “payment” or “exchange.”",

  "About Us Page":
    "A link on your target page pointed to an About Us Page.  (A link that contains the word “about,” “our history,” “company,” or “our details” in an A tag.  This link is usually in the header of the page).",

  "Blog Page":
    "A link on your target page pointed to a Blog Page. (A link that contains the word “blog” or “news” in an A tag.  This link is usually in the header or footer of the page).",

  "Cookie Policy/GDPR page":
    "The presence of a link that includes the words “cookies,” or “cookie.”",

  "Copyright":
    "The presence of the words “copyright,” “copyrights,” or “all rights reserved” or the copyright symbol in an a tag or in a p tag.",

  "Current copyright":
    "The current year near a copyright symbol in the footer of the target page.",

  "Trademark":
    "A link on your page that contains the words “trademark, or “trademarks.”  Alternatively, the presence of the trademark symbol in the footer of the target page.",

  "Contact Details":
    "The presence of contact indicators.  (A link that contains the words “phone,” “contact,” or “email” in an A tag.  This type of link is usually displayed in the footer of your target page.)",

  "Phone number":
    "The presence of a phone number in the HTML source code of your target page.",

  "Google Map Displayed":
    "An embedded link to a Google Map in the html source of your target page.",

  "Business Address":
    "Presence of business address in the html source of your target page.",

  "Organization Schema":
    "The presence of Organization Schema markup in the HTML source code.  This type of schema should be displayed on either the home page, the about us page, or the contact us page.",

  "Rating Schema":
    "The presence of rating schema in the html source code of your target page.",

  "Author Schema":
    "The presence of author schema in the html source code of your target page.",

  "Date Updated/Published":
    "Presence of Updated/Published dates in the HTML source code of your target page.",

  "Authority Outbound Links: .gov":
    "The presence of a link on your page with the TLD .gov in it.",

  "Authority Outbound Links: .edu":
    "The presence of a link on your page with the TLD .edu in it.",

  "Total Internal Links":
    "The number of UNIQUE internal links (links in the BODY of your target page) pointed to other internal pages.  Internal pages are pages that exist on the same domain.",

  "Internal Link":
    "Does your target page has any internal links in BODY? Internal pages are pages that exist on the same domain.",

  "Internal links without 404":
    "Number of internal links that are error free (the do not result in a 404 errors) pointed to other internal pages.  Internal pages are pages that exist on the same domain.  This signal is limited to the first 20 links discovered.",

  "Internal Broken Links":
    "The number of internal links on the page that do not redirect to a 404 page.  Internal links are links that will take you to pages that exist on the same domain.",

  "Recent blog post":
    "A date in the Time tag that is within 30 days from the current date.",

  "Email": "The presence of a linked email, clickable on the page.",

  "More than one email on site":
    "The presence of more than one linked email, clickable on the page.",

  "SSL Certificate on the Website":
    'It is recommended to use a URL that starts with "https" instead of "http" for a secure connection. The extra "s" in "https" indicates that the website has an SSL Certificate, providing a secure layer of encryption.',

  "Link to Author Page":
    "The presence of a tag that has the class “author” or a link which includes the word “author” in the source code.",

  "Social Media Links":
    "The presence of any link to a social media account displayed in the footer or header of the target page.  The following social media accounts are checked: Twitter, Facebook, Linkedin, Instagram.",

  "Twitter Card":
    'The presence of <meta name="twitter:card" content="...." /> a tag in the html source code of your target page.',

  "Twitter Description":
    'The presence of <meta name="twitter:description" content="...." /> a tag in the html source code of your target page.',

  "Meta Author Tag":
    "The presence of a meta tag in the source code that contains the word “author.”",

  "rel=publisher":
    'The presence of <link rel="publisher" href="http://plus.google.com/+XYZCompany/posts"/> a tag in the html source code of your target page.', // removed but keep for old report

  "Article Schema":
    "The presence of article schema in the html source code of your target page.",
};

export default explainData;
