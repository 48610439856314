<template>
  <div class="pricing-plan is-active" style="max-width: 400px;">
    <div class="plan-header">{{ computedTitle }}</div>
    <!-- <div class="has-text-centered" v-if="computedTitle == 'Basic'">
      <seven-day-trial-mark></seven-day-trial-mark>
    </div> -->
    <div v-if="periodFreeCouponApplied && coupon" class="plan-price">
      <span class="plan-price-amount">{{ coupon.freeDays }} days free</span>
    </div>
    <div v-else class="plan-price">
      <span class="plan-price-amount"><span class="plan-price-currency">$</span>{{ price.toFixed(2) }}</span>/{{ timeunit }}
    </div>
    <div class="plan-items">
      <div class="plan-item">
        <p v-for="(item, i) in computedItems" :key="i" v-html="Array.isArray(item) ? item.join(': ') : item"></p>
      </div>
    </div>
    <div class="plan-footer">
      <a :href="differntPlanLink" class="button is-fullwidth is-grey">
        <span >Choose Different Plan</span>
      </a>
    </div>
  </div>
</template>

<script>
// import SevenDayTrialMark from '@/components/SevenDayTrialMark.vue'
import servicePackages from '@/components/service_package_constants'

export default {
  components: {
    // SevenDayTrialMark
  },
  props: {
    'title': { type: String },
    'price': { type: Number },
    'timeunit': {type: String},
    'items': {
      type: Array,
    },
    'affiliateCode': {
      type: String,
    },
    'periodFreeCouponApplied': {
      type: Boolean,
      default: false,
    },
    'coupon': {
      type: Object,
      default: null,
    }
  },
  computed: {
    computedTitle () {
      return this.periodFreeCouponApplied ? 'Period Free' : this.title
    },
    computedItems () {
      if (this.periodFreeCouponApplied) {
        return ["Unlimited reports in period", "Unlimited domains"]
      }
      return this.items
    },
    differntPlanLink () {
      let link = `https://pageoptimizer.pro/pricing`;
      if ( this.affiliateCode ) {
        link += `?affiliateCode=${this.affiliateCode}`
      }
      return link;
    }
  }
}
</script>

<style>

</style>