const ALL_TYPES = {
  KEYWORD_TYPE: 0,
  VARIATION_TYPE: 1,
  PAGE_STRUCTURE_TYPE: 2,
  LSI_TERMS_TYPE: 3,
  SCHEMA_TYPE: 4,
  CONTENT_BRIEF_TYPE: 5,
  EEAT_TYPE: 6,
  WATCHDOG_TASK: 7,
}

export const mixin = {
  data () {
    return {
      ALL_TYPES: ALL_TYPES,
    }
  },
  methods: {
    pageScoreTintColor(score) {
      let value = score ? score : 0;
      if (value >= 0 && value <= 69) {
        return "#FF0000";
      } else if (value > 69 && value <= 74) {
        return "#FF9C00";
      } else if (value > 74 && value <= 80) {
        return "#FFC000";
      } else if (value > 80 && value <= 83) {
        return "#8CCFB5";
      } else if (value > 83 && value <= 85) {
        return "#78C8A5";
      } else if (value > 85 && value <= 88) {
        return "#5EC198";
      } else if (value > 88 && value <= 90) {
        return "#00B482";
      } else if (value > 90 && value <= 93) {
        return "#00925D";
      } else if (value > 93 && value <= 95) {
        return "#008452";
      } else if (value > 95) {
        return "#006A42";
      }
      return "#000000";
    },
    getTaskSignalDisplayName (task) {
      let dispNames = {
        'titleTotal': 'Search Engine Title',
        'pageTitleTotal': 'Page Title',
        'subHeadingsTotal': 'Sub-headings',
        'pTotal': 'Main content',
        'list': 'List',
      }
      let rlt =  dispNames[task.signal] || task.signal
      if (task.type == ALL_TYPES.KEYWORD_TYPE) {
        rlt += ' ( Keyword )'
      } else if (task.type == ALL_TYPES.VARIATION_TYPE) {
        rlt += ' ( Variation )'
      } else if (task.type == ALL_TYPES.PAGE_STRUCTURE_TYPE) {
        rlt += ' ( Page Structure )'
      } else if (task.type == ALL_TYPES.SCHEMA_TYPE) {
        rlt += ' ( SchemaType )'
      } else if (task.type == ALL_TYPES.EEAT_TYPE) {
        rlt += ' ( EEAT )'
      } else if (task.type == ALL_TYPES.WATCHDOG_TASK) {
        rlt = task.customRecommendationText || 'Watchdog task'
      }
      return rlt
    },
  }
}