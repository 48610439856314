<template>
<div>
  <div class="d-flex align-center mt-3 mb-5">
    <div class="flex-grow-1 report-one-page-name gap10">
      <span>Page structure</span>
      <!-- <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
        @click="() => {
          $store.commit('setCurrentVideoTutorialToDisplay', {
            title: 'Variations and LSI in POP',
            videoLink: 'https://www.youtube.com/embed/ZDE3lU50gMI?si=3dpddTelv2Ahifud',
            description: 'Understand what LSI and Variations are.',
            time: '3m 03s'
          });
        }">
        videocam
      </span> -->
    </div>
  </div>

  <div class="d-flex flex-wrap align-center">
    <div class="flex-grow-1 flex">
        <div class="custom-tabs">
          <div @click="showSignalType = 'all_todo'" :class="{ 'active': showSignalType == 'all_todo' }">Show all recommendations</div>
          <div @click="showSignalType = 'high'" :class="{ 'active': showSignalType == 'high' }">Show only high priority recommendations</div>
          <div @click="showSignalType = 'resolved'" :class="{ 'active': showSignalType == 'resolved' }">Show resolved recommendtaions</div>
          <div @click="showSignalType = 'all'" :class="{ 'active': showSignalType == 'all' }">Show all signals</div>
        </div>
    </div>
    <div>
      <div class="sort-filter-block">
        <div v-if='hasFocusData'>
          <span class="has-text-grey">Comparing to </span>
          <span @click="showFocusData = !showFocusData" class="selection-tag" :class="{ 'yellow-active-tag': showFocusData }">
            Focus
          </span>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div>
          <span class="has-text-grey">Comparing with report </span>
          <select v-model="comparePageRunId">
            <option value="">Select one pagerun to compare</option>
            <template v-for="p in comparingPageRuns">
              <option :value="p.id" :key="p.id" v-if="p.id!=pageRun.id">
                {{ p.keyword ? p.keyword : page.keyword }} - {{ getDateAsLocalString(p.createdAt) }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </div>
  </div>

  
  <v-card class="is-fullheight d-flex flex-column white-common-box">
    <div class="hscroll-container">
      <table class="top-border-table" style="min-width: 1000px;">
        <thead>
          <tr>
            <th>Where</th>
            <th>Lowest</th>
            <th>Highest</th>
            <th>Average</th>
            <template v-if="!pageNotBuilt">
              <th>Current</th>
              <th class="recommendations">Recommendation</th>
              <th></th>
            </template>

            <template v-if="showFocusData">
              <th :style="{'background': compBg}">Focus Lowest</th>
              <th :style="{'background': compBg}">Focus Highest</th>
              <th :style="{'background': compBg}">Focus Average</th>
              <!-- <th v-if="!pageNotBuilt">Current</th> -->
              <th :style="{'background': compBg}" class="recommendations">
                Recommendation
              </th>
            </template>
            
            <th v-if="comparePageRunId" class="compare-field">Lowest</th>
            <th v-if="comparePageRunId" class="compare-field">Highest</th>
            <th v-if="comparePageRunId" class="compare-field">Average</th>
            <th v-if="!pageNotBuilt && comparePageRunId" class="compare-field">Current</th>
            <th v-if="comparePageRunId" class="recommendations compare-field">Recommendation</th>
            

            <th class="action-column">Action</th>
            <th>Current Selection</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tagCount, i) in tagCountsForRender" :key="`tag-total-row-${i}`">
            <td>
              <div class="flex align-center font-weight-bold">
                <i class="material-icons"
                  v-tooltip.right="{ content: tagExplainData[tagCount.tagLabel] ? tagExplainData[tagCount.tagLabel] : '',
                  classes: 'custom-tooltip' }" >info</i>
                <span>{{ tagCount.tagLabel }}</span>
              </div>
            </td>
            <td>{{ tagCount.min }}</td>
            <td>{{ tagCount.max }}</td>
            <td>{{ tagCount.mean | round(2) }}</td>
            <template v-if="!pageNotBuilt">
              <td v-if="!pageNotBuilt">{{ tagCount.signalCnt }}</td>
              <td v-if="!pageNotBuilt" class="recommendations">
                {{ tagCount.comment }}
              </td>
              <td>
                <span :class="getMarkTypeStyle(tagCount.comment)"></span>
              </td>
            </template>
            <td v-if="pageNotBuilt" class="recommendations">{{ tagCount.comment }}</td>

            <template v-if="showFocusData">
              <td :style="{'background': compBg}">{{ getFocusDataForThisRun(tagCount.tagLabel, 'min') }}</td>
              <td :style="{'background': compBg}">{{ getFocusDataForThisRun(tagCount.tagLabel, 'max') }}</td>
              <td :style="{'background': compBg}">{{ getFocusDataForThisRun(tagCount.tagLabel, 'mean') | round(2) }}</td>
              <!-- <td v-if="!pageNotBuilt">{{ getFocusDataForThisRun(tagCount.tagLabel, 'signalCnt') }}</td> -->
              <td :style="{'background': compBg}" v-if="!pageNotBuilt" class="recommendations">{{ getFocusDataForThisRun(tagCount.tagLabel, 'comment') }}<span :class="getMarkTypeStyle(getFocusDataForThisRun(tagCount.tagLabel, 'comment'))"></span></td>
              <td :style="{'background': compBg}" v-if="pageNotBuilt" class="recommendations">{{ getFocusDataForThisRun(tagCount.tagLabel, 'comment') }}</td>
            </template>


            <td v-if="comparePageRunId" class="compare-field">{{ getCompareData(tagCount.tagLabel, 'min') }}</td>
            <td v-if="comparePageRunId" class="compare-field">{{ getCompareData(tagCount.tagLabel, 'max') }}</td>
            <td v-if="comparePageRunId" class="compare-field">{{ getCompareData(tagCount.tagLabel, 'mean') | round(2) }}</td>
            <td v-if="!pageNotBuilt && comparePageRunId" class="compare-field">{{ getCompareData(tagCount.tagLabel, 'signalCnt') }}</td>
            <td v-if="!pageNotBuilt && comparePageRunId" class="recommendations compare-field">{{ getCompareData(tagCount.tagLabel, 'comment') }}<span :class="getMarkTypeStyle(getCompareData(tagCount.tagLabel, 'comment'))"></span></td>
            <td v-if="pageNotBuilt && comparePageRunId" class="recommendations compare-field">{{ getCompareData(tagCount.tagLabel, 'comment') }}</td>

            <td>
              <v-btn :disabled="$store.getters.isDemoUser" v-if="tagCount.checklistId > 0"
                rounded x-small outlined color="greenColor"
                class="button-with-icon text-none">
                <span class="material-icons">add_task</span>
                <span>Added to to-do list</span>
              </v-btn>
              <v-btn :disabled="$store.getters.isDemoUser" v-else
                rounded x-small color="profileAnchorColor"
                class="button-with-icon text-none whiteColor--text"
                @click="addToChecklist(tagCount)">
                <span class="material-icons">add_task</span>
                <span>Add to to-do list</span>
              </v-btn>
            </td>
            <td :class="{ 'is-warning': tagCount['checklistId'] }">
              <span v-if="tagCount['checklistId']">
                In checklist
              </span>
              <span v-else>
                None
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="has-text-centered table-explain-label my-2 has-text-info">Change your dashboard view by clicking on the orange filters above.</div>
    </div>
  </v-card>

</div>
</template>

<script>

import _ from 'lodash'
import { mixin } from '@/components/mixin'
import explainData from './TagTotalsExplainData'

export default {
  mixins: [mixin],
  props: ['tagCounts', 'featuredTagCounts', 'pageNotBuilt', 'pageId', 'checkLists'],
  components: {
  },
  data () {
    return {
      searchTerm: '',
      tagExplainData: {},
      showSignalType: 'high', // 'high' 'resolved', 'all', 'all_todo'

      showFocusData: false,
    }
  },
  computed: {
    compBg() {
      return this.$vuetify.theme.currentTheme['link9Color']
    },
    hasFocusData () {
      return this.featuredTagCounts.length > 0
    },
    comparingPageRuns () {
      return this.pageRunsWithRecentOrder.filter(p => p.pageNotBuilt == this.pageNotBuilt)
    },

    unarchivedChecklists () {
      return this.checkLists.filter(item => !item.isArchived)
    },
    tagCountsForRender () {
      let data = this.tagCounts.map(row => {
        let found = this.unarchivedChecklists.find(checklist => {
          return (checklist.type == 2 && checklist.signal && checklist.signal == row.tagLabel)
        })
        if (found) {
          row.checklistId = found.id
          row.isCompleted = found.isCompleted
        } else {
          row.checklistId = 0
        }
        return row
      })

      data = data.filter(item => {
        if (!item.comment) return true
        if (this.showSignalType == 'resolved') {
          return item.comment.indexOf('Leave As Is') > -1
        } else if (this.showSignalType == 'high') {
          return item.comment.indexOf('Increase') > -1 || item.comment.indexOf('Add') > -1
        } else if (this.showSignalType == 'all_todo') {
          return item.comment.indexOf('Leave As Is') == -1
        }
        return true
      })
      return data
    },
    compareTagCountsForRender () {
      if (!this.comparePageRunId) {
        return []
      }

      let compPageRun = this.pageRuns.filter(p => p.id == this.comparePageRunId)[0]
      if (!compPageRun) { return [] }
      let tagCounts = compPageRun.tagCounts

      let data = tagCounts.filter(item => {
        if (!item.comment) return true
        if (this.showSignalType == 'resolved') {
          return item.comment.indexOf('Leave As Is') > -1
        } else if (this.showSignalType == 'high') {
          return item.comment.indexOf('Increase') > -1 || item.comment.indexOf('Add 1') > -1
        } else if (this.showSignalType == 'all_todo') {
          return item.comment.indexOf('Leave As Is') == -1
        }
        return true
      })
      return data
    }
  },
  methods: {
    /** 
     * type: 0: keyword, 1: variation, 2: page structure
     * dispType: regular, focus, max
     * signal : H1, H2 ......
    */
    addToChecklist (row) {
      let data = {
        pageId: this.pageId,
        recommendationType: 'pageStructure',
        type: 2,
        dispType: '',
        signal: row.tagLabel
      }
      console.log(data)
      this.$store.commit('showLoading')
      return this.$store.dispatch('addOneToCheckList', data)
      .then(response => {
        if (response.data && response.data.status === 'SUCCESS') {
          console.log('successfully added!')
        }
        if (response.data.page) {
          this.$store.commit('setPage', response.data.page)
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    checkColor (comment) {
      if (this.pageNotBuilt) {
        return 'white'
      }
      let color = ''
      if (comment.indexOf('Leave As Is') > -1) {
        color = '#b8e1cc'
      } else if (comment.indexOf('Increase') > -1 || comment.toLowerCase().indexOf('add') > -1) {
        color = '#cf8c8e'
      } else {
        color = '#f0c291'
      }
      return color;
    },
    getMarkTypeStyle (comment) {
      if (this.pageNotBuilt) {
        return {}
      }
      if (comment.indexOf('Leave As Is') > -1) {
        return { 'signal-mark': true, 'good-marktype': true }
      } else if (comment.indexOf('Reduce') > -1 || comment.indexOf('reducing') > -1) {
        return { 'signal-mark': true, 'mid-marktype': true }
      } else {
        return { 'signal-mark': true, 'high-marktype': true }
      }
    },
    getFocusDataForThisRun(tagLabel, fieldName) {
      let found = this.featuredTagCounts.find(item => item.tagLabel == tagLabel)
      if (found) {
        return found[`${fieldName}`]
      } else {
        return ''
      }
    },
    getCompareData (tagLabel, fieldName) {
      let find = this.compareTagCountsForRender.filter(s => s.tagLabel == tagLabel)
      if (find.length > 0) {
        return find[0][`${fieldName}`]
      }
      return ''
    }
  },
  mounted() {
    this.tagExplainData = explainData
    if (this.pageNotBuilt) {
      this.showSignalType = 'all'
    }
  }
}
</script>

<style scoped lang="scss">
.search-input {
  width: 50% !important;
}

.recommendations {
  max-width: 400px;
}

.custom-tabs {
  border: 1px solid var(--v-gray12Color-base);
  border-radius: 9px;
  margin-bottom: 30px;
  display: flex;
  & > div {
    cursor: pointer;
    padding: 10px 20px;
    color: var(--v-mainColor-base);
  }
  & > div:not(:last-child) {
    border-right: 1px solid var(--v-gray12Color-base);
  }
  & > div:has(+ .active) {
    border-right: none;
  }
  & > div.active {
    background: var(--v-gray13Color-base);
    border-radius: 9px;
    border-right: 1px solid var(--v-gray12Color-base);
    border-left: 1px solid var(--v-gray12Color-base);
    font-weight: bold;
  }
}

@media screen and (max-width: 700px) {
  .custom-tabs {
    width: 100%;
    flex-direction: column;

    & > div:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid var(--v-gray12Color-base);
    }
    & > div:has(+ .active) {
      border-bottom: none;
    }
    & > div.active {
      background: var(--v-gray13Color-base);
      border-radius: 9px;
      border-bottom: 1px solid var(--v-gray12Color-base);
      border-top: 1px solid var(--v-gray12Color-base);
      font-weight: bold;
    }
  }    
}

.table-explain-label {
  font-size: 15px;
}
</style>