<template>
  <div>
    <div class="plan-items">
      <div class="plan-item">
        <div v-for="(item, i) in computedItems" :key="i">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
          <div class="flex-grow-1">
            {{ Array.isArray(item) ? item[0] : item }}
          </div>
          <div>{{ Array.isArray(item) ? item[1] : '' }}</div>
        </div>
      </div>
    </div>
    <div class="plan-footer">
      <div v-if="showStarSignExplainText"
        class="mt-2 text-right" style="font-size: 11px;">* subject to our Fair Use Policy</div>
      <a :href="differntPlanLink" class="return-to-pricing-link mt-2">
        Return to pricing page
        <i class="material-icons">arrow_forward</i>
      </a>
    </div>
  </div>
</template>

<script>
import servicePackages from '@/components/service_package_constants'

export default {
  props: {
    'title': { type: String },
    'price': { type: Number },
    'timeunit': {type: String},
    'items': {
      type: Array,
    },
    'affiliateCode': {
      type: String,
    },
    'periodFreeCouponApplied': {
      type: Boolean,
      default: false,
    },
    'coupon': {
      type: Object,
      default: null,
    },
    'showStarSignExplainText': {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    computedTitle () {
      return this.periodFreeCouponApplied ? 'Unlimited Period Free' : this.title
    },
    computedItems () {
      if (this.periodFreeCouponApplied) {
        return ["Unlimited reports in period", "Unlimited domains"]
      }
      return this.items || []
    },
    differntPlanLink () {
      let link = `https://pageoptimizer.pro/pricing`;
      if ( this.affiliateCode ) {
        link += `?affiliateCode=${this.affiliateCode}`
      }
      return link;
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-item {
  color: var(--v-mainColor-base);
  font-size: 1rem;
  & > div {
    padding-bottom: 8px;
    display: flex;
    align-items: center;
  }
  i {
    color: var(--v-greenColor-base);
    margin-right: 7px;
  }
}

.return-to-pricing-link {
  color: var(--v-profileAnchorColor-base);
  display: flex;
  align-items: center;
  font-weight: 700;
  .material-icons {
    font-size: 17px;
    margin-left: 10px;
  }
}
</style>