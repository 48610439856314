<template>
<section>
  <div class="hero white-background">
    <div class="hero-body" style="background-image: url('https://pageoptimizer.pro/wp-content/uploads/2020/06/12.gif')">
      <div class="container">
        <div class="pricing-table mt20 mb30 mx30" style="justify-content: center;">
          <service-card
            :title="serviceFromRoute.title"
            :price="serviceFromRoute.price"
            :timeunit="serviceFromRoute.timeUnit"
            :items="serviceFromRoute.items"
            :affiliateCode="promoCode"
            >
          </service-card>
        </div>
      </div>
    </div>
    <div class="hero-foot mt20">
      <section class="columns">
        <div class="column is-offset-3 is-6">
          <h2 class="title has-text-centered" style="color: #494e8f;">REGISTER & PAY</h2>

          <p class='is-italic has-text-centered mb40' v-if="serviceFromRoute.code === servicePackages.SERVICE_PKG_B2.code">* You have selected the Basic Package, your card will not be charged until the trial period has ended or you have elected to end the trial period to unlock premium features or purchase more credits.</p>
          <p class='is-italic has-text-centered mb40' v-if="isAgencyPackage">* This plan is <b>not</b> meant for group buys or the sharing of accounts between users.  If you are not using the agency account properly your account will be closed and you will lose access to your projects.</p>
          <div class="columns">
            <div class="column">
              <!-- purchase amount -->
              <div class="field">
                  <label class="label has-text-black">Amount ($)</label>
                  <div class="control has-icons-left">
                      <input type="text" class="input" readonly v-model="purchaseAmt">
                      <span class="icon is-small is-left">
                          <i class='fa fa-money'></i>
                      </span>
                  </div>
              </div>
            </div>

            <div class="column">
              <!-- Promo Code -->
              <label class="label has-text-black">Promo Code</label>
              <div class="field has-addons">
                <div class="control has-icons-left">
                  <input type="text" placeholder="Example: abc123xy" class='input' v-model="promoCode">
                  <span class="icon is-small is-left">
                      <i class='fa fa-money'></i>
                  </span>
                </div>
                <div class="control">
                  <a class="button is-info" v-if="promoCode" @click="fetchValidatedCoupons">
                    <span class="icon"><i class="fa fa-check-circle-o"></i></span>
                    <span>Apply Coupon</span>
                  </a>
                </div>
                <p v-if="serviceCouponsValid" class="help has-text-success">{{ serviceCouponsMessage }}</p>
                <p v-if="!serviceCouponsValid" class="help has-text-danger">{{ serviceCouponsMessage }}</p>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
                  <!-- email -->
              <div class="field">
                  <label class="label has-text-black">Email</label>
                  <div class="control has-icons-left">
                    <input type="email" placeholder="example@mail.com" class='input' v-model="email" required>
                    <span class="icon is-small is-left">
                        <i class='fa fa-envelope'></i>
                    </span>
                  </div>
                  <p v-if="!validEmail" class="help has-text-danger">must be a valid email address</p>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <!-- password -->
              <div class="field">
                  <label class="label has-text-black">Password</label>
                  <div class="control has-icons-left">
                    <input type="password" placeholder="********" class='input' v-model="password" required>
                    <span class="icon is-small is-left">
                        <i class='fa fa-lock'></i>
                    </span>
                  </div>
                  <p v-if="!passwordMinLength" class="help has-text-danger">password must be at least 6 characters long</p>
              </div>
            </div>
            <div class="column">
              <!-- password confirmation -->
              <div class="field">
                  <label class="label has-text-black">Verify Password</label>
                  <div class="control has-icons-left">
                    <input type="password" placeholder="********" class='input' v-model="verifyPassword" required>
                    <span class="icon is-small is-left">
                        <i class='fa fa-lock'></i>
                    </span>
                  </div>
                  <p v-if="!passwordsMatch" class="help has-text-danger">passwords must match</p>
              </div>
            </div>
          </div>

          <!--
          <label class="checkbox mb20">
            <input type="checkbox" id="isThisForEndUser" v-model="isThisForEndUser">
            Check this box if you are creating this account as a 3rd party vendor
          </label>
          -->
          <div v-if="isThisForEndUser">
            <div class="columns">
              <div class="column">
                    <!-- end user email -->
                <div class="field">
                    <label class="label has-text-black">End User Email</label>
                    <div class="control has-icons-left">
                      <input type="email" placeholder="example@mail.com" class='input' v-model="endUserEmail" required>
                      <span class="icon is-small is-left">
                          <i class='fa fa-envelope'></i>
                      </span>
                    </div>
                    <p v-if="!validEndUserEmail" class="help has-text-danger">must be a valid email address</p>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <!-- password -->
                <div class="field">
                    <label class="label has-text-black">End User Password</label>
                    <div class="control has-icons-left">
                      <input type="password" placeholder="********" class='input' v-model="endUserPassword" required>
                      <span class="icon is-small is-left">
                          <i class='fa fa-lock'></i>
                      </span>
                    </div>
                    <p v-if="!endUserPasswordMinLength" class="help has-text-danger">password must be at least 6 characters long</p>
                </div>
              </div>
              <div class="column">
                <!-- password confirmation -->
                <div class="field">
                    <label class="label has-text-black">Verify End User Password</label>
                    <div class="control has-icons-left">
                      <input type="password" placeholder="********" class='input' v-model="endUserVerifyPassword" required>
                      <span class="icon is-small is-left">
                          <i class='fa fa-lock'></i>
                      </span>
                    </div>
                    <p v-if="!endUserPasswordsMatch" class="help has-text-danger">passwords must match</p>
                </div>
              </div>
            </div>
          </div>

          <div class="columns mt10">
            <div class="column">
              <div class="credit-card-button-and-text">
                <div class="control">
                  <button :disabled="!validForm" class="button is-primary" id="stripe-purchase-button" @click.stop="register('stripe')">
                    <span class="icon">
                      <i class="fa fa-credit-card-alt"></i>
                    </span>
                    <span>Credit Card</span>
                  </button>
                </div>
                <div class="control">
                  <button :disabled="!validForm" class="button is-warning" id="paypal-purchase-button" @click.stop="register('paypal')">
                    <span class="icon">
                      <i class="fa fa-cc-paypal"></i>
                    </span>
                    <span>PayPal</span>
                  </button>
                </div>
                <div class="control" style="padding-left: 20px;">
                  <label class="is-italic">
                    If paying by credit card a popup will appear for you to put in your credit card information or, if paying by PayPal you will be redirected to PayPal to login and confirm your subscription.
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
</template>

<script>
import _ from 'lodash'
import { segmentPage } from '@/utils'
import servicePackages from '@/components/service_package_constants'
import DynamicServiceCard from '@/components/Register/DynamicServiceCard.vue'
import { mixin as RegisterMixin } from '@/components/Register/register_mixin'
export default {
  mixins: [RegisterMixin],
  components: { ServiceCard: DynamicServiceCard },
  data () {
    return {
      serviceFromRoute: null,
      servicePackages: null
    }
  },
  computed: {
    isAgencyPackage () {
      if (!_.isEmpty(this.servicePackages) && !_.isEmpty(this.serviceFromRoute)) {
        const { SERVICE_PKG_F, SERVICE_PKG_G, SERVICE_PKG_H, SERVICE_PKG_I, SERVICE_PKG_J, SERVICE_PKG_K } = servicePackages
        return [
          SERVICE_PKG_F.code,
          SERVICE_PKG_G.code,
          SERVICE_PKG_H.code,
          SERVICE_PKG_I.code,
          SERVICE_PKG_J.code,
          SERVICE_PKG_K.code
        ].includes(this.serviceFromRoute.code)
      }

      return false
    }
  },
  methods: {
      mapRouteParamToService() {
          const { service } = this.$route.params
          const servicePkg = Object.values(servicePackages).find(svcPkg => svcPkg.urlParam === service)
          return servicePkg || servicePackages.SERVICE_PKG_B2
      },
  },
  mounted () {
    console.log(this.$route.params)
    const service = this.mapRouteParamToService()
    const { price, code, description } = service
    this.serviceFromRoute = service
    this.servicePackages = servicePackages
    this.purchaseAmt = price
    this.selectedServicePkg = code
    this.serviceDescription = description
    segmentPage('DynamicRegister', {
      price,
      selectedServicePkg: code,
      serviceDescription: description
    })
  }
}
</script>

<style scoped lang="scss">
.box {
    padding: 30px 50px;
}

.service-package {
  min-height: 230px;
}

.is-service-benefit {
  font-weight: bold;
}

.credit-card-button-and-text {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .credit-card-button-and-text {
    flex-direction: column;
  }
}

.tab-circles {
  display: flex;
  justify-content: center;
  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #ffcc00;
    margin: 5px 7px;
    cursor: pointer;
  }
  span.active {
    background: #ffcc00;
  }
}
</style>
