<template>
<div class="mt-5 white-common-box px-5 py-8" v-if="hasApiDashboardAccess">
  <div class="d-flex align-center mb-5">
    <div v-if="!forAdmin" class="api-key flex-grow-1">
      API Keys
    </div>
    <a :href="API_DOC_LINK" target="_blank" class="go-to-doc">
      Go to API Documentation
      <span class="material-icons">open_in_new</span>
    </a>
  </div>
  <div class="api-key-disp-area mt-3"
    v-for="item in apiKeys" :key="item.keyType">
    <div class="font-weight-bold mr-2" style="flex-basis: 140px;">{{ item.keyLabel }}</div>
    <div class="flex-grow-1 d-flex align-center gap10">
      <span class="font-weight-bold">{{ item.key }}</span>
      <span class="material-icons-outlined copy-icon"
        v-clipboard:copy="item.key"
        v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
        v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })">
        content_copy
      </span>
    </div>
    <div>
      <div class="font-weight-bold">{{ toLocalDateTimeString(item.dateCreated) }}</div>
      <div>Date connected</div>
    </div>
    <div>
      <v-btn rounded color="profileAnchorColor"
        class="whiteColor--text text-none"
        @click="() => showConfirmRegenerateModalAction(item.keyType)">
        <span>Regenerate</span>
      </v-btn>
    </div>
  </div>
  <div class="has-text-grey mt-5">
    Generating a new API Key will invalidate your current API Key
  </div>




  <div class="modal" :class="{ 'is-active': showConfirmRegenerateModal }">
    <div class="modal-background" @click.stop="showConfirmRegenerateModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="subtitle-font mainColor--text">
          You're about to regenerate "{{ selectedKeyLabel }}". <br />
          Do you want to proceed?
        </div>
        <div class="text-right">
          <v-btn rounded outlined color="mainColor" class="button-with-sicon mainColor--text text-none"
            @click.stop="showConfirmRegenerateModal = false;">
            Cancel
          </v-btn>
          <v-btn rounded color="profileAnchorColor" class="button-with-sicon whiteColor--text text-none ml-2"
            @click.stop="() => regenerateActionForSelectedKeyType()">
            Yes
          </v-btn>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="() => (showConfirmRegenerateModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</div>
</template>

<script>
import { toLocalDateTimeString } from '@/utils'

export default {
  props: {
    forAdmin: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    hasPOPApiKey () {
      return this.$store.getters.hasPOPApiKey
    },
    hasApiDashboardAccess () {
      return this.$store.getters.hasApiDashboardAccess
    },
    apiKeys () {
      return this.$store.getters.availablePOPKeys
    },
    selectedKeyLabel () {
      if (!this.selectedKeyType) {
        return ''
      }
      let found = this.apiKeys.find(item => item.keyType == this.selectedKeyType)
      if (found) {
        return found.keyLabel
      }
      return ''
    }
  },
  data () {
    return {
      API_DOC_LINK: 'https://documenter.getpostman.com/view/33951035/2sA35Mzedv',

      selectedKeyType: '',
      showConfirmRegenerateModal: false,
    }
  },
  methods: {
    toLocalDateTimeString (d) {
      return toLocalDateTimeString(d)
    },
    showConfirmRegenerateModalAction (keyType) {
      this.selectedKeyType = keyType;
      this.showConfirmRegenerateModal = true;
    },
    regenerateActionForSelectedKeyType () {
      this.showConfirmRegenerateModal = false;
      this.$store.commit("showLoading");
      this.$store
        .dispatch("createAPIAccessKey", {
          keyType: this.selectedKeyType,
        })
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$store.commit("setUser", response.data.user);
            this.$notify({
              group: 'info', type: 'success',
              text: 'Successfully created new api key.'
            })
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: response.data.msg || 'Failed to create api key.'
            })
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    }
  }

}
</script>

<style lang="scss" scoped>

.api-key-disp-area {
  padding: 15px;
  border-radius: 10px;
  background: var(--v-pink1Color-base);
  color: var(--v-mainColor-base);
  display: flex;
  align-items: center;
  gap: 20px;
  .copy-icon {
    font-size: 1rem;
    cursor: pointer;
  }
}

.go-to-doc {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--v-profileAnchorColor-base);
  font-weight: 800;
  font-size: 1rem;
  span {
    font-size: 1rem;
    font-weight: 800;
  }
}

.api-key {
  color: var(--v-mainColor-base);
  font-weight: 800;
  font-size: 1rem;
}
</style>