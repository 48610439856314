<template>
<div class="section">
  <div class="width-limitation">
    <div class="top-section">
      <div class="title-label flex-grow-1">Cards on file</div>
      <div
        @click="addNewCard"
        class="add-card-button">
        <i class="material-icons">add_circle</i>
        <span class='has-text-centered'>Add card</span>
      </div>
    </div>
    <div class="description">
      If your subscription expired, you will need to reactivate your subscription by choosing a monthly plan.  Your original plan will not reactivate simply by adding a new credit card. 
      <br/><br/>
      Removing a card will not cancel your subscription. You will still need to cancel your subscription.
    </div>

      <table class="row-round-table mt-6">
        <thead>
          <tr>
            <th>Card Type</th>
            <th>Last 4 digits</th>
            <th>Preferred</th>
            <th>Expiry date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="card in cards" :key="card.id">
            <td>
              <template v-if="card.brand == 'visa'">
                <img src="/static/visa.png" width="40px" />
              </template>
              <template v-else-if="card.brand == 'mastercard'">
                <img src="/static/mastercard.png" width="40px" />
              </template>
              <template v-else>
                {{ card.brand }}
              </template>
            </td>
            <td>{{ card.last4 }}</td>
            <td>{{ card.preferred ? 'Yes' : 'No' }}</td>
            <td>{{ card.expMonth }}/{{ card.expYear }}</td>
            <td>
              <v-btn outlined rounded x-small
                color="anchor1Color"
                class="button-with-icon text-none"
                @click="deleteCard(card)">
                <span>Delete</span>
              </v-btn>
              <v-btn outlined rounded x-small
                color="mainColor"
                class="button-with-icon text-none ml-2"
                :disabled="card.preferred"
                @click="makeItDefaultCard(card)">
                <span>Set as primary</span>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="further-explain">
        <div class="need-more-reports">Need more reports?</div>
        <div class="explain">After you have added or changed a card you can buy reports or upgrade your account.</div>
      </div>
      <div class="mt-6">
        <v-btn rounded
          color="anchor1Color"
          class="button-with-icon text-none whiteColor--text"
          tag="a"
          @click="goToBuyCreditTab">
          Buy credits
        </v-btn>
      </div>
  </div>
</div>

</template>

<script>
import { doStripeUpdateCard } from '@/utils' 
export default {
  data () {
    return {
      cards: []
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    addNewCard () {
      this.$store.commit('showLoading')
      this.$store.dispatch('createStripeCardAdditionLink')
      .then((response) => {
        if (response.data && response.data.url) {
          window.open(response.data.url, '_self')
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    updateCard (card) {
      this.$store.commit('showLoading')
      doStripeUpdateCard(this.user.email)
        .then((response) => {
          if (response.status && response.status === 'closed') {
            return 1
          } else {
              console.log('updateCard', response)
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
    deleteCard (card) {
      this.$store.commit('showLoading')
      return this.$store.dispatch('deletePaymentSource', { cardId: card.id })
        .then((response) => this.$store.commit('setUser', response.data.user))
        .then(() => this.loadCards())
        .finally(() => this.$store.commit('hideLoading'))
    },
    makeItDefaultCard (card) {
      console.log('testing for making default ', card)
      this.$store.commit('showLoading')
      return this.$store.dispatch('setDefaultPaymentSource', card.id)
        .then(() => this.loadCards())
        .finally(() => this.$store.commit('hideLoading'))
      
    },
    loadCards () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchPaymentSources').then((response) => {
        if (response.data.status == 'SUCCESS') {
          this.cards = response.data.cards
        } else {
          console.log('error fetching cards')
        }
        this.$store.commit('hideLoading')
      })
    },
    goToBuyCreditTab () {
      let refreshKey = parseInt(Math.random()*10000)
      this.$router.push(`/useraccount/${this.user.id}?mainTab=billing-and-payments&subTab=buy-credits&refreshKey=${refreshKey}`)
    }
  },
  beforeMount() {
    this.loadCards()
  },
}
</script>

<style lang="scss" scoped>
.width-limitation {
  max-width: 1000px;
}
.top-section {
  display: flex;
  align-items: end;
  margin-top: 50px;
  margin-bottom: 25px;
}
.title-label {
  font-size: 18px;
  font-weight: bold;
}
.add-card-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--v-anchor1Color-base);
  i {
    font-size: 15px;
    margin-right: 5px;
  }
}


.further-explain {
  margin-top: 20px;
  .need-more-reports {
    font-weight: bold;
  }
  .explain {
    margin-top: 10px;
  }

}
</style>