<template>
<div class="modal" :class="{'is-active': showModal}">
  <div class="modal-background" @click="showModal = false"></div>
  <div class="modal-content" style="min-width: 60vw;">
    <div class="box modal-container" style="overflow: auto;">
      <div class="modal-content-root">
        <div class="is-size-4 mainColor--text">You don't have any card added.</div>
        <div class="mt-5 mainColor--text">
          Please click button below to add card.
        </div>
        <div class="mt-3 d-flex justify-end">
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            @click="gotoManagementCreditCardPage">
            <span>Manage cards</span>
          </v-btn>
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none ml-3"
            @click="showModal = false;">
            <span>Close</span>
          </v-btn>
        </div>
      </div>
    </div>
    <button class="custom-modal-close-btn" aria-label="close" @click="showModal = false;"><i class="material-icons">close</i></button>
  </div>
</div>
</template>

<script>
export default {
  name: 'NoStripeCardModal',
  data () {
    return {
      showModal: false,
    }
  },
  computed: {
    user () {
      return this.$store.state.user;
    }
  },
  methods: {
    showModalAction () {
      this.showModal = true;
    },
    gotoManagementCreditCardPage () {
      this.$router.push(`/useraccount/${this.user.id}?mainTab=billing-and-payments&subTab=credit-cards`)
    }
  }
}
</script>