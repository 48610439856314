<!-- eslint-disable -->
<template>
<div class="wow fadeIn flex-grow-1 rows-with-two-col-for-create-run" data-wow-duration="1s">

    <div class="main-padding left-main-area" v-if="renderComponent">
      <div>
        <div class="report-top-left-icon">
          <template v-if="isExpressReport">
            <i class="material-icons">rocket_launch</i>
            <span>POP Pro</span>
          </template>
          <template v-else>
            <i class="material-icons">display_settings</i>
            <span>POP Custom</span>
          </template>
        </div>
        <i class="is-pulled-right material-icons newpage-close-button" @click="confirmCloseModal=true">close</i>
      </div>

      <template v-if="isOnePageSetup">
        <one-page-pro-step
          v-model="keyword"
          :useSecondaryKeywords="useSecondaryKeywords"
          :secondaryKeywordsArray="secondaryKeywords"
          @toggleUseSecondaryKeywords="() => useSecondaryKeywords = !useSecondaryKeywords"
          @secondarykeywordschange="(s_kwds) => secondaryKeywords = s_kwds"

          :targetLang="targetLang"
          :googleSearchLocations="googleSearchLocations"

          :targetLanguages="targetLanguages"
          @languagechange="(l) => targetLang = l"

          :ignoreSpaces="ignoreSpaces"
          @ignorespaceschange="(ignore) => ignoreSpaces = ignore"

          :location="proxyLocation"
          @locationchange="(l) => proxyLocation = l"

          :willUseGoogleNlp="willUseGoogleNlp"
          @usegooglenlpchange="(usenlp) => willUseGoogleNlp = usenlp"

          :willUseEatAnalysis="willUseEatAnalysis"
          @useeatanalysischange="(useeat) => willUseEatAnalysis = useeat"

          :willUseNextGenEat="willUseNextGenEatAnalysis"
          @usenextgeneatanalysischange="(useeat) => willUseNextGenEatAnalysis = useeat"

          :isExpressReport="isExpressReport"
          
          :url="targetUrl"
          @urlchange="(url) => targetUrl = url"
          
          :switchedSiteProtocol="switchedSiteProtocol"
          @protocolchange="(p) => switchedSiteProtocol = p"
          
          :pageNotBuilt="pageNotBuilt"
          @pagenotbuiltchange="(notbuilt) => updateRunBuiltState(!notbuilt)"

          :useKeywordPrecisionSearch="useKeywordPrecisionSearch"
          @usekeywordprecisionsearchchange="(p) => useKeywordPrecisionSearch = p"
          
          :name="name"
          @pagenamechange="(n) => name = n"
          
          :submittingRun="submittingRun"
          
          :isTargetUrlSourceDirectly="isTargetUrlSourceDirectly"
          @targetsourcedchange="(d) => isTargetUrlSourceDirectly = d"
          
          :targetUrlSourceCode="targetUrlSourceCode"
          @targetsourcecodechange="(c) => targetUrlSourceCode = c"
          
          :advanceable="isReadyForSubmitInOnePageSetup()"
          @gonextpage="submitForOnePageSetup"
          :cleanBaseUrl="cleanBaseUrl"
          :disablePathInput="disabledPagePathInput()"

          :isAgencyUnlimitedUser="isAgencyUnlimitedUser"

          :isRerunPage="true"
          ></one-page-pro-step>
      </template>
      <template v-else>
        <add-keyword
          v-show="currentPage==PageRunPages.ADD_KEYWORD"
          v-model="keyword" 
          :useSecondaryKeywords="useSecondaryKeywords"
          :secondaryKeywordsArray="secondaryKeywords"
          :advanceable="isReadyInPage(PageRunPages.ADD_KEYWORD)"
          @toggleUseSecondaryKeywords="() => useSecondaryKeywords = !useSecondaryKeywords"
          @secondarykeywordschange="(s_kwds) => secondaryKeywords = s_kwds"
          @nextpage="goNextPage"
          :errorMsg="errorMsg"
          :url="targetUrl"
          @urlchange="(url) => targetUrl = url"
          
          :switchedSiteProtocol="switchedSiteProtocol"
          @protocolchange="(p) => switchedSiteProtocol = p"
          
          :pageNotBuilt="pageNotBuilt"
          @pagenotbuiltchange="(notbuilt) => updateRunBuiltState(!notbuilt)"
          

          :useKeywordPrecisionSearch="useKeywordPrecisionSearch"
          @usekeywordprecisionsearchchange="(p) => useKeywordPrecisionSearch = p"
          
          :name="name"
          @pagenamechange="(n) => name = n"
          
          :submittingRun="submittingRun"
          
          :isTargetUrlSourceDirectly="isTargetUrlSourceDirectly"
          @targetsourcedchange="(d) => isTargetUrlSourceDirectly = d"
          
          :targetUrlSourceCode="targetUrlSourceCode"
          @targetsourcecodechange="(c) => targetUrlSourceCode = c"
          
          :cleanBaseUrl="cleanBaseUrl"
          :disablePathInput="disabledPagePathInput()"
          :isExpressReport="isExpressReport"
          :isRerunPage="true"></add-keyword>

        <locale-selection
          v-if="currentPage==PageRunPages.CHOOSE_LANGUAGE_AND_REGION"
          :targetLang="targetLang"
          :googleSearchLocations="googleSearchLocations"
          :location="proxyLocation"
          @locationchange="(l) => proxyLocation = l"
          
          :targetLanguages="targetLanguages"
          @languagechange="(l) => targetLang = l"
          
          :ignoreSpaces="ignoreSpaces"
          @ignorespaceschange="(ignore) => ignoreSpaces = ignore"

          :useKeywordPrecisionSearch="useKeywordPrecisionSearch"
          @usekeywordprecisionsearchchange="(p) => useKeywordPrecisionSearch = p"
          
          :willUseGoogleNlp="willUseGoogleNlp"
          @usegooglenlpchange="(usenlp) => willUseGoogleNlp = usenlp"
          
          :willUseEatAnalysis="willUseEatAnalysis"
          @useeatanalysischange="(useeat) => willUseEatAnalysis = useeat"

          :willUseNextGenEat="willUseNextGenEatAnalysis"
          @usenextgeneatanalysischange="(useeat) => willUseNextGenEatAnalysis = useeat"
          
          :isRememberLangAndProxy="isRememberLangAndProxy"
          @langandproxychange="(remember) => isRememberLangAndProxy = remember"
          
          :googleSearchResults="googleSearchResults"
          :isAvailableChromeExtension="isAvailableChromeExtension"
          :stepRerunnable="cleanText(keyword)==pageRun.keyword && originRunProxyLocation==proxyLocation"
          :advanceable="isReadyInPage(PageRunPages.CHOOSE_LANGUAGE_AND_REGION)"
          :isAgencyUnlimitedUser="isAgencyUnlimitedUser"
          @goprevpage="goPrevPage"
          @gonextpage="goNextPage"
          @skipsteprerun="goNextPage(jumpToStep=PageRunPages.METHOD_OF_COMPETITOR_SELECTION)"
          :isExpressReport="isExpressReport"
          :errorMsg="errorMsg"></locale-selection>

        <variations
          v-if="currentPage==PageRunPages.VARIATIONS"
          :keyword="keyword"
          :variations="variations"
          :secondaryKeywords="cleanedSecondaryKeywords"
          @variationschange="(vars) => $store.commit('setVariations', vars)"
          
          :runWithoutVariation="runWithoutVariation"
          @runvariationschange="(noVars) => runWithoutVariation = noVars"
          
          :isExpressReport="isExpressReport"
          :advanceable="isReadyInPage(PageRunPages.VARIATIONS)"
          @goprevpage="goPrevPage"
          @gonextpage="goNextPage"
          :errorMsg="errorMsg"></variations>
        
        <competitors-methodology
          v-show="currentPage==PageRunPages.METHOD_OF_COMPETITOR_SELECTION"
          :isUserWillPickCompetitors="isUserWillPickCompetitors"
          @userpickscompetitorschange="(c) => isUserWillPickCompetitors = c"
          :advanceable="isReadyInPage(PageRunPages.METHOD_OF_COMPETITOR_SELECTION)"
          @goprevpage="goPrevPage"
          @gonextpage="goNextPage"
          :errorMsg="errorMsg"></competitors-methodology>

        <add-competitors
          v-if="currentPage==PageRunPages.ADD_COMPETITORS"
          :competitorUrls="competitorUrls"
          @competitorurlschange="(urls) => competitorUrls = urls"
          :googleSearchResults="googleSearchResults"
          @setErrorMsg="(msg) => {console.log(msg)}"
          
          :competitorUrlsArray="competitorUrlsArray"
          :validatedCompetitorUrls="validatedCompetitorUrls"
          :submittingRun="submittingRun"
          :submittingValidation="submittingValidation"
          :activeManualCompetitorSource="activeManualCompetitorSource"
          :manualCopySourceCompetitorUrls="manualCopySourceCompetitorUrls"
          :competitorsUrlValidationError="competitorsUrlValidationError"
          :invalidCompetitorUrls="invalidCompetitorUrls"
          :competitorUrlsMissingHttpError="competitorUrlsMissingHttpError"
          :missingHttpCompetitorUrls="missingHttpCompetitorUrls"
          :missingPastingManualCompetitorUrlSources="missingPastingManualCompetitorUrlSources"
          :manualCompetitorUrlsSources="manualCompetitorUrlsSources"
          
          :advanceable="isReadyInPage(PageRunPages.ADD_COMPETITORS)"
          :isAlreadyDoneValidatingUrl="isAlreadyDoneValidatingUrl"
          
          @showgoogleresults="onShowGoogleResults"
          @togglecompetitorsourcearea="toggleDisplayActiveCompetitorSourceArea"
          @toggleurlinmanuallist="toggleUrlIsInManualCopySourceList"
          @changemanualcompetitorsource="changedManualCompetitorUrlSource"
          @goprevpage="goPrevPage"
          @gonextpage="goNextPage"
          :errorMsg="errorMsg">
        </add-competitors>

        <focus-group
          v-show="currentPage==PageRunPages.SELECT_FOCUS_GROUP"
          :validatedCompetitorUrls="validatedCompetitorUrls"
          :submittingRun="submittingRun"
          :disabled="false"
          :advanceable="true"
          :focusCompetitorUrls="focusCompetitorUrls"
          @focuscompetitorurlschange="(urls) => focusCompetitorUrls = urls"
          @gonextpage="goNextPage"
          @goprevpage="goPrevPage"
          :errorMsg="errorMsg">
        </focus-group>

        <lsa
          v-if="currentPage==PageRunPages.LSA"
          :keyword="keyword"
          :variations="variations"
          
          :phrases="lsaPhrases"
          @phraseschange="(phrases) => lsaPhrases = phrases"
          :removedLSITermsByUser="removedLSITermsByUser"
          @removedLSITermsByUserchange="(terms) => removedLSITermsByUser = terms"
          
          :runWithoutPhrases="runWithoutPhrases"
          @runwithoutphrasesschange="(noPhrases) => runWithoutPhrases = noPhrases"
          
          :isExpressReport="isExpressReport"
          :advanceable="true"
          :submittingRun="submittingRun"
          @submitform="submitForm"
          @goprevpage="goPrevPage"
          :errorMsg="errorMsg"></lsa>
        <div
          v-if="currentPage==PageRunPages.SUBMITTING_PAGE_RUN"
          class="mainColor--text is-size-4 mt40">
          We have the competitor info, please wait a few seconds and we'll start processing it...
        </div>


        <!-- <p class='has-text-centered profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</p> -->
      </template>
    </div>

    <steps-summary
      :isOnePageSetup="isOnePageSetup"
      :site="site"
      :keyword="keyword"
      :useSecondaryKeywords="useSecondaryKeywords"
      :secondaryKeywords="cleanedSecondaryKeywords"
      :baseUrl="baseUrl"
      :errorMsg="errorMsg"
      :isGoogleSearchDoneWithExtension="isGoogleSearchDoneWithExtension"
      :proxyLocation="proxyLocation"
      :targetUrl="targetUrl"
      :targetLang="targetLang"
      :pageNotBuilt="pageNotBuilt"
      :cleanBaseUrl="cleanBaseUrl"
      :isExpressReport="isExpressReport"
      :competitorUrlsArray="competitorUrlsArray"
      :validatedCompetitorUrls="validatedCompetitorUrls"
      :invalidCompetitorUrls="invalidCompetitorUrls"
      :focusCompetitorUrls="focusCompetitorUrls"
      :currentPage="currentPage"
      :runWithoutVariation="runWithoutVariation"
      :variations="variations"
      :lsaPhrases="lsaPhrases"
      :runWithoutLsaPhrases="runWithoutPhrases"></steps-summary>

  
  <!-- only for non pro run case modals -->
  <template v-if="!isOnePageSetup">
  
    <div class="modal" :class="{'is-active': submittingGoogleSearch}">
      <div class="modal-background"></div>
      <div class="modal-content report-progress-modal-width">
        <div class="box modal-container d-flex flex-column justify-center">
          <div>
            <div class="d-flex justify-center">
              <v-progress-circular
                :rotate="-90"
                :size="160"
                :width="4"
                :value="googleSearchProgress"
                color="green15Color"
              >
                <img src="/static/pop_green_circle_logo.png" class="logo-img" />
              </v-progress-circular>
            </div>
            <div class="just-moment">
              Just a moment, we're getting<br/>
              your competitors
            </div>
            <div class="sub-explain">
              <template v-if="isGoogleSearchDoneWithExtension">
                When using the POP Chrome Extension, you will notice tabs opening in your Chrome window.
                The extension is using your current Chrome settings.
                The tool will close those windows for you so do not touch them while the tool is running.
              </template>
            </div>
            <!-- <div class="action-area">
              <template v-if="!isGoogleSearchDoneWithExtension">
                <span v-if="isCancellingProgress" class="is-italic is-info">Cancelling...</span>
                <v-btn rounded
                  color="profileAnchorColor"
                  class="button-with-icon whiteColor--text text-none" :disabled="currentTaskId == -1" @click.stop="cancelGoogleSearch">
                  Cancel
                </v-btn>
              </template>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'is-active': showTooManyCompetitorsModal}">
      <div class="modal-background" @click="showTooManyCompetitorsModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <div class="subtitle-font mainColor--text text-center">
              You may only have up to {{ MAX_COMPETITORS }} competitors. <br/>
              Please reduce your competitors to {{ MAX_COMPETITORS }} or fewer and submit again.
            </div>
            <div class="text-right">
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none" @click="showTooManyCompetitorsModal = false">Ok</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal google-result-modal" :class="{'is-active': showGoogleResultsModal && googleSearchResults.length && !submittingGoogleSearch}">
      <div class="modal-background" @click.stop="$store.commit('setShowGoogleResultsModal', false)"></div>
      <div class="modal-content" style="min-width: 70vw !important;">
        <div class="box modal-container flex" style="overflow: hidden;">
          <div class="flex flex-column">
            <div class="top-part">
              <img src="/static/google-icon.png" style="width: 25px; height: auto;" />
              <span class="is-size-4 font-weight-bold">Google Search Results</span>
              <div class="how-many-comp-selected-bar" :class="{'active': combinedSelectedCompetitors.length >= MAX_COMPETITORS}">
                <i class="material-icons">ads_click</i>
                <span >{{ combinedSelectedCompetitors.length }} of {{ MAX_COMPETITORS }} maximum competitors selected</span>
                <span class="badge">{{ combinedSelectedCompetitors.length }}/{{ MAX_COMPETITORS }} </span>
              </div>
            </div>
            <div class="mt-4">
              Some results might be slightly out of order from a true local search. That's ok. If you see results that are not accurate for your location we recommend using the POP Chrome Extension.
              If there are competitors that you want to add that aren't in this list, you can copy and paste them into POP on the next screen.
            </div>
            <div class="must-select-3 mt-7 mb-7">
              <i class="material-icons">info_outline</i>
              <span>You must select at least 3 competitors. You can open this window again after closing it.</span>
            </div>
            <div class="google-result-modal-list flex-grow-1">
              <div class="mb20 google-result-list-item" v-for="(page, i) in googleSearchResults" :key="`search-result-${i}`">
                <div>
                  <div>
                    <label :for="`search-result-${i}`" class="checkbox checkbox-container">
                      <a>{{ i + 1 }}</a>
                      <input
                        v-model="selectedGooglePages"
                        :id="`search-result-${i}`"
                        type="checkbox"
                        :value="page"
                        :disabled="isGoogleSelectionDisabled(page)"
                        >
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div>
                    <div class="subtitle-font mb-2">
                      <a :href="page.url" target="_blank" class="font-weight-bold">{{ _.startCase(page.title) }}</a>
                    </div>
                    <div class="mb-2">
                      <a :href="page.url" target="_blank" class="font-weight-bold link15Color--text word-break-all">{{ page.url }}</a>
                    </div>
                    <div v-html="page.description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 text-right">
              <v-btn rounded small
                color="profileAnchorColor"
                class="button-with-icon text-none whiteColor--text" @click.stop="useGoogleSearchResults">
                <span>Use selected competitors</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click.stop="$store.commit('setShowGoogleResultsModal', false)"><i class="material-icons">close</i></button>
      </div>
    </div>

    <div class="modal" :class="{'is-active': submittingValidation}">
      <div class="modal-background"></div>
      <div class="modal-content report-progress-modal-width">
        <div class="box modal-container d-flex flex-column justify-center">
          <div>
            <div class="d-flex justify-center">
              <v-progress-circular
                :rotate="-90"
                :size="125"
                :width="4"
                :value="$store.state.validateUrlsProgress"
                color="green15Color"
              >
                <img src="/static/pop_green_circle_logo.png" class="logo-img" />
              </v-progress-circular>
            </div>
            <div class="just-moment">
              <template v-if="isFetchingSourceForTargetUrl">
                We're visiting your page.
              </template>
              <template v-else>
                Checking out the competition. <br/>
                This process may take up to 3 minutes.
              </template>
              <div>
                <div class="mt-5">
                  While you're waiting...
                </div>
                <VueSlickCarousel v-if="selected3Tips.length > 0"
                  v-bind="carouselSetting" class="mt-5 carousel">
                  <div class="seo-tip-carousel-item" v-for="(item, idx) in selected3Tips" :key="idx">
                    <b>TIP: </b>{{ item }}
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
            <div class="sub-explain">
            </div>
            <div class="action-area">
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'is-active': submittingFetchLsaProcess && currentPage == PageRunPages.LSA}">
      <div class="modal-background"></div>
      <div class="modal-content report-progress-modal-width">
        <div class="box modal-container">
          <p class="subtitle">
            We are getting your LSI terms, please wait just a moment.
          </p>
        </div>
      </div>
    </div>
  </template>
  <template v-if="isOnePageSetup">
    <div class="modal" :class="{'is-active': showModalForProReportSteps }">
      <div class="modal-background"></div>
      <div class="modal-content report-progress-modal-width">
        <div class="box modal-container d-flex flex-column justify-center" style="min-height: 350px;">
          <div v-if="failedAutoPickCompetitors" class="pad25">
            <div class="subtitle">
              No direct SEO competitors found in the SERPs. You can now use the custom run feature to manually add your chosen competitors' URLs for targeted analysis.
            </div>
            <div class="mt-5 text-right">
              <v-btn rounded
                color="grayColor"
                class="button-with-icon gray2Color--text text-none mr-1"
                @click="() => $router.go(-1)"
              >
                <span>Cancel</span>
              </v-btn>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                @click="switchToCustomRun"
              >
                <span>Switch to custom run</span>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <div class="d-flex justify-center">
              <v-progress-circular
                :rotate="-90"
                :size="125"
                :width="4"
                :value="proProgressValue"
                color="green15Color"
              >
                <img src="/static/pop_green_circle_logo.png" class="logo-img" />
              </v-progress-circular>
            </div>
            <template v-if="submittingGoogleSearch">
              <div class="just-moment">
                Just a moment, we're getting<br/>
                your competitors
              </div>
              <div class="sub-explain">
                <template v-if="isGoogleSearchDoneWithExtension">
                  When using the POP Chrome Extension, you will notice tabs opening in your Chrome window.
                  The extension is using your current Chrome settings.
                  The tool will close those windows for you so do not touch them while the tool is running.
                </template>
              </div>
              <!-- <div class="action-area">
                <template v-if="!isGoogleSearchDoneWithExtension">
                  <span v-if="isCancellingProgress" class="is-italic is-info">Cancelling...</span>
                  <v-btn rounded
                    color="profileAnchorColor"
                    class="button-with-icon whiteColor--text text-none" :disabled="currentTaskId == -1" @click.stop="cancelGoogleSearch">
                    Cancel
                  </v-btn>
                </template>
              </div> -->
            </template>
            <template v-else-if="submittingValidation">
              <div class="just-moment">
                <template v-if="isFetchingSourceForTargetUrl">
                  We're visiting your page.
                </template>
                <template v-else>
                  Checking out the competition. <br/>
                  This process may take up to 3 minutes.
                </template>
                <div>
                  <div class="mt-5">
                    While you're waiting...
                  </div>
                  <VueSlickCarousel v-if="selected3Tips.length > 0"
                    v-bind="carouselSetting" class="mt-5 carousel">
                    <div class="seo-tip-carousel-item" v-for="(item, idx) in selected3Tips" :key="idx">
                      <b>TIP: </b>{{ item }}
                    </div>
                  </VueSlickCarousel>
                </div>
              </div>
              <div class="sub-explain">
              </div>
              <div class="action-area">
              </div>
            </template>
            <template v-else-if="submittingFetchLsaProcess">
              <div class="just-moment">
                Getting your important terms
              </div>
            </template>
            <template v-else>
              <div class="just-moment">
                Just a moment, we are creating the report in your dashboard now.  You will be redirected to the Pageruns page.
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>

  <div class="modal" :class="{'is-active': showNoAutoVariationsModal}">
    <div class="modal-background" @click="showNoAutoVariationsModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div class="title-font is-size-4">Unfortunately the tool wasn't able to find any variations for your keyword.</div>
          <div class="subtitle-font mt-5">
            <p class="mb10">Check to see if there are any bolded terms in your search engine results descriptions that the tool may have missed.</p>
            <p class="mb10">If there are, manually add them into the tool. If there simply aren't any variations for your term, we recommend manually adding in singulars, plurals, appropriate stemming -ing, -ed, extremely close synonyms and single words that comprise your keyword phrase.</p>
            <p class="mb10">For example, if your keyword is Dallas lawyers, we would recommend manually adding in the following: <b>Dallas, lawyers, lawyer, attorney, attorneys</b></p>
            <p>If your keyword is Dallas plumbers we would recommend manually adding: <b>Dallas, plumbers, plumber, plumbing</b></p>
          </div>
          <div class="control d-flex mt-5">
            <div class="flex-grow-1">
              If there aren't any variations to manually add for your keyword you can run the report without variations by checking the box underneath the variations drop down.
            </div>
            <button class="button is-large ready-button" @click="showNoAutoVariationsModal = false">
              I got it &nbsp;&nbsp;
              <i class="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showNoAutoVariationsModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>
  

  <div class="modal" :class="{'is-active': showConfirmChangeUrlOnReRun}">
    <div class="modal-background"></div>
    <div class="modal-content" style="min-width: 70vw !important;">
      <div class="box modal-container" style="overflow: auto;">
        <div class="modal-content-root">
          <p class="title is-size-4 font-weight-bold mainColor--text">
            <template v-if="showRunBuiltPageForReport">
              Choose how you want to re-run your page
            </template>
            <template v-else>
              Please choose one option for the re-run:
            </template>
          </p>

          <div v-if="showRunBuiltPageForReport" class="mb-3 font-weight-bold">
            Please choose one option for the re-run and add your URL following the instructions.
          </div>
          
          <div class="rerun-type-box" :class="{ 'two-col': showRunBuiltPageForReport }">
            <!-- <button class="button ready-button" @click="setRerunType(rerunType)">Start Re-run</button> -->
            <div class="box-item" v-if="!showRunBuiltPageForReport">
              <b class="box-title">Update Optimization Score</b>
              <p class="mt-3 flex-grow-1">
                You have made changes to your page and want to re-run for an updated optimization score.
                <br /><br />
                Your original list of NLP, Variations, and LSI will be used. If you would like to re-run to find potential new terms, please select "Make Changes to Your Setup.
              </p>
              <div
                class="custom-btn text-none"
                @click="setRerunType(0)"
                >
                <i class="material-icons">autorenew</i>
                <span class="font-weight-bold">Start re-run</span>
                <i class="material-icons">arrow_right_alt</i>        
              </div>
              <div class="text-right font-weight-bold mainColor--text">
                {{ (isUnlimitedUser || selectedSubAccountRelation) ? '0 POP credit' : '1 POP credit' }}
              </div>
              <!-- @click="rerunType = 0 -->
            </div>
            <div class="box-item">
              <b class="box-title">Make Changes to your Setup</b>
              <p class="mt-3 flex-grow-1">
                <template v-if="showRunBuiltPageForReport">
                  Add your URL and if you want, you can change keyword, region or check for changes in your competitors list from Google.
                </template>
                <template v-else>
                  You want to check for changes in your competitors list from Google or you want to change setup.
                </template>
                <br /><br />
                EEAT and Google's NLP are not automatically selected and rerun. If you wish to rerun those features, please check the appropriate boxes. We will rerun Variations, LSI, and POP's NLP terms.
              </p>
              <!-- <p class="mt-3" v-if="isExpressReport">You want to make changes to your initial set up.  For example, you may want to change the location or or add secondary keywords.  This option would also be used in the situation where you didn't have a live URL for your page and now you do.</p>
              <p class="mt-3" v-else>You want to check for any changes in your competitor list from Google or you want to make changes to your set up (i.e. change the target URL, Keyword, Competitor List, Variations, or LSI).</p> -->
              <div
                class="custom-btn text-none"
                @click="setRerunType(1)"
                >
                <i class="material-icons">autorenew</i>
                <span class="font-weight-bold">Start re-run</span>
                <i class="material-icons">arrow_right_alt</i>        
              </div>
              <div class="text-right font-weight-bold mainColor--text">
                {{ (isUnlimitedUser || selectedSubAccountRelation) ? '0 POP credit' : '2 POP credits' }}
              </div>
              <!-- @click="rerunType = 1" -->
            </div>
            <div class="box-item">
              <b class="box-title">Switch Setups</b>
              <p class="mt-3 flex-grow-1">
                <template v-if="showRunBuiltPageForReport">
                  Add your URL and if you want, you can re-run this report in a different mode and go from POP Pro to POP Custom (or vice versa).
                </template>
                <template v-else>
                  You want to re-run the report in a different mode and go from POP Pro to POP Custom (or vice versa).
                </template>
                <br /><br />
                EEAT and Google's NLP are not automatically selected and rerun. If you wish to rerun those features, please check the appropriate boxes. We will rerun Variations, LSI, and POP's NLP terms.
              </p>
              <div
                class="custom-btn text-none"
                @click="setRerunType(2)"
                >
                <i class="material-icons">autorenew</i>
                <span class="font-weight-bold">Start re-run</span>
                <i class="material-icons">arrow_right_alt</i>        
              </div>
              <div class="text-right font-weight-bold mainColor--text">
                {{ (isUnlimitedUser || selectedSubAccountRelation) ? '0 POP credit' : '2 POP credits' }}
              </div>
              <!-- <article class="message" @click="rerunType = 2"> -->
            </div>
          </div>
          <div class="tip-box mt20">
            <div class="image-area">
              <img src="/static/q-a-icon.png" />
            </div>
            <div class="tip-box-main">
              <div class="top-part">
                <div class="left-area">
                  <span class="tip-label">POP tips</span>
                </div>
                <div class="right-area">
                  <i class="material-icons"
                    :class="{ 'inactive' : currentTip == 1 }"
                    @click="() => {
                      currentTip = currentTip > 1 ? currentTip - 1 : 1;
                    }">arrow_circle_left</i>
                  <i class="material-icons ml10"
                    :class="{ 'inactive' : currentTip == POP_TIPS.length }"
                    @click="() => {
                      currentTip = currentTip < POP_TIPS.length ? currentTip + 1 : POP_TIPS.length;
                    }">arrow_circle_right</i>
                </div>
              </div>
              <div class="explain" v-html="POP_TIPS[currentTip-1]">
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="$router.go(-1)"><i class="material-icons">close</i></button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showErrorReasonModal}">
    <div class="modal-background" @click="showErrorReasonModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <p class="title">Error Details</p>
        <div class="subtitle">
          <p class="has-text-centered" style="color: red;" v-if="errorMsg">{{ errorMsg }}</p>
          <div class="has-text-centered" style="color: red;" v-if="validationAlerts.length">
            <ul>
              <li v-for="(issue, idx) in validationAlerts" :key="`validationIssue${idx}`">* {{ issue }}</li>
            </ul>
          </div>

          <div v-if="competitorsUrlValidationError">
            <div style="color: red;">{{ this.competitorsUrlValidationError }}</div>
            <div v-for="(invalidUrl, idx) in invalidCompetitorUrls" :key="idx" style="color: red;">{{ invalidUrl }}</div>
            <div class="has-text-link">
              <br/>
              There are many reasons that a URL can't be crawl.
              The most common reason is that the URL's hosting figured out that we are a bot.
              <br/>
              Do not worry though, we have a couple of work arounds.
              You can either remove the URL(s) and run your page report without them, or you can copy and paste in the URL(s)' source code directly into POP.
            </div>
          </div>
        </div>
        <div class="field has-addons">
          <div class="control">
            <button class="button is-warning" @click="showErrorReasonModal = false">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': confirmCloseModal}">
    <div class="modal-background" @click="confirmCloseModal = false"></div>
    <div class="modal-content" style="max-width: 650px;">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="title-font is-size-4 mainColor--text mb20">Do you want to leave this page?</div>
          <div class="subtitle-font mainColor--text">
            You will lose any of the information that you have entered.
          </div>
          <div class="control d-flex mt-5">
            <div class="flex-grow-1">
            </div>
            <v-btn 
              rounded outlined
              color="mainColor"
              class="button-with-icon text-none" @click="confirmCloseModal = false;">
              No
            </v-btn>
            <v-btn
              rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click="confirmCloseModal = false; $router.go(-1);">
              Yes
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="confirmCloseModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

  <missing-extension-modal
    :showModal="showMissingChromeExtensionModal"
    @closemodal="showMissingChromeExtensionModal = false"
    @changelocation="goNextPage(jumpToStep=PageRunPages.CHOOSE_LANGUAGE_AND_REGION)"></missing-extension-modal>
  
  <same-url-used-in-24-hrs-modal
    ref="sameUrlUsedModal"
    @cancelAction="sameUrlCancelAfterAction"
    @okAction="() => { rerunType == 0 ? validateInputUrls() : validateTargetUrl() }"
    ></same-url-used-in-24-hrs-modal>

</div>
</template>

<script>
import _ from 'lodash';

import VueSlickCarousel from 'vue-slick-carousel'
import { mixin } from '@/components/page_analysis_mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import AddKeyword from '@/components/PageRun/Steps/AddKeyword.vue'
import LocaleSelection from '@/components/PageRun/Steps/LocaleSelection.vue'
import Variations from '@/components/PageRun/Steps/Variations.vue'
import CompetitorsMethodology from '@/components/PageRun/Steps/CompetitorsMethodology.vue'
import AddCompetitors from '@/components/PageRun/Steps/AddCompetitors.vue'
import FocusGroup from '@/components/PageRun/Steps/FocusGroup.vue'
import Lsa from '@/components/PageRun/Steps/Lsa.vue'
import StepsSummary from '@/components/PageRun/Steps/StepsSummary.vue'
import OnePageProStep from '@/components/PageRun/Steps/OnePageProStep.vue'
import MissingExtensionModal from '@/components/PageRun/Steps/MissingExtensionModal.vue'
import SameUrlUsedIn24HrsModal from '@/components/PageRun/SameUrlUsedIn24HrsModal.vue'

import { isInSameDomain, getPathNameFromUrl } from '@/utils'

export default {
  components: {
    VueSlickCarousel,
    AddKeyword, LocaleSelection,
    Variations, CompetitorsMethodology, AddCompetitors,
    FocusGroup, StepsSummary, OnePageProStep,
    MissingExtensionModal, Lsa, SameUrlUsedIn24HrsModal
  },
  mixins: [mixin, ServiceMixin],
  data () {
    return {
      renderComponent: true,

      name: '',
      targetUrl: '',
      note: '',
      variationsToAdd: '',
      fetchingVariations: false,
      invalidUrls: [],
      submittingRun: false,
      originRunProxyLocation: '',
      proxyLocation: '',
      targetLang: '',

      ignoreSpaces: false,
      runBuiltPage: false,
      useKeywordPrecisionSearch: false,
      subDirectory: '',
      willUseGoogleNlp: false,
      willUseEatAnalysis: false,
      willUseNextGenEatAnalysis: false,

      isTargetUrlSourceDirectly: false,
      targetUrlSourceCode: '',

      showManualCopyPasteArea: false,
      activeManualCompetitorSource: '', // this indicate the competitor url that can copy & paste for now. expanded manual copy pane
      manualCopySourceCompetitorUrls: [], // collection of teh manual copy source competitor urls.
      manualCompetitorUrlsSources: {},
      isEditableUrlAndKeyword: true,

      ipAddress: '',

      showConfirmClearManualSourceCodeModal: false,
      showConfirmChangeUrlOnReRun: false,
      showErrorReasonModal: false,

      isRememberLangAndProxy: true,

      isContentEditorUsed: false,
      
      rerunType: 0,



      currentTip: 1,
      POP_TIPS: [
        'Re-runs count as <b>report credits</b>.  To avoid using credits, you can use the POP Chrome Extension to check your important term counts and optimization scores in real time in your website\'s editor or in a Google Doc.  Using the POP Chrome Extension to check counts and scores <b>does not use your monthly credits.</b>',
        'Please note that any changes to your setup will likely result in changes to your page score and recommendations',
        'NLP and EEAT do not automatically re-run.  If you would like to recheck those dashboards, please select "Make Changes" and then check the boxes for NLP and EEAT in the PageRun setup.',
      ]
    }
  },
  watch: {
    'targetUrl': function(newUrl) {
      let targetUrl = newUrl.trim()
      if (isInSameDomain(targetUrl, this.cleanBaseUrl)) {
        targetUrl = getPathNameFromUrl(targetUrl)
      }
      targetUrl = targetUrl.replace(/\s/g, ''); // remove all spaces from string
      if (targetUrl == '/') { targetUrl = '' }
      if (this.targetUrl == targetUrl) {
        return;
      }
      this.targetUrl = targetUrl
      this.urlsValidated = false
    },
    'keyword': function(newVal, oldVal) {
      if (oldVal != '' && newVal != oldVal) {
        this.$store.commit('setVariations', [])
      }
    },
    'submittingRun': function (newVal, oldVal) {
      if (newVal) {
        this.showModalForProReportSteps = false;
      }
    }
  },
  computed: {
    _ () {
      return _
    },
    targetLanguages () {
      return this.$store.state.targetLanguages
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isAvailableGoogleNlp () {
      return (this.selectedSubAccountRelation || this.isAgencyUnlimitedUser)
    },
    remainedGoogleNlpUsage () {
      if (this.selectedSubAccountRelation) {
        return this.selectedSubAccountRelation.remainedGoogleNlpUsage
      } else {
        return this.$store.state.user.remainedGoogleNlpUsage
      }
    },
    validForm () {
      return !!this.keyword && !!this.competitorUrls && (this.isGoogleSearchDoneWithExtension || this.proxyLocation )
    },
    page () {
      if (!this.site || !this.site.pages.length) {
        return {}
      }
      return this.site.pages.find((p) => p.id === parseInt(this.$route.params.pageId))
    },
    pageRun () {
      if (!this.page) {
        return {}
      }
      const pageRunId = parseInt(this.$route.params.pageRunId)
      const pageRun = this.$store.state.pageRuns.find((r) => r.id === pageRunId)
      return pageRun
    },
    availableReportCreditsOfUserOrTeam () {
      return this.$store.getters.availableReportCreditsOfUserOrTeam
    },
    canCreateNewPage () {
      if (this.availableReportCreditsOfUserOrTeam >= 2) {
        return true;
      }
      return false;
    },
    canCreateRerun () {
      if (this.availableReportCreditsOfUserOrTeam >= 1) {
        return true;
      }
      return false;
    },
    showRunBuiltPageForReport () {
      if (!this.pageRun.pageNotBuilt) {
        return false;
      }
      if (this.$route.query.runBuiltPage && this.$route.query.runBuiltPage == 'true') {
        return true;
      }
      return false;
    },
    pageNotBuilt () {
      if (this.runBuiltPage) {
        return 0
      }
      // if (!this.pageRun.pageNotBuilt) { // we don't have to consider pagenot built state from the pagerun. it will be reflected to runBuiltPage variable
      //   return 0
      // }
      return 1
    },
    validationAlerts () {
      return this.validationIssues?.filter(issue => {
        return !issue.includes(this.badCompetitorUrlMsg)
      })
    },
    competitorUrlsMissingHttpError () {
      let found = this.validationIssues.find((element) => {
        return element.includes(this.missingHttpInCompetitorUrlsMsg)
      });
      return found;
    },
    competitorsUrlValidationError () {
      let found = this.validationIssues.find((element) => {
        return element.includes(this.badCompetitorUrlMsg)
      });
      return found;
    },
    missingPastingManualCompetitorUrlSources () {
      if (this.manualCopySourceCompetitorUrls.length === 0) {
        return false
      }
      let resultStr = '';
      Object.keys(this.manualCompetitorUrlsSources).forEach(key => {
        if ( this.manualCompetitorUrlsSources[`${key}`] && this.manualCompetitorUrlsSources[`${key}`].trim() == '') {
          console.log('missing::::', key, this.manualCompetitorUrlsSources[`${key}`].trim())
          resultStr += key + '<br/>'
        }
      });
      if (resultStr == '') return false // This means filled all manual source codes
      return 'You selected to manually copy and paste this URL.  Please follow the instructions to copy and paste the URL\'s source code or unselect manually copy and paste.<br/>' + resultStr
    },
    googleSearchLocations () {
      return this.$store.state.googleSearchLocations ? this.$store.state.googleSearchLocations : []
    }
  },
  methods: {
    async forceRender () {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    updateRunBuiltState (newVal) {
      console.log('update run built state function call run built page', newVal);
      this.runBuiltPage = newVal
      try {
        let notBuiltPageCheckbox = document.querySelector('#run-not-built-page')
        let runBuiltPageCheckbox = document.querySelector('#run-built-page')
        if (this.runBuiltPage) {
          if (notBuiltPageCheckbox) notBuiltPageCheckbox.checked = false
          if (runBuiltPageCheckbox) runBuiltPageCheckbox.checked = true
        } else {
          if (notBuiltPageCheckbox) notBuiltPageCheckbox.checked = true
          if (runBuiltPageCheckbox) runBuiltPageCheckbox.checked = false
        }
      } catch (e) {
        console.log('error to update checkbox state', e)
      }
    },
    clearManualCopySourceCodes () {
      this.manualCopySourceCompetitorUrls = []
      this.manualCompetitorUrlsSources = {}
      let manualCopyCheckboxes = document.querySelectorAll('.manual_copy_source_checkbox')
      manualCopyCheckboxes.forEach(checkbox => {  // resetting all checkbox as unchecked status
        checkbox.checked = false
      });
    },
    initManualCopySourceCheckboxes () {
      this.manualCopySourceCompetitorUrls.map(url => {
        let element = document.getElementById(`manualcopy_checkbox_${url}`)
        console.log('Finding checkbox', `manualcopy_checkbox_${url}`, element)
        if (element) {
          element.checked = true
        }
      })
    },
    showBestPractices () {
      this.$refs.bestPractices.show()
    },
    urlToPlainStr (url) {
      return url.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")
    },
    toggleUrlIsInManualCopySourceList ({ event, url }) {
      if (!event) return;
      if (event.target.checked) {
        let value = this.manualCopySourceCompetitorUrls.slice()
        value.push(url)
        this.manualCopySourceCompetitorUrls = value

        value = Object.assign({}, this.manualCompetitorUrlsSources)
        value[`${url}`] = value[`${url}`] || ''
        this.manualCompetitorUrlsSources = value
        this.toggleDisplayActiveCompetitorSourceArea(url)
      } else {
        this.manualCopySourceCompetitorUrls = this.manualCopySourceCompetitorUrls?.filter((item) => {
          return (item !== url)
        })
        if (this.activeManualCompetitorSource === url) this.activeManualCompetitorSource = ''
        delete this.manualCompetitorUrlsSources[`${url}`]
      }

      
    },
    isManualCopyUrl (url) {
      if (this.manualCopySourceCompetitorUrls.indexOf(url) !== -1) {
        return true
      } else {
        return false
      }
    },
    toggleDisplayActiveCompetitorSourceArea(url) {
      if (this.isManualCopyUrl(url)) {
        this.activeManualCompetitorSource = url
        let element = document.getElementById(`manualcopy_textarea_${url}`)
        if (element) {
          element.value = this.manualCompetitorUrlsSources[`${url}`]
        }
          
      }
    },
    changedManualCompetitorUrlSource ({ url, htmlCode, done }) {
      let value = Object.assign({}, this.manualCompetitorUrlsSources)
      value[`${url}`] = htmlCode
      this.manualCompetitorUrlsSources = value
      console.log('call done callback', done)
      if (done) {
        done()
      }
    },
    cleanManualCopiedSourceCodes () {
      let value = Object.assign({}, this.manualCompetitorUrlsSources)
      Object.keys(value).forEach(key => {
        value[`${key}`] = ''
      });
      this.manualCompetitorUrlsSources = value
      this.showConfirmClearManualSourceCodeModal = false
    },
    fetchVariations () {
      this.fetchingVariations = true
      try {
        this.$store.dispatch('fetchVariations', { keyword: this.cleanText(this.keyword), proxyLocation: this.proxyLocation })
            .then((response) => {
              let variations = this.$store.state.variations.slice()
              response.data.forEach((v) => {
                if (v.toLowerCase() !== this.keyword.toLowerCase() && variations.indexOf(v) === -1 && this.isValidWord(v)) {
                  variations.push(this.cleanText(v.trim()))
                }
              })
              this.$store.commit('setVariations', variations)
              this.$store.commit('setFetchedVariations', true)
            })
            .catch((err) => {
              console.log('Error fetching variations ', err)
              this.errorMsg = 'Error fetching variations. Please check validation messages. If error persists contact support.'
            })
            .finally(() => this.fetchingVariations = false)
      } catch(e) {
        console.log('error fetching variations')
        console.log(e)
        this.fetchingVariations = false
        this.errorMsg = 'Error fetching variations. Please check validation messages. If error persists contact support.'
      }
    },
    addNewVariations (newVariation) {
      this.variationsToAdd = newVariation
      this.addVariations()
    },
    addVariations () {
      let variations = this.$store.state.variations.slice()
      this.variationsToAdd
        .split(',')
        .filter(v => v.toLowerCase() !== this.cleanText(this.keyword).toLowerCase())
        .forEach((v) => {
        if (this.variations.indexOf(v) === -1) {
          if (v.trim().length > 0) {
            variations.push(v.trim())
          }
        }
      })
      this.$store.commit('setVariations', variations)
      this.variationsToAdd = ''
    },
    disabledPagePathInput () {
      if (this.runBuiltPage) {
        return false
      }
      if ( this.pageNotBuilt) {
        if (this.targetUrl !== '') this.tempSaveTargetUrl = this.targetUrl;
        this.targetUrl = '';
        return true;
      } else {
        if (this.tempSaveTargetUrl !== '') {
          this.targetUrl = this.tempSaveTargetUrl;
          this.tempSaveTargetUrl = '';
        }
        return false;
      }
    },
    submitForm () {
      this.submitData();
    },
    submitData (dispatchActionName='') {
      const { siteId, pageId } = this.$route.params
      if (this.validForm && this.canCreateRerun) {
        
        if (!this.canCreateReport()) {
          return;
        }

        if (this.submittingRun ) {
          return;
        }

        this.submittingRun = true
        
        try {
          const completeUrl = this.getCompleteUrl()
          this.$store.commit('updateLastReportCreationTime');
          
          // const dispatchName = dispatchActionName ? dispatchActionName : 'rerunAnalysis'
          const dispatchName = 'rerunAnalysis'
          this.$store.dispatch(dispatchName, {
            validatedUrls: this.validatedUrls,
            siteId,
            pageId,
            pageRunId: this.pageRun.id,
            pageUrl: completeUrl,
            keyword: this.cleanText(this.keyword),
            secondaryKeywords: this.useSecondaryKeywords ? this.cleanedSecondaryKeywords : [],
            competitorUrls: this.cleanCompetitorUrls(this.competitorUrls),
            focusCompetitorUrls: this.focusCompetitorUrls.length > 0 ?  this.cleanCompetitorUrls(this.focusCompetitorUrls).split('\n') : [],
            originGoogleVariations: this.originGoogleVariations,
            variations: this.runWithoutVariation ? [] : this.variations,
            note: this.note,
            proxyId: this.proxyId,
            proxyLocation: this.proxyLocation,
            isExtensionUsed: this.isGoogleSearchDoneWithExtension ? 1 : 0,
            targetLang: this.targetLang,
            ignoreSpaces: this.ignoreSpaces ? 1 : 0,
            pageNotBuilt: this.pageNotBuilt ? 1 : 0,
            useKeywordPrecisionSearch: this.useKeywordPrecisionSearch ? 1 : 0,
            willUseGoogleNlp: this.willUseGoogleNlp ? 1 : 0,
            willUseEatAnalysis: this.willUseEatAnalysis ? 1 : 0,
            willUseNextGenEatAnalysis: this.willUseNextGenEatAnalysis ? 1 : 0,
            targetUrlSourceCode: this.isTargetUrlSourceDirectly ? this.targetUrlSourceCode : '',
            manualCompetitorUrlsSources: this.manualCompetitorUrlsSources,
            isContentEditorUsed: this.isContentEditorUsed ? 1 : 0,
            pageRunType: this.isExpressReport ? 1 : 0,
            lsaPhrases: this.runWithoutPhrases ? [] : this.lsaPhrases,
            removedLSITermsByUser: this.removedLSITermsByUser,
            runWithoutPhrases: this.runWithoutPhrases ? 1 : 0,
            gCompetitors: this.googleSearchResults && this.googleSearchResults.length ? this.googleSearchResults : [],
            switchedSiteProtocol: this.switchedSiteProtocol ? 1 : 0,
            relatedSearches: this.relatedSearches,
            relatedQuestions: this.relatedQuestions,
            // isForUpdateScore: (this.rerunType == 0 || this.googleSearchResults.length == 0), // googleSearchResults==0 menas if user didn't do google search again for this rerun
            isForUpdateScore: this.rerunType == 0,
          })
          .then((response) => {
            if (response.data.status && response.data.status === 'FAILURE') {
              this.errorMsg = response.data.msg
              this.submittingRun = false
              return 1
            } else {
              this.clearErrorGeneralMessage()
              this.$store.commit('addPageRun', response.data.pageRun)
              if (response.data.site) {
                this.$store.commit('setSite', response.data.site) // this is needed to update site default setting like lang and proxy
              }
              return this.$store.dispatch('loadUser')
                .then((response) => {
                  this.$store.commit('setUser', response.data.user)
                  this.submittingRun = false
                  return this.$router.push(`/sites/${siteId}/pages/${pageId}`)
                })
            }
          })
          .catch(err => {
            this.submittingRun = false
            console.log('Error in page rerun ', err)
            this.errorMsg = 'Error re-running analysis. Please check validation messages. If error persists contact support.'
          })
        } catch(e) {
          console.log('errro in re-running analysis')
          console.log(e)
          this.errorMsg = 'Error re-running analysis. Please check validation messages. If error persists contact support.'
        }
      }
      if (!this.canCreateRerun) {
        this.errorMsg = 'Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.'
      }
    },
    setRerunType (rerunType) {
      this.rerunType = rerunType
      if (!this.isAvailableChromeExtension && this.isGoogleSearchDoneWithExtension) {
        this.showConfirmChangeUrlOnReRun = false
        this.showMissingChromeExtensionModal = true
        return
      }
      // rerunType:   0-leave as is   1-need to make some change 2-want to switch pagerun type
      this.showConfirmChangeUrlOnReRun = false
      if (rerunType == 0) {
        this.isEditableUrlAndKeyword = false
        this.urlsValidated = true
        this.currentPage = this.PageRunPages.SELECT_FOCUS_GROUP // changed from 7 to 8
        if (this.isExpressReport) {
          this.isOnePageSetup = true;
        } else {
          this.isOnePageSetup = false;
        }
        this.validateInputUrls()
        // this.checkWhetherUrlUsedIn24HrsAndValidateTargetUrl()
      } else if (rerunType == 1 || rerunType == 2) {
        this.validatedCompetitorUrls = []
        this.urlsValidated = false
        this.currentPage = this.PageRunPages.ADD_KEYWORD
        this.firstRunOnRerunPage = false

        if (rerunType == 2) {
          this.isExpressReport = !this.isExpressReport
        }

        if (this.isExpressReport) {
          this.isOnePageSetup = true;
        } else {
          this.isOnePageSetup = false;
        }
      }
    },
    beforeCloseBrowser () {
      return 'You might lose the content you entered.'
    }
  },
  mounted () {
    this.checkExtensionIsInstalled()
    
    if (!this.pageRun) { // browser refresh case.
      this.$router.push('/sites');
    }

    // load default setting for project
    let settings = this.site.settings
    if (settings) {
      if (settings.targetLang!='' && settings.targetLang!=undefined) {
        this.isRememberLangAndProxy = true
      }
    }


    
    this.isRerunPage = true
    this.$store.commit('showLoading')
    let googleSearchLocations = this.googleSearchLocations.slice()
    try {

      // reset the relatedQuestions and relatedSearches from store.
      // and also originGoogleVariations
      this.$store.commit('resetRelatedQuestionsAndSearches');

      this.$store.dispatch('fetchAvailableProxies').then(response => {
          let allProxies = response.data
          const proxyMap = {}
          allProxies.forEach((p) => {
            proxyMap[p.location] = p
          })

          this.keyword =  this.pageRun.keyword != null ? this.cleanText(this.pageRun.keyword) : this.cleanText(this.page.keyword)
          
          let targetUrl = this.pageRun.url ? this.pageRun.url : this.page.url
          let decoded = targetUrl
          if (!this.pageNotBuilt) {
            if (decoded.indexOf(this.site.baseURL) > -1) {
              decoded = decoded.replace(this.site.baseURL, '').replace(/^\//, '')
            }
          }
          this.targetUrl = decoded
          this.ignoreSpaces = this.pageRun.ignoreSpaces
          
          this.competitorUrls = this.cleanCompetitorUrls(this.pageRun.competitors.map(c => c.url).join('\n'))
          this.isGoogleSearchDoneWithExtension = this.pageRun.isExtensionUsed==1
          
          let urlSources = {}
          this.manualCopySourceCompetitorUrls = this.pageRun.competitors?.filter(c => c.urlSourceCode != null && c.urlSourceCode != '').map((c, idx) => {
            urlSources[`${c.url}`] = c.urlSourceCode
            return c.url
          })
          setTimeout(() => {
            this.initManualCopySourceCheckboxes()
          }, 50);
          this.manualCompetitorUrlsSources = urlSources

          this.invalidUrls = this.pageRun.competitors?.filter(c => !c.isValid).map(c => c.url)
          this.$store.commit('setVariations', this.pageRun.variations?.filter(v => this.isValidWord(v) && v != this.pageRun.keyword))

          this.focusCompetitorUrls = this.pageRun.featuredCompetitors ? this.pageRun.featuredCompetitors : []
          this.ipAddress = this.pageRun.ipAddress

          if (this.page.invalidURL) {
            this.errorMsg = 'Main URL is invalid. '
          }
          if (this.invalidUrls.length > 0) {
            this.errorMsg = this.errorMsg + ' Analysis contains invalid competitor urls.'
          }

          // if (!this.isGoogleSearchDoneWithExtension && this.pageRun.proxyId && allProxies.length) {
          if (!this.isGoogleSearchDoneWithExtension && this.pageRun.proxyId) {
            let proxy = {
                canonical_name: "United States", 
                name: "United States", 
                country_code: "US"
            }
            if (this.pageRun.googleLocation) {
              let canonical_name 
              try {
                canonical_name = googleSearchLocations.find(gl => gl === this.pageRun.googleLocation.trim())
                if (!canonical_name) {
                  let prevName = this.pageRun.googleLocation.trim().split(',').reverse().map(p => p.trim()).join(', ').trim()
                    canonical_name = googleSearchLocations.find(gl => gl === prevName)
                }
              } catch(e) {
                console.log(e);
              }
              proxy = {
                canonical_name: canonical_name ? canonical_name : "United States"
              }
            } else if (this.pageRun.proxyId) {
              const oldProxy = allProxies.find(p => p.id == this.pageRun.proxyId)
              if (oldProxy) {
                switch(oldProxy.location) {
                  case 'US':
                    proxy = {
                        canonical_name: "Ohio, United States", 
                        name: "Ohio", 
                        country_code: "US"
                    }
                    break
                  case 'UK':
                    proxy = {
                        canonical_name: "Greater London, England, United Kingdom", 
                        name: "Greater London", 
                        country_code: "GB"
                    }
                    break
                  case 'EU':
                    proxy = {
                        canonical_name: "Frankfurt, Hesse, Germany", 
                        name: "Frankfurt", 
                        country_code: "DE"
                    }
                    break
                  case 'Asia':
                    proxy =     {
                        canonical_name: "Singapore", 
                        name: "Singapore", 
                        country_code: "SG"
                    }
                    break
                  case 'Australia':
                    proxy = {
                        canonical_name: "Australia", 
                        name: "Australia", 
                        country_code: "AU"
                    }
                    break
                  case 'Canada':
                    proxy = {
                        canonical_name: "Canada", 
                        name: "Canada", 
                        country_code: "CA"
                    }
                    break;
                  case 'South America':
                    proxy = {
                        canonical_name: "Brazil", 
                        name: "Brazil", 
                        country_code: "BR"
                    }
                    break
                }
              }
            }
            
            this.proxyLocation = proxy.canonical_name
            this.proxyId = this.pageRun.proxyId
            this.originRunProxyLocation = proxy.canonical_name
          } else {
            this.originRunProxyLocation = 'United States'
            this.proxyLocation = 'United States'
          }
          
          if (!this.$route.query.withEditedSource) {
            if (this.pageRun['targetUrlSourceCode'] && this.pageRun.targetUrlSourceCode !== '') {
              this.isTargetUrlSourceDirectly = true;
              this.targetUrlSourceCode = this.pageRun.targetUrlSourceCode
            }
          }

          this.name = this.page?.name || ''
          this.targetLang = this.pageRun.targetLanguage

          this.isExpressReport = this.pageRun.pageRunType==1 // this means express run 

          this.lsaPhrases = this.pageRun.lsaPhrases

          this.secondaryKeywords = this.pageRun.secondaryKeywords
          if (this.secondaryKeywords.length > 0) {
            this.useSecondaryKeywords = true
          }
          if (this.pageRun.pageNotBuilt == 0) {
            this.runBuiltPage = true;
          }
          this.useKeywordPrecisionSearch = this.pageRun.useKeywordPrecisionSearch

          this.$store.commit('hideLoading') // hide spinner because we have modal to display progressbar

          this.firstRunOnRerunPage = true
          this.checkModalDisplayForRerun()
          return 1
        })
        .catch(err => {
          console.log('error fetching page run ', err)
        })
        .finally(() => {
          this.forceRender();
        })
        if (this.$route.query.runBuiltPage && this.$route.query.runBuiltPage == 'true') {
          this.runBuiltPage = true
        }
    } catch(e) {
      console.log('error fetching page run ', e)
      this.$store.commit('hideLoading')
    }
  },
  beforeDestroy () {
    window.onbeforeunload = null;
    document.removeEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
  },
  created () {
    window.onbeforeunload = this.beforeCloseBrowser
  },
  beforeMount () {
    if (this.$route.query.withEditedSource && parseInt(this.$route.query.withEditedSource) === 1) {
      this.isContentEditorUsed = true
      this.isTargetUrlSourceDirectly = true
      this.targetUrlSourceCode = this.$store.state.seoEditHtmlSource
    }
    if (!this.googleSearchLocations || !this.googleSearchLocations.length) {
      return this.$store.dispatch('fetchGoogleSearchLocations')
    }
  }
}
</script>

<style scoped lang="scss">

div {
  color: var(--v-mainColor-base);
}
.tag {
  margin: 3px;
}

.custom-btn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: var(--v-profileAnchorColor-base);
}
.erorr-text {
  color: red;
}
.tip-box {
  min-height: 130px;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid var(--v-grayColor-base);
  display: flex;
  gap: 10px;

  .image-area {
    display: flex;
    align-items: center;
    width: 100px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .tip-box-main {
    flex-grow: 1;
    .top-part {
      display: flex;
      align-items: center;
      .left-area {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
      .right-area {
        i {
          cursor: pointer;
        }
      }

      .tip-label {
        font-size: 14px;
        font-weight: 700;
        margin-left: 5px;
      }

      i {
        font-size: 18px;
        color: var(--v-gray2Color-base);
      }

      i.inactive {
        color: var(--v-grayColor-base);
      }
    }
    .explain {
      margin-top: 20px;
      line-height: 130%;
    }
  }
}

.rerun-type-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  & > .box-item {
    color: var(--v-mainColor-base);
    border: 2px solid var(--v-grayColor-base);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .box-title {
      font-size: 1.1rem;
      font-weight: 800;
    }

    .content {
      min-height: 150px;
    }
  }

  &.two-col {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .rerun-type-box {
    grid-template-columns: 1fr;
    .box-item .content {
      min-height: auto;
    }
  }
}
</style>
