<template>
<div class="update-password-page">
  <div>
    <div class="title-label">Reset account password</div>
    <update-password :isForgotPasswordPage="true"></update-password>  
  </div>
</div>
</template>

<script>
import UpdatePassword from "@/components/UpdatePassword.vue";

export default {
  components: {
    UpdatePassword,
  }
  
}
</script>

<style scoped lang="scss">

.update-password-page {

  .title-label {
    font-size: 25px;
    font-weight: bold;
    color: var(--v-mainColor-base);
    margin-bottom: 20px;
    text-align: center;
  }


  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 600px;
    max-width: 90vw;
  }
}


</style>