<template>
<div>
  <div class="comp-title">Word Count</div>
  <div class="word-count-area my-5">
    <span class="color-dot mr-2" :style="{ background: colorForDot }"></span>
    <span class="current-count">{{ currentCount }}</span>
    /
    <span class="target-count">{{ targetCount }}</span>
  </div>
  <div class="recommendation-txt">{{ recommendationText }}</div>
</div>
</template>

<script>
export default {
  props: {
    currentCount: {
      default: 0,
    },
    targetCount: {
      default: 0,
    },
    recommendationText: {
      default: '',
    },
  },
  computed: {
    colorForDot () {
      let colors = [
        '#FF6266',
        '#FF8F91',
        '#FF9B2D',
        '#FF9B2D',
        '#22DC7C',
        '#00CD49',
        '#22DC7C',
        '#FF9B2D',
        '#FF9B2D',
        '#FF8F91',
        '#FF6266',
      ]
      let step = 100 / colors.length;
      let percentage = this.currentCount * 100 / ( this.targetCount * 2 );
      let idx = Math.floor(percentage / step)
      return colors[idx]
    }
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
.comp-title {
  color: var(--v-mainColor-base);
  font-size: 1rem;
  font-weight: 700;
}
.word-count-area {
  background: var(--v-pink1Color-base);
  padding: 10px;
}
.color-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.current-count {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
  border-radius: 5px;
}
.target-count {
  font-size: 1.1rem;
  color: var(--v-mainColor-base);

}
.recommendation-txt {
  color: var(--v-mainColor-base);
}
</style>