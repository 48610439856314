
<template>
  <div class="modal" :class="{'is-active': showModal}">
    <div class="modal-background" @click="hide"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="subtitle-font mainColor--text" v-if="invalidDomain">
            You are currently in your free trial period. During your free trial you cannot use a domain that has previously been used as a target domain in PageOptimizer Pro. The domain you are trying to run has already been used esther by yourself or by someone else. To use the tool during your trial period, please try a different domain. If you wish to use this domain, you can convert your free trial now.
          </p>
          <div class="subtitle-font mainColor--text">
            Converting your trial will charge your payment method ${{ activeSubscription && activeSubscription.credits > 0 ? activeSubscription.credits : 27 }} placing you in the Basic subscription service. <br />
            If you wish to have a larger plan, click "Keep Trial" below, then the "Account setting" at the top of this page, and finally the green Upgrade button from the Account Management page where you can choose a larger plan.
          </div>
          <div class="d-flex justify-end mt-5">
            <v-btn rounded color="profileAnchorColor"
              class="mainColor--text text-none" style="min-width: 150px;"
              @click="convertTrial">
              Convert My Trial
            </v-btn>
            <v-btn rounded color="darkGrayColor"
              class="mainColor--text text-none ml-3" style="min-width: 150px;"
              @click="hide">
              Keep Trial
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrialConversionModal',
  props: {
    invalidDomain: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    activeSubscription() {
      return this.user.purchases.find(
        p => p.id == this.user.activeSubscription
      );
    },
  },
  methods: {
    show () {
      this.showModal = true
    },
    hide () {
      this.showModal = false
    },
    setShow (showModal) {
      this.showModal = showModal
    },
    convertTrial () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('convertSubscription')
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          this.$store.commit('setUser', response.data.user)
          if (response.data.approvalUrl) { // paypal
            window.open(response.data.approvalUrl, '_self')
          } else { // stripe
            if (response.data.convertFailed) {
              this.$notify({ group: 'info', type: 'error', text: 'Failed to convert.' })
            } else {
              this.$notify({ group: 'info', type: 'success', text: 'Successfully updated.' });
            }       
          }
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
        this.setShow(false)
      })
    }
  }
}
</script>

<style lang="scss">
  
</style>
