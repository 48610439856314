<template>
<div class="pad15">
  <div v-if="isLoading" class="has-text-link">
    Loading ...
  </div>
  <div v-else-if="error" class="has-text-danger">
    {{ error }}
  </div>
  <div v-else class='wrapper-box'>
    <div class="stats-text">
      <span class="stats-big-number">{{ stats._7dayReports }} </span> pages optimized in the past&nbsp;<span class="stats-small-number">7 days</span>
    </div>
    <!-- <div>
      <span class="stats-number">{{ stats.totalReports }}</span> pages optimized in total
    </div> -->
  </div>
</div>
</template>


<script>
export default {
  data () {
    return {
      stats: { totalReports: 0, _7dayReports: 0 },


      error: 'Loading ...',
      isLoading: true,
    }
  },
  methods: {
    updateStats() {
      this.$store.dispatch('getWeeklyPageRunStats')
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.stats = response.data.results
          this.error =  ''
        } else {
          this.error = 'Can not fetch optimized pages data'
        }
      })
      .finally(() => this.isLoading = false)
    }
  },
  beforeMount () {
    this.updateStats()
  }
}
</script>


<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
* {
  font-family: 'Mulish', 'Mulish';
}
.wrapper-box {
  display: flex;
  align-items: center;
  color: black;
  & > img {
    margin-right: 1rem;
  }
}
.stats-text {
  /* font-size: 2rem; */
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: Inter, sans-serif;
  font-weight: 500;
}
.stats-big-number {
  font-size: 30px;
  font-family: Inter, sans-serif;
  font-weight: 900;
  color: black;
  margin-left: 10px;
  margin-right: 10px;
}
.stats-number {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: black;
}
</style>