<template>
<div class="watchdog-unsub-root">
  <div class="container">
    <div v-if="succeed"
      class="notification-box">
      <div class="icon-part success">
        <span class="material-icons-outlined">check_circle</span>
      </div>
      <div class="text-part">
        <div class="title-text">
          Unsubscription Successful
        </div>
        <div class="body-text">
          <template v-if="isEmailUnsubscribe">
            You will no logner receive this watchdog report email.
          </template>
          <template>
            We're sorry to see you go. You'll no longer receive updates from PageOptimizer Pro. 
          </template>
        </div>
      </div>
    </div>
    <div v-else
      class="notification-box">
      <div class="icon-part error">
        <span class="material-icons-outlined">highlight_off</span>
      </div>
      <div class="text-part">
        <div class="title-text">
          Error
        </div>
        <div class="body-text">
          Please check if this is valid url.
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      succeed: false,
      isEmailUnsubscribe: false,
    }
  },
  mounted () {
    const code = this.$route.query.code;
    const email = this.$route.query.email;
    const justEmailUnsubscribe = Number.parseInt(this.$route.query.justEmailUnsubscribe || 0);
    if (justEmailUnsubscribe) {
      this.isEmailUnsubscribe = true;
    }

    if (code) {
      this.$store.dispatch('unsubscribeWatchdogMadeByAdmin', {
        code,
        email,
        justEmailUnsubscribe,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.succeed = true;
        }
      })
      .finally(() => {
        this.loading = false;
      })
    } else {
      this.loading = false;
      this.succeed = false;
    }
  }
}
</script>

<style scoped lang="scss">
.watchdog-unsub-root {
  background: var(--v-topHeaderBg-base);
}
.notification-box {
  display: flex;
  background: var(--v-cardBg-base);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 4px #999;
  margin-top: 5px;
  .icon-part {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
  }
  .text-part {
    padding: 15px 10px;
    flex-grow: 1;
    .title-text {
      font-weight: 800;
      font-size: 1rem;
      color: var(--v-mainColor-base);
    }
    .body-text {
      margin-top: 5px;
      font-size: 0.9rem;
      color: var(--v-mainColor-base);
    }
  }
  .icon-part.success {
    background: var(--v-green21Color-base);
  }
  .icon-part.warning {
    /* background: #FE9A2D; */
    background: var(--v-yellow2Color-base);
  }
  .icon-part.error {
    background: var(--v-green21Color-base);
  }
}
</style>