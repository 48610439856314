export const getFulfillementStatusLabel = (status) => {
    if (status === 'pending') {
      return 'Ordered'
    }
    if (status === 'in_progress') {
      return 'In progress'
    }
    if (status === 'completed') {
      return 'Completed'
    }
    return status;
};
