<template>
<div class="time-series">
  <canvas :id="id" style="width: 100%; height: 300px;"></canvas>
</div>
</template>

<script>
import Chart from 'chart.js'
import { smallUniqueId } from '@/utils'
export default {
  props: ['dataSeries', 'labels', 'seriesLabel'],
  data () {
    return {
      id: smallUniqueId(),
      chart: null
    }
  },
  watch: {
    'dataSeries': function(newVal) {
      this.dataSeries = newVal
      this.update()
    }
  },
  methods: {
    update () {
      this.chart.data.datasets[0].data = this.dataSeries
      this.chart.data.labels = this.labels
      this.chart.update()
      return 1
    },
    initialize () {
      const el = document.getElementById(this.id)
      const dataSeries = this.dataSeries
      const labels = this.labels
      const label = this.seriesLabel
      this.chart = new Chart(el, this.getOptions())
    },
    getOptions () {
      return {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [{ data: this.dataSeries, label: this.seriesLabel }]
        },
        options: { responsive: true }
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.initialize()
    }, 100)
  }
}
</script>

<style>
  
</style>