<template>
  <div>
    <div class="modal" :class="{ 'is-active': existingReportPop }">
      <div @click="allModalClose"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <div class="select-a-setup-type">
              AI Writer report
            </div>
            <div class="setup-type-explain mt-4">Choose an existing project and page that you want to add a new
              AI Writer report to:
            </div>
            <!-- {{ selectedPage }} -->
            <div class="columns mt-5 m-auto">
              <div class="column is-half">
                <div>
                  <div class="mb-2">
                    <b>Project</b>
                  </div>
                  <div class="dropdown" :class="{ 'is-active': projectDropdownOpen }">
                    <div class="dropdown-trigger">
                      <button @click.stop="toggleProjectDropdown" class="button dropdown-button"
                        :class="{ 'selected-project-button': selectedProject }">
                        {{ selectedProject ? selectedProject.name : 'Choose project' }}
                        <span class="icon is-small">
                          <i class="material-icons">unfold_more</i>
                        </span>
                      </button>
                    </div>
                    <div class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <div class="dropdown-item">
                          <div class="control has-icons-left">
                            <input class="input" type="text" v-model="searchTerm" ref="searchInput1">

                            <span class="icon is-small is-left">
                              <i class="material-icons mt-2">search</i>
                            </span>
                          </div>
                        </div>
                        <div class="dropdown-item projects-section">
                          <h4 class="is-4">Recent Projects</h4>
                          <div v-for="project in recentProjects" :key="project.id" class="mt-2">
                            <a @click="selectProject(project)">{{ project.name }}</a>
                          </div>
                        </div>
                        <div class="dropdown-item projects-section">
                          <h4 class="is-4 mt-2 mb-2">All Projects</h4>

                          <div v-for="project in sortedFilteredSites" :key="project.id" class="mt-2">
                            <a @click="selectProject(project)">{{ project.name }}</a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="has-text-danger mt-2">{{ aiErrors?.projectError }}</div>
                <div>
                  <div class="mb-2 mt-15">
                    <b>Page</b>
                  </div>
                  <div :class="{ 'is-active': pageDropdownOpen, dropdown: !projectDropdownOpen }">
                    <div class="dropdown-trigger">
                      <button @click.stop="togglePageDropdown" class="button dropdown-button"
                        :class="{ 'selected-project-button': selectedPage }">
                        {{ selectedPage ?
                          selectedPage.name : 'Choose page' }}

                        <span class="icon is-small">
                          <i class="material-icons">unfold_more</i>
                        </span>
                      </button>
                    </div>
                    <div class="dropdown-menu" role="menu" v-if="selectedProject">
                      <div class="dropdown-content">
                        <div class="dropdown-item">
                          <div class="control has-icons-left">
                            <input class="input" type="text" v-model="searchPage" ref="searchInput">
                            <span class="icon is-small is-left">
                              <i class="material-icons mt-2">search</i>
                            </span>
                          </div>
                        </div>
                        <div class="dropdown-item projects-section">
                          <h4 class="is-4"> {{ selectedProject && selectedProject.name }}</h4>

                          <div v-for="page in searchedPages" :key="page.id" class="mt-1 ml-2">
                            <a @click="selectPage(page)" class="page-link">
                              <span class="material-icons arrow-icon">east</span>
                              <span class="page-name">{{ page.name }}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="has-text-danger mt-2">{{ aiErrors?.pageError }}</div>
              </div>
            </div>
            <div class="continue-button-area">
              <v-btn rounded color="" class="button-with-icon back-button-text mr-2 mainColor--text text-none"
                @click="dismissPickingReportTypeModalAndGoBack">
                <span>Back</span>
              </v-btn>
              <v-btn rounded color="profileAnchorColor"
                class="button-with-icon continue-button-text whiteColor--text text-none"
                  :to="contentEditorLink"
                  :disabled="!contentEditorLink"
                  target="_blank"
                >
                <span>Continue</span>
                <i class="material-icons">arrow_forward</i>
              </v-btn>
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click="allModalClose">
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import { mixin } from '@/components/mixin'
import _ from "lodash";
export default {
  mixins: [mixin,],
  props: ["existingReportPop"],
  data() {
    return {
      searchTerm: '',
      projectDropdownOpen: false,
      pageDropdownOpen: false,
      selectedProject: null,
      selectedPage: null,
      searchPage: '',
      aiErrors: {
        projectError: '',
        pageError: '',
      },
    };
  },
  methods: {
    selectProject(project) {
      this.selectedProject = project;
      this.selectedPage = null
      this.projectDropdownOpen = false;
    },
    selectPage(page) {
      this.selectedPage = page;
      this.pageDropdownOpen = false
    },
    loadSite() {
      this.$store.commit('showLoading')
      return this.$store.dispatch('loadSite', this.site.id)
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            if (response.data.site) {
              this.$store.commit('setSite', response.data.site)
            }
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
    toggleProjectDropdown() {
      this.projectDropdownOpen = !this.projectDropdownOpen;
      if (this.projectDropdownOpen) {
        this.pageDropdownOpen = false;
        document.addEventListener('click', this.closeDropdown);
      } else {
        document.removeEventListener('click', this.closeDropdown);
      }
    },

    togglePageDropdown() {
      this.pageDropdownOpen = !this.pageDropdownOpen;
      if (this.pageDropdownOpen) {
        this.projectDropdownOpen = false;
        document.addEventListener('click', this.closeDropdown);
      } else {
        document.removeEventListener('click', this.closeDropdown);
      }
    },
    dismissPickingReportTypeModalAndGoBack() {
      this.$emit("close-modal");
    },
    allModalClose() {
      this.$emit("mainModalCLose");
    },
    closeDropdown() {
      const isSearchInput = this.$refs.searchInput?.contains?.(event.target);
      const isSearchInput1 = this.$refs.searchInput1?.contains?.(event.target);
      if (!isSearchInput && !isSearchInput1) {
        this.projectDropdownOpen = false;
        this.pageDropdownOpen = false
      }
    },
  },
  mounted() {
    // document.addEventListener('click', this.closeDropdown);
    this.selectedProject = this.site;
  },
  beforeUpdate() {
    if (!this.selectedProject) {
      const siteId = this.$route.params.siteId;
      this.selectedProject = this.selectedSites.find(s => s.id === Number.parseInt(siteId)) || null;
    }
  },
  computed: {
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    contentEditorLink() {
      const pageRunId = this.filteredPagesId?.pageRunId
      if (this.selectedProject && this.selectedPage && pageRunId) {
        let url = `/content-editor?pageRunId=${pageRunId}&editorMode=AI_MODE`;
        if (this.selectedSubAccountRelation) {
          url += `&relId=${ this.selectedSubAccountRelation.id }`
        }
        return url;
      }
      return ""
    },
    selectedSites() {
      return this.$store.getters.getSiteData
    },
    filteredSites() {
      if (this.searchTerm) {
        const searchTermLower = this.searchTerm.toLowerCase();
        return this.selectedSites.filter((project) =>
          project.name.toLowerCase().includes(searchTermLower)
        );
      } else {
        return this.selectedSites;
      }
    },
    searchedPages() {
      if (this.searchPage) {
        const searchPageLower = this.searchPage.toLowerCase();
        return this.filteredPages.filter((project) =>
          project.name.toLowerCase().includes(searchPageLower)
        );
      } else {
        return this.filteredPages;
      }
    },
    sortedFilteredSites() {
      const copiedFilteredSites = [...this.filteredSites];
      return copiedFilteredSites.sort((a, b) =>
        new Date(a.createdAt) - new Date(b.createdAt)
      );
    },
    recentProjects() {
      return this.selectedSites
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 2);
    },
    filteredAllProjects() {
      return this.filteredPages.filter(project =>
        project.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    filteredPages() {
      const selectedPages = this.selectedProject?.pages || [];

      const filteredPages = selectedPages.filter(page => {
        return page.lastPageRunInfo.pageRunId
      }
      );
      return filteredPages;
    },
    filteredPagesId() {
      const filteredPage = this.filteredPages.find(p => p.id === this.selectedPage?.id);
      return filteredPage?.lastPageRunInfo || null;
    },

  },
  created() {
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
  beforeMount() {
    this.siteName = this.site ? this.site.name : ''
    if (!this.$store.state.sites.length) {
      this.$store.commit('showLoading')
      return this.$store.dispatch('loadSites')
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('setSites', response.data.sites)
          }
        })
        .finally(() => {
          this.loadSite()
        })
    } else {
      this.loadSite()
    }
  },
  watch: {
    existingReportPop: {
      handler(newValue, oldValue) {
        if (newValue === false && oldValue === true) {
          this.searchTerm = "";
          this.projectDropdownOpen = false;
          this.pageDropdownOpen = false;
          this.selectedPage = null;
          this.searchPage = "";
          this.aiErrors.projectError = "";
          this.aiErrors.pageError = "";
        }

        this.selectedProject = this.site;
      },
      immediate: true
    },
    selectedProject(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.aiErrors.projectError = '';
      }
    },
    selectedPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.aiErrors.pageError = '';
      }
    }
  },
};
</script>
  
<style lang="scss" scoped>
div {
  color: var(--v-mainColor-base);
}

.page-link {
  display: flex;
  align-items: center;

}

.arrow-icon {
  font-size: 15px;
  margin-right: 5px;
}

.modal-content {
  width: 30vw;
}

ul {
  margin-left: 0;
}

.back-button-text {
  border: 1px solid var(--v-mainColor-base);
  height: 38px !important;
  background: none !important;

  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }
}

.continue-button-text {
  height: 38px !important;

  i,
  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }

  i {
    margin-top: 2px;
  }
}

.input {
  color: var(--v-mainColor-base);
}

.button {
  width: 254px;
  justify-content: space-between;
}

.dropdown {
  z-index: 999;
  display: inline-block;
  position: fixed;
}

.dropdown-content {
  width: 254px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dropdown-button {
  height: 40px;
  font-size: 12px;
  font-weight: 700;
  color: var(--v-grayColor-base);
  background: var(--v-whiteColor-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.selected-project-button {
  color: var(--v-mainColor-base);
}

.dropdown-content {
  background: var(--v-whiteColor-base);
}

.icon {
  color: var(--v-mainColor-base);
}

.projects-section h4 {
  font-weight: 800;
  font-size: 12px;
}

@media screen and (min-width: 769px) {

  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 700px;
  }
}

.select-a-setup-type {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
}

.setup-type-explain {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.continue-button-area {
  margin-top: 25px;
  text-align: right;
}
</style>
