<template>
<div class="three-rows-grid pt5">
    <div class="wow_disaled slideInLeft">
        <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="$emit('goprevpage')">keyboard_backspace</i>
    </div>          
    <div>
        <div class="full-width-container">
            <div class="level1-size title-font wow_disaled slideInLeft" data-wow-delay="0s">
                Add missing variations or remove ones that don't belong
            </div>

            <div class="level2-size mt-3 mb-4 wow_disaled slideInLeft" data-wow-delay="0.3s">
                Type variations you want to add above and press enter.
            </div>

            <div class="wow_disaled slideInLeft" data-wow-delay="0.5s">
                <multiselect
                    v-model="thisVariations"
                    :options="thisVariations"
                    :multiple="true"
                    :taggable="true"
                    :hide-selected="true"
                    placeholder=""
                    tag-placeholder="Press enter to add"
                    @tag="addNewVariations"
                    @remove="handleRemoveVariation"
                    :disabled="runWithoutVariation"
                    >
                </multiselect>
            </div>
            
            <div class="level2-size mb20 mt10 wow_disaled slideInLeft" data-wow-delay="0.3s">
              <button class="button is-pulled-right is-small is-warning" v-show="!variationsUnchanged" @click="resetToOriginalVariations">Reset Variations</button>
            </div>
            
            <div class="d-flex align-center mt-2">
                <div class="control">
                    <label for="run-without-variation">
                        <input id="run-without-variation" type="checkbox" v-model="thisRunWithoutVariation" @change="handleRunWithoutVariations" />
                        <span>&nbsp;Run this report without variations</span>
                    </label>
                </div>
                <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                        Check this box if POP does not find any variations and if there aren't any clear variations to add for your keyword or keyword phrase.
                    </div>
                </v-tooltip>
            </div>

            <div class="q-a-explain-block mt-4">
                <img src="/static/q-a-icon.png" alt="Q/A" />
                <div>
                    <div class="section-title">How to choose variations?</div>
                    <div class="mt-2">
                        Variations are terms that are <b>critically important</b> to your target keyword or keyword phrase.  They are often close synonyms, misspellings, and partial matches of a phrase.
                    </div>
                    <div class="mt-2">
                        <b>Only in rare circumstances do you need to add variations.</b>
                        As an example, if you want to optimize for the term 'Lawyer San Diego' and the keyword variation 'attorney' did not appear in the variation list, you may want to add that variation.
                    </div>
                    <div class="mt-2">
                        <b>Do not add other main keywords</b> that aren't direct synonyms, close misspellings or partial matches.
                    </div>
                    <div class="mt-2">
                        <b>Sometimes things slip in that don't need to be in your variations list.</b>
                    </div>
                    <div class="mt-2">
                        You won't need to optimise for <b>any symbols</b> ($, #, &), <b>connecting words</b> (and, or), or <b>prepositions</b> (of, by, with);
                        so remove any of those if they appear above.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class='has-text-centered font-weight-bold profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</div>
        <div class="is-bottom-right">
            <v-btn rounded
                color="profileAnchorColor"
                ref="nextBtn" 
                class="button-with-icon whiteColor--text text-none"
                :disabled="!advanceable" @click="$emit('gonextpage')">
                <span>Next</span>
                <i class="material-icons">arrow_forward</i>
            </v-btn>
        </div>
    </div>

</div>
</template>

<script>
import { cleanText } from '@/utils'
import Multiselect from 'vue-multiselect'

export default {
    components: { Multiselect },
    props: ['keyword', 'secondaryKeywords', 'variations', 'runWithoutVariation', 'isExpressReport', 'advanceable', 'errorMsg'],
    data () {
        return {
            origVariations: this.variations.slice(),
            thisVariations: this.variations.slice(),
            thisRunWithoutVariation: this.runWithoutVariation,
            variationsToAdd: '',
        }
    },
    watch: {
        'variations': function(vars) {
            if (!this.thisVariations.length) {
                this.origVariations = vars.slice()
            }
            this.thisVariations = vars.slice()
        }
    },
    methods: {
        addNewVariations (newVariation) {
            this.variationsToAdd = newVariation
            if (!this.thisVariations.length) {
                this.origVariations = [newVariation]
            }
            const variations = this.variations.slice()
            this.variationsToAdd.split(',')
                .map(v => v.trim())
                .filter(v => v.toLowerCase() !== this.cleanText(this.keyword).toLowerCase())
                .filter(v => !this.secondaryKeywords.includes(v.toLowerCase()))
                .forEach((v) => {
                    if (variations.indexOf(v) === -1) {
                        if (v.length > 0) {
                            variations.push(v.trim())
                        }
                    }
                })
            this.$emit('variationschange', variations)
            this.variationsToAdd = ''
        },
        handleRemoveVariation (v) {
            if (!this.origVariations.length) {
                this.origVariations = this.variations.slice()
            }
            const i = this.variations.indexOf(v)
            if (i >= 0) {
                const variations = this.variations.slice()
                variations.splice(i, 1)
                this.$emit('variationschange', variations)
            }
        },
        handleRunWithoutVariations (e) {
            this.$emit('runvariationschange', this.thisRunWithoutVariation)
        },
        resetToOriginalVariations () {
            this.$emit('variationschange', this.origVariations.slice())
        }
    },
    computed: {
        variationsUnchanged () {
            let allMatch = true
            const original = this.origVariations.map(v => v.toLowerCase().trim())
            const variations = this.thisVariations.map(v => v.toLowerCase().trim())
            for (const v of original) {
                if (!variations.includes(v)) {
                    allMatch = false
                    break
                }
            }
            return allMatch && original.length == variations.length
        }
    },
    created () {
        this.cleanText = cleanText
    },
    mounted() {
        if (this.advanceable) { this.$refs.nextBtn.$el.focus() }
        this.showNoAutoVariationsModal = this.variations.length === 0
    }
}
</script>

<style>

</style>