<template>
<div>
  <v-btn rounded outlined block
    color="mainColor"
    class="mt10 text-none"
    v-if="user.canReactiveSubscription"
    @click.stop="showReactivateSubscription = true">
    <span class="icon">
      <i class="fa fa-refresh"></i>
    </span>
    <span>Reactivate Subscription</span>
  </v-btn>
  <div class="modal" :class="{'is-active': showReactivateSubscription}">
    <div class="modal-background" @click.stop="showReactivateSubscription = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div>
          <p class="title mainColor--text">Select a subscription to reactivate</p>
          <div style="max-height: 75vh; overflow-y: auto;">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Description</th>
                  <th>Cancelled On</th>
                  <th>Payment Method</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="purchase in reactiveSubscriptions" :key="purchase.id" v-if="purchase.subscriptionId && purchase.cancelledOn && !['admin update', 'paypal'].includes(purchase.paymentMethod)">
                  <td>{{ serviceLabel(purchase.servicePackage) }}</td>
                  <td>{{ getServiceDescription(purchase.servicePackage) }}</td>
                  <td>{{ purchase.cancelledOn }}</td>
                  <td>{{ purchase.paymentMethod === 'stripe' ? 'Credit Card' : 'PayPal' }}</td>
                  <td>
                    <button v-if="purchase.servicePackage !== 'SERVICE_PKG_A'" class="button is-primary" @click.stop="reactivateSubscription(purchase.id)">Reactivate</button>
                    <button v-else class="button is-primary disabled" disabled>Discontinued</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.stop="showReactivateSubscription = false"></button>
  </div>
</div>
</template>

<script>
import {
  oldPlans
} from "@/components/plans_constants"

import { mixin as ServiceMixin } from '@/components/service_mixin'
export default {
  mixins: [ServiceMixin],
  computed:  {
    user () {
      return this.$store.state.user
    },
    reactiveSubscriptions () {
      // Exclude the purchases which are created coupon or old price
      let excluded = []
      let rlt = (this.$store.state.user.purchases || []).filter(p => {
        if (p.paymentMethod == 'paypal' || oldPlans.includes(p.servicePackage) || p.couponCode) { // need to check with full service package name not pure here.
          excluded.push(p)
          return false
        }
        return true;
      });
      // console.log('excluded are', excluded)
      return rlt;
    },
  },
  methods: {
    reactivateSubscription (purchaseId) {
      this.showReactivateSubscription = false
      this.$store.commit('showLoading')
      return this.$store.dispatch('reactivateSubscription', { purchaseId })
        .then((response) => {
          if (response.data.status === 'FAILURE') {
            if (response.data.clientSecret) {
              this.$notify({
                group: 'info', type: 'success', duration: 5000,
                text: 'Additional confirmation is needed. please wait a little.'
              })
              window.stripe.confirmCardPayment(response.data.clientSecret).then((result) => {
                if (result.error) {
                  // Inform the customer that there was an error.
                  this.$notify({
                    group: 'info', type: 'error', duration: 5000,
                    text: result.error.message
                  })
                } else {
                  this.$router.push('/sites');
                }
              })
            } else {
              this.$notify({
                group: 'info', type: 'error', duration: 5000,
                text: response.data.msg
              })
            }
          } else if (response.data.status === 'SUCCESS') {
            this.$store.commit('setUser', response.data.user)
            if (response.data.approvalUrl) {
              window.open(response.data.approvalUrl, '_self')
            }
          }
          return 1
        })
        .finally(() => this.$store.commit('hideLoading'))
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  th, td {
    color: var(--v-mainColor-base);
  }
}  
</style>
