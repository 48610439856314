<template>
<div class="google-login-root">
  <div class="main-area">
    <img src="/static/google-logo.png" />
    <div class="connect-status">
      <template v-if="googleUser">
        {{ googleUser?.name }}
      </template>
      <template v-else>
        no account selected
      </template>
    </div>
    <google-button></google-button>
  </div>
</div>
</template>

<script>
import GoogleButton from '@/components/Campaigns/GoogleButton.vue';
import { EventEmitter } from '@/utils/Event.js';

export default {
  components: {
    GoogleButton,
  },
  data () {
    return {
      googleUser: null,
    }
  },
  methods: {

  },
  mounted () {
    EventEmitter.subscribe('GOOGLE_USER_UPDATE', (googleUser) => {
      if (googleUser?.Ca) {
        if (googleUser?.Ca) {
          const name = googleUser.getBasicProfile().getName();
          const profileImg = googleUser.getBasicProfile().getImageUrl();
          this.googleUser = {
              name: name,
              profileImg: profileImg,
          };
        } else {
          this.googleUser = null;
        }
      }
    })
  }

}
</script>

<style lang="scss" scoped>
.google-login-root {
  .main-area {
    max-width: 500px;
    border: 1px solid var(--v-grayColor-base);
    background: var(--v-whiteColor-base);
    border-radius: 5px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 30px;
      height: auto;
    }
    .connect-status {
      font-weight: 700;
      color: var(--v-mainColor-base);
      flex-grow: 1;
    }
  }  
}
</style>