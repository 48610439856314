<template>
  <silo-list-view v-if="!this.$route.query.id" :campaignId="campaignId"/>
  <silo-single-view v-else :campaignId="campaignId"/>
</template>

<script>
import SiloListView from './SiloListView.vue';
import SiloSingleView from './SiloSingleView.vue';

export default {
  props: ['campaignId'],
  components: {
    SiloListView,
    SiloSingleView,
  }
};

</script>

<style lang="scss" scoped>
@import './silo.scss';
</style>
