<template>
<div>
  <v-card class="is-fullheight flex flex-column white-common-box">
    <div class="pa-5 flex-grow-1 flex flex-column">
      <div class="title-font mainColor--text box-title-label mb-5">
        Your affiliate links
      </div>
      <div v-for="(item, idx) in affiliateLinks" :key="idx" :class="{ 'mt-3': idx > 0 }">
        <div class="title-font mainColor--text font-weight-bold">{{ item.label}}</div>
        <div class="d-flex align-center">
          <div class="flex-grow-1">{{ item.link }}</div>
          <div>
            <v-btn rounded outlined x-small
              color="mainColor"
              class="text-none"
              v-clipboard:copy="item.link"
              v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
              v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })"
              >
              <span>Copy</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-card>
  <v-card class="is-fullheight flex flex-column white-common-box mt-5">
    <div class="pa-5 flex-grow-1 flex flex-column">
      <div class="title-font mainColor--text box-title-label">
        Your paypal email for commission
      </div>
      <div class="mt-5 d-flex align-center gap10">
        <v-text-field dense outlined
          color="greenColor"
          v-model="paypalEmail"
          hide-details="auto"
          ></v-text-field>
        <v-btn rounded small
          color="profileAnchorColor"
          class="text-none whiteColor--text"
          :disabled="!paypalEmail || paypalEmail == user.paypalEmail"
          @click="savePaypalEmail">
          Save
        </v-btn>
      </div>
      <div class="my-5 input-valid-paypal-note">
        Note: Please input valid paypal email to get your comission.
      </div>
    </div>
  </v-card>
</div>
</template>

<script>
export default {
  name: 'AffiliateSetup',
  data () {
    return {
      paypalEmail: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isAffiliate () {
      if (this.user) {
        return this.user.isAffiliate
      }
      return false
    },
    affiliateLinks () {
      if (!this.isAffiliate) return []
      let links = []
      links.push({
        link: this.user.affiliateLink,
        label: 'Affiliate link',
      })
      if (this.user.affiliateCustomLink) {
        links.push({
          link: this.user.affiliateCustomLink,
          label: 'Affiliate custom link',
        })
      }
      links.push({
        link: this.user.affiliateLinkToPricingPage,
        label: 'Affiliate link to pricing page',
      })
      if (this.user.affiliateCustomLinkToPricingPage) {
        links.push({
          link: this.user.affiliateCustomLinkToPricingPage,
          label: 'Affiliate custom link to pricing page',
        })
      }
      return links;
    },
  },
  methods: {
    savePaypalEmail () {
      if (!this.paypalEmail) {
        return;
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('savePaypalEmailForAffiliateCommission', this.paypalEmail)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({ group: 'info', type: 'success', text: 'Successfully saved your paypal email.' })
          this.$store.commit('setUser', response.data.user)
        } else {
          this.$notify({ group: 'info', type: 'error', text: 'Failed to save' })
        }
      })
      .catch((e) => {
        this.$notify({ group: 'info', type: 'error', text: 'Failed to save' })
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    }
  },
  mounted () {
    if (this.user && this.user.paypalEmail) {
      this.paypalEmail = this.user.paypalEmail
    }
  }
}
</script>

<style lang="scss" scoped>
.input-valid-paypal-note {
  display: inline-block;
  background: var(--v-link9Color-base);
  color: var(--v-link3Color-base);
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 5px;
}

</style>