<template>
  <div class="header-area">
    <div class="new-header">
      <div class="header-left-side">
        <i v-if="isHideSidebar" class="material-icons-outlined open-sidebar-icon"
          @click="expandSideBarAction">expand_circle_down</i>

        <component :is="$store.getters.isDemoUser ? 'span' : 'router-link'" to="/sites"
          :class="$store.getters.isDemoUser ? 'not-allowed-cursor' : 'watchdog-dashboard-link'">
          All projects
        </component>
        <component :is="$store.getters.isDemoUser ? 'span' : 'router-link'" to="/guard-dashboard"
          :class="$store.getters.isDemoUser ? 'not-allowed-cursor' : 'watchdog-dashboard-link'">
          Watchdog dashboard
        </component>
        <component v-if="isAvailableBulkRun" :is="$store.getters.isDemoUser ? 'span' : 'router-link'" to="/new-bulk-run"
          :class="$store.getters.isDemoUser ? 'not-allowed-cursor' : 'watchdog-dashboard-link'">
          Bulk runs
        </component>
      </div>
      <div class="buttons-area">
        <div class="action-buttons-area">
          <demo-plans :isSmall="true" />
          <v-btn v-if="[
              'Sites', 'Campaign',
              'ProjectLevelWorkStation', 'PageLevelWorkStation', 'ProgressNotes'
            ].includes($route.name)"
            rounded small color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            :disabled="$store.getters.isDemoUser"
            :to="`/newsite`">
            <i class="material-icons">add</i>
            <span>New project</span>
          </v-btn>
          <v-menu v-else
            offset-y bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="$store.getters.isDemoUser" rounded small color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none" v-bind="attrs" v-on="on">
                <i class="material-icons">add</i>
                <span>Create new</span>
                <i class="material-icons-outlined">expand_more</i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link :to="`/newsite`">
                <v-list-item-title>New project</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="addPage" v-if="['ListTools', 'Pages', 'PageRuns', 'PageRun'].includes($route.name)">
                <v-list-item-title>New page</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <div class="modal" :class="{ 'is-active': getModalDataState.value }">
            <div class="modal-background" @click.stop="closeAction()"></div>
            <div class="modal-content">
              <div class="box modal-container" style="overflow: hidden;">
                <div>
                  <div class="title-font is-size-5 mb20 mainColor--text">
                    <div class="subtitle-font mainColor--text">
                      {{ getModalDataState.schemaType }} schema report is ready
                    </div>
                  </div>
                  <div class="control flex ">
                    <div class="flex-grow-1"></div>
                    <v-btn rounded color="profileAnchorColor" class="button-with-button text-none whiteColor--text"
                      @click="navigate()">
                      Go to page
                    </v-btn>
                    <v-btn rounded color="profileAnchorColor" class="button-with-button text-none whiteColor--text"
                      @click="closeAction()">
                      Close
                    </v-btn>
                  </div>
                </div>
              </div>
              <button class="custom-modal-close-btn" aria-label="close" @click="closeAction()">
                <i class="material-icons">close</i>
              </button>
            </div>
          </div>

          <template v-if="($route.name == 'PageRuns' && lastPageRunForCurrentPage.pageNotBuilt) || ($route.name == 'PageRun' && pageRun.pageNotBuilt)">
            <v-menu offset-y bottom v-if="['PageRuns', 'PageRun'].includes($route.name)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn rounded outlined small color="mainColor" class="button-with-icon text-none" v-bind="attrs"
                  v-on="on">
                  <i class="material-icons">loop</i>
                  <span>Re-run options</span>
                  <i class="material-icons-outlined">expand_more</i>
                </v-btn>
              </template>
              <v-list>
                <template v-if="$route.name === 'PageRuns'">
                  <v-list-item link @click="makeRerunAnalysis">
                    <v-list-item-title>Re-run</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="() => runBuiltPage(lastPageRunForCurrentPage.id)">
                    <v-list-item-title>Run built page</v-list-item-title>
                  </v-list-item>
                </template>
                <template v-if="$route.name == 'PageRun'">
                  <v-list-item link @click="rerunAnalysisForThisReport">
                    <v-list-item-title>Re-run</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="() => { runBuiltPage() }">
                    <v-list-item-title>Run built page</v-list-item-title>
                  </v-list-item>
                </template>

              </v-list>
            </v-menu>
          </template>
          <template v-else>
            <template v-if="$route.name === 'PageRuns' && this.pageRuns.length > 0">
              <v-btn :disabled="$store.getters.isDemoUser" rounded small outlined color="mainColor" class="button-with-icon mainColor--text text-none"
                @click="makeRerunAnalysis">
                <i class="fa fa-refresh"></i>
                <span>Re-run</span>
              </v-btn>
            </template>
            <template v-if="$route.name == 'PageRun'">
              <v-btn :disabled="$store.getters.isDemoUser" rounded small outlined color="mainColor" class="button-with-icon mainColor--text text-none"
                @click="rerunAnalysisForThisReport">
                <i class="fa fa-refresh"></i>
                <span class='has-text-centered'>Re-run</span>
              </v-btn>
            </template>
          </template>


          <v-btn rounded small outlined color="mainColor" class="button-with-icon mainColor--text text-none"
            :disabled="$store.getters.isDemoUser" :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`">
            <i class="fa fa-money"></i>
            <span class="">Buy credits</span>
          </v-btn>

          <silo-orders-cart v-if="isWhiteGloveUser" />

        </div>
        <div class="action-buttons-on-mobile">
          <v-btn fab x-small color="mainColor" :disabled="$store.getters.isDemoUser" class="white--text" id="buttons-on-mobile">
            <v-icon>menu</v-icon>
          </v-btn>
          <v-menu activator="#buttons-on-mobile" bottom offset-y>
            <v-list>
              <v-list-item>
                <v-list-item-title tag="v-btn" rounded block color="greenColor"
                  class="button-with-icon white--text text-none" :to="`/guard-dashboard`">
                  <i class="material-icons">shield</i>
                  <span>Watchdog dashboard</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title tag="v-btn" rounded block outlined color="mainColor"
                  class="button-with-icon mainColor--text text-none"
                  :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`">
                  <i class="fa fa-money"></i>
                  <span class="">Buy credits</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title tag="v-btn" rounded block
                  class="button-with-icon whiteColor--text text-none" :to="`/newsite`">
                  <i class="material-icons">add</i>
                  <span>New project</span>
                </v-list-item-title>
              </v-list-item>
              <template v-if="$route.name === 'Pages' || $route.name === 'PageRuns'">
                <v-list-item>
                  <v-list-item-title tag="v-btn" rounded block
                    class="button-with-icon whiteColor--text text-none" @click="addPage" v-if="!user.isVendor">
                    <i class="fa fa-plus-circle"></i>
                    <span>New page</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$route.name === 'PageRuns' && this.pageRuns.length > 0">
                  <v-list-item-title tag="v-btn" rounded block outlined color="mainColor"
                    class="button-with-icon mainColor--text text-none" @click="makeRerunAnalysis">
                    <i class="fa fa-refresh"></i>
                    <span>Re-run</span>
                  </v-list-item-title>
                </v-list-item>
              </template>

              <template v-if="$route.name == 'PageRun'">
                <v-list-item>
                  <v-list-item-title tag="v-btn" rounded block
                    class="button-with-icon mainColor--text text-none" @click="rerunAnalysisForThisReport">
                    <i class="fa fa-refresh"></i>
                    <span class='has-text-centered'>Re-run</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="pageRun.pageNotBuilt">
                  <v-list-item-title tag="v-btn" rounded block
                    class="button-with-icon mainColor--text text-none" @click="runBuiltPage">
                    <i class="fa fa-toggle-right"></i>
                    <span class='has-text-centered'>Run built page</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title tag="v-btn" rounded block
                    class="button-with-icon mainColor--text text-none" @click="runBuiltPage" v-if="!user.isVendor">
                    <i class="fa fa-plus-circle"></i>
                    <span class='has-text-centered'>New page</span>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
        <div>
          <div class="dropdown is-right is-hoverable custom-dropdown-menu ml-3">
            <div class="dropdown-trigger">
              <a class="d-flex align-center" aria-haspopup="true" aria-controls="dropdown-menu">
                <div class="profile-logo">
                  <i class="material-icons person-icon">account_circle</i>
                </div>
                <div class="d-flex align-center mainColor--text">
                  <i class="material-icons">expand_more</i>
                </div>
              </a>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div class="mainColor--text info-area flex align-center">
                  <span class="mr5">
                    <div class="profile-logo">
                      <i class="material-icons person-icon">account_circle</i>
                    </div>
                  </span>
                  <div class="flex-grow-1">
                    <div class="email-label">
                      {{ $store.getters.isDemoUser ? "Demo User" : user.email }}
                    </div>
                    <div
                      v-if="!$store.getters.isDemoUser"
                      class="font-weight-bold darkGrayColor--text"
                    >
                      <div
                        v-for="(text, idx) in accountBalanceLabels"
                        :key="idx"
                      >
                        {{ text }}
                      </div>
                    </div>
                    <div
                      class="flex font-weight-bold"
                      v-if="isUnlimitedUser && !$store.getters.isDemoUser"
                    >
                      <span class="darkGrayColor--text"> Reports limit: </span>
                      <span class="profileAnchorColor--text"
                        >&nbsp;Unlimited</span
                      >
                    </div>
                  </div>
                </div>

                <template v-if="!$store.getters.isDemoUser">
                  <router-link v-if="isAdmin" :to="`/admin/${user.id}`" class="dropdown-item">
                    <span class="material-icons-outlined">admin_panel_settings</span>
                    Admin
                  </router-link>

                  <router-link v-if="user && user.id" class="dropdown-item" :to="`/useraccount/${user.id}`">
                    <span class="material-icons-outlined">manage_accounts</span>
                    Account settings
                  </router-link>
                  <router-link
                    :to="isAvailableBulkRun ? `/new-bulk-run` : `/useraccount/${user.id}/?mainTab=billing-and-payments`"
                    class="dropdown-item">
                    <span class="material-icons-outlined">inventory_2</span>
                    Bulk projects
                    <span>
                      <img src="/static/upgrade.png" class="upgrade_img" v-if="!isAvailableBulkRun" />
                    </span>
                  </router-link>
                  <router-link to="/guard-dashboard" class="dropdown-item">
                    <span class="material-icons-outlined">gpp_good</span>
                    Watchdog dashboard
                  </router-link>
                  <router-link to="/ai-writer-log" class="dropdown-item">
                    <span class="material-icons-outlined">auto_fix_high</span>
                    AI articles dashboard
                  </router-link>
                  <router-link :to="`/useraccount/${user.id}?mainTab=credit-logs`" class="dropdown-item">
                    <span class="material-icons-outlined">savings</span>
                    Credit log
                  </router-link>
                  <router-link v-if="hasApiDashboardAccess" to="/api-usage-dashboard" class="dropdown-item">
                    <span class="material-icons-outlined">stacked_bar_chart</span>
                    API usage dashboard
                  </router-link>
                  <router-link v-if="hasOwnerAccounts" :to="`/useraccount/${user.id}?mainTab=switch-account`"
                    class="dropdown-item">
                    <span class="material-icons-outlined">sync_alt</span>
                    Switch account
                  </router-link>
                  <router-link class="dropdown-item" :to="`/admin/${user.id}`" v-if="isAffiliate">
                    <span><i class='material-icons'>currency_exchange</i></span>
                    Affiliate Dashboard
                  </router-link>
                  <a target="_blank" href="https://academy.pageoptimizer.pro/" class="dropdown-item">
                    <span class="material-icons-outlined">playlist_add_check_circle</span>
                    POP Academy
                  </a>
                  <a class="dropdown-item" target="_blank" :href="`https://help.pageoptimizer.pro/`">
                    <span class="material-icons-outlined">live_help</span>
                    Help centre
                  </a>
                  <a class="dropdown-item" target="_blank"
                    :href="`https://pageoptimizer.pro/pageoptimizer-pro-chrome-extension-guide/`">
                    <span class="material-icons-outlined">extension</span>
                    POP chrome extension
                  </a>
                  <hr class="dropdown-divider">
                  <div class="light-darm-menu">
                    <div class="mainColor--text">Theme</div>
                    <div class="light-dark-switch-area">
                      <div @click="() => setDarkMode(false)">
                        <div :class="{ 'is-active': !$vuetify.theme.dark }">
                          <img class="mode-img" src="/static/light-mode.png" />
                        </div>
                        <div class="mode-label">Light</div>
                      </div>
                      <div @click="() => setDarkMode(true)">
                        <div :class="{ 'is-active': $vuetify.theme.dark }">
                          <img class="mode-img" src="/static/dark-mode.png" />
                        </div>
                        <div class="mode-label">Dark</div>
                      </div>
                    </div>
                  </div>
                </template>
                <hr class="dropdown-divider">
                <a @click.stop="logout" class="dropdown-item signout-item">
                  <span>Sign out</span>
                  <i class='fa fa-sign-out'></i>
                </a>

                <!-- <div>
                <a class="dropdown-item has-sub-account">
                  <span class="icon is-small">
                    <i class='fa fa-medkit'></i>
                  </span>
                  Services
                </a>
                <div class="sub-menu">
                  <ul>
                    <li>
                      <a class="dropdown-item" href="https://pageoptimizer.pro/done-for-you/" target="_blank">
                        <span class="icon is-small">
                          <i class='fa fa-link'></i>
                        </span>
                        Done For You
                      </a>
                    </li>
                  </ul>
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <run-best-practices-model ref='bestPractices' />

      <insufficient-fund-modal ref="insufficientFundModal" />
    </div>
  </div>
</template>


<script>
import RunBestPracticesModel from '@/components/PageRun/RunBestPracticesModal.vue'
import InsufficientFundModal from '@/components/InsufficientFundModal.vue'
import DemoPlans from '@/components/DemoPlans.vue'
import { mixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { mixin as notificaitons_mixin } from '@/components/mixins/common_notifies';
import { DEMO_SESSION_KEYS, DEMO_PRICES } from "@/components/PageRun/constants";
import SiloOrdersCart from '@/components/Campaigns/silo/SiloOrdersCart.vue'
import { getPureServicePkgName } from '@/utils'
import { whiteGlovePlans } from "@/components/plans_constants"

export default {
  name: 'NewHeader',
  mixins: [mixin, ServiceMixin, notificaitons_mixin,],
  components: { DemoPlans, RunBestPracticesModel, InsufficientFundModal, SiloOrdersCart },
  data() {
    return {};
  },
  computed: {
    DEMO_PRICES () {
      return DEMO_PRICES
    },
    getModalDataState() {
      return this.$store.getters.getModalData;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    user() {
      return this.$store.state.user
    },
    ownerUser() {
      return this.$store.state.selectedSubAccountRelation || this.user
    },
    hasApiDashboardAccess () {
      return this.$store.getters.hasApiDashboardAccess
    },
    hasPOPApiKey () {
      return this.$store.getters.hasPOPApiKey
    },
    isWatchdogOnlyUser() {
      return this.$store.getters.isWatchdogOnlyUser
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isHideSidebar() {
      return this.$store.state.isHideSidebar
    },
    availableReportCreditsOfUserOrTeam() {
      return this.$store.getters.availableReportCreditsOfUserOrTeam
    },
    canCreateNewPage() {
      if (this.availableReportCreditsOfUserOrTeam >= 2) {
        return true;
      }
      return false;
    },
    canCreateRerun() {
      if (this.availableReportCreditsOfUserOrTeam >= 1) {
        return true;
      }
      return false;
    },
    currentSiteId() {
      if (this.$route.params.siteId) {
        return this.$store.state.currentSiteId
      }
      return ''
    },
    isAffiliate() {
      if (this.user) {
        return this.user.isAffiliate
      }
      return false
    },
    isAvailableBulkRun() {
      // console.log('seleted sub account relation', this.$store.state.selectedSubAccountRelation)
      return (this.$store.state.selectedSubAccountRelation || this.isUnlimitedUser)
    },
    isPartnershipApiKeyAllowed() {
      return this.$store.getters.isPartnershipApiKeyAllowed
    },
    site() {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId))
    },
    page() {
      if (!this.site || !this.site.pages.length) {
        return {}
      }
      return this.site.pages.find((p) => p.id === parseInt(this.$route.params.pageId))
    },
    accountBalanceLabels() {
      if (!this.user) {
        return ['', '']
      }
      return this.getAccountBalanceLabel(this.ownerUser)
    },
  },
  methods: {
    expandSideBarAction() {
      this.$store.commit('setIsUserClosedSideBar', false)
      this.$store.commit('setHideSideBar', false)
    },
    changeTopBarVisibility(payload) {
      this.$store.commit('setHideTopBar', payload)
    },
    addPage() {
      if (this.isWatchdogOnlyUser) {
        this.showCanNotCreatePageBecauseYouWatchdogUserNotification();
        return;
      }
      if (this.canCreateNewPage) {
        this.errMsg = ''
        this.$router.push(`/sites/${this.site.id}/newpage`)
      } else {
        this.$refs.insufficientFundModal.show()
        this.errMsg = 'Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.'
      }
    },
    makeRerunAnalysis() {
      if (this.canCreateRerun) {
        this.errMsg = ''
        this.rerunAnalysis()
      } else {
        this.$refs.insufficientFundModal.show()
        this.errMsg = 'Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.'
      }
    },
    gotoBulkProjectPage() {
      if (!this.isAgencyUnlimitedUser) { return }
      this.$router.push('/new-bulk-run')
    },
    showBestPractices() {
      this.$refs.bestPractices.show()
    },
    logout() {
      Object.values(DEMO_SESSION_KEYS).forEach(key => sessionStorage.removeItem(key))
      this.$store.commit('clearStore')
      this.$router.push('/login')
    },
    setDarkMode (isDarkMode) {
      this.$vuetify.theme.dark = isDarkMode;
      let settings = Object.assign({}, this.user.settings)
      settings['isDarkMode'] = isDarkMode;
      this.$store.dispatch('updateUserSettings', settings)
      .then((response) => {
        this.$store.commit('setUserSettings', response.data)
      })
      .finally(() => {
      })
    },
    navigate() {
      let filePath = this.getModalDataState.currentLocation;
      if (filePath.startsWith("#")) {
        filePath = filePath.slice(1);
      }
      this.$router.push(filePath)
      this.$store.commit('setPopUpModal', { pageRunId: this.getModalDataState.pageRunId, currentLocation: '', value: false, schemaType: this.getModalDataState.schemaType })
    },
    closeAction() {
      this.$store.commit('setPopUpModal', { pageRunId: this.getModalDataState.pageRunId, currentLocation: '', value: false, schemaType: this.getModalDataState.schemaType })
    },
  },
  watch: {
    // Watch for changes in modalData
    getModalDataState: {
      handler(newVal, oldVal) {
        console.log('modalData changed', newVal);
      }
    }
  },

}
</script>
<style lang="scss">
.button-with-icon {

  i,
  span {
    font-weight: normal;
  }
}

.not-allowed-cursor {
  cursor: not-allowed;
  font-size: 1rem;
  color: var(--v-mainColor-base);
  font-weight: 700;
  cursor: pointer;
}

.header-area {
  position: relative;
  border-bottom: 1.5px solid var(--v-grayColor-base);
}

.fold-icon {
  font-size: 1rem;
  color: var(--v-profileAnchorColor-base);
}

.open-sidebar-icon {
  font-size: 1.5rem;
  color: var(--v-mainColor-base);
  transform: rotate(-90deg);
  cursor: pointer;
}

.new-header {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  padding: 0px 20px 5px 20px;
  /* border-bottom: 1px solid white; */
  flex-wrap: wrap;

  .header-left-side {
    display: flex;
    align-items: center;
    gap: 20px;

    .watchdog-dashboard-link {
      font-size: 1rem;
      color: var(--v-mainColor-base);
      font-weight: 700;
      cursor: pointer;
    }
  }


  .buttons-area {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .action-buttons-area {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
    }

    .action-buttons-on-mobile {
      flex-grow: 1;
      display: none;
    }
  }


  .back-to-home {
    color: var(--v-mainColor-base)
  }

  .project-name {
    color: var(--v-mainColor-base)
  }

  .bulk-run-button {
    background: #fffaf2;
    border: 1px solid #ffebcc;
    color: black;
  }

  .custom-dropdown-menu {
    .dropdown-menu {
      width: 300px !important;

      .dropdown-content {
        border-radius: 10px !important;
          background: var(--v-cardBg-base);
          max-height: calc(100vh - 100px);
          overflow: auto;
      }
    }

    .has-sub-account {
      cursor: default;
      color: grey;

      &:hover {
        background: none;
      }

      /* &:hover + .sub-menu,
        & .sub-menu:hover {
          background: red;
          display: block;
        } */
    }

    .sub-menu {
      text-align: left;
      padding-left: 15px;
    }

    /* .dropdown-item, .dropdown .dropdown-menu .has-link a {
        font-size: 16px !important;
      } */
  }

  .dropdown-content {
    .email-label {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 6px;
      overflow-wrap: anywhere;
      color: var(--v-mainColor-base);
    }
  }

  .dropdown-item {
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
    font-size: 14px;

    img {
      width: 14px;
      height: 15px;
    }

    img.upgrade_img {
      margin-left: 1rem;
      width: 70px;
      height: 20px;
    }

    i,
    span {
      font-size: 1.2rem;
    }

    &> :first-child {
      width: 33px;
    }
  }

  .signout-item {
    display: flex;
    padding-left: 50px;
    padding-right: 25px;
    color: var(--v-profileAnchorColor-base);

    &>span:first-child {
      flex-grow: 1;
    }

    i {
      font-size: 1rem;
    }
  }

  .profile-logo {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;

    i {
      font-size: 2.8rem;
      color: #7895A7;
    }
  }

  .dropdown-menu .profile-logo {
    margin: 0 11px 0px 20px;
  }

  .info-area {
    padding: 10px 5px 10px 0px;
  }

  /* @media screen and (max-width: 600px) {
      .navbar-item {
        &.has-dropdown {
          .navbar-dropdown {
            display: none;
          }
          &.is-active {
            .navbar-dropdown {
              display: block;
            }
          }
        }
      }
    } */
}

@media screen and (max-width: 780px) {
  .new-header {
    display: grid;
    grid-template-columns: 1fr;

      .buttons-area {
        .action-buttons-area {
          display: none;
        }
        .action-buttons-on-mobile {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }    
  }
  .light-darm-menu {
    padding: 10px 10px 10px 20px; 
    .light-dark-switch-area {
      margin-top: 10px;
      display: flex;
      gap: 15px;
      & > div {
        cursor: pointer;
        position: relative;
        & > div {
          display: flex;

          & {
            border: 2px solid transparent;
          }
          &.is-active {
            border-radius: 5px;
            border: 2px solid var(--v-profileAnchorColor-base);
            &::before {
              position: absolute;
              display: inline-block;
              font-family: 'Material Icons';
              content: '\e86c';
              color: var(--v-green21Color-base);
              font-size: 1rem;
              top: 5px;
              right: 5px;
              
            }
          }
        }
        
        .mode-img {
          width: 80px;
          height: auto;
          
        }
        .mode-label {
          color: var(--v-mainColor-base);
        }
      }
    }
  }
</style>
