<template>
<div class="mt-5 compopnent-root">
  <div class="cluster-chart-root"></div>
  <!-- {{ clusterData }} -->
  <div class="tree-wrapper">
    <v-chart
      :option="options"
      @click="clickedClusterInChart" autoresize
      class="cluster-echart"
      :theme="theme">
    </v-chart>
    <!-- <tree
      ref="tree"
      :identifier="getId"
      :data="treeData"
      :zoomable="treeOption.zoomable"
      :node-text="treeOption.nodeText"
      :margin-x="treeOption.Marginx"
      :margin-y="treeOption.Marginy"
      :radius="treeOption.radius"
      :type="treeOption.type"
      :layoutType="treeOption.layoutType"
      :duration="treeOption.duration"
      class="tree"
      @clickedText="clickedText"
    >
    </tree> -->
  </div>
</div>
</template>

<script>
// import * as d3 from 'd3'
import { tree } from 'vued3tree';
import { use } from 'echarts/core';
import { TreeChart } from 'echarts/charts'
import { TitleComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart, {} from 'vue-echarts';

use([ TitleComponent, TreeChart, CanvasRenderer ])


export default {
  props: {
    topics: {
      default: [],
    },
    showClustersCount: {
      default: 10,
    },
    clusterNames: {
      default: () => []
    }
  },
  components: {
    tree,
    VChart,
  },
  computed: {
    serializedTopics () {
      return this.topics.map(topic => {
        const maxLength = 35
        //trim the string to the maximum length
        let trimmedString = topic.substr(0, maxLength);
        if (trimmedString.length < topic.length) {
          trimmedString = trimmedString + '...'
        }
        return trimmedString
      }).join('\n\n')
    },
    treeData () {
      let clusterNames = this.clusterNames.slice(0, 10)
      if (this.clusterNames.length > this.showClustersCount) {
        clusterNames = [...clusterNames, 'Load more clusters']
      }

      let rlt = {
        text: this.serializedTopics,
        children: clusterNames.map(name => {
          return {
            text: name
          }
        })
      }
      console.log(rlt);
      return rlt;
    },
    options () {
      let clusterNames = this.clusterNames.slice(0, 10)
      let childs = clusterNames.map(item => {
        return {
          name: item,
          type: 'cluster-node',
          itemStyle: {
            color: this.$vuetify.theme.currentTheme['profileAnchorColor'],
          },
        }
      })

      if (this.clusterNames.length > this.showClustersCount) {
        childs.push({
          name: 'Load more clusters',
          type: 'load-more',
          label: {
            color: this.$vuetify.theme.currentTheme['blue5Color'],
            fontWeight: 'bold',
            fontStyle: 'underline',
            "fontFamily": "Mulish"
          },
          itemStyle: {
            color: 'transparent',
          }
        })
      }

      return {
        // title: {
        //   text: 'This is test',
        //   left: 'center',
        // },
        series: [
          {
            type: 'tree',
            textStyle: {
              color: 'red',
            },
            lineStyle: {
              type: 'dashed',
              curveness: 0.6,
              color: this.$vuetify.theme.currentTheme['profileAnchorColor'],
            },
            data: [
              {
                name: this.serializedTopics,
                itemStyle: {
                  color: 'transparent',
                },
                label: {
                  fontSize: 21,
                  fontWeight: 'bold',
                  color: this.$vuetify.theme.currentTheme['mainColor'],
                  "fontFamily": "Mulish"
                },
                children: childs,
              }
            ],
            top: '1%',
            left: '25%',
            bottom: '1%',
            right: '25%',
            symbol: 'circle',
            symbolSize: 12,
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 20,
              "fontFamily": "Mulish"
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
                fontSize: 20,
                color: this.$vuetify.theme.currentTheme['mainColor'],
                "fontFamily": "Mulish"
              }
            },
          }
        ]
      }
    },
    theme () {
      return {

      }
    }
  },
  data () {
    return {
      treeOption: {
        type: "tree",
        duration: 750,
        Marginx: 100,
        Marginy: 100,
        radius: 4,
        nodeText: "text",
        currentNode: null,
        zoomable: false,
        layoutType: 'horizontal',
        isLoading: false,
      },
    }
  },
  methods: {
    getId(node) {
      return node.text.replaceAll(' ', '_');
    },
    clickedText(option) {
      let { element } = option
      console.log("element", element);
      if (element.depth == 1) {
        if (element.data.text == 'Load more clusters') {
          this.$emit('showMoreClusters');
        } else {
          this.$emit('showClusterDetail', element.data.text);
        }
      }
    },
    clickedClusterInChart(param) {
      if (param.data) {
        if (param.data.type == 'cluster-node') {
          this.$emit('showClusterDetail', param.data.name);
        } else if (param.data.type == 'load-more') {
          this.$emit('showMoreClusters');
        }
      }
    }

  },
  mounted () {
    
  }
}
</script>

<style lang="scss">
.compopnent-root {
  overflow-x: auto;
  min-width: 1000px;
}

.tree-wrapper {
  height: 630px;
  // max-width: 900px;
  margin: auto;

  .graph-root {
    height: 550px;
    width: 100%;
    color: #FF5D0A;
    background: #FF5D0A;
  }
  
  .node {
    circle {
      fill: #FF5D0A !important;
    }
    text {
      fill: red !important;
      background: blue !important;
    }
  }

  path {
    stroke: #FF5D0A !important;
    // stroke: linear-gradient(to right, rgba(255, 93, 9, 1), blue) !important;
    stroke-dasharray: 5, 2;
  }
}

</style>