<template>
  <div>
    <div class="modal" :class="{ 'is-active': !!popUpSilo }">
      <div class="modal-background" @click="ModalClose"></div>
      <div class="modal-content">

        <div class="box modal-container">
          <div class="bottom-boder">
            <div class="p-5">
              <div class="select-a-setup-type">
                Create new silo
              </div>
              <button class="custom-modal-close-btn" aria-label="close" @click="ModalClose">
                <i class="material-icons">close</i>
              </button>
            </div>
          </div>
          <div class="modal-content-root px-5 pb-5">
            <v-text-field outlined dense hide-details class="mt-2 bg-white" label="Enter silo name"
              v-model="searchTerm">
            </v-text-field>

            <div class="continue-button-area">
              <v-btn rounded class="button-with-icon back-button-text mr-2 mainColor--text text-none"
                @click="ModalClose">
                <span>Cancel</span>
              </v-btn>
              <v-btn :disabled="searchTerm?.length < 3" @click="onSubmit" rounded color="profileAnchorColor" class="continue-button-text whiteColor--text text-none">
                <span>Create</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popUpSilo'],
  data() {
    return {
      searchTerm: '',
      isFormSubmit: false,
    };
  },
  methods: {
    onSubmit() {
      this.isFormSubmit = true
      if (!this.searchTerm) return

      this.$store.commit('showLoading')

      this.$store.dispatch('createSilo', { campaignId: this.$route.params.campaignId, name: this.searchTerm }).then((silo) => {
        this.$emit("closeModal");
        this.$store.commit('setIsNewSiloUpdates', true);
        this.$emit("addKeywordToSilo", this.popUpSilo, { ...silo.data.data, silo_keywords: [] }, true);
        this.$notify({
          group: 'info', type: 'success',
          text: 'Successfully created silo.'
        });
      }).finally(() => this.$store.commit('hideLoading'))
    },
    ModalClose() {
      this.$emit("closeModal");
    },
  },
  computed: {
    isError() {
      return this.isFormSubmit && !this.searchTerm;
    }
  },
  watch: {
    popUpSilo: function () {
      this.searchTerm = ''
    }
  },
};
</script>

<style lang="scss" scoped>
.bottom-boder {
  display: flex;
  border-bottom: 1px solid var(--v-grayColor-base);
}

.modal-content {
  width: 27.223vw;
  max-width: 27.223vw;
}

.modal-container {
  padding: 0 !important;
}

.material-icons {
  margin-top: -10px;
  color: var(--v-mainColor-base);
}

.back-button-text {
  border: 1px solid var(--v-mainColor-base);
  height: 38px !important;
  background: none !important;

  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }
}

.continue-button-text {
  height: 38px !important;

  i,
  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }

  i {
    margin-top: 2px;
  }
}

.select-a-setup-type {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
  color: var(--v-mainColor-base);
}

.continue-button-area {
  margin-top: 25px;
  text-align: right;
}
.bg-white {
  background-color: var(--v-whiteColor-base);
}

</style>
