<template>
<div>
  <router-view
    :campaignId="campaignId"
    :hasUnlimitedAccess="hasUnlimitedAccess"
  ></router-view>
</div>
</template>

<script>
export default {
  props: ['hasUnlimitedAccess'],
  computed: {
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
  },
  methods: {

  },
  mounted () {
    
  }
}
</script>

<style scoped lang="scss">
@import "../../keyword-research.scss";



</style>