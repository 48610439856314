<template>
  <div class="component-root">
    <div class="cluster-chart-root"></div>
    <div class="tree-wrapper">
      <v-chart :option="option" autoresize class="cluster-echart" :theme="theme">
      </v-chart>
    </div>
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { GraphChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts';

use([TitleComponent, TooltipComponent, GraphChart, CanvasRenderer])

export default {
  props: {
    topic: {
      type: String,
      default: '',
    },
    showClustersCount: {
      type: Number,
      default: 10,
    },
    clusterNames: {
      type: Array,
      default: () => []
    },
    graphData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    VChart,
  },
  computed: {
    option() {
      return {
        title: {
          text: ''
        },
        tooltip: {},
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'none',
            symbolSize: [100, 50],
            symbol: 'image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjUwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iNTAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjRkY1RDBBIiBzdHJva2U9IiNFRTM0QTQiIHN0cm9rZS13aWR0aD0iMiIgLz48L3N2Zz4=',
            roam: true,
            label: {
              show: true,
              color: '#014751',
            },
            itemStyle: {
              color: '#FFE1F34D',
              borderColor: '#EF34A4',
              borderWidth: 2,
              borderRadius: 10,
            },

            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [4, 10],
            edgeLabel: {
              fontSize: 20
            },
            data: [
              { name: this.graphData.center, x: 550, y: 300, itemStyle: { color: '#CFFFD64D', borderColor: '#007438' } },
              ...this.graphData.left.map((name, idx) => ({ name, x: 300, y: 100 * (idx + 1), itemStyle: { color: '#FFE1F34D' } })),
              ...this.graphData.right.map((name, idx) => ({ name, x: 800, y: 100 * (idx + 1), itemStyle: { color: '#FFE1F34D' } })),
            ],
            links: [
              // Links from center to left nodes
              ...this.graphData.left.map(source => ({ source, target: this.graphData.center })),
              // Links from center to right nodes
              ...this.graphData.right.map(source => ({ source, target: this.graphData.center })),
              // Links between left nodes
              ...this.graphData.left.flatMap((source, idx) => {
                const nextIdx = idx + 1
                if (nextIdx >= this.graphData.left.length) return []

                const nextNode = this.graphData.left[nextIdx]

                return [{ source, target: nextNode }, { source: nextNode, target: source }]
              }),
              // Links between right nodes
              ...this.graphData.right.flatMap((source, idx) => {
                const nextIdx = idx + 1
                if (nextIdx >= this.graphData.right.length) return []

                const nextNode = this.graphData.right[nextIdx]

                return [{ source, target: nextNode }, { source: nextNode, target: source }]
              }),
            ],
            lineStyle: {
              opacity: 0.9,
              width: 1,
              curveness: 0.2
            }
          }
        ]
      };
    }
  },
  data() {
    return {
      treeOption: {
        type: "graph",
        duration: 750,
        Marginx: 100,
        Marginy: 100,
        radius: 4,
        nodeText: "text",
        currentNode: null,
        zoomable: false,
        layoutType: 'horizontal',
        isLoading: false,
      },
    }
  },
}
</script>

<style lang="scss">
.component-root {
  overflow-x: auto;
  min-width: 1000px;
}

.tree-wrapper {
  height: 630px;
  margin: auto;

  .cluster-echart {
    background-color: var(--v-whiteColor-base)!important;
  }

}
</style>
