<template>
<div class="mt-10" v-if="canAddDevUser">
  <div class="comp-title">Add collaborators</div>
  <div class="mt-5">
    If you want to add sub-accounts for your collaborators, here you can send out email invitations and control permissions or delete your sub-accounts.  
  </div>
  <div class="mt-5 title1">
    Send invitation
  </div>
  <div class="mt-5 d-flex align-center gap10" style="max-width: 350px">
    <div class="flex-grow-1">
      <v-text-field outlined dense hide-details
        label="Enter email"
        color="greenColor"
        v-model="inviteDevEmail"></v-text-field>
    </div>
    <v-btn rounded
      color="profileAnchorColor"
      class="text-none whiteColor--text"
      :disabled="!inviteDevEmail"
      @click="addDevAccountAction">
      <span>Send</span>
    </v-btn>
  </div>
  <div class="mt-5 white-common-box px-5 py-8">
    <div class="title1 mb-5">Collaborators</div>
    <div v-for="(dev, idx) in user.devAccounts" :key="dev.devUserEmail" class="dev-account">
      <div class="email">
        {{ dev.devUserEmail }}
      </div>
      <div class="actions">
        <!-- <span class="material-icons" @click="() => {
        }">edit</span> -->
        <span class="material-icons" @click="() => {
          selectedDevAccountId = dev.id;
          showDeleteDevAccountModal = true;
        }">delete_forever</span>
      </div>
    </div>
  </div>



  <div class="modal" :class="{ 'is-active': showDeleteDevAccountModal }">
    <div class="modal-background" @click="showDeleteDevAccountModal = false;"></div>
    <div class="modal-content" style="max-width: 650px">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="subtitle-font mainColor--text">Do you want to delete this dev account?</p>
          <div class="mt-5 d-flex align-center justify-end gap10">
            <v-btn rounded color="profileAnchorColor" class="button-with-icon whiteColor--text text-none"
              @click="() => deleteDevAccountAction()">Yes</v-btn>
            <v-btn rounded color="darkGrayColor" class="button-with-icon whiteColor--text text-none"
              @click="showDeleteDevAccountModal = false;">No</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import validator from "validator";

export default {
  computed: {
    user () {
      return this.$store.state.user;
    },
    canAddDevUser () {
      if (!this.user) {
        return false;
      }
      if (this.user.isPartnershipApiKeyAllowed && !this.user.isPartnershipApiBlocked) {
        return true;
      }
      if (this.user.isDevApiKeyAllowed) {
        return true;
      }
      return false;
    },
  },
  data () {
    return {
      inviteDevEmail: '',
      selectedDevAccountId: null,
      showDeleteDevAccountModal: false,
    }
  },
  methods: {
    addDevAccountAction () {
      if (!validator.isEmail(this.inviteDevEmail)) {
        this.$notify({
          group: 'info', type: 'error',
          text: 'Invalid email.'
        })
        return;
      }
      
      this.$store.commit('showLoading');
      this.$store.dispatch('addDevAccount', {
        devAccountEmail: this.inviteDevEmail,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          if (response.data.user) {
            this.$store.commit('setUser', response.data.user);
          }
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully added the developer to your account'
          })
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: response.data.msg || 'Failed to add dev account'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    deleteDevAccountAction () {
      this.$store.commit('showLoading');
      this.$store.dispatch('deleteDevAccount', {
        id: this.selectedDevAccountId,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          if (response.data.user) {
            this.$store.commit('setUser', response.data.user);
          }
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully deleted.'
          })
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to delete dev account'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.comp-title {
  font-size: 1rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
}
.title1 {
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
}
.dev-account {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  margin-bottom: 10px;
  background: var(--v-pink1Color-base);
  border-radius: 5px;
  .email {
    flex-grow: 1;
    font-weight: 700;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      font-size: 1.5rem;
      color: var(--v-profileAnchorColor-base);
      cursor: pointer;
    }
  }
}
</style>