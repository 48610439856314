<template>
<div id="demo">
  <div id="rect-container">
    <div v-if="isCalculating" class="demo-spinner">
      <div>
        <div class="spinner-title">
          On page analysis...
        </div>
        <div class="mt-10 spinner-explain">
          It takes on average 70 seconds to process your score, but can take up to 3 minutes depending on data input.
        </div>
        <div class="mt-10 percentage-area">
          <!-- <progress class="progress" :value="calcPercentage" max="100"></progress> -->
          <div class="progress-bar1" :style="{ width: calcPercentage + '%'}"></div>
        </div>
        <div class="status-area">
          <div class="flex-grow-1">{{ calcMsg }}</div>
          <div class="percentage">{{ calcPercentage }} %</div>
        </div>
      </div>
    </div>
    <div v-if="step==1">
      <h1 class="demo-title has-text-centered">Score your page</h1>
      <h2 class="demo-title-explain mt-5 mb-5" style="">
        See how well you stack up against your competitors!
      </h2>

      <form @submit.prevent="submitPageRun">

        <div class="field">
          <label class="label">Target Keyword</label>
          <div class="control">
            <input class="input" type="text" placeholder="Best Protein Powders" v-model="keyword">
          </div>
        </div>
        <div class="field">
          <label class="label">Target Page</label>
          <div class="control">
            <input class="input" type="text" placeholder="https://petes-protein.com/shop" v-model="targetUrl">
          </div>
        </div>
        <div class="field">
          <label class="label">Target Region</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="proxyLocation">
                <option value="" disabled selected>Select</option>
                <option v-for="p in proxies" :key="p.location" :value="p.location">{{ p.location }}</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="inputErrors.length > 0">
          <div class="error-noty is-light mt5 mb5">
            <!-- <template v-for="err in inputErrors"> - {{ err }}<br/></template> -->
            {{ inputErrors[0] }}
          </div>
        </div>
        <div class="has-text-centered mt-10">
          <button type="submit" class="calc-button">Score my page</button>
        </div>
      </form>
      <div class="has-text-centered signup-link mt-5">
        Or&nbsp;&nbsp;<a :href="`${FRONTEND_URL}/#/register/`" target="_blank">sign up now</a>        
      </div>
    </div>
    <div v-else-if="step==2">
      <div class="demo-title has-text-centered">Your On Page Optimization Score!</div>
      <div class="work-type-label text-center mt-3" :style="{ 'color': pageScoreTintColor(score) }">
        <!-- <span class="material-icons">info</span> -->
        {{ workTypeText }}
        <div v-if="workTypeText=='Page Well Optimized'" class="is-size-6">Check for opportunities to get an edge on your competitors </div>
      </div>
      <div class="text-center d-flex justify-center my-10">
        <radial-progress-bar
          :diameter="140"
          :completed-steps="score"
          :total-steps="100"
          :strokeWidth="10"
          :startColor="pageScoreTintColor(score)"
          :stopColor="pageScoreTintColor(score)"
          :innerStrokeColor="'#eeeeee'"
          strokeLinecap="square"
          >
          <div class="has-text-weight-bold" :style="{ 'font-size': '2rem', 'color': pageScoreTintColor(score) }">{{ score | round(1) }}</div>          
        </radial-progress-bar>
      </div>

      <!-- <div class="columns is-desktop over-under-optimized-zone" v-if="!demoPageRun.pageNotBuilt">
        <div class="column">
          <div class="title-label">
            EXACT KEYWORD OPTIMIZATION
          </div>
          <div>
            <under-over-optimize-chart
              :underOptimized="keywordCounts.optimizedKeyword.underOptimized"
              :overOptimized="keywordCounts.optimizedKeyword.overOptimized"
              :underOptimziedTooltipText="`You have ${keywordCounts.optimizedKeyword.underOptimized} under optimized signals for your exact keyword`"
              :overOptimziedTooltipText="`You have ${keywordCounts.optimizedKeyword.overOptimized} over optimized signals for your exact keyword`"
              >
            </under-over-optimize-chart>
          </div>
        </div>
        <div class="column">
          <div class="title-label">
            KEYWORD VARIATION OPTIMIZATION
          </div>
          <div>
            <under-over-optimize-chart
              :underOptimized="keywordCounts.optimizedVariations.underOptimized"
              :overOptimized="keywordCounts.optimizedVariations.overOptimized"
              :underOptimziedTooltipText="`You have ${keywordCounts.optimizedVariations.underOptimized} under optimized signals for the variations of your keyword`"
              :overOptimziedTooltipText="`You have ${keywordCounts.optimizedVariations.overOptimized} over optimized signals for the variations of your keyword`"
              >
            </under-over-optimize-chart>
          </div>
        </div>
      </div>
      <div class="columns is-desktop over-under-optimized-zone" v-if="!demoPageRun.pageNotBuilt">
        <div class="column">
          <div class="title-label">
            LSI OPTIMIZATION
          </div>
          <div>
            <div>
              <under-over-optimize-chart
                :underOptimized="lsiKeywordCounts.optimizedVariations.underOptimized"
                :overOptimized="lsiKeywordCounts.optimizedVariations.overOptimized"
                :underOptimziedTooltipText="`You have ${lsiKeywordCounts.optimizedVariations.underOptimized} under optimized signals for your keyword's contextual terms (LSI)`"
                :overOptimziedTooltipText="`You have ${lsiKeywordCounts.optimizedVariations.overOptimized} over optimized signals for your keyword's contextual terms (LSI)`"
                >
              </under-over-optimize-chart>
            </div>
          </div>
        </div>
      </div> -->
      <div class="has-text-centered signup-link1 mt5">
        Get a detailed step-by-step analysis to increase traffic and rankings.
      </div>
      <button class="calc-button mt-5" @click.stop="gotoPlanPage">Get started now</button>
    </div>
  </div>
</div>
</template>


<script>
import { mixin } from '@/components/mixin'
import RadialProgressBar from 'vue-radial-progress'
import UnderOverOptimizeChart from '@/components/PageRun/UnderOverOptimizeChart.vue'
import { fetchTaskResult } from '@/api'


export default {
  components: {
    RadialProgressBar,
    UnderOverOptimizeChart
  },
  mixins: [mixin],
  computed: {
    cleanedContentBrief() {
      let found = this.demoPageRun.runStrategies.find(item => item.dashboardType == 'contentBrief' )
      if (found) {
        return found
      }
      return {}
    },
    score () {
      if (!this.demoPageRun) {
        return 0
      }
      if (this.competitorsCount == 3) {
        return this.keywordCounts.pageScore.pageScore
      } else {
        return this.cleanedContentBrief['pageScore']['pageScore']
      }
    },
    keywordCounts () {
      return this.demoPageRun.keywordCounts
    },
    adjKeywordCounts () {
      return this.demoPageRun.adjKeywordCounts
    },
    lsiKeywordCounts () {
      return this.demoPageRun['lsaKeywordCounts']
    },
    workTypeText () {
      let value = this.score
      if (value >= 0 && value <=69) {
        return 'Urgent Work Required'
      } else if (value > 69 && value <=80) {
        return 'Missing Traffic Potential'
      } else if (value > 80) {
        return 'Page Well Optimized'
      }
      return ''
    }
  },
  data () {
    return {
      keyword: '',
      targetUrl: '',
      proxyLocation: '',

      proxies: [],

      demoPageRun: JSON.parse(demo_data),

      inputErrors: [],
      timer: null,
      taskId: '',

      step: 1, // 1 input page, 2 result page,
      isCalculating: false,
      calcMsg: 'Starting analysis...',
      calcPercentage: 0,

      competitorsCount: 3,
    }
  },
  methods: {
    downloadFile(downloadUrl) {
      if (!downloadUrl) return;
      let fileName = downloadUrl.split('/').pop()
      let downloadLink;
      downloadLink = document.createElement("a");
      downloadLink.download = fileName;
      downloadLink.href = downloadUrl
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setTimeout(() => {
        document.body.removeChild(downloadLink)
      }, 100);
    },
    downloadContentBrief () {
      let data = {
        downloadType: 'excel',
        pageRunId: this.demoPageRun.id
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('downloadContentBrief', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          let contentBriefDataFile = response.data.contentBriefDataFile
          let downloadUrl = ''
          if (contentBriefDataFile['s3_excel'])
            downloadUrl = contentBriefDataFile['s3_excel']
          else
            downloadUrl = `${API_URL}/download/?file=${contentBriefDataFile['excel']}`
          this.downloadFile(downloadUrl)
        } else {
          console.log('failed to make content brief')
        }
      })
      .catch(error => {
        console.log(error)
        console.log('error check server side')
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    gotoPlanPage () {
      window.open(`${FRONTEND_URL}/#/register`, '_parent')
    },


    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    },
    isValidInput () {
      let errors = []
      if (this.keyword.trim() == '') {
        errors.push('Please input the keyword to optimize')        
      }

      if (this.targetUrl.trim() == '') {
        errors.push('Please input the target url')
      } else if (!this.validURL(this.targetUrl)) {
        errors.push('Please provide valid url')
      }

      if (this.proxyLocation == '') {
        errors.push('You must choose the region ')
      }
      this.inputErrors = errors
      if (errors.length > 0) {
        return false
      }
      return true
    },
    submitPageRun () {
      if (!this.isValidInput()) {
        return
      }
      console.log('ok cool')
      let data = {
        keyword: this.keyword,
        targetUrl: this.targetUrl,
        proxyLocation: this.proxyLocation,
        competitorsCount: this.competitorsCount,
      }
      this.isCalculating = true
      this.$store.dispatch('createDemoPageRun', data)
      .then(response => {
        console.log(response.data)
        if (response.data && (response.data.status == 'SUCCESS' || response.data.status == 'PENDING')) {
          let taskId = response.data.taskId
          return new Promise((resolve, reject) => {
            const timer = setInterval(() => {
              fetchTaskResult(taskId)
              .then(resultResponse => {
                let { status } = resultResponse.data
                if (status === 'SUCCESS') {
                  clearInterval(timer)
                  resolve(resultResponse.data)
                } else if (status === 'FAILURE') {
                  clearInterval(timer)
                  reject(resultResponse.data)
                } else if (status === 'PROGRESS') {
                  console.log('making demo run now ...', taskId, resultResponse.data)
                  if (resultResponse.data.msg && resultResponse.data.value >=0) {
                    this.calcMsg = resultResponse.data.msg
                    this.calcPercentage = resultResponse.data.value
                  }
                }
              })
            }, 3000);
          })
        }
        return response
      })
      .then(response => {
        if (response && response.status === 'SUCCESS') {
          this.demoPageRun = response.demoPageRun
          this.step = 2
        }
      })
      .catch((err) => {
        console.log(err)
        window.alert('Failed to make demo run. Please check whether your target url is correct. if this persists, please contact us with info.')
      })
      .finally(() => this.isCalculating = false)
      

    },
    loadProxies () {
      this.$store.commit('showLoading')
      this.$store.dispatch('fetchAvailableProxies')
      .then((response) => {
        let proxyMap = {}
        response.data.forEach((p) => {
          proxyMap[p.location] = p
        })
        this.proxies = Object.values(proxyMap)
        return 1
      })
      .catch((err) => {
        console.log('Errro fetching proxies', err)
      })
      .finally(() => this.$store.commit('hideLoading'))
    }
  },
  beforeMount() {
    this.loadProxies();
  },
  mounted () {
    let routeName = this.$route.name
    if (routeName == 'DemoPage') {
      this.competitorsCount = 3 // means demo page
    } else if (routeName == 'DemoContentBriefPage') {
      this.competitorsCount = 10 // means demo-for-contentbrief page
    }
  }
}


let demo_data = `
      {
    "adjKeywordCounts": {
      "featuredKwScore": 0,
      "featuredVarsScore": 0,
      "isLSI": false,
      "kwScore": 20.080000000000002,
      "lsiFeaturedScore": 0,
      "lsiScore": 0,
      "optimizedKeyword": {
        "overOptimized": 1,
        "underOptimized": 14
      },
      "optimizedVariations": {
        "overOptimized": 3,
        "underOptimized": 18
      },
      "pageScore": {
        "pageScore": 27.98,
        "pageScoreValue": ""
      },
      "rows": [
        {
          "signal": "Keyword in Main URL",
          "signalData": {
            "comment": "It is recommended that you have your target keyword in your URL 1 time, when possible",
            "competitorsAvg": "",
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "keywordsTrueMax": "",
            "keywordsTrueMaxRecommendations": "",
            "keywordsTrueMin": "",
            "targetKeywordCnt": 0
          },
          "signalTags": "mainURL",
          "variationData": {
            "comment": "",
            "competitor1VariationCnt": "",
            "competitorsMax": "",
            "competitorsMin": "",
            "competitorsVariationsAvg": "",
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": "",
            "variationsTrueMax": "",
            "variationsTrueMaxRecommendations": ""
          }
        },
        {
          "signal": "Meta Title",
          "signalData": {
            "comment": "Add 1 exact keyword in your title tag of the page",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "title",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your title tag by 2",
            "competitor1VariationCnt": 2,
            "competitorsMax": 3.0,
            "competitorsMin": 2.0,
            "competitorsVariationsAvg": 3.6,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "H1",
          "signalData": {
            "comment": "Add 1 exact keyword in your H1 of the page",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h1",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your H1s by 2",
            "competitor1VariationCnt": 2,
            "competitorsMax": 2.0,
            "competitorsMin": 2.0,
            "competitorsVariationsAvg": 2.7,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "H2",
          "signalData": {
            "comment": "Increase the number of exact keywords in your H2s by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 5",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h2",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your H2s by 13",
            "competitor1VariationCnt": 58,
            "competitorsMax": 58.0,
            "competitorsMin": 13.0,
            "competitorsVariationsAvg": 13.99,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 58,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 58"
          }
        },
        {
          "signal": "H3",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h3",
          "variationData": {
            "comment": "Reduce the number of variations of your keyword in your H3s by 2",
            "competitor1VariationCnt": 0,
            "competitorsMax": 1.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 1.36,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 3,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Reduce the number of variations of your keyword by 1"
          }
        },
        {
          "signal": "H4",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h4",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.67,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Paragraph Text",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Paragraph Text by 2",
            "competitorsAvg": 4.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 2
          },
          "signalTags": "p",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Paragraph Text by 16",
            "competitor1VariationCnt": 24,
            "competitorsMax": 28.0,
            "competitorsMin": 20.0,
            "competitorsVariationsAvg": 16.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 4,
            "variationsTrueMax": 24,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 20"
          }
        },
        {
          "signal": "Anchor Text",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "a",
          "variationData": {
            "comment": "Reduce the number of variations of your keyword in your Anchor Text by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 1.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 1.81,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Image alt",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Image Alts by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "img_alt",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Image Alts by 3",
            "competitor1VariationCnt": 8,
            "competitorsMax": 8.0,
            "competitorsMin": 3.0,
            "competitorsVariationsAvg": 3.15,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 8,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 8"
          }
        },
        {
          "signal": "Bold",
          "signalData": {
            "comment": "Reduce the number of exact keywords in your Bolds by 1",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Reduce the number of keyword phrase by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "bold",
          "variationData": {
            "comment": "Reduce the number of variations of your keyword in your Bolds by 2",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.67,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Italic",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "i",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "H2 to H3",
          "signalData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding exact match keywords in H2s or H3s by 1",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h2_h3",
          "variationData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding variations of your keyword in H2s or H3s by 12",
            "competitor1VariationCnt": 58,
            "competitorsMax": 58.0,
            "competitorsMin": 15.0,
            "competitorsVariationsAvg": 15.33,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 3,
            "variationsTrueMax": 58,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 55"
          }
        },
        {
          "signal": "H2 to H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h2_h6",
          "variationData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding variations of your keyword in H2s, H3s, or H4s by 13",
            "competitor1VariationCnt": 58,
            "competitorsMax": 58.0,
            "competitorsMin": 16.0,
            "competitorsVariationsAvg": 16.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 3,
            "variationsTrueMax": 58,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 55"
          }
        },
        {
          "signal": "H4 to H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h4_h6",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.67,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Word Count",
          "signalData": {
            "comment": "Increase your word count to a value similar to the target value 2",
            "competitorsAvg": 1410.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1491,
            "keywordsTrueMaxRecommendations": "value low 83",
            "keywordsTrueMin": 68,
            "targetKeywordCnt": 1408
          },
          "signalTags": "word_count",
          "variationData": {
            "comment": "",
            "competitor1VariationCnt": 1491,
            "competitorsMax": "",
            "competitorsMin": "",
            "competitorsVariationsAvg": 568.55,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 1408,
            "variationsTrueMax": 1491,
            "variationsTrueMaxRecommendations": ""
          }
        },
        {
          "signal": "HTML Head & Body Tags",
          "signalData": {
            "comment": "Increase the number of exact keywords in your HTML Head & Body Tags by 31",
            "competitorsAvg": 35.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 40,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 36",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 4
          },
          "signalTags": "html",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your  HTML Head & Body Tags by 147",
            "competitor1VariationCnt": 191,
            "competitorsMax": 191.0,
            "competitorsMin": 158.0,
            "competitorsVariationsAvg": 158.5,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 11,
            "variationsTrueMax": 191,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 180"
          }
        },
        {
          "signal": "H5",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h5",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h6",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Meta Keywords",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "meta_keywords",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Meta keywords by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 1.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 1.81,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 8,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 8"
          }
        },
        {
          "signal": "Meta Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Meta Descriptions by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "meta_description",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Meta Descriptions by 4",
            "competitor1VariationCnt": 4,
            "competitorsMax": 4.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 4.29,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 4"
          }
        },
        {
          "signal": "Open Graph Title",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Open Graph Titles by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "open_graph_title",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Open Graph Titles by 2",
            "competitor1VariationCnt": 2,
            "competitorsMax": 3.0,
            "competitorsMin": 2.0,
            "competitorsVariationsAvg": 3.15,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Open Graph Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Open Graph Descriptions by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "open_graph_description",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Open Graph Descriptions by 4",
            "competitor1VariationCnt": 4,
            "competitorsMax": 5.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 5.41,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 7,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 7"
          }
        },
        {
          "signal": "Twitter Card Title",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Twitter Card Titles by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "twitter_card_title",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Twitter Card Titles by 1",
            "competitor1VariationCnt": 2,
            "competitorsMax": 2.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.81,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Twitter Card Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Twitter Card Descriptions by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "twitter_card_description",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Twitter Card Descriptions by 4",
            "competitor1VariationCnt": 4,
            "competitorsMax": 4.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 4.07,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 7,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 7"
          }
        },
        {
          "signal": "Image File Names",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "image_file_names",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Image File Names by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 1.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 1.12,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 4"
          }
        },
        {
          "signal": "Figure Caption",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "figure_caption",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Source Code URLs",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Source Code URLs by 31",
            "competitorsAvg": 35.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 40,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 36",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 4
          },
          "signalTags": "source_code_urls",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Source Code URLs by 147",
            "competitor1VariationCnt": 191,
            "competitorsMax": 191.0,
            "competitorsMin": 158.0,
            "competitorsVariationsAvg": 158.5,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 11,
            "variationsTrueMax": 191,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 180"
          }
        },
        {
          "signal": "Microdata (schema format)",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 3,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 3",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "microdata_schema_format",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Microdatas (schema format) by 3",
            "competitor1VariationCnt": 0,
            "competitorsMax": 6.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 6.08,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 27,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 27"
          }
        },
        {
          "signal": "RDF (schema format)",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "rdf_schema_format",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "JSON LD (schema format)",
          "signalData": {
            "comment": "Increase the number of exact keywords in your JSON LD (schema format) by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 3,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 3",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "json_ld_schema_format",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your JSON LDs (schema format) by 5",
            "competitor1VariationCnt": 15,
            "competitorsMax": 15.0,
            "competitorsMin": 5.0,
            "competitorsVariationsAvg": 5.41,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 15,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 15"
          }
        }
      ],
      "varsScore": 2.2
    },
    "commonEatSchemaTypes": {
      
    },
    "commonNlpSchemaData": {
      
    },
    "competitors": [
      {
        "createdAt": "2020-02-26 20:47:04",
        "eatSchemaTypes": {
          
        },
        "id": 519547,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          
        ],
        "url": "https://www.ignitesearch.com.au/dmss-2018-kyle-roof-seo-tests-and-how-can-you-set-them-up/",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:05",
        "eatSchemaTypes": {
          
        },
        "id": 519548,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          
        ],
        "url": "https://www.linkedin.com/in/kyle-roof-411166a4",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:06",
        "eatSchemaTypes": {
          
        },
        "id": 519549,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          {
            "count": 1,
            "type": "WebSite"
          }
        ],
        "url": "https://mpsocial.com/t/get-now-kyle-roof-on-page-seo/95923",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:07",
        "eatSchemaTypes": {
          
        },
        "id": 519550,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          
        ],
        "url": "https://www.crunchbase.com/person/kyle-roof",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:08",
        "eatSchemaTypes": {
          
        },
        "id": 519551,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          
        ],
        "url": "https://www.stitcher.com/podcast/doug-cunnington/the-doug-show/e/63156990",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:08",
        "eatSchemaTypes": {
          
        },
        "id": 519552,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          {
            "count": 1,
            "type": "Article"
          }
        ],
        "url": "https://www.proposify.com/podcast/052",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:10",
        "eatSchemaTypes": {
          
        },
        "id": 519553,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          
        ],
        "url": "https://soundcloud.com/kyle-roof",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:11",
        "eatSchemaTypes": {
          
        },
        "id": 519554,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          
        ],
        "url": "https://trello.com/kyleroof",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:11",
        "eatSchemaTypes": {
          
        },
        "id": 519555,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          
        ],
        "url": "https://www.researchgate.net/profile/kyle_roof",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:13",
        "eatSchemaTypes": {
          
        },
        "id": 519556,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          {
            "count": 1,
            "type": "BreadcrumbList"
          }
        ],
        "url": "https://www.eventbrite.com/e/googles-algorithm-is-an-algorithm-with-kyle-roof-tickets-60900133940",
        "urlSourceCode": ""
      },
      {
        "createdAt": "2020-02-26 20:47:14",
        "eatSchemaTypes": {
          
        },
        "id": 519557,
        "isValid": 1,
        "name": null,
        "nlpSchemaData": {
          
        },
        "pageScore": null,
        "runId": 88124,
        "schemaTypes": [
          {
            "count": 1,
            "type": "VideoObject"
          },
          {
            "count": 1,
            "type": "BreadcrumbList"
          }
        ],
        "url": "https://vimeo.com/195256002",
        "urlSourceCode": ""
      }
    ],
    "createdAt": "2020-02-26 20:45:56",
    "disabled": 0,
    "eatSchemaTypes": {
      
    },
    "eatStatus": null,
    "editedSource": "",
    "excelFile": "/Volumes/Work/Work/WEB/web-projects/2018_08_08_Flask_Vue_maria_project/highvoltageseo/web/Report/DemoRun_2020226154556.xlsx",
    "featuredCompetitors": null,
    "featuredTagCounts": [
      
    ],
    "id": 88124,
    "ignoreSpaces": 0,
    "ipAddress": "127.0.0.1",
    "isBulkRun": false,
    "isContentEditorUsed": false,
    "isEatCalculationUsed": false,
    "isExtensionUsed": false,
    "isGoogleNlpApiUsed": false,
    "isValid": 1,
    "keyword": "kyle roof",
    "keywordCounts": {
      "featuredKwScore": 0,
      "featuredVarsScore": 0,
      "isLSI": false,
      "kwScore": 28.366666666666667,
      "lsiFeaturedScore": 0,
      "lsiScore": 0,
      "optimizedKeyword": {
        "overOptimized": 3,
        "underOptimized": 9
      },
      "optimizedVariations": {
        "overOptimized": 3,
        "underOptimized": 16
      },
      "pageScore": {
        "pageScore": 38.56666666666666,
        "pageScoreValue": ""
      },
      "rows": [
        {
          "signal": "Keyword in Main URL",
          "signalData": {
            "comment": "It is recommended that you have your target keyword in your URL 1 time, when possible",
            "competitorsAvg": "",
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "keywordsTrueMax": "",
            "keywordsTrueMaxRecommendations": "",
            "keywordsTrueMin": "",
            "targetKeywordCnt": 0
          },
          "signalTags": "mainURL",
          "variationData": {
            "comment": "",
            "competitor1VariationCnt": "",
            "competitorsMax": "",
            "competitorsMin": "",
            "competitorsVariationsAvg": "",
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": "",
            "variationsTrueMax": "",
            "variationsTrueMaxRecommendations": ""
          }
        },
        {
          "signal": "Meta Title",
          "signalData": {
            "comment": "Add 1 exact keyword in your title tag of the page",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "title",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your title tag by 1",
            "competitor1VariationCnt": 2,
            "competitorsMax": 2.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.45,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "H1",
          "signalData": {
            "comment": "Add 1 exact keyword in your H1 of the page",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h1",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your H1s by 1",
            "competitor1VariationCnt": 2,
            "competitorsMax": 2.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.09,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "H2",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 5",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h2",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your H2s by 5",
            "competitor1VariationCnt": 58,
            "competitorsMax": 58.0,
            "competitorsMin": 5.0,
            "competitorsVariationsAvg": 5.64,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 58,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 58"
          }
        },
        {
          "signal": "H3",
          "signalData": {
            "comment": "Reduce the number of exact keywords in your H3s by 1",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h3",
          "variationData": {
            "comment": "Reduce the number of variations of your keyword in your H3s by 3",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.55,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 3,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Reduce the number of variations of your keyword by 1"
          }
        },
        {
          "signal": "H4",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h4",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.27,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Paragraph Text",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Paragraph Text by 1",
            "competitorsAvg": 3.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 2
          },
          "signalTags": "p",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Paragraph Text by 6",
            "competitor1VariationCnt": 24,
            "competitorsMax": 28.0,
            "competitorsMin": 10.0,
            "competitorsVariationsAvg": 6.45,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 4,
            "variationsTrueMax": 24,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 20"
          }
        },
        {
          "signal": "Anchor Text",
          "signalData": {
            "comment": "Reduce the number of exact keywords in your Anchor Text by 1",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "a",
          "variationData": {
            "comment": "Reduce the number of variations of your keyword in your Anchor Text by 2",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.73,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Image alt",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "img_alt",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Image Alts by 1",
            "competitor1VariationCnt": 8,
            "competitorsMax": 8.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.27,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 8,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 8"
          }
        },
        {
          "signal": "Bold",
          "signalData": {
            "comment": "Reduce the number of exact keywords in your Bolds by 1",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Reduce the number of keyword phrase by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "bold",
          "variationData": {
            "comment": "Reduce the number of variations of your keyword in your Bolds by 2",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.27,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Italic",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "i",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "H2 to H3",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h2_h3",
          "variationData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding variations of your keyword in H2s or H3s by 3",
            "competitor1VariationCnt": 58,
            "competitorsMax": 58.0,
            "competitorsMin": 6.0,
            "competitorsVariationsAvg": 6.18,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 3,
            "variationsTrueMax": 58,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 55"
          }
        },
        {
          "signal": "H2 to H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h2_h6",
          "variationData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding variations of your keyword in H2s, H3s, or H4s by 3",
            "competitor1VariationCnt": 58,
            "competitorsMax": 58.0,
            "competitorsMin": 6.0,
            "competitorsVariationsAvg": 6.45,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 3,
            "variationsTrueMax": 58,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 55"
          }
        },
        {
          "signal": "H4 to H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h4_h6",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.27,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Word Count",
          "signalData": {
            "comment": "You should consider reducing your word count to an amount similar to the target word count.  That said, before reducing word count you should read the best practices on what to do when your word count is higher than the target word count. 839",
            "competitorsAvg": 569.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1491,
            "keywordsTrueMaxRecommendations": "value low 83",
            "keywordsTrueMin": 68,
            "targetKeywordCnt": 1408
          },
          "signalTags": "word_count",
          "variationData": {
            "comment": "",
            "competitor1VariationCnt": 1491,
            "competitorsMax": "",
            "competitorsMin": "",
            "competitorsVariationsAvg": 568.55,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 1408,
            "variationsTrueMax": 1491,
            "variationsTrueMaxRecommendations": ""
          }
        },
        {
          "signal": "HTML Head & Body Tags",
          "signalData": {
            "comment": "Increase the number of exact keywords in your HTML Head & Body Tags by 10",
            "competitorsAvg": 14.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 40,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 36",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 4
          },
          "signalTags": "html",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your  HTML Head & Body Tags by 52",
            "competitor1VariationCnt": 191,
            "competitorsMax": 191.0,
            "competitorsMin": 63.0,
            "competitorsVariationsAvg": 63.91,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 11,
            "variationsTrueMax": 191,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 180"
          }
        },
        {
          "signal": "H5",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h5",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h6",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Meta Keywords",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "meta_keywords",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.73,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 8,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 8"
          }
        },
        {
          "signal": "Meta Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Meta Descriptions by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "meta_description",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Meta Descriptions by 1",
            "competitor1VariationCnt": 4,
            "competitorsMax": 4.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.73,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 4"
          }
        },
        {
          "signal": "Open Graph Title",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Open Graph Titles by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "open_graph_title",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Open Graph Titles by 1",
            "competitor1VariationCnt": 2,
            "competitorsMax": 2.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.27,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Open Graph Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Open Graph Descriptions by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "open_graph_description",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Open Graph Descriptions by 2",
            "competitor1VariationCnt": 4,
            "competitorsMax": 4.0,
            "competitorsMin": 2.0,
            "competitorsVariationsAvg": 2.18,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 7,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 7"
          }
        },
        {
          "signal": "Twitter Card Title",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "twitter_card_title",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Twitter Card Titles by 1",
            "competitor1VariationCnt": 2,
            "competitorsMax": 2.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.73,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 2,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 2"
          }
        },
        {
          "signal": "Twitter Card Description",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "twitter_card_description",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Twitter Card Descriptions by 1",
            "competitor1VariationCnt": 4,
            "competitorsMax": 4.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.64,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 7,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 7"
          }
        },
        {
          "signal": "Image File Names",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "image_file_names",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.45,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 4"
          }
        },
        {
          "signal": "Figure Caption",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "figure_caption",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Source Code URLs",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Source Code URLs by 10",
            "competitorsAvg": 14.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 40,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 36",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 4
          },
          "signalTags": "source_code_urls",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Source Code URLs by 52",
            "competitor1VariationCnt": 191,
            "competitorsMax": 191.0,
            "competitorsMin": 63.0,
            "competitorsVariationsAvg": 63.91,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 11,
            "variationsTrueMax": 191,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 180"
          }
        },
        {
          "signal": "Microdata (schema format)",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 3,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 3",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "microdata_schema_format",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your Microdatas (schema format) by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 2.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 2.45,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 27,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 27"
          }
        },
        {
          "signal": "RDF (schema format)",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "rdf_schema_format",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "JSON LD (schema format)",
          "signalData": {
            "comment": "Increase the number of exact keywords in your JSON LD (schema format) by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 3,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 3",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "json_ld_schema_format",
          "variationData": {
            "comment": "Increase the number of variations of your keyword in your JSON LDs (schema format) by 2",
            "competitor1VariationCnt": 15,
            "competitorsMax": 15.0,
            "competitorsMin": 2.0,
            "competitorsVariationsAvg": 2.18,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 15,
            "variationsTrueMaxRecommendations": "Increase the number of variations of your keyword by 15"
          }
        }
      ],
      "varsScore": 2.2
    },
    "lsaAdjKeywordCounts": {
      "featuredKwScore": 0,
      "featuredVarsScore": 0,
      "isLSI": true,
      "kwScore": 0,
      "lsiFeaturedScore": 5.699999999999999,
      "lsiScore": 5.699999999999999,
      "optimizedKeyword": {
        "overOptimized": 1,
        "underOptimized": 17
      },
      "optimizedVariations": {
        "overOptimized": 4,
        "underOptimized": 12
      },
      "pageScore": {
        
      },
      "rows": [
        {
          "signal": "Keyword in Main URL",
          "signalData": {
            "comment": "It is recommended that you have your target keyword in your URL 1 time, when possible",
            "competitorsAvg": "",
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "keywordsTrueMax": "",
            "keywordsTrueMaxRecommendations": "",
            "keywordsTrueMin": "",
            "targetKeywordCnt": 0
          },
          "signalTags": "mainURL",
          "variationData": {
            "comment": "",
            "competitor1VariationCnt": "",
            "competitorsMax": "",
            "competitorsMin": "",
            "competitorsVariationsAvg": "",
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": "",
            "variationsTrueMax": "",
            "variationsTrueMaxRecommendations": ""
          }
        },
        {
          "signal": "Meta Title",
          "signalData": {
            "comment": "Add 1 exact keyword in your title tag of the page",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "title",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 1,
            "competitorsMax": 4.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 4.96,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 6,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 4"
          }
        },
        {
          "signal": "H1",
          "signalData": {
            "comment": "Add 1 exact keyword in your H1 of the page",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h1",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 1,
            "competitorsMax": 6.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 6.99,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 12,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 10"
          }
        },
        {
          "signal": "H2",
          "signalData": {
            "comment": "Increase the number of exact keywords in your H2s by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 5",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h2",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 45,
            "competitorsMax": 45.0,
            "competitorsMin": 13.0,
            "competitorsVariationsAvg": 13.52,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 26,
            "variationsTrueMax": 45,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 19"
          }
        },
        {
          "signal": "H3",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h3",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your H3s by 6",
            "competitor1VariationCnt": 9,
            "competitorsMax": 9.0,
            "competitorsMin": 6.0,
            "competitorsVariationsAvg": 6.77,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 9,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 9"
          }
        },
        {
          "signal": "H4",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h4",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your H4s by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 2.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 2.7,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 4"
          }
        },
        {
          "signal": "Paragraph Text",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Paragraph Text by 2",
            "competitorsAvg": 4.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 2
          },
          "signalTags": "p",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Paragraph Text by 31",
            "competitor1VariationCnt": 134,
            "competitorsMax": 154.0,
            "competitorsMin": 128.0,
            "competitorsVariationsAvg": 108.45,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 97,
            "variationsTrueMax": 134,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 37"
          }
        },
        {
          "signal": "Anchor Text",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "a",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Anchor Text by 69",
            "competitor1VariationCnt": 92,
            "competitorsMax": 92.0,
            "competitorsMin": 79.0,
            "competitorsVariationsAvg": 79.14,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 10,
            "variationsTrueMax": 97,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 87"
          }
        },
        {
          "signal": "Image alt",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Image Alts by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "img_alt",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Image Alts by 8",
            "competitor1VariationCnt": 10,
            "competitorsMax": 13.0,
            "competitorsMin": 10.0,
            "competitorsVariationsAvg": 13.76,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 40,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 38"
          }
        },
        {
          "signal": "Bold",
          "signalData": {
            "comment": "Reduce the number of exact keywords in your Bolds by 1",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Reduce the number of keyword phrase by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "bold",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your Bolds by 9",
            "competitor1VariationCnt": 7,
            "competitorsMax": 7.0,
            "competitorsMin": 2.0,
            "competitorsVariationsAvg": 2.7,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 16,
            "variationsTrueMax": 7,
            "variationsTrueMaxRecommendations": "Reduce the number of contextual terms of your keyword by 9"
          }
        },
        {
          "signal": "Italic",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "i",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your Italic by 3",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 3,
            "variationsTrueMax": 15,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 12"
          }
        },
        {
          "signal": "H2 to H3",
          "signalData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding exact match keywords in H2s or H3s by 2",
            "competitorsAvg": 3.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h2_h3",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 54,
            "competitorsMax": 54.0,
            "competitorsMin": 20.0,
            "competitorsVariationsAvg": 20.29,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 26,
            "variationsTrueMax": 54,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 28"
          }
        },
        {
          "signal": "H2 to H6",
          "signalData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding exact match keywords in H2s, H3s 1",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h2_h6",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 54,
            "competitorsMax": 54.0,
            "competitorsMin": 22.0,
            "competitorsVariationsAvg": 22.99,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 26,
            "variationsTrueMax": 54,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 28"
          }
        },
        {
          "signal": "H4 to H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h4_h6",
          "variationData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding contextual terms of your keyword in H4s, H5s, or H6s by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 2.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 2.7,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 4"
          }
        },
        {
          "signal": "Word Count",
          "signalData": {
            "comment": "Increase your word count to a value similar to the target value 2",
            "competitorsAvg": 1410.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1491,
            "keywordsTrueMaxRecommendations": "value low 83",
            "keywordsTrueMin": 68,
            "targetKeywordCnt": 1408
          },
          "signalTags": "word_count",
          "variationData": {
            "comment": "",
            "competitor1VariationCnt": 1491,
            "competitorsMax": "",
            "competitorsMin": "",
            "competitorsVariationsAvg": 568.55,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 1408,
            "variationsTrueMax": 1491,
            "variationsTrueMaxRecommendations": ""
          }
        },
        {
          "signal": "HTML Head & Body Tags",
          "signalData": {
            "comment": "Increase the number of exact keywords in your HTML Head & Body Tags by 31",
            "competitorsAvg": 35.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 40,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 36",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 4
          },
          "signalTags": "html",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your HTML Head & Body Tags by 1175",
            "competitor1VariationCnt": 1060,
            "competitorsMax": 1679.0,
            "competitorsMin": 1060.0,
            "competitorsVariationsAvg": 1679.18,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2854,
            "variationsTrueMax": 1864,
            "variationsTrueMaxRecommendations": "Reduce the number of contextual terms of your keyword by 990"
          }
        },
        {
          "signal": "H5",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h5",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h6",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Meta Keywords",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Meta keywords by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "meta_keywords",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Meta keywords by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 2.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 2.93,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 12,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 12"
          }
        },
        {
          "signal": "Meta Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Meta Descriptions by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "meta_description",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Meta Descriptions by 4",
            "competitor1VariationCnt": 4,
            "competitorsMax": 9.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 9.7,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 16,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 16"
          }
        },
        {
          "signal": "Open Graph Title",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Open Graph Titles by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "open_graph_title",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 1,
            "competitorsMax": 3.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 3.37,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 2"
          }
        },
        {
          "signal": "Open Graph Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Open Graph Descriptions by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "open_graph_description",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 4,
            "competitorsMax": 17.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 17.81,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 6,
            "variationsTrueMax": 35,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 29"
          }
        },
        {
          "signal": "Twitter Card Title",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Twitter Card Titles by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "twitter_card_title",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Twitter Card Titles by 1",
            "competitor1VariationCnt": 1,
            "competitorsMax": 2.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 2.26,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 3,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 3"
          }
        },
        {
          "signal": "Twitter Card Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Twitter Card Descriptions by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "twitter_card_description",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Twitter Card Descriptions by 4",
            "competitor1VariationCnt": 4,
            "competitorsMax": 13.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 13.52,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 35,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 35"
          }
        },
        {
          "signal": "Image File Names",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "image_file_names",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 39,
            "competitorsMax": 39.0,
            "competitorsMin": 10.0,
            "competitorsVariationsAvg": 10.81,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 23,
            "variationsTrueMax": 39,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 16"
          }
        },
        {
          "signal": "Figure Caption",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "figure_caption",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Source Code URLs",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Source Code URLs by 31",
            "competitorsAvg": 35.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 40,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 36",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 4
          },
          "signalTags": "source_code_urls",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your Source Code URLs by 1175",
            "competitor1VariationCnt": 1060,
            "competitorsMax": 1679.0,
            "competitorsMin": 1060.0,
            "competitorsVariationsAvg": 1679.18,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2854,
            "variationsTrueMax": 1864,
            "variationsTrueMaxRecommendations": "Reduce the number of contextual terms of your keyword by 990"
          }
        },
        {
          "signal": "Microdata (schema format)",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Microdata (schema format) by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 3,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 3",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "microdata_schema_format",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Microdatas (schema format) by 12",
            "competitor1VariationCnt": 0,
            "competitorsMax": 23.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 23.91,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 106,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 106"
          }
        },
        {
          "signal": "RDF (schema format)",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "rdf_schema_format",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "JSON LD (schema format)",
          "signalData": {
            "comment": "Increase the number of exact keywords in your JSON LD (schema format) by 2",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 3,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 3",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "json_ld_schema_format",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your JSON LDs (schema format) by 12",
            "competitor1VariationCnt": 22,
            "competitorsMax": 22.0,
            "competitorsMin": 14.0,
            "competitorsVariationsAvg": 14.43,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 22,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 20"
          }
        }
      ],
      "varsScore": 0
    },
    "lsaKeywordCounts": {
      "featuredKwScore": 0,
      "featuredVarsScore": 0,
      "isLSI": true,
      "kwScore": 0,
      "lsiFeaturedScore": 7.999999999999999,
      "lsiScore": 7.999999999999999,
      "optimizedKeyword": {
        "overOptimized": 2,
        "underOptimized": 12
      },
      "optimizedVariations": {
        "overOptimized": 5,
        "underOptimized": 11
      },
      "pageScore": {
        
      },
      "rows": [
        {
          "signal": "Keyword in Main URL",
          "signalData": {
            "comment": "It is recommended that you have your target keyword in your URL 1 time, when possible",
            "competitorsAvg": "",
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "keywordsTrueMax": "",
            "keywordsTrueMaxRecommendations": "",
            "keywordsTrueMin": "",
            "targetKeywordCnt": 0
          },
          "signalTags": "mainURL",
          "variationData": {
            "comment": "",
            "competitor1VariationCnt": "",
            "competitorsMax": "",
            "competitorsMin": "",
            "competitorsVariationsAvg": "",
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": "",
            "variationsTrueMax": "",
            "variationsTrueMaxRecommendations": ""
          }
        },
        {
          "signal": "Meta Title",
          "signalData": {
            "comment": "Add 1 exact keyword in your title tag of the page",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "title",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 1,
            "competitorsMax": 2.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 6,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 4"
          }
        },
        {
          "signal": "H1",
          "signalData": {
            "comment": "Add 1 exact keyword in your H1 of the page",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h1",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 1,
            "competitorsMax": 2.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 2.82,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 12,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 10"
          }
        },
        {
          "signal": "H2",
          "signalData": {
            "comment": "Increase the number of exact keywords in your H2s by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 5",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h2",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 45,
            "competitorsMax": 45.0,
            "competitorsMin": 5.0,
            "competitorsVariationsAvg": 5.45,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 26,
            "variationsTrueMax": 45,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 19"
          }
        },
        {
          "signal": "H3",
          "signalData": {
            "comment": "Reduce the number of exact keywords in your H3s by 1",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h3",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your H3s by 2",
            "competitor1VariationCnt": 9,
            "competitorsMax": 9.0,
            "competitorsMin": 2.0,
            "competitorsVariationsAvg": 2.73,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 9,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 9"
          }
        },
        {
          "signal": "H4",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h4",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your H4s by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 1.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 1.09,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 4"
          }
        },
        {
          "signal": "Paragraph Text",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Paragraph Text by 1",
            "competitorsAvg": 3.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 2
          },
          "signalTags": "p",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 134,
            "competitorsMax": 154.0,
            "competitorsMin": 63.0,
            "competitorsVariationsAvg": 43.73,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 97,
            "variationsTrueMax": 134,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 37"
          }
        },
        {
          "signal": "Anchor Text",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "a",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Anchor Text by 21",
            "competitor1VariationCnt": 92,
            "competitorsMax": 92.0,
            "competitorsMin": 31.0,
            "competitorsVariationsAvg": 31.91,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 10,
            "variationsTrueMax": 97,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 87"
          }
        },
        {
          "signal": "Image alt",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Image Alts by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "img_alt",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Image Alts by 3",
            "competitor1VariationCnt": 10,
            "competitorsMax": 10.0,
            "competitorsMin": 5.0,
            "competitorsVariationsAvg": 5.55,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 40,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 38"
          }
        },
        {
          "signal": "Bold",
          "signalData": {
            "comment": "Reduce the number of exact keywords in your Bolds by 1",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Reduce the number of keyword phrase by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "bold",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your Bolds by 9",
            "competitor1VariationCnt": 7,
            "competitorsMax": 7.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.09,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 16,
            "variationsTrueMax": 7,
            "variationsTrueMaxRecommendations": "Reduce the number of contextual terms of your keyword by 9"
          }
        },
        {
          "signal": "Italic",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "i",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your Italic by 3",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 3,
            "variationsTrueMax": 15,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 12"
          }
        },
        {
          "signal": "H2 to H3",
          "signalData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding exact match keywords in H2s or H3s by 1",
            "competitorsAvg": 2.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h2_h3",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 54,
            "competitorsMax": 54.0,
            "competitorsMin": 8.0,
            "competitorsVariationsAvg": 8.18,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 26,
            "variationsTrueMax": 54,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 28"
          }
        },
        {
          "signal": "H2 to H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 5,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 1
          },
          "signalTags": "h2_h6",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 54,
            "competitorsMax": 54.0,
            "competitorsMin": 9.0,
            "competitorsVariationsAvg": 9.27,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 26,
            "variationsTrueMax": 54,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 28"
          }
        },
        {
          "signal": "H4 to H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h4_h6",
          "variationData": {
            "comment": "Although your individual signals might be correct, its possible that you are under optimized in total. Consider adding contextual terms of your keyword in H4s, H5s, or H6s by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 1.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 1.09,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 4"
          }
        },
        {
          "signal": "Word Count",
          "signalData": {
            "comment": "You should consider reducing your word count to an amount similar to the target word count.  That said, before reducing word count you should read the best practices on what to do when your word count is higher than the target word count. 839",
            "competitorsAvg": 569.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1491,
            "keywordsTrueMaxRecommendations": "value low 83",
            "keywordsTrueMin": 68,
            "targetKeywordCnt": 1408
          },
          "signalTags": "word_count",
          "variationData": {
            "comment": "",
            "competitor1VariationCnt": 1491,
            "competitorsMax": "",
            "competitorsMin": "",
            "competitorsVariationsAvg": 568.55,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 1408,
            "variationsTrueMax": 1491,
            "variationsTrueMaxRecommendations": ""
          }
        },
        {
          "signal": "HTML Head & Body Tags",
          "signalData": {
            "comment": "Increase the number of exact keywords in your HTML Head & Body Tags by 10",
            "competitorsAvg": 14.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 40,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 36",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 4
          },
          "signalTags": "html",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your HTML Head & Body Tags by 1794",
            "competitor1VariationCnt": 1060,
            "competitorsMax": 1060.0,
            "competitorsMin": 677.0,
            "competitorsVariationsAvg": 677.09,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2854,
            "variationsTrueMax": 1864,
            "variationsTrueMaxRecommendations": "Reduce the number of contextual terms of your keyword by 990"
          }
        },
        {
          "signal": "H5",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h5",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "H6",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "h6",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Meta Keywords",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 4,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 4",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "meta_keywords",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Meta keywords by 1",
            "competitor1VariationCnt": 0,
            "competitorsMax": 1.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 1.18,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 12,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 12"
          }
        },
        {
          "signal": "Meta Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Meta Descriptions by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "meta_description",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Meta Descriptions by 3",
            "competitor1VariationCnt": 4,
            "competitorsMax": 4.0,
            "competitorsMin": 3.0,
            "competitorsVariationsAvg": 3.91,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 16,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 16"
          }
        },
        {
          "signal": "Open Graph Title",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Open Graph Titles by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "open_graph_title",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your Open Graph Titles by 1",
            "competitor1VariationCnt": 1,
            "competitorsMax": 1.0,
            "competitorsMin": 1.0,
            "competitorsVariationsAvg": 1.36,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 4,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 2"
          }
        },
        {
          "signal": "Open Graph Description",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Open Graph Descriptions by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "open_graph_description",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 4,
            "competitorsMax": 7.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 7.18,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 6,
            "variationsTrueMax": 35,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 29"
          }
        },
        {
          "signal": "Twitter Card Title",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 1,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 1",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "twitter_card_title",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Twitter Card Titles by 1",
            "competitor1VariationCnt": 1,
            "competitorsMax": 1.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0.91,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 3,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 3"
          }
        },
        {
          "signal": "Twitter Card Description",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 2,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 2",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "twitter_card_description",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Twitter Card Descriptions by 4",
            "competitor1VariationCnt": 4,
            "competitorsMax": 5.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 5.45,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 35,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 35"
          }
        },
        {
          "signal": "Image File Names",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "image_file_names",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 39,
            "competitorsMax": 39.0,
            "competitorsMin": 4.0,
            "competitorsVariationsAvg": 4.36,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 23,
            "variationsTrueMax": 39,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 16"
          }
        },
        {
          "signal": "Figure Caption",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "figure_caption",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "Source Code URLs",
          "signalData": {
            "comment": "Increase the number of exact keywords in your Source Code URLs by 10",
            "competitorsAvg": 14.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 40,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 36",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 4
          },
          "signalTags": "source_code_urls",
          "variationData": {
            "comment": "Reduce the number of contextual terms of your keyword in your Source Code URLs by 1794",
            "competitor1VariationCnt": 1060,
            "competitorsMax": 1060.0,
            "competitorsMin": 677.0,
            "competitorsVariationsAvg": 677.09,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2854,
            "variationsTrueMax": 1864,
            "variationsTrueMaxRecommendations": "Reduce the number of contextual terms of your keyword by 990"
          }
        },
        {
          "signal": "Microdata (schema format)",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 3,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 3",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "microdata_schema_format",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your Microdatas (schema format) by 5",
            "competitor1VariationCnt": 0,
            "competitorsMax": 9.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 9.64,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 106,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 106"
          }
        },
        {
          "signal": "RDF (schema format)",
          "signalData": {
            "comment": "Leave As Is",
            "competitorsAvg": 0.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 0,
            "keywordsTrueMaxRecommendations": "Leave As Is",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "rdf_schema_format",
          "variationData": {
            "comment": "Leave As Is",
            "competitor1VariationCnt": 0,
            "competitorsMax": 0.0,
            "competitorsMin": 0.0,
            "competitorsVariationsAvg": 0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 0,
            "variationsTrueMax": 0,
            "variationsTrueMaxRecommendations": "Leave As Is"
          }
        },
        {
          "signal": "JSON LD (schema format)",
          "signalData": {
            "comment": "Increase the number of exact keywords in your JSON LD (schema format) by 1",
            "competitorsAvg": 1.0,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsKeywordAvg": "",
            "featuredCompetitorsKeywordMax": "",
            "featuredCompetitorsKeywordMin": "",
            "keywordsTrueMax": 3,
            "keywordsTrueMaxRecommendations": "Increase the number of exact keywords by 3",
            "keywordsTrueMin": 0,
            "targetKeywordCnt": 0
          },
          "signalTags": "json_ld_schema_format",
          "variationData": {
            "comment": "Increase the number of contextual terms of your keyword in your JSON LDs (schema format) by 3",
            "competitor1VariationCnt": 22,
            "competitorsMax": 22.0,
            "competitorsMin": 5.0,
            "competitorsVariationsAvg": 5.82,
            "featuredCompetitorsComment": "",
            "featuredCompetitorsMax": "",
            "featuredCompetitorsMin": "",
            "featuredCompetitorsVariationsAvg": "",
            "targetVariationCnt": 2,
            "variationsTrueMax": 22,
            "variationsTrueMaxRecommendations": "Increase the number of contextual terms of your keyword by 20"
          }
        }
      ],
      "varsScore": 0
    },
    "lsaPhrases": [
      {
        "averageCount": 9,
        "phrase": "seo",
        "targetCount": 22,
        "weight": 0.31467406361791195
      },
      {
        "averageCount": 1,
        "phrase": "19",
        "targetCount": 0,
        "weight": 0.2122241981707307
      },
      {
        "averageCount": 5,
        "phrase": "page",
        "targetCount": 21,
        "weight": 0.18461517142681252
      },
      {
        "averageCount": 1,
        "phrase": "lounge",
        "targetCount": 0,
        "weight": 0.14855693871951153
      },
      {
        "averageCount": 2,
        "phrase": "training",
        "targetCount": 0,
        "weight": 0.1420441125724691
      },
      {
        "averageCount": 1,
        "phrase": "person",
        "targetCount": 0,
        "weight": 0.1353550580197015
      },
      {
        "averageCount": 1,
        "phrase": "stitcher",
        "targetCount": 0,
        "weight": 0.1313444915440681
      },
      {
        "averageCount": 1,
        "phrase": "video",
        "targetCount": 0,
        "weight": 0.12906190238135506
      },
      {
        "averageCount": 0,
        "phrase": "browser",
        "targetCount": 1,
        "weight": 0.11905979639608524
      },
      {
        "averageCount": 1,
        "phrase": "vimeo",
        "targetCount": 0,
        "weight": 0.1161557121432196
      },
      {
        "averageCount": 1,
        "phrase": "crunchbase",
        "targetCount": 0,
        "weight": 0.11015672882047439
      },
      {
        "averageCount": 2,
        "phrase": "ranking",
        "targetCount": 4,
        "weight": 0.10761453544935544
      },
      {
        "averageCount": 3,
        "phrase": "google",
        "targetCount": 3,
        "weight": 0.1064421026969651
      },
      {
        "averageCount": 0,
        "phrase": "activity",
        "targetCount": 0,
        "weight": 0.10567517226641837
      },
      {
        "averageCount": 4,
        "phrase": "event",
        "targetCount": 0,
        "weight": 0.10200571329862253
      },
      {
        "averageCount": 1,
        "phrase": "episode",
        "targetCount": 0,
        "weight": 0.10009646182575473
      },
      {
        "averageCount": 1,
        "phrase": "join",
        "targetCount": 4,
        "weight": 0.09636800997830725
      },
      {
        "averageCount": 3,
        "phrase": "marketing",
        "targetCount": 3,
        "weight": 0.08680404129577195
      },
      {
        "averageCount": 1,
        "phrase": "account",
        "targetCount": 0,
        "weight": 0.08654455543939694
      },
      {
        "averageCount": 1,
        "phrase": "method",
        "targetCount": 5,
        "weight": 0.08622483958133169
      },
      {
        "averageCount": 1,
        "phrase": "advertising",
        "targetCount": 0,
        "weight": 0.08583802756666896
      },
      {
        "averageCount": 1,
        "phrase": "competition",
        "targetCount": 0,
        "weight": 0.08412900235583468
      },
      {
        "averageCount": 1,
        "phrase": "log",
        "targetCount": 0,
        "weight": 0.08204601961864573
      },
      {
        "averageCount": 1,
        "phrase": "show",
        "targetCount": 2,
        "weight": 0.07764276386284
      },
      {
        "averageCount": 0,
        "phrase": "access",
        "targetCount": 0,
        "weight": 0.07664375853989969
      },
      {
        "averageCount": 2,
        "phrase": "free",
        "targetCount": 5,
        "weight": 0.07575190140248299
      },
      {
        "averageCount": 1,
        "phrase": "page seo",
        "targetCount": 5,
        "weight": 0.07504753507469294
      },
      {
        "averageCount": 2,
        "phrase": "algorithm",
        "targetCount": 1,
        "weight": 0.07476402228819894
      },
      {
        "averageCount": 1,
        "phrase": "wrap",
        "targetCount": 0,
        "weight": 0.07381038649629797
      },
      {
        "averageCount": 1,
        "phrase": "load",
        "targetCount": 0,
        "weight": 0.07363503063915364
      },
      {
        "averageCount": 1,
        "phrase": "ago",
        "targetCount": 0,
        "weight": 0.07339771906405457
      },
      {
        "averageCount": 0,
        "phrase": "check",
        "targetCount": 1,
        "weight": 0.07143218814339307
      },
      {
        "averageCount": 0,
        "phrase": "agree",
        "targetCount": 0,
        "weight": 0.0712723366075483
      },
      {
        "averageCount": 1,
        "phrase": "info",
        "targetCount": 1,
        "weight": 0.07100561109486558
      },
      {
        "averageCount": 1,
        "phrase": "topics",
        "targetCount": 0,
        "weight": 0.07031663154892599
      },
      {
        "averageCount": 2,
        "phrase": "privacy",
        "targetCount": 5,
        "weight": 0.06809084478405218
      },
      {
        "averageCount": 0,
        "phrase": "keep",
        "targetCount": 1,
        "weight": 0.06787922030544198
      },
      {
        "averageCount": 0,
        "phrase": "create account",
        "targetCount": 0,
        "weight": 0.06542543240261699
      },
      {
        "averageCount": 2,
        "phrase": "content",
        "targetCount": 8,
        "weight": 0.0653167326704563
      },
      {
        "averageCount": 1,
        "phrase": "media",
        "targetCount": 1,
        "weight": 0.06522118098488634
      },
      {
        "averageCount": 1,
        "phrase": "social media",
        "targetCount": 0,
        "weight": 0.06522118098488634
      },
      {
        "averageCount": 2,
        "phrase": "sign",
        "targetCount": 1,
        "weight": 0.0639560069761152
      },
      {
        "averageCount": 1,
        "phrase": "website",
        "targetCount": 7,
        "weight": 0.060916060025192856
      },
      {
        "averageCount": 0,
        "phrase": "profiles",
        "targetCount": 0,
        "weight": 0.06024412779074571
      },
      {
        "averageCount": 1,
        "phrase": "time",
        "targetCount": 3,
        "weight": 0.06016234969197562
      },
      {
        "averageCount": 0,
        "phrase": "enterprise",
        "targetCount": 0,
        "weight": 0.05945670580221848
      },
      {
        "averageCount": 1,
        "phrase": "create",
        "targetCount": 0,
        "weight": 0.05923880882746645
      },
      {
        "averageCount": 2,
        "phrase": "search",
        "targetCount": 2,
        "weight": 0.0592238864763967
      },
      {
        "averageCount": 1,
        "phrase": "app",
        "targetCount": 0,
        "weight": 0.05816800041147715
      },
      {
        "averageCount": 0,
        "phrase": "blocked",
        "targetCount": 0,
        "weight": 0.05794985817273575
      },
      {
        "averageCount": 1,
        "phrase": "years ago",
        "targetCount": 0,
        "weight": 0.057806224690422646
      },
      {
        "averageCount": 0,
        "phrase": "name",
        "targetCount": 2,
        "weight": 0.056015964971435676
      },
      {
        "averageCount": 2,
        "phrase": "view",
        "targetCount": 0,
        "weight": 0.05549142285142888
      },
      {
        "averageCount": 1,
        "phrase": "social",
        "targetCount": 0,
        "weight": 0.054753187993815754
      },
      {
        "averageCount": 1,
        "phrase": "web",
        "targetCount": 7,
        "weight": 0.05421583602662868
      },
      {
        "averageCount": 2,
        "phrase": "2020",
        "targetCount": 1,
        "weight": 0.05323830168366958
      },
      {
        "averageCount": 2,
        "phrase": "pro",
        "targetCount": 13,
        "weight": 0.052600539273138944
      },
      {
        "averageCount": 0,
        "phrase": "reply",
        "targetCount": 0,
        "weight": 0.0524329907000229
      },
      {
        "averageCount": 1,
        "phrase": "link",
        "targetCount": 1,
        "weight": 0.05074750198966533
      },
      {
        "averageCount": 2,
        "phrase": "events",
        "targetCount": 0,
        "weight": 0.05047114146257954
      },
      {
        "averageCount": 1,
        "phrase": "data",
        "targetCount": 1,
        "weight": 0.04764101403140449
      },
      {
        "averageCount": 1,
        "phrase": "conference",
        "targetCount": 0,
        "weight": 0.04744766740331928
      },
      {
        "averageCount": 0,
        "phrase": "actually",
        "targetCount": 3,
        "weight": 0.04645330594599354
      },
      {
        "averageCount": 1,
        "phrase": "management",
        "targetCount": 1,
        "weight": 0.039739006730531476
      }
    ],
    "lsaVariations": [
      {
        "averageCount": 0,
        "phrase": "roofing",
        "targetCount": 0,
        "weight": 0.0
      },
      {
        "averageCount": 0,
        "phrase": "kyle's",
        "targetCount": 0,
        "weight": 0
      },
      {
        "averageCount": 0,
        "phrase": "kyle roof's",
        "targetCount": 0,
        "weight": 0
      },
      {
        "averageCount": 0,
        "phrase": "kyle roof kyle roof",
        "targetCount": 0,
        "weight": 0.0
      },
      {
        "averageCount": 0,
        "phrase": "kyle roofs",
        "targetCount": 0,
        "weight": 0.0
      },
      {
        "averageCount": 0,
        "phrase": "kyle roofing",
        "targetCount": 0,
        "weight": 0.0
      },
      {
        "averageCount": 7,
        "phrase": "kyle",
        "targetCount": 4,
        "weight": 0.8559213855598985
      },
      {
        "averageCount": 3,
        "phrase": "roof",
        "targetCount": 3,
        "weight": 0.33782960863560735
      }
    ],
    "nlpSchemaData": {
      
    },
    "note": null,
    "pageId": null,
    "pageNotBuilt": 0,
    "pageRunType": 1,
    "pageScrore": null,
    "progress": 100,
    "proxyId": 33,
    "schemaTypes": [
      {
        "count": 1,
        "type": "Organization"
      }
    ],
    "status": "SUCCESS",
    "tagCounts": [
      {
        "comment": "Reduce the total number on your page by 1",
        "max": 10,
        "mean": 2.0,
        "min": 0,
        "signalCnt": 3,
        "tagLabel": "H1 Tag Total"
      },
      {
        "comment": "Reduce the total number on your page by 8",
        "max": 8,
        "mean": 2.1,
        "min": 0,
        "signalCnt": 10,
        "tagLabel": "H2 Tag Total"
      },
      {
        "comment": "Reduce the total number on your page by 4",
        "max": 15,
        "mean": 5.09,
        "min": 0,
        "signalCnt": 9,
        "tagLabel": "H3 Tag Total"
      },
      {
        "comment": "Increase the total number on your page by 2",
        "max": 7,
        "mean": 1.8,
        "min": 0,
        "signalCnt": 0,
        "tagLabel": "H4 Tag Total"
      },
      {
        "comment": "Leave As Is",
        "max": 0,
        "mean": 0.0,
        "min": 0,
        "signalCnt": 0,
        "tagLabel": "H5 Tag Total"
      },
      {
        "comment": "Reduce the total number on your page by 1",
        "max": 0,
        "mean": 0.0,
        "min": 0,
        "signalCnt": 1,
        "tagLabel": "H6 Tag Total"
      },
      {
        "comment": "Reduce the total number on your page by 52",
        "max": 44,
        "mean": 11.9,
        "min": 0,
        "signalCnt": 64,
        "tagLabel": "Paragraph Text Tag Total"
      },
      {
        "comment": "Increase the total number on your page by 13",
        "max": 125,
        "mean": 64.09,
        "min": 6,
        "signalCnt": 51,
        "tagLabel": "Anchor Text Tag Total"
      },
      {
        "comment": "Reduce the total number on your page by 13",
        "max": 40,
        "mean": 8.9,
        "min": 1,
        "signalCnt": 22,
        "tagLabel": "Image File total"
      },
      {
        "comment": "Reduce the total number on your page by 14",
        "max": 31,
        "mean": 7.91,
        "min": 1,
        "signalCnt": 22,
        "tagLabel": "Image Alt Tag Total"
      },
      {
        "comment": "Reduce the total number on your page by 59",
        "max": 6,
        "mean": 1.64,
        "min": 0,
        "signalCnt": 61,
        "tagLabel": "Bold Tag Total"
      },
      {
        "comment": "Leave As Is",
        "max": 17,
        "mean": 2.3,
        "min": 0,
        "signalCnt": 2,
        "tagLabel": "Italic Tag Total"
      },
      {
        "comment": "Reduce the total number on your page by 1",
        "max": 1,
        "mean": 0.18,
        "min": 0,
        "signalCnt": 1,
        "tagLabel": "Video File Total"
      },
      {
        "comment": "Reduce the total number on your page by 1",
        "max": 2,
        "mean": 0.3,
        "min": 0,
        "signalCnt": 1,
        "tagLabel": "Ordered List Total"
      },
      {
        "comment": "Reduce the total number on your page by 3",
        "max": 6.0,
        "mean": 0.9,
        "min": 0.0,
        "signalCnt": 4,
        "tagLabel": "Ordered List Items Total"
      },
      {
        "comment": "Reduce the total number on your page by 9",
        "max": 9,
        "mean": 3.4,
        "min": 0,
        "signalCnt": 12,
        "tagLabel": "Unordered List Total"
      },
      {
        "comment": "Reduce the total number on your page by 29",
        "max": 48.0,
        "mean": 15.73,
        "min": 0.0,
        "signalCnt": 45,
        "tagLabel": "Unordered List Items Total"
      },
      {
        "comment": "Leave As Is",
        "max": 1,
        "mean": 0.3,
        "min": 0,
        "signalCnt": 0,
        "tagLabel": "Table Total"
      },
      {
        "comment": "Increase the total number on your page by 2",
        "max": 14,
        "mean": 1.9,
        "min": 0,
        "signalCnt": 0,
        "tagLabel": "Table Items Total"
      },
      {
        "comment": "Reduce the total number on your page by 3",
        "max": 2,
        "mean": 1.2,
        "min": 0,
        "signalCnt": 4,
        "tagLabel": "Form Total"
      },
      {
        "comment": "Reduce the total number on your page by 2",
        "max": 8,
        "mean": 2.27,
        "min": 0,
        "signalCnt": 4,
        "tagLabel": "iFrame Total"
      }
    ],
    "targetLanguage": "english",
    "targetUrlSourceCode": "",
    "taskId": "3a888e42-efb3-490a-b9da-1f1a755c43c2",
    "url": "https://pageoptimizer.pro",
    "userId": null,
    "variations": [
      "roofing",
      "kyle's",
      "kyle roof's",
      "kyle roof kyle roof",
      "kyle roofs",
      "kyle roofing",
      "kyle",
      "roof"
    ]
  }
      `


</script>





<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
* {
  font-family: 'Mulish', sans-serif;
}

$MAIN-COLOR: black;

#demo {
  height: 100vh;
  overflow: auto;

  #rect-container {
    margin: 5px 5px;
    /* border: 1px solid #FFDE05;  */
    border: none;
    // background: rgba(6, 46, 88, 0.9);
    background: white;
    padding: 20px 25px 10px 25px;
    border-radius: 15px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    .demo-title {
      color: black;
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-family: 'Mulish';
      font-weight: bold;
    }
    .demo-title-explain {
      font-size: 1rem;
      color: black;
    }

    label.label {
      font-weight: normal;
      color: $MAIN-COLOR;
      margin-top: 12px;
      font-size: 0.8rem;
    }
    input[type=text], select {
      background: #f2f2f2;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #979797;
      border-radius: 10px;
      border: none;
      font-size: 1rem;
      color: black;

      &::placeholder {
        color: #718396;
      }
      &::-ms-input-placeholder {
        color: #718396;
      }
    }
    input::placeholder {
      font-style: italic;
      font-size: 0.8rem;
      color: #a6a6a6;
    }

    select {
      color: black;
    }
    select option:disabled {
      color: #718396;
    }
    .select:not(.is-multiple):not(.is-loading)::after, .navbar-link:not(.is-arrowless)::after {
      border: none;
      font-family: "Material Icons";
      content: "\e5cf";
      transform: rotate(0);
      transform: translateY(-50%);
      color:  grey;
      font-size: 1.1rem;
    }

    .error-noty {
      background: rgb(255, 73, 73);
      color: white;
      border-radius: 5px;
      font-size: 0.8rem;
      padding: 5px;
      text-align: center;
    }
    

    .calc-button {
      width: 100%;
      background: linear-gradient(92deg, #FFC11F 7.52%, #FF6B01 59.16%);
      color: white;
      border: none;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 5px;
    }

    .explain-label {
      font-size: 0.8rem;
    }

    .signup-link {
      font-size: 1rem;
      color: $MAIN-COLOR;
      a {
        font-size: 1rem;
        color: #0162FF;
        font-weight: 600;
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }

    // result page
    .work-type-label {
      font-size: 1rem;
      font-weight: bold;
    }
    .score-explain {
      font-size: 0.8rem;
      color: black;
    }
    .signup-link1 {
      font-size: 0.9rem;
      color: $MAIN-COLOR;
      a {
        color: $MAIN-COLOR;
        text-decoration: underline;
      }
    }







    .demo-spinner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 90;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0 30px;

      .spinner-title {
        font-size: 1.8rem;
        font-weight: 600;
        text-align: center;
      }
      .spinner-explain {
        font-size: 1rem;
        text-align: center;
      }

      .percentage-area {
        animation-duration: 3s;
        animation-delay: 5s;
        border-radius: 7px;
        box-sizing: border-box;
        background: #E7E7E7;
        height: 10px;
        overflow: hidden;
        position: relative;

        .progress-bar1 {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background: #FF6B01;
          border-radius: 7px;
        }
      }
      .status-area {
        display: flex;
        font-size: 1rem;
        margin-top: 5px;
        .percentage {
          flex-basis: 60px;
          text-align: right;
        }
      }
    }
  }

}
</style>