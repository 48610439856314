<template>
  <section class="guard-dashboard">
    <div class="container">
      <div class="dashboard-page-header">
        <img src="/static/dashboard-icon1.png" />
        <div class="flex-grow-1">
          <h2 class="page-title">
            Watchdog Dashboard
          </h2>
          <div class="desc">
            The Watchdog dashboard shows you the pages that you are currently watching. You can toggle notifications on and off.  Click settings to modify your Watchdog settings for each page.
          </div>
        </div>
        <div>
          <v-btn rounded small
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            @click="() => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: 'What is POP Watchdog Monitoring?',
                videoLink: 'https://www.youtube.com/embed/yU3kSNgQrGo?si=wnwXS77KXXXqyoqI',
                description: 'POP inventor Kyle Roof explains why you need to use POP Watchdog Monitoring for your pages.',
                time: '2m 08s'
              });
            }">
            <span>Watch video</span>
          </v-btn>          
        </div>
      </div>

      <div class="d-flex my-5">
        <div class="tabs">
          <div @click="currentTab = 'pages'" :class="{ 'active': currentTab == 'pages' }">Current Watchdog pages</div>
          <div @click="currentTab = 'runs'" :class="{ 'active': currentTab == 'runs' }">Previous Watchdog runs</div>
        </div>
        <div v-if="isAdmin" class="flex-grow-1 text-right">
          <v-btn rounded small
            color="profileAnchorColor"
            class="whiteColor--text text-none"
            @click="testSummaryEmail">Send summary email for test</v-btn>
        </div>
      </div>

      <div v-if="currentTab == 'pages'"
        class="d-flex align-center mb-5">
        <div
          v-if="user.isGuardSummaryReportEnabled && user.nextSummaryReportDate"
          class="summary-date-box mr10">
          Your next summary email will go out on
          {{ toLocalDateString(user.nextSummaryReportDate) }}
          <i class="material-icons">mail</i>
        </div>
        <div class="flex-grow-1"></div>
        <v-btn rounded
          color="profileAnchorColor"
          class="button-with-icon whiteColor--text text-none"
          @click="() => { showCreateWatchdogSetupModal = true; }">
          <span class="material-icons">add</span>
          <span>Add new project</span>
        </v-btn>
      </div>
      <div class="white-common-box">
        <div class="card-head-part d-flex justify-space-between align-center gap10 pa-3">
          <div v-if="currentTab == 'pages'" class="search-input">
            <input
              type="text"
              placeholder="Search by project"
              v-model="searchPagesByProject"
            />
            <button @click="onFilter">Search</button>
          </div>
          <div v-if="currentTab == 'runs'" class="search-input">
            <input
              type="text"
              placeholder="Search"
              v-model="searchForReport"
            />
            <button @click="onFilter">Search</button>
          </div>
          <select v-model="perPage" @change="onPerPageChange" class="pop-select">
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="25">25 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div v-show="currentTab == 'pages'" class="pa-3">
          <b-table
            :data="pagesWithGuardSetting"
            :total="pagesWithGuardSetting.length"
            :paginated="needPaginationForPagesTable"
            :loading="isLoading"
            checkable
            :checked-rows.sync="checkedPageRows"
            :per-page="perPage"
          >
            <template slot-scope="props">
              <b-table-column field="guardRunId" label="ID">{{
                props.row.id
              }}</b-table-column>
              <b-table-column field="name" label="Page and project">
                <div>
                  <div class="font-weight-bold">{{ props.row.name }}</div>
                  <div>{{ props.row.siteName }}</div>
                </div>
              </b-table-column>
              <b-table-column field="guardDiffData" label="Rank change">
                <span v-html="getRankChange(props.row)" class="d-flex align-center gap5"></span>
              </b-table-column>
              <b-table-column field="guardRepeat" label="Report frequency">
                {{ props.row.guardRepeat }}
              </b-table-column>
              <b-table-column field="notification" label="Notification email">
                <vue-toggles
                  :value="props.row.isNotificationEnabled"
                  width="40" height="20"
                  :disabled="isLoading"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="() => showNotificationChangeModalForPage(props.row)"
                ></vue-toggles>
              </b-table-column>
              <b-table-column field="isIncludeOnSummary" label="Summary email">
                <vue-toggles
                  :value="props.row.isIncludeInSummaryEnabled"
                  width="40" height="20"
                  :disabled="isLoading"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="() => showIncludeOnSummaryModalForPage(props.row)"
                ></vue-toggles>
              </b-table-column>
              <!-- <b-table-column label="Filter">
                <button
                  class="button is-warning"
                  @click="() => togglePageSelect(props.row.id)"
                >
                  {{ selectedPageId == props.row.id ? "UnSelect" : "Select" }}
                </button>
              </b-table-column> -->
              <b-table-column label="Settings" class="setting-icons">
                <!-- <v-btn icon :id="`dot-menu-for-${props.row.id}`">
                  <img src="/static/watchdog_dots.png"
                    width="20px"
                    class="guard-card-dot" />
                  </v-btn> -->
                  <span
                    class="material-icons-outlined profileAnchorColor--text"
                    @click="() => cancelWatchdogSetupForPages([ props.row.id ])">
                    delete
                  </span>
                  <span
                    class="material-icons-outlined profileAnchorColor--text"
                    @click="() => pauseWatchdogForPages([ props.row.id ])">
                    pause_circle_outline
                  </span>
                  <span
                  class="material-icons-outlined profileAnchorColor--text ml-1"
                  @click="() => openPageSettingModal(props.row)">
                  settings
                </span>
              </b-table-column>
            </template>
          </b-table>
          <div v-if="pagesWithGuardSetting.length == 0"
            class="notify-label">
            <i class="material-icons">error_outline</i>
            <span>
              It looks like you don't have any tracking enabled. Don't miss out on valuable insights — set up Watchdog for comprehensive SERP analysis and stay ahead of the game.
            </span>
          </div>
        </div>
        <div v-show="currentTab == 'runs'" class="pa-3">
          <b-table
            :data="records"
            :loading="isLoading"
            :paginated="needPaginationForRunsTable"
            backend-pagination
            :total="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
          >
            <template slot-scope="props">
              <b-table-column field="runId" label="Run ID" sortable numeric>
                {{ props.row.runId }}
              </b-table-column>
              <b-table-column field="pageId" label="ID" sortable numeric>{{
                props.row.page.id
              }}</b-table-column>
              <b-table-column field="createdAt" label="Date" sortable>{{
                toLocalDateString(props.row.createdAt)
              }}</b-table-column>
              <b-table-column field="pageUrl" label="Page Url" sortable>{{
                props.row.url
              }}</b-table-column>
              <b-table-column field="keyword" label="Keyword" sortable>{{
                props.row.keyword
              }}</b-table-column>
              <b-table-column field="guardDiffData" label="Status" sortable>
                <span v-if="watchdogStatus(props.row.pageRun)"
                  :class="{
                    'task-pending': props.row.pageRun.guardDiffData && props.row.pageRun.guardDiffData.new_tasks_pending,
                    'all-good': props.row.pageRun.guardDiffData && !props.row.pageRun.guardDiffData.new_tasks_pending,
                  }"
                >{{ watchdogStatus(props.row.pageRun) }}</span>
              </b-table-column>
              <b-table-column field="download" label="Download">
                <a class="view-link"
                  @click="downloadRun(props.row)">
                  Download</a>
              </b-table-column>

              <b-table-column field="goto-report" label="Action">
                <a class="view-link"
                  @click="gotoReport(props.row)"
                >
                  View
              </a>
              </b-table-column>
            </template>
            <template slot="detail" slot-scope="props">
              <div>Information {{ props.row.page.id }}</div>
            </template>
          </b-table>
        </div>
      </div>
    </div>


    <div v-if="checkedPageRows.length > 0" class="action-bar" style="width: 350px;">
      <div class="left-area">
        {{ checkedPageRows.length }}
      </div>
      <div class="middle-area">
        <div class="item-selected-text">
          {{ checkedPageRows.length > 1 ? 'items': 'item' }} selected
        </div>
        
        <div
          class="remove-btn"
          @click="() => cancelWatchdogSetupForPages( checkedPageRows.map(p => p.id) )">
          <span class="material-icons-outlined">delete</span>
          <span>Delete</span>
        </div>
        <div
          class="remove-btn"
          @click="() => pauseWatchdogForPages( checkedPageRows.map(p => p.id) )">
          <span class="material-icons-outlined">pause_circle_outline</span>
          <span>Pause</span>
        </div>
        
      </div>
      <div class="right-area">
        <span class="material-icons close-button"
          @click="() =>{
            checkedPageRows = []
          }">
          close
        </span>
      </div>
    </div>

    <page-setting-modal ref="pageSettingModal" :disableDeletePage="true" />
    <guard-summary-modal ref="guardSummaryModal" />

    <div class="modal" :class="{ 'is-active': showNotificationConfirmModal }">
      <div
        class="modal-background"
        @click="showNotificationConfirmModal = false"
      ></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div>
            <div class="title-font mainColor--text is-size-4 mb20">
              <template v-if="selectedPageForNotification">
                {{
                  selectedPageForNotification.isNotificationEnabled
                    ? "Do you want to turn off the notification?"
                    : "Do you want to turn on the notificaiton?"
                }}
              </template>
            </div>
            <div class="d-flex justify-end gap10 mt-5">
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                @click="changePageNotification"
              >
                Yes
              </v-btn>
              <v-btn rounded
                color="grayColor"
                class="button-with-icon white--text text-none"
                @click="showNotificationConfirmModal = false"
              >
                No
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="() => (showNotificationConfirmModal = false)"
        >
        <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <div
      class="modal"
      :class="{ 'is-active': showIncludeOnSummaryConfirmModal }"
    >
      <div
        class="modal-background"
        @click="showIncludeOnSummaryConfirmModal = false"
      ></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div>
            <div class="title-font is-size-5 mb20 mainColor--text">
              <template v-if="selectedPageForNotification">
                {{
                  selectedPageForNotification.isIncludeInSummaryEnabled
                    ? "Do you want to exclude in summary email?"
                    : "Do you want to include in summary email?"
                }}
              </template>
            </div>
            <div class="control flex mt20">
              <div class="flex-grow-1"></div>
              <v-btn rounded
                color="darkGrayColor"
                class="button-with-button text-none whiteColor--text"
                @click="showIncludeOnSummaryConfirmModal = false"
              >
                Cancel
              </v-btn>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-button text-none whiteColor--text"
                @click="changePageIncludeOnSummary"
              >
                Yes
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="() => (showIncludeOnSummaryConfirmModal = false)"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <div class="modal" :class="{'is-active': showCreateWatchdogSetupModal}">
      <div class="modal-background" @click="showCreateWatchdogSetupModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root d-flex flex-column">
            <div class="modal-title mainColor--text font-weight-bold">Select a project and a page</div>
            <div class="mt-2 mainColor--text">
              Select project and page you would like to set up POP Watchdog for. 
            </div>

            <div class="projects-and-pages-for-setup-prepare mt-5 flex-grow-1">
              <div class="header-part">
                <div>
                  Projects with at least 1 page
                </div>
              </div>
              <div class="sites-wrapper">
                <div class="site-item">
                  <input type="checkbox" style="visibility: hidden;" />
                  <div class="site-name font-weight-bold">Projects</div>
                  <div class="pages-count-col font-weight-bold">
                    Pages
                  </div>
                </div>
                <div v-for="(site, idx) in sitesForSetup" :key="idx">
                  <div class="site-item" @click="() => {
                    if (selectedSiteId == site.id) {
                      selectedSiteId = '';
                    } else {
                      selectedSiteId = site.id;
                    }
                    selectedPage = null;
                  }">
                    <input type="checkbox" :checked="selectedSiteId == site.id" />
                    <div class="site-name">{{ site.name }}</div>
                    <div class="pages-count-col">
                      {{ site.pages.length }} {{ site.pages.length > 1 ? 'pages' : 'page' }}
                    </div>
                  </div>
                  <div v-if="selectedSiteId == site.id" class="my-2">
                    <div v-for="(page, pageIdx) in site.pages" :key="pageIdx" class="page-item" @click="() => {
                      if (page.isGoogleGuardEnabled) {
                        return;
                      }
                      selectedPage = page;
                    }">
                      <input type="checkbox" :checked="page.isGoogleGuardEnabled || selectedPage?.id == page.id" :disabled="page.isGoogleGuardEnabled" />
                      <div class="page-name">{{ page.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 d-flex justify-end gap10">
              <v-btn rounded outlined
                color="mainColor"
                class="button-with-icon text-none"
                @click="showCreateWatchdogSetupModal = false">
                <span>Cancel</span>
              </v-btn>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                :disabled="!selectedSiteId || !selectedPage"
                @click="() => {
                  showCreateWatchdogSetupModal = false;
                  openPageSettingModal(selectedPage, true)
                }">
                <span>Next</span>
                <i class="material-icons">arrow_forward</i>
              </v-btn>
            </div>
          </div>
          <button
            class="custom-modal-close-btn"
            aria-label="close"
            @click="() => (showCreateWatchdogSetupModal = false)"
          >
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import PageSettingModal from "@/components/PageSettingModal.vue";
import GuardSummaryModal from "@/components/GuardSummaryModal.vue";
import { mixin } from "../components/PageRun/ContentBrief/cb_mixin";
import { numberToRankEng, shortenUrl, toLocalDateString, isInSameDomain, isSameUrl, extraExplainTextOfUrl } from "@/utils";

export default {
  name: "GuardDashboard",
  components: {
    PageSettingModal,
    GuardSummaryModal
  },
  computed: {
    isAgencyUnlimitedUser() {
      return this.$store.getters.isAgencyUnlimitedUser;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    user() {
      return this.$store.state.user;
    },
    sitesForSetup () {
      let sites = this.$store.state.sites.filter(s => s.pages.length > 0)
      return sites.sort((a, b) => {
          if (a.name.toLowerCase()==b.name.toLowerCase()) return 0
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
    },

    needPaginationForPagesTable () {
      if (Math.ceil(this.pagesWithGuardSetting.length / this.perPage) > 1) {
        return true;
      }
      return false;
    },
    needPaginationForRunsTable () {
      if (Math.ceil(this.totalRows / this.perPage) > 1) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {

      currentTab: 'pages', // pages/runs


      isLoading: false,
      totalRows: 0,
      records: [],
      currentPage: 1,
      perPage: 10,
      sortField: "runId",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      searchPagesByProject: '',
      searchForReport: "",

      pagesWithGuardSetting: [],

      selectedPageForNotification: null,
      showNotificationConfirmModal: false,
      showIncludeOnSummaryConfirmModal: false,



      showCreateWatchdogSetupModal: false,
      selectedSiteId: '',
      selectedPage: null,
      checkedPageRows: [],
    };
  },
  methods: {
    getRankChange (page) {
      if (! page || !page.latestGuardDiffData) return '';
      const competitor_changes = page.latestGuardDiffData.competitors_change || []
      let found = competitor_changes.find(item => {
        return isSameUrl(item.item.url, page.url)
      })
      if (found) {
        let old_rank = found.old_rank || 100
        let new_rank = found.new_rank || 100
        
        if (found.type == 'removed_url') {
          return `
            <span class="material-icons-outlined red3Color--text">trending_down</span>
            <span>left top 10</span>
          `
        } else if (found.type == 'new_url') {
          return `
            <span class="material-icons-outlined green7Color--text">trending_up</span>
            <span>entered top 10</span>
          `
        } else {
          if (old_rank > new_rank) {
            return `
              <span>${ numberToRankEng(old_rank) }</span>
              <span class="material-icons-outlined green7Color--text">east</span>
              <span class="green7Color--text">${ numberToRankEng(new_rank) }</span>
            `
          } else {
            return `
              <span>${ numberToRankEng(old_rank) }</span>
              <span class="material-icons-outlined red3Color--text">east</span>
              <span class="red3Color--text">${ numberToRankEng(new_rank) }</span>
            `
          }
        }

        
      } else {
        return ''
      }
    },

    pauseWatchdogForPages (pageIds) {
      if (pageIds.length == 0) {
        this.$notify({
          group: 'info', type: 'warning',
          text: 'Please select pages to cancel'
        })
        return;
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('pauseWatchdogSetupForPages', {
        pageIds: pageIds
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully paused.'
          })
        }

      })
      .finally(() => {
        this.$store.commit('hideLoading')
        this.loadData()
      })
    },
    cancelWatchdogSetupForPages (pageIds) {
      if (pageIds.length == 0) {
        this.$notify({
          group: 'info', type: 'warning',
          text: 'Please select pages to cancel'
        })
        return;
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('cancelWatchdogSetupForPages', {
        pageIds: pageIds
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully deleted watchdog setup.'
          })
        }

      })
      .finally(() => {
        this.$store.commit('hideLoading')
        this.loadData()
      })
    },
    testSummaryEmail() {
      console.log("testing summary email");
      this.$store.commit("showLoading");
      this.$store
        .dispatch("testRunForSummaryReport")
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$notify({
              group: 'info',
              type: 'success',
              text:
                "Successfull executed summary module for test purpose.\n You will get an email for it."
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr);
    },
    onPerPageChange() {
      this.$nextTick(() => this.loadData());
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadData();
    },
    onFilter() {
      // console.log('filtering')
      this.$nextTick(() => {
        this.loadData();
      });
    },
    loadData() {
      // remove full screen loading indicator for speed up (to feel user experience)
      // this.$store.commit('showLoading')
      this.isLoading = true;
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterPagesText=${this.searchPagesByProject}`,
        `filterText=${this.searchForReport}`,
        `pageId=${this.selectedPageId ? this.selectedPageId : 0}`
      ].join("&");

      this.$store
        .dispatch("getGuardRunsForUser", { params })
        .then(({ data }) => {
          const { total, records, pages } = data;
          this.totalRows = total;
          this.records = records;

          this.pagesWithGuardSetting = pages;
        })
        .finally(() => {
          // this.$store.commit('hideLoading')
          this.isLoading = false;
          this.checkedPageRows = [];
        });
    },
    // downloadFile(downloadUrl) {
    //   if (!downloadUrl) return;
    //   let fileName = downloadUrl.split('/').pop()
    //   let downloadLink;
    //   downloadLink = document.createElement("a");
    //   downloadLink.download = fileName;
    //   downloadLink.href = downloadUrl
    //   downloadLink.style.display = "none";
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    //   setTimeout(() => {
    //     document.body.removeChild(downloadLink)
    //   }, 100);
    // },
    downloadRun(row) {
      const pageRun = row.pageRun;
      this.$store
        .dispatch("downloadContentBrief", { downloadType: "pdf", pageRunId: pageRun.id })
        .then(response => {
          if (!response.data || response.data.status !== "SUCCESS") {
            return console.log("failed to make content brief");
          }

          const { contentBriefDataFile } = response.data;
          const fileName = contentBriefDataFile.pdf;
          const downloadUrl = `${API_URL}/download/?file=${
            fileName
          }`;
          this.downloadFile(downloadUrl);
        })
        .catch(() => {
          console.log("error check server side");
        });
    },
    gotoReport(row) {
      const pageRun = row.pageRun;
      console.log(pageRun);
      this.$router.push({
        name: "PageRun",
        params: {
          siteId: row.siteId,
          pageId: row.page.id,
          pageRunId: pageRun.id
        }
      });
    },
    showNotificationChangeModalForPage(page) {
      this.selectedPageForNotification = page;
      this.showNotificationConfirmModal = true;
      // setTimeout(() => {
      //   // To keep checkbox state
      //   this.$refs[`enable-guard-notification` + page.id].checked =
      //     page.isNotificationEnabled;
      // }, 50);
    },
    changePageNotification() {
      // forOnlyNotificationChange
      this.$store.commit('showLoading');
      this.$store.dispatch("updatePageSettings", {
        forOnlyNotificationChange: true,
        pageId: this.selectedPageForNotification.id
      })
      .then(() => {
        this.selectedPageForNotification = null;
        this.showNotificationConfirmModal = false;
        this.loadData();
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      });
    },
    showIncludeOnSummaryModalForPage(page) {
      this.selectedPageForNotification = page;
      this.showIncludeOnSummaryConfirmModal = true;
      // setTimeout(() => {
      //   // To keep checkbox state
      //   this.$refs[`is-include-on-summary` + page.id].checked =
      //     page.isIncludeInSummaryEnabled;
      // }, 50);
    },
    changePageIncludeOnSummary() {
      // forOnlyIncludeOnSummaryChange
      this.$store.commit("showLoading");
      this.$store.dispatch("updatePageSettings", {
        forOnlyIncludeOnSummaryChange: true,
        pageId: this.selectedPageForNotification.id
      })
      .then(response => {
        if (response.data && response.data.status == "SUCCESS") {
          this.$store.commit("setUser", response.data.user);
        }

        this.selectedPageForNotification = null;
        this.showIncludeOnSummaryConfirmModal = false;
        this.loadData();
      })
      .finally(() => {
        this.$store.commit("hideLoading");
      });
    },

    openPageSettingModal(page, enableGoogleGuardByDefault=false) {
      this.$refs.pageSettingModal.show(page, this.loadData, '', null, enableGoogleGuardByDefault);
    },
    togglePageSelect(pageId) {
      if (this.selectedPageId == pageId) {
        this.selectedPageId = null;
      } else {
        this.selectedPageId = pageId;
      }
      this.loadData();
    },
    showSummarySettingModal() {
      console.log("DDD", this.$refs);
      this.$refs.guardSummaryModal.show();
    },
    watchdogStatus (pageRun) {
      if (pageRun.status != 'SUCCESS') {
        return ''
      }
      if ( pageRun.guardDiffData && pageRun.guardDiffData.new_tasks_pending ) {
        return "Tasks pending"
      } else {
        return "All Good"
      }
    },
    goBack () {
      this.$router.go(-1);
    }
  },
  mounted() {
    if (this.records.length == 0) {
      this.loadData();
    }
  }
};
</script>

<style scoped lang="scss">

.tabs {
  border: 2px solid var(--v-gray12Color-base);
  border-radius: 7px;
  color: var(--v-mainColor-base);
  & > div {
    cursor: pointer;
    padding: 15px 20px;
  }
  & > div:first-child {
    border-right: 2px solid var(--v-gray12Color-base);
  }
  & > div.active {
    background: var(--v-gray13Color-base);
  }

}

.summary-date-box {
  background: var(--v-green6Color-base);
  color: var(--v-mainColor-base);
  font-weight: 700;
  border-radius: 5px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  i {
    font-size: 1rem;
    margin-left: 5px;
    color: var(--v-green7Color-base);
  }
}

.task-pending {
  padding: 5px 10px;
  border-radius: 10px;
  color: var(--v-red2Color-base);
  background: var(--v-red1Color-base);
  font-weight: bold;
  white-space: nowrap;
}
.all-good {
  padding: 5px 10px;
  border-radius: 10px;
  color: var(--v-green5Color-base);
  background: var(--v-green4Color-base);
  font-weight: bold;
  white-space: nowrap;
}

.v-application.theme--dark {
  .task-pending {
    background: transparent;
  }
  .all-good {
    background: transparent;
  }
}

.view-link {
  color: var(--v-link1Color-base);
  font-weight: bold;
}

.b-table {
  .table thead td, .table thead th {
    border-bottom-width: 1px !important;
  }
  th, td {
    vertical-align: middle;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}


.card-head-part {
  background: var(--v-pink1Color-base);
  border-bottom: 1px solid var(--v-grayColor-base);
}

.search-input {
  position: relative;
  background: var(--v-whiteColor-base);
  border-radius: 5px;
  border: 1px solid var(--v-grayColor-base);
  display: flex;
  min-width: 330px;
  overflow: hidden;
  input {
    flex-grow: 1;
    padding: 10px 10px 10px 35px;
    font-size: 1rem;
    &, &::placeholder {
      color: var(--v-mainColor-base);
    }
    outline: none;
  }
  &::before {
    position: absolute;
    font-family: 'Material Icons';
    content: '\e8b6';
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.1rem;
    color: var(--v-mainColor-base);
  }
  button {
    background: var(--v-profileAnchorColor-base);
    color: var(--v-whiteColor-base);
    border: none;
    outline: none;
    padding-left: 5px;
    padding-right: 5px;
  }
}


.projects-and-pages-for-setup-prepare {
  border-radius: 10px;
  border: 1px solid var(--v-grayColor-base);
  color: var(--v-mainColor-base);
  overflow: hidden;
  & .header-part {
    padding: 15px 15px;
    font-weight: 700;
    background: var(--v-pink1Color-base);
    border-bottom: 1px solid var(--v-grayColor-base);
    display: flex;
    & > div:first-child {
      flex-grow: 1;
    }
    .pages-count-col {
      flex-basis: 100px;
      flex-shrink: 0;
      flex-grow: 0;
    }

  }
  & > .sites-wrapper {
    overflow: auto;
    max-height: 500px;
  }
  .site-item {
    padding: 15px 15px;
    border-top: 1px solid var(--v-grayColor-base);
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    .site-name {
      flex-grow: 1;
    }
    .pages-count-col {
      flex-basis: 100px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  .page-item {
    padding: 5px 10px 5px 35px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    .site-name {
      flex-grow: 1;
    }
  }
}

.setting-icons {
  span {
    cursor: pointer;
  }
}


</style>
