<template>
<div class="tool-card pa-5">
  <div class="top-part">
    <span class="icon-part">
      <span class="material-icons-outlined">auto_awesome</span>
    </span>
    <span class="name">AI Writer</span>
    <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
      @click="() => {
        $store.commit('setCurrentVideoTutorialToDisplay', {
          title: 'POP AI Express Walkthrough',
          videoLink: 'https://www.youtube.com/embed/74O9o0d1mfs?si=iQGbwfnehZ3F-chU',
          description: 'With this brand new PageOptimizer Pro update you\'ll be able to create reports for the AI Writer even faster. In this video you\'ll learn about what changes were made, and how to take advantage of them.',
          time: '2m 27s'
        });
      }">
      videocam
    </span>
  </div>
  <div class="mt-5">
    <v-text-field outlined dense
      v-model="searchTerm"
      hide-details="auto"
      label="Search"
      color="greenColor">
    </v-text-field>
  </div>
  <div class="flex-grow-1 pages-wrapper mt-3">
    <template v-if="$store.getters.isDemoUser">
      <div v-for="page in demoSearchPages" :key="page.id" class="page-item" @click="() => {
        $router.push(`/sites/${site.id}/pages/${page.id}?tab=ai-writer`)
      }">
        <div class="flex-grow-1">
          <div class="name">{{ page.name }}</div>
          <div class="time">
            {{ toLocalDateTimeString(page.createdAt) }}
          </div>
        </div>
        <div class="action">
          <i class="material-icons-outlined">arrow_circle_right</i>
        </div>
      </div>
      <div v-if="demoPages.length == 0" class="no-project-block">
        <div>
          <img src="/static/empty-folder.png" />
          <div>
            You haven't created any <br />
            AI reports for this project yet
          </div>
        </div>
      </div>
    </template>
    <div v-else v-for="page in searchedPages" :key="page.id" class="page-item" @click="() => {
      $router.push(`/sites/${site.id}/pages/${page.id}?tab=ai-writer`)
    }">
      <div class="flex-grow-1">
        <div class="name">{{ page.name }}</div>
        <div class="time">
          {{ toLocalDateTimeString(page.createdAt) }}
        </div>
      </div>
      <div class="action">
        <i class="material-icons-outlined">arrow_circle_right</i>
      </div>
    </div>
    <div v-if="pagesWhichHasAiArticle.length == 0" class="no-project-block">
      <div>
        <img src="/static/empty-folder.png" />
        <div>
          You haven't created any <br />
          AI reports for this project yet
        </div>
      </div>
    </div>
  </div>

  <div :class="`mt-5 d-flex align-center ${$store.getters.isDemoUser ? 'justify-end' : 'justify-space-between'}`">
    <v-btn v-if="!$store.getters.isDemoUser" rounded small color="profileAnchorColor"
      class="button-with-icon whiteColor--text text-none"
      @click="showAiReportPopup">
    <span>Create new</span>
    </v-btn>
    <template v-if="$store.getters.isDemoUser">
      <span class="how-many" :style="{ cursor: $store.getters.isDemoUser ? 'default' : '' }" @click="() =>  { $store.getters.isDemoUser ? null : $router.push(`/sites/${site.id}/pages?tab=ai-writer`) }">
        {{ demoPages.length }} {{ demoPages.length > 1 ? 'pages' : 'page' }}
      </span>
    </template>
    <span v-else class="how-many" :style="{ cursor: $store.getters.isDemoUser ? 'default' : '' }" @click="() =>  { $store.getters.isDemoUser ? null : $router.push(`/sites/${site.id}/pages?tab=ai-writer`) }">
      {{ pagesWhichHasAiArticle.length }} {{ pagesWhichHasAiArticle.length > 1 ? 'pages' : 'page' }}
    </span>
  </div>
  <ai-report-popup ref="aiReportPopupRef" />
</div>
</template>

<script>
import { mixin } from '@/components/mixin'
import { DEMO_SESSION_KEYS } from "@/components/PageRun/constants"
import AiReportPopup from "@/components/AiReportPopup.vue"; // Adjust the import path as needed

export default {
  components: { AiReportPopup },
  mixins: [mixin, ],
  data () {
    return {
      searchTerm: '',
      showPickingReportTypeModal: false,
    }
  },
  computed: {
    pagesWhichHasAiArticle () {
      return this.pages.filter(p => {
        return p.hasAiArticle
      })
    },
    searchedPages () {
      return this.pagesWhichHasAiArticle.filter(p => {
        return p.hasAiArticle && p.name.includes(this.searchTerm)
      })
    },
    demoPages() {
      return this.isDemoPageNotCreated() ? [] : this.pagesWhichHasAiArticle
    },
    demoSearchPages() {
      return this.demoPages.filter(p => {
        return p.hasAiArticle && p.name.includes(this.searchTerm)
      })
    }
  },
  methods: {
    showAiReportPopup () {
      this.$refs.aiReportPopupRef.showAiReportPopup();
    },
    isDemoPageNotCreated() {
      return !sessionStorage.getItem(DEMO_SESSION_KEYS.pageCreated)
    },
    loadSite () {
      this.$store.commit('showLoading')
        return this.$store.dispatch('loadSite', this.site.id)
          .then((response) => {
            if (response.data && response.data.status === 'SUCCESS') {
              if (response.data.site) {
                this.$store.commit('setSite', response.data.site)
              }
            }
          })
          .finally(() => this.$store.commit('hideLoading'))
    },
  },
  beforeMount () {
    this.siteName = this.site ? this.site.name : ''
    if (!this.$store.state.sites.length) {
      this.$store.commit('showLoading')
      return this.$store.dispatch('loadSites')
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('setSites', response.data.sites)
          }
        })
        .finally(() => {
          // this.$store.commit('hideLoading')
          this.loadSite()
        })
    } else {
      this.loadSite()
    }
  },
}
</script>
