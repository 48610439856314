<template>
<div :key="componentKey">

  <div class="d-flex align-center mt-3">
    <div class="flex-grow-1 report-one-page-name gap10">
      <span>Compare data</span>
      <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
        @click="() => {
          // $store.commit('setCurrentVideoTutorialToDisplay', {
          //   title: 'How to use Google Entities NLP',
          //   videoLink: 'https://www.youtube.com/embed/zrX_SemcuIw?si=acw6lIHzFxuLB0jI',
          //   description: 'Learn how to use POP Google Entities report for your projects.',
          //   time: '4m 39s'
          // });
        }">
        videocam
      </span>
    </div>
  </div>
  <div class="mt-3 mb-8 mainColor--text">
    Here you can find signal specific recommendations on how to optimize your page. <br />
    When working with this tab, we recommend that you do your increases first as reducing is a very high risk high reward strategy.
  </div>
  <div class="tab-line">
    <div class="tab-item" :class="{ 'active': $parent.activeTab == 'keywords' }" @click="$parent.showTab('keywords')">All Competitor Recommendations</div>
    <div class="tab-item" :class="{ 'active': $parent.activeTab == 'adjKeywords' }" @click="$parent.showTab('adjKeywords')">Based on Your Word Count Recommendations</div>
    <div class="flex-grow-1"></div>
    <div>
      <v-btn
        :disbaled="isDemo"
        outlined
        small
        color="mainColor"
        class="button-with-icon text-none"
        v-if="!isFromAdminPage" @click.stop="isDemo ? null : showExpressEditorModal()" data-tooltip="Open Content editor">
        <span class="material-icons">open_in_new</span>
        <span>Open Content Editor</span>
      </v-btn>
      <v-btn
        :disbaled="isDemo"
        outlined
        small
        color="mainColor"
        class="button-with-icon text-none ml-2"
        :href="isDemo ? '#' : downloadUrl(pageRun)" data-tooltip="Download Report">
        <span class="material-icons-outlined">file_download</span>
        <span>Download</span>
      </v-btn>
      <!-- <b-dropdown aria-role="list" class="mr5 is-right">
        <a class="icon-only-button tooltip is-tooltip-top" data-tooltip="Download Checklist Options" slot="trigger">
          <i class="material-icons">settings</i>
        </a>
        <b-dropdown-item @click="downloadExcelForChecklist(0, pageId, pageRunId)">Recommendations only</b-dropdown-item>
        <b-dropdown-item @click="downloadExcelForChecklist(1, pageId, pageRunId)">Recommendations and current status</b-dropdown-item>
        <b-dropdown-item @click="downloadExcelForChecklist(2, pageId, pageRunId)">Export all (with notes)</b-dropdown-item>
      </b-dropdown> -->
    </div>
  </div>

  <div class="mt-10 type-selection">
    <span class="type-item" :class="{'active': signalType=='keyword'}" @click="signalType='keyword'">
      <span class="material-icons-outlined">vpn_key</span>
      <span>Exact keyword</span>
    </span>
    <span class="type-item" :class="{'active': signalType=='variations'}" @click="signalType='variations'">
      <span class="material-icons-outlined">account_tree</span>
      <span>Variations</span>
    </span>
    <span class="type-item" :class="{'active': signalType=='lsi_terms'}" @click="signalType='lsi_terms'">
      <span class="material-icons-outlined">workspaces</span>
      LSI
    </span>
    <span v-if="type === 'Keyword'" class="type-item" :class="{'active': signalType=='wordCount'}" @click="signalType='wordCount'">
      <span class="material-icons-outlined">motion_photos_auto</span>
      <span>Word Count</span>
    </span>
  </div>

  <div class="mt-8 type-selection">
    <span class="selected-one" v-if="signalType=='keyword'" @click="signalType='keyword'">
      <span class="material-icons-outlined">vpn_key</span>
      <span>Exact keyword</span>
    </span>
    <span class="selected-one" v-if="signalType=='variations'" @click="signalType='variations'">
      <span class="material-icons-outlined">account_tree</span>
      <span>Variations</span>
    </span>
    <span class="selected-one" v-if="signalType=='lsi_terms'" @click="signalType='lsi_terms'">
      <span class="material-icons-outlined">workspaces</span>
      LSI
    </span>
    <span class="selected-one"
      v-if="type === 'Keyword' && signalType=='wordCount'" @click="signalType='wordCount'">
      <span class="material-icons-outlined">motion_photos_auto</span>
      <span>Word Count</span>
    </span>
    <div class="compariing-section ml-5">
      <span>COMPARING TO</span>
      <span>
        <v-menu
          :disabled="isDemo"
          v-model="showComparingDropdown"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="d-flex align-center">
              {{ itemsSelectedText }}
              <span class="material-icons-outlined mainColor--text" style="font-size: 1rem;">keyboard_arrow_down</span>
            </span>
          </template>

          <v-card>
            <v-list>
              <template v-if="signalType === 'keyword'">
                <v-list-item>
                  <v-checkbox dense v-model="showKwRegular" label="All competitors"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox dense v-model="showKwFocus" label="Focus competitors"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox dense v-model="showKwMax" label="Max values"></v-checkbox>
                </v-list-item>
                <v-list-item v-if="$parent.activeTab == 'keywords'">
                  <v-checkbox dense v-model="showKwAdjData" label="Your word count"></v-checkbox>
                </v-list-item>
              </template>
              <template v-if="signalType === 'variations' || (signalType === 'lsi_terms' && hasLsiSignals)">
                <v-list-item>
                  <v-checkbox dense v-model="showVarRegular" label="All competitors"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox dense v-model="showVarFocus" label="Focus competitors"></v-checkbox>
                </v-list-item>
                <v-list-item>
                  <v-checkbox dense v-model="showVarMax" label="Max values"></v-checkbox>
                </v-list-item>
                <v-list-item v-if="$parent.activeTab == 'keywords'">
                  <v-checkbox dense v-model="showVarAdjData" label="Your word count"></v-checkbox>
                </v-list-item>
              </template>
              <v-list-item>
                <select v-model="comparePageRunId" v-show="signalType !== 'wordCount'" class="mainColor--text">
                  <option value="">Select one report to compare</option>
                  <template v-for="p in comparingPageRuns">
                    <option :value="p.id" :key="p.id" v-if="p.id!=pageRunId">
                      {{ p.keyword ? p.keyword : page.keyword }} - {{ getDateAsLocalString(p.createdAt) }}
                    </option>
                  </template>
                </select>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </span>
    </div>
  </div>

  <div class="mt-8 mainColor--text mb-8">
    <template v-if="signalType=='keyword'">
      This section tells you where you should use your exact keyword and how many times you should use it. <br />
      This is your keyword, exactly as you gave it to us.
    </template>
    <template v-else-if="signalType=='variations'">
      This section tells you where you should use variations of your keyword and how many times you should use them.<br />
      The tool then calculates a “weight” or importance score.  The weight is the number listed first in the parentheses that is less than 1. <br />
      The closer to 1, the more important the term. The tool then calculates the number of times you have used the term (C: x) and compares that to the average competitor usage (A: y).
    </template>
    <template v-else-if="signalType=='lsi_terms'">
      This section looks for the "other" terms you need on your page. <br />
      POP starts off with a wide set of possible terms and then runs through different filtering processes to get down to terms where LSI (Latent Semantic Indexing / NLP (Natural Language Processing) / TF-IDF (Term Frequency - Inverse Document Frequency) are in relative agreement. <br />
      The tool then calculates a “weight” or importance score.  The weight is the number listed first in the parentheses that is less than 1.<br />
      The closer to 1, the more important the term. The tool then calculates the number of times you have used the term (C: x) and compares that to the average competitor usage (A: y).
    </template>
    <template v-else-if="type === 'Keyword' && signalType=='wordCount'">
      This section tells you what your target word count should be.  You should shoot for 10% +/- of the target.
    </template>
  </div>


  <div v-if="signalType === 'variations'">
    <div>
      <label class="box has-text-weight-bold mb-3" v-if="variations.length == 0">
        This report was run without variations. Skip this section. 
      </label>
    </div>
    <div>

      <div>

      </div>

      <span class="variations-disp-control-box">
        <v-btn small outlined color="mainColor"
          class="button-with-icon text-none"
          @click="showWeights=!showWeights">
          {{ showWeights ? 'Hide weights' : 'Show weights' }}
        </v-btn>
        <span class="mainColor--text">
          Sort: 
          <select v-model="variationSortMethod" class="mainColor--text">
            <option value="importance">Importance</option>
            <option value="deficit" :disabled="isDeficitSortDisabled">Deficit</option>
            <option value="alphabetic">Alphabetic</option>
          </select>
        </span>
        <v-btn small outlined color="mainColor"
          class="button-with-icon text-none"
          v-clipboard:copy="messageForVariationAndLSICopy('variations')"
          v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
          v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })">Copy to clipboard
        </v-btn>
      </span>
      <span v-if="!hasLsaForVariations" class="is-italic grayColor--text">Please re-run to see latest features</span>
      <div class="tags mt10">
        <template v-if="hasLsaForVariations">
          <span v-for="(item, idx) in sortedLsaVariations" :key="idx" class="variation-tag" :class="{'secondary-keyword-tag': item.isSecondaryKeyword }">
            {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ (item.weight * 100).toFixed(2) + '%' }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
          </span>
        </template>
        <template v-else>
          <span v-for="v in variations" :key="v" class="variation-tag">{{ v }}</span>
        </template>
      </div>
    </div>
  </div>
  <div v-else-if="signalType === 'lsi_terms'">
    <div v-if="hasLsiSignals">
      <span class="variations-disp-control-box">
        <v-btn small outlined color="mainColor"
          class="button-with-icon text-none"
          @click="showWeights=!showWeights">
          {{ showWeights ? 'Hide weights' : 'Show weights' }}
        </v-btn>
        <span class="mainColor--text">
          Sort: 
          <select v-model="lsiSortMethod" class="mainColor--text">
            <option value="importance">Importance</option>
            <option value="deficit" :disabled="isDeficitSortDisabled">Deficit</option>
            <option value="alphabetic">Alphabetic</option>
          </select>
        </span>
        <v-btn small outlined color="mainColor"
          class="button-with-icon text-none"
          v-clipboard:copy="messageForVariationAndLSICopy('lsi_terms')"
          v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
          v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })">
          Copy to clipboard
        </v-btn>
      </span>
      <span v-if="isDeficitSortDisabled" class="is-italic has-text-grey">Please re-run to use defict sort option</span>
      <div class="tags mt10">
        <template>
          <span v-for="(item, idx) in sortedLsaPhrases" :key="idx" class="variation-tag lsi-tag"
            :class="{ 'nlp-tag': item.isNLPTerm, 'google-nlp-tag': item.isGoogleNLPTerm }">
            {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ (item.weight*100).toFixed(2) + '%' }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
          </span>
        </template>
      </div>
      <div v-if="sortedLsaPhrases.length==0" class="is-italic">
        We were unable to find any contextual terms that met our standards for frequency of use or importance to the text.
      </div>
    </div>
    <div v-else class="box full-height row-height mt20">
        <p class="subtitle-font mt10 has-text-centered">
          To see this new data, please re-run your analysis
        </p>
    </div>
  </div>

  <div class="white-common-box pa-0 mt-5" v-if="signalType !== 'wordCount'">
    <div v-show="signalType === 'keyword'">
      <div class="hscroll-container">
        <table class="signal-table">
          <thead>
            <tr>
              <th>Where</th>
              <th v-if="!pageNotBuilt">Current</th>
              <th v-if="showKwRegular">Target</th>
              <th v-if="showKwRegular" class="recommendations">Recommendation</th>
              <th v-if="!pageNotBuilt && comparePageRunId" :style="{'background': compBg}">FMR. current</th>
              <th v-if="showKwRegular && comparePageRunId" :style="{'background': compBg}">FMR. target</th>
              <th v-if="showKwRegular && comparePageRunId" class="recommendations" :style="{'background': compBg}">
                FMR. recommendation
              </th>
              <th v-if="!pageNotBuilt && showKwAdjData" :style="{'background': compBg}">Adj. current</th>
              <th v-if="showKwAdjData" :style="{'background': compBg}">Adj. target</th>
              <th v-if="showKwAdjData" class="recommendations" :style="{'background': compBg}">
                Adj. Recommendation
              </th>

              <th v-if="hasFocusColumns && showKwFocus" :style="{'background': compBg}">Focus target</th>
              <th v-if="hasFocusColumns && showKwFocus" class="recommendations" :style="{'background': compBg}">
                Focus recommendation
              </th>
              <th v-if="hasFocusColumns && showKwFocus && comparePageRunId" :style="{'background': compBg}">FMR. focus target</th>
              <th v-if="hasFocusColumns && showKwFocus && comparePageRunId" class="recommendations" :style="{'background': compBg}">
                FMR. focus recommendation
              </th>
              
              <th v-if="hasTrueMaxColumns && showKwMax" :style="{'background': compBg}">Max Keyword</th>
              <th v-if="hasTrueMaxColumns && showKwMax" class="recommendations" :style="{'background': compBg}">
                Max keyword recommendation
              </th>
              <th v-if="hasTrueMaxColumns && showKwMax && comparePageRunId" :style="{'background': compBg}">FMR. Max Keyword</th>
              <th v-if="hasTrueMaxColumns && showKwMax && comparePageRunId" class="recommendations" :style="{'background': compBg}">
                FMR. Max Keyword Recommendation
              </th>
              <th class="action-column">Action</th>
              <th>Current selection</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in dispSignalData" :key="`${type}-signal-row-${i}`" v-if="row.signal !== 'Word Count'">
              <td class="signal-td">
                <div class="d-flex align-center gap5">
                  <span class="material-icons" v-tooltip.right="{ content: signalExplainData[row.signal], classes: 'custom-tooltip' }">info</span>
                  <span class="mainColor--text font-weight-bold">{{ row.signal }}</span>
                </div>
              </td>
              <td v-if="!pageNotBuilt">{{ row.targetKeywordCnt }}</td>
              <td v-if="showKwRegular">{{ row.competitorsAvg }}</td>
              <td v-if="!pageNotBuilt && showKwRegular" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ row.comment }}<span :class="getMarkTypeStyle(row.comment, row.isWeakSignal)"></span></td>
              <td v-if="pageNotBuilt && showKwRegular" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ row.comment }}</td>
              <td v-if="!pageNotBuilt && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareSignalData, row.signal, 'targetKeywordCnt') }}</td>
              <td v-if="showKwRegular && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareSignalData, row.signal, 'competitorsAvg') }}</td>
              <td v-if="!pageNotBuilt && showKwRegular && comparePageRunId" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispCompareSignalData, row.signal, 'comment') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareSignalData, row.signal, 'comment'), row.isWeakSignal)"></span></td>
              <td v-if="pageNotBuilt && showKwRegular && comparePageRunId" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispCompareSignalData, row.signal, 'comment') }}</td>
              <td v-if="!pageNotBuilt && showKwAdjData" :style="{'background': compBg}">{{ getCompareData(dispAdjSignalData, row.signal, 'targetKeywordCnt') }}</td>
              <td v-if="showKwAdjData" :style="{'background': compBg}">{{ getCompareData(dispAdjSignalData, row.signal, 'competitorsAvg') }}</td>
              <td v-if="!pageNotBuilt && showKwAdjData" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispAdjSignalData, row.signal, 'comment') }}<span :class="getMarkTypeStyle(getCompareData(dispAdjSignalData, row.signal, 'comment'), row.isWeakSignal)"></span></td>
              <td v-if="pageNotBuilt && showKwAdjData" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispAdjSignalData, row.signal, 'comment') }}</td>
              
              <td v-if="hasFocusColumns && showKwFocus" :style="{'background': compBg}">{{ row.featuredCompetitorsKeywordAvg }}</td>
              <td v-if="!pageNotBuilt && hasFocusColumns && showKwFocus" class='recommendations' :style="{'background': compBg}">{{ row.featuredCompetitorsComment }}<span :class="getMarkTypeStyle(row.featuredCompetitorsComment, row.isWeakSignal)"></span></td>
              <td v-if="pageNotBuilt && hasFocusColumns && showKwFocus" class='recommendations' :style="{'background': compBg}">{{ row.featuredCompetitorsComment }}</td>
              <td v-if="hasFocusColumns && showKwFocus && comparePageRunId" :style="{'background': compBg}">{{ row.featuredCompetitorsKeywordAvg }}</td>
              <td v-if="!pageNotBuilt && hasFocusColumns && showKwFocus && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareSignalData, row.signal, 'featuredCompetitorsComment') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareSignalData, row.signal, 'featuredCompetitorsComment'), row.isWeakSignal)"></span></td>
              <td v-if="pageNotBuilt && hasFocusColumns && showKwFocus && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareSignalData, row.signal, 'featuredCompetitorsComment') }}</td>
              
              <td v-if="hasTrueMaxColumns && showKwMax" :style="{'background': compBg}">{{ row.keywordsTrueMax }}</td>
              <td v-if="!pageNotBuilt && hasTrueMaxColumns && showKwMax" class='recommendations' :style="{'background': compBg}">{{ row.keywordsTrueMaxRecommendations }}<span :class="getMarkTypeStyle(row.keywordsTrueMaxRecommendations, row.isWeakSignal)" ></span></td>
              <td v-if="pageNotBuilt && hasTrueMaxColumns && showKwMax" class='recommendations' :style="{'background': compBg}">{{ row.keywordsTrueMaxRecommendations }}</td>
              <td v-if="hasTrueMaxColumns && showKwMax && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareSignalData, row.signal, 'keywordsTrueMax') }}</td>
              <td v-if="!pageNotBuilt && hasTrueMaxColumns && showKwMax && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareSignalData, row.signal, 'keywordsTrueMaxRecommendations') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareSignalData, row.signal, 'keywordsTrueMaxRecommendations'), row.isWeakSignal)" ></span></td>
              <td v-if="pageNotBuilt && hasTrueMaxColumns && showKwMax && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareSignalData, row.signal, 'keywordsTrueMaxRecommendations') }}</td>
              
              <td>
                <span v-if="isDemo" class="material-icons-outlined grayColor--text" slot="trigger">add_task</span>
                <b-dropdown v-else>
                  <!-- <v-btn rounded x-small color="profileAnchorColor"
                    class="button-with-icon text-none whiteColor--text" slot="trigger">
                    <span>Add to to-do list</span>
                    <span class="material-icons">arrow_drop_down</span>
                  </v-btn> -->
                  <span class="material-icons-outlined" :class="{
                      'profileAnchorColor--text': !row['checklistId'],
                      'green7Color--text': row['checklistId']
                    }" slot="trigger">add_task</span>
                  <b-dropdown-item @click="addToChecklist(0, 'regular', row)">Regular</b-dropdown-item>
                  <b-dropdown-item :disabled="!hasFeaturedCompetitorsUrl" @click="addToChecklist(0, 'focus', row)">Focus</b-dropdown-item>
                  <b-dropdown-item @click="addToChecklist(0, 'max', row)">Max</b-dropdown-item>
                </b-dropdown>
              </td>
              <td :class="{ 'is-warning': row['checklistId'] && !row['isArchived'], 'yellow-bg': row['checklistId'] && !row['isArchived'] }">
                <span v-if="row['checklistId'] && !row['isArchived']">
                  {{ getDispTypeForItem(row) | capitalize }}
                </span>
                <span v-else>
                  None
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-show="signalType === 'variations'">
      <table class="signal-table">
        <thead>
          <tr>
            <th>Where</th>
            <th v-if="!pageNotBuilt">Current</th>
            <th v-if="showVarRegular">Target min</th>
            <th v-if="showVarRegular">Target max</th>
            <th v-if="showVarRegular" class="recommendations">Recommendations</th>
            <th v-if="!pageNotBuilt && comparePageRunId" :style="{'background': compBg}">Fmr. current</th>
            <th v-if="showVarRegular && comparePageRunId" :style="{'background': compBg}">Fmr. target min</th>
            <th v-if="showVarRegular && comparePageRunId" :style="{'background': compBg}">Fmr. target max</th>
            <th v-if="showVarRegular && comparePageRunId" class="recommendations" :style="{'background': compBg}">
              Fmr. recommendations
            </th>
            <th v-if="!pageNotBuilt && showVarAdjData" :style="{'background': compBg}">ADJ. Current</th>
            <th v-if="showVarAdjData" :style="{'background': compBg}">Adj. target min</th>
            <th v-if="showVarAdjData" :style="{'background': compBg}">Adj. target max</th>
            <th v-if="showVarAdjData" class="recommendations" :style="{'background': compBg}">
              Adj. recommendations
            </th>

            <th v-if="hasFocusColumns && showVarFocus" :style="{'background': compBg}">Focus Target Min</th>
            <th v-if="hasFocusColumns && showVarFocus" :style="{'background': compBg}">Focus Target Max</th>
            <th v-if="hasFocusColumns && showVarFocus" class="recommendations" :style="{'background': compBg}">
              Focus recommendations
            </th>
            <th v-if="hasFocusColumns && showVarFocus && comparePageRunId" :style="{'background': compBg}">FMR. Focus Target Min</th>
            <th v-if="hasFocusColumns && showVarFocus && comparePageRunId" :style="{'background': compBg}">FMR. Focus Target Max</th>
            <th v-if="hasFocusColumns && showVarFocus && comparePageRunId" class="recommendations" :style="{'background': compBg}">
              Fmr. focus recommendations
            </th>

            <th v-if="hasTrueMaxColumns && showVarMax" :style="{'background': compBg}">Max Variations</th>
            <th v-if="hasTrueMaxColumns && showVarMax" :style="{'background': compBg}">
              Max variations recommendation
            </th>
            <th v-if="hasTrueMaxColumns && showVarMax && comparePageRunId" :style="{'background': compBg}">FMR. Max Variations</th>
            <th v-if="hasTrueMaxColumns && showVarMax && comparePageRunId" class="recommendations" :style="{'background': compBg}">
              Fmr. max variations recommendation
            </th>

            <th class="action-column">Action</th>
            <th>Current selection</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(row, i) in dispVariationData" :key="`${type}-signal-row-${i}`" v-if="row.signal !== 'Word Count' && row.signal !== 'Keyword in Main URL'">
            <td class="signal-td">
              <div class="d-flex align-center gap5">
                <span class="material-icons" v-tooltip.right="{ content: signalExplainData[row.signal], classes: 'custom-tooltip' }">info</span>
                <span>{{ row.signal }}</span>
              </div>
            </td>
            <td v-if="!pageNotBuilt">{{ row.targetVariationCnt }}</td>
            <td v-if="showVarRegular">{{ row.competitorsMin }}</td>
            <td v-if="showVarRegular">{{ row.competitorsMax }}</td>
            <td v-if="!pageNotBuilt && showVarRegular" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ row.comment }}<span :class="getMarkTypeStyle(row.comment, row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && showVarRegular" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ row.comment }}</td>
            <td v-if="!pageNotBuilt && comparePageRunId" :style="{'background': compBg}">{{  getCompareData(dispCompareVariationData, row.signal, 'targetVariationCnt') }}</td>
            <td v-if="showVarRegular && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'competitorsMin') }}</td>
            <td v-if="showVarRegular && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'competitorsMax') }}</td>
            <td v-if="!pageNotBuilt && showVarRegular && comparePageRunId" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispCompareVariationData, row.signal, 'comment') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareVariationData, row.signal, 'comment'), row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && showVarRegular && comparePageRunId" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispCompareVariationData, row.signal, 'comment') }}</td>
            <td v-if="!pageNotBuilt && showVarAdjData" :style="{'background': compBg}">{{  getCompareData(dispAdjVariationData, row.signal, 'targetVariationCnt') }}</td>
            <td v-if="showVarAdjData" :style="{'background': compBg}">{{ getCompareData(dispAdjVariationData, row.signal, 'competitorsMin') }}</td>
            <td v-if="showVarAdjData" :style="{'background': compBg}">{{ getCompareData(dispAdjVariationData, row.signal, 'competitorsMax') }}</td>
            <td v-if="!pageNotBuilt && showVarAdjData" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispAdjVariationData, row.signal, 'comment') }}<span :class="getMarkTypeStyle(getCompareData(dispAdjVariationData, row.signal, 'comment'), row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && showVarAdjData" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispAdjVariationData, row.signal, 'comment') }}</td>
            
            <td v-if="hasFocusColumns && showVarFocus" :style="{'background': compBg}">{{ row.featuredCompetitorsMin }}</td>
            <td v-if="hasFocusColumns && showVarFocus" :style="{'background': compBg}">{{ row.featuredCompetitorsMax }}</td>
            <td v-if="!pageNotBuilt && hasFocusColumns && showVarFocus" class='recommendations' :style="{'background': compBg}">{{ row.featuredCompetitorsComment }}<span :class="getMarkTypeStyle(row.featuredCompetitorsComment, row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && hasFocusColumns && showVarFocus" class='recommendations' :style="{'background': compBg}">{{ row.featuredCompetitorsComment }}</td>
            <td v-if="hasFocusColumns && showVarFocus && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'featuredCompetitorsMin') }}</td>
            <td v-if="hasFocusColumns && showVarFocus && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'featuredCompetitorsMax') }}</td>
            <td v-if="!pageNotBuilt && hasFocusColumns && showVarFocus && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'featuredCompetitorsComment') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareVariationData, row.signal, 'featuredCompetitorsComment'), row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && hasFocusColumns && showVarFocus && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'featuredCompetitorsComment') }}</td>

            <td v-if="hasTrueMaxColumns && showVarMax" :style="{'background': compBg}">{{ row.variationsTrueMax }}</td>
            <td v-if="!pageNotBuilt && hasTrueMaxColumns && showVarMax" class='recommendations' :style="{'background': compBg}">{{ row.variationsTrueMaxRecommendations }}<span :class="getMarkTypeStyle(row.variationsTrueMaxRecommendations, row.isWeakSignal)" ></span></td>
            <td v-if="pageNotBuilt && hasTrueMaxColumns && showVarMax" class='recommendations' :style="{'background': compBg}">{{ row.variationsTrueMaxRecommendations }}</td>
            <td v-if="hasTrueMaxColumns && showVarMax && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'variationsTrueMax') }}</td>
            <td v-if="!pageNotBuilt && hasTrueMaxColumns && showVarMax && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'variationsTrueMaxRecommendations') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareVariationData, row.signal, 'variationsTrueMaxRecommendations'), row.isWeakSignal)" ></span></td>
            <td v-if="pageNotBuilt && hasTrueMaxColumns && showVarMax && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareVariationData, row.signal, 'variationsTrueMaxRecommendations') }}</td>
            
            <td>
              <span v-if="isDemo" class="material-icons-outlined grayColor--text" slot="trigger">add_task</span>
              <b-dropdown v-else>
                <!-- <v-btn rounded x-small color="profileAnchorColor"
                  class="button-with-icon text-none whiteColor--text" slot="trigger">
                  <span>Add to to-do list</span>
                  <span class="material-icons">arrow_drop_down</span>
                </v-btn> -->
                <span class="material-icons-outlined" :class="{
                    'profileAnchorColor--text': !row['checklistId'],
                    'green7Color--text': row['checklistId']
                  }" slot="trigger">add_task</span>
                <b-dropdown-item @click="addToChecklist(1, 'regular', row)">Regular</b-dropdown-item>
                <b-dropdown-item :disabled="!hasFeaturedCompetitorsUrl" @click="addToChecklist(1, 'focus', row)">Focus</b-dropdown-item>
                <b-dropdown-item @click="addToChecklist(1, 'max', row)">Max</b-dropdown-item>
              </b-dropdown>
            </td>
            <td :class="{ 'is-warning': row['checklistId'] && !row['isArchived'], 'yellow-bg': row['checklistId'] && !row['isArchived'] }">
              <span v-if="row['checklistId'] && !row['isArchived']">
                {{ getDispTypeForItem(row) | capitalize }}
              </span>
              <span v-else>
                None
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="signalType === 'lsi_terms' && hasLsiSignals">
      <table class="signal-table">
        <thead>
          <tr>
            <th>Where</th>
            <th v-if="!pageNotBuilt">Current</th>
            <th v-if="showVarRegular">Target Min</th>
            <th v-if="showVarRegular">Target Max</th>
            <th v-if="showVarRegular" class="recommendations">Recommendations</th>
            <th v-if="!pageNotBuilt && comparePageRunId" :style="{'background': compBg}">FMR. Current</th>
            <th v-if="showVarRegular && comparePageRunId" :style="{'background': compBg}">FMR. Target Min</th>
            <th v-if="showVarRegular && comparePageRunId" :style="{'background': compBg}">FMR. Target Max</th>
            <th v-if="showVarRegular && comparePageRunId" class="recommendations" :style="{'background': compBg}">
              FMR. Recommendations
            </th>
            <th v-if="!pageNotBuilt && showVarAdjData" :style="{'background': compBg}">ADJ. Current</th>
            <th v-if="showVarAdjData" :style="{'background': compBg}">ADJ. Target Min</th>
            <th v-if="showVarAdjData" :style="{'background': compBg}">ADJ. Target Max</th>
            <th v-if="showVarAdjData" class="recommendations" :style="{'background': compBg}">
              ADJ. Recommendations
            </th>

            <th v-if="hasFocusColumns && showVarFocus" :style="{'background': compBg}">Focus Target Min</th>
            <th v-if="hasFocusColumns && showVarFocus" :style="{'background': compBg}">Focus Target Max</th>
            <th v-if="hasFocusColumns && showVarFocus" class="recommendations" :style="{'background': compBg}">
              Focus Recommendations
            </th>
            <th v-if="hasFocusColumns && showVarFocus && comparePageRunId" :style="{'background': compBg}">FMR. Focus Target Min</th>
            <th v-if="hasFocusColumns && showVarFocus && comparePageRunId" :style="{'background': compBg}">FMR. Focus Target Max</th>
            <th v-if="hasFocusColumns && showVarFocus && comparePageRunId" class="recommendations" :style="{'background': compBg}">
              FMR. Focus Recommendations
            </th>

            <th v-if="hasTrueMaxColumns && showVarMax" :style="{'background': compBg}">Max LSI</th>
            <th v-if="hasTrueMaxColumns && showVarMax" class="recommendations" :style="{'background': compBg}">
              Max LSI Recommendation
            </th>
            <th v-if="hasTrueMaxColumns && showVarMax && comparePageRunId" :style="{'background': compBg}">FMR. Max LSI</th>
            <th v-if="hasTrueMaxColumns && showVarMax && comparePageRunId" class="recommendations" :style="{'background': compBg}">
              FMR. Max LSI Recommendation
            </th>

            <th class="action-column">Action</th>
            <th>Current Selection</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, i) in dispLsiVariationData" :key="`${type}-signal-row-${i}`" v-if="row.signal !== 'Word Count' && row.signal !== 'Keyword in Main URL'">
            <td class="signal-td">
              <div class="d-flex align-center gap5">
                <span class="material-icons" v-tooltip.right="{ content: signalExplainData[row.signal], classes: 'custom-tooltip' }">info</span>
                <span>{{ row.signal }}</span>
              </div>
            </td>
            <td v-if="!pageNotBuilt">{{ row.targetVariationCnt }}</td>
            <td v-if="showVarRegular">{{ row.competitorsMin }}</td>
            <td v-if="showVarRegular">{{ row.competitorsMax }}</td>
            <td v-if="showVarRegular && !pageNotBuilt" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ row.comment }}<span :class="getMarkTypeStyle(row.comment, row.isWeakSignal)"></span></td>
            <td v-if="showVarRegular && pageNotBuilt" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ row.comment }}</td>
            <td v-if="!pageNotBuilt && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'targetVariationCnt') }}</td>
            <td v-if="showVarRegular && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'competitorsMin') }}</td>
            <td v-if="showVarRegular && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'competitorsMax') }}</td>
            <td v-if="showVarRegular && !pageNotBuilt && comparePageRunId" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'comment') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareLsiVariationData, row.signal, 'comment'), row.isWeakSignal)"></span></td>
            <td v-if="showVarRegular && pageNotBuilt && comparePageRunId" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'comment') }}</td>
            <td v-if="!pageNotBuilt && showVarAdjData" :style="{'background': compBg}">{{ getCompareData(dispAdjLsiVariationData, row.signal, 'targetVariationCnt') }}</td>
            <td v-if="showVarAdjData" :style="{'background': compBg}">{{ getCompareData(dispAdjLsiVariationData, row.signal, 'competitorsMin') }}</td>
            <td v-if="showVarAdjData" :style="{'background': compBg}">{{ getCompareData(dispAdjLsiVariationData, row.signal, 'competitorsMax') }}</td>
            <td v-if="!pageNotBuilt && showVarAdjData" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispAdjLsiVariationData, row.signal, 'comment') }}<span :class="getMarkTypeStyle(getCompareData(dispAdjLsiVariationData, row.signal, 'comment'), row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && showVarAdjData" :style="{'background': compBg}" :class="{recommendations: hasFocusColumns || hasTrueMaxColumns}">{{ getCompareData(dispAdjLsiVariationData, row.signal, 'comment') }}</td>
            
            <td v-if="hasFocusColumns && showVarFocus" :style="{'background': compBg}">{{ row.featuredCompetitorsMin }}</td>
            <td v-if="hasFocusColumns && showVarFocus" :style="{'background': compBg}">{{ row.featuredCompetitorsMax }}</td>
            <td v-if="!pageNotBuilt && hasFocusColumns && showVarFocus" class='recommendations' :style="{'background': compBg}">{{ row.featuredCompetitorsComment }}<span :class="getMarkTypeStyle(row.featuredCompetitorsComment, row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && hasFocusColumns && showVarFocus" class='recommendations' :style="{'background': compBg}">{{ row.featuredCompetitorsComment }}</td>
            <td v-if="hasFocusColumns && showVarFocus && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'featuredCompetitorsMin') }}</td>
            <td v-if="hasFocusColumns && showVarFocus && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'featuredCompetitorsMax') }}</td>
            <td v-if="!pageNotBuilt && hasFocusColumns && showVarFocus && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'featuredCompetitorsComment') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareLsiVariationData, row.signal, 'featuredCompetitorsComment'), row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && hasFocusColumns && showVarFocus && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'featuredCompetitorsComment') }}</td>
            
            <td v-if="hasTrueMaxColumns && showVarMax" :style="{'background': compBg}">{{ row.variationsTrueMax }}</td>
            <td v-if="!pageNotBuilt && hasTrueMaxColumns && showVarMax" class='recommendations' :style="{'background': compBg}">{{ row.variationsTrueMaxRecommendations }}<span :class="getMarkTypeStyle(row.variationsTrueMaxRecommendations, row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && hasTrueMaxColumns && showVarMax" class='recommendations' :style="{'background': compBg}">{{ row.variationsTrueMaxRecommendations }}</td>
            <td v-if="hasTrueMaxColumns && showVarMax && comparePageRunId" :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'variationsTrueMax') }}</td>
            <td v-if="!pageNotBuilt && hasTrueMaxColumns && showVarMax && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'variationsTrueMaxRecommendations') }}<span :class="getMarkTypeStyle(getCompareData(dispCompareLsiVariationData, row.signal, 'variationsTrueMaxRecommendations'), row.isWeakSignal)"></span></td>
            <td v-if="pageNotBuilt && hasTrueMaxColumns && showVarMax && comparePageRunId" class='recommendations' :style="{'background': compBg}">{{ getCompareData(dispCompareLsiVariationData, row.signal, 'variationsTrueMaxRecommendations') }}</td>
            
            <td>
              <span v-if="isDemo" class="material-icons-outlined grayColor--text" slot="trigger">add_task</span>
              <b-dropdown v-else>
                <!-- <v-btn rounded x-small color="profileAnchorColor"
                  class="button-with-icon text-none whiteColor--text" slot="trigger">
                  <span>Add to to-do list</span>
                  <span class="material-icons">arrow_drop_down</span>
                </v-btn> -->
                <span class="material-icons-outlined" :class="{
                    'profileAnchorColor--text': !row['checklistId'],
                    'green7Color--text': row['checklistId']
                  }" slot="trigger">add_task</span>
                <b-dropdown-item @click="addToChecklist(3, 'regular', row)">Regular</b-dropdown-item>
                <b-dropdown-item :disabled="!hasFeaturedCompetitorsUrl" @click="addToChecklist(3, 'focus', row)">Focus</b-dropdown-item>
                <b-dropdown-item @click="addToChecklist(3, 'max', row)">Max</b-dropdown-item>
              </b-dropdown>
            </td>
            <td :class="{ 'is-warning': row['checklistId'] && !row['isArchived'], 'yellow-bg': row['checklistId'] && !row['isArchived'] }">
              <span v-if="row['checklistId'] && !row['isArchived']">
                {{ getDispTypeForItem(row) | capitalize }}
              </span>
              <span v-else>
                None
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div  class="mt-5" v-show="signalType === 'wordCount' && type == 'Keyword'">
    <div class="word-count-block">
      <div v-if="!pageNotBuilt">
        <div class="box-number">{{ currentWordCount }}</div>
        <div class="mt-2 box-label">Current Word Count</div>
      </div>
      <div v-else class="column box box-item has-text-centered" style="display: flex; align-items: center;">
        {{ targetWordCountComment }}
      </div>

      <div>
        <div class="box-number">{{ competitorWordCountData['competitorsAvg'] }}</div>
        <div class="mt-2 box-label">Target Word Count</div>
        <div class="small-text mt-1">
          <span>
            Competitor range ( {{ competitorWordCountData['competitorsMin'] }} - {{ competitorWordCountData['competitorsMax'] }} )
          </span>
          <span v-if="!competitorWordCountData['competitorsMin']" class="is-italic">
            To see the word count range for your competitors, please re-run this report.
          </span>
        </div>
      </div>
      <div>
        <div class="box-number">{{ featuredCompetitorWordCountData['avgFeaturedCompetitorWordCount'] }}</div>
        <div class="mt-2 box-label">Focus competitor target word count</div>
        <div class="small-text mt-1">
          <span>
            Focus competitor range ( {{ featuredCompetitorWordCountData['minFeaturedCompetitorWordCount'] }} - {{ featuredCompetitorWordCountData['maxFeaturedCompetitorWordCount'] }} )
          </span>
          <span v-if="!competitorWordCountData['competitorsMin']" class="is-italic">
            To see the word count range for your focus competitors, please re-run this report.
          </span>
        </div>
      </div>
    </div>
    <div class="mt-10" style="max-width: 1000px;">
      <div class="over-under-optimized-zone pad15">
        <div class="title-label d-flex">
          <span>Page word count: {{ !pageNotBuilt ? currentWordCount : '' }}</span>
          <span class="flex-grow-1"></span>
          <span class="is-pulled-right toggle-focus-or-normal-chart">All competitors</span>
        </div>
        <word-count-color-chart
          :showWithFeaturedCompetitors="false"
          :pageNotBuilt="pageNotBuilt"
          :currentWordCount="currentWordCount"
          :minWordCount="competitorWordCountData['competitorsMin']"
          :maxWordCount="competitorWordCountData['competitorsMax']"
          :targetWordCount="competitorWordCountData['competitorsAvg']"
          :featuredMinWordCount="featuredCompetitorWordCountData['minFeaturedCompetitorWordCount']"
          :featuredMaxWordCount="featuredCompetitorWordCountData['maxFeaturedCompetitorWordCount']"
          :featuredTargetWordCount="featuredCompetitorWordCountData['avgFeaturedCompetitorWordCount']"
          >
        </word-count-color-chart>
      </div>
      <div class="over-under-optimized-zone pad15">
        <div class="title-label flex">
          <span>Page word count: {{ !pageNotBuilt ? currentWordCount : '' }}</span>
          <span class="flex-grow-1"></span>
          <span class="is-pulled-right toggle-focus-or-normal-chart">Focus competitors only</span>
        </div>
        <word-count-color-chart
          :showWithFeaturedCompetitors="true"
          :pageNotBuilt="pageNotBuilt"
          :currentWordCount="currentWordCount"
          :minWordCount="competitorWordCountData['competitorsMin']"
          :maxWordCount="competitorWordCountData['competitorsMax']"
          :targetWordCount="competitorWordCountData['competitorsAvg']"
          :featuredMinWordCount="featuredCompetitorWordCountData['minFeaturedCompetitorWordCount']"
          :featuredMaxWordCount="featuredCompetitorWordCountData['maxFeaturedCompetitorWordCount']"
          :featuredTargetWordCount="featuredCompetitorWordCountData['avgFeaturedCompetitorWordCount']"
          >
        </word-count-color-chart>
      </div>
    </div>
  </div>


  <div v-if="!isFromAdminPage"> <!-- if this is not from admin history tab -->
    <source-editor
      ref="sourceEditor"
      :signalExplainData="signalExplainData"
      :pageRunId="pageRunId"
      :checklist="seoEditChecklist"
      :pageNotBuilt="pageNotBuilt"
      :keyword="keyword"
      :variations="hasLsaForVariations ? sortedLsaVariations : variations"
      :lsaPhrases="hasLsiSignals ? lsaPhrases : []"
      :signalType="signalType"
      :rerunLink="`/sites/${site.id}/pages/${pageId}/pageRun/${pageRunId}/rerun?withEditedSource=1`"
      @updateSignalType="updateSignalType"
      >
      <!-- lsaPhrases:   change from sortedLsaPhrases to  lsaPhrases -->
    </source-editor>
  </div>
</div>

</template>

<script>

let WEAK_SIGNAL_LABELS = ['HTML Head & Body Tags', 'H5', 'H6', 'Meta Keywords', 'Meta Description', 'Open Graph Title', 'Open Graph Description', 'Twitter Card Title', 'Twitter Card Description', 'Image File Names', 'Figure Caption', 'Source Code URLs', 'Microdata (schema format)', 'RDF (schema format)', 'JSON LD (schema format)']

import _ from 'lodash';
import { mixin } from '@/components/mixin'
import WordCountColorChart from '@/components/PageRun/WordCountColorChart.vue'
import SourceEditor from '@/components/PageRun/SourceEditor.vue'

import  explainData from './ExplainData'
export default {
  mixins: [mixin],
  components: {
    WordCountColorChart,
    SourceEditor,
  },
  props: [
    'isFromAdminPage',
    'pageRunId', 'excelFile', 
    'type', 'recommendation_type', 
    'signals', 'lsiSignals', 
    'adjSignals', 'adjLsiSignals', // this 2 props is available for only when type="Keyword"
    'keyword', 'variations', 'lsaVariations', 'nlpCommons', 'targetURL', 'pageNotBuilt', 'baseURL', 'featuredCompetitors', 'pageId', 'checkLists', 'lsaPhrases'
  ],
  watch: {
    'recommendation_type': {
      handler: function(newVal, oldVal) {
        if (newVal=='adjKeyword') {
          this.showKwAdjData = false
          this.showVarAdjData = false
        }
        this.calculateOrderedSignals()
        this.forceRerender()
      }
    },
    'checkLists': {
      handler: function(newVal, oldVal) {
        this.calculateOrderedSignals()
        if (newVal != oldVal) {
          this.forceRerender()
        }
      }
    },
    'comparePageRunId': {
      handler: function(newVal, oldVal) {
        this.orderedSignalsForComparing()
        if (newVal != oldVal) {
          this.forceRerender()
        }
      }
    }
  },
  data () {
    return {

      componentKey: 0,
      justEnteredToComponent: true,

      signalType: 'keyword',
      message: 'Hide',

      showComparingDropdown: false,
      showKwFocus: false,
      showKwMax: false,
      showKwRegular: true,
      showVarFocus: false,
      showVarMax: false,
      showVarRegular: true,
      variationColsExpanded: false,
      variationData: [],
      lsiVariationData: [],
      signalData: [],
      signalExplainData: {},

      showKwAdjData: false,
      showVarAdjData: false,

      adjVariationData: [],
      adjLsiVariationData: [],
      adjSignalData: [],

      showWeights: false,


      compareVariationData: [],
      compareLsiVariationData: [],
      compareSignalData: [],



      colorPalette: [ '#defaf3', '#fcf1ce', '#e8f5fd', '#fffaf2' ],

      isAvailableChromeExtension: false,
    }
  },
  computed: {
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    compBg() {
      return this.$vuetify.theme.currentTheme['lightYellowColor']
    },
    itemsSelectedText () {
      let rlt = []
      if (this.signalType == 'keyword') {
        if (this.showKwRegular) { rlt.push('All competitors') }
        if (this.showKwFocus) { rlt.push('Focus competitors') }
        if (this.showKwMax) { rlt.push('Max values') }
        if (this.$parent.activeTab == 'keywords' && this.showKwAdjData) { rlt.push('Your word count') }
      } else if (this.signalType == 'variations' || ( this.signalType == 'lsi_terms' && this.hasLsiSignals )) {
        if (this.showVarRegular) { rlt.push('All competitors') }
        if (this.showVarFocus) { rlt.push('Focus competitors') }
        if (this.showVarMax) { rlt.push('Max values') }
        if (this.$parent.activeTab == 'keywords' && this.showVarAdjData) { rlt.push('Your word count') }
      }
      if (rlt.length > 1) {
        return `${ rlt.length } items selected`
      } else if (rlt.length == 1) {
        return rlt[0]
      } else {
        return 'Select filter'
      }
    },
    unarchivedChecklists () {
      return this.checkLists.filter(item => !item.isArchived)
    },
    hasFocusColumns () {
      if (this.html(this.signals) && this.html(this.signals).signalData.featuredCompetitorsComment && this.type !== 'Adjusted Keyword') {
        return true
      }
      return false
    },
    hasTrueMaxColumns () {
      if (this.html(this.signals) && this.html(this.signals).signalData.keywordsTrueMaxRecommendations && this.type !== 'Adjusted Keyword') {
        return true
      }
      return false
    },
    hasKeywordChecklist () {
      if (!this.checkLists) return false
      return this.checkLists.filter(item => item.type == 0).length
    },
    hasVariationChecklist () {
      if (!this.checkLists) return false
      return this.checkLists.filter(item => item.type == 1).length
    },
    hasFeaturedCompetitorsUrl () {
      if (this.featuredCompetitors == null || this.featuredCompetitors.length == 0) return false
      return true
    },
    
    currentWordCount () {
      if (this.type === 'Keyword') {
        const wordCount = this.signals.rows.find(wc => wc.signal === 'Word Count')
        return wordCount.signalData.targetKeywordCnt
      }
      return 0
    },
    competitorWordCountData () {
      if (this.type === 'Keyword') {
        const wordCount = this.signals.rows.find(wc => wc.signal === 'Word Count')
        return {
          competitorsAvg: Math.round(wordCount.signalData.competitorsAvg),
          competitorsMax: wordCount.signalData['keywordsTrueMax'],
          competitorsMin: wordCount.signalData['keywordsTrueMin'],
        }
      }
      return {
        competitorsAvg: 0,
        competitorsMax: 0,
        competitorsMin: 0
      }
    },
    featuredCompetitorWordCountData () {
      if (this.type === 'Keyword') {
        const wordCount = this.signals.rows.find(wc => wc.signal === 'Word Count')
        return {
          avgFeaturedCompetitorWordCount: Math.round(wordCount.signalData.featuredCompetitorsKeywordAvg),
          maxFeaturedCompetitorWordCount: wordCount.signalData['featuredCompetitorsKeywordMax'],
          minFeaturedCompetitorWordCount: wordCount.signalData['featuredCompetitorsKeywordMin']
        }
      }
      return {
        avgFeaturedCompetitorWordCount: 0,
        maxFeaturedCompetitorWordCount: 0,
        minFeaturedCompetitorWordCount: 0
      }
    },
    targetWordCountComment () {
      if (this.type === 'Keyword') {
        const wordCount = this.signals.rows.find(wc => wc.signal === 'Word Count')
        return wordCount.signalData.comment
      }
      return ""
    },

    hasLsiSignals() {
      return this.hasSignals(this.lsiSignals)
    },

    dispSignalData () {
      return this.sortSignalData(this.signalData)      
    },
    dispAdjSignalData () {
      return this.sortSignalData(this.adjSignalData)      
    },
    dispCompareSignalData () {
      return this.sortSignalData(this.compareSignalData)
    },

    dispVariationData () {
      return this.sortVariationData(this.variationData)      
    },
    dispAdjVariationData () {
      return this.sortVariationData(this.adjVariationData)      
    },
    dispCompareVariationData () {
      return this.sortVariationData(this.compareVariationData)      
    },

    dispLsiVariationData () {
      return this.sortLsiVariationData(this.lsiVariationData)      
    },
    dispAdjLsiVariationData () {
      return this.sortLsiVariationData(this.adjLsiVariationData)      
    },
    dispCompareLsiVariationData () {
      return this.sortLsiVariationData(this.compareLsiVariationData)      
    },

    comparingPageRuns () {
      return this.pageRunsWithRecentOrder.filter(p => p.pageNotBuilt == this.pageNotBuilt)
    }
  },
  methods: {
    mainURL (signals) { return signals.rows.find(s => s.signal === 'Keyword in Main URL') },
    metaTitle (signals) { return signals.rows.find(s => s.signal === 'Meta Title') },
    h1 (signals) { return signals.rows.find(s => s.signal === 'H1') },
    h2 (signals) { return signals.rows.find(s => s.signal === 'H2') },
    h3 (signals) { return signals.rows.find(s => s.signal === 'H3') },
    h4 (signals) { return signals.rows.find(s => s.signal === 'H4') },
    paragraphTxt (signals) { return signals.rows.find(s => s.signal === 'Paragraph Text') },
    anchorTxt (signals) { return signals.rows.find(s => s.signal === 'Anchor Text') },
    altImg (signals) { return signals.rows.find(s => s.signal === 'Image alt') },
    bold (signals) { return signals.rows.find(s => s.signal === 'Bold') },
    italic (signals) { return signals.rows.find(s => s.signal === 'Italic') },
    h2ToH3 (signals) { return signals.rows.find(s => s.signal === 'H2 to H3') },
    h2ToH6 (signals) { return signals.rows.find(s => s.signal === 'H2 to H6') },
    h4ToH6 (signals) { return signals.rows.find(s => s.signal === 'H4 to H6') },
    wordCount (signals) { return signals.rows.find(s => s.signal === 'Word Count') }, // for only word count radio
    //  for Weak Signals
    html (signals) { return signals.rows.find(s => s.signal === 'HTML Head & Body Tags') },
    h5 (signals) { return signals.rows.find(s => s.signal === 'H5') },
    h6 (signals) { return signals.rows.find(s => s.signal === 'H6') },
    metaKeywords (signals) { return signals.rows.find(s => s.signal === 'Meta Keywords') },
    metaDescription (signals) { return signals.rows.find(s => s.signal === 'Meta Description')},
    openGraphTitle (signals) { return signals.rows.find(s => s.signal === 'Open Graph Title')},
    openGraphDescription (signals) { return signals.rows.find(s => s.signal === 'Open Graph Description')},
    twitterCardTitle (signals) { return signals.rows.find(s => s.signal === 'Twitter Card Title')},
    twitterCardDescription (signals) { return signals.rows.find(s => s.signal === 'Twitter Card Description')},
    imageFileNames (signals) { return signals.rows.find(s => s.signal === 'Image File Names') },
    figureCaption (signals) { return signals.rows.find(s => s.signal === 'Figure Caption') },
    sourceCodeURLs (signals) { return signals.rows.find(s => s.signal === 'Source Code URLs') },
    microdataSchema (signals) { return signals.rows.find(s => s.signal === 'Microdata (schema format)') },
    rdfSchema (signals) { return signals.rows.find(s => s.signal === 'RDF (schema format)') },
    jsonLDSchema (signals) { return signals.rows.find(s => s.signal === 'JSON LD (schema format)') },
    /** 
     * type: 0: keyword, 1: variation, 2: page structure
     * dispType: regular, focus, max
     * signal : H1, H2 ......
    */
    addToChecklist (type, dispType, row) {
      if (row['checklistId']) {
        if (!window.confirm('Are you sure you want to change your selection?')) { return }
      }
      let data = {
        pageId: this.pageId,
        recommendationType: this.recommendation_type,
        type,
        dispType,
        signal: row.signal
      }
      console.log(data)
      this.$store.commit('showLoading')
      return this.$store.dispatch('addOneToCheckList', data)
      .then(response => {
        if (response.data && response.data.status === 'SUCCESS') {
          console.log('successfully added!')
          if (response.data.page) {
            this.$store.commit('setPage', response.data.page)
          }
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    addAllToChecklist (dispType) {
      let type = 0  // 2 is for page structure
      if (this.signalType == 'keyword') {
        type = 0
      }else if (this.signalType == 'variations') {
        type = 1;
      } else if (this.signalType == 'lsi_terms') {
        type = 3;
      }

      let data = {
        pageId: this.pageId,
        recommendationType: this.recommendation_type,
        type,
        dispType,
      }

      if((data.type == 0 && this.hasKeywordChecklist) || (data.type == 1 && this.hasVariationChecklist)){
        if (!window.confirm('Are you sure you want to change several recommendations?')) {
          return;
        }
      }

      console.log('add all to checklist', data)
      this.$store.commit('showLoading')
      return this.$store.dispatch('addAllToCheckList', data)
      .then(response => {
        if (response.data && response.data.status === 'SUCCESS') {
          console.log('successfully added!')
        }
        return this.$parent.loadSites()
      })
    },
    makeSignals (signalData) {
      let signals = []
      this.mainURL(signalData) && this.targetURL && (this.targetURL !== this.baseURL || !this.baseURL)? signals.push(this.mainURL(signalData)) : null
      this.metaTitle(signalData) ? signals.push(this.metaTitle(signalData)) : null
      this.h1(signalData) ? signals.push(this.h1(signalData)) : null
      this.h2(signalData) ? signals.push(this.h2(signalData)) : null
      this.h3(signalData) ? signals.push(this.h3(signalData)) : null
      this.h4(signalData) ? signals.push(this.h4(signalData)) : null
      this.paragraphTxt(signalData) ? signals.push(this.paragraphTxt(signalData)) : null
      this.anchorTxt(signalData) ? signals.push(this.anchorTxt(signalData)) : null
      this.altImg(signalData) ? signals.push(this.altImg(signalData)) : null
      this.bold(signalData) ? signals.push(this.bold(signalData)) : null
      this.italic(signalData) ? signals.push(this.italic(signalData)) : null
      this.h2ToH3(signalData) ? signals.push(this.h2ToH3(signalData)) : null
      this.h2ToH6(signalData) ? signals.push(this.h2ToH6(signalData)) : null
      this.h4ToH6(signalData) ? signals.push(this.h4ToH6(signalData)) : null
      // For weak signals
      this.html(signalData) ? signals.push(this.html(signalData)) : null
      this.h5(signalData) ? signals.push(this.h5(signalData)) : null
      this.h6(signalData) ? signals.push(this.h6(signalData)) : null
      this.metaKeywords(signalData) ? signals.push(this.metaKeywords(signalData)) : null
      this.metaDescription(signalData) ? signals.push(this.metaDescription(signalData)) : null
      this.openGraphTitle(signalData) ? signals.push(this.openGraphTitle(signalData)) : null
      this.openGraphDescription(signalData) ? signals.push(this.openGraphDescription(signalData)) : null
      this.twitterCardTitle(signalData) ? signals.push(this.twitterCardTitle(signalData)) : null
      this.twitterCardDescription(signalData) ? signals.push(this.twitterCardDescription(signalData)) : null
      this.imageFileNames(signalData) ? signals.push(this.imageFileNames(signalData)) : null
      this.figureCaption(signalData) ? signals.push(this.figureCaption(signalData)) : null
      this.sourceCodeURLs(signalData) ? signals.push(this.sourceCodeURLs(signalData)) : null
      this.microdataSchema(signalData) ? signals.push(this.microdataSchema(signalData)) : null
      this.rdfSchema(signalData) ? signals.push(this.rdfSchema(signalData)) : null
      this.jsonLDSchema(signalData) ? signals.push(this.jsonLDSchema(signalData)) : null
      return signals
    },
    calculateOrderedSignals() {
      let rlt = this.orderedSignals(this.signals, this.lsiSignals)
      this.variationData = rlt[0]
      this.lsiVariationData = rlt[1]
      this.signalData = rlt[2]

      if (this.adjSignals) {
        let rlt = this.orderedSignals(this.adjSignals, this.adjLsiSignals)
        this.adjVariationData = rlt[0]
        this.adjLsiVariationData = rlt[1]
        this.adjSignalData = rlt[2]
      }
    },
    orderedSignals (in_signals, in_lsiSignals) {

      if (!in_signals.rows) {
        return [[], [], []]
      }
      
      const signals = this.makeSignals(in_signals)

      let lsiSignals = []
      if (this.hasSignals(in_lsiSignals)) {
        lsiSignals = this.makeSignals(in_lsiSignals)
      }

      console.log('UNARCHIVED', this.unarchivedChecklists)

      let variationData = signals.map((row, i) => Object.assign(row.variationData, { signal: row.signal, signalTags: row.signalTags })).map(row => {
          if (WEAK_SIGNAL_LABELS.includes(row.signal)) row.isWeakSignal = true; else row.isWeakSignal = false;
          let found = this.checkLists.find(item => {
            if (item.type ==1 && item.signal === row.signal && item.recommendationType === this.recommendation_type) return true; else return false;
          })
          if (found) {
            row.checklistId = found.id
            row.dispType = found.dispType
            row.isCompleted = found.isCompleted
            row.isArchived = found.isArchived
          } else {
            row.checklistId = 0
          }
          return row
        })

      let lsiVariationData = lsiSignals.map((row, i) => Object.assign(row.variationData, { signal: row.signal, signalTags: row.signalTags })).map(row => {
        if (WEAK_SIGNAL_LABELS.includes(row.signal)) row.isWeakSignal = true; else row.isWeakSignal = false;
        let found = this.checkLists.find(item => {
          if (item.type == 3 && item.signal === row.signal && item.recommendationType === this.recommendation_type) return true; else return false;
        })
        if (found) {
          row.checklistId = found.id
          row.dispType = found.dispType
          row.isCompleted = found.isCompleted
          row.isArchived = found.isArchived
        } else {
          row.checklistId = 0
        }
        return row
      })

      let signalData = signals.map((row) => Object.assign(row.signalData, { signal: row.signal, signalTags: row.signalTags })).map(row => {
          if (WEAK_SIGNAL_LABELS.includes(row.signal)) row.isWeakSignal = true; else row.isWeakSignal = false;
          let found = this.checkLists.find(item => {
            if (item.type ==0 && item.signal === row.signal && item.recommendationType === this.recommendation_type) return true; else return false;
          })
          if (found) {
            row.checklistId = found.id
            row.dispType = found.dispType
            row.isCompleted = found.isCompleted
            row.isArchived = found.isArchived
          } else {
            row.checklistId = 0
          }
          return row
        })

      this.justEnteredToComponent = false

      return [ variationData, lsiVariationData, signalData]
    },
    orderedSignalsForComparing () {
      // compareSignalData
      if (!this.comparePageRunId) {
        this.compareSignalData = []
        return
      }

      let compPageRun = this.pageRuns.filter(p => p.id == this.comparePageRunId)[0]
      if (!compPageRun) {
        return
      }

      const signals = this.makeSignals(compPageRun.keywordCounts)

      let lsiSignals = []
      if (this.hasSignals(compPageRun['lsaKeywordCounts'])) {
        lsiSignals = this.makeSignals(compPageRun['lsaKeywordCounts'])
      }

      this.compareVariationData = signals.map((row, i) => Object.assign(row.variationData, { signal: row.signal, signalTags: row.signalTags })).map(row => {
        if (WEAK_SIGNAL_LABELS.includes(row.signal)) row.isWeakSignal = true; else row.isWeakSignal = false;
        let found = this.unarchivedChecklists.find(item => {
          if (item.type ==1 && item.signal === row.signal) return true; else return false;
        })
        if (found) {
          row.checklistId = found.id
          row.dispType = found.dispType
          row.isCompleted = found.isCompleted
        } else {
          row.checklistId = 0
        }
        return row
      })

      this.compareLsiVariationData = lsiSignals.map((row, i) => Object.assign(row.variationData, { signal: row.signal, signalTags: row.signalTags })).map(row => {
        if (WEAK_SIGNAL_LABELS.includes(row.signal)) row.isWeakSignal = true; else row.isWeakSignal = false;
        let found = this.unarchivedChecklists.find(item => {
          if (item.type == 3 && item.signal === row.signal) return true; else return false;
        })
        if (found) {
          row.checklistId = found.id
          row.dispType = found.dispType
          row.isCompleted = found.isCompleted
        } else {
          row.checklistId = 0
        }
        return row
      })

      this.compareSignalData = signals.map((row) => Object.assign(row.signalData, { signal: row.signal, signalTags: row.signalTags })).map(row => {
        if (WEAK_SIGNAL_LABELS.includes(row.signal)) row.isWeakSignal = true; else row.isWeakSignal = false;
        let found = this.unarchivedChecklists.find(item => {
          if (item.type ==0 && item.signal === row.signal) return true; else return false;
        })
        if (found) {
          row.checklistId = found.id
          row.dispType = found.dispType
          row.isCompleted = found.isCompleted
        } else {
          row.checklistId = 0
        }
        return row
      })
    },

    sortSignalData (signalDataParam) {
      let signalData = signalDataParam.filter(item => {
        // if (!item.comment) return true
        // return item.comment.indexOf('Leave As Is') == -1
        return true;
      })
      return signalData
    },
    sortVariationData (variationDataParam) {
      let variationData = variationDataParam.filter(item => {
        // if (!item.comment) return true
        // return item.comment.indexOf('Leave As Is') == -1
        return true;
      })
      return variationData
    },
    sortLsiVariationData (lsiVariationDataParam) {
      let variationData = lsiVariationDataParam.filter(item => {
        // if (!item.comment) return true
        // return item.comment.indexOf('Leave As Is') == -1
        return true;
      })
      return variationData      
    },
    getCompareData (signalData, signalName, fieldName) {
      let find = signalData.filter(s => s.signal == signalName)
      if (find.length > 0) {
        return find[0][`${fieldName}`]
      }
      return ''
    },
    checkColor (comment) {
      if (this.pageNotBuilt) {
        return 'white'
      }
      let color = 'black'
      if (comment.indexOf('Leave As Is') > -1) {
        color = '#b8e1cc'
      } else if (comment.indexOf('Reduce') > -1 || comment.indexOf('reducing') > -1) {
        color = '#f0c291'
      } else if (comment.indexOf('Increase') > -1 || comment.indexOf('Add 1') > -1){
        color = '#cf8c8e'
      }
      return color;
    },
    getMarkTypeStyle (comment, isWeakSignal) {
      if (this.pageNotBuilt || !comment) {
        return {}
      }
      if (comment.indexOf('Leave As Is') > -1) {
        return { 'signal-mark': true, 'good-marktype': true }
      } else if (comment.indexOf('Reduce') > -1 || comment.indexOf('reducing') > -1) {
        if (isWeakSignal) {
          return { 'signal-mark': true, 'low-marktype': true }
        }
        return { 'signal-mark': true, 'mid-marktype': true }
      } else if (comment.indexOf('Increase') > -1 || comment.indexOf('Add 1') > -1){
        if (isWeakSignal) {
          return { 'signal-mark': true, 'mid-marktype': true }
        }
        return { 'signal-mark': true, 'high-marktype': true }
      }
      return {}
    },
    messageForVariationAndLSICopy (type) {
      if (type=='variations') {
        if (this.hasLsaForVariations) {
          return this.sortedLsaVariations.map(item => item.phrase).join(',')
        }
        return this.variations.join(',')
      } else if (type=='lsi_terms') {
        if (this.hasLsaForVariations) {
          return this.sortedLsaPhrases.map(item => item.phrase).join(',')
        }
      } else if (type=='entities') {
        return this.sortedGoogleNlpCommonEntities.map(item => item[0]).join(',')
      }
      return ''
    },
    forceRerender () {
      console.log('FORCE RERENDERING SIGNALS TABLES - - - - -')
      this.componentKey += 1;

      // require('./table-resizable.js')
    },
    isEmptyHtml(htmlStr) {
      let hStr = htmlStr.replace(/\s/g, '').toLowerCase() // remove all spaces
      // hStr = hStr.replace(/\r?\n|\r/g, '') // remove all enters
      let emptyHTMLs = [
        '<!doctypehtml><html><head></head><body></body></html>',
        '<html><body></body></html>'
      ]
      if ( emptyHTMLs.includes(hStr) ) {
        return true
      }
      return false
    },
    showEditSourceModal (isExpressEditor=false) {
      this.$store.commit('clearEditorArchivedList')
      this.$store.commit('setEditorType', isExpressEditor)
      if ((this.$store.state.seoEditHtmlSource === '' || this.isEmptyHtml(this.$store.state.seoEditHtmlSource))) {
        if (this.pageRun.editedSource && !this.isEmptyHtml(this.pageRun.editedSource)) {
          this.$refs.sourceEditor.show(this.pageRun.editedSource, isExpressEditor)
          return
        } else if (this.pageRun.targetUrlSourceCode && !this.isEmptyHtml(this.pageRun.targetUrlSourceCode)) {
          this.$refs.sourceEditor.show(this.pageRun.targetUrlSourceCode, isExpressEditor)
          return
        } else if (this.pageNotBuilt) {
          this.$refs.sourceEditor.show('', isExpressEditor)
        } else if (this.isAvailableChromeExtension) {
          this.$store.commit('showLoading')
          this.dispatch_custom_event( "validate_urls", {
            url_arr: [ this.targetURL ]
          })
          return
        }
      }
      this.$refs.sourceEditor.show(this.$store.state.seoEditHtmlSource, isExpressEditor)
    },
    showExpressEditorModal () {
      this.showEditSourceModal(true)
    },
    dispatch_custom_event: function ( name, data ) {

      if (!this.isAvailableChromeExtension) {
        console.log("chrome extension is not installed!")
        return window.alert("Please check you installed google extension!")
      }

      // the web application should fire this event to trigger the extension
      // the extension will listen for this event

      var event = new CustomEvent(

        "web_app_event", 
        {
          detail: {
            name: name,
            data: data,
          },
          bubbles: true,
          cancelable: true
        }
      );
      console.log('Dispatching event to extension', name)
      document.dispatchEvent( event );
    },
    checkExtensionIsInstalled () {
      if ( document.readyState === "interactive" || document.readyState === "complete" ) {
  
        if ( document.documentElement.dataset.page_optimizer_browser_extension_version ) {
          this.isAvailableChromeExtension = true
          console.log('Adding event listener for POP extension!!')
          document.addEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
        } else {
          this.isAvailableChromeExtension = false
          console.log('can not find extension in this browser')
        };
  
      };
    },
    extensionEventListener (event) {
      var name = event.detail.name;
      var data = event.detail.data;
      if ( name === "validate_urls_result" ) {
        var resData = data.validate_urls_result
        console.log('extension callback [validate urls]', resData)
        let { status, total_urls, processed } = resData

        if (status=='PENDING' || status=='PROGRESS') {
          // let progress = Math.round(processed * 100 / total_urls)
          // if (progress < 1) progress = 1
        } else if (status=='FAILURE') {
          this.$store.commit('hideLoading')
        } else if (status=='SUCCESS') {
          this.$store.commit('hideLoading')
          console.log('SUCCESS', resData)
          let data = resData.results[0]
          let isExpressEditor = this.$store.state.isExpressEditor
          if (data && data.validated) {
            this.$refs.sourceEditor.show(data.source, isExpressEditor)
          } else {
            console.log('Can not fetch source code')
            this.$refs.sourceEditor.show('', isExpressEditor)
          }
        }
      };
    },
    updateSignalType (type) {
      this.signalType = type
    },
    loadSites () {
      return this.$parent.loadSites()
    }
  },
  beforeMount() {
    this.checkExtensionIsInstalled()
    // setSeoSourceCode inside route '/sites/:siteId/pages/:pageId/pageruns/:pageRunId'
    this.comparePageRunId = ''
    this.justEnteredToComponent = true
    
    
    this.signalExplainData = explainData;
    this.calculateOrderedSignals()
  },
  beforeDestroy () {
    document.removeEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
  }
}
</script>

<style lang="scss" scoped>

.tab-line {
  display: flex;
  border-bottom: 1px solid var(--v-grayColor-base);
  & > div {
    padding: 10px 15px;
  }
  .tab-item {
    font-weight: 700;
    color: var(--v-mainColor-base);
    cursor: pointer;
    &.active {
      color: var(--v-profileAnchorColor-base);
      border-bottom: 2px solid var(--v-profileAnchorColor-base);
    }
  }
}

.type-selection {
  display: flex;
  align-items: center;
  gap: 10px;
  .type-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    gap: 5px;
    cursor: pointer;
    color: var(--v-mainColor-base);
    font-weight: 700;
    
    .material-icons-outlined {
      font-size: 1.05rem;
    }
    &.active {
      border: 1px solid var(--v-profileAnchorColor-base);
      background: var(--v-lightYellowColor-base);
      border-radius: 5px;
      color: var(--v-profileAnchorColor-base);
    }
  }
  .selected-one {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--v-mainColor-base);
    .material-icons-outlined {
      font-size: 1.05rem;
    }
  }

  .compariing-section {
    border: 1px solid var(--v-mainColor-base);
    border-radius: 10px;
    display: flex;
    & > span {
      padding: 8px 10px;
      color: var(--v-mainColor-base);
      &:first-child {
        border-right: 1px solid var(--v-mainColor-base);
      }
      &:nth-child(2) {
        color: var(--v-link15Color-base);
      }
    }
  }
  
}
.variations-disp-control-box {
  display: flex;
  align-items: center;
  gap: 5px;

  & > span {
    border: 1px solid var(--v-mainColor-base);
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: var(--v-mainColor-base);
  }
}

table {
  th, td {
    padding: 15px 0px 15px 10px;
  }
  tr:nth-child(odd) td {
    background: var(--v-pink1Color-base);
  }
}

.box-item {
  margin: 20px;
}
.recommendations {
  max-width: 400px;
  border-right: 1px solid var(--v-profileAnchorColor-base) !important;
  /* border-left: 1px solid #ffe570 !important; */
}

.compare-field {
  background: rgb(255, 255, 227);
}

.vc-compact {
  /* background: none; */
  box-shadow: none;
  display: inline-block;
  margin: auto;
  width: auto;
}

.word-count-block {
  display: inline-flex;
  /* display: flex; */
  background: var(--v-whiteColor-base);
  border: 1px solid var(--v-grayColor-base);
  border-radius: 10px;
  color: var(--v-mainColor-base);
  & > div {
    padding: 20px;
  }
  & > div:not(:last-child) {
    border-right: 1px solid var(--v-grayColor-base);
  }
  .box-number {
    font-size: 1.1rem;
    font-weight: 700;
  }
  .box-label {
    font-size: 1rem;
    font-weight: 700;
  }
  .small-text {
    * {
      font-size: 0.7rem;
    }
  }
}

</style>
