<template>
<div>
  <div class="explain-card limited-width-box">
    <div>
      <div>
        <i class="material-icons">info</i>
      </div>
      <div class="flex-grow-1" style="padding-top: 3px;">
        <div class="explain-title">API access $50 (one time fee)</div>
        <div class="explain-text mt-3">
          To access POP API packages you will need to pay a one-time fee of $50. You will get 100 API calls. After the initial one-time fee you can buy any of the POP API packages below. <br />
          Access API documentation
          <a href="https://api-docs.pageoptimizer.pro/" target="_blank"
            class="profileAnchorColor--text text-decoration-underline">here</a>.
        </div>
      </div>
      <div class="d-flex align-center">
        <v-btn rounded outlined
          v-if="!user.isDevApiKeyAllowed"
          color="mainColor"
          class="text-none"
          @click="() => {
            showConfirmModalAction('API_ACCESS_ONE_TIME')
          }">
          <span>Request access</span>
        </v-btn>
        <v-btn rounded
          v-else
          color="greenColor"
          class="button-with-icon text-none whiteColor--text"
          @click="() => {
            $notify({ group: 'info', type: 'success', text: 'You already granted this access.' })
          }">
          <span class="material-icons whiteColor--text">check</span>
          <span>Access granted</span>
        </v-btn>
      </div>
    </div>
  </div>
  <div class="mt-10">
    <div class="explain-title">API calls for developers</div> 
    <div class="auto-top-up-part" v-if="user.isDevApiKeyAllowed">
      <vue-toggles
        :value="user.devApiCallsAutoToggle == 1"
        width="40" height="20"
        :checkedBg="$vuetify.theme.currentTheme['green7Color']"
        :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
        @click="saveAutoTopUpSetting"
      ></vue-toggles>
      <span>Auto top-up toggle</span>
      <v-tooltip top color="transparent">
        <template v-slot:activator="{ on, attrs }">
          <i class="material-icons info-icon-size ml-1 mainColor--text" v-bind="attrs" v-on="on">info</i>
        </template>
        <div class="white-bg-tooltip small-tooltip">
          If you enable this toggle, we will automatically charge you the same amount as your last API package once your API calls fall below 50. An invoice and confirmation will be sent to your email.
        </div>
      </v-tooltip>
    </div>
    
    <div class="price-packages mt-10">
      <div :class="{'not-popular': !pkg.isPopular, 'popular': pkg.isPopular}" class="pa-5"
        v-for="(pkg, idx) in pricePackages" :key="idx">

        <div v-if="pkg.isPopular" class="popular-label">Most Popular</div>
        <img v-if="pkg.isPartnerProgram" class="partner-program-img" src="/static/partner-program.svg" />

        <div class="price-label mt-5">
          <template v-if="pkg.isPartnerProgram">
            Partner<br />program
          </template>
          <template v-else>
            {{ pkg.calls }} API calls
          </template>
        </div>
        <div class="flex-grow-1">
          <div class="mt-7 buy-this-package" v-if="pkg.isPartnerProgram">
            Interested to partner up with POP? PIease contact us for a custom plan quote.
          </div>
          <div v-else class="mt-3">
            with <span class="font-weight-bold profileAnchorColor--text">{{ pkg.pricePerCall.toFixed(2) }} cents</span> per API call
          </div>
          <template v-if="!pkg.isPartnerProgram">
            <div class="mt-10 buy-this-package font-weight-bold">
              Plan features
            </div>

            <ul class="mt-3">
              <li v-for="(item, idx1) in pkg.featuresList" :key="`${idx}_feature_${idx1}`" class="mt-1">
                <span>{{ item.text }}</span>
              </li>
            </ul>
          </template>
        </div>

        
        <div class="mt-7">
          <v-btn block rounded outlined
            v-if="pkg.isPartnerProgram"
            :disabled="user.isPartnershipApiKeyAllowed"
            @click="showContactUsModal = true;"
            color="mainColor"
            class="text-none mt-7">
            <span>Contact us</span>
          </v-btn>
          <v-btn block rounded
            v-else
            @click="() => {
              if(!user.isDevApiKeyAllowed) {
                showPurchaseAccessPackageFirstModal = true;
                return;
              }
              showConfirmModalAction(pkg.packageName)
            }"
            :color="user.isDevApiKeyAllowed ? 'profileAnchorColor' : 'grayColor'"
            class="text-none whiteColor--text">
            <span>Buy for ${{ pkg.price }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-10">
    <div class="explain-title">
      API calls exchange rates
    </div>
    <table class="api-exchange-table mt-5">
      <tr>
        <td>POP report run</td>
        <td>1 API call</td>
      </tr>
      <tr>
        <td>Watchdog report run</td>
        <td>1 API call</td>
      </tr>
      <tr>
        <td>AI Schema run</td>
        <td>4 API calls</td>
      </tr>
      <tr>
        <td>Basic EEAT run</td>
        <td>4 API calls</td>
      </tr>
      <tr>
        <td>Google NLP run</td>
        <td>6 API calls</td>
      </tr>
    </table>
  </div>


  <div class="modal" :class="{ 'is-active': showConfirmModal }">
    <div class="modal-background" @click.stop="showConfirmModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="subtitle-font mainColor--text">
          You're about to purchase "{{ packageLabels[selectedPackage] }}". <br />
          Do you want to proceed?
        </div>
        <div class="text-right">
          <v-btn rounded outlined color="mainColor" class="button-with-sicon mainColor--text text-none"
            @click.stop="showConfirmModal = false;">
            Cancel
          </v-btn>
          <v-btn rounded color="profileAnchorColor" class="button-with-sicon whiteColor--text text-none ml-2"
            @click.stop="() => confirmedGoProcess()">
            Yes
          </v-btn>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="() => (showConfirmModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal" :class="{ 'is-active': showPurchaseAccessPackageFirstModal }">
    <div class="modal-background" @click.stop="showPurchaseAccessPackageFirstModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="subtitle-font mainColor--text">
            Please purchase your access to API packages first.
          </div>
          <div class="text-right">
            <v-btn rounded color="profileAnchorColor" class="button-with-sicon whiteColor--text text-none ml-2"
              @click.stop="() => showPurchaseAccessPackageFirstModal = false">
              Ok
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="() => (showPurchaseAccessPackageFirstModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal" :class="{ 'is-active': showContactUsModal }">
    <div class="modal-background" @click.stop="showContactUsModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="subtitle-font mainColor--text mb-1">
            Need Custom POP API Solutions?
          </div>
          <div class="mainColor--text mb-3">Contact our sales team! Tell us how you want to use POP API and about your current user base.</div>
          <v-textarea outlined
            v-model="contactMsg"
            color="greenColor"
            rows="7"
            label="Please write the content here.">
          </v-textarea>
          <div class="text-right">
            <v-btn rounded color="profileAnchorColor" class="button-with-sicon whiteColor--text text-none ml-2"
              :disabled="!contactMsg"
              @click.stop="() => sendContactUsMsg()">
              Send
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="() => (showContactUsModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal" :class="{ 'is-active': showContactUsEmailSentModal }">
    <div class="modal-background" @click.stop="showContactUsEmailSentModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="subtitle-font mainColor--text text-center mb-1">
            Thanks for reaching out! We've got your message and will reply to your POP email soon.
          </div>
          <div class="text-right">
            <v-btn rounded color="profileAnchorColor" class="button-with-sicon whiteColor--text text-none ml-2"
              @click.stop="() => {
                showContactUsEmailSentModal = false;
              }">
              Ok
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="() => (showContactUsEmailSentModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  

</div>
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.state.user;
    },
    price () {
      return this.prices[this.selectedPackage] || 0
    }
  },
  data () {
    return {
      pricePackages: [
        {
          packageName: '2100_CALLS',
          calls: 2100,
          price: 250,
          pricePerCall: 0.12,
          isPopular: false,
          isPartnerProgram: false,
          featuresList: [
            {
              text: '1 API call equals 1 POP run',
            },
            {
              text: 'Access to POP API for $250',
            },
            {
              text: 'Free support via email',
            }
          ]
        },
        {
          packageName: '4550_CALLS',
          calls: 4550,
          price: 500,
          pricePerCall: 0.11,
          isPopular: true,
          isPartnerProgram: false,
          featuresList: [
            {
              text: '1 API call equals 1 POP run',
            },
            {
              text: 'Access to POP API for $500',
            },
            {
              text: 'Free support via email',
            }
          ]
        },
        {
          packageName: '10000_CALLS',
          calls: 10000,
          price: 1000,
          pricePerCall: 0.10,
          isPopular: false,
          isPartnerProgram: false,
          featuresList: [
            {
              text: '1 API call equals 1 POP run',
            },
            {
              text: 'Access to POP API for $1000',
            },
            {
              text: 'Free support via email',
            }
          ]
        },
        {
          packageName: '22225_CALLS',
          calls: 22225,
          price: 2000,
          pricePerCall: 0.09,
          isPopular: false,
          isPartnerProgram: false,
          featuresList: [
            {
              text: '1 API call equals 1 POP run',
            },
            {
              text: 'Access to POP API for $2000',
            },
            {
              text: 'Free support via email',
            }
          ]
        },
        {
          calls: 0,
          price: 0,
          isPopular: false,
          isPartnerProgram: true,
        },
      ],

      paymentMethod: '', // stripe / paypal
      showConfirmModal: false,
      isConfirmedByUser: false,

      showPurchaseAccessPackageFirstModal: false,

      packageLabels: {
        'API_ACCESS_ONE_TIME': 'API package access (one time payment)',
        '2100_CALLS': '2100 API calls',
        '4550_CALLS': '4550 API calls',
        '10000_CALLS': '10000 API calls',
        '22225_CALLS': '22225 API calls',
      },
      selectedPackage: '',
      prices: {},

      promoCode: '',

      showContactUsModal: false,
      contactMsg: '',

      showContactUsEmailSentModal: false,
    }
  },
  methods: {
    showConfirmModalAction (packageName) {
      if (!packageName) return;
      this.selectedPackage = packageName;
      this.paymentMethod = 'stripe';
      this.showConfirmModal = true;
    },
    confirmedGoProcess() {
      this.showConfirmModal = false;
      this.isConfirmedByUser = true;
      if (this.paymentMethod == 'stripe') {
        this.submit();
        return;
      }
      // now modal is only for stripe, so following is not needed.
      // if (this.payment == 'paypal') {
      //   paypal.Button.click('#credit-purchase-paypal-button');
      //   return;
      // }
    },
    saveAutoTopUpSetting () {
      // <vue-toggles
      let data = {
        devCallsToggledOn: this.user.devApiCallsAutoToggle == 1 ? 0 : 1,
      }
      this.$store.commit('showLoading');
      this.$store.dispatch('updateDevCallsTopupSetting', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$store.commit('setUser', response.data.user);
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    submit() {

      if (!this.isConfirmedByUser) { // to ask user once more about confirm purchase.
        this.showConfirmModal = true;
        return;
      }

      this.$store.commit('showLoading');
      this.$store.dispatch('createStripePayment', {
        packageType: 'API_PACKAGE_PURCHASE',
        selectedPackage: this.selectedPackage,
        amount: this.price,
        userID: this.user.id,
        promoCode: this.promoCode.trim(),
      })
      .then(function (response) {
        if (response.data && response.data.url) {
          window.open(response.data.url, '_self')
        }
      })
      .finally(() => {
        this.$store.commit('showLoading');
      })
    },
    sendContactUsMsg () {
      this.$store.commit('showLoading')
      this.$store.dispatch('sendApiPartnershipContactEmail', {
        contactMsg: this.contactMsg
      }).then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.showContactUsModal = false;
          this.showContactUsEmailSentModal = true;
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
  },
  mounted () {
    this.$store.commit('showLoading')
    this.$store.dispatch('getAvailableAPICreditPackages')
    .then(response => {
      if (response.data && response.data.status == 'SUCCESS') {
        // this.packages = response.data.packages;
        this.prices = response.data.prices;
      }
    })
    .finally(() => {
      this.$store.commit('hideLoading')
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/ui-for-api-style.scss";
</style>