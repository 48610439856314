<template>
<div class="container">
  <div class="site-name">{{ site.name }}</div>
  <div class="title-label">Report history</div>
  
  <div class="white-common-box pa-3 mb-3" v-if="selectedPageRunIds.length > 0">
    <div class="d-flex align-center">
      <div class="gray2Color--text">{{ selectedPageRunIds.length }} Pagerun report{{ selectedPageRunIds.length>1 ? 's' : '' }} selected</div>
      <v-btn outlined small
        color="gray15Color"
        class="button-with-icon text-none ml-7"
        @click.stop="cancelAllSelection">
        <i class="material-icons mainColor--text">check_box_outline_blank</i>
        <span class="mainColor--text">Unselect all</span>
      </v-btn>
      <v-btn outlined small
        color="gray15Color"
        class="ml-2 button-with-icon text-none" @click.stop="downloadExcelFiles">
        <i class="material-icons mainColor--text">download</i>
        <span class="mainColor--text">Download pagerun reports</span>
      </v-btn>
    </div>
  </div>
  <v-card class="is-fullheight flex flex-column white-common-box">
    <div class="pa-5">
      <table>
        <thead>
          <tr>
            <td></td>
            <td>Page name</td>
            <td>Last run</td>
            <td>Report ID</td>
            <td>Optimization score</td>
            <td>Adj. score</td>
            <td>Competitors</td>
            <td>Download checklist</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pageRun in pageRunsWithRecentOrder" :key="pageRun.id">
            <td>
              <i v-if="isSelectedPageRun(pageRun.id)" class="material-icons checked-box" @click="unSelectPageRun(pageRun.id)">check_box</i>
              <i v-else class="material-icons checked-box" @click="selectPageRun(pageRun.id)">check_box_outline_blank</i>
            </td>
            <td>
              <div class="title-font">{{ pageRun.keyword !=null ? pageRun.keyword : page.keyword }}</div>
              <div class="slug">{{ getSubFolderPartFromUrl(pageRun.url ? pageRun.url : page.url, pageRun.pageNotBuilt) }}</div>
            </td>
            <td>
              {{ getDateAsLocalString(pageRun.createdAt) }}
            </td>
            <td>{{ pageRun.id }}</td>
            <td>
              <div class="d-flex align-center">
                <radial-progress-bar 
                  :diameter="40"
                  :completed-steps="pageRun.keywordCounts?.pageScore?.pageScore || 0"
                  :total-steps="100"
                  :strokeWidth="4"
                  :startColor="pageScoreTintColor(pageRun.keywordCounts?.pageScore?.pageScore || 0)"
                  :stopColor="pageScoreTintColor(pageRun.keywordCounts?.pageScore?.pageScore || 0)"
                  :innerStrokeColor="'#eeeeee'"
                  strokeLinecap="square"
                  >
                </radial-progress-bar>
                <div class="has-text-weight-bold"
                  :style="{ 'color': pageScoreTintColor(pageRun.keywordCounts?.pageScore?.pageScore || 0) }">
                  {{ pageRun.keywordCounts?.pageScore?.pageScore | round(1) }}
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-center">
                <radial-progress-bar 
                  :diameter="40"
                  :completed-steps="pageRun.adjKeywordCounts?.pageScore?.pageScore"
                  :total-steps="100"
                  :strokeWidth="5"
                  :startColor="pageScoreTintColor(pageRun.adjKeywordCounts?.pageScore?.pageScore)"
                  :stopColor="pageScoreTintColor(pageRun.adjKeywordCounts?.pageScore?.pageScore)"
                  :innerStrokeColor="'#eeeeee'"
                  >
                </radial-progress-bar>
                <div class="has-text-weight-bold" :style="{ 'color': pageScoreTintColor(pageRun.adjKeywordCounts?.pageScore?.pageScore) }">
                  {{ pageRun.adjKeywordCounts?.pageScore?.pageScore | round(1) }}
                </div>
              </div>
            </td>
            <td>
              <a class="a-link" @click="showCompetitors(pageRun)">Show</a>
            </td>
            <td>
              <b-dropdown aria-role="list" class="is-right">
                <a class="a-link tooltip is-tooltip-top" data-tooltip="Download Checklist Options" slot="trigger">
                  Select options
                </a>
                <b-dropdown-item @click="downloadExcelForChecklist(0, pageRun.pageId, pageRun.id)">Recommendations only</b-dropdown-item>
                <b-dropdown-item @click="downloadExcelForChecklist(1, pageRun.pageId, pageRun.id)">Recommendations and current status</b-dropdown-item>
                <b-dropdown-item @click="downloadExcelForChecklist(2, pageRun.pageId, pageRun.id)">Export all (with notes)</b-dropdown-item>
              </b-dropdown>
            </td>
            <td>
              <v-btn icon :id="`dot-menu-for-${pageRun.id}`">
                <img src="/static/dot-3.png"
                  width="25px"
                  class="guard-card-dot" />
              </v-btn>
              <v-menu 
                :activator="`#dot-menu-for-${pageRun.id}`"
                bottom offset-y>
                <v-list>
                  <v-list-item link>
                    <v-list-item-title
                      class="mainColor--text"
                      tag="a"
                      :to="pageRun.status === 'SUCCESS' ? `/sites/${site.id}/pages/${pageRun.pageId}/pageruns/${pageRun.id}` : `/sites/${site.id}/pages/${pageRun.pageId}/pageRun/${pageRun.id}/rerun`">
                      {{ pageRun.status === 'SUCCESS' ? 'View' : 'Check Errors' }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title
                      class="mainColor--text"
                      tag="a"
                      :to="`/sites/${site.id}/pages/${pageRun.pageId}/pageRun/${pageRun.id}/rerun`">
                      Re-run
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link v-if="pageRun.status === 'SUCCESS'">
                    <v-list-item-title
                      class="mainColor--text tooltip is-tooltip-top"
                      data-tooltip="Download Report"
                      @click="window.open(downloadUrl(pageRun), '_blank')">
                      Download report
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>



  <div class="modal" :class="{'is-active': showCompetitorsPopup}">
    <div class="modal-background" @click="showCompetitorsPopup = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div v-if="currentPageRun">
          <div class="is-size-4 title-font">{{ currentPageRun.competitors.length }} Competitors Selected</div>
          <div class="d-flex">
            <div class="has-text-grey is-size-7">{{ getDateAsLocalString(pageRun.createdAt) }} - {{ getSubFolderPartFromUrl(pageRun.url ? pageRun.url : page.url, pageRun.pageNotBuilt) }}</div>
            <div class="flex-grow-1 has-text-grey has-text-weight-bold is-size-7 has-text-right">
              REPORT ID: {{ currentPageRun.id }}
            </div>
          </div>
          <div class="mt20">
            <div class="tabs keyword-variations-tabs">
              <ul>
                <li :class="{'is-active': showCompetitorsOrFocus}">
                  <a class="" @click="showCompetitorsOrFocus=true">ALL COMPETITORS ( {{ currentPageRun.competitors.length }} )</a>
                </li>
                <li :class="{'is-active': !showCompetitorsOrFocus}">
                  <a class="" @click="showCompetitorsOrFocus=false">FOCUS COMPETITORS ( {{ currentPageRun.featuredCompetitors.length }} )</a>
                </li>
                <li class="flex-grow-1"></li>
                <li>
                  <div class="competitor-item is-focus-competitor is-size-7" style="color: #54b3f1;">FOCUS</div>
                </li>
              </ul>
            </div>
            <div v-if="showCompetitorsOrFocus">
              <div v-for="c in currentPageRun.competitors" :key="c.id" class="competitor-item" :class="{'is-focus-competitor': currentPageRun.featuredCompetitors.includes(c.url)}">
                <div v-if="c.name">{{ c.name }}</div>
                <a :href="c.url" target="_blank">{{ c.url }}</a>
              </div>
            </div>
            <div v-else>
              <div v-for="(c, idx) in currentPageRun.featuredCompetitors" :key="idx" class="competitor-item is-focus-competitor">
                <a :href="c" target="_blank">{{ c }}</a>
              </div>          
            </div>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showCompetitorsPopup = false"><i class="material-icons">close</i></button>
    </div>
  </div>
</div>
</template>

<script>

import RadialProgressBar from 'vue-radial-progress'
import { mixin } from '@/components/mixin'

export default {
  mixins: [mixin],
  components:  {
    RadialProgressBar
  },
  data () {
    return {
      showCompetitorsPopup: false,
      currentPageRun: null,
      showCompetitorsOrFocus: true, // true will show all


      selectedPageRunIds: [],

    }
  },
  computed: {
    selectedPageRuns () {
      return this.selectedPageRunIds.map(pageRunId => {
        let found = this.pageRuns.find(ele => {
          return ele.id==pageRunId
        })
        return found
      })
    }
  },
  methods: {
    showCompetitors (pageRun) {
      this.currentPageRun = pageRun
      this.showCompetitorsPopup = true
    },
    isSelectedPageRun(pageRunId) {
      return this.selectedPageRunIds.includes(pageRunId)
    },
    selectPageRun (pageRunId) {
      if (this.selectedPageRunIds.indexOf(pageRunId)===-1) {
        this.selectedPageRunIds.push(pageRunId)
      }
    },
    unSelectPageRun (pageRunId) {
      let idx = this.selectedPageRunIds.indexOf(pageRunId)
      if (idx>=0) {
        this.selectedPageRunIds.splice(idx, 1)
      }
    },
    cancelAllSelection () {
      this.selectedPageRunIds = []
    },

    downloadExcelFiles () {
      let urls = this.selectedPageRuns.map(pageRun => {
        return pageRun.reportResourceUrl ? pageRun.reportResourceUrl : `${API_URL}/download/?file=${pageRun.excelFile}`
      })

      if (urls.length == undefined || urls.length==0) {
        return
      }
      console.log('download urls', urls)
      let interval = setInterval(download, 300, urls);
      let self = this
      function download(urls) {
        let url = urls.pop();
        if (!url) { return }
        let a = document.createElement("a");
        a.download = url.split('/').pop();
        a.target = '_blank';
        a.href = url
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a)
        if (urls.length == 0) {
          clearInterval(interval);
          self.cancelAllSelection()
        }
      }
    },
  }
}
</script>


<style lang="scss" scoped>

.site-name {
  color: var(--v-gray2Color-base);
  font-size: 18px;
}
.title-label {
  font-size: 21px;
  font-weight: 700;
  color: var(--v-mainColor-base);
  margin-top: 5px;
  margin-bottom: 20px;
}

.slug {
  color: var(--v-gray2Color-base);
}

table {
  width: 100%;
  tbody tr {
    border-top: 1px solid var(--v-gray15Color-base);
  }
  td {
    color: var(--v-mainColor-base);
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
  }
}

.checked-box {
  color: var(--v-gray15Color-base);
  cursor: pointer;
}

.note-and-chart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .note {
    color: #209cee;
  }
}

.pagerun-card-button {
  background: #fffaf2;
  border: 1px solid #ffebcc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  span {
    margin-left: 5px;
    color: #62605d;
    font-weight: bold;
    font-size: 0.75rem;
  }
  i.material-icons {
    font-size: 0.8rem;
    color: #ff9b00;
  }
}

.a-link {
  color: var(--v-link1Color-base);
  font-weight: bold;
  cursor: pointer;
}



</style>