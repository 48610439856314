<template>
<div class="tutorial-video" v-if="currentVideoTutorial">
  <div class="close-icon" @click="closeAction">
    <span class="material-icons">close</span>
  </div>
  <div class="video">
    <iframe
      :src="videoLink"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <div class="mt-5 video-title">{{ title }}</div>
  <div class="mt-3 video-time">{{ time }}</div>
  <div class="mt-3 video-explain">
    {{ description }}
  </div>
</div>
</template>

<script>
export default {
  name: 'TutorialVideo',
  data () {
    return {
      // showTutorialModal: false,
      // title: '',
      // videoLink: '',
      // description: '',
      // time: '',
    }
  },
  computed: {
    currentVideoTutorial() {
      return this.$store.state.currentVideoTutorial
    },
    title () {
      return this.currentVideoTutorial ? this.currentVideoTutorial.title : ''
    },
    videoLink () {
      return this.currentVideoTutorial ? this.currentVideoTutorial.videoLink : ''
    },
    description () {
      return this.currentVideoTutorial ? this.currentVideoTutorial.description : ''
    },
    time () {
      return this.currentVideoTutorial ? this.currentVideoTutorial.time : ''
    }
  },
  methods: {
    closeAction () {
      // this.showTutorialModal = false;
      this.$store.commit('setCurrentVideoTutorialToDisplay', null);
    },
    // showVideoModal(title, videoLink, description, time) {
      
    //   this.showTutorialModal = true;
      
    //   this.title = title;
    //   this.videoLink = videoLink;
    //   this.description = description;
    //   this.time = time;
    // }
  }

}
</script>


<style scoped lang="scss">
.tutorial-video {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: calc(100vw - 50px);
  padding: 15px;
  border-radius: 6.622px;
  background: var(--v-cardBg-base);
  z-index: 100;
  box-shadow: 0px 6px 27px 0px rgba(0, 0, 0, 0.17);
  
  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    transform: translate(50%, -50%);
    background: white;
    border-radius: 100%;
    box-shadow: 0px 6px 27px 0px rgba(0, 0, 0, 0.17);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .material-icons {
      font-size: 1.3rem;
      color: var(--v-gray2Color-base);
    }
  }
  .video {
    height: 370px;
    background: gray;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .video-title {
    font-size: 1.2rem;
    font-weight: 800;
    color: var(--v-mainColor-base);
  }
  .video-time {
    color: var(--v-red12Color-base);
  }
  .video-explain {
    color: var(--v-mainColor-base);
  }
}

</style>