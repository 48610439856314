<template>
<div class="tool-card pa-5">
  <div class="top-part">
    <span class="icon-part">
      <span class="material-icons-outlined">article</span>
    </span>
    <span class="name">On Page Optimization</span>
  </div>
  <div class="mt-5">
    <v-text-field outlined dense
      v-model="searchTerm"
      hide-details="auto"
      label="Search"
      color="greenColor">
    </v-text-field>
  </div>
  <div v-if="$store.getters.isDemoUser" class="flex-grow-1 pages-wrapper mt-3">
    <div v-for="page in demoSearchPages" :key="page.id"  class="page-item" @click="gotoPage(page)">
      <div class="flex-grow-1">
        <div class="name">{{ page.name }}</div>
        <div class="time">
          {{ toLocalDateTimeString(page.createdAt) }}
        </div>
      </div>
      <div class="action">
        <i class="material-icons-outlined" @click="gotoPage(page)">arrow_circle_right</i>
      </div>
    </div>
    <div v-if="demoPages.length == 0" class="no-project-block">
      <div>
        <img src="/static/empty-folder.png" />
        <div>
          You haven't created any <br />
          Reports for this project yet
        </div>
      </div>
    </div>
  </div>
  <div v-else class="flex-grow-1 pages-wrapper mt-3">
    <div v-for="page in searchedPages" :key="page.id"  class="page-item" @click="gotoPage(page)">
      <div class="flex-grow-1">
        <div class="name">{{ page.name }}</div>
        <div class="time">
          {{ toLocalDateTimeString(page.createdAt) }}
        </div>
      </div>
      <div class="action">
        <i class="material-icons-outlined" @click="gotoPage(page)">arrow_circle_right</i>
      </div>
    </div>
    <div v-if="pages.length == 0" class="no-project-block">
      <div>
        <img src="/static/empty-folder.png" />
        <div>
          You haven't created any <br />
          Reports for this project yet
        </div>
      </div>
    </div>
  </div>
  <div :class="`mt-5 d-flex align-center ${($store.getters.isDemoUser && !isDemoPageNotCreated()) ? 'justify-end' : 'justify-space-between'}`">
    <v-btn v-if="!$store.getters.isDemoUser || isDemoPageNotCreated()" rounded small color="profileAnchorColor"
      class="button-with-icon whiteColor--text text-none"
      @click="createNewOnClick">
      <span>Create new</span>
    </v-btn>
    <span v-if="$store.getters.isDemoUser" class="how-many" :style="{ cursor: $store.getters.isDemoUser ? 'default' : '' }" @click="() => { $store.getters.isDemoUser ? null : $router.push(`/sites/${site.id}/pages`) }">
      {{ demoPages.length }} {{ demoPages.length > 1 ? 'pages' : 'page' }}
    </span>
    <span v-else class="how-many" @click="() => { $router.push(`/sites/${site.id}/pages`) }">
      {{ pages.length }} {{ pages.length > 1 ? 'pages' : 'page' }}
    </span>
  </div>
</div>
</template>

<script>
import { mixin } from '@/components/mixin'
import { DEMO_SESSION_KEYS } from "@/components/PageRun/constants";

export default {
  mixins: [mixin, ],
  data () {
    return {
      searchTerm: '',
    }
  },
  computed: {
    searchedPages () {
      return this.pages.filter(p => {
        return p.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
    },
    demoPages() {
      return this.isDemoPageNotCreated() ? [] : this.pages
    },
    demoSearchPages() {
      return this.demoPages.filter(p => {
        return p.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
    }
  },
  watch: {
    '$route': function(newVal, oldVal) {
      this.loadSite()
    }    
  },
  methods: {
    createNewOnClick() {
      if (this.$store.getters.isDemoUser) {
        sessionStorage.setItem(DEMO_SESSION_KEYS.runUrl, `/sites/${this.site.id}/pages/${this.pages[0].id}`)
      }
      this.$router.push(`/sites/${this.site.id}/newpage`)
    },
    isDemoPageNotCreated() {
      return !sessionStorage.getItem(DEMO_SESSION_KEYS.pageCreated)
    },
    loadSite () {
      this.$store.commit('showLoading')
        return this.$store.dispatch('loadSite', this.site.id)
          .then((response) => {
            if (response.data && response.data.status === 'SUCCESS') {
              if (response.data.site) {
                this.$store.commit('setSite', response.data.site)
              }
            }
          })
          .finally(() => this.$store.commit('hideLoading'))
    },
    gotoPage (page) {
      this.$router.push(`/sites/${this.site.id}/pages/${page.id}`)
    }
  },
  beforeMount () {
    this.siteName = this.site ? this.site.name : ''
    if (!this.$store.state.sites.length) {
      this.$store.commit('showLoading')
      return this.$store.dispatch('loadSites')
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('setSites', response.data.sites)
          }
        })
        .finally(() => {
          // this.$store.commit('hideLoading')
          this.loadSite()
        })
    }
  },
}
</script>
