<template>
  <div class="pl20 pr20">
    <div class="bread-crumb">
      <div
        @click="
          () => {
            $router.push('/workstation');
          }
        "
      >
        Workstation
      </div>
      <div class="font-weight-bold">
        <span class="material-icons">chevron_right</span>
        {{ site?.name }}
      </div>
    </div>
    <div class="mt-7 d-flex align-center">
      <div class="page-name flex-grow-1">
        {{ site?.name | capitalize }} project workstation
      </div>
      <div class="d-flex align-center gap5">
        <v-btn
          outlined
          rounded
          color="mainColor"
          class="button-with-icon text-none"
          @click="
            () => {
              showActivitySidebar = true;
            }
          "
        >
          <span class="material-icons">update</span>
          <span>Show activity</span>
        </v-btn>
        <!-- <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="isDemo" rounded outlined
            color="mainColor" class="button-with-icon text-none"
            v-bind="attrs"
            v-on="on">
            <span class="material-icons-outlined">download_for_offline</span>
            <span>Download</span>
          </v-btn>          
        </template>
        <v-list>
          <v-list-item @click="() => {
            $notify({
              group: 'info', type: 'warning',
              text: 'this is in developemnt'
            })
          }">
            <v-list-item-title>Download as PDF</v-list-item-title>
          </v-list-item>
          <v-list-item @click="() => {
            $notify({
              group: 'info', type: 'warning',
              text: 'this is in developemnt'
            })
          }">
            <v-list-item-title>Download as XLSX</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      </div>
    </div>
    <div class="pages-overview mt-5" v-if="paginatedPages.length">
      <div class="stat-by-completion-status">
        <div class="box-label">Pages by completion status</div>
        <div class="total-label">Total</div>
        <div class="pages-count">
          {{ pagesCount }} {{ pagesCount > 1 ? "pages" : "page" }}
        </div>
        <div>
          <apex-chart
            height="300px"
            type="donut"
            :series="pagesCountBycompletionstatusChartData.series"
            :options="pagesCountBycompletionstatusChartData.options"
          ></apex-chart>
        </div>
      </div>
      <div class="stat-by-completion-status">
        <div class="box-label">Tasks by completion status</div>
        <div class="total-label">Total</div>
        <div class="pages-count">
          {{ tasksCount }} {{ tasksCount > 1 ? "tasks" : "task" }}
        </div>
        <div>
          <apex-chart
            height="300px"
            type="donut"
            :series="tasksCountWithStatusChartData.series"
            :options="tasksCountWithStatusChartData.options"
          ></apex-chart>
        </div>
      </div>
      <div
        class="progress-over-time-box flex-grow-1"
        style="position: relative"
      >
        <div class="d-flex align-center">
          <div class="flex-grow-1 box-label">Your progress over time</div>
          <div>
            <i
              class="material-icons box-dot-menu"
              @click="
                () => {
                  showProgressOvertimeCalendar = !showProgressOvertimeCalendar;
                }
              "
              >more_horiz</i
            >

            <date-picker
              v-if="showProgressOvertimeCalendar"
              v-click-outside="
                () => {
                  showProgressOvertimeCalendar = false;
                }
              "
              v-model="progressOverTimeDateRange"
              range
              inline
              style="position: absolute; right: 20px; top: 50px; z-index: 12"
            >
            </date-picker>
          </div>
        </div>
        <!-- {{ progressOverTimeData }} -->
        <apex-chart
          width="100%"
          height="240"
          type="line"
          :options="progressOverTimeData.chartOptions"
          :series="progressOverTimeData.series"
        ></apex-chart>
        <!-- <changes-over-time-chart
        ref="changesOverTimeChartRef" :labels="changesOverTimeData.labels" :datasets='changesOverTimeData.data'/> -->
        <!-- <div class="box-label">Your progress over time</div> -->
      </div>
    </div>
    <div class="mt-7" v-if="paginatedPages.length">
      <div class="sub-label">Tasks by pages</div>
      <div class="mt-3 page-level-pages-wrapper">
        <div
          v-for="page in paginatedPages"
          class="mt-2 clickable"
          :key="page.id"
          :class="{ 'is-all-done': page.allTasksCompleted }"
          @click="
            () => {
              $router.push(`/workstation/${page.siteId}/pages/${page.id}/`);
            }
          "
        >
          <div class="icon-part">
            <span class="material-icons" v-if="page.allTasksCompleted"
              >check_circle</span
            >
            <span class="material-icons-outlined" v-else>check_circle</span>
          </div>
          <div class="flex-grow-1">
            <div class="d-flex align-center gap10">
              <div class="name">{{ page.name }}</div>
              <div class="tasks-count">{{ page.tasksCount }}</div>
            </div>
            <div class="last-report-date">
              Last POP run on {{ toLocalDateTimeString(page.lastRunDate) }}
            </div>
          </div>
          <div v-if="page.pageRunId" style="display: flex; align-items: center;" class="mr-5">
            <v-tooltip top color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <span style="font-size: 1rem;" v-if="page.pageNotBuilt" class="schema-container" v-bind="attrs" v-on="on" @click="gotoPage(`/sites/${page.siteId}/pages/${page.id}/pageruns/${page.pageRunId}`)">
                  N/A
                </span>
                <span v-else v-bind="attrs" v-on="on" class="schema-container" style="display: flex; align-items: center;" @click="gotoPage(`/sites/${page.siteId}/pages/${page.id}/pageruns/${page.pageRunId}`)">
                  <radial-progress-bar
                    :diameter="40"
                    :completed-steps="page.pageRunScore || 0"
                    :total-steps="100"
                    :strokeWidth="7"
                    :startColor="pageScoreTintColor(page.pageRunScore)"
                    :stopColor="pageScoreTintColor(page.pageRunScore)"
                    :innerStrokeColor="'#D9D9D9'"
                    strokeLinecap="square"
                  />
                  <span v-if="!page.pageNotBuilt" class="ml-1" style="font-size: 1rem;">{{ page.pageRunScore | round(1) }}</span>
                </span>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                Your current POP Content Brief Score
              </div>
            </v-tooltip>
          </div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="flex-grow: unset;"
                class="publish-status"
                :class="{ 'is-published': page.isPublished }"
              >
                <span class="material-icons icon">rocket_launch</span>
                <span class="text">
                  {{ page.isPublished ? "PUBLISHED" : "NOT PUBLISHED" }}
                </span>
              </div>
            </template>
            <v-list>
              <div
                class="publish-menu-item"
                @click="
                  () => {
                    updatePublishStatus(page, 0);
                  }
                "
              >
                <span class="material-icons icon">rocket_launch</span>
                <span class="text"> NOT PUBLISHED </span>
              </div>
              <div
                class="publish-menu-item is-published"
                @click="
                  () => {
                    updatePublishStatus(page, 1);
                  }
                "
              >
                <span class="material-icons icon">rocket_launch</span>
                <span class="text"> PUBLISHED </span>
              </div>
              <!-- <v-list-item @click="() => {

            }">
              <v-list-item-title>All reports</v-list-item-title>
            </v-list-item> -->
              <!-- <v-list-item @click="downloadReports(bulkRun, 'ai_runs')" v-if="bulkRun.hasAIRun">
              <v-list-item-title>All AI articles</v-list-item-title>
            </v-list-item> -->
            </v-list>
          </v-menu>
          <div>
            <span class="action-btn" :id="`dot-menu-for-${page.id}`">
              <span class="material-icons">more_horiz</span>
            </span>
            <v-menu :activator="`#dot-menu-for-${page.id}`" bottom offset-y>
              <v-list>
                <v-list-item
                  link
                  @click="
                    () => {
                      $router.push(
                        `/workstation/${page.siteId}/pages/${page.id}/`
                      );
                    }
                  "
                >
                  <v-list-item-title class="mainColor--text">
                    Open task list
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="
                    () => {
                      $router.push(`/sites/${page.siteId}/pages/${page.id}/`);
                    }
                  "
                >
                  <v-list-item-title class="mainColor--text">
                    Open page runs
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2" v-if="pagesTotal > perPage">
      <paginate
        :value="currentPage"
        :page-count="Math.ceil(pagesTotal / perPage)"
        prev-text="<i class='material-icons'>navigate_before</i>"
        next-text="<i class='material-icons'>navigate_next</i>"
        container-class="card-pagination"
        :click-handler="
          (cPage) => {
            currentPage = cPage;
            loadPagesForWorkStation();
          }
        "
      ></paginate>
    </div>

    <div v-if="showActivitySidebar" class="activity-sidebar">
      <div class="header-part">
        <span
          class="material-icons close-btn"
          @click="
            () => {
              showActivitySidebar = false;
            }
          "
          >close</span
        >
        <span class="title-label">Recent activity</span>
        <div>
          <i
            class="material-icons filter-btn"
            @click="
              () => {
                showRecentActivityCalendar = !showRecentActivityCalendar;
              }
            "
            >filter_list</i
          >

          <date-picker
            v-if="showRecentActivityCalendar"
            @change="
              () => {
                showRecentActivityCalendar = false;
              }
            "
            v-model="progressOverTimeDateRange"
            range
            style="position: absolute; right: 20px; top: 50px; z-index: 12"
          >
          </date-picker>
        </div>
      </div>
      <div class="mt-5 recent-activities">
        <div
          v-for="note in recentActivities"
          :key="note.id"
          class="activity-item"
        >
          <div
            class="avatar-circle"
            :style="{ background: strToHslColor(note.email) }"
          >
            <template v-if="note.email">
              {{ note.email[0].toUpperCase() }}
            </template>
            <template v-else>
              <span class="material-icons">person</span>
            </template>
          </div>
          <div>
            <div class="note-signal">
              {{ getTaskSignalDisplayName(note) }}
            </div>
            <div class="note-desc">
              <span class="who-made">{{
                note.email ? note.email + " " : ""
              }}</span>
              {{ note.isAutoNote ? note.note : `added comment.` }}
              <div
                v-if="!note.isAutoNote"
                class="profileAnchorColor--text my-1"
              >
                {{
                  `"${note.note.substring(0, 100)}${
                    note.note.length > 100 ? "..." : ""
                  }"`
                }}
              </div>
            </div>
            <div class="note-date">{{ note.createdAt }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column align-center flex-gap-10 justify-center no-page-container"
      v-if="!paginatedPages.length"
    >
      <img
        src="../../assets/fontawesome/no-pages.svg"
        alt="no-pages"
      />
      <h5 class="h5-size">You don’t have any pages</h5>
      <p class="heading-description">
        Add a new page to this project by clicking the plus icon next to your
        project in the menu on the left.
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import RadialProgressBar from 'vue-radial-progress';
import Paginate from "vuejs-paginate";
import { toLocalDateTimeString, strToHslColor } from "@/utils";
import { mixin } from "./common_mixin.js";

export default {
  mixins: [mixin],
  components: {
    RadialProgressBar,
    DatePicker,
    Paginate,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filterText: "",

      site: null,
      pagesCount: 0,
      pagesCountByCompletionStatus: [0, 0, 0],
      tasksCount: 0,
      tasksCountByCompletionStatus: [0, 0, 0, 0],
      pagesTotal: 0,
      paginatedPages: [],
      allNotesInPeriod: [],

      showProgressOvertimeCalendar: false,
      progressOverTimeDateRange: [
        moment().subtract(3, "months").toDate(),
        moment().add(1, "days").toDate(),
      ],

      showActivitySidebar: false,
      showRecentActivityCalendar: false,
      // recentActivityDateRange: [moment().subtract(3, 'months').toDate(), moment().add(1, 'days').toDate()],
      // use progressOverTimeDateRange variable together for simplicity
    };
  },
  watch: {
    showProgressOvertimeCalendar: function (newVal, oldVal) {
      if (newVal == false && oldVal == true) {
        this.loadPagesForWorkStation();
      }
    },
  },
  computed: {
    siteId() {
      return parseInt(this.$route.params.siteId);
    },
    recentActivities() {
      return this.allNotesInPeriod.toReversed();
    },
    pagesCountBycompletionstatusChartData() {
      return {
        series: this.pagesCountByCompletionStatus,
        options: {
          labels: ["Not started", "Completed", "In review"],
          colors: ["#A6A6A6", "#25A97A", "#FF3939"],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: "left",
          },
          // plotOptions: {
          //   pie: {
          //     donut: {}
          //   },
          // }
        },
      };
    },
    tasksCountWithStatusChartData() {
      return {
        series: this.tasksCountByCompletionStatus,
        options: {
          labels: ["Not started", "Completed", "Working", "In review"],
          colors: ["#A6A6A6", "#25A97A", "#316BFF", "#FF3939"],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: "left",
          },
        },
      };
    },
    progressOverTimeData() {
      let labels = [];
      let filteredNotes = this.allNotesInPeriod;
      filteredNotes.map((note) => {
        labels.push(note.createdAt.split(" ")[0]);
      });
      labels = labels
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
        .reverse();
      let progressHistory = new Array(labels.length).fill(0);

      filteredNotes.map((note) => {
        let idx = labels.indexOf(note.createdAt.split(" ")[0]);
        progressHistory[idx]++;
      });

      return {
        series: [
          {
            name: "progress",
            data: progressHistory,
          },
        ],
        chartOptions: {
          colors: ["#FF5700"],
          xaxis: {
            categories: labels,
            labels: {
              style: {
                colors: "#CCCCCC",
              },
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
        },
      };
    },
  },
  methods: {
    gotoPage(url) {
      this.$router.push(url)
    },
    toLocalDateTimeString(d) {
      return toLocalDateTimeString(d);
    },
    strToHslColor(str) {
      return strToHslColor(str);
    },
    loadPagesForWorkStation() {
      let [fromDate, toDate] = this.progressOverTimeDateRange;
      const params = [
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.filterText}`,
        `fromDate=${fromDate ? fromDate.valueOf() : ""}`,
        `toDate=${toDate ? toDate.valueOf() : ""}`,
      ].join("&");
      const data = {
        siteId: this.siteId,
        params: params,
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("getPagesForWorkstation", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.site = response.data.site;
            this.pagesCount = response.data.pagesCount;
            this.pagesCountByCompletionStatus =
              response.data.pagesCountByCompletionStatus;
            this.tasksCount = response.data.tasksCount;
            this.tasksCountByCompletionStatus =
              response.data.tasksCountByCompletionStatus;
            this.pagesTotal = response.data.pagesTotal;
            this.paginatedPages = response.data.paginatedPages;
            this.allNotesInPeriod = response.data.allNotesInPeriod;
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: response.data?.msg || "Failed to load data",
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    updatePublishStatus(page, markAsPublished) {
      if (page.isPublished == markAsPublished) {
        return;
      }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("updatePagePublishStatus", {
          pageId: page.id,
          isPublished: markAsPublished,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$notify({
              group: "info",
              type: "success",
              text: "Successfully saved",
            });
            this.loadPagesForWorkStation();
          } else {
            this.$store.commit("hideLoading");
          }
        })
        .catch((e) => {
          this.$store.commit("hideLoading");
        });
    },
  },
  mounted() {
    this.loadPagesForWorkStation();
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
.h5-size {
  font-size: 1.13rem;
  color: var(--v-mainColor-base);
  font-weight: 600;
}
.heading-description {
  color: var(--v-mainColor-base);
  max-width: 450px;
  text-align: center;
}
.no-page-container {
  padding-top: 7rem;
}
</style>
