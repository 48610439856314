<template>
<div class="custom-stack-chart">
  <div class="label-layer">
    <div class="point-label" :style="{ 'left': `0%` }">0</div>
    <div class="point-label" :style="{ 'left': `${currentPosition}%`, 'color': currentPositionColor }">{{ current }}</div>
    <div class="point-label" :style="{ 'left': `${100}%`, }">{{ max }}</div>
  </div>
  <div class="bar-layer">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>

    <div class="point under-point" :style="{ 'left': `${currentPosition}%` }"></div>
    <div class="point-tooltip under-point-tooltip" :style="{ 'left': `${currentPosition}%`}">
      {{ currentTooltipText }}
    </div>
  </div>
  <div class="label-layer">
    <div>UNDER OPTIMIZED</div>
    <!-- <div>OVER OPTIMIZED</div> -->
  </div>
</div>

</template>


<script>

let colorBarCount = 8
let colorArray = [
  '#FF1F2D',
  '#FF6266',
  '#FF8F91',
  '#22DC7C',
  '#00CD49',
  '#FF9B2D',
  '#FFB95A',
  '#FF9B2D',
]
export default {
  props: [
    'current', 'max',
    'currentTooltipText', 'maxTooltipText'
  ],
  data () {
    return {
            
    }
  },
  computed: {
    currentPosition () {
      if (this.max<=0) {
        return 100
      }
      return this.current / this.max * 100
    },
    currentPositionColor () {
      return this.$vuetify.theme.currentTheme['mainColor']
    }
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>

  $background-1: #FF1F2D;
  $background-2: #FF6266;
  $background-3: #FF8F91;
  $background-4: #FF9B2D;
  $background-5: #FFB95A;
  $background-6: #FF9B2D;
  $background-7: #22DC7C;
  $background-8: #00CD49;


  .custom-stack-chart {
    display: block;
    .bar-layer {
      display: grid;
      grid-auto-flow: column;
      position: relative;
      height: 15px;
      div:nth-child(1) {
        background: $background-1;
      }
      div:nth-child(2) {
        background: $background-2;
      }
      div:nth-child(3) {
        background: $background-3;
      }
      div:nth-child(4) {
        background: $background-4;
      }
      div:nth-child(5) {
        background: $background-5;
      }
      div:nth-child(6) {
        background: $background-6;
      }
      div:nth-child(7) {
        background: $background-7;
      }
      div:nth-child(8) {
        background: $background-8;
      }
     
      .point {
        background: var(--v-whiteColor-base);
        position: absolute;
        cursor: pointer;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .point:hover {
        background-color: yellow;      
      }
      .under-point:hover ~ .under-point-tooltip,
      .point-tooltip:hover{
         display: block;
      }
      
      .over-point:hover ~ .over-point-tooltip,
      .point-tooltip:hover{
         display: block;
      }

      .point-tooltip {
        position: absolute;
        display: none;
        background: var(--v-cardBg-base);
        border: 1px solid rgb(192, 192, 192);
        border-radius: 5px;
        padding: 10px;
        min-width: 300px;
        top: 70%;
        z-index: 50;
      }

    }
    
    .label-layer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;
      color: var(--v-mainColor-base);
      min-height: 16px;
      div {
        font-size: 0.6rem;
      }
      div:nth-child(1) {
        text-align: left;
      }
      div:nth-child(2) {
        text-align: right;
      }
      
      .point-label {
        position: absolute;
        color: var(--v-mainColor-base);
        transform: translateX(-50%);
      }
    }
  }
</style>