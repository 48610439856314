<template>
<div>
  <div class="columns is-desktop">
    <div class="column is-12">
      <div class="hscroll-container">
        <table class="signal-table">
          <thead>
            <tr>
              <th class="fixed-width">Schema Type</th>
              <th>Recommendation</th>
              <template v-if="todoTabType=='checklist'">
                <th>Notes</th>
                <th>Status</th>
                <th>Archive</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, idx) in recommendationList" :key="idx">
              <td>
                <a class="is-link" @click="$parent.$parent.showExamplePopup(row.type)" >{{ row.type }}</a>
              </td>
              <td>{{ row.recommendation }}</td>
              <template v-if="todoTabType=='checklist'">
                <td>
                  <i class="fa fa-sticky-note-o" aria-hidden="true" @click="dispNotesForChecklist(row.checklistId)" style="cursor: pointer;"></i>
                </td>
                <td>
                  <div class="control">
                    <label class="radio">
                      <input type="radio" :name="row.checklistId" :checked="row.isCompleted == 1" @click="updateChecklistState(1, row)">
                      Complete
                    </label>
                    <label class="radio">
                      <input type="radio" :name="row.checklistId" :checked="row.isCompleted == 0" @click="updateChecklistState(0, row)">
                      Incomplete
                    </label>
                    <label class="radio">
                      <input type="radio" :name="row.checklistId" :checked="row.isCompleted == 2" @click="updateChecklistState(2, row)">
                      Ignore
                    </label>
                  </div>
                </td>
                <td>
                  <button class="button is-success" @click="archiveChecklistItem(row.checklistId)">Archive</button>                  
                </td>
              </template>
            </tr>
            <tr v-if="recommendationList.length == 0">
              <td colspan="1000" class="has-text-centered">There are no current tasks for your Schema</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { mixin } from '@/components/mixin'
export default {
  mixins: [mixin],
  props: ['todoTabType', 'pageId', 'mainPageSchemaTypes', 'competitors', 'checkLists'],
  data () {
    return {
      recommendationList: [],
    }
  },
  computed: {
    targetPageSchemaList () {
      return Object.keys(this.mainPageSchemaTypes)
    },
    unarchivedChecklists () {
      return this.checkLists.filter(item => !item.isArchived)
    },    
  },
  watch: {
    'checkLists': {
      handler: function(newVal, oldVal) {
        this.orderedRecommendations()
      }
    }     
  },
  methods: {
    dispNotesForChecklist (checklistId) {
      this.$parent.selectedChecklistId = checklistId
      this.$parent.showNoteModal = true      
    },
    updateChecklistState (status, row) {

      let checklistId = row.checklistId
      let data = {
        id: checklistId,
        isCompleted: status
      }
      this.$store.commit('showLoading')
      return this.$store.dispatch('updateChecklistState', data)
        .then(response => {
          if (response.data && response.data.status === 'SUCCESS') {
            console.log('successfully added!')
          }
          return this.$parent.loadSites()
        })
    },
    archiveChecklistItem (checklistId) {
      if (!window.confirm('Do you want to archive this schema type')) { return }
      this.$store.commit('showLoading')
      return this.$store.dispatch('archiveOneFromCheckList', { id: checklistId })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            return this.$parent.loadSites()
          } else {
            this.$store.commit('hideLoading')
          }
        })
        .catch(() => {
          this.$store.commit('hideLoading')
        })
    },

    orderedRecommendations () {
      let rlt = {}
      this.competitors.map(competitor => {
        competitor.schemaTypes.map(item => {
          if (rlt[`${item.type}`] == null) rlt[`${item.type}`] = 1; else rlt[`${item.type}`] +=1;
        })
      })
      
      let recommendationList = []

      Object.keys(rlt).map(key => {
        recommendationList.push({
          type: key,
          count: rlt[`${key}`]
        })
      })
      this.recommendationList = recommendationList.sort((a, b) => {
          if (a.count < b.count) return 1
          if (a.count == b.count) return 0
          if (a.count > b.count) return -1
        })
        .map(row => {
          let found = this.unarchivedChecklists.find(item => {
            if (item.type == 4 && item.signal === row.type) return true; else return false;
          })
          let resultRow = Object.assign({}, row)
          if (found) {
            resultRow.checklistId = found.id
            resultRow.dispType = found.dispType
            resultRow.isCompleted = found.isCompleted
            if (this.targetPageSchemaList.indexOf(resultRow.type) >=0) {
              resultRow.recommendation = 'Leave as'
            } else {
              resultRow.recommendation = 'Add this schema to your target page'
            }
          }
          return resultRow
        })
        .filter(row => row['checklistId']!=undefined)
    }
  },
  beforeMount () {
    this.orderedRecommendations()
  }
}
</script>

<style scoped>
.tag__custom {
  height: auto !important;
  white-space: unset !important;
}
</style>