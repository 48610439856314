<template>
    <div style="max-width: 340px;">
        <v-autocomplete
            outlined
            dense
            :items="filteredItems"
            :value="selectedValue"
            item-value="id"
            hide-details="auto"
            color="grayColor"
            @change="updateSelected"
            item-props
            @keydown="onAnyOtherKeyDown"
        >
            <!-- Search Input -->
            <template v-slot:prepend-item>
                <v-text-field
                    v-model="searchQuery"
                    :placeholder="searchPlaceholder"
                    clearable
                    @click:clear="onClear"
                    dense
                    @click.stop
                    class="search-query-input"
                />
            </template>

            <!-- Item Template -->
            <template v-slot:item="{ item }">
                <div class="col">
                    <div class="has-text-weight-bold">
                        {{ item.name }}
                        <span class="main-icon-color material-icons" v-if="item.isFavorite">star</span>
                    </div>
                    <div>
                        Report ID: {{ item.id }}, created on {{ formatDate(item.createdAt) }}
                    </div>
                </div>

                <!-- Three-Dot Dropdown Menu for Actions -->
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>more_horiz</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="cluster-menu">
                        <v-btn class="clusterBtn" text @click="() => onRename(item)">Rename</v-btn><br />
                        <v-btn class="clusterBtn" text @click="() => onToggleFavorite(item)">
                            <span v-if="!item.isFavorite">Star</span>
                            <span v-else>Unstar</span>
                        </v-btn><br />
                        <v-btn class="clusterBtn" text @click="() => onDelete(item)">Delete</v-btn>
                    </v-list>
                </v-menu>
            </template>

            <!-- Selection Template -->
            <template v-slot:selection="{ item }">
                <span class="has-text-weight-bold">{{ item.name }}</span>
                &nbsp;&nbsp;
                <span>Report ID: {{ item.id }}</span>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        selectedValue: {
            type: [String, Number, null],
            default: null,
        },
        searchPlaceholder: {
            type: String,
            default: 'Search...',
        },
        itemValue: {
            type: String,
            default: 'id',
        },
    },
    data() {
        return {
            searchQuery: '',
        };
    },
    computed: {
        filteredItems() {
            const query = this.searchQuery ? this.searchQuery.toLowerCase() : '';
            return query ? this.items.filter(item => item.name.toLowerCase().includes(query)) : this.items;
        },
    },
    methods: {
        updateSelected(value) {
            this.$emit('updateSelectedValue', value);
        },
        onRename(item) {
            this.$emit('rename', item);
            this.searchQuery = '';
        },
        onToggleFavorite(item) {
            const updatedItem = { ...item, isFavorite: !item.isFavorite };
            this.$emit('toggle-favorite', updatedItem);
            this.searchQuery = '';
        },
        onDelete(item) {
            this.$emit('delete', item);
            this.searchQuery = '';
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString();
        },
        onAnyOtherKeyDown(event) {
            event.preventDefault();
        },
        onClear() {
            this.searchQuery = '';
        },
    },
    // On distroy reset the search query
    destroyed() {
        this.searchQuery = '';
    },
};
</script>

<style scoped>
.main-icon-color {
    color: var(--v-profileAnchorColor-base);
}

.cluster-menu {
    padding-left: 0px;
    align-items: flex-start;
}

.clusterBtn {
    text-transform: capitalize;
    font-family: Mulish;
    width: 100%;
    justify-content: flex-start;
}

.v-btn {
    text-align: left;
}

.search-query-input {
    padding-left: 10px;
    font-family: 'Mulish', sans-serif;
}
</style>