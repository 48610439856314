import { Packer } from "docx";
import moment from "moment";
import { ReportTypes } from "../components/PageRun/constants";
import {
  Document,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  ImageRun,
  ExternalHyperlink,
  WidthType,
  BorderStyle,
  HeadingLevel,
  convertInchesToTwip,
  PageBreak,
  LevelFormat,
} from "docx";

const WORD_CONFIG = [
  {
    reference: "numbering-style-one",
    levels: [
      {
        level: 0,
        format: LevelFormat.DECIMAL,
        text: "%1.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.2),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 1,
        format: LevelFormat.DECIMAL,
        text: "%2.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 2,
        format: LevelFormat.DECIMAL,
        text: "%3.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.1),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 3,
        format: LevelFormat.DECIMAL,
        text: "%4)",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
    ],
  },
  {
    reference: "numbering-style-upper-letter",
    levels: [
      {
        level: 0,
        format: LevelFormat.UPPER_LETTER,
        text: "%1.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.2),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 1,
        format: LevelFormat.DECIMAL,
        text: "%2.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 2,
        format: LevelFormat.DECIMAL,
        text: "%3.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.1),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 3,
        format: LevelFormat.DECIMAL,
        text: "%4)",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
    ],
  },
  {
    reference: "numbering-style-lower-letter",
    levels: [
      {
        level: 0,
        format: LevelFormat.LOWER_LETTER,
        text: "%1.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.2),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 1,
        format: LevelFormat.DECIMAL,
        text: "%2.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 2,
        format: LevelFormat.DECIMAL,
        text: "%3.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.1),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 3,
        format: LevelFormat.DECIMAL,
        text: "%4)",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
    ],
  },
  {
    reference: "numbering-style-upper-roman",
    levels: [
      {
        level: 0,
        format: LevelFormat.UPPER_ROMAN,
        text: "%1.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.2),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 1,
        format: LevelFormat.DECIMAL,
        text: "%2.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 2,
        format: LevelFormat.DECIMAL,
        text: "%3.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.1),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 3,
        format: LevelFormat.DECIMAL,
        text: "%4)",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
    ],
  },
  {
    reference: "numbering-style-lower-roman",
    levels: [
      {
        level: 0,
        format: LevelFormat.LOWER_ROMAN,
        text: "%1.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.2),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 1,
        format: LevelFormat.DECIMAL,
        text: "%2.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 2,
        format: LevelFormat.DECIMAL,
        text: "%3.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.1),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 3,
        format: LevelFormat.DECIMAL,
        text: "%4)",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.6),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
    ],
  },
  {
    reference: "my-unique-bullet-points",
    levels: [
      {
        level: 0,
        format: LevelFormat.BULLET,
        text: "\u1F60",
        alignment: AlignmentType.LEFT,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
      {
        level: 1,
        format: LevelFormat.BULLET,
        text: "\u00A5",
        alignment: AlignmentType.LEFT,
        style: {
          paragraph: {
            indent: {
              left: convertInchesToTwip(1),
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
      {
        level: 2,
        format: LevelFormat.BULLET,
        text: "\u273F",
        alignment: AlignmentType.LEFT,
        style: {
          paragraph: {
            indent: {
              left: 2160,
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
      {
        level: 3,
        format: LevelFormat.BULLET,
        text: "\u267A",
        alignment: AlignmentType.LEFT,
        style: {
          paragraph: {
            indent: {
              left: 2880,
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
      {
        level: 4,
        format: LevelFormat.BULLET,
        text: "\u2603",
        alignment: AlignmentType.LEFT,
        style: {
          paragraph: {
            indent: {
              left: 3600,
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
    ],
  },
];

export const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const downloadWordFile = (doc, fileName) => {
  const mimeType =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  Packer.toBlob(doc).then((blob) => {
    const docblob = blob.slice(0, blob.size, mimeType);
    saveAs(docblob, `${fileName}.docx`);
  });
};

export const downloadEditorReport = (
  store,
  sourceType,
  reportingFormatType,
  siloKeywordId,
  secretKey
) => {
  const getPDFReport = (siloKeywordID, reportType, sourceType, secretKey) => {
    if (!siloKeywordID) return;
    const timestamp = moment().format("YYYY_M_D_h_mm_ss");
    store.commit("showLoading");
    store
      .dispatch("getSiloPDFReport", {
        siloKeywordId: siloKeywordID,
        reportType: reportType,
        sourceType: sourceType,
        secretKey: secretKey,
      })
      .then((response) => {
        const fileName = `${reportType}_keyword_${timestamp}`;
        downloadFile(response.data, fileName);
      })
      .finally(() => {
        store.commit("hideLoading");
      });
  };
  // Helper function to get alignment
  const getAlignment = (alignment) => {
    return alignment
      ? alignment === "left"
        ? AlignmentType.LEFT
        : alignment === "center"
        ? AlignmentType.CENTER
        : alignment === "right"
        ? AlignmentType.RIGHT
        : AlignmentType.JUSTIFIED
      : AlignmentType.LEFT;
  };

  // Helper function to create a paragraph with numbering
  const createNumberedParagraph = (
    textRuns,
    level,
    numberingRef,
    alignment
  ) => {
    return new Paragraph({
      children: textRuns,
      numbering: {
        level: level,
        reference: numberingRef,
      },
      alignment: getAlignment(alignment),
    });
  };

  // Helper function to create a paragraph with bullets
  const createBulletParagraph = (textRuns, level, bulletLevel, alignment) => {
    return new Paragraph({
      children: textRuns,
      bullet: {
        level: bulletLevel,
      },
      alignment: getAlignment(alignment),
    });
  };

  // Helper function to create paragraphs based on list type
  const createParagraphForListType = (element, textRuns, level) => {
    const numberingStyles = {
      numbers: "numbering-style-one",
      "lower-alpha": "numbering-style-lower-letter",
      "upper-alpha": "numbering-style-upper-letter",
      "lower-roman": "numbering-style-lower-roman",
      "upper-roman": "numbering-style-upper-roman",
      "lower-greek": "numbering-style-lower-letter", // Same as lower-alpha
    };

    const bulletLevels = {
      circle: 1,
      square: 2,
    };

    // Handle numbered lists
    if (numberingStyles[element.list_type]) {
      return createNumberedParagraph(
        textRuns,
        level,
        numberingStyles[element.list_type],
        element.alignment
      );
    }

    // Handle bullet lists
    if (bulletLevels[element.list_type]) {
      return createBulletParagraph(
        textRuns,
        level,
        bulletLevels[element.list_type],
        element.alignment
      );
    }

    // Default case for bullets and numbers
    return new Paragraph({
      children: textRuns,
      bullet: {
        level: level,
        format:
          element.list_type === "numbers"
            ? LevelFormat.DECIMAL
            : LevelFormat.BULLET,
      },
      alignment: getAlignment(element.alignment),
    });
  };

  // Main function to add list items
  function addListItems(element, htmlContentCell, level = 0) {
    element.children.forEach((child) => {
      if (child.tag === "list") {
        // Recursively handle nested lists
        addListItems(child, htmlContentCell, level + 1);
      } else if (child.tag === "li") {
        // Create an array of text runs from the li children
        const textRuns = child.children.map((childElement) => {
          if (childElement.isLink) {
            return new ExternalHyperlink({
              children: [
                new TextRun({
                  text: childElement.text,
                  bold: childElement.bold,
                  italics: childElement.italics,
                  strike: childElement.line_through || false,
                  font: { name: "Arial" },
                  size: element.fontSize * 2 || 22,
                  color: element.color || "0000FF", // Links are usually blue
                  underline: true,
                }),
              ],
              link: childElement.link,
            });
          }
          return new TextRun({
            text: childElement.text,
            bold: childElement.bold,
            italics: childElement.italics,
            strike: childElement.line_through || false,
            font: { name: "Arial" },
          });
        });

        // Create a paragraph based on the list type
        const paragraph = createParagraphForListType(element, textRuns, level);

        // Add the paragraph to the htmlContentCell
        htmlContentCell.addChildElement(paragraph);

        // Check for nested lists within this list item
        const nestedList = child.children.find(
          (childElement) => childElement.tag === "list"
        );
        if (nestedList) {
          // Recursively add nested list items
          addListItems(nestedList, htmlContentCell, level + 1);
        }
      }
    });
  }

  const formatWordContent = (element, htmlContentCell) => {
    const getAlignment = (alignment) => {
      switch (alignment) {
        case "left":
          return AlignmentType.LEFT;
        case "center":
          return AlignmentType.CENTER;
        case "right":
          return AlignmentType.RIGHT;
        default:
          return AlignmentType.JUSTIFIED;
      }
    };

    const getMargins = (styles) => ({
      top: convertInchesToTwip(styles.margin.top),
      bottom: convertInchesToTwip(styles.margin.bottom),
      left: convertInchesToTwip(styles.margin.left),
      right: convertInchesToTwip(styles.margin.right),
    });

    const getParagraph = (children) =>
      new Paragraph({
        children,
        alignment: getAlignment(element.alignment),
        spacing: {
          before: element.styles.spacing.before,
          after: element.styles.spacing.after,
          afterAutoSpacing: true,
          beforeAutoSpacing: true,
        },
        indent: {
          left: element.styles.indent.left,
          right: element.styles.indent.right,
        },
        margins: getMargins(element.styles),
      });

    if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(element.tag)) {
      const size = element.tag === "h1" || element.tag === "h2" ? 28 : 24;
      const hContent = getParagraph([
        new TextRun({
          text: `${element.content}`,
          color: element.color || "#000000",
          bold: true,
          size,
        }),
      ]);
      htmlContentCell.addChildElement(hContent);
    } else if (element.tag === "p") {
      const pContent = getParagraph(
        element.children.map((childElement) => {
          if (childElement.isLink) {
            // Handle anchor element (hyperlink)
            return new ExternalHyperlink({
              children: [
                new TextRun({
                  text: childElement.text,
                  color: childElement.color,
                  highlight: childElement.highlight,
                  bold: childElement.bold,
                  italics: childElement.italics,
                  font: { name: "Arial" },
                  strike: childElement.line_through || false,
                  size: element.fontSize * 2 || 22,
                  color: element.color || "0000FF", // Usually links are blue
                  underline: true, // Underline links
                }),
              ],
              link: childElement.link, // The href URL for the hyperlink
            });
          } else {
            // Handle other elements (non-link)
            return new TextRun({
              text: childElement.text,
              color: childElement.color,
              highlight: childElement.highlight,
              bold: childElement.bold,
              italics: childElement.italics,
              strike: childElement.line_through || false,
              font: childElement.code
                ? { name: "Courier New" }
                : { name: "Arial" },
              size: element.fontSize * 2 || 22,
            });
          }
        })
      );
      htmlContentCell.addChildElement(pContent);
    } else if (element.tag === "list") {
      addListItems(element, htmlContentCell);
    } else if (element.tag === "a") {
      const linkItem = getParagraph([
        new ExternalHyperlink({
          children: [
            new TextRun({
              text: element.content,
              color: element.color,
              highlight: element.highlight,
              font: { name: "Arial" },
              style: "Hyperlink",
              color: "#FF5700",
            }),
          ],
          link: element.link,
        }),
      ]);
      htmlContentCell.addChildElement(linkItem);
    } else if (element.tag === "img") {
      const imageItem = new Paragraph({
        children: [
          new ImageRun({
            data: element.src,
            transformation: {
              width: element.width,
              height: element.height,
            },
          }),
        ],
        alignment: AlignmentType.CENTER,
      });
      htmlContentCell.addChildElement(imageItem);
    } else if (element.tag === "table") {
      // Handle tables
      const tableRows = element.children.map((row) => {
        return new TableRow({
          children: row.children.map((cell) => {
            return new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: cell.content,
                      size: 22,
                    }),
                  ],
                }),
              ],
              width: {
                size: cell.width || "auto",
                type: WidthType.PERCENTAGE,
              },
            });
          }),
        });
      });

      const table = new Table({
        rows: tableRows,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        cellWidths: [10000, 3300],
      });

      htmlContentCell.addChildElement(table);
    } else if (element.tag === "pre") {
      // Handle preformatted text (monospaced block)
      const preContent = new Paragraph({
        children: [
          new TextRun({
            text: `${element.content}`,
            color: element.color,
            highlight: element.highlight,
            font: { name: "Courier New" }, // Monospaced font
            size: 22,
          }),
        ],
        spacing: {
          before: 40,
          after: 40,
        },
      });
      htmlContentCell.addChildElement(preContent);
    } else if (element.tag === "hr") {
      // Handle horizontal rule (<hr>)
      const hrContent = new Paragraph({
        border: {
          bottom: { size: 2, color: "000000", style: BorderStyle.SINGLE },
        },
      });
      htmlContentCell.addChildElement(hrContent);
    } else if (element.tag === "br") {
      // Handle line break (<br>)
      const brContent = new Paragraph({
        children: [new TextRun({ text: "" })],
        spacing: { before: 0, after: 0 },
      });
      htmlContentCell.addChildElement(brContent);
    }
  };
  const getWordReportData = (
    siloKeywordId,
    reportType,
    sourceType,
    secretKey
  ) => {
    if (!siloKeywordId) return;
    store.commit("showLoading");
    store
      .dispatch("getSiloWordReport", {
        siloKeywordId: siloKeywordId,
        reportType: reportType,
        sourceType: sourceType,
        secretKey: secretKey,
      })
      .then((response) => {
        if (sourceType == ReportTypes.AI_DRAFT) {
          reportType = "ai_draft";
          generatePageDraftWord(response.data, false, "ai_draft");
        } else if (sourceType == ReportTypes.HUMAN_DRAFT) {
          reportType = "human_draft";
          generatePageDraftWord(response.data, true, "human_draft");
        } else {
          generateOutlineWord(response.data);
        }
      })
      .finally(() => {
        store.commit("hideLoading");
      });
  };
  // Top Level Outline
  const generateOutlineWord = (responseData) => {
    // Create a new document
    const doc = new Document({
      styles: { default: { document: { run: { font: "Arial" } } } },
      sections: [
        {
          children: [
            // First Table: One row, two columns
            new Paragraph({
              children: [
                new ImageRun({
                  data: responseData.headerImg,
                  transformation: {
                    width: 600,
                    height: 230,
                  },
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                line: 240,
                after: 480,
              },
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "💡 Instructions",
                              color: "#FFFFFF",
                              bold: true,
                              size: 38,
                            }),
                          ],
                          heading: HeadingLevel.HEADING_1,
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            before: 120,
                            after: 120,
                            afterAutoSpacing: true,
                          },
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FF5600",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                      },
                      borders: {
                        top: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Use your favorite AI or human writing tool to draft the content for this page based on the",
                            }),
                            new TextRun({
                              text: " instructions ",
                              bold: true,
                            }),
                            new TextRun({
                              text: "below.",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                        new Paragraph({
                          children: responseData.data.isTopLevelKeyword
                            ? [
                                new TextRun({
                                  text: "When satisfied with the outcome, you may want to do a final SEO check of your final draft using the",
                                }),
                                new TextRun({
                                  text: " POP Chrome Extension ",
                                  bold: true,
                                }),
                                new TextRun({
                                  text: "to ensure the content scores above 80.",
                                  bold: true,
                                }),
                              ]
                            : [
                                new TextRun({
                                  text: "When satisfied with the outcome, just do a final check",
                                }),
                                new TextRun({
                                  text: " final check ",
                                  bold: true,
                                }),
                                new TextRun({
                                  text: "to ensure the main keyword is included at least once in these places:",
                                  bold: true,
                                }),
                              ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 120,
                            after: 120,
                          },
                        }),
                        ...(responseData.data.isTopLevelKeyword
                          ? [
                              new Paragraph({
                                children: [
                                  new ExternalHyperlink({
                                    children: [
                                      new TextRun({
                                        text: "Here is a tutorial explaining how to do that.",
                                        style: "Hyperlink",
                                        bold: true,
                                        color: "#FF5600",
                                      }),
                                    ],
                                    link: "https://help.pageoptimizer.pro/article/pageoptimizer-pro-chrome-extension-guide",
                                  }),
                                ],
                                bullet: {
                                  level: 1,
                                },
                                spacing: {
                                  before: 120,
                                  after: 120,
                                },
                              }),
                              new Paragraph({
                                children: [
                                  new ExternalHyperlink({
                                    children: [
                                      new TextRun({
                                        text: "Here is a list of POP Chrome Extension compatible editors.",
                                        style: "Hyperlink",
                                        bold: true,
                                        color: "#FF5600",
                                      }),
                                    ],
                                    link: "https://help.pageoptimizer.pro/article/compatible-editors",
                                  }),
                                ],
                                bullet: {
                                  level: 1,
                                },
                                spacing: {
                                  before: 120,
                                  after: 120,
                                },
                              }),
                            ].flat()
                          : [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "The title tag & H1 tag.",
                                  }),
                                ],
                                bullet: {
                                  level: 1,
                                },
                                spacing: {
                                  before: 120,
                                  after: 120,
                                },
                              }),
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "The main content.",
                                  }),
                                ],
                                bullet: {
                                  level: 1,
                                },
                                spacing: {
                                  before: 120,
                                  after: 120,
                                },
                              }),
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "If possible, the URL of the page (however do not change an existing URL).",
                                  }),
                                ],
                                bullet: {
                                  level: 1,
                                },
                                spacing: {
                                  before: 120,
                                  after: 120,
                                },
                              }),
                            ].flat()),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Your content is now ready to",
                            }),
                            new TextRun({
                              text: " publish ",
                              bold: true,
                            }),
                            new TextRun({
                              text: "to your website.",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Don't forget to",
                            }),
                            new TextRun({
                              text: " interlink",
                              bold: true,
                            }),
                            new TextRun({
                              text: " the page according to the",
                            }),
                            new TextRun({
                              text: " Silo map",
                              bold: true,
                            }),
                            new TextRun({
                              text: " included in the same folder as this document.",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "For a detailed",
                            }),
                            new TextRun({
                              text: " tutorial",
                              bold: true,
                            }),
                            new TextRun({
                              text: " on how the POP on-page process works ",
                            }),
                            new ExternalHyperlink({
                              children: [
                                new TextRun({
                                  text: "start here",
                                  style: "Hyperlink",
                                  bold: true,
                                  color: "#FF5600",
                                }),
                              ],
                              link: "https://academy.pageoptimizer.pro/lessons/introduction/",
                            }),
                            new TextRun({
                              text: ".",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "",
                      shading: {
                        fill: "#FEF7EF",
                      },
                      margins: {
                        top: convertInchesToTwip(0.15),
                        left: convertInchesToTwip(0.15),
                        bottom: convertInchesToTwip(0.15),
                        right: convertInchesToTwip(0.15),
                      },
                      borders: {
                        top: {
                          color: "#FF5600",
                          size: 5,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                      },
                    }),
                  ],
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              columnWidths: [9000, 3300],
            }),
            new Paragraph({ children: [new TextRun({ text: "  " })] }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Type of the Page",
                              bold: true,
                              size: 24,
                              color: "#FFFFFF",
                            }),
                          ],
                          spacing: {
                            before: 60,
                            after: 60,
                            afterAutoSpacing: true,
                          },
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FF5600",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: responseData.data.pageType,
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FEF7EF",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Main Keyword",
                              bold: true,
                              size: 24,
                              color: "#FFFFFF",
                            }),
                          ],
                          spacing: {
                            before: 60,
                            after: 60,
                            afterAutoSpacing: true,
                          },
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FF5600",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: responseData.data.mainKeyword,
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FEF7EF",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Recommended word count",
                              bold: true,
                              size: 24,
                              color: "#FFFFFF",
                            }),
                          ],
                          spacing: {
                            before: 60,
                            after: 60,
                            afterAutoSpacing: true,
                          },
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FF5600",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Around ${responseData.data.recommendedWC.toLocaleString()} words${
                                responseData.data.isTopLevelKeyword
                                  ? " (give or take 10%)"
                                  : ""
                              }`,
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FEF7EF",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                  ],
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              columnWidths: [4500, 4500],
            }),

            new Paragraph({
              children: [new PageBreak()],
            }),
          ],
        },
      ],
    });
    // Outline
    const outlineTable = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "📝 Page Outline",
                      color: "#FFFFFF",
                      bold: true,
                      size: 38,
                    }),
                  ],
                  heading: HeadingLevel.HEADING_1,
                  alignment: AlignmentType.CENTER,
                  spacing: {
                    before: 240,
                    after: 240,
                    afterAutoSpacing: true,
                  },
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              verticalAlign: "center",
              shading: {
                fill: "#FF5600",
              },
              margins: {
                top: convertInchesToTwip(0.1),
                bottom: convertInchesToTwip(0.1),
                left: convertInchesToTwip(0.1),
                right: convertInchesToTwip(0.1),
              },
              borders: {
                top: {
                  color: "#FF5600",
                  size: 5,
                },
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "#FFFFFF",
                  size: 5,
                },
                left: {
                  style: BorderStyle.SINGLE,
                  color: "#FF5600",
                  size: 5,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "#FF5600",
                  size: 5,
                },
              },
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      columnWidths: [9000, 3300],
    });
    // Add outline and subheading section to doc
    const htmlContentRow = new TableRow({
      children: [],
    });

    const htmlContentCell = new TableCell({
      children: [],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      shading: {
        fill: "#FEF7EF",
      },
      verticalAlign: "center",
      margins: {
        top: convertInchesToTwip(0.2),
        bottom: convertInchesToTwip(0.2),
        left: convertInchesToTwip(0.2),
        right: convertInchesToTwip(0.2),
      },
      borders: {
        top: {
          style: BorderStyle.SINGLE,
          color: "#FF5600",
          size: 5,
        },
        bottom: {
          style: BorderStyle.SINGLE,
          color: "#FF5600",
          size: 5,
        },
        left: {
          style: BorderStyle.SINGLE,
          color: "#FF5600",
          size: 5,
        },
        right: {
          style: BorderStyle.SINGLE,
          color: "#FF5600",
          size: 5,
        },
      },
    });

    responseData.data.content.forEach((element) => {
      formatWordContent(element, htmlContentCell);
    });

    htmlContentRow.addChildElement(htmlContentCell);
    outlineTable.addChildElement(htmlContentRow);
    doc.addSection({ children: [outlineTable] });
    const pageType = responseData.data.pageType.toLowerCase().replace(" ", "_");

    const timestamp = moment().format("YYYY_M_D_h_mm_ss");
    const fileName = `${pageType}_outline_keyword_${responseData.data.mainKeyword.replace(
      " ",
      "_"
    )}_${timestamp}`;
    downloadWordFile(doc, fileName);
  };
  const generatePageDraftWord = (responseData, edited, reportType) => {
    // Create a new document
    const doc = new Document({
      numbering: {
        config: WORD_CONFIG,
      },
      styles: { default: { document: { run: { font: "Arial" } } } },
      sections: [
        {
          children: [
            // First Table: One row, two columns
            new Paragraph({
              children: [
                new ImageRun({
                  data: responseData.headerImg,
                  transformation: {
                    width: 600,
                    height: 230,
                  },
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                line: 240,
                after: 480,
              },
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "💡 Instructions",
                              color: "#FFFFFF",
                              bold: true,
                              size: 38,
                            }),
                          ],
                          heading: HeadingLevel.HEADING_1,
                          alignment: AlignmentType.CENTER,
                          spacing: {
                            before: 120,
                            after: 120,
                            afterAutoSpacing: true,
                          },
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FF5600",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                      },
                      borders: {
                        top: {
                          color: "#FF5600",
                          size: 5,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "From an SEO perspective this is a",
                            }),
                            new TextRun({
                              text: " fully written page draft. ",
                              bold: true,
                            }),
                            new TextRun({
                              text: "The page sections, word count and placement of words have all been",
                            }),
                            new TextRun({
                              text: " optimized for search engines. ",
                              bold: true,
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Whether this is an",
                            }),
                            new TextRun({
                              text: " AI generated draft ",
                              bold: true,
                            }),
                            new TextRun({
                              text: "or",
                            }),
                            new TextRun({
                              text: " Human edited draft",
                              bold: true,
                            }),
                            new TextRun({
                              text: ", (you can confirm by checking the table below in ‘Level of content’) you still need to review and potentially edit the content to ensure it is factually correct, on brand and meets your own content standards.",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 120,
                            after: 120,
                          },
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Do keep in mind ",
                              bold: true,
                            }),
                            new TextRun({
                              text: "that if this is Supporting Page content, it is not intended to be super high value, it's intended to meet the needs of search engines first and foremost. If a Supporting Page starts to perform very well it can then be upgraded to a Top Level page and benefit from getting its own Supporting Pages, (no need to worry about this now)",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Have the below content draft",
                            }),
                            new TextRun({
                              text: " reviewed ",
                              bold: true,
                            }),
                            new TextRun({
                              text: "and then publish it to the website.",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Don't forget to",
                            }),
                            new TextRun({
                              text: " interlink the page according to the Silo map ",
                              bold: true,
                            }),
                            new TextRun({
                              text: "included in the same folder as this document.",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "For a detailed",
                            }),
                            new TextRun({
                              text: " tutorial",
                              bold: true,
                            }),
                            new TextRun({
                              text: " on how the POP on-page process works ",
                            }),
                            new ExternalHyperlink({
                              children: [
                                new TextRun({
                                  text: "start here",
                                  style: "Hyperlink",
                                  bold: true,
                                  color: "#FF5600",
                                }),
                              ],
                              link: "https://academy.pageoptimizer.pro/lessons/introduction/",
                            }),
                            new TextRun({
                              text: ".",
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                          spacing: {
                            before: 240,
                          },
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "",
                      shading: {
                        fill: "#FEF7EF",
                      },
                      margins: {
                        top: convertInchesToTwip(0.15),
                        left: convertInchesToTwip(0.15),
                        bottom: convertInchesToTwip(0.15),
                        right: convertInchesToTwip(0.15),
                      },
                      borders: {
                        top: {
                          color: "#FF5600",
                          size: 5,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FF5600",
                          size: 5,
                        },
                      },
                    }),
                  ],
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              cellWidths: [10000, 3300],
            }),
            new Paragraph({ children: [new TextRun({ text: "  " })] }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Type of the Page",
                              bold: true,
                              size: 24,
                              color: "#FFFFFF",
                            }),
                          ],
                          spacing: {
                            before: 60,
                            after: 60,
                            afterAutoSpacing: true,
                          },
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FF5600",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: responseData.data.pageType,
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FEF7EF",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Level of content",
                              bold: true,
                              size: 24,
                              color: "#FFFFFF",
                            }),
                          ],
                          spacing: {
                            before: 60,
                            after: 60,
                            afterAutoSpacing: true,
                          },
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FF5600",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: edited
                                ? "Human edited draft"
                                : "AI generated draft",
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FEF7EF",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Main Keyword",
                              bold: true,
                              size: 24,
                              color: "#FFFFFF",
                            }),
                          ],
                          spacing: {
                            before: 60,
                            after: 60,
                            afterAutoSpacing: true,
                          },
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FF5600",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: responseData.data.mainKeyword,
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                      },
                      verticalAlign: "center",
                      shading: {
                        fill: "#FEF7EF",
                      },
                      margins: {
                        top: convertInchesToTwip(0.1),
                        bottom: convertInchesToTwip(0.1),
                        left: convertInchesToTwip(0.1),
                        right: convertInchesToTwip(0.1),
                      },
                      borders: {
                        top: {
                          color: "#FFFFFF",
                          size: 10,
                        },
                        bottom: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        left: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                        right: {
                          style: BorderStyle.SINGLE,
                          color: "#FFFFFF",
                          size: 10,
                        },
                      },
                    }),
                  ],
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              cellWidths: [7700, 3300],
            }),
          ],
        },
      ],
    });

    const parser = new DOMParser();
    const docHTML = parser.parseFromString(
      responseData.data.content,
      "text/html"
    );
    const convertedElements = [];

    const elements = docHTML.body.childNodes;
    elements.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        convertedElements.push(...convertElementToDocxRun(node)); // Ensure this function returns valid docx elements
      }
    });
    // Page Draft
    const draftTable = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "📝 Page Draft",
                      color: "#FFFFFF",
                      bold: true,
                      size: 38,
                    }),
                  ],
                  heading: HeadingLevel.HEADING_1,
                  alignment: AlignmentType.CENTER,
                  spacing: {
                    before: 240,
                    after: 240,
                    afterAutoSpacing: true,
                  },
                }),
              ],
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              verticalAlign: "center",
              shading: {
                fill: "#FF5600",
              },
              margins: {
                top: convertInchesToTwip(0.1),
                bottom: convertInchesToTwip(0.1),
                left: convertInchesToTwip(0.1),
                right: convertInchesToTwip(0.1),
              },
              borders: {
                top: {
                  color: "#FFFFFF",
                  size: 5,
                },
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "#FF5600",
                  size: 5,
                },
                left: {
                  style: BorderStyle.SINGLE,
                  color: "#FF5600",
                  size: 5,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "#FF5600",
                  size: 5,
                },
              },
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      cellWidths: [7700, 3300],
    });

    const htmlContentRow = new TableRow({
      children: [],
    });

    const htmlContentCell = new TableCell({
      children: [],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      shading: {
        fill: "#FEF7EF",
      },
      verticalAlign: "center",
      margins: {
        top: convertInchesToTwip(0.2),
        bottom: convertInchesToTwip(0.2),
        left: convertInchesToTwip(0.2),
        right: convertInchesToTwip(0.2),
      },
      borders: {
        top: {
          style: BorderStyle.SINGLE,
          color: "#FF5600",
          size: 5,
        },
        bottom: {
          style: BorderStyle.SINGLE,
          color: "#FF5600",
          size: 5,
        },
        left: {
          style: BorderStyle.SINGLE,
          color: "#FF5600",
          size: 5,
        },
        right: {
          style: BorderStyle.SINGLE,
          color: "#FF5600",
          size: 5,
        },
      },
    });

    responseData.data.content.forEach((element) => {
      formatWordContent(element, htmlContentCell);
    });
    htmlContentRow.addChildElement(htmlContentCell);
    draftTable.addChildElement(htmlContentRow);
    doc.addSection({ children: [draftTable] });
    const pageType = responseData.data.pageType.toLowerCase().replace(" ", "_");
    const timestamp = moment().format("YYYY_M_D_h_mm_ss");
    const fileName = `${pageType}_${reportType}_keyword_${responseData.data.mainKeyword.replace(
      " ",
      "_"
    )}_${timestamp}.docx`;
    downloadWordFile(doc, fileName);
  };
  let reportType = "ai_draft";
  if (sourceType == ReportTypes.AI_DRAFT) {
    reportType = "ai_draft";
  } else if (sourceType == ReportTypes.HUMAN_DRAFT) {
    reportType = "human_draft";
  } else {
    reportType = "outline";
  }
  if (reportingFormatType === "pdf") {
    getPDFReport(siloKeywordId, reportType, sourceType, secretKey);
  } else {
    getWordReportData(siloKeywordId, reportType, sourceType, secretKey);
  }
};

export const generateSchemasWord = (responseData) => {
  // Create a new document
  const doc = new Document({
    styles: { default: { document: { run: { font: "Arial" } } } },
    sections: [
      {
        children: [
          // First Table: One row, two columns
          new Paragraph({
            children: [
              new ImageRun({
                data: responseData.headerImg,
                transformation: {
                  width: 600,
                  height: 230,
                },
              }),
            ],
            alignment: AlignmentType.CENTER,
            spacing: {
              line: 240,
              after: 480,
            },
          }),
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "💡 Add Schema to this page!",
                            color: "#FFFFFF",
                            bold: true,
                            size: 38,
                          }),
                        ],
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                        spacing: {
                          before: 120,
                          after: 120,
                          afterAutoSpacing: true,
                        },
                      }),
                    ],
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    verticalAlign: "center",
                    shading: {
                      fill: "#FF5600",
                    },
                    margins: {
                      top: convertInchesToTwip(0.1),
                      bottom: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.2),
                      right: convertInchesToTwip(0.2),
                    },
                    borders: {
                      top: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5600",
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5600",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5600",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5600",
                        size: 5,
                      },
                    },
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "POP analyzes the schema used on top competing pages.",
                          }),
                        ],
                        spacing: {
                          before: 240,
                        },
                        alignment: AlignmentType.CENTER,
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "We then filter and build the optimal custom schema for you to add to the page.",
                          }),
                        ],
                        spacing: {
                          before: 240,
                        },
                        alignment: AlignmentType.CENTER,
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "For instructions on how to publish schema to your page, ",
                          }),
                          new ExternalHyperlink({
                            children: [
                              new TextRun({
                                text: "click here",
                                style: "Hyperlink",
                                bold: true,
                                color: "#FF5600",
                              }),
                            ],
                            link: "https://help.pageoptimizer.pro/article/what-eeat-signals-look-like",
                          }),
                        ],
                        spacing: {
                          before: 240,
                        },
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    verticalAlign: "",
                    shading: {
                      fill: "#FEF7EF",
                    },
                    margins: {
                      top: convertInchesToTwip(0.15),
                      left: convertInchesToTwip(0.15),
                      bottom: convertInchesToTwip(0.15),
                      right: convertInchesToTwip(0.15),
                    },
                    borders: {
                      top: {
                        color: "#FF5600",
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5600",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5600",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5600",
                        size: 5,
                      },
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            columnWidths: [9000, 3300],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: " ",
              }),
            ],
          }),
          new Table({
            rows: [
              ...responseData.data.schemas
                .map((schema, index) => {
                  return [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${schema.type} Schema`,
                                  color: "#000000",
                                  bold: true,
                                  size: 36,
                                }),
                              ],
                              spacing: {
                                before: 60,
                                after: 60,
                                afterAutoSpacing: true,
                              },
                              heading: HeadingLevel.HEADING_1,
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: schema.schema,
                                  color: "#000000",
                                  size: 22,
                                }),
                              ],
                              spacing: {
                                before: 120,
                                after: 60,
                                afterAutoSpacing: true,
                              },
                            }),
                          ],
                          width: {
                            size: 100,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FEF7EF",
                          },
                          margins: {
                            top: convertInchesToTwip(0.2),
                            bottom: convertInchesToTwip(0.2),
                            left: convertInchesToTwip(0.4),
                            right: convertInchesToTwip(0.4),
                          },
                          borders: {
                            top: {
                              style:
                                index === 0
                                  ? BorderStyle.SINGLE
                                  : BorderStyle.NONE,
                              color: "#FF5600",
                              size: 5,
                            },
                            bottom: {
                              style:
                                index + 1 === responseData.data.schemas.length
                                  ? BorderStyle.SINGLE
                                  : BorderStyle.NONE,
                              color: "#FF5600",
                              size: 5,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5600",
                              size: 5,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5600",
                              size: 5,
                            },
                          },
                        }),
                      ],
                    }),
                  ];
                })
                .flat(),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            columnWidths: [7700, 3300],
          }),
        ],
      },
    ],
  });

  const pageType = responseData.data.pageType.toLowerCase().replace(" ", "_");
  const timestamp = moment().format("YYYY_M_D_h_mm_ss");
  const fileName = `${pageType}_schemas_keyword_${responseData.data.mainKeyword.replace(
    " ",
    "_"
  )}_${timestamp}.docx`;
  downloadWordFile(doc, fileName);
};

export const generateSchemasJSON = (responseData) => {
  const formatted = responseData.data.schemas.map((schema) => ({
    "Schema Name": schema.type,
    data: JSON.parse(schema.schema),
  }));
  const blob = new Blob([JSON.stringify(formatted, null, 4)], {
    type: "application/json",
  }); // Indentation of 2 spaces
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${responseData.data.report_title}_.json`;
  a.click();
  URL.revokeObjectURL(url);
};


export const getReportHeaderImage = (store, reportType) => {
  store.commit("showLoading");
  return store
    .dispatch("getReportHeader", { reportType: reportType })
    .then((response) => {
      return response.data;
    })
    .finally(() => {
      store.commit("hideLoading");
    });
};


export const generateEEATWordReport = (
  headerImg, 
  siteIcon, 
  addIcon, 
  presentIcon, 
  targetUrl, 
  data,
  descriptionObj
) => {
  const doc = new Document({
    styles: { default: { document: { run: { font: "Arial" } } } },
    sections: [
      {
        children: [
          new Paragraph({
            children: [
              new ImageRun({
                data: headerImg,
                transformation: {
                  width: 600,
                  height: 230,
                },
              }),
            ],
            alignment: AlignmentType.CENTER,
            spacing: {
              line: 240,
              after: 480,
            },
          }),

          // Second Table: One row, one column, with bullet points
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "💡 Instructions",
                            color: "#FFFFFF",
                            bold: true,
                            size: 38,
                          }),
                        ],
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                        spacing: {
                          before: 120,
                          after: 120,
                          afterAutoSpacing: true,
                        },
                      }),
                    ],
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    verticalAlign: "center",
                    shading: {
                      fill: "#FF5700",
                    },
                    margins: {
                      top: convertInchesToTwip(0.1),
                      bottom: convertInchesToTwip(0.1),
                      left: convertInchesToTwip(0.2),
                      right: convertInchesToTwip(0.2),
                    },
                    borders: {
                      top: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                    },
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "While ticking off Google’s EEAT items (as addressed in",
                          }),
                          new TextRun({
                            text: " Google’s Quality Rater Guidelines",
                            bold: true,
                            color: "#FF5700"
                          }),
                          new TextRun({
                            text: ") on your website may not directly benefit rankings, by missing too many of these signals you ",
                          }),
                          new TextRun({
                            text: "risk being ignored by Google",
                            bold: true
                          }),
                          new TextRun({
                            text: ", which will obviously affect your ability to generate SEO traffic.",
                          }),
                        ],
                        bullet: {
                          level: 0,
                        },
                        spacing: {
                          before: 240,
                        },
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "In essence, Google is trying to decipher whether you are a",
                          }),
                          new TextRun({
                            text: " real business ",
                            bold: true,
                          }),
                          new TextRun({
                            text: ", whether you are who you say you are and that you can be trusted to deliver what you say you are going to deliver.",
                          }),
                        ],
                        bullet: {
                          level: 0,
                        },
                        spacing: {
                          before: 240,
                        },
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "We have found the EEAT signals below on",
                          }),
                          new TextRun({
                            text: " your top competitor’s ",
                            bold: true,
                          }),
                          new TextRun({
                            text: "websites and have tagged them as being either present or missing from your own website.",
                          }),
                        ],
                        bullet: {
                          level: 0,
                        },
                        spacing: {
                          before: 240,
                        },
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "We have given a description of each signal and your job is to try and",
                          }),
                          new TextRun({
                            text: " implement ",
                            bold: true,
                          }),
                          new TextRun({
                            text: "as many of the missing signals as possible. Most of them are very easy and can be knocked off in a matter of minutes.",
                          }),
                          new TextRun({
                            text: ".",
                          }),
                        ],
                        bullet: {
                          level: 0,
                        },
                        spacing: {
                          before: 240,
                        },
                      }),
                    ],
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    verticalAlign: "",
                    shading: {
                      fill: "#FEF7EF",
                    },
                    margins: {
                      top: convertInchesToTwip(0.15),
                      left: convertInchesToTwip(0.15),
                      bottom: convertInchesToTwip(0.15),
                      right: convertInchesToTwip(0.15),
                    },
                    borders: {
                      top: {
                        color: "#FF5700",
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            columnWidths: [9000, 3300],
          }),
          new Paragraph({ children: [new TextRun({ text: "  " })] }),
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            font: "Arial",
                            text: "Your site's EEAT audit",
                            size: "20pt",
                            bold: true
                          })
                        ],
                        spacing: {
                          before: 60,
                          after: 120,
                        }
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: " "
                          })
                        ]
                      }),
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new ExternalHyperlink({
                            children: [
                              new ImageRun({
                                data: siteIcon,
                                transformation: {
                                  width: 10,
                                  height: 10,
                                },
                              }),
                              new TextRun({
                                text: " "
                              }),
                              new TextRun({
                                font: "Arial",
                                text: targetUrl,
                                style: "Hyperlink",
                                bold: true,
                                color: "#FF5700"
                              })
                            ],
                            link: targetUrl
                          })
                        ],
                        spacing: {
                          before: 60,
                          after: 120,
                        }
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: " "
                          })
                        ]
                      }),
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            font: "Arial",
                            text: "We have found these EEAT signals on your top competitors' sites. The ones marked ‘Add’ have not been found on your site and as many as possible should be added before you begin publishing new content.",
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: " "
                          })
                        ]
                      }),
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            font: 'Arial',
                            text: 'For instructions on what each of the EEAT signals looks like on the page, ',
                          }),
                          new ExternalHyperlink({
                            children: [
                              new TextRun({
                                font: 'Arial',
                                text: 'click here',
                                style: 'Hyperlink',
                                color: "#FF5700"
                              }),
                            ],
                            link: 'https://help.pageoptimizer.pro/article/what-eeat-signals-look-like',
                          }),
                        ],
                        spacing: {
                          before: 120,
                          after: 120,
                        }
                      })
                    ],
                    verticalAlign: 'center',
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    shading: {
                      fill: "#FEF7EF",
                    },
                    margins: {
                      top: convertInchesToTwip(0.20),
                      left: convertInchesToTwip(0.20),
                      bottom: convertInchesToTwip(0.20),
                      right: convertInchesToTwip(0.20),
                    },
                    borders: {
                      top: {
                        color: "#FF5700",
                        style: BorderStyle.SINGLE,
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                    }
                  }),
                  
                ],
              }),
            ],
            columnWidths: [11000],
          }),

          new Paragraph({
            children: [
              new PageBreak()
            ]
          }),

          // Third Table: 3 columns, 3 rows
          new Table({
            rows: [
              // Header row
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({ font: "Arial", text: "Name", bold: true, color: "#FFFFFF" }),
                        ],
                      }),
                    ],
                    width: { size: 15, type: "pct" },
                    shading: {
                      fill: "#FF5700"
                    },
                    borders: {
                      top: {
                        color: "#FF5700",
                        style: BorderStyle.SINGLE,
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FFFFFF",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                    },
                    margins: {
                      top: convertInchesToTwip(0.10),
                      left: convertInchesToTwip(0.10),
                      bottom: convertInchesToTwip(0.10),
                      right: convertInchesToTwip(0.10),
                    },
                    verticalAlign: "center",
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({ font: "Arial", text: "Description", bold: true, color: "#FFFFFF" }),
                        ],
                      }),
                    ],
                    width: { size: 70, type: "pct" },
                    shading: {
                      fill: "#FF5700"
                    },
                    borders: {
                      top: {
                        color: "#FF5700",
                        style: BorderStyle.SINGLE,
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FFFFFF",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                    },
                    margins: {
                      top: convertInchesToTwip(0.10),
                      left: convertInchesToTwip(0.10),
                      bottom: convertInchesToTwip(0.10),
                      right: convertInchesToTwip(0.10),
                    },
                    verticalAlign: "center",
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({ font: "Arial", text: "Status", bold: true, color: "#FFFFFF" }),
                        ],
                      }),
                    ],
                    width: { size: 15, type: "pct" },
                    shading: {
                      fill: "#FF5700"
                    },
                    borders: {
                      top: {
                        color: "#FF5700",
                        style: BorderStyle.SINGLE,
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FFFFFF",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                    },
                    margins: {
                      top: convertInchesToTwip(0.10),
                      left: convertInchesToTwip(0.10),
                      bottom: convertInchesToTwip(0.10),
                      right: convertInchesToTwip(0.10),
                    },
                    verticalAlign: "center",
                  }),
                ],
              }),

              ...data.map((row) => {
                return row.signal && (new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              font: "Arial",
                              text: row.signal,
                              bold: true,
                              color: "#FFFFFF"
                            }),
                          ],
                        }),
                      ],
                      shading: {
                        fill: "#FF5700"
                      },
                      verticalAlign: 'center',
                      margins: {
                        top: convertInchesToTwip(0.10),
                        left: convertInchesToTwip(0.10),
                        bottom: convertInchesToTwip(0.10),
                        right: convertInchesToTwip(0.10),
                      },
                      borders: {
                        top: {
                        color: "#FFFFFF",
                        style: BorderStyle.SINGLE,
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FFFFFF",
                        size: 10,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FFFFFF",
                        size: 10,
                      },
                      }
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          font: "Arial",
                          text: descriptionObj?.[row.signal] ? descriptionObj?.[row.signal] + "*" : '',
                        }),
                      ],
                      shading: {
                        fill: "#FFEDDD"
                      },
                      margins: {
                        top: convertInchesToTwip(0.10),
                        left: convertInchesToTwip(0.10),
                        bottom: convertInchesToTwip(0.10),
                        right: convertInchesToTwip(0.10),
                      },
                      borders: {
                        top: {
                        color: "#FF5700",
                        style: BorderStyle.SINGLE,
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      }
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          font: "Arial",
                          children: [
                            new ImageRun({
                              data: row.result ? presentIcon : addIcon,
                              transformation: {
                                width: row.result ? 80 : 60,
                                height: 25,
                              },
                            }),
                          ],
                        }),
                      ],
                      verticalAlign: 'center',
                      shading: {
                        fill: row.result ? "#D7F4E8" : "#FFC4C4"
                      },
                      margins: {
                        top: convertInchesToTwip(0.10),
                        left: convertInchesToTwip(0.10),
                        bottom: convertInchesToTwip(0.10),
                        right: convertInchesToTwip(0.10),
                      },
                      borders: {
                        top: {
                        color: "#FF5700",
                        style: BorderStyle.SINGLE,
                        size: 5,
                      },
                      bottom: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      left: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      right: {
                        style: BorderStyle.SINGLE,
                        color: "#FF5700",
                        size: 5,
                      },
                      }
                    }),
                  ],
                }))
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            columnWidths: [2200, 6600, 2200],
          }),

          new Paragraph({
            text: "",
            heading: HeadingLevel.HEADING_2,
          }),

          new Paragraph({
            children: [
              new TextRun({
                size: 18,
                font: "Arial",
                text: "*Usually, the signal is detected in these elements. We recommend incorporating them accordingly. However, our AI can identify these signals even if they are implemented unconventionally.",
                italics: true,
              }),
            ],
          }),
        ],
      },
    ],
  });
  const timestamp = moment().format('YYYY_M_D_h_mm_ss');
  const fileName = `google_eeat_${timestamp}.docx`;
  downloadWordFile(doc, fileName)
}