<template>

  <div class="box">

    <img class="mb-6" src="/static/silo-order-summary-icon.svg" />

    <div class="card-big-title mb-8">Your content reports order</div>
  
    <div v-if="isMissionControlSession && silo.content_report_order.order_status === 'paid'">
      <div class="flex" style="gap: 4px; align-items: center;">
        <strong>Set content reports order status</strong>

        <b-dropdown aria-role="list">
          <template #trigger>
            <b-button
              :label="getFulfillementStatusLabel(silo.content_report_order.fulfillement_status)"
              icon-right="angle-down" />
          </template>
          <b-dropdown-item v-if="silo.content_report_order.fulfillement_status !== 'pending'" aria-role="listitem" @click="updateOrder({set_pending: true})">{{ getFulfillementStatusLabel('pending')}}</b-dropdown-item>
          <b-dropdown-item v-if="silo.content_report_order.fulfillement_status !== 'in_progress'" aria-role="listitem" @click="updateOrder({set_in_progress: true})">{{ getFulfillementStatusLabel('in_progress')}}</b-dropdown-item>
          <b-dropdown-item v-if="silo.content_report_order.fulfillement_status !== 'completed'" aria-role="listitem" @click="updateOrder({set_completed: true})">{{ getFulfillementStatusLabel('completed')}}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div v-else>
      Your content reports order status: <strong>{{ getFulfillementStatusLabel(silo.content_report_order.fulfillement_status) }}</strong>
    </div>

    <div>Delivery period start date: <strong>{{ silo.content_report_order.delivery_period_starts_at }}</strong></div>
    <div>Delivery period: <strong>{{ silo.content_report_order.delivery_period_days }} days</strong></div>
    <div>Delivery due date: <strong>{{ silo.content_report_order.due_date }}</strong></div>
  </div>

</template>

<script>
import { getPureServicePkgName } from '@/utils';
import {
  whiteGlovePlans,
} from "@/components/plans_constants"
import { getFulfillementStatusLabel } from './utils'


export default {
  props: ['silo', 'isMissionControlSession', 'refreshData'],
  components: {

  },
  data() {
    return {

    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isWhiteGloveUser () {
      if (!this.user) return false;
      if (whiteGlovePlans.includes(getPureServicePkgName(this.user.accountType))) {
        return true;
      }
      return false;
    },
    AVAILAIBLE_TOP_LEVEL_SILO_REPORTS() {
      return this.packages.filter(p => p.type === 'top_level');
    },
    AVAILAIBLE_SUPPORTING_SILO_REPORTS() {
      return this.packages.filter(p => p.type === 'supporting');
    },
    selectedTopLevelOption() {
      return this.AVAILAIBLE_TOP_LEVEL_SILO_REPORTS.filter(p => p.key === this.silo.content_report_order.selected_top_level_option_key)[0];
    },
    selectedSupportingOption() {
      return this.AVAILAIBLE_SUPPORTING_SILO_REPORTS.filter(p => p.key === this.silo.content_report_order.selected_supporting_option_key)[0];
    },
    topLevelKeyword() {
      return this.keywords.filter(keyword => keyword.keywordData.isTopLevelKeyword)[0];
    },
    supportingKeywords() {
      return this.keywords.filter(keyword => keyword.keywordData.isSupportingKeyword);
    },
  },
  methods: {
    getFulfillementStatusLabel: getFulfillementStatusLabel,
    getSiloUrl(siloKeyword) {
      return siloKeyword.keywordData.page || siloKeyword.keywordData.currentRankingUrl;
    },
    updateOrder(payload) {
      this.$store.commit('showLoading');
      this.$store.dispatch('updateSiloContentReportOrder', {
        siloId: this.silo.id,
        body: payload
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.refreshData()
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
  },
  mounted () {
    
  }
};
</script>

<style lang="scss" scoped>

</style>
