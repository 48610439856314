<template>
  <div>
    <div class="modal" :class="{ 'is-active': !!popUpSilo }">
      <div class="modal-background" @click="ModalClose"></div>
      <div class="modal-content">

        <div class="box modal-container">
          <div class="p-5 mt-5 mb-5">
            <div class="select-a-setup-type">
              Add Keywords to Silo
            </div>
            <button class="custom-modal-close-btn" aria-label="close" @click="ModalClose">
              <i class="material-icons">close</i>
            </button>
            <br/>
            <div>
              <div v-if="!newSilo">
                <v-autocomplete 
                  outlined 
                  dense 
                  hide-details
                  :items="silos"
                  :item-text="item => item.name"
                  :item-value="item => item.id"
                  v-model="selectedSilo"
                  placeholder="Select silo"
                  @input="value => siloSearch = value"
                  class="custom-autocomplete"
                  color="grayColor">
                </v-autocomplete>
              </div>
              <div v-else>
                <label>
                  Create a new Silo
                </label>
                <v-text-field
                  v-model="newSiloName"
                  outlined
                  dense
                  hide-details
                  label="Type your new silo's name"
                  placeholder="Type your new silo's name"
                  color="grayColor"
                ></v-text-field>
              </div>
              <br/>
              <div class="row p-2">
                <div
                  class="flex gap2 align-center cursor-pointer"
                  style="margin-left: 8px; vertical-align: middle;"
                  @click="openSiloModal"
                  :disabled="newSilo"
                  :style="{ 'opacity': newSilo ? 0 : 1 }"
                >
                  <img
                    src="/static/add_orange.svg"
                    style="
                      background-color: transparent;
                      width: auto;
                      height: auto;
                      margin-left: -4px;
                    "
                  />
                  <span
                    class="primary-text"
                    style="
                      margin-left: 6px;
                      color: var(--v-profileAnchorColor-base);
                      font-size: 0.85rem;
                      font-weight: 700;
                    "
                    >Create new silo</span
                  >
                </div>
                <div
                  style="vertical-align: middle;"
                >
                  <v-btn 
                    medium
                    @click="ModalClose" 
                    rounded
                    class="primary-text mainColor--text text-none mx-1"
                    style="
                      background-color: var(--v-whiteColor-base); 
                      border: 1px solid var(--v-mainColor-base);
                    "
                  >
                    <b>Cancel</b>
                  </v-btn>
                  <v-btn 
                    medium 
                    @click="onSubmit" 
                    rounded
                    style="vertical-align: middle;"
                    color="profileAnchorColor" 
                    class="primary-text whiteColor--text text-none"
                  >
                    <b>Add to Silo</b>
                  </v-btn>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popUpSilo', 'silos', 'keywords'],
  data() {
    return {
      newSiloName: '',
      isFormSubmit: false,
      selectedSilo: null,
      newSilo: false,
    };
  },
  methods: {
    onSubmit() {
      if (this.newSilo) {
      this.isFormSubmit = true
        if (!this.newSiloName) return

        this.$store.commit('showLoading')
        this.$store.dispatch('createSilo', { campaignId: this.$route.params.campaignId, name: this.newSiloName }).then((silo) => {
          this.$emit("closeModal");
          this.$store.commit('setIsNewSiloUpdates', true);
          this.$emit("bulkAddKeywordToSilo", silo.data.data.id, true, false);
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully created silo.'
          });
        }).finally(() => this.$store.commit('hideLoading'));
        this.newSilo = false;
        this.selectedSilo = null;
        this.newSiloName = "";
      } else {
        this.$emit("bulkAddKeywordToSilo", this.selectedSilo, true, true);
      }
    },
    ModalClose() {
      if (this.newSilo) {
        this.newSiloName = ''
        this.newSilo = false;
      } else {
        this.selectedSilo = null
        this.$emit("closeModal");
      }
    },
    openSiloModal() {
      this.newSilo = true
    }
  },
  computed: {
    isError() {
      return this.isFormSubmit && !this.newSiloName;
    },
  },
  watch: {
    popUpSilo: function () {
      this.newSiloName = ''
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  width: 425px;
  max-width: 425px;
}

.modal-container {
  padding: 0 !important;
}

.material-icons {
  margin-top: -10px;
  color: var(--v-mainColor-base);
}

.back-button-text {
  border: 1px solid var(--v-mainColor-base);
  height: 38px !important;
  background: none !important;

  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }
}

.continue-button-text {
  height: 38px !important;

  i,
  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }

  i {
    margin-top: 2px;
  }
}

.select-a-setup-type {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
  color: var(--v-mainColor-base);
}

.continue-button-area {
  margin-top: 25px;
  text-align: right;
}
.bg-white {
  background-color: var(--v-whiteColor-base);
}

.custom-autocomplete .v-menu__content {
  max-height: 200px; /* Set the maximum height for the dropdown */
  overflow-y: auto;   /* Enable scrolling if the content exceeds the max height */
}

</style>
