<template>
<div v-if="task" class="task-status-root">
  <div :id="`dot-menu-task-status-${task.checklistId}`"
    :class="{
      'task-status': true,
      'not-started': task.isCompleted == 0,
      'completed': task.isCompleted == 1,
      'working': task.isCompleted == 2,
      'review': task.isCompleted == 3,
    }">
      {{ taskStatusText }}
  </div>
  <v-menu 
    :activator="`#dot-menu-task-status-${task.checklistId}`"
    bottom offset-y>
    <v-list class="pa-0">
      <v-list-item class="not-started" link @click="updateChecklistState(0)">
        <v-list-item-title class="whiteColor--text">
          Not started
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="completed" link @click="updateChecklistState(1)">
        <v-list-item-title class="whiteColor--text">
          Completed
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="working" link @click="updateChecklistState(2)">
        <v-list-item-title class="whiteColor--text">
          Working on it
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="review" link @click="updateChecklistState(3)">
        <v-list-item-title class="whiteColor--text">
          In review
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</div>
</template>

<script>
export default {
  props: ['task'],
  data () {
    return {

    }
  },
  computed: {
    taskStatusText () {
      if (!this.task) {
        return ''
      }
      let taskStatusText = {
        0: 'Not started',
        1: 'Completed',
        2: 'Working on it',
        3: 'In review'
      }
      return taskStatusText[this.task.isCompleted] || ''
    }
  },
  methods: {
    updateChecklistState (status) {
      // needs some work for auto checklist here with the variable row

      if (status == this.task.isCompleted) {
        return;
      }

      let checklistId = this.task.checklistId

      let data = {}

      if (!checklistId) {
        console.log('Page Id', this.pageId)
        data = {
          type: this.task.type,
          dispType: this.task.dispType,
          recommendationType: this.task.recommendationType,
          pageId: this.pageId,
          signal: this.task.signal
        }
      }

      data = {
        ...data,
        id: checklistId,
        isCompleted: status
      }
      this.$store.commit('showLoading')
      return this.$store.dispatch('updateChecklistState', data)
        .then(response => {
          this.$store.commit('hideLoading')
          if (response.data && response.data.status === 'SUCCESS') {
            this.$emit('statusChanged')
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          console.log('finished update state for task')
        })
    },
  }
}
</script>

<style scoped lang="scss">
.task-status-root {
  display: inline-block;
  color: var(--v-whiteColor-base);
  font-weight: 700;
  & .task-status {
    padding: 3px 5px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
  }
}
.not-started {
  background: var(--v-red3Color-base);
  color: var(--v-whiteColor-base);
}
.completed {
  background: var(--v-green7Color-base);
}
.working {
  background: var(--v-link3Color-base);
}
.review {
  background: var(--v-yellow11Color-base);
}
</style>