<template>
  <div>
    <!-- First Step Modal  -->
    <div class="modal" :class="{ 'is-active': showPopup }">
      <div
        class="modal-background"
        @click="dismissPickingReportTypeModalAndGoBack"
      ></div>
      <div class="modal-content" v-if="!childModalOpen">
        <div class="box modal-container">
          <div class="modal-content-root">
            <div class="select-a-setup-type">AI Writer report</div>
            <div class="setup-type-explain mt-4">
              Choose how you want to create a new AI Writer report.
            </div>
            <div class="setup-type-boxes mt-5">
              <div
                class="full-height has-text-centered flex flex-column"
                :class="{ active: !isExpressReport }"
                @click="setReportType(false)"
              >
                <div>
                  <img src="/static/select-report-icon.svg" />
                </div>
                <div class="setup-type-name">Select an existing project</div>
                <div class="flex-grow-1 type-explain">
                  <div>
                    Create a new AI Writer report for one of your existing
                    projects
                  </div>
                </div>
              </div>
              <div
                class="full-height has-text-centered flex flex-column"
                :class="{ active: isExpressReport }"
                @click="setReportType(true)"
              >
                <div>
                  <img src="/static/add-report-icon.svg" />
                </div>
                <div class="setup-type-name">Create a new report</div>
                <div class="flex-grow-1 type-explain">
                  <div>Create a new AI Writer report from scratch</div>
                </div>
              </div>
            </div>
            <div class="continue-button-area">
              <v-btn
                rounded
                color="profileAnchorColor"
                class="button-with-icon continue-button-text whiteColor--text text-none"
                @click="
                  () => {
                    goToNextStepAndCheckSelection();
                    openChildModal();
                  }
                "
              >
                <span>Continue</span>
                <i class="material-icons">arrow_forward</i>
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="dismissPickingReportTypeModalAndGoBack"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <!-- Create New Report Modal Form -->
    <ai-create-report
      @on-create-page="onCreatePage"
      :createReportPop="createReportPop"
      @close-modal="closeAiCreateReport"
      :targetLanguages="targetLanguages"
      @mainModalCLose="dismissPickingReportTypeModalAndGoBack"
    />

    <!-- Choose Existing Report Modal -->
    <ai-existing-report
      :existingReportPop="existingReportPop"
      @close-modal="closeAiExistingReport"
      @mainModalCLose="dismissPickingReportTypeModalAndGoBack"
    />
  </div>
</template>

<script>
import AiCreateReport from "./AiCreateReport.vue";
import AiExistingReport from "./AiExistingReport.vue";
import { mixin } from "@/components/page_analysis_mixin";
import { isInSameDomain, getPathNameFromUrl } from "@/utils";
import { capitalize } from 'lodash';

export default {
  components: { AiCreateReport, AiExistingReport: AiExistingReport },
  mixins: [mixin],
  data() {
    return {
      showPopup: false,


      name: "",
      targetUrl: "",
      targetLang: "",
      pageNotBuilt: true,
      childModalOpen: false,
      submittingRun: false,
      isExpressReport: false,
      createReportPop: false,
      existingReportPop: false,
    };
  },
  computed: {
    targetLanguages () {
      return this.$store.state.targetLanguages.map(item => {
        return {
          text: capitalize(item),
          value: item,
        }
      })
    },
    googleSearchLocations () {
      return this.$store.state.googleSearchLocations ? this.$store.state.googleSearchLocations : []
    }
  },
  methods: {
    showAiReportPopup () {
      this.showPopup = true;
    },
    closeAiReportModal () {
      this.showPopup = false;
    },
    onCreatePage(url, keyword, location, lang) {
      this.proxyLocation = location;
      this.keyword = keyword;
      this.targetUrl = url;
      this.targetLang = lang;
      this.name = keyword;
      this.submitForOnePageSetup();
      this.$store.commit("setAiWriterLoading", true);
      return;
    },
    submitData() {
      this.$store.commit("setAiWriterLoading", false);
    },
    closeAiCreateReport() {
      this.createReportPop = false;
      this.childModalOpen = false;
    },
    closeAiExistingReport() {
      this.existingReportPop = false;
      this.childModalOpen = false;
    },
    openChildModal(){
      this.childModalOpen = true;
    },
    dismissPickingReportTypeModalAndGoBack() {
      this.closeAiReportModal();
      if (this.existingReportPop) {
        this.existingReportPop = false;
      } else if (this.createReportPop) {
        this.createReportPop = false;
      }
    },
    setReportType(isExpress) {
      this.isExpressReport = isExpress;
    },
    goToNextStepAndCheckSelection() {
      if (this.isExpressReport) {
        this.createReportPop = true;
      } else {
        this.existingReportPop = true;
      }
    },
  },
  watch: {
    targetUrl: function (newUrl) {
      let targetUrl = newUrl.trim();
      if (isInSameDomain(targetUrl, this.cleanBaseUrl)) {
        targetUrl = getPathNameFromUrl(targetUrl);
      }
      targetUrl = targetUrl.replace(/\s/g, ""); // remove all spaces from string
      if (targetUrl == "/") {
        targetUrl = "";
      }
      if (this.targetUrl == targetUrl) {
        return;
      }
      this.targetUrl = targetUrl;
      this.urlsValidated = false;
    },
    createReportPop: {
      handler(newVal){
        if(!newVal){
          this.childModalOpen = false
        }
      }
    },
    existingReportPop: {
      handler(newVal){
        if(!newVal){
          this.childModalOpen = false
        }
      }
    },
    showPopup: {
      handler(newV, oldV){
        this.isExpressReport = false
      },
      immediate: true
    }
  },
  beforeMount () {
    try {
      this.$store.commit('setSubmittingGoogleSearch', false)
      this.$store.commit('setShowGoogleResultsModal', false)
      this.$store.commit('setGoogleSearchProgress', 1)
      if (!this.googleSearchLocations || !this.googleSearchLocations.length) {
        this.$store.dispatch('fetchGoogleSearchLocations')
      }
    } catch (e) {
      console.log('Error fetching proxies', e)
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  color: var(--v-mainColor-base);
}

.modal-content {
  width: 30vw;
}

ul {
  margin-left: 0;
}

.continue-button-text {
  height: 38px !important;

  i,
  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }

  i {
    margin-top: 2px;
  }
}

@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 700px;
  }
}

.select-a-setup-type {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
}

.setup-type-explain {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.setup-type-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > div {
    flex: 1 1 270px;
    border: 2px solid var(--v-grayColor-base);
    border-radius: 5px;
    padding: 20px 50px;
    cursor: pointer;

    &.active {
      border-color: var(--v-profileAnchorColor-base);
    }

    i.material-icons {
      color: var(--v-profileAnchorColor-base);
      font-size: 2rem;
    }

    .setup-type-name {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 800;
    }

    .type-explain {
      margin-top: 5px;
      font-size: 1rem;

      ul {
        padding-left: 0;
        margin-top: 10px;
        list-style: inside;
      }
    }
  }
}

.continue-button-area {
  margin-top: 25px;
  text-align: right;
}
</style>
