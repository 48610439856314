<template>
<div>
  <v-card class="is-fullheight flex flex-column white-common-box">
    <div class="pa-5 flex-grow-1 flex flex-column">
      <div class="title-font mainColor--text box-title-label">Manage my own GPT-4 access key</div>
      <div class="notify-label">
        <i class="material-icons">error_outline</i>
        Need help? Follow our&nbsp;
        <a href="https://help.pageoptimizer.pro/article/how-to-use-gpt-4-api-in-pop" target="_blank">
          How to use GPT-4 API in POP Guide.
        </a>
      </div>

      <v-text-field dense hide-details="auto"
        label="GPT key"
        color="greenColor"
        class="mt-5"
        v-model="currentAPIKey"
        outlined
      ></v-text-field>
      <div class="mt-5" v-if="!isExistingApiKeyChanged">
        <div class="d-flex align-center">
          <vue-toggles
            :value="useMyOwnAPI"
            width="40" height="20"
            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
            :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
            @click="() => toggleUseMyOwnAPI()"
            >
          </vue-toggles>
          <div class="ml-2 mainColor--text">
            {{ useMyOwnAPI ? 'Enabled': 'Disabled' }}
          </div>
        </div>
      </div>
      <div class="mt-5">
        <v-btn rounded
          color="profileAnchorColor"
          class="text-none whiteColor--text"
          :disabled="!isSomethingChanged"
          @click="saveOpenAiKey">
          <span>{{ !isExistingApiKeyChanged && user.openAIKey ? 'Save setting' : (user.openAIKey ? 'Update key' : 'Save key') }}</span>
        </v-btn>

        <v-btn rounded
          v-if="user.openAIKey"
          color="redColor"
          class="text-none whiteColor--text ml-3"
          @click="deleteOpenAiKey">
          <span>Delete Key</span>
        </v-btn>
      </div>
    </div>
  </v-card>


  <div class="modal" :class="{'is-active': showApiKeyErrorModal}">
    <div class="modal-background" @click="showApiKeyErrorModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="subtitle-font mainColor--text text-center">
            Sorry, it looks like the API key you are trying to add doesn't have GPT 4 access yet.  This is a common issue.  You'll need to go back into your OpenAI and select GPT 4 specifically.
            <div class="mt-5 subtitle mainColor--text font-weight-bold text-center">{{ errorMsg }}</div>
          </div>
          <div class="d-flex justify-end">
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none"
              @click="showApiKeyErrorModal = false">
              Ok
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showApiAddedSuccessfullyModal}">
    <div class="modal-background" @click="showApiAddedSuccessfullyModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="subtitle-font mainColor--text text-center">
          {{ successMsg }}
        </div>
        <div class="text-right">
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            @click="showApiAddedSuccessfullyModal = false">
            Ok
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      currentAPIKey: '',
      useMyOwnAPI: false,

      showApiKeyErrorModal: false,
      errorMsg: '',
      showApiAddedSuccessfullyModal: false,
      successMsg: '',
    }
  },
  computed:  {
    user () {
      try {
        return this.$store.state.user;
      } catch (e) {
        return null;
      }
    },
    isExistingApiKeyChanged () {
      if (!this.user || !this.user.openAIKey) {
        return false;
      }
      if (this.user.openAIKey != this.currentAPIKey) {
        return true;
      }
      return false;
    },
    isSomethingChanged () {
      if (!this.user.openAIKey && this.currentAPIKey) {
        return true;
      }
      return this.isExistingApiKeyChanged || this.useMyOwnAPI != this.user.useMyOwnApiKey
    }
  },
  methods: {
    updateDefaultValues(user) {
      this.currentAPIKey = user.openAIKey || ''
      this.useMyOwnAPI = user.useMyOwnApiKey
    },
    loadUser () {
      return this.$store.dispatch('loadUser')
      .then((response) => {
        if (response.data.user) {
          this.updateDefaultValues(response.data.user)
          this.$store.commit('setUser', response.data.user)
        }
      })
    },
    saveOpenAiKey () {
      this.$store.commit('showLoading')
      this.$store.dispatch('addOpenAiKey', {
        apiKey: this.currentAPIKey.trim(),
        useMyOwnAPI: this.useMyOwnAPI,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.successMsg = response.data.msg
          this.showApiAddedSuccessfullyModal = true;
          this.loadUser();
        } else if (response.data.status == 'FAILURE') {
          console.log('failure', response.data.error)
          this.errorMsg = response.data.error
          this.showApiKeyErrorModal = true
          this.loadUser();
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    deleteOpenAiKey () {
      this.$store.commit('showLoading')
      this.$store.dispatch('addOpenAiKey', {
        deleteKey: true,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.successMsg = response.data.msg
          this.showApiAddedSuccessfullyModal = true;
          this.loadUser();
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    toggleUseMyOwnAPI () {
      this.useMyOwnAPI = !this.useMyOwnAPI
    }
  },
  mounted () {
    if (this.user) {
      this.updateDefaultValues(this.user);
    }
  }
}
</script>

<style lang="scss">
.switch[type=checkbox]:checked + label::before, .switch[type=checkbox]:checked + label:before {
  background: var(--v-green7Color-base);
}
</style>