<template>
<div>
  <div class="d-flex align-center mt-3 mb-5">
    <div class="flex-grow-1 report-one-page-name gap10">
      <span>Dashboard</span>
      <!-- <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
        @click="() => {
          $store.commit('setCurrentVideoTutorialToDisplay', {
            title: 'Variations and LSI in POP',
            videoLink: 'https://www.youtube.com/embed/ZDE3lU50gMI?si=3dpddTelv2Ahifud',
            description: 'Understand what LSI and Variations are.',
            time: '3m 03s'
          });
        }">
        videocam
      </span> -->
    </div>
  </div>

  <div class="columns">
    <div class="column is-3">
      <v-card class="is-fullheight d-flex flex-column white-common-box">
        <div class="pa-5">
          <div class="d-flex">
            <div class="label-section flex-grow-1">
              Report ID: <span>#{{ currentPageRun.id }}</span>
            </div>
            <div>
              <img src="/static/dot-3.png" style="width: 25px; height: auto; cursor: pointer;" :id="`dot-menu-download`" />
              <v-menu
                v-if="!isDemo"
                :activator="`#dot-menu-download`"
                bottom offset-y>
                <v-list>
                  <v-list-item link>
                    <v-list-item-title
                      tag="a"
                      :href="downloadUrlLink"
                      class="mainColor--text">
                      Download report
                    </v-list-item-title>
                  </v-list-item>
                  <template v-if="isAdmin || isUnlimitedUser">
                    <v-list-item link>
                      <v-list-item-title
                        v-clipboard:copy="currentPageRun.secretKey"
                        v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
                        v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })"
                        class="mainColor--text">
                        Copy secret key
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title
                        @click="createSecretKey"
                        class="mainColor--text">
                        {{ currentPageRun.secretKey ? 'Update Secret Key' : 'Create Secret Key' }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link v-if="currentPageRun.secretKey">
                      <v-list-item-title
                        v-clipboard:copy="uniqueUrlForEditor"
                        v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
                        v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })"
                        class="mainColor--text">
                        Copy Content Editor URL
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="mt-4 very-big-section-label">Details</div>
          <div class="mt-4 label-section">Main keyword</div>
          <div class="main-keyword-tag">{{ keyword }}</div>
          <div class="mt-4 flex">
            <div class="label-section">
              Language: <span>{{ currentPageRun.targetLanguage | capitalize }}</span>
            </div>
            <div style="min-width: 25px;"></div>
            <div class="label-section">
              Region: 
              <span v-if="isExtensionUsed">IP: {{currentPageRun.ipAddress}}</span>
              <span v-else-if="proxy.location">{{ currentPageRun.googleLocation ? currentPageRun.googleLocation : proxy.location }}</span>
            </div>
          </div>
          <div class="mt-4 label-section">Target URL</div>
          <div class="target-url-link">
            <a :href="targetURL" target="_blank">{{ targetURL }}</a>
          </div>
          <div class="mt-4 d-flex justify-space-between">
            <div>
              <a v-if="isDemo" class="grayColor--text download-checklist-button tooltip is-tooltip-top" data-tooltip="Download Checklist Options" slot="trigger">
                <i class="material-icons">browser_updated</i>
                <span>Download checklist</span>
              </a>
              <b-dropdown v-else aria-role="list">
                <a class="download-checklist-button tooltip is-tooltip-top" data-tooltip="Download Checklist Options" slot="trigger">
                  <i class="material-icons">browser_updated</i>
                  <span>Download checklist</span>
                </a>
                <b-dropdown-item @click="downloadExcelForChecklist(0, currentPageRun.pageId, currentPageRun.id)">Recommendations only</b-dropdown-item>
                <b-dropdown-item @click="downloadExcelForChecklist(1, currentPageRun.pageId, currentPageRun.id)">Recommendations and current status</b-dropdown-item>
                <b-dropdown-item @click="downloadExcelForChecklist(2, currentPageRun.pageId, currentPageRun.id)">Export all (with notes)</b-dropdown-item>
              </b-dropdown>
            </div>
            <div>
              <span v-if="isDemo"
                class="download-checklist-button grayColor--text">
                <i class="material-icons">edit</i>
                <span>My notes</span>
              </span>
              <span v-else-if="canInputNote"
                class="download-checklist-button" @click="showNoteModalAction">
                <i class="material-icons">edit</i>
                <span>My notes</span>
              </span>
            </div>
          </div>
          <div class="score-section">
            <div class="big-section-label">
              Optimization score
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  {{ dashboardType == 'contentBrief' ? `This score is based on the Content Brief. The goal is to get to 100%.` : 'This score is how optimized your page is relative to your selected strategy. This score tallies the recommendations' }}
                </div>
              </v-tooltip>
            </div>
            <div class="score-box-in-left">
              <div style="width: 70px; padding-top: 20px;">
                <vue-ellipse-progress
                  :progress="pageNotBuilt ? 0 : pageScore"
                  :half="true"
                  :angle="0"
                  :size="70"
                  :thickness="8"
                  :emptyThickness="8"
                  :color="pageScoreTintColor(pageScore)"
                  :legend-formatter="({ currentValue }) => {
                    return `<span class='mainColor--text font-weight-bold'>${ pageNotBuilt ? 'N/A' : currentValue.toFixed(2) }</span>`;
                  }"
                  >
                </vue-ellipse-progress>
              </div>
              <!-- <radial-progress-bar 
                :diameter="60"
                :completed-steps="pageScore"
                :total-steps="100"
                :strokeWidth="5"
                :percentage="180"

                :clockwise="false"
                :startColor="pageScoreTintColor(pageScore)"
                :stopColor="pageScoreTintColor(pageScore)"
                :innerStrokeColor="'#eeeeee'"
                strokeLinecap="square"
                >
                <div class="score-number" :style="{ 'color': pageScoreTintColor(pageScore) }">{{ pageScore | round(1) }}</div>
              </radial-progress-bar> -->
              <div class="explain">
                <template v-if="dashboardType == 'contentBrief'">
                  Improve this score by getting each section of the Content Brief into their target ranges.
                </template>
                <template v-else>
                  <b>{{ pageScoreExplainByStrategy }}</b>
                </template>
              </div>
            </div>
          </div>
          <div>
            <div class="big-section-label">Competitors</div>
            <div class="mt-4 competitors-tab">
              <div :class="{'is-active': showCompetitorsOrFocus}">
                <a class="" @click="showCompetitorsOrFocus=true">All competitors ({{ competitors.length }})</a>
              </div>
              <div :class="{'is-active': !showCompetitorsOrFocus}">
                <a class="" @click="showCompetitorsOrFocus=false">Focus competitors ({{ featuredCompetitors.length }})</a>
              </div>
            </div>
            <div v-if="showCompetitorsOrFocus">
              <div v-for="c in competitors" :key="c.id" class="competitor-item" :class="{'is-focus-competitor': featuredCompetitors.includes(c.url)}">
                <div v-if="c.name">{{ c.name }}</div>
                <a :href="c.url" target="_blank">{{ c.url }}</a>
              </div>
            </div>
            <div v-else>
              <div v-for="(c, idx) in featuredCompetitors" :key="idx" class="competitor-item is-focus-competitor">
                <a :href="c" target="_blank">{{ c }}</a>
              </div>          
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div class="column is-9">
      <v-card class="is-fullheight d-flex flex-column white-common-box">
        <div class="pa-5">
          <div class="very-big-section-label">Overview</div>          
          <div class="mt-4">
            <template v-if="!pageNotBuilt">
              <span class="selection-tag" :class="{'blue-active-tag': viewRecommendationType=='recommendation'}" @click="viewRecommendationType='recommendation'">Recommendations</span>
              <span class="selection-tag" :class="{'blue-active-tag': viewRecommendationType=='alt_recommendation'}" @click="viewRecommendationType='alt_recommendation'">Adj. Recommendations</span>
            </template>
            <span class="selection-tag" @click.stop="showUnderOverChartWithType1 = !showUnderOverChartWithType1">More Details</span>
          </div>
          <div class="columns is-desktop over-under-optimized-zone" v-if="!pageNotBuilt">
            <div class="column">
              <div class="title-label">
                EXACT KEYWORD OPTIMIZATION
              </div>
              <div v-if="viewRecommendationType=='recommendation'">
                <under-over-optimize-chart
                  v-if="showUnderOverChartWithType1"
                  :underOptimized="keywordCounts.optimizedKeyword.underOptimized"
                  :overOptimized="keywordCounts.optimizedKeyword.overOptimized"
                  :underOptimziedTooltipText="`You have ${keywordCounts.optimizedKeyword.underOptimized} under optimized signals for your exact keyword`"
                  :overOptimziedTooltipText="`You have ${keywordCounts.optimizedKeyword.overOptimized} over optimized signals for your exact keyword`"
                  >
                </under-over-optimize-chart>
                <under-over-optimize-chart-v2
                  v-else
                  :underOptimized="keywordCounts.optimizedKeyword.underOptimized"
                  :overOptimized="keywordCounts.optimizedKeyword.overOptimized"
                  :underOptimziedTooltipText="`You have ${keywordCounts.optimizedKeyword.underOptimized} under optimized signals for your exact keyword`"
                  :overOptimziedTooltipText="`You have ${keywordCounts.optimizedKeyword.overOptimized} over optimized signals for your exact keyword`"
                  >
                </under-over-optimize-chart-v2>
              </div>
              <div v-else>
                <under-over-optimize-chart
                  v-if="showUnderOverChartWithType1"
                  :underOptimized="adjKeywordCounts.optimizedKeyword.underOptimized"
                  :overOptimized="adjKeywordCounts.optimizedKeyword.overOptimized"
                  :underOptimziedTooltipText="`You have ${adjKeywordCounts.optimizedKeyword.underOptimized} under optimized signals for your exact keyword`"
                  :overOptimziedTooltipText="`You have ${adjKeywordCounts.optimizedKeyword.overOptimized} over optimized signals for your exact keyword`"
                  >
                </under-over-optimize-chart>
                <under-over-optimize-chart-v2
                  v-else
                  :underOptimized="adjKeywordCounts.optimizedKeyword.underOptimized"
                  :overOptimized="adjKeywordCounts.optimizedKeyword.overOptimized"
                  :underOptimziedTooltipText="`You have ${adjKeywordCounts.optimizedKeyword.underOptimized} under optimized signals for your exact keyword`"
                  :overOptimziedTooltipText="`You have ${adjKeywordCounts.optimizedKeyword.overOptimized} over optimized signals for your exact keyword`"
                  >
                </under-over-optimize-chart-v2>
              </div>
            </div>
            <div class="column">
              <div class="title-label">
                KEYWORD VARIATION OPTIMIZATION
              </div>
              <div v-if="viewRecommendationType=='recommendation'">
                <under-over-optimize-chart
                  v-if="showUnderOverChartWithType1"
                  :underOptimized="keywordCounts.optimizedVariations.underOptimized"
                  :overOptimized="keywordCounts.optimizedVariations.overOptimized"
                  :underOptimziedTooltipText="`You have ${keywordCounts.optimizedVariations.underOptimized} under optimized signals for the variations of your keyword`"
                  :overOptimziedTooltipText="`You have ${keywordCounts.optimizedVariations.overOptimized} over optimized signals for the variations of your keyword`"
                  >
                </under-over-optimize-chart>
                <under-over-optimize-chart-v2
                  v-else
                  :underOptimized="keywordCounts.optimizedVariations.underOptimized"
                  :overOptimized="keywordCounts.optimizedVariations.overOptimized"
                  :underOptimziedTooltipText="`You have ${keywordCounts.optimizedVariations.underOptimized} under optimized signals for the variations of your keyword`"
                  :overOptimziedTooltipText="`You have ${keywordCounts.optimizedVariations.overOptimized} over optimized signals for the variations of your keyword`"
                  >
                </under-over-optimize-chart-v2>
              </div>
              <div v-else>
                <div v-if="!pageNotBuilt">
                  <under-over-optimize-chart
                    v-if="showUnderOverChartWithType1"
                    :underOptimized="adjKeywordCounts.optimizedVariations.underOptimized"
                    :overOptimized="adjKeywordCounts.optimizedVariations.overOptimized"
                    :underOptimziedTooltipText="`You have ${adjKeywordCounts.optimizedVariations.underOptimized} under optimized signals for the variations of your keyword`"
                    :overOptimziedTooltipText="`You have ${adjKeywordCounts.optimizedVariations.overOptimized} over optimized signals for the variations of your keyword`"
                    >
                  </under-over-optimize-chart>
                  <under-over-optimize-chart-v2
                    v-else
                    :underOptimized="adjKeywordCounts.optimizedVariations.underOptimized"
                    :overOptimized="adjKeywordCounts.optimizedVariations.overOptimized"
                    :underOptimziedTooltipText="`You have ${adjKeywordCounts.optimizedVariations.underOptimized} under optimized signals for the variations of your keyword`"
                    :overOptimziedTooltipText="`You have ${adjKeywordCounts.optimizedVariations.overOptimized} over optimized signals for the variations of your keyword`"
                    >
                  </under-over-optimize-chart-v2>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-desktop over-under-optimized-zone" v-if="!pageNotBuilt">
            <div class="column">
              <div class="title-label">
                LSI OPTIMIZATION
              </div>
              <div v-if="viewRecommendationType=='recommendation'">
                <div v-if="lsiKeywordCounts.optimizedVariations">
                  <under-over-optimize-chart
                    v-if="showUnderOverChartWithType1"
                    :underOptimized="lsiKeywordCounts.optimizedVariations.underOptimized"
                    :overOptimized="lsiKeywordCounts.optimizedVariations.overOptimized"
                    :underOptimziedTooltipText="`You have ${lsiKeywordCounts.optimizedVariations.underOptimized} under optimized signals for your keyword's contextual terms (LSI)`"
                    :overOptimziedTooltipText="`You have ${lsiKeywordCounts.optimizedVariations.overOptimized} over optimized signals for your keyword's contextual terms (LSI)`"
                    >
                  </under-over-optimize-chart>
                  <under-over-optimize-chart-v2
                    v-else
                    :underOptimized="lsiKeywordCounts.optimizedVariations.underOptimized"
                    :overOptimized="lsiKeywordCounts.optimizedVariations.overOptimized"
                    :underOptimziedTooltipText="`You have ${lsiKeywordCounts.optimizedVariations.underOptimized} under optimized signals for your keyword's contextual terms (LSI)`"
                    :overOptimziedTooltipText="`You have ${lsiKeywordCounts.optimizedVariations.overOptimized} over optimized signals for your keyword's contextual terms (LSI)`"
                    >
                  </under-over-optimize-chart-v2>
                </div>
              </div>
              <div v-else>
                <div v-if="lsiAdjKeywordCounts.optimizedVariations">
                  <under-over-optimize-chart
                    v-if="showUnderOverChartWithType1"
                    :underOptimized="lsiAdjKeywordCounts.optimizedVariations.underOptimized"
                    :overOptimized="lsiAdjKeywordCounts.optimizedVariations.overOptimized"
                    :underOptimziedTooltipText="`You have ${lsiAdjKeywordCounts.optimizedVariations.underOptimized} under optimized signals for your keyword's contextual terms (LSI)`"
                    :overOptimziedTooltipText="`You have ${lsiAdjKeywordCounts.optimizedVariations.overOptimized} over optimized signals for your keyword's contextual terms (LSI)`"
                    >
                  </under-over-optimize-chart>
                  <under-over-optimize-chart-v2
                    v-else
                    :underOptimized="lsiAdjKeywordCounts.optimizedVariations.underOptimized"
                    :overOptimized="lsiAdjKeywordCounts.optimizedVariations.overOptimized"
                    :underOptimziedTooltipText="`You have ${lsiAdjKeywordCounts.optimizedVariations.underOptimized} under optimized signals for your keyword's contextual terms (LSI)`"
                    :overOptimziedTooltipText="`You have ${lsiAdjKeywordCounts.optimizedVariations.overOptimized} over optimized signals for your keyword's contextual terms (LSI)`"
                    >
                  </under-over-optimize-chart-v2>
                </div>
              </div>
            </div>
            <div class="column" v-if="commonNlpSchemaData && targetNlpSchemaData.entities">
              <div class="title-label">
                GOOGLE ENTITIES
              </div>
              <google-entity-under-over-chart
                :current="targetNlpSchemaData.entities.length"
                :max="commonNlpSchemaData.common_entities_array.length"
                :currentTooltipText="`${targetNlpSchemaData.entities.length}`"
                :maxTooltipText="`${commonNlpSchemaData.common_entities_array.length}`"
                >
              </google-entity-under-over-chart>
            </div>
          </div>
          <div class="columns is-desktop over-under-optimized-zone">
            <div class="column">
              <div class="title-label flex">
                <span class="is-size-7">PAGE WORD COUNT: {{ !pageNotBuilt ? mainPageWordCount : '' }}</span>
                <span class="flex-grow-1"></span>
                <span v-if="featuredCompetitorWordCountData['avgFeaturedCompetitorWordCount'] > 0" class="is-pulled-right toggle-focus-or-normal-chart selection-tag blue-active-tag" @click="showWithFeaturedCompetitors=!showWithFeaturedCompetitors">{{ showWithFeaturedCompetitors ? 'Competitors Count.' : 'Focus Word Count.' }}</span>
              </div>
              <word-count-color-chart
                :showWithFeaturedCompetitors="showWithFeaturedCompetitors"
                :pageNotBuilt="pageNotBuilt"
                :currentWordCount="mainPageWordCount"
                :minWordCount="competitorWordCountData['competitorsMin']"
                :maxWordCount="competitorWordCountData['competitorsMax']"
                :targetWordCount="competitorWordCountData['competitorsAvg']"
                :featuredMinWordCount="featuredCompetitorWordCountData['minFeaturedCompetitorWordCount']"
                :featuredMaxWordCount="featuredCompetitorWordCountData['maxFeaturedCompetitorWordCount']"
                :featuredTargetWordCount="featuredCompetitorWordCountData['avgFeaturedCompetitorWordCount']"
                >
              </word-count-color-chart>
            </div>
          </div>
          <div class="mt-10">
            <div class="tabs keyword-variations-tabs">
              <ul>
                <li :class="{'is-active': activeTabForTerms=='variations'}">
                  <a class="" @click="activeTabForTerms='variations'">KEYWORD VARIATIONS</a>
                </li>
                <li :class="{'is-active': activeTabForTerms=='lsi_terms'}">
                  <a class="" @click="activeTabForTerms='lsi_terms'">LSI</a>
                </li>
                <li :class="{'is-active': activeTabForTerms=='entities'}">
                  <a class="" @click="activeTabForTerms='entities'">Entities</a>
                </li>
                <li class="flex-grow-1"></li>
                <li class="d-flex pb-2">
                  <button class="show-weight-tag" @click="showWeights=!showWeights">{{ showWeights ? 'Hide weights' : 'Show weights' }}</button>
                  <button type="button"
                    class="copy-clipboard-button ml-2"
                    v-clipboard:copy="messageForVariationAndLSICopy"
                    v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
                    v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })">
                    Copy to clipboard
                  </button>
                  <div class="ml-4 sort-filter-block">
                    <span class="mainColor--text font-weight-bold">Sort</span>
                    <select v-model="variationSortMethod">
                      <option value="importance">Importance</option>
                      <option value="deficit" :disabled="isDeficitSortDisabled || activeTabForTerms=='entities'">Deficit</option>
                      <option value="alphabetic">Alphabetic</option>
                    </select>
                  </div>
                </li>
              </ul>
            </div>

            <div class="mt-4">
              <div v-if="activeTabForTerms=='variations'">
                <div class="mt10">
                  <!-- v-if="hasLsaForVariations" -->
                  <template v-if="sortedSecondaryKeywords.length > 0">
                    <div class="mainColor--text">Secondary Keywords</div>
                    <div class="mt-2">
                      <span v-for="(item, idx) in sortedSecondaryKeywords"
                        :key="idx"
                        class="secondary-keyword-tag">
                        {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ item.weight.toFixed(2) }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                      </span>
                    </div>
                  </template>
                  
                  <div class="mt10">
                    <div class="mainColor--text">Keyword Variations</div>
                    <div class="mt-2">
                      <template v-if="hasLsaForVariations">
                        <span v-for="(item, idx) in sortedLsaVariationsOnly"
                          :key="idx" class="variation-tag">
                          {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ item.weight.toFixed(2) }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                        </span>
                      </template>
                      <template v-else>
                        <span v-for="v in variations" :key="v" class="variation-tag">{{ v }}</span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="activeTabForTerms=='lsi_terms'">  <!-- this is LSI/ area -->
                <div>
                  <div class="mainColor--text">NLP terms</div>
                  <div class="mt-2">
                    <span v-for="(item, idx) in sortedNLPTermsOnly" :key="idx" class="nlp-tag">
                      {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ item.weight.toFixed(2) }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                    </span>
                  </div>
                </div>
                <div class="mt10">
                  <div class="mainColor--text">LSI terms</div>
                  <div class="mt-2">
                    <span v-for="(item, idx) in sortedLsaPhrasesWithoutNLP" :key="idx" class="lsi-tag">
                      {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ item.weight.toFixed(2) }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                    </span>
                  </div>
                </div>
              </div>
              <div v-else-if="activeTabForTerms=='entities'" class="mt5 tags hscroll-container">
                <span class="tag mr5"
                  :class="{'is-info': entity[2], 'is-faded-red-bg': !entity[2] }"
                  v-for="(entity, idx) in sortedGoogleNlpCommonEntities" :key="idx">
                  {{ entity[0] }} <span v-if="showWeights">&nbsp; ( {{ entity[1] }} )</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>

  <page-run-note :pageRun="{ note, id: reportID }" :showModal="showNoteModal"></page-run-note>

</div>

</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

import { mixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import PageNoteModal from '@/components/PageNoteModal.vue'
import OptimizationsBarChart from '@/components/OptimizationsBarChart.vue'
import SimpleBarChart from '@/components/SimpleBarChart.vue'
import UnderOverOptimizeChart from '@/components/PageRun/UnderOverOptimizeChart.vue'
import UnderOverOptimizeChartV2 from '@/components/PageRun/UnderOverOptimizeChartV2.vue'
import WordCountColorChart from '@/components/PageRun/WordCountColorChart.vue'
import GoogleEntityUnderOverChart from '@/components/PageRun/GoogleEntityUnderOverChart.vue'

export default {
  mixins: [mixin, ServiceMixin],
  components: {
    PageRunNote: PageNoteModal,
    RadialProgressBar, 
    OptimizationsBarChart, SimpleBarChart,
    UnderOverOptimizeChart,
    UnderOverOptimizeChartV2,
    WordCountColorChart,
    GoogleEntityUnderOverChart
  },
  props: [
    'currentPageRun',
    'pageId', 'reportID', 'variations', 'lsaVariations', 'nlpCommons', 'competitors', 'keywordCounts', 'adjKeywordCounts', 'lsiKeywordCounts', 'lsiAdjKeywordCounts', 'keyword',
    'excelFile', 'targetURL', 'keywordOptimizations', 'variationsOptimizations', 'note',
    'mainPageWordCount', 'avgCompetitorWordCount', 'avgFeaturedCompetitorWordCount', 'proxy', 'isExtensionUsed', 'featuredCompetitors', 'pageNotBuilt', 'lsaPhrases'],
  data () {
    return {
      downloadUrlLink: this.currentPageRun.reportResourceUrl ? this.currentPageRun.reportResourceUrl : `${API_URL}/download/?file=${this.excelFile}`,

      activeTabForTerms: 'variations',
      showCompetitorsOrFocus: true, // true -> competitros , false -> focus competitors
      showWithFeaturedCompetitors: false,
      showNoteModal: false,

      showWeights: false,
      viewRecommendationType: 'recommendation',
      showUnderOverChartWithType1: true
    }
  },
  computed: {
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    locationName () {
      if (this.proxy.location) return this.proxy.location; else return 'Unknown';
    },
    // currentWordCount () {
    //   const wordCount = this.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
    //   return wordCount.signalData.targetKeywordCnt
    // },
    dashboardType () {
      return this.$store.state.dashboardType
    },
    strategy () {
      return this.$store.state.strategy
    },
    approach () {
      return this.$store.state.approach
    },
    pageScore () {
      return this.strategyPageScore(this.dashboardType, this.strategy, this.approach)
    },
    competitorWordCountData () {
      const wordCount = this.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
      if (wordCount) {
        return {
          competitorsAvg: Math.round(wordCount.signalData.competitorsAvg),
          competitorsMax: wordCount.signalData['keywordsTrueMax'],
          competitorsMin: wordCount.signalData['keywordsTrueMin'],
        }
      }
      return {
        competitorsAvg: 0,
        competitorsMax: 0,
        competitorsMin: 0
      }

    },
    featuredCompetitorWordCountData () {
      const wordCount = this.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
      if (wordCount) {
        let avg = Math.round(wordCount.signalData.featuredCompetitorsKeywordAvg)
        return {
          avgFeaturedCompetitorWordCount: avg,
          maxFeaturedCompetitorWordCount: avg==0 ? 0 : wordCount.signalData['featuredCompetitorsKeywordMax'],
          minFeaturedCompetitorWordCount: avg==0 ? 0 : wordCount.signalData['featuredCompetitorsKeywordMin']
        }
      }
      return {
        avgFeaturedCompetitorWordCount: 0,
        maxFeaturedCompetitorWordCount: 0,
        minFeaturedCompetitorWordCount: 0
      }
    },
    commonNlpSchemaData () {
      return this.currentPageRun.commonNlpSchemaData
    },
    targetNlpSchemaData () {
      return this.currentPageRun.nlpSchemaData
    },
    messageForVariationAndLSICopy () {
      if (this.activeTabForTerms=='variations') {
        if (this.hasLsaForVariations) {
          return this.sortedLsaVariations.map(item => item.phrase).join(',')
        }
        return this.variations.join(',')
      } else if (this.activeTabForTerms=='lsi_terms') {
        if (this.hasLsaForVariations) {
          return this.sortedLsaPhrases.map(item => item.phrase).join(',')
        }
      } else if (this.activeTabForTerms=='entities') {
        return this.sortedGoogleNlpCommonEntities.map(item => item[0]).join(',')
      }
      return ''
    },
    canInputNote () {
      let pageRun = this.currentPageRun;
      if (!pageRun.isEatCalculationUsed) {
        return true
      } else {
        if(pageRun.eatStatus == 'FAILURE' || pageRun.eatStatus == 'SUCCESS') {
          return true
        }
      }
      return false
    },
    uniqueUrlForEditor () {
      let routeData = this.$router.resolve({name: 'FullPageSourceEditor', query: {secretKey: this.currentPageRun.secretKey }});
      return window.location.origin + "/" + routeData.href
    },
  },
  watch: {
    'variationSortMethod': function (val) {
      this.lsiSortMethod = val
      this.nlpEntitiySortMethod = val
    }
  },
  methods: {
    showEditSourceModal (isExpressEditor) {
      this.$parent.showEditSourceModal(isExpressEditor)
    },
    downloadReport () {
      let promise
      if (this.currentPageRun.reportResourceUrl) {
        promise = this.$store.dispatch('downloadExcelReportFromUrl', { url: this.currentPageRun.reportResourceUrl })
      } else {
        promise = this.$store.dispatch('downloadExcelReport', { filePath: this.excelFile })
      }
      return promise.then((response) => {
          const fileParts = this.excelFile.split('/')
          const fileName = fileParts[fileParts.length - 1]
          const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
        })
        .catch((err) => {
          console.log('ERROR: could not down load report', err)
        })
    },

    createSecretKey () {
      this.$store.commit('showLoading')
      this.$store.dispatch('createSecretKey', this.$route.params)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          let pRun = response.data.pageRun
          if (pRun) {
            this.$store.commit('setCurrentPageRun', pRun)
            this.$store.commit('updatePageRun', pRun)
          }
        }
      })
      .catch(error => console.log(error))
      .finally(() => this.$store.commit('hideLoading'))
    },

    pageScoreBGClass(value) {
      return {
        'page-score-poor': value >= 0 && value <= 69 ,
        'page-score-fair': value > 69 && value <= 74 ,
        'page-score-good': value > 74 && value <= 80 ,
        'page-score-verygood1': value > 80 && value <= 83 ,
        'page-score-verygood2': value > 83 && value <= 85 ,
        'page-score-verygood3': value > 85 && value <= 88 ,
        'page-score-verygood4': value > 88 && value <= 90 ,
        'page-score-verygood5': value > 90 && value <= 93 ,
        'page-score-verygood6': value > 93 && value <= 95 ,
        'page-score-verygood7': value > 95,
      }
    },

    showNoteModalAction () {
      console.log('Trying to display note edit modal for user')
      this.showNoteModal = true
    },
    hideNoteModalAction () {
      this.showNoteModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.label-section {
  color: var(--v-darkGrayColor-base);
  font-weight: bold;
  span {
    color: var(--v-mainColor-base);
  }
}
.very-big-section-label {
  font-size: 23px;
  font-weight: bold;
  color: var(--v-mainColor-base);
}
.main-keyword-tag {
  background: var(--v-lightYellowColor-base);
  border: 1px solid var(--v-profileAnchorColor-base);
  font-size: 16px;
  font-weight: bold;
  color: var(--v-profileAnchorColor-base);
  display: inline-block;
  margin: 3px 3px 3px 0px;
  border-radius: 5px;
  padding: 5px 8px;
  box-sizing: border-box;
  &:hover {
    cursor: pointer;
  }
}

.target-url-link {
  a {
    color: var(--v-link1Color-base);
    font-weight: bold;
  }
}

.download-checklist-button {
  color: var(--v-profileAnchorColor-base);
  display: flex;
  align-items: center;
  i {
    font-size: 14px;
    margin-right: 7px;
  }
  span {
    font-weight: bold;
  }
}

.score-section {
  border-top: 1px solid var(--v-gray8Color-base);
  border-bottom: 1px solid var(--v-gray8Color-base);
  padding: 25px 0;
  margin: 35px 0;

}
/* .separtor {
  height: 1px;
  background: var(--v-gray8Color-base);
  margin: 40px 0 30px 0;
} */

.score-box-in-left {
  display: flex;
  align-items: center;
  margin: 10px 0;
  .explain {
    margin-left: 25px;
    flex-grow: 1;
    font-weight: bold;
    color: var(--v-darkGrayColor-base);
  }
}

.big-section-label {
  font-size: 20px;
  font-weight: bold;
  color: var(--v-mainColor-base);
  display: flex;
  align-items: center;
  i {
    font-size: 16px;
    margin-left: 10px;
    color: var(--v-mainColor-base);
  }
}

.competitors-tab {
    display: flex;
    margin-bottom: 30px;
    border-bottom: 2px solid var(--v-gray8Color-base);
    gap: 25px;
    & > div {
      padding: 7px 0;
      height: 100%;
      &.is-active {
        border-bottom: 2px solid var(--v-profileAnchorColor-base);
      }
    }
}


.page-score {
  font-size: 1.2em !important;
}

.page-score-pass {
  background-color: lightgreen;
}

.page-score-fail {
  background-color: red;
}

.under-optimized{
  background-color: #f0c291;
}
.over-optimized{
  background-color: #cf8c8e;
}

.is-centered-table {
  margin-left: auto;
  margin-right: auto;
} 

.row-height {
  min-height: 200px !important;
}

.chart-height {
  height: 350px;
}
.full-height {
  height: 100%;
}
.score-chart-box {
  flex-grow: 1;
  flex-basis: 0;
  
  display: flex;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  background: #f7f7f7;
  align-items: center;
  word-wrap: wrap;

  .score-number {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .labels {
    margin-left: 10px;
    .score-label {
      color: #555555;
      font-weight: bold;
      font-size: 0.9rem;
      text-align: left;
    }
    .score-explain {
      color: #555555;
      font-size: 0.7rem;
      word-break: break-word;
    }
  }
}

.toggle-focus-or-normal-chart {
  font-size: 0.6rem;
  color: #2fa0eb;
  cursor: pointer;
}

.is-faded-red-bg {
  background-color: rgb(255, 115, 115);
  color: white;
}

.columns-2-responsive-tablet {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 20px;
}


@media only screen and (max-width: 1300px) {
  .columns-2-responsive-tablet {
    grid-template-columns: 1fr;
  }  
}

.copy-clipboard-button {
  border: 1px solid var(--v-grayColor-base);
  border-radius: 5px;
  padding: 4px 5px;
  font-size: 12px;
  color: var(--v-mainColor-base);
}


</style>