<template>
<div class="pl20 pr20">
  <div class="mt10 mb-5 navigate-bread-part">
    <div class="breadcrumbs-area">
      <a class="back-to-home inline-flex align-center" @click="() => {
        $router.push(`/sites/${ site.id }/tools`)
      }">
        <span class="bread-crumb-size mainColor--text font-weight-bold">{{ site.name }}</span>
      </a>
      <template v-if="currentCampaign">
        <a class="back-to-home inline-flex align-center" v-if="currentCampaign.isDomainLevelCampaign">
          <i class="material-icons arrow-right-size">chevron_right</i>
          <span class="bread-crumb-size mainColor--text font-weight-bold">Keyword research</span>
        </a>
        <template v-else>
          <a class="back-to-home inline-flex align-center" @click="() => {
            $router.push(`/sites/${site.id}/pages?tab=keyword-research`)
          }">
            <i class="material-icons arrow-right-size">chevron_right</i>
            <span class="bread-crumb-size mainColor--text font-weight-bold">Page keyword research</span>
          </a>
          <a class="back-to-home inline-flex align-center" style="position: relative; overflow: visible;">
            <i class="material-icons arrow-right-size">chevron_right</i>
            <v-autocomplete outlined dense hide-details="auto"
              :items="pageLevelCampaigns"
              :item-text="(c) => {
                return `${ c.campaignName }`
              }"
              item-value="id"
              v-model="currentCampaign"
              @change="(id) => {
                if (id && selectedCampaignId != id) {
                  selectedCampaignId = id
                  $router.push(`/sites/${ siteId }/campaign/${ id }`)
                }
              }">
            </v-autocomplete>

            <!-- <i class="material-icons arrow-right-size">chevron_right</i>
            <span class="bread-crumb-size mainColor--text font-weight-bold">{{ currentCampaign.campaignName }}</span>
            
            
            <div class="select-page-campaign">
              <v-autocomplete outlined dense hide-details="auto"
                :items="pageLevelCampaigns"
                :item-text="(c) => {
                  return `${ c.campaignName }`
                }"
                item-value="id"
                v-model="currentCampaign"
                @change="(id) => {
                  if (id && selectedCampaignId != id) {
                    selectedCampaignId = id
                    $router.push(`/sites/${ siteId }/campaign/${ id }`)
                  }
                }"></v-autocomplete> 
            </div> -->
          </a>
        </template>
      </template>
    </div>
  </div>
  <div class="pagerun-tabs" :style="selectedTab !== cleanedTabs[0].key ? 'margin-left: -10px;' : ''">
    <ul>
      <li v-for="tab in cleanedTabs" :key="tab.key"
        :class="{'is-active': selectedTab === tab.key}">
        <a @click="() => changeTab(tab)">
          <span>{{ tab.label }}</span>
        </a>
        <div>
          <span 
            v-if="('silo' === tab.key && isNewSiloUpdates) || ('keyword-tracking' === tab.key && isNewTrackingUpdates) " 
            class="bullet kgr-score-bullet normal"
          ></span>
        </div>
      </li>
      <li class="flex-grow-1"></li>
      <!-- <li :class="{'is-active': activeTab === 'checklist'}"><a @click="showTab('checklist')">Progress Notes</a></li> -->
    </ul>
  </div>
  <div class="mt-5">
    <router-view
      :campaignId="selectedCampaignId"
      :hasUnlimitedAccess="hasUnlimitedAccess"
    ></router-view>
    <async-tasks-popup
      :siteId="siteId"
      :campaignId="selectedCampaignId"
    />
  </div>
</div>
</template>

<script>
import AsyncTasksPopup from '@/components/AsyncTasksPopup.vue';
import { getPureServicePkgName } from '@/utils'
import {
  SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS,
  SUBSCRIPTIONS_WITH_UNLIMITED_ACCESS_TO_KEYWORD_RESEARCH_TOOLS
} from "@/components/plans_constants";

export const ALL_TABS = [
  {
    label: 'Keyword strategy',
    key: 'keyword-wizard',
  },
  {
    label: 'Silo',
    key: 'silo',
  },
  {
    label: 'Keyword insight',
    key: 'keyword-insight',
  },
  {
    label: 'Keyword gap',
    key: 'keyword-gap',
  },
  {
    label: 'Keyword tracking',
    key: 'keyword-tracking',
  },
]

export default {
  components: { AsyncTasksPopup },
  computed: {
    isNewSiloUpdates() {
      return this.$store.getters.isNewSiloUpdates;
    },
    isNewTrackingUpdates() {
      return this.$store.getters.isNewTrackingUpdates;
    },
    currentUser () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    availableTabKeys () {
      return ALL_TABS.map(item => item.key).map(key => key.split('/')[0])
    },
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId)) || {}
    },
    siteId () {
      return parseInt(this.$route.params.siteId)
    },
    selectedTab () {
      let selectedTab = null
      this.$route.matched.forEach((matchedRoute) => {
        if (this.availableTabKeys.indexOf(matchedRoute.name) >= 0) {
          selectedTab = matchedRoute.name
        }
      })
      return selectedTab
    },
    currentCampaign () {
      return this.campaigns.find(c => c.id == this.selectedCampaignId)
    },
    pageLevelCampaigns () {
      return this.campaigns.filter( c => !c.isDomainLevelCampaign)
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isSpecialOwnerSelected () {
      return this.$store.getters.isSpecialOwnerSelected
    },
    cleanedTabs () {
      if (this.selectedSubAccountRelation) {
        if (this.isSpecialOwnerSelected) {
          return ALL_TABS
        } else {
          return ALL_TABS.filter(t => {
            return (
              (
                ['keyword-wizard', 'keyword-insight', 'keyword-tracking'].includes(t.key)
                && SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.selectedSubAccountRelation.ownerAccountType))
              )
              || this.selectedSubAccountRelation.grantedDevModeTools.map(key => key.split('/')[0]).includes(t.key)
            )
          })
        }
      } else if (this.isSpecialAdminUser) {
        return ALL_TABS
      }
      if (this.currentUser) {
        return ALL_TABS.filter(t => {
          return (
            (
              ['keyword-wizard', 'keyword-insight', 'keyword-tracking'].includes(t.key)
              && SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.currentUser.accountType))
            )
            || this.currentUser.grantedDevModeTools.map(key => key.split('/')[0]).includes(t.key)
          )
        })
      }
      return []
    },
    hasUnlimitedAccess () {
      if (this.selectedSubAccountRelation) {
        if (this.isSpecialOwnerSelected) {
          return true;
        }
        if (SUBSCRIPTIONS_WITH_UNLIMITED_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.selectedSubAccountRelation.ownerAccountType))) {
          return true
        }
      } else {
        if (this.isSpecialAdminUser) {
          return true;
        }
        if (SUBSCRIPTIONS_WITH_UNLIMITED_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.currentUser.accountType))) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    'site': function (newVal, oldVal) {
      this.loadCampaigns()
    }
  },
  data () {
    return {
      selectedCampaignId: 0,

      campaigns: [],

    }
  },
  methods: {
    changeTab (tab) {
      if (tab.key !== this.selectedTab || tab.key === 'silo' || tab.key === 'keyword-tracking') {
        if (tab.key === 'silo') this.$store.commit('setIsNewSiloUpdates', false);
        if (tab.key === 'keyword-tracking') this.$store.commit('setIsNewTrackingUpdates', false);

        const {siteId, campaignId} = this.$route.params
        this.$router.push(`/sites/${siteId}/campaign/${campaignId}/${tab.key}`)
      }
    },

    loadCampaigns () {
      this.$store.dispatch('getCampaignsForSite', this.siteId)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.campaigns = response.data.campaigns
        }
      })
    },
    // test (d) {
    //   console.log('adfasdf', d)
    // }

  },
  mounted () {
    this.$store.commit('setHideSideBar', true)    
    let campaignId = this.$route.params.campaignId || 0
    this.selectedCampaignId = campaignId

    this.loadCampaigns()
  },
  beforeMount() {
    this.$store.commit('resetInsightKeywords')
  },
}
</script>

<style lang="scss" scoped>
.select-page-campaign {
  position: absolute;
  background: var(--v-whiteColor-base);
  padding: 10px 10px;
  border-radius: 10px;
  border: 1px solid var(--v-grayColor-base);
  top: 30px;
  left: 25px;
  height: 300px;
  height: 300px;
  background: red;
  z-index: 100;
}

.bullet {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: -15px;
  margin-left: -13px;
  background: var(--v-redColor-base);
}

</style>