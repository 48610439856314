<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="columns is-desktop">
            <div class="column is-2">
              <div class="field">
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="perPage" @change="onPerPageChange">
                      <option value="10">10 per page</option>
                      <option value="15">15 per page</option>
                      <option value="25">25 per page</option>
                      <option value="50">50 per page</option>
                      <option value="100">100 per page</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    placeholder="Search"
                    v-model="searchText"
                    @input="changeSearchText"
                  />
                </div>
              </div>
            </div>
            <div class="column is-2">
              <button class="button is-info is-fullwidth" @click="onFilter">
                Search
              </button>
            </div>
            <div class="column is-2">
              <button @click="exportPage" class="button is-link is-fullwidth">
                Export Page
              </button>
            </div>
            <div class="column is-2">
              <a
                class="button is-link is-fullwidth"
                download
                :href="downloadAllUrl"
                >Export All</a
              >
            </div>
          </div>

          <b-table
            :key="detailViewKey"
            :data="records"
            :loading="isLoading"
            paginated
            backend-pagination
            :total="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            detailed
            detail-key="runId"
            @details-open="(row, index) => showDetailsForPageRun(row.pageRun)"
          >
            <template slot-scope="props">
              <b-table-column field="runId" label="Run ID" sortable numeric>{{
                props.row.runId
              }}</b-table-column>
              <b-table-column field="createdAt" label="Created On" sortable>{{
                toLocalDateString(props.row.createdAt)
              }}</b-table-column>
              <b-table-column field="email" label="User Email" sortable>{{
                props.row.email
              }}</b-table-column>
              <b-table-column field="keyword" label="Keyword" sortable>{{
                props.row.keyword
              }}</b-table-column>
              <b-table-column field="status" label="Status" sortable>{{
                props.row.status
              }}</b-table-column>
              <b-table-column field="score" label="Score" numeric>{{
                props.row.score | round(2)
              }}</b-table-column>
              <b-table-column field="ipAddress" label="IP Address" numeric>{{
                props.row.ipAddress
              }}</b-table-column>
              <b-table-column field="pageRunType" label="Report Type" numeric>{{
                props.row.pageRunType == 0
                  ? "Full"
                  : props.row.pageRunType
                  ? "Pro"
                  : "Old Run"
              }}</b-table-column>
              <b-table-column field="isRerun" label="Run Type" numeric>{{
                props.row.isRerun ? "ReRun" : "NewRun"
              }}</b-table-column>
              <b-table-column field="isGuardRun" label="Watchdog Run">
                <span
                  v-if="
                    props.row.page.isGoogleGuardEnabled &&
                    props.row.page.guardRunId == props.row.pageRun.id
                  "
                  class="main-color"
                >
                  Watched
                  <template v-if="props.row.page.guardSetupCancelDate">
                    <br />
                    <span class="mainColor--text">
                      Ends at:
                      {{
                        toLocalDateString(props.row.page.guardSetupCancelDate)
                      }}
                    </span>
                  </template>
                </span>
                <span v-else>{{
                  props.row.pageRun.isGuardRun ? "Yes" : ""
                }}</span>
              </b-table-column>
            </template>
            <template slot="detail" slot-scope="props">
              <div v-if="props.row.status === 'SUCCESS'">
                <div>
                  <h2 class="title has-text-centered">
                    {{ props.row.pageName }}
                  </h2>
                  <p class="subtitle-font has-text-centered">
                    Created on
                    {{ toLocalDateString(props.row.pageRun.createdAt) }}
                  </p>
                  <div class="text-right">
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon text-none mainColor--text"
                      @click="() => openPageSettingModal(props.row)"
                    >
                      Watchdog setup
                    </v-btn>
                  </div>

                  <section class="section pt15">
                    <h2 class="subtitle">Content brief</h2>
                    <checklist
                      type="Keyword"
                      :selectedMainTab="'advanced'"
                      :signals="props.row.pageRun.keywordCounts"
                      :adjSignals="props.row.pageRun.adjKeywordCounts"
                      :lsiSignals="props.row.pageRun['lsaKeywordCounts']"
                      :lsiAdjSignals="props.row.pageRun['lsaAdjKeywordCounts']"
                      :keyword="
                        props.row.pageRun.keyword != null
                          ? props.row.pageRun.keyword
                          : props.row.keyword
                      "
                      :targetURL="
                        props.row.pageRun.url
                          ? props.row.pageRun.url
                          : props.row.url
                      "
                      :variations="props.row.pageRun.variations"
                      :lsaVariations="props.row.pageRun['lsaVariations']"
                      :nlpCommons="props.row.pageRun['commonNlpSchemaData']"
                      :baseURL="getBaseUrl(props.row.url)"
                      :pageNotBuilt="props.row.pageRun.pageNotBuilt"
                      :tagCounts="props.row.pageRun.tagCounts"
                      :pageId="props.row.pageRun.pageId"
                      :pageRunId="props.row.pageRun.id"
                      :mainPageSchemaTypes="
                        props.row.pageRun.schemaTypes
                          ? props.row.pageRun.schemaTypes
                          : []
                      "
                      :competitors="props.row.pageRun.competitors"
                      :checkLists="props.row.checkLists"
                      :lsaPhrases="lsaPhrases(props.row.pageRun)"
                      :relatedSearches="props.row.pageRun.relatedSearches"
                      :relatedQuestions="props.row.pageRun.relatedQuestions"
                      :gCompetitors="props.row.pageRun.gCompetitors"
                      @actionForUpdateParent="test"
                    />
                  </section>

                  <section class="section pt15">
                    <h2 class="subtitle">Summary</h2>
                    <run-summary
                      :currentPageRun="props.row.pageRun"
                      :variations="props.row.pageRun.variations"
                      :lsaVariations="props.row.pageRun['lsaVariations']"
                      :nlpCommons="props.row.pageRun['commonNlpSchemaData']"
                      :pageId="props.row.pageRun.pageId"
                      :reportID="props.row.pageRun.id"
                      :competitors="props.row.pageRun.competitors"
                      :keyword="
                        props.row.pageRun.keyword
                          ? props.row.pageRun.keyword
                          : props.row.keyword
                      "
                      :keywordCounts="props.row.pageRun.keywordCounts"
                      :adjKeywordCounts="props.row.pageRun.adjKeywordCounts"
                      :lsiKeywordCounts="props.row.pageRun['lsaKeywordCounts']"
                      :lsiAdjKeywordCounts="
                        props.row.pageRun['lsaAdjKeywordCounts']
                      "
                      :excelFile="props.row.pageRun.excelFile"
                      :targetURL="props.row.url"
                      :note="props.row.pageRun.note"
                      :keywordOptimizations="[
                        props.row.pageRun.keywordCounts.optimizedKeyword
                          .underOptimized,
                        props.row.pageRun.keywordCounts.optimizedKeyword
                          .overOptimized,
                      ]"
                      :variationsOptimizations="[
                        props.row.pageRun.keywordCounts.optimizedVariations
                          .underOptimized,
                        props.row.pageRun.keywordCounts.optimizedVariations
                          .overOptimize,
                      ]"
                      :mainPageWordCount="mainPageWordCount(props.row.pageRun)"
                      :avgCompetitorWordCount="
                        avgCompetitorWordCount(props.row.pageRun)
                      "
                      :avgFeaturedCompetitorWordCount="
                        avgFeaturedCompetitorWordCount(props.row.pageRun)
                      "
                      :proxy="
                        proxies.find((p) => p.id === props.row.pageRun.proxyId)
                      "
                      :isExtensionUsed="props.row.pageRun.isExtensionUsed"
                      :featuredCompetitors="
                        props.row.pageRun.featuredCompetitors
                      "
                      :pageNotBuilt="props.row.pageRun.pageNotBuilt"
                      :lsaPhrases="lsaPhrases(props.row.pageRun)"
                    />
                  </section>
                  <section class="section pt15">
                    <h2 class="subtitle">Recommendations</h2>
                    <signals
                      :isFromAdminPage="true"
                      :pageRunId="props.row.pageRun.id"
                      :excelFile="props.row.pageRun.excelFile"
                      type="Keyword"
                      recommendation_type="keyword"
                      :signals="props.row.pageRun.keywordCounts"
                      :lsiSignals="props.row.pageRun['lsaKeywordCounts']"
                      :adjSignals="props.row.pageRun.adjKeywordCounts"
                      :adjLsiSignals="props.row.pageRun['lsaAdjKeywordCounts']"
                      :keyword="
                        props.row.pageRun.keyword
                          ? props.row.pageRun.keyword
                          : props.row.keyword
                      "
                      :targetURL="props.row.url"
                      :variations="props.row.pageRun.variations"
                      :lsaVariations="props.row.pageRun['lsaVariations']"
                      :nlpCommons="props.row.pageRun['commonNlpSchemaData']"
                      :baseURL="getBaseUrl(props.row.url)"
                      :pageNotBuilt="props.row.pageRun.pageNotBuilt"
                      :featuredCompetitors="
                        props.row.pageRun.featuredCompetitors
                      "
                      :pageId="props.row.pageRun.pageId"
                      :checkLists="props.row.checkLists"
                      :lsaPhrases="lsaPhrases(props.row.pageRun)"
                    />
                  </section>
                  <section class="section pt15">
                    <h2 class="subtitle">Adjusted Recommendations</h2>
                    <signals
                      :isFromAdminPage="true"
                      :pageRunId="props.row.pageRun.id"
                      :excelFile="props.row.pageRun.excelFile"
                      type="Adjusted Keyword"
                      recommendation_type="adjKeyword"
                      :signals="props.row.pageRun.adjKeywordCounts"
                      :lsiSignals="props.row.pageRun['lsaAdjKeywordCounts']"
                      :keyword="
                        props.row.pageRun.keyword
                          ? props.row.pageRun.keyword
                          : props.row.keyword
                      "
                      :variations="props.row.pageRun.variations"
                      :lsaVariations="props.row.pageRun['lsaVariations']"
                      :nlpCommons="props.row.pageRun['commonNlpSchemaData']"
                      :baseURL="getBaseUrl(props.row.url)"
                      :pageNotBuilt="props.row.pageRun.pageNotBuilt"
                      :featuredCompetitors="
                        props.row.pageRun.featuredCompetitors
                      "
                      :pageId="props.row.pageRun.pageId"
                      :checkLists="props.row.checkLists"
                      :lsaPhrases="lsaPhrases(props.row.pageRun)"
                    />
                  </section>
                  <section class="section pt15">
                    <h2 class="subtitle">Page Structure Suggestions</h2>
                    <tag-totals
                      :tagCounts="props.row.pageRun.tagCounts"
                      :featuredTagCounts="props.row.pageRun.featuredTagCounts"
                      :pageNotBuilt="props.row.pageRun.pageNotBuilt"
                      :pageId="props.row.pageRun.pageId"
                      :checkLists="props.row.checkLists"
                    />
                  </section>
                  <section class="section pt15">
                    <h2 class="subtitle">Schema</h2>
                    <schema-types
                      :pageId="props.row.pageRun.pageId"
                      :targetURL="
                        props.row.pageRun.url
                          ? props.row.pageRun.url
                          : props.row.url
                      "
                      :genPageSchemaTypes="props.row.pageRun.aiGenSchemaTypes"
                      :mainPageSchemaTypes="
                        props.row.pageRun.schemaTypes
                          ? props.row.pageRun.schemaTypes
                          : []
                      "
                      :competitors="props.row.pageRun.competitors"
                      :pageNotBuilt="props.row.pageRun.pageNotBuilt"
                      :checkLists="props.row.checkLists"
                    ></schema-types>
                  </section>
                  <section class="section pt15">
                    <h2 class="subtitle">GOOGLE ENTITIES (BETA)</h2>
                    <nlp-schema-data
                      :targetUrl="props.row.pageRun.url"
                      :pageNotBuilt="props.row.pageRun.pageNotBuilt"
                      :targetNlpSchemaData="props.row.pageRun.nlpSchemaData"
                      :competitors="props.row.pageRun.competitors"
                      :commons="props.row.pageRun.commonNlpSchemaData"
                    ></nlp-schema-data>
                  </section>
                  <section class="section pt15">
                    <h2 class="subtitle">EEAT</h2>
                    <eat-data-view
                      :eatStatus="props.row.pageRun.eatStatus"
                      :isEatCalculationUsed="
                        props.row.pageRun.isEatCalculationUsed
                      "
                      :pageId="props.row.pageRun.pageId"
                      :targetURL="
                        props.row.pageRun.url
                          ? props.row.pageRun.url
                          : props.row.url
                      "
                      :pageNotBuilt="props.row.pageRun.pageNotBuilt"
                      :mainPageSchemaTypes="
                        props.row.pageRun.eatSchemaTypes
                          ? props.row.pageRun.eatSchemaTypes
                          : []
                      "
                      :commonEatSchemaTypes="
                        props.row.pageRun.commonEatSchemaTypes
                          ? props.row.pageRun.commonEatSchemaTypes
                          : []
                      "
                      :competitors="props.row.pageRun.competitors"
                      :checkLists="props.row.checkLists"
                      :nextGenEatSchemas="pageRun.nextGenEatSchemas"
                    />
                  </section>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <page-setting-modal
      ref="pageSettingModal"
      :disableDeletePage="true"
      :isAdminSetupTheGuard="true"
    />
  </section>
</template>

<script>
import { mixin } from "@/components/mixin";
import Buefy from "buefy";
import RunSummary from "@/components/PageRun/RunSummary.vue";
import TagTotals from "@/components/PageRun/TagTotals.vue";
import Signals from "@/components/PageRun/Signals.vue";
import SchemaTypes from "@/components/PageRun/SchemaTypes.vue";
import NlpSchemaData from "@/components/PageRun/NlpSchemaData.vue";
import EatDataView from "@/components/PageRun/eat-data/EatDataView.vue";
import Checklist from "@/components/PageRun/Checklist.vue";
import PageSettingModal from "@/components/PageSettingModal.vue";

import { mixin as exportmixin } from "@/components/export_mixin";
import { toLocalDateString } from "@/utils";

export default {
  components: {
    RunSummary,
    TagTotals,
    Signals,
    SchemaTypes,
    NlpSchemaData,
    EatDataView,
    Checklist,
    PageSettingModal,
  },
  mixins: [mixin, exportmixin],
  data() {
    return {
      records: [],
      totalRows: 0,
      isLoading: false,
      sortField: "runId",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      currentPage: 1,
      perPage: 15,
      isSimple: false,
      isRounded: false,
      searchText: "",
      proxies: [],
      downloadAllUrl: `${API_URL}/download/?file=/home/hvseo/web/highvoltageseo.com/pageoptimizer/webapp/web/Report/allhistory.txt`,

      detailViewKey: 0,
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.user) {
        return this.$store.state.user;
      }
      return {};
    },
  },
  methods: {
    test() {
      console.log("history tab function is called as intended");
      this.$nextTick(() => this.loadData());
    },
    openPageSettingModal(row) {
      this.$refs.pageSettingModal.show(
        row.page,
        this.loadData,
        row.email,
        row.pageRun
      );
    },

    showDetailsForPageRun(pageRun) {
      // console.log('detail', pageRun)
      this.$store.commit("showLoading");
      this.$store.commit("setCurrentPageRun", pageRun);
      this.$store.commit("hideLoading");
    },
    getBaseUrl(fullUrl) {
      let pathArray = fullUrl.split("/");
      let protocol = pathArray[0];
      let host = pathArray[2];
      let url = protocol + "//" + host;
      return url;
    },
    lsaPhrases(pageRun) {
      if (
        pageRun["lsaPhrases"] == undefined ||
        !Array.isArray(pageRun["lsaPhrases"])
      ) {
        return [];
      } else {
        return pageRun.lsaPhrases.map((item) => {
          // round weight as 2 digits
          let newItem = Object.assign({}, item);
          newItem.weight = Math.round(newItem.weight * 100) / 100;
          return newItem;
        });
      }
    },
    exportPage() {
      let data = this.records.map(
        (record) =>
          `${record.runId},"${this.toLocalDateString(record.createdAt)}",${
            record.email
          },${record.keyword},${record.status},${record.url},${record.score}`
      );
      data = ["Run ID,Created At, User Email,Keyword,Status,Target Url,Score"]
        .concat(data)
        .join("\n");
      const now = new Date();
      const fileName =
        "History_" +
        now.getUTCFullYear() +
        "_" +
        (now.getUTCMonth() + 1) +
        "_" +
        now.getUTCDate() +
        ".csv";
      this.exportToCSV(data, fileName);
    },
    avgCompetitorWordCount(pageRun) {
      if (pageRun.hasOwnProperty("keywordCounts")) {
        const wordCount = pageRun.keywordCounts.rows.find(
          (wc) => wc.signal === "Word Count"
        );
        return Math.round(wordCount.signalData.competitorsAvg);
      }
      return 0;
    },
    avgFeaturedCompetitorWordCount(pageRun) {
      if (pageRun.hasOwnProperty("keywordCounts")) {
        const wordCount = pageRun.keywordCounts.rows.find(
          (wc) => wc.signal === "Word Count"
        );
        return Math.round(wordCount.signalData.featuredCompetitorsKeywordAvg);
      }
      return 0;
    },
    mainPageWordCount(pageRun) {
      const wordCount = pageRun.keywordCounts.rows.find(
        (wc) => wc.signal === "Word Count"
      );
      return wordCount.signalData.targetKeywordCnt;
    },
    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr);
    },
    onPerPageChange() {
      this.$nextTick(() => this.loadData());
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadData();
    },
    onFilter() {
      // console.log('filtering')
      this.$nextTick(() => {
        this.loadData();
      });
    },
    loadData() {
      this.isLoading = true;
      this.$store.commit("showLoading");
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.searchText}`,
      ].join("&");
      this.$store
        .dispatch("loadHistoryForAdmin", { params })
        .then(({ data }) => {
          const { total, records } = data;
          this.totalRows = total;
          this.records = records;
          this.isLoading = false;
          this.$store.commit("hideLoading");
          this.detailViewKey = parseInt(Math.random() * 1000, 10);
          console.log("data received is", records[0]);
        })
        .then(() => this.$store.dispatch("fetchAvailableProxies"))
        .then((response) => {
          this.proxies = response.data;
        });
    },
    changeSearchText() {
      console.log(this.searchText);
    },
  },
  beforeMount() {
    // this.loadData()
  },
};
</script>

<style lang="scss">
.table-mobile-sort {
  display: none;
}
</style>
