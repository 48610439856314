<template>
  <div class="stepper">
    <template v-if="!isOnePageSetup">
      <template v-for="(step, idx) in steps">
        <div v-if="!isExpressReport || step.expressStep" :key="idx" class="step-item" :class="{'is-current': step.substeps.includes(currentPage)}">
          <div class="flex-grow-1 has-text-right" :class="{'has-text-grey': true}">{{ step.name }}</div>
          <div class="bar" :class="{'is-not-done': stepComplete(step)}">
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { PageRunPages } from '@/components/page_run_pages.js'
export default {
    props: ['isOnePageSetup', 'currentPage', 'isExpressReport'],
    data () {
      return {
        // steps: ['KEYWORD', 'LANGUAGE', 'VARIATIONS', 'PAGE', 'COMPETITORS', 'FOCUS COMPETITORS', 'LSA'],
        // steps: ['KEYWORD', 'LANGUAGE', 'COMPETITORS', 'FOCUS COMPETITORS', 'PAGE', 'VARIATIONS', 'LSI'],
        steps: [{
            name: 'KEYWORD',
            substeps: [PageRunPages.ADD_KEYWORD],
            expressStep: true
          }, {
            name: 'LOCATION',
            substeps: [PageRunPages.CHOOSE_LANGUAGE_AND_REGION],
            expressStep: true
          }, {
            name: 'COMPETITORS',
            substeps: [PageRunPages.METHOD_OF_COMPETITOR_SELECTION, PageRunPages.ADD_COMPETITORS],
            expressStep: false
          }, {
            name: 'FOCUS COMPETITORS',
            substeps: [PageRunPages.SELECT_FOCUS_GROUP],
            expressStep: false
          }, {
            name: 'PAGE',
            substeps: [PageRunPages.ADD_PAGE_TO_OPTIMIZE],
            expressStep: true
          }, {
            name: 'VARIATIONS',
            substeps: [PageRunPages.VARIATIONS],
            expressStep: false
          }, {
            name: 'LSI',
            substeps: [PageRunPages.LSA],
            expressStep: false
          }
        ],
        stepsLength: [1, 1, 3, 1, 1, 1, 1],
        ignoreStepsInExpress: ['VARIATIONS', 'COMPETITORS', 'FOCUS COMPETITORS', 'LSI']
      }
    },
    computed: {
      hideStepper () {
        if (this.isExpressReport) {
          return true;
        }
        return false;
      },
      actualCurrentStep () {
        let s=0;
        for (let i=0; i<this.stepsLength.length; i++) {
          s+=this.stepsLength[i]
          if (s>=this.currentPage) {
            return i;
          }
        }
      }
    },
    methods: {
      canBeRendered (step) {
        if (this.isExpressReport) {
          if (this.ignoreStepsInExpress.includes(step.name)) {
            return false
          }
        }
        return true
      },
      stepComplete (step) {
        const stepIdx = this.steps.findIndex(s => s.name === step.name)
        let i = 0;
        for (let s of this.steps) {
          if (s.substeps.includes(this.currentPage)) {
            break
          }
          i++
        }
        return stepIdx > i;
      }
    }

}
</script>

<style lang="scss" scoped>
  $height: 30px;
  $gray-color: #dddddd;
  $normal-color: #ffcc02;
  $active-color: #ff9b00;
  .stepper {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 0.8rem;
    padding-bottom: 150px;

    .step-item {
      display: flex;
      align-items: center;
      min-height: $height;
      & > div {
        font-size: 0.8rem;
      }

      .bar {
        position: relative;
        flex-basis: 30px;
        &::after {
          position: absolute;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: $normal-color;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
        }
        &.is-not-done::after {
          background: $gray-color;
        }
      }
      &:not(:first-child) .bar::before {
        position: absolute;
        content: '';
        width: 5px;
        height: $height;
        background: $normal-color;
        left: 50%;
        bottom: 50%;
        transform: translateX(-50%);
        z-index: 5;
      }
      &:not(:first-child) .bar.is-not-done::before {
        background: $gray-color;
      }

      &.is-current .bar::after {
        background: $active-color;
      }
    }
  }
</style>
