<template>
<div>
  <div class="ml20 mr20 dashboard-name">All projects</div>
  <div class="hero-body" v-if="user.accountType == 'sub_account' && !selectedOwnerAccount">
    <div class="columns">
      <div class="column is-offset-2 is-8">

        <div class="columns" >
          <div class="column">
            <p style="margin-top: 50px;"
              class='has-text-centered subtitle'>
              You are in your own account and do not have an active POP subscription. <br/>
              To view your assigned projects or create projects in another account that you have been given access to, click manage account and switch accounts in the account management dashboard.
            </p>
          </div>
        </div>

        <div class="columns" >
          <div class="column">
            <p style="margin-top: 50px;"
              class='has-text-centered body-font is-size-4 mainColor--text'>
              
            </p>
            <p v-if="user.isTrial">During your trial period you cannot use a domain that you or someone else has previously used.</p>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div v-else-if="!$store.state.isLoading && !sites.length">
    <div v-if="!user.isVendor" class="empty-project">
      <img class="no-page-img" src="/static/no-page.png" />
      <div class="title-label">
        You don’t have any projects yet
        <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
          @click="() => {
            $store.commit('setCurrentVideoTutorialToDisplay', {
              title: 'How to Set Up a New Project in POP',
              videoLink: 'https://www.youtube.com/embed/z_Mu-86IFfc?si=DUb4xYaDKwu4RR2V',
              description: 'Learn how to set up a new project in POP.',
              time: '2m 23s'
            });
          }">
          videocam
        </span>
      </div>
      <div class="explain">
        Looks like you're just getting started!  Click the orange New project button above, the New project text in the left side bar, or the button below to create your first project.
        <br/>
      </div>
      <v-btn rounded outlined
        color="grayColor"
        class="button-with-icon text-none mt20 gray2Color--text"
        @click="addSite"
        >
        <i class="material-icons font-weight-bold">add</i>
        <span class="font-weight-bold">Add new project</span>
      </v-btn>
    </div>
  </div>
  
  <section>
    <div class="pl20 pr20 mt-5" ref="observer">
      <div v-if="selectedSiteIds.length > 0"
        class="flex flex-column white-common-box mb-3">
        <div class="d-flex align-center pa-3">
          <div class="gray2Color--text mr20">
            {{ selectedSiteIds.length }} project{{ selectedSiteIds.length>1 ? 's' : '' }} selected
          </div>
          <v-btn small outlined
            color="red3Color"
            class="button-with-icon whiteColor--text text-none ml10"
            @click.stop="showDeleteSitesConfirmModal = true">
            <i class="material-icons">delete_outline</i>
            <span>Delete all</span>
          </v-btn>
          <v-btn small outlined
            color="mainColor"
            class="button-with-icon text-none ml10"
            @click.stop="cancelAllSelection">
            <span>Unselect all</span>
          </v-btn>
        </div>
      </div>
      <div class='sites-or-pages-container mt-6' ref="sitesContainer">
        <div
          class='is-fullheight flex flex-column site-page-card'
          :class="{'selected-site-page': isSelectedSite(site.id)}"
          v-for='site in paginatedSites'
          :key='site.id'>
          <div class="top-part border-bottom is-size-6 title-font mainColor--text flex align-center">
            <span class="d-flex align-center flex-gap-10">
              <i v-if="isSelectedSite(site.id)" class="material-icons checked-box" @click="$store.getters.isDemoUser ? null : unSelectSite(site.id)">check_box</i>
              <i v-else class="material-icons checked-box" @click="$store.getters.isDemoUser ? null : selectSite(site.id)">check_box_outline_blank</i>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"
                    class="material-icons pin-icon" :class="{ 'pinned': site.isPinned }"
                    @click="() => $store.getters.isDemoUser ? null : pinProjectAction(site.id, !site.isPinned)">push_pin</span>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  {{
                    site.isPinned ? 'Click to unpin' : 'Pin this Project to the top of your dashboard to find it easily.'
                  }}
                </div>
              </v-tooltip>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"
                    class="material-icons guard-icon" :class="{ 'guarded': hasGuardPage(site) }">
                    verified_user
                  </span>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  {{
                    hasGuardPage(site) ? 'This Project is monitored by POP Watchdog.' : 'This Project is not monitored by POP Watchdog.'
                  }}
                </div>
              </v-tooltip>
            </span>
            <div class="flex-grow-1"></div>
            <div>
              <v-btn icon :id="`dot-menu-for-${site.id}`">
                <span class="material-icons-outlined mainColor--text">more_horiz</span>
              </v-btn>
              <v-menu
                v-if="!isDemo"
                :activator="`#dot-menu-for-${site.id}`"
                bottom offset-y>
                <v-list>
                  <v-list-item link @click="$store.getters.isDemoUser ? null : enterProjectSetting(site)">
                    <v-list-item-title
                      class="mainColor--text">
                      Project setting
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="$store.getters.isDemoUser ? null : displayDeleteSiteConfirmationDlg(site.id)">
                    <v-list-item-title
                      class="mainColor--text">
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="main-part">
            <div class="flex-grow-1">
              <div class="mt-5">
                <img src="/static/site-icon.png" />
              </div>
              <div class="mt-3 tooltip is-tooltip-top"
                style="max-width: 100%;" :data-tooltip="site.name">
                <div class="project-name truncate-ellipse-one-line">
                  {{ site.name }}
                </div>
              </div>
              <div class="mt-1 mainColor--text font-weight-regular">
                Created on {{ getDateAsLocalString(site.createdAt) }}
              </div>
              <!-- <div v-if="site.lastPageRunInfo" class="mt-3">
                <div class="score-chart-box" v-if="site.lastPageRunInfo.pageNotBuilt">
                  <radial-progress-bar 
                    :diameter="70"
                    :completed-steps="0"
                    :total-steps="100"
                    :strokeWidth="8"
                    startColor="#40c8f9"
                    stopColor="#40c8f9"
                    :innerStrokeColor="'#eeeeee'"
                    strokeLinecap="square"
                    >
                    <div class="has-text-weight-bold" :style="{ 'color': '#40c8f9' }">N/A</div>
                  </radial-progress-bar>
                  <div class="score-label">
                    <span>Optimization Score</span>
                    <div class="score-explain">No Optimization Score just yet. After creating your content, re-run POP with that content to see your score.</div>
                  </div>
                </div>
                <div class="score-chart-box" v-else>
                  <radial-progress-bar 
                    :diameter="65"
                    :completed-steps="getStrategyScore(site)"
                    :total-steps="100"
                    :strokeWidth="8"
                    :startColor="`${pageScoreTintColor(getStrategyScore(site))}FF`"
                    :stopColor="`${pageScoreTintColor(getStrategyScore(site))}55`"
                    :innerStrokeColor="`${pageScoreTintColor(getStrategyScore(site))}22`"
                    strokeLinecap="square"
                    >
                    <div class="has-text-weight-bold" :style="{ 'color': pageScoreTintColor(getStrategyScore(site)) }">
                      {{ getStrategyScore(site) | round(1) }}
                    </div>
                  </radial-progress-bar>
                  <div class="score-label">
                    <div v-html="getStrategyScoreType(site)"></div>
                    <div class="mt5">
                      <span class="grey--text text--darken-1 last-report">
                        Last report: {{ site.lastPageRunInfo ? _.utc(site.lastPageRunInfo.createdAt).format('MMM DD, YYYY') : 'None' }}
                      </span>
                    </div>
                  </div>
                </div>
                
                <div class="score-chart-box ml5">
                  <radial-progress-bar 
                    :diameter="70"
                    :completed-steps="site.lastPageRunInfo.altScore"
                    :total-steps="100"
                    :strokeWidth="8"
                    :startColor="pageScoreTintColor(site.lastPageRunInfo.altScore)"
                    :stopColor="pageScoreTintColor(site.lastPageRunInfo.altScore)"
                    :innerStrokeColor="'#eeeeee'"
                    >
                    <p class="has-text-weight-bold" :style="{ 'color': pageScoreTintColor(site.lastPageRunInfo.altScore) }">{{ site.lastPageRunInfo.altScore | round(1) }}</p>
                  </radial-progress-bar>
                  <div class="score-label">
                    <span>ADJ.<br/>SCORE</span>
                  </div>
                </div>
              </div> -->
            </div>
            <footer>
              <v-btn
                rounded
                color="profileAnchorColor"
                :to="`/sites/${site.id}/tools`"
                class="view-project-page-button whiteColor--text text-none">
                <span>
                  Open
                </span>
              </v-btn>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <paginate
      v-if="pageCount > 1"
      :value="currentPageForSite"
      :page-count="pageCount"
      prev-text="<i class='material-icons'>navigate_before</i>"
      next-text="<i class='material-icons'>navigate_next</i>"
      container-class="card-pagination"
      :click-handler="paginateFunc">
    </paginate>
  </section>

  <div class="modal" :class="{'is-active': showDeleteConfirmModal}">
    <div class="modal-background" @click="showDeleteConfirmModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div class="title-font is-size-4 mainColor--text">You’re about to delete this project.</div>
          <div class="mt-5 mainColor--text">
            Are you sure you want to delete this project? <br/>
            <span v-if="user.accountType === 'trial'">On a free trial if you delete this project you won't be able to use this domain again.</span>
          </div>
          <div class="control flex mt20">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded outlined
              color="mainColor"
              class="button-with-icon mainColor--text text-none"
              @click="showDeleteConfirmModal=false">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whiteColor--text" @click="deleteSite">
              <i class="fa fa-trash mr5"></i>
              <span>Delete</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showDeleteConfirmModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

  <run-best-practices-model ref='bestPractices'/>

  <div class="modal" :class="{'is-active': showNoPermissionToCreateProject}">
    <div class="modal-background" @click="showNoPermissionToCreateProject = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <p class="title"></p>
        <p class="subtitle">
          {{ (user.accountType == 'sub_account' && $store.state.selectedSubAccountRelation=='') ? 'You currently have access to PageOptimizer Pro through a Sub Account.  You must purchase your own subscription to create projects in your own account.' : 'You don\'t have access to create a project in this Primary Account.  Please contact the account owner to give you permission.' }}
        </p>
        <div class="field has-addons">
          <div class="control">
            <button class="button is-warning" @click="showNoPermissionToCreateProject = false">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <project-setting-modal ref="projectSettingModal" />


  <div class="modal" :class="{'is-active': showDeleteSitesConfirmModal}">
    <div class="modal-background" @click="showDeleteSitesConfirmModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="subtitle-font mainColor--text">
            Are you sure you want to delete these Projects and all their Pages and Reports? <br/>
          </div>
          <div class="mt-3 mb-3 mainColor--text">
            The following projects have been selected:
          </div>
          <div v-for="site in selectedSites" :key="site.id" style="max-height: 400px; overflow-y: auto;">
            <span class="has-text-weight-bold mainColor--text">- {{ site.name }}</span>
            <span class="ml-2 mainColor--text">{{ getDateAsLocalString(site.createdAt) }}</span>
          </div>
          <div class="control flex mt20">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded color="grayColor"
              class="button-with-icon text-none gray2Color--text"
              @click="showDeleteSitesConfirmModal=false">
              <i class="fa fa-times mr5"></i>
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="lightGreenColor"
              class="button-with-icon text-none whiteColor--text" @click="deleteSites">
              <i class="fa fa-trash mr5"></i>
              <span>Delete</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showDeleteSitesConfirmModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

</div>
</template>

<script>
import moment from 'moment'
import RadialProgressBar from 'vue-radial-progress'
import AppMenu from '@/components/Menu.vue'
import ProjectSettingModal from '@/components/ProjectSettingModal.vue'
import RunBestPracticesModel from '@/components/PageRun/RunBestPracticesModal.vue'
import { mixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import Paginate from 'vuejs-paginate'


export default {
  components: {
    RadialProgressBar,
    AppMenu, ProjectSettingModal, RunBestPracticesModel,
    Paginate,
  },
  mixins: [ mixin, ServiceMixin ],
  data () {
    return {
      selectedSiteId: 0,
      showDeleteConfirmModal: false,
      showNoPermissionToCreateProject: false,


      selectedSiteIds: [],
      showDeleteSitesConfirmModal: false,


      ro: null,
    }
  },
  computed: {
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    _ () {
      return moment
    },
    selectedOwnerAccount () {
      return this.$store.state.selectedSubAccountRelation
    },
    selectedSites () {
      return this.selectedSiteIds.map(siteId => {
        let found = this.sites.find(ele => {
          return ele.id==siteId
        })
        return found
      })
    },
    sitesPerPage () {
      return this.$store.state.sitesPerPage
    },
    currentPageForSite () {
      return this.$store.state.currentPageForSite
    },
    pageCount () {
      return Math.ceil(this.sites.length / this.sitesPerPage)
    },
    paginatedSites () {
      let from = (this.currentPageForSite - 1) * this.sitesPerPage
      let to = this.currentPageForSite * this.sitesPerPage
      return this.sites.slice(from, to)
    }
  },
  methods: {
    paginateFunc (pageNumber) {
      this.$store.commit('setCurrentPageForSite', pageNumber)
    },
    isSelectedSite(siteId) {
      return this.selectedSiteIds.includes(siteId)
    },
    selectSite (siteId) {
      if (this.selectedSiteIds.indexOf(siteId)===-1) {
        this.selectedSiteIds.push(siteId)
      }
    },
    unSelectSite (siteId) {
      let idx = this.selectedSiteIds.indexOf(siteId)
      if (idx>=0) {
        this.selectedSiteIds.splice(idx, 1)
      }
    },
    cancelAllSelection () {
      this.selectedSiteIds = []
    },
    addSite () {
      if (this.hasAbilityOfCreateProject()) {
        this.$router.push('/newsite')
      } else {
        this.showNoPermissionToCreateProject = true
      }
    },
    pageRunCountForSite(site) {
      let total = 0
      site.pages.map(p => total +=p.pageRunCount)
      return total
    },
    showBestPractices () {
      this.$refs.bestPractices.show()
    },
    displayDeleteSiteConfirmationDlg (siteId) {
      this.selectedSiteId = siteId;
      this.showDeleteConfirmModal = true;
    },
    deleteSite () {
      let siteId = this.selectedSiteId;
      // console.log('user is', this.user.accountType);
      this.showDeleteConfirmModal = false;
      this.$store.commit('showLoading')
      this.$store.dispatch('removeSite', { siteId })
        .then((response) => this.$store.dispatch('loadSites'))
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('setSites', response.data.sites)
          }
          return 1
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    deleteSites () {
      if (this.selectedSiteIds.length>0) {
        this.$store.commit('showLoading')
        this.$store.dispatch('removeSites', { siteIds: this.selectedSiteIds })
          .then((response) => {
            this.selectedSiteIds = []
            return this.$store.dispatch('loadSites')
          })
          .then((response) => {
            if (response.data && response.data.status === 'SUCCESS') {
              this.$store.commit('setSites', response.data.sites)
              return 1
            }
          })
          .finally(() => {
            this.showDeleteSitesConfirmModal = false
            this.$store.commit('hideLoading')
          })
      }      
    },
    enterProjectSetting (site) {
      this.$refs.projectSettingModal.show(site)       
    },
    hasGuardPage (site) {
      for (const page of site.pages) {
        if (page.isGoogleGuardEnabled) {
          return true
        }
      }
      return false;
    },
    pinProjectAction (siteId, isPinned) {
      this.$store.commit('showLoading')
      this.$store.dispatch('saveProjectSettings', {
        siteId,
        changePinOnly: true,
        isPinned: isPinned ? 1 : 0,
      })
      .then((res) => {
        if (res.data && res.data.status=='SUCCESS') {
          this.$store.commit('setSite', res.data.site)
        } else {
          let error = res.data.error ? res.data.error : ''
          window.alert('Can not update project settings.\n' + error)
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    // onResize () { // change grid
    //   const element = this.$refs.observer;
    //   const cardWidth = 345;
    //   if (!element) return;
    //   const width = element.clientWidth;
    //   let style = `repeat(4, ${cardWidth}px)`
    //   console.log('width is', width);
      
    //   if ( width < 1460) { // $site-card-width * 4 + 10 (gap) * 3  = 1410 + (50px for extra)
    //     style = `repeat(3, ${cardWidth}px)`
    //   }
    //   if ( width < 1105) { // $site-card-width * 3 + 10 (gap) * 2  = 1055 + (50px for extra)
    //     style = `repeat(2, ${cardWidth}px)`
    //   }
    //   if ( width < 750) { // $site-card-width * 2 + 10 (gap) * 1  = 700 + (50px for extra)
    //     style = `repeat(1, ${cardWidth}px)`
    //   }
    //   if ( width < 350) { // $site-card-width + (5 px extra)
    //     style = `1fr`;
    //   }
    //   console.log('set for element of grid', style);
    //   this.$refs.sitesContainer.style.gridTemplateColumns = style;
    // }
  },
  beforeMount () {
    // if (!this.sites.length) {
    this.$store.commit('showLoading')
    return this.$store.dispatch('loadSites')
      .then((response) => {
        if (response.data && response.data.status === 'SUCCESS') {
          this.$store.commit('setSites', response.data.sites)
          this.$store.commit('hideLoading')
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    // }
  },
  mounted () {
    this.$store.commit('setHideSideBar', this.$store.state.isUserClosedSideBar)
    // sitesContainer
    // this.ro = new ResizeObserver(this.onResize)
    // this.ro.observe(this.$refs.observer);
  },
  beforeUnmount() {
    // this.ro.unobserve(this.$refs.observer) 
  },
}
</script>

<style lang="scss" scoped>

.delete-site {
  position: relative;
  top: 0px;
  right: -10px;
}

.add-site {
  width: 50%;
  display: inline-block;
  cursor: pointer;
}

.sites {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.site {
  margin: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: lightblue;
  color: black;
  width: 30%;
  min-width: 150px;
  min-height: 150px;
  border-radius: 7px;
}

.page-title {
  width: 50%;
  display: inline-block;
}



.hero-body {
  padding: 1rem 1.5rem 0rem 1.5rem !important;
}
</style>
