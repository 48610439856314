<template>
<div class="email-change-result">
  <div class="container center-screen">
    <div class="field has-text-centered">
      <img src="/static/long_logo@4x.png" width="400">
    </div>
    <div class="notification is-danger is-size-4" v-if="emailIsInUse">
      This email is already in use.
    </div>
    <div v-else-if="expired" class="notification is-warning is-size-4">
      Your email change link is expired. Please try again.
    </div>
    <div class="notification is-primary is-light is-size-4" v-else>
      Success! Your account email has been changed.
    </div>

    <div class="has-text-right">
      <a @click.stop="gotoLoginPage" class="button is-text is-size-4">Click here to login</a>
    </div>
  </div>
</div>
</template>


<script>
export default {
  data () {
    return {
      emailIsInUse: 0,
      expired: 0,
    }
  },
  methods: {
    gotoLoginPage () {
      this.$router.push('/login')
    }
  },
  beforeMount () {
    this.emailIsInUse = this.$route.query.emailIsInUse
    this.expired = this.$route.query.expired
  }
}
</script>


<style lang="scss" scoped>
  .email-change-result {
    .center-screen {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>