
export const PageRunPages = Object.freeze({
  ADD_KEYWORD: 1,
  CHOOSE_LANGUAGE_AND_REGION: 2,
  VARIATIONS: 3,
  ADD_VARIATIONS: 3,
  REMOVE_VARIATIONS: 4,
  ADD_PAGE_TO_OPTIMIZE: 5,
  METHOD_OF_COMPETITOR_SELECTION: 6,
  ADD_COMPETITORS: 7,
  SELECT_FOCUS_GROUP: 8,
  LSA: 9,
  SUBMITTING_PAGE_RUN: 10
});

