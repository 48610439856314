<template>
  <div class="modal" :class="{'is-active': showModal}">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-content" style="max-width: 800px;">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="title-font is-size-4 mb20">Settings for project - {{ site.name }}</div>
          <!-- <div class="subtitle"></div> -->
          <v-text-field dense
            type="text"
            label="Proect name"
            color="greenColor"
            v-model="siteName"
            hide-details="auto"
            outlined
          ></v-text-field>
          <div class="d-flex mt-5 gap20">
            <div class="flex-grow-1">
              Default location
              <div class="control">
                <!-- <div class="select is-fullwidth">
                  <select v-model="proxyLocation">
                    <option v-for="p in proxies" :key="p.location" :value="p.location">{{ p.location }}</option>
                  </select>
                </div> -->
                <multiselect
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                  v-model="proxyLocation"
                  :options="googleSearchLocations"
                  placeholder="Select location"
                  ></multiselect>
              </div>
            </div>
            <div class="flex-grow-1">
              Default target langauge
              <multiselect
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                v-model="targetLang"
                :options="targetLanguages"
                placeholder="Select language"
                @select="handleLanguageSelected">
                <template v-slot:option="props">
                    {{ props.option | capitalize }}
                </template>
                <template v-slot:singleLabel="props">
                    {{ props.option | capitalize }}
                </template>
              </multiselect>
              <!-- <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="targetLang">
                    <option v-for="lang in targetLanguages" :key="lang" :value="lang">{{ lang | capitalize }}</option>
                    <option value="other" placeholder="select location">Other Language</option>
                  </select>
                </div>
              </div> -->
            </div>
          </div>
          <div class="mt-5">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" v-model="ignoreSpaces">
                <span>Special language support</span>
              </label>
            </div>
            <!-- <div class="control mt-1">
              <label class="checkbox">
                <input type="checkbox" v-model="useAdvancedStrategyByDefault">
                <span>Default to legacy advanced strategies (not recommended)</span> &nbsp;
              </label>
            </div> -->
          </div>
          
          
          <div class="mt-5 text-right">
            <v-btn rounded outlined
              color="mainColor"
              class="button-with-icon  mainColor--text is-danger text-none" @click="deleteProjectAction">
              <i class="material-icons-outlined">delete</i>
              <span>Delete project</span>
            </v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none ml-2"
              @click="saveProjectSettingsAction" :disabled="siteName.trim()==''">
              <i class="fa fa-floppy-o mr5"></i>
              <span>Save changes</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: { Multiselect },
  data () {
    return {
      showModal: false,

      proxies: [],

      site: {},
      siteName: '',
      proxyLocation: '',
      targetLang: '',
      ignoreSpaces: false,
      useAdvancedStrategyByDefault: false,

      errorMsg: '',
    }
  },
  computed: {
    targetLanguages () {
      return this.$store.state.targetLanguages
    },
    googleSearchLocations () {
      return this.$store.state.googleSearchLocations
    }
  },
  methods: {
    handleLanguageSelected (option, id) {
      const val = option.includes('extension') ? 'extension_use' : option
      this.targetLang = val
    },
    show(site) {
      this.loadProxiesAndLanguages()
      
      if (site) {
        this.site = site
        this.siteName = site.name

        let settings = site.settings
        if (settings) {
          this.targetLang = settings.targetLang
          this.ignoreSpaces = settings.ignoreSpaces==1
          this.proxyLocation = settings.proxyLocation
          this.useAdvancedStrategyByDefault = settings.useAdvancedStrategyByDefault == 1
        }
        this.showModal = true
      }
    },
    saveProjectSettingsAction () {
      if (!this.site) return

      this.$store.commit('showLoading')
      this.$store.dispatch('saveProjectSettings', {
        siteId: this.site.id, 
        siteName: this.siteName,
        targetLang: this.targetLang, 
        proxyLocation: this.proxyLocation,
        ignoreSpaces: this.ignoreSpaces ? 1 : 0,
        useAdvancedStrategyByDefault: this.useAdvancedStrategyByDefault ? 1 : 0,
      })
      .then((res) => {
        if (res.data && res.data.status=='SUCCESS') {
          this.showModal = false
          this.$store.commit('setSite', res.data.site)
        } else {
          let error = res.data.error ? res.data.error : ''
          window.alert('Can not update project settings.\n' + error)
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    deleteProjectAction () {
      if (window.confirm('Are you really delete this project?')) {
        let siteId = this.site.id

        this.$store.commit('showLoading')
        this.$store.dispatch('removeSite', { siteId })
          .then((response) => this.$store.dispatch('loadSites'))
          .then((response) => {
            if (response.data && response.data.status === 'SUCCESS') {
              this.$store.commit('setSites', response.data.sites)
              this.$store.commit('setCurrentSiteById', { siteId: '' })
              this.$router.push(`/sites`)
              this.showModal = false
            }
            return 1
          })
          .finally(() => {
            this.$store.commit('hideLoading')
          })
      }
    },

    loadProxiesAndLanguages () {
      // this.$store.commit('showLoading')
      // return this.$store.dispatch('fetchAvailableProxies')
      //   .then((response) => {
      //     let proxyMap = {}
      //     response.data.forEach((p) => {
      //       proxyMap[p.location] = p
      //     })
      //     this.proxies = Object.values(proxyMap)
      //     return 1
      //   })
      //   .catch((err) => {
      //     console.log('Errro fetching proxies', err)
      //   })
      //   .finally(() => {
      //     this.$store.commit('hideLoading')
      //   })
      if (!this.googleSearchLocations || !this.googleSearchLocations.length) {
        this.$store.commit('showLoading')
        this.$store.dispatch('fetchGoogleSearchLocations')
          .finally(() => {
            this.$store.commit('hideLoading')
          })
      }
    },
  },
  beforeMount () {
    
  }
}
</script>

<style lang="scss" scoped>
div {
  color: var(--v-mainColor-base);
}
  
</style>