<template>
<div>
    <div v-if='isLoading' id='spinner-wrapper'>
        <!-- <div id="spinner"></div> -->
        <atom-spinner
          id="spinner"
          :animation-duration="1000"
          :size="70"
          :color="'#FF9B2D'"
        />
        <!-- <fingerprint-spinner
          id="spinner"
          :animation-duration="1500"
          :size="64"
          color="#FF9B2D"
        /> -->
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import {AtomSpinner, FingerprintSpinner } from 'epic-spinners'

export default {
  computed: mapState([ 'isLoading' ]),
  components: { AtomSpinner, FingerprintSpinner}
}
</script>

<style>
/* Center the loader */
#spinner-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(199,199,199,0.5);
}

#spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

/* #spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
} */

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

#myDiv {
  display: none;
  text-align: center;
}
</style>
