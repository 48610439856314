<template>
<div>
  <v-autocomplete outlined hide-details dense
    :loading="isLoadingSites"
    :items="userDomains"
    item-text="url"
    item-value="url"
    v-model="selectedDomain"
    color="green7Color"
  >
    <template v-slot:item="data">
      <v-list-item :disabled="!data.item.valid" @click="() => {
        if (data.item.valid) {
          selectedDomain = data.item.url;
        }
      }">
        {{ data.item.url }}
      </v-list-item>
    </template>
  </v-autocomplete>
</div>
</template>

<script>
import { gapi, loadAuth2 } from 'gapi-script';
import { EventEmitter } from '@/utils/Event.js';
import { loadGAPIClient, GOOGLE_OAUTH_SCOPE_DEF, getSitesList } from '@/utils/search-console-helper.js';
import { isRootDomainSame } from '@/utils';

export default {
  props: {
    baseDomain: {
      default: '',
    },
    initialDomain: {
      default: '',
    }
  },
  data () {
    return {
      googleUserCa: null,
      isLoadingSites: false,

      selectedDomain: '',

      sites: [],

    }
  },
  computed: {
    googleUser () {
      return this.$store.state.googleUser;
    },
    userDomains () {
      let results = []
      if (this.sites) {
        this.sites.forEach((site) => {
          try {
              const site$ = site.replace('sc-domain:', '')
              const isValid = isRootDomainSame(site$, this.baseDomain)
              results.push({
                  url: site,
                  valid: isValid
              })
          } catch (e) {
              results.push({
                  url: site,
                  valid: false
              })
          }
        })
      }
      return results
    }
  },
  watch: {
    googleUserCa: function (newVal, oldVal) {
      if (newVal) {
        this.loadDomainsFromGoogle();
      }
    },
    selectedDomain: function (newVal, oldVal) {
      this.$emit('onChangeDomain', newVal);
    }
  },
  methods: {
    initGoogleAuth() {
      const setAuth2 = async () => {
        const auth2 = gapi?.auth2?.getAuthInstance() || await loadAuth2(gapi, GOOGLE_CLIENT_ID, GOOGLE_OAUTH_SCOPE_DEF)
        if (auth2.isSignedIn.get()) {
          const gu = auth2.currentUser.get()
          this.googleUserCa = gu.Ca;
        }
      }
      setAuth2();
    },
    loadDomainsFromGoogle () {
      this.isLoadingSites = true;
      loadGAPIClient().then(() => {
        setTimeout(async () => {
          const res = await getSitesList();
          if (res && res.status == 200) {
            this.sites = (res.result.siteEntry || []).map(s => s.siteUrl);

            // auto select initialDomain if given
            if (this.initialDomain && !this.selectedDomain) {
              const correspondingItem = this.userDomains.filter(
                item => item.valid && item.url.replace('sc-domain:', '') === this.initialDomain
              )[0];
              if (correspondingItem) {
                this.selectedDomain = correspondingItem.url
              }
            }
          }
          this.isLoadingSites = false;
        }, 3000);
      }).catch((e) => {
        console.info('=== loadGAPIClient Error ===', JSON.stringify(e, null, 4))
        this.isLoadingSites = false;
      })
    }
  },
  mounted () {
    this.initGoogleAuth();
    EventEmitter.subscribe('GOOGLE_USER_UPDATE', (gu) => {
      this.googleUserCa = gu?.Ca;
    })
  }

}
</script>

<style lang="scss" scoped>

</style>