
<template>
  <div class="modal" :class="{ 'is-active': true }">
    <div class="modal-background" @click.stop="$emit('close')"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden">
        <div class="modal-content-root" @click.stop="() => {}">
          <div class="title-font is-size-4 mainColor--text">
            Connect this silo to a page
          </div>
          <div class="mt-10 mb-10">
            <v-autocomplete
              v-if="pages.length"
              outlined dense hide-details
              placeholder="Select a page"
              color="grayColor"
              item-text="name"
              item-value="id"
              :items="pages"
              v-model="selectedPageId"
            >
            </v-autocomplete>
          </div>
          <div class="control flex mt20">
            <div class="flex-grow-1"></div>
            <v-btn rounded outlined color="mainColor" class="button-with-icon mainColor--text text-none"
              @click.stop="$emit('close')">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="profileAnchorColor" class="button-with-icon text-none whiteColor--text"
              :disabled="!selectedPageId"
              @click.stop="submit">
              <span>Connect</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click.stop="$emit('close')">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: ['silo'],
  data() {
    return {
      pages: [],
      selectedPageId: null
    };
  },
  computed: {
    
  },
  methods: {
    loadPages () {
      return this.$store.dispatch('loadPages', {siteId: this.silo.siteId})
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.pages = response.data.pages
          }
        })
    },
    submit() {
      this.$store.commit('showLoading');
      this.$store
        .dispatch('updateSilo', {
          siloId: this.silo.id,
          body: { pageId: this.selectedPageId },
        })
        .then(() => {
          this.$emit('submitted');
        })
        .finally(() => {
          this.$store.commit('hideLoading');
        });
    }
  },
  mounted() {
    this.loadPages()
  }
};

</script>

