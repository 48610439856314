<template>
  <div class="container">
    <div class="guardrun-box pa-7">
      <div>
        <div class="d-flex align-center gap20">
          <div>
            <div class="status-circle" :class="{
              'task-pending': guardDiffData.new_tasks_pending,
              'all-good': !guardDiffData.new_tasks_pending
            }">
              <span class="material-icons" v-if="guardDiffData.new_tasks_pending">warning</span>
              <span class="material-icons" v-else>emoji_emotions</span>
            </div>
          </div>
          <div class="flex-grow-1">
            <div class="page-title mt-3 d-flex align-center gap10">
              Watchdog report
              <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
                @click="() => {
                  $store.commit('setCurrentVideoTutorialToDisplay', {
                    title: 'How to read POP Content Brief',
                    videoLink: 'https://www.youtube.com/embed/1RLVj1lrApk?si=Q7qO0dQjRLR_BmuJ',
                    description: 'Understand the recommendations POP provides for your pages in the Content Brief.',
                    time: '6m 02s'
                  });
                }">
                videocam
              </span>
            </div>
            <div class="d-flex">
              <div class="serp-status" :class="{
                'all-good': !guardDiffData.new_tasks_pending,
                'tasks-pending': guardDiffData.new_tasks_pending
              }">
                <span v-if="hasDroppedOutOfTop10Url" class="has-fallen">
                  <template v-if="guardDiffData.url_old_rank && guardDiffData.url_old_rank <=10">
                    Status: Target page has fallen off of page one!
                  </template>
                  <template>
                    Status: your page has dropped!
                  </template>
                </span>
                <span v-else-if="guardDiffData.new_tasks_pending" class="task-pending">Status: New tasks pending</span>
                <span v-else class="all-good-text">Status: All good!</span>
              </div>
            </div>
          </div>
          <div>
            <v-btn outlined rounded
              class="text-none mainColor--text"
              @click="openPageSettingsModal"
            >
              Watchdog settings
            </v-btn>
          </div>
        </div>
        
        <div class="mt-3">
          <div v-if="!guardDiffData.new_tasks_pending" class="serp-state-description">
            No immediate action required. Observational changes noted in SERP landscape (see competitor changes and rank fluctuations below).
          </div>
          <div v-if="guardDiffData.new_tasks_pending" class="serp-state-description">
            There has been recent movement in the SERP for the page below. That movement may be due to a Google update, competitors increasing their optimization, or new competitors in the top 10 (see competitor changes and rank fluctuations below).
          </div>
        </div>
      </div>
      <div class="mt-5">
        <div class="item-label">KEYWORDS:</div>
        <div class="item-text mt-2">
          <span class="variation-tag" @click="() => gotoPage(`/sites/${site.id}/pages/${currentPageRun.pageId}?scroll_to_wd=true`)">{{ currentPageRun.keyword }}</span>
          <span v-for="(k) in currentPageRun.secondaryKeywords" :key="k"
            class="variation-tag">
            {{ k }}
          </span>
        </div>
      </div>
      <div class="mt-5" v-if="currentPageRun.secondaryKeywords > 0">
        <div class="item-label">SECONDARY KEYWORDS:</div>
        <div class="item-text mt-2">
          <span v-for="(k) in currentPageRun.secondaryKeywords" :key="k"
            class="variation-tag">
            {{ k }}
          </span>
        </div>
      </div>
      <div class="mt-5 run-common-info">
        <div>
          <div class="item-label">Page monitored</div>
          <div class="item-text mb-2">{{ page.name }}</div>
          <a class="url-link"
            :href="currentPageRun.url" target="_blank">{{ currentPageRun.url }}</a>
        </div>
        <div>
          <div class="item-label">Location</div>
          <div class="item-text">
            {{
              currentPageRun.googleLocation == "extension_use"
                ? "United States"
                : currentPageRun.googleLocation
            }}
          </div>
        </div>
      </div>
      <div class="mt-7 score-and-rank-row">
        <div>
          <div class="icon-part">
            <span class="material-icons">donut_large</span>
          </div>
          <div>
            <div class="score-label">Optimization Score:</div>
            <div class="score-val-part">
              <div class="score-val">
                {{ guardScore }}
              </div>
              <div class="score-diff">
                <span v-if="guardScore > originScore" class="increased">
                  +{{ (guardScore - originScore) | round(2) }}
                </span>
                <span v-else-if="guardScore < originScore" class="decreased">
                  {{ (guardScore - originScore) | round(2) }}
                </span>
                <span v-else>
                  (no change)
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="icon-part">
            <span class="material-icons">leaderboard</span>
          </div>
          <div>
            <div class="score-label">CURRENT RANK:</div>
            <div class="score-val-part">
              <div class="score-val">
                {{ currentRank }}
              </div>
              <div class="score-diff">
                <span v-if="rankDiff > 0" class="increased">
                  <!-- <span class="material-icons">arrow_drop_up</span> -->
                  ▲ {{ rankDiff }}
                </span>
                <span v-else-if="rankDiff < 0" class="decreased">
                  <!-- <span class="material-icons">arrow_drop_down</span> -->
                  ▼ {{ rankDiff }}
                </span>
                <span v-else>
                  N/A
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </div>

    <div class="guardrun-box pa-7 mt-5" v-if="checklistSectionData">
      <div class="section-title">SEO Action Items Checklist</div>
      <div v-for="(item, idx) in checklistSectionData" :key="'one-section' + idx" class="one-section">
        <div class="section-label">
          <span class="circle-index">{{ idx + 1 }}</span>
          {{ item.section_label }}
        </div>
        <div v-for="(item1, idx1) in item.data" :key="'list-item' + idx1" class="list-item">
          <div class="main">
            <span class="icon-part" :class="{ 'is-added': allWatchdogTaskTexts.includes(item1) }">
              <span class="material-icons add-task-icon"
                @click="() => {
                  addToWorkstation(item1)
                }">add_task</span>
              <!-- <span class="material-icons-outlined">tips_and_updates</span> -->
            </span>
            <span class="checklist-one-text" v-html="item1"></span>
            <span
              v-if="currentPageRun && item.section_label === 'Optimize schema markup'"
              class="url-link cursor-pointer" style="margin-left: -5px;"
              @click="() => gotoPage(`/sites/${site.id}/pages/${currentPageRun.pageId}/pageruns/${currentPageRun.originalPageRunId}?activeTab=schemaType&scroll_to_schema=true`)"
            >
              Click here
            </span>
          </div>
          <div class="extra" v-if="item.should_disp_related_questions">
            <ul>
              <li
                v-for="(relatedQuestion, i) in new_related_questions"
                :key="`${i}-related-question-checlist`"
              >
              {{ relatedQuestion.question }}
              </li>
            </ul>
        </div>
        </div>
      </div>

    </div>

    <div class="guardrun-box pa-7 mt-5">
      <div class="section-title">Competitor Activity</div>
      <div>
        <div class="section-sub-title mt-10">NEW ENTRANTS:</div>
        <div v-if="newCompetitors.length > 0" class="section-list">
          <div
            v-for="item in newCompetitors"
            :key="item.item.url"
            :class="{ 'bold-green-url': isSameUrl(item.item.url, currentPageRun.url) }"
          >
            <div class="url-title mt-5 mb-1">{{  item.item.title }}</div>
            <!-- {{ item.item.new_rank }} -->
            <a :href="item.item.url" target="_blank" class="url-link">
              {{ shortenUrl(item.item.url) }}
              {{ extraExplainTextOfUrl(item.item.url, currentPageRun.url) }}
            </a>
          </div>
        </div>
        <div v-else>
          No new competitors
        </div>
      </div>
      <div>
        <div class="section-sub-title mt-10">
          DROPPED OUT:
          <span v-if="hasDroppedOutOfTop10Url" class="bold-red-url">
            Your target page has dropped out of the top 10
          </span>
        </div>
        <div v-if="removedCompetitors.length > 0" class="section-list">
          <div
            v-for="item in removedCompetitors"
            :key="item.url"
            :class="{ 'bold-red-url': isSameUrl(item.item.url, currentPageRun.url) }"
          >
            <div class="url-title mt-5 mb-1">{{  item.item.title }}</div>
            <a :href="item.item.url" target="_blank" class="url-link">
              {{ shortenUrl(item.item.url) }}
              {{ extraExplainTextOfUrl(item.item.url, currentPageRun.url) }}
            </a>
          </div>
        </div>
        <div v-else>
          No out competitors
        </div>
      </div>
    </div>

    <div class="guardrun-box pa-7 mt-5">
      <div class="section-title">
        Rank Fluctuations: <span class="improved-number">{{ rankChangeImprovedCount }}</span> improved, <span class="declined-number">{{ rankChangeDeclinedCount }}</span> declined
      </div>
      <div v-if="rankChangeCompetitors.length > 0">
        <table class="rank-change-table">
          <thead>
            <tr>
              <th>Competitor</th>
              <th>Old rank</th>
              <th>New rank</th>
              <th>Change</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in rankChangeCompetitors" :key="item.url">
              <td>
                <a :href="item.item.url" target="_blank">
                  {{ getHostName(item.item.url) }}
                </a>
              </td>
              <td>{{ item.old_rank }}</td>
              <td>{{ item.new_rank }}</td>
              <td>
                <span v-if="item.old_rank && item.new_rank" class="rank-diff-cell"
                  :class="{
                    'rank-up': item.old_rank >= item.new_rank,
                    'rank-down': item.old_rank < item.new_rank
                  }">
                  <template v-if="item.old_rank < item.new_rank">
                    <span class="material-icons">arrow_drop_down</span>
                    {{ item.new_rank - item.old_rank }}
                  </template>
                  <template v-else>
                    <span class="material-icons">arrow_drop_up</span>
                    {{ item.old_rank - item.new_rank }}
                  </template>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        No Rank Change competitors
      </div>
      <!-- <div class="flex-grow-1 flex-basis-0">
        <div class="section-sub-title">Current Top 10:</div>
        <div v-if="currentTop10Competitors.length > 0" class="section-list">
          <div
            v-for="(item, idx) in currentTop10Competitors"
            :key="item.url"
            :class="{ 'bold-green-url': isSameUrl(item.url, currentPageRun.url) }"
          >
            <div>{{ idx + 1 }}:
            <a :href="item.url" target="_blank" class="url-link">
              {{ shortenUrl(item.url) }}
              {{ extraExplainTextOfUrl(item.url, currentPageRun.url) }}
            </a>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <template v-if="guardDiffData.origin_content_brief_data">
      <div
        class="guardrun-box pa-7 mt-5"
        v-for="section in contentBriefSections"
        :key="section.sectionTotalName"
      >
        <div class="section-title">{{ section.label }}</div>
        <div class="flex-container">
          <div class="flex-grow-1 flex-basis-0">
            <span class="section-sub-title">Important Term Range Change </span>
            <span class="ml-3">
              Previous 
              (
              {{ guardDiffData.origin_content_brief_data[section.sectionTotalName]["min"] }}
              -
              {{ guardDiffData.origin_content_brief_data[section.sectionTotalName]["max"] }}
              ) 
              Now ( 
              {{ cleanedContentBrief[section.sectionTotalName]["min"] }} -
              {{ cleanedContentBrief[section.sectionTotalName]["max"] }} 
              )
            </span>
          </div>
          <div class="flex-grow-1 flex-basis-0">
            <div class="d-flex">
              <div class="flex-grow-1">
                <span class="section-sub-title">
                  Current Count: 
                  {{ cleanedContentBrief[section.sectionTotalName]["current"] }}
                </span>
                <span class="ml-3">
                  {{ getRecommendationText(cleanedContentBrief[section.sectionTotalName]) }}
                </span>
              </div>
              <div class="expand-icon-wrap"
                @click="() => toggleExpandOfSection(section.sectionName)">
                <i v-if="showDetailTermsForSections.includes(section.sectionName)" class="material-icons-outlined">indeterminate_check_box</i>
                <i v-else class="material-icons-outlined">add_box</i>
              </div>
            </div>
          </div>
        </div>
        <div v-show="showDetailTermsForSections.includes(section.sectionName)">
          <individual-terms-for-section
            :sectionName="section.sectionName"
            :contentBriefData="cleanedContentBrief"
          ></individual-terms-for-section>
          <div class="text-right">
            <v-btn rounded x-small
              color="profileAnchorColor"
              class="text-none whiteColor--text mt-3"
              @click="showContentBrief">
              View more details
            </v-btn>
          </div>
        </div>
      </div>
    </template>

    <!-- <div class="guardrun-box pa-7 mt-5">
      <div class="flex-container">
        <div class="flex-grow-1 flex-basis-0 pr20">
          <div class="section-title">New Related Keywords:</div>
          <ul>
            <li
              v-for="(relatedSearch, i) in new_related_searches"
              :key="`${i}-related-search`"
            >
              <a :href="relatedSearch.link" target="_blank" class="url-link">{{
                relatedSearch.query ? relatedSearch.query : relatedSearch
              }}</a>
            </li>
          </ul>
        </div>
        <div class="flex-grow-1 flex-basis-0">
          <div class="section-title">New Related Questions:</div>
          <ul>
            <li
              v-for="(relatedQuestion, i) in new_related_questions"
              :key="`${i}-related-question`"
            >
              <div style="display: flex; justify-content: space-between;">
                {{ relatedQuestion.question }}
                <a @click.stop="toggleQuestion(relatedQuestion)">
                  <span
                    v-show="expandedQuestion !== relatedQuestion.title"
                    class="material-icons fold-icon"
                    >expand_more</span
                  >
                  <span
                    v-show="expandedQuestion === relatedQuestion.title"
                    class="material-icons fold-icon"
                    >expand_less</span
                  >
                </a>
              </div>

              <div v-show="expandedQuestion === relatedQuestion.title">
                {{ relatedQuestion.snippet }}
                <p>
                  <a :href="relatedQuestion.link" target="_BLANK">{{
                    relatedQuestion.displayed_link
                  }}</a>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import IndividualTermsForSection from "@/components/PageRun/GuardRun/IndividualTermsForSection.vue";
import { 
  getHostName,
  numberToRankEng, shortenUrl, toLocalDateString, isInSameDomain, isSameUrl, extraExplainTextOfUrl 
} from "@/utils";

export default {
  name: "GuardRun",
  props: [
    "page",
    "currentPageRun",
    "showContentBrief", // this is the function to go content brief tab
    "openPageSettingsModal" // this is the function to open page settings modal
  ],
  components: {
    IndividualTermsForSection
  },
  data() {
    return {
      expandedQuestion: "",

      contentBriefSections: [
        {
          sectionTotalName: "titleTotal",
          label: "Search Engine Title",
          sectionName: "title"
        },
        {
          sectionTotalName: "pageTitleTotal",
          label: "Page Title",
          sectionName: "pageTitle"
        },
        {
          sectionTotalName: "subHeadingsTotal",
          label: "SubHeadings",
          sectionName: "subHeadings"
        },
        { sectionTotalName: "pTotal", label: "Main Content", sectionName: "p" }
      ],
      showDetailTermsForSections: [],


      allTasks: [],
    };
  },
  computed: {
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId)) || {};
    },
    targetUrl() {
      return this.currentPageRun.url;
    },
    guardDiffData() {
      return this.currentPageRun.guardDiffData;
    },
    currentRank () {
      if (this.guardDiffData) {
        if (this.guardDiffData.url_new_rank) {
          let suffix = ''
          switch (this.guardDiffData.url_new_rank) {
            case 1:
              suffix = 'st'
              break
            case 2:
              suffix = 'nd'
              break
            case 3:
              suffix = 'rd'
              break
            default:
              suffix = 'th'
              break
          }
          return this.guardDiffData.url_new_rank + suffix
        }
      }
      return 'N/A'
    },
    rankDiff () {
      if (this.guardDiffData) {
        let oldRank = this.guardDiffData.url_old_rank
        let newRank = this.guardDiffData.url_new_rank
        if (newRank && oldRank) {
          return oldRank - newRank
        }
      }
      return 0
    },
    originScore() {
      if (this.guardDiffData && this.guardDiffData.origin_score) {
        return this.guardDiffData.origin_score;
      } else {
        return 0;
      }
    },
    guardScore() {
      if (this.guardDiffData && this.guardDiffData.guard_score) {
        return this.guardDiffData.guard_score;
      } else {
        return 0;
      }
    },
    checklistSectionData () {
      return (this.guardDiffData.checklist_section_data || []).filter(item => {
        return item.data.length > 0
      })
    },
    competitorsChange() {
      return this.guardDiffData.competitors_change || [];
    },
    newCompetitors() {
      return this.competitorsChange.filter(item => item.type == "new_url");
    },
    removedCompetitors() {
      return this.competitorsChange.filter(item => item.type == "removed_url");
    },
    rankChangeCompetitors() {
      return this.competitorsChange.filter(item => item.type == "rank_change");
    },
    rankChangeImprovedCount () {
      return this.rankChangeCompetitors.filter(item => item.old_rank > item.new_rank).length
    },
    rankChangeDeclinedCount () {
      return this.rankChangeCompetitors.filter(item => item.old_rank < item.new_rank).length
    },
    currentTop10Competitors() {
      return (this.currentPageRun.gCompetitors || []).slice(0, 10);
    },
    newlyAddedVariations() {
      return this.guardDiffData.newly_added_variations || [];
    },
    removedVariations() {
      return this.guardDiffData.removed_variations || [];
    },
    new_related_searches() {
      return this.guardDiffData.new_related_searches || [];
    },
    new_related_questions() {
      return this.guardDiffData.new_related_questions || [];
    },
    runStrategies() {
      return this.currentPageRun.runStrategies;
    },
    hasDroppedOutOfTop10Url () {
      return this.removedCompetitors.some(item => {
        return this.isSameUrl(item.item.url, this.currentPageRun.url)
      })
    },
    cleanedContentBrief() {
      let found = this.runStrategies.find(
        item => item.dashboardType == "contentBrief"
      );
      if (found) {
        return found;
      }
      return {};
    },
    allWatchdogTaskTexts () {
      return this.allTasks.filter(item => item.type == 7).map(item => item.customRecommendationText)
    }
  },
  methods: {
    toLocalDateString (date) {
      return toLocalDateString(date);
    },
    isInSameDomain (url1, url2) {
      return isInSameDomain(url1, url2);
    },
    isSameUrl (url1, url2) {
      return isSameUrl(url1, url2);
    },
    extraExplainTextOfUrl(targetUrl, url) {
      return extraExplainTextOfUrl(targetUrl, url);
    },
    toggleExpandOfSection(sectionName) {
      if (this.showDetailTermsForSections.includes(sectionName)) {
        this.showDetailTermsForSections = this.showDetailTermsForSections.filter(
          item => item != sectionName
        );
      } else {
        this.showDetailTermsForSections.push(sectionName);
      }
    },
    numberToRankEng(num) {
      return numberToRankEng(num);
    },
    shortenUrl(url, length = null) {
      return shortenUrl(url, length);
    },
    getHostName (url) {
      return getHostName(url)
    },
    toggleQuestion(q) {
      this.expandedQuestion = this.expandedQuestion === q.title ? "" : q.title;
    },
    gotoPage(url) {
      this.$router.push(url);
    },
    gotoGuardDashboard() {
      this.gotoPage("/guard-dashboard")
    },
    // getSectionData(sectionName) {
    //   return {
    //     origin: this.guardDiffData.origin_content_brief_data[sectionName]
    //   }
    // },
    getRecommendationText(sectionData) {
      const current = sectionData.current;
      const minTarget = sectionData.min;
      const maxTarget = sectionData.max;

      if (minTarget > current) {
        return `Add at least ${minTarget - current} terms`;
      } else if (minTarget <= current && maxTarget >= current) {
        return `Leave as is`;
      } else if (maxTarget < current) {
        return `Remove at least ${current - maxTarget} terms`;
      }
    },
    removeTrailingSlash(url) {
      return url[url.length - 1] == "/" ? url.substr(0, url.length - 1) : url;
    },
    isTargetUrl(url) {
      return (
        this.removeTrailingSlash(this.targetUrl) ==
        this.removeTrailingSlash(url)
      );
    },
    addToWorkstation(recommendationText) {
      if (this.allWatchdogTaskTexts.includes(recommendationText)) {
        this.$notify({
          group: 'info', type: 'warning',
          text: 'This is already added.'
        })
        return;
      }
      this.$store.commit('showLoading');
      this.$store.dispatch('addOneToCheckList', {
        pageId: this.page.id,
        type: 7,
        recommendationText: recommendationText,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully added into workstation. '
          })
          if (response.data.page?.checkLists) {
            this.allTasks = response.data.page.checkLists
          }
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to add'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    loadAllTasks () {
      if (!this.currentPageRun) {
        return
      }
      this.$store.commit('showLoading');
      this.$store.dispatch('getAllTasksForPageRun', {
        pageRunId: this.currentPageRun.id,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.allTasks = response.data.tasks
        }
      })
      this.$store.commit('hideLoading');
    }
  },

  mounted() {
    console.log("GuardRun page", this.page);
    this.loadAllTasks();
  }
};
</script>

<style lang="scss" scoped>
@import "../ContentBrief/content-brief.scss";
* {
  font-size: 1rem;
}

.guardrun-box {
  background: var(--v-cardBg-base);
  border-radius: 10px;
  color: var(--v-mainColor-base);
  .material-icons {
    color: var(--v-mainColor-base)
  }
}

.status-circle {
  padding: 20px;
  border-radius: 50%;
  span {
    font-size: 2.1rem;
  }
  &.task-pending {
    background: #FFEFF0;
    span {
      color: #DF323B;
    }
  }
  &.all-good {
    background: #E0FFED;
    span {
      color: #25A97A;
    }
  }
}
.serp-state-description {
  font-size: 1.1rem;
  * {
    font-size: 1.1rem;
  }
  line-height: 150%;
}
.page-title {
  font-size: 21px;
  font-weight: 700;
  color: var(--v-mainColor-base);
}

.serp-status {
  margin-top: 5px;
  font-weight: 800;
  span {
    font-size: 1.1rem;
    font-weight: 800;
  }
  
  .has-fallen {
    color: #DF323B;
  }
  .task-pending {
    color: var(--v-profileAnchorColor-base);
  }
  .all-good-text {
    color: #25A97A;
  }
}

ul.description {
  margin-top: 10px;
  list-style:disc;
  color: var(--v-mainColor-base);
}

.score-and-rank-row {
  display: flex;
  gap: 50px;
  & > div {
    display: flex;
    align-items: center;
    gap: 15px;
    .icon-part {
      padding: 10px;
      border-radius: 5px;
      background: #E2F6FF;
      span {
        color: #51B0FF;
        font-size: 2.1rem;
      }
    }
    .score-label {
      text-transform: uppercase;
      color: var(--v-mainColor-base);
      font-size: 1.2rem;
      font-weight: 700;
    }
    .score-val-part {
      display: flex;
      align-items: end;
      margin-top: 10px;
      gap: 20px;
      .score-val {
        font-size: 1.4rem;
        font-weight: 700;
      }
      .score-diff {
        span {
          font-size: 1.1rem;
          font-weight: 800;
        }
        color: var(--v-mainColor-base);
        .increased, .decreased {
          display: flex;
          align-items: center;
          .material-icons {
            font-size: 1.5rem;
          }
        }
        .increased {
          color: var(--v-green7Color-base);
          .material-icons {
            color: var(--v-green7Color-base);
          }
        }
        .decreased {
          color: var(--v-profileAnchorColor-base);
          .material-icons {
            color: var(--v-profileAnchorColor-base);            
          }
        }
      }
    }
  }
}

.item-label {
  font-size: 1.1rem;
  margin-top: 20px;
  font-weight: bold;
  color: var(--v-mainColor-base);
}
.run-common-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  & > div {
    flex-grow: 1;
  }
  .item-text {
    font-size: 1.1rem;
    margin-top: 5px;
  }
}

.one-section {
  margin-top: 40px;
  .section-label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 1.1rem;
    .circle-index {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      padding: 5px;
      background: var(--v-profileAnchorColor-base);
      color: var(--v-whiteColor-base);
      font-size: 1.1rem;
    }
  }
  .list-item {
    ::v-deep strong, li {
      font-size: 1.1rem !important;
      color: var(--v-mainColor-base) !important;
    }
    .main {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
      .checklist-one-text {
        font-size: 1.1rem;
      }
      .icon-part {
        padding: 5px;
        background: var(--v-lightYellowColor-base);
        display: flex;
        align-items: center;
        justify-content: center;
        .material-icons,
        .material-icons-outlined {
          font-size: 1rem;
          color: var(--v-profileAnchorColor-base);
        }
        .add-task-icon {
          cursor: pointer;
        }
        &.is-added {
          background: var(--v-green4Color-base);
          .material-icons,
          .material-icons-outlined {
            color: var(--v-green7Color-base);
          }
        }
      }
    }
    .extra {
      padding-left: 50px;
      ul {
        list-style: disc;
        li {
          margin-top: 7px;
          font-weight: 700;
        }
      }
    }
  }
}



.rank-change {
  color: rgb(139, 139, 139);
}

.bold-green-url {
  color: #2b8f1c;
  font-weight: bold;
  a {
    color: #2b8f1c;
  }
}
.bold-red-url {
  color: #e43e3e;
  font-weight: bold;
  a {
    color: #e43e3e;
  }
}

.url-title {
  font-weight: 700;
  font-size: 0.9rem;
}
.url-link {
  font-weight: 500;
  color: #0A6CDB;
  text-decoration: underline;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  color: var(--v-mainColor-base);
  margin-bottom: 20px;
}
.section-sub-title {
  font-size: 1rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
  margin-top: 22px;
  margin-bottom: 10px;
}

.section-list {
  & > * {
    margin-bottom: 5px;
  }
}
.rank-change-table {
  width: 100%;
  th, td {
    color: var(--v-mainColor-base);
    font-size: 0.9rem;
    padding: 10px 5px;
  }
  a {
    font-size: 0.9rem;
  }
  td {
    border-top: 1px solid var(--v-grayColor-base);
  }
  .rank-diff-cell {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 0.9rem;
    .material-icons  {
      font-size: 2rem;
    }
    &.rank-up {
      color: var(--v-green7Color-base);
      span {
        color: var(--v-green7Color-base);
      }
    }
    &.rank-down {
      color: #FF1F2D;
      span {
        color: #FF1F2D;
      }
    }
  }
}

ul {
  padding-left: 0 !important;
  li {
    margin-bottom: 5px;
    color: var(--v-mainColor-base);
  }
}

.improved-number {
  font-size: 20px;
  color: var(--v-green7Color-base);
}
.declined-number {
  font-size: 20px;
  color: var(--v-redColor-base);
}

.expand-icon-wrap {
  cursor: pointer;
  i {
    font-size: 1.7rem;
    color: var(--v-profileAnchorColor-base);
  }
}
</style>
