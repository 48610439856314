<template>
<div class="modal" :class="{'is-active': showModal}">
  <div class="modal-background" @click="showModal = false"></div>
  <div class="modal-content">
    <div class="box modal-container" style="overflow: hidden;">
      <div>
        <div class="mainColor--text font-weight-bold is-size-4 mb20">Please purchase credits</div>
        <div class="body-font is-size-6 mainColor--text">
          Your current credit balance is 0. <br/><br/>
          To create a new page, run a report, re-run an existing report, or create an AI article you will need to purchase more credits in your account management dashboard.  Without credits you will still have access to all previous projects, reports, and AI articles.
        </div>
        <div class="control flex mt20">
          <div class="flex-grow-1">
          </div>
          <v-btn
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none" @click="showModal = false">
            <i class="fa fa-arrow-right mr5"></i>
            Ok, I got it
          </v-btn>
        </div>
      </div>
    </div>
    <button class="custom-modal-close-btn" aria-label="close" @click="showModal = false"><i class="material-icons">close</i></button>
  </div>
</div>
</template>


<script>
export default {
  data () {
    return {
      showModal: false,
    }
  },
  methods: {
    show () {
      this.showModal = true
    }
  }
}
</script>