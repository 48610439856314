import moment from 'moment'
import { toLocalDateString, toLocalDateTimeString, pageScoreTintColor } from '@/utils'

// const safeAccess = require('safe-access')
import safeAccess from 'safe-access';

let importantSignalOrder = [
  'title', 'h1', 'p', 'h2', 'h3', 'h4', 'a', 'img_alt', 'bold', 'i', 'h5', 'h6', 'meta_keywords', 'meta_description', 'figure_caption'
]

export const mixin = {
  data () {
    return {
      variationSortMethod: 'importance',
      lsiSortMethod: 'importance',
      nlpEntitiySortMethod: 'importance',

      comparePageRunId: '',
    }
  },
  computed: {
    tagTotalData () {
      let pageRun = this.pageRun
      if (!pageRun) pageRun = this.$store.state.currentPageRun

      let checkLists = this.checkLists
      if (!checkLists) checkLists = this.page.checkLists

      let data = pageRun.tagCounts.map(row => {
        let found = checkLists.find(checklist => {
          return (checklist.type == 2 && checklist.signal == row.tagLabel)
        })
        if (found) {
          row.checklistId = found.id
          row.isCompleted = found.isCompleted
          row.isArchived = found.isArchived
          row.isAutoChecklist = found.isAutoChecklist
        } else {
          row.checklistId = 0
        }
        return row
      })
      return data
    },
    seoEditChecklist () {
      let isExpressEditor = this.$store.state.isExpressEditor
      if (!this.signalType) {
        return []
      }

      let data = []
      let editorChecklistItemLabels = new Set([])
      if (this.signalType === 'pageStructure') { // do something for page structure

        // getting auto checklist items
        // this.tagTotalData.map(item => {
        //   if (editorChecklistItemLabels.size < 5 && !item.comment.toLowerCase().startsWith('leave as')) { // restrict auto generating items count to 5
        //     editorChecklistItemLabels.add(item.tagLabel)
        //   }
        // })
        this.tagTotalData.map(item => {
          if (item['checklistId'] && item['checklistId'] > 0) {
            if (item['isArchived']) {
              editorChecklistItemLabels.delete(item.tagLabel)
            } else {
              editorChecklistItemLabels.add(item.tagLabel)
            }
          }
        })
        data = this.tagTotalData.filter(item => {
          // if (isExpressEditor) { // if express editor
          //   return !item.comment.toLowerCase().startsWith('leave as')
          // }
          // return item['checklistId'] && item['checklistId'] > 0
          return editorChecklistItemLabels.has(item.tagLabel)
        })
        data = data.map(item => {
          let editedCount = this.$store.state.seoEditTagCounts[`${item.tagLabel}`]
          if (isNaN(editedCount)) editedCount = 0; else editedCount = Math.round(editedCount);
          return {
            type: 2, // checklist type
            dispType: '',
            recommendationType: 'pageStructure',
            signal: item.tagLabel,
            signalTags: item.tagLabel,
            target: Math.round(item.mean),
            current: item.signalCnt,
            editedCount: editedCount,
            checklistId: item.checklistId,
            isAutoChecklist: item.isAutoChecklist
          }
        })
        return data
      } else if (this.signalType === 'keyword') {
        // this.signalData.map(item => { // auto checklist
        //   if(editorChecklistItemLabels.size < 5 && item.comment && !item.comment.toLowerCase().startsWith('leave as')) {
        //     editorChecklistItemLabels.add(item.signal)
        //   }
        // })
        this.signalData.map(item => {
          if (item['checklistId'] && item['checklistId'] > 0) {
            if (item['isArchived']) {
              editorChecklistItemLabels.delete(item.signal)
            } else {
              editorChecklistItemLabels.add(item.signal)
            }
          }
        })

        data = this.signalData.filter(item => {
          // if (isExpressEditor) {
          //   return item.comment && !item.comment.toLowerCase().startsWith('leave as')
          // }
          // return item['checklistId'] && item['checklistId'] > 0
          return editorChecklistItemLabels.has(item.signal)
        })
        data = data.map(item => {
          let target = 0
          switch(item.dispType) {
            case 'regular':
              target = item['competitorsAvg']
              break;
            case 'max':
              target = item['keywordsTrueMax']
              break;
            case 'focus':
              target = item['featuredCompetitorsKeywordAvg']
              break;
            default: // if it's auto checklist
              target = item['competitorsAvg'];
              break;

          }
          let editedCount = this.$store.state.seoEditWordCounts[`${item.signalTags}`]
          if (isNaN(editedCount)) editedCount = 0; else editedCount = Math.round(editedCount);

          return {
            type: 0,  // checklist type
            dispType: item.dispType ? item.dispType : 'regular',
            recommendationType: item.recommendationType ? item.recommendationType : 'keyword',
            signal: item.signal,
            signalTags: item.signalTags,
            target: Math.round(target),
            current: item.targetKeywordCnt,
            editedCount: editedCount,
            checklistId: item.checklistId,
            isAutoChecklist: item.isAutoChecklist
          }
        })
      } else {
        if (this.signalType === 'variations') {
          // this.variationData.map(item => {
          //   if (editorChecklistItemLabels.size < 5 && item.comment && !item.comment.toLowerCase().startsWith('leave as')) {
          //     editorChecklistItemLabels.add(item.signal)
          //   }
          // })
          this.variationData.map(item => {
            if (item['checklistId'] && item['checklistId'] > 0) {
              if (item['isArchived']) {
                editorChecklistItemLabels.delete(item.signal)
              } else {
                editorChecklistItemLabels.add(item.signal)
              }
            }
          })
          data = this.variationData.filter(item => {
            // if (isExpressEditor) {
            //   return item.comment && !item.comment.toLowerCase().startsWith('leave as')
            // }
            // return item['checklistId'] && item['checklistId'] > 0
            return editorChecklistItemLabels.has(item.signal)
          })
        } else if (this.signalType === 'lsi_terms') {
          // this.lsiVariationData.map(item => {
          //   if (editorChecklistItemLabels.size < 5 && item.comment && !item.comment.toLowerCase().startsWith('leave as')) {
          //     editorChecklistItemLabels.add(item.signal)
          //   }
          // })
          this.lsiVariationData.map(item => {
            if (item['checklistId'] && item['checklistId'] > 0) {
              if (item['isArchived']) {
                editorChecklistItemLabels.delete(item.signal)
              } else {
                editorChecklistItemLabels.add(item.signal)
              }
            }
          })
          data = this.lsiVariationData.filter(item => {
            // if (isExpressEditor) {
            //   return item.comment && !item.comment.toLowerCase().startsWith('leave as')
            // }
            // return item['checklistId'] && item['checklistId'] > 0
            return editorChecklistItemLabels.has(item.signal)
          })
        }
        data = data.map(item => {
          let target = 0
          switch(item.dispType) {
            case 'regular':
              target = this.getVariationsRecommendationTargetForRegular(item)
              break;
            case 'max':
              target = item['variationsTrueMax']
              break;
            case 'focus':
              target = item['featuredCompetitorsMin'] > 0 ? item['featuredCompetitorsMin'] : Math.round(item['featuredCompetitorsMax'] / 2)
              break;
            default:
              target = this.getVariationsRecommendationTargetForRegular(item)
          }
          let editedCount = 0
          if (this.signalType === 'variations') {
            editedCount = this.$store.state.seoEditEmCounts[`${item.signalTags}`]
          } else if (this.signalType === 'lsi_terms') {
            editedCount = this.$store.state.seoEditLsiEmCounts[`${item.signalTags}`]
          }
          if (isNaN(editedCount)) editedCount = 0; else editedCount = Math.round(editedCount);

          return {
            type: (this.signalType === 'variations') ? 1 : 3,  // checklist type
            dispType: item.dispType ? item.dispType : 'regular',
            recommendationType: item.recommendationType ? item.recommendationType : 'keyword',
            signal: item.signal,
            signalTags: item.signalTags,
            target: Math.round(target),
            current: item.targetVariationCnt,
            editedCount: editedCount,
            checklistId: item.checklistId,
            isAutoChecklist: item.isAutoChecklist
          }
        })
      }
      data = data.map(item => {
        if (item.signalTags == 'h1' || item.signalTags == 'title') {
          if (item['target'] == 0) item['target'] = 1
        }
        return item
      })
      // if (isExpressEditor) {
      //   return this.getTop5ImportantRecommendation(data)
      // } else {
      //   return data
      // }
      return data
    },
    user () {
      return this.$store.state.user
    },
    availableReportCreditsOfUserOrTeam () {
      return this.$store.getters.availableReportCreditsOfUserOrTeam
    },
    canCreateNewPage () {
      if (this.availableReportCreditsOfUserOrTeam >= 2) {
        return true;
      }
      return false;
    },
    canCreateRerun () {
      if (this.availableReportCreditsOfUserOrTeam >= 1) {
        return true;
      }
      return false;
    },
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId)) || {}
    },
    sitesAlpabetic () {
      if (!!this.$store.state.sites) {
        let sites = this.$store.state.sites.slice()
        return sites.sort((a, b) => {
          if (a.name.toLowerCase()==b.name.toLowerCase()) return 0
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
      }
      return []
    },
    sites () {
      let sortModeSite = this.$store.state.sortModeSite
      if (!!this.sitesAlpabetic) {
        let sites = this.sitesAlpabetic.slice()
        sites = sites.map(site => {
          site[`lastPageRunInfo`] = this.lastPageRunInfo(site)
          return site
        })
        sites.sort((a, b) => {

          if (a.isPinned && !b.isPinned) return -1;
          if (b.isPinned && !a.isPinned) return 1;


          if(sortModeSite=='recent') {
            let date1 = moment(a.createdAt), date2 = moment(b.createdAt)
            if (date1.isBefore(date2)) return 1;
            if (date1.isAfter(date2)) return -1;
            return 0;
          } else if (sortModeSite==='alphabetic') {
            if (a.name.toLowerCase()==b.name.toLowerCase()) return 0
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          } else if (sortModeSite==='recent_report' || sortModeSite==='oldest_report') {
            let date1 = moment(a.lastPageRunInfo ? a.lastPageRunInfo.createdAt : '1970-01-01')
            let date2 = moment(b.lastPageRunInfo ? b.lastPageRunInfo.createdAt : '1970-01-01')

            console.log(sortModeSite, date1, date2)
            if (sortModeSite=='recent_report') {
              if (date1.isBefore(date2)) return  1
              if (date1.isAfter(date2)) return  -1
              return 0
            }

            if (sortModeSite=='oldest_report') {
              if (date1.isAfter(date2)) return 1
              if (date1.isBefore(date2)) return -1
              return 0
            }
            return 0
          } else if ( sortModeSite === 'best_score' || sortModeSite === 'worst_score' ) {
            let val1 = this.getStrategyScore(a)
            let val2 = this.getStrategyScore(b)

            if (sortModeSite == 'best_score') {
              return val2 - val1
            }
            if (sortModeSite == 'worst_score') {
              return val1 - val2;
            }
          }
        })

        return sites
      }
      return []
    },

    page () {
      if (!this.site || !(this.site.pages || []).length) {
        return {}
      }
      return this.site.pages.find((p) => p.id === parseInt(this.$route.params.pageId)) || {}
    },
    pageStrategySetting () {
      let strategySetting = this.page.strategySetting
      if (strategySetting) {
        return strategySetting
      } else {
        return {}
      }
    },
    pagesAlpabetic () {
      if (!this.site || !(this.site.pages || []).length) {
        return []
      }
      const pages = this.site.pages.slice()
      return pages.sort((a, b) => {
        if (a.name.toLowerCase()==b.name.toLowerCase()) return 0
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
      return []
    },
    pages () {
      
      const pages = this.pagesAlpabetic
      let sortModePage = this.$store.state.sortModePage
      return pages.sort((a, b) => {
        if (a.isPinned && !b.isPinned) return -1;
        if (b.isPinned && !a.isPinned) return 1;

        if(sortModePage=='recent') {
          let date1 = moment(a.createdAt), date2 = moment(b.createdAt)
          if (date1.isBefore(date2)) return 1;
          if (date1.isAfter(date2)) return -1;
          return 0;
        } else if (sortModePage==='alphabetic') {
          if (a.name.toLowerCase()==b.name.toLowerCase()) return 0
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        } else if (sortModePage==='recent_report' || sortModePage==='oldest_report') {
          let date1 = moment(a.lastPageRunInfo ? a.lastPageRunInfo.createdAt : '1970-01-01')
          let date2 = moment(b.lastPageRunInfo ? b.lastPageRunInfo.createdAt : '1970-01-01')

          if (date1.isBefore(date2)) return sortModePage=='recent_report' ? 1 : -1;
          if (date1.isAfter(date2)) return sortModePage=='oldest_report' ? -1 : 1;
          return 0
        } else if (sortModePage==='best_score' || sortModePage==='worst_score') {
            let val1 = this.getStrategyScore(a)
            let val2 = this.getStrategyScore(b)

            if (sortModePage == 'best_score') {
              return val2 - val1
            }
            if (sortModePage == 'worst_score') {
              return val1 - val2;
            }
        }
      })
    },

    pageRun () {
      if (!this.page) {
        return {}
      }
      const pageRunId = parseInt(this.$route.params.pageRunId)
      if (pageRunId) {
        return this.$store.state.pageRuns.find((r) => r.id === pageRunId) || {}
      }
      return this.$store.state.currentPageRun || {}
    },
    isCurrentPageRunAvailableForStrategy () {
      return this.isPageRunAvailableForStrategy(this.pageRun)
    },
    hasContentBriefData () {
      let found = this.runStrategies.find(item => item.dashboardType == 'contentBrief' )
      return !!found
    },
    isNotInLatestPageRun () {
      let hasMoreLatestReport = this.pageRuns.some(pageRun => pageRun.id > this.pageRun.id)
      return hasMoreLatestReport
    },
    pageRunsWithLatestOrder () {
      const pageId = parseInt(this.$route.params.pageId)
      let pageRuns = this.$store.state.pageRuns.filter(pageRun => pageRun.pageId === pageId && pageRun.status == 'SUCCESS').slice()
      return pageRuns.sort((a, b) => {
        let date1 = moment(a.createdAt), date2 = moment(b.createdAt)
        if (date1.isBefore(date2)) return 1;
        if (date1.isAfter(date2)) return -1;
        return 0;
      })
    },
    pageRuns () {
      const pageId = parseInt(this.$route.params.pageId)
      const pageRuns = this.$store.state.pageRuns.filter(pageRun => pageRun.pageId === pageId).slice()
      let sortModePageRun = this.$store.state.sortModePageRun
      return pageRuns.sort((a, b) => {
        
        if (a.isPinned && !b.isPinned) return -1;
        if (b.isPinned && !a.isPinned) return 1;

        if (sortModePageRun==='recent') {
          let date1 = moment(a.createdAt), date2 = moment(b.createdAt)
          if (date1.isBefore(date2)) return 1;
          if (date1.isAfter(date2)) return -1;
          return 0;
        } else if (sortModePageRun==='alphabetic') {
          if (a.keyword==b.keyword) return 0
          return a.keyword > b.keyword ? 1 : -1
        } else if (sortModePageRun==='worstscore' || sortModePageRun==='bestscore') {
          let scoreA = safeAccess(a, 'keywordCounts.pageScore.pageScore')
          let scoreB = safeAccess(b, 'keywordCounts.pageScore.pageScore')
          if (scoreA===scoreB) return 0
          if (sortModePageRun==='worstscore') {
            return scoreA > scoreB ? 1 : -1
          } else {
            return scoreA < scoreB ? 1 : -1
          }
        }
      })
    },
    lastPageRunForCurrentPage () {
      if(this.pageRuns.length > 0) {
        return this.pageRuns[0]
      } else {
        return {}
      }
    },
    pageRunsWithRecentOrder () {
      const pageId = parseInt(this.$route.params.pageId)
      const pageRuns = this.$store.state.pageRuns.filter(pageRun => pageRun.pageId === pageId && pageRun.status === 'SUCCESS').slice()
      return pageRuns.sort((a, b) => {
        let date1 = moment(a.createdAt), date2 = moment(b.createdAt)
        if (date1.isBefore(date2)) return 1;
        if (date1.isAfter(date2)) return -1;
        return 0;
      })
    },
    sortedLsaPhrases() {
      const normalizeWeight = (item) => {
        if (item.weight == 3) { // google nlp 
          return 1
        }
        if (item.weight > 1) {
          return item.weight - 1
        }
        return item.weight
      }

      let lsaPhrases = this.lsaPhrases.map(item => {
        return {
          ...item,
          weight: normalizeWeight(item)
        }
      })
      if (this.lsiSortMethod === 'alphabetic') {
        return lsaPhrases.map(item => item).sort(function(a, b) {
          if (a.phrase < b.phrase) return -1;
          if (a.phrase > b.phrase) return 1;
          return 0;
        })
      }
      if (this.lsiSortMethod === 'deficit') {
        return lsaPhrases.map(item => item).sort(function(a, b) {
          let compV1 = a['averageCount'] - a['targetCount']
          let compV2 = b['averageCount'] - b['targetCount']
           if (compV1 > compV2) return -1;
           if (compV1 < compV2) return 1;

           if (a.weight > b.weight) return -1;
           if (a.weight < b.weight) return 1;
           return 0;
        })
      }
      return lsaPhrases // default is important order
    },
    sortedNLPTermsOnly() {
      return this.sortedLsaPhrases.filter(item => item.isNLPTerm)
    },
    sortedLsaPhrasesWithoutNLP() {
      return this.sortedLsaPhrases.filter(item => !item.isNLPTerm)
    },
    sortedLsaVariations() {
      if (!this.hasLsaForVariations) return []

      let lsaVariations = this.lsaVariations

      if (this.variationSortMethod === 'alphabetic') {
        return lsaVariations.map(item => item).sort(function(a, b) {
          if (a.phrase < b.phrase) return -1;
          if (a.phrase > b.phrase) return 1;
          return 0;
        })
      }
      if (this.variationSortMethod === 'deficit') {
        return lsaVariations.map(item => item).sort(function(a, b) {
          let compV1 = a['averageCount'] - a['targetCount']
          let compV2 = b['averageCount'] - b['targetCount']
           if (compV1 > compV2) return -1;
           if (compV1 < compV2) return 1;

           if (a.weight > b.weight) return -1;
           if (a.weight < b.weight) return 1;
           return 0;
        })
      }
      if (this.variationSortMethod === 'importance') {
        return lsaVariations.map(item => item).sort(function(a, b) {
          if (a.weight > b.weight) return -1;
          if (a.weight < b.weight) return 1;
          return 0;
        })
      }
    },
    sortedSecondaryKeywords () {
      return this.sortedLsaVariations.filter(item => item.isSecondaryKeyword)
    },
    sortedLsaVariationsOnly () { // no secondary keywords
      return this.sortedLsaVariations.filter(item => !item.isSecondaryKeyword)
    },
    sortedGoogleNlpCommonEntities() {
      if (!this.nlpCommons || !this.nlpCommons.common_entities_array) return []
      
      if (this.nlpEntitiySortMethod === 'alphabetic') {
        return this.nlpCommons.common_entities_array.sort(function(a, b) {
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          return 0;
        })
      }
      if (this.nlpEntitiySortMethod === 'importance') {
        return this.nlpCommons.common_entities_array.sort(function(a, b) {
          if (a[1] > b[1]) return -1;
          if (a[1] < b[1]) return 1;
          return 0;
        })
      }
      if (this.nlpEntitiySortMethod === 'deficit') {
        // return this.lsaPhrases.map(item => item).sort(function(a, b) {
        //   let compV1 = a['averageCount'] - a['targetCount']
        //   let compV2 = b['averageCount'] - b['targetCount']
        //    if (compV1 > compV2) return -1;
        //    if (compV1 < compV2) return 1;

        //    if (a.weight > b.weight) return -1;
        //    if (a.weight < b.weight) return 1;
        //    return 0;
        // })
      }
      return this.nlpCommons ? this.nlpCommons.common_entities_array : []
    },

    isDeficitSortDisabled () { // This is for old run
      if (this.lsaPhrases.length > 0 && this.lsaPhrases[0]['targetCount'] == undefined) 
        return true
      return false
    },
    hasLsaForVariations () {
      return this.lsaVariations.length > 0 && this.lsaVariations != undefined
    },
    pageScoreExplainByStrategy () {
      if (!this.strategy) {
        return ''
      }
      if (this.strategy == 'custom') {
        return 'An optimization score is currently unavailable for Custom strategies.  This feature is coming soon.'
      }
      let strategyText = ''
      let approachText = ''
      if (this.strategy == 'target')
        strategyText = 'all your competitors'
      else if (this.strategy == 'adjusted')
        strategyText = 'your current word count relative to all your competitors'
      else if (this.strategy == 'focus')
        strategyText = 'your focus competitors'
      else if (this.strategy == 'max')
        strategyText = 'the high number for each signal from your competitors' //Use with extreme caution.
      
      if (this.approach == 'regular')
        approachText = 'a regular approach'
      else if (this.approach == 'conservative')
        approachText = 'a conservative approach'
      else if (this.approach == 'aggressive')
        approachText = 'an aggressive approach'
      else if (this.approach == 'hyper_aggressive')
        approachText = 'a hyper aggressive'
      
      return `based on ${strategyText} and ${approachText}.`
    },
  },
  methods: {
    // getTop5ImportantRecommendation(data) {
    //   let filteredData = data.filter (item => {
    //     return importantSignalOrder.indexOf(item.signalTags) > -1
    //   })
    //   filteredData.sort((a, b) => {
    //     let idx_a = importantSignalOrder.indexOf(a.signalTags)
    //     let idx_b = importantSignalOrder.indexOf(b.signalTags)
    //     if (idx_a > idx_b) return 1;
    //     if (idx_a < idx_b) return -1;
    //     return 0;
    //   })
    //   return filteredData.slice(0, 5)
    // },
    getDateAsLocalString (dateStr) {
      if (!dateStr) {
        return ''
      }
      return new Date(dateStr.replace(' ', 'T') + 'Z').toLocaleString()
    },
    toLocalDateString (date) {
      return toLocalDateString(date)
    },
    toLocalDateTimeString (d) {
      return toLocalDateTimeString(d)
    },
    getSubFolderPartFromUrl(url, pageNotBuilt=false) {
      if (pageNotBuilt) {
        return 'N / A'
      }
      if (!url || url.length <= 8) {
        return ''
      }
      let idx = url.indexOf('/', 8)
      let rlt = url.substr(idx).trim()

      if (idx==-1 || rlt=='/' || rlt=='') {
        return 'Homepage'
      }
      return rlt
    },
    hasSignals(signal) {
      return (signal && signal['rows'])
    },
    downloadUrl(pageRun) {
      if (pageRun.reportResourceUrl) {
        return pageRun.reportResourceUrl
      }
      return `${API_URL}/download/?file=${pageRun.excelFile}`
    },

    /**
     * type: 0: recommendations only, 1: with status 2: all including notes
     */
    downloadExcelForChecklist(type, pageId, pageRunId, isQuickListDownload=false, strategyAndApproach=null) {
      // downloadExcelForChecklist
      let data = {
        pageId: pageId ? pageId : this.pageId,
        pageRunId: pageRunId ? pageRunId : this.pageRun.id,  // reportID is pageRunId
        downloadType: type,
        isQuickListDownload,
      }
      if (strategyAndApproach) {
        data = {
          ...data,
          ...strategyAndApproach
        }
      }
      console.log('DOWNLOAD REQUEST DATA', data)
      this.$store.commit('showLoading')
      this.$store.dispatch('downloadExcelForChecklist', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          let downloadAllUserUrl = `${API_URL}/download/?file=${response.data.url}`
          let fileName = downloadAllUserUrl.split('/').pop()
          let downloadLink;
          downloadLink = document.createElement("a");
          downloadLink.download = fileName;
          downloadLink.href = downloadAllUserUrl
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          setTimeout(() => {
            document.body.removeChild(downloadLink)
          }, 100);
        } else {
          console.log('error during download.. ', response.data)
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    rerunAnalysis () {
      let pageRunId = 0
      if(this.pageRuns.length > 0)
        pageRunId = this.pageRuns[0].id
      if (pageRunId==0) { // This means no any pagerun
        console.log('Can not make rerun because you do not have any pagerun in this page')
        return
      }
      if (this.canCreateRerun) {
        this.$router.push(`/sites/${this.$route.params.siteId}/pages/${this.$route.params.pageId}/pageRun/${pageRunId}/rerun`)
      } else {
        this.errMsg = 'Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.'
      }
    },
    rerunAnalysisForThisReport () {
      if (this.$route.params.pageRunId==0) {
        return
      }
      if (this.canCreateRerun) {
        this.$router.push(`/sites/${this.$route.params.siteId}/pages/${this.$route.params.pageId}/pageRun/${this.$route.params.pageRunId}/rerun`)
      } else {
        this.errMsg = 'Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.'
        if (this.$refs.insufficientFundModal) {
          this.$refs.insufficientFundModal.show()
        }
      }
    },
    runBuiltPage (pageRunId=0) {
      if (this.canCreateRerun) {
        this.$router.push(`/sites/${this.$route.params.siteId}/pages/${this.$route.params.pageId}/pageRun/${pageRunId ? pageRunId : this.$route.params.pageRunId}/rerun?runBuiltPage=true`)
      } else {
        this.errMsg = 'Insufficient report balance to create a page.  Please purchase more reports in your user profile dashboard.'
      }
    },

    lastPageRunInfo (site) {
      let pageRunInfos = site.pages.filter (p => {
        return p.lastPageRunInfo && ( 'strategyScore' in p.lastPageRunInfo || 'score' in p.lastPageRunInfo)
      }).map(p => p.lastPageRunInfo)

      pageRunInfos.sort((a, b) => {
        let date1 = moment(a.createdAt), date2 = moment(b.createdAt)
        if (date1.isBefore(date2)) return 1;
        if (date1.isAfter(date2)) return -1;
        return 0;
      })
      if (pageRunInfos.length > 0) {
        return pageRunInfos[0]
      }
      return null
    },
    countNotBuiltOptimizations (pageRun) {
      const kwOptimizationsAvailable = pageRun.keywordCounts.rows.length
      const varOptimizationsAvailable = pageRun.keywordCounts.rows.length
      const lsiOptimizationsAvailable = pageRun.lsaKeywordCounts.rows.length
      const kwOptimizations = pageRun.keywordCounts.rows.filter((s) => s.signalData.competitorsAvg).length
      const varsOptimizations = pageRun.keywordCounts.rows.filter((s) => s.variationData.competitorsVariationsAvg).length
      const lsaOptimizations = pageRun.lsaKeywordCounts.rows.filter((s) => s.variationData.competitorsVariationsAvg).length
      const totalOptimizationsAvailable = kwOptimizationsAvailable + varOptimizationsAvailable + lsiOptimizationsAvailable
      const totalOptimizations = kwOptimizations + varsOptimizations + lsaOptimizations
      return {
        kwOptimizations,
        varsOptimizations,
        lsaOptimizations,
        totalOptimizationsAvailable,
        totalOptimizations
      }
    },
    strategyPageScore (dashboardType, strategy, approach, runStrategies) {

      if (!runStrategies) {
        runStrategies = this.pageRun.runStrategies
      }

      let found = runStrategies.find(item => {
        if ( dashboardType == 'contentBrief' ) {
          return item.dashboardType == dashboardType
        } else if (dashboardType == 'signals') {
          return item.approach == approach && item.strategy == strategy
        }
        return false
      })
      console.log('Looking for appropricate page score')
      if (found && found.pageScore) {
        console.log(found)
        return found.pageScore.pageScore
      }
      if (dashboardType == 'contentBrief') {
        return 0
      }
      switch (this.strategy) {
        case 'target':
          return this.pageRun.keywordCounts.pageScore.pageScore
        case 'focus':
          return 0
        case 'max':
          return 0
        case 'adjusted':
          return this.pageRun.adjKeywordCounts.pageScore.pageScore
        case 'custom':
          return 0
        default:
          return 0
      }
    },
    pageScoreTintColor(score) {
      return pageScoreTintColor(score)
    },
    getScoreTypeLabelByStrategy (dashboardType, strategy, aproach) {
      console.log('back to old code', dashboardType)
      if (dashboardType == 'contentBrief') {
        return 'Content Brief<br/>Optimization Score'
      } if (dashboardType == 'aiWriter') {
        return 'AI Writer<br/>Optimization Score'
      } else if (dashboardType == 'signals') {
        switch (strategy) {
          case 'target':
            return '<div>Signals Optimization Score</div><div class="score-type">All Competitors</div>'
          case 'adjusted':
            return '<div>Signals Optimization Score</div><div class="score-type">Adjusted for Your Word Count</div>'
          case 'focus':
            return '<div>Signals Optimization Score</div><div class="score-type">Focus Competitors</div>'
          case 'max':
            return '<div>Signals Optimization Score</div><div class="score-type">Highest Signal Numbers</div>'    
          case 'custom':
            break;
          default:
            break;
        }
      }
      return 'OPTIMIZATION SCORE'
    },
    getVariationsRecommendationTargetForRegular(item) {
      let current = parseInt(item['targetVariationCnt'])
      let comment = item['comment']
      let diff = 0
      try {
        let idx = comment.indexOf('by')
        if (idx==-1)
          diff = 0
        else
          diff = parseInt(comment.substring(idx+3))

        console.log('ITEM', current, comment, diff, current-diff, current+diff)

        if (comment.indexOf('Leave As Is') > -1)
            return current
        else if (comment.indexOf('Reduce') > -1 || comment.indexOf('reducing') > -1)
            return current - diff
        else if (comment.indexOf('Increase') > -1 || comment.indexOf('Add 1') > -1)
            return current + diff
        return 0
      } catch (error) {
        return 0
      }
    },
    getDestWordCountByStrategy (strategy, approach, type, item) {
      let targetCount = 0
      if (type == 0) {
        if (approach == 'regular') {
          if (strategy == 'target') {
            targetCount = item['competitorsAvg']
          } else if (strategy == 'adjusted') {
            targetCount = item['competitorsAvg']
          } else if (strategy == 'focus') {
            targetCount = item['featuredCompetitorsKeywordAvg']
          } else if (strategy == 'max') {
            targetCount = item['keywordsTrueMax']
          }
        } else if (approach == 'conservative') { // for 'conservative' we use regular
          targetCount = item['competitorsAvg']
        } else if (approach == 'aggressive') {
            targetCount = item['competitorsAvg'] >= item['featuredCompetitorsKeywordAvg'] ? item['competitorsAvg'] : item['featuredCompetitorsKeywordAvg']
        } else if (approach == 'hyper_aggressive') {
            targetCount = item['keywordsTrueMax']
        }
      } else if (type == 1 || type == 3) { // variation or lsi terms
        if (approach == 'regular') {
          if (strategy == 'target') {
            targetCount = item['competitorsVariationsAvg']
          } else if (strategy == 'adjusted') {
            targetCount = item['competitorsVariationsAvg']
          } else if (strategy == 'focus') {
            targetCount = item['featuredCompetitorsVariationsAvg']
          } else if (strategy == 'max') {
            targetCount = item['variationsTrueMax']
          }
        } else if (approach == 'conservative') {
          targetCount = item['competitorsVariationsAvg'] <= item['featuredCompetitorsVariationsAvg'] ? item['competitorsVariationsAvg'] : item['featuredCompetitorsVariationsAvg']
        } else if (approach == 'aggressive') {
          targetCount = item['competitorsVariationsAvg'] >= item['featuredCompetitorsVariationsAvg'] ? item['competitorsVariationsAvg'] : item['featuredCompetitorsVariationsAvg']
        } else if (approach == 'hyper_aggressive') {
          targetCount = item['variationsTrueMax']
        }
      }
      if (this.strategy == 'custom') {
        targetCount = 0
      }
      return targetCount
    },
    /**
     * 
     * @param {*} strategy 
     * @param {*} approach 
     * @param {*} type : 0: keyword, 1: varaition, 3: lsi terms
     * @param {*} item 
     */
    getStrategyRecommendationCommentOrNumber(strategy, approach, type, item, runStrategies, getNumber=false) {
      if (this.strategy == 'custom') {
        if (getNumber)
          return [0, 0]
        else
          return ''
      }

      let runStrategy = runStrategies.find(row => row.strategy == strategy && row.approach == approach)
      if (!runStrategy || !runStrategy.rows) {
        if (getNumber)
          return [0, 0]
        else
          return ''
      }

      let properRow = (runStrategy.rows || []).find(row => row.signal == item.signal)
      if (!properRow) {
        if (getNumber)
          return [0, 0]
        else
          return ''        
      }
      
      
      let comment = ''
      let number = 0, number1 = 0

      

      if (type == 0) {
        comment = properRow['signalData']['comment']
        number = properRow['signalData']['competitorsAvg']
      }
      if (type != 0 && properRow['signal'] != 'Keyword in Main URL') {
        if (type == 1) { // variation or lsi terms
          comment = properRow['variationData']['comment']
          // number = properRow['variationData']['competitorsMin'] > 0 ? properRow['variationData']['competitorsMin'] : Math.round(properRow['variationData']['competitorsMax'] / 2.0)
          number = properRow['variationData']['competitorsMin']
          number1 = properRow['variationData']['competitorsMax']
        } else if (type == 3) {
          comment = properRow['lsiData']['comment']
          // number = properRow['lsiData']['competitorsMin'] > 0 ? properRow['lsiData']['competitorsMin'] : Math.round(properRow['lsiData']['competitorsMax'] / 2.0)       
          number = properRow['lsiData']['competitorsMin']
          number1 = properRow['lsiData']['competitorsMax']
        }
      }

      if (getNumber) {
        return [number, number1]
      } else {
        return comment
      }
    },
    getDispTypeForItem(item) {
      if (item.isAutoChecklist) {
        return 'Auto Generated'
      }
      if (item.dispType) {
        return item.dispType
      }
      return ''
    },
    isPageRunAvailableForStrategy(pageRun) {
      if (pageRun.rawKeywordCounts) {
        return true
      }
      return false
    },


    getStrategyScore(pageOrSite, isAiTab=false) {
      const aiScore = pageOrSite.lastAiRunInfo?.aiScore
      if(aiScore && isAiTab){
        return aiScore
      }
      if (pageOrSite.lastPageRunInfo) {
        if (pageOrSite.lastPageRunInfo.strategyScore) {
          return pageOrSite.lastPageRunInfo.strategyScore
        } else {
          return pageOrSite.lastPageRunInfo.score
        }
      }
      return 0;
    },
    getStrategyScoreType (pageOrSite, dashboardType) {
      if (pageOrSite.lastPageRunInfo) {
        return this.getScoreTypeLabelByStrategy(dashboardType || pageOrSite.lastPageRunInfo.dashboardType, pageOrSite.lastPageRunInfo.strategy, pageOrSite.lastPageRunInfo.approach)
      }
      return ''
    },



    getSortedSignalVariationData (data) {
      let newData = JSON.parse(JSON.stringify(data))
      return newData.sort(this.sortByImportance)
    },
    sortByImportance(item1, item2) {
      
      let idx_a = importantSignalOrder.indexOf(item1.signalTags)
      let idx_b = importantSignalOrder.indexOf(item2.signalTags)
      idx_a = idx_a == -1 ? 10000 : idx_a
      idx_b = idx_b == -1 ? 10000 : idx_b
      if (idx_a > idx_b) return 1;
      if (idx_a < idx_b) return -1;
      return 0;
    }
  },
  mounted () {
    // this.$store.subscribe((mutation, state) => {
    //   console.log('Mutation type: ', mutation.type)
    //   switch(mutation.type) {
    //     case 'setSeoSourceCode':
    //       // calc again
    //       break;
    //   }
    // })
  }
}
