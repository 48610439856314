<template>
<div class="right-main-area main-padding">
    <!-- <img src="/static/cartoon1.svg" class="cartoon-img" />
    <a class="show-hide-ballon" @click="isShowBallon=!isShowBallon">{{ isShowBallon ? 'HIDE TIPS' : 'SHOW TIPS'}}</a> -->

    <div class="is-size-6 mt-2 mb-2"><b>Project:</b> {{ site.name }}</div>
    <div class="is-size-4 font-weight-800 word-wrap">{{ keyword }}</div>
    <div class="is-size-6 mt10"><b>Domain:</b> {{ baseUrl }}</div>
    <br/>

    <div class="new-project section-name-size mb-3">
        <span>New page</span>
    </div>
    <p class="has-text-centered profileAnchorColor--text font-weight-bold" v-if="errorMsg">{{ errorMsg }}</p>
    <div v-if="invalidCompetitorUrls && invalidCompetitorUrls.length">
        <p v-for="(url, idx) in invalidCompetitorUrls" :key="idx" class="profileAnchorColor--text">{{ url }}</p>
    </div>
    <div class="columns is-desktop wow_disaled jackInTheBox" data-wow-delay="1.5s">
        <div class="column is-12">
            <div class="columns">
                <div class="column">
                    <div class="section-name-size font-weight-bold mb-1">Main Keyword(s)</div>
                    <span class="variation-tag">{{ keyword }}</span>
                </div>
                <div class="column">
                    <div class="section-name-size font-weight-bold mb-1">Language</div>
                    {{ targetLang | capitalize }}
                </div>
                <div class="column">
                    <div class="section-name-size font-weight-bold mb-1">Region</div>
                    {{ proxyLocation == 'extension_use' || isGoogleSearchDoneWithExtension ? 'Your Location': proxyLocation }}
                </div>
            </div>

            <div v-if="useSecondaryKeywords" class="mt-2">
                    <div class="section-name-size font-weight-bold mb-1">Secondary Keyword(s)</div>
                    <span class="variation-tag" v-for="(term, idx) in secondaryKeywords" :key="idx">{{ term }}</span>
                </div>
            <div>
                <div class="section-name-size font-weight-bold mt-5 mb-1">Page to optimize</div>
                <a class="font-weight-bold link15Color--text word-break-all" target="_blank" :href="cleanBaseUrl+'/'+targetUrl">{{ cleanBaseUrl }}/{{ targetUrl }}</a>
                <div v-if="!isExpressReport">
                    <div class="section-name-size font-weight-bold mt-5 mb-1">Competitor pages</div>
                    <div class="competitor-list">
                    <div v-for="(url, idx) in competitorUrlsArray" :key="idx">
                        <a class="font-weight-bold link15Color--text word-break-all" target="_blank" :href="url">{{ url }}</a>
                    </div> 
                    </div>

                    <div class="section-name-size font-weight-bold mt-5 mb-1">Focus competitors</div>
                    <div class="competitor-list" v-if="validatedCompetitorUrls.length>0">
                    <div v-for="(url, idx) in focusCompetitorUrls" :key="idx">
                        <a class="font-weight-bold link15Color--text word-break-all" target="_blank" :href="url">{{ url }}</a>
                    </div> 
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="column is-4">
            <stepper :currentPage="currentPage" :isExpressReport="isExpressReport" :isOnePageSetup="isOnePageSetup"></stepper>
        </div> -->
    </div>

    <div class="columns mt-5" v-if="!isOnePageSetup">
        <div class="column is-6">
            <div class="section-name-size font-weight-bold">Keyword variations</div>
            <div v-if="runWithoutVariation">
                Report run without variations
            </div>
            <div v-if="showVariations">
                <span v-for="variation in variations" :key="variation" class="default-color-tag clickable">{{ variation }}</span>
            </div>
        </div>
        <div class="column is-6">
            <div class="section-name-size font-weight-bold">LSI</div>
            <div v-if="runWithoutLsaPhrases">
                Report run without LSI
            </div>
            <div v-if="showLsa">
                <span v-for="p in lsaPhrases" :key="p.phrase" class="default-color-tag clickable">{{ p.phrase }}</span>
            </div>
        </div>
    </div>

    <!-- <div v-if="isShowBallon && cartoonAnswer" class="speech-bubble boy wow_disaled fadeIn bubble-text" data-wow-duration="1s">
    {{ cartoonAnswer }}
    </div>
    <div v-if="isShowBallon && cartoonQuestion" class="speech-bubble girl wow_disaled fadeIn bubble-text" data-wow-duration="1s">
    {{ cartoonQuestion }}
    </div> -->
</div>
</template>

<script>
import { PageRunPages } from '@/components/page_run_pages.js'
import Stepper from '@/components/PageRun/Steps/Stepper.vue'

export default {
    components: { Stepper },
    props: ['isOnePageSetup', 'site', 'keyword', 'useSecondaryKeywords', 'secondaryKeywords', 'baseUrl', 'errorMsg', 'isGoogleSearchDoneWithExtension',
        'proxyLocation', 'targetUrl', 'targetLang', 'pageNotBuilt', 'cleanBaseUrl', 
        'isExpressReport', 'competitorUrlsArray', 'validatedCompetitorUrls', 'invalidCompetitorUrls', 'focusCompetitorUrls',
        'currentPage', 'runWithoutVariation', 'variations', 'lsaPhrases', 'runWithoutLsaPhrases'],
    computed: {
        showVariations () {
            return !this.runWithoutVariation && [PageRunPages.VARIATIONS, PageRunPages.LSA].includes(this.currentPage)
        },
        showLsa () {
            return !this.runWithoutLsaPhrases && PageRunPages.LSA === this.currentPage
        }
    },
    created () {
        // makes the const available in templates
        this.PageRunPages = PageRunPages
    },
}
</script>

<style scoped lang="scss">
div {
    color: var(--v-mainColor-base);
}
.section-name-size {
    /* font-size: 13.6px; */
}
</style>
