<template>
<div class="limited-width-box">
  <v-card class="is-fullheight flex flex-column white-common-box">
    <div class="pa-5 flex flex-column">
      <div class="title-font mainColor--text box-title-label">
        {{ isForgotPasswordPage ? '' : 'Change password' }}
      </div>

      <div class="notify-label" v-if="!isForgotPasswordPage">
        <i class="material-icons">error_outline</i>
        After you click submit, your password will immediately update and you will be redirected back to your projects dashboard.
      </div>

      <v-text-field dense
        label="Email"
        color="greenColor"
        v-model="email"
        outlined
        readonly
        hide-details="auto"
      ></v-text-field>

      <v-text-field dense
        :label="!isAuthenticated ? 'Current temporary password' : 'Your current password'"
        color="greenColor"
        v-model="currentPassword"
        outlined
        hide-details="auto"
      ></v-text-field>

      <v-text-field dense
        :type="showNewPassword ? 'text' : 'password'"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showNewPassword = !showNewPassword"
        label="New password"
        color="greenColor"
        v-model="password"
        outlined
        hide-details="auto"
        :error-messages="password && password.length < 5 ? [ 'must be at least 5 characters long' ] : []"
      ></v-text-field>

      <v-text-field dense
        :type="showVerifyPassword ? 'text' : 'password'"
        :append-icon="showVerifyPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showVerifyPassword = !showVerifyPassword"  
        label="Verify new password"
        color="greenColor"
        v-model="verifyPassword"
        outlined
        hide-details="auto"
        :error-messages="passwordMisMatchErrorMsg ? [ passwordMisMatchErrorMsg ] : []"
      ></v-text-field>

      <div>
        <v-btn rounded
          type="password"
          color="lightGreenColor"
          class="button-with-icon text-none white--text" @click="updatePassword"
          :disabled="!passwordsMatch">
          Submit
        </v-btn>
      </div>
    </div>
  </v-card>

<div class="modal" :class="{'is-active': showAlertModal}">
  <div class="modal-background" @click="showAlertModal = false"></div>
  <div class="modal-content">
    <div class="box modal-container">
      <p class="title" v-if="isSuccess">
        Your password is updated successfully
      </p>
      <p class="title" v-if="!isSuccess">
        Error Updating Password. <br/>
      </p>
      <p class="subtitle" v-if="!isSuccess">Please check your email and password again.</p>
      <div class="field has-addons">
        <div class="control">
          <button class="button" :class="{ 'is-primary' : isSuccess, 'is-warning': !isSuccess }" @click="hideAlertAndGoSitesPage">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</template>

<script>
export default {
  props: {
    isForgotPasswordPage: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      email: '',
      currentPassword: '',
      password: '',
      verifyPassword: '',
      errorMsg: '',
      confirmMsg: '',
      submitting: false,

      showNewPassword: false,
      showVerifyPassword: false,


      showAlertModal: false,
      isSuccess: false,
    }
  },
  computed: {
    passwordsMatch () {
      return this.password === this.verifyPassword && this.password.length > 5
    },
    passwordMisMatchErrorMsg () {
      if (this.password && this.verifyPassword && this.password != this.verifyPassword) {
        return 'passwords do not match'
      }
      return ''
    },
    isAuthenticated () {
      if (this.$store.getters.isAuthenticated) {
        return true
      }
      return false
    },
    user () {
      if (this.isAuthenticated) {
        return this.$store.state.user
      }
      return false
    }
  },
  methods: {
    updatePassword () {
      if (this.passwordsMatch) {
        this.submitting = true
        this.$store.commit('showLoading')
        this.$store.dispatch('updatePassword', { email: this.email, currentPassword: this.currentPassword, newPassword: this.password })
              .then((response) => {
                if (response.data.status === 'SUCCESS') {
                  this.isSuccess = true; this.showAlertModal = true
                  if (!this.isForgotPasswordPage) {
                    this.isSuccess = true; this.showAlertModal = true
                    this.$store.commit('setJwtToken', { jwt: response.data.token })
                    this.$store.commit('setUser', response.data.user )
                  }
                } else {
                  // throw up dialog
                  this.isSuccess = false; this.showAlertModal = true
                  return 1
                }
              })
              .catch(error => {
                console.log('Error Updating Password: ', error)
                this.errorMsg = "Error Updating Password."
              })
              .finally(() => { 
                this.submitting = false
                this.$store.commit('hideLoading')
              })
      }
    },
    hideAlertAndGoSitesPage () {
      this.showAlertModal = false
      if (this.isForgotPasswordPage) {
        this.$router.push('/login')
      }
      // this.$router.push('/sites')
    },
  },
  beforeMount () {
    console.log('zhao is test of', this.$route.query, this.user)
    if (this.isAuthenticated && this.user && this.user.email) {
      this.email = this.user.email
    } else {
      try {
        const email = this.$route.query.email
        const password = this.$route.query.tempPassword
        this.email = email ? email : ''
        this.currentPassword = password ? password : ''
      } catch (e) {
        console.log('No params to help with')
      }
    }
  }
}
</script>

<style scoped>
.box {
    padding: 30px 50px;
}

.v-input.v-input--hide-details {
  margin-bottom: 24px !important;
}

</style>