<template>
<div v-if="asyncTasks?.length" class="p-5 mainColor--text async-tasks-popup">

  <div v-for="task in asyncTasks" :key="task.task_id" class="mb-5 async-task">

    <template v-if="task.is_pending_or_in_progress">
      <div class="async-task-pending-or-progress">
        <div class="title-part mb-4">
          {{ getProgressLabel(task.type) }}
        </div>
        <div class="progress-msg-part mb-3">
          <b-icon
            pack="fa"
            icon="spinner"
            custom-class="fa-spin">
          </b-icon>
          <span class="ml-1 text">{{ task.task_status.status === 'PENDING' ? 'Starting...' : task.task_status.msg }}</span>
        </div>
        <v-progress-linear
          rounded height="5"
          color="profileAnchorColor"
          :value="task.task_status.value || 0">
        </v-progress-linear>
      </div>
    </template>

    <template v-else-if="task.is_completed">
      <div class="async-task-success">
        <div class="icon-part">
          <i class="material-icons">check_circle</i>
        </div>
        <div class="title-part">
          {{ getSuccessLabel(task.type) }}
        </div>
        <div class="action-part">
          <a :href="getRouteFromTask(task)" @click.stop.prevent="acknowledgeTask(task).then(() => $router.push(getRouteFromTask(task)))">View</a>
        </div>
      </div>
    </template>

    <template v-else-if="task?.task_status?.status === 'FAILURE'">
      <div class="async-task-failure">
        <div class="icon-part">
          <i class="material-icons">error</i>
        </div>
        <div class="message-part">
          <div class="title-part">{{ getFailureLabel(task.type) }}</div>
          <div class="reason-part" v-if="task.task_status.msg">{{task.task_status.msg}}</div>
        </div>
        <div class="action-part">
          <router-link :to="getRouteFromTask(task)">Open</router-link>
        </div>
      </div>
    </template>
  
    <button
      v-if="!task.is_pending_or_in_progress"
      class="close-btn"
      aria-label="close"
      @click="acknowledgeTask(task)"
    >
      <i class="material-icons" style="font-size: 1.7rem;">close</i>
    </button>
  </div>

</div>
</template>

<script>
import { setIntervalAndRunImmediately } from '@/utils'

export default {
  props: ['siteId', 'campaignId'],
  data() {
    return {
      interval: null,
      isFirstLoadMade: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    asyncTasks() {
      return [
        ...this.$store.state.asyncTasks,                     // server side tasks
        ...this.$store.state.clientSideAsyncTasks            // client side tasks
    ].sort((a, b) => new Date(b.initiated_at) - new Date(a.initiated_at))  // desc
    },
    hasPendingOrInProgressBackendTasks() {
      return this.asyncTasks.filter((task) => !task.is_client_side && task.is_pending_or_in_progress).length > 0
    },
  },
  watch: {
    'campaignId': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.interval) {
          clearInterval(this.interval)
        }

        this.$store.commit("resetAsyncTasks");
  
        if (newVal) {
          this.startTaskMonitor()
        }
      }
    },
    '$store.state.triggerAsyncTasksRefresh': function () {
      this.getActiveTasks();
    }
  },
  methods: {
    acknowledgeTask (task) {
      if (task.is_client_side) {
        this.$store.commit('removeClientSideAsyncTask', task.id)
        return Promise.resolve()
      }
      return this.$store.dispatch('acknowledgeTask', {
        id: task.id,
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            return this.getActiveTasks()
          }
        })
    },
    getActiveTasks () {
      if (!this.isFirstLoadMade) {
        this.isFirstLoadMade = true
      }

      return this.$store.dispatch('getActiveTasks', {
        siteId: this.siteId,
        campaignId: this.campaignId
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.$store.commit("setAsyncTasks", response.data.data);
            return Promise.resolve()
          }
        })
    },
    startTaskMonitor () {
      this.interval = setIntervalAndRunImmediately(() => {
        
        // NOTE(hichem): notice how the tasks list call is only made on first mount or if the list has in-progress tasks
        // This is an optimization, so clients do not keep sending server requests if not needed

        // App components that start new tasks are supposed to manually call this.$store.commit('triggerAsyncTasksRefresh'),
        // in order to trigger this.getActiveTasks() which should make hasPendingOrInProgressBackendTasks == true

        if (!this.isFirstLoadMade || this.hasPendingOrInProgressBackendTasks) {
          this.getActiveTasks()
        }

      }, 3000)
    },
    getProgressLabel(type) {
      return {
        'KeywordResearch.task': 'Getting Keyword Insight data',
        'KeywordResearch.ai_task': 'Analyzing data with AI',
        'SupportKeywordResearch.ai_task': 'Analyzing data with AI',
        'TopLevelSupportKeywordResearch.ai_task': 'Analyzing data with AI',
        'SupportKeywordResearch.task.0': 'Getting keywords',
        'SupportKeywordResearch.task.1': 'Getting keywords data',
        'TopLevelSupportKeywordResearch.task.0': 'Getting keywords',
        'TopLevelSupportKeywordResearch.task.1': 'Getting keywords data',
        'PageOpportunity.form': 'Getting Google Console data',
        'KeywordWizardResearch.form': 'Getting Google Console data',
        'KeywordWizardResearch.monthly_search_task': 'Getting monthly search data',
        'KeywordWizardResearch.labels_task': 'Analyzing data with AI',
      }[type] || `In progress: ${type}`
    },
    getSuccessLabel(type) {
      return {
        'KeywordResearch.task': 'Keyword Insight data ready',
        'KeywordResearch.ai_task': 'AI analysis ready',
        'SupportKeywordResearch.ai_task': 'AI analysis ready',
        'TopLevelSupportKeywordResearch.ai_task': 'AI analysis ready',
        'SupportKeywordResearch.task.0': 'Keywords ready',
        'SupportKeywordResearch.task.1': 'Keywords data ready',
        'TopLevelSupportKeywordResearch.task.0': 'Keywords ready',
        'TopLevelSupportKeywordResearch.task.1': 'Keywords data ready',
        'PageOpportunity.form': 'Google Console data ready',
        'KeywordWizardResearch.form': 'Google Console data ready',
        'KeywordWizardResearch.monthly_search_task': 'Monthly search data ready',
        'KeywordWizardResearch.labels_task': 'AI analysis ready',
      }[type] || `${type} succeeded`
    },
    getFailureLabel(type) {
      return {
        'KeywordResearch.task': 'Keyword Insight data task failed',
        'KeywordResearch.ai_task': 'AI analysis task failed',
        'SupportKeywordResearch.ai_task': 'AI analysis task failed',
        'TopLevelSupportKeywordResearch.ai_task': 'AI analysis task failed',
        'SupportKeywordResearch.task.0': 'Keywords task failed',
        'SupportKeywordResearch.task.1': 'Keywords data task failed',
        'TopLevelSupportKeywordResearch.task.0': 'Keywords task failed',
        'TopLevelSupportKeywordResearch.task.1': 'Keywords data task failed',
        'PageOpportunity.form': 'Google Console data task failed',
        'KeywordWizardResearch.form': 'Google Console data task failed',
        'KeywordWizardResearch.monthly_search_task': 'Monthly search data task failed',
        'KeywordWizardResearch.labels_task': 'AI analysis task failed',
      }[type] || `${type} failed`
    },
    getRouteFromTask(task) {
      if (task.type === 'KeywordResearch.task') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-insight`
      } else if (task.type === 'KeywordResearch.ai_task') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-insight`
      } else if (task.type === 'SupportKeywordResearch.ai_task') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/existing-content/supporting-keyword-research`
      } else if (task.type === 'TopLevelSupportKeywordResearch.ai_task') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/new-content/keyword-only`
      } else if (task.type === 'SupportKeywordResearch.task.0') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/existing-content/supporting-keyword-research`
      } else if (task.type === 'SupportKeywordResearch.task.1') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/existing-content/supporting-keyword-research`
      } else if (task.type === 'TopLevelSupportKeywordResearch.task.0') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/new-content/keyword-only`
      } else if (task.type === 'TopLevelSupportKeywordResearch.task.1') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/new-content/keyword-only`
      } else if (task.type === 'PageOpportunity.form') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/existing-content/page-opportunity`
      } else if (task.type === 'KeywordWizardResearch.form') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/existing-content/site-only`
      } else if (task.type === 'KeywordWizardResearch.monthly_search_task') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/existing-content/site-only`
      } else if (task.type === 'KeywordWizardResearch.labels_task') {
        return `/sites/${this.$route.params.siteId}/campaign/${this.$route.params.campaignId}/keyword-wizard/existing-content/site-only`
      }
    }
  },
  mounted () {
    // NOTE: actual startTaskMonitor() call is is made in the 'campaignId' watcher above
    // this.startTaskMonitor()
  },
  destroyed () {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.$store.commit("resetAsyncTasks");
  }
};
</script>

<style lang="scss" scoped>
.async-tasks-popup {
  position: fixed;
  top: auto !important;
  right: 20px !important;
  bottom: 20px !important;
  left: auto !important;
  z-index: 30;
  overflow-y: scroll;
  width: 570px;
  max-height: 70vh;
  
  .async-task {
    background: var(--v-cardBg-base);
    border-radius: 4px;
    box-shadow: 0px 6px 10px -1px #0000001A;
    padding: 20px 16px;
    position: relative;

    .close-btn {
      position: absolute;
      top: 4px;
      right: 4px;
      .material-icons {
        color: #888;
        &:hover {
          color: #000;
        }
      }
    }

    .async-task-pending-or-progress {
      .title-part {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 700;
      }
      .progress-msg-part {
        .text {
          font-size: 0.95rem !important;
        }
      }
    }
    
    .async-task-success {
      display: flex;
      flex-direction: row;
      gap: 16px;
      .icon-part {
        color: #26B472;
        .material-icons {
          font-size: 30px;
        }
      }
      .title-part {
        flex: 1;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 700;
        padding-top: 3px;
      }
      .action-part {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 20px;
        text-align: right;
        a {
          font-size: 1rem;
          font-weight: 700;
          color: #FF5700;
          text-decoration: underline;

        }
      }
    }

    .async-task-failure {
      display: flex;
      flex-direction: row;
      gap: 16px;
      .icon-part {
        color: #e4220c;
        .material-icons {
          font-size: 30px;
        }
      }
      .message-part {
        flex: 1;
        padding-top: 3px;
        
        .title-part {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 700;
        }
        .reason-part {
          font-size: 0.9rem;
        }
      }
      .action-part {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 20px;
        text-align: right;
        a {
          font-size: 1rem;
          font-weight: 700;
          color: #FF5700;
          text-decoration: underline;

        }
      }
    }

  }
}
</style>
