<template>
  <div class="keyword-tracking-root">
    <div v-if="keywordTrack && trackKeywordRows?.length">
      <div class="pb-5">
        <div class="d-flex align-center gap20 flex-wrap">
          <div class="mb-4">
            <div class="card-big-title mb-4">
              Keep Track of Keyword Rank changes
              <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
                @click="() => {
                  $store.commit('setCurrentVideoTutorialToDisplay', {
                    title: 'How to Use the Keyword Tracker Tool',
                    videoLink: 'https://www.youtube.com/embed/ZvKyAujQJfc',
                    description: 'In this video, you\'ll learn how to use POP\'s Keyword Tracker tool. The Keyword Tracker tool takes the guesswork out of monitoring your keyword progress without bogging you down with complicated dashboards. You\'ll receive straightforward updates on your keywords, including old rank, new rank, rank changes, monthly search volume, and the current ranking URL. Plus, with email updates, you\'re always in the loop about any ranking changes.',
                    time: '1m 43s'
                  });
                }">
                videocam
              </span>
            </div>
            <div class="mainColor--text" style="max-width: 1100px; line-height: 1.4rem;">
              The Keyword Tracking Tool helps you monitor keyword rankings over time, showing changes in old and new ranks, rank shifts, and current ranking URLs. With simple metrics and optional email updates, it keeps you informed on SEO performance without complex dashboards.
            </div>
          </div>
          <div class="flex-grow-1 d-flex align-center justify-end gap20">
            <v-btn
              rounded
              color="profileAnchorColor"
              class="text-none whiteColor--text"
              :disabled="trackKeywordRows.length == 0"
              @click="startKeywordTrackAction"
            >
              <span>Start tracking monthly</span>
            </v-btn>
            <div class="d-flex align-center" style="gap: 5px">
              <vue-toggles
                :value="keywordTrack.sendEmailNotification == 1"
                width="40"
                height="20"
                :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                @click="changeEmailNotificationSetting"
              >
              </vue-toggles>
              <span class="mainColor--text"> Email me updates </span>
            </div>

            <v-btn
              outlined
              color="mainColor"
              :id="`dot-download-${keywordTrack.id}`"
              class="text-none"
            >
              <span class="material-icons-outlined">file_download</span>
              <span>Download</span>
            </v-btn>
            <v-menu
              :activator="`#dot-download-${keywordTrack.id}`"
              bottom
              offset-y
            >
              <v-list>
                <v-list-item link>
                  <v-list-item-title
                    class="mainColor--text"
                    @click="downloadAsCSV"
                    >Downlaod as CSV</v-list-item-title
                  >
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title
                    class="mainColor--text"
                    @click="downloadAsPDF"
                    >Download as PDF</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="mt-5 update-dates-wrapper">
          <div>
            <div class="icon-part">
              <span class="material-icons-outlined">history</span>
            </div>
            <div>
              <div class="date-label">Last rank tracking date</div>
              <div class="date-data">
                {{ toLocalDateString(keywordTrack.oldTrackDate) }}
              </div>
            </div>
          </div>
          <div>
            <div class="icon-part">
              <span class="material-icons-outlined">calendar_month</span>
            </div>
            <div>
              <div class="date-label">New rank tracking date</div>
              <div class="date-data">
                {{ toLocalDateString(keywordTrack.newTrackDate) }}
              </div>
            </div>
          </div>
          <div>
            <div class="icon-part">
              <span class="material-icons-outlined">event_available</span>
            </div>
            <div>
              <div class="date-label">Next update of rank tracking date</div>
              <div class="next-update-date">
                <span class="material-icons">check_circle</span>
                {{ toLocalDateString(keywordTrack.nextTrackDate) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <template>
        <div class="white-common-box mt-5 mb-10">
          <horizontal-two-scroll-bar>
            <table class="keyword-tracking-table" :id="tableId">
              <thead>
                <tr>
                  <th style="text-align: left;">Tracked keyword</th>
                  <th style="text-align: left;">Old rank</th>
                  <th style="text-align: left;">New rank</th>
                  <th style="text-align: left;">Change</th>
                  <th style="text-align: left;">Monthly Vol, Low to High</th>
                  <th style="text-align: left;">Current ranking URL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in trackKeywordRows" :key="idx">
                  <td>
                    <div class="keyword-col d-flex align-center gap10">
                      <div>{{ item.keyword }}</div>
                      <span
                        class="material-icons"
                        @click="
                          () => {
                            untrackKeywordAction(item);
                          }
                        "
                        >close</span
                      >
                    </div>
                  </td>
                  <td>
                    {{ item.oldRank || "None" }}
                  </td>
                  <td>
                    {{ item.currentRankGoogle }}
                  </td>
                  <td>
                    <span
                      v-if="item.rankDiff && item.rankDiff > 0"
                      class="material-icons-outlined green7Color--text"
                      >trending_up</span
                    >
                    <span
                      v-if="item.rankDiff && item.rankDiff < 0"
                      class="material-icons-outlined red3Color--text"
                      >trending_down</span
                    >
                    {{ item.rankDiff || "None" }}
                  </td>
                  <td>
                    {{ item.broadMatchMonthlySearches_LowEstimate }} -
                    {{ item.broadMatchMonthlySearches_HighEstimate }}
                  </td>
                  <td>
                    <a
                      v-if="item.currentRankingUrl"
                      :href="item.currentRankingUrl"
                      class="open-url-link"
                      >{{ item.currentRankingUrl }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </horizontal-two-scroll-bar>
        </div>
      </template>
    </div>
    <div v-else>
      <div class="card-big-title mb-4">
        Keyword Tracking
        <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
          @click="() => {
            $store.commit('setCurrentVideoTutorialToDisplay', {
              title: 'How to Use the Keyword Tracker Tool',
              videoLink: 'https://www.youtube.com/embed/ZvKyAujQJfc',
              description: 'In this video, you\'ll learn how to use POP\'s Keyword Tracker tool. The Keyword Tracker tool takes the guesswork out of monitoring your keyword progress without bogging you down with complicated dashboards. You\'ll receive straightforward updates on your keywords, including old rank, new rank, rank changes, monthly search volume, and the current ranking URL. Plus, with email updates, you\'re always in the loop about any ranking changes.',
              time: '1m 43s'
            });
          }">
          videocam
        </span>
      </div>
      <div class="mainColor--text" style="max-width: 1100px; line-height: 1.4rem;">
        The Keyword Tracking Tool helps you monitor keyword rankings over time, showing changes in old and new ranks, rank shifts, and current ranking URLs. With simple metrics and optional email updates, it keeps you informed on SEO performance without complex dashboards.
      </div>
      <div class="mt-5">
        <v-row class="py-2">
          <v-col cols="12" md="6" lg="4">
            <div class="d-flex">
              <img
                src="../../../assets/fontawesome/no-keyword.svg"
                alt="no-keyword"
                width="100%"
                style="max-width: 600px"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <div
              style="height: 100%"
              class="d-flex flex-column justify-center flex-gap-10"
            >
              <h3 class="font-weight-bold is-size-5 mainColor--text">
                You haven’t added any keywords yet
              </h3>
              <div class="mainColor--text">
                Please add some keywords from Keyword Insight or Keyword Strategy
                tabs to be able to track their change over time. Just click on
                the plus icon on the left side of the keywords. You will be
                redirected to Keyword Insight tab by default.
              </div>
              <div class="mainColor--text">
                Please note: You can track up to 250 keywords if you have an Unlimited package, and up to 500 keywords with a Teams plan.
              </div>
              <div class="d-flex flex-gap-10 items-center">
                <v-btn
                  rounded
                  color="profileAnchorColor"
                  class="text-none whiteColor--text"
                  @click="moveToKeywordInsight"
                >
                  <span>Go to Keyword Insight</span>
                </v-btn>
                <v-btn rounded outlined class="text-none" color="primary">
                  <span
                  @click="() => {
                    $store.commit('setCurrentVideoTutorialToDisplay', {
                      title: 'How to Use the Keyword Tracker Tool',
                      videoLink: 'https://www.youtube.com/embed/ZvKyAujQJfc',
                      description: 'In this video, you\'ll learn how to use POP\'s Keyword Tracker tool. The Keyword Tracker tool takes the guesswork out of monitoring your keyword progress without bogging you down with complicated dashboards. You\'ll receive straightforward updates on your keywords, including old rank, new rank, rank changes, monthly search volume, and the current ranking URL. Plus, with email updates, you\'re always in the loop about any ranking changes.',
                      time: '1m 43s'
                    });
                  }"
                  >Launch tutorial</span>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { toLocalDateString } from "@/utils";
import { mixin as export_mixin } from "@/components/export_mixin";
import HorizontalTwoScrollBar from "@/common/HorizontalTwoScrollBar.vue";

export default {
  mixins: [export_mixin],
  components: {
    HorizontalTwoScrollBar,
  },
  data() {
    return {
      keywordTrack: null,
    };
  },
  computed: {
    tableId() {
      return `track-table-${this.keywordTrack?.id || 0}`;
    },
    campaignId() {
      return parseInt(this.$route.params.campaignId || 0);
    },
    trackKeywordRows() {
      let result = [];
      if (this.keywordTrack) {
        result = this.keywordTrack.trackResult;
      }
      return result.map((item) => {
        item["oldRank"] = this.getOldRank(item);
        item["rankDiff"] = this.getRankDiff(item);
        return item;
      });
    },
    oldTrackKeywordRows() {
      let result = [];
      if (this.keywordTrack) {
        result = this.keywordTrack.oldTrackResult;
      }
      return result;
    },
  },
  watch: {
    campaignId: function (newVal, oldVal) {
      if (newVal) {
        this.loadKeywordTrack();
      }
    },
  },
  methods: {
    moveToKeywordInsight() {
      let currentRoute = this.$router.currentRoute.path.split("/");
      currentRoute[currentRoute.length - 1] = "keyword-insight";
      this.$router.push(currentRoute.join("/"));
    },
    toLocalDateString(d) {
      return toLocalDateString(d);
    },
    loadKeywordTrack() {
      this.$store
        .dispatch("getKeywordTrackForCampaign", this.campaignId)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.keywordTrack = response.data.keywordTrack;
          }
        });
    },
    getOldRank(item) {
      const oldResult = this.oldTrackKeywordRows.find(
        (r) => r.keyword == item.keyword
      );
      if (oldResult) {
        return oldResult.currentRankGoogle;
      } else {
        return null;
      }
    },
    getRankDiff(item) {
      const oldResult = this.oldTrackKeywordRows.find(
        (r) => r.keyword == item.keyword
      );
      if (oldResult) {
        const oldRank =
          typeof oldResult.currentRankGoogle == "number"
            ? oldResult.currentRankGoogle
            : 0;
        const newRank =
          typeof item.currentRankGoogle == "number"
            ? item.currentRankGoogle
            : 0;
        return newRank - oldRank;
      } else {
        return null;
      }
    },
    changeEmailNotificationSetting() {
      let data = {
        campaignId: this.campaignId,
        sendEmailNotification:
          this.keywordTrack.sendEmailNotification == 1 ? 0 : 1,
      };

      this.$store.commit("showLoading");
      this.$store
        .dispatch("updateTrackEmailNotificationSetting", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.keywordTrack = response.data.keywordTrack;
            this.$notify({
              group: "info",
              type: "success",
              text: "Successfully saved the notification setting",
            });
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: "Failed to save notification setting",
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    untrackKeywordAction(item) {
      let data = {
        campaignId: this.campaignId,
        trackKeyword: item.keyword,
      };

      this.$store.commit("showLoading");
      this.$store
        .dispatch("removeKeywordFromTrack", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.keywordTrack = response.data.keywordTrack;
            this.$notify({
              group: "info",
              type: "success",
              text: "Successfully removed keyword from track.",
            });
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: "Failed to remove keyword from the track.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    downloadAsCSV() {
      console.log("cccc clicked");
      let rlt = [];
      rlt.push([
        "Tracked keyword",
        "Old rank",
        "New rank",
        "Change",
        "Monthly Volumn(Low-High)",
        "Current ranking URL",
      ]);
      this.trackKeywordRows.map((item) => {
        rlt.push([
          item.keyword,
          item.oldRank || "None",
          item.currentRankGoogle,
          item.rankDiff || "None",
          `${item.broadMatchMonthlySearches_LowEstimate} - ${item.broadMatchMonthlySearches_HighEstimate}`,
          item.currentRankingUrl,
        ]);
      });
      let csvDownloadData = rlt
        .map((item) => {
          return item.join(",");
        })
        .join("\n");
      this.exportToCSV(
        csvDownloadData,
        `track-keywords-${this.keywordTrack?.id || ""}.csv`
      );
    },
    downloadAsPDF() {
      const columns = [
        "Tracked keyword",
        "Old rank",
        "New rank",
        "Change",
        "Monthly Vol, Low to High",
        "Current ranking URL",
      ];
      const rows = this.trackKeywordRows.map((row) => [
        row.keyword,
        row.oldRank || "None",
        row.currentRankGoogle,
        row.rankDiff || "None",
        `${row.broadMatchMonthlySearches_LowEstimate} - ${row.broadMatchMonthlySearches_HighEstimate}`,
        row.currentRankingUrl || "",
      ]);

      this.generatePDF(
        columns,
        rows,
        `track-keywords-${this.keywordTrack?.id || ""}`
      );
    },
    startKeywordTrackAction() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("startKeywordTrack", {
          campaignId: this.campaignId,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.keywordTrack = response.data.keywordTrack;
            this.$notify({
              group: "info",
              type: "success",
              text: "Started the track.",
            });
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: response.data.msg || "Failed",
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
  },
  mounted() {
    if (this.campaignId && !this.keywordTrack) {
      this.loadKeywordTrack();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../keyword-research.scss";
.keyword-tracking-root {
  .update-dates-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    & > div {
      display: flex;
      align-items: center;
      gap: 12px;
      background: var(--v-whiteColor-base);
      border: 1px solid var(--v-grayColor-base);
      padding: 20px;
      border-radius: 5px;
      .icon-part {
        span {
          background: var(--v-lightYellowColor-base);
          color: var(--v-profileAnchorColor-base);
          font-size: 2rem;
          padding: 5px;
          border-radius: 5px;
        }
      }
      .date-label {
        font-size: 0.85rem;
        font-weight: 700;
        color: var(--v-mainColor-base);
      }
      .date-data {
        font-size: 1.1rem;
        margin-top: 10px;
        font-weight: 700;
        color: var(--v-mainColor-base);
      }
      .next-update-date {
        color: var(--v-green7Color-base);
        font-size: 1.1rem;
        font-weight: 700;
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .h-line {
    height: 1px;
    background: var(--v-grayColor-base);
  }

  table {
    tr:nth-child(odd) td {
      background: var(--v-pink1Color-base);
    }
  }
}
</style>
