<template>
<div class="modal" :class="{'is-active': showModal}">
  <div class="modal-background" @click="hideThisModal"></div>
  <div class="modal-content">
    <div class="box modal-container">
      <div class="modal-content-root">
        <div class="title-font is-size-4 mainColor--text mb10">
          Keyword cannibalization report
        </div>
        <div class="mt-2 mainColor--text">
          Keyword cannibalization level is {{ keywordCannibalizationText }}. These are all URLs that are being cannibalized, meaning that they all rank for the same keyword. Consider restructuring your website, consolidate your content or create new landing pages.          
        </div>
        <div class="mt-5 voltage-result-area">
          <div class="header-part d-flex align-center">
            <v-text-field outlined dense hide-details
              label="Search"
              prepend-inner-icon="search"
              style="max-width: 300px;"
              v-model="searchTerm">
            </v-text-field>
            <div class="flex-grow-1"></div>
            <div>
              <v-btn outlined small
                color="mainColor"
                class="text-none button-with-icon"
                @click="downloadAsCSV">
                <i class="material-icons-outlined">file_download</i>
                <span>Download</span>
              </v-btn>
            </div>
          </div>
          <b-table
            class="voltage-table"
            :data="filteredRows"
            paginated
            :total="filteredRows.length"
            :per-page="perPage"
            :default-sort-direction="'desc'"
            :default-sort="[sortField, sortOrder]">
            <template slot-scope="props">
              <b-table-column field="url" label="Url" class="url-cell">
                <a target="_blank" :href="props.row.page" sortable :title="props.row.page">
                  {{ props.row.page }}
                </a>
              </b-table-column>
              <b-table-column field="keyword" label="Keyword" sortable>
                {{ props.row.keyword }}
              </b-table-column>
              <b-table-column field="clicks" label="Clicks" sortable>
                {{ props.row.clicks }}
              </b-table-column>
              <b-table-column field="impressions" label="Impressions" sortable>
                {{ props.row.impressions }}
              </b-table-column>
              <b-table-column field="ctr" label="CTR %" sortable>
                {{ props.row.ctr }} %
              </b-table-column>
              <b-table-column field="position" label="AVG. position" sortable>
                {{ props.row.position }}
              </b-table-column>
            </template>
          </b-table>
        </div>
        
      </div>
    </div>
    <button class="custom-modal-close-btn" aria-label="close" @click="hideThisModal"><i class="material-icons">close</i></button>
  </div>
</div>
</template>

<script>
import { mixin as export_mixin } from '@/components/export_mixin'

export default {
  mixins: [ export_mixin ],
  data () {
    return {
      showModal: false,

      cannibalizedKeywords: [],
      keywordCannibalizationText: '',

      searchTerm: '',
      perPage: 5,
      sortField: 'keyword',
      sortOrder: 'asc',
    }
  },
  computed: {
    filteredRows () {
      return this.cannibalizedKeywords.filter(item => {
        return item.keyword.includes(this.searchTerm) || item.page.includes(this.searchTerm)
      })
    }
  },
  methods: {
    openCannibalizationPopupAction (cannibalizedKeywords, keywordCannibalizationText) {
      this.cannibalizedKeywords = cannibalizedKeywords
      this.keywordCannibalizationText = keywordCannibalizationText
      this.showModal = true;
    },
    hideThisModal () {
      this.showModal = false;
    },
    downloadAsCSV () {
      let results = []
      results.push([
        'Keyword', 'Url', 'Clicks', 'Impressions', 'CTR', 'AVG. position'
      ])
      this.filteredRows.map(row => {
        results.push([
          row.keyword, row.page, row.clicks, row.impressions, row.ctr, row.position
        ])
      })
      
      let csvData = results.map(item => {
        return item.join(', ')
      }).join('\n')
      this.exportToCSV(csvData, `keyword_cannibalization.csv`);
    }

  }
}
</script>

<style lang="scss" scoped>
@import '../../../keyword-research.scss';

</style>