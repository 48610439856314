<template>
<section class="section pt5">
  <div class="container">
    <div class="columns">
      <div class="column is-offset-1 is-10">
        <div class="field">
          <div class="control flex justify-end">
            <div @click="showAddUpdateNotification = true" class="add-item is-link has-text-centered">
              <i class="fa fa-plus-circle fa-add-item" style="font-size:72px; cursor:pointer;"></i>
              <p class='has-text-centered'>Add Notification</p>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="has-text-centered column hscroll-container">
            <div class="field is-horizontal">
              <div class="control">
                <div class="select">
                  <select v-model="perPage">
                    <option value="10">10 per page</option>
                    <option value="20">20 per page</option>
                    <option value="50">50 per page</option>
                    <option value="100">100 per page</option>
                  </select>
                </div>
              </div>
            </div>
            <b-table
            :data="notifications"
            :loading="isLoading"
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            :current-page="page"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-directrion="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            :mobile-cards="true"
            @sort="onSort">
            <template slot-scope="props">
              <b-table-column field="id" label="ID" sortable numeric>{{ props.row.id }}</b-table-column>
              <b-table-column field="title" label="Title" sortable>{{ props.row.title }}</b-table-column>
              <b-table-column field="message" label="Message" sortable width="250" style="word-break:break-word; min-width: 250px;">{{ props.row.message }}</b-table-column>
              <b-table-column field="group" label="User Group" sortable>{{ props.row.group }}</b-table-column>
              <b-table-column field="expiryDate" label="Expiry Date" sortable>{{ toLocalDateString(props.row.expiryDate) }}</b-table-column>
              <b-table-column field="isDispLoginPage" label="Display on Login">{{ props.row.isDispLoginPage ? 'YES' : 'NO' }}</b-table-column>
              <b-table-column field="isDispInsideDashboard" label="Display on Dashboard">{{ props.row.isDispInsideDashboard ? 'YES' : 'NO' }}</b-table-column>
              <b-table-column field="" label="Action">
                <div class="field has-addons">
                  <div class="control">
                    <button class="button is-primary" @click="initEditing(props.row)">Edit</button>
                  </div>
                  <div class="control">
                    <button class="button is-warning" @click="deleteNotification(props.row)">Delete</button>
                  </div>
                </div>
              </b-table-column>
            </template>

            </b-table>
          </div>
        </div>

      </div>
    </div>
    <div class="modal" :class="{'is-active': showAddUpdateNotification}">
      <div class="modal-background" @click="resetFields"></div>
      <div class="modal-content" style="min-width: 70vw;">
        <div class="box modal-container">

          <h3 v-if="isEditMode" class="subtitle">Update Notification</h3>
          <h3 v-else class="subtitle">Create Notification</h3>

          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Title</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="text" class="input" placeholder="Message title" v-model="title">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Message</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <textarea class="textarea" v-model="text" rows="4" placeholder="Notification message ..."></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Color</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <compact-picker
                    @input="updateColor"
                    :value="color"
                    :palette="colorPalette"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Expiry Date</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <datepicker v-model="expiryDate" input-class="input" placeholder="Select Date"></datepicker>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Group</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <div class="select">
                    <select @change="onSelectedGroupChange" v-model="selectedGroup">
                      <option v-for="grp in userGroups" :value="grp" :key="grp">{{ grp }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal" v-if="selectedGroup !== 'All'">
            <div class="field-label">
              <label class="label">User</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div style="z-index: 99">
                  <multiselect
                    v-model="selectedUserObjects"
                    tag-placeholder="Select users to send notifications"
                    placeholder="Search user" label="email" track-by="id" :options="filteredUsers" :multiple="true" :taggable="true"></multiselect>
                </div>
                <!-- <div class="control">
                  <div class="select is-multiple">
                    <select multiple v-model="selectedUsers">
                      <option v-for="u in filteredUsers" :value="u.id" :key="u.id">{{ u.email }}</option>
                    </select>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label"></div>
            <div class="field-body">
              <label class="checkbox">
                <input type="checkbox" v-model="isDispLoginPage">
                Notification on Login Page
              </label>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label"></div>
            <div class="field-body">
              <label class="checkbox">
                <input type="checkbox" v-model="isDispInsideDashboard">
                Notification inside Dashboard
              </label>
            </div>
          </div>
          <div class="field is-horizontal" v-if="formInputError">
            <div class="field-label"></div>
            <div class="field-body">
              <div class="has-text-danger is-italic">
                {{ formInputError }}
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <label class="label">Preview</label>
            </div>
            <div class="field-body">
              <div class="full-width-container">
                <div
                  v-if="isDispLoginPage"
                  class="custom-notification">
                  <div class="icon-wrapper" :style="{ 'background': color.hex }">
                    <i class="material-icons n-icon" :style="{ 'color': '#FFFFFF' }">{{'notification_important'}}</i>
                  </div>
                  <div class="n-body">
                    <b class="n-title">{{ title }}</b>
                    <div class="n-content" v-html="text"></div>
                  </div>
                </div>
                <div
                  v-if="isDispInsideDashboard"
                  class="notification-in-dashboard"
                  :style="{background: color.hex ? color.hex : '#ff9b00'}">
                  <div class="flex justify-center has-text-white px-4">
                    <!-- <span class="has-text-weight-bold">{{ title }}:&nbsp;</span> -->
                    <span class="has-text-weight-bold" v-html="text"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label"></div>
            <div class="field-body">
              <div class="field">
                <div class="control is-pulled-right">
                  <button @click="submitNotification" class="button is-primary" >
                    <span class="icon">
                      <i class="fa fa-bullhorn"></i>
                    </span>
                    <span>Save</span>
                  </button>
                  <button @click="showAddUpdateNotification = false" class="button is-primary" >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'
import { Swatches, Compact } from 'vue-color'
import { toLocalDateString } from '@/utils'
import { mixin as ServiceMixin } from '@/components/service_mixin'

let defaultColor = {
  hex: '#ff9b00',
}

export default {
  components: {
    Datepicker, Multiselect,
    'swatches-picker': Swatches,
    'compact-picker': Compact,
  },
  mixins: [ ServiceMixin ],
  data () {
    return {
      isEditMode: false,
      showAddUpdateNotification: false,
      users: [],
      notifications: [],
      expiryDate: new Date(),
      title: '',
      text: '',
      color: defaultColor,
      colorPalette: [
        '#25A97A', '#FF6266', '#3E65B7'
        // '#4FBA86', '#F6EAAD', '#ff9b00', '#fa0019', '#359cec', '#26ce43', '#6766cc',
      ],
      userGroups: ['All', 'Affiliates', 'Individuals', 'Unlimited', 'Unlimited FREE', 'Credits', 'Pay Per Credit FREE', 'Starter', 'Basic', 'Premium'],
      selectedGroup: 'All',
      selectedUserObjects: [],
      selectedUsers: [],
      isDispLoginPage: 0,
      isDispInsideDashboard: 0,
      formInputError: '',

      isLoading: false,
      total: 0,
      id: null,
      page: 1,
      perPage: 20,
      defaultSortOrder: 'desc',
      sortField: 'id',
      sortOrder: 'desc'
    }
  },
  watch: {
    'selectedUserObjects': function(val) {
      this.selectedUsers = val.map(item => item.id)
    }
  },
  filters: {
    truncate (value) {
      if (value.length > 40) {
        return value.substr(0, 40) + '...'
      }
      return value
    }
  },
  computed: {
    currentUser () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    filteredUsers () {
      if (this.selectedGroup === 'Affiliates') {
        return this.users.filter((u) => u.isAffiliate)
      } else if (this.selectedGroup === 'All' || this.selectedGroup === 'Individuals'){
        return this.users
      } else {
        return this.users.filter(u => this.serviceLabel(u.accountType) === this.selectedGroup)
      }
    },
    filteredUsersDict () {
      let users = []
      if (this.selectedGroup === 'Affiliates') {
        users = this.users.filter((u) => u.isAffiliate)
      } else if (this.selectedGroup === 'All' || this.selectedGroup === 'Individuals'){
        users = this.users
      } else {
        users = this.users.filter(u => this.serviceLabel(u.accountType) === this.selectedGroup)
      }
      let rlt = {}
      users.map(u => {
        rlt[u.id] = u
      })
      return rlt
    }
  },
  methods: {
    toLocalDateString (dateStr) {
      return toLocalDateString(dateStr)
    },
    resetFields () {
      this.formInputError = ''
      this.id = null,
      this.title = ''
      this.text = ''
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      this.expiryDate = tomorrow
      this.selectedGroup = 'All'
      this.selectedUserObjects = []
      this.isEditMode = false
      this.isDispLoginPage = 0
      this.isDispInsideDashboard = 0
      this.color = defaultColor
      this.showAddUpdateNotification = false
    },
    initEditing (notification) {
      console.log(notification)
      this.formInputError = ''
      this.isEditMode = true
      this.id = notification.id
      this.title = notification.title
      this.text = notification.message
      this.expiryDate = notification.expiryDate
      this.selectedGroup = notification.group
      this.selectedUserObjects = notification.userNotifications.map(un => {
        return this.filteredUsersDict[un.userId]
      }).filter(user => user!=undefined)
      console.log(this.selectedUserObjects)
      this.isDispLoginPage = notification.isDispLoginPage
      this.isDispInsideDashboard = notification.isDispInsideDashboard
      this.showAddUpdateNotification = true
      if (notification.color) {
        this.color['hex'] = notification.color
      } else {
        this.color = defaultColor
      }
    },
    updateColor (newColor) {
      // console.log(newColor)
      this.color['hex'] = newColor['hex']
    },
    onPageChange (page) {
      this.page = page
      this.$nextTick(() => {
        this.loadData()
      })
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadData()
    },
    onSelectedGroupChange (evt) {
      const group = evt.target.value
      console.log('onSelectedGroupChange', group)
    },
    onNotificationExpirySelected() {
      console.log('onNotificationExpirySelected')
    },
    loadData () {
      this.$store.commit('showLoading')
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.page}`,
        `perPage=${this.perPage}`,
        `filterField=${this.filterField}`,
        `filterText=${this.searchText}`
      ].join('&')
      return this.$store.dispatch('fetchAllUsersForAdmin').then((response) => {
        this.users = response.data
        return this.$store.dispatch('fetchAllNotificationsForAdmin', { params }).then((response) => {
          const { notifications, total } = response.data
          this.notifications = notifications
          this.total = total
          this.$store.commit('hideLoading')
        })
      })
    },
    updateNotification (notification) {
      console.log('updateNotification', nofication)
    },
    deleteNotification (notification) {
      this.$store.dispatch('deleteNotificationForAdmin', { id:notification.id })
        .then(() => this.loadData())
    },
    submitNotification () {
      if ( !this.isDispLoginPage && !this.isDispInsideDashboard ) {
        this.formInputError = 'You have to check at least one for the notification to display'
        return
      }
      const action = this.isEditMode ? 'updateNotificationForAdmin' : 'createNotification'
      const notification = {
        id: this.id ? this.id : null,
        title: this.title,
        text: this.text,
        expiryDate: this.expiryDate,
        userGroup: this.selectedGroup,
        users: this.selectedGroup === 'All' ? [] : this.selectedUsers,
        isDispLoginPage: this.isDispLoginPage ? 1 : 0,
        isDispInsideDashboard: this.isDispInsideDashboard ? 1 : 0,
        color: this.color.hex
      }
      this.$store.commit('showLoading')
      return this.$store.dispatch(action, notification)
        .then((response) => {
          this.notifications.push(response.data)
          this.resetFields()
          return this.loadData()
        })
        .finally(() => this.$store.commit('hideLoading'))
    }
  },
  beforeMount () {
    this.resetFields()
    this.loadData()
  }
}
</script>

<style lang="scss">
.table-mobile-sort {
  display: none;
}
</style>