<template>
<div class="user-profile">
    <div class="sidebar">
      <div class="title-label">Account settings</div>
      <ul>
        <li
          v-for="item in filteredTabs" :key="item.key"
          @click="() => changeMainTab(item)"
          :class="{ 'active': selectedMainTab == item.key }">
          {{ item.label }}
        </li>
      </ul>
    </div>

    <div class="content-area pb-5">
      <div class="mobile-menu">
        <v-btn fab x-small
          color="mainColor"
          class="whiteColor--text"
          id="sidebar-on-mobile">
          <v-icon>menu</v-icon>
        </v-btn>
        <v-menu activator="#sidebar-on-mobile" bottom offset-y>
          <v-list>
            <v-list-item v-for="item in filteredTabs" :key="item.key">
                <v-list-item-title tag="v-btn" rounded outlined block
                  color="mainColor" class="button-with-icon text-none"
                  @click="() => changeMainTab(item)"
                  >
                  <span>{{ item.label }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div v-show="currentMainTab">
        <div class="title-label d-flex align-center gap10">
          {{ currentMainTab.label }}
          <template v-if="currentMainTab.info">
            <v-tooltip right color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <i class=" material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
              </template>
              <div class="white-bg-tooltip" style="max-width: 500px;">
                {{ currentMainTab.info }}
              </div>
            </v-tooltip>
          </template>
          
          <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
            v-if="selectedMainTab == 'credit-logs'"
            @click="() => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: 'How to use and read Credit Log dashboard',
                videoLink: 'https://www.youtube.com/embed/T9NUg_WN0FY?si=xJ_h1hJ7ynQcfD5p',
                description: 'In this video you\'ll learn how to read POP Credit Log dashboard.',
                time: '2m 49s'
              });
            }">
            videocam
          </span>
        </div>
        <div class="submenus mb-1">
          <div v-for="item in currentMainTab.subMenu" :key="item.key"
            :class="{ 'active': selectedSubTab == item.key }"
            @click="() => { selectedSubTab = item.key }">
            {{ item.label }}
          </div>
        </div>
        <!-- {{ selectedMainTab }} / {{ selectedSubTab }} -->

        <div v-show="selectedSubTab == 'email'">
          <change-email></change-email>                  
        </div>
        <div v-show="selectedSubTab == 'password'">
          <update-password></update-password>
        </div>
        <div v-show="selectedSubTab == 'company-details'">
          <div class="limited-width-box">
            <v-card class="is-fullheight flex flex-column white-common-box">
              <div class="pa-5 flex flex-column">
                <div class="title-font mainColor--text box-title-label">
                  Add or edit company details
                </div>

                <div class="notify-label">
                  <i class="material-icons">error_outline</i>
                  Information added here will appear on your invoices
                </div>

                <v-text-field dense hide-details="auto"
                  label="Company Name"
                  color="greenColor"
                  v-model="companyData.companyName"
                  outlined
                ></v-text-field>
                <v-text-field dense hide-details="auto"
                  label="VAT ID / Company ID"
                  color="greenColor"
                  v-model="companyData.vatId"
                  outlined
                ></v-text-field>
                <v-text-field dense hide-details="auto"
                  label="First Name"
                  color="greenColor"
                  v-model="companyData.firstName"
                  outlined
                ></v-text-field>
                <v-text-field dense hide-details="auto"
                  label="Last Name"
                  color="greenColor"
                  v-model="companyData.lastName"
                  outlined
                ></v-text-field>
                <v-text-field dense hide-details="auto"
                  label="Street Address"
                  color="greenColor"
                  v-model="companyData.streetAddr"
                  outlined
                ></v-text-field>
                <v-text-field dense hide-details="auto"
                  label="City"
                  color="greenColor"
                  v-model="companyData.city"
                  outlined
                ></v-text-field>
                <v-text-field dense hide-details="auto"
                  label="State/Province"
                  color="greenColor"
                  v-model="companyData.stateProvince"
                  outlined
                ></v-text-field>
                <v-text-field dense hide-details="auto"
                  label="Postal Code"
                  color="greenColor"
                  v-model="companyData.postalCode"
                  outlined
                ></v-text-field>
                <v-text-field dense hide-details="auto"
                  label="Country"
                  color="greenColor"
                  v-model="companyData.country"
                  outlined
                ></v-text-field>
                
                <v-checkbox label="Add Reverse Charge"
                  v-model="companyData.addReverseCharge">Add Reverse Charge</v-checkbox>
                
                <div>
                  <v-btn rounded
                    color="lightGreenColor"
                    class="button-with-icon text-none whiteColor--text" @click="submitCompanyDetail">
                    Save changes
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        
        </div>

        <div v-show="selectedSubTab == 'white-label-reports'">
          <upload-logo></upload-logo>
        </div>

        <div v-show="selectedSubTab == 'other-features'" class="limited-width-box">
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 flex-grow-1 flex flex-column">
              <div class="title-font mainColor--text box-title-label">
                Other features
              </div>
              <div class="notify-label">
                <i class="material-icons">error_outline</i>
                Well Done feature plays animation each time you achieve a high optimization score in POP
              </div>
              <div class="mt-5">
                <div class="d-flex align-center">
                  <vue-toggles
                    :value="isEnabledWellDonePopup"
                    width="40" height="20"
                    :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                    :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
                    @click="() => saveWellDonePopupSetting()"
                    >
                  </vue-toggles>
                  <div class="ml-2 mainColor--text">
                    Enable Well Done feature
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </div>

        

        <div v-show="selectedSubTab == 'cancel-subscription'" class="limited-width-box">
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 flex-grow-1 flex flex-column">
              <div class="title-font mainColor--text box-title-label">
                Cancel subscription
              </div>
              <div class="flex-grow-1 darkGrayColor--text">
                <div class="mt-5"
                  v-if="!isFreeUser && !user.activeSubscriber && (activeSubscription || ( lastSubscriptionPurchase && lastSubscriptionPurchase.cancelledOn ))">
                  <template v-if="activeSubscription && isFutureDate(user.timeLeftCancelledSubscription)">
                    You cancelled your subscription on {{ toLocalDateString(activeSubscription.cancelledOn) }}. <br/>
                    You will have access to your POP plan until {{ toLocalDateString(user.timeLeftCancelledSubscription) }}.<br/>
                    After your plan ends you will still have access to your reports and unused purchased POP credits.
                  </template>
                  <template v-else-if="lastSubscriptionPurchase">
                    You cancelled your subscription on {{ toLocalDateString(lastSubscriptionPurchase.cancelledOn) }}.
                  </template>
                </div>
              </div>
              <div class="mt-5" v-if="user.activeSubscriber">
                <v-btn rounded outlined
                  color="mainColor"
                  class="button-with-icon button-label-bold text-none mt10"
                  @click.stop="cancelSubscriptionButtonAction"
                >
                  <span>Cancel subscription</span>
                </v-btn>
              </div>
            </div>
          </v-card>

          <v-card class="is-fullheight flex flex-column white-common-box mt-5">
            <div class="pa-5 flex-grow-1 flex flex-column">
              <div class="title-font mainColor--text box-title-label">
                Cancellation code (optional)
              </div>
              <div class="flex-grow-1 darkGrayColor--text">
                <div class="mt25 cancellation-explain-label">
                  If you wish to cancel via email or through live chat, please click the button to generate a cancellation code. You must send that code to POP support to confirm account ownership.
                </div>
                <div class="mt20 cancellation-explain-label">
                  Please note: simply creating the code doesn’t cancel your account. If you are close to the end of your subscription month, we recommend cancelling your account by clicking the cancel subscription button to avoid any unwanted charges.
                </div>
                <div class="mt10 mainColor--text has-text-weight-bold">{{ user.cancellationCode }}</div>
              </div>
              <div class="mt30">
                <v-btn rounded
                  @click="generateCancellationCode"
                  color="grayColor"
                  class="button-with-icon text-none mainColor--text">
                  {{
                    user.cancellationCode ? "Re-generate Code" : "Generate Code"
                  }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>

        <div v-show="selectedSubTab == 'delete-account'" class="limited-width-box">
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 flex-grow-1 flex flex-column" v-if="!isEndUser">
              <div class="title-font mainColor--text box-title-label">
                Delete account
              </div>
              <div class="sure-delete-label">
                Are you sure you want to delete your account? All of your data will be gone. <br/>
                Additionally, you will not be able to use  your account email again.
              </div>
              <div>
                <v-btn rounded
                  color="red3Color"
                  class="text-none whiteColor--text font-weight-bold"
                  @click="showDeleteAccountModal = true">
                  Delete my account
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>

        <div v-show="selectedSubTab == 'change-plan'" style="max-width: 800px;">
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 flex-grow-1">
              <div class="your-current-plan mt10 mb10">Your current plan</div>
              <div class="account-type">{{ serviceLabel(user.accountType) }}</div>

              <div class="notify-label">
                <i class="material-icons">error_outline</i>
                <span>
                  <template v-if="!isFreeUser">
                    <template v-if="!user.activeSubscriber && (activeSubscription || ( lastSubscriptionPurchase && lastSubscriptionPurchase.cancelledOn ))">
                      <template v-if="activeSubscription && isFutureDate(user.timeLeftCancelledSubscription)">
                        You cancelled your subscription on {{ toLocalDateString(activeSubscription.cancelledOn) }}. <br/>
                        You will have access to your POP plan until {{ toLocalDateString(user.timeLeftCancelledSubscription) }}.<br/>
                        After your plan ends you will still have access to your reports and unused purchased POP credits.
                      </template>
                      <template v-else-if="lastSubscriptionPurchase && lastSubscriptionPurchase.cancellationReason == 'payment_failed'">
                        We cancelled your subscription on {{ toLocalDateString(lastSubscriptionPurchase.cancelledOn) }} due to a failed charge. <br />
                        To continue using POP with your plan, please review your payment method and resubscribe in Account settings >> Billing and payments.
                      </template>
                      <template v-else-if="lastSubscriptionPurchase">
                        You cancelled your subscription on {{ toLocalDateString(lastSubscriptionPurchase.cancelledOn) }}.
                      </template>
                    </template>
                    <template v-else-if="isTrialUser && isUserInTrialPlan">
                      Your trial period ends on {{ toLocalDateString(user.trialEndingDate) }}. <br />
                      During your trial period you cannot use a domain that you or someone else has previously used.
                    </template>
                    <span v-else-if="isWatchdogOnlyUser && lastSubscriptionPurchase">
                      Your subscription was downgraded to the Watchdog Only plan on {{ toLocalDateString(lastSubscriptionPurchase.createdAt) }}
                    </span>
                    <template v-else-if="user.activeSubscriber">
                      Your monthly balance will reset with your billing cycle.<br/>
                      Unused monthly credits do not roll over.
                    </template>
                  </template>
                  <template v-else-if="!user.activeSubscriber">
                    You do not have any active subscriptions
                    <template v-if="user.timeLeftCancelledSubscription">
                      <template v-if="activeSubscription.cancelledOn">
                        You cancelled on
                        {{
                          toLocalDateString(activeSubscription.cancelledOn)
                        }}.<br />
                      </template>
                      <template v-if="user.isFreePeriodUser">
                        You're on Free Period. You can use until {{ toLocalDateString(user.timeLeftCancelledSubscription) }} for free.
                      </template>
                      <template v-else>
                        You can still use POP under your current plan until
                        {{
                          toLocalDateString(user.timeLeftCancelledSubscription)
                        }}
                        which is the end of your billing cycle.
                      </template>
                    </template>
                  </template>
                </span>
              </div>

              <div class="d-flex">
                <div class="flex-grow-1 d-flex align-center">
                  <div class="your-current-plan">
                    <div class="mainColor--text mb-2">
                      {{ getAccountBalanceLabel(user)[0] }}
                      <template v-if="isUnlimitedUser"> ( Unlimited reports )</template>
                    </div>
                    <span class="mainColor--text">{{ getAccountBalanceLabel(user)[1] }}</span>
                  </div>
                </div>
                <div v-if="!isEndUser" class="flex justify-end align-center">
                  <div>
                    <v-btn rounded outlined block
                      color="mainColor"
                      class="button-with-icon button-label-bold text-none"
                      :to="`/useraccount/${user.id}/purchase`">
                      Change plan
                    </v-btn>
                    <v-btn rounded outlined block
                      v-if="user.activeSubscriber"
                      color="mainColor"
                      class="button-with-icon button-label-bold text-none mt10"
                      @click.stop="cancelSubscriptionButtonAction"
                    >
                      <span>Cancel subscription</span>
                    </v-btn>
                    <reactivate-subscription />
                  </div>
                </div>
              </div>
              <seat-management v-if="isAvailableToPurchaseAdditionalSeats" class="mt15"></seat-management>
            </div>
          </v-card>
        </div>

        <div v-if="selectedSubTab == 'buy-credits'" class="limited-width-box">
          <universal-credits></universal-credits>
        </div>

        <!-- <div v-show="selectedSubTab == 'buy-nlp-eeat'" class="limited-width-box">
          <nlp-eat-credits></nlp-eat-credits>
        </div> -->

        <div v-show="selectedSubTab == 'credit-cards'" class="limited-width-box">
          <manage-cards></manage-cards>
        </div>
        <div v-show="selectedSubTab == 'api-add-ons'">
          <api-add-ons></api-add-ons>
        </div>
        <div v-if="selectedSubTab == 'for-developers'">
          <api-permission-for-dev></api-permission-for-dev>
        </div>
        

        <div v-show="selectedSubTab == 'promo-codes'" class="limited-width-box">
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 flex flex-column">
              <div class="title-font mainColor--text box-title-label">
                Promo codes
              </div>
              <div class="mt-5">
                <div class="flex-grow-1 flex flex-column" v-if="!isEndUser">
                  <div class="flex-grow-1">
                    <v-text-field dense outlined
                      color="greenColor"  
                      class="mt-2 mb-0"
                      :hide-details="true"
                      v-model="couponCode" label="Promo code"></v-text-field>
                    <div v-if="isCouponApplyError && couponCodeApplyMsg" class="red2Color--text">
                      {{ couponCodeApplyMsg }}
                    </div>
                  </div>
                  <div>
                    <v-btn rounded
                      color="lightGreenColor"
                      class="button-with-icon text-none whiteColor--text"
                      :disabled="!couponCode"
                      @click.stop="submitCoupon"
                    >
                      Apply
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-card> 
          <div class="title-font mainColor--text box-title-label mt-10">
            Promo codes history
          </div>
          <v-card class="is-fullheight flex flex-column white-common-box mt-2">
            <div class="pa-5">
              <div v-if="!isEndUser">
                <table class="custom-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Promo code</th>
                      <th>Applied on</th>
                      <th>Promo type</th>
                      <th>Amount</th>
                      <th>Product applied to</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(coupon, i) in appliedCoupons" :key="i">
                      <td></td>
                      <td>
                        {{ coupon.codeAlias ? coupon.codeAlias : coupon.code }}
                      </td>
                      <td>{{ toLocalDateString(coupon.appliedOn) }}</td>
                      <td class="nowrap">{{ coupon.couponType }}</td>
                      <td>
                        {{ coupon.amount }}&nbsp;
                        <template v-if="coupon.couponType == 'Credit Percentage Off'">
                           % Off
                        </template>
                        <template v-else-if="coupon.couponType == 'Period Free'">
                          days free
                        </template>
                      </td>
                      <td v-html="simplifyProductName(coupon.product)"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-card>       
        </div>

        <div v-show="selectedSubTab == 'invoice-history'">
          <user-invoices></user-invoices>          
        </div>

        <div v-show="isWhiteGloveUser && selectedSubTab == 'white-glove-addons'">
          <white-glove-addons-tab></white-glove-addons-tab>          
        </div>

        <div v-show="selectedMainTab == '2-factor-auth'" class="limited-width-box">
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 flex-grow-1 flex flex-column">
              <div class="notify-label">
                <i class="material-icons">error_outline</i>
                If you want to make your account more secure, you can enable two factor authentication. When enabled, you will receive an email with a security code to enter into POP every time you login.
              </div>
              <div>
                <vue-toggles
                  :value="user.enabled2FaAuth"
                  width="40" height="20"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="() => toggleChange2FaAuthConfirmModalAction()"
                  ></vue-toggles>
              </div>
            </div>
          </v-card>
        </div>

        <manage-sub-accounts v-if="selectedSubTab == 'manage-sub-accounts'"></manage-sub-accounts>
        <sub-accounts-activity-log v-if="selectedSubTab == 'sub-accounts-activity-log'"></sub-accounts-activity-log>
        <!-- <div v-if="selectedMainTab == 'sub-accounts'">
        </div> -->

        <div v-if="selectedMainTab == 'credit-logs'">
          <credit-logs></credit-logs>          
        </div>

        <div v-show="selectedMainTab == 'switch-account'">
          <div class="limited-width-box">
            <v-card
              v-if="hasOwnerAccounts"
              class="is-fullheight flex flex-column white-common-box">
              <div class="pa-5">
                <div class="select mt5">
                  <select v-model="selectedSubAccountRelation">
                    <option selected disabled>Please select one account</option>
                    <option value="">My account</option>
                    <option disabled> ---------- </option>
                    <option
                      :value="relation"
                      v-for="relation in user.ownerAccounts"
                      :key="relation.id"
                    >
                      {{ relation.agencyName }}
                      ( Permission:
                      {{
                        relation.isAllPermission
                          ? "All Projects"
                          : relation.permittedProjects.length == 1
                          ? relation.permittedProjects.length + " Project"
                          : +relation.permittedProjects.length + " Projects"
                      }}, Create Project:
                      {{ relation.canCreateProject ? "YES" : "NO" }} )
                    </option>
                  </select>
                </div>
                <v-btn rounded
                  color="red3Color"
                  class="button-with-icon text-none whiteColor--text mt-2"
                  v-if="selectedSubAccountRelation != ''"
                  @click="showLeaveMainAccountModal = true"
                >
                  Leave this primary account
              </v-btn>
              </div>
            </v-card>
          </div>
        </div>

        <div v-if="selectedMainTab == 'affiliate-setup'" class="limited-width-box">
          <affiliate-setup></affiliate-setup>          
        </div>

        <div v-if="selectedMainTab == 'manage-gpt-key'" class="limited-width-box">
          <manage-my-open-ai-key></manage-my-open-ai-key>
        </div>

        <div v-if="selectedMainTab == 'manage-pop-api-key'" style="max-width: 900px;">
          <v-card class="is-fullheight flex flex-column white-common-box">
            <div class="pa-5 flex-grow-1 flex flex-column">
              <div class="title-font mainColor--text box-title-label">
                API access key
              </div>
              <div class="notify-label">
                <i class="material-icons">error_outline</i>
                Manage your API key here. Generating a new API key will invalidate the current API key.
              </div>
              <manage-pop-keys></manage-pop-keys>
              <manage-dev-accounts></manage-dev-accounts>
            </div>
          </v-card>
        </div>
      </div>
    </div>



    <div class="modal" :class="{ 'is-active': show2FaConfirmModal }">
      <div class="modal-background" @click="onClose2FaModal()"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <div class="title-font is-size-4 mainColor--text">
              Are you sure you want to disable 2 factor authentication for your account?
            </div>
            <div class="subtitle-font mt-5">
              If you decide to enable this feature again, you'll receive an email with a code to enter into POP on your next log ins. 
            </div>
            <div class="d-flex justify-end gap10 mt-5">
              <v-btn rounded outlined
                color="mainColor"
                class="button-with-icon text-none"
                @click="onClose2FaModal()">
                No
              </v-btn>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                @click="change2FaAuthSetting">
                Yes
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="onClose2FaModal()"
        >
        <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showCouponAppliedModal }">
      <div
        class="modal-background"
        @click="showCouponAppliedModal = false"
      ></div>
      <div class="modal-content" style="max-width: 700px;">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="promo-applied-modal">
            <div class="text-center">
              <i class="material-icons">check_circle_outline</i>
            </div>
            <div class="title-label">Promo code applied!</div>
            <div class="explain">
              {{ couponCodeApplyMsg }}
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="() => (showCouponAppliedModal = false)"
        >
        <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showOldPlanCancelWarnModal }">
      <div class="modal-background" @click="showOldPlanCancelWarnModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="title-font is-size-4 mb20">
              Please confirm
            </div>
            <div class="body-font is-size-6">
              <div>Your current plan price has changed.</div>
              <div v-if="currentPrice">Your current plan subscription price is: ${{ currentPrice }}</div>
              <div v-if="newPrice">The new subscription plan price is: ${{ newPrice  }}</div>
            </div>
            <div class="control d-flex mt-5">
              <div class="flex-grow-1"></div>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon text-none"
                @click="showOldPlanCancelWarnModal = false"
              >
                <!-- <i class="fa fa-times mr5"></i> -->
                Cancel
            </v-btn>
              <v-btn rounded
                color="red3Color"
                class="button-with-icon text-none whiteColor--text"
                @click="confirmOldPriceCancel"
              >
                <!-- <i class="fa fa-trash mr5"></i> -->
                Yes, I want to cancel
          </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="() => (showOldPlanCancelWarnModal = false)"
        >
          CLOSE WINDOW
        </button>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showDeleteAccountModal }">
      <div class="modal-background" @click.stop="showDeleteAccountModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <p class="title-font is-size-4">
              Are you sure you want delete your account?
            </p>
            <div class="body-font is-size-6">
              All of your data will be deleted and you can never use this
              email address again.<br/>
              If you just wish to cancel your subscription, do so at the
              top of this page with the cancel subscription button.
            </div>
            <div class="flex mt-5">
              <div class="flex-grow-1"></div>
              <v-btn rounded
                color="red3Color"
                class="button-with-icon text-none whiteColor--text"
                @click.stop="deleteUserAccount">
                Yes, delete account
              </v-btn>
              <v-btn rounded
                color="greenColor"
                class="button-with-icon ml-2 text-none whiteColor--text"
                @click.stop="showDeleteAccountModal = false">
                Keep my account
              </v-btn>
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click="showDeleteAccountModal = false">
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showLeaveMainAccountModal }">
      <div
        class="modal-background"
        @click="showLeaveMainAccountModal = false"
      ></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <p class="is-size-4 font-weight-bold">Please confirm</p>
            <p class="subtitle">
              Are you sure you want to leave the main account? <br />
              Once you leave this account you will no longer have access to
              the main account's projects.
            </p>
            <div class="field has-addons">
              <v-btn rounded
                color="redColor"
                class="button-with-icon text-none whiteColor--text" @click="leaveMainAccount">
                Yes I want to leave
              </v-btn>
              <v-btn rounded
                color="darkGrayColor"
                class="button-with-icon text-none whiteColor--text ml-2"
                @click="showLeaveMainAccountModal = false"
              >
                No I want to stay
            </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    
</div>  
</template>

<script>

import { mixin as ServiceMixin } from "@/components/service_mixin";
import ReactivateSubscription from "@/components/ReactivateSubscription.vue";
import UploadLogo from "@/components/UploadLogo.vue";
import ChangeEmail from "@/components/ChangeEmail.vue";
import UpdatePassword from "@/components/UpdatePassword.vue";
import UniversalCredits from "@/components/UniversalCredits.vue";
import SeatManagement from "@/components/SeatManagement.vue";
import ManageSubAccounts from "@/components/ManageSubAccounts.vue"
import SubAccountsActivityLog from "@/components/SubAccountsActivityLog.vue"
import ManageCards from "@/components/ManageCards.vue"
import CreditLogs from "@/components/credit-logs/CreditLogs.vue"
import ManageMyOpenAiKey from "@/components/ManageMyOpenAiKey.vue"
import AffiliateSetup from "@/components/AffiliateSetup.vue"
import ApiAddOns from "@/components/ApiAddOns.vue"
import ApiPermissionForDev from "@/components/ApiPermissionForDev.vue"
import ManagePopKeys from '@/components/ManagePopKeys.vue';
import ManageDevAccounts from '@/components/ManageDevAccounts.vue';
import UserInvoices from '@/components/UserInvoices.vue';
import WhiteGloveAddonsTab from './WhiteGloveAddons/WhiteGloveAddonsTab.vue';

import { toLocalDateString, isFutureDate, getPureServicePkgName } from "@/utils";
import {
  DEFAULT_PREFIX,
  additionalSeatPlans,
  PURE_TRIAL_PLANS,
  oldPlans,
  PRICE_OF_EACH_VERSIONING,
  freePlans,
  whiteGlovePlans,
} from "@/components/plans_constants"


const MAIN_TABS = [
  {
    key: 'profile-setting',
    label: 'Profile settings',
    subMenu: [
      {
        key: 'email',
        label: 'Email'
      },
      {
        key: 'password',
        label: 'Password'
      },
      {
        key: 'company-details',
        label: 'Company details'
      },
      {
        key: 'white-label-reports',
        label: 'White label reports'
      },
      {
        key: 'other-features',
        label: 'Other features'
      },
      {
        key: 'delete-account',
        label: 'Delete account'
      }
    ]
  },
  {
    key: 'billing-and-payments',
    label: 'Billing and payments',
    subMenu: [
      {
        key: 'change-plan',
        label: 'Change plan'
      },
      {
        key: 'buy-credits',
        label: 'Buy credits'
      },
      // {
      //   key: 'buy-nlp-eeat',
      //   label: 'Buy NLP and EEAT credits'
      // },
      {
        key: 'credit-cards',
        label: 'Credit cards'
      },
      {
        key: 'api-add-ons',
        label: 'API add-ons'
      },
      {
        key: 'for-developers',
        label: 'For developers'
      },
      {
        key: 'cancel-subscription',
        label: 'Cancel subscription'
      },
      {
        key: 'promo-codes',
        label: 'Promo codes'
      },
      {
        key: 'invoice-history',
        label: 'Invoices'
      },
      {
        key: 'white-glove-addons',
        label: 'White Glove add-ons'
      },
    ]
  },
  {
    key: '2-factor-auth',
    label: '2 factor authentication'
  },
  {
    key: 'sub-accounts',
    label: 'Manage sub-accounts',
    subMenu: [
      {
        key: 'manage-sub-accounts',
        label: 'Manage sub-accounts',
      },
      {
        key: 'sub-accounts-activity-log',
        label: 'Sub-accounts activity log',
      },
    ]
  },
  {
    key: 'credit-logs',
    label: 'Credit log',
    info: 'In this dashboard you can see how all of your credits in POP have been spent.'
  },
  {
    key: 'switch-account',
    label: 'Switch account',
  },
  {
    key: 'affiliate-setup',
    label: 'Affiliate setup',
  },
  {
    key: 'manage-gpt-key',
    label: 'GPT-4 access key'
  },
  {
    key: 'manage-pop-api-key',
    label: 'Manage POP API keys',
  }
]

export default {
  components: {
    ReactivateSubscription,
    ChangeEmail,
    UpdatePassword,
    UploadLogo,
    UniversalCredits,
    SeatManagement,
    ManageSubAccounts,
    SubAccountsActivityLog,
    ManageCards,
    CreditLogs,
    ManageMyOpenAiKey,
    AffiliateSetup,
    ApiAddOns,
    ApiPermissionForDev,
    ManagePopKeys,
    ManageDevAccounts,
    UserInvoices,
    WhiteGloveAddonsTab,
  },
  mixins: [ServiceMixin],
  data() {
    return {
      mainTabs: MAIN_TABS,
      selectedMainTab: 'profile-setting',
      selectedSubTab: 'email',


      paypalEmail: "",
      couponCode: "",
      couponCodeApplyMsg: "",
      isCouponApplyError: false,

      cancellingSubscription: false,
      showCancelSubscriptionModal: false,
      showDeleteAccountModal: false,
      showUpdateCardModal: false,
      showLeaveMainAccountModal: false,

      companyData: {
        companyName: this.$store.state.user.companyName,
        vatId: this.$store.state.user.vatId,
        firstName: this.$store.state.user.firstName,
        lastName: this.$store.state.user.lastName,
        streetAddr: this.$store.state.user.streetAddr,
        city: this.$store.state.user.city,
        stateProvince: this.$store.state.user.stateProvince,
        postalCode: this.$store.state.user.postalCode,
        country: this.$store.state.user.country,
        addReverseCharge: this.$store.state.user.addReverseCharge
      },

      selectedSubAccountRelation: "",

      agreedOldPlanCancel: false,
      showOldPlanCancelWarnModal: false,
      currentPrice: 0,
      newPrice: 0,

      show2FaConfirmModal: false,
      showCouponAppliedModal: false,
      isEnabledWellDonePopup: false,
    };
  },
  watch: {
    selectedSubAccountRelation: function(newVal, oldVal) {
      this.$store.commit("setCurrentSubAccount", newVal);
      this.$store.commit("setCurrentSiteById", { siteId: -1 });
      this.loadSites();
    },
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        const mainTab = newQuery.mainTab
        console.log("new main tab is just", mainTab)
        if (mainTab) {
          this.actionForRouteQueryChange(mainTab)
        }
      }
    },
    userSetting: function (newVal, oldVal) {
      if (newVal) {
        if (newVal.disabledWellDonePopup) {
          this.isEnabledWellDonePopup = false;
        } else {
          this.isEnabledWellDonePopup = true;
        }
      }
    }
  },
  computed: {
    filteredTabs () {
      return this.mainTabs
        .filter(mainTab => {
          switch (mainTab.key) {
            case 'switch-account':
              if (this.hasOwnerAccounts) {
                return true;
              } else {
                return false;
              }
            case 'affiliate-setup':
              if (this.isAffiliate) {
                return true;
              } else {
                return false;
              }
            case 'manage-pop-api-key':
              if (this.hasPOPApiKey) {
                return true;
              } else {
                return false;
              }
            default:
              return true;
          }
        }).map(mainTab => ({
          ...mainTab,
          ...(mainTab.subMenu && mainTab.subMenu.length)
            ?{
              subMenu: mainTab.subMenu.filter(subTab => {
                if (subTab.key === 'white-glove-addons') {
                  return this.isWhiteGloveUser
                }
                return true
              })
            }
            :{}
        }))
    },
    currentMainTab () {
      return this.filteredTabs.find(item => item.key == this.selectedMainTab);
    },
    user() {
      return this.$store.state.user;
    },
    isUserInTrialPlan () {
      if (!this.user) {
        return false;
      }
      return PURE_TRIAL_PLANS.includes(getPureServicePkgName(this.user.accountType))
    },
    hasPOPApiKey () {
      return this.$store.getters.hasPOPApiKey
    },
    apiKeys () {
      let rlt = []
      if (this.user.addOnApiKey) {
        rlt.push({
          keyLabel: 'Add-on API key',
          keyType: 'ADD_ON_KEY',
          key: this.user.addOnApiKey
        })
      }
      if (this.user.isDevApiKeyAllowed) {
        rlt.push({
          keyLabel: 'Dev API key',
          keyType: 'DEV_PACKAGE_KEY',
          key: this.user.devApiKey,
        })
      }
      if (this.user.isPartnershipApiKeyAllowed) {
        rlt.push({
          keyLabel: 'Partership API Key',
          keyType: 'PARTNER_KEY',
          key: this.user.partnerApiKey,
        })
      }
      return rlt;
    },
    userSetting () {
      const settings = this.user ? this.user.settings : {};
      return settings;
    },
    isAffiliate () {
      if (this.user) {
        return this.user.isAffiliate
      }
      return false
    },
    isWatchdogOnlyUser () {
      return this.$store.getters.isWatchdogOnlyUser;
    },
    isAvailableToPurchaseAdditionalSeats () {
      return this.$store.getters.isAvailableToPurchaseAdditionalSeats;
    },
    isEndUser() {
      return !!this.$store.state.user.vendorId;
    },
    isTrialUser() {
      return this.$store.getters.isTrialUser;
    },
    isFreeUser () {
      if (!this.user) return false;
      if (freePlans.includes(this.user.accountType)) {
        return true;
      }
      if (this.user.isFreePeriodUser) {
        return true;
      }
      return false;
    },
    isWhiteGloveUser () {
      if (!this.user) return false;
      if (whiteGlovePlans.includes(getPureServicePkgName(this.user.accountType))) {
        return true;
      }
      return false;
    },
    appliedCoupons() {
      return this.user.purchases
        .filter(p => p.couponInfo.code)
        .map(p => p.couponInfo);
    },
    isSubscription() {
      return this.isSubscriptionService(this.$store.state.user.accountType);
    },
    lastInvoice() {
      if (this.user.invoices.length > 1) {
        return this.user.invoices[this.user.invoices.length - 1];
      }
      return {};
    },
    lastPurchase() {
      let purchase = this.user.purchases.find(
        item => item.id == this.lastInvoice.purchaseId
      );
      return purchase || {};
    },
    runBalance() {
      if (!this.user) {
        return "";
      }
      return this.getRunBalance(this.user);
    },
    lastSubscription() {
      const now = new Date();
      const subscriptions = this.user.purchases
        .filter(p => p.servicePackage === "platinum" && p.periodEnds)
        .sort((a, b) => b.periodEnds - a.periodEnds);
      if (subscriptions && subscriptions.length) {
        const cancelledInThisPeriod = subscriptions.find(
          sub => sub.cancelAtPeriodEnd && now < new Date(sub.periodEnds)
        );
        if (cancelledInThisPeriod) {
          return cancelledInThisPeriod;
        } else {
          return subscriptions[0];
        }
      } else {
        return null;
      }
    },
    activeSubscription() {
      return this.user.purchases.find(
        p => p.id == this.user.activeSubscription
      );
    },
    lastSubscriptionPurchase() {
      try {
        let found = this.user.purchases.find(p => {          
          return !freePlans.includes(p.servicePackage) && p.subscriptionId && !additionalSeatPlans.includes(getPureServicePkgName(p.servicePackage))
        })
        return found
      } catch (e) { }
      return null;
    },
  },
  methods: {

    changeMainTab (item, subMenuKey='') {
      this.selectedMainTab = item.key;
      if (item.subMenu && item.subMenu.length > 0) {
        let selectedSubMenu = null
        if (subMenuKey) {
          selectedSubMenu = item.subMenu.find(s => s.key == subMenuKey)
        }
        if(!selectedSubMenu) {
          selectedSubMenu = item.subMenu[0]
        }
        this.selectedSubTab = selectedSubMenu.key
      } else {
        this.selectedSubTab = '';
      }
    },
    changeEmail() {
      console.log("open window for change email");
      this.$refs.changeEmailRef.show();
    },
    leaveMainAccount() {
      // leaveMainAccount
      if (this.$store.state.selectedSubAccountRelation == "") {
        return;
      }
      let data = { id: this.$store.state.selectedSubAccountRelation.id };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("leaveMainAccount", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.selectedSubAccountRelation = "";
            this.$store.commit("setCurrentSiteById", { siteId: -1 });
            this.$store.commit("setUser", response.data.user);
          } else {
          }
        })
        .finally(err => {
          this.$store.commit("hideLoading");
          this.showLeaveMainAccountModal = false;
        });
    },
    updatePaypalEmail() {
      if (this.paypalEmail) {
        this.$store
          .dispatch("updatePaypalEmail", { email: this.paypalEmail })
          .then(response => {
            if (response.data.status === "SUCCESS") {
              this.paypalEmail = response.data.user.paypalEmail;
              this.$store.commit("setUser", response.data.user);
            }
          });
      }
    },
    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr);
    },
    isFutureDate(dateStr) {
      return isFutureDate(dateStr);
    },
    isInvoicePaid(invoice) {
      let status = invoice.status.toLowerCase();
      return ["paid", "succeeded"].indexOf(status) > -1;
    },
    deleteUserAccount() {
      try {
        this.$store
          .dispatch("deleteUserAccount")
          .then(() => {
            this.$store.commit("clearStore");
            this.$router.push("/login");
          })
          .catch(err => this.$router.push("/login"));
      } catch (e) {
        console.log("Error deleting account");
        this.$router.push("/login");
      }
    },
    submitCoupon() {
      if (this.couponCode) {
        this.$store.commit("showLoading");
        this.couponCodeApplyMsg = '';
        this.isCouponApplyError = false;
        return this.$store
          .dispatch("addCoupon", { couponCode: this.couponCode })
          .then(response => {
            const { data } = response;
            if (data.status === "SUCCESS") {
              this.$store.commit("setUser", response.data.user);
              this.couponCodeApplyMsg = response.data.msg;
              this.couponCode = "";
              if (data.approvalUrl) {
                window.open(data.approvalUrl, "_self");
              } else {
                this.showCouponAppliedModal = true;
              }
            } else {
              this.isCouponApplyError = true;
              this.couponCodeApplyMsg = data.msg;
              return 1;
            }
          })
          .finally(() => this.$store.commit("hideLoading"));
      }
    },
    confirmOldPriceCancel () {
      this.agreedOldPlanCancel = true;
      this.showOldPlanCancelWarnModal = false;
      this.cancelSubscriptionButtonAction();
    },
    cancelSubscriptionButtonAction () {
      if (oldPlans.includes(this.user.accountType) && !this.agreedOldPlanCancel) {
        const purePackageName = getPureServicePkgName(this.user.accountType);
        // const oldPrice = PRICE_OF_EACH_VERSIONING[this.user.accountType];
        this.currentPrice = this.activeSubscription.credits
        this.newPrice = PRICE_OF_EACH_VERSIONING[`${DEFAULT_PREFIX}${purePackageName}`]
        this.showOldPlanCancelWarnModal = true;
        console.log(this.user.accountType);
        return;
      }
      // showCancelSubscriptionModal = true;
      console.log('check which route needs to be called.', this.isWatchdogOnlyUser)
      if (this.isWatchdogOnlyUser) {
        this.$router.push('/tell-us-your-experience');
      } else {
        this.$router.push('/cancel-subscription-confirm');
      }
    },
    cancelSubscription() {
      this.cancellingSubscription = true;
      this.$store.dispatch("cancelSubscription").then(response => {
        this.showCancelSubscriptionModal = false;
        this.cancellingSubscription = false;
        if (response.data && response.data.status === "SUCCESS") {
          this.$store.commit("setUser", response.data.user);
          alert("Your subscription is successfully canceled!");
        } else {
          alert(
            "Unable to cancel at this time. If you have already cancelled your PayPal payment,  your POP subscription has been cancelled.\nYour dashboard will update in the next 24 hours. If you have not cancelled in PayPal and are still receiving this error message please contact support."
          );
        }
      });
      // .then(() => this.$store.dispatch('loadData'))
      // .then(() => this.$store.dispatch('loadInvoiceData'))
      // .then((response) => this.$store.commit('setInvoices', { invoices: response.data }))
      // .then(() => {
      //   this.showCancelSubscriptionModal = false
      //   alert('Your subscription is successfully canceled!');
      // })
      // .catch((err) => {
      //   console.log('error', err)
      // })
    },
    saveWellDonePopupSetting () {
      let settings = Object.assign({}, this.userSetting)
      let isEnabledWellDonePopup = !this.isEnabledWellDonePopup
      settings.disabledWellDonePopup = isEnabledWellDonePopup ? false : true
      
      this.$store.commit('showLoading')
      this.$store.dispatch('updateUserSettings', settings)
        .then((response) => {
          this.$store.commit('setUserSettings', response.data)
        })
        .finally(() => {
          this.$store.commit('hideLoading')          
        })
    },

    submitCompanyDetail() {
      // if (!window.confirm('Do you want to save this information?')) { return }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("saveCompanyDetails", {
          ...this.companyData,
          addReverseCharge: this.companyData.addReverseCharge ? 1 : 0
        })
        .then(res => {
          if (res.data && res.data.status == "SUCCESS") {
            this.$store.commit("setUserData", { userData: res.data.user });
            console.log(res);
            this.$store.commit("hideLoading");
            window.alert("Successfully saved the company details");
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    loadSites() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("loadSites")
        .then(response => {
          if (response.data && response.data.status === "SUCCESS") {
            this.$store.commit("setSites", response.data.sites);
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    generateCancellationCode() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("generateCancellationCode")
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$store.commit("setUser", response.data.user);
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    toggleChange2FaAuthConfirmModalAction () {
      if (this.show2FaConfirmModal) {
        this.show2FaConfirmModal = false;
        this.$refs.toggleFor2Fa.checked = this.user.enabled2FaAuth;
      } else {
        this.show2FaConfirmModal = true;
      }
    },
    onClose2FaModal() {
        this.show2FaConfirmModal = false
        this.$refs.toggleFor2Fa.checked = this.user.enabled2FaAuth;
    },
    change2FaAuthSetting() {
      let data = {
        enabled2FaAuth: this.user.enabled2FaAuth ? 0 : 1
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("change2FaAuthSetting", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$store.commit("setUser", response.data.user);
            this.onClose2FaModal()
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    simplifyProductName(name) {
      if (!name) return '';
      let result = name;
      return result.replace('PageOptimizer ', '').replace('(', '<br/>(')
    },
    actionForRouteQueryChange (mainTab) {
      let found = this.filteredTabs.find(item => item.key == mainTab)
      if (found) {
        let subTabKey = this.$route.query.subTab
        this.changeMainTab(found, subTabKey)
      }
    }
  },
  beforeMount() {
    this.selectedSubAccountRelation = this.$store.state.selectedSubAccountRelation;
    this.$store.commit("showLoading");
    return this.$store.dispatch("loadUser").then(response => {
      if (response.data && response.data.status === "SUCCESS") {
        this.$store.commit("setUser", response.data.user);
        this.paypalEmail = response.data.user.paypalEmail;
        // return this.$store.dispatch('loadInvoiceData')
        //   .then((response) => this.$store.commit('setInvoices', { invoices: response.data }))
      }
      this.$store.commit("hideLoading");
    });
  },
  mounted () {
    this.$store.commit('setHideSideBar', true)

    let mainTab = this.$route.query.mainTab
    if (mainTab) {
      this.actionForRouteQueryChange(mainTab)
    }
  }
};
</script>

<style scoped lang="scss">

div {
  color: var(--v-mainColor-base);
}

$ACTIVE-COLOR: var(--v-profileAnchorColor-base);

.user-profile {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  .sidebar {
    width: 290px;
    flex-shrink: 0;
    background: var(--v-cardBg-base) !important;
    padding: 20px 10px 5px 10px;
    height: 100%;
    border-right: 1.5px solid var(--v-grayColor-lighten1);
    .title-label {
      font-size: 18px;
      font-weight: bold;
      padding-left: 15px;
      padding-right: 15px;
    }

    ul {
      margin-top: 20px;
      padding-left: 0;
      li {
        padding: 10px 15px 10px 15px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;

        &.active {
          background: #FFF7F0;
          color: $ACTIVE-COLOR;
          font-weight: bold;
        }
      }
    }
  }
  .mobile-menu {
    display: none;
    margin-bottom: 10px;
  }

  .content-area {
    flex-grow: 1;
    padding: 70px 30px 0 30px;
    overflow: auto;

    .title-label {
      font-size: 25px;
      font-weight: bold;
    }

    .submenus {
      display: flex;
      flex-wrap: wrap;
      padding-top: 15px;
      padding-bottom: 15px;
      column-gap: 20px;
      row-gap: 10px;

      & > div {
        font-size: 16px;
        padding: 10px 5px 10px 5px;
        cursor: pointer;
        /* border: 1px solid red; */
        &.active {
          font-weight: 800;
          color: $ACTIVE-COLOR;
          border-bottom: 2px solid $ACTIVE-COLOR;
        }
      }
    }
  }

  @media screen and (max-width: 780px) {
    .sidebar {
      display: none;
    }
    .mobile-menu {
      display: block;
    }
    .content-area {
      padding-top: 20px;            
    }
  }
}



.cards-in-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  margin: 0px 0 25px 0px;
}

.your-current-plan {
  font-size: 14px;
  color: var(--v-mainColor-base);
}
.account-type {
  font-size: 20px;
  font-weight: 800;
  line-height: 25px;
  color: var(--v-mainColor-base);
}

.is-full-width {
  width: 100%;
}

.service-package {
  min-height: 225px;
  background-color: hsl(171, 73%, 58%) !important;
}

.is-service-benefit {
  font-weight: bold;
}

.profile-heading {
  width: 50%;
}
.info-text {
  font-style: italic;
}
.field-label {
  flex-grow: 2;
}

.cancellation-explain-label {
  line-height: 18px;
}

.sure-delete-label {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 35px;
}

.download-icon {
  /* width: 18px;
  height: auto; */
  font-size: 1.5rem;
  color: var(--v-mainColor-base);
  cursor: pointer;
}

.custom-table {
  width: 100%;
  thead {
    /* tr {

    } */
    th {
      color: var(--v-darkGrayColor-base);
      font-weight: normal;
      padding: 10px 10px 10px 0px;
      font-size: 0.78rem;
    }
  }
  tbody {
    tr {
      border-top: 1px solid var(--v-gray12Color-base);
    }
    td, a {
      font-size: 0.78rem;
      color: var(--v-mainColor-base);
      padding: 10px 5px 10px 0px;
    }
  }
}

.current-api-key {
  font-size: 1.2rem;
  span {
    font-weight: 700;
    font-size: 1.3rem;
  }
}

.v-input.v-input--hide-details {
  margin-bottom: 24px !important;
}


.v-application.theme--dark {
  .sidebar {
    ul {
      li {
        &.active {
          background: rgba(#FF752E, 0.1);
        }
      }
    }
  }
}
</style>
