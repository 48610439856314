<template>
<div>
  <div>
    <div class="my-2 mx-3">
      <div class="report-one-page-name gap10 mb-5" v-if="todoTabType == 'simple_list'">
        <span>Custom strategy</span>
        <!-- <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
          @click="() => {
            $store.commit('setCurrentVideoTutorialToDisplay', {
              title: 'How to use Google Entities NLP',
              videoLink: 'https://www.youtube.com/embed/zrX_SemcuIw?si=acw6lIHzFxuLB0jI',
              description: 'Learn how to use POP Google Entities report for your projects.',
              time: '4m 39s'
            });
          }">
          videocam
        </span> -->
      </div>
    </div>

    <div v-if="todoTabType == 'simple_list'" class="mb-3">
      <div class="columns is-desktop">
        <div class="column">
          <v-card class="is-fullheight d-flex flex-column white-common-box">
            <div class="pa-5">
              <div class="d-flex flex-wrap align-center rowgap10">
                <div v-if="!pageRun.pageNotBuilt">
                  <div v-if="calculatingScore">
                    <trinity-rings-spinner
                      :animation-duration="1500"
                      :size="70"
                      color="#FF9B2D"
                    />
                  </div>
                  <radial-progress-bar
                    v-else
                    :diameter="70"
                    :completed-steps="pageScore"
                    :total-steps="100"
                    :strokeWidth="8"
                    :startColor="pageScoreTintColor(pageScore)"
                    :stopColor="pageScoreTintColor(pageScore)"
                    :innerStrokeColor="'#eeeeee'"
                    strokeLinecap="square"
                    >
                    <div class="score-number" :style="{ 'color': 'blue' }" v-if="strategy=='custom'">N/A</div>
                    <div class="score-number" :style="{ 'color': pageScoreTintColor(pageScore) }" v-else>{{ pageScore | round(1) }}</div>
                  </radial-progress-bar>
                </div>
                <div v-else> <!-- page not built -->
                  <div class="score-chart-box mr5 mb10">
                    <radial-progress-bar
                      :diameter="100"
                      :completed-steps="0"
                      :total-steps="100"
                      :strokeWidth="7"
                      :startColor="pageScoreTintColor(0)"
                      :stopColor="pageScoreTintColor(0)"
                      :innerStrokeColor="'#eeeeee'"
                      strokeLinecap="square"
                      >
                      <div class="score-number" :style="{ 'color': 'blue' }">N/A</div>
                    </radial-progress-bar>
                  </div>
                </div>
                <div class="flex-grow-1 pl-3">
                  <div class="d-flex align-center">
                    <div v-if="!pageRun.pageNotBuilt" class="d-flex align-center">
                      <div class="score-label mr-1">
                        <template v-if="dashboardType == 'contentBrief'">Content Brief</template>&nbsp;Optimization Score
                      </div>
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i class="material-icons info-icon-size mainColor--text" v-bind="attrs" v-on="on">info</i>
                        </template>
                        <div class="white-bg-tooltip small-tooltip" v-html="scoreExplainText">
                        </div>
                      </v-tooltip>
                    </div>
                    <div v-else
                      class="labels">
                      <div class="score-label">Optimization Score</div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="control">
                      <label class="radio">
                        <input type="radio" name="dashboardType" :checked="dashboardType == 'contentBrief'" @click.stop="dashboardType = 'contentBrief'" :disabled="isDemo">
                        Content Brief
                      </label>
                      <label class="radio" :disabled="!isCurrentPageRunAvailableForStrategy">
                        <input type="radio" name="dashboardType" :checked="dashboardType == 'signals'" @click.stop="dashboardType = 'signals'" :disabled="!isCurrentPageRunAvailableForStrategy || isDemo" >
                        Signals
                      </label>
                    </div>
                    <div v-if="!hasContentBriefData" class="is-size-6">
                      <b>Content Brief Score is unavailable.</b><br/>
                      <i>Please re-run and select "Make Changes" and re-run all the steps</i>
                    </div>
                  </div>
                </div>
                <div  v-if="todoTabType=='simple_list'" class="flex-grow-1 justify-end d-flex">
                  <div class="download-quick-list-btn" @click.stop="isDemo ? null : downloadQuicklist()">
                    <img src="/static/download-icon-yellow.png" />
                    <span>Download quicklist</span>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <v-tooltip bottom color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center mr-5" v-bind="attrs" v-on="on">
                      <img src="/static/why-advanced-strategy-icon.png" class="mr-5" style="width: 25px; height: auto;" />
                      <div class="mainColor--text">
                        Why are the advanced strategies optimization scores different from the Content Brief optimization score?
                      </div>
                    </div>
                  </template>
                  <div class="white-bg-tooltip">
                    <ul style="list-style-type: circle; margin-left: 20px;">
                      <li>Advanced Strategies are for those who want to make their own nuanced Strategy and Approach choices.</li>
                      <li>Advanced Strategies take a signal by signal approach.</li>
                      <li>The Advanced Strategies Optimization Score is relative to your competitors according your your chosen Strategy.</li>
                      <li>It is not always possible or desirable to get to 100% as your competitors may have made mistakes.</li>
                      <li>Most people see rank improvement with an Optimization Score in the 60-70% range.</li>
                      <li>An Optimization Score of 80-90% is usually as high as most choose to go.</li>
                      <li>If you wish to switch from the Content Brief to Advanced Strategies, Select "Signals," choose your Strategy and Approach and then click "Save Strategy."</li>
                      <li>You can switch back to the Content Brief at any time.</li>
                    </ul>
                  </div>
                </v-tooltip>
              </div>
              <div class="mainColor--text mt-5">
                {{ strategySuggestionText }}
              </div>
              <div class="mt-5">
                <div class="word-count-section">
                  <div class="by-text">
                    <div class="current-label">Current word count</div>
                    <div class="explain mt-2">{{ wordCountRecommendation }}</div>
                  </div>
                  <div class="by-progress">
                    <div class="d-flex justify-space-between align-center mb-3">
                      <div class="current-number">{{ currentWordCount }}</div>
                      <div class="font-weight-bold mainColor--text">Target: {{ goalWordCountByStrategy }}</div>
                    </div>
                    <v-progress-linear
                      background-color="grayColor"
                      :color="pageScoreTintColor(currentWordCount * 100 / goalWordCountByStrategy)"
                      :value="currentWordCount * 100 / goalWordCountByStrategy">
                    </v-progress-linear>
                  </div>
                </div>
              </div>
              <div class="mt-10">
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn rounded block
                    v-bind="attrs" v-on="on"
                    color="profileAnchorColor"
                    class="text-none"
                    @click.stop="updateRecommendations"
                    :disabled="!isActiveSaveRecommendationButton || $store.getters.isDemoUser">
                    <span class="whiteColor--text">Save strategy</span>
                  </v-btn>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    Click here to use these recommendations and optimization score
                  </div>
                </v-tooltip>
                
              </div>
            </div>
          </v-card>
        </div>
        <div class="column is-two-thirds" v-if="dashboardType != 'signals'">
          <v-card class="is-fullheight d-flex flex-column white-common-box">
            <div class="pa-5">
              <div class="rerun-suggest-overlay">
                <div class="text-center" v-if="!isCurrentPageRunAvailableForStrategy">
                  Please re-run to use this feature.
                  <div class="is-italic is-size-6">( Select "Make Changes" and re-run each step. )</div>
                </div>
                <div v-else class="strategy-greyout-overlay">
                  <div class="has-text-centered is-size-4">Select Signals and this area is enabled.</div>
                  <ul class="mt-5">
                    <li>You can select your own Approach and Strategy with this feature.</li>
                    <li>You can click through the different Strategies and Approaches without losing any data anywhere else.</li>
                    <li>You will see a change in Optimization Score and Recommendations.</li>
                    <li>After you have decided on your Strategy and Approach click "Save Recommendations" to lock in those recommendations and Optimization Score.</li>
                    <li>You can always return to the Content Brief Optimization Score and Recommendations.</li>
                  </ul>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <template v-else>
          <div class="column">
            <v-card class="is-fullheight d-flex flex-column white-common-box">
              <div class="pa-5">
                <div class="score-label">Strategy</div>
                <div class="mt-5 strategy-choices">
                  <template v-for="(item, idx) in availableStrategyOptions">
                    <div :key="idx">
                      <div>
                        <v-tooltip top color="transparent">
                          <template v-slot:activator="{ on, attrs }">
                            <label v-bind="attrs" v-on="on" :disabled="isDemo || (item.disableCheck && item.disabled)"
                              class="radio" :class="{ 'font-weight-bold': strategy == item.value }">
                              <input type="radio" name="strategy" v-model="strategy" :value="item.value" :disabled="isDemo || (item.disableCheck && item.disabled)">
                              {{ item.label }}
                            </label>
                          </template>
                          <div class="white-bg-tooltip small-tooltip">
                            {{ item.tooltip }}
                          </div>
                        </v-tooltip>
                      </div>
                      <div v-if="item.value == 'adjusted' && strategy == 'adjusted'" class="mt-3">
                        <v-text-field outlined dense hide-details="auto"
                          type="number"
                          color="greenColor"
                          label="Please input your adjusted word count here if you want to use."
                          v-model.number="adjustedWordCountUserDefined"
                          ></v-text-field>
                      </div>
                      <div class="strategy-explainer-text my-3" v-if="strategy == item.value">{{ strategyExplainerText }}</div>
                    </div>
                  </template>
                </div>
              </div>
            </v-card>
          </div>
          <div class="column">
            <v-card class="is-fullheight d-flex flex-column white-common-box">
              <div class="pa-5">
                <div class="score-label">Approach</div>
                <div class="mt-5 strategy-choices">
                  <template v-for="(item, idx) in availableApproachOptions">
                    <div :key="idx">
                      <div>
                        <v-tooltip top color="transparent">
                          <template v-slot:activator="{ on, attrs }">
                            <label v-bind="attrs" v-on="on" :disabled="isDemo || (item.disableCheck && item.disabled)"
                              class="radio" :class="{ 'font-weight-bold': approach == item.value }">
                              <input type="radio" name="approach" v-model="approach" :value="item.value" :disabled="isDemo || (item.disableCheck && item.disabled)">
                              {{ item.label }}
                            </label>
                          </template>
                          <div class="white-bg-tooltip small-tooltip">
                            {{ item.tooltip }}
                          </div>
                        </v-tooltip>
                      </div>
                      <div class="strategy-explainer-text my-3" v-if="approach == item.value">{{ approachExplainerText }}</div>
                    </div>
                  </template>
                </div>
              </div>
            </v-card>
          </div>
        </template>
      </div>    
    </div>

    <div v-if="todoTabType == 'simple_list'"
      class="checklist-area is-centered mt-10checklist-area is-centered mt-10">
      <!-- <div class="disable-checklist-overlay" v-if="dashboardType == 'contentBrief'"></div> -->
      <div v-show="signalType === 'keyword' || signalType === 'all'">
        <div v-if="signalType === 'all'" class="is-size-6 has-text-weight-bold">Exact Keyword</div>
        <div style="min-width: 500px;" class="hscroll-container">
          <table class="signal-table">
            <thead>
              <tr :class="{'todo-list-table': todoTabType == 'simple_list'}">
                <th class="fixed-width">Signal</th>
                <th>Recommendations</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in signalData" :key="`${type}-signal-row-${i}`">
                <td class="signal-td">
                  <i class="material-icons info-icon-size"
                    v-tooltip.right="{ content: signalExplainData[row.signal], classes: 'custom-tooltip' }" >info</i>
                  * {{ row.signal }}
                </td>
                <td :class="{ recommendations: true }">
                  {{ row.comment }}
                  <span :class="getMarkTypeStyle(row.signal, row.comment)"></span>
                </td>
              </tr>
              <tr v-if="signalData.length == 0">
                <td colspan="1000" class="has-text-centered">There are no current tasks for your Exact Keyword</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-show="signalType === 'variations' || signalType === 'all'">
        <div v-if="signalType === 'all'" class="is-size-6 has-text-weight-bold mt-10">
          Variations
          <a style="margin-left: 10px" class="show-hide-button ml15 mr5" @click.stop="isShowVariationDropdown=!isShowVariationDropdown"
            v-if="todoTabType=='simple_list'">
            {{ isShowVariationDropdown ? 'Hide' : 'Show' }}
          </a>
        </div>
        <div v-if="todoTabType == 'simple_list'">
          <div class="tags mt10" v-if="isShowVariationDropdown && hasLsaForVariations">
            <span v-for="(item, idx) in sortedLsaVariations" :key="idx" class="variation-tag">
              {{ item.phrase }} <span v-if="isShowVariationWeight">&nbsp;( {{ (item.weight*100).toFixed(2) + '%' }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
            </span>
            <v-btn small outlined color="mainColor"
              class="button-with-icon text-none"
              @click="isShowVariationWeight=!isShowVariationWeight">
              {{ isShowVariationWeight ? 'Hide weights' : 'Show weights' }}
            </v-btn>
          </div>
          <div class="tags mt10" v-if="isShowVariationDropdown && !hasLsaForVariations">
            <span v-for="v in variations" :key="v" class="tag is-light">{{ v }}</span>
          </div>
        </div>
        <table class="signal-table">
          <thead>
            <tr :class="{'todo-list-table': todoTabType == 'simple_list'}">
              <th class="fixed-width">Signal</th>
              <th>Recommendations</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in variationData" :key="`${type}-signal-row-${i}`">
              <td class="signal-td">
                <i class="material-icons info-icon-size"
                  v-tooltip.right="{ content: signalExplainData[row.signal], classes: 'custom-tooltip' }" >info</i>
                * {{ row.signal }}
              </td>
              <td :class="{ recommendations: true }">
                {{ row.comment }}
                <span :class="getMarkTypeStyle(row.signal, row.comment)"></span>
              </td>
            </tr>
            <tr v-if="variationData.length == 0">
              <td colspan="1000" class="has-text-centered">There are no current tasks for your Variations</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-show="signalType === 'lsi_terms' || signalType === 'all'">
        <div v-if="signalType === 'all'" class="is-size-6 has-text-weight-bold mt-10">
          LSI
          <a class="show-hide-button ml15 mr5" @click.stop="isShowLsiDropdown = !isShowLsiDropdown" v-if="todoTabType == 'simple_list'">
            {{ isShowLsiDropdown ? 'Hide' : 'Show' }}
          </a>
        </div>
        <div v-if="todoTabType == 'simple_list'">
          <div class="tags my-5" v-if="isShowLsiDropdown">
            <v-tooltip top color="transparent" v-for="(item, idx) in sortedLsaPhrases" :key="idx">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"
                  :class="{ 'nlp-tag': item.isNLPTerm || item.isGoogleNLPTerm, 'lsi-tag': !item.isNLPTerm && !item.isGoogleNLPTerm }">
                  {{ item.phrase }} <span v-if="isShowLsiWeight">&nbsp;( {{ (item.weight*100).toFixed(2) + '%' }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                </span>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                {{ item.isNLPTerm ? 'NLP' : ( item.isGoogleNLPTerm ? 'Google NLP' : 'LSI' ) }}
              </div>
            </v-tooltip>
            
            <v-btn small outlined color="mainColor"
              class="button-with-icon text-none"
              @click="isShowLsiWeight=!isShowLsiWeight">
              {{ isShowLsiWeight ? 'Hide weights' : 'Show weights' }}
            </v-btn>
          </div>
        </div>
        <table class="signal-table">
          <thead>
            <tr :class="{'todo-list-table': todoTabType == 'simple_list'}">
              <th class="fixed-width">Signal</th>
              <th>Recommendations</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in lsiVariationData" :key="`${type}-signal-row-${i}`">
              <td class="signal-td">
                <i class="material-icons info-icon-size"
                  v-tooltip.right="{ content: signalExplainData[row.signal], classes: 'custom-tooltip' }" >info</i>
                * {{ row.signal }}
              </td>
              <td :class="{ recommendations: true }">
                {{ row.comment }}
                <span :class="getMarkTypeStyle(row.signal, row.comment)"></span>
              </td>
            </tr>
            <tr v-if="lsiVariationData.length == 0">
              <td colspan="1000" class="has-text-centered">There are no current tasks for your LSI</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-show="signalType == 'page_structure' || signalType === 'all'">
        <div v-if="signalType === 'all'" class="is-size-6 has-text-weight-bold mt-10">Page Structure</div>
        <tag-totals-checklist
          :todoTabType="todoTabType"
          :tagCounts="tagCountsForRender"
          :pageNotBuilt="pageNotBuilt"
          :pageId="pageId"
          />
      </div>

      <div v-if="signalType == 'schema_type' || signalType === 'all'">
        <div v-if="signalType === 'all'" class="is-size-6 has-text-weight-bold mt-10">Schema</div>
        <SchemaTypeChecklist
          :todoTabType="todoTabType"
          :pageId="pageId"
          :mainPageSchemaTypes="mainPageSchemaTypes"
          :competitors="competitors"
          :checkLists="checkLists"
        ></SchemaTypeChecklist>
      </div>

    </div>

    <div class="modal" :class="{'is-active': showNoteModal}">
      <div class="modal-background" @click="hideNoteModalAction"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="d-flex">
            <p class="title" style="flex-grow: 1;">Notes ( {{ currentSignalName }} )</p>
            <i class="fa fa-close" @click="hideNoteModalAction" style="cursor: pointer; font-size: 1.5rem;"></i>
          </div>
          <div>
            <textarea class="textarea" v-model="newNote" placeholder="Write Note here.." :rows="2">
            </textarea>
            <div v-if="isNoteEditMode" class="mt5">
              <button class="button is-primary" @click="updateNote" :disabled="newNote.trim() == ''">Update Note</button>
              <button class="button is-light" @click="cancelEditNoteMode">Cancel</button> 
            </div>
            <div v-else class="mt5">
              <button class="button is-primary mt5" @click="addNote" :disabled="selectedChecklistId == 0 || newNote.trim() == ''">Add Note</button>
            </div>
          </div>
          <div class="mt5 box" style="max-height: 400px; overflow: auto;">
              <article class="message" :class="{'is-info': row.isAutoNote != 1, 'is-warning': row.isAutoNote == 1}" v-for="row in currentChecklistNotes" :key="row.id">
                <div class="message-header">
                  <p>
                    {{ row.createdAt }}
                    <span v-if="row.isEdited" class="is-italic is-size-7"> ( edited: {{ row.editedAt }} )</span>
                  </p>
                  <div>
                    <button class="button is-small" aria-label="delete" @click="setEditNoteMode(row)" v-if="!row.isAutoNote">Edit</button>
                    <button class="button is-small is-dark" aria-label="delete" @click="deleteNote(row.id)">Delete</button>
                  </div>
                </div>
                <div class="message-body">
                  <p v-html="row.note.replace(/\n/g, '<br/>')"></p>
                </div>
              </article>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'is-active': dispInOldReportModal}">
      <div class="modal-background" @click="dispInOldReportModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="title-font is-size-4 mb20">
              You will need to make changes to your Tasks in the most current PageRun Report.
            </div>
            <div class="control d-flex mt-5">
              <div class="flex-grow-1">
              </div>
              <button class="button ready-button" @click="dispInOldReportModal=false">
                <i class="fa fa-times mr5"></i>
                Close
              </button>
              <!-- <button class="button ready-button is-danger" @click="dispInOldReportModal = false">
                <i class="fa fa-trash mr5"></i>
                Delete
              </button> -->
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click="dispInOldReportModal = false"><i class="material-icons">close</i></button>
      </div>
    </div>

  </div>

  <div v-show="todoTabType === 'content_brief'">
    <content-brief
      :selectedMainTab="selectedMainTab"
      :pageRun="pageRun"
      :createdAt="pageRun.createdAt"
      :url="pageRun.url ? pageRun.url : page.url"
      :keyword="keyword"
      :secondaryKeywords="pageRun.secondaryKeywords"
      :lsaPhrases="pageRun.lsaPhrases"
      :pageRunId="pageRun.id"
      :pageNotBuilt="pageNotBuilt"
      :page="page"
      :ignoreSpaces="pageRun.ignoreSpaces"
      :willUseSecretKey="false"
      :secretKey="pageRun.secretKey"
      :runStrategies="pageRun.runStrategies"
      :contentBrief="null"
      :tagTotalData="tagTotalData"
      :wordCountData="[currentWordCount, contentBriefGoalWordCount, getWordCountRecommendation(currentWordCount, contentBriefGoalWordCount)]"
      :sectionCount="pageRun.subHeadingsCount"
      :gCompetitors="gCompetitors"
      :relatedSearches="relatedSearches"
      :relatedQuestions="relatedQuestions"
      :contentBriefFile="pageRun.contentBriefDataFile"
      :excludedTermsByUser="pageRun.excludedTermsByUser"
      :isFromExtension="false"
      :showAsMobileView="false"
      :defaultTabOfContentBrief="defaultTabOfContentBrief"
      @actionForUpdateParent="() => $emit('actionForUpdateParent')"
      />
  </div>

  
</div>

</template>

<script>
import { mixin } from '@/components/mixin'
import { mixin as ContentBriefMixin } from '@/components/content_brief_mixin'
import TagTotalsChecklist from '@/components/PageRun/TagTotalsChecklist.vue'
import SchemaTypeChecklist from '@/components/PageRun/SchemaTypeChecklist.vue'
import ContentBrief from '@/components/PageRun/ContentBrief/ContentBrief.vue'
import RadialProgressBar from 'vue-radial-progress'
import { TrinityRingsSpinner } from 'epic-spinners'

import explainData from './ExplainData'
import moment from 'moment'
let WEAK_SIGNAL_LABELS = ['HTML Head & Body Tags', 'H5', 'H6', 'Meta Keywords', 'Meta Description', 'Open Graph Title', 'Open Graph Description', 'Twitter Card Title', 'Twitter Card Description', 'Image File Names', 'Figure Caption', 'Source Code URLs', 'Microdata (schema format)', 'RDF (schema format)', 'JSON LD (schema format)']

export default {
  mixins: [mixin, ContentBriefMixin],
  components: {
    TagTotalsChecklist, SchemaTypeChecklist, ContentBrief,
    RadialProgressBar, TrinityRingsSpinner
  },
  props: [
    'selectedMainTab',
    'type', 'signals', 'adjSignals', 'lsiSignals', 'lsiAdjSignals', 'keyword', 'variations',
    'lsaVariations', 'nlpCommons', 'targetURL', 'pageNotBuilt', 'baseURL', 'pageId', 'pageRunId',
    'mainPageSchemaTypes', 'competitors', 'checkLists', 'lsaPhrases', 'tagCounts', 'relatedSearches', 'relatedQuestions', 'gCompetitors',
    'defaultTabOfContentBrief',
  ],
  watch: {
    'checkLists': function(newVal, oldVal) {
      this.generateQuicklist()
    },
    'todoTabType': function(newVal, oldVal) {
      this.signalType = 'all'
      this.generateQuicklist()
      switch(newVal) {
        case 'simple_list':
          this.isShowVariationDropdown = false // variations
          this.isShowLsiDropdown = false // lsi
          break;
      }
    },
    'strategy': function (newVal, oldVal) {
      if (newVal == oldVal) return
      if (!this.isCurrentPageRunAvailableForStrategy) {
        return
      }

      this.isChangedStrategySetting = true
      switch(newVal) {
        case 'target':
        case 'focus':
        case 'max':
          break;
        case 'adjusted':
          if (this.pageRun.adjustedWordCountUserDefined) {
            this.adjustedWordCountUserDefined = this.pageRun.adjustedWordCountUserDefined
          } else {
            this.adjustedWordCountUserDefined = this.currentWordCount
          }
          break;
      }

      this.updatePageScoreStrategy()
    },
    'approach': function (newVal, oldVal) {
      if (newVal == oldVal) return
      if (!this.isCurrentPageRunAvailableForStrategy) {
        return
      }
      this.isChangedStrategySetting = true
      this.updatePageScoreStrategy()
    },
    'dashboardType': function (newVal, oldVal) {
      if (newVal == oldVal) return

      this.isChangedDashboardType = true
      switch (newVal) {
        case 'contentBrief':
          break;
        case 'signals':
          break;
      
        default:
          break;
      }
    },
    adjustedWordCountUserDefined: function (newVal, oldVal) {
      this.isChangedDashboardType = true;
    },
    pageRun: function (newVal, oldVal) {
      this.runStrategies = newVal.runStrategies
      this.generateQuicklist()
    }
  },
  data () {
    return {
      todoTabType: 'content_brief', // simple_list/content_brief

      signalType: 'all',
      isShowVariationDropdown: false,
      isShowVariationWeight: false,
      message: 'Hide',
      showKwFocus: false,
      showKwMax: false,
      showKwAll: false,
      showVarFocus: false,
      showVarMax: false,
      showVarAll: false,
      variationColsExpanded: false,
      variationData: [],
      signalData: [],
      lsiVariationData: [],
      signalExplainData: {},

      newNote: '',
      selectedChecklistId: '',
      isNoteEditMode: false,
      selectedNoteId: '',
      showNoteModal: false,

      allNotesFilterText: '',
      changesOverTimeDisplayDays: 7,
      isShowLsiDropdown: false,
      isShowLsiWeight: false,

      typeOfArchiveAll: -1,

      showChecklistHistoryModal: false,

      dashboardType: 'contentBrief', // contentBrief / signals
      strategy: 'target', // target, adjusted, focus, max, custom
      approach: 'regular',  // regular, conservative, aggressive, hyper_aggressive
      runStrategies: [],
      calculatingScore: false,

      isChangedStrategySetting: false,
      isChangedDashboardType: false,

      dispInOldReportModal: false,

      adjustedWordCountUserDefined: 0,
    }
  },
  computed: {
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    scoreExplainText () {
      if (this.dashboardType == 'contentBrief') {
        return `This score is based on the Content Brief.  Improve this score by getting each section of the Content Brief into their target ranges. The goal is to get to 100%.`
      } else {
        let rlt = ``
        if (this.strategy!='custom') {
          rlt = `This score is how optimized your page is relative to your selected strategy and approach.  This score tallies the recommendations `
        }
        rlt += `<b>${ this.pageScoreExplainByStrategy }</b>`
        return rlt
      }
      return ''
    },
    isActiveSaveRecommendationButton () {
      if (this.dashboardType == 'contentBrief' && !this.hasContentBriefData) {
        return false
      }
      if (this.isChangedDashboardType) {
        console.log('hey dashboard')
        return true
      }
      if (this.isCurrentPageRunAvailableForStrategy && this.isChangedStrategySetting) {
        console.log('hey strategy')
        return true
      }
      return false
    },
    strategyWordCountTarget () {
      if (
        (this.strategy == 'adjusted' && this.approach == 'regular') ||
        (this.strategy == 'custom')) {
        return this.currentWordCount
      }

      let found = this.runStrategies.find(item => {
        return item.approach == this.approach && item.strategy == this.strategy
      })
      if (found) {
        found = found.rows.find(item => item.signal == 'Word Count')
        return found.signalData.competitorsAvg
      }
      return 0
    },
    hasFocusCompetitor () {
      return this.pageRun.featuredCompetitors && this.pageRun.featuredCompetitors.length > 0
    },
    availableStrategyOptions () {
      let rlt = [];
      rlt.push({
        label: 'All Competitors',
        tooltip: 'Recommendations based on all your competitors',
        value: 'target',
      })
      rlt.push({
        label: 'Adjusted for Your Word Count',
        tooltip: 'Recommendations based on your current word count',
        value: 'adjusted'
      })
      rlt.push({
        label: 'Focus Competitors',
        tooltip: this.hasFocusCompetitor ? 'Recommendations based only on your selected focus competitors' : 'Please select Focus Competitors to enable this strategy',
        value: 'focus',
        disableCheck: true,
        disabled: !this.hasFocusCompetitor,
      })
      rlt.push({
        label: 'Highest Signal Numbers',
        tooltip: 'Recommendations based on the highest number for each signal',
        value: 'max'
      })
      rlt.push({
        label: 'Custom',
        tooltip: 'Recommendations that you have selected',
        value: 'custom'
      })
      return rlt;
    },
    availableApproachOptions () {
      let rlt = [];
      rlt.push({
        label: 'Regular',
        tooltip: 'The most commonly used approach',
        value: 'regular',
      })
      rlt.push({
        label: 'Conservative',
        tooltip: 'Typically lowers recommendations',
        value: 'conservative',
      })
      rlt.push({
        label: 'Aggressive',
        tooltip: 'Typically increases recommendations',
        value: 'aggressive',
      })
      rlt.push({
        label: 'Hyper Aggressive',
        tooltip: 'Typically the highest recommendations',
        value: 'hyper_aggressive',
      })

      return rlt;
      
    },
    strategyExplainerText() {
      let explainerTexts = {
        'target': "The All Competitors strategy is based on all the competitors in your competitor list. POP's advanced algorithm accounts for all the different sites you are competing against to provide recommendations to give you a ranking edge.  In most situations you should use this strategy.",
        'adjusted': "The Adjusted for Your Word Count strategy adjusts the All Competitor recommendations by factoring in your current word count. You should use this strategy when your word count is significantly over the All Competitors word count recommendation or you are under the recommendation and cannot add to your word count.  POP will let you know when you should consider this strategy.",
        'focus': "The Focus Competitors strategy provides recommendations based on the focus competitors you selected. We recommend that only advanced users consider this strategy. For Express Users, your focus competitors are the top 3 competitors from Google.",
        'max': "The Highest Signal Number strategy show the highest number from any of your competitors for a particular signal. This strategy is rarely recommended. These recommendations are useful for comparison and sometimes for work done to a single signal.",
        'custom': "You can create your own custom strategy by selecting different recommendations from all the other strategies."
      }
      let text = explainerTexts[`${this.strategy}`]
      return text ? text : '--'
    },
    approachExplainerText() {
      let explainerTexts = {
        'regular': "The Regular approach is the most common approach. You should use this approach if there is nothing unusual happening with your page, word count, or niche.  The majority of SEO Campaigns will benefit from the regular approach.",
        'conservative': "Consider the Conservative approach when your page is already ranking well. If you are on the first page of Google or top of the second page, you will often want to go low and slow with changes to your page. You can always get more aggressive later, if needed.",
        'aggressive': "The Aggressive approach is usually only appropriate if your page is \"stuck\" and you are in a competitive niche. Only advanced users should use this approach.",
        'hyper_aggressive': "The Hyper Aggressive approach is often a last resort. When there is nothing left to do and nothing left to lose, only advanced users and those with an extremely high risk tolerance should consider this approach."
      }
      let text = explainerTexts[`${this.approach}`]
      return text ? text : '--'
    },
    hasAlreadyCalculatedScore() {
      let found = this.runStrategies.find(item => {
        return item.approach == this.approach && item.strategy == this.strategy
      })
      return !!found
    },
    pageScore () {
      return this.strategyPageScore(this.dashboardType, this.strategy, this.approach, this.runStrategies)
    },
    tagTotalData () {
      let data = this.pageRun.tagCounts.map(row => {
        let found = this.checkLists.find(checklist => {
          return (checklist.type == 2 && checklist.signal == row.tagLabel)
        })
        if (found) {
          row.checklistId = found.id
          row.isCompleted = found.isCompleted
          row['isArchived'] = found.isArchived
          row.isAutoChecklist = found.isAutoChecklist
        } else {
          row.checklistId = 0
        }
        return row
      })
      return data
    },
    tagCountsForRender () {  // page structure checklist
      let editorChecklistItemLabels = new Set([])
      this.tagTotalData.map(item => {
        if (item['checklistId'] && item['checklistId'] > 0) {
          if (item['isArchived']) {
            editorChecklistItemLabels.delete(item.tagLabel)
          } else {
            if (this.strategy == 'custom') {
              if (!item['isAutoChecklist']) {
                editorChecklistItemLabels.add(item.tagLabel)
              }
            } else {
              editorChecklistItemLabels.add(item.tagLabel)
            }
          }
        }
      })
      if (editorChecklistItemLabels.size == 0) {
        // getting auto checklist items
        this.tagTotalData.map(item => {
          if (editorChecklistItemLabels.size < 5 && !item.comment.toLowerCase().startsWith('leave as')) {  // restrict auto generating items count to 5
            editorChecklistItemLabels.add(item.tagLabel)
          }
        })
      }

      return this.tagTotalData.filter(item => {
          return editorChecklistItemLabels.has(item.tagLabel)
        })
        .map(row => {
          return row
        })
    },
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    currentChecklistNotes () {
      if (this.selectedChecklistId == '') return []
      let item = this.checkLists.find(item => item.id == this.selectedChecklistId)
      if (item) return item.notes; else return [];
    },
    allCheckListNotes () {
      let allNotes = []
      this.checkLists.map(checklist => {
        let notes = checklist.notes.map(note => {
          note['signal'] = checklist['signal']
          note['type'] = checklist['type']
          return note
        })
        allNotes = allNotes.concat(notes)
      })

      let searchKeyword = this.allNotesFilterText.toLowerCase()
      allNotes = allNotes.filter(note => {
        let keywordVariationText = note.type == 0 ? 'Exact Keyword': 'Variation'
        return (
          note.note.toLowerCase().indexOf(searchKeyword) != -1 ||
          note.signal.toLowerCase().indexOf(searchKeyword) != -1 ||
          keywordVariationText.toLowerCase().indexOf(searchKeyword) != -1
        )
      })
      allNotes.sort((a, b) => {
        return a.id < b.id
      })
      return allNotes
    },
    currentSignalName () {
      if (this.selectedChecklistId == '') return ''
      let item = this.checkLists.find(item => item.id == this.selectedChecklistId)
      if (item && item['signal']) return item.signal; else return '';
    },

    currentWordCount () {
      if (this.type === 'Keyword') {
        const wordCount = this.signals.rows.find(wc => wc.signal === 'Word Count')
        return wordCount.signalData.targetKeywordCnt
      }
      return 0
    },
    avgCompetitorWordCount () {
      const wordCount = this.signals.rows.find(wc => wc.signal === 'Word Count')
      return wordCount.signalData.competitorsAvg
    },
    targetWordCountComment () {
      const wordCount = this.signals.rows.find(wc => wc.signal === 'Word Count')
      return wordCount.signalData.comment
    },
    // wordCountData
    goalWordCountByStrategy () { // returns current and target based on strategy and approach [for now only based on strategy]
      return this.strategyWordCountTarget
    },
    wordCountRecommendation() {
      return this.getWordCountRecommendation(this.currentWordCount, this.goalWordCountByStrategy)
    },
    strategySuggestionText () {
      if (this.currentWordCount < this.avgCompetitorWordCount) {
        let underPercent = (this.avgCompetitorWordCount - this.currentWordCount) * 100.0 / this.avgCompetitorWordCount
        if (underPercent < 15) {
          return 'We recommend using "All Competitors" for your strategy selection.'
        } else {
          return 'We recommend increasing your word count and using "All Competitors" for your strategy selection.  If you are unable to increase your word count, we recommend using "Adjusted for Your Word Count" for your strategy selection.'
        }
      } else {
        let overPercentage = (this.currentWordCount - this.avgCompetitorWordCount) * 100.0 / this.avgCompetitorWordCount
        if (overPercentage<=20) {
          return 'We recommend using "All Competitors" for your strategy selection.'
        } else {
          return 'We recommend using "Adjusted for Your Word Count" for your strategy selection.'
        }
      }

      return ''
    },
    contentBriefGoalWordCount () {
      return this.calcContentBriefGoalWordCount(this.currentWordCount, this.avgCompetitorWordCount)    
    }
  },
  methods: {
    updatePageScoreStrategy () {
      console.log('Here is update page score startegy function')
      if (this.hasAlreadyCalculatedScore) {
        this.generateQuicklist()
        return
      }
      if (this.strategy == 'custom') { // this is not available yet
        this.generateQuicklist()
        return
      }

      const pageRunId = parseInt(this.$route.params.pageRunId)
      let options = {
        pageRunId,
        approach: this.approach,
        strategy: this.strategy
      }
      this.calculatingScore = true
      this.$store.dispatch('updateScoringStrategy', options).then(response => {
        this.runStrategies.push(response.data)

        let updatedPageRun = JSON.parse(JSON.stringify(this.pageRun))
        updatedPageRun.runStrategies = this.runStrategies
        this.$store.commit('updatePageRun', updatedPageRun)
        this.generateQuicklist()
      })
      .finally(() => {
        this.calculatingScore = false
      })
    },
    updateRecommendations () {
      if (this.isNotInLatestPageRun) {
        this.dispInOldReportModal = true
        return
      }

      const pageRunId = parseInt(this.$route.params.pageRunId)
      let options = {
        dashboardType: this.dashboardType,
        strategy: this.strategy,
        approach: this.approach,
        siteId: this.site.id,
        pageId: this.page.id,
        pageRunId: pageRunId,
        adjustedWordCountUserDefined: this.strategy == 'adjusted' ? this.adjustedWordCountUserDefined : 0,
      }
      // add api call here..
      this.$store.commit('showLoading')
      this.$store.dispatch('updateRecommendationsForPage', options)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          console.log('cool')
          if (response.data.page) {
            this.$store.commit('setPage', response.data.page)
            this.$store.commit('setStrategy', this.strategy)
            this.$store.commit('setApproach', this.approach)
            this.isChangedStrategySetting = false
            this.isChangedDashboardType = false
          }
          if (response.data.pageRun) {
            // this.$store.commit('setCurrentPageRun', response.data.pageRun)
            this.$store.commit('updatePageRun', response.data.pageRun)
          }
        } else {
          console.log('something went wrong in this action')
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    loadSites () {
      return this.$parent.loadSites()
    },
    getSignalTypeForNote (row) {
      switch (row['type']) {
        case 0:
          return '| Exact Keyword'
        case 1:
          return '| Variation'
        case 2:
          return ''
        case 3:
          return '| LSI'
        case 4:
          return '| Schema Type'
        default:
          break;
      }
    },
    makeClearTooltipForOverTimeChart (tooltipData) {
      if (!Array.isArray(tooltipData)) return []

      return tooltipData.map(item => {
        let tooltip = '', tempSignalCount = {}
        item.map(signal => { 
          if (tempSignalCount[`${signal}`]) tempSignalCount[`${signal}`]++; else tempSignalCount[`${signal}`] = 1; 
        })
        Object.keys(tempSignalCount).map(signal => { tooltip += signal + '(' + tempSignalCount[`${signal}`] + '), ' })
        return tooltip.slice(0,-2)
      })
    },
    hideNoteModalAction () {
      this.showNoteModal = false
      this.isNoteEditMode = false
      this.selectedNoteId = 0
    },
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    addNote () {
      let data = {
        checklistId: this.selectedChecklistId,
        note: this.newNote
      }
      this.$store.commit('showLoading')
      return this.$store.dispatch('createChecklistNote', data)
        .then(response => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.newNote = ''
            // call getting api
            return this.loadSites()
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    updateNote () {
      let data = {
        id: this.selectedNoteId,
        note: this.newNote
      }
      this.$store.commit('showLoading')
      return this.$store.dispatch('updateChecklistNote', data)
        .then(response => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.cancelEditNoteMode()
            // call getting api
            return this.loadSites()
          } else {
            this.$store.commit('hideLoading')
          }
        })
        .catch(() => {
          this.$store.commit('hideLoading')
        })
    },
    setEditNoteMode (note) {
      this.isNoteEditMode = true
      this.selectedNoteId = note.id
      this.newNote = note.note
    },
    cancelEditNoteMode(note) {
      this.isNoteEditMode = false
      this.selectedNoteId = 0
      this.newNote = ''
    },
    deleteNote (noteId) {
      if (!window.confirm('Are you sure you want to delete this note?')) {
        return
      }
      this.$store.commit('showLoading')
      return this.$store.dispatch('deleteChecklistNote', noteId)
        .then(response => {
          if (response.data && response.data.status === 'SUCCESS') {
            // call getting api
            return this.loadSites()
          } else {
            this.$store.commit('hideLoading')
          }
        })
        .catch(() => {
          this.$store.commit('hideLoading')
        })
    },
    archiveChecklistItem (type, row) {
      let checklistId = row.checklistId

      let data = { 
        id: checklistId,
        isAutoRecommendtaionArchive: 0
      }
      if (!window.confirm('Do you want to archive this signal')) { return }

      if (!checklistId) {
        data = {
          ...data,
          type: type,
          dispType: row.dispType,
          recommendationType: row.recommendationType,
          pageId: this.pageId,
          signal: row.signal,
          isAutoRecommendtaionArchive: 1
        }
      }

      this.$store.commit('showLoading')
      return this.$store.dispatch('archiveOneFromCheckList', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            return this.loadSites()
          } else {
            this.$store.commit('hideLoading')
          }
        })
        .catch(() => {
          this.$store.commit('hideLoading')
        })
    },
    dispNotesForChecklist (checklistId) {
      this.selectedChecklistId = checklistId
      this.showNoteModal = true      
    },
    generateQuicklist () {
      const scoringStrategy = this.runStrategies.find(s => s.approach === this.approach && s.strategy === this.strategy)
      let strategySignals = scoringStrategy ? scoringStrategy.rows : []

      // now use the strategySignals to display new checklist

      // Recommendations
      let keywordSignals = strategySignals.filter(item => item.signalData).map(item => {
        return {
          signal: item.signal,
          ...item.signalData
        }
      })

      let lsiSignals = strategySignals.filter(item => item.lsiData).map(item => {
        return {
          signal: item.signal,
          ...item.lsiData
        }
      })
      let lsiAllSignals = []
      for (let i=0; i<lsiSignals.length;i++) {
        lsiAllSignals.push(lsiSignals[i])
      }


      let data = []

      let editorChecklistItemLabels = new Set([])
      console.log('making temp auto checklist')
      keywordSignals.map(item => {
        if (editorChecklistItemLabels.size < 5 && !item.comment.toLowerCase().startsWith('leave as') && !['Word Count'].includes(item.signal))
        editorChecklistItemLabels.add(item.signal)
      })
      this.signalData = keywordSignals.filter(item => {
        return editorChecklistItemLabels.has(item.signal)
      })
      editorChecklistItemLabels.clear()


      let variationSignals = strategySignals.filter(item => item.variationData).map(item => {
        return {
          signal: item.signal,
          ...item.variationData
        }
      })

      variationSignals.map(item => {
        if (editorChecklistItemLabels.size < 5 && !item.comment.toLowerCase().startsWith('leave as') && !['Word Count', 'Keyword in Main URL'].includes(item.signal))
        editorChecklistItemLabels.add(item.signal)
      })
      this.variationData = variationSignals.filter(item => {
        return editorChecklistItemLabels.has(item.signal)
      })
      editorChecklistItemLabels.clear()


      let lsiVariationSignals = strategySignals.filter(item => item.lsiData).map(item => {
        return {
          signal: item.signal,
          ...item.lsiData
        }
      })

      lsiVariationSignals.map(item => {
        if (editorChecklistItemLabels.size < 5 && !item.comment.toLowerCase().startsWith('leave as') && !['Word Count', 'Keyword in Main URL'].includes(item.signal))
        editorChecklistItemLabels.add(item.signal)
      })
      this.lsiVariationData = lsiVariationSignals.filter(item => {
        return editorChecklistItemLabels.has(item.signal)
      })
      editorChecklistItemLabels.clear()


      

      this.signalExplainData = explainData;
    },
    changeMessage: function(){
        this.isShowVariationDropdown = !this.isShowVariationDropdown
        if (this.message === 'Hide'){
            this.message = 'Show'
        }else if(this.message === 'Show'){
            this.message='Hide'
        }
      },
    checkColor (comment) {
      if (this.pageNotBuilt) {
        return 'white'
      }
      let color = ''
      if (comment.indexOf('Leave As Is') > -1) {
        color = '#b8e1cc'
      } else if (comment.indexOf('Reduce') > -1 || comment.indexOf('reducing') > -1) {
        color = '#f0c291'
      } else {
        color = '#cf8c8e'
      }
      return color;
    },

    getMarkTypeStyle (signalName, comment) {
      if (this.pageNotBuilt || !comment) {
        return {}
      }
      const isWeakSignal = WEAK_SIGNAL_LABELS.includes(signalName)
      if (comment.indexOf('Leave As Is') > -1) {
        return { 'signal-mark': true, 'good-marktype': true }
      } else if (comment.indexOf('Reduce') > -1 || comment.indexOf('reducing') > -1) {
        if (isWeakSignal) {
          return { 'signal-mark': true, 'low-marktype': true }
        }
        return { 'signal-mark': true, 'mid-marktype': true }
      } else if (comment.indexOf('Increase') > -1 || comment.indexOf('Add 1') > -1){
        if (isWeakSignal) {
          return { 'signal-mark': true, 'mid-marktype': true }
        }
        return { 'signal-mark': true, 'high-marktype': true }
      }
      return {}
    },

    downloadQuicklist () {
      this.downloadExcelForChecklist(0, null, null, true, { strategy: this.strategy, approach: this.approach })
    },
    loadStrategySettingFromPage () {
      console.log('get page setting', this.page)
      let strategySetting = this.page.strategySetting
      if (strategySetting) {
        if (this.$store.state.strategies.includes(strategySetting.strategy)) this.strategy = strategySetting.strategy
        if (this.$store.state.approachies.includes(strategySetting.approach)) this.approach = strategySetting.approach
        if (this.$store.state.dashboardTypes.includes(strategySetting.dashboardType)) this.dashboardType = strategySetting.dashboardType
      } else {
        this.strategy = 'target'
        this.approach = 'regular'
      }
      this.$store.commit('setStrategy', this.strategy)
      this.$store.commit('setApproach', this.approach)
      this.$store.commit('setDashboardType', this.dashboardType)
      
      setTimeout(() => {
        this.isChangedDashboardType = false
        this.isChangedStrategySetting = false
        this.generateQuicklist()
      }, 100);
    },
    getWordCountRecommendation(currentWordCount, goalWordCount) {
      return this.calcWordCountRecommendation(currentWordCount, goalWordCount)
    },
    changeTodoTab(tabName) {
      this.todoTabType = tabName
      switch (this.todoTabType) {
        case 'content_brief':
          this.dashboardType = 'contentBrief'
          break;
        case 'simple_list':
          this.dashboardType = 'signals'
          break;
      }
    }
  },
  mounted() {
    if (this.pageRun) {
      this.runStrategies = this.pageRun.runStrategies
    }

    this.loadStrategySettingFromPage()
    this.generateQuicklist()
  },
  beforeDestroy () {
    if (this.isChangedStrategySetting) {
      this.loadStrategySettingFromPage() // this is for if user changed strategy setting but didn't click 'update recommendation' button    
    }
  }
}
</script>

<style lang="scss" scoped>
.box-item {
  margin: 20px;
}

.recommendations {
  max-width: 400px;
}

.is-keyword-noty {
  background-color: #ffdd57;
  color: rgba(0, 0, 0, 0.7);
}
.is-variation-noty {
  background-color: #FFA264;
  color: rgba(0, 0, 0, 0.7);
}


.view-checklist-history-button {
  /* margin-right: 50px; */
  font-weight: bold;
  font-size: 0.85rem;
  color: #209cee;
}

.score-label {
  color: var(--v-mainColor-base);
  font-size: 18px;
  font-weight: 700;
  line-height: 106.5%;
}
.score-number {
  font-weight: 700;
}

.download-quick-list-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: end;
  img {
    width: 14.7px;
    height: auto;
    margin-right: 5px;
  }
  span {
    color: var(--v-profileAnchorColor-base);
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
  }
}
.radio {
  color: var(--v-mainColor-base);
}

.word-count-section {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  align-items: center;

  .by-text {
    flex-grow: 2;
    .current-label {
      font-size: 18px;
      font-weight: 700;
      color: var(--v-mainColor-base);
    }
    .explain {
      color: var(--v-mainColor-base);
    }
  }

  .by-progress {
    flex-grow: 1;

    .current-number {
      font-size: 25px;
      font-weight: 700;
      color: var(--v-mainColor-base);
    }
  }

}

.score-strategy-section {
  position: relative;
  border: 1px solid #e4e4e4;
  background: white;
  border-radius: 5px;
  /* padding: 15px 10px; */
  box-shadow: 2px 2px 4px #999;

  .strategy-section {
    display: grid;
    grid-template-columns: 2fr 5fr;
    column-gap: 50px;

    & > div {
      padding: 15px 10px;
    }
  }
  .right-area {
    position: relative;
  }


  @media only screen and (max-width: 1100px) {
    .strategy-section {
      grid-template-columns: 1fr;
    }
  }
}
.rerun-suggest-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: var(--v-grayColor-base);

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
.strategy-greyout-overlay {
  font-size: 1.1rem;
  font-weight: bold;
  padding: 20px 50px;
  ul {
    list-style-type: circle;
  }
}

.column { // cusomize bulma
  padding: 8px;
}

.strategy-choices {
  label.radio {
    margin: 8px 0 8px 0;
    &[aria-expanded="true"] {
      font-weight: 700;
    }
  }
}
.strategy-explainer-text {
  color: var(--v-mainColor-base);
}


.checklist-area {
  position: relative;
}
.disable-checklist-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 35;
  background: rgba(49, 49, 49, 0.85);
}

</style>