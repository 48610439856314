import { html as htmlBeautifiy } from 'js-beautify'

export const beautifyHtmlString = (htmlStr) => {
  const options = {
      "indent_size": "2",
      "indent_char": " ",
      "max_preserve_newlines": "5",
      "preserve_newlines": true,
      "keep_array_indentation": false,
      "break_chained_methods": false,
      "indent_scripts": "normal",
      "brace_style": "collapse,preserve-inline",
      "space_before_conditional": true,
      "unescape_strings": false,
      "jslint_happy": true,
      "end_with_newline": false,
      "wrap_line_length": "0",
      "indent_inner_html": true,
      "comma_first": false,
      "e4x": true,
      "indent_empty_lines": false
    }
    let beautifiedCode = htmlBeautifiy(htmlStr, options)
    return beautifiedCode
}

export const downloadAsHtml = (fileName, htmlStr) => {
  try {

    let beautifiedCode = beautifyHtmlString(htmlStr)
    
    if (beautifiedCode) {
      const blob = new Blob([ beautifiedCode ], { type: 'text/html' })
      const url = URL.createObjectURL(blob);
      var element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  } catch (e) {
    console.log(e);
  }
}