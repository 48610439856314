<template>
<div class="d-flex align-center gap10">
  <v-btn rounded
    v-show="!googleUser"
    color="profileAnchorColor"
    class="text-none whiteColor--text googleLoginButton">
    <span>Connect</span>
  </v-btn>
  <a  v-show="googleUser" class="connected-status">
    <span class="material-icons">check_circle</span>
    <span>Connected</span>
  </a>
  <a v-show="googleUser"
    @click="signOut"
    class="signout-btn">
    <span class="material-icons-outlined">delete_forever</span>
  </a>
</div>
</template>

<script>
import { gapi, loadAuth2 } from 'gapi-script';
import { EventEmitter } from '@/utils/Event.js';

export default {
  data () {
    return {
      googleUser: null,
    }
  },
  methods: {
    initializeGoogleButton () {
      const setAuth2 = async () => {
        const auth2 = await loadAuth2(gapi, GOOGLE_CLIENT_ID, GOOGLE_OAUTH_SCOPE)
        if (auth2.isSignedIn.get()) {
          this.updateUser(auth2.currentUser.get())
        }
        this.attachSignin(document.getElementsByClassName('googleLoginButton'), auth2)
      }
      setAuth2();
    },
    updateUser (currentUser) {
      this.googleUser = currentUser
      this.$store.commit('updateGoogleUser', {
        name: currentUser.getBasicProfile().getName(),
        profileImg: currentUser.getBasicProfile().getImageUrl(),
        Ca: currentUser.Ca
      })
      EventEmitter.dispatch('GOOGLE_USER_UPDATE', currentUser)
    },

    attachSignin (elements, auth2) {
      try {
        for (const key in elements) {
          auth2.attachClickHandler(elements[key], {},
              (gu) => {
                  this.updateUser(gu);
              }, (error) => {
                  console.log(JSON.stringify(error))
              });
        }
      } catch (e) {
        console.log('looks like alerady attached')
      }
    },
    signOut () {
      this.googleUser = null;
      const auth2 = gapi.auth2.getAuthInstance();
      this.$store.commit('updateGoogleUser', null);
      auth2.signOut().then(() => {
        console.log('User signed out');
      })
      EventEmitter.dispatch('GOOGLE_USER_UPDATE', null);
    }
  },
  mounted() {
    this.initializeGoogleButton()
  },
  beforeDestroy() {}
  
}
</script>

<style lang="scss" scoped>
.connected-status {
  display: flex;
  align-items: center;
  gap: 2px;
  .material-icons {
    font-size: 1.2rem;
    color: var(--v-green7Color-base);
  }
  span {
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--v-green7Color-base);
  }
}
.signout-btn {
  cursor: pointer;
  span {
    font-size: 1.6rem;
    color: var(--v-profileAnchorColor-base);
  }
}
</style>