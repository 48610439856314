// let signalsForPro = [
//   'H1 Tag Total', 'H2 Tag Total', 'H3 Tag Total', 'H4 Tag Total', 'H5 Tag Total', 'H6 Tag Total',
//   'Frequently Asked Questions', 'Table of content'
// ]

import { TARGET_MIN_PERCENTAGE } from "@/components/PageRun/constants";
import { dispNames, StopLightBGColorOptions } from '@/components/PageRun/ContentBrief/content_brief_constants.js'

let signalsForCustom = [
  'Bold Tag Total', 'Italic Tag Total', 'Image File total', 'list', 'Table Total', 'Form Total', 'Anchor Text Tag Total',
  'Frequently Asked Questions', 'Table of content'
]

let signalsForPro = signalsForCustom;

export const mixin = {
  computed: {
    // selectedMainTab
    signals () {
      if (this.selectedMainTab == 'advanced') {
        return signalsForCustom
      } else {
        return signalsForPro
      }
    },
    pageStructureDataForPDF() {
      let rlt = []
      for (let i = 0; i < this.signals.length; i++) {
        let signalName = this.signals[i]
        switch(signalName) {
          case 'list':
              rlt.push(`Lists: Current ${ this.getTagTotalMinMaxCurrentForLabel('Ordered List Total')[2] + this.getTagTotalMinMaxCurrentForLabel('Unordered List Total')[2] }`)
              rlt.push(`${ this.getTagTotalRecommendationForLabel('list') }`)
            break;
          default:
            if (this.getTagTotalRecommendationForLabel(signalName)) {
              rlt.push(`${ dispNames[signalName] || signalName }: Current ${this.getTagTotalMinMaxCurrentForLabel(signalName)[2]}`)
              rlt.push(`${this.getTagTotalRecommendationForLabel(signalName)}`)
            }
            break;
        }
      }
      return rlt
    },
    pageStructureRecommendations () {
      let rlt = []
      for (let i = 0; i < this.signals.length; i++) {
        let signalName = this.signals[i]
        switch(signalName) {
          case 'list':
            let orderedTagTotalData = this.getTagTotalMinMaxCurrentForLabel('Ordered List Total')
            let unOrderedTagTotalData = this.getTagTotalMinMaxCurrentForLabel('Unordered List Total')
            rlt.push({
              tagLabel: 'list',
              signalName: signalName,
              label: (dispNames[signalName] || signalName),
              targetMin: orderedTagTotalData[0] + unOrderedTagTotalData[0],
              targetMax: orderedTagTotalData[1] + unOrderedTagTotalData[1],
              current: orderedTagTotalData[2] + unOrderedTagTotalData[2],
              recommend: this.getTagTotalRecommendationForLabel('list'),
            })
              // rlt.push(`Lists: Current ${ orderedTagTotalData[2] + unOrderedTagTotalData[2] }`)
              // rlt.push(`${ this.getTagTotalRecommendationForLabel('list') }`)
            break;
          default:
            if (this.getTagTotalRecommendationForLabel(signalName)) {
              let tagTotalData = this.getTagTotalMinMaxCurrentForLabel(signalName)
              rlt.push({
                tagLabel: signalName,
                signalName: signalName,
                label: (dispNames[signalName] || signalName),
                targetMin: tagTotalData[0],
                targetMax: tagTotalData[1],
                current: tagTotalData[2],
                recommend: this.getTagTotalRecommendationForLabel(signalName)
              })
            }
            break;
        }
      }
      return rlt;
    },
    filteredPageStructureRecommendations () {
      let count = 0;
      return this.pageStructureRecommendations.filter(item => {
        count ++;
        if (this.showMorePageStructure) {
          if (count <= 10) return true; else return false;
        } else {
          if (count <= 5) return true; else return false;
        }
      })      
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
  },
  methods: {
    getTagTotalRecommendationForLabel(tagLabel) {
      let minVal1 = 0, maxVal1 = 0, minVal2 = 0, maxVal2 = 0;
      let rlt = '';

      if (tagLabel == 'list') {
        minVal1 = this.getTagTotalMinMaxCurrentForLabel('Ordered List Total')[0]
        maxVal1 = this.getTagTotalMinMaxCurrentForLabel('Ordered List Total')[1]
        minVal2 = this.getTagTotalMinMaxCurrentForLabel('Unordered List Total')[0]
        maxVal2 = this.getTagTotalMinMaxCurrentForLabel('Unordered List Total')[1]
        if (minVal1 + minVal2 == 0) {
          rlt = `Use up to ${maxVal1 + maxVal2}`
        } else {
          rlt = `Use between ${ minVal1 + minVal2 } and ${maxVal1 + maxVal2}`
        }
      } else if (['Frequently Asked Questions', 'Table of content'].includes(tagLabel)) {
        let found = this.findSignalDataByLabel(tagLabel)
        if (found) {
          rlt = found.comment
        }
        return rlt
      } else {
        let data = this.getTagTotalMinMaxCurrentForLabel(tagLabel)
        minVal1 = data[0], maxVal1 = data[1]
        if (minVal1 == 0) {
          rlt = `Use up to ${maxVal1}`
        } else {
          rlt = `Use between ${minVal1} and ${maxVal1}`
        }
      }

      if (tagLabel == 'list') {
        if (maxVal1 + maxVal2 == 0) return ''
      } else if (maxVal1 == 0) {
        return ''
      }
      return rlt
    },
    findSignalDataByLabel(tagLabel) {
      let found = this.tagTotalData.find(item => {
        return item.tagLabel == tagLabel
      })
      return found
    },
    getTagTotalMinMaxCurrentForLabel(tagLabel) {
      if (!this.tagTotalData) return [0, 0, 0]
      let minVal = 0, maxVal = 0, current = 0;
      let found = this.findSignalDataByLabel(tagLabel)
      if (found) {
        minVal = found.min
        maxVal = found.max
        current = found.signalCnt
      }
      return [minVal, maxVal, current]
    },
    openFullPageEditor(editorMode='') {
      let queryParams = {}
      if (this.secretKey && editorMode != 'AI_MODE') {
        queryParams = { secretKey: this.secretKey }
      } else {
        queryParams = { pageRunId: this.pageRunId }
      }
      
      if (editorMode) {
        queryParams['editorMode'] = editorMode
      }
      if (this.selectedSubAccountRelation) {
        queryParams['relId'] = this.selectedSubAccountRelation.id
      }
      let routeData = this.$router.resolve({name: 'FullPageSourceEditor', query: queryParams});
      window.open(routeData.href, '_blank')
    },
    getStopLightColorForItem(contentBriefType, item) {
      let current = 0, targetMin = 0, targetMax = 0;
      switch (item.term.type) {
        case 'keyword':
          current = item.contentBrief.current
          targetMax = item.contentBrief.target
          targetMin = Math.round(targetMax / 2)
          break;
        case 'variation':
        case 'lsi':
          current = item.contentBrief.current
          targetMin = item.contentBrief.targetMin
          targetMax = item.contentBrief.targetMax
          break;
      }
      return this.getStopLightBGColor(contentBriefType, current, targetMin, targetMax)
    },
    getStopLightBGColor(contentBriefType, current, targetMin, targetMax, isForSection=false, considerOverOptimization=false) {
      if (targetMax > 0 && current == 0) {
        if ( targetMin == 0 ) {
          return StopLightBGColorOptions.yellow.color
        }
        return StopLightBGColorOptions.red.color
      }
      if (current >= targetMin && current <= targetMax) {
        if (isForSection) {
          if (contentBriefType == 'title') {
          // if keyword is not fit yet, then we will not display green for page title section
            let found = this.titleData.find(item => item.term.type == 'keyword')
            if (found && found.contentBrief.current < found.contentBrief.target) {
              return StopLightBGColorOptions.yellow.color
            }
          }

          if (considerOverOptimization) {
            let sectionTermsData = []
            let targetMinPercentage = 0
            if (contentBriefType == 'title') {
              sectionTermsData = this.titleData
              targetMinPercentage = TARGET_MIN_PERCENTAGE['title']
            } else if (contentBriefType == 'pageTitle') {
              sectionTermsData = this.pageTitleData
              targetMinPercentage = TARGET_MIN_PERCENTAGE['pageTitle']
            } else if (contentBriefType == 'subHeadings') {
              sectionTermsData = this.subHeadingsData
              targetMinPercentage = TARGET_MIN_PERCENTAGE['subHeadings']
            } else if (contentBriefType == 'p') {
              sectionTermsData = this.bodyContentData
              targetMinPercentage = TARGET_MIN_PERCENTAGE['p']
            }


            let foundOverTerm = false;
            let foundUnderTerm = false;
            sectionTermsData.map(item => {
              let itemCurrent = item.contentBrief.current;
              let itemTargetMin = item.term.type == 'keyword' ? Math.round(item.contentBrief.target * targetMinPercentage ) : item.contentBrief.targetMin
              let itemtTargetMax = item.term.type == 'keyword' ? item.contentBrief.target : item.contentBrief.targetMax
              if ( itemCurrent > itemtTargetMax) { foundOverTerm = true; }
              if ( itemCurrent < itemTargetMin) { foundUnderTerm = true; }
            })

            if (foundOverTerm) {
              return StopLightBGColorOptions.purple.color
            }
            if (foundUnderTerm) {
              return StopLightBGColorOptions.yellow.color
            }
          }
        }
        return StopLightBGColorOptions.green.color
      }

      if (current > targetMax) {
        return StopLightBGColorOptions.purple.color
      }
      if (current >= targetMin && current <= targetMax) {
        return StopLightBGColorOptions.green.color;
      }
      return StopLightBGColorOptions.yellow.color;
    },
    /**
     * return value 
     * underoptimized2 // red case
     * underoptimized1 // orange case
     * optimized // greencase
     */
    getStopLightStateText(contentBriefType, current, targetMin, targetMax, isForSection=false) {
      if (current >= targetMin && current <= targetMax) {
        if (isForSection && contentBriefType == 'title') {
          // if keyword is not fit yet, then we will not display green for page title section
          let found = this.titleData.find(item => item.term.type == 'keyword')
          if (found && found.contentBrief.current < Math.round(found.contentBrief.target * TARGET_MIN_PERCENTAGE['tilte'])) {
            return 'underoptimized1'
          }
        }
        return 'optimized'
      }
      if (targetMin > 0 && current == 0) {
        return 'underoptimized2'
      }
      if (current > targetMax) {
        return 'underoptimized1'
      }
      if (current >= targetMin && current <= targetMax) {
        return 'optimized';
      }
      return 'underoptimized1'
    },
    scoreToText(score) {
      if (score >= 80) {
        return 'GOOD'
      } else {
        return 'NEEDS WORK'
      }
    },
    downloadFile(downloadUrl) {
      if (!downloadUrl) return;

      const fileName = downloadUrl.split("/").pop();
      const downloadLink = document.createElement("a");
      downloadLink.download = fileName;
      downloadLink.href = downloadUrl;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();

      setTimeout(() => {
        document.body.removeChild(downloadLink);
      }, 100);
    }
  }
}
