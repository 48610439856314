import Vue from 'vue'
import Vuetify from 'vuetify'
// import Vuetify from 'vuetify/lib' // if user vuetify-loader
import 'vuetify/dist/vuetify.min.css'

import { light, dark } from '../themes.js';


Vue.use(Vuetify)

const opts = {
  theme: {
    // dark: true,
    options: { customProperties: true },
    themes: {
      light: light,
      dark: dark,
    }
  }
}

new Vuetify(opts)

export default new Vuetify(opts)