<template>
  <div class="limited-width-box">
    <v-card class="is-fullheight flex flex-column white-common-box">
      <div class="pa-5 flex-grow-1 flex flex-column">
        <div class="title-font mainColor--text box-title-label">
          Manage subaccounts
        </div>
        <div class="notify-label">
          <i class="material-icons">error_outline</i>
          You're allowed to add up to {{ user.maximumSubAccountsCount }} sub-accounts.
        </div>
        <div v-if="user.maximumSubAccountsCount > 0">
          <p class="main-section-label">Primary Account</p>
          <div class="account-setting-section d-sx-block justify-space-between d-md-flex">
            <div v-if="!isAccountNameEditMode">
              <span class='section-label'>Name: </span>
              <span class="section-desc">{{ accountName }}</span>
              <div class="edit-account-name-btn" data-tooltip="Edit Account Name" @click="toggleAccountNameEditMode()">
                <i class="material-icons">border_color</i>
                <span>edit</span>
              </div>
            </div>
            <div class="flex-grow-1 field has-addons" v-else>
              <div class="control is-expanded">
                <input type="text" v-model="accountName" class="input" @keyup.13="changeAccountName()" />
              </div>
              <div class="control flex align-center">
                <v-btn rounded x-small color="profileAnchorColor" class="text-none" @click="changeAccountName()">Save</v-btn>
                <v-btn rounded x-small color="grayColor" class="text-none"
                  @click="toggleAccountNameEditMode()">Cancel</v-btn>
              </div>
            </div>

            <div v-if="!isAccountNameEditMode">
              <span class="section-label">Email: </span>
              <span class="section-desc">{{ user.email }}</span>
            </div>
          </div>


          <div class="mt30">
            <div class="d-sm-block d-md-flex">
              <div class="flex-grow-1">
                <p class="main-section-label">Active Sub-Accounts</p>
              </div>
              <div @click="showAddSubAccountModalAction" class="add-subaccount-button">
                <i class="material-icons">add_circle</i>
                <span class='has-text-centered'>Add a sub-account</span>
              </div>
            </div>

            <table class="row-round-table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Access to projects</th>
                  <th>Can create projects</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(account, idx) in subAccounts" :key="idx">
                  <td>{{ account.subAccountEmail }}</td>
                  <td>
                    {{ account.isAllPermission ? 'All projects' : (account.permittedProjects.length + ' project' +
                      `${account.permittedProjects.length > 1 ? 's' : ''}`) }}
                  </td>
                  <td>
                    {{ account.canCreateProject ? 'yes' : 'no' }}
                  </td>
                  <td>
                    <i class="material-icons option-dot" :id="`dot-menu-for-${idx}`">more_horiz</i>
                    <v-menu :activator="`#dot-menu-for-${idx}`" bottom offset-y>
                      <v-list>
                        <v-list-item link>
                          <v-list-item-title class="mainColor--text" @click="enterSubAccountEditMode(account)">
                            Edit
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                          <v-list-item-title class="red3Color--text" @click="enterDeleteSubAccountMode(account)">
                            Delete
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="modal" :class="{ 'is-active': showAddEditAccountModal }">
          <div class="modal-background"></div>
          <div class="modal-content">
            <div class="box modal-container" style="overflow: hidden;">
              <div class="title-font mainColor--text is-size-4 mb20">
                {{ isSubAccountEditMode ? 'Edit sub-account' : 'Add a sub-account' }}
              </div>
              <div>
                <div class="field">
                  <label class="label"></label>
                  <div class="control">
                    <input class="input" type="email" placeholder="Enter Email" v-model="subAccountEmail">
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" v-model="canCreateProject">
                      can create a new project in your account (you will still be the sole owner of that project)
                    </label>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" v-model="allowAccessToAllProjects">
                      can access all the projects in your account
                    </label>
                  </div>
                </div>
                <div v-if="!allowAccessToAllProjects">
                  <v-select outlined v-model="permittedProjectIds" :items="sites" item-value="id" item-text="name"
                    label="Select projects" multiple chips></v-select>
                </div>
                <div class="mt-5 text-right">
                  <v-btn rounded outlined color="mainColor" class="button-with-icon text-none white--text"
                    @click="resetInput">{{ isSubAccountEditMode ? 'Cancel' : 'Reset selected projects' }}</v-btn>
                  <template v-if="!isSubAccountEditMode">
                    <v-btn rounded color="profileAnchorColor" class="button-with-icon text-none white--text ml-2"
                      @click="saveSubAccount">Add sub-account</v-btn>
                  </template>
                  <template v-else>
                    <v-btn rounded color="profileAnchorColor" class="button-with-icon text-none white--text ml-2"
                      @click="saveSubAccount">Save changes</v-btn>
                  </template>
                </div>
              </div>
            </div>
            <button class="custom-modal-close-btn" aria-label="close" @click="showAddEditAccountModal = false"><i
                class="material-icons">close</i></button>
          </div>
        </div>


        <div class="modal" :class="{ 'is-active': showSubAccountDeleteModal }">
          <div class="modal-background" @click="editSubAccountId = ''; showSubAccountDeleteModal = false;"></div>
          <div class="modal-content" style="max-width: 650px">
            <div class="box modal-container">
              <div class="title-font is-size-4 mainColor--text">
                Do you want to delete this sub account?
              </div>
              <div class="subtitle-font mainColor--text mt-5">
                This sub-account will lose all access to your POP account.
              </div>
              <div class="mt-5 d-flex justify-end gap10">
                <v-btn rounded outlined
                  color="mainColor" class="button-with-icon text-none"
                  @click="editSubAccountId = ''; showSubAccountDeleteModal = false;">
                  No
                </v-btn>
                <v-btn rounded color="profileAnchorColor" class="button-with-icon whiteColor--text text-none"
                  @click="deleteSubAccountFromAgency(editSubAccountId)">Yes</v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="modal" :class="{ 'is-active': showErrorModal }">
          <div class="modal-background" @click="showErrorModal = false"></div>
          <div class="modal-content">
            <div class="box modal-container">
              <p class="subtitle" v-html="errorMsg"></p>
              <div class="field has-addons">
                <div class="control">
                  <v-btn rounded color="profileAnchorColor" class="button-with-icon mainColor--text text-none"
                    @click="showErrorModal = false">Ok</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>

import validator from 'validator';

export default {
  components: {},
  data() {
    return {
      accountName: '',
      isAccountNameEditMode: false,

      isSubAccountEditMode: false,
      editSubAccountId: '',
      subAccountEmail: '',
      canCreateProject: false,
      allowAccessToAllProjects: false,
      permittedProjectIds: [],

      showSubAccountDeleteModal: false,

      errorMsg: '',
      showErrorModal: false,

      showAddEditAccountModal: false,
    }
  },
  computed: {
    sites() {
      return this.$store.state.sites
    },
    user() {
      return this.$store.state.user
    },
    subAccounts() {
      return this.$store.state.user.subAccounts
    }
  },
  watch: {

  },
  methods: {
    toggleAccountNameEditMode() {
      this.isAccountNameEditMode = !this.isAccountNameEditMode
    },
    changeAccountName() {
      const data = { accountName: this.accountName }
      // saveAccountName
      this.$store.commit('showLoading')
      this.$store.dispatch('saveAccountName', data)
        .then(response => {
          if (response.data.status && response.data.status == 'SUCCESS') {
            this.$store.commit('setUserData', { userData: response.data.user })
            this.isAccountNameEditMode = false
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
    changeSelectedGroup(event, site) {
      console.log(event.target.checked, site.id)
      if (event.target.checked) {
        if (this.permittedProjectIds.indexOf(site.id) == -1) this.permittedProjectIds.push(site.id)
      } else {
        let pos = this.permittedProjectIds.indexOf(site.id)
        if (pos >= 0) this.permittedProjectIds.splice(pos, 1)
      }
    },
    enterSubAccountEditMode(account) {
      this.isSubAccountEditMode = true
      this.editSubAccountId = account.id
      this.subAccountEmail = account.subAccountEmail
      this.canCreateProject = (account.canCreateProject == 1)
      this.permittedProjectIds = account.permittedProjects.slice()
      this.allowAccessToAllProjects = (account.isAllPermission == 1)
      this.showAddEditAccountModal = true;
    },
    enterDeleteSubAccountMode(account) {
      this.showSubAccountDeleteModal = true
      this.editSubAccountId = account.id
    },
    resetInput() {
      if (this.isSubAccountEditMode) {
        this.showAddEditAccountModal = false;
      }

      this.isSubAccountEditMode = false
      this.editSubAccountId = ''
      this.subAccountEmail = ''
      this.canCreateProject = ''
      this.permittedProjectIds = []
      this.allowAccessToAllProjects = false
      document.querySelectorAll('.projectList').forEach(chk => {
        chk.checked = false
      });
    },
    showAddSubAccountModalAction() {
      this.resetInput();
      this.showAddEditAccountModal = true;
    },
    deleteSubAccountFromAgency(id) {
      this.showSubAccountDeleteModal = false
      this.$store.commit('showLoading')
      this.$store.dispatch('deleteSubAccount', { id })
        .then(response => {
          if (response.data.status && response.data.status == 'SUCCESS') {
            this.resetInput()
            this.$store.commit('setUser', response.data.user)
          } else {
            this.errorMsg = response.data.msg
            this.showErrorModal = true
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
    saveSubAccount() {
      let errorMsg = ''
      if (this.subAccountEmail == '') {
        errorMsg += '* Please input an email for the sub-account'
      } else if (validator.isEmail(this.subAccountEmail) == false) {
        errorMsg += '* Please input a valid Email for the Sub Account'
      }
      if (this.allowAccessToAllProjects == false && this.permittedProjectIds.length == 0) {
        errorMsg += '<br/>* You must select at least 1 project'
      }
      if (errorMsg != '') {
        this.errorMsg = errorMsg
        this.showErrorModal = true
        return
      }

      let data = {
        subAccountEmail: this.subAccountEmail,
        allowAccessToAllProjects: this.allowAccessToAllProjects ? 1 : 0,
        canCreateProject: this.canCreateProject ? 1 : 0,
        permittedProjectIds: this.allowAccessToAllProjects ? [] : this.permittedProjectIds
      }
      if (this.isSubAccountEditMode) {
        data['id'] = this.editSubAccountId
        this.$store.commit('showLoading')
        this.$store.dispatch('updateSubAccount', data)
          .then(response => {
            if (response.data.status && response.data.status == 'SUCCESS') {
              this.resetInput()
              this.$store.commit('setUser', response.data.user)
            } else {
              this.errorMsg = response.data.msg
              this.showErrorModal = true
            }
          })
          .finally(() => this.$store.commit('hideLoading'))
      } else {
        this.$store.commit('showLoading')
        this.$store.dispatch('addSubAccount', data)
          .then(response => {
            if (response.data.status && response.data.status == 'SUCCESS') {
              this.resetInput()
              this.$store.commit('setUser', response.data.user)
              this.showAddEditAccountModal = false;
            } else {
              this.errorMsg = response.data.msg
              this.showErrorModal = true
            }
          })
          .finally(() => this.$store.commit('hideLoading'))
      }
    },
    loadSites() {
      this.$store.dispatch('loadSites')
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('setSites', response.data.sites)
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
  },
  beforeMount() {
    this.accountName = this.$store.state.user.agencyName
    this.loadSites()
  }
}
</script>

<style scoped lang="scss">
* {
  color: var(--v-mainColor-base);
}

.main-section-label {
  margin-top: 30px;
  font-size: 16px;
  font-weight: bold;
}
.section-label {
  font-weight: bold;
  color: var(--v-darkGrayColor-base);
}

.section-desc {
  color: var(--v-mainColor-base);
  font-weight: bold;
}

.edit-account-name-btn {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;

  i {
    font-size: 11px;
    margin-right: 5px;
  }

  span {
    font-weight: bold;
  }

  i,
  span {
    color: var(--v-link1Color-base);
  }
}

.add-subaccount-button {
  cursor: pointer;
  display: flex;
  align-items: center;

  i {
    font-size: 15px;
    margin-right: 5px;
  }

  i,
  span {
    color: var(--v-anchor1Color-base) !important;
  }
}
</style>