<template>
<div class="modal" :class="{'is-active': showModal}">
    <div class="modal-background" @click="closeModalAction"></div>
    <div class="modal-content" style="max-width: 650px;">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div class="title-font warning-title mb-5">Warning!</div>
          <div>
            <div class="might-not-pick">POP might not pick up your changes.</div>
            <div class="explain">
              <div>
                You have run your target URL 2 or more times in the last 24 hours. While this is a very rare occurrence your hosting might start caching your page.<br/>
              </div>
              <div>
                Please check out the FAQ <a href="https://help.pageoptimizer.pro/article/pop-isn%E2%80%99t-picking-up-the-changes-i%E2%80%99ve-made-to-my-page" target="_blank" class="main-color has-text-weight-bold">"POP isn't picking up my changes"</a> on what you can do to get around this issue.
              </div>
            </div>
          </div>
          <div class="control flex mt-10">
            <div class="flex-grow-1"></div>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whiteColor--text" @click="okAction">
              Continue run &nbsp;
              <i class="material-icons">arrow_forward</i>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="closeModalAction">
        <i class="material-icons">close</i>
      </button>
    </div>
</div>
</template>


<script>

export default {
    data () {
        return {
            showModal: false
        }
    },
    methods: {
        show () {
            this.showModal = true
        },
        okAction () {
            this.$emit('okAction');
            this.showModal = false
        },
        closeModalAction () {
          this.showModal = false;
          this.cancelAction();
        }
    }
}
</script>

<style scoped lang="scss">
.warning-title {
  font-size: 20px;
}
.might-not-pick {
  font-size: 20px;
  text-decoration: underline;
  font-weight: 700;
}
.explain {
  & > div {
    font-size: 17px;
    line-height: 20px;
    margin-top: 15px;;
  }
  a {
    font-size: 17px;
    font-weight: 700px;
  }

}

div {
  color: var(--v-mainColor-base);
}
</style>