<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="columns is-desktop">
            <div class="column is-2">
              <div class="field">
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="perPage" @change="onPerPageChange">
                      <option value="10">10 per page</option>
                      <option value="25">25 per page</option>
                      <option value="50">50 per page</option>
                      <option value="100">100 per page</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <div class="control">
                  <input type="text" class="input" placeholder="Search" v-model="searchText" @input="changeSearchText">
                </div>
              </div>
            </div>
            <div class="column is-2">
              <button class="button is-info is-fullwidth" @click="onFilter">Search</button>
            </div>
            <div class="column is-2">
              <button @click="exportPage" class="button is-link is-fullwidth">Export Page</button>
            </div>
            <div class="column is-2">
                <a class="button is-link is-fullwidth" download :href="downloadAllUrl">Export All</a>
            </div>
          </div>
          
          <b-table
            :data="records"
            :loading="isLoading"
            paginated
            backend-pagination
            :total="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"

            detail-key="runId"
            @details-open="(row, index) => showDetailsForPageRun(row.pageRun)">
            <template slot-scope="props">
              <b-table-column field="originReportId" label="Origin report ID" numeric>{{ props.row.page.guardRunId || 'No' }}</b-table-column>
              <b-table-column field="runId" label="Run ID" sortable numeric>{{ props.row.runId }}</b-table-column>
              <b-table-column field="createdAt" label="Created On" sortable>{{ toLocalDateString(props.row.createdAt) }}</b-table-column>
              <b-table-column field="email" label="User Email" sortable>{{ props.row.email }}</b-table-column>
              <b-table-column field="keyword" label="Keyword" sortable>{{ props.row.keyword }}</b-table-column>
              <b-table-column field="status" label="Status" sortable>{{ props.row.status }}</b-table-column>
              <b-table-column field="score" label="Score" numeric>{{ props.row.score | round(2) }}</b-table-column>
              <b-table-column field="guardDiffData" label="Watchdog Status" numeric>
                {{ watchdogStatus(props.row.pageRun) }}
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
  
    </div>
  </section>
  </template>
  
  <script>
  import { mixin } from '@/components/mixin'
  import { mixin as exportmixin } from '@/components/export_mixin'
  import { toLocalDateString } from '@/utils'
  
  export default {
    components: {  },
    mixins: [mixin, exportmixin],
    data () {
      return {
        records: [],
        totalRows: 0,
        isLoading: false,
        sortField: 'runId',
        sortOrder: 'desc',
        defaultSortOrder: 'asc',
        currentPage: 1,
        perPage: 25,
        isSimple: false,
        isRounded: false,
        searchText: '',
        proxies: [],
        downloadAllUrl: `${API_URL}/download/?file=/home/hvseo/web/highvoltageseo.com/pageoptimizer/webapp/web/Report/watchdog_allhistory.txt`
      }
    },
    computed: {
      currentUser () {
        if (this.$store.state.user) {
          return this.$store.state.user
        }
        return {}
      }
    },
    methods: {
      showDetailsForPageRun (pageRun) {
        // console.log('detail', pageRun)
        this.$store.commit('setCurrentPageRun', pageRun)
      },
      getBaseUrl (fullUrl) {
        let pathArray = fullUrl.split( '/')
        let protocol = pathArray[0]
        let host = pathArray[2]
        let url = protocol + '//' + host
        return url
      },
      exportPage () {
        let data = this.records.map(record => `${record.runId},"${this.toLocalDateString(record.createdAt)}",${record.email},${record.keyword},${record.status},${record.url},${record.score},${this.watchdogStatus(record.pageRun)}`)
        data = ['Run ID,Created At, User Email,Keyword,Status,Target Url,Score,Watchdog Status'].concat(data).join('\n')
        const now = new Date();
        const fileName = "WatchdogHistory_" + now.getUTCFullYear() + "_" + (now.getUTCMonth()+1) + "_"+ now.getUTCDate() + ".csv"
        this.exportToCSV(data, fileName)
      },      
      mainPageWordCount (pageRun) {
        const wordCount = pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return wordCount.signalData.targetKeywordCnt
      },
      watchdogStatus (pageRun) {
        if (pageRun.status != 'SUCCESS') {
          return ''
        }
        if ( pageRun.guardDiffData && pageRun.guardDiffData.new_tasks_pending ) {
          return "Tasks Pending"
        } else {
          return "All Good"
        }
      },
      toLocalDateString (dateStr) {
        return toLocalDateString(dateStr)
      },
      onPerPageChange () {
        this.$nextTick(() => this.loadData())
      },
      onPageChange (page) {
        this.currentPage = page
        this.loadData()
      },
      onSort (field, order) {
        this.sortField = field
        this.sortOrder = order
        this.loadData()
      },
      onFilter () {
        // console.log('filtering')
        this.$nextTick(() => {
          this.loadData()
        })
      },
      loadData () {
        this.isLoading = true
        this.$store.commit('showLoading')
        const params = [
          `onlyWatchdogRuns=1`,
          `sortField=${this.sortField}`,
          `sortOrder=${this.sortOrder}`,
          `page=${this.currentPage}`,
          `perPage=${this.perPage}`,
          `filterText=${this.searchText}`
        ].join('&')
        this.$store.dispatch('loadHistoryForAdmin', { params })
          .then(({ data }) => {
            const { total, records } = data
            this.totalRows = total
            this.records = records
            this.isLoading = false
            this.$store.commit('hideLoading')
          })
      },
      changeSearchText () {
        console.log(this.searchText);
      }
    },
    beforeMount () {
      // this.loadData()
    }
  }
  </script>
  
  <style lang="scss">
  .table-mobile-sort {
    display: none;
  }
  </style>
  