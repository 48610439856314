<template>
<div class="mt-5">
  <no-stripe-card-modal ref="noStripeCardModalRef"></no-stripe-card-modal>
  <div class="font-weight-bold">
    Additional seat purchase
  </div>
  <div class="mt-3">
    Here you can add more seats to your team. If you need to add more than 100 additional seats, please contact us.
    <a class="link15Color--text text-decoration-underline font-weight-bold"
      @click="showAdditionalSubscriptionsModal = true"
      >Manage seats here.
    </a>
  </div>
  
  <div class="mt-9 d-flex align-center flex-gap-20">
    <div>How many seats?</div>
    <div class="seat-count-component">
      <div>
        <i class="material-icons" @click="() => {
          if (numberOfSeatsToPurchase > 0) {
            numberOfSeatsToPurchase -= 1
          }
        }">remove</i>
      </div>
      <div>
        <input type="text" v-model.number="numberOfSeatsToPurchase" />
      </div>
      <div>
        <i class="material-icons" @click="() => {
          if (numberOfSeatsToPurchase < 100) {
            numberOfSeatsToPurchase += 1
          }
        }">add</i>
      </div>
    </div>
    <div v-if="!showCouponInputBox" class="apply-coupon-button"
      @click="() => { showCouponInputBox = true }">
      <i class="material-icons">add</i>
      <span>Apply Coupon</span>
    </div>
    <div v-else class="coupon-input-box-custom">
      <input type="text" placeholder="Enter coupon code" v-model="promoCode">
      <button @click="fetchValidatedCoupons">
        Apply
      </button>
    </div>
  </div>
  <div>
    <p v-if="serviceCouponsValid" class="help has-text-success">{{ serviceCouponsMessage }}</p>
    <p v-if="!serviceCouponsValid" class="help has-text-danger">{{ serviceCouponsMessage }}</p>
  </div>
  <div class="mt-9 total-due-box d-flex align-center">
    <div class="flex-grow-1">
      Total Due for <b>{{ numberOfSeatsToPurchase }} additional</b> {{ numberOfSeatsToPurchase > 1 ? 'seats' : 'seat' }}
    </div>
    <div class="total-price">
      $ {{ totalPrice > 0 ? totalPrice.toFixed(2) : '' }}
    </div>
  </div>
  <div class="mt-9">
    <div class="d-flex gap10">
      <v-btn rounded
      v-if="totalPrice > 0"
        color="profileAnchorColor"
        class="text-none whiteColor--text"
        id="stripe-purchase-button" @click="submit" :disabled="!seatPkgName">
        <span class="icon">
          <i class="fa fa-credit-card-alt"></i>
        </span>
        <span>
          {{ payViaInvoice ? 'Send invoice' : 'Pay with credit card' }}
        </span>
      </v-btn>
      <div v-if="isYearlyExtraPakcageSelected">
        <v-checkbox dense
          color="mainColor"
          v-model="payViaInvoice"
          hide-details="auto"
          label="I want to pay via invoice">
        </v-checkbox>
      </div>
      <!-- <div class="control">
        <button
          v-if="totalPrice > 0"
          class="button is-warning" id="paypal-purchase-button" @click="submitForPayPal" :disabled="!seatPkgName">
          <span class="icon">
            <i class="fa fa-cc-paypal"></i>
          </span>
          <span>PayPal</span>
        </button>
      </div> -->
    </div>
  </div>
  <div class="mt-5 gray2Color--text">Contact us if you need more than 100 additional seats</div>



  <div class="modal" :class="{'is-active': showAdditionalSubscriptionsModal}">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 80vw;">
      <div class="box modal-container" style="padding: 0;">
        <div class="modal-content-root">
          <div class="title-font is-size-4">Active extra seats subscriptions</div>
          <div class="mt-5">
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>Additional Seats</th>
                  <th>Payment Method</th>
                  <th>Created At</th>
                  <th>Coupon applied</th>
                  <th>Cancelled On</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="purchase in seatPurchases" :key="purchase.id">
                  <td>{{ purchase.seatCounts }}</td>
                  <td>{{ purchase.paymentMethod }}</td>
                  <td>{{ purchase.createdAt ? toLocalDateString(purchase.createdAt) : '' }}</td>
                  <td>{{ purchase.couponCode }}</td>
                  <td>{{ purchase.cancelledOn ? toLocalDateString(purchase.cancelledOn) : '' }}</td>
                  <td>
                    <button class="button is-danger" :disabled="!!purchase.cancelledOn" @click="() => cancelSeatSubscription(purchase)">Cancel subscription</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="control d-flex mt-5">
            <div class="is-italic is-size-6">
              Each additional subscription will show as a separate charge from PageOptimizer Pro
            </div>
            <div class="flex-grow-1">
            </div>
            <v-btn rounded color="grayColor"
              class="text-none button-with-icon" @click="showAdditionalSubscriptionsModal=false">
              <i class="fa fa-times mr5"></i>
              Close
            </v-btn>
            <!-- <button class="button ready-button is-danger">
              <i class="fa fa-pencil-square-o mr5"></i>
              Update Email
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="modal" :class="{'is-active': showConfirmUseExistingPayment}">
    <div class="modal-background" @click.stop="onCancelNewPurchase"></div>
    <div class="modal-content" style="min-width: 50%;">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="title-font mainColor--text is-size-4">
            Payment Source Already On File
          </div>
          <div class="body-font is-size-6 has-text-grey is-italic mt-5">
            Looks like we already have a payment source on file for you ending in {{ preferredCard ? ( preferredCard.last4 || '' ) : '' }}
          </div>
          <div class="control flex mt-5">
            <div class="flex-grow-1"></div>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whtieColor--text" @click.stop="onConfirmUseExistingCard">Use Card On File</v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whtieColor--text" @click.stop="onUseDifferentCard">Enter Different Card</v-btn>
            <v-btn rounded
              color="grayColor"
              class="button-with-icon text-none white--text" @click.stop="onCancelNewPurchase">Cancel</v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- <button class="modal-close is-large" aria-label="close" @click.stop="onCancelNewPurchase"></button> -->
  </div>

  <div class="modal" :class="{'is-active': showContactUsForMoreSeatsModal}">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div class="mb20">
            <div class="title-font is-size-4 mt20">Contact Us</div>
            <div class="body-font is-size-6">Contact us if you need more than 100 additional seats</div>
          </div>
          <div class="control d-flex mt-5">
            <div class="flex-grow-1"></div>
            <button class="button ready-button" @click="showContactUsForMoreSeatsModal=false">
              I got it.
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showPurchaseCompleteModal}">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div v-if="isYearlyExtraPakcageSelected && payViaInvoice">
            <div class="title-font is-size-4 mainColor--text">Invoice sent</div>
            <div class="body-font is-size-6 mainColor--text mt-5">You will receive an invoice via email. Please ensure timely payment.</div>
          </div>
          <div v-else>
            <div class="title-font is-size-4 mainColor--text">Purchase Complete</div>
            <div class="body-font is-size-6 mainColor--text mt-5">Please click the button below to manage team members.</div>
          </div>
          
          <div class="d-flex justify-end mt-3">
            <div class="flex-grow-1"></div>
            <v-btn rounded
              v-if="!payViaInvoice"
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click.stop="gotoManageSubaccountsPage">
              <span>Manage Team members</span>
            </v-btn>
            <v-btn rounded
              color="grayColor"
              class="button-with-icon gray2Color--text text-none ml-3" @click="showPurchaseCompleteModal=false">
              <span>Close</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showErrorModal}">
    <div class="modal-background" @click.stop="showErrorModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title">Purchase Failed</p>
          <p class="subtitle">{{ errorMsg }}</p>
          <div class="field">
            <div class="control">
              <button class="button is-warning" @click.stop="showErrorModal = false;">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.stop="showErrorModal = false"></button>
  </div>
</div>
</template>

<script>
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { segmentTrack } from '@/utils'
import NoStripeCardModal from "@/components/NoStripeCardModal.vue";
import { toLocalDateString } from "@/utils";

const MAXIMUM_ALLOWED_SEATS_COUNT = 105

export default  {
  name: 'SeatManagement',
  mixins: [ServiceMixin],
  components: {
    NoStripeCardModal
  },
  data () {
    return {
      numberOfSeatsToPurchase: 1,
      showAdditionalSubscriptionsModal: false,
      showContactUsForMoreSeatsModal: false,
      showPurchaseCompleteModal: false,

      // following 4 is for check existing card modal
      confirmedUseExistingPayment: false,
      showConfirmUseExistingPayment: false,
      useDifferentCard: false,
      preferredCard: '',

      errorMsg: '',
      showErrorModal: false,
      successMsg: '',
      showCouponInputBox: false,

      
    }
  },
  watch: {
    user: {
      handler: function() {
        console.log('monitor change for user...')
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    activeSubscription() {
      return this.user.purchases.find(
        p => p.id == this.user.activeSubscription
      );
    },
    isStripeUsedForMainSubscription () {
      if (this.activeSubscription && this.activeSubscription.paymentMethod && this.activeSubscription.paymentMethod.toLowerCase() == 'stripe') {
        return true;
      }
      return false;
    },
    seatPurchases() {
      return this.user.seatPurchases
    },
    seatsCountList () {
      let rlt = [];
      for (let i = 1; i <= 25; i++) {
        rlt.push(i);
      }
      return rlt;
    },
    seatPkgName () {
      return `${this.$store.getters.additionalSeatPkgName}`
    },
    totalPrice () {
      let pricePerSeat = this.purchaseAmt
      return pricePerSeat * this.numberOfSeatsToPurchase
    },
  },
  methods: {
    toLocalDateString(date) {
      return toLocalDateString(date);
    },
    hideConfirmUseExistingCardDialog () {
      this.showConfirmUseExistingPayment = false
    },
    onConfirmUseExistingCard () {
      this.confirmedUseExistingPayment = true
      this.hideConfirmUseExistingCardDialog()
      this.submit()
    },
    onUseDifferentCard () {
      this.useDifferentCard = true
      this.hideConfirmUseExistingCardDialog()
      this.submit()
    },
    onCancelNewPurchase () {
      this.confirmedUseExistingPayment = false
      this.useDifferentCard = false
      this.hideConfirmUseExistingCardDialog()
    },

    gotoManageSubaccountsPage () {
      this.$router.push(`/useraccount/${this.user.id}?mainTab=sub-accounts&subTab=manage-sub-accounts`)
    },




    submit () {
      if (this.numberOfSeatsToPurchase < 0) {
        return;
      }

      if (this.numberOfSeatsToPurchase > MAXIMUM_ALLOWED_SEATS_COUNT - this.user.maximumSubAccountsCount) {
        this.showContactUsForMoreSeatsModal = true;
        return;
      }


      let promise;

      const order = {
        description: this.serviceDescription,
        servicePackage: this.selectedServicePkg,
        amount: this.totalPrice
      }
      if (this.user.hasPaymentSource && !this.useDifferentCard) {
        // if (!this.confirmedUseExistingPayment) {
        //   this.showConfirmUseExistingPayment = true
        //   return;
        // }
        this.$store.commit('showLoading')
        promise = this.$store.dispatch('createPurchase', {
          userId: this.user.id,
          servicePackage: this.selectedServicePkg,
          stripeToken: '',
          paymentService: 'stripe',
          description: order.description,
          promoCode: this.promoCode.trim(),
          seatCounts: this.numberOfSeatsToPurchase,
          payViaInvoice: this.isYearlyExtraPakcageSelected ? this.payViaInvoice : false,
        })
      } else {
        this.$refs.noStripeCardModalRef.showModalAction();
        return;
        // promise = doStripeCheckout(this.user.email, order)
        //   .then((response) => {
        //     if (response.data && response.data.status === 'CLOSED') {
        //       return new Promise((resolve, reject) => {
        //         resolve(response)
        //       })
        //     } else {
        //       this.$store.commit('showLoading')
        //         return this.$store.dispatch('createPurchase', {
        //           userId: this.user.id,
        //           servicePackage: this.selectedServicePkg,
        //           stripeToken: response.stripeToken,
        //           paymentService: 'stripe',
        //           description: order.description,
        //           promoCode: this.promoCode.trim(),
        //           seatCounts: this.numberOfSeatsToPurchase,
        //         })
        //     }
        //   })        
      }
      promise.then((response) => {
        if (response.data.status === 'FAILURE') {
          if (response.data.clientSecret) {
            this.$notify({
              group: 'info', type: 'success', duration: 5000,
              text: 'Additional confirmation is needed. please wait a little.'
            })
            window.stripe.confirmCardPayment(response.data.clientSecret).then((result) => {
              if (result.error) {
                // Inform the customer that there was an error.
                this.errorMsg = result.error.message
                this.showErrorModal = true
              } else {
                this.$router.push('/sites');
              }
            })
          } else {
            this.errorMsg = response.data.msg
            this.showErrorModal = true
          }
        } else if (response.data.status === 'SUCCESS') {
          this.showPurchaseCompleteModal = true
          this.successMsg = response.data.msg
          this.$store.commit('setUser', response.data.user)
          segmentTrack('purchase', {
            paymentService: 'stripe',
            email: response.data.user.email,
            userID: response.data.user.id,
            servicePackage: this.selectedServicePkg,
            promoCode: this.promoCode.trim()
          })
        } else  if (response.data.status === 'CLOSED') {
          this.errorMsg = response.data.errorMsg
        }
        return 1
      })
      .catch((err) => {
        this.errorMsg = "Transaction denied"
        console.log('stripe error', err)
      })
      .finally(() => this.$store.commit('hideLoading'))

    },
    submitForPayPal () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('createPurchase', {
        userId: this.user.id,
        servicePackage: this.selectedServicePkg,
        paymentService: 'paypal',
        promoCode: this.promoCode.trim(),
        seatCounts: this.numberOfSeatsToPurchase,
      })
      .then((response) => {
        if (response.data.status === 'FAILURE') {
          this.errorMsg = response.data.msg
          this.showErrorModal = true
        } else {
          this.$store.commit('setUser', response.data.user)
          segmentTrack('purchase', {
            paymentService: 'paypal',
            email: response.data.user.email,
            userID: response.data.user.email.id,
            servicePackage: this.selectedServicePkg,
            promoCode: this.promoCode.trim()
          })
          window.open(response.data.approvalUrl, '_self')
        }
      })
      .catch((err) => {
        this.errorMsg = "Transaction denied"
        console.log('stripe error', err)
      })
      .finally(() => this.$store.commit('hideLoading'))
    },














    cancelSeatSubscription (p) {
      console.log('puchase info is', p);
      this.$store.commit('showLoading');
      this.$store.dispatch("cancelSubscription", {
        purchaseId: p.id,
      }).then(response => {
        this.showCancelSubscriptionModal = false;
        if (response.data && response.data.status === "SUCCESS") {
          this.$store.commit("setUser", response.data.user);
        } else {
          alert(
            "Unable to cancel at this time. If you have already cancelled your PayPal payment,  your POP subscription has been cancelled.\nYour dashboard will update in the next 24 hours. If you have not cancelled in PayPal and are still receiving this error message please contact support."
          );
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      });

    }
  },
  mounted () {
    setTimeout(() => {
      console.log('testsetset', this.seatPkgName);
      this.updateSelectedServicePkg(this.seatPkgName);
    }, 500);
    if (this.user.hasPaymentSource) {
      return this.$store.dispatch('fetchPaymentSources').then((response) => {
        if (response.data.status == 'SUCCESS') {
          const preferredCard = response.data.cards.find(card => card.preferred)
          this.preferredCard = preferredCard
        } else {
          console.log('error fetching cards')
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    }
  }
}
</script>

<style scoped lang="scss">
.seat-count-component {
  display: flex;
  border-radius: 12px;
  border: 1px solid var(grayColor-base);
  & > div {
    display: flex;
    align-items: center;
  }
  .material-icons {
    font-size: 1.3rem;
    cursor: pointer;
  }
  input {
    max-width: 50px;
    height: 35px;
    text-align: center;
    color: var(--v-blackColor-base);
    border: none;
    margin: 0;
    outline: none;
  }
  & > div:first-child {
    padding: 5px 8px;
    border-right: 1px solid var(grayColor-base);;
  }
  & > div:last-child {
    padding: 5px 8px;
    border-left: 1px solid var(grayColor-base);;
  }
}
.apply-coupon-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--v-profileAnchorColor-base);
  span {
    text-decoration: underline;
    /* text-underline-offset: 4px; */
    font-weight: 700;
  }
}
.coupon-input-box-custom {
  border-radius: 12px;
  display: flex;
  align-items: center;
  border: 1px solid var(grayColor-base);
  overflow: hidden;
  input {
    height: 35px;
    padding: 5px 5px 5px 10px;
    &:focus, &:active {
      outline: none;
      border: none;
    }
  }
  button {
    background: var(--v-profileAnchorColor-base);
    color: var(--v-whiteColor-base);
    height: 35px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 0.9rem;
    /* display: flex;
    align-items: center; */
    
  }
}

.total-due-box {
  padding: 15px 15px;
  border-radius: 5px;
  border: 0.5px solid var(--v-profileAnchorColor-base);
  background: var(--v-lightYellowColor-base);

  .total-price {
    font-size: 1.2rem;
    font-weight: 800;
  }
  
}
</style>