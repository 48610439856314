<template>
  <div class="start-page">
    <div class="left">
      <img src="/static/ai-writer-welcome.svg" class="welcome-ai-img" />
    </div>
    <div class="right">
      <div>
        <div class="welcome-title">Welcome to POP AI Writer</div>
        <div class="mt-10 click-to-start-explain">
          Creating report to generate AI optimized content.
        </div>
        <div class="bottom-part mt-10">
          <div>
            <i class="material-icons">sticky_note_2</i>
            <div class="explain">
              This is an express version of running POP reports to get you into the AI writer quickly to create your content.
              A POP Pro report will be created for you  in a new project or assigned to an existing project with the same domain.
              You can review the report, make changes to your setup, or rerun the report after the content goes live on your site.
            </div>
          </div>
          <div class="mt-7">
            <i class="material-icons">analytics</i>
            <div class="explain">
              POP AI Writer is trained to get your score to 70 though it will
              almost always be higher. We recommend getting your score to at
              least 80 to see SEO benefits.
            </div>
          </div>
          <div class="mt-7">
            <i class="material-icons">sticky_note_2</i>
            <div class="explain">
              It is best to think of AI content, from any tool, as a good first
              draft. Keep in mind that you may need to manually tweak the
              content a bit for tone, voice, and optimization.
            </div>
          </div>
        </div>
        <div class="mt-10" v-if="!taskErrorMessage">
          <v-progress-linear
            height="10"
            color="profileAnchorColor"
            :value="progress"
          ></v-progress-linear>
        </div>
        <div class="mt-5 flex items-center justify-center" style="align-items:center; font-weight:800">
          {{taskErrorMessage ? taskErrorMessage : "This process may take up to 5 minutes." }}
          <v-btn text v-if="taskErrorMessage" color="profileAnchorColor" class="home-btn ml-2" to="/">Go Home</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/page_analysis_mixin";
export default {
  mixins: [mixin],
  data() {
    return {
      name: "",
      targetUrl: "",
      targetLang: "",
      pageNotBuilt: true,
      submittingRun: false,
      createReportPop: false,
      existingReportPop: false,
      taskErrorMessage: "",
      siteId: "",
      submitCalled: false,


      pageRunId: null,
      progress: 1,
    };
  },
  mounted() {
    const data = this.$store.state.aiWriterData || {};
    if (["lang", "region", "keyword", "url"].some((key) => !data[key])) {
      this.$router.push("/sites");
    }
    this.siteId = this.$route.params.siteId;
    this.proxyLocation = data.region;
    this.keyword = data.keyword;
    this.targetUrl = data.url;
    this.targetLang = data.lang;
    this.name = data.keyword;

    if (!this.submitCalled && this.siteId) {
      this.submitCalled = true;
      this.submitReportGeneration()
    }
  },
  methods: {
    validForm() {
      return !!this.keyword || !!this.proxyLocation || !!this.targetUrl || !!this.targetLang || !!this.name
    },
    submitReportGeneration() {
      const siteId = this.$route.params.siteId;

      if (this.validForm()) {

        if (this.submittingRun) {
          return;
        }

        this.submittingRun = true;

        let data = {
          competitorsCount: 6,
          name: this.name,
          url: this.targetUrl,
          keyword: this.cleanText(this.keyword),
          pageNotBuilt: this.pageNotBuilt ? 1 : 0,
          secondaryKeywords: this.useSecondaryKeywords
          ? this.cleanedSecondaryKeywords
            : [],
          proxyLocation: this.proxyLocation,
          targetLang: this.targetLang,
          siteId,
          pageRunType: 1,
          lsaPhrases: this.runWithoutPhrases ? [] : this.lsaPhrases,
          removedLSITermsByUser: this.removedLSITermsByUser,
          runWithoutPhrases: this.runWithoutPhrases ? 1 : 0,
          switchedSiteProtocol: this.switchedSiteProtocol ? 1 : 0,
          gCompetitors: this.googleSearchResults,
          relatedSearches: this.relatedSearches,
          relatedQuestions: this.relatedQuestions,
        }
     

        try {
          this.$store.commit("updateLastReportCreationTime");
          this.$store
            .dispatch("createPageRunInBackend", data)
            .then((response) => {
              if (response.data.status === "FAILURE") {
                this.taskErrorMessage = response.data.msg;
                this.submittingRun = false;
                return 1;
              } else if (response.data.status === "UNAVAILABLE_DOMAIN") {
                this.submittingRun = false;
                this.$refs.trialConversion.show();
                return 1;
              } else if(response.data.status === "PENDING" || response.data.status == 'SUCCESS') {

                 const pageRunId = response.data.pageRun.id
                 const page = response.data.page
                 let intervalTimer = setInterval(() => {
                  this.$store.dispatch('fetchPageRun', {
                    siteId: page.siteId,
                    pageId: page.id,
                    pageRunId: pageRunId
                  })
                  .then((response) => {
                    if (response.data && response.data.status === 'SUCCESS') {
                      let pRun = response.data.pageRun
                      // this.$store.commit('setCurrentPageRun', pRun)
                      // this.$store.commit('updatePageRun', pRun)

                      if (pRun.status != 'PENDING' && pRun.status != 'PROCESSING') {
                        clearInterval(intervalTimer)
                      } else {
                        this.progress = pRun.progress > 1 ? pRun.progress : 1 // to make it 1 at least for user
                      }
                      if (pRun.status === 'SUCCESS') {
                        this.$router.replace(
                            `/content-editor?pageRunId=${pageRunId}&editorMode=AI_MODE`
                          );
                      } else if (pRun.status === 'FAILURE') {
                        this.taskErrorMessage = "PageRun failed."
                      }
                    }
                  })        
                }, 5000);

              } else {
                const pageRunId = response.data.pageRun.id;
                return this.$store.dispatch("loadUser").then((response) => {
                  this.$store.commit("setUser", response.data.user);
                  return this.$router.replace(
                    `/content-editor?pageRunId=${pageRunId}&editorMode=AI_MODE`
                  );
                });
              }
            })
            .catch((err) => {
              this.submittingRun = false;
              console.log("Error making page ", err);
              this.taskErrorMessage =
                "Error submitting analysis. Please check validation messages. If error persists contact support.";
            });
        } catch (e) {
          console.log("Error creating page");
          console.log(e);
          this.submittingRun = false;
          this.taskErrorMessage =
            "Error submitting analysis. Please check validation messages. If error persists contact support.";
        }
      } else {
        console.log(
          "this is not valid form so I can not make this to be happen"
        );
      }
    },
  }
};
</script>

<style lang="scss">
  .home-btn {
    font-size: 23px;
    font-family: Mulish;
    font-weight: 800;
  }
</style>