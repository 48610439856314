<template>
<section class="section main-section" v-if="ready">
  <div class="container is-fluid">

    <div v-if="user.isAdmin">
      <div class="columns">
        <div class="column"><h2 class='title'>Sales Total ${{ totalSales | round(2) }} (Yesterday ${{ yesterdaySales | round(2) }} vs Month Ago ${{ monthAgoSales | round(2) }}, {{ ((yesterdaySales - monthAgoSales) / monthAgoSales * 100) | round(1) }}%)</h2></div>
      </div>
      <div class="columns">

        <div class="column is-4">
          <div class="field">
            <div class="control">
              <datepicker v-model="salesStartDate" @selected="onSalesDaysChange" input-class="input" placeholder="Start Date"></datepicker>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <datepicker v-model="salesEndDate" @selected="onSalesDaysChange" input-class="input" placeholder="End Date"></datepicker>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="control">
            <button class="button is-primary" @click="exportAllSales">Download All Sales</button>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-two-thirds">
          <multi-time-series ref="salesChart" :labels='salesDates' :datasets='sales'/>
        </div>

        <div class="column">
          <pie-chart ref="salesPieChart" :labels="['Credits', 'Starter', 'Basic', 'Premium', 'Unlimited']" :values="[creditSales, productATotal, productBTotal, productCTotal, productDTotal]"></pie-chart>
        </div>
      </div>
    </div>

    <div>
      <div class="columns">
        <div class="column"><h2 class='title'>Affiliate Sales Total ${{ affiliateSales | round(2) }} (Yesterday ${{ yesterdayAffSales | round(2) }} vs Month Ago ${{ monthAgoAffSales | round(2) }}, {{ ((yesterdayAffSales - monthAgoAffSales) / monthAgoAffSales * 100) | round(1) }}%)</h2></div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <datepicker v-model="affSalesStartDate" @selected="onAffSalesDaysChange" input-class="input" placeholder="Start Date"></datepicker>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <datepicker v-model="affSalesEndDate" @selected="onAffSalesDaysChange" input-class="input" placeholder="End Date"></datepicker>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="control">
            <button class="button is-primary" @click="exportAffiliateSales">Download Affiliate Sales</button>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-two-thirds">
          <multi-time-series ref="affiliateSalesChart" :labels='affiliateSalesByDay.labels' :datasets='[affiliateSalesByDay.totalAffiliateSales, affiliateSalesByDay.affiliateCommissions]'/>
        </div>
        <div class="column">
          <pie-chart ref="affiliateSalesPieChart" :labels="['Affiliate Sales', 'Affiliate Commissions']" :values="[affiliateSales, affiliateCommissions]"></pie-chart>
        </div>
      </div>
    </div>

  </div>
</section>
</template>

<script>
import TimeSeries from '@/components/Admin/TimeSeries.vue'
import MultiTimeSeries from '@/components/MultiTimeSeries.vue'
import PieChart from '@/components/Admin/PieChart.vue'
import SimpleBarChart from '@/components/SimpleBarChart.vue'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { mixin as BaseMixin } from '@/components/mixin'
import Datepicker from 'vuejs-datepicker'
import { toLocalDateString, toLocalDate } from '@/utils'
export default {
  mixins: [ServiceMixin, BaseMixin],
  components: { TimeSeries, PieChart, SimpleBarChart, MultiTimeSeries, Datepicker },
  data () {
    return {
      ready: false,

      // sales data by day
      salesStartDate: null,
      salesEndDate: null,

      affSalesStartDate: null,
      affSalesEndDate: null,

      yesterdaySales: 0,
      monthAgoSales: 0,
      yesterdayAffSales: 0,
      monthAgoAffSales: 0,
      salesDates: [],
      sales: [],
      creditSales: 0,
      subscriptionSales: 0,
      totalSales: 0,
      productATotal: 0,
      productBTotal: 0,
      productCTotal: 0,
      productDTotal: 0,
      detailedAffiliateSales: {},
      affiliateSales: 0,
      affiliateCommissions: 0,
      affiliateSalesByDay: [],

      chartColors: {
        blue: "rgb(54, 162, 235)",
        green: "rgb(75, 192, 192)",
        grey: "rgb(201, 203, 207)",
        orange: "rgb(255, 159, 64)",
        purple: "rgb(153, 102, 255)",
        red: "rgb(255, 99, 132)",
        yellow: "rgb(255, 205, 86)",
        black: "rgb(0, 0, 0)"
      }
    }
  },
  computed: {
    days () {
      let options = [1, 7, 14, 30, 60, 90, 'all']
      return options
    },
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    exportAllSales () {
      this.$store.commit('showLoading')
      this.$store.dispatch('downloadAllSales')
      .then(response => {
        console.log(response.data)
        if (response.data.status === 'SUCCESS') {
          let downloadAllUserUrl = `${API_URL}/download/?file=${response.data.url}`
          let fileName = downloadAllUserUrl.split('/').pop()

          let downloadLink;
          downloadLink = document.createElement("a");
          downloadLink.download = fileName;
          downloadLink.href = downloadAllUserUrl
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    exportAffiliateSales () {
      let csvData = ['Sale Date,Affiliate Email,Customer Email,Sale Amount,Affiliate Commission']
      this.detailedAffiliateSales.forEach((day) => {
        const date = day.date
        day.sales.forEach((sale) => {
          csvData.push(`${date},${sale.affiliateEmail},${sale.customerEmail},${sale.saleAmount},${sale.affiliatePortion}`)
        })
      })
      csvData = csvData.join('\n')
      let downloadLink;
      const now = new Date()
      const filename = "Affiliate_Sales_" + now.getUTCFullYear() + "_" + (now.getUTCMonth()+1) + "_"+ now.getUTCDate() + ".csv"
      const csvFile = new Blob([csvData], {type: "text/csv"});
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    toLocalDateString (dateStr) {
      return toLocalDateString(dateStr)
    },
    onAffSalesDaysChange (event) {
      this.$store.commit('showLoading')
      this.$nextTick(() => {
        this.getAffSalesData()
          .then(() => {
            this.$refs.affiliateSalesChart.update(this.affiliateSalesByDay.labels, [this.affiliateSalesByDay.totalAffiliateSales, this.affiliateSalesByDay.affiliateCommissions])
            this.$refs.affiliateSalesPieChart.update(['Affiliate Sales', 'Affiliate Commissions'], [this.affiliateSales, this.affiliateCommissions])
            return 1
          }).finally(() => this.$store.commit('hideLoading'))
      })
    },
    onSalesDaysChange (event) {
      this.$store.commit('showLoading')
      this.$nextTick(() => {
        this.getSalesData()
          .then(() => {
            this.$refs.salesChart.update(this.salesDates, this.sales)
            this.$refs.salesPieChart.update(['Credits', 'Starter', 'Basic', 'Premium', 'Unlimited'], [this.creditSales, this.productATotal, this.productBTotal, this.productCTotal, this.productDTotal])
            return 1
          }).finally(() => this.$store.commit('hideLoading'))
      })
    },
    getAffSalesData () {
      return this.$store.dispatch('fetchSalesData', {
          startDate: this.affSalesStartDate ? this.affSalesStartDate.valueOf() : '',
          endDate: this.affSalesEndDate ? this.affSalesEndDate.valueOf() : ''
        })
        .then((response) => {
          const { affiliateSales } = response.data
          this.detailedAffiliateSales = affiliateSales

          let affiliateTotalSales = 0
          let affiliateCommissions = 0
          this.affiliateSalesByDay = affiliateSales.reduce((data, day) => {
            data.labels.push(day.date)
            data.totalAffiliateSales.data.push(day.affiliateTotalSales)
            data.affiliateCommissions.data.push(day.affiliateTotalCommissions)
            affiliateTotalSales += day.affiliateTotalSales
            affiliateCommissions += day.affiliateTotalCommissions
            return data
          }, {
            labels: [],
            totalAffiliateSales: {
                label: 'Total Affiliate Sales',
                backgroundColor: this.chartColors.blue,
                borderColor: this.chartColors.blue,
                data: [],
                fill: false
              },
              affiliateCommissions: {
                label: 'Affiliate Sales Commission',
                backgroundColor: this.chartColors.red,
                borderColor: this.chartColors.red,
                data: [],
                fill: false
            }
          })
          this.affiliateSales = affiliateTotalSales
          this.affiliateCommissions = affiliateCommissions

          return 1
        })
    },
    getSalesData () {
      return this.$store.dispatch('fetchSalesData', {
          startDate: this.salesStartDate ? this.salesStartDate.valueOf() : '',
          endDate: this.salesEndDate ? this.salesEndDate.valueOf() : ''
        })
        .then((response) => {
          const { creditsTotal, totalSales, sales, productATotal, productBTotal, productCTotal, productDTotal, yesterdaySales, monthAgoSales, yesterdayAffSales, monthAgoAffSales } = response.data
          this.yesterdaySales = yesterdaySales
          this.monthAgoSales = monthAgoSales
          this.yesterdayAffSales = yesterdayAffSales
          this.monthAgoAffSales = monthAgoAffSales
          this.creditSales = creditsTotal
          this.totalSales = totalSales
          this.productATotal = productATotal
          this.productBTotal = productBTotal
          this.productCTotal = productCTotal
          this.productDTotal = productDTotal
          const salesData = sales.reduce((data, day) => {
            data.labels.push(day.date)
            data.productASales.data.push(day.productA)
            data.productBSales.data.push(day.productB)
            data.productCSales.data.push(day.productC)
            data.productDSales.data.push(day.productD)
            data.creditsSales.data.push(day.credits)
            data.totalSales.data.push(day.productA + day.productB + day.productC + day.productD + day.credits)
            return data
          }, {
            labels: [],
            productASales: {
              label: 'Starter',
              backgroundColor: this.chartColors.blue,
              borderColor: this.chartColors.blue,
              fill: false,
              data: []
            },
            productBSales: {
              label: 'Basic',
              backgroundColor: this.chartColors.green,
              borderColor: this.chartColors.green,
              fill: false,
              data: []
            },
            productCSales: {
              label: 'Premium',
              backgroundColor: this.chartColors.orange,
              borderColor: this.chartColors.orange,
              fill: false,
              data: []
            },
            productDSales: {
              label: 'Unlimited',
              backgroundColor: this.chartColors.purple,
              borderColor: this.chartColors.purple,
              fill: false,
              data: []
            },
            creditsSales: {
              label: 'Credits',
              backgroundColor: this.chartColors.red,
              borderColor: this.chartColors.red,
              fill: false,
              data: []
            },
            totalSales: {
              label: 'Total',
              backgroundColor: this.chartColors.black,
              borderColor: this.chartColors.black,
              fill: false,
              data: []
            },
            affilateSales: {
              label: 'Affiliate Sales',
              backgroundColor: this.chartColors.blue,
              borderColor: this.chartColors.blue,
              fill: false,
              data: []
            }
          })
          this.sales = [salesData.productASales, salesData.productBSales, salesData.productCSales, salesData.productDSales, salesData.creditsSales, salesData.totalSales]
          this.salesDates = salesData.labels
          return 1
        })
    }
  },
  beforeMount () {
    this.$store.commit('showLoading')
    let now = new Date()
    this.salesStartDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), 1)
    this.affSalesStartDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), 1)
    this.getAffSalesData() // don't make this wait until getSalesData completes
    return this.getSalesData().then(() => {
        this.ready = true
        this.$store.commit('hideLoading')
      })
      .catch((err) => {
        this.$store.commit('hideLoading')
        console.log(err)
      })
  }
}
</script>

<style scoped>

</style>