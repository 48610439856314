<template>
  <div class="modal" :class="{'is-active': showNow}">
      <div class="modal-background"></div>
      <div class="modal-content" style="width: 60vw;">
        <div class="modal-content">
          <div class="box modal-container">
            <div class="modal-content-root">
              <div class="mb20">
                <div>
                  <div class="title-font is-size-4">Terms And Conditions</div>
                  <div class="is-size-6 mt20">
                    As an IMG advocate, you are rewarded with 30 days of free access to the PageOptimizer Pro Unlimited plan for each new IMG member you refer. <br /><br />
                    As a PageOptimizer Pro (or POP) Unlimited user you get unlimited POP report runs and re-runs, as well as 5 POP credits that you can spend on POP AI Writer (an AI writing assistant that produces pre-optimized pages), POP Watchdog (the new SERP monitoring feature), POP E-E-A-T, Google NLP, plus other premium POP features.
                  </div>
                </div>
                <div class="is-size-6 mt30">How to claim your reward:</div>
                <div class="best-practice-list mt10">
                  <ol>
                    <li>You should have received a coupon code. This needs to be activated on the POP registration page.</li>
                    <!-- <li>You are required to add credit card details for verification and security. You will NOT be charged unless you choose to upgrade or downgrade your plan at the end of the 30-day period.</li> -->
                    <li>If you successfully refer more than 1 person to IMG, you can add additional coupon codes to your POP account to claim additional 30 days of POP Unlimited usage. The coupon can be added after the previous 30 days have expired.</li>
                    <li>If you choose to upgrade or downgrade your plan at the end of the 30-day period, you can do it inside your POP account.</li>
                    <li>Your coupon code is unique. It can only be used once and cannot be shared with a third party.</li>
                  </ol>
                </div>
              </div>
              <div class="control flex mt20">
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox" v-model='acknowledged'>
                      I acknowledge that I have read the above
                    </label>
                  </div>
                </div>
                <div class="field">
                </div>

                <div class="flex-grow-1">
                </div>
                <button :disabled="!acknowledged" class="button ready-button" @click.stop="submit">
                  <i class="fa fa-book mr5"></i>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      acknowledged: false,
      showNow: false
    }
  },
  methods: {
    submit () {
      if (!this.acknowledged) {  return; }
      const settings = Object.assign({}, this.user.settings)
      settings['acknowlegedForPeriodUser'] = new Date().valueOf()
      this.$store.dispatch('updateUserSettings', settings)
        .then((response) => this.$store.commit('setUserSettings', response.data))
        .finally(() => this.showNow = false)
    },
    show () {
      this.showNow = true
    },
    checkAutoDisplay () {
      this.showNow = this.showPageRunBestPractices
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    showPageRunBestPractices () {
      if (!this.user) {
        return true
      } else if (!this.user.settings) {
        return true
      }
      if (this.user.settings.showAnalysisBestPractices == null) {
        return true
      }
      return this.user.settings.showAnalysisBestPractices
    }
  },
  mounted () {
    // this.showNow = this.showPageRunBestPractices
  }
}
</script>

<style lang="scss" scoped>
  .h-padding {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .best-practice-list {
    max-height: 60vh;
    overflow-y: auto;
    list-style-position: inside;
    border: 1px solid #DDD;
    border-radius: 5px;
    ol {
      padding-left: 0 !important;
      li {
        padding: 1.1rem;
      }

      li:nth-child(odd) {
        background: rgb(255, 243, 230);
        /* background: #EEE; */
      }
    }
  }
</style>