<template>
  <div class="header-area">
    <div class="new-header">
      <div class="header-left-side">
        <component :is="$store.getters.isDemoUser ? 'span' : 'router-link'" to="/sites"
          :class="$store.getters.isDemoUser ? 'not-allowed-cursor' : 'watchdog-dashboard-link'">
          All projects
        </component>
        <component :is="$store.getters.isDemoUser ? 'span' : 'router-link'" to="/guard-dashboard"
          :class="$store.getters.isDemoUser ? 'not-allowed-cursor' : 'watchdog-dashboard-link'">
          Watchdog dashboard
        </component>
        <component v-if="isAvailableBulkRun" :is="$store.getters.isDemoUser ? 'span' : 'router-link'" to="/new-bulk-run"
          :class="$store.getters.isDemoUser ? 'not-allowed-cursor' : 'watchdog-dashboard-link'">
          Bulk runs
        </component>
      </div>
      <div class="buttons-area">
        <div class="action-buttons-area">
          <v-btn rounded small outlined color="mainColor" class="button-with-icon mainColor--text text-none"
            :disabled="$store.getters.isDemoUser" :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`">
            <i class="fa fa-money"></i>
            <span class="">Buy credits</span>
          </v-btn>
        </div>
        <div>
          <div class="dropdown is-right is-hoverable custom-dropdown-menu ml-3">
            <div class="dropdown-trigger">
              <a class="d-flex align-center" aria-haspopup="true" aria-controls="dropdown-menu">
                <div class="profile-logo">
                  <i class="material-icons person-icon">account_circle</i>
                </div>
                <div class="d-flex align-center mainColor--text">
                  <i class="material-icons">expand_more</i>
                </div>
              </a>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <div class="mainColor--text info-area flex align-center">
                  <span class="mr5">
                    <div class="profile-logo">
                      <i class="material-icons person-icon">account_circle</i>
                    </div>
                  </span>
                  <div class="flex-grow-1">
                    <div class="email-label">
                      {{ $store.getters.isDemoUser ? "Demo User" : user.email }}
                    </div>
                    <div
                      v-if="!$store.getters.isDemoUser"
                      class="font-weight-bold darkGrayColor--text">
                      <div
                        v-for="(text, idx) in accountBalanceLabels"
                        :key="idx">
                        {{ text }}
                      </div>
                    </div>
                    <div
                      class="flex font-weight-bold"
                      v-if="isUnlimitedUser && !$store.getters.isDemoUser">
                      <span class="darkGrayColor--text"> Reports limit: </span>
                      <span class="profileAnchorColor--text"
                        >&nbsp;Unlimited</span
                      >
                    </div>
                  </div>
                </div>

                <template v-if="!$store.getters.isDemoUser">
                  <router-link v-if="isAdmin" :to="`/admin/${user.id}`" class="dropdown-item">
                    <span class="material-icons-outlined">admin_panel_settings</span>
                    Admin
                  </router-link>

                  <router-link v-if="user && user.id" class="dropdown-item" :to="`/useraccount/${user.id}`">
                    <span class="material-icons-outlined">manage_accounts</span>
                    Account settings
                  </router-link>
                  <router-link
                    :to="isAvailableBulkRun ? `/new-bulk-run` : `/useraccount/${user.id}/?mainTab=billing-and-payments`"
                    class="dropdown-item">
                    <span class="material-icons-outlined">inventory_2</span>
                    Bulk projects
                    <span>
                      <img src="/static/upgrade.png" class="upgrade_img" v-if="!isAvailableBulkRun" />
                    </span>
                  </router-link>
                  <router-link to="/guard-dashboard" class="dropdown-item">
                    <span class="material-icons-outlined">gpp_good</span>
                    Watchdog dashboard
                  </router-link>
                  <router-link to="/ai-writer-log" class="dropdown-item">
                    <span class="material-icons-outlined">auto_fix_high</span>
                    AI articles dashboard
                  </router-link>
                  <router-link :to="`/useraccount/${user.id}?mainTab=credit-logs`" class="dropdown-item">
                    <span class="material-icons-outlined">savings</span>
                    Credit log
                  </router-link>
                  <router-link v-if="hasApiDashboardAccess" to="/api-usage-dashboard" class="dropdown-item">
                    <span class="material-icons-outlined">stacked_bar_chart</span>
                    API usage dashboard
                  </router-link>
                  <router-link v-if="hasOwnerAccounts" :to="`/useraccount/${user.id}?mainTab=switch-account`"
                    class="dropdown-item">
                    <span class="material-icons-outlined">sync_alt</span>
                    Switch account
                  </router-link>
                  <router-link class="dropdown-item" :to="`/admin/${user.id}`" v-if="isAffiliate">
                    <span><i class='material-icons'>currency_exchange</i></span>
                    Affiliate Dashboard
                  </router-link>
                  <a target="_blank" href="https://academy.pageoptimizer.pro/" class="dropdown-item">
                    <span class="material-icons-outlined">playlist_add_check_circle</span>
                    POP Academy
                  </a>
                  <a class="dropdown-item" target="_blank" :href="`https://help.pageoptimizer.pro/`">
                    <span class="material-icons-outlined">live_help</span>
                    Help centre
                  </a>
                  <a class="dropdown-item" target="_blank"
                    :href="`https://pageoptimizer.pro/pageoptimizer-pro-chrome-extension-guide/`">
                    <span class="material-icons-outlined">extension</span>
                    POP chrome extension
                  </a>
                  <hr class="dropdown-divider">
                  <div class="light-darm-menu" v-if="!$store.getters.isDemoUser">
                    <div class="mainColor--text">Theme</div>
                    <div class="light-dark-switch-area">
                      <div @click="() => setDarkMode(false)">
                        <div :class="{ 'is-active': !$vuetify.theme.dark }">
                          <img class="mode-img" src="/static/light-mode.png" />
                        </div>
                        <div class="mode-label">Light</div>
                      </div>
                      <div @click="() => setDarkMode(true)">
                        <div :class="{ 'is-active': $vuetify.theme.dark }">
                          <img class="mode-img" src="/static/dark-mode.png" />
                        </div>
                        <div class="mode-label">Dark</div>
                      </div>
                    </div>
                  </div>
                </template>
                <hr class="dropdown-divider">
                <a @click.stop="logout" class="dropdown-item signout-item">
                  <span>Sign out</span>
                  <i class='fa fa-sign-out'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <run-best-practices-model ref='bestPractices' />

      <insufficient-fund-modal ref="insufficientFundModal" />
    </div>
  </div>
</template>


<script>
import RunBestPracticesModel from '@/components/PageRun/RunBestPracticesModal.vue'
import InsufficientFundModal from '@/components/InsufficientFundModal.vue'
import DemoPlans from '@/components/DemoPlans.vue'
import { mixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { mixin as notificaitons_mixin } from '@/components/mixins/common_notifies';
import { DEMO_SESSION_KEYS } from "@/components/PageRun/constants";

export default {
  name: 'NewHeader',
  mixins: [mixin, ServiceMixin, notificaitons_mixin,],
  components: { DemoPlans, RunBestPracticesModel, InsufficientFundModal },
  data() {
    return {};
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    user() {
      return this.$store.state.user
    },
    ownerUser() {
      return this.$store.state.selectedSubAccountRelation || this.user
    },
    hasApiDashboardAccess () {
      return this.$store.getters.hasApiDashboardAccess
    },
    hasPOPApiKey () {
      return this.$store.getters.hasPOPApiKey
    },
    isWatchdogOnlyUser() {
      return this.$store.getters.isWatchdogOnlyUser
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    availableReportCreditsOfUserOrTeam() {
      return this.$store.getters.availableReportCreditsOfUserOrTeam
    },
    isAffiliate() {
      if (this.user) {
        return this.user.isAffiliate
      }
      return false
    },
    isAvailableBulkRun() {
      return (this.$store.state.selectedSubAccountRelation || this.isUnlimitedUser)
    },
    site() {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId))
    },
    page() {
      if (!this.site || !this.site.pages.length) {
        return {}
      }
      return this.site.pages.find((p) => p.id === parseInt(this.$route.params.pageId))
    },
    accountBalanceLabels() {
      if (!this.user) {
        return ['', '']
      }
      return this.getAccountBalanceLabel(this.ownerUser)
    },
  },
  methods: {
    gotoBulkProjectPage() {
      if (!this.isAgencyUnlimitedUser) { return }
      this.$router.push('/new-bulk-run')
    },
    logout() {
      Object.values(DEMO_SESSION_KEYS).forEach(key => sessionStorage.removeItem(key))
      this.$store.commit('clearStore')
      this.$router.push('/login')
    },
    setDarkMode (isDarkMode) {
      this.$vuetify.theme.dark = isDarkMode;
      let settings = Object.assign({}, this.user.settings)
      settings['isDarkMode'] = isDarkMode;
      this.$store.dispatch('updateUserSettings', settings)
      .then((response) => {
        this.$store.commit('setUserSettings', response.data)
      })
      .finally(() => {
      })
    },
  },
}
</script>
<style lang="scss">
.button-with-icon {

  i,
  span {
    font-weight: normal;
  }
}

.not-allowed-cursor {
  cursor: not-allowed;
  font-size: 1rem;
  color: var(--v-mainColor-base);
  font-weight: 700;
  cursor: pointer;
}

.header-area {
  position: relative;
  border-bottom: 1.5px solid var(--v-grayColor-base);
  background: var(--v-topHeaderBg-base);
}

.fold-icon {
  font-size: 1rem;
  color: var(--v-profileAnchorColor-base);
}

.open-sidebar-icon {
  font-size: 1.5rem;
  color: var(--v-mainColor-base);
  transform: rotate(-90deg);
  cursor: pointer;
}

.new-header {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  padding: 0px 20px 5px 20px;
  /* border-bottom: 1px solid white; */
  flex-wrap: wrap;

  .header-left-side {
    display: flex;
    align-items: center;
    gap: 20px;

    .watchdog-dashboard-link {
      font-size: 1rem;
      color: var(--v-mainColor-base);
      font-weight: 700;
      cursor: pointer;
    }
  }


  .buttons-area {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .action-buttons-area {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
    }

    .action-buttons-on-mobile {
      flex-grow: 1;
      display: none;
    }
  }


  .back-to-home {
    color: var(--v-mainColor-base)
  }

  .project-name {
    color: var(--v-mainColor-base)
  }

  .bulk-run-button {
    background: #fffaf2;
    border: 1px solid #ffebcc;
    color: black;
  }

  .custom-dropdown-menu {
    .dropdown-menu {
      width: 300px !important;

      .dropdown-content {
        border-radius: 10px !important;
          background: var(--v-cardBg-base);
          max-height: calc(100vh - 100px);
          overflow: auto;
      }
    }

    .has-sub-account {
      cursor: default;
      color: grey;

      &:hover {
        background: none;
      }

      /* &:hover + .sub-menu,
        & .sub-menu:hover {
          background: red;
          display: block;
        } */
    }

    .sub-menu {
      text-align: left;
      padding-left: 15px;
    }

    /* .dropdown-item, .dropdown .dropdown-menu .has-link a {
        font-size: 16px !important;
      } */
  }

  .dropdown-content {
    .email-label {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 6px;
      overflow-wrap: anywhere;
      color: var(--v-mainColor-base);
    }
  }

  .dropdown-item {
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
    font-size: 14px;

    img {
      width: 14px;
      height: 15px;
    }

    img.upgrade_img {
      margin-left: 1rem;
      width: 70px;
      height: 20px;
    }

    i,
    span {
      font-size: 1.2rem;
    }

    &> :first-child {
      width: 33px;
    }
  }

  .signout-item {
    display: flex;
    padding-left: 50px;
    padding-right: 25px;
    color: var(--v-profileAnchorColor-base);

    &>span:first-child {
      flex-grow: 1;
    }

    i {
      font-size: 1rem;
    }
  }

  .profile-logo {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;

    i {
      font-size: 2.8rem;
      color: #7895A7;
    }
  }

  .dropdown-menu .profile-logo {
    margin: 0 11px 0px 20px;
  }

  .info-area {
    padding: 10px 5px 10px 0px;
  }

  /* @media screen and (max-width: 600px) {
      .navbar-item {
        &.has-dropdown {
          .navbar-dropdown {
            display: none;
          }
          &.is-active {
            .navbar-dropdown {
              display: block;
            }
          }
        }
      }
    } */
}

@media screen and (max-width: 780px) {
  .new-header {
    display: grid;
    grid-template-columns: 1fr;

      .buttons-area {
        .action-buttons-area {
          display: none;
        }
        .action-buttons-on-mobile {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }    
  }
  .light-darm-menu {
    padding: 10px 10px 10px 20px; 
    .light-dark-switch-area {
      margin-top: 10px;
      display: flex;
      gap: 15px;
      & > div {
        cursor: pointer;
        position: relative;
        & > div {
          display: flex;

          & {
            border: 2px solid transparent;
          }
          &.is-active {
            border-radius: 5px;
            border: 2px solid var(--v-profileAnchorColor-base);
            &::before {
              position: absolute;
              display: inline-block;
              font-family: 'Material Icons';
              content: '\e86c';
              color: var(--v-green21Color-base);
              font-size: 1rem;
              top: 5px;
              right: 5px;
              
            }
          }
        }
        
        .mode-img {
          width: 80px;
          height: auto;
          
        }
        .mode-label {
          color: var(--v-mainColor-base);
        }
      }
    }
  }
</style>
