<template>
  <div>
    <div v-if="!defaultTabOfContentBrief" class="pagerun-tabs level3">
      <ul>
        <li :class="{ 'is-active': activeTab === 'report' }">
          <a @click="activeTab = 'report'">Report</a
          ><span class="divider"> | </span>
        </li>
        <li :class="{ 'is-active': activeTab === 'contentPrompt' }">
          <a @click="activeTab = 'contentPrompt'">Content prompts</a>
        </li>
        <!-- more work here -->
        <!-- <template v-if="selectedMainTab=='standard'">
      <li :class="{'is-active': activeTab === 'nlpSchemaType'}">
        <a @click="activeTab = 'nlpSchemaType'">Google Entities (Beta)</a><span class="divider"> | </span>
      </li>
      </template> -->
      </ul>
    </div>

    <template v-if="activeTab == 'report'">
      <report-embed
        v-if="showAsMobileView"
        :selectedMainTab="selectedMainTab"
        :createdAt="createdAt"
        :pageRun="pageRun"
        :url="url"
        :keyword="keyword"
        :secondaryKeywords="secondaryKeywords"
        :lsaPhrases="lsaPhrases"
        :pageRunId="pageRunId"
        :pageNotBuilt="pageNotBuilt"
        :page="page"
        :ignoreSpaces="ignoreSpaces"
        :willUseSecretKey="willUseSecretKey"
        :secretKey="secretKey"
        :runStrategies="runStrategies"
        :contentBrief="contentBrief"
        :tagTotalData="tagTotalData"
        :wordCountData="wordCountData"
        :sectionCount="sectionCount"
        :gCompetitors="gCompetitors"
        :relatedSearches="relatedSearches"
        :relatedQuestions="relatedQuestions"
        :contentBriefFile="contentBriefFile"
        :excludedTermsByUser="excludedTermsByUser"
        :isFromExtension="isFromExtension"
        :showAsMobileView="showAsMobileView"
        :isAIMode="isAIMode"
        :fakeScore="fakeScore"
        :isCalculating="isCalculating"
        @needChecklistUpdate="() => $emit('needChecklistUpdate')"
      />
      <report
        v-else
        :selectedMainTab="selectedMainTab"
        :createdAt="createdAt"
        :pageRun="pageRun"
        :url="url"
        :keyword="keyword"
        :secondaryKeywords="secondaryKeywords"
        :lsaPhrases="lsaPhrases"
        :pageRunId="pageRunId"
        :pageNotBuilt="pageNotBuilt"
        :page="page"
        :ignoreSpaces="ignoreSpaces"
        :willUseSecretKey="willUseSecretKey"
        :secretKey="secretKey"
        :runStrategies="runStrategies"
        :contentBrief="contentBrief"
        :tagTotalData="tagTotalData"
        :wordCountData="wordCountData"
        :sectionCount="sectionCount"
        :gCompetitors="gCompetitors"
        :relatedSearches="relatedSearches"
        :relatedQuestions="relatedQuestions"
        :contentBriefFile="contentBriefFile"
        :excludedTermsByUser="excludedTermsByUser"
        :isFromExtension="isFromExtension"
        :showAsMobileView="showAsMobileView"
        @actionForUpdateParent="() => $emit('actionForUpdateParent')"
      />
    </template>

    <template v-if="activeTab == 'contentPrompt'">
      <content-prompt-embed
        ref="contentPromptEmbedRef"
        v-if="showAsMobileView"
        :selectedMainTab="'advanced'"
        :createdAt="createdAt"
        :url="url"
        :keyword="keyword"
        :pageRunId="pageRunId"
        :pageNotBuilt="pageNotBuilt"
        :page="page"
        :ignoreSpaces="ignoreSpaces"
        :willUseSecretKey="willUseSecretKey"
        :secretKey="secretKey"
        :runStrategies="runStrategies"
        :contentBrief="contentBrief"
        :tagTotalData="tagTotalData"
        :wordCountData="wordCountData"
        :gCompetitors="gCompetitors"
        :relatedSearches="relatedSearches"
        :relatedQuestions="relatedQuestions"
        :contentBriefFile="contentBriefFile"
        :excludedTermsByUser="excludedTermsByUser"
        :competitors="competitors"
        :isFromExtension="isFromExtension"
        :showAsMobileView="showAsMobileView"
      />

      <content-prompt
        v-else
        :selectedMainTab="selectedMainTab"
        :createdAt="createdAt"
        :url="url"
        :keyword="keyword"
        :pageRunId="pageRunId"
        :pageNotBuilt="pageNotBuilt"
        :page="page"
        :ignoreSpaces="ignoreSpaces"
        :willUseSecretKey="willUseSecretKey"
        :secretKey="secretKey"
        :runStrategies="runStrategies"
        :contentBrief="contentBrief"
        :tagTotalData="tagTotalData"
        :wordCountData="wordCountData"
        :gCompetitors="gCompetitors"
        :relatedSearches="relatedSearches"
        :relatedQuestions="relatedQuestions"
        :contentBriefFile="contentBriefFile"
        :excludedTermsByUser="excludedTermsByUser"
        :competitors="competitors"
        :isFromExtension="isFromExtension"
        :showAsMobileView="showAsMobileView"
      />
    </template>
  </div>
</template>

<script>
import Report from "./Report.vue";
import ReportEmbed from "./ReportEmbed.vue";
import ContentPrompt from "./ContentPrompt.vue";
import ContentPromptEmbed from "./ContentPromptEmbed.vue";

export default {
  props: [
    "selectedMainTab",
    "createdAt",
    "pageRun",
    "url",
    "fakeScore",
    "keyword",
    "secondaryKeywords",
    "lsaPhrases",
    "pageRunId",
    "pageNotBuilt",
    "page",
    "ignoreSpaces",
    "willUseSecretKey",
    "secretKey",
    "contentBrief",
    "runStrategies",
    "tagTotalData",
    "wordCountData",
    "sectionCount",
    "gCompetitors",
    "relatedSearches",
    "relatedQuestions",
    "contentBriefFile",
    "isFromExtension",
    "showAsMobileView",
    "hideActionButtons",
    "excludedTermsByUser",
    "defaultTabOfContentBrief",
    "isAIMode",
    "isCalculating",
  ], // showAsMobileView : this will be true if this component will display inside extension modal or source editor modal

  components: {
    Report,
    ReportEmbed,
    ContentPrompt,
    ContentPromptEmbed,
  },
  watch: {
    defaultTabOfContentBrief: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.activeTab = newVal;
      }
    },
  },
  computed: {
    competitors() {
      if (this.pageRun) {
        return this.pageRun.competitors;
      }
      return [];
    },
  },
  data() {
    return {
      activeTab: "report", // 'report', 'contentPrompt'
    };
  },
  methods: {},
  mounted() {
    if (this.defaultTabOfContentBrief) {
      this.activeTab = this.defaultTabOfContentBrief;
    }
  },
};
</script>

<style lang="scss" scoped>
.active-tab {
  background: red;
}
</style>
