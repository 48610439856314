<template>
<div>
  <existing-content-view-header
    :options="availableOptions"
    :campaignId="campaignId"
    :siteId="siteId"
  />

  <router-view
    :campaignId="campaignId"
    :selectedScenario="selectedScenario"
    :hasUnlimitedAccess="hasUnlimitedAccess"
  ></router-view>
  
</div>
</template>

<script>
import { invert } from 'lodash'
import ExistingContentViewHeader from './ExistingContentViewHeader.vue';

const SITE_URL_EXIST = 'SITE_URL_EXIST'
const SITE_ONLY_EXIST = 'SITE_ONLY_EXIST'
const SUPPORTING_KEYWORD_RESEARCH = 'SUPPORTING_KEYWORD_RESEARCH'
const PAGE_OPPORTUNITY = 'PAGE_OPPORTUNITY'

const scenarioToRouteName = {
  'SITE_URL_EXIST' : 'site-url',
  'SITE_ONLY_EXIST' : 'site-only',
  'SUPPORTING_KEYWORD_RESEARCH' : 'supporting-keyword-research',
  'PAGE_OPPORTUNITY' : 'page-opportunity'
}

export default {
  props: ['hasUnlimitedAccess'],
  components: {
    ExistingContentViewHeader
  },
  computed: {
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
    siteId () {
      return parseInt(this.$route.params.siteId || 0)
    },
    campaign () {
      return this.$store.state.currentCampaign
    },
    currentScenarioRouteName () {
      let name = null
      const availableNames = Object.values(scenarioToRouteName)
      this.$route.matched.forEach((matchedRoute) => {
        if (availableNames.indexOf(matchedRoute.name) >= 0) {
          name = matchedRoute.name
        }
      })
      return name
    },
    selectedScenario () {
      const scenario = invert(scenarioToRouteName)[this.currentScenarioRouteName]
      if (this.availableOptions.length && this.availableOptions.indexOf(scenario) >= 0) {
        return scenario
      }
      return undefined
    },
    availableOptions () {
      if (this.campaign) {
        if (this.campaign.isDomainLevelCampaign) {
          return [PAGE_OPPORTUNITY, SITE_ONLY_EXIST, SUPPORTING_KEYWORD_RESEARCH]
        } else {
          return [SITE_URL_EXIST, SITE_ONLY_EXIST, SUPPORTING_KEYWORD_RESEARCH]
        }
      }
      return []
    },
  },
  watch: {
    availableOptions: function(newVal, oldVal) {
      if (newVal && newVal.length > 0 && !this.selectedScenario) {
        this.changeScenario(newVal[0])
      }
    },
  },
  methods: {
    changeScenario (scenario) {
      if (scenario !== this.selectedScenario && this.availableOptions.indexOf(scenario) >= 0) {
        const {siteId, campaignId} = this.$route.params
        console.log(`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/${scenarioToRouteName[scenario]}`)
        this.$router.push(`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/${scenarioToRouteName[scenario]}`)
      }
    },
  },
  mounted () {
    
  }
}
</script>

<style scoped lang="scss">
@import "../../keyword-research.scss";



</style>