<template>
<div>
  <section>
    <div class="hero is-fullheight white-background">
      <div class="hero-body">
        <div class="container is-fluid">
          <div class="columns is-centered">
            <div class="column">
              <div>
                <section class="columns">
                  <div class="column is-offset-3 is-6">

                    <h2 class="title has-text-centered" style="color: #494e8f;">REGISTER</h2>

                    <div class="columns">
                      <div class="column">
                            <!-- email -->
                        <div class="field">
                            <label class="label has-text-black">Email</label>
                            <div class="control has-icons-left">
                              <input type="email" placeholder="example@mail.com" class='input' v-model="email" required>
                              <span class="icon is-small is-left">
                                  <i class='fa fa-envelope'></i>
                              </span>
                            </div>
                            <p v-if="!validEmail" class="help has-text-danger">must be a valid email address</p>
                        </div>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <!-- password -->
                        <div class="field">
                            <label class="label has-text-black">Password</label>
                            <div class="control has-icons-left">
                              <input type="password" placeholder="********" class='input' v-model="password" required>
                              <span class="icon is-small is-left">
                                  <i class='fa fa-lock'></i>
                              </span>
                            </div>
                            <p v-if="!passwordMinLength" class="help has-text-danger">password must be at least 6 characters long</p>
                        </div>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <!-- password confirmation -->
                        <div class="field">
                            <label class="label has-text-black">Verify Password</label>
                            <div class="control has-icons-left">
                              <input type="password" placeholder="********" class='input' v-model="verifyPassword" required>
                              <span class="icon is-small is-left">
                                  <i class='fa fa-lock'></i>
                              </span>
                            </div>
                            <p v-if="!passwordsMatch" class="help has-text-danger">passwords must match</p>
                        </div>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <!-- Promo Code -->
                        <div class="field">
                            <label class="label has-text-black">Promo Code</label>
                            <div class="control has-icons-left">
                              <input type="text" placeholder="Example: abc123xy" class='input' v-model="promoCode">
                              <span class="icon is-small is-left">
                                  <i class='fa fa-money'></i>
                              </span>
                            </div>
                            <p v-if="serviceCouponsValid" class="help has-text-success">{{ serviceCouponsMessage }}</p>
                            <p v-if="!serviceCouponsValid" class="help has-text-danger">{{ serviceCouponsMessage }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <div class="credit-card-button-and-text">
                          <div class="control">
                            <button :disabled="!validForm" class="button is-primary" id="stripe-purchase-button" @click="register">
                              <span>Register</span>
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="modal" :class="{'is-active': showErrorModal}">
    <div class="modal-background" @click.stop="showErrorModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title">Registration Failure</p>
          <p class="subtitle" v-if="errorMsg">{{ errorMsg }}</p>
          <p v-if="!errorMsg">Please note that you will not be able to make any purchases until you log in.</p>
          <div class="field" style="padding: 20px;">
            <div class="control">
              <button class="button is-pulled-right" @click.stop="goToLogin">Login</button>
              <button class="button is-warning is-pulled-right" @click.stop="showErrorModal = false">Ok</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.stop="showErrorModal = false"></button>
  </div>

</div>
</template>

<script>
import { mixin as ServiceMixin } from '@/components/service_mixin'
export default {
  mixins: [ServiceMixin],
  data () {
    return {
      email: '',
      password: '',
      verifyPassword: '',
      errorMsg: '',
      inputErrors: {},
      showErrorModal: false,

      isThisForEndUser: false,
      endUserEmail: '',
      endUserPassword: '',
      endUserVerifyPassword: '',
    }
  },
  computed: {
    passwordMinLength () {
      return this.password.length > 6
    },
    passwordsMatch () {
      return this.passwordMinLength && this.password === this.verifyPassword
    },
    validEmail () {
      return this.email && this.email.includes('@')
    },
    validForm () {
      return this.passwordMinLength && this.passwordsMatch && this.validEmail
    },
    loginOrRegisterView () {
      return this.$store.getters.isLoginOrRegister(this.$route.name)
    },
    endUserPasswordMinLength() {
      return this.endUserPassword.length > 6
    },
    endUserPasswordsMatch () {
      return this.endUserPasswordMinLength && this.endUserPassword === this.endUserVerifyPassword
    },
    validEndUserEmail () {
      return this.endUserEmail && this.endUserEmail.includes('@')
    },
  },
  methods: {
    goToLogin(){
      this.$router.push('/login')
    },
    validateInput () {
      const inputErrors = {}
      if (this.email.length == 0) { inputErrors['email'] = 'Please input an email'; }
      if (this.password.length < 6) { inputErrors['password'] = 'Your password must contain at least 6 characters'; }
      if (this.password !== this.verifyPassword) { inputErrors['verifyPassword'] = 'Your password and password verify do not match'; }

      this.inputErrors = inputErrors
      return Object.keys(inputErrors).length == 0;
    },
    register () {
      if ( !this.validateInput()) { return; }
      this.$store.commit('showLoading')
      return this.$store.dispatch('registerNoCard', {
        email: this.email,
        password: this.password,
        promoCode: this.promoCode.trim()
      }).then((response) => {
        if (response.data.status === 'SUCCESS') {
          return this.$router.push('/register-check-email')
        } else {
          this.showErrorModal = true
          this.errorMsg = response.data.errorMsg
          return 1
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
  },
  beforeMount() {
    this.$store.commit('clearStore')
  }
}
</script>

<style scoped>
.box {
    padding: 30px 50px;
}

.service-package {
  min-height: 230px;
}

.is-service-benefit {
  font-weight: bold;
}

.credit-card-button-and-text {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .credit-card-button-and-text {
    flex-direction: column;
  }
}
</style>
