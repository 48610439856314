<template>
  <section class="guard-dashboard">
    <div :class="{ 'container': !forAdminPage  }">
      <div class="dashboard-page-header">
        <img src="/static/dashboard-icon1.png" />
        <div class="flex-grow-1">
          <h2 class="page-title">
            AI Articles
          </h2>
          <div class="desc">
            In this dashboard you can find all of your AI articles, who created them and when they were last edited. Click "view" to open an article in the AI Writer.
          </div>
        </div>
        <div>
          <!-- <v-btn rounded small
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            @click="() => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: '',
                videoLink: '',
                description: '',
                time: '0m 00s'
              });
            }">
            <span>Watch video</span>
          </v-btn> -->
        </div>
      </div>

      <div class="white-common-box pa-3 mt-5 mb-3">
        <div class="d-flex align-center">
          <div class="control" v-if="!forAdminPage">
            <input
              type="text"
              class="input"
              placeholder="Search"
              v-model="searchText"
              @keyup.enter="onFilter"
            />
          </div>
          <!-- <button class="button is-info ml10" @click="onFilter">Search</button> -->
          <div class="select ml10">
            <select v-model="perPage" @change="onPerPageChange">
              <option value="5">5 per page</option>
              <option value="10">10 per page</option>
              <option value="25">25 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </select>
          </div>
          <div class="flex-grow-1"></div>
        </div>
      </div>
      
      <div class="white-common-box pa-3">
        <b-table
          :data="records"
          :loading="isLoading"
          :paginated="totalRows > 0"
          backend-pagination
          :total="totalRows"
          :per-page="perPage"
          :current-page="currentPage"
          @page-change="onPageChange"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <template slot-scope="props">
            <b-table-column field="id" label="AI article ID" sortable class="text-center">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="runId" label="Report ID" sortable class="text-center">
              {{ props.row.runId }}
            </b-table-column>
            <b-table-column field="createdAt" label="Date created" sortable>
              {{ toLocalDateString(props.row.createdAt) }}
            </b-table-column>
            <b-table-column field="editedAt" label="Last edited date" sortable>
              {{ toLocalDateString(props.row.editedAt) }}
            </b-table-column>
            <b-table-column field="keyword" label="Keyword" sortable>
              {{  props.row.keyword }}
            </b-table-column>
            <b-table-column field="url" label="URL">
              {{ props.row.url }}
            </b-table-column>
            <b-table-column field="totalCredits" label="Credits used" class="text-center">
              {{ props.row.totalCredits }}
            </b-table-column>
            <b-table-column field="creatorEmail" label="Created by">
              {{ props.row.creatorEmail }}
            </b-table-column>
            <b-table-column field="goto-report" label="Action" class="text-center">
              <a class="view-link" @click="() => viewAIReport(props.row)">
                View
              </a>
            </b-table-column>
          </template>
          <template slot="detail" slot-scope="props">
            <div>Information {{ props.row.page.id }}</div>
          </template>
        </b-table>
      </div>
    </div>

  </section>
</template>

<script>

import { toLocalDateString } from "@/utils";

export default {
  name: "AIWriterLogDashboard",
  props: {
    forAdminPage: {
      type: Boolean,
      default: false,
    },
    userId: {
      default: 0,
      type: Number,
    }
  },
  components: {
  },
  computed: {
    isAgencyUnlimitedUser() {
      return this.$store.getters.isAgencyUnlimitedUser;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    user() {
      return this.$store.state.user;
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
  },
  data() {
    return {

      isLoading: false,
      totalRows: 0,
      records: [],
      currentPage: 1,
      perPage: 10,
      sortField: "id",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      searchText: "",

    };
  },
  methods: {
    viewAIReport(aiRun) {
      let queryParams = {}      
      queryParams = {
        pageRunId: aiRun.pageRunId,
        aiRunId: aiRun.id,
      }
      queryParams['editorMode'] = 'AI_MODE'
      
      if (this.selectedSubAccountRelation) {
        queryParams['relId'] = this.selectedSubAccountRelation.id
      }
      let routeData = this.$router.resolve({name: 'FullPageSourceEditor', query: queryParams});
      window.open(routeData.href, '_blank')
    },
    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr);
    },
    onPerPageChange() {
      this.$nextTick(() => this.loadData());
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadData();
    },
    onFilter() {
      // console.log('filtering')
      this.$nextTick(() => {
        this.loadData();
      });
    },
    loadData() {
      // remove full screen loading indicator for speed up (to feel user experience)
      // this.$store.commit('showLoading')
      this.isLoading = true;
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.searchText}`,
        `userId=${this.forAdminPage ? this.userId : 0}`
      ].join("&");

      this.$store
        .dispatch("getAIRunsForUser", { params })
        .then(({ data }) => {
          const { total, records } = data;
          this.totalRows = total;
          this.records = records;

        })
        .finally(() => {
          // this.$store.commit('hideLoading')
          this.isLoading = false;
        });
    },
    // downloadRun(row) {
    //   const pageRun = row.pageRun;
    //   console.log(pageRun);
    //   createContentBriefPDF(
    //     this.$store.state.customLogoBase64,
    //     pageRun.id,
    //     pageRun.keyword,
    //     pageRun.wordCountData,
    //     pageRun.score,
    //     pageRun.secretKey,
    //     pageRun.subHeadingsTarget,
    //     pageRun.cleanedContentBrief,
    //     pageRun.gCompetitors,
    //     pageRun.relatedSearches,
    //     pageRun.relatedQuestions,
    //     pageRun.pageStructureDataForPDF
    //   );
    // },
    goBack () {
      this.$router.go(-1);
    }
  },
  mounted() {
    if (this.records.length == 0) {
      this.loadData();
    }
  }
};
</script>

<style scoped lang="scss">

.tabs {
  border: 2px solid var(--v-gray12Color-base);
  border-radius: 7px;
  margin-top: 40px;
  margin-bottom: 40px;
  & > div {
    cursor: pointer;
    padding: 15px 20px;
  }
  & > div:first-child {
    border-right: 2px solid var(--v-gray12Color-base);
  }
  & > div.active {
    background: var(--v-gray13Color-base);
  }

}

.summary-date-box {
  background: var(--v-green6Color-base);
  color: var(--v-green7Color-base);
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
}

.task-pending {
  padding: 5px 10px;
  border-radius: 10px;
  color: var(--v-red2Color-base);
  background: var(--v-red1Color-base);
  font-weight: bold;
}
.all-good {
  padding: 5px 10px;
  border-radius: 10px;
  color: var(--v-green5Color-base);
  background: var(--v-green4Color-base);
  font-weight: bold;
}

.view-link {
  color: var(--v-link1Color-base);
  font-weight: bold;
}

.b-table {
  td {
    vertical-align: middle;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

</style>
