<template>
  <div class="ai-stepper" v-if="currentStep > STEPS.START">
    <div class="d-flex">
      <div class="step" v-for="(step, idx) in availableStepsExceptFirst" :key="step">
        <div>
          <div class="draw-layer" :class="{ 'succeed': step <= currentStep }">
            <div class="first-spot" v-if="idx == 0"
              :class="{ 'succeed': step <= currentStep , 'current': !(step <= currentStep)}"
              @click="() => {
                if (currentStep == STEPS.PARAGRAPH) return;
                $emit('goTo', idx == 0 ? STEPS.SETTING : step)
              }">
              <template v-if="step <= currentStep">
                <span class="material-icons">done</span>
              </template>
              <template v-else>
                1
              </template>
            </div>
            <div class="spot"
              :class="getSpotClasses(step)"
              @click="() => {
                if (currentStep == STEPS.PARAGRAPH) return;
                $emit('goTo', step)
              }">
              <template v-if="step < currentStep || (currentStep == STEPS.PARAGRAPH && ($store.getters.isDemoUser ? $store.state.isDemoMainContentDone : hasParagraphResult))">
                <span class="material-icons">done</span>
              </template>
              <template v-else>
                {{ idx + 2 }}
              </template>
              <!-- #{{ step }}# -->
            </div>
          </div>
          <div class="first-spot-label" v-if="idx == 0">{{ STPES_LABEL[availableSteps[0]] }}</div>
          <div class="spot-label">{{ STPES_LABEL[step] }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { STEPS, STPES_LABEL } from '@/components/PageRun/OpenAIEditor/AiWriter_constants.js';


export default {
  name: 'AiWriterSteps',
  props: ['currentStep', 'separateSearchEngineTitleAndTitle', 'hasParagraphResult'],
  data () {
    return {
      STEPS: STEPS,
      STPES_LABEL: STPES_LABEL,
    }
  },
  computed: {
    availableSteps () {
      let rlt = []
      Object.keys(STEPS).map((key, index) => {
        if (index < 1) return;
        if (!this.separateSearchEngineTitleAndTitle && STEPS[key] == STEPS.SEARCH_ENGINE_TITLE) {
          console.log('skipping..')
          return;
        }
        rlt.push(STEPS[key])
      })
      return rlt;
    },
    availableStepsExceptFirst () {
      return this.availableSteps.slice(1);
    },

  },
  methods: {
    getSpotClasses (step) {
      if (step == this.currentStep) {
        if (this.currentStep == this.STEPS.PARAGRAPH) {
          return {
            'succeed': this.$store.getters.isDemoUser ? this.$store.state.isDemoMainContentDone : this.hasParagraphResult,
            'current': this.$store.getters.isDemoUser ? !this.$store.state.isDemoMainContentDone : !this.hasParagraphResult
          }
        }
      }

      return {
        'succeed': step < this.currentStep,
        'current': step == this.currentStep
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-stepper {
  padding: 30px 50px 55px 50px;
  border-bottom: 1px solid var(--v-grayColor-base);
  background: var(--v-whiteColor-base);
}
.step {
  position: relative;
  flex-grow: 1;
  
  .draw-layer {
    height: 2px;
    margin-left: 20px;
    margin-right: 20px;
    background: var(--v-grayColor-base);

    &.succeed {
      background: var(--v-green7Color-base);
    }
    
    .first-spot, .spot {
      // background: red;
      cursor: pointer;
      border: 1px solid var(--v-grayColor-base);
      width: 26px;
      height: 26px;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      color: var(--v-grayColor-base);


      &.succeed {
        background: var(--v-green7Color-base);
      }

      &.current {
        border: 1px solid var(--v-profileAnchorColor-base);
        color: var(--v-profileAnchorColor-base);
      }

      .material-icons {
        font-size: 20px;
        color: var(--v-whiteColor-base);
      }

      
    }

    .first-spot {
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
  .first-spot-label, .spot-label {
    position: absolute;
    top: calc(50% + 20px);
    right: 0;
    font-size: 14px;
    transform: translateX(50%);
    color: var(--v-mainColor-base);
    font-weight: 700;
  }

  .first-spot-label {
    left: 0;
    right: unset;
    transform: translateX(-50%);
  }
}
</style>