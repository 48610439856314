<template>
<span>
  <v-btn v-if="isTrialUser && !isServicePurchasePage" rounded small
    color="profileAnchorColor"
    class="button-with-icon whiteColor--text text-none"
    @click.stop="convertTrial">
    <i class="fa fa-fighter-jet"></i>
    <span>Switch to Basic plan now</span>
  </v-btn>
  <trial-conversion-modal :invalidDomain="false" ref="trialConversion"/>
</span>
</template>

<script>
import { mixin as ServiceMixin } from '@/components/service_mixin'
import TrialConversionModal from '@/components/TrialConversionModal.vue'
export default {
  name: 'TrialConversionButton',
  components: { TrialConversionModal },
  mixins: [ServiceMixin],
  computed:  {
    user () {
      return this.$store.state.user
    },
    isTrialUser() {
      return this.$store.getters.isTrialUser;
    },
    isServicePurchasePage () {
      return ['ServicePurchase'].includes(this.$route.name)
    },
  },
  methods: {
    convertTrial () {
      this.$refs.trialConversion.show()
    }
  }
}
</script>

<style lang="scss">
  
</style>
