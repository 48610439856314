<template>
<div>
  <div class="scroll_master" @scroll="handleTopScroll">
    <div class="scroll-div1" :style="{ width: boxWidth+'px' }">
    </div>
  </div>
  <div class="scroll_slave" @scroll="handleSlaveScroll">
    <div class="scroll-div2" ref="boxRef">
        <slot></slot>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      interval: null,
      boxWidth: 800,
    }
  },
  methods: {
    handleTopScroll (e) {
      let slave = e.target.parentNode.querySelector(".scroll_slave");
      let master = e.target;
      let newSlavePosition = master.scrollLeft 
      slave.scroll({
          left: newSlavePosition
      });
    },
    handleSlaveScroll (e) {
      let slave = e.target;
      let master = e.target.parentNode.querySelector(".scroll_master");
      let newMasterPosition = slave.scrollLeft
      master.scroll({
          left: newMasterPosition
      });
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      this.boxWidth = this.$refs.boxRef.clientWidth;            
    }, 1000);
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll_master, .scroll_slave {
    overflow-x: auto;
    width: 100%;
}
.scroll-div1 {
    height: 1px;
}
.scroll-div2 {
  width: fit-content;
  min-width: 100%;
}
</style>