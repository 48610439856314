<template>
  <div>
    <div v-if="isWhiteGloveUser && !isCalculating" class="silo-white-glove-header mb-10">

      <div class="container-launch px-4 py-5" style="flex: 1; max-width: 1150px;">
        <div class="flex flex-column justify-space-between width704">
          <div class="font12">YOUR WHITE GLOVE SERVICES</div>
          <div class="font20">Done-for-you silo reports </div>
          <div class="font1rem">
            Content silo is a very powerful tool that helps your site rank by leveraging its own authority.<br/>
            Put us to work on your silo by selecting your Top-level page and supporting pages, then place the order for your POP team. When your order is ready, we will send you an email.
          </div>
          <div>
            <v-tooltip
              v-if="isContentOrderEditable"
              top
              color="transparent"
              :disabled="isValidKeywordSelection"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  style="display: inline-block;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    rounded
                    color="anchor1Color"
                    class="button-with-icon text-none whiteColor--text font15 padding8 height38"
                    tag="a"
                    :disabled="!isValidKeywordSelection"
                    @click="showOrderReportsFormModal = true"
                  >
                    <i class="material-icons-outlined" style="margin-right: 10px;">shopping_basket</i>
                    Order here
                  </v-btn>
                </div>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                <span>To place the order, select the Top-level page and from 3 up to 15 supporting pages from the table below</span>
              </div>
            </v-tooltip>
            
            <v-tooltip
              v-if="isContentOrderInCheckout"
              top
              color="transparent"
              :disabled="isValidKeywordSelection"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  style="display: inline-block;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    rounded
                    color="anchor1Color"
                    class="button-with-icon text-none whiteColor--text font15 padding8 height38"
                    tag="a"
                    :disabled="!isValidKeywordSelection"
                    @click="showOrderReportsFormModal = true"
                  >
                    <i class="material-icons-outlined" style="margin-right: 10px;">shopping_basket</i>
                    Order ready for checkout - continue
                  </v-btn>
                </div>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                <span>To place the order, select the Top-level page and from 3 up to 15 supporting pages from the table below</span>
              </div>
            </v-tooltip>
            
            <v-tooltip
              v-if="isContentOrderInCart"
              top
              color="transparent"
              :disabled="isValidKeywordSelection"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  style="display: inline-block;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    rounded
                    color="anchor1Color"
                    class="button-with-icon text-none whiteColor--text font15 padding8 height38"
                    tag="a"
                    :disabled="!isValidKeywordSelection"
                    @click="$store.commit('setShowSiloContentReportsOrdersCart', true);"
                  >
                    <i class="material-icons-outlined" style="margin-right: 10px;">shopping_basket</i>
                    Order in cart - continue
                  </v-btn>
                </div>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                <span>To place the order, select the Top-level page and from 3 up to 15 supporting pages from the table below</span>
              </div>
            </v-tooltip>
          </div>
        </div>
        <div>
          <img src="/static/silo-white-glove-message-bg.svg" class="silo-structure" />
        </div>
      </div>

      <silo-order-widget
        v-if="isWhiteGloveUser && ['to_be_paid_by_invoice', 'paid'].includes(silo?.content_report_order?.order_status)"
        :silo="silo"
        :isMissionControlSession="isMissionControlSession"
        :refreshData="() => fetchSilo()"
      />

    </div>
  
    <div id="silo-result-table">
      <v-progress-linear rounded height="8"
        class="ml-1"
        background-color="gray7Color"
        color="profileAnchorColor"
        :value="siloReportProgress">
      </v-progress-linear>
      <div class="voltage-result-area mt-5">
        <template v-if="!isCalculating">
          <div class="header-part d-flex align-center">
            <v-text-field
              outlined
              dense
              hide-details
              label="Search"
              prepend-inner-icon="search"
              class="search-field"
              v-model="searchTerm"
            >
            </v-text-field>
            <div class="flex-grow-1"></div>
            <div class="d-flex align-center gap10">
              <div>
                <b-dropdown
                  class="columns-visibility-dropdown"
                  append-to-body
                  aria-role="menu"
                  scrollable
                  max-height="200"
                  trap-focus
                  position="is-bottom-left"
                >
                    <template #trigger>
                      <v-btn outlined
                        color="mainColor" class="button-with-icon text-none"
                      >
                        <i class="material-icons-outlined mr-1">view_week</i>
                        <span>Columns</span>
                      </v-btn>
                    </template>

                    <b-dropdown-item custom paddingless aria-role="listitem">
                      <div
                        class="filter-item-label pb-4 mb-4"
                        style="border-bottom: 2px solid #f5f5f5; font-weight: bold;"
                      >{{hideableColumns.length - hiddenColumnsKeys.length}} out of {{hideableColumns.length}} selected</div>
                      <div
                        class="pb-4 mb-4"
                        style="border-bottom: 2px solid #f5f5f5;"
                      >
                        <div
                          v-for="hideableColumn in hideableColumns" :key="hideableColumn.key"
                        >
                          <v-checkbox
                            color="#0075FF"
                            dense
                            hide-details
                            :label="hideableColumn.label"
                            :input-value="isColumnVisible(hideableColumn.key)"
                            @change="(value) => setColumnVisibility(hideableColumn.key, value)"
                          ></v-checkbox>
                        </div>
                      </div>
                      
                      <div class="d-flex align-center gap10">
                        <v-btn text color="profileAnchorColor"
                          class="text-none"
                          @click="resetColumnVisibility">
                          <v-icon
                            left
                            dark
                          >
                            mdi-refresh
                          </v-icon>
                          Reset
                        </v-btn>
                      </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <table-pdf-csv-download
                btnLabel="Download"
                tableId="silo-result-table table"
                fileName="silo-keywords"
                title="Silo Keywords"
                :columns="visibleTableColumns"
                :rows="sortedTableData"
              ></table-pdf-csv-download>
              <v-btn
                outlined
                color="mainColor"
                class="button-with-icon text-none hw34"
                :class="{'bgProfileAnchorColor': graphChart}"
                @click="() => graphChart = !graphChart"
              >
                <i class="material-icons-outlined">schema</i>
              </v-btn>
            </div>
          </div>
          <b-table
            v-if="!graphChart"
            id="silo-result-table"
            class="voltage-table"
            ref="voltage-table"
            :data="sortedTableData"
            :default-sort-direction="'desc'"
            :default-sort="['keywordType', 'desc']"
            @sort="onSort"
            scrollable
          >
            <template #header="{ column }">
              <div v-if="column.label === 'Track'" class="pt-5 pb-5">
                <v-checkbox
                  color="#007aff"
                  hide-details
                  dense
                  class="mt-0 pt-0"
                  :value="isAllSelected"
                  :disabled="isSelectionDisabled"
                  @change="() => toggleAllSiloKeywords()"
                />
              </div>
              <div v-else class="pt-5 pb-5" style="text-wrap: nowrap;">{{ column.label }}</div>
            </template>
            <template slot-scope="props">
              <b-table-column
                centered
                field="hiddenDefaultSortColumn"
                label="hiddenDefaultSortColumn"
                :visible="false"
                sortable
                :custom-sort="handleDefaultSort"
              >
                -
              </b-table-column>
              <b-table-column centered field="track" label="Track" sticky>
                <v-checkbox
                  color="#007aff"
                  hide-details
                  dense
                  style="margin-left: 10px"
                  class="mt-0 pt-0"
                  :disabled="isSelectionDisabled"
                  :value="selectedSiloKeywords.includes(props.row.id)"
                  @change="() => toggleSelectedSiloKeyword(props.row.id)"
                />
              </b-table-column>
              <b-table-column
                field="keyword"
                label="Keyword"
                sortable
                class="keyword-cell"
                sticky
              >
                <div 
                  class="icon-text-container" 
                  style="width: 200px;"
                  @mouseover="currentIdx = props.row.id" @mouseleave="currentIdx = null"
                >
                  <div v-if="trackedKeywords.includes(props.row.keyword)">
                    <v-tooltip top color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span 
                          v-bind="attrs" 
                          v-on="on" 
                          @click="() => {
                            toggleTrackUntrackKeyword(false, props.row)
                          }"
                          class="cursor-pointer"
                        >
                          <i class="material-icons" style="color: var(--v-green7Color-base);">check_circle</i>
                        </span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip tooltip-arrow">
                        <span>Tracked Keyword</span>
                      </div>
                    </v-tooltip>
                  </div>
                  <span v-else>
                    <v-tooltip top color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs" 
                          v-on="on" 
                          @click="() => {
                            toggleTrackUntrackKeyword(true, props.row)
                          }"
                          class="cursor-pointer"
                        >
                          <i class="material-icons add-icon grayColor--text">add_circle</i>
                        </span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip tooltip-arrow">
                        <span>Track</span>
                      </div>
                    </v-tooltip>
                  </span>
                  {{ props.row.keyword }}
                  <span
                    :style="currentIdx !== props.row.id?'visibility:hidden;':''"
                    :class="['material-icons mr-2', { 'cursor-pointer': !(keywordsOngoingRemoval.indexOf(props.row.id) >= 0) }]"
                    @click="
                      !(keywordsOngoingRemoval.indexOf(props.row.id) >= 0)
                        ? removeKeyword(props.row.id)
                        : undefined
                    "
                  >
                    <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <i class="material-icons" style="color: var(--v-profileAnchorColor-base);">close</i>
                          </span>
                        </template>
                        <div class="white-bg-tooltip small-tooltip tooltip-arrow">
                          <span>Remove</span>
                        </div>
                      </v-tooltip>
                  </span>
                </div>
              </b-table-column>
              <b-table-column field="url" label="Url" sortable class="url-cell" sticky>
                <div>
                  <a
                    v-if="getSiloUrl(props.row)"
                    target="_blank"
                    :href="getSiloUrl(props.row)"
                    :title="getSiloUrl(props.row)"
                  >
                    {{ getSiloUrl(props.row) }}
                  </a>
                </div>
              </b-table-column>
              <b-table-column centered field="keywordType" label="Type" sortable>
                <v-tooltip top color="transparent" v-if="props.row.keywordData.isTopLevelKeyword">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="box-container cursor-pointer">
                      <template>
                          <b-tag type="is-success is-light"><strong class="has-text-success-dark">T</strong></b-tag>
                      </template>
                    </span>
                  </template>
                  <div class="white-bg-tooltip small-tooltip p-3 tooltip-arrow">
                    <span class="tooltip-heading">Top-level keyword</span><br /><br />
                    <span class="tooltip-discription">
                      This is the main keyword your target page<br />
                      can rank for. The more difficult this keyword<br />
                      is, the more supporting posts you’ll need
                    </span>
                  </div>
                </v-tooltip>
                <v-tooltip top color="transparent" v-else-if="props.row.keywordData.isSupportingKeyword">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="box-container cursor-pointer">
                      <template>
                          <b-tag type="is-danger is-light"><strong class="has-text-danger">S</strong></b-tag>
                      </template>
                    </span>
                  </template>
                  <div class="white-bg-tooltip small-tooltip p-3 tooltip-arrow">
                    <span class="tooltip-heading">Supporting keyword</span><br /><br/>
                    <span class="tooltip-discription">
                      This is a keyword that you can use to<br />
                      create supporting pages and point<br />
                      their links to your main target page.
                    </span>
                  </div>
                </v-tooltip>
              </b-table-column>
              <b-table-column
                centered
                field="pageRunResult.pageRunScore"
                label="POP score"
                sortable
                :visible="isColumnVisible('pageRunResult.pageRunScore')"
              >
                <template v-if="props.row.pageRunId && !props.row.pageRunResult.pageNotBuilt">
                  <span
                    class="flex align-sm-center justify-md-center cursor-pointer"
                    @click="gotoPage(`/sites/${props.row.siteId}/pages/${props.row.pageId}/pageruns/${props.row.pageRunId}`)"
                  >
                    <radial-progress-bar
                      :diameter="40"
                      :completed-steps="props.row.pageRunResult.pageRunScore"
                      :total-steps="100"
                      :strokeWidth="7"
                      :startColor="pageScoreTintColor(props.row.pageRunResult.pageRunScore)"
                      :stopColor="pageScoreTintColor(props.row.pageRunResult.pageRunScore)"
                      :innerStrokeColor="'#D9D9D9'"
                      strokeLinecap="square"
                    />
                    <span class="ml-2">{{ props.row.pageRunResult.pageRunScore }}</span>
                  </span>
                </template>
                <span
                  v-else-if="props.row.pageRunId"
                  class="cursor-pointer"
                  @click="gotoPage(`/sites/${props.row.siteId}/pages/${props.row.pageId}/pageruns/${props.row.pageRunId}`)"
                >
                  N/A
                </span>
              </b-table-column>
              
              <!-- VoltageTable columns -->
              <!-------------------------->
    
              <b-table-column
                numeric
                field="keywordData.pageOpportunityScore"
                label="Opportunity Score"
                sortable
                :visible="isColumnVisible('keywordData.pageOpportunityScore')"
              >
                <template v-if="['KeywordWizardResearch'].indexOf(props.row.sourceReportType) >= 0">
                  <b-tag
                    v-if="props.row.keywordData.pageOpportunityScore"
                    :style="{
                      ...scoreTierToColorStyles(
                        props.row.keywordData.pageOpportunityScoreTier,
                      ),
                      ...{ width: '60px' },
                    }"
                  >
                    {{ props.row.keywordData.pageOpportunityScore }}
                  </b-tag>
                </template>
              </b-table-column>
              <b-table-column
                numeric
                field="keywordData.opportunityScore"
                label="Keyword Opportunity Score"
                sortable
                :visible="isColumnVisible('keywordData.opportunityScore')"
              >
                <template v-if="['KeywordWizardResearch'].indexOf(props.row.sourceReportType) >= 0">
                  <b-tooltip v-if="props.row.keywordData.isBestOpportunityKeyword" label="Best opportunity keyword" type="is-dark">
                    <b-tag type="is-success" style="
                      margin-right: 10px;
                      padding: 0.25em;
                      height: initial;
                    ">
                      <i class="material-icons-outlined" style="font-size: 1em; line-height: 1em;">grade</i>
                    </b-tag>
                  </b-tooltip>
                  <template v-if="props.row.keywordData.opportunityScore === undefined">
                    -
                  </template>
                  <span v-else>{{ props.row.keywordData.opportunityScore }} %</span>
                </template>
              </b-table-column>
              <b-table-column field="keywordData.monthlySearch" label="Search volume" sortable numeric :visible="isColumnVisible('keywordData.monthlySearch')">
                <template v-if="['KeywordWizardResearch'].indexOf(props.row.sourceReportType) >= 0">
                  <template v-if="props.row.keywordData.monthlySearch !== undefined">
                    {{ props.row.keywordData.monthlySearch }}
                  </template>
                </template>
              </b-table-column>
              <b-table-column numeric field="keywordData.clicks" label="Clicks" sortable :visible="isColumnVisible('keywordData.clicks')">
                <template v-if="['KeywordWizardResearch'].indexOf(props.row.sourceReportType) >= 0">
                  {{ props.row.keywordData.clicks }}
                </template>
              </b-table-column>
              <b-table-column numeric field="keywordData.impressions" label="Impressions" sortable :visible="isColumnVisible('keywordData.impressions')">
                <template v-if="['KeywordWizardResearch'].indexOf(props.row.sourceReportType) >= 0">
                  {{ props.row.keywordData.impressions }}
                </template>
              </b-table-column>
              <b-table-column field="keywordData.impressionsShare" label="Search impressions share %" sortable numeric :visible="isColumnVisible('keywordData.impressionsShare')">
                <template v-if="['KeywordWizardResearch'].indexOf(props.row.sourceReportType) >= 0">
                  <template v-if="props.row.keywordData.impressionsShare !== undefined">
                    {{ props.row.keywordData.impressionsShare }} %
                  </template>
                </template>
              </b-table-column>
              <b-table-column numeric field="keywordData.ctr" label="CTR" sortable :visible="isColumnVisible('keywordData.ctr')">
                <template v-if="['KeywordWizardResearch'].indexOf(props.row.sourceReportType) >= 0">
                  {{ props.row.keywordData.ctr }}
                </template>
              </b-table-column>
              <b-table-column field="keywordData.position" label="Position" sortable numeric :visible="isColumnVisible('keywordData.position')">
                <template v-if="['KeywordWizardResearch'].indexOf(props.row.sourceReportType) >= 0">
                  {{ props.row.keywordData.position }}
                </template>
              </b-table-column>
    
              <!-- KeywordResearchResult columns -->
              <!----------------------------------->
    
              <b-table-column numeric field="keywordData.keywordScore" label="Keyword score" sortable :visible="isColumnVisible('keywordData.keywordScore')">
                <template>
                  <b-icon v-if="props.row.keywordResearchDataTaskId"
                    pack="fa"
                    icon="spinner"
                    custom-class="fa-spin mr-4">
                  </b-icon>
                  <span v-else-if="![undefined, null].includes(props.row.keywordData.keywordScore)" class="tag" :style="{...keywordScoreToColorStyles(props.row.keywordData.keywordScore), 'justify-content': 'end'}">
                    {{ Math.round(props.row.keywordData.keywordScore) }}
                  </span>
                </template>
              </b-table-column>
              <b-table-column numeric field="keywordData.KGRScore" label="KGR Score" sortable :visible="isColumnVisible('keywordData.KGRScore')">
                <b-icon v-if="props.row.keywordResearchDataTaskId"
                  pack="fa"
                  icon="spinner"
                  custom-class="fa-spin">
                </b-icon>
                <template v-else>
                  <div class="flex align-center" style="justify-content: end;">
                    {{ props.row.keywordData.KGRScore }}
                    <span
                      class="bullet kgr-score-bullet ml-2"
                      :class="{
                        best: props.row.keywordData.KGRScore <= 2.5,
                        good: props.row.keywordData.KGRScore > 2.5 && props.row.keywordData.KGRScore <= 5,
                        normal: props.row.keywordData.KGRScore > 5
                      }"
                    >
                    </span>
                  </div>
                </template>
              </b-table-column>
              <b-table-column numeric field="keywordData.realCompMajesticTitleAndAnchorComp" label="SEO Competitiveness Index (SCI)" sortable :visible="isColumnVisible('keywordData.realCompMajesticTitleAndAnchorComp')">
                <b-icon v-if="props.row.keywordResearchDataTaskId"
                pack="fa"
                icon="spinner"
                custom-class="fa-spin">
              </b-icon>
              <template v-else>
                {{ props.row.keywordData.realCompMajesticTitleAndAnchorComp }}
              </template>
            </b-table-column>
            <b-table-column numeric field="keywordData.allintitle" label="All In Title" sortable :visible="isColumnVisible('keywordData.allintitle')">
              <b-icon v-if="props.row.keywordResearchDataTaskId"
                pack="fa"
                icon="spinner"
                custom-class="fa-spin">
              </b-icon>
              <template v-else>
                {{ props.row.keywordData.allintitle }}
              </template>
            </b-table-column>
              <b-table-column numeric field="keywordData.broadMatchMonthlySearches_LowEstimate" label="Monthly searches low" sortable :visible="isColumnVisible('keywordData.broadMatchMonthlySearches_LowEstimate')">
                <b-icon v-if="props.row.keywordResearchDataTaskId"
                  pack="fa"
                  icon="spinner"
                  custom-class="fa-spin">
                </b-icon>
                <template v-else>
                  {{ props.row.keywordData.broadMatchMonthlySearches_LowEstimate }}
                </template>
              </b-table-column>
              <b-table-column numeric field="keywordData.broadMatchMonthlySearches_HighEstimate" label="Monthly searches high" sortable :visible="isColumnVisible('keywordData.broadMatchMonthlySearches_HighEstimate')">
                <b-icon v-if="props.row.keywordResearchDataTaskId"
                  pack="fa"
                  icon="spinner"
                  custom-class="fa-spin">
                </b-icon>
                <template v-else>
                  {{ props.row.keywordData.broadMatchMonthlySearches_HighEstimate }}
                </template>
              </b-table-column>
              <b-table-column numeric field="keywordData.difficultyScore" label="Difficulty Score %" sortable :visible="isColumnVisible('keywordData.difficultyScore')">
                <b-icon v-if="props.row.keywordResearchDataTaskId"
                  pack="fa"
                  icon="spinner"
                  custom-class="fa-spin">
                </b-icon>
                <template v-else>
                  {{ props.row.keywordData.difficultyScore }}
                </template>
              </b-table-column>
              <b-table-column centered field="keywordData.currentRankGoogle" label="Current rank(Google)" sortable :visible="isColumnVisible('keywordData.currentRankGoogle')">
                <b-icon v-if="props.row.keywordResearchDataTaskId"
                  pack="fa"
                  icon="spinner"
                  custom-class="fa-spin">
                </b-icon>
                <template v-else>
                  {{ props.row.keywordData.currentRankGoogle }}
                </template>
              </b-table-column>
              <b-table-column centered field="keywordData.competitionStrength" label="Competition strength" sortable :visible="isColumnVisible('keywordData.competitionStrength')">
                <b-icon v-if="props.row.keywordResearchDataTaskId"
                  pack="fa"
                  icon="spinner"
                  custom-class="fa-spin">
                </b-icon>
                <template v-else>
                  {{ props.row.keywordData.competitionStrength }}
                </template>
              </b-table-column>
              <b-table-column centered field="keywordData.estimatedTimeToWin_in_Months" label="Est. Months to Win" sortable :visible="isColumnVisible('keywordData.estimatedTimeToWin_in_Months')">
                <b-icon v-if="props.row.keywordResearchDataTaskId"
                  pack="fa"
                  icon="spinner"
                  custom-class="fa-spin">
                </b-icon>
                <template v-else>
                  {{ props.row.keywordData.estimatedTimeToWin_in_Months }}
                </template>
              </b-table-column>
        
              <!-- Order reports -->
              <!------------------->

              <b-table-column
                v-if="isWhiteGloveUser"
                field="order_reports" label="Order reports"
              >
                <div class="flex" style="gap: 8px;">
                  <v-btn
                    v-if="!isContentOrderEditable && selectedKeywordIdsForOrder.indexOf(props.row.id) >= 0"
                    outlined
                    small
                    :ripple="false"
                    color="mainColor"
                    class="button-with-icon text-none content-order-keyword-select ordered"
                  >
                    <i class="material-icons">check</i>
                    <span>Ordered</span>
                  </v-btn>
                  <v-btn
                    v-else-if="selectedKeywordIdsForOrder.indexOf(props.row.id) >= 0"
                    outlined
                    small
                    color="success"
                    class="button-with-icon text-none content-order-keyword-select selected"
                    @click="unselectKeywordForContentReportOrder(props.row)"
                  >
                    <i class="material-icons">check</i>
                    <span>Selected</span>
                  </v-btn>
                  <v-btn
                    v-else
                    outlined
                    small
                    color="mainColor"
                    class="button-with-icon text-none content-order-keyword-select"
                    :disabled="
                      (
                        props.row.keywordData.isTopLevelKeyword
                          ?selectedKeywordsForOrder.filter(keyword => keyword.keywordData.isTopLevelKeyword).length > 0
                          :selectedKeywordsForOrder.filter(keyword => keyword.keywordData.isSupportingKeyword).length >= 15
                      )
                      || !isContentOrderEditable
                    "
                    @click="selectKeywordForContentReportOrder(props.row)"
                  >
                    <span>Select</span>
                  </v-btn>

                  <b-dropdown
                    append-to-body
                    aria-role="menu"
                    scrollable
                    max-height="200"
                    trap-focus
                    position="is-bottom-left"
                  >
                    <template #trigger>
                      <v-btn
                        icon
                        small
                        :color="getKeywordNoteTextFromOrder(props.row.id)?'#FF5700':'#C7C7C7'"
                        class="content-order-keyword-note-btn"
                      >
                        <span v-if="getKeywordNoteTextFromOrder(props.row.id)" class="badge">1</span>
                        <i class="material-icons-outlined">textsms</i>
                      </v-btn>
                    </template>

                    <b-dropdown-item custom style="width: 300px;">
                      <v-textarea
                        label="Leave a note for us"
                        :value="getKeywordNoteInputValue(props.row.id)"
                        @input="onKeywordNoteTextChange(props.row.id, $event)"
                      ></v-textarea>
                      <v-btn
                        outlined
                        small
                        color="mainColor"
                        class="button-with-icon text-none"
                        :disabled="!hasUnsavedKeywordNoteChanges(props.row.id)"
                        @click="saveKeywordNoteChanges(props.row.id)">
                        <span>Save</span>
                      </v-btn>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-table-column>
              
              <!-- Actions -->
              <!------------->
    
              <b-table-column  field="action" label="Actions">
                <div class="flex">
                  <v-menu
                    :close-on-content-click="false"
                    v-if="props.row.keywordData.isTopLevelKeyword && props.row.pageRunId"
                    offset-y
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-btn
                          outlined
                          small
                          color="mainColor"
                          class="button-with-icon text-none mr-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="material-icons">speed</i>
                          <span class="ml-1">POP report</span>
                        </v-btn>
                      </span>
                    </template>
                    <v-card class="silo-report-menu">
                      <div style="padding-top: 15px;">
                        <div class="has-text-weight-bold" style="padding-left: 15px;">
                          Generate POP report
                        </div>
                        <div
                          style="padding-top: 10px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;"
                          class="cursor-pointer pop-report-menu-item"
                          @click="gotoNewPopReport(props.row)"
                        >
                          POP Content Brief report
                        </div>
                        <div
                          v-if="props.row.keywordData.isTopLevelKeyword"
                          style="padding-top: 10px; padding-left: 15px; padding-right: 15px; padding-bottom: 10px;"
                          class="cursor-pointer pop-report-menu-item"
                          @click="gotoPageRuns(props.row)"
                        >
                          SEO Boost Checklist
                        </div>
                      </div>
                    </v-card>
                  </v-menu>
                  <span v-else>
                    <v-tooltip top color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          @click="gotoNewPopReport(props.row)"
                        >
                          <v-btn
                            outlined
                            small
                            color="mainColor"
                            class="button-with-icon text-none mr-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="material-icons">speed</i>
                            <span class="ml-1">POP report</span>
                          </v-btn>
                        </span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip tooltip-arrow">
                        Generate POP report
                      </div>
                    </v-tooltip>
                  </span>
                  <silo-action-menu v-if="props.row.pageRunId" :siloKeyword="props.row" @refreshData="refreshData"/>
                </div>
              </b-table-column>
            </template>
          </b-table>

          <silo-order-reports-form
            v-if="showOrderReportsFormModal"
            :isEditMode="isContentOrderEditable"
            :silo="silo"
            :keywords="selectedKeywordsForOrder"
            :refreshData="refreshData"
            @close="showOrderReportsFormModal = false"
          />

        </template>
        <template v-else>
          <div>
            <!-- No data to display -->
          </div>
        </template>
        <graph-chart v-if="graphChart" :graph-data="makeGraphData()"/>
      </div>

      <div v-if="!isCalculating" class="mt-4 flex" style="justify-content: flex-end;">
        <v-tooltip
          v-if="isWhiteGloveUser && isContentOrderEditable"
          top
          color="transparent"
          :disabled="isValidKeywordSelection"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <v-btn
                rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                :style="{'margin-right': '10px'}"
                :disabled="!isValidKeywordSelection"
                @click="showOrderReportsFormModal = true"
              >
                <span>Next step</span>
              </v-btn>
            </div>
          </template>
          <div class="white-bg-tooltip small-tooltip">
            <span>To place the order, select the Top-level page and from 3 up to 15 supporting pages from the table below</span>
          </div>
        </v-tooltip>
        
        <v-tooltip
          v-if="isWhiteGloveUser && isContentOrderInCheckout"
          top
          color="transparent"
          :disabled="isValidKeywordSelection"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
            <v-btn
              rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none"
              :style="{'margin-right': '10px'}"
              :disabled="!isValidKeywordSelection"
              @click="showOrderReportsFormModal = true">
              <span>Order ready for checkout - continue</span>
            </v-btn>
            </div>
          </template>
          <div class="white-bg-tooltip small-tooltip">
            <span>To place the order, select the Top-level page and from 3 up to 15 supporting pages from the table below</span>
          </div>
        </v-tooltip>
        
        <v-tooltip
          v-if="isWhiteGloveUser && isContentOrderInCart"
          top
          color="transparent"
          :disabled="isValidKeywordSelection"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
            <v-btn
              rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none"
              :style="{'margin-right': '10px'}"
              :disabled="!isValidKeywordSelection"
              @click="$store.commit('setShowSiloContentReportsOrdersCart', true);">
              <span>Order in cart - continue</span>
            </v-btn>
            </div>
          </template>
          <div class="white-bg-tooltip small-tooltip">
            <span>To place the order, select the Top-level page and from 3 up to 15 supporting pages from the table below</span>
          </div>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import SiloActionMenu from '@/components/Menu/SiloActionMenu.vue';
import { filter, map } from 'lodash';
import RadialProgressBar from 'vue-radial-progress';
import TablePdfCsvDownload from '@/components/Campaigns/TablePdfCsvDownload.vue';
import GraphChart from '@/components/Campaigns/linked-graph/GraphChart.vue'
import SiloOrderReportsForm from '@/components/Campaigns/silo/SiloOrderReportsForm.vue'
import SiloOrderWidget from '@/components/Campaigns/silo/SiloOrderWidget.vue';
import { pageScoreTintColor, getPureServicePkgName } from '@/utils';
import { fetchTaskResult } from '@/api'
import { isEmpty } from 'lodash'
import {
  whiteGlovePlans,
} from "@/components/plans_constants"

const HIDDEN_COLUMNS_KEYS_DEFAULT = [
  'keywordData.pageOpportunityScore',
  'keywordData.opportunityScore',
  'keywordData.monthlySearch',
  'keywordData.clicks',
  'keywordData.impressions',
  'keywordData.impressionsShare',
  'keywordData.ctr',
  'keywordData.position',
  'keywordData.competitionStrength',
  'keywordData.estimatedTimeToWin_in_Months',
]

export default {
  props: {
    isCalculating: {
      default: false,
    },
    silos: {
      default: [],
    },
    siteId: {
      
    },
    silo: {
      
    },
  },
  components: {
    SiloActionMenu,
    RadialProgressBar,
    TablePdfCsvDownload,
    GraphChart,
    SiloOrderReportsForm,
    SiloOrderWidget,
  },
  data() {
    return {
      searchTerm: '',
      guardRunInterval: null,
      graphChart: false,
      keywordsOngoingRemoval: [],
      showOrderReportsFormModal: false,
      editedKeywordNotes: [],
      sortKey: "keywordType",
      sortOrder: "desc",
      trackedKeywords: [],
      currentIdx: null,
      keywordTaskIntervals: {},
    };
  },
  watch: {
    silo: function () {
      this.editedKeywordNotes = []
    },
    silos: {
      immediate: true,  // means we refresh on mount as well
      handler:function () {
        this.refreshKeywordTaskMonitors()
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
    isContentOrderEditable () {
      return [undefined, 'user_editing'].includes(this.silo?.content_report_order?.order_status);
    },
    isContentOrderInCheckout () {
      return this.silo?.content_report_order?.order_status === 'in_checkout';
    },
    isContentOrderInCart () {
      return ['in_cart', 'in_cart_checkout'].includes(this.silo?.content_report_order?.order_status);
    },
    selectedKeywordIdsForOrder() {
      return [
        ...this.silo?.content_report_order?.selected_top_level_keyword_id
          ?[this.silo?.content_report_order?.selected_top_level_keyword_id]
          :[],
        ...this.silo?.content_report_order?.selected_supporting_keyword_ids || []
      ]
    },
    selectedKeywordsForOrder () {
      return this.sortedTableData.filter(keyword => this.selectedKeywordIdsForOrder.indexOf(keyword.id) >= 0)
    },
    isValidKeywordSelection () {
      // const topLevelKeywordsCount = this.selectedKeywordsForOrder.filter(keyword => keyword.keywordData.isTopLevelKeyword).length
      const supportingKeywordsCount = this.selectedKeywordsForOrder.filter(keyword => keyword.keywordData.isSupportingKeyword).length
      return /* topLevelKeywordsCount > 0 && */ supportingKeywordsCount >= 3
    },
    siloReportProgress() {
      return this.$store.getters.siloReportProgress;
    },
    isSelectionDisabled() {
      return !!this.siloReportProgress;
    },
    filteredSilos() {
      return this.searchTerm ? this.silos.filter(silo => silo.keyword?.toLowerCase().includes(this.searchTerm?.toLowerCase())) : this.silos
    },
    selectedSiloKeywords() {
      return this.$store.getters.selectedSiloKeywords;
    },
    campaignId() {
      return parseInt(this.$route.params.campaignId || 0);
    },
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    sortedTableData() {
      let searchTerm = this.searchTerm ? this.searchTerm.toLowerCase() : '';
      let data = [...this.filteredSilos].filter((item) => {
        if (!searchTerm) {
          return true;
        }
        return (
          item.keyword.includes(searchTerm) || item.page.includes(searchTerm)
        );
      });

      if (this.sortKey) {
        let modifier = this.sortOrder === "desc" ? 1 : -1;

        if (this.sortKey === "keywordType") {
          data.sort((a, b) => {
            // Determine whether each item is a Top or Supporting keyword
            let aIsTop = +a["keywordData"]["isTopLevelKeyword"];
            let bIsTop = +b["keywordData"]["isTopLevelKeyword"];

            // When sortOrder is "asc", Supporting keywords should come first.
            // When sortOrder is "desc", Top keywords should come first.
            if (aIsTop !== bIsTop) {
              return (bIsTop - aIsTop) * modifier;
            }

            // If both items are in the same group (either both Top or both Supporting)
            // Check if sourceReportType is valid
            let aSourceReportTypeValid = a['sourceReportType'].includes('KeywordWizardResearch');
            let bSourceReportTypeValid = b['sourceReportType'].includes('KeywordWizardResearch');

            // Assign monthlySearch only if sourceReportType is valid, otherwise 0
            let aMonthlySearch = aSourceReportTypeValid ? a["keywordData"]["monthlySearch"] : 0;
            let bMonthlySearch = bSourceReportTypeValid ? b["keywordData"]["monthlySearch"] : 0;

            // Sort by monthlySearch within the same group (Top or Supporting)
            return (bMonthlySearch - aMonthlySearch);
          });
        }



        if (this.sortKey === "pageRunResult.pageRunScore") {
          data.sort((a, b) => {
            let aValue = a["pageRunResult"]["pageRunScore"] ?? "";
            let bValue = b["pageRunResult"]["pageRunScore"] ?? "";
            if (aValue < bValue) return -1 * modifier;
            if (aValue > bValue) return 1 * modifier;
            return 0;
          });
        }
      }

      return data;
    },
    isAllSelected() {
      return this.selectedSiloKeywords.length === this.silos.length
    },
    tableColumns() {
      return [
          {
            key: 'keyword',
            label: 'Keyword',
            value: 'keyword',
          },
          {
            key: 'url',
            label: 'URL',
            downloadButtonValue: (row) => {
              return this.getSiloUrl(row);
            },
          },
          {
            key: 'keywordType',
            label: 'Type',
            downloadButtonValue: (row) => {
              if (row.keywordData.isTopLevelKeyword) {
                return 'Top-Level Keyword'
              }
              if (row.keywordData.isSupportingKeyword) {
                return 'Supporting Keyword'
              }
              return '';
            },
          },

          /* --- VoltageTable columns --- */
          /* ---------------------------- */
          {
            key: 'pageRunResult.pageRunScore',
            label: 'POP Score',
            downloadButtonValue: (row) => {
              if (row.pageRunId && !row.pageRunResult.pageNotBuilt) {
                return row.pageRunResult.pageRunScore
              } else if (row.pageRunId) {
                return 'N/A'
              }
              return '';
            },
          },
          {
            key: 'keywordData.pageOpportunityScore',
            label: 'Opportunity Score',
            downloadButtonValue: (row) => {
              if (row.sourceReportType === 'KeywordWizardResearch') {
                return row.keywordData.pageOpportunityScore;
              }
              return ''
            },
          },
          {
            key: 'keywordData.opportunityScore',
            label: 'Keyword Opportunity Score',
            downloadButtonValue: (row) => {
              if (row.sourceReportType === 'KeywordWizardResearch') {
                return row.keywordData.opportunityScore;
              }
              return ''
            },
          },
          // Opportunity Level only shown if at least one row comes from KeywordWizardResearch
          ...this.sortedTableData.some(row => row.sourceReportType === 'KeywordWizardResearch')
            ?[{
              key: 'keywordData.isBestOpportunityKeyword',
              label: 'Opportunity Level',
              downloadButtonValue: (row) => {
                if (row.sourceReportType === 'KeywordWizardResearch') {
                  if (row.keywordData.isBestOpportunityKeyword) {
                    return 'Best'
                  }
                }
                return ''
              },
            }]
            :[]
          ,
          {
            key: 'keywordData.monthlySearch',
            label: 'Search volume',
            downloadButtonValue: (row) => {
              if (row.sourceReportType === 'KeywordWizardResearch') {
                if (row.keywordData.monthlySearch !== undefined) {
                  return row.keywordData.monthlySearch;
                }
              }
              return ''
            },
          },

          {
            key: 'keywordData.clicks',
            label: 'Clicks',
            downloadButtonValue: (row) => {
              if (row.sourceReportType === 'KeywordWizardResearch') {
                if (row.keywordData.clicks !== undefined) {
                  return row.keywordData.clicks;
                }
              }
              return ''
            },
          },
          {
            key: 'keywordData.impressions',
            label: 'Impressions',
            downloadButtonValue: (row) => {
              if (row.sourceReportType === 'KeywordWizardResearch') {
                if (row.keywordData.impressions !== undefined) {
                  return row.keywordData.impressions;
                }
              }
              return ''
            },
          },
          {
            key: 'keywordData.impressionsShare',
            label: 'Search impressions share %',
            downloadButtonValue: (row) => {
              if (row.sourceReportType === 'KeywordWizardResearch') {
                if (row.keywordData.impressionsShare !== undefined) {
                  return row.keywordData.impressionsShare;
                }
              }
              return ''
            },
          },
          {
            key: 'keywordData.ctr',
            label: 'CTR',
            downloadButtonValue: (row) => {
              if (row.sourceReportType === 'KeywordWizardResearch') {
                if (row.keywordData.ctr !== undefined) {
                  return row.keywordData.ctr;
                }
              }
              return ''
            },
          },
          {
            key: 'keywordData.position',
            label: 'Position',
            downloadButtonValue: (row) => {
              if (row.sourceReportType === 'KeywordWizardResearch') {
                if (row.keywordData.position !== undefined) {
                  return row.keywordData.position;
                }
              }
              return ''
            },
          },

          /* --- KeywordResearchResult columns --- */
          /* ------------------------------------- */
          {
            key: 'keywordData.keywordScore',
            label: 'Keyword score',
            downloadButtonValue: (row) => {
              if (row.keywordData.keywordScore !== undefined) {
                return row.keywordData.keywordScore;
              }
            },
          },
          {
            key: 'keywordData.KGRScore',
            label: 'KGR Score',
            downloadButtonValue: (row) => {
              if (row.keywordData.KGRScore !== undefined) {
                return row.keywordData.KGRScore;
              }
            },
          },
          {
            key: 'keywordData.realCompMajesticTitleAndAnchorComp',
            label: 'SEO Competitiveness Index (SCI)',
            downloadButtonValue: (row) => {
              if (row.keywordData.realCompMajesticTitleAndAnchorComp !== undefined) {
                return row.keywordData.realCompMajesticTitleAndAnchorComp;
              }
            },
          },
          {
            key: 'keywordData.allintitle',
            label: 'All In Title',
            downloadButtonValue: (row) => {
              if (row.keywordData.allintitle !== undefined) {
                return row.keywordData.allintitle;
              }
            },
          },
          {
            key: 'keywordData.broadMatchMonthlySearches_LowEstimate',
            label: 'Monthly searches low',
            downloadButtonValue: (row) => {
              if (row.keywordData.broadMatchMonthlySearches_LowEstimate !== undefined) {
                return row.keywordData.broadMatchMonthlySearches_LowEstimate;
              }
            },
          },
          {
            key: 'keywordData.broadMatchMonthlySearches_HighEstimate',
            label: 'Monthly searches high',
            downloadButtonValue: (row) => {
              if (row.keywordData.keywordScore !== undefined) {
                return row.keywordData.broadMatchMonthlySearches_HighEstimate;
              }
            },
          },
          {
            key: 'keywordData.difficultyScore',
            label: 'Difficulty Score %',
            downloadButtonValue: (row) => {
              if (row.keywordData.difficultyScore !== undefined) {
                return row.keywordData.difficultyScore;
              }
            },
          },
          {
            key: 'keywordData.currentRankGoogle',
            label: 'Current rank(Google)',
            downloadButtonValue: (row) => {
              if (row.keywordData.currentRankGoogle !== undefined) {
                return row.keywordData.currentRankGoogle;
              }
            },
          },
          {
            key: 'keywordData.competitionStrength',
            label: 'Competition strength',
            downloadButtonValue: (row) => {
              if (row.keywordData.competitionStrength !== undefined) {
                return row.keywordData.competitionStrength;
              }
            },
          },
          ...this.isAdmin
          ?[
            {
              key: 'keywordData.estimatedTimeToWin_in_Months',
              label: 'Est. Months to Win',
              downloadButtonValue: (row) => {
                if (row.keywordData.estimatedTimeToWin_in_Months !== undefined) {
                  return row.keywordData.estimatedTimeToWin_in_Months;
                }
              },
            }
          ]:[],
        ];
    },
    hiddenColumnsKeys () {
      const globalValue = this?.user?.settings?.siloResultTableHiddenColumnsKeysPerSite?.[this.siteId]
      if (globalValue === null || globalValue === undefined) {
        return HIDDEN_COLUMNS_KEYS_DEFAULT
      }
      return globalValue
    },
    hideableColumns () {
      return [
        // all columns
        ...this.tableColumns.filter((column) => [
          // except
          'keyword',
          'url',
          'keywordType',
          'keywordData.isBestOpportunityKeyword'
        ].indexOf(column.key) < 0),
      ]
    },
    visibleTableColumns () {
      return this.tableColumns.filter(column => {
        return this.isColumnVisible(column.key)
      })
    },
  },
  methods: {
    isActionMenuVisible(siloKeyword) {
      return (siloKeyword.keywordData.isTopLevelKeyword && (!isEmpty(siloKeyword.guardDiffData) || siloKeyword.aiRunData)) ||
        (siloKeyword.keywordData.isSupportingKeyword && siloKeyword.aiRunData)
    },
    scoreTierToColorStyles(tier) {
      switch (tier) {
        case 0:
          return {'background-color': '#FFD989', 'color': '#014751'}
        case 1:
          return {'background-color': '#C6FFD2CC', 'color': '#014751'}
        case 2:
          return {'background-color': '#88FF9ACC', 'color': '#014751'}
        case 3:
          return {'background-color': '#43EA64CC', 'color': '#014751'}
        case 4:
          return {'background-color': '#00B12ECC', 'color': '#FFFFFF'}
        case 5:
          return {'background-color': '#008F4F', 'color': '#FFFFFF'}
        default:
          return {'background-color': '#999', 'color': '#FFFFFF'}
      }
    },
    keywordScoreToColorStyles (keywordScore) {
      if (keywordScore > 90)
        return { 'background-color': '#006B3E', 'color': '#FFFFFF' }
      else if (keywordScore > 80 && keywordScore <= 90)
        return { 'background-color': '#009254', 'color': '#FFFFFF' }
      else if (keywordScore > 70 && keywordScore <= 80)
        return { 'background-color': '#49BA8B', 'color': '#FFFFFF' }
      else if (keywordScore > 65 && keywordScore <= 70)
        return { 'background-color': '#65DDAB', 'color': '#014751' }
      else if (keywordScore > 60 && keywordScore <= 65)
        return { 'background-color': '#A6EFD1', 'color': '#014751' }
      else if (keywordScore > 50 && keywordScore <= 60)
        return { 'background-color': '#DAFFF0', 'color': '#014751' }
      else
        return { 'color': '#014751' }
    },
    getSiloUrl(siloKeyword) {
      return siloKeyword.keywordData.page || siloKeyword.keywordData.currentRankingUrl;
    },
    createTaskMonitor(taskId) {
      this.guardRunInterval = setInterval(() => {
        fetchTaskResult(taskId).then((response) => {
          if (!response.data) return;

          const { status } = response.data;

          if (['PROGRESS', 'PENDING'].includes(status)) {
            this.$store.commit('incrementSiloReportProgress', 2.5);
          } else if (status == 'SUCCESS') {
            this.clearTaskInterval();
            this.$emit('refreshData');
            this.$store.commit('setSiloReportProgress', 0);
            this.$notify({
              group: 'info',
              type: 'success',
              text: 'SEO Checklist generated successfully.',
            });
          } else if (status == 'FAILURE') {
            this.clearTaskInterval();
            this.$store.commit('setSiloReportProgress', 0);
            this.$notify({
              group: 'info',
              type: 'error',
              text: response.data.msg || 'Failed!',
            });
          }
        });
      }, 5000);
    },
    clearTaskInterval () {
      if (this.guardRunInterval) {
        clearInterval(this.guardRunInterval)
      }
    },
    pageScoreTintColor(score) {
      return pageScoreTintColor(score)
    },
    removeKeyword(keywordId) {
      this.keywordsOngoingRemoval = [...this.keywordsOngoingRemoval, keywordId]
      this.$store.dispatch('removeKeywordFromSilo', keywordId)
      .then(response => {
        if (response?.data?.status === 'SUCCESS') {
          this.$emit('onKeywordRemoveSuccess', keywordId)
          return Promise.resolve()
        }
        this.$notify({
          group: 'info',
          type: 'error',
          text: response?.data?.msg || 'Failed!',
        });
      })
      .finally(() => {
        this.keywordsOngoingRemoval = this.keywordsOngoingRemoval.filter(k => k !== keywordId)
      })
    },
    gotoAiRun(runId) {
      if (!runId) return;

      window
        .open(
          `/#/content-editor?pageRunId=${runId}&editorMode=AI_MODE&selectTargetWC=true`,
          '_blank',
        )
        .focus();
    },
    gotoPageRuns(siloKeyword) {
      if (!siloKeyword.keywordData.isTopLevelKeyword || this.isSelectionDisabled) return;

      this.$store.commit('showLoading');
      this.$store
        .dispatch('createGuardRunFromSilo', siloKeyword.id)
        .then((response) => {
          if (response?.data?.status !== 'SUCCESS') return;

          this.$store.commit('setSiloReportProgress', 10);
          this.createTaskMonitor(response.data.taskId);
        })
        .finally(() => this.$store.commit('hideLoading'));
    },
    gotoNewPopReport(siloKeyword) {
      const url = this.getSiloUrl(siloKeyword);
      const externalData = {
        keyword: siloKeyword.keyword,
        isPageNotBuilt: !url,
        loc: siloKeyword.keywordData.locationCode,
        lang: siloKeyword.keywordData.languageCode,
        url,
        type: 'Silo',
        id: siloKeyword.id,
      };

      this.$router.push({
        name: 'NewPage',
        params: {
          siteId: siloKeyword.siteId,
          externalData,
        }
      });
    },
    gotoPage(url) {
      this.$router.push(url)
    },
    makeGraphData() {
      const topLevel = filter(this.silos, 'keywordData.isTopLevelKeyword')
      const supporting = filter(this.silos, 'keywordData.isSupportingKeyword')

      const mid = Math.ceil(supporting?.length / 2);
      const leftKeywords = supporting?.slice(0, mid) || [];
      const rightKeywords = supporting?.slice(mid) || [];

      const mk = (list) => map(list, 'keyword')
      return { center: topLevel[0].keyword, left: mk(leftKeywords), right: mk(rightKeywords) }
    },
    toggleSelectedSiloKeyword(id) {
      this.$store.commit('toggleSelectedSiloKeyword', id);
    },
    toggleAllSiloKeywords() {
      if (this.isAllSelected) this.$store.commit('resetSelectedSiloKeywords');
      else this.$store.commit('selectAllSiloKeywords', this.silos.map(silo => silo.id));
    },
    handleDefaultSort(a, b) {
      if (a.totalPageImpressions == b.totalPageImpressions) {
        if (a.page == b.page) {
          if (a.impressions == b.impressions) {
            if (a.keyword < b.keyword) return -1;
            if (a.keyword > b.keyword) return 1;
          } else {
            return Number(b.impressions) - Number(a.impressions);
          }
        } else {
          if (a.page < b.page) {
            return -1;
          }
          if (a.page > b.page) {
            return 1;
          }
        }
      } else {
        return Number(b.totalPageImpressions) - Number(a.totalPageImpressions);
      }
    },
    onSort(label, direction) {
      (this.sortKey = label), (this.sortOrder = direction);
    },
    setColumnVisibility(targetKey, visible) {
      const updatedValue = this.hiddenColumnsKeys.filter(key => (
        key !== targetKey
      ))
      if (!visible) {
        updatedValue.push(targetKey)
      }

      const updatedUserSettings = {
        ...this.user.settings,
        siloResultTableHiddenColumnsKeysPerSite: {
          ...this.user.settings.siloResultTableHiddenColumnsKeysPerSite || {},
          [this.siteId]: updatedValue
        }
      }

      // eager update of settings state
      this.$store.commit('setUserSettings', updatedUserSettings)

      // persist in backend
      this.$store.dispatch('updateUserSettings', updatedUserSettings)
    },
    resetColumnVisibility() {
      const updatedUserSettings = {
        ...this.user.settings,
        siloResultTableHiddenColumnsKeysPerSite: {
          ...this.user.settings.siloResultTableHiddenColumnsKeysPerSite || {},
          [this.siteId]: HIDDEN_COLUMNS_KEYS_DEFAULT
        }
      }

      // eager update of settings state
      this.$store.commit('setUserSettings', updatedUserSettings)

      // persist in backend
      this.$store.dispatch('updateUserSettings', updatedUserSettings)
    },
    isColumnVisible(targetKey) {
      return this.hiddenColumnsKeys.indexOf(targetKey) < 0
    },
    loadKeywordTrack () {
      this.$store.dispatch('getKeywordTrackForCampaign', this.campaignId)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.trackedKeywords = response.data.keywordTrack.trackKeywords
        }
      })
    },
    toggleTrackUntrackKeyword (shouldAddToTrack, item) {
      let data = {}
      if (shouldAddToTrack) {
        data = {
          campaignId: this.campaignId,
          trackKeyword: item.keyword,
          trackData: item,
          languageCode: item.keywordData.languageCode,
          locationCode: item.keywordData.locationCode
        }
        this.$store.commit('showLoading');
        this.$store.dispatch('addKeywordToTrack', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.trackedKeywords = response.data.keywordTrack.trackKeywords
            this.$notify({
              group: 'info', type: 'success',
              text: 'Successfully added keyword to track.'
            })
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: response.data.msg || 'Failed to add keyword to the track.'
            })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      } else {
        data = {
          campaignId: this.campaignId,
          trackKeyword: item.keyword,
        }

        this.$store.commit('showLoading');
        this.$store.dispatch('removeKeywordFromTrack', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.trackedKeywords = response.data.keywordTrack.trackKeywords
            this.$notify({
              group: 'info', type: 'success',
              text: 'Successfully removed keyword from track.'
            })
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: 'Failed to remove keyword from the track.'
            })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      }
    },
    selectKeywordForContentReportOrder(row) {
      this.$store.dispatch('updateSiloContentReportOrder', {
        siloId: this.silo.id,
        body: {
          ...row.keywordData.isTopLevelKeyword
            ?{
              selected_top_level_keyword_id: row.id
            }
            :{
              selected_supporting_keyword_ids: (
                [
                  ...(this.silo.content_report_order?.selected_supporting_keyword_ids || []).filter(id => id !== row.id),
                  ...[row.id]
                ]
              )
            }
        }
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.refreshData()
          }
        })
    },
    unselectKeywordForContentReportOrder(row) {
      this.$store.dispatch('updateSiloContentReportOrder', {
        siloId: this.silo.id,
        body: {
          ...row.keywordData.isTopLevelKeyword
            ?{
              selected_top_level_keyword_id: null
            }
            :{
              selected_supporting_keyword_ids: (
                this.silo.content_report_order?.selected_supporting_keyword_ids || []).filter(id => id !== row.id
              )
            }
        }
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.refreshData()
          }
        })
    },
    getKeywordNoteTextFromOrder(id) {
      return (this.silo?.content_report_order?.keyword_notes || []).filter(note => note.keyword_id === id)[0]?.text || ''
    },
    getKeywordNoteTextFromUnsavedEdits(id) {
      return this.editedKeywordNotes.filter(note => note.keyword_id === id)[0]?.text || ''
    },
    getKeywordNoteInputValue(id) {
      if (this.hasUnsavedKeywordNoteChanges(id)) {
        return this.getKeywordNoteTextFromUnsavedEdits(id)
      }
      return this.getKeywordNoteTextFromOrder(id)
    },
    onKeywordNoteTextChange(keywordId, value) {
      this.editedKeywordNotes = [
        ...this.editedKeywordNotes.filter(note => note.keyword_id !== keywordId),
        ...value !== this.getKeywordNoteTextFromOrder(keywordId)
          ?[{keyword_id: keywordId, text: value}]
          :[]
      ]
    },
    hasUnsavedKeywordNoteChanges(keywordId) {
      return this.editedKeywordNotes.filter(note => note.keyword_id === keywordId).length > 0
    },
    saveKeywordNoteChanges(keywordId) {
      this.$store.dispatch('updateSiloContentReportOrder', {
        siloId: this.silo.id,
        body: {
          keyword_note: this.editedKeywordNotes.filter(note => note.keyword_id === keywordId)[0]
        }
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.refreshData()
          }
        })
    },
    refreshData(silent = false) {
      this.$emit('refreshData', silent)
    },
    refreshKeywordTaskMonitors () {
      this.clearAllKeywordTaskIntervals()
      this.silos.forEach(keywordRow => {
        if (keywordRow.keywordResearchDataTaskId) {
          this.createKeywordTaskMonitor(keywordRow.id, keywordRow.keywordResearchDataTaskId)
        }
      })
    },
    createKeywordTaskMonitor (keywordId, taskId) {
        this.keywordTaskIntervals = {

          ...this.keywordTaskIntervals,

          [keywordId]: setInterval(() => {
            this.$store.dispatch('getTaskStatus', taskId)
            .then(response => {
              if (response.data) {
                if (response.data.status == 'PENDING') {
                  // ignore
                  // Celery's PENDING state may as well mean that the task is simply unknwon
                  // (i.e. could be a really pending task, but could also be an invalid/non-existing task id or could be a completed but forgotten task)
                } else if (response.data.status == 'PROGRESS') {
                  // do nothing
                } else if (response.data.status == 'SUCCESS') {
                  this.clearKeywordTaskInterval(keywordId);
                  this.refreshData(true)
                } else if (response.data.status == 'FAILURE') {
                  this.clearKeywordTaskInterval(keywordId);
                  this.refreshData(true)
                }
              }
            })
          }, 3000)
        }
      },
      clearKeywordTaskInterval (keywordId) {
        if (this.keywordTaskIntervals[keywordId]) {
          clearInterval(this.keywordTaskIntervals[keywordId])
          this.keywordTaskIntervals = Object.fromEntries(
            Object.entries(this.keywordTaskIntervals).filter(([key]) => key !== keywordId)
          )
        }
      },
      clearAllKeywordTaskIntervals () {
        Object.entries(this.keywordTaskIntervals).forEach(([id]) => {
          this.clearKeywordTaskInterval(id)
        })
      },
  },
  mounted () {
    this.loadKeywordTrack()
  },
  destroyed () {
    this.clearAllKeywordTaskIntervals()
  }
};
</script>

<style lang="scss" scoped>
@import './silo-research.scss';
.v-menu__content {
  background-color: transparent !important;
  border: none !important; 
  box-shadow: none !important;
  max-width: 300px !important;
}
.disabled-field {
  background-color: var(--v-normalLightGray-base) !important;
  cursor: not-allowed;
  color: #A7A7A7 !important;
}
[role="menu"]:has(.silo-report-menu) {
  box-shadow: 0px 2.563px 20.825px 0px rgba(0, 0, 0, 0.12) !important;
}
.pop-report-menu-item:hover {
  background-color: rgba(161, 161, 161, 0.12) !important;
}

.silo-white-glove-header {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;

  .container-launch {
    display: flex;
    background-color: var(--v-lightGray-base);;
    border-radius: 0.313rem;
    border: 1px solid var(--v-grayColor-base);
  
    .width704 {
      width: 44rem;
    }
  
    .font12 {
      font-size: 0.75rem;
      color: var(--v-primary-base);
      font-weight: 400;
    }
  
    .font20 {
      font-size: 1.25rem;
      font-weight: 800;
      color: var(--v-primary-base);
    }
  
    .font1rem {
      font-size: 1rem !important;
      font-weight: 400 !important;
      color: var(--v-primary-base);
      line-height: 1.6rem;
    }
  
    .font15 {
      font-size: 0.938rem;
      font-weight: 700;
    }

    .height38 {
      height: 2.375rem !important;
    }

    .padding8 {
      padding: 8px 12px !important;
    }
  
    .silo-structure {
      padding-top: 3px;
    }
  
    .cross-container {
      margin-top: -10px;
    }
  }
}


.content-order-keyword-select {
  border-radius: 40px;
  padding: 0 8px !important;

  span {
    font-weight: 700 !important;
  }

  .material-icons {
    margin-right: 0;
  }

  &.selected {
    border: 1.3px solid #00844D;
    background: #EFFFFF;
    color: #00844D !important;
    padding: 0 6px !important;
  }
  
  &.ordered {
    cursor: initial;

    &::before {
      transition: none;
    }

    &:hover, &:focus {
      border-color: initial !important;
      &::before {
        background: none;
        transition: none;
      }
    }

    .material-icons {
      margin-right: 0;
      background: #00AA64;
      color: #ffffff;
      border-radius: 30px;
      font-size: 15px;
      margin-right: 4px;
    }
  }
}

.content-order-keyword-note-btn {
  position: relative;

  .badge {
    position: absolute;
    display: inline-block;
    top: -6px;
    right: -6px;
    background: #FF5700;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 16px;
    font-size: 10px;
  }
}
</style>

<style lang="scss">
.columns-visibility-dropdown {
  .dropdown-menu {
    padding-top: 17px;
  }
  .dropdown-content {
    border-radius: 5px;
    padding: 15px;
    width: 300px;
    border: 1px solid var(--v-grayColor-base);
    background: var(--v-whiteColor-base);
    box-shadow: 0px 2.563px 20.825px 0px rgba(0, 0, 0, 0.12);
  }
}
.material-icons {
  vertical-align: middle;
  font-size: 1rem;
}
</style>
