<template>
  <div class="modal" :class="{'is-active': showModal}">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="subtitle-font mainColor--text">
            <!-- {{ errorMsg }} -->
            <div v-html="errorMsg"></div>
          </div>
          <div class="text-right">
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none"
              @click="showModal = false">
              Ok
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showModal: false,
      reportId: 0,
      errorMsg: '',
    }
  },
  methods: {
    showModalAction(reportId, errorMsg) {
      this.reportId = reportId
      this.errorMsg = errorMsg.replace('\n', '<br />')
      this.showModal = true;
    }
  }
}
</script>