<template>
    <div 
        @keydown.esc="onCancelModal"
    >
        <div class="modal" :class="{ 'is-active': showModal }">
            <div class="modal-background" @click="onCancelModal"></div>
            <div class="modal-content">

                <div class="box modal-container">
                    <div class="p-5 mt-5 mb-5">
                        <div class="card-small-title">
                            {{ modalTitle }}
                        </div>
                        <button class="custom-modal-close-btn" aria-label="close" @click="onCancelModal">
                            <i class="material-icons">close</i>
                        </button>
                        <br />
                        <div v-if="modalType == 1">
                            <br />
                            <v-text-field 
                                :value="reportTitle"
                                @input="updateReportTitle"
                                clearable
                                @keydown.enter="onCloseModal"
                                :autofocus="true"
                                label="Report Name"
                                hide-details="auto"
                                outlined
                            ></v-text-field>
                        </div>
                        <div v-else>
                            <!-- Delete Report -->
                            <p>
                                Are you sure you want to delete this report? This action cannot be undone.
                            </p>
                        </div>
                        <div class="d-flex justify-end gap10 mt-5">
                            <v-btn v-if="modalType == 2" rounded outlined color="mainColor" class="text-none"
                                @click="onCancelModal">Cancel</v-btn>
                            <v-btn rounded color="profileAnchorColor" class="whiteColor--text text-none"
                                @click="onCloseModal">
                                <span v-if="modalType == 1">Save</span>
                                <span v-else>Delete</span>
                            </v-btn>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        modalTitle: {
            type: String,
            required: true,
        },
        modalType: {
            type: Number,
            required: true,
        },
        reportTitle: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            title: this.reportTitle,
        };
    },
    methods: {
        onCancelModal() {
            this.$emit('cancel');
        },
        onCloseModal() {
            this.$emit('close');
        },
        updateReportTitle(value) {
            this.$emit('update-title', value);
        },
    },
};
</script>