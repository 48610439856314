<template>
  <div class="date-only-picker" :key="current">
    <div class="disp-area" @click="toggleShowPickArea">
      {{ current ? current : 'No Date selected.' }}
    </div>
    <div class="pick-area" v-if="showDateSelector" ref="pickAreaRef">
      <div class="pick-frame">
        <div class="pick-label">Pick a day of the month</div>
        <div class="pick-date">
          <div
            v-for="date in dates" :key="date" 
            :class="{ 'current-date': date == current }"
            @click="() => dateSelected(date)">
            {{ date }}   
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: [
    'current', 'from', 'to',
    'onDateSelected'
  ],
  computed: {
    dates () {
      let from = this.from;
      let to = this.to;
      console.log('FFF', from, to)
      if (!isNaN(from) && !isNaN(to)) {
        let dates = [];
        for (let i = from; i <= to; i++) {
          dates.push(i);
        }
        return dates;
      }
      return [];      
    }
  },
  data () {
    return {
      showDateSelector: false,

    }
  },
  methods: {
    toggleShowPickArea () {
      if (!this.showDateSelector) {
        this.showDateSelector = true;
      }
    },
    dateSelected (date) {
      if (this.onDateSelected) {
        this.onDateSelected(date);
        this.showDateSelector = false;
      }
    },
    handleOutsideClick (event) {
      if (this.$refs.pickAreaRef && !this.$refs.pickAreaRef.contains(event.target)) {
        console.log('outside is clicked.')
        this.showDateSelector = false;
      }
    }
  },
  mounted () {
    document.addEventListener('mouseup', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('mouseup', this.handleOutsideClick);
  },
}
</script>


<style lang="scss" scoped>
.date-only-picker {
  border: 1px solid rgb(210, 210, 210);
  background: white;
  border-radius: 5px;
  padding: 5px 5px;
  position: relative;
}

.disp-area {
  cursor: pointer;
}
.pick-area {
  position: absolute;
  z-index: 9999;
  // height: 100px;
  top: 110%;
  left: 0;
  width: 290px;
  
  .pick-frame {
    background: white;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 30px;
  }

  .pick-label {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 20px 0;
  }
  .pick-date {
    
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;

    & > * {
      cursor: pointer;
      color: black;
      border-radius: 3px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      transition: all 0.1s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    .current-date {
      background: var(--v-mainColor-base);
      color: var(--v-whiteColor-base);
    }
  }
}

</style>