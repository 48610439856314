import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import keywordTrackingLogo from "../../public/static/keyword-tracking-logo.png"
import topLogo from "../../public/static/ki-report-logo.png"

function getBase64FromUrl(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function() {
    const reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export const mixin = {
  data () {
    return {
      bgImgCode: null,
      reportLogo: null
    }
  },
  methods: {
    exportToCSV(csvData, fileName) {
      let downloadLink;
  
      let csvFile = new Blob([csvData], {type: "text/csv"});
      downloadLink = document.createElement("a");
      downloadLink.download = fileName;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    exportToHtml(data, fileName) {
      let downloadLink;
  
      let file = new Blob([data], {type: "text/plain;charset=utf-8"});
      downloadLink = document.createElement("a");
      downloadLink.download = fileName;
      let url = window.URL.createObjectURL(file);
      downloadLink.href = url
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.URL.revokeObjectURL(url)
    },
    generatePDF(columns, rows, fileName) {
      const doc = new jsPDF();
      doc.setDrawColor(0);
      doc.addImage(this.bgImgCode, 'png', 0, 0, doc.internal.pageSize.getWidth(), 51)
      doc.addImage(this.reportLogo, 'png', 14, 10, 8, 8)

      doc.setTextColor(255, 255, 255);
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text('POP', 14, 30);
      doc.setTextColor(255, 87, 0);
      doc.text('Keyword Tracking', 29, 30);
      doc.setTextColor(255, 255, 255);
      doc.text('Report', 85, 30);
      doc.setFontSize(12);
      doc.text('Track the keywords that you and your competitors are ranking for.', 14, 40);

      const tableColumn = columns.map(col => col.toUpperCase());

      doc.autoTable({
        startY: 55,
        head: [tableColumn],
        body: rows,
        styles: { fontSize: 6 },
        margin: { left: 5, right: 5, top: 5, bottom: 5 },
        theme: 'grid',
        bodyStyles: {
          lineColor: [0, 0, 0, 0]
        },
        willDrawCell: function(data) {
          const doc = data.doc;
          const row = data.row;
          doc.setLineWidth(0);

          if (data.section === 'head') {
            doc.setFillColor(255, 87, 0);
          }
          if (data.section === 'body' && row.index % 2 === 0) {
            doc.setFillColor(220, 220, 220);
          }
        }
      });

      const timestamp = moment().format('YYYY_M_D_h_mm_ss')
      doc.save(`${fileName}_${timestamp}.pdf`);
    },
    generateTableToPDF(domSelector, fileName) {
      // let table = document.querySelector(`#${domSelector}`);
      let SPAN_SIZE = 60;
      // let width = table.clientWidth + SPAN_SIZE * 2;
      let width = 1920;
      let height = width * 0.7;
    
      console.log('W_H', width, height);
      // return;
    
    
      let doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [ width, height ],
      })
    
      doc.autoTable({
        html: `#${domSelector}`,
        startY: 90,
        margin: {
          top: 90,
          bottom: 80
        },
        // horizontalPageBreak: true,
        // horizontalPageBreakRepeat: 0,
        styles: {
          fontSize: 15,
          cellWidth: 'auto',
        },
        headStyles: {
          cellWidth: 'auto',
        },
        columnStyles: {
          0: {cellWidth: 'auto'}
        },
        didDrawPage: function(data) {
          doc.setFontSize(40);
          doc.text(fileName, SPAN_SIZE, SPAN_SIZE);
          doc.setFontSize(25);
          doc.setTextColor('gray');
          doc.text(`${ (new Date()).toLocaleString() }`, SPAN_SIZE, height - SPAN_SIZE);
        }
      });
      doc.save(`${fileName}_${(new Date()).toLocaleDateString()}.pdf`);
    }
  },
  mounted () {
    getBase64FromUrl(keywordTrackingLogo, (base64) => {
      this.bgImgCode = base64;
    });
    getBase64FromUrl(topLogo, (base64) => {
      this.reportLogo = base64;
    });
  }
}