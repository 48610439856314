<template>
<div class="limited-width-box">
<v-card class="is-fullheight flex flex-column white-common-box">
  <div>
    
    
    <div class="pa-5 flex flex-column">
      <div class="title-font mainColor--text box-title-label">
        Change email
      </div>

      <div class="notify-label">
        <i class="material-icons">error_outline</i>
        You will receive a verification email
      </div>

      <v-text-field dense
        type="password"
        label="Your password"
        color="greenColor"
        v-model="password"
        hide-details="auto"
        outlined
      ></v-text-field>

      <v-text-field dense
        hide-details="auto"
        label="New email"
        color="greenColor"
        v-model="newEmail"
        outlined
      ></v-text-field>
      <div>
        <v-btn rounded
          color="lightGreenColor"
          class="button-with-icon text-none white--text" @click="updateEmail" :disabled="!isReadyToChangeEmail">
          Change email
        </v-btn>
      </div>
    </div>
  </div>
</v-card>
</div>
</template>

<script>

export default {
  data () {
    return {

      password: '',
      newEmail: '',
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isReadyToChangeEmail () {
      return this.password && this.newEmail && this.newEmail != this.user.email
    }
  },
  methods: {
    // show () {
    //   this.showNow = true
    // },

    updateEmail () {
      console.log('Performing update email action')

      let data = {
        currentEmail: this.user.email,
        newEmail: this.newEmail,
        password: this.password
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('changeEmailRequest', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          window.alert('Confirmation email sent.  Please check your new email\'s inbox.')
          this.logout()
        } else {
          if (response.data.msg)           {
            window.alert(response.data.msg)
          }
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    logout () {
      this.$store.commit('clearStore')
      this.$router.push('/login')
    },
  },
  beforeMount () {
    this.newEmail = this.user.email
  }
}

</script>

<style lang="scss" scoped>
* {
  color: var(--v-mainColor-base);
}
.v-input.v-input--hide-details {
  margin-bottom: 24px !important;
}
.theme--light.v-label {
  color: red !important;
  background: blue !important;
  /* opacity: 1; */
}


</style>