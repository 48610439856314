import { gapi } from 'gapi-script';
import LocationsData from '@/consts/dataforseo_locations.json';
import moment from 'moment';
import { getCountryData } from 'countries-list'

const { locations } = LocationsData


export const GOOGLE_OAUTH_SCOPE_DEF = 'https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/webmasters.readonly'
// CLIENT_SECRET   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


export const loadGAPIClient = async function () {
  // await loadClientAuth2(gapi, GOOGLE_CLIENT_ID, GOOGLE_OAUTH_SCOPE_DEF);
  gapi.load('client', () => {
    gapi.client.init({
      client_id: GOOGLE_CLIENT_ID,
      scope: GOOGLE_OAUTH_SCOPE_DEF
    })
    if (gapi.client) {
      gapi.client.setApiKey(GOOGLE_API_KEY);
      return gapi.client.load("https://content.googleapis.com/discovery/v1/apis/searchconsole/v1/rest")
        .then(function () { console.log("GAPI client loaded for API"); },
          function (err) { console.error("Error loading GAPI client for API", err); });
    }
  });
}



function getOriginFromUrl(url) {
  try {
    const { origin } = new URL(url);
    return origin
  } catch (e) {
    console.log('getDomain', e)
    return ''
  }
}
export const getCountryCode_ISO3 = (location_code) => {
  const loc = locations.find(l => l.location_code == location_code)
  if (loc) {
    const country = getCountryData(loc.country_iso_code)
    if (country) {
      return country.iso3
    }
  }
  return 'USA'
}


const VOLTAGE_LEVELS = [
  0, 10, 20, 50, 100, 200, 500, 1000, 1500, 2000, 3000, 4000, 5000, 7500, 10000, 12500, 15000, 25000
]
function calculateVoltageLevelWithImpressions(rows) {
  let minImpressions = 99999;
  let maxImpressions = 0;
  for (let i = 0; i < rows.length; i++) {
    let impressions = rows[i].impressions
    if (impressions > maxImpressions) maxImpressions = impressions
    if (impressions < minImpressions) minImpressions = impressions
  }
  let average = (minImpressions + maxImpressions) / 2
  for (let i = 0; i < VOLTAGE_LEVELS.length - 1; i++) {
    if (VOLTAGE_LEVELS[i] < average && VOLTAGE_LEVELS[i + 1] >= average) {
      return {
        average: average,
        levelMin: VOLTAGE_LEVELS[i],
        levelMax: VOLTAGE_LEVELS[i + 1],
      }
    }
  }
  return 0
}

const makeNotContainKeywordFilter = (keywords) => {
  let notContainRestriction = []
  keywords.map((keyword) => {
    notContainRestriction.push(
      {
        "dimension": "QUERY",
        "operator": "NOT_CONTAINS",
        "expression": keyword
      }
    )
  })
  return notContainRestriction
}

export const calcCannibalization = (average_impressions, current_impressions) => {
  const minimum_impressions = average_impressions * 0.25
  if (current_impressions >= average_impressions) {
    return 'high'
  } else if (current_impressions < average_impressions && current_impressions >= minimum_impressions) {
    return 'medium'
  } else {
    return 'low'
  }
}

export const calculateVoltageLevel = async (targetUrl, location, brandedKeywords) => {
  try {

    let locationCode = getCountryCode_ISO3(location)

    let notContainRestriction = makeNotContainKeywordFilter(brandedKeywords)


    return new Promise((resolve, reject) => {
      gapi.client.webmasters.searchanalytics.query({
        "siteUrl": getOriginFromUrl(targetUrl),
        "resource": {
          "startDate": moment().subtract(3, 'month').format('YYYY-MM-DD'),
          "endDate": moment().format('YYYY-MM-DD'),
          "dimensions": [
            "DATE"
          ],
          "rowLimit": 25000,
          "dimensionFilterGroups": [
            {
              "filters": [
                {
                  "dimension": "COUNTRY",
                  "expression": locationCode
                },
                ...notContainRestriction
              ]
            }
          ]
        }
      })
        .then(
          function (response) {
            // Handle the results here (response.result has the parsed body).

            let voltageLevel = calculateVoltageLevelWithImpressions(response?.result?.rows || [])
            resolve(voltageLevel)
          },
          function (err) {
            console.error("Execute error", err);
            reject(err);
          }
        );
    })

  } catch (e) {
    console.log(e);
    throw new Error('Failed')
  }
}

export const getSitesList = () => {
  return gapi.client?.webmasters?.sites?.list({})
}
export const searchAnalyticsForSearchConsole = (targetUrl, location, brandedKeywords, dateRanges) => {
  try {

    if (!targetUrl.endsWith('/')) {
      targetUrl += '/'
    }

    let locationCode = getCountryCode_ISO3(location)
    let notContainRestriction = makeNotContainKeywordFilter(brandedKeywords)

    let targetPageFilter = []
    targetPageFilter.push({
      "dimension": "PAGE",
      "operator": "EQUALS",
      "expression": targetUrl
    })

    return new Promise((resolve, reject) => {
      gapi.client.webmasters.searchanalytics.query({
        "siteUrl": getOriginFromUrl(targetUrl),
        "resource": {
          "startDate": dateRanges.startDate.format('YYYY-MM-DD'),
          "endDate": dateRanges.endDate.format('YYYY-MM-DD'),
          "dimensions": [
            "QUERY"
          ],
          "rowLimit": 25000,
          "dimensionFilterGroups": [
            {
              "filters": [
                {
                  "dimension": "COUNTRY",
                  "expression": locationCode
                },
                ...notContainRestriction,
                ...targetPageFilter,
              ]
            }
          ]
        }
      })
        .then(
          function (response) {
            // Handle the results here (response.result has the parsed body).

            let result = response?.result?.rows || []
            resolve(result)
          },
          function (err) {
            console.error("Execute error", err);
            reject(err);
          }
        );
    })

  } catch (e) {
    console.log(e);
    throw new Error('Failed')
  }
}


export const searchAnalyticsForSearchConsole_byPage = (targetUrl, location, dateRanges) => {
  try {

    let locationCode = getCountryCode_ISO3(location)
    // let brandedKeywords = []
    // let notContainRestriction = makeNotContainKeywordFilter(brandedKeywords)

    return new Promise((resolve, reject) => {
      gapi.client.webmasters.searchanalytics.query({
        "siteUrl": getOriginFromUrl(targetUrl),
        "resource": {
          "startDate": dateRanges.startDate.format('YYYY-MM-DD'),
          "endDate": dateRanges.endDate.format('YYYY-MM-DD'),
          "dimensions": [
            "PAGE",
            "QUERY"
          ],
          "rowLimit": 25000,
          "dimensionFilterGroups": [
            {
              "filters": [
                {
                  "dimension": "COUNTRY",
                  "expression": locationCode
                },
              ]
            }
          ]
        }
      })
        .then(
          function (response) {
            // Handle the results here (response.result has the parsed body).

            let result = response?.result?.rows || []
            console.log("result", result)

            result = result.map(r => {
              if (r.keys.length == 2) {
                r['url'] = r.keys[0]
                r['keyword'] = r.keys[1]
                delete r.keys
              }
              return r
            })

            resolve(result)
          },
          function (err) {
            console.error("Execute error", err);
            reject(err);
          }
        );
    })

  } catch (e) {
    console.log(e);
    throw new Error('Failed')
  }
}