<template>
  <div class="mt10" v-if="hasData">
    <div>
      <div class="column">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5">
            <div class="d-flex">
              <h3 class="flex-grow-1 section-name">
                Page Structure
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
                  </template>
                  <div class="white-bg-tooltip" style="max-width: 400px;">
                    These things probably won't affect rank but will help you create a better page
                  </div>
                </v-tooltip>
              </h3>
              <a v-if="!isCollapsePageStructureArea" @click.stop="isCollapsePageStructureArea=true"><span class="material-icons fold_icon" >unfold_less</span></a>
              <a v-else @click.stop="isCollapsePageStructureArea=false"><span class="material-icons fold_icon">unfold_more</span></a>
            </div>
          </div>
          <div class="flex-grow-1 px-5 pb-5 flex" v-if="!isCollapsePageStructureArea">
            <div class="flex flex-column">
              <div class="flex-grow-1">
                <table class="page-structure-table">
                  <thead>
                    <tr>
                      <th>Element</th>
                      <th>Current usage</th>
                      <th>Recommended range</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in filteredPageStructureRecommendations" :key="idx">
                      <td>{{ item.label }}</td>
                      <td>
                        {{ item.current }}
                      </td>
                      <td>
                        {{ item.recommend }}
                        <v-tooltip bottom color="transparent" v-if="item.label == 'Links'">
                          <template v-slot:activator="{ on, attrs }">
                            <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
                          </template>
                          <div class="white-bg-tooltip small-tooltip">
                            These can be  links to another page on you site or links to an authority site.  You could also do jump links on your page or a Table of contents.
                          </div>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="has-text-right mt10">
                <v-btn
                  plain small
                  color="grey-lighten-5"
                  class="text-none"
                  @click.stop="showMorePageStructure = !showMorePageStructure">
                  {{ showMorePageStructure ? 'Show Less' : 'Show more' }}
                  <i v-if="showMorePageStructure" class="material-icons">arrow_drop_up</i>
                  <i v-else class="material-icons">arrow_drop_down</i>
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div class="column">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5">
            <div class="d-flex">
              <h3 class="flex-grow-1 section-name">
                Competitor Titles
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
                  </template>
                  <div class="white-bg-tooltip" style="max-width: 400px;">
                    These are the page titles of your competitors that are displayed in Google.  Use these titles for inspiration on creating a title that Google will like.
                  </div>
                </v-tooltip>
              </h3>
              <a v-if="!isCollapseCompetitorTitlesArea" @click.stop="isCollapseCompetitorTitlesArea=true"><span class="material-icons fold_icon" >unfold_less</span></a>
              <a v-else @click.stop="isCollapseCompetitorTitlesArea=false"><span class="material-icons fold_icon">unfold_more</span></a>
            </div>
          </div>
          <div class="flex-grow-1 px-5 pb-5 flex" v-if="!isCollapseCompetitorTitlesArea">
            <div class="flex flex-column">
              <div class="flex-grow-1">
                <ul class="competitor-titles-ul">
                  <li v-for="(gCompetitor, i) in filteredGCompetitors" :key="`${i}-google-compeitor-title`">
                    <a :href="gCompetitor.url" target="_blank">
                      {{ gCompetitor.title }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="has-text-right mt10" v-if="gCompetitors.length > 5">
                <v-btn
                  plain small
                  color="grey-lighten-5"
                  class="text-none"
                  @click.stop="showMoreGCompetitors = !showMoreGCompetitors">
                  {{ showMoreGCompetitors ? 'Show Less' : 'Show more' }}
                  <i v-if="showMoreGCompetitors" class="material-icons">arrow_drop_up</i>
                  <i v-else class="material-icons">arrow_drop_down</i>
              </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div class="column">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5">
            <div class="d-flex">
              <h3 class="flex-grow-1 section-name">
                Related Keywords
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
                  </template>
                  <div class="white-bg-tooltip" style="max-width: 400px;">
                    If relevant, use these keywords where you can on your page.  If the related keyword is important, consider using it in a subtitle.
                  </div>
                </v-tooltip>
              </h3>
              <a v-if="!isCollapseRelatedKeywordsArea" @click.stop="isCollapseRelatedKeywordsArea=true"><span class="material-icons fold_icon" >unfold_less</span></a>
              <a v-else @click.stop="isCollapseRelatedKeywordsArea=false"><span class="material-icons fold_icon">unfold_more</span></a>
            </div>
          </div>
          <div class="px-5 pb-5" v-if="!isCollapseRelatedKeywordsArea">
            <ul class="competitor-titles-ul">
              <li v-for="(relatedSearch, i) in filteredRelatedSearches" :key="`${i}-related-search`">
                <a :href="relatedSearch.link" target='_blank'>{{ relatedSearch.query }}</a>
              </li>
              <li v-if="filteredRelatedSearches.length == 0" class="has-text-grey is-italic">We checked but Google didn't give any Related Keywords.</li>
            </ul>
            <div class="has-text-right mt10" v-if="relatedSearches.length > 5">
              <v-btn
                plain small
                color="grey-lighten-5"
                class="text-none"
                @click.stop="showMoreRelatedSearches = !showMoreRelatedSearches">
                {{ showMoreRelatedSearches ? 'Show Less' : 'Show more' }}
                <i v-if="showMoreRelatedSearches" class="material-icons">arrow_drop_up</i>
                <i v-else class="material-icons">arrow_drop_down</i>
            </v-btn>
            </div>
          </div>
        </v-card>
      </div>
      <div class="column">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5">
            <div class="d-flex">
              <h3 class="flex-grow-1 section-name">
                Related Questions
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
                  </template>
                  <div class="white-bg-tooltip" style="max-width: 400px;">
                    Use these questions to provide additional related content.  Use them on your page if you need to expand your content.  You can also use them to create related pages on your site and link those pages to your target page.
                  </div>
                </v-tooltip>
              </h3>
              <a v-if="!isCollapseRelatedQuestionsArea" @click.stop="isCollapseRelatedQuestionsArea=true"><span class="material-icons fold_icon" >unfold_less</span></a>
              <a v-else @click.stop="isCollapseRelatedQuestionsArea=false"><span class="material-icons fold_icon">unfold_more</span></a>
            </div>
          </div>
          <div class="px-5 pb-5" v-if="!isCollapseRelatedQuestionsArea">
            <ul class="competitor-titles">
              <li v-for="(relatedQuestion, i) in filteredRelatedQuestions" :key="`${i}-related-question`">
                <div class="related-question-item">
                  <div>
                    {{ relatedQuestion.question }}
                  </div>
                  <a @click.stop="toggleQuestion(relatedQuestion)">
                    <span v-show='expandedQuestion !== relatedQuestion.title' class="material-icons fold-icon">expand_more</span>
                    <span v-show='expandedQuestion === relatedQuestion.title' class="material-icons fold-icon">expand_less</span>
                  </a>
                </div>
                <div v-show='expandedQuestion === relatedQuestion.title' class="question-detail">
                  <div>{{ relatedQuestion.snippet }}</div>
                  <p>
                    <a :href="relatedQuestion.link" target="_BLANK">{{ relatedQuestion.displayed_link }}</a>
                  </p>
                </div>
              </li>
            </ul>
            <div class="has-text-right mt10" v-if="relatedQuestions.length > 5">
              <v-btn
                plain small
                color="grey-lighten-5"
                class="text-none"
                @click.stop="showMoreRelatedQuestions = !showMoreRelatedQuestions">
                {{ showMoreRelatedQuestions ? 'Show Less' : 'Show more' }}
                <i v-if="showMoreRelatedQuestions" class="material-icons">arrow_drop_up</i>
                <i v-else class="material-icons">arrow_drop_down</i>
            </v-btn>
            </div>
            <div v-if="!relatedQuestions || relatedQuestions.length == 0" class="has-text-grey is-italic">
              We tried but we couldn't get the AI to generate a question.
            </div>
          </div>
        </v-card>
      </div>
      <div class="column">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5 border-bottom">
            <div>
              <h3 class="has-text-weight-bold is-size-6 mainColor--text flex-grow-1">
                Competitor H2s
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
                  </template>
                  <div class="white-bg-tooltip" style="max-width: 400px;">
                    Use these H2s for inspiration for the different sections on your page.
                  </div>
                </v-tooltip>
              </h3>
              <div class="mainColor--text">These are all the H2 tags on the pages of your competitors</div>
            </div>
            <!-- <a v-if="!isCollapseRelatedQuestionsArea" @click.stop="isCollapseRelatedQuestionsArea=true"><span class="material-icons fold_icon" >unfold_less</span></a>
            <a v-else @click.stop="isCollapseRelatedQuestionsArea=false"><span class="material-icons fold_icon">unfold_more</span></a> -->
          </div>
          <div class="pa-5">
            <ul class="competitor-titles">
              <li v-for="(c, i) in (showMoreCompetitorsForH2 ? competitors : competitors.slice(0, 5))" :key="`${i}-related-question`">
                <div class="related-question-item">
                  <div>
                    {{ c.url }}
                  </div>
                  <a @click.stop="() => {
                    if (showH2CompetitorId == c.id) {
                      showH2CompetitorId = 0
                    } else {
                      showH2CompetitorId = c.id
                    }
                  }">
                    <span v-show='showH2CompetitorId !== c.id' class="material-icons fold-icon">expand_more</span>
                    <span v-show='showH2CompetitorId === c.id' class="material-icons fold-icon">expand_less</span>
                  </a>
                </div>
                <div v-show='showH2CompetitorId == c.id' class="">
                  <div v-if="!c.h2Texts || c.h2Texts.length == 0" class="no-h2-or-h3-item">
                    This competitor doesn’t have any H2.
                  </div>
                  <template v-else>
                    <div v-for="(h2, j) in c.h2Texts" :key="`${i}-${j}-h2-list`" class="h2_3-item">
                      <span class="h2-mark">H2</span>
                      <span class="text">{{ h2 }}</span>
                    </div>
                  </template>              
                </div>
              </li>
            </ul>
            <div class="has-text-right mt10" v-if="competitors.length > 5">
              <v-btn
                plain small
                color="grey-lighten-5"
                class="text-none"
                @click.stop="showMoreCompetitorsForH2 = !showMoreCompetitorsForH2">
                {{ showMoreCompetitorsForH2 ? 'Show Less' : 'Show more' }}
                <i v-if="showMoreCompetitorsForH2" class="material-icons">arrow_drop_up</i>
                <i v-else class="material-icons">arrow_drop_down</i>
            </v-btn>
            </div>
          </div>
        </v-card>
      </div>
      <div class="column">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5 border-bottom">
            <div>
              <h3 class="has-text-weight-bold is-size-6 mainColor--text flex-grow-1">
                Competitor H3s
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
                  </template>
                  <div class="white-bg-tooltip" style="max-width: 400px;">
                    Use these H3s for inspiration for the different sections on your page.
                  </div>
                </v-tooltip>
              </h3>
              <div class="mainColor--text">These are all the H3 tags on the pages of your competitors</div>
            </div>
            <!-- <a v-if="!isCollapseRelatedQuestionsArea" @click.stop="isCollapseRelatedQuestionsArea=true"><span class="material-icons fold_icon" >unfold_less</span></a>
            <a v-else @click.stop="isCollapseRelatedQuestionsArea=false"><span class="material-icons fold_icon">unfold_more</span></a> -->
          </div>
          <div class="pa-5">
            <ul class="competitor-titles">
              <li v-for="(c, i) in (showMoreCompetitorsForH3 ? competitors : competitors.slice(0, 5))" :key="`${i}-related-question`">
                <div class="related-question-item">
                  <div class="word-break-all">
                    {{ c.url }}
                  </div>
                  <a @click.stop="() => {
                    if (showH3CompetitorId == c.id) {
                      showH3CompetitorId = 0
                    } else {
                      showH3CompetitorId = c.id
                    }
                  }">
                    <span v-show='showH3CompetitorId !== c.id' class="material-icons fold-icon">expand_more</span>
                    <span v-show='showH3CompetitorId === c.id' class="material-icons fold-icon">expand_less</span>
                  </a>
                </div>
                <div v-show='showH3CompetitorId == c.id' class="">
                  <div v-if="!c.h3Texts || c.h3Texts.length == 0"  class="no-h2-or-h3-item">
                    This competitor doesn’t have any H3.
                  </div>
                  <template v-else>
                    <div v-for="(h3, j) in c.h3Texts" :key="`${i}-${j}-h3-list`" class="h2_3-item">
                      <span class="h3-mark">H3</span>
                      <span class="text">{{ h3 }}</span>
                    </div>
                  </template>       
                </div>
              </li>
            </ul>
            <div class="has-text-right mt10" v-if="competitors.length > 5">
              <v-btn
                plain small
                color="grey-lighten-5"
                class="text-none"
                @click.stop="showMoreCompetitorsForH3 = !showMoreCompetitorsForH3">
                {{ showMoreCompetitorsForH3 ? 'Show Less' : 'Show more' }}
                <i v-if="showMoreCompetitorsForH3" class="material-icons">arrow_drop_up</i>
                <i v-else class="material-icons">arrow_drop_down</i>
            </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>

import { mixin as cb_mixin} from './cb_mixin'

export default {
  props: [
    'selectedMainTab',
    'createdAt', 'url', 'keyword', 'pageRunId', 'pageNotBuilt', 'page', 'ignoreSpaces', 'willUseSecretKey', 'secretKey', 'contentBrief', 'runStrategies', 'tagTotalData', 'wordCountData',
    'gCompetitors', 'relatedSearches', 'relatedQuestions',
    'contentBriefFile', 'isFromExtension', 'showAsMobileView',
    'hideActionButtons', 'excludedTermsByUser', 'competitors',
  ],
  mixins: [cb_mixin],

  computed: {
    hasData () {
      return !! this.gCompetitors || !!this.relatedSearches
    },
    filteredGCompetitors () {
      let count = 0
      return this.gCompetitors.filter(item => {
        count ++;
        switch (this.showMoreGCompetitors) {
          case true:
            if (count <=10) return true; else return false;
          case false:
            if (count <=7) return true; else return false;
          default:
            break;
        }
      })
    },
    filteredRelatedSearches () {
      let count = 0;
      return this.relatedSearches.filter(item => {
        if (this.showMoreRelatedSearches) return true;
        count++;
        if (count<=5) return true; else return false;
      })
    },
    filteredRelatedQuestions () {
      return this.relatedQuestions.filter((item, i) => {
        if (this.showMoreRelatedQuestions) return true;
        return i <= 5;
      })
    },
  },

  data () {
    return {
      expandedQuestion: '',
      showMorePageStructure: false,
      showMoreGCompetitors: false,
      showMoreRelatedSearches: false,
      showMoreRelatedQuestions: false,

      isCollapsePageStructureArea: false,
      isCollapseCompetitorTitlesArea: false,
      isCollapseRelatedKeywordsArea: false,
      isCollapseRelatedQuestionsArea: false,

      showH2CompetitorId: 0,
      showMoreCompetitorsForH2: false,

      showH3CompetitorId: 0,
      showMoreCompetitorsForH3: false,
    }
  },
  methods: {
    toggleQuestion (q) {
      this.expandedQuestion = this.expandedQuestion === q.title ? '' : q.title
    },

    getStatusClassForPageStructureItem(item) {
      if (item.current > item.targetMax) {
        return { over: true }
      } else if (item.current < item.targetMin) {
        return { lower: true }
      } else {
        return { good: true }
      }
    }
    
  }
}
</script>

<style lang="scss" scoped>
@import "./content-brief.scss";

.section-name {
  font-weight: 800;
  font-size: 17px;
  color: var(--v-mainColor-base);
}
.page-structure-table {
  width: 100%;
  th {
    padding: 12px 0px;
    color: var(--v-darkGrayColor-base);
  }
  td {
    border-top: 1px solid var(--v-gray8Color-base);
    padding: 12px 0px;
    color: var(--v-mainColor-base);
    font-weight: bold;
  }
}
ul.competitor-titles-ul {
    padding-left: 0;
  }
  li {
    padding: 10px 0px;
    border-bottom: 1px solid var(--v-gray8Color-base);
    a {
      font-size: 15px;
      font-weight: bold;
      color: var(--v-link1Color-base);
    }
  }
</style>