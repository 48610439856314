<template>
  <div class="table-pdf-csv-download">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          color="mainColor"
          class="button-with-icon text-none"
          v-bind="attrs"
          v-on="on"
        >
          <i class="material-icons-outlined">file_download</i>
          <span>Download</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="generatePDF">
          <v-list-item-title>PDF</v-list-item-title>
        </v-list-item>
        <v-list-item @click="createDocx">
          <v-list-item-title>Word</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import moment from 'moment';
import eeatPdfHeader from '../../../../public/static/eeat-pdf-header.png';
import bulbIcon from '../../../../public/static/bulb-icon.png';
import eeatWorldIcon from '../../../../public/static/eeat-world-icon.png';
import eeatKeywordWarningIcon from '../../../../public/static/eeat-keyword-warning-icon.png';
import eeatKeywordPresentIcon from '../../../../public/static/eeat-keyword-present-icon.png';
import wordDocHeadImg from '../../../../public/static/word-doc-head-image.png';
import { generateEEATWordReport, getReportHeaderImage } from '../../../utils/download-reports';

function getBase64FromUrl(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export default {
  props: ['data', 'descriptionObj', 'targetUrl'],
  data() {
    return {
      wordDocHeadImg: null,
      bulbIcon: null,
      eeatWorldIcon: null,
      eeatKeywordWarningIcon: null,
      eeatKeywordPresentIcon: null,
      reportLogo: null,
    };
  },
  methods: {
    generatePDF() {
      getReportHeaderImage(this.$store, "eeat").then((responseData) => {
        const doc = new jsPDF();
        doc.setDrawColor(0);
        doc.setFillColor(1, 35, 35);
        const startX = 14;
        let currentY = 10;
        doc.addImage(responseData.headerImg, 'png', startX, currentY, 182, 71);
        currentY += 70;

        const bulletPoints = [
          {
            text: [
              { text: 'While ticking off Google\'s EEAT items (as addressed in ', style: {} },
              { text: 'Google\'s Quality Rater ', style: { fontStyle: 'bold', textColor: '#FF5700', isLink: true, link: 'https://static.googleusercontent.com/media/guidelines.raterhub.com/en//searchqualityevaluatorguidelines.pdf' } },
              { text: 'Guidelines', style: { fontStyle: 'bold', textColor: '#FF5700', isLink: true, link: 'https://static.googleusercontent.com/media/guidelines.raterhub.com/en//searchqualityevaluatorguidelines.pdf' } },
              { text: ') on your website may not directly benefit rankings, by missing too many of', style: {} },
              { text: 'these signals you', style: {} },
              { text: 'risk being ignored by Google', style: { fontStyle: 'bold' } },
              { text: ', which will obviously affect your ability to', style: {} },
              { text: 'generate SEO traffic.', style: {} },
            ]
          },
          {
            text: [
              { text: 'In essence, Google is trying to decipher whether you are a', style: {} },
              { text: ' real business', style: { fontStyle: 'bold' } },
              { text: 'whether you are', style: {} },
              { text: 'who you say you are and that you can be trusted to deliver what you say you are going to', style: {} },
              { text: 'deliver.', style: {} },
            ]
          },
          {
            text: [
              { text: 'We have found the EEAT signals below on ', style: {} },
              { text: 'your top competitor\'s', style: { fontStyle: 'bold' } },
              { text: 'websites and have', style: {} },
              { text: 'tagged them as being either present or missing from your own website.', style: {} },
            ]
          },
          {
            text: [
              { text: 'We have given a description of each signal and your job is to try and ', style: {} },
              { text: 'implement', style: { fontStyle: 'bold' } },
              { text: 'as many', style: {} },
              { text: 'of the missing signals as possible. Most of them are very easy and can be', style: {} },
              { text: 'knocked off in a matter of minutes.', style: {} },
            ]
          }
        ];

        let rows = [
          [
            {
              content: '',
              styles: {
                minCellWidth: 30,
                minCellHeight: 20,
                cellPadding: 1,
                halign: 'right',
                valign: 'middle',
                fillColor: '#FF5700',
                textColor: '#FFFFFF',
              },
            },
            {
              content: 'Instructions',
              styles: {
                minCellWidth: 5,
                minCellHeight: 20,
                halign: 'left',
                cellPadding: 1,
                fillColor: '#FF5700',
                fontSize: 22.65,
                fontStyle: 'bold',
                textColor: '#FFFFFF',
              },
            },
          ],
          [
            {
              colSpan: 2,
              content: '',
              styles: {
                fillColor: '#FFEDDD',
                minCellHeight: 80,
                fontSize: 12,
                lineHeight: 20,
                fontStyle: 'normal',
                textColor: '#000000',
              },
            },
          ],
        ];

        // Define the styles for the table
        let tableOptions = {
          startY: currentY + 8, // You can adjust this to place the table where you want in the PDF
          tableLineColor: '#FF5700',
          tableLineWidth: 0.5,
          styles: {
            cellPadding: 5,
            valign: 'middle',
            fontSize: 12,
            textColor: [0, 0, 0], // Black text color
          },
          body: rows,
          didDrawCell: (imageSrc => function (data) {
            const doc = data.doc;

            if (data.row.index === 0 && data.column.index === 0) {
              const { x, y, width, height } = data.cell;
              const imgWidth = height - 4;
              const imgX = x + width - imgWidth - 2;
              doc.addImage(imageSrc, 'png', imgX + 8, y + 4, 10, 10);
            }
            if (data.row.index === 1) {
              let startY = data.cell.y + 10
              let startX = data.cell.x + 6
              bulletPoints.forEach((point) => {
                // Draw the bullet
                doc.circle(startX, startY - 1.5, 1, 'F');

                let currentX = startX + 3; // Start position after bullet

                point.text.forEach((part, idx) => {
                  if (part.style.fontStyle === 'bold') {
                    doc.setFont('helvetica', 'bold');
                  } else {
                    doc.setFont('helvetica', 'normal');
                  }

                  if (part.style.textColor) {
                    doc.setTextColor(part.style.textColor);
                  } else {
                    doc.setTextColor('#000000');
                  }
                  if (idx) currentX++

                  doc.text(part.text, currentX, startY);

                  if (part.style.isLink) {
                    const textWidth = doc.getTextWidth(part.text);
                    doc.setTextColor(part.style.textColor || '#0000FF');
                    doc.text(part.text, currentX, startY);

                    doc.link(currentX, startY - 4, textWidth, 8, { url: part.style.link });
                  }

                  // Update X position for the next part
                  currentX += doc.getTextWidth(part.text);
                  if (idx !== point.text.length - 1 && currentX > 170) {
                    currentX = startX + 3
                    startY += 5
                  }
                });

                // Move Y position for the next bullet point
                startY += 8; // Adjust spacing between bullet points
              });
            }
          })(this.bulbIcon),
        };

        doc.autoTable(tableOptions);

        rows = [
          [
            {
              colSpan: 2,
              content: "Your site's EEAT audit",
              styles: {
                halign: 'center',
                fillColor: '#FFEDDD',
                fontSize: 22.65,
                fontStyle: 'bold',
                textColor: '#000000',
              },
            },
          ],
          [
            {
              content: '',
              styles: {
                halign: 'right',
                cellPadding: 1,
                valign: 'middle',
                fillColor: '#FFEDDD',
                fontSize: 12,
                fontStyle: 'bold',
                minCellWidth: this.targetUrl?.length > 50 ? 15 : 35,
                textColor: '#FF5700',
              },
            },
            {
              content: this.targetUrl,
              styles: {
                halign: 'left',
                valign: 'middle',
                fillColor: '#FFEDDD',
                cellPadding: 1,
                fontSize: 12,
                fontStyle: 'bold',
                minCellWidth: this.targetUrl?.length > 50 ? 80 : 35,
                textColor: '#FF5700',
              },
            },
          ],
          [
            {
              colSpan: 2,
              content:
                "We have found these EEAT signals on your top competitors' sites. The ones marked 'Add' have not been found on your site and as many as possible should be added before you begin publishing new content.",
              styles: {
                halign: 'center',
                fillColor: '#FFEDDD',
                fontSize: 12,
                textColor: '#000000',
              },
            },
          ],
          [
            {
              colSpan: 2,
              content:
                "For instructions on what each of the EEAT signals looks like on the page,",
              styles: {
                cellPadding: { top: 3, right: 3, bottom: 3, left: 17 },
                fillColor: '#FFEDDD',
                fontSize: 12,
                textColor: '#000000',
              },
            },
          ],
        ];

        tableOptions = {
          tableLineColor: '#FF5700',
          tableLineWidth: 0.5,
          styles: {
            cellPadding: 5,
            valign: 'middle',
            fontSize: 12,
            textColor: [0, 0, 0], // Black text color
          },
          body: rows,
          didDrawCell: (imageSrc => function (data) {
            const doc = data.doc;
            
            if (data.row.index === 1 && data.column.index === 0) {
              const { x, y, width, height } = data.cell;
              const imgWidth = height - 4;
              const imgX = x + width - imgWidth - 2;
              doc.addImage(imageSrc, 'png', imgX, y + 1, 4, 4);
            } else if (data.row.index === 3 && data.column.index === 0) {
              const { x, y } = data.cell;

              doc.setTextColor(5, 99, 193);
              doc.textWithLink('click here', x + 155, y + 7, { url: 'https://help.pageoptimizer.pro/article/what-eeat-signals-look-like' });
            }
          })(this.eeatWorldIcon),
        };

        doc.autoTable(tableOptions);

        rows = this.data.map((row) => ({
          name: {
            content: row.signal,
            styles: {
              halign: 'left',
              valign: 'middle',
              fillColor: '#FF5700',
              fontSize: 12,
              fontStyle: 'bold',
              textColor: '#FFFFFF',
            },
          },
          description: {
            content: this.descriptionObj?.[row.signal] || '',
            styles: {
              fillColor: '#FFEDDD',
              fontSize: 12,
              fontStyle: 'normal',
              valign: 'middle',
              halign: 'left',
              cellPadding: { top: 5, right: 5, bottom: 5, left: 5 },
            },
          },
          status: {
            content: !!row.result,
            styles: {
              fillColor: row.result ? '#D7F4E8' : '#FFC4C4',
              fontSize: 8.7,
              fontStyle: 'bold',
              valign: 'middle',
              halign: 'center',
              textColor: '#FFFFFF',
            },
          },
        }));

        // Define the styles for the table
        tableOptions = {
          startY: doc.lastAutoTable.finalY,
          tableLineColor: '#FF5700',
          tableLineWidth: 0.5,
          head: [
            [
              {
                content: 'Name',
                styles: {
                  fillColor: '#FF5700',
                  textColor: '#FFFFFF',
                  fontSize: 12,
                  fontStyle: 'bold',
                  halign: 'left',
                },
              },
              {
                content: 'Description',
                styles: {
                  fillColor: '#FF5700',
                  textColor: '#FFFFFF',
                  fontSize: 12,
                  fontStyle: 'bold',
                  halign: 'left',
                },
              },
              {
                content: 'Status',
                styles: {
                  fillColor: '#FF5700',
                  textColor: '#FFFFFF',
                  fontSize: 12,
                  fontStyle: 'bold',
                  halign: 'left',
                },
              },
            ],
          ],
          body: rows.map((row) => [
            row.name.content,
            row.description.content ? row.description.content + "*" : "",
            {
              content: row.status.content,
              styles: {
                ...row.status.styles,
                // minCellHeight: 18,
                // minCellWidth: 38,
                margin: 2,
                padding: 4,
                textAlign: 'center',
                borderRadius: 10,
                lineHeight: 1.2,
              },
            },
          ]),
          styles: {
            cellPadding: 3.5,
            fontSize: 12,
            textColor: [0, 0, 0],
            fontStyle: 'normal',
          },
          columnStyles: {
            0: {
              fillColor: '#FF5700',
              textColor: '#FFFFFF',
              fontSize: 12,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'middle',
            },
            1: {
              fillColor: '#FFEDDD',
              fontSize: 12,
              fontStyle: 'normal',
              valign: 'middle',
              halign: 'left',
            },
            2: {
              fontSize: 8.7,
              fontStyle: 'bold',
              valign: 'middle',
              halign: 'center',
              textColor: '#FFFFFF',
            },
          },
          didDrawCell: ((addImage, presentImage) => function (data) {
            const doc = data.doc;
            const cell = data.cell;

            if (
              data.column.dataKey === 2 &&
              data.row.section === 'body' &&
              data.row.index >= 0
            ) {
              // Draw the custom component (pill shape with check icon)
              const centerX = cell.x + cell.width / 2;
              const centerY = cell.y + cell.height / 2;
              const isCheck = data.row?.raw?.[2]?.content;

              // Draw the pill shape
              const pillWidth = isCheck ? 18 : 14;
              const pillHeight = 7;
              doc.setFillColor(isCheck ? '#25A97A' : '#FF3939');
              doc.roundedRect(
                centerX - pillWidth / 2,
                centerY - pillHeight / 2,
                pillWidth,
                pillHeight,
                pillHeight / 2,
                pillHeight / 2,
                'F',
              );

              if (isCheck) {
                doc.addImage(presentImage, 'png', (centerX - pillWidth / 2) + 1, (centerY - pillHeight / 2) + 2, 3, 3);
              } else {
                doc.addImage(addImage, 'png', (centerX - pillWidth / 2) + 2, (centerY - pillHeight / 2) + 2, 3, 3);
              }


              // Draw the check icon
              doc.setTextColor('#FFFFFF');
              doc.setFontSize(8.7);
              doc.text(isCheck ? 'Present' : 'Add', centerX + (isCheck ? 1.5 : 2), centerY + 1.2, {
                align: 'center',
              });
            }

            // Set custom border colors for each side
            let borderColorTop = '#FF5700';
            let borderColorBottom = '#FF5700';
            const borderColorRight = '#FF5700';
            const borderColorLeft = '#FF5700';

            if (!data.column.dataKey) {
              if (data.row.section !== 'head') borderColorTop = '#FFFFFF';
              borderColorBottom = '#FFFFFF';
            }

            doc.setLineWidth(0.3);

            // Draw top border
            doc.setDrawColor(borderColorTop);
            doc.line(cell.x, cell.y, cell.x + cell.width, cell.y);

            // Draw right border
            doc.setDrawColor(borderColorRight);
            doc.line(
              cell.x + cell.width,
              cell.y,
              cell.x + cell.width,
              cell.y + cell.height,
            );

            // Draw bottom border
            doc.setDrawColor(borderColorBottom);
            doc.line(
              cell.x,
              cell.y + cell.height,
              cell.x + cell.width,
              cell.y + cell.height,
            );

            // Draw left border
            doc.setDrawColor(borderColorLeft);
            doc.line(cell.x, cell.y, cell.x, cell.y + cell.height);
          })(this.eeatKeywordWarningIcon, this.eeatKeywordPresentIcon),
        };

        doc.autoTable(tableOptions);

        rows = [
          [
            {
              content: '*Usually, the signal is detected in these elements. We recommend incorporating them accordingly. However, our AI can identify these signals even if they are implemented unconventionally.',
              styles: { halign: 'center', valign: 'middle', fontStyle: 'italic', fillColor: null },
            },
          ],
        ];

        tableOptions = {
          startY: doc.lastAutoTable.finalY + 2,
          startX: 20,
          body: rows,
          columnStyles: { 0: { cellWidth: 170 } }
        };
        doc.autoTable(tableOptions);

        const timestamp = moment().format('YYYY_M_D_h_mm_ss');
        doc.save(`google_eeat_${timestamp}.pdf`);
      })
    },
    createDocx() {
      getReportHeaderImage(this.$store, "eeat").then((responseData) => {
        // Create a new document
        generateEEATWordReport(
          responseData.headerImg,
          responseData.data.siteIcon,
          responseData.data.addIcon,
          responseData.data.presentIcon,
          this.targetUrl,
          this.data,
          this.descriptionObj
        )
      })
    },
  },
  mounted() {
    getBase64FromUrl(eeatPdfHeader, (base64) => {
      this.reportLogo = base64;
    });
    getBase64FromUrl(eeatWorldIcon, (base64) => {
      this.eeatWorldIcon = base64;
    });
    getBase64FromUrl(eeatKeywordWarningIcon, (base64) => {
      this.eeatKeywordWarningIcon = base64;
    });
    getBase64FromUrl(eeatKeywordPresentIcon, (base64) => {
      this.eeatKeywordPresentIcon = base64;
    });
    getBase64FromUrl(bulbIcon, (base64) => {
      this.bulbIcon = base64;
    });
    getBase64FromUrl(wordDocHeadImg, (base64) => {
      this.wordDocHeadImg = base64;
    });
  },
};
</script>

<style lang="scss" scoped>
.table-pdf-csv-download {
  display: inline-block;
}
</style>
