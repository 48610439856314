<template>
  <div class="cancel-succeed-page">
    <div class="flex flex-column min-height-screen">
      <div class="text-center">
        <img width="270px" src="/static/long_logo@4x.png" />
      </div>
      <div class="flex-grow-1 main-area">
        <div class="text-center is-size-4 has-text-weight-bold">
          Your subscription is successfully canceled.
        </div>
        <div class="text-center mt50">
          <button
            class="button ready-button"
            @click="goToProfilePage">Go to Profile Page</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "CancelSubscriptionConfirm",
  computed: {
    user() {
      return this.$store.state.user;
    },
    
  },
  data() {
    return {
    
    };
  },
  methods: {
    goToProfilePage() {
      this.$router.push(`/useraccount/${this.user.id}`);
    },
  }
};
</script>

<style scoped lang="scss">
* {
  font-size: 1rem;
}
.cancel-succeed-page {
  margin: 0 auto 0 auto;
  width: 700px;
}

.main-area {
  background: #fffaf2;
  border-top: 2px solid #ffc387;
  padding: 2.5rem 5rem 1rem 5rem;
}

.padding-area {
  padding: 2rem 0rem 2rem 0rem;

  .downgrade-box {
    .downgrade-title {
      &,
      & > * {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
      }
    }
    .icon-area {
      padding: 2rem;
      img {
        width: 20rem;
      }
    }

    .downgrade-btn {
      background: var(--v-profileAnchorColor-base);;
      border: none;
      color: white;
      width: 100%;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  }

  .note {
    font-size: 0.65rem;
    margin: 1.5rem 0;
    padding: 0 2rem;
    text-align: center;
  }
  .bottom-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .never-mind-btn {
      i.fa {
        color: var(--v-profileAnchorColor-base);;
      }
      background: #ffeed7;
      border: 1px solid #ffc387;
      padding: 1rem 1rem;
      cursor: pointer;
    }

    .still-want-cancel-btn {
      color: black;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .cancel-succeed-page {
    width: 100vw;
  }
  .main-area {
    padding: 1.5rem 2rem;
  }
  .bottom-buttons {
    flex-direction: column;
  }
}
</style>
