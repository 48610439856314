<template>
<div>
  <div class="eat-tab-selector my-3">
    <div class="base" :class="{ 'selected': currentTab == 'BASE_EAT' }"
      @click="() => currentTab = 'BASE_EAT'">
      <i class="material-icons-outlined">fact_check</i>
      <span>Basic EEAT</span>
    </div>
    <div class="next-gen" :class="{ 'selected': currentTab == 'NEXT_GEN_EAT' }"
      @click="() => currentTab = 'NEXT_GEN_EAT'">
      <i class="material-icons-outlined">auto_awesome</i>
      <span>NextGen EEAT</span>
    </div>
  </div>
  <eat-schema-types
    v-if="currentTab == 'BASE_EAT'"
    :isEatCalculationUsed="isEatCalculationUsed"
    :eatStatus="eatStatus"
    :pageId="pageId"
    :targetURL="targetURL"
    :pageNotBuilt="pageNotBuilt"
    :mainPageSchemaTypes="mainPageSchemaTypes"
    :commonEatSchemaTypes="commonEatSchemaTypes"
    :competitors="competitors"
    :checkLists="checkLists"
    :devLog="devLog"
  ></eat-schema-types>
  <next-gen-eat-types
    v-if="currentTab == 'NEXT_GEN_EAT'"
    :nextGenEatSchemas="nextGenEatSchemas">
  </next-gen-eat-types>
  
</div>
</template>

<script>
import EatSchemaTypes from '@/components/PageRun/eat-data/EatSchemaTypes.vue'
import NextGenEatTypes from '@/components/PageRun/eat-data/NextGenEatTypes.vue'

export default {
  props: [
    'pageId', 'targetURL', 'pageNotBuilt', 'mainPageSchemaTypes', 'commonEatSchemaTypes', 'competitors', 'checkLists',
    'eatStatus', 'isEatCalculationUsed', 'nextGenEatSchemas',
    'devLog',
  ],
  computed: {
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
  },
  components: {
    EatSchemaTypes,
    NextGenEatTypes,
  },
  data () {
    return {
      currentTab: 'BASE_EAT', // BASE_EAT or NEXT_GEN_EAT
    }
  }

}
</script>

<style lang="scss" scoped>
.eat-tab-selector {
  display: inline-flex;
  border-radius: 5px;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    color: var(--v-mainColor-base);
    cursor: pointer;
    i {
      font-size: 1rem;
    }
    span {
      font-size: 0.8rem;
      font-weight: 500;
    }
    &.base {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-left: 1px solid var(--v-mainColor-base);
      border-top: 1px solid var(--v-mainColor-base);
      border-bottom: 1px solid var(--v-mainColor-base);
      &.selected {
        border: 1px solid var(--v-profileAnchorColor-base);
        background: var(--v-lightYellowColor-base);
      }
    }
    &.next-gen {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-right: 1px solid var(--v-mainColor-base);
      border-top: 1px solid var(--v-mainColor-base);
      border-bottom: 1px solid var(--v-mainColor-base);
      &.selected {
        border: 1px solid var(--v-profileAnchorColor-base);
        background: var(--v-lightYellowColor-base);
      }      
    }
  }
}

</style>
