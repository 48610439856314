<template>
<div>
  <table class="signal-table" style="table-layout: fixed;">
    <thead>
      <tr>
        <th style="width: 50%;">Important Term</th>
        <th>Current Usage</th>
        <th>Target Range</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, idx) in sectionData" :key="idx">
        <td>
          {{ item.term.phrase }}
        </td>
        <td>{{ item.contentBrief.current }}</td>
        <td>
          <span class="target-range">
            {{ item.term.type == 'keyword' ? Math.round(item.contentBrief.target * TARGET_MIN_PERCENTAGE['title']) + ' - ' + item.contentBrief.target : item.contentBrief.targetMin + ' - ' + item.contentBrief.targetMax }}
          </span>
          <span class="stoplights-box" :style="{ 'background': getStopLightColorForItem('title', item) }"></span>
          <!-- <br v-if="showAsMobileView" /> -->
          <!-- <span
            v-if="item.term.type == 'keyword'"
            class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
            data-tooltip="Using your exact keyword is critical to SEO success"
            >Must Use
          </span> -->
          <!-- <span
            v-if="isSecondaryKeyword(item.term.phrase)"
            class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
            data-tooltip="Use your secondary keyword"
            >Secondary KW
          </span> -->
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>

import { TARGET_MIN_PERCENTAGE } from '@/components/PageRun/constants.js'
import { mixin as cb_mixin } from '@/components/PageRun/ContentBrief/cb_mixin'

export default {
  name: 'IndividualTermsForSection',
  props: ['sectionName', 'contentBriefData'],
  mixins: [cb_mixin],
  data () {
    return {
      TARGET_MIN_PERCENTAGE,
    }
  },
  computed: {
    sectionData () {
      return this.contentBriefData[this.sectionName] || []
    }
  },

  methods: {

  }
}
</script>


<style lang="scss" scoped>
@import "../ContentBrief/content-brief.scss";
</style>