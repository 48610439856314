<template>
  <v-menu v-if="isDemo" offset-y transition="scale-transition" rounded="lg" class="plans-container">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        :small="!!isSmall"
        color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none pulsing-yellow-button"
        :class="{ 'mr-3': currentStep !== undefined ? currentStep > 6 : false }"
        v-bind="attrs"
        v-on="on"
      >
        Get Started
      </v-btn>
    </template>
    <v-list class="plans-container">
      <div :class="`text-bold text-center margin-y-2 ${colorClass}`">
        Choose your POP plan
      </div>
      <div class="text-center mb-2">
        ✅ 7-day refund guarantee
      </div>
      <div class="d-flex mx-4">
        <div v-for="(item, index) in DEMO_PRICES" :key="index" class="mx-2">
          <pricing-card :item="item" />
        </div>
      </div>
      <div>
        <v-card class="testimonial-card">
          <v-card-text class="d-flex align-center padding-v-card">
            <v-img class="mendy-pic" src="/static/mendy-pic.png" />
            <div class="ml-4">
              <blockquote class="black-color">
                <p>
                  "What I like most about POP are the results. I've had a number of pages 
                  <strong>shoot to position 1-3 within days</strong> of making the recommended updates."
                </p>
                <footer>
                  <cite>Mendy Perlman, SEO Specialist</cite>
                </footer>
              </blockquote>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div :class="`footer-text-bold text-center mb-1 mt-1 ${colorClass}`">
        Trusted by 6k+ users
      </div>
      <div class="text-center mb-1">
        <v-img height="50" width="500" src="/static/plans-footer.png" />
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { DEMO_PRICES } from '@/components/PageRun/constants.js';
import PricingCard from '@/components/DemoPricingCard.vue'

export default {
  name: "DemoPlans",
  props: ['currentStep', 'isSmall'],
  components: { PricingCard },
  computed: {
    DEMO_PRICES () {
      return DEMO_PRICES
    },
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    colorClass() {
      return this.$vuetify.theme.dark ? "white-color" : "black-color"
    },
  }
}
</script>

<style scoped>
.text-bold {
  font-family: Inter, sans-serif;
  color: black !important;
  font-size: 22px !important;
  font-weight: 900;
}

.footer-text-bold {
  font-family: Inter, sans-serif;
  color: black !important;
  font-size: 15px !important;
  font-weight: 900;
}


.text-center {
  display: flex;
  justify-content: center;
}

.margin-y-2 {
  margin-top: 20px;
  margin-bottom: 25px;
}

.testimonial-card {
  background-color: #fef2e6 !important;
  margin: .5rem 1.25rem;
  padding: .5rem;
  border-radius: 12px !important;
}

.padding-v-card {
  padding: .25rem 1rem;
}

blockquote {
  margin: 0;
  padding: 0;
  quotes: "“" "”" "‘" "’";
}

blockquote p {
  display: inline;
  font-style: italic;
}

blockquote footer {
  margin-top: 8px;
  font-weight: bold;
}

.mendy-pic {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.black-color {
  color: black !important;
}

.white-color {
  color: white !important;
}

.plans-container {
  width: 840px;
  z-index: 999 !important;
}

@media (min-width: 720px) {
  .margin-y-2 {
    margin-top: 10px !important;
    margin-bottom: 12px !important;
  }
}
@media (min-width: 1080px) {
  .margin-y-2 {
    margin-top: 12px !important;
    margin-bottom: 15px !important;
  }
  .text-bold {
    font-size: 28px !important;
  }
}
@media (min-width: 1840px) {
  .margin-y-2 {
    margin-top: 15px !important;
    margin-bottom: 25px !important;
  }
  .text-bold {
    font-size: 36px !important;
  }
  .testimonial-card {
    margin: 1rem;
    padding: 1rem;
  }
  .padding-v-card {
  padding: 1rem;
}
}

</style>
