<template>
<div>

  <div v-if="silos.length" class="sites-or-pages-container silos-container mt-6">
    <silo-card
      v-for="(silo, idx) in silos"
      :key="idx"
      :isProjectLevelView="true"
      :silo="silo"
      :refreshData="() => fetchSilos()"
    />
  </div>

</div>
</template>

<script>
import SiloCard from '@/components/Campaigns/silo/SiloCard.vue';

export default {
  components: {
    SiloCard
  },
  props: ['page'],
  data () {
    return {
      silos: [],
    }
  },
  computed: {
    siteId () {
      return this.$route.params.siteId;
    }
  },
  methods: {
    fetchSilos() {
      this.$store.commit('showLoading');
      this.$store
        .dispatch('getSilos', {
          siteId: this.$route.params.siteId,
          pageId: this.page.id,
          pageName: this.page.name,
          paginate: 0
        })
        .then((response) => {
          this.silos = response.data.data;
        })
        .finally(() => {
          this.$store.commit('hideLoading');
        });
    },    
  },
  mounted () {
    this.fetchSilos() 
  }
}
</script>