<template>
  <section class="section">
    <div class="container">
      <div class="user-email">{{ oneAffiliateData.affiliateEmail }}</div>
      <div class="mt-5 d-flex align-center">
        <div class="flex-grow-1 page-label d-flex align-center gap10">
          Your POP affiliate dashboard
          <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
            @click="() => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: 'POP Affiliate Dashboard Overview',
                videoLink: 'https://www.youtube.com/embed/-Z0p_yOQgVQ?si=DHDG1LHe9zcEIYor',
                description: 'In this video, Dade, the Customer Success Manager, provides an overview of the POP Affiliate Dashboard. Please note that the dashboard is only accessible to POP affiliates.',
                time: '1m 20s'
              });
            }">
            videocam
          </span>
        </div>
        <div class="total-comission-data-area">
          <div>
            Total comissions (all time): ${{ totalComissionAllTime }}
          </div>
          <div>
            Unpaid comissions: ${{ unPaidComissionTotal.toFixed(2) }}
          </div>
        </div>
      </div>
      <div class="notify-label">
        <i class="material-icons">error_outline</i>
        Payments are made once your account gets to $50 in commissions        
      </div>

      <!-- <div>
        Test data: {{ JSON.stringify(oneAffiliateData) }}
      </div> -->

      <v-card class="card-box pa-5">
        <div class="d-flex align-center">
          <div class="flex-grow-1 stats-label">
            Affiliate stats
          </div>
          <div class="actions-part">
            <!-- <select class="range-select-box">
              <option selected>All time</option>
            </select> -->
            <date-picker
              v-if="forUserView"
              v-model="dates" range></date-picker>
            <v-btn icon :id="`export-menus`">
              <span class="material-icons-outlined mainColor--text">more_horiz</span>
            </v-btn>
            <v-menu 
              :activator="`#export-menus`"
              bottom offset-y>
              <v-list>
                <v-list-item link @click="exportCsv()">
                  <v-list-item-title
                    class="mainColor--text">
                    Export to csv
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="mt-5">
          <table class="stats-table">
            <thead>
              <tr>
                <th>AFFILIATE ID</th>
                <th>TOTAL SALES</th>
                <th>TOTAL VALUE</th>
                <th>PERCENTAGE</th>
                <th>TOTAL COMISSION</th>
                <th>NOTIFICATION SENT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ filteredAffiliateSaleInfo.affiliateId }}</td>
                <td>{{ filteredAffiliateSaleInfo.sales.length }}</td>
                <td>${{ filteredAffiliateSaleInfo.sales.reduce((acc, item) => acc + item.saleAmount, 0) | round(2) }}</td>
                <td>{{ filteredAffiliateSaleInfo.affiliatePercentage }}%</td>
                <td class="profileAnchorColor--text">${{ filteredAffiliateSaleInfo.sales.reduce((acc, item) => acc + item.affiliatePortion, 0) | round(2) }}</td>
                <td>{{ toLocalDateString(filteredAffiliateSaleInfo.lastPayoutDate) }}</td>
                
              </tr>
            </tbody>
          </table>
        </div>

      </v-card>

      <div class="mt-15"></div>

      <div class="columns">
        <div class="column">
          <div class="user-email mb-5">Payouts received</div>
          <v-card class="card-box pa-0" style="max-height: 500px; overflow: auto;">
            <table class="payout-table">
              <thead>
                <tr>
                  <th>Payout Date</th>
                  <th>Payout Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, i) in filteredAffiliateSaleInfo.recentPayouts" :key="i">
                  <td>{{ toLocalDateString(p.payoutDate) }}</td>
                  <td>${{ p.payoutAmount ? p.payoutAmount.toFixed(2) : '' }}</td>
                  <td>
                    <span v-if="p.status == 'SUCCESS'" class="success-badge">
                      Success
                    </span>
                    <!-- <span v-else class="error-badge">
                      {{ p.status }}
                    </span> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </div>
        <div class="column">
          <div class="user-email mb-5">Your affiliate commissions</div>
          <v-card class="card-box pa-5" style="max-height: 500px; overflow: auto;">
            <div v-for="(s, i) in filteredAffiliateSaleInfo.sales" :key="i" class="d-flex align-center commission-row" :class="{ 'mt-8': i > 0 }">
              <div class="flex-grow-1">
                <div class="d-flex align-center">
                  <div class="user-id">User ID {{ s.userId }}</div>
                  <div class="customer-email">{{ s.customerEmail }}</div>
                </div>
                <div class="purchased-plan mt-2">
                  {{ s.product }}
                </div>
              </div>
              <div>
                <div class="d-flex align-center">
                  <div class="commision">${{ s.affiliatePortion | round(2) }}</div>
                  <div class="plan-price ml-2">(${{ s.saleAmount | round(2) }} plan)</div>
                </div>
                <div class="mt-2 purchase-date">
                  {{ toLocalDateString(s.date) }}
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import { toLocalDateString, toLocalDate } from '@/utils'
import { mixin as BaseMixin } from '@/components/mixin'

export default {
  name: 'AffiliateSalesForUser',
  components: { DatePicker },
  props: {
    forUserView: {
      default: false,
    },
    oneAffiliateData: {
      default: {}
    },
    reloadData: {},
  },
  mixins: [BaseMixin],
  data () {
    return {
      totalSales: 0,
      perPage: 50,
      currentPage: 1,
      defaultSortOrder: 'asc',
      // startDate: null,
      // endDate: null,
      dates: [moment().subtract(3, 'months').toDate(), moment().toDate()],

      INVOICE_PAID_STATUS_STRINGS: [
        'approved',
        'paid',
        'succeeded',
        'completed',
        'Completed',
        'COMPLETED'
      ]
    }
  },
  computed: {
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    unPaidComissionTotal () {
      let rlt = 0;
      if (this.oneAffiliateData.sales) {
        this.oneAffiliateData.sales.map(item => {
          if (!item.isPaidtoAffiliate) {
            rlt += item.affiliatePortion          
          }
        })
        return rlt;
      }
      return rlt;
    },
    totalComissionAllTime () {
      if (this.oneAffiliateData) {
        return this.oneAffiliateData.totalCommission
        // this.oneAffiliateData.sales.map(item => {
        //   if (item.affiliatePortion) {
        //     rlt += item.affiliatePortion
        //   }
        // })
        // return rlt;
      }
      return 0;
    },
    filteredAffiliateSaleInfo () {
      let oneAffiliateData = JSON.parse(JSON.stringify(this.oneAffiliateData))
      if (!oneAffiliateData.sales) {
        oneAffiliateData['sales'] = []
      }

      let startDate = toLocalDate(this.dates[0])
      let endDate = toLocalDate(this.dates[1])
      // console.log('testing', startDate, endDate)

      try {
        if (startDate) {
          let sales = oneAffiliateData.sales.filter((s) => {
            let saleDate = toLocalDate(s.date)
            return saleDate.isValid() && saleDate >= startDate
          })
          if (sales.length) {
            oneAffiliateData.sales = sales
          }
        }

        if (endDate) {
          let sales = oneAffiliateData.sales.filter((s) => {
            let saleDate = toLocalDate(s.date)
            return saleDate.isValid() && saleDate <= endDate
          })
          if (sales.length) {
            oneAffiliateData.sales = sales
          }
        }
      } catch (e) {
        console.log('error found', e)
      }
      return oneAffiliateData
    },
  },
  methods: {
    toLocalDateString (timestamp) {
      return toLocalDateString(timestamp)
    },
    exportCsv () {
      let csvData = ['Sale Date,Affiliate Email,Customer Email,Sale Amount,Affiliate Commission,Product']
      this.filteredAffiliateSaleInfo.sales.forEach((sale) => {
        csvData.push(`${toLocalDateString(sale.date)},${sale.affiliateEmail},${sale.customerEmail},${sale.saleAmount},${sale.affiliatePortion},${sale.product}`)
      })
      csvData = csvData.join('\n')
      let downloadLink;
      const now = new Date()
      const filename = "Affiliate_Sales_" + now.getUTCFullYear() + "_" + (now.getUTCMonth()+1) + "_"+ now.getUTCDate() + ".csv"
      const csvFile = new Blob([csvData], {type: "text/csv"});
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
  },
  beforeMount () {
  }
}
</script>

<style lang="scss" scoped>

.user-email {
  font-size: 0.9rem;
  color: var(--v-mainColor-base);
  font-weight: 700;
}
.page-label {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
}
.total-comission-data-area {
  display: flex;
  gap: 15px;
  & > div {
    color: var(--v-mainColor-base);
    font-size: 1rem;
    font-weight: 700;
  }
}

.card-box {
  border: 1px solid var(--v-gray15Color-base) !important;
  background-color: var(--v-whiteColor-base);
  padding: 5px;
  border-radius: 5px !important;
  overflow-x: auto;
  
  .border-bottom {
    border-bottom: 1.5px solid var(--v-grayColor-base) !important;
  }

  .stats-label {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--v-mainColor-base);
  }

  .actions-part {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .range-select-box {
    border: 1px solid var(--v-mainColor-base);
    border-radius: 9px;
    padding: 5px 15px 5px 10px;
    color: var(--v-mainColor-base);

    appearance: none;

    &::before {
      position: absolute;
      right: 0;
      top: 50%;
      /* font-family: "Material Icons";
      content: "link"; */
      content: "adf";
      color: red;

    }
  }

  .stats-table {
    width: 100%;
    table-layout: fixed;
    th, td {
      color: var(--v-mainColor-base);
    }
    th {
      font-weight: 500;
    }
    td {
      padding-top: 20px;
      font-size: 1.5rem;
      font-weight: 800;
    }
  }
}

.payout-table {
  width: 100%;
  th, td {
    color: var(--v-mainColor-base);
  }
  th {
    padding: 15px 15px;
    border-bottom: 1px solid var(--v-gray15Color-base);
    text-transform: uppercase;
    font-weight: 500;
  }
  td {
    padding: 10px 15px;
  }

  tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--v-gray15Color-base);
  }
  tbody tr:nth-child(odd) {
    background: var(--v-gray7Color-base);
  }

  .success-badge, .error-badge {
    position: relative;
    background: var(--v-green19Color-base);
    font-size: 0.8rem;
    font-weight: 700;
    color: var(--v-green20Color-base);
    padding: 3px 10px 3px 25px;
    border-radius: 100px;
    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 50%;
      width: 7px;
      height: 7px;
      border-radius: 10px;
      transform: translateY(-50%);
      background: var(--v-green20Color-base);
    }
  }
  .error-badge {
    background: var(--v-redColor-base);
    color: var(--v-whiteColor-base);
    &::before {
      background: var(--v-red2Color-base);
    }
  }
}

.commission-row {
  .user-id {
    border: 1px solid var(--v-mainColor-base);
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: 700;
    color: var(--v-mainColor-base);
  }
  .customer-email {
    margin-left: 10px;
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--v-mainColor-base);
  }
  .purchased-plan {
    display: inline-block;
    color: var(--v-yellow9Color-base);
    background: var(--v-yellow10Color-base);
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 700;
  }
  .commision {
    font-size: 1.2rem;
    color: var(--v-green20Color-base);
    font-weight: 800;
  }
  .plan-price {
    font-size: 0.9rem;
    font-weight: 800;
    color: var(--v-mainColor-base);
  }
  .purchase-date {
    font-size: 1rem;
    color: var(--v-mainColor-base);
    text-align: right;
  }
}

.table-mobile-sort {
  display: none;
}
</style>