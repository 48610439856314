<template>
<div>
  <!-- {{ tableData.slice(0, 5) }} <br /><br />
  {{ sortedTableData.slice(0, 5) }} <br/><br />
  {{ competitorsUrlIncludingTragetDomain }} -->
  
  <div class="tabs-container mt-10">
    <div :class="{ 'is-active': currentTab == 'shared' }" @click="() => { currentTab = 'shared' }">
      <span>Shared</span>
      <span class="keywords-count">{{ numberFormatter.format(tabsInfo.shared || 0) }}</span>
    </div>
    <div :class="{ 'is-active': currentTab == 'missing' }" @click="() => { currentTab = 'missing' }">
      <span>Missing</span>
      <span class="keywords-count">{{ numberFormatter.format(tabsInfo.missing || 0) }}</span>
    </div>
    <div :class="{ 'is-active': currentTab == 'untapped' }" @click="() => { currentTab = 'untapped' }">
      <span>Untapped</span>
      <span class="keywords-count">{{ numberFormatter.format(tabsInfo.untapped || 0) }}</span>
    </div>
    <div :class="{ 'is-active': currentTab == 'all' }" @click="() => { currentTab = 'all' }">
      <span>All keywords</span>
      <span class="keywords-count">{{ numberFormatter.format(tabsInfo.all || 0) }}</span>
    </div>
  </div>
  <div class="gap-result-area mt-5">
    <template v-if="gapReport">
      <div class="header-part d-flex align-center">
        <v-text-field outlined dense hide-details
          label="Search"
          prepend-inner-icon="search"
          style="max-width: 300px;"
          v-model="searchTerm">
        </v-text-field>
        <div class="flex-grow-1"></div>
        <div class="d-flex align-center gap10">
          <div class="keyword-filter-area">
            <div>FILTERS</div>
            <div class="filter-btn" @click="() => { showFilterPopup = true; }">
              Apply filters
              <span class="material-icons-outlined">keyboard_arrow_down</span>

              <div class="filter-popup"
                v-if="showFilterPopup" v-click-outside="() => { showFilterPopup = false; }"
                style="position: absolute; right: 0px; top: 50px; z-index: 12;">
                <!-- <div class="filter-item-label">Clicks</div>
                <div class="range-wrapper">
                  <v-range-slider hide-details
                    :min="filterClicksMinMax[0]"
                    :max="filterClicksMinMax[1]"
                    v-model="filterClicksRange"
                    color="profileAnchorColor"
                    step="1"
                    stroke="10"
                    thumb-label="always"
                  ></v-range-slider>
                </div> -->
              </div>
            </div>
          </div>
          <table-pdf-csv-download
            :tableId="`keyword-gap-table table`"
            :fileName="`keyword-gap-keywords_${gapReport?.id || ''}_`"
            :columns="downloadColumns"
            :rows="sortedTableData"
            ></table-pdf-csv-download>
        </div>
      </div>
      <b-table
        id="keyword-gap-table"
        class="voltage-table"
        :data="sortedTableData"
        paginated
        :total="sortedTableData.length"
        :per-page="perPage"
        :default-sort-direction="'desc'"
        :default-sort="[sortField, sortOrder]">
        <template slot-scope="props">
          <b-table-column field="track" label="Track">
            <v-checkbox dense hide-details class="mt-0 pt-0"></v-checkbox>
          </b-table-column>
          <b-table-column field="keyword" label="Keyword" class="keyword-cell">
            {{ props.row.keyword }}
          </b-table-column>
          <b-table-column v-for="(c, idx) in competitorsUrlIncludingTragetDomain" :key="idx" field="c" :label="c">
            {{ props.row[c] || '-' }}
          </b-table-column>
          <b-table-column field="monthlySearches" label="Monthly searches" sortable>
            {{ props.row.monthlySearches }}
          </b-table-column>
          <b-table-column field="monthlySearches" label="Keyword difficulties" sortable>
            {{ props.row.keywordDifficulties }}
          </b-table-column>
        </template>
      </b-table>
    </template>
    <template v-else>
      <div>
        <!-- No data to display -->
      </div>
    </template>

  </div>
</div>
</template>

<script>
import TablePdfCsvDownload from '@/components/Campaigns/TablePdfCsvDownload.vue'


export default {
  props: {
    gapReport: {
      default: null,
    }
  },
  components: {
    TablePdfCsvDownload,
  },
  data () {
    return {
      searchTerm: '',
      currentTab: 'shared',

      showFilterPopup: false,
      sortField: 'monthlySearches',
      sortOrder: 'desc',
      perPage: 10,
    }
  },
  computed: {
    numberFormatter () {
      return new Intl.NumberFormat('en-GB', {
        notation: 'compact',
        compactDisplay: 'short'
      })
    },
    tabsInfo () {
      let tabs = {}
      if (this.gapReport) {
        tabs = Object.fromEntries(
          Object.entries(this.gapReport.result).map(([key, list]) => {
            return [
              key,
              list ? list.length : 0
            ]
          })
        )
      }
      return tabs;
    },
    downloadColumns () {
      let rlt = [
        {
          label: 'Keyword',
          value: 'keyword',
        }
      ]
      this.competitorsUrlIncludingTragetDomain.map(url => {
        rlt.push({
          label: url,
          value: url
        })
      })
      rlt.push({
        label: 'Monthly searches',
        value: 'monthlySearches'
      })
      rlt.push({
        label: 'Keyword difficulties',
        value: 'keywordDifficulties',
      })
      return rlt;
    },
    competitorsUrlIncludingTragetDomain () {
      if (this.gapReport) {
        return [
          this.gapReport.domain,
          ...this.gapReport.competitorsSites.map(c => c.url)
        ]
      }
      return []
    },
    tableData () {
      if (this.gapReport) {
        switch(this.currentTab) {
          case 'shared':
            return this.gapReport.result.shared;
          case 'missing':
            return this.gapReport.result.missing;
          case 'untapped':
            return this.gapReport.result.untapped;
          case 'all':
            return this.gapReport.result.all;
        }
      }
      return []
    },
    sortedTableData () {
      let rlt = this.tableData;
      if (this.searchTerm) {
        const searchTerm = this.searchTerm.toLowerCase()
        rlt = rlt.filter(item => {
          if (item.keyword.includes(searchTerm)) {
            return true;
          }
          return false;
        })
      }
      rlt = rlt.map(item => {
        let { positions, ...newItem} = item;

        this.competitorsUrlIncludingTragetDomain.map(url => {
          let found = positions.find(item1 => item1.site == url)
          if (found) {
            newItem[url] = found.rank
            console.log('...', url, found.rank)
          }
        })
        return newItem
      })
      return rlt;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../keyword-research.scss";

.tabs-container {
  display: flex;
  gap: 15px;
  border-bottom: 1px solid var(--v-grayColor-base);
  
  & > div {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    padding-bottom: 5px;

    span {
      font-size: 1rem;
      color: var(--v-mainColor-base);
    }
    .keywords-count {
      color: var(--v-mainColor-base);
      background: var(--v-grayColor-base);
      border-radius: 50vh;
      padding: 5px 10px;
      font-size: 1rem;
      font-weight: 700;
      
    }

    &.is-active {
      border-bottom: 2px solid var(--v-profileAnchorColor-base);
      .keywords-count {
        color: var(--v-profileAnchorColor-base);
        background: var(--v-lightYellowColor-base);
        
      }
    }
  }

}

</style>
