<template>
  <div class="modal" :class="{'is-active': showModal}">
    <div class="modal-background" @click="$emit('closemodal')"></div>
    <div class="modal-content" style="max-width: 650px;">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="title-font is-size-5 mainColor--text mb-5">Missing Chrome Extension</div>
          <div class="mainColor--text is-size-6">
            You have selected to use the POP Chrome Extension but it has not been installed.
          </div>
          <div class="d-flex justify-end mt-15">
            <v-btn plain
              class="mainColor--text text-none text-decoration-underline" @click="handleChangeLocation">
              <span>Change Location</span>
            </v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none ml-3"
              target="_blank" href="https://pageoptimizer.pro/pageoptimizer-pro-chrome-extension-guide/">
              <span>Install Extension</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="$emit('closemodal')"><i class="material-icons">close</i></button>
    </div>
  </div>
</template>

<script>
export default {
    props: ['showModal'],
    methods: {
        handleChangeLocation () {
            this.$emit('closemodal')
            this.$emit('changelocation')
        }
    }
}
</script>

<style>

</style>