<template>
  <div>
    <div>
      <div class="modal" :class="{ 'is-active': createReportPop }">
        <div @click="allModalClose"></div>
        <div class="modal-content">
          <div class="box modal-container">
            <div class="modal-content-root">
              <div class="select-a-setup-type">AI Writer report</div>
              <div class="setup-type-explain mt-4">
                Type your target keyword and select your target language and
                region
              </div>
              <div class="columns mt-2 m-auto">
                <div class="column is-half">
                  <div>
                    <div class="mb5">
                      <b>Domain</b>
                    </div>
                    <div class="control">
                      <input
                        type="text"
                        class="input site-url-input"
                        id="url"
                        ref="url"
                        v-model="url"
                        placeholder="https://www.yourdomain.com"
                      />
                    </div>
                    <div class="has-text-danger mt-2">
                      {{ aiErrors?.urlError }}
                    </div>
                  </div>
                  <div>
                    <div class="mb5 mt-5">
                      <b>Language</b>
                    </div>
                    <div class="control">
                      <v-autocomplete
                        :items="targetLanguages"
                        v-model="thisTargetLang"
                        placeholder="Choose language"
                        @change="handleChangeLanguage"
                        property
                        outlined
                        dense
                      ></v-autocomplete>
                    </div>
                    <div
                      class="has-text-danger"
                      :style="{ marginTop: '-15px' }"
                    >
                      {{ aiErrors?.languageError }}
                    </div>
                  </div>
                </div>

                <div class="column is-half">
                  <div>
                    <div
                      class="mb5 wow_disaled slideInLeft"
                      data-wow-delay="0.3s"
                    >
                      <b>Target keyword</b>
                    </div>
                    <div class="control">
                      <input
                        type="text"
                        class="input site-url-input"
                        id="keyword"
                        ref="keyword"
                        v-model="keyword"
                        placeholder="Type your target keyword"
                      />
                    </div>
                    <div class="has-text-danger mt-2">
                      {{ aiErrors?.keywordError }}
                    </div>
                  </div>
                  <div>
                    <div class="mb5 mt-5">
                      <b>Region</b>
                    </div>
                    <div class="control">
                      <v-autocomplete
                        :items="googleSearchLocations"
                        placeholder="Choose region"
                        v-model="searchLocation"
                        @change="handleLocationSelected"
                        item-text="name"
                        property
                        item-value="id"
                        outlined
                        dense
                      ></v-autocomplete>
                    </div>
                    <div
                      class="has-text-danger"
                      :style="{ marginTop: '-13px' }"
                    >
                      {{ aiErrors?.regionError }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="continue-button-area">
                <v-btn
                  rounded
                  color=""
                  class="button-with-icon back-button-text mr-2 mainColor--text text-none"
                  @click="dismissPickingReportTypeModalAndGoBack"
                >
                  <span>Back</span>
                </v-btn>
                <v-btn
                  rounded
                  color="profileAnchorColor"
                  class="button-with-icon continue-button-text whiteColor--text text-none"
                  @click="submitForm"
                >
                  <span>Continue</span>
                  <i class="material-icons">arrow_forward</i>
                </v-btn>
              </div>
            </div>
          </div>
          <button
            class="custom-modal-close-btn"
            aria-label="close"
            @click="allModalClose"
          >
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showErrorModal }">
      <div class="modal-background" @click="showErrorModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden">
          <div
            v-if="errorMsg == ''"
            class="title-font is-size-4 mb20 mainColor--text"
          >
            Confirm domain name
          </div>
          <div class="subtitle-font mainColor--text">
            <div v-if="errorMsg != ''" class="has-text-danger">
              We aren't able to find {{ url }}, it's possible that the site
              isn't live yet. Do you still wish to proceed with that domain?
            </div>
            <div v-else>
              Please double check that this domain name is correct. Does the
              domain use http:// or https://? Do you see the correct TLD (.com,
              .io, etc)?
            </div>
          </div>
          <div class="control">
            <input
              type="text"
              class="input is-large mainColor--text"
              id="completeDomain"
              v-model="completeDomain"
            />
          </div>
          <div class="mt-5 mainColor--text">
            You can <b>edit the domain name above</b> - we need this to be
            accurate as we use it to create the project.<br />
            <div v-if="!isDomainHasProtocol" class="has-text-danger is-size-7">
              Your project domain must start with either http:// or https://
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="showErrorModal = false"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { cleanText } from "@/utils";
import { PageRunPages } from "@/components/page_run_pages.js";

export default {
  props: ["targetLanguages", "createReportPop"],
  data() {
    return {
      extensionOptions: {
        displayText: "Exact Location (Chrome Extension)",
        value: "extension_use",
      },
      thisTargetLang: this.targetLang,
      searchLocation: "",
      selectedRegion: "",
      googleSearchProgress: 0,
      url: "",
      errorMsg: "",
      keyword: "",
      completeDomain: "",
      selectedLanguage: "",
      showErrorModal: false,
      submittingRun: false,
      aiErrors: {
        urlError: "",
        keywordError: "",
        regionError: "",
        languageError: "",
      },
    };
  },
  computed: {
    googleSearchLocations() {
      if (this.$store.getters.getGoogleLocations) {
        return this.$store.getters.getGoogleLocations.filter(item => !item.includes('Extension'))
      }
      return [];
    },
    isDomainHasProtocol() {
      return (
        this.completeDomain &&
        (this.completeDomain.startsWith("http://") ||
          this.completeDomain.startsWith("https://"))
      );
    },
  },
  methods: {
    getHostName(url) {
      if (url.indexOf("//") > -1) {
        return url.split("/")[2];
      } else {
        return url.split("/")[0];
      }
    },
    extractHostnameWithProtocol(url) {
      let hostname = this.getHostName(url);
      if (!this.url.includes(hostname)) hostname = this.getHostName(this.url); // this validated domain is different to user inputed, then we will use user inputted
      if (url.toLowerCase().startsWith("https")) {
        return "https://" + hostname;
      } else {
        return "http://" + hostname;
      }
    },
    isUrlEnteredAsGood() {
      let url = this.url.toLowerCase();
      if (!url) {
        return false;
      }
      if (!this.isValidUrl()) {
        return false;
      }
      return true;
    },
    isValidUrl() {
      if (
        !this.url.includes(".") ||
        this.url.endsWith(".") ||
        this.url.startsWith(".")
      ) {
        return false;
      }
      const regexp =
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
      let url = this.url.toLowerCase();
      if (!url.startsWith("http")) {
        url = "http://" + url;
      }
      return regexp.test(url);
    },
    validForm() {
      return (
        !!this.keyword &&
        !!this.competitorUrls &&
        (this.isGoogleSearchDoneWithExtension || !!this.proxyLocation)
      );
    },
    submitForm() {
      if (!this.selectedLanguage) {
        this.aiErrors.languageError = "Please choose a language";
      }
      if (!this.selectedRegion) {
        this.aiErrors.regionError = "Please choose a region";
      }
      if (!this.keyword) {
        this.aiErrors.keywordError = "Please enter keyword";
      }
      if (!this.url) {
        this.aiErrors.urlError = "Please enter valid url";
      }
      if (Object.values(this.aiErrors).some(Boolean)) {
        return;
      }

      this.$store.commit("showLoading");
      this.addNewSite();
    },
    addNewSite() {
      if (!this.isUrlEnteredAsGood()) {
        return;
      }
      const projectName = this.getHostName(this.url);
      this.errorMsg = "";
      this.$store.commit("showLoading");
      this.$store
        .dispatch("validateSiteDomain", this.url)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.completeDomain = this.extractHostnameWithProtocol(
              response.data.url
            );
          } else if (response.data.status === "FAILURE") {
            this.errorMsg = response.data.msg;
            this.completeDomain = this.extractHostnameWithProtocol(this.url);
            this.showErrorModal = true;
            this.$store.commit("hideLoading");
            return;
          }

          return this.$store
            .dispatch("createSite", {
              name: projectName,
              url: this.completeDomain,
              useAdvancedStrategyByDefault: 0,
            })
            .then((response) => {
              if (response.data.status === "FAILURE") {
                const { data: respObj } = response;
                if (respObj.site && respObj.msg === "You have already project that has same name") {
                  console.log('Detected existing project for this ai writer report creation.')
                  // const existingPage = respObj.site.pages?.find(page => page.name === this.keyword);
                  // const pageRunId = existingPage?.lastPageRunInfo?.pageRunId;
                  // if (pageRunId) {
                  //   this.$store.dispatch("loadUser").then((response) => {
                  //     this.$store.commit("setUser", response.data.user);
                  //     this.$router.replace(
                  //       `/content-editor?pageRunId=${pageRunId}&editorMode=AI_MODE`
                  //     );
                  //   });
                  //   return
                  // }
                } else {
                  this.errorMsg = response.data.msg;
                  return 1;
                }
              } else if (response.data.status === "UNAVAILABLE_DOMAIN") {
                this.errorMsg = response.data.status;
                return
              } else { // only add if it's new site not existing
                this.$store.commit("addSite", response.data.site);
              }
              this.$store.commit("setAiWritingData", {
                lang: this.selectedLanguage,
                region: this.selectedRegion,
                keyword: this.keyword,
                url: this.url,
              });
              this.$store.commit("hideLoading");
              this.$router.push(
                `/ai-writer-waiting/${response.data.site.id}`
              );
              this.$emit("close-modal")
              this.$emit("mainModalCLose")
            })
            .catch((err) => {
              this.errorMsg = String(err);
            })
            .finally(() => this.$store.commit("hideLoading"));
        })
        .finally(() => {});
    },
    handleKeywordChange(e) {
      this.kw = e.target.value;
      this.$emit("input", e.target.value);
    },
    dismissPickingReportTypeModalAndGoBack() {
      this.$emit("close-modal");
    },
    allModalClose() {
      this.$emit("mainModalCLose");
    },
    handleChangeLanguage(option) {
      this.selectedLanguage = option;
    },
    handleLocationSelected(option) {
      this.selectedRegion = option;
    },
  },
  created() {
    this.PageRunPages = PageRunPages;
    this.cleanText = cleanText;
  },
  watch: {
    createReportPop(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.selectedLanguage = "";
        this.url = "";
        this.selectedRegion = "";
        this.searchLocation = "";
        this.keyword = "";
        this.selectedLanguage = null;
        this.selectedRegion = null;
        this.keyword = null;
        this.url = null;
      }
    },
    selectedLanguage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.aiErrors.languageError = "";
      }
    },
    selectedRegion(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.aiErrors.regionError = "";
      }
    },
    keyword(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.aiErrors.keywordError = "";
      }
    },
    url(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.aiErrors.urlError = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  color: var(--v-mainColor-base);
}

.modal-content {
  width: 30vw;
}

ul {
  margin-left: 0;
}

.tag {
  margin: 3px;
}

.back-button-text {
  border: 1px solid var(--v-mainColor-base);
  height: 38px !important;
  background: none !important;

  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }
}

.continue-button-text {
  height: 38px !important;

  i,
  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }

  i {
    margin-top: 2px;
  }
}

.site-url-input {
  color: var(--v-primary-base);
}

@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 700px;
  }
}

.select-a-setup-type {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
}

.setup-type-explain {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.setup-type-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > div {
    flex: 1 1 270px;
    border: 2px solid var(--v-grayColor-base);
    border-radius: 5px;
    padding: 20px 50px;
    cursor: pointer;

    &.active {
      border-color: var(--v-profileAnchorColor-base);
    }

    i.material-icons {
      color: var(--v-profileAnchorColor-base);
      font-size: 2rem;
    }

    .setup-type-name {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 800;
    }

    .type-explain {
      margin-top: 5px;
      font-size: 1rem;

      ul {
        padding-left: 0;
        margin-top: 10px;
        list-style: inside;
      }
    }
  }
}

.continue-button-area {
  margin-top: 10px;
  text-align: right;
}
</style>
