<template>
<div class="limited-width-box">
  <v-card class="is-fullheight flex flex-column white-common-box">
    <div class="pa-5 flex flex-column">
      <div class="title-font mainColor--text box-title-label">
        White label reports
      </div>
      <div class="notify-label">
        <i class="material-icons">error_outline</i>
        Here you can add your company logo to your reports. Please upload a file that is at least 240px wide and 60px high.
      </div>

      <div class="mt20">
        <v-btn rounded
          @click="showModal"
          color="lightGreenColor"
          class="button-with-icon text-none white--text">
          <span style="width: 100%; white-space: break-spaces;">
            {{ customLogo ? 'Change a logo of  your company' : 'Upload a logo of your company' }}
          </span>
        </v-btn>
      </div>                 
    </div>
    <div>
      <logo-upload field="img"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        v-model="show"
        :langExt="langExt"
        :width="240"
        :height="60"
        :url="uploadUrl"
        :params="params"
        :headers="headers"
        :noCircle="true"
        img-format="png"></logo-upload>
    </div>
    <div>
      <!-- <v-btn rounded outlined
          
          color="mainColor"
          class="button-with-icon text-none mt10">
          Delete logo
        </v-btn> -->
    </div>
  </v-card>
  <div class="modal" :class="{'is-active': changeOrDeleteLogoModal}">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="mb20">
            <div>
              <div class="mainColor--text is-size-4">Update Logo</div>
            </div>
            <div class="best-practice-list mt10 is-size-5">
              <div v-if="customLogo" class="mt10 has-text-centered">
                <img
                  :src="`${customLogo}?nocache=${nocache}`"
                  class="current-logo" />
              </div>
            </div>
          </div>
          <div class="control d-md-flex d-sm-block mt20">
            <div class="flex-grow-1"></div>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none mb-1" @click="showModal">
              <i class="fa fa-pencil-square-o mr5"></i>
              <span>Change logo</span>
            </v-btn>
            <v-btn rounded
              v-if="customLogo"
              color="red3Color"
              class="button-with-icon whiteColor--text text-none mb-1"
               @click="deleteLogo">
              <i class="fa fa-pencil-square-o mr5"></i>
              <span>Delete logo</span>
            </v-btn>
            <v-btn rounded
              color="grayColor"
              class="button-with-icon gray2Color--text text-none mb-1"
              @click="changeOrDeleteLogoModal=false">
              <i class="fa fa-times mr5"></i>
              <span>Cancel</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import LogoUpload from 'vue-image-crop-upload/upload-2.vue';



export default {
  components: {
    LogoUpload,
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    customLogo () {
      return this.user.customLogo
    },
  },
  watch: {
    'show': function (newVal) {
      if (newVal == false) {
        this.nocache = performance.now()
      }
    }
  },
  data () {
    return {
      show: false,
      nocache: performance.now(),
      changeOrDeleteLogoModal: false,
      
      uploadUrl: `${API_URL}/user/upload-logo/`,
      params: {},
      headers: { Authorization: `Bearer: ` },
      langExt: {
        hint: 'Click or drag your logo image file here to upload.',
        loading: 'Uploading…',
        noSupported: 'Browser is not supported, please use IE10+ or other browsers',
        success: 'Upload success',
        fail: 'Upload failed',
        preview: 'Preview',
        btn: {
          off: 'Cancel',
          close: 'Close',
          back: 'Back',
          save: 'Save'
        },
        error: {
          onlyImg: 'Image only',
          outOfSize: 'Image exceeds size limit: ',
          lowestPx: 'Your logo is too small.  Please increase the size to at least '
        }
      }
    }
  },
  methods: {
    showModal () {
      if (this.customLogo && !this.changeOrDeleteLogoModal && !this.show) {
        this.changeOrDeleteLogoModal = true;
        return;
      } else {
        this.setHeaders();
        this.changeOrDeleteLogoModal = false;
        this.show = true
      }
    },
    loadUser () {
      return this.$store.dispatch('loadUser')
              .then((response) => {
                this.$store.commit('setUser', response.data.user)
              })
    },
    setHeaders () {
      this.headers = { Authorization: `Bearer: ${this.$store.getters.getToken}` }
    },
    cropSuccess (imgDataUrl, field) {
      console.log('Image crop callback', imgDataUrl, field)      
    },
    cropUploadSuccess (jsonData, field) {
      console.log('image crop upload success callback', jsonData, field)
      this.loadUser();
    },
    cropUploadFail (status, field) {
      console.log('crop uplaod fail callback', status, field)
    },

    deleteLogo () {
      if (!window.confirm('Do you want to delete your logo?')) {
        return;
      }
      this.$store.dispatch('deleteCustomLogo')
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.loadUser();
          this.$notify({
            group: 'info', type: 'success',
            text: 'Your logo has been deleted.'
          });
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Can not delete your logo.'
          });
        }
      })
    }
  }
}
</script>

<style>
.vicp-img {
    max-width: unset;
    /* max-width: unset !important; */
}

@media only screen and (max-width: 500px) {
  .vicp-wrap {
    padding: 10px !important;
  }
  .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item {
    margin-right: 0 !important;
  }
  .vue-image-crop-upload .vicp-wrap {
    width: auto;
  }
}
.current-logo {
  border: 1px solid gray;
}
</style>
