<template>
<div class="voltage-result-area mt-5 mb-16">
  <template v-if="!isCalculating && tableData.length > 0">
    <div class="header-part d-flex align-center">
      <v-text-field outlined dense hide-details
        label="Search"
        prepend-inner-icon="search"
        style="max-width: 300px;"
        v-model="searchTerm">
      </v-text-field>
      <div class="flex-grow-1"></div>
      <div class="d-flex align-center gap10">
        <v-btn
          v-if="showResetSortingBtn"
          outlined
          color="mainColor"
          class="button-with-icon text-none"
          @click="resetSorting">
          <span>Reset sorting</span>
        </v-btn>
        <div class="keyword-filter-area">
          <div>FILTERS</div>
          <div class="filter-btn" @click="() => { showFilterPopup = true; }">
            Apply filters
            <span class="material-icons-outlined">keyboard_arrow_down</span>

            <div class="filter-popup"
              v-if="showFilterPopup" v-click-outside="() => { showFilterPopup = false; }"
              style="position: absolute; right: 0px; top: 50px; z-index: 12;">
              <div class="filter-item-label">Page Opportunity Score</div>
              <div class="range-wrapper">
                <v-range-slider hide-details
                  :min="filterScoreMinMax[0]"
                  :max="filterScoreMinMax[1]"
                  v-model="filterScoreRange"
                  color="profileAnchorColor"
                  step="1"
                  stroke="10"
                  thumb-label="always"
                ></v-range-slider>
              </div>
              <div class="filter-item-label">Clicks</div>
              <div class="range-wrapper">
                <v-range-slider hide-details
                  :min="filterClicksMinMax[0]"
                  :max="filterClicksMinMax[1]"
                  v-model="filterClicksRange"
                  color="profileAnchorColor"
                  step="1"
                  stroke="10"
                  thumb-label="always"
                ></v-range-slider>
              </div>
              <div class="filter-item-label">Impressions</div>
              <div class="range-wrapper">
                <v-range-slider hide-details
                  :min="filterImpressionsMinMax[0]"
                  :max="filterImpressionsMinMax[1]"
                  v-model="filterImpressionsRange"
                  color="profileAnchorColor"
                  step="5"
                  stroke="10"
                  thumb-label="always"
                ></v-range-slider>
              </div>
              <div class="filter-item-label">CTR</div>
              <div class="range-wrapper">
                <v-range-slider hide-details
                  :min="filterCTRMinMax[0]"
                  :max="filterCTRMinMax[1]"
                  v-model="filterCTRRange"
                  color="profileAnchorColor"
                  step="5"
                  stroke="10"
                  thumb-label="always"
                ></v-range-slider>
              </div>
              <div class="filter-item-label">Average Position</div>
              <div class="range-wrapper">
                <v-range-slider hide-details
                  :min="filterPositionMinMax[0]"
                  :max="filterPositionMinMax[1]"
                  v-model="filterPositionRange"
                  color="profileAnchorColor"
                  step="5"
                  stroke="10"
                  thumb-label="always"
                ></v-range-slider>
              </div>
              <div class="filter-item-label">Ranking Keywords</div>
              <div class="range-wrapper">
                <v-range-slider hide-details
                  :min="filterRankingKeywordsMinMax[0]"
                  :max="filterRankingKeywordsMinMax[1]"
                  v-model="filterRankingKeywordsRange"
                  color="profileAnchorColor"
                  step="5"
                  stroke="10"
                  thumb-label="always"
                ></v-range-slider>
              </div>
              <div class="d-flex align-center gap10">
                <v-btn color="profileAnchorColor"
                  class="text-none whiteColor--text flex-grow-1"
                  @click="applyFiltersAction">
                  <span>Apply</span>
                </v-btn>
                <v-btn outlined color="mainColor"
                  class="text-none"
                  @click="() => {
                    updateFilterRangeReset()
                  }">
                  <span>Reset All</span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <table-pdf-csv-download
          btnLabel="Download"
          :tableId="`voltage-table table`"
          fileName="keyword-wizard-keywords"
          :columns="downloadableColumns"
          :rows="sortedTableData"
          ></table-pdf-csv-download>
      </div>
    </div>
    <b-table
      id="voltage-table"
      class="voltage-table"
      ref="voltage-table"
      :data="sortedTableData"
      paginated
      checkable
      :checked-rows.sync="checkedRows"
      :total="sortedTableData.length"
      :per-page="perPage"
      :default-sort-direction="'desc'"
      :default-sort="[defaultSortField, defaultSortOrder]"
      @sort="onSortChange">
      <template slot-scope="props">
        <b-table-column field="hiddenDefaultSortColumn" label="hiddenDefaultSortColumn" :visible="false" sortable :custom-sort="handleDefaultSort">
          -
        </b-table-column>
        <b-table-column
          field="page" label="Landing Page" sortable class="url-cell">
          <a target="_blank" :href="props.row.page" :title="props.row.page">
            {{ props.row.page }}
          </a>
        </b-table-column>
        <b-table-column field="pageOpportunityScore" label="Page Opportunity Score" sortable centered>
          <b-tag :style="{...scoreTierToColorStyles(props.row.pageOpportunityScoreTier), ...{width: '60px'}}">
            {{ props.row.pageOpportunityScore }}
          </b-tag>
        </b-table-column>
        <b-table-column field="impressions" label="Impressions" sortable>
          {{ props.row.impressions }}
        </b-table-column>
        <b-table-column field="clicks" label="Clicks" sortable>
          {{ props.row.clicks }}
        </b-table-column>
        <b-table-column field="avgCTR" label="CTR" sortable>
          {{ props.row.avgCTR }} %
        </b-table-column>
        <b-table-column field="avgPosition" label="Average Position" sortable>
          {{ props.row.avgPosition }}
        </b-table-column>
        <b-table-column field="keywordsCount" label="Ranking Keywords" sortable>
          {{ props.row.keywordsCount }}
        </b-table-column>
        <b-table-column field="actions" label="Actions">
          <b-tooltip label="Discover keywords for this page" type="is-dark" position="is-left">
            <v-btn
              x-small
              class="button-with-icon text-none"
              color="#FFEDDD"
              :style="{'color': '#FF5700'}"
              @click="() => discoverKeywords([props.row.page])">
              <span class="material-icons-outlined" :style="{'font-size': '16px !important'}">vpn_key</span>
            </v-btn>
          </b-tooltip>
        </b-table-column>
      </template>
    </b-table>

    <div v-if="checkedRows.length > 0" class="action-bar">
      <div class="left-area">
        {{ checkedRows.length }}
      </div>
      <div class="middle-area">
        <div class="item-selected-text">
          {{ checkedRows.length > 1 ? 'Items': 'Item' }} selected
        </div>
        <div
          class="action-btn"
          @click="() => discoverKeywords(checkedRows.map(row => row.page))">
          <span class="material-icons-outlined">vpn_key</span>
          <span>Discover keywords</span>
        </div>
        
        
      </div>
      <div class="right-area">
        <span class="material-icons close-button"
          @click="() =>{
            checkedRows = []
          }">
          close
        </span>
      </div>
    </div>

  </template>
  <template v-else>
    <div>
      <!-- No data to display -->
    </div>
  </template>

</div>
</template>


<script>
import { wildcardMatchString } from "@/utils";
import TablePdfCsvDownload from '@/components/Campaigns/TablePdfCsvDownload.vue'

export default {
  props: {
    isCalculating: {
      default: false,
    },
    resultData: {
      default: []
    },
  },
  components: {
    TablePdfCsvDownload,
  },
  data () {
    return {
      tableData: [],
      avgImpression: 0,
      voltageImpression: 0,

      checkedRows: [],

      searchTerm: '',
      perPage: 100,
      defaultSortOrder: 'desc',
      defaultSortField: 'hiddenDefaultSortColumn',
      showResetSortingBtn: false,

      showFilterPopup: false,
      allAppliedFilters: {},

      filterClicksMinMax: [0, 0],
      filterClicksRange: [0, 0],
      filterImpressionsMinMax: [0, 0],
      filterImpressionsRange: [0, 0],
      filterCTRMinMax: [0, 0],
      filterCTRRange: [0, 0],
      filterPositionMinMax: [0, 0],
      filterPositionRange: [0, 0],
      filterRankingKeywordsMinMax: [0, 0],
      filterRankingKeywordsRange: [0, 0],
    }
  },
  computed: {
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
    siteId () {
      return this.$route.params.siteId
    },
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    sortedTableData () {
      let searchTerm = this.searchTerm ? this.searchTerm.toLowerCase() : ''
      let data = [...this.tableData].filter((item) => {
        if (!searchTerm) {
          return true;
        }
        return item.page.includes(searchTerm)
      })

      let {
        filterScoreRange,
        filterClicksRange,
        filterImpressionsRange,
        filterCTRRange,
        filterPositionRange,
        filterRankingKeywordsRange,
      } = this.allAppliedFilters

      if (filterScoreRange) {
        data = data.filter(item => {
          if (item.pageOpportunityScore >= filterScoreRange[0] && item.pageOpportunityScore <= filterScoreRange[1]) {
            return true;
          }
          return false;
        })
      }
      if (filterClicksRange) {
        data = data.filter(item => {
          if (item.clicks >= filterClicksRange[0] && item.clicks <= filterClicksRange[1]) {
            return true;
          }
          return false;
        })
      }
      if (filterImpressionsRange) {
        data = data.filter(item => {
          if (item.impressions >= filterImpressionsRange[0] && item.impressions <= filterImpressionsRange[1]) {
            return true;
          }
          return false;
        })
      }
      if (filterCTRRange) {
        data = data.filter(item => {
          if (item.avgCTR >= filterCTRRange[0] && item.avgCTR <= filterCTRRange[1]) {
            return true;
          }
          return false;
        })
      }
      if (filterPositionRange) {
        data = data.filter(item => {
          if (item.avgPosition >= filterPositionRange[0] && item.avgPosition <= filterPositionRange[1]) {
            return true;
          }
          return false;
        })
      }
      if (filterRankingKeywordsRange) {
        data = data.filter(item => {
          if (item.keywordsCount >= filterRankingKeywordsRange[0] && item.keywordsCount <= filterRankingKeywordsRange[1]) {
            return true;
          }
          return false;
        })
      }

      // each time we filter data, we also sort with default sort
      // this is so print and download buttons can show conveniently sorted data
      data.sort((a, b) => this.handleDefaultSort(a, b))

      // Filter urls
      const pageUrls = this.config.wizardPOSelectedPageUrls || []

      return pageUrls.length
        ? data.filter(row => 
          pageUrls.filter(
            pageUrl => pageUrl.includes('*')
              ? wildcardMatchString(row.page, pageUrl)                        // selected url contains *, use wildcard match
              : pageUrl.toLocaleLowerCase() === row.page.toLocaleLowerCase()  // selected url does not contain *, check exact match
          ).length > 0
        )
        : data

    },
    downloadableColumns () {
      return [
        {
          label: 'Landing Page',
          value: 'page',
        },
        {
            label: 'Page Opportunity Score',
            value: 'pageOpportunityScore',
            isPercentage: true,
        },
        {
            label: 'Impressions',
            value: 'impressions',
        },
        {
            label: 'Clicks',
            value: 'clicks',
        },
        {
            label: 'CTR',
            value: 'avgCTR',
            isPercentage: true,
        },
        {
            label: 'Average Position',
            value: 'avgPosition',
        },
        {
            label: 'Ranking Keywords',
            value: 'keywordsCount',
        },
      ]
    },
  },
  watch: {
    "config.wizardPOResults": {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal) {

          this.tableData = newVal
          const data = newVal;
          if (this.tableData.length > 0) { // to avoid 0 division
            const $avg = data.reduce((acc, d) => acc + d.impressions, 0) / data.length;
            this.avgImpression = $avg;

            const allImpressions = data.map(g => g.impressions)
            const maxImp = Math.max(...allImpressions)
            const minImp = Math.min(...allImpressions)

            const $voltageAvg = (maxImp + minImp) / 2;
            this.voltageImpression = $voltageAvg;
          } else {
            this.avgImpression = 0;
            this.voltageImpression = 0;
          }
        } else {
          this.tableData = [];
        }
        this.updateFilterRangeReset()
      }
    },
  },
  methods: {
    setConfig(key, value) {
      this.$store.commit("setInsightKeywordsConfig", { key, value });
    },
    updateFilterRangeReset () {
      const scores = this.tableData.map(item => item.pageOpportunityScore)
      let minVal = Math.min(...scores)
      let maxVal = Math.max(...scores)
      this.filterScoreMinMax = [minVal, maxVal]
      this.filterScoreRange = [minVal, maxVal]
      
      const clicks = this.tableData.map(item => item.clicks)
      minVal = Math.min(...clicks)
      maxVal = Math.max(...clicks)
      this.filterClicksMinMax = [minVal, maxVal]
      this.filterClicksRange = [minVal, maxVal]

      const impressions = this.tableData.map(item => item.impressions)
      minVal = Math.min(...impressions)
      maxVal =Math.max(...impressions)
      this.filterImpressionsMinMax = [minVal, maxVal]
      this.filterImpressionsRange = [minVal, maxVal]

      const ctr = this.tableData.map(item => item.avgCTR)
      minVal = Math.min(...ctr)
      maxVal = Math.max(...ctr)
      this.filterCTRMinMax = [minVal, maxVal]
      this.filterCTRRange = [minVal, maxVal]

      const positions = this.tableData.map(item => item.avgPosition)
      minVal = Math.min(...positions)
      maxVal =Math.max(...positions)
      this.filterPositionMinMax = [minVal, maxVal]
      this.filterPositionRange = [minVal, maxVal]

      const keywordsCounts = this.tableData.map(item => item.keywordsCount)
      minVal = Math.min(...keywordsCounts)
      maxVal =Math.max(...keywordsCounts)
      this.filterRankingKeywordsMinMax = [minVal, maxVal]
      this.filterRankingKeywordsRange = [minVal, maxVal]

      this.allAppliedFilters = {}
    },
    applyFiltersAction () {
      this.allAppliedFilters = {
        filterScoreRange: this.filterScoreRange,
        filterClicksRange: this.filterClicksRange,
        filterImpressionsRange: this.filterImpressionsRange,
        filterCTRRange: this.filterCTRRange,
        filterPositionRange: this.filterPositionRange,
        filterRankingKeywordsRange: this.filterRankingKeywordsRange,
      }
    },
    handleDefaultSort(a, b, isAsc) {
      // Sort by clicks (fallback to page asc if impressions equal)
      if (a.clicks == b.clicks) {
        if(a.page < b.page) return -1;
        if(a.page > b.page) return 1;
      } else {
        return Number(b.clicks) - Number(a.clicks);
      }
    },
    onSortChange(field, order) {
      this.showResetSortingBtn = (field !== this.defaultSortField)
    },
    resetSorting() {
      this.$refs['voltage-table'].initSort()
    },
    scoreTierToColorStyles(tier) {
      switch (tier) {
        case 0:
          return {'background-color': '#FFD989', 'color': '#014751'}
        case 1:
          return {'background-color': '#C6FFD2CC', 'color': '#014751'}
        case 2:
          return {'background-color': '#88FF9ACC', 'color': '#014751'}
        case 3:
          return {'background-color': '#43EA64CC', 'color': '#014751'}
        case 4:
          return {'background-color': '#00B12ECC', 'color': '#FFFFFF'}
        case 5:
          return {'background-color': '#008F4F', 'color': '#FFFFFF'}
        default:
          return {'background-color': '#999', 'color': '#FFFFFF'}
      }
    },
    discoverKeywords (urls) {
      this.$router.push({
        name: 'site-only',
        params: {
          siteId: this.siteId,
          campaignId: this.campaignId,
          initialFormValue: {
            wizardSelectedPageUrls: urls
          }
        }
      })
    }
  },
  mounted () {
    this.updateFilterRangeReset()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../keyword-research.scss';

.action-bar {
  width: 360px;
}

</style>
<style lang="scss">
// fix for the automatically checkbox-cell to get the same custom styling as the rest of the table (keyword-research.scss)
// this is because keyword-research.scss above is scoped and checkbox-cell is only loaded after scoped styles are applied
.voltage-result-area {
  .b-table.voltage-table {
    th.checkbox-cell, td.checkbox-cell {
      padding: 12px 5px !important;
    }
    th.checkbox-cell {
      border-width: 0 0 1px !important;
      justify-content: center !important;
    }
    tbody {
      tr:nth-child(2n) {
        td.checkbox-cell {
          background: var(--v-pink1Color-base);
        }
      }
    }
  }
}
.voltage-result-area {
  .b-table.voltage-table {
    .checkbox-cell {
      vertical-align: initial !important;
    }
  }
}
</style>