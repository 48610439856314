<template>
  <div id="ext_checklist">
    <div class="ext_container">

      <div v-show="!isCollapseInputArea">
      
        <template v-if="willUseSecretKey">
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <v-text-field outlined dense
                type="text"
                color="greenColor"
                label="Secret Key"
                v-model="secretKey"
                class="custom-border-input"
                hide-details></v-text-field>
            </div>
            <a :disabled="secretKey.trim()==''" @click.stop="fetchPageRunBySecretKey" class="ml-3 profileAnchorColor--text d-flex align-center font-weight-bold">
              <i class="material-icons">hourglass_empty</i>
              Load page run
            </a>
          </div>
        </template>
        <template v-else>
          <div v-if="!isSelectedLoginOrUseSecretKey" class="text-center" style="width: 60%; margin: auto;">
            <img src="/static/pop_logo.png" class="mb-5" style="width: 120px; height: auto; margin-top: 2rem;" />
            <div class="welcome-message mt-4">
              Welcome to POP<br/>
              Chrome Extension
            </div>
            <div class="must-login-text mt-2">
              You must be logged into POP<br/>
              or use a secret key
            </div>
            <div class="mt-6">
              <v-btn block large
                color="profileAnchorColor" 
                style="font-weight: bold;border-radius: 10px;"
                class="text-none whiteColor--text"
                @click="openLoginPopup" data-tooltip="Plase click this If you are logged in.">
                Sign In
              </v-btn>
            </div>
            <div>
              <v-btn block outlined large
                style="border-color: #CFCFCF; font-weight: bold;border-radius: 10px;"
                class="text-none mt-3"
                @click="checkIfUserLoggedInAlready" data-tooltip="Plase click this If you are logged in.">
                  I'm Logged In
              </v-btn>
            </div>
            <div class="darkGrayColor--text mt-5">
              Or use <span class="profileAnchorColor--text font-weight-bold clickable" @click="() => {
                  willUseSecretKey = true
                  isSelectedLoginOrUseSecretKey = true;
                }">POP Secret Key</span> to log in
            </div>
          </div>
          <div v-else>
            <div class="pick-pagerun-area">
              <v-select outlined dense
                class="custom-border-input"
                v-model="selectedSubAccountRelation"
                label="Account"
                :items="selectAccountItems"
                hide-details>
              </v-select>
              <div class="has-text-right mt-2" v-if="!willUseSecretKey && isAuthenticated">
                <button @click="loadUser"
                  class="button is-small check-login-button tooltip is-tooltip-multipleline is-tooltip-left"
                  data-tooltip="Click here to get your most current PageRun list">
                  <i class="material-icons" style="font-size: 18px;">autorenew</i>
                  {{ 'Refresh page runs list' }}
                </button>
              </div>
              <v-autocomplete outlined dense
                class="custom-border-input"
                v-model="currentPageRunId"
                :search-input.sync="searchPageRunQuery"
                :filter="customPageRunSearch"
                label="Pageruns"
                :items="filteredPageRuns"
                item-title="item.keyword"
                item-value="id"
                >
                <template v-slot:selection="{ item }">
                  <div>
                    {{ item.keyword + ' | ' + toLocalDateString(item.createdAt) + ' | ' + item.siteName + ' | ' + item.id }}
                    {{ item.isGuardRun ? ' ( Watchdog run )' : '' }}
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div>
                    {{ item.keyword + ' | ' + toLocalDateString(item.createdAt) + ' | ' + item.siteName + ' | ' + item.id }}
                    {{ item.isGuardRun ? ' ( Watchdog run )' : '' }}
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </template>
        <div v-if="willUseSecretKey || isAuthenticated" class="mt5">
          <template v-if="currentPageRun">
            <div class="where-are-you-editing mt-2">Where are you editing?</div>
            <div class="mt-2">
              <div class="control">
                <label class="radio small-font">
                  <input type="radio" name="editingWith" v-model="willCheckWithGoogleDoc" :value="false">
                  The website editor for page: <b>{{ currentPageRun.url }}</b>
                </label>
              </div>
            </div>
            <div class="mt-2">
              <div class="control">
                <label class="radio small-font">
                  <input type="radio" name="editingWith" v-model="willCheckWithGoogleDoc" :value="true">
                  Google docs
                </label>
              </div>
            </div>
            <div class="mt-2">
              <v-tooltip bottom color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <div v-if="currentPageRun"
                    v-bind="attrs" v-on="on"
                    class="confirm-correct-page-run" 
                    @click="() => isDoubleCheckedPickingCorrectPagerun = !isDoubleCheckedPickingCorrectPagerun">
                    <span class="material-icons">check_circle</span>            
                    {{ isDoubleCheckedPickingCorrectPagerun ? 'Confirmed': 'Click to confirm correct page run' }}
                  </div>
                </template>
                <div class="white-bg-tooltip">
                  Check this box to confirm you are editing the correct page and looking at the correct PageRun report
                </div>
              </v-tooltip>
              
            </div>
          </template>
        </div>
      </div>

      <template v-if="willUseSecretKey || isAuthenticated">
        <!-- <div>
          <div class="d-flex">
            <hr class="flex-grow-1" style="margin: 0.6rem 0;"/>
            <a v-if="!isCollapseInputArea" @click.stop="isCollapseInputArea=true"><span class="material-icons" >expand_less</span></a>
            <a v-else @click.stop="isCollapseInputArea=false"><span class="material-icons">expand_more</span></a>
          </div>
        </div> -->
        <div class="error-notification" v-if="errorMsg || notFoundYourPage">
          <template v-if="notFoundYourPage">
            <div class="d-flex">
              <div class="mr-2">
                <i class="material-icons info-icon">info</i>
              </div>
              <div class="flex-grow-1">
                <div class="error-title">
                  We can't find the page you want to edit 
                </div>
                <div class="error-content">
                  <div>Please check the following:</div>
                  <ul style="list-style-type: disc;">
                    <li>You have closed and <b>restarted Chrome</b></li>
                    <li>You are using the <b>regular backend page editor</b> and not the frontend page editor</li>
                    <li>If you are editing in a <b>page editor</b>, make sure you only have one page editor open (close all other open pages that have a page editor on them)</li>
                    <li>If you are editing in <b>Google Docs</b>, make sure you only have one Google Doc open (close all other open Google Docs)</li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-else>{{ errorMsg }}</template>
        </div>
        <div v-if="isSelectedLoginOrUseSecretKey"
          class="has-text-centered stick-area mt-2">
          <span :class="canCalcChecklist ? '' : 'tooltip is-tooltip-multiline is-tooltip-top'" :data-tooltip="!currentPageRun ? 'Please select the PageRun you want to work on' : (!isDoubleCheckedPickingCorrectPagerun ? `Check the box above to confirm you are editing the correct page` : '')">
            <v-btn large
              rounded
              color="profileAnchorColor"
              class="start_btn text-none whiteColor--text px-10"
              :disabled="!canCalcChecklist"
              @click="updateEditedSource">
              <img src="/static/pop_logo_white.png" width="24px" />&nbsp;
              <b>{{ hasChecklistData ? 'Check optimization score' : 'Start now' }}</b>
            </v-btn>
          </span>
        </div>
        <div class="flex-grow-1">
          <div class="result-area">
            <div class="checklist-result-area">
              <!-- <div class="control has-text-centered" v-show="currentPageRun">
                <label class="radio" :disabled="!hasContentBriefData">
                  <input type="radio" name="dispResultType" v-model="dispResultType" value="contentBrief" :disabled="!hasContentBriefData" />
                  Content Brief
                </label>
                <label class="radio">
                  <input type="radio" name="dispResultType" v-model="dispResultType" value="signals" />
                  Signals
                </label>
              </div> -->
              <div v-if="dispResultType == 'signals' && currentPageRun && hasChecklistData">
                <!-- {{ JSON.stringify(checklistResult) }} -->
                <div class="control mt-5">
                  <label class="radio">
                    <input type="radio" name="signalType" value="word_count" v-model="signalType" /> Word Count
                  </label>
                  <label class="radio">
                    <input type="radio" name="signalType" value="keyword" v-model="signalType" /> Keyword
                  </label>
                  <label class="radio">
                    <input type="radio" name="signalType" value="variations" v-model="signalType" /> Variations
                  </label>
                  <label class="radio">
                    <input type="radio" name="signalType" value="lsi_terms" v-model="signalType" /> LSI
                  </label>
                  <label class="radio">
                    <input type="radio" name="signalType" value="pageStructure" v-model="signalType" /> Page Structure
                  </label>
                </div>
                <v-card class="white-common-box pa-5 mt-5">
                  <div v-if="signalType=='keyword'">
                    <span class="mainColor--text font-weight-bold mr-2">Keyword</span>
                    <span class="variation-tag">{{ currentPageRun.keyword }}</span>
                  </div>
                  <div v-else-if="signalType=='variations'">
                    <div class="d-flex align-center">
                      <span class="has-text-weight-bold mainColor--text mr-2">
                        Variations
                      </span>
                      <span class="flex-grow-1">
                        <span class="selection-tag blue-active-tag mr10" @click="showVariations=!showVariations">{{ showVariations ? 'Hide Variations' : 'Show Variations' }}</span>
                        <span class="selection-tag blue-active-tag" @click="showWeights=!showWeights" v-if="showVariations">{{ showWeights ? 'Hide Weights' : 'Show Weights' }}</span>
                      </span>
                      <v-btn outlined small color="mainColor" class="text-none"
                        @click.stop="downloadVariationOrLSI">
                        Download as .txt
                      </v-btn>
                    </div>
                    <div class="tags">
                      <template v-if="hasLsaForVariations">
                        <span v-for="(item, idx) in sortedLsaVariations" :key="idx" class="variation-tag" v-show="showVariations">
                          {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ (item.weight * 100).toFixed(2) + '%' }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                        </span>       
                      </template>
                      <template v-else>
                        <span v-for="v in variations" :key="v" class="variation-tag">{{ v }}</span>
                      </template>
                    </div>
                    <a class="has-text-grey has-text-weight-bold is-italic is-size-6" @click.stop="downloadVariationOrLSI">Download as .txt</a>
                  </div>
                  <div v-else-if="signalType=='lsi_terms'">
                    <div class="d-flex align-center">
                      <span class="has-text-weight-bold mainColor--text mr-2">LSI</span>
                      <span class="flex-grow-1">
                        <span class="selection-tag blue-active-tag mr10" @click="showVariations=!showVariations">{{ showVariations ? 'Hide LSI' : 'Show LSI' }}</span>
                        <span class="selection-tag blue-active-tag mr10" @click="showWeights=!showWeights" v-if="showVariations">{{ showWeights ? 'Hide Weights' : 'Show Weights' }}</span>
                      </span>
                      <v-btn outlined small color="mainColor" class="text-none"
                        @click.stop="downloadVariationOrLSI">
                        Download as .txt
                      </v-btn>
                    </div>
                    <div class="tags">
                      <template v-if="sortedLsaPhrases.length > 0">
                        <span v-for="(item, idx) in sortedLsaPhrases" :key="idx" class="variation-tag" v-show="showVariations">
                          {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ (item.weight * 100).toFixed(2) + '%' }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                        </span>       
                      </template>
                    </div>
                    <a class="has-text-grey has-text-weight-bold is-italic is-size-6" @click.stop="downloadVariationOrLSI">Download as .txt</a>
                  </div>
    
                  <div v-if="signalType == 'word_count'" class="pt15">
                    <word-count-view v-if="currentWordCount!=0"
                      :currentCount="currentWordCount"
                      :targetCount="goalWordCountByStrategy"
                      :recommendationText="wordCountRecommendation"
                    ></word-count-view>
                    <template v-else>
                      Please re-run to use this feature
                    </template>
                  </div>
                  <div v-else>
                    <source-editor-checklist
                      v-if="hasChecklistData"
                      :pageId="pageId"
                      @setBallonSignal="setBallonSignal"
                      :signalType="signalType"
                      :pageNotBuilt="pageNotBuilt"
                      :checklist="checklist"
    
                      :isThisExtensionEmbedPage="true"
                      :willUseSecretKey="willUseSecretKey"
                      :secretKey="willUseSecretKey ? secretKey : ''"
                    />
                  </div>
                </v-card>
              </div>
              <div v-if="dispResultType == 'contentBrief' && currentPageRun && hasContentBriefData" class="">
                <content-brief
                  :pageRun="currentPageRun"
                  :createdAt="currentPageRun.createdAt"
                  :url="currentPageRun.url ? currentPageRun.url : currentPage.url"
                  :keyword="currentPageRun.keyword"
                  :secondaryKeywords="currentPageRun.secondaryKeywords"
                  :lsaPhrases="currentPageRun.lsaPhrases"
                  :pageRunId="currentPageRun.id"
                  :pageNotBuilt="pageNotBuilt"
                  :page="currentPage"
                  :ignoreSpaces="currentPageRun.ignoreSpaces"
                  :willUseSecretKey="willUseSecretKey"
                  :secretKey="currentPageRun.secretKey"
                  :contentBrief="contentBrief"
                  :runStrategies="currentPageRun.runStrategies"
                  :tagTotalData="tagTotalData"
                  :wordCountData="[currentWordCount, contentBriefGoalWordCount, getWordCountRecommendation(currentWordCount, contentBriefGoalWordCount)]"
                  :sectionCount="currentPageRun.subHeadingsCount"
                  :gCompetitors="currentPageRun.gCompetitors"
                  :relatedSearches="currentPageRun.relatedSearches"
                  :relatedQuestions="currentPageRun.relatedQuestions"
                  :contentBriefFile="currentPageRun.contentBriefDataFile"
                  :isFromExtension="true"
                  :showAsMobileView="true"
                  @updateCurrentPage="updateCurrentPage"
                  />
              </div>
            </div>
          </div>


        </div>
      </template>

    </div>

    <div class="ballon-layer" v-if="signalExplainData[ballonSignal]" @click.stop="ballonSignal=''">
      <div class="ballon-content" @click.stop="">
        <div class="has-text-right">
          <span class="material-icons clickable" @click.stop="ballonSignal=''">highlight_off</span>
        </div>
        <div v-html="signalExplainData[ballonSignal]"></div>
      </div>
    </div>



    <div class="modal" :class="{ 'is-active': showLoginModal }">
      <div class="modal-background" @click="showLoginModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="font-weight-bold mainColor--text">Log in</div>
          <div class="mt-2">
            <v-text-field outlined dense hide-details="auto"
              color="greenColor" class="mainColor--text"
              v-model="email"
              label="Email"></v-text-field>
            <v-text-field outlined dense hide-details="auto"
              type="password"
              color="greenColor" class="mainColor--text mt-3"
              v-model="password"
              label="Password"></v-text-field>
            <v-text-field
              v-if="needLoginCode"
              outlined dense hide-details="auto"
              color="greenColor" class="mainColor--text mt-3"
              v-model="loginCode"
              label="Your login code ( 2FA auth )"></v-text-field>
              
          </div>
          <div class="text-center">
            <v-btn rounded small
              color="profileAnchorColor"
              :disabled="!email || !password"
              @click="loginAction"
              class="whiteColor--text text-none mt-5">
              <span>Log in</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    
    <div class="content-brief-same-score-modal" v-show="showContentBriefScoreNotChangedModal">
      <div>
        <div class="title-font is-size-4 mb20">
          Your Score Didn't Change
        </div>
        <div class="body-font is-size-6">
          And that's ok!  The Content Brief score won't change too much until you have gotten each section into the correct range.
        </div>
        <div class="text-right mt-5">
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text"
            @click="acknowledgeAndHideContentBriefScoreNotChangedModal">
            <i class="fa fa-check" aria-hidden="true"></i>
            I got it
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
  

<script>

import SourceEditorChecklist from '@/components/PageRun/SourceEditorChecklist.vue'
import ContentBrief from '@/components/PageRun/ContentBrief/ContentBrief.vue'
import { toLocalDateString, toLocalDate, getToken } from '@/utils'
import explainData from '@/components/PageRun/ExplainData'
import { mixin as ContentBriefMixin } from '@/components/content_brief_mixin'
import WordCountView from '@/components/IndependentPages/components/WordCountView.vue'

export default {
  mixins: [ ContentBriefMixin ],
  components: {
    SourceEditorChecklist,
    ContentBrief,
    WordCountView
  },
  data () {
    return {
      showLoginModal: false,
      needLoginCode: false,
      email: '',
      password: '',
      loginCode: '',


      pageRuns: [],
      currentPageRun: null,
      currentPageRunId: null,
      searchPageRunQuery: '',
      
      signalType: 'keyword',
      sourceCode: '',
      currentPage: {},
      checklistResult: {}, //'keyword': keyword_checklists, 'variation': variation_checklists, 'lsiVariation': lsi_variation_checklists, 'pageStructure': tag_counts_checklist
      cleanedContentBrief: [],
      currentTagCounts: {},
      
      errorMsg: '',
      notFoundYourPage: false,



      selectedSubAccountRelation: null,
      showWeights: false,
      showVariations: false,
      isDoubleCheckedPickingCorrectPagerun: false,
      willCheckWithGoogleDoc: false,
      editedCurrentWordCount: 0,


      willUseSecretKey: false,
      secretKey: '',
      isAvailableChromeExtension: false,

      isCollapseInputArea: false,

      ballonSignal: '',

      dispResultType: 'signals', // signals/contentBrief
      isClickToStart: true, // true : if user clicks first time 'click to start' button, false : when user click 'check your work'
      showContentBriefScoreNotChangedModal: false,

      isSelectedLoginOrUseSecretKey: false,
    }
  },
  watch: {
    'selectedSubAccountRelation': function(newVal, oldVal) {
      if (!this.isAuthenticated) {
        console.log('You are not logged in yet so we are ignoring your sub account selection')
        return;
      }
      console.log(newVal)
      this.$store.commit('setCurrentSubAccount', newVal)
      this.loadPageRuns()
    },
    'willUseSecretKey': function () {
      this.initData()
    },
    'currentPageRun': function (newVal, oldVal) {
      if (oldVal && newVal && newVal.id == oldVal.id) { return }
      this.checklistResult = {}
      this.cleanedContentBrief = []
      this.editedCurrentWordCount = 0
      this.isDoubleCheckedPickingCorrectPagerun = false
      this.isClickToStart = true
      this.errorMsg = ''
    },
    'currentPageRunId': function (newVal, oldVal) {
      this.loadPageRun(newVal)
      this.checklistResult = {}
      this.cleanedContentBrief = []
      this.editedCurrentWordCount = 0
      this.isDoubleCheckedPickingCorrectPagerun = false
      this.isClickToStart = true
      this.errorMsg = ''
    },
    'willCheckWithGoogleDoc': function (newVal, oldVal) {
      if (!this.isDoubleCheckedPickingCorrectPagerun) {
        return
      }
      if (newVal != oldVal && newVal==true && !this.hasChecklistData) {
        this.updateChecklist('<html></html>')
      }
    }
  },
  computed: {
    selectAccountItems () {
      let rlt = []
      rlt.push({
        text: 'My account',
        value: ''
      })

      rlt.push({
        text: 'Accounts you have access to:',
        value: '',
        // disabled: true
      })
      if (this.user && this.user.ownerAccounts) {

        this.user.ownerAccounts.map(relation => {
          let label = relation.agencyName
          label += `( Permission: ${ relation.isAllPermission ? 'All Projects' : (relation.permittedProjects.length == 1 ? relation.permittedProjects.length + ' Project' : + relation.permittedProjects.length + ' Projects') }`
          label += `Create Project: ${ relation.canCreateProject ? 'YES': 'NO' } )`
          rlt.push({
            text: label,
            value: relation
          })
        })
      }
      return rlt
    },
    'signalExplainData': function () {
      console.log(explainData)
      return explainData
    },
    'filteredPageRuns': function () {
      return this.pageRuns
      // let keyword = this.keywordFilter.trim().toLowerCase()
      // if (!keyword) {
      //   return this.pageRuns
      // } else {
      //   return this.pageRuns.filter(p => {
      //     if (!p || !p.keyword) return false
      //     return p.keyword.toLowerCase().includes(keyword)
      //   })
      // }
    },
    hasChecklistData () {
      return this.checklistResult && Object.keys(this.checklistResult).length > 0
    },
    checklist () {
      switch(this.signalType) {
        case 'keyword':
          return this.checklistResult.keyword
        case 'variations':
          return this.checklistResult.variations
        case 'lsi_terms':
          return this.checklistResult.lsiTerms
        case 'pageStructure':
          return this.checklistResult.pageStructure
        default:
          return []
      }
    },
    jwt () {
      return this.$store.getters.getToken
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    user () {
      return this.$store.state.user
    },
    canCalcChecklist () {
      return this.currentPageRun != null && this.isDoubleCheckedPickingCorrectPagerun
    },

    pageId: function () {
      return this.currentPageRun.pageId
    },
    page: function () {
      if (this.currentPage) {
        return this.currentPage
      }
      return {}
    },
    pageNotBuilt: function () {
      if (this.currentPageRun) {
        return this.currentPageRun.pageNotBuilt
      }
      return false
    },
    variationsStringArr: function() {
      if (!this.currentPageRun) return []
      return this.currentPageRun.variations
    },
    lsiStringArr: function () {
      if (!this.currentPageRun) return []
      return this.currentPageRun.lsaPhrases.map(item => item.phrase)
    },
    hasLsaForVariations () {
      return this.currentPageRun.lsaVariations.length > 0 && this.currentPageRun.lsaVariations[0].phrase
    },
    variations () {
      if (!this.currentPageRun) return []
      return this.currentPageRun.variations
    },
    sortedLsaVariations: function() {
      if (!this.currentPageRun) return []
      if (this.currentPageRun.lsaVariations.length > 0 && this.currentPageRun.lsaVariations[0].phrase) {
        return this.currentPageRun.lsaVariations
      }
      return []
    },
    sortedLsaPhrases: function () {
      if (this.currentPageRun && this.currentPageRun.lsaPhrases) return this.currentPageRun.lsaPhrases
      return []
    },
    strategy () {
      let strategy = this.page && this.page.strategySetting ? this.page.strategySetting.strategy : 'target'
      return strategy
    },
    approach () {
      let approach = this.page && this.page.strategySetting ? this.page.strategySetting.approach : 'regular'
      return approach
    },
    currentWordCount () {
      if (this.editedCurrentWordCount > 0) return this.editedCurrentWordCount
      if (!this.currentPageRun || !this.currentPageRun.runStrategies) return 0
      let found = this.currentPageRun.runStrategies.find(item => {
        if (this.dispResultType == 'signals')
          return item.dispResultType == this.dispResultType
        else
          return item.approach == this.approach && item.strategy == this.strategy
      })
      if (found && found.rows) {
        found = found.rows.find(item => item.signal == 'Word Count')
        return found.signalData.targetKeywordCnt
      }
      return 0
    },
    avgCompetitorWordCount () {
      if (this.currentPageRun.hasOwnProperty('keywordCounts')) {
        const wordCount = this.currentPageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return Math.round(wordCount.signalData.competitorsAvg)
      }
      return 0
    },
    contentBriefGoalWordCount () {
      return this.calcContentBriefGoalWordCount(this.currentWordCount, this.avgCompetitorWordCount)    
    },
    strategyWordCountTarget () {
      if (!this.currentPageRun || !this.currentPageRun.runStrategies) return 0

      if (
        (this.strategy == 'adjusted' && this.approach == 'regular') ||
        (this.strategy == 'custom')) {
        return this.currentWordCount
      }

      let found = this.currentPageRun.runStrategies.find(item => {
        return item.approach == this.approach && item.strategy == this.strategy
      })
      if (found) {
        found = found.rows.find(item => item.signal == 'Word Count')
        return found.signalData.competitorsAvg
      }
      return 0
    },
    goalWordCountByStrategy () {
      return this.strategyWordCountTarget
    },
    wordCountRecommendation() {
      return this.getWordCountRecommendation(this.currentWordCount, this.goalWordCountByStrategy)
    },


    // for content brief
    hasContentBriefData () {
      return this.contentBrief && Object.keys(this.contentBrief).length > 0
    },
    contentBrief () {
      return this.cleanedContentBrief
      // if (!this.currentPageRun) return []
      // return this.currentPageRun.contentBrief
    },
    tagTotalData () {
      if (!this.currentPageRun) return []
      console.log('current page run is', this.currentPageRun, this.currentPageRun.tagCounts)
      let data = this.currentPageRun.tagCounts.map(row => {
        row['signalCnt'] = 0
        if (row.tagLabel in this.currentTagCounts)
          row['signalCnt'] = this.currentTagCounts[`${row.tagLabel}`]
        return row
      })
      return data
    },
  },
  methods: {
    checkIfUserLoggedInAlready () {
      return this.dispatch_custom_event( "get_localStorage", ['token']);
    },
    customPageRunSearch (item, queryText, itemText) {
      let text = item.keyword + ' | ' + toLocalDateString(item.createdAt) + ' | ' + item.siteName + ' | ' + item.id
      console.log('filtering pagerun for search', text)
      return text.includes(queryText)
    },
    acknowledgeAndHideContentBriefScoreNotChangedModal() {
      this.$store.dispatch('acknowledgeForContentBriefScoreNotChanged', {
        pageRunId: this.currentPageRun.id,
        secretKey: this.willUseSecretKey ? this.secretKey : ''
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          let pageRun = response.data.pageRun
          if (pageRun) {
            if (!this.willUseSecretKey) {
              this.pageRuns = this.pageRuns.map(p => {
                if (p.id == pageRun.id)
                  return pageRun
                return p
              })
            }
            this.currentPageRun = pageRun
          }
        }
      })
      this.showContentBriefScoreNotChangedModal = false
    },
    checkForDisplaySameContentBriefScore() {
      if (this.isClickToStart) { // to avoid score not changed modal when user clicks 'Click to start' button. we will only display it when user clicks 'upate your work' button first time
        this.isClickToStart = false
        return
      }
      if (!this.currentPageRun) {
        return
      }
      if (this.pageNotBuilt) {
        return
      }
      if (this.currentPageRun.otherSettings && this.currentPageRun.otherSettings.acknowlegedForContentBriefScoreNotChanged) {
        return
      }
      if (this.dispResultType != 'contentBrief') {
        return
      }
      let originPageRunScore = 0, livePageScore = 0;
      let found = this.currentPageRun.runStrategies.find(item => {
        if (this.dispResultType == 'contentBrief')
          return item.dashboardType == 'contentBrief'
        else
          return false
      })
      if (found) {
        originPageRunScore = found.pageScore.pageScore
      }
      if (this.cleanedContentBrief) {
        livePageScore = this.cleanedContentBrief.pageScore.pageScore
      }
      if ( Math.abs(originPageRunScore - livePageScore) < 0.001) {
        this.showContentBriefScoreNotChangedModal = true
      }
      this.showContentBriefScoreNotChangedModal = true
    },
    downloadVariationOrLSI () {
      console.log('downloaded')
      let fileName = ''
      let data = ''
      if (this.signalType == 'variations') {
        fileName = 'variations.txt'
        if (this.hasLsaForVariations) {
          this.sortedLsaVariations.map(item => {
            data = data + item.phrase + '(' + (item.weight*100).toFixed(2) + '%' + ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] + ')' + '\r\n'
          })
        }
        else {
          this.variations.map(item => {
            data = data + item + '\r\n'
          })
        }
      } else if (this.signalType == 'lsi_terms') {
        fileName = 'lsi.txt'
        this.sortedLsaPhrases.map(item => {
          data = data + item.phrase + '(' + (item.weight*100).toFixed(2) + '%' + ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] + ')' + '\r\n'
        })
      }

      if (data) {
        fileName = `Report_${this.currentPageRun.id}_` + fileName
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        element.setAttribute('download', fileName);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    },
    isStillLoggedInOrUseSecretKey () {
      if (this.willUseSecretKey) {
        return true
      }
      let token = getToken()
      if (token) {
        return true
      } else {
        this.$store.commit('setJwtToken', { jwt: '' })
        this.initData();
        return false
      }
    },
    openLoginPage () {
      window.open(`${FRONTEND_URL}/#/login`, '_blank')
    },
    openLoginPopup () {
      this.showLoginModal = true;
    },
    toLocalDateString (dateStr) {
      return toLocalDateString(dateStr)
    },

    setBallonSignal (signal) {
      this.ballonSignal = signal
    },

    initData () {
      this.pageRuns = []
      this.currentPageRun = null
    },

    updateEditedSource () {
      this.errorMsg = ''
      this.notFoundYourPage = false;

      if (!this.isStillLoggedInOrUseSecretKey()) { return }

      if (!this.currentPageRun) {
        this.errorMsg = 'Please pick one pagerun'
        return
      }

      this.dispatch_custom_event('source_from_editor', {
        url: this.willCheckWithGoogleDoc ? 'https://docs.google.com' : this.currentPageRun.url,
      })
    },
    updateCurrentPage (page) {
      this.currentPage = page
      if (this.page && this.page.strategySetting && this.page.strategySetting.dashboardType) {
        this.dispResultType = this.page.strategySetting.dashboardType
      } else if (this.hasContentBriefData) {
        this.dispResultType = 'contentBrief'
      } else {
        this.dispResultType = 'signals'
      }
    },
    updateChecklist (source = '') {
      this.$store.commit('showLoading')
      this.$store.dispatch('getChecklistForExtension', {
        pageRunId: this.currentPageRun.id,
        html: source ? source : this.sourceCode,
        keyword: this.currentPageRun.keyword,
        variations: this.variationsStringArr,
        lsiPhrases: this.lsiStringArr,
        ignoreSpaces: this.currentPageRun.ignoreSpaces ? 1 : 0,
        willCheckWithGoogleDoc: this.willCheckWithGoogleDoc,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          console.log(response.data)
          this.checklistResult = response.data.result.checklistResult
          this.cleanedContentBrief = response.data.result.cleanedContentBrief
          this.editedCurrentWordCount = response.data.result.editedCurrentWordCount
          this.currentTagCounts = response.data.result.currentTagCounts
          this.updateCurrentPage(response.data.result.page)
          this.errorMsg = ''
          this.checkForDisplaySameContentBriefScore()
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    loadPageRuns () {
      this.initData()
      this.$store.commit('showLoading')
      this.$store.dispatch('loadPageRunsForExtension')
      .then(response => {
        if (response.data && response.data.status=='SUCCESS') {
          this.pageRuns = response.data.pageRuns
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    loadPageRun () {
      this.$store.commit('showLoading')
      this.$store.dispatch('loadPageRunForExtension', { pageRunId: this.currentPageRunId })
      .then(response => {
        if (response.data && response.data.status=='SUCCESS') {
          this.currentPageRun = response.data.pageRun
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    fetchPageRunBySecretKey () {
      this.initData()
      this.$store.commit('showLoading')
      this.$store.dispatch('fetchPageRunBySecretKeyOrPageRunId', { secretKey: this.secretKey })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          if (response.data.pageRun) {
            this.currentPageRun = response.data.pageRun
          }
        } else {
          this.errorMsg = response.data.msg
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    
    loginAction () {
      this.$store.commit('showLoading')
      let data = { email: this.email, password: this.password }
      if (this.loginCode) {
        data['loginCode'] = this.loginCode
      }
      this.$store.dispatch('loginFromExtension', data)
        .then((response) => {
          this.$store.commit('hideLoading')
          if (response.data && response.data.status === 'SUCCESS') {
            if (response.data.action == 'LOGIN_CODE_NEEDED') {
              this.needLoginCode = true;              
              this.$store.commit('hideLoading')
              return;              
            }

            this.$store.commit('setCurrentSubAccount', '')
            this.$store.commit('setJwtToken', { jwt: response.data.token })
            this.$store.commit('setUser', response.data.user)
            this.postLoadUserAction(response.data.user);
          } else {
            if (response.data && response.data.action == 'INVALID_LOGIN_CODE') {
              this.$notify({ group: 'info', type: 'warning', text: 'Invalid login code' })
              return;
            }
            this.$notify({
              group: 'info', type: 'warning',
              text: 'Error authenticating. Please verify credentials or register for a free trial.'
            })
            return 1
          }
        })
        .catch(error => {
          console.log("Error authenticating", error)
          this.$notify({ group: 'info', type: 'error', text: 'Error authenticating.' })
          this.$store.commit('hideLoading')
        })
    },

    loadUser () {
      this.initData()
      this.$store.commit('showLoading')
      this.$store.dispatch('loadUser', true)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.postLoadUserAction(response.data.user);
        } else {
          console.log('Error', response)
          this.$store.commit('hideLoading')
        }
      })
      .catch(err => {
        console.log(err)
        this.$store.commit('hideLoading')
      })
    },
    postLoadUserAction (user) {
      this.showLoginModal = false;

      this.isSelectedLoginOrUseSecretKey = true;
      this.$store.commit('setUser', user)
      this.loadPageRuns();
    },

    checkExtensionIsInstalled () {
  
      if ( document.readyState === "interactive" || document.readyState === "complete" ) {
  
        if ( document.documentElement.dataset.page_optimizer_browser_extension_version ) {
          this.isAvailableChromeExtension = true
          console.log('Adding event listener for POP extension!!')
          document.addEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
        } else {
          this.isAvailableChromeExtension = false
          console.log('can not find extension in this browser')
        };
  
      };
    },
    dispatch_custom_event: function ( name, data ) {

      if (!this.isAvailableChromeExtension) {
        console.log("chrome extension is not installed!")
        return window.alert("Can not detect extension. Please click 'hard refresh' button for it.")
      }

      // the web application should fire this event to trigger the extension
      // the extension will listen for this event

      var event = new CustomEvent(

        "web_app_event", 
        {
          detail: {
            name: name,
            data: data,
          },
          bubbles: true,
          cancelable: true
        }
      );
      console.log('Dispatching event to extension', name)
      document.dispatchEvent( event );
    },
    extensionEventListener (event) {
      var name = event.detail.name;
      var data = event.detail.data;

      console.log('extension response', name, data)
      
      if ( name === "source_from_editor_result" ) {
        let resData = data.source_from_editor_result
        console.log('EDITOR PARSE EXTENSION RESULT', resData)
        if (resData.status == 'FAILURE') {
          this.notFoundYourPage = true;
        } else if (resData.status == 'SUCCESS') {
          this.sourceCode = resData.source
          this.updateChecklist()
        }
      } else if (name === "get_localStorage_result") {
        let resData = data.get_localStorage_result
        if (resData.token) {
          localStorage.setItem("token", resData.token)
          this.loadUser()
        }
      }
    },
    getWordCountRecommendation(currentWordCount, goalWordCount) {
      return this.calcWordCountRecommendation(currentWordCount, goalWordCount)
    }

  },
  beforeDestroy () {
    document.removeEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
  },
  mounted () {
    
    document.addEventListener("DOMContentLoaded", (event) => { 
      //do work
      console.log('Checking whether extension is installed')
      this.checkExtensionIsInstalled()
    });

    this.selectedSubAccountRelation = this.$store.state.selectedSubAccountRelation
    // this.loadUser();
  }
    
}
</script>


<style lang="scss" scoped>
#ext_checklist {
  /* height: 100vh;
  overflow: auto; */
  background: rgba(0,0,0,0);
  position: relative;
.custom-border-input {
  border-radius: 10px;
}
  .ext_container {
    margin: 0px 5px;
    /* border: 1px solid rgb(255, 255, 58); */
    padding: 10px;
    border-radius: 15px;
    background: white;
    overflow-y: auto;
    position: relative;
    height: 635px;
    /* display: flex;
    flex-direction: column; */
    justify-content: center;
  }

  .welcome-message {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
  }
  .must-login-text {
    font-size: 14px;
    line-height: 14px;
    color: var(--v-darkGrayColor-base);
  }

  .where-are-you-editing {
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
  }

  .confirm-correct-page-run {
    padding: 6px 10px;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--v-green11Color-base);
    background: var(--v-green10Color-base);
    margin-bottom: 10px;
    span {
      font-size: 14px;
      color: var(--v-green11Color-base);
      margin-right: 5px;
    }
  }

  .check-login-button {
    border: none;
    font-weight: bold;
    color: var(--v-profileAnchorColor-base);
    display: inline-flex;
    align-items: center;
    i {
      font-size: 13px;
      margin-right: 5px;
    }
  }

  .select:not(.is-multiple):not(.is-loading)::after, .navbar-link:not(.is-arrowless)::after {
    /*
    border: none;
    font-family: "Material Icons"; content: "\e3c8";
    transform: rotate(0);
    transform: translateY(-50%);
    font-size: 1.1rem; */

    right: 15px;
    top: 65%;
    color: #999;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: "";
    transform: rotate(0deg);
  }
  .multiselect__placeholder, .multiselect__input::placeholder {
    color: #000000 !important;
  }
  .multiselect {
    font-size: 0.75rem;
  }


  .error-notification {
    background: var(--v-red1Color-base);
    margin-top: 10px;
    margin-bottom: 25px;
    color: black;
    border-radius: 5px;
    padding: 10px 10px;
    border-left: 5px solid var(--v-red3Color-base);

    .info-icon {
      color: var(--v-red3Color-base);
      font-size: 17px;            
    }

    .error-title {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
      color: var(--v-red3Color-base);
    }
    .error-content * {
      font-size: 12px;
    }
  }

  .pick-pagerun-area {
    
  }

  .stick-area {
    position: sticky;
    top: 2px;
    z-index: 100;
  }

  .checklist-result-area {

  }

  .has-text-centered.stick-area {
    z-index: 1;
  }




  .custom-small-tooltip:hover::before {
    min-width: 15rem;
  }

  .content-brief-same-score-modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(243, 243, 243, 0.904);
    padding: 0 20px;
    z-index: 9999;
  }

  .small-font {
    font-size: 14px;
  }
  .start_btn {
    width: 80%;
    * {
      font-size: 18px;
    }
  }
}
</style>