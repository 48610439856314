<template>
<div class="section">
  <no-stripe-card-modal ref="noStripeCardModalRef"></no-stripe-card-modal>
  
  <div class="container">
    <trial-conversion-button></trial-conversion-button>
    <h2 class="step-title mt-3 mainColor--text">Change my plan</h2>
    <div class="mt-5">
      <div>
        <div class="custom-service-purchase-tabs">
          <div :class="{ 'is-active': currentTabLabel == 'Single user plans' }"
            @click="() => {
              currentTabLabel = 'Single user plans'
              handleTabChange(0)
            }">
            Single user plans
          </div>
          <div :class="{ 'is-active': currentTabLabel == 'Team plans' }"
            @click="() => {
              currentTabLabel = 'Team plans'
              handleTabChange(1)
            }">
            Team plans
          </div>
        </div>
      </div>

      <div v-show="currentTabLabel == 'Single user plans'" class="plans-flex mt-3">
        <div>
          <div class="pricing-plan mt-5">
            <div class="top-part">
              <div class="plan-title">Basic</div>
              <div class="price-per-period">
                <span class="price">${{ servicePkgBPrice }}</span> /month
              </div>
            </div>
            <div class="middle-part">
              <div class="features-label">For those getting started</div>
              <div class="plan-item">
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    20 POP Credits per month to access POP Reports, Watchdog, EEAT, Google's NLP API, and AI Writer
                  </div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">Unlimited domains</div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">Access to POP AI Writer</div>
                </div>
              </div>
            </div>
            <div class="bottom-part">
              <v-btn
                rounded block large
                :color="selectedServicePkg === `${pricing_prefix}SERVICE_PKG_B2` ? 'green7Color' : 'profileAnchorColor'"
                class="whiteColor--text font-weight-bold text-none"
                @click="updateSelectedServicePkg(`${pricing_prefix}SERVICE_PKG_B2`)">
                <span v-if="selectedServicePkg === `${pricing_prefix}SERVICE_PKG_B2`" class="d-flex align-center">
                  <span class="material-icons" v-if="user.accountType == `${pricing_prefix}SERVICE_PKG_B2`">done</span>
                  {{ user.accountType == `${pricing_prefix}SERVICE_PKG_B2` ? 'Your current plan' : 'Selected' }}
                </span>
                <span v-else>Choose Basic plan</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <div v-if="showYearlyForSingleUserPlan" class="pricing-plan popular-plan">
            <div class="most-popular">
              Most popular
            </div>
            <div class="top-part">
              <div class="plan-title">Unlimited</div>
              <div class="price-per-period">
                <span class="price">${{ servicePkgEPrice }}</span> /year
                <span class="save-in-yearly-box">
                  <span>%</span>
                  Save 16%
                </span>
              </div>
            </div>
            <div class="middle-part">
              <div class="features-label">Best for high traffic sites</div>
              <div class="plan-item">
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    Unlimited* POP Reports and Watchdog Credits per month
                  </div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    50 POP EEAT, AI Writer, NLP Credits per month
                  </div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">Unlimited domains</div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">Access to POP AI Writer</div>
                </div>
              </div>
            </div>
            <div class="bottom-part">
              <v-btn 
                rounded block large
                :color="selectedServicePkg === `${pricing_prefix}SERVICE_PKG_E` ? 'green7Color' : 'profileAnchorColor'"
                class="whiteColor--text font-weight-bold text-none"
                @click="updateSelectedServicePkg(`${pricing_prefix}SERVICE_PKG_E`)"
                >
                <span v-if="selectedServicePkg === `${pricing_prefix}SERVICE_PKG_E`" class="d-flex align-center">
                  <span class="material-icons" v-if="user.accountType == `${pricing_prefix}SERVICE_PKG_E`">done</span>
                  {{ user.accountType == `${pricing_prefix}SERVICE_PKG_E` ? 'Your current plan' : 'Selected' }}
                </span>
                <span v-else>Choose Unlimited plan</span>
              </v-btn>
              <div class="d-flex justify-center">
                <div class="monthly-yearly-toggle">
                  <a @click="togglePlantypeForSinglePlan">Monthly</a>
                  <span>Yearly</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="pricing-plan popular-plan">
            <div class="most-popular">
              Most popular
            </div>
            <div class="top-part">
              <div class="plan-title">Unlimited</div>
              <div class="price-per-period">
                <span class="price">${{ servicePkgDPrice }}</span> /month
              </div>
            </div>
            <div class="middle-part">
              <div class="features-label">Best for high traffic sites</div>
              <div class="plan-item">
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    Unlimited* POP Reports and Watchdog Credits per month
                  </div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    50 POP EEAT, AI Writer, NLP Credits per month
                  </div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">Unlimited domains</div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">Access to POP AI Writer</div>
                </div>
              </div>
            </div>
            <div class="bottom-part">
              <v-btn 
                rounded block large
                :color="selectedServicePkg === `${pricing_prefix}SERVICE_PKG_D` ? 'green7Color' : 'profileAnchorColor'"
                class="whiteColor--text font-weight-bold text-none"
                @click="updateSelectedServicePkg(`${pricing_prefix}SERVICE_PKG_D`)"
                >
                <span v-if="selectedServicePkg === `${pricing_prefix}SERVICE_PKG_D`" class="d-flex align-center">
                  <span class="material-icons" v-if="user.accountType == `${pricing_prefix}SERVICE_PKG_D`">done</span>
                  {{ user.accountType == `${pricing_prefix}SERVICE_PKG_D` ? 'Your current plan' : 'Selected' }}
                </span>
                <span v-else>Choose Unlimited plan</span>
              </v-btn>
              <div class="d-flex justify-center">
                <div class="monthly-yearly-toggle">
                  <span>Monthly</span>
                  <a @click="togglePlantypeForSinglePlan">Yearly</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="pricing-plan mt-5">
            <div class="top-part">
              <div class="plan-title">Premium</div>
              <div class="price-per-period">
                <span class="price">${{ servicePkgCPrice }}</span> /month
              </div>
            </div>
            <div class="middle-part">
              <div class="features-label">Best for a simple site</div>
              <div class="plan-item">
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    40 POP Credits per month to access POP Reports, Watchdog, EEAT, Google's NLP API, and AI Writer
                  </div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">Unlimited domains</div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">Access to POP AI Writer</div>
                </div>
              </div>
            </div>
            <div class="bottom-part">
              <v-btn
                rounded block large
                :color="selectedServicePkg === `${pricing_prefix}SERVICE_PKG_C` ? 'green7Color' : 'profileAnchorColor'"
                class="whiteColor--text font-weight-bold text-none"
                @click="updateSelectedServicePkg(`${pricing_prefix}SERVICE_PKG_C`)">
                <span v-if="selectedServicePkg === `${pricing_prefix}SERVICE_PKG_C`" class="d-flex align-center">
                  <span class="material-icons" v-if="user.accountType == `${pricing_prefix}SERVICE_PKG_C`">done</span>
                  {{ user.accountType == `${pricing_prefix}SERVICE_PKG_C` ? 'Your current plan' : 'Selected' }}
                </span>
                <span v-else>Choose Premium plan</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div v-show="currentTabLabel == 'Team plans'" class="plans-flex mt-3">
        <div>
          <div class="pricing-plan" :class="{'is-active': isAgencyPlanSelected('monthly')}">
            <div class="top-part">
              <div class="plan-title" v-html="simplifyProductNameForTeamPlan(SERVICE_PKG_AA_CONSTANT.title)"></div>
              <div class="price-per-period">
                <span class="price">
                  <span class="plan-price-currency">$</span>{{ getAgencyPlanPrice('monthly')}}
                </span> /month
              </div>
            </div>
            <div class="middle-part">
              <div class="features-label">FEATURES</div>
              <div class="plan-item">
                <div v-for="(item, idx) in (SERVICE_PKG_AA_CONSTANT.items || [])" :key="idx">
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    {{ Array.isArray(item) ? item[0] : item }}
                  </div>
                  <div>{{ Array.isArray(item) ? item[1] : '' }}</div>
                </div>
              </div>
            </div>
            <div class="bottom-part">
              <v-btn
                rounded block large
                :color="isAgencyPlanSelected('monthly') ? 'green7Color' : 'profileAnchorColor'"
                class="whiteColor--text font-weight-bold text-none"
                @click="updateSelectedServicePkgForAgency('monthly')">
                <span v-if="isAgencyPlanSelected('monthly')" class="d-flex align-center">
                  <span class="material-icons" v-if="user.accountType == `${pricing_prefix}SERVICE_PKG_AA`">done</span>
                  {{ user.accountType == `${pricing_prefix}SERVICE_PKG_AA` ? 'Your current plan' : 'Selected' }}
                </span>
                <span v-else>Choose</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <div class="pricing-plan" :class="{'is-active': isAgencyPlanSelected('yearly')}">
            <div class="top-part">
              <div>
                <div class="plan-title" v-html="simplifyProductNameForTeamPlan(SERVICE_PKG_BB_CONSTANT.title)"></div>
                
                
              </div>
              <div class="price-per-period">
                <span class="price">
                  <span class="plan-price-currency">$</span>{{ getAgencyPlanPrice('yearly') }}
                </span> /year
              </div>
            </div>
            <div class="middle-part">
              <div class="features-label">FEATURES</div>
              <div class="plan-item">
                <div v-for="(item, idx) in (SERVICE_PKG_AA_CONSTANT.items || [])" :key="idx">
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    {{ Array.isArray(item) ? item[0] : item }}
                  </div>
                  <div>{{ Array.isArray(item) ? item[1] : '' }}</div>
                </div>
              </div>
            </div>
            <div class="bottom-part">
              <v-btn
                rounded block large
                :color="isAgencyPlanSelected('yearly') ? 'green7Color' : 'profileAnchorColor'"
                class="whiteColor--text font-weight-bold text-none"
                @click="updateSelectedServicePkgForAgency('yearly')">
                <span v-if="isAgencyPlanSelected('yearly')" class="d-flex align-center">
                  <span class="material-icons" v-if="user.accountType == `${pricing_prefix}SERVICE_PKG_BB`">done</span>
                  {{ user.accountType == `${pricing_prefix}SERVICE_PKG_BB` ? 'Your current plan' : 'Selected' }}
                </span>
                <span v-else>Choose</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div>
          <div class="pricing-plan" :class="{'is-active': isAgencyPlanSelected('monthly_white_glove')}">
            <div class="top-part">
              <div class="plan-title" v-html="simplifyProductNameForTeamPlan(SERVICE_PKG_CC_CONSTANT.title)"></div>
              <div class="price-per-period">
                <span class="price">
                  <span class="plan-price-currency">$</span>{{ getAgencyPlanPrice('monthly_white_glove')}}
                </span> /month
              </div>
            </div>
            <div class="middle-part">
              <div class="features-label">FEATURES</div>
              <div class="plan-item">
                <div v-for="(item, idx) in (SERVICE_PKG_CC_CONSTANT.items || [])" :key="idx">
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    {{ Array.isArray(item) ? item[0] : item }}
                  </div>
                  <div>{{ Array.isArray(item) ? item[1] : '' }}</div>
                </div>
                <div>
                  <img src="/static/green-circle-checkmark.png" />
                  <div class="flex-grow-1">
                    Seats are added for <strong>${{ EXTRA_SEAT_CC_BASE_PRICE }} / mo</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-part">
              <v-btn
                v-if="user.allowWhiteGlovePurchase || user.accountType == `${pricing_prefix}SERVICE_PKG_CC`"
                rounded block large
                :color="isAgencyPlanSelected('monthly_white_glove') ? 'green7Color' : 'profileAnchorColor'"
                class="whiteColor--text font-weight-bold text-none"
                @click="updateSelectedServicePkgForAgency('monthly_white_glove')">
                <span v-if="isAgencyPlanSelected('monthly_white_glove')" class="d-flex align-center">
                  <span class="material-icons" v-if="user.accountType == `${pricing_prefix}SERVICE_PKG_CC`">done</span>
                  {{ user.accountType == `${pricing_prefix}SERVICE_PKG_CC` ? 'Your current plan' : 'Selected' }}
                </span>
                <span v-else>Choose</span>
              </v-btn>
              <v-btn
                v-else
                rounded block large
                color="green7Color"
                class="whiteColor--text font-weight-bold text-none"
                @click="showWhiteGloveMeetingForm = true">
                <span>Book a meeting</span>
              </v-btn>
              <div v-if="showWhiteGloveMeetingForm">
                <iframe src="https://meetings.hubspot.com/andrew-steven/kyle-andy?embed=true&amp;parentHubspotUtk=355db957e50e51de53a5764165292cea&amp;parentPageUrl=https://www.pageoptimizer.pro/pricing&amp;_ga=2.161359677.1862271784.1726158477-633970671.1724094012" width="100%" data-hs-ignore="true" style="min-width: 312px; min-height: 615px; height: 25px; border: none;"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-center mt-3" v-if="isYearlyPakcageSelected">
        <v-checkbox dense
          color="mainColor"
          v-model="payViaInvoice"
          hide-details="auto"
          class="py-0 mt-0"
          label="I want to pay via invoice">
        </v-checkbox>
      </div>
      <div class="d-flex justify-center mt-3" style="gap: 10px;">
        <!-- <div class="control">
          <div id="purchase-paypal-button"></div>
        </div> -->
        <v-btn rounded outlined
          color="mainColor"
          class="button-with-icon text-none"
          @click.stop="() => {
              paymentMethod = 'stripe';
              confirmedUseExistingPayment = false;
              submit();
            }"
          :disabled="!selectedServicePkg">
          <span class="icon">
            <i class="fa fa-credit-card-alt"></i>
          </span>
          <span>Next</span>
        </v-btn>
        <!-- <div v-if="!isPaypalDisabled" class="control">
          <v-btn rounded outlined
            color="mainColor"
            class="button-with-icon text-none"
            id="paypal-purchase-button"
            @click="() => {
              paymentMethod = 'paypal';
              submitForPayPal();
            }"
            :disabled="!selectedServicePkg">
            <span class="icon">
              <i class="fa fa-cc-paypal"></i>
            </span>
            <span>PayPal</span>
          </v-btn>
        </div> -->
        <!-- <div class="flex-grow-1"></div>
        <div class="control">
          <v-btn small rounded
            color="profileAnchorColor"
            to="/sites"
            class="button-with-icon text-none whiteColor--text">
            <span>Cancel</span>
          </v-btn>
        </div> -->
      </div>

      <div class="text-center mt-5 mainColor--text">
        <div>
          You will be charged automatically every month. For more information, please go to
          <a href="https://www.pageoptimizer.pro/pricing" target="_blank" class="link15Color--text text-decoration-underline">https://www.pageoptimizer.pro/pricing</a>
        </div>
        <div class="mt-2">
          *Subject to our Fair Use Policy
        </div>
      </div>
    </div>

  </div>

  <div class="modal" :class="{'is-active': showPurchaseCompleteModal}">
    <div class="modal-background" @click.stop="showPurchaseCompleteModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div v-if="isYearlyPakcageSelected && payViaInvoice">
            <div class="title-font is-size-4 mainColor--text">Invoice sent</div>
            <div class="body-font is-size-6 mainColor--text mt-5">You will receive an invoice via email. Please ensure timely payment.</div>
          </div>
          <div v-else>
            <p class="title-font is-size-4 mainColor--text">Purchase Complete</p>
            <p class="subtitle-font mainColor--text">{{ successMsg }}</p>
            <p class="subtitle-font mainColor--text" v-if="!successMsg">Please click the button below to go to projects page and run a page analysis</p>
          </div>

          <div class="d-flex justify-end mt-5">
            <div class="flex-grow-1"></div>
            <v-btn rounded color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click.stop="goToSites">
              Go to projects
            </v-btn>
            <v-btn rounded color="profileAnchorColor"
              v-if="!payViaInvoice && isTeamsPlan"
              class="button-with-icon whiteColor--text text-none ml-2" @click.stop="goToProfile">
              Add more seats
            </v-btn>
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showPurchaseCompleteModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showErrorModal}">
    <div class="modal-background" @click.stop="clearErrorModal"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title">Transaction failure</p>
          <br>
          <p class="subtitle" v-if="!errorMsg">An error happened.</p>
          <p class="subtitle" v-if="errorMsg">{{ errorMsg }}</p>
          <div class="field">
            <div class="control">
              <button class="button is-primary" @click.stop="clearErrorModal">Back to Purchases</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.stop="clearErrorModal"></button>
  </div>

  <div class="modal" :class="{'is-active': showDeleteSubAccountsModal}">
    <div class="modal-background" @click.stop="showDeleteSubAccountsModal = false"></div>
    <div class="modal-content" style="min-width: 60%;">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Action Required</p>
          <p class="subtitle mt-5 mainColor--text">
            Your account has {{ user.subAccounts.length }} active Sub Accounts. <br/>
            Your new plan only allows {{ maxAvailableSubAccountCountInNewPlan }} Sub Accounts. <br/>
            Please delete {{ user.subAccounts.length - maxAvailableSubAccountCountInNewPlan }} Sub Accounts from the list below.
          </p>
          <div class="mt-5">
            <p class="is-size-5 mainColor--text">Active Sub Accounts</p>
            <article class="message is-hoverable is-dark" v-for="(account, idx) in user.subAccounts" :key="idx">
              <div class="message-body">
                <div class="d-flex gap10">
                  <div v-if="maxAvailableSubAccountCountInNewPlan>0">
                    <input type="checkbox" v-model="selectedSubAccountIds" :value="account.id" />
                  </div>
                  <div style="flex-grow: 1;">
                    <div class="font-weight-bold is-size-5 mainColor--text">{{ account.subAccountEmail }}</div>
                    <div class="mt-2">
                      Permission: {{ account.isAllPermission ? 'All projects' : (account.permittedProjects.length == 1 ? account.permittedProjects.length+' project' :  account.permittedProjects.length+' projects') }},
                      Create projects: {{ account.canCreateProject ? 'YES': 'NO' }}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <br/>
          <div class="d-flex justify-end gap10">
            <v-btn rounded
              color="profileAnchorColor"
              class="text-none whiteColor--text"
              @click.stop="deleteExceedSubAccountsAndDownGrade"
              :disabled="false && selectedSubAccountIds.length==0 && maxAvailableSubAccountCountInNewPlan>0">
              <span>
                {{ maxAvailableSubAccountCountInNewPlan > 0 ? 'Delete Selected SubAccounts and Downgrade' : 'Delete All Sub Accounts and Downgrade'}}
              </span>
            </v-btn>
            <v-btn rounded
              color="grayColor"
              class="text-none whiteColor--text"
              @click.stop="cancelUpgradeDowngrade">
              <span>
                Cancel
              </span>
            </v-btn>
          </div>
          <div class="mt-5 is-italic">
            None of your projects will be deleted. Sub Accounts will lose access to your projects.
          </div>
          <br/>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.stop="cancelUpgradeDowngrade"></button>
  </div>

  <div class="modal" :class="{ 'is-active': showCouponAppliedModal }">
    <div
      class="modal-background"
      @click="showCouponAppliedModal = false"
    ></div>
    <div class="modal-content" style="max-width: 700px;">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="promo-applied-modal">
          <div class="text-center">
            <i class="material-icons">check_circle_outline</i>
          </div>
          <div class="title-label">Promo code applied!</div>
          <div class="explain">
            {{ couponCodeApplyMsg }}
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showCouponAppliedModal = false)"
      >
      <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <!-- <div class="modal" :class="{'is-active': showConfirmUseExistingPayment}">
    <div class="modal-background" @click.stop="onCancelNewPurchase"></div>
    <div class="modal-content" style="min-width: 50%;">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="title-font mainColor--text is-size-4">
            Payment Source Already On File
          </div>
          <div class="body-font is-size-6 has-text-grey is-italic mt-5">
            Looks like we already have a payment source on file for you ending in {{ preferredCard && preferredCard.hasOwnProperty('last4') ? preferredCard.last4 : '' }}
          </div>
          <div class="control flex mt-5">
            <div class="flex-grow-1"></div>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whiteColor--text" @click.stop="onConfirmUseExistingCard">Use Card On File</v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whiteColor--text" @click.stop="onUseDifferentCard">Enter Different Card</v-btn>
            <v-btn rounded
              color="grayColor"
              class="button-with-icon text-none white--text" @click.stop="onCancelNewPurchase">Cancel</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="modal" :class="{'is-active': showTryingSamePlanChangeModal}">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div class="body-font mainColor--text subtitle">
            Your current plan is <span class="font-weight-bold subtitle mainColor--text">{{ serviceLabel(user.accountType) }}</span> please choose another plan to upgrade. 
          </div>
          <div class="control flex mt20">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none"
              @click="showTryingSamePlanChangeModal = false;">
              Ok
          </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showTryingSamePlanChangeModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>


  <div class="modal" :class="{'is-active': showCheckoutModal}">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 900px; max-width: 95vw;">
      <div class="box modal-container" style="overflow: hidden; padding: 0;">
        <div class="checkout-modal">
          <div class="d-flex pad25">
            <div class="modal-title">Checkout</div>
          </div>
          <div class="pad25 main-part">
            <div class="card-part">
              <div class="sub-title mb-5" style="min-height: 30px;">Card details</div>
              <div v-if="preferredCard" class="preferred-card-info">
               <div>
                <template v-if="preferredCard.brand == 'visa'">
                  <img src="/static/visa.png" width="40px" />
                </template>
                <template v-else-if="preferredCard.brand == 'mastercard'">
                  <img src="/static/mastercard.png" width="40px" />
                </template>
                <template v-else>
                  {{ preferredCard.brand }}
                </template>
               </div>
               <div class="flex-grow-1 card-text">
                ending in {{ preferredCard.last4 || '' }}
               </div>
               <div>
                <a @click="onUseDifferentCard">Edit credit card</a>
               </div>
              </div>
            </div>
            <div class="summary-part">
              <div class="d-flex align-center mb-5" style="min-height: 30px;">
                <div class="sub-title">
                  Summary
                </div>
                <div class="flex-grow-1 text-right">
                  <template v-if="showApplyCouponBox">
                    <div class="promo-input-in-popup">
                      <input type="text" placeholder="coupon code" v-model="promoCode">
                      <a class="coupon-apply-btn" @click="applyCoupon">
                        Apply
                      </a>
                    </div>
                  </template>
                  <template v-else>
                    <a @click="showApplyCouponBox = true;" class="show-apply-coupon-button">
                      <span class="material-icons-outlined">add</span>
                      Apply coupon code
                    </a>
                  </template>
                </div>
              </div>
              <p v-if="serviceCouponsMessage && serviceCouponsValid" class="help has-text-success">{{ serviceCouponsMessage }}</p>
              <p v-if="serviceCouponsMessage && !serviceCouponsValid" class="help has-text-danger">{{ serviceCouponsMessage }}</p>
              <div class="summary-box pb-5">
                <div class="d-flex align-center">
                  <div class="flex-grow-1">
                    <div>Current subscription</div>
                    <div class="font-weight-bold" v-html="simplifyProductNameForTeamPlan(serviceLabel(user.accountType))">
                    </div>
                  </div>
                  <div class="nowrap">
                  </div>
                </div>
                <div class="d-flex align-center mt-5">
                  <div class="flex-grow-1">
                    <div>New subscription</div>
                    <div class="font-weight-bold" v-html="simplifyProductNameForTeamPlan(serviceLabel(selectedServicePkg))">
                    </div>
                  </div>
                  <div class="nowrap">
                    $ {{ selectedPkgPrice.toFixed(2) }}
                  </div>
                </div>
                <div class="d-flex align-center mt-5" v-if="amountDue > -1">
                  <div class="flex-grow-1">
                    <div>Amount due</div>
                    <div class="font-weight-bold">
                    </div>
                  </div>
                  <div class="nowrap">
                    $ {{ amountDue.toFixed(2) }}
                  </div>
                </div>
                <div class="pt-5 mt-5 top-border buttons-area">
                  <v-btn rounded
                    color="profileAnchorColor"
                    class="button-with-icon whiteColor--text text-none"
                    @click="() => {
                      confirmedUseExistingPayment = true;
                      showCheckoutModal = false;
                      submit();
                    }">
                    <span>Subscribe</span>
                  </v-btn>
                  <a class="cancel-link" @click="showCheckoutModal = false;">
                    Cancel
                  </a>
                </div>
                <div class="mt-5">
                  *if you have time remaining on your current subscription, you will pay a pro-rated amount today.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showCheckoutModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

</div>
</template>

<script>
import { segmentTrack, segmentPage, getPureServicePkgName } from '@/utils'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import {
  DEFAULT_PREFIX,
  agencyPlansWithAdditionalSeatsAvailable,
} from "@/components/plans_constants"
import ALL_PLANS_OBJ from "@/components/service_package_constants"
import TrialConversionButton from "@/components/TrialConversionButton.vue";
import NoStripeCardModal from "@/components/NoStripeCardModal.vue";

export default {
  name: 'ServicePurchase',
  mixins: [ServiceMixin],
  components: {
    TrialConversionButton,
    NoStripeCardModal
  },
  data () {
    return {
      showPurchaseCompleteModal: false,
      errorMsg: '',
      successMsg: '',
      showErrorModal: false,

      selectedSubAccountIds: [],
      paymentMethod: '',
      showDeleteSubAccountsModal: false,
      confirmedUseExistingPayment: false,
      showConfirmUseExistingPayment: false,
      useDifferentCard: false,
      preferredCard: null,

      pricing_prefix: DEFAULT_PREFIX,


      showCouponAppliedModal: false,
      couponCodeApplyMsg: '',

      showTryingSamePlanChangeModal: false,
      showApplyCouponBox: false,
      showCheckoutModal: false,

      showWhiteGloveMeetingForm: false,
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isPaypalDisabled () {
      if (this.user) {
        return this.user.isPaypalDisabled
      }
      return true
    },
    isTeamsPlan () {
      if (!this.user) {
        return false;
      }
      let pureServicePkg = getPureServicePkgName(this.user.accountType)
      return agencyPlansWithAdditionalSeatsAvailable.includes(pureServicePkg);
    },
    maxAvailableSubAccountCountInNewPlan () {
      return this.getMaxSubAccountCountInPackage(this.selectedServicePkg)
    },

    // SERVICE_PKG_E_CONSTANT () {
    //   return ALL_PLANS_OBJ['SERVICE_PKG_E']
    // },
    SERVICE_PKG_AA_CONSTANT () {
      return ALL_PLANS_OBJ['SERVICE_PKG_AA']
    },
    SERVICE_PKG_BB_CONSTANT () {
      return ALL_PLANS_OBJ['SERVICE_PKG_BB']
    },
    SERVICE_PKG_CC_CONSTANT () {
      return ALL_PLANS_OBJ['SERVICE_PKG_CC']
    }
  },
  methods: {
    simplifyProductNameForTeamPlan(name) {
      if (!name) return '';
      let result = name;
      return result.replace('(', '<br/>(')
    },
    goToSites () {
      this.showPurchaseCompleteModal = false
      this.$router.push('/sites')
    },
    goToProfile () {
      this.showPurchaseCompleteModal = false;
      const userId = this.user ? this.user.id : '';
      if (userId) {
        this.$router.push(`/useraccount/${userId}`);
      }
    },
    clearErrorModal () {
      this.errorMsg = ''
      this.showErrorModal = false
    },
    applyCoupon () {
      return this.fetchValidatedCoupons(true).then(coupons => {
        console.log('coupon to apply', coupons)
        try {
          const coupon = coupons && coupons.length ? coupons[0] : null
          if (['Period Free', 'Credits'].includes(coupon.couponType)) {
            this.submitPeriodOrCreditCoupon();
          }
          this.calculateProrationAmount()
        } catch(e) {
          console.error(e)
        }
      })
    },
    submitPeriodOrCreditCoupon() {
      if (this.promoCode) {
        this.$store.commit("showLoading");
        this.couponCodeApplyMsg = '';
        return this.$store
          .dispatch("addCoupon", { couponCode: this.promoCode.trim() })
          .then(response => {
            const { data } = response;
            if (data.status === "SUCCESS") {
              this.$store.commit("setUser", response.data.user);
              this.appliedOfPeriodFreeCoupon = true;
              this.couponCodeApplyMsg = response.data.msg;
              this.promoCode = "";

              if (data.approvalUrl) {
                window.open(data.approvalUrl, "_self");
              } else {
                this.showCouponAppliedModal = true;
              }
            } else {
              console.log('this of coll')
              this.serviceCouponsValid = false;
              this.serviceCouponsMessage = data.msg ? data.msg : data.errorMsg;
              return 1;
            }
          })
          .finally(() => this.$store.commit("hideLoading"));
      }
    },
    // submitForPayment () {
    //   if (this.isTryingSamePlanChange()) {
    //     this.showTryingSamePlanChangeModal = true;
    //     return;
    //   }

    //   if (!this.checkCanDowngradeIfCurrentIsAgencyPlan()) {
    //     return
    //   }
    //   this.$store.commit('showLoading')
    //   return this.$store.dispatch('createPurchase', {
    //     userId: this.user.id,
    //     servicePackage: this.selectedServicePkg,
    //     paymentService: this.paymentMethod,
    //     promoCode: this.promoCode.trim(),
    //     description: this.serviceDescription,
    //   })
    //   .then((response) => {
    //     if (response.data.status === 'FAILURE') {
    //       this.errorMsg = response.data.msg
    //       this.showErrorModal = true
    //     } else {
    //       this.$store.commit('setUser', response.data.user)
    //       segmentTrack('purchase', {
    //         paymentService: this.paymentMethod,
    //         email: response.data.user.email,
    //         userID: response.data.user.email.id,
    //         servicePackage: this.selectedServicePkg,
    //         promoCode: this.promoCode.trim(),
    //       })
    //       if (this.paymentMethod == 'paypal') {
    //         window.open(response.data.approvalUrl, '_self')
    //       } else if (this.paymentMethod == 'stripe') {
    //         console.log('check.')
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     this.errorMsg = "Transaction denied"
    //     console.log('stripe error', err)
    //   })
    //   .finally(() => this.$store.commit('hideLoading'))
    // },
    submitForPayPal () {
      if (this.isTryingSamePlanChange()) {
        this.showTryingSamePlanChangeModal = true;
        return;
      }

      this.isDowngradeByStripe = false
      if (!this.checkCanDowngradeIfCurrentIsAgencyPlan()) {
        return
      }
      this.$store.commit('showLoading')
      return this.$store.dispatch('createPurchase', {
        userId: this.user.id,
        servicePackage: this.selectedServicePkg,
        paymentService: 'paypal',
        promoCode: this.promoCode.trim()
      })
      .then((response) => {
        if (response.data.status === 'FAILURE') {
          this.errorMsg = response.data.msg
          this.showErrorModal = true
        } else {
          this.$store.commit('setUser', response.data.user)
          segmentTrack('purchase', {
            paymentService: 'paypal',
            email: response.data.user.email,
            userID: response.data.user.email.id,
            servicePackage: this.selectedServicePkg,
            promoCode: this.promoCode.trim()
          })
          window.open(response.data.approvalUrl, '_self')
        }
      })
      .catch((err) => {
        this.errorMsg = "Transaction denied"
        console.log('stripe error', err)
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    deleteExceedSubAccountsAndDownGrade () {
      console.log(this.selectedSubAccountIds)
      let data = {
        ids: this.selectedSubAccountIds
      }
      if (this.maxAvailableSubAccountCountInNewPlan==0) {
        data = {
          ids: this.user.subAccounts.map(item => item.id)
        }
      }

      this.$store.commit('showLoading')
      this.$store.dispatch('deleteSubAccounts', data)
        .then(res => {
          this.showDeleteSubAccountsModal = false
          this.$store.commit('hideLoading')
          if (res.data && res.data.status=='SUCCESS') {
            this.$store.commit('setUser', res.data.user)
            this.submitForPayment
          }
        })
        .finally(() => { })
    },
    cancelUpgradeDowngrade() {
      // this.updateSelectedServicePkg(this.user.accountType)
      this.showDeleteSubAccountsModal = false
    },
    checkCanDowngradeIfCurrentIsAgencyPlan () {
      if (!this.isAgencyPlan(this.user.accountType)) {
        return true
      }
      let currentSubAccountCount = this.user.subAccounts.length
      console.log(currentSubAccountCount, this.maxAvailableSubAccountCountInNewPlan)
      if (currentSubAccountCount > this.maxAvailableSubAccountCountInNewPlan) {
        this.selectedSubAccountIds = []
        this.showDeleteSubAccountsModal = true
        return false
      } else {
        return true
      }
    },
    onConfirmUseExistingCard () {
      this.confirmedUseExistingPayment = true
      this.hideConfirmUseExistingCardDialog()
      this.submit()
    },
    onUseDifferentCard () {
      this.$router.push(`/useraccount/${this.user.id}?mainTab=billing-and-payments&subTab=credit-cards`)
      // this.useDifferentCard = true
      // this.hideConfirmUseExistingCardDialog()
      // this.submit()
    },
    onCancelNewPurchase () {
      this.confirmedUseExistingPayment = false
      this.useDifferentCard = false
      this.hideConfirmUseExistingCardDialog()
    },
    hideConfirmUseExistingCardDialog () {
      this.showConfirmUseExistingPayment = false
    },
    isTryingSamePlanChange () {
      if (!this.user) {
        return false;
      }
      if (this.user.activeSubscriber && this.selectedServicePkg == this.user.accountType) {
        return true;
      }
      return false;
    },
    calculateProrationAmount () {
      this.amountDue = -1
      this.$store.dispatch('getProratedAmountForPurchase', {
        'servicePackage': this.selectedServicePkg,
        'promoCode': this.promoCode,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          console.log('response for proration', response.data)
          this.amountDue = response.data.amountDue
        }
      })
    },
    submit () {
      if (this.isTryingSamePlanChange()) {
        this.showTryingSamePlanChangeModal = true;
        return;
      }


      this.isDowngradeByStripe = true
      if (!this.checkCanDowngradeIfCurrentIsAgencyPlan()) {
        return
      }
      const order = {
        description: this.serviceDescription,
        servicePackage: this.selectedServicePkg,
        amount: this.purchaseAmt
      }
      if (this.purchaseAmt < 0) {
        this.errorMsg = 'Invalid purchase attempt.'
        return
      }
      console.log('check order here', order);
      let promise
      if (this.user.hasPaymentSource && !this.useDifferentCard) {
        if (!this.confirmedUseExistingPayment) {
          // this.showConfirmUseExistingPayment = true
          this.showCheckoutModal = true;

          this.calculateProrationAmount()
          return;
        }
        this.$store.commit('showLoading')
        promise = this.$store.dispatch('createPurchase', {
          userId: this.user.id,
          servicePackage: this.selectedServicePkg,
          stripeToken: '',
          paymentService: 'stripe',
          description: order.description,
          promoCode: this.promoCode.trim(),
          payViaInvoice: this.isYearlyPakcageSelected ? this.payViaInvoice : false,
        })
      } else {
        this.$refs.noStripeCardModalRef.showModalAction();
        return;
        // promise = doStripeCheckout(this.user.email, order)
        //   .then((response) => {
        //     if (response.data && response.data.status === 'CLOSED') {
        //       return new Promise((resolve, reject) => {
        //         resolve(response)
        //       })
        //     } else {
        //       this.$store.commit('showLoading')
        //         return this.$store.dispatch('createPurchase', {
        //           userId: this.user.id,
        //           servicePackage: this.selectedServicePkg,
        //           stripeToken: response.stripeToken,
        //           paymentService: 'stripe',
        //           description: order.description,
        //           promoCode: this.promoCode.trim()
        //         })
        //     }
        //   })
      }
      promise.then((response) => {
        if (response.data.status === 'FAILURE') {
          if (response.data.clientSecret) {
            this.$notify({
              group: 'info', type: 'success', duration: 5000,
              text: 'Additional confirmation is needed. please wait a little.'
            })
            window.stripe.confirmCardPayment(response.data.clientSecret).then((result) => {
              if (result.error) {
                // Inform the customer that there was an error.
                this.errorMsg = result.error.message
                this.showErrorModal = true
              } else {
                this.$router.push('/sites');
              }
            })
          } else {
            this.errorMsg = response.data.msg
            this.showErrorModal = true
          }
        } else if (response.data.status === 'SUCCESS') {
          this.showPurchaseCompleteModal = true
          this.successMsg = response.data.msg
          this.$store.commit('setUser', response.data.user)
          segmentTrack('purchase', {
            paymentService: 'stripe',
            email: response.data.user.email,
            userID: response.data.user.id,
            servicePackage: this.selectedServicePkg,
            promoCode: this.promoCode.trim()
          })
        } else  if (response.data.status === 'CLOSED') {
          this.errorMsg = response.data.errorMsg
        }
        return 1
      })
      .catch((err) => {
        this.errorMsg = "Transaction denied"
        console.log('stripe error', err)
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
  },
  mounted () {
    this.$store.commit('showLoading')
    return this.$store.dispatch('loadUser').then((response) => {
        if (response.data && response.data.status === 'SUCCESS') {
            let user = response.data.user
            segmentPage('ServicePurchase', {
              email: user.email,
              userID: user.id
            })
            this.$store.commit('setUser', user)
            this.updateSelectedServicePkg(this.user.accountType)
            if (this.isAgencyPlanSelected('')) {
              this.setAgencyTabToVisible()
            }
            if (this.user.hasPaymentSource) {
              return this.$store.dispatch('fetchPaymentSources').then((response) => {
                if (response.data.status == 'SUCCESS') {
                  const preferredCard = response.data.cards.find(card => card.preferred)
                  this.preferredCard = preferredCard
                } else {
                  console.log('error fetching cards')
                }
              })
            }
        }
      }).finally(() => this.$store.commit('hideLoading'))
  }
}
</script>

<style scoped lang="scss">

.step-title {
  font-size: 1.25rem;
  font-weight: 800;
}

.nav-tabs-navigatmulishion {
  * {
    font-family: 'Mulish', sans-serif !important;
  }
}

.plans-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  & > div {
    /* flex-grow: 1; */
    flex-shrink: 0;
    flex-basis: 310px;
  }
}

.monthly-yearly-toggle {
  display: inline-block;
  background: var(--v-lightYellowColor-base);
  border-radius: 50vh;
  margin-top: 20px;
  padding: 10px 5px;
  a, span {
    padding: 5px 10px;
    border-radius: 50vh;
    text-decoration: none;
  }
  span {
    color: var(--v-whiteColor-base);
    background: var(--v-mainColor-base);
    font-weight: bold;
    padding: 5px 20px;
  }
}
.custom-pricing-table {
}
@media only screen and (min-width: 1600px) {
  .custom-pricing-table {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }  
}

</style>
