<template>
<div>
  <div class="limited-width-box">
    <v-card class="is-fullheight white-common-box pa-5">
      <div class="title-font mainColor--text box-title-label">
        Send invoices to:
      </div>
      <div class="mainColor--text mt-5">
        Billing email
      </div>
      <v-text-field outlined hide-details dense
        class="mt-3"
        v-model="invoicingEmail">
      </v-text-field>
      <v-btn rounded
        color="profileAnchorColor"
        class="mt-5 text-none whiteColor--text"
        :disabled="user.invoicingEmail == invoicingEmail"
        @click="saveInvoicingEmailAction">
        <span>Save</span>
      </v-btn>
      <div class="mainColor--text mt-5">
        All invoices will be sent to this email address
      </div>
    </v-card>
  </div>
  <div class="limited-width-box mt-7" v-if="user.isPartnershipApiKeyAllowed">
    <v-card class="is-fullheight white-common-box pa-5">
      <div class="title-font mainColor--text box-title-label">
        Pay as you go
      </div>
      <div class="mainColor--text mt-5">
        Your pending invoice
      </div>
      <div class="partnership-price-to-pay mt-5">
        <span v-if="loadingPartnershipMoney">Loading...</span>
        <template v-else>
          $ {{ payAmountforPartnershipKey }}
        </template>
      </div>
      <div class="mainColor--text mt-5">
        You will be billed at the end of each calendar month for usage during that month.
      </div>
      <v-btn rounded
        color="profileAnchorColor"
        class="mt-5 text-none whiteColor--text"
        :disabled="payAmountforPartnershipKey < 5"
        @click="createPayment">
        <span>Pay</span>
      </v-btn>
    </v-card>
  </div>
  <v-card class="mt-7 is-fullheight white-common-box pa-5">
    <div class="title-font mainColor--text box-title-label">
      Invoice history
    </div>
    <div class="darkGrayColor--text">
      <div
        v-if="invoicesToExport.length > 0"
        class="field is-horizontal">
        <div class="field-label"></div>
        <div class="field-body">
          <div class="field">
            <div class="control has-text-right">
              <v-btn outlined rounded small
                @click="downloadInvoices(false)"
                class="text-none"
                color="mainColor"
                >
                  <span>Download selected original invoices</span>
              </v-btn>
              <v-btn outlined rounded small
                @click="downloadInvoices(true)"
                class="text-none"
                color="mainColor"
              >
                <span>Download selected paid invoices</span>
            </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="hscroll-container">
        <table class="top-border-table is-fullwidth invoice-table" style="min-width: 1000px">
          <thead>
            <tr>
              <th></th>
              <th>Purchase Date</th>
              <th>Service Package</th>
              <th>Payment Processor</th>
              <!-- <th>Total ($)</th> -->
              <th>Amount Paid ($)</th>
              <th>Status</th>
              <th>Cancelled On</th>
              <th>Period Start</th>
              <th>Period End</th>
              <th>Original Invoice</th>
              <th>Paid Invoice</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(invoice, idx) in processedInvoices">
              <tr
                :key="'refund' + invoice.id"
                v-if="paypalRefundData(idx)"
              >
                <td colspan="3">
                  Refund on
                  {{ toLocalDateString(paypalRefundData(idx).paidAt) }}
                </td>
                <td colspan="6">
                  {{ paypalRefundData(idx).description }}
                </td>
                <td></td>
                <td>
                  <button
                    class="button is-warning is-small"
                    @click="
                      downloadRefundInvoice(paypalRefundData(idx))
                    "
                  >
                    Refund Invoice
                  </button>
                </td>
              </tr>
              <tr :key="invoice.id" v-if="invoice.status !== 'Created'">
                <td class="pr-2">
                  <i v-if="isInvoiceSelected(invoice)" class="material-icons checked-box" @click="() => selectOrDeselectInvoice(invoice)">check_box</i>
                  <i v-else class="material-icons  checked-box" @click="() => selectOrDeselectInvoice(invoice)">check_box_outline_blank</i>
                  <!-- <input
                    type="checkbox"
                    v-model="invoicesToExport"
                    :value="invoice"
                  /> -->
                </td>
                <td>{{ toLocalDateString(invoice.created) }}</td>
                <td>
                  {{ invoice.service }}
                  <span v-if="invoice.seatCountsText">( {{ invoice.seatCountsText }} )</span>
                </td>
                <td>
                  {{
                    invoice.paymentProcessor === "stripe"
                      ? "Credit Card"
                      : "PayPal"
                  }}
                </td>
                <!-- <td>{{ invoice.total }}</td> -->
                <td>{{ invoice.amountPaid.toFixed(2) }}</td>
                <td>{{ _.startCase(invoice.status) }}</td>
                <td>
                  {{
                    isNextInvoiceHasSamePurchaseId(idx)
                      ? ""
                      : toLocalDateString(invoice.cancelledOn)
                  }}
                </td>
                <td>
                  {{ toLocalDateString(invoice.billingPeriodStart) }}
                </td>
                <td>
                  {{
                    invoice.billingPeriodEnd
                      ? toLocalDateString(invoice.billingPeriodEnd)
                      : ""
                  }}
                </td>
                <td class="text-center" style="padding-left: 0;">
                  <a @click="downloadInvoice(invoice, false)">
                    <i class="material-icons-outlined download-icon">file_download</i>
                  </a>
                </td>
                <td class="text-center" style="padding-left: 0;">
                  <a @click="downloadInvoice(invoice, true)">
                    <i class="material-icons-outlined download-icon">file_download</i>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="text-right" v-if="invoicesCount > 5 && !this.showMoreInvoices">
        <v-btn rounded
          x-small
          color="profileAnchorColor"
          class="button-with-icon whiteColor--text text-none"
          @click="() => showMoreInvoices = true">
          <span>Show more</span>                    
        </v-btn>                  
      </div>
      <div class="my-3">
        <div class="charges-may-take-box">
          <i class="material-icons">schedule</i>
          <span>Charges may take up to 24 hours to show.</span>
        </div>
        <div class="mt-2">
          <i v-if="user.billingCycleEnds">
            Current billing cycle ends {{ toLocalDateString(user.billingCycleEnds) }}.
            To add company details to your invoices, go in Profile settings >> Company Details
          </i>
        </div>
      </div>      
    </div>
  </v-card>
</div>
</template>

<script>
import _ from "lodash";
import { toLocalDateString, getPureServicePkgName } from "@/utils";
import {
  additionalSeatPlans,
} from "@/components/plans_constants"
import validator from "validator";

export default {
  data () {
    return {
      invoicesToExport: [],
      showMoreInvoices: false,
      changedEmail: false,
      invoicingEmail: '',

      loadingPartnershipMoney: false,
      payAmountforPartnershipKey: 0,
      callsCount: 0,
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    processedInvoices() {
      // this is not working now. no any setInvoices call for store
      if (!this.user) return [];
      let invoices = this.user.invoices.map(invoice => {
        let seatCounts = this.purchasedSeatCounts(invoice.purchaseId)
        if (seatCounts) {
          invoice['seatCountsText'] = `${seatCounts} seat${seatCounts>1?'s':''}`
        }
        return invoice
      })
      if (this.showMoreInvoices) {
        return invoices
      } else {
        return invoices.slice(0, 5);
      }
    },
    invoicesCount () {
      if (!this.user) return 0;
      return this.user.invoices.length;
    },
    _() {
      return _;
    }
  },
  methods: {
    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr);
    },
    purchasedSeatCounts (purchaseId) {
      if (!this.user) {
        return ''
      }
      const purchase = this.user.purchases.find(p => p.id == purchaseId)
      if (!purchase) {
        return ''
      }
      const pureServicePkg = getPureServicePkgName(purchase.servicePackage)
      if (purchase && additionalSeatPlans.includes(pureServicePkg)) {
        return purchase.seatCounts
      }
      return ''
    },
    isNextInvoiceHasSamePurchaseId(invoiceIndex) {
      let invoices = this.user.invoices;
      if (invoiceIndex == 0) {
        return true;
      }
      if (
        invoices[invoiceIndex].purchaseId ==
        invoices[invoiceIndex - 1].purchaseId
      ) {
        return true;
      }
      return false;
    },
    isInvoiceSelected(invoice) {
      return this.invoicesToExport.find(i => i.id == invoice.id)
    },
    selectOrDeselectInvoice(invoice) {
      let found = this.isInvoiceSelected(invoice)
      if (found) {
        this.invoicesToExport = this.invoicesToExport.filter(i => i.id !=invoice.id)
      } else {
        this.invoicesToExport.push(invoice)
      }
    },
    paypalRefundData(invoiceIndex) {
      let invoices = this.user.invoices;
      if (
        invoiceIndex > 0 &&
        invoices[invoiceIndex].purchaseId ==
          invoices[invoiceIndex - 1].purchaseId
      ) {
        return false;
      }
      let arr = this.user.paypalRefunds.find(
        refund => refund.purchase.id == invoices[invoiceIndex].purchaseId
      );
      return arr || false;
    },
    downloadInvoice(invoice, isPaid) {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("downloadInvoices", { invoices: [invoice], isPaid: isPaid })
        .then(response => {
          if (response.data.status === "SUCCESS") {
            const { url, file } = response.data;
            return this.downloadFile(url, file);
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    downloadInvoices(isPaid) {
      if (this.invoicesToExport.length == 0) {
        alert("You must select at least one invoice");
        return;
      }

      this.$store.commit("showLoading");
      this.$store
        .dispatch("downloadInvoices", {
          invoices: this.invoicesToExport,
          isPaid: isPaid
        })
        .then(response => {
          if (response.data.status === "SUCCESS") {
            const { url, file } = response.data;
            return this.downloadFile(url, file);
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    downloadRefundInvoice(refund) {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("downloadRefundInvoices", { refunds: [refund] })
        .then(response => {
          if (response.data.status === "SUCCESS") {
            const { url, file } = response.data;
            return this.downloadFile(url, file);
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    saveInvoicingEmailAction () {
      if (this.invoicingEmail) {
        if (!validator.isEmail(this.invoicingEmail)) {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Please input valid email'
          })
          return
        }
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('saveInvoicingEmail', {
        email: this.invoicingEmail,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({
            group: 'info', type: 'success',
            text: this.invoicingEmail ? 'Successfully saved.' : 'Successfully removed.'
          })
          this.$store.commit('setUser', response.data.user)
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to save.'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    downloadFile(fileURL, fileName) {
      // for non-IE
      if (!window.ActiveXObject) {
        var save = document.createElement("a");
        save.href = fileURL;
        save.target = "_blank";
        var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
        save.download = fileName || filename;
        if (
          navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
          navigator.userAgent.search("Chrome") < 0
        ) {
          document.location = save.href;
          // window event not working here
        } else {
          var evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
        // for IE < 11
      } else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, "_blank");
        _window.document.close();
        _window.document.execCommand("SaveAs", true, fileName || fileURL);
        _window.close();
      }
    },
    createPayment() {
      if (!this.payAmountforPartnershipKey) {
        return
      }
      this.$store.commit('showLoading');
      this.$store.dispatch('createStripePayment', {
        packageType: 'PARTNER_API_USAGE',
        amount: this.payAmountforPartnershipKey,
        callsCount: this.callsCount,
        userID: this.user.id
      })
      .then(function (response) {
        if (response.data && response.data.url) {
          window.open(response.data.url, '_self')
        }
      })
      .catch(err => {
        this.$notify({
          group: 'info',
          type: 'error',
          text: err.response.data.message || "Amount can't be zero"
        })
      })
      .finally(() => {
        this.$store.commit("hideLoading")
      })
    },
    loadPayAmountForPartnershipApiUsage () {
      if (!this.user.isPartnershipApiKeyAllowed) {
        return;
      }
      this.loadingPartnershipMoney = true;
      this.$store.dispatch('getPayAmountForPartnerApiKeyUsage')
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.callsCount = response.data.callsCount;
          this.payAmountforPartnershipKey = response.data.moneyToPay;
        }
      })
      .finally(() => {
        this.loadingPartnershipMoney = false;
      })
    }
  },
  mounted () {
    if (this.user) {
      this.invoicingEmail = this.user.invoicingEmail
    }
    this.loadPayAmountForPartnershipApiUsage();
  }
}
</script>

<style lang="scss" scoped>
.invoice-table {
  th:not(first-child),
  td:not(first-child) {
    padding-left: 20px;
  }

  @media screen and (max-width: 700px) {
    th:not(first-child),
    td:not(first-child) {
      padding-left: 5px;
    }
  }
}
.partnership-price-to-pay {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
}
.charges-may-take-box {
  display: inline-flex;
  align-items: center;
  background: var(--v-yellow3Color-base);
  padding: 5px 10px;
  border-radius: 5px;
  i, span {
    color: var(--v-yellow4Color-base);
  }
  i {
    font-size: 17px;
  }
  span {
    /* font-size: 14px; */
    font-weight: bold;
    margin-left: 10px;
  }
}
</style>