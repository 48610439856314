<template>
<div class="three-rows-grid pt5">
    <div class="wow_disaled slideInLeft">
        <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="$emit('goprevpage')">keyboard_backspace</i>
    </div>
    <div>
        <div class="level2-size mb5 wow_disaled slideInLeft" data-wow-delay="0.3s">
            <b>Add main keyword or phrase</b>
            <span class="is-pulled-right has-text-grey">At least 1 word required</span>
        </div>
        <div class="field " data-wow-delay="0.6s">
            <div class="control is-expanded" :class="{ 'demo-highlight-border': isDemo }">
                <input type="text" class="input site-url-input" :class="{ 'demo-highlight': isDemo }"
                    id="keyword" ref="keyword" :value="kw"
                    @input="handleKeywordChange"
                    :placeholder="keywordPlaceholder" />
            </div>
            <div class="text-right">
                <a :class="`${$store.getters.isDemoUser ? 'gray1Color--text' : 'profileAnchorColor--text'} font-weight-bold`"
                    @click="() => {
                        if (!$store.getters.isDemoUser) {
                            $emit('toggleUseSecondaryKeywords')
                        }
                    }">
                    {{ useSecondaryKeywords ? '- Remove Secondary Keywords' : '+ Add Secondary Keywords' }}
                </a>
            </div>
            <div v-if="useSecondaryKeywords">
                <div class="mb5"><b>Add secondary keywords</b></div>
                <div class="columns">
                <div class="column">
                    <input type="text" class="input" v-model="secondaryKeywords[0]" placeholder="Secondary Keyword 1" />
                </div>
                <div class="column">
                    <input type="text" class="input" v-model="secondaryKeywords[1]" placeholder="Secondary Keyword 2" />
                </div>
                <div class="column">
                    <input type="text" class="input" v-model="secondaryKeywords[2]" placeholder="Secondary Keyword 3" />
                </div>
                </div>
            </div>
        </div>

        <div>
            <div class="level2-size mb5 mt-10">
                <b>Select target language</b>
                <span class="is-pulled-right has-text-grey">Required</span>
            </div>
            <div class="control">
                <div class="select is-fullwidth">
                    <div v-if="isDemo" class="control is-expanded demo-highlight-border">
                        <input type="text" :disabled="isDemo" class="input site-url-input demo-highlight" value="English" />
                    </div>
                    <multiselect
                        v-else
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                        v-model="thisTargetLang"
                        :options="targetLanguages"
                        placeholder="Select language"
                        @select="handleChangeLanguage">
                        <template v-slot:option="props">
                            {{ props.option | capitalize }}
                        </template>
                        <template v-slot:singleLabel="props">
                            {{ props.option | capitalize }}
                        </template>
                    </multiselect>
                </div>
            </div>

            <div class="d-flex align-center mt10">
                <div class="control">
                <label for="ignore-spaces">
                    <input id="ignore-spaces" type="checkbox" :disabled="!!$store.getters.isDemoUser" v-model="thisIgnoreSpaces" @change="handleIgnoreSpaces" />
                    <span class="gray1Color--text"> Special language support</span>
                </label>
                </div>
                <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                        Useful for languages that combine nouns and adjectives into one word such as Chinese or Thai
                    </div>
                </v-tooltip>
            </div>
        </div>

        <div class="columns is-desktop wow_disaled slideInLeft mt-10" data-wow-delay="0.3s">
            <div class="column">
            <div class="level2-size mb5">
                <b>Select target region</b>
                <span class="is-pulled-right has-text-grey">Required</span>
            </div>
            <div class="control">
                <div class="select is-fullwidth">
                <div v-if="isDemo" class="control is-expanded demo-highlight-border">
                    <input type="text" :disabled="isDemo" class="input site-url-input demo-highlight" value="United States" />
                </div>
                <multiselect
                    v-else
                    selectLabel=""
                    selectedLabel=""
                    deselectLabel=""
                    v-model="searchLocation"
                    :options="searchableLocations"
                    placeholder="Type location"
                    @select="handleLocationSelected"></multiselect>
                </div>
            </div>
            </div>
        </div>

        <div>
            <div class="full-width-container mt-10">
                <div class="mt10 mb10 wow_disaled slideInLeft" data-wow-delay="0.3s">
                    <div class="level2-size mb5">
                        <b>Copy & paste the full URL of the specific page</b>
                        <span class="is-pulled-right has-text-grey">Required</span>
                    </div>
                    <div class="d-flex align-center">
                        <div style="min-width: unset;" class="mr-3">
                            {{ cleanBaseUrl }}/
                        </div>
                        <div class="flex-grow-1" :class="{ 'demo-highlight-f': isDemo }">
                            <v-text-field outlined dense
                                color="greenColor"
                                type="text" hide-details="auto"
                                :value="targetUrl" @input="handleChangeTargetUrl" :label="isDemo ? '' : 'Page path (example: /products or leave empty for home page)'"
                                :disabled="disablePathInput || !!$store.getters.isDemoUser"
                            ></v-text-field>
                        </div>
                    </div>
                </div>
                <div>
                    <label class="checkbox gray1Color--text">
                        
                    </label>
                </div>
                <div class="d-flex align-center">
                    <div class="control">
                        <label class="checkbox">
                            <input :disabled="!!$store.getters.isDemoUser" type="checkbox" v-model="thisPageNotBuilt" @change="handlePageNotBuiltChange" /> 
                            <span class="gray1Color--text">
                                I haven't built this page yet
                            </span>
                        </label>
                    </div>
                    <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                            <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                            If you are just starting your optimization and your page is not live yet, check this box.
                        </div>
                    </v-tooltip>
                    <span class="material-icons video-tutorial-icon ml-1"
                        style="font-size: 0.75rem; padding: 3px;"
                        @click="() => {
                        $store.commit('setCurrentVideoTutorialToDisplay', {
                            title: 'Create a Blueprint for Your New Page With POP',
                            videoLink: 'https://www.youtube.com/embed/evAohglW51w?si=-ij1CiUa5GzKRIWH',
                            description: 'Learn how to create a blueprint for your unbuilt pages with PageOptimizer Pro',
                            time: '1m 48s'
                        });
                        }">
                        videocam
                    </span>
                </div>
                <div class="d-flex align-center mt-2">
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox" v-model="useGoogleNLP" :disabled="!isAvailableGoogleNlp || !!$store.getters.isDemoUser" @change="handleUseGoogleNPL">
                            <span class="gray1Color--text">
                                Analyze text with Google's NLP API 
                                (2 POP credits to run this feature)
                                <!-- ( {{ remainedGoogleNlpUsage > 0 ? remainedGoogleNlpUsage+` / ${isAgencyUnlimitedUser ? 60 : 5}` : '0 / 0' }} runs remaining ) -->
                            </span> 
                        </label>
                    </div>
                    <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                            <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                            Check the box and the tool will analyze your text and provide the entities and categories that Google identifies for you and your competitors. You can now purchase stand alone EEAT and Google NLP credits on any plan. <br />
                            Google NLP available languages: English, Chinese (Simplified), Chinese (Traditional), French, German, Italian, Japanese, Korean, Portuguese (Brazilian & Continental), Russian and Spanish
                        </div>
                    </v-tooltip>
                </div>
                <div class="d-flex align-center mt-2">
                    <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="useEatAnalysis" :disabled="!isAvailableEAT || !!$store.getters.isDemoUser" @change="handleUseEatAnalysis">
                        <span class="gray1Color--text">
                            Basic EEAT analysis powered by AI (2 POP credits to run this feature)
                            <!-- ( {{ remainedEATUsage > 0 ? remainedEATUsage+` / ${isAgencyUnlimitedUser ? 60 : 5}` : '0 / 0' }} runs remaining ) -->
                        </span>
                    </label>
                    </div>
                    <v-tooltip top color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                          <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">
                        POP will look for basic EEAT signals on your page and your competitor pages and let you know which signals you are missing)
                      </div>
                    </v-tooltip>
                </div>
                <div class="d-flex align-center mt-2">
                    <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="useNextGenEatAnalysis" :disabled="!isAvailableEAT || !!$store.getters.isDemoUser" @change="handleUseNextGenEatAnalysis">
                        <span class="gray1Color--text">
                            NexGen EEAT analysis powered by AI (2 POP credits to run this feature)
                        </span>
                    </label>
                    </div>
                    <v-tooltip top color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                          <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">
                        POP will do an 80 point evaluation on your content for Expertise, Experience, Authoritativeness, and Trustworthiness. This analysis will provide insight as to whether you have written "good" content; it dives deep into your content, measuring it against the standards inspired by Search Quality Rater Guidelines.
                      </div>
                    </v-tooltip>
                </div>
                <div class="text-right">
                    <a :class="`font-weight-bold ${$store.getters.isDemoUser ? 'gray1Color--text' : 'profileAnchorColor--text'}`"
                        @click="() => { 
                            if(!$store.getters.isDemoUser) {
                                showAdvancedOptions = !showAdvancedOptions
                            }
                        }">
                        {{ showAdvancedOptions ? '- Hide Advanced Options' : '+ Show Advanced Options' }}
                    </a>
                </div>
                <div v-if="showAdvancedOptions" class="advanced-options-area">
                    <div class="d-flex align-center mt-2">
                        <div class="control">
                            <label class="checkbox">
                                <input type="checkbox" v-model="thisUseKeywordPrecisionSearch" @change="handleUseKeywordPrecisionSearchChange" /> 
                                <span class="gray1Color--text">
                                    Keyword precision mode
                                </span>
                            </label>
                        </div>
                        <v-tooltip top color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                            </template>
                            <div class="white-bg-tooltip small-tooltip">
                                POP will choose the pages that appear to have the best on page optimization regardless of actual rank and will base the recommendations on those pages rather than on just the existing high ranking pages.
                            </div>
                        </v-tooltip>
                    </div>
                    <div class="d-flex align-center mt-2">
                        <div class="control">
                            <label class="checkbox">
                                <input type="checkbox" v-model="switchProtocol" @change="handleSwitchProtocol" /> 
                                <span class="gray1Color--text">
                                    Switch your target URLs from http to https or vice versa
                                </span>
                            </label>
                        </div>
                        <div class="control">
                            <div class="has-text-centered">
                                <span class="icon tooltip is-tooltip-multiline is-tooltip-top"
                                    data-tooltip="You would check this box if you have moved your site from http to https or vice versa after originally creating this project.">
                                    <i class="material-icons info-icon-size mainColor--text">info</i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="mb20 wow_disaled slideInLeft" data-wow-delay="0.6s">
                        <div class="or-select-bar">
                            <span class="bar"></span>
                            <span class="text-label">
                                OR select an option below
                            </span>
                            <span class="bar"></span>
                        </div>
                        <template v-if="!thisPageNotBuilt">
                            <label class="checkbox">
                                <input type="checkbox" v-model="targetSourcedDirectly" @change="handleTargetSourcedDirectlyChange"/> I'd like to copy and paste in html or source code
                            </label>
                            <br />
                            <textarea 
                                v-if="targetSourcedDirectly"
                                v-model="targetSourceCode"
                                @input="handleTargetSourceCodeChange"
                                class="textarea manual-copy-textarea"
                                rows="10" 
                                :placeholder="
`1. Go to: view-source:https://www.mydomain.com/my-target-page
2. Copy everything that appears on that page
3. Paste what you copied into this box
                                `">
                            </textarea>
                        </template>
                    </div>
                </div>
                
                <div v-if="!isRerunPage" class="mb20 wow_disaled slideInLeft" data-wow-delay="0.4s">
                    <div class="level2-size mb5">
                    <b>Give the page a name</b>
                    <span class="is-pulled-right has-text-grey">Required</span>
                    </div>
                    <div class="control" :class="{ 'demo-highlight-border': isDemo }">
                        <input type="text" class="input" :class="{ 'demo-highlight': isDemo }" :disabled="!!$store.getters.isDemoUser" :value="pageName"
                                @input="handlePageNameChange" placeholder="Page name">
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="is-bottom-right">
        <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            :loading="submittingRun"
            :disabled="!$store.getters.isDemoUser && (submittingRun || !advanceable)"
            @click="() => handleGoNextPage()">
            <span>Next</span>
            <i class="material-icons">arrow_forward</i>
        </v-btn>
    </div>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { isInSameDomain, getPathNameFromUrl } from '@/utils'
import { DEMO_SESSION_KEYS, DEMO_PAGE_RUN_VALUES } from "@/components/PageRun/constants";

export default {
    components: { Multiselect },
    props: ['value', 'useSecondaryKeywords', 'secondaryKeywordsArray',
    'targetLanguages', 'location', 'ignoreSpaces', 'willUseGoogleNlp', 'willUseEatAnalysis', 'willUseNextGenEat',
    'isExpressReport',
    'targetLang', 'googleSearchLocations',
    'url', 'switchedSiteProtocol', 'pageNotBuilt', 'useKeywordPrecisionSearch', 'name', 'submittingRun', 'isTargetUrlSourceDirectly', 'targetUrlSourceCode', 'cleanBaseUrl', 'disablePathInput', 
    'advanceable', 'isAgencyUnlimitedUser',
    'isRerunPage',
    ],
    data () {
        return {
            kw: this.value,
            secondaryKeywords: this.secondaryKeywordsArray || [],
            thisTargetLang: this.targetLang,
            thisIgnoreSpaces: this.ignoreSpaces,
            useGoogleNLP: this.willUseGoogleNlp,
            useEatAnalysis: this.willUseEatAnalysis,
            useNextGenEatAnalysis: this.willUseNextGenEat,
            searchLocation: '',
            searchableLocations: [],
            extensionOptions: { displayText: 'Exact Location (Chrome Extension)', value:'extension_use' },
            targetUrl: this.$store.getters.isDemoUser ? DEMO_PAGE_RUN_VALUES.PAGE_URL : this.url,
            thisPageNotBuilt: this.pageNotBuilt,
            thisUseKeywordPrecisionSearch: this.useKeywordPrecisionSearch,
            switchProtocol: this.switchedSiteProtocol,
            pageName: this.$store.getters.isDemoUser ? "Natural flea remedies for dogs" : this.name,
            targetSourcedDirectly: this.isTargetUrlSourceDirectly,
            targetSourceCode: this.targetUrlSourceCode,


            showAdvancedOptions: false,
        }
    },
    computed: {
        isDemo () {
            return this.$store.getters.isDemoUser
        },
        keywordPlaceholder () {
            return this.$store.getters.isDemoUser ? 'Type "natural flea remedies for dogs" here' : 'Keyword'
        },
        isSpecialAdminUser () {
            return this.$store.getters.isSpecialAdminUser
        },
        currentUser () {
            return this.$store.state.user;
        },
        ownerUser () {
            return this.$store.state.selectedSubAccountRelation || this.currentUser
        },
        isAvailableGoogleNlp () {
            if (!this.googleNlpSupportedLanguages.includes(this.thisTargetLang)) {
                return false;
            }
            // return (this.$store.state.selectedSubAccountRelation || this.isAgencyUnlimitedUser)
            return this.ownerUser.monthlyCredits + this.ownerUser.remainedUniversalCredits >= 2
        },
        isAvailableEAT () {
            // return (this.$store.state.selectedSubAccountRelation || this.isAgencyUnlimitedUser)
            return (this.ownerUser.monthlyCredits + this.ownerUser.remainedUniversalCredits >= 1) && !this.thisPageNotBuilt
        },
        // remainedGoogleNlpUsage () {
        //     return this.ownerUser.remainedGoogleNlpUsage
        // },
        // remainedEATUsage () {
        //     return this.ownerUser.remainedEATUsage
        // },
        googleNlpSupportedLanguages () {
            return this.$store.state.googleNlpSupportedLanguages
        },
    },
    watch: {
        'url': function(newVal) { // props change
            if (newVal && !this.targetUrl) {
                this.targetUrl = newVal;
            }
        },
        'targetUrl': function(newUrl) {
            let targetUrl = newUrl.trim()
            if (isInSameDomain(targetUrl, this.cleanBaseUrl)) {
                targetUrl = getPathNameFromUrl(targetUrl)
            }
            targetUrl = targetUrl.replace(/\s/g, ''); // remove all spaces from string
            if (targetUrl == '/') { targetUrl = '' }
            if (this.targetUrl == targetUrl) {
                return;
            }
            this.targetUrl = targetUrl
            this.urlsValidated = false
        },
        'value': function(kw) {
            this.kw = kw
        },
        'secondaryKeywordsArray': function(data) {
            this.secondaryKeywords = data
        },
        'targetLang': function (newVal) {
            this.thisTargetLang = newVal;
        },
        'location': function (newVal) {
            this.updateDefaultSelectionOfTargetRegion()
        }
    },
    methods: {
        updateDefaultSelectionOfTargetRegion () {
            let locationValue = this.location == this.extensionOptions.value ? this.extensionOptions.displayText : this.location

            if (this.searchLocation !== locationValue) {
                this.searchLocation = locationValue
            }
        },

        handleKeywordChange (e) {
            if (this.isDemo) {
                this.kw = 'natural flea remedies for dogs'
                e.target.value = this.kw
            } else {
                this.kw = e.target.value;
                this.$emit('input', e.target.value);
            }
        },
        handleChangeLanguage (option, id) {
            const val = option === this.extensionOptions.displayText ? this.extensionOptions.value : option
            this.$emit('languagechange', val)
        },
        handleIgnoreSpaces (e) {
            this.$emit('ignorespaceschange', e.target.checked)
        },
        handleUseGoogleNPL (e) {
            this.$emit('usegooglenlpchange', this.useGoogleNLP)
        },
        handleUseEatAnalysis (e) {
            this.$emit('useeatanalysischange', this.useEatAnalysis)
        },
        handleUseNextGenEatAnalysis (e) {
            this.$emit('usenextgeneatanalysischange', this.useNextGenEatAnalysis)
        },
        handleLocationSelected (option, id) {
            const val = option === this.extensionOptions.displayText ? this.extensionOptions.value : option
            this.$emit('locationchange', val)
        },




        handleChangeTargetUrl (value) {
            this.targetUrl = value;
            this.$emit('urlchange', value)
        },
        handleSwitchProtocol (e) {
            this.$emit('protocolchange', this.switchProtocol)
        },
        handlePageNameChange (e) {
            this.pageName = e.target.value;
            this.$emit('pagenamechange', e.target.value)
        },
        handlePageNotBuiltChange (e) {
            this.$emit('pagenotbuiltchange', this.thisPageNotBuilt)
            if (this.thisPageNotBuilt) {
                this.useNextGenEatAnalysis = false;
                this.$emit('usenextgeneatanalysischange', false)
            }
        },
        handleUseKeywordPrecisionSearchChange (e) {
            this.$emit('usekeywordprecisionsearchchange', this.thisUseKeywordPrecisionSearch)
        },
        handleTargetSourcedDirectlyChange (e) {
            this.$emit('targetsourcedchange', this.targetSourcedDirectly)
        },
        handleTargetSourceCodeChange (e) {
            this.$emit('targetsourcecodechange', e.target.value)
        },



        handleGoNextPage () {
            if (this.secondaryKeywords.includes(this.kw)) {
                window.alert('You can not use target keyword as secondary keyword')
                return;
            }
            const demoPageRunUrl = sessionStorage.getItem(DEMO_SESSION_KEYS.runUrl)
            if (this.$store.getters.isDemoUser && demoPageRunUrl) {
                sessionStorage.setItem(DEMO_SESSION_KEYS.pageCreated, true)
                this.$router.push(demoPageRunUrl)
            } else this.$emit('gonextpage')
        }
    },
    mounted () {
        if (this.targetLang)
            this.thisTargetLang = this.targetLang
        else
            this.thisTargetLang = this.targetLanguages[0]

        this.searchableLocations = this.googleSearchLocations
        this.updateDefaultSelectionOfTargetRegion()

        if (this.$store.getters.isDemoUser) {
            this.kw = 'natural flea remedies for dogs'
            this.$emit('input', 'natural flea remedies for dogs')
            this.useGoogleNLP = true
            this.useEatAnalysis = true
            this.useNextGenEatAnalysis = true
            this.$emit('pagenamechange', this.pageName)
        }
    }
}
</script>

<style scoped lang="scss">

select {
    height: 40px;
}
.advanced-options-area {
  margin: 20px 10px;
  padding: 20px 30px 10px 30px;
  border-radius: 5px;
  background: var(--v-gray7Color-base);
  border: 1px solid var(--v-grayColor-base);
  * {
    color: var(--v-gray2Color-base);
  }

  .or-select-bar {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .text-label {
        margin-left: 15px;
        margin-right: 15px;
    }
    
    .bar {
        height: 1px;
        width: 70px;
        background: var(--v-gray2Color-base);
    }
  }
}

.input:disabled {
    background: var(--v-cardBg-base);
    color: var(--v-mainColor-base);
}
.demo-highlight fieldset {
    border: 1px solid var(--v-profileAnchorColor-base) !important;
}
</style>
