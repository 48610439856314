<template>
  <div>
    <div class="d-flex align-center mt-3 mb-5">
      <div class="flex-grow-1 report-one-page-name gap10">
        <span>LSI & Variations</span>
        <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
          @click="() => {
            $store.commit('setCurrentVideoTutorialToDisplay', {
              title: 'Variations and LSI in POP',
              videoLink: 'https://www.youtube.com/embed/_jUZ27saugY?si=zSPoBHxTfDmzu8Au',
              description: 'This video goes over how to interpret the Variations & LSI tab in PageOptimizer Pro',
              time: '2m 13s'
            });
          }">
          videocam
        </span>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="has-text-grey is-size-7">
          TARGET = Competitor Average
        </div>
        <div class="has-text-grey is-size-7">
          CURRENT = My Average
        </div>
        <div v-if="dispType=='list'" class="mt5">
          <span class="selection-tag blue-active-tag" @click="showWeights=!showWeights">{{ showWeights ? 'Hide Weights' : 'Show Weights' }}</span>
        </div>
      </div>
      <div class="column has-text-right">
        <span class="view-type-selector">
          <span class="has-text-gre is-size-7">View As</span>
          <i class="material-icons" :class="{ 'is-active': dispType == 'table' }" @click="dispType='table'">format_list_bulleted</i>
          <i class="material-icons" :class="{ 'is-active': dispType == 'list' }" @click="dispType='list'">apps</i>
        </span>
        <v-tooltip bottom color="transparent">
          <template v-slot:activator="{ on, attrs }">
            <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
          </template>
          <div class="white-bg-tooltip small-tooltip">
            Use this tab to see all suggested keywrod variations in one place - helpful when you're editing the content of your webpage.
          </div>
        </v-tooltip>
      </div>
    </div>
    <div class="columns is-desktop">
      <div class="column is-6">
        <div v-if="dispType=='table'">
          <table class="signal-table">
            <thead>
              <tr>
                <th>VARIATION</th>
                <th>
                  <span class="sort-filter-block">
                    <select v-model="variationSortMethod">
                      <option value="importance">Importance</option>
                      <option value="deficit" :disabled="isDeficitSortDisabled">Deficit</option>
                      <option value="alphabetic">Alphabetic</option>
                    </select>
                  </span>
                </th>
                <th>TARGET</th>
                <th>CURRENT</th>
                <th>+ / -</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="hasLsaForVariations">
                <tr v-for="(item, idx) in sortedLsaVariations" :key="idx">
                  <td :class="{ 'secondary-keyword-tag': item.isSecondaryKeyword }">
                    <v-tooltip top color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ item.phrase }}
                        </span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip" v-if="item.isSecondaryKeyword">
                        {{ item.isSecondaryKeyword ? 'Secondary Keyword' : '' }}
                      </div>
                    </v-tooltip>
                  </td>
                  <td>{{ (item.weight*100).toFixed(2) }}%</td>
                  <td>{{ item['averageCount'] }}</td>
                  <td :class="{ 'red-color': item['targetCount']<item['averageCount'], 'yellow-color': item['targetCount']>item['averageCount'] }">{{ item['targetCount'] != undefined ? item['targetCount'] : '' }}</td>
                  <td :class="{ 'red-color': item['targetCount']<item['averageCount'], 'yellow-color': item['targetCount']>item['averageCount'] }">{{ item['targetCount'] != undefined ? item['targetCount']-item['averageCount'] : '' | plus_minus_sign }}</td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="v in variations" :key="v">
                  <td>{{ v }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-else-if="dispType=='list'">
          <div class="tabs keyword-variations-tabs">
            <ul>
              <li>
                <a>KEYWORD VARIATIONS</a>
              </li>
              <li class="flex-grow-1"></li>
              <!-- <li>
                <span class="selection-tag blue-active-tag mr10" @click="showWeights=!showWeights">{{ showWeights ? 'Hide Weights' : 'Show Weights' }}</span>
              </li> -->
              <li class="sort-filter-block">
                <span class="has-text-grey is-size-7">SORT BY</span>
                <select v-model="variationSortMethod">
                  <option value="importance">Importance</option>
                  <option value="deficit" :disabled="isDeficitSortDisabled">Deficit</option>
                  <option value="alphabetic">Alphabetic</option>
                </select>
              </li>
            </ul>
          </div>
          <div class="mt10" v-if="hasLsaForVariations">
            <v-tooltip top color="transparent" v-for="(item, idx) in sortedLsaVariations" :key="idx">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"
                  class="variation-tag"
                  :class="{ 'secondary-keyword-tag': item.isSecondaryKeyword }">
                  {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ item.weight.toFixed(2) }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
                </span>
              </template>
              <div class="white-bg-tooltip small-tooltip" v-if="item.isSecondaryKeyword">
                {{ item.isSecondaryKeyword ? 'Secondary Keyword' : '' }}
              </div>
            </v-tooltip>
          </div>
          <div class="mt10" v-else>
            <span v-for="v in variations" :key="v" class="variation-tag">{{ v }}</span>
          </div>
        </div>
        <div class="has-text-right mt10">
          <button type="button"
            class="copy-to-clipboard-button"
            v-clipboard:copy="messageForVariationAndLSICopy('variations')"
            v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
            v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })">Copy To Clipboard
          </button>
        </div>
      </div>
      <div class="column is-6">
        <div v-if="dispType=='table'">
          <table class="signal-table">
            <thead>
              <tr>
                <th>LSI</th>
                <th>
                  <span class="sort-filter-block">
                    <select v-model="lsiSortMethod">
                      <option value="importance">Importance</option>
                      <option value="deficit" :disabled="isDeficitSortDisabled">Deficit</option>
                      <option value="alphabetic">Alphabetic</option>
                    </select>
                  </span>
                </th>
                <th>TARGET</th>
                <th>CURRENT</th>
                <th>+ / -</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="sortedLsaPhrases">
                <tr v-for="(item, idx) in sortedLsaPhrases" :key="idx">
                  <td :class="{ 'link4Color--text' : item.isNLPTerm }">
                    <v-tooltip top color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ item.phrase }}
                        </span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip" v-if="item.isNLPTerm">
                        {{ item.isNLPTerm ? 'NLP' : '' }}
                      </div>
                    </v-tooltip>
                  </td>
                  <td>{{ (item.weight*100).toFixed(2) }}%</td>
                  <td>{{ item['averageCount'] }}</td>
                  <td :class="{ 'red-color': item['targetCount']<item['averageCount'], 'yellow-color': item['targetCount']>item['averageCount'] }">{{ item['targetCount'] != undefined ? item['targetCount'] : '' }}</td>
                  <td :class="{ 'red-color': item['targetCount']<item['averageCount'], 'yellow-color': item['targetCount']>item['averageCount'] }">{{ item['targetCount'] != undefined ? item['targetCount']-item['averageCount'] : '' | plus_minus_sign }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-else-if="dispType=='list'">
          <div class="tabs keyword-variations-tabs">
            <ul>
              <li>
                <a>LSI</a>
              </li>
              <li class="flex-grow-1"></li>
              <!-- <li>
                <span class="selection-tag blue-active-tag mr10" @click="showWeights=!showWeights">{{ showWeights ? 'Hide Weights' : 'Show Weights' }}</span>
              </li> -->
              <li class="sort-filter-block">
                <span class="has-text-grey is-size-7">SORT BY</span>
                <select v-model="lsiSortMethod">
                  <option value="importance">Importance</option>
                  <option value="deficit" :disabled="isDeficitSortDisabled">Deficit</option>
                  <option value="alphabetic">Alphabetic</option>
                </select>
              </li>
            </ul>
          </div>
          <v-tooltip top color="transparent" v-for="(item, idx) in sortedLsaPhrases" :key="idx">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"
                class="variation-tag" :class="{ 'nlp-tag': item.isNLPTerm, 'lsi-tag': !item.isNLPTerm }">
                {{ item.phrase }} <span v-if="showWeights">&nbsp;( {{ item.weight.toFixed(2) }}{{ item['targetCount'] != undefined ? ', C: ' + item['targetCount'] + ', A: ' + item['averageCount'] :  '' }} )</span>
              </span>
            </template>
            <div class="white-bg-tooltip small-tooltip" v-if="item.isNLPTerm">
              {{ item.isNLPTerm ? 'NLP' : '' }}
            </div>
          </v-tooltip>
        </div>
        <div class="has-text-right mt10">
          <button type="button"
            class="copy-to-clipboard-button"
            v-clipboard:copy="messageForVariationAndLSICopy('lsi_terms')"
            v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
            v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })">Copy To Clipboard
          </button>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>

import { mixin } from '@/components/mixin'

export default {
  name: 'VariationLsi',
  mixins: [ mixin ],
  components: {},
  props: [ 'variations', 'secondaryKeywords', 'lsaVariations', 'lsaPhrases'],
  data () {
    return {
      dispType: 'table', // table or list
      showWeights: false,
    }
  },
  methods: {
    isSecondaryKeyword (term) {
      let secondaryKeywords = this.secondaryKeywords || []
      return secondaryKeywords.includes(term)
    },
    messageForVariationAndLSICopy (type) {
      if (type=='variations') {
        if (this.hasLsaForVariations) {
          return this.sortedLsaVariations.map(item => item.phrase).join(', ')
        }
        return this.variations.join(',')
      } else if (type=='lsi_terms') {
        if (this.hasLsaForVariations) {
          return this.sortedLsaPhrases.map(item => item.phrase).join(', ')
        }
      } else if (type=='entities') {
        return this.sortedGoogleNlpCommonEntities.map(item => item[0]).join(', ')
      }
      return ''
    },
  }
  
}
</script>

<style lang="scss" scoped>
.view-type-selector {
  display: inline-flex;
  align-items: center;
  i.material-icons {
    color: grey;
    margin-left: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    &.is-active {
      color: var(--v-profileAnchorColor-base);
    }
  }
}
.red-color {
  color: red;
}
.yellow-color {
  color: #ff9b00;
}
</style>