<template>
<div class="ml20 mr20">
  <h1 class="title text-center">OpenAI Integration Test Interface</h1>
  <div class="field">
    <label class="label">Questions</label>
    <div class="control">
      <textarea
        v-model="questions"
        class="textarea"
        placeholder="You dont't have to add some new lines at the end of this box."></textarea>
    </div>
  </div>
  
  <div class="field">
    <label class="label">HTML source code.</label>
    <div class="control">
      <textarea
        v-model="html"
        class="textarea" placeholder="html source code here."></textarea>
    </div>
  </div>
  <!-- <div class="field">
    <label class="label">Urls You want to test.</label>
    <div class="control">
      <textarea
        v-model="urls"
        class="textarea" :disabled="true" placeholder="separate by enter."></textarea>
    </div>
  </div> -->
  <div class="field is-grouped">
    <div class="control">
      <button class="button is-link is-medium" :disabled="!readyForSubmit" @click="submit">
        <self-building-square-spinner
          v-if="loading"
          :animation-duration="6000"
          :size="20"
          color="#ffffff"
        />
        Submit
      </button>
    </div>
    <div class="control">
      <button class="button is-link is-light is-medium" @click="loadDefaultData">Load Default</button>
    </div>
  </div>

  <div class="box mt20">
    <h2 class="subtitle">Results</h2>
    <template v-if="result">
      <div class="notification is-warning">
        Total Tokens: {{ totalTokens }} <br/>
        API Calls: {{ apiCalls }} <br/>
      </div>
      <article class="message">
        <div class="message-header">
          <p>OpenAI Output</p>
          <button class="delete" aria-label="delete"></button>
        </div>
        <div class="message-body">
          Preview: 
          <pre>
            {{ result }}
          </pre>
          Raw Data:
          <div>
            {{ result  }}
          </div>
        </div>
      </article>
    </template>
  </div>
</div>
</template>

<script>

import { SelfBuildingSquareSpinner  } from 'epic-spinners'


const defaultQuestions = `Question1: Is there any address in content?
Question2: What is the copyright year in this content?
Question3: Does this content have cookie policy?`
const defaultUrls = 'https://hvseo.co'

export default {
  components: {
    SelfBuildingSquareSpinner,
  },
  data () {
    return {
      questions: '',
      urls: '',
      html: '',
      loading: false,
      
      result: '',
      totalTokens: 0,
      apiCalls: 0,
    }
  },
  computed: {
    urlsArray () {
      return this.urls.split('\n').filter(item => item.trim().length > 0);
    },
    readyForSubmit () {
      return !this.loading && this.urlsArray.length > 0 && this.html
      // return this.urlsArray.length > 0 && this.questions
    }
  },
  methods: {
    submit () {
      this.loading = true;
      this.$store.dispatch('openApiTest', {
        // urls: this.urlsArray,
        questions: this.questions,
        html: this.html,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.result = response.data.result;
          this.totalTokens = response.data.totalTokens;
          this.apiCalls = response.data.apiCalls;
          this.$notify({
            group: 'info', type: 'success',
            text: 'Succeed.',
          })
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to test.',
          })
        }
      })
      .catch(error => {
        console.log(error);
        this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to test.',
          })
      })
      .finally(() => {
        this.loading = false;
      })
      
    },
    loadDefaultData () {
      this.questions = defaultQuestions;
      this.urls = defaultUrls;
    }
  },
  mounted () {
    this.loadDefaultData()    
  }
}
</script>