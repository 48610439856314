<template>
<div class="time-series">
  <canvas :id="id" style="width: 100%; height: 300px;"></canvas>
</div>
</template>

<script>
import Chart from 'chart.js'
import { smallUniqueId } from '@/utils'
export default {
  // props: ['dataSeries', 'labels', 'seriesLabel'],
  props: ['labels', 'datasets'],
  data () {
    return {
      id: smallUniqueId(),
      chart: null
    }
  },
  methods: {
    update (labels, datasets) {
      this.chart.data.datasets = datasets
      this.chart.data.labels = labels
      this.chart.update()
      return 1
    },
    initialize () {
      const el = document.getElementById(this.id)
      this.chart = new Chart(el, this.getOptions())
    },
    getOptions () {
      return {
        type: 'line',
        data: {
          labels: this.labels.slice(),
          datasets: this.datasets.slice()
        },
        options: { responsive: true }
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.initialize()
    }, 100)
  }
}
</script>

<style>
  
</style>