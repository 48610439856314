<template>
<div>
  <div class="d-flex align-center">
    <div class="flex-grow-1">
      <date-picker v-model="dates" range></date-picker>
      <v-btn rounded outlined
        color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none ml-2"
        @click="loadData"
        :loading="isLoading">
        <span>Load data</span>
      </v-btn>
    </div>
    <div class="total-credits-in-range">
      <span>
        Total credits:&nbsp;
      </span>
      <span>{{ totalCreditsUsed }}</span>
      <v-tooltip top color="transparent">
        <template v-slot:activator="{ on, attrs }">
          <i class="ml-2 material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
        </template>
        <div class="white-bg-tooltip small-tooltip">
          Total credits used is sum of all the credits used for one report. Total credits used will include report credits (if you are not on an unlimited or teams plan) + EEAT credits + NLP Credits + Watchdog credits + New Al content credits + Paid Al regenerations.  The calculation for total credits used will change based on the dates you have selected.
        </div>
      </v-tooltip>
    </div>
  </div>

  <v-card class="is-fullheight flex flex-column white-common-box mt-5">
    <div class="hscroll-container">
      <b-table
        :data="records"
        :loading="isLoading"
        :paginated="totalRows > 0"
        :total="totalRows"
        :per-page="perPage"
        :current-page="currentPage"
        @page-change="onPageChange"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort">
        <template slot-scope="props">
          <b-table-column field="id" label="Report Id" sortable class="text-left">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column field="keyword" label="Keyword">
            {{ props.row.keyword }}
          </b-table-column>
          <b-table-column field="createdAt" label="Report created">
            {{ toLocalDateString(props.row.createdAt) }}
          </b-table-column>
          <b-table-column field="lastCreditUsedAt" label="Last credit used on">
            {{ toLocalDateString(props.row.lastCreditUsedAt) }}
          </b-table-column>
          <b-table-column field="reportCreditsUsed" label="Report credits">
            <a @click="() => {
              if (props.row.reportCreditsUsed > 0) {
                $router.push(`/sites/${ props.row.siteId }/pages/${ props.row.pageId }/pageruns/${ props.row.id }`)
              }
            }">
              {{  props.row.reportCreditsUsed == 0 ? '-' :  props.row.reportCreditsUsed }}
            </a>
          </b-table-column>
          <b-table-column field="basicEatCredits" label="Basic EEAT credits">
            <a :class="{ 'linked-number': props.row.basicEatCredits > 0}" @click="() => {
              if (props.row.basicEatCredits > 0) {
                $router.push(`/sites/${ props.row.siteId }/pages/${ props.row.pageId }/pageruns/${ props.row.id }?tab=eatSchemaType`)
              }
            }">
              {{  props.row.basicEatCredits == 0 ? '-' :  props.row.basicEatCredits }}
            </a>
          </b-table-column>
          <b-table-column field="NextGenEatCredits" label="Next EEAT credits">
            <a :class="{ 'linked-number': props.row.NextGenEatCredits > 0}" @click="() => {
              if (props.row.NextGenEatCredits > 0) {
                $router.push(`/sites/${ props.row.siteId }/pages/${ props.row.pageId }/pageruns/${ props.row.id }?tab=eatSchemaType`)
              }
            }">
              {{  props.row.NextGenEatCredits == 0 ? '-' :  props.row.NextGenEatCredits }}
            </a>
          </b-table-column>
          <b-table-column field="nlpCredits" label="NLP credits">
            <a :class="{ 'linked-number': props.row.nlpCredits > 0}" @click="() => {
              if (props.row.nlpCredits > 0) {
                $router.push(`/sites/${ props.row.siteId }/pages/${ props.row.pageId }/pageruns/${ props.row.id }?tab=nlpSchemaType`)
              }
            }">
              {{  props.row.nlpCredits == 0 ? '-' :  props.row.nlpCredits }}
            </a>
          </b-table-column>
          <b-table-column field="watchdogCredits" label="Watchdog credits">
            {{ props.row.watchdogCredits == 0 ? '-' :  props.row.watchdogCredits }}
          </b-table-column>
          <b-table-column field="aiArticleCount" label="AI article count">
            <a :class="{ 'linked-number': props.row.aiArticleCount > 0}" @click="() => {
              if (props.row.aiArticleCount > 0) {
                $router.push(`/ai-writer-log`)
              }
            }">
              {{  props.row.aiArticleCount }}
            </a>
          </b-table-column>
          <b-table-column field="aiArticleCreditsTotal" label="New AI content credits">
            <a :class="{ 'linked-number': props.row.aiArticleCreditsTotal > 0}" @click="() => {
              if (props.row.aiArticleCreditsTotal > 0) {
                $router.push(`/ai-writer-log`)
              }
            }">
              {{ props.row.aiArticleCreditsTotal }}
            </a>
          </b-table-column>
          <b-table-column field="aiSchemaCreditsTotal" label="AI schema count">
            <a :class="{ 'linked-number': props.row.aiSchemaCreditsTotal > 0}" @click="() => {
              if (props.row.aiSchemaCreditsTotal > 0) {
                $router.push(`/sites/${ props.row.siteId }/pages/${ props.row.pageId }/pageruns/${ props.row.id }?tab=schemaType`)
              }
            }">
              {{ props.row.aiSchemaCreditsTotal }}
            </a>
          </b-table-column>
          <b-table-column field="aiFreeRegenerationUsedTotal" label="Free AI regenerations">
            {{ props.row.aiFreeRegenerationUsedTotal }}
          </b-table-column>
          <b-table-column field="aiPaidRegenerationUsedTotal" label="Paid AI regenerations">
            <a :class="{ 'linked-number': props.row.aiPaidRegenerationUsedTotal > 0}" @click="() => {
              if (props.row.aiPaidRegenerationUsedTotal > 0) {
                $router.push(`/ai-writer-log`)
              }
            }">
              {{ props.row.aiPaidRegenerationUsedTotal }}
            </a>
          </b-table-column>
          <b-table-column field="totalCredits" label="Total credits used">
            {{ props.row.totalCredits }}
          </b-table-column>
        </template>
      </b-table>
      <!-- <table class="top-border-table is-fullwidth invoice-table" style="min-width: 1000px">
        <thead>
          <tr>
            <th>Report ID</th>
            <th>Keyword</th>
            <th>Date</th>
            <th>Report credits</th>
            <th>EEAT credits</th>
            <th>NLP credits</th>
            <th>Watchdog credits used</th>
            <th>AI article count</th>
            <th>New AI content credits</th>
            <th>Free AI regenerations</th>
            <th>Paid AI regenerations</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(r, idx) in records">
            <tr :key="idx">
              <td>{{ r.id }}</td>
              <td>{{ r.keyword }}</td>
              <td>{{ toLocalDateString(r.createdAt) }}</td>
              <td>{{ r.reportCreditsUsed == 0 ? '-' : r.reportCreditsUsed }}</td>
              <td>{{ r.eatCredits == 0 ? '-' : r.eatCredits }}</td>
              <td>{{ r.nlpCredits == 0 ? '-' : r.nlpCredits }}</td>
              <td>{{ r.watchdogCredits == 0 ? '-' : r.watchdogCredits }}</td>
              <td>{{ r.aiArticleCount }}</td>
              <td>{{ r.aiArticleCreditsTotal }}</td>
              <td>{{ r.aiFreeRegenerationUsedTotal }}</td>
              <td>{{ r.aiPaidRegenerationUsedTotal }}</td>
            </tr>
          </template>
        </tbody>
      </table> -->
    </div>
  </v-card>
</div>
</template>

<script>

import moment from 'moment'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { toLocalDateString } from "@/utils";


export default {
  props: {
    forAdminPage: {
      type: Boolean,
      default: false,
    },
    userId: {
      default: 0,
      type: Number,
    }
  },

  components: {
    DatePicker,
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  data () {
    return {
      dates: [moment().subtract(1, 'months').toDate(), moment().toDate()],


      isLoading: false,
      totalRows: 0,
      records: [],
      totalCreditsUsed: 0,

      currentPage: 1,
      perPage: 10, // no pagination for now for the total credits
      sortField: "runId",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      searchText: "",

    }
  },
  methods: {
    test(data) {
      console.log(data)
    },

    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr);
    },

    onPerPageChange() {
      // this.$nextTick(() => this.loadData());
    },
    onPageChange(page) {
      this.currentPage = page;
      // this.loadData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // this.loadData();
    },
    onFilter() {
      // console.log('filtering')
      // this.$nextTick(() => {
      //   this.loadData();
      // });
    },

    loadData() {
      let startDate = moment(this.dates[0]).startOf('day').toDate();
      let endDate = moment(this.dates[1]).endOf('day').toDate();

      if (this.isLoading) return;
      this.isLoading = true;
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.searchText}`,
        `startDate=${startDate.getTime()}`,
        `endDate=${endDate.getTime()}`,
        `userId=${this.forAdminPage ? this.userId : 0}`
      ].join("&");

      this.$store.dispatch('getCreditUsageForUser', { params })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          console.log(response.data)
          this.totalRows = response.data.total;
          this.records = response.data.records;
          this.totalCreditsUsed = response.data.totalCredits
        } else {
          this.totalRows = 0;
          this.records = [];
          this.totalCreditsUsed = 0
          
          if (response?.data?.msg) {
            this.$notify({
              group: 'info', type: 'error',
              text: response?.data?.msg
            })
          }

        }
      })
      .finally(() => {
        this.isLoading = false;
      })
    },

  },
  mounted () {
    this.loadData()
  }

}
</script>

<style lang="scss" scoped>

.total-credits-in-range { 
  display: flex;
  align-items: center;
  span {
    font-size: 17px;
  }
  & > span:nth-child(1) {
    font-weight: bold;
  }
}


.b-table {
  .table thead td, .table thead th {
    border-bottom-width: 1px !important;
  }
  th, td {
    vertical-align: middle;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.linked-number {
  color: var(--v-link15Color-base);
}
</style>