// utils/index.js

import Vue from 'vue'
import moment from 'moment'
export const EventBus = new Vue()

export function getToken () {
  return localStorage.getItem('token') || sessionStorage.getItem("token")
}

export function isValidJwt (jwt) {
  let token = jwt || getToken()
  if (!token || token.split('.').length < 3) {
    return false
  }
  const data = JSON.parse(atob(token.split('.')[1]))
  const exp = new Date(data.exp * 1000)
  const now = new Date()
  return now < exp
}

export function decodeToken (jwt) {
  let token = jwt || getToken()
  if (!token || token.split('.').length < 3) return
  
  return JSON.parse(atob(token.split('.')[1]))
}

export class PageRunQueueItem {
  constructor(store, siteId, pageId, pageRunId) {
    this.store = store
    this.siteId = siteId
    this.pageRunId = pageRunId
    this.pageId = pageId
    this.requests = 0
    this.makeInterval = this.makeInterval.bind(this)
    this.makeInterval()
  }

  makeInterval () {
    this.timer = setInterval(() => {
      return this.store.dispatch('checkStatusOfPageRun', { pageRunId: this.pageRunId })
        .then(response => {
          this.requests++
          if (response.data.status === 'SUCCESS' || response.data.status === 'FAILURE') {
            clearInterval(this.timer)
            return this.store.dispatch('loadPageRuns', { siteId: this.siteId, pageId: this.pageId })
              .then((response) => {
                if (response.data && response.data.status === 'SUCCESS') {
                  if (response.data.site) {
                    this.store.commit('setSite', response.data.site)
                  }
                  if (response.data.page) { // this is needed to update auto checklist
                    console.log('update page for auto checklist update', response.data.page)
                    this.store.commit('setPage', response.data.page)
                  }

                  this.store.commit('deQueuePageRunItem', { siteId: this.siteId, pageId: this.pageId, pageRunId: this.pageRunId })
                  this.store.commit('setPageRuns', response.data.pageRuns)
                }
                return this.store.dispatch('loadUser')
                  .then((response) => {
                    this.store.commit('setUser', response.data.user)
                    return this.store.dispatch('loadSites')
                      .then((response) => {
                        if (response.data && response.data.status === 'SUCCESS') {
                          this.store.commit('setSites', response.data.sites)
                        }
                        return 1
                      })
                  })
              })
          } else {  // running the tool for now.
            this.store.commit('updatePageRun', response.data)
            // return this.store.dispatch('loadPageRuns', { siteId: this.siteId, pageId: this.pageId })
            //   .then((response) => {
            //     if (response.data && response.data.status === 'SUCCESS') {
            //       this.store.commit('deQueuePageRunItem', { siteId: this.siteId, pageId: this.pageId, pageRunId: this.pageRunId })
            //       this.store.commit('setPageRuns', response.data.pageRuns)
            //     }
            //     return this.store.dispatch('loadUser')
            //       .then((response) => {
            //         this.store.commit('setUser', response.data.user)
            //         return this.store.dispatch('loadSites')
            //           .then((response) => {
            //             if (response.data && response.data.status === 'SUCCESS') {
            //               this.store.commit('setSites', response.data.sites)
            //             }
            //             return 1
            //           })
            //       })
            //   })
          }
        })
        .catch(err => {
          clearInterval(this.timer)
        })
    }, 5000)
  }
}

export function doStripeCheckout (email, order) {
  // let stripeConvertedAmt = order.amount * 100
  // return new Promise((resolve, reject) => {
  //   let handler = window.StripeCheckout.configure({
  //     key: STRIPE_KEY,
  //     image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
  //     locale: 'auto',
  //     token: (token) => {
  //         let postData = {
  //             amount: stripeConvertedAmt,
  //             stripeToken: token.id,
  //             currency: 'usd',
  //             email: email
  //         }
  //         resolve(postData)
  //     },
  //     closed: () => {
  //       resolve({ data: { status: 'CLOSED', errorMsg: 'Purchase cancelled' } })
  //     }
  //   })

  //   handler.open({
  //     name: 'PageOptimizer Pro',
  //     description: order.description,
  //     amount: stripeConvertedAmt,
  //     email: email
  //   })
  // })
}

export function doStripeUpdateCard (email) {
  // return new Promise((resolve, reject) => {
  //   let handler = window.StripeCheckout.configure({
  //     key: STRIPE_KEY,
  //     image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
  //     locale: 'auto',
  //     token: (token) => {
  //         let postData = {
  //             stripeToken: token.id,
  //             currency: 'usd',
  //             email: email
  //         }
  //         resolve(postData)
  //     },
  //     closed: () => {
  //       resolve({ status: 'closed' })
  //     }
  //   })

  //   handler.open({
  //     name: 'PageOptimizer Pro',
  //     panelLabel: 'Update Card',
  //     email: email
  //   })
  // })
}

export function segmentTrack(eventName, data) {
  try {
    data.href = window.location.href
    window.analytics.track(eventName, data)
  } catch(e) {
    console.log(e);
  }
}

export function segmentPage(pageName, data) {
  try {
    data.href = window.location.href
    window.analytics.page(pageName, data)
  } catch(e) {
    console.log(e)
  }
}

export function sendUserInfoToAppcues (user) {
  let cancelledOn = ''
  if (!user.activeSubscription) {
    for (let p of user.purchases) {
      if (p.servicePackage != 'gold' && p.subscriptionId && p.cancelledOn) {
        cancelledOn = p.cancelledOn
        break;
      }
    }
  }

  let dataToPassAppcues = {
    // recommended (optional) properties

    userId: user.id,
    email: user.email,
    isAdmin: user.isAdmin,
    createdAt: moment(user.createdAt).unix(), // Unix timestamp of user signup date
    isValidated: user.isValidated,
    accountBalance: user.monthlyCredits + user.remainedUniversalCredits,
    accountType: user.accountType,
    isDeleted: false, // login means notn deleted.
    isAffiliate: user.isAffiliate,
    paidTotal: user.paidTotal || 0,
    cancellationDate: cancelledOn ? moment(cancelledOn).unix() : '',
    lastLoginDate: moment().unix(),
    reportsCount: user.reportsCount || 0,
    neverMindClicked: user.nevermindClickedDate ? moment(user.nevermindClickedDate).unix() : '',
    watchdogEnabled: user.hasWatchdogSetup,
    // Watchdog Reports 
    firstName: user.firstName,
    lastName: user.lastName,
    isTrial: user.isTrial,
    days21Passed: user.daysAsPopUser > 21,
    days15Passed: user.daysAsPopUser > 15,
    days5Passed: user.daysAsPopUser > 5,

    lastProjectId: user.statsData ? user.statsData['lastProjectId'] : '',
    lastPageId: user.statsData ? user.statsData['lastPageId'] : '',
    lastReportId: user.statsData ? user.statsData['lastReportId'] : '',
    lastAIWriterId: user.statsData ? user.statsData['lastAIWriterId'] : '',

    // purchasedAd: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
    // planTier: "Standard", // Current user’s plan tier
    // role: "Admin", // Current user’s role or permissions
    // accountId: "1234", // Current user's account ID
    // firstName: "John", // current user's first name

    // additional suggestions

    // companyName: "Acme Corp", // Current user’s company name
    // email: "john.doe@example.com", // Current user's email
    // location: "90210", // a zipcode, state, or country enables location-based targeting
    // version: "2.0", // users on different versions may need to see different content
    // language: "spanish", // for multi-language applications
    // renewalDate: 1577880288 // to remind users to renew
    firstPopReportCreationDateplusOneDay: user.firstPopReportCreationDateplusOneDay || '',
  }
    
  if (window.Appcues) {
    window.Appcues.identify(
      user.id, // unique, required
      dataToPassAppcues,      
    );
  } else {
    console.log('Data to pass appcues', dataToPassAppcues)
  }
}

export function sendSpecificDataToAppcuesWithUserId (userId, dataToPassAppcues) {
  if (window.Appcues) {
    window.Appcues.identify(
      userId, // unique, required
      dataToPassAppcues,      
    );
  } else {
    console.log('Specific data to pass appcues', dataToPassAppcues)
  }
}


export function smallUniqueId () {
  return Math.random().toString(36).substr(2, 10)
}

export function toLocalDateString (date) {
  if (!date) {
    return ''
  }
  try {
    return new Date(date.replace(' ', 'T') + 'Z').toLocaleDateString()
  } catch (e) {
    console.log('date convert error', date)
  }

  let momentDate = moment(date)
  return momentDate.isValid() ? momentDate.format("LL") : ''
}
export function toLocalDateTimeString (date) {
  if (!date) {
    return ''
  }
  try {
    return new Date(date.replace(' ', 'T') + 'Z').toLocaleString()
  } catch (e) {
    console.log('date convert error', date)
  }
  let momentDate = moment(date)
  return momentDate.isValid() ? momentDate.format("LLL") : ''
}

export function isFutureDate (date) {
  if (!date) {
    return false
  }
  let momentDate = moment(date)
  if (!momentDate.isValid()) {
    return false
  }
  let today = moment()
  return today.diff(momentDate) < 0
}

export function toLocalDate (date) {
  return moment(date)
}

export function sortByName (a, b) {
  let aLower = a.name.toLowerCase()
  let bLower = b.name.toLowerCase()
  if (aLower < bLower) {
    return -1
  }
  if (aLower > bLower) {
    return 1
  }
  return 0
}

export function sortByKeyword (a, b) {
  let aLower = a.keyword.toLowerCase()
  let bLower = b.keyword.toLowerCase()
  if (aLower < bLower) {
    return -1
  }
  if (aLower > bLower) {
    return 1
  }
  return 0
}

export function cleanText(word) {
  return word.replace(/ +(?= )/g,'').trim().replace(',', '') // regex to remove multiple spaces
}


export function numberToRankEng(num) {
  switch(num) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    default:
      return `${num}th`
  }
}

export function shortenUrl(url, length = null) {
  const MAX_LENGTH = length || 80;
  try {
    if (url.length > MAX_LENGTH) {
      return url.substring(0, MAX_LENGTH) + '...';
    }
    return url;
  } catch (e) {
    console.log(e)
    return url;
  }
}

export function isInSameDomain(url1, url2) {
  try {
    const urlA = new URL(url1.trim());
    const urlB = new URL(url2.trim());
    // Remove www. from the hostnames if present
    const hostA = urlA.hostname.replace(/^www\./, '');
    const hostB = urlB.hostname.replace(/^www\./, '');
    // Compare the origins with the modified hostnames
    return `${urlA.protocol}//${hostA}:${urlA.port}` === `${urlB.protocol}//${hostB}:${urlB.port}`;
  } catch (e) {
    console.log(e)
  }
  return false
}

export function isRootDomainSame(url1, url2) {
  try {
    let domainA = url1;
    let domainB = url2;
    if (url1.startsWith('http')) {
      const urlA = new URL(url1.trim());
      domainA = urlA.hostname.replace(/^www\./, '');
    }
    if (url2.startsWith('http')) {
      const urlB = new URL(url2.trim());
      // Remove www. from the hostnames if present
      domainB = urlB.hostname.replace(/^www\./, '');
    }
    if (domainA && domainB) {
      return (domainA.includes(domainB) || domainB.includes(domainA))
    }
    return false;
  } catch (e) {
    console.log(e)
  }
  return false
}

export function getPathNameFromUrl (url) {
  try {
    let urlObj = new URL(url.trim())
    let pathName = urlObj.pathname + urlObj.search
    if (pathName.startsWith('/')) {
      return pathName.slice(1);
    }
  } catch (e) {
    console.log(e)
    return url
  }
}

export function isSameUrl(url1, url2) {
  try {
    if (url1.endsWith('/')) {
      url1 = url1.slice(0 ,-1); // remove trailing slash        
    }
    if (url2.endsWith('/')) {
      url2 = url2.slice(0, -1);
    }
    return url1 == url2;
  } catch (e) {
    return false;
  }
}

export function extraExplainTextOfUrl(targetUrl, url) {
  try {
    if (targetUrl != url && isSameUrl(targetUrl, url)) {
      return ' [trailing slash changed]'
    } else if (isSameUrl(targetUrl, url)) {
      return ' [your website]'
    }
  } catch (e) {
    console.log(e);
  }
  return ''
}




export function getPureServicePkgName(arg_serviceName) {
  let serviceName = arg_serviceName
  if (arg_serviceName && arg_serviceName.startsWith('P1__')) {
    serviceName = arg_serviceName.replace('P1__', '')
  } else if (arg_serviceName && arg_serviceName.startsWith('P2__')) {
    serviceName = arg_serviceName.replace('P2__', '')
  } if (arg_serviceName && arg_serviceName.startsWith('P3__')) {
    serviceName = arg_serviceName.replace('P3__', '')
  }
  return serviceName
}


export function getHostName(url) {
  if (url.indexOf("//") > -1) {
    return url.split("/")[2];
  } else {
    return url.split("/")[0];
  }
}


export function serviceLabel (arg_serviceName) {
  let purePackageName = getPureServicePkgName(arg_serviceName)

  console.log('log for service name for test', purePackageName)

  if (purePackageName === 'sub_account') {
    return 'Sub Account'
  } else if (purePackageName === 'gold') {
    return 'Credits'
  } else if (purePackageName === 'trial') {
    return 'Trial'
  } else if (purePackageName === 'platinum' || purePackageName === 'UnlimitedYearly' || purePackageName === 'SERVICE_PKG_D') {
    return 'Unlimited'
  } else if (purePackageName === 'SERVICE_PKG_E') {
    return 'Unlimited Yearly'
  } else if (purePackageName === 'SERVICE_PKG_F') {
    return 'Agency Unlimited Monthly ( 5 Sub Accounts )'
  } else if (purePackageName === 'SERVICE_PKG_G') {
    return 'Agency Unlimited Monthly ( 10 Sub Accounts )'
  } else if (purePackageName === 'SERVICE_PKG_H') {
    return 'Agency Unlimited Monthly ( 20 Sub Accounts )'
  } else if (purePackageName === 'SERVICE_PKG_I') {
    return 'Agency Unlimited Yearly ( 5 Sub Accounts )'
  } else if (purePackageName === 'SERVICE_PKG_J') {
    return 'Agency Unlimited Yearly ( 10 Sub Accounts )'
  } else if (purePackageName === 'SERVICE_PKG_K') {
    return 'Agency Unlimited Yearly ( 20 Sub Accounts )'
  } else if (purePackageName === 'SERVICE_PKG_L') {
    return 'Agency Unlimited Monthly ( 100 Sub Accounts )'
  } else if (purePackageName === 'SERVICE_PKG_M') {
    return 'Agency Unlimited Yearly ( 100 Sub Accounts )'
  } else if (purePackageName === 'UNLIMITED_AGENCY5_FREE') {
    return 'Agency Unlimited FREE ( 5 Sub Accounts )'
  } else if (purePackageName === 'UNLIMITED_AGENCY20_FREE') {
    return 'Agency Unlimited FREE ( 20 Sub Accounts )'
  } else if (purePackageName === 'UNLIMITED_AGENCY_FREE_EDUCATION') {
    return 'Agency Unlimited FREE FOR EDUCATION ( 100 Sub Accounts )'
  } else if ( purePackageName === 'gold_free') {
    return 'Pay Per Credit FREE'
  } else if (purePackageName === 'platinum_free') {
    return 'Unlimited FREE'
  } else if(purePackageName === 'BASIC_FREE') {
    return 'Basic free'
  } else if(purePackageName === 'PREMIUM_FREE') {
    return 'Premium free'
  } else if (purePackageName === 'SERVICE_PKG_A') {
    return 'Starter'
  } else if (purePackageName === 'SERVICE_PKG_B' || purePackageName === 'SERVICE_PKG_B2') {
    return 'Basic'
  } else if (purePackageName === 'SERVICE_PKG_C') {
    return 'Premium'
  } else if (purePackageName === 'SERVICE_PKG_AA') {
    return 'Teams Plan Unlimited Monthly (5 Sub Accounts / Available to add more seats)'
  } else if (purePackageName === 'SERVICE_PKG_BB') {
    return 'Teams Plan Unlimited Yearly (5 Sub Accounts / Available to add more seats)'
  } else if (purePackageName === 'SERVICE_PKG_CC') {
    return 'White Glove Plan Unlimited Monthly (5 Sub Accounts / Available to add more seats)'
  } else if (purePackageName === 'EXTRA_SEAT_AA') {
    return 'Teams Plan Seat Monthly'
  } else if (purePackageName === 'EXTRA_SEAT_BB') {
    return 'Teams Plan Seat Yearly'
  } else if (purePackageName === 'EXTRA_SEAT_CC') {
    return 'White Glove Plan Seat Monthly'
  } else if (purePackageName === 'SERVICE_PKG_Z') {
    return 'WatchDog Only'
  } else if (purePackageName === 'ANY') {
    return 'All plans'
  } else {
    console.log('purepure', purePackageName)
    // this includes trial, canceled, and past_due
    return 'Credits'
  }
}

export function pageScoreTintColor(score) {
  let value = score ? score : 0;
  if (value >= 0 && value <= 69) {
    return '#FF0000'
  } else if (value > 69 && value <= 74) {
    return '#FF9C00'
  } else if (value > 74 && value <= 80) {
    return '#FFC000'
  } else if (value > 80 && value <= 83) {
    return '#8CCFB5'
  } else if (value > 83 && value <= 85) {
    return '#78C8A5'
  } else if (value > 85 && value <= 88) {
    return '#5EC198'
  } else if (value > 88 && value <= 90) {
    return '#00B482'
  } else if (value > 90 && value <= 93) {
    return '#00925D'
  } else if (value > 93 && value <= 95) {
    return '#008452'
  } else if (value > 95) {
    return '#006A42'
  }
  return '#000000'
}

export function strToHslColor(str) {
  if (!str) {
    return '#CCCCCC'
  }
  let s = 55;
  let l = 50
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    if (str.length < i) break;
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`
}

/**
 * Same as standard setInterval but starts the first run immediately
 * (Note that standard setInterval only starts running function after interval has elapsed once)
 */
export function setIntervalAndRunImmediately(func, interval) {
  func();
  return setInterval(func, interval);
}

/**
 * Check if a string matches a given query (supports wildcard (*) characters)
 * @param {*} str String to test
 * @param {*} query query which can use wildcard (*) characters
 * @returns bool
 */
export function wildcardMatchString(str, query) {
  // Escape special regex characters except the asterisk
  let escapedQuery = query.toLocaleLowerCase().replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&');
  // Replace the asterisk with a regex that matches any sequence of characters
  const regex = new RegExp('^' + escapedQuery.replace(/\*/g, '.*') + '$', 'i');

  return regex.test(str.toLocaleLowerCase());
}
