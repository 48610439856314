<template>
<div class="three-rows-grid pt5">
    <div class="wow_disaled slideInLeft">
        <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="handleGoPrevPage">keyboard_backspace</i>
    </div>
    <div>
        <div class="full-width-container">
            <div class="level1-size title-font wow_disaled slideInLeft" data-wow-delay="0s">
                Choose your target language and region
            </div>
            
            <div class="columns is-desktop mt-5 wow_disaled slideInLeft" data-wow-delay="0.3s">
                <div class="column">
                    <div>Select target language (required)</div>
                    <div class="control mt-2">
                        <div class="select is-fullwidth">
                            <multiselect
                                selectLabel=""
                                selectedLabel=""
                                deselectLabel=""
                                v-model="thisTargetLang"
                                :options="targetLanguages"
                                placeholder="Select language"
                                @select="handleLanguageSelected">
                                <template v-slot:option="props">
                                    {{ props.option | capitalize }}
                                </template>
                                <template v-slot:singleLabel="props">
                                    {{ props.option | capitalize }}
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="d-flex align-center mt10">
                        <div class="control">
                            <label class="checkbox">
                                <input type="checkbox" v-model="thisIgnoreSpaces" @change="handleIgnoreSpaces">
                                <span>
                                    Special language support
                                </span>
                            </label>
                        </div>
                        <v-tooltip top color="transparent">
                            <template v-slot:activator="{ on, attrs }">
                                <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                            </template>
                            <div class="white-bg-tooltip small-tooltip">
                                Useful for languages that combine nouns and adjectives into one word such as Chinese or Thai
                            </div>
                        </v-tooltip>
                    </div>
                </div>
                <div class="column">
                    <div>Select target location (required)</div>
                    <div class="control mt-2">
                        <div class="select is-fullwidth">
                            <multiselect
                                selectLabel=""
                                selectedLabel=""
                                deselectLabel=""
                                v-model="searchLocation"
                                :options="searchableLocations"
                                placeholder="Type location"
                                @select="handleLocationSelected">
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-3 mb-3 font-weight-bold">Additional settings</div>
            <div class="d-flex align-center mt-2">
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="thisUseKeywordPrecisionSearch" @change="handleUseKeywordPrecisionSearchChange" />
                        <span>Keyword precision mode</span>
                    </label>
                </div>
                <div class="d-flex align-center gap10">
                    <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                            <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                            POP will choose the pages that appear to have the best on page optimization regardless of actual rank and will base the recommendations on those pages rather than on just the existing high ranking pages.
                        </div>
                    </v-tooltip>
                    <span class="material-icons video-tutorial-icon"
                        style="font-size: 0.75rem; padding: 3px;"
                        @click="() => {
                            $store.commit('setCurrentVideoTutorialToDisplay', {
                            title: 'Keyword Precision Mode',
                            videoLink: 'https://www.youtube.com/embed/FOVZI59K1WM?si=aQKMiHweVONhMpmS',
                            description: 'In this video we go over the brand new Keyword Precision Mode in PageOptimizer Pro. We cover what this new feature does,  when you should use it, and where you can find it.',
                            time: '0m 48s'
                            });
                        }">
                        videocam
                    </span>
                </div>
            </div>
            <div class="d-flex align-center mt-2">
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="useGoogleNLP" :disabled="!isAvailableGoogleNlp" @change="handleUseGoogleNPL">
                        <span>
                            Analyze text with Google's NLP API 
                            (2 POP credits to run this feature )
                        </span>
                    </label>
                </div>
                <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                        Check the box and the tool will analyze your text and provide the entities and categories that Google identifies for you and your competitors. You can now purchase stand alone EEAT and Google NLP credits on any plan. <br />
                        Google NLP available languages: English, Chinese (Simplified), Chinese (Traditional), French, German, Italian, Japanese, Korean, Portuguese (Brazilian & Continental), Russian and Spanish
                    </div>
                </v-tooltip>
            </div>
            <div class="d-flex align-center mt-2">
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="useEatAnalysis" :disabled="!isAvailableEAT" @change="handleUseEatAnalysis">
                        <span>
                            Basic EEAT analysis powered by AI (2 POP credits to run this feature)
                            <!-- ( {{ remainedEATUsage }} / {{ isAgencyUnlimitedUser ? '60' : '5' }} runs remaining in {{ isAgencyUnlimitedUser ? 'monthly' : 'lifetime' }} ) -->
                        </span>
                    </label>
                </div>
                <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                        POP will look for basic EEAT signals on your page and your competitor pages and let you know which signals you are missing)
                    </div>
                </v-tooltip>
            </div>
            <div class="d-flex align-center mt-2">
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="useNextGenEat" :disabled="!isAvailableEAT" @change="handleUseNextGenEatAnalysis">
                        <span>
                            NexGen EEAT analysis powered by AI (2 POP credits to run this feature)
                        </span>
                    </label>
                </div>
                <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                        POP will do an 80 point evaluation on your content for Expertise, Experience, Authoritativeness, and Trustworthiness. This analysis will provide insight as to whether you have written "good" content; it dives deep into your content, measuring it against the standards inspired by Search Quality Rater Guidelines.
                    </div>
                </v-tooltip>
            </div>

            <div class="d-flex align-center mt-2">
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="rememberLangAndProxy" @change="handleRememberLangAndProxy">
                        <span>
                            Remember target language and target region for this project
                        </span>
                    </label>
                </div>
                <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                        If you check this box, all pages in this project will default to your selected language and region
                    </div>
                </v-tooltip>
            </div>

            <div class="d-flex align-center mt-2">
                <div class="control">
                    <label class="checkbox">
                        <input type="checkbox" v-model="thisUseOwnCompetitorList" @change="handleUseOwnCompetitorList">
                        <span>
                            Check this box if you already have your own list of competitors
                        </span>
                    </label>
                </div>
                <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                        Check this box and on the next screen you will be able to insert your list of competitors. POP will not search Google for your competitors but will use the list you provide.
                    </div>
                </v-tooltip>
            </div>

        </div>
    </div>
    <div>
        <div class='has-text-centered font-weight-bold profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</div>
        <div class="is-bottom-right">
            <v-btn rounded
                color="profileAnchorColor"
                :loading="submittingGoogleSearch"
                class="button-with-icon whiteColor--text text-none" :disabled="!advanceable" @click="handleGoNextPage">
                <span>Next</span>
                <i class="material-icons">arrow_forward</i>
            </v-btn>
            &nbsp;
            <v-btn rounded
                v-if="stepRerunnable"
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none" :disabled="!advanceable" @click="$emit('skipsteprerun')">
                <span>Don't re-run this step</span>
                <i class="material-icons">arrow_forward</i>
            </v-btn>
        </div>
    </div>

</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    components: { Multiselect },
    props: ['targetLanguages', 'location', 'targetLang', 'ignoreSpaces', 'useKeywordPrecisionSearch',
            'willUseGoogleNlp', 'willUseEatAnalysis', 'willUseNextGenEat',
            'isRememberLangAndProxy', 'useOwnCompetitorList',
            'isAvailableChromeExtension', 'googleSearchResults',
            'stepRerunnable', 'advanceable', 'isAgencyUnlimitedUser', 'isExpressReport', 'googleSearchLocations', 'submittingGoogleSearch',
            'errorMsg'],
    data () {
        return {
            thisTargetLang: this.targetLang,
            thisIgnoreSpaces: this.ignoreSpaces,
            thisUseKeywordPrecisionSearch: this.useKeywordPrecisionSearch,
            useGoogleNLP: this.willUseGoogleNlp,
            useEatAnalysis: this.willUseEatAnalysis,
            useNextGenEat: this.willUseNextGenEat,
            rememberLangAndProxy: this.isRememberLangAndProxy,
            thisUseOwnCompetitorList: this.useOwnCompetitorList,
            extensionOptions: { displayText: 'Exact Location (Chrome Extension)', value:'extension_use' },
            filteringLocations: false,
            searchableLocations: [],
            searchLocation: ''
        }
    },
    methods: {
        handleGoPrevPage () {
            this.$emit('goprevpage')
        },
        handleGoNextPage () {
            this.$emit('gonextpage')
        },
        handleProxyChange (e) {
            this.$emit('proxychange', e.target.value)
        },
        handleIgnoreSpaces (e) {
            this.$emit('ignorespaceschange', e.target.checked)
        },
        handleUseKeywordPrecisionSearchChange (e) {
            this.$emit('usekeywordprecisionsearchchange', this.thisUseKeywordPrecisionSearch)
        },
        handleUseGoogleNPL (e) {
            if (this.isAvailableGoogleNlp) {
                this.$emit('usegooglenlpchange', this.useGoogleNLP)
            }
        },
        handleUseEatAnalysis (e) {
            this.$emit('useeatanalysischange', this.useEatAnalysis)
        },
        handleUseNextGenEatAnalysis (e) {
            this.$emit('usenextgeneatanalysischange', this.useNextGenEat)
        },
        handleRememberLangAndProxy (e) {
            this.$emit('langandproxychange', e.target.checked)
        },
        handleUseOwnCompetitorList (e) {
            this.$emit('useOwnCompetitorChange', e.target.checked)
        },
        handleLanguageSelected (option, id) {
            const val = option === this.extensionOptions.displayText ? this.extensionOptions.value : option
            this.$emit('languagechange', val)
        },
        handleLocationSelected (option, id) {
            const val = option === this.extensionOptions.displayText ? this.extensionOptions.value : option
            this.$emit('locationchange', val)
        },
    },
    computed: {
        isSpecialAdminUser () {
            return this.$store.getters.isSpecialAdminUser
        },
        remainedGoogleNlpUsage () {
            if (this.$store.state.selectedSubAccountRelation) {
                return this.$store.state.selectedSubAccountRelation.remainedGoogleNlpUsage
            } else {
                return this.$store.state.user.remainedGoogleNlpUsage
            }
        },
        remainedEATUsage () {
            if (this.$store.state.selectedSubAccountRelation) {
                return this.$store.state.selectedSubAccountRelation.remainedEATUsage
            } else {
                return this.$store.state.user.remainedEATUsage
            }
        },
        currentUser () {
            return this.$store.state.user;
        },
        ownerUser () {
            return this.$store.state.selectedSubAccountRelation || this.currentUser
        },
        isAvailableGoogleNlp () {
            if (!this.googleNlpSupportedLanguages.includes(this.thisTargetLang)) {
                return false;
            }
            // return (this.$store.state.selectedSubAccountRelation || this.isAgencyUnlimitedUser)
            return this.ownerUser.monthlyCredits + this.ownerUser.remainedUniversalCredits >= 2
        },
        isAvailableEAT () {
            // return (this.$store.state.selectedSubAccountRelation || this.isAgencyUnlimitedUser)
            return (this.ownerUser.monthlyCredits + this.ownerUser.remainedUniversalCredits >= 1) && !this.thisPageNotBuilt
        },
        googleNlpSupportedLanguages () {
            return this.$store.state.googleNlpSupportedLanguages
        },
    },
    beforeMount () {
        if (this.targetLang)
            this.thisTargetLang = this.targetLang
        else
            this.thisTargetLang = this.targetLanguages[0]
        
        const defaultLocation = 'United States'
        
        this.searchableLocations = this.googleSearchLocations

        if (this.location && this.location !== this.extensionOptions.value) {
            this.searchLocation = this.location
        } else {
            this.searchLocation = defaultLocation
            this.handleLocationSelected(defaultLocation, 0)
        }
    }
}
</script>

<style lang="scss" scoped>
div {
    color: var(--v-mainColor-base);
}
</style>