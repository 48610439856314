<template>
  <div class="mainDiv">
    <div class="d-flex align-center mt-3 mb-5">
      <div class="flex-grow-1 report-one-page-name gap10">
        <span>Schema</span>
        <span class="material-icons video-tutorial-icon video-tutorial-icon-size" @click="() => {
          $store.commit('setCurrentVideoTutorialToDisplay', {
            title: 'PageOptimizer Pro\'s Schema Tab',
            videoLink: 'https://www.youtube.com/embed/deqFsyFcSpw?si=A9lq3tcVPqLawn8y',
            description: 'This video details how to utilize PageOptimizer Pro\'s Schema Tab',
            time: '1m 48s'
          });
        }">
          videocam
        </span>
      </div>
    </div>
    <div class="dashboard-text mb-5">
      <div class="mb-2">
        <span>Schema are great for helping search engines understand the structure of your website & pages.</span>
      </div>
      <div class="mb-2">
        <span>Use this tab to understand how your page's schema compares to your competitors.</span>
      </div>
      <div class="mb-2">
        <span>Green color means that at least 2 of your competitors have this type of schema. You may want to add this to
          your page too.</span>
      </div>
      <div class="mb-5">
        <span>Click on a Schema to read more.</span>
      </div>
      <a :href="targetURL" target='_blank' class="competitor-link-custom">{{ targetURL }}</a>

      <div class="align-center d-flex flex-wrap flex-gap-5 mt-5">
        <div class="mr-2">Schema types found on your page </div>

        <div class="schemas-type">
          <div class="d-flex flex-wrap flex-gap-5">
            <div v-for="(schema, idx) in mainPageSchemaTypes" :key="idx">
              <span v-if="schema.type">
                {{ schema.type }}
              </span>
            </div>
          </div>
          <span v-if="mainPageSchemaTypes == null || mainPageSchemaTypes.length == 0">None found</span>
        </div>
      </div>
    </div>
    <div class="mb-6">
      <span class="box-title">Competitors Overview</span>
    </div>

    <div class="mainClass">
      <div class="schemas-mainbox">
        <div>
          <table width="100%">
            <thead>
              <tr>
                <td class="tableHead">Competitors</td>
                <td class="tableHead">Schema types</td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(c, idx) in competitors" :key="c.id">
                <td :class="{ 'no-brder-bottom': competitors.length == idx + 1 }" class="pr-3">
                  <a :href="c.url" target='_blank' class="competitor-link">{{ c.url }}</a>
                </td>
                <td class="schemas" :class="{ 'no-brder-bottom': competitors.length == idx + 1 }">
                  <div class="d-flex flex-wrap flex-gap-5">
                    <span v-for="(schema, idx) in c.schemaTypes" :key="idx"
                      :class="{ 'green-signal': isDuplicatedSchema(schema.type) }">
                      {{ cleanUpSchema(schema.type) }}
                    </span>
                    <span v-if="c.schemaTypes == null || c.schemaTypes.length == 0">None found</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="mb-5 d-flex align-center gap10" id="_page_run_schema_recommendations_">
      <span class="box-title">Recommendations</span>
      <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
        @click="() => {
          $store.commit('setCurrentVideoTutorialToDisplay', {
            title: 'POP\'s New AI Schema Markup',
            videoLink: 'https://www.youtube.com/embed/5CA-56EQjwo?si=JapGgH5TLmG92bpr',
            description: 'In this video we reveal the brand new AI Schema Markup feature in POP. Worry no longer about creating schema yourself, with this very exciting new feature.',
            time: '1m 32s'
          });
        }">
        videocam
      </span>
    </div>
    <div class="dashboard-text mb-2">
      <div class="mb-2">
        <span>You can generate schemas for your page using AI. It costs 1 POP credit but is free if you use your own GPT
          key.</span>
      </div>
      <div class="mb-2">
        <span>Alternatively, you can add each schema as separate task to POP to-do list.</span>
      </div>
    </div>
    <div class="mainClass">
      <div class="schemas-mainbox">
        <div>
          <table width="100%">
            <thead>
              <tr>
                <td class="tableHead">Schema type</td>
                <td class="text-center tableHead">Current selection</td>
                <td class="text-center tableHead">Present in</td>
                <td class="text-center tableHead">Generate with AI</td>
                <td class="text-center tableHead">Action</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in recommendationList" :key="idx">
                <td class="schema-chip">
                  <div class="schemas-type">
                    <div class="d-flex flex-wrap flex-gap-5">
                      <div>
                        <span class="green-signal custom-cursor"
                          @click="$parent.showExamplePopup(row.type)">
                          {{ cleanUpSchema(row.type) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center schema-chip">
                  <v-chip outlined :size="'x-small'" :color="row['checklistId'] ? 'greenColor' : ''">
                    {{ row['checklistId'] ? 'In to-do list' : 'None' }}
                  </v-chip>
                </td>
                <td class="text-center">{{ row.count }}</td>
                <td class="text-center schema-chip">
                  <div v-if="$store.getters.isDemoUser">
                    <v-tooltip top color="transparent" v-if="showDownloadButton[row.type]">
                      <template v-slot:activator="{ on, attrs }">
                        <a class="action-icon-btn" v-bind="attrs" v-on="on" @click="downloadSchema(row.type)">
                          <i class="material-icons-outlined">download_for_offline</i>
                        </a>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">
                        Download
                      </div>
                    </v-tooltip>
                    <v-btn v-else-if="!schemaProgresses[`${pageRunId}>${row.type}`]" rounded small color="profileAnchorColor"
                      class="button-with-icon text-none whiteColor--text"
                      :disabled="!['Breadcrumblist', 'Article', 'Organization'].includes(row.type)"
                      @click="fakeProgress(row.type)">
                      <i class="fa fa-magic"></i>
                      <span>{{mainPageSchemas?.includes(row.type) ? 'Re-Generate' :  'Generate'}}</span>
                    </v-btn>
                    <div v-else class="mt-5">
                      <v-progress-linear rounded height="8" background-color="gray32Color" color="profileAnchorColor"
                        :value="schemaProgresses[`${pageRunId}>${row.type}`]">
                      </v-progress-linear>
                    </div>
                  </div>
                  <template v-else>
                    <schema-types-download-menu
                      v-if="showDownloadButton[row.type]"
                      @downloadJSON="() => downloadSchema(row.type)"
                      :getPDFData="() => makeSchemaData(row.type)"
                    />
                    <div v-else>
                      <v-btn v-if="isSchemaTypeMatch(row.type)" rounded small color="profileAnchorColor"
                        class="button-with-icon text-none whiteColor--text"
                        @click=" handleGenerateSchemaButtonClick(row.type)"
                        :disabled="pageNotBuilt || getSchemaStatus[row.type] || $store.getters.isDemoUser">
                        <i class="fa fa-magic"></i>
                        <span>{{mainPageSchemas?.includes(row.type) ? 'Re-Generate' :  'Generate'}}</span>
                      </v-btn>
                      <v-btn v-else rounded small color="grayColor" class="button-with-icon text-none blackColor--text"
                        @click="requestSchema(row.type)" :disabled="buttonDisabledStates[row.type] || $store.getters.isDemoUser || unsupportedSchemaList.includes(schemaMapper(row.type))">
                        <i class="fa fa-magic"></i>
                        <span>{{ requested[row.type] ? 'Requested' : 'Request Schema' }}</span>
                      </v-btn>
                    </div>
                    <div v-if="getSchemaStatus[row.type]" class="mt-5">
                      <v-progress-linear rounded height="8" background-color="gray32Color" color="profileAnchorColor"
                        :value="schemaProgresses[`${pageRunId}>${row.type}`]">
                      </v-progress-linear>
                    </div>
                    <div class="modal" :class="{ 'is-active': showConfirmationDialog }">
                      <div class="modal-background" @click.stop="cancelAction"></div>
                      <div class="modal-content">
                        <div class="box modal-container" style="overflow: hidden;">
                          <div>
                            <div class="title-font is-size-4 mainColor--text">
                              Generate Schema using AI
                            </div>
                            <div class="subtitle-font mainColor--text mt-5">
                              This action will take 1 POP credit to complete. Are you sure you want to
                              proceed?
                            </div>
                            <div class="control d-flex justify-end gap10 mt-5">
                              <v-btn rounded outlined
                                color="mainColor"
                                class="button-with-button text-none" @click="cancelAction">
                                Cancel
                              </v-btn>
                              <v-btn rounded color="profileAnchorColor"
                                class="button-with-button text-none whiteColor--text" @click="confirmAction">
                                Generate
                              </v-btn>
                              
                            </div>
                          </div>
                        </div>
                        <button class="custom-modal-close-btn" aria-label="close" @click="cancelAction">
                          <i class="material-icons">close</i>
                        </button>
                      </div>
                    </div>
                  </template>
                </td>
                <td class="text-center">
                  <span v-if="$store.getters.isDemoUser" class="material-icons-outlined grayColor--text">add_task</span>
                  <v-tooltip v-else bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="backgroundColor" @click="() => addToChecklist(row.type, row)" v-bind="attrs" v-on="on">
                        <span v-if="row['checklistId']" class="material-icons-outlined greenColor--text">add_task</span>
                        <span v-else class="material-icons-outlined profileAnchorColor--text">add_task</span>
                      </v-btn>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      Add to to-do list
                    </div>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from '@/components/mixin'
import SchemaTypesDownloadMenu from "./SchemaTypesDownloadMenu.vue"
import { fetchTaskResult } from '@/api'
const sanitizeSchema = schema => {
  return schema.toLowerCase().replace(/\s+/g, '')
}
export default {
  mixins: [mixin],
  components: { SchemaTypesDownloadMenu },
  props: ['pageId', 'targetURL', 'pageNotBuilt', 'mainPageSchemaTypes', 'genPageSchemaTypes', 'competitors', 'checkLists', 'pageRunId'],
  data() {
    return {
      duplicatedSchemas: [],
      recommendationList: [],
      schemaList: [
        'Article', 'Organization', 'FAQ', 'FAQPage', 'Webpage', 'Blog post', 'WebSite',
        'Product', 'Local Business', 'Reviews', 'HowTo', 'Author', 'Person',
        'Book', 'Breadcrumb', 'Course', 'Course list', 'Movie', 'Recipe',
        'Restaurant', 'Job posting', 'Logo', 'Software app', 'Video', 'Sitelink',
        'BreadcrumbList', 'ImageObject',
        'SaleEvent', 'ProfessionalService',
        'Corporation',
        'Service', 'Place','BlogPosting',
        'RealEstateListing', 'NewsArticle', 'Blog',
        'Locksmith', 'CollectionPage', 'Store', 
        'Offer', 'ItemList', 
        'ListItem', 'ImageGallery', 'MedicalWebPage', 
        'MedicalOrganization', 'MedicalClinic',
        'ItemPage', 'Question', 'Plumber', 'HVACBusiness',
        'Offer Catalog', 'Shipping delivery time', 'Contact Point', 'Brand',
        'Postal Address', 'Local Business', 'Search Action',
        'Software Application', 'Site Navigation Element',
        'Video Object', 'Review', 'Home And Construction Business',
        'Attorney', 'Legal Service', 'Self Storage', 'Watch Action'
      ].map(sanitizeSchema),
      unsupportedSchemaList: [
        'Action',
        'Aggregaterating',
        'Answer',
        'Map',
        'Merchant Return Policy',
        'Quotation',
        'Rating',
        'Thing',
        'Wpfooter',
        'Wpheader',
      ].map(sanitizeSchema),
      show: false,
      loadingType: null,
      progressInterval: null,
      buttonDisabledStates: {},
      schemaProgressVisible: {},
      progress: {},
      showConfirmationDialog: false,
      currentType: null,
      showDownloadButton: {},
      requested: {},
      downloadReady: {},
      stateManageDownloaded: {},
    }
  },
  computed: {
    isScrollToSchemaNeeded() {
      return this.$route.query.scroll_to_schema === 'true';
    },
    mainPageSchemas() {
      return this.mainPageSchemaTypes?.map(schema => schema.type)
    },
    unarchivedChecklists() {
      return this.checkLists.filter(item => !item.isArchived);
    },
    user() {
      return this.$store.state.user;
    },
    getSchemaStatus() {
      return this.$store.getters.isSchemaLoading;
    },
    isSchemaInProgress() {
      return this.$store.getters.isSchemaLoading[this.currentType];
    },
    getProgressState() {
      return this.$store.getters.isProcess;
    },
    schemaProgresses() {
      return this.$store.getters.getSchemaProgresses;
    }
  },
  mounted() {
    if (this.isScrollToSchemaNeeded) {
      document.getElementById('_page_run_schema_recommendations_')?.scrollIntoView?.()
      setTimeout(() => this.$router.replace({ query: {} }), 200)
    }
  },
  methods: {
    cleanUpSchema (type) {
      return (type || '').replace(/schema\.org/i, '')
    },
    schemaMapper (type) {
      return sanitizeSchema(type)
    },
    handleGenerateSchemaButtonClick(type) {
      if (this.$store.getters.isDemoUser) return

      this.currentType = type;
      if (this?.user?.openAIKey.trim() !== "" && this?.user?.useMyOwnApiKey == true) {
        this.generateSchema(this.currentType);
      } else {
        this.showConfirmationDialog = true;
      }
    },
    confirmAction() {
      this.showConfirmationDialog = false;
      this.generateSchema(this.currentType);
    },
    cancelAction() {
      this.showConfirmationDialog = false;
    },
    closeAction(type) {
      this.downloadReady = { ...this.downloadReady, [type]: false };
    },
    makeSchemaData(schemaType) {
      for (const generated of this.genPageSchemaTypes) {
        if (generated.type === schemaType) {
          return JSON.parse(JSON.stringify(generated.schema, null, 4));
        }
      }
      return '';
    },
    downloadSchema(jsonData) {
      // Vue js convert every item in the data to something that can be observed
      const formatted = JSON.parse(JSON.stringify(this.makeSchemaData(jsonData), null, 4))
      // Create a Blob containing the JSON data
      const blob = new Blob([formatted], { type: 'application/json' });
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create an <a> element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = `${jsonData}-${this.pageRunId}.json`; // Set the filename for the downloaded file
      // Trigger a click event on the <a> element to initiate the download
      a.click();
      // Revoke the URL to free up resources
      URL.revokeObjectURL(url);
    },
    isSchemaTypeMatch(schemaType) {
      return this.schemaList.includes(this.schemaMapper(schemaType));
    },
    startProgressBar(schemaType) {
      this.$store.commit('setSchemaProgress', { schemaType, pageRunId: this.pageRunId, value: 1 });
      this.$set(this.buttonDisabledStates, schemaType, true);
    },
    completeProgressBar(schemaType) {
      clearInterval(this.progressInterval);
      this.$store.commit('setSchemaProgress', { schemaType, pageRunId: this.pageRunId, value: 100 });
      this.$store.commit('setGenerateSchema', { schemaType: schemaType, isLoading: false }); //Hide the progress bar
    },
    fakeProgress(schemaType) {
      this.startProgressBar(schemaType)
      const interval = setInterval(() => {
        this.$store.commit('setSchemaProgress', { schemaType, pageRunId: this.pageRunId });
      }, 1000)
      setTimeout(() => {
        clearInterval(interval)
        this.completeProgressBar(schemaType)
        this.showDownloadButton[schemaType] = true;
      }, 5000)
    },
    generateSchema(schemaType) {
      this.currentType = schemaType
      this.$store.commit('setGenerateSchema', { schemaType, isLoading: true });
      this.loadingType = schemaType
      this.startProgressBar(schemaType);
      const payload = {
        schemaType,
        reportId: this.pageRunId
      };
      this.$store.dispatch('getGenerateSchema', payload)
        .then(response => {
          const { data } = response
          if (!(data && data.status != 'FAILURE')) return response

          return new Promise((resolve, reject) => {
            const timer = setInterval(() => {
              fetchTaskResult(data.taskId)
                .then(resultResponse => {
                  const { status, error } = resultResponse.data;
                  if (status === 'SUCCESS') {
                    this.completeProgressBar(schemaType);
                    clearInterval(timer);
                    if (error) {
                      reject(resultResponse.data);
                      return
                    }
                    this.$store.commit('setPopUpModal', { pageRunId: this.pageRunId, currentLocation: window.location.hash, value: true, schemaType: schemaType });
                    this.downloadReady = { ...this.downloadReady, [schemaType]: true };
                    resolve(resultResponse.data)
                  } else if (status === 'FAILURE') {
                    this.completeProgressBar(schemaType)
                    clearInterval(timer)
                    reject(resultResponse.data)
                  } else if (status === 'PENDING') {
                    const progressVal = this.schemaProgresses[`${this.pageRunId}>${schemaType}`];
                    if (!isNaN(progressVal) && progressVal < 90) {
                      this.$store.commit('setSchemaProgress', { schemaType, pageRunId: this.pageRunId });
                    }
                  }
                })
            }, 8000);
          })
        })
        .then(response => {
          const error = response.error || response.data?.error
          console.log(response)
          this.completeProgressBar(schemaType)
          if (error) {
            this.$notify({
              group: 'info', type: 'error', text: error
            });
          }
          else if (response.ai_schema) {
            this.$notify({
              group: 'info', type: 'success', text: `Generated schema.`
            });
            this.loadPageRun()
          }
          else {
            this.$notify({
              group: 'info', type: 'error', text: response.error
            });
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'info', type: 'error',
            text: "There has been an error generating your schema, please contact support with report ID and schema markup you tried to generate."
          });
        })
    },
    requestSchema(schemaType) {
      if (this.$store.getters.isDemoUser) return

      this.requested[schemaType] = true;
      this.loadingType = schemaType;
      this.$set(this.buttonDisabledStates, schemaType, true);
      const payload = { schemaType, email: this.user.email, pageRunId: this.pageRunId };
      this.$store.dispatch('getRequestSchema', payload)
        .then(response => {
          console.log('API Response:', response);
        })
        .finally(() => {
        })
    },
    isDuplicatedSchema(type) {
      if (this.duplicatedSchemas.indexOf(type) >= 0)
        return true
      else
        return false
    },
    addToChecklist(schemaName, row) {
      if (row['checklistId']) {
        if (!window.confirm('This is already in checklist. Do you want to add once again?')) { return }
      }
      let data = {
        pageId: this.pageId,
        recommendationType: 'schemaType',
        type: 4,
        // dispType: '',
        signal: schemaName
      }
      console.log(data)
      this.$store.commit('showLoading')
      return this.$store.dispatch('addOneToCheckList', data)
        .then(response => {
          if (response.data && response.data.status === 'SUCCESS') {
            console.log('successfully added!')
          }
          if (response.data.page) {
            this.$store.commit('setPage', response.data.page)
            this.orderedRecommendations()
          }
        })
        .finally(() => this.$store.commit('hideLoading'))
    },
    orderedRecommendations() {
      let rlt = {}
      this.competitors.map(competitor => {
        competitor.schemaTypes.map(item => {
          if (rlt[`${item.type}`] == null) rlt[`${item.type}`] = 1; else rlt[`${item.type}`] += 1;
        })
      })

      let duplicatedSchemas = []
      let recommendationList = []

      Object.keys(rlt).map(key => {
        if (rlt[`${key}`] >= 2) duplicatedSchemas.push(key)
        recommendationList.push({
          type: key,
          count: rlt[`${key}`]
        })
      });
      this.duplicatedSchemas = duplicatedSchemas
      this.recommendationList = recommendationList.sort((a, b) => {
        if (a.count < b.count) return 1
        if (a.count == b.count) return 0
        if (a.count > b.count) return -1
      })
        .map(row => {
          let found = this.unarchivedChecklists.find(item => {
            if (item.type == 4 && item.signal === row.type) return true; else return false;
          })
          if (found) {
            row.checklistId = found.id
            row.dispType = found.dispType
            row.isCompleted = found.isCompleted
          }
          return row
        })
    },
    schemaMatched() {
      const recommendationSet = new Set(this.recommendationList.map(item => item.type));
      const requestSchemas = this.$store.getters.requestSchemas;
      requestSchemas.forEach(schema => {
        this.requested[schema] = true
        this.$set(this.buttonDisabledStates, schema, true);
      });

      for (const generated of this.genPageSchemaTypes) {
        if (recommendationSet.has(generated.type)) {
          const isRequested = requestSchemas.includes(generated.type) || !!generated.is_requested;
          this.requested[generated.type] = isRequested;
          this.$set(this.buttonDisabledStates, generated.type, isRequested);
          this.showDownloadButton[generated.type] = this.$store.getters.isDemoUser ? false : !!generated.schema;
        }
      }
    },
    loadPageRun () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchPageRun', this.$route.params)
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('addPageRun', response.data.pageRun)
            this.$store.commit('setCurrentPageRun', response.data.pageRun)
          }
        })
        .catch(err => {
          console.log('error fetching page run ', err)
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
  },
  watch: {
    genPageSchemaTypes: function (newVal, oldVal) {
      console.log('PageRun/SchemaTypes: called genPageSchemaTypes watcher function')
      this.schemaMatched();
    },
    'checkLists': {
      handler: function (_newVal, _oldVal) {
        this.orderedRecommendations()
      }
    },
  },
  beforeMount() {
    this.orderedRecommendations()
    this.schemaMatched()
  }
}
</script>

<style scoped lang="scss">
.tag__custom {
  height: auto !important;
  white-space: unset !important;
}

.schemas-mainbox {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &>div {
    border: 1px solid var(--v-gray15Color-base);
    border-radius: 10px;
    background: var(--v-whiteColor-base);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }

  .section-with-bottom {
    padding: 30px 35px;
    border-bottom: 1px solid var(--v-gray12Color-base);
  }

  .my-url {
    margin-top: 20px;
    margin-bottom: 30px;

    a {
      font-size: 25px;
      font-weight: bold;
    }
  }

  .schemas {
    margin-top: 20px;

    & span {
      color: var(--v-mainColor-base);
      border-radius: 5px;
      border: 1.3px solid var(--v-mainColor-base);
      padding: 3px 7px;
      display: inline-block;
      font-size: 13px;
    }

    .green-signal {
      color: var(--v-green7Color-base);
      border-color: var(--v-green7Color-base);
    }
  }
}

.schemas-type {

  & span {
    color: var(--v-mainColor-base);
    border-radius: 5px;
    border: 1.3px solid var(--v-mainColor-base);
    padding: 3px 7px;
    display: inline-block;
    font-size: 13px;
  }

  .green-signal {
    color: var(--v-green7Color-base);
    border-color: var(--v-green7Color-base);
  }
}

table {
  border-spacing: 20px 35px;

  thead td {
    color: var(--v-darkGrayColor-base);
    font-size: 14px;
    font-weight: 700;
  }

  td:first-child {
    padding-left: 35px;
  }

  td:last-child {
    padding-right: 35px;
  }

  td {
    vertical-align: middle;
    padding: 20px 0;
    font-weight: 600;
    font-size: 13px;
  }

  tr td {
    border-bottom: 1px solid var(--v-gray12Color-base);
    ;
  }

  .no-brder-bottom {
    border-bottom: none;
  }

  .backgroundColor {
    background-color: transparent !important;
  }

  .no-background-on-hover:hover {
    background-color: transparent !important;
  }

  .custom-cursor {
    cursor: pointer;
  }

}

.backgroundColor {
  background-color: transparent !important;
}

.no-background-on-hover:hover {
  background-color: transparent !important;
}

.custom-cursor {
  cursor: pointer;
}

.action-icon-btn {
  background: var(--v-lightYellowColor-base);
  display: inline-flex;
  align-items: center;
  padding: 5px;

  &>i {
    color: var(--v-profileAnchorColor-base);
  }
}

.mainClass {
  padding: 0 0px 80px 0px;
}

.mainDiv {
  margin: 0 10px;
}

.competitor-link-custom {
  color: var(--v-link1Color-base);
  font-weight: 700;
  font-size: 20px;
}

.competitor-link {
  color: var(--v-link1Color-base);
  font-weight: 700;
  font-size: 14px;
}

.box-title {
  font-size: 20px;
  font-weight: 800;
  color: var(--v-mainColor-base);
}

.tableHead {
  color: var(--v-mainColor-base);
}
</style>
