const explainData = {}

explainData['H1 Tag Total'] = `<div>
<span class="wrap-long-tooltip">
The is the total number of H1s your competitors are using.
H1 is typically the page title that visitors to your page will see.
H1 uses the HTML tag &lt;h1&gt; &lt;/h1&gt;.
Page Structure Suggestions are not looking at the use of keywords or variations in H1s.
In this section, the tool is simply counting the total number of H1s on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.

A common question:  The competitor average is 2 (or more) H1s,
I thought I should only have 1 H1 on a page?  As a general rule, you should only have 1 H1 on a page.
That said, POP really doesn't care about general rules.
If you are using 1 H1 and are not getting the rank improvement you think you should,
you now have something else to try that you might not have previously considered.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;h1&gt;Heading Tag 1&lt;/h1&gt; <br />
    &lt;h2&gt;Heading Tag 2&lt;/h2&gt; <br />
    &lt;h3&gt;Heading Tag 3&lt;/h3&gt; <br />
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <h1 class="h1-size">Heading Tag 1</h1>
    <h2 class="h2-size">Heading Tag 2</h2>
    <h3 class="h4-size">Heading Tag 3</h3>
  </div>          
</div>
</div>`

explainData['H2 Tag Total'] = `<div>
<span class="wrap-long-tooltip">
The is the total number of H2s your competitors are using.
H2s are typically sub headers on your page.  H2 uses the HTML tag &lt;h2&gt; &lt;/h2&gt;.
Page Structure Suggestions are not looking at the use of keywords or variations in H2s.
In this section, the tool is simply counting the total number of H2s on your competitors' pages.
Keep in mind that page structure suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;h1&gt;Heading Tag 1&lt;/h1&gt; <br />
    &lt;h2&gt;Heading Tag 2&lt;/h2&gt; <br />
    &lt;h3&gt;Heading Tag 3&lt;/h3&gt; <br />
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <h1 class="h1-size">Heading Tag 1</h1>
    <h2 class="h2-size">Heading Tag 2</h2>
    <h3 class="h4-size">Heading Tag 3</h3>
  </div>          
</div>
</div>`

explainData['H3 Tag Total'] = `<div>
<span class="wrap-long-tooltip">
The is the total number of H3s your competitors are using.
H3s are typically sub headers on your page.
H3 uses the HTML tag &lt;h3&gt; &lt;/h3&gt;.
Page Structure Suggestions are not looking at the use of keywords or variations in H3s.
In this section, the tool is simply counting the total number of H3s on your competitors' pages.
Keep in mind that page structure suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;h1&gt;Heading Tag 1&lt;/h1&gt; <br />
    &lt;h2&gt;Heading Tag 2&lt;/h2&gt; <br />
    &lt;h3&gt;Heading Tag 3&lt;/h3&gt; <br />
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <h1 class="h1-size">11Heading Tag 1</h1>
    <h2 class="h2-size">Heading Tag 2</h2>
    <h3 class="h3-size">Heading Tag 3</h3>
  </div>          
</div>
</div>`

explainData['H4 Tag Total'] = `<div>
<span class="wrap-long-tooltip">
The is the total number of H4s your competitors are using.
H4s are typically sub headers on your page.
H4 uses the HTML tag &lt;h4&gt; &lt;/h4&gt;.
Page Structure Suggestions are not looking at the use of keywords or variations in H4s.
In this section, the tool is simply counting the total number of H4s on your competitors' pages.
Keep in mind that page structure suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;h4&gt;Heading Tag 4&lt;/h4&gt; <br />
    &lt;h5&gt;Heading Tag 5&lt;/h5&gt; <br />
    &lt;h6&gt;Heading Tag 6&lt;/h6&gt; <br />
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <h4 class="h4-size">Heading Tag 4</h4>
    <h5 class="h5-size">Heading Tag 5</h5>
    <h6 class="h6-size">Heading Tag 6</h6>
  </div>          
</div>
</div>`


explainData['H5 Tag Total'] = `<div>
<span class="wrap-long-tooltip">
The is the total number of H5s your competitors are using.
H5s are typically sub headers on your page, but they are not very commonly used.
H5 uses the HTML tag &lt;h5&gt; &lt;/h5&gt;.
Page Structure Suggestions are not looking at the use of keywords or variations in H5s.
In this section, the tool is simply counting the total number of H5s on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;h4&gt;Heading Tag 4&lt;/h4&gt; <br />
    &lt;h5&gt;Heading Tag 5&lt;/h5&gt; <br />
    &lt;h6&gt;Heading Tag 6&lt;/h6&gt; <br />
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <h4 class="h4-size">Heading Tag 4</h4>
    <h5 class="h5-size">Heading Tag 5</h5>
    <h6 class="h6-size">Heading Tag 6</h6>
  </div>          
</div>
</div>`


explainData['H6 Tag Total'] = `<div>
<span class="wrap-long-tooltip">
The is the total number of H6s your competitors are using.
H6s are typically sub headers on your page, but they are not very commonly used.
H6 uses the HTML tag &lt;h6&gt; &lt;/h6&gt;.
Page Structure Suggestions are not looking at the use of keywords or variations in H6s.
In this section, the tool is simply counting the total number of H6s on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;h4&gt;Heading Tag 4&lt;/h4&gt; <br />
    &lt;h5&gt;Heading Tag 5&lt;/h5&gt; <br />
    &lt;h6&gt;Heading Tag 6&lt;/h6&gt; <br />
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <h4 class="h4-size">Heading Tag 4</h4>
    <h5 class="h5-size">Heading Tag 5</h5>
    <h6 class="h6-size">Heading Tag 6</h6>
  </div>          
</div>
</div>`


explainData['Paragraph Text Tag Total'] = `<div>
<span class="wrap-long-tooltip">
The is the total number of Paragraph tags your competitors are using.
Paragraph text is often the main content on the page.
Paragraph uses the HTML tag &lt;p&gt; &lt;/p&gt;.
Combined with word count, the use of paragraph tags might give some insight 
as to what type of pages your competitors are building.
Page Structure Suggestions are not looking at the use of keywords or variations in Paragraph tags.
In this section, the tool is simply counting the total number of paragraph tags on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;p&gt;Lorem ipsum. Lorem ipsum dolor sit amet&lt;/p&gt;
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <p>Lorem ipsum. Lorem ipsum dolor sit amet</p>
  </div>          
</div>
</div>`


explainData['Anchor Text Tag Total'] = `<div>
<span class="wrap-long-tooltip">
Anchor Text is the clickable text of a link on a page.
The link could be an internal link or an external link.
Anchor Text uses the HTML &lt;a&gt; &lt;/a&gt;
By counting the Anchor Text tags, we can see how many links your competitors are putting on their pages.
Page Structure Suggestions are not looking at the use of keywords or variations in any Anchor Text.
In this section, the tool is simply counting the total number of Anchor Text tags on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;a href=&rdquo;https://pageoptimizer.pro&rdquo;&gt;click here&lt;/a&gt;
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <a href=”https://pageoptimizer.pro”>click here</a>
  </div>          
</div>
</div>`

explainData['Image File total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Images on your competitors&rsquo; pages.
The tool counts all image file types as the same (e.g. png, jpeg, gif).
Page Structure Suggestions are not looking at what the Images actually contain.
In this section, the tool is simply counting the total number of image files on your competitors' pages.
Keep in mind that page structure suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
</div>`


explainData['Image Alt Tag Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Image Alts that your competitors are using.
An Image Alt is the text that describes an image.
While your competitors may have several images on their pages, they may or may not be describing them.
This count will help you see how much detail you may need to put into your images.
Page Structure Suggestions are not looking at the use of keywords or variations in any Image Alts.
In this section, the tool is simply counting the total number of Image Alts on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
Image Alt uses the HTML &lt;img src=&quot;name-of-image.jpg&quot; alt=&quot;description of image&quot;&gt;
</span>
</div>`

explainData['Bold Tag Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Bold words on your competitors&rsquo; pages.
The HTML tags bold &lt;b&gt; &lt;/b&gt; and strong &lt;strong&gt; &lt;/strong&gt; will both be counted as bold.
Page Structure Suggestions are not looking at which words your competitors have bolded.
In this section, the tool is simply counting the total number of bold words on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;b&gt;This is bold text&lt;/b&gt; <br/>
    &lt;strong&gt;This is strong text&lt;/strong&gt;
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <b>This is bold text</b> <br/>
    <strong>This is strong text</strong>
  </div>          
</div>
</div>`


explainData['Italic Tag Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Italic words on your competitors&rsquo; pages.
The HTML tags italics &lt;i&gt; &lt;/i&gt; and emphasis &lt;em&gt; &lt;/em&gt; will both be counted as Italics.
Page Structure Suggestions are not looking at which words your competitors have italicized.
In this section, the tool is simply counting the total number of italicized words on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;i&gt;This is italic text&lt;/i&gt; <br/>
    &lt;em&gt;This is emphasis text&lt;/em&gt;
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <i>This is italic text</i> <br/>
    <em>This is emphasis text</em>
  </div>          
</div>
</div>`


explainData['Video File Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of embedded videos on your competitors&rsquo; pages.
The tool counts all video file types and video platforms as the same (e.g. avi, mp4, youtube, vimeo).
Page Structure Suggestions are not looking at what the videos actually contain.
In this section, the tool is simply counting the total number of videos on your competitors' pages.
Keep in mind that page structure suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
</div>`



explainData['Ordered List Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Ordered Lists on your competitors&rsquo; pages.
An Ordered List is a list that is numbered.  An Ordered List uses the HTML tag &lt;ol&gt; &lt;/ol&gt;.
The example below is one (1) Ordered List.
Page Structure Suggestions are not looking at the content of any list.
In this section, the tool is simply counting the total number of Ordered Lists on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;ol&gt; <br/>
    This is an Ordered List <br/>
    &lt;li&gt;First item in this list&lt;/li&gt; <br/>
    &lt;li&gt;Second item in this list&lt;/li&gt; <br/>
    &lt;li&gt;Third item in this list&lt;/li&gt; <br/>
    &lt;/ol&gt;

  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <ol>
      This is an Ordered List
      <li>First item in this list</li>
      <li>Second item in this list</li>
      <li>Third item in this list</li>
    </ol>
  </div>          
</div>
</div>`




explainData['Ordered List Items Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of List Items contained within Ordered Lists on your competitors&rsquo; pages.
An Ordered List is a list that is numbered.  The numbered items are the List Items.
A List Item uses the HTML tag &lt;li&gt; &lt;/li&gt;.
In the example below, there are three (3) List Items in one (1) Ordered List.
Page Structure Suggestions are not looking at the content of any List Item.
In this section, the tool is simply counting the total number of List Items
contained in Ordered Lists on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;ol&gt; <br/>
    This is an Ordered List <br/>
    &lt;li&gt;First item in this list&lt;/li&gt; <br/>
    &lt;li&gt;Second item in this list&lt;/li&gt; <br/>
    &lt;li&gt;Third item in this list&lt;/li&gt; <br/>
    &lt;/ol&gt;

  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <ol>
      This is an Ordered List
      <li>First item in this list</li>
      <li>Second item in this list</li>
      <li>Third item in this list</li>
    </ol>
  </div>          
</div>
</div>`




explainData['Unordered List Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Unordered Lists on your competitors&rsquo; pages.
An Unordered List is a list that is not numbered.
An Unordered List is typically a bullet point list.
An Unordered List uses the HTML tag &lt;ul&gt; &lt;/ul&gt;.
The example below is one (1) Unordered List.
Page Structure Suggestions are not looking at the content of any list.
In this section, the tool is simply counting the total number of Unordered Lists on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;ul&gt; <br/>
    This is an Unordered List <br/>
    &lt;li&gt;First item in this list&lt;/li&gt; <br/>
    &lt;li&gt;Second item in this list&lt;/li&gt; <br/>
    &lt;li&gt;Third item in this list&lt;/li&gt; <br/>
    &lt;/ul&gt;
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <ul style="list-style-type: circle;">
    This is an Unordered List
    <li>First item in this list</li>
    <li>Second item in this list</li>
    <li>Third item in this list</li>
    </ul>
  </div>          
</div>
</div>`





explainData['Unordered List Items Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of List Items contained within UnOrdered Lists on your competitors&rsquo; pages.
An Unordered List is a bullet point list.
The bullet point items are the List Items.
A List Item uses the HTML tag &lt;li&gt; &lt;/li&gt;.
In the example below, there are three (3) List Items in one (1) Unordered List.
Page Structure Suggestions are not looking at the content of any List Item.
In this section, the tool is simply counting the total 
number of List Items contained in Unordered Lists on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;ul&gt; <br/>
    This is an Unordered List <br/>
    &lt;li&gt;First item in this list&lt;/li&gt; <br/>
    &lt;li&gt;Second item in this list&lt;/li&gt; <br/>
    &lt;li&gt;Third item in this list&lt;/li&gt; <br/>
    &lt;/ul&gt;
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <ul style="list-style-type: circle;">
    This is an Unordered List
    <li>First item in this list</li>
    <li>Second item in this list</li>
    <li>Third item in this list</li>
    </ul>
  </div>          
</div>
</div>`



explainData['Table Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Tables on your competitors&rsquo; pages.
Tables use the HTML tag &lt;table&gt; &lt;/table&gt;.
The example below is one (1) Table.
Page Structure Suggestions are not looking at the content in a Table.
In this section, the tool is simply counting the total number of Tables on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;table&gt; <br/>
      &lt;tr&gt; <br/>
        &lt;th&gt;Column 1&lt;/th&gt; <br/>
        &lt;th&gt;Column 2&lt;/th&gt; <br/>
      &lt;/tr&gt; <br/>
      &lt;tr&gt; <br/>
        &lt;td&gt;Item 1&lt;/td&gt; <br/>
        &lt;td&gt;Item 2&lt;/td&gt; <br/>
    &lt;/table&gt;
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <table border="1" width="100%">
      <tr>
        <th style="padding: 10px;">Column 1</th>
        <th style="padding: 10px;">Column 2</th>
      </tr>
      <tr>
        <td style="padding: 10px;">Item 1</td>
        <td style="padding: 10px;">Item 2</td>
    </table>
  </div>          
</div>
</div>`



explainData['Table Items Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Tables Items on your competitors&rsquo; pages.
The tool will count items within tables using the HTML tags
&lt;th&gt; &lt;/th&gt;, &lt;td&gt; &lt;/td&gt;, and &lt;li&gt; &lt;/li&gt;.
The example below is one (1) Table and four (4) Table Items.
Both the column headers and the column items will be counted as Table Items.
Page Structure Suggestions are not looking at the content in a Table or Table Items.
In this section, the tool is simply counting the total number of Table Items on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
<div class="html-result-container">
  <div>
    <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
    &lt;table&gt; <br/>
      &lt;tr&gt; <br/>
        &lt;th&gt;Column 1&lt;/th&gt; <br/>
        &lt;th&gt;Column 2&lt;/th&gt; <br/>
      &lt;/tr&gt; <br/>
      &lt;tr&gt; <br/>
        &lt;td&gt;Item 1&lt;/td&gt; <br/>
        &lt;td&gt;Item 2&lt;/td&gt; <br/>
    &lt;/table&gt;
  </div>
  <div>
    <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
    <table border="1" width="100%">
      <tr>
        <th style="padding: 10px;">Column 1</th>
        <th style="padding: 10px;">Column 2</th>
      </tr>
      <tr>
        <td style="padding: 10px;">Item 1</td>
        <td style="padding: 10px;">Item 2</td>
    </table>
  </div>          
</div>
</div>`


explainData['Form Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Forms on your competitors&rsquo; pages.
Forms use the HTML tag &lt;form&gt; &lt;/form&gt;
Knowing how many Forms your competitors are using can help you determine
how many conversion points you may need on your pages.
Page Structure Suggestions are not looking at the content in a Form or what type of Form it is.
In this section, the tool is simply counting the total number of Forms on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
</div>`


explainData['iFrame Total'] = `<div>
<span class="wrap-long-tooltip">
This is the total number of Iframes on your competitors&rsquo; pages.
Iframes use the HTML tag &lt;iframe&gt; &lt;/iframe&gt;
If your competitors are using Iframes they may be pulling data or functions into their pages from other sources.
Page Structure Suggestions are not looking at the content or functions in any Iframes.
In this section, the tool is simply counting the total number of Iframes on your competitors' pages.
Keep in mind that Page Structure Suggestions may not be ranking factors.
They are not part of the page score.
These suggestions will help you build a page that Google and your visitors are expecting to see.
</span>
</div>`

explainData['Frequently Asked Questions'] = `<div>
<span class="wrap-long-tooltip">
  We are checking whether target page and our competitors' pages have an FAQ section. We will be looking for the presence of 'faq', 'Frequently Asked Question', 'Frequently Asked Questions' on the page.
</span>
</div>`


export default explainData;