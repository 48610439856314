<template>
  <section class="section">
    <div class="contain">
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <div class="control">
                <input type="text" class="input" placeholder="Search by affiliate" v-model="searchText">
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <div class="control">
                  <datepicker v-model="startDate" input-class="input" placeholder="Start Date"></datepicker>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <div class="control">
                  <datepicker v-model="endDate" input-class="input" placeholder="End Date"></datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="control is-pulled-left">
                <div class="select is-fullwidth">
                  <select v-model="payoutType">
                    <option value="unpaid">Unpaid</option>
                    <option value="paid">Paid</option>
                    <option value="denied">Denied</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column is-8">
              <p class='has-text-centered'>
                <button class='button is-primary is-large' :disabled="payoutType === 'paid' || checkedRows.length === 0" @click.stop="submitPayout">
                  Pay ${{ totalPayout | round(2) }}
                </button>
              </p>
            </div>
            <div class="column">
              <div class="control is-pulled-right">
                <div class="select is-fullwidth">
                  <select v-model="perPage">
                    <option value="10">10 per page</option>
                    <option value="25">25 per page</option>
                    <option value="50">50 per page</option>
                    <option value="100">100 per page</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <center class="has-text-centered">
          <b-table
            :data="filteredPayouts"
            :paginated="true"
            :per-page="perPage"
            :checked-rows.sync="checkedRows"
            :is-row-checkable="(row) => row.paypalEmail"
            :checkable="payoutType === 'unpaid'"
            :current-page.sync="currentPage"
            :pagination-simple="false"
            :default-sort-direction="defaultSortOrder"
            default-sort="id"
            detailed
            detail-key="id">
            <template slot-scope="props">
              <b-table-column field="id" label="Payout ID" sortable numeric width="80">{{ props.row.id }}</b-table-column>
              <b-table-column field="affiliateEmail" label="Affiliate Email" sortable width="250">{{ props.row.affiliateEmail }}</b-table-column>
              <b-table-column field="paypalEmail" label="PayPal Email" sortable width="250">{{ props.row.paypalEmail }}</b-table-column>
              <b-table-column field="payoutAmount" label="Payout ($)" sortable numeric>{{ props.row.transactions.reduce((acc, item) => acc + item.affiliatePortion, 0) | round(2) }}</b-table-column>
              <b-table-column field="adminNotified" label="Admin Notified On" sortable width="170">{{ toLocalDateString(props.row.adminNotified) }}</b-table-column>
              <b-table-column :visible="payoutType === 'paid'" field="payoutDate" label="Payment Initiated On" sortable  width="170">{{ toLocalDateString(props.row.payoutDate) }}</b-table-column>
              <b-table-column :visible="payoutType === 'paid'" field="paidBy" label="Paid By" sortable  width="200">{{ props.row.paidBy }}</b-table-column>
              <b-table-column field="status" label="Status" sortable  width="200">{{ props.row.status }}</b-table-column>
            </template>
            <template slot="detail" slot-scope="props">
              <div class='box'>
                <div class="mb10">
                  <h3 class="subtitle-font mb10">Default Affiliate Link</h3>
                  <div>
                    <p>Pricing Page: {{ props.row.affiliateLinkToPricingPage }}</p>
                    <p>Sign up Page: {{ props.row.affiliateLink }}</p>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <h3 class="subtitle-font mb10">Transactions</h3>
                  </div>
                </div>
                
                <table class="mb20">
                  <thead>
                    <tr>
                      <th>User ID</th>
                      <th>Date</th>
                      <th>Customer Email</th>
                      <th>Product</th>
                      <th>Total Amount ($)</th>
                      <th>Comission ($)</th>
                      <th v-if="payoutType === 'unpaid'"></th>
                      <th v-if="payoutType === 'unpaid'"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(s, i) in props.row.transactions" :key="i">
                      <td>{{ s.userId }}</td>
                      <td>{{ toLocalDateString(s.date) }}</td>
                      <td>{{ s.customerEmail }}</td>
                      <td>{{ s.product }}</td>
                      <td>${{ s.saleAmount  | round(2)}}</td>
                      <td>${{ s.affiliatePortion | round(2) }}</td>
                      <td v-if="payoutType === 'unpaid'">
                        <button class="button is-warning" @click.stop="removeFromAllPayouts(s)">Remove</button>
                      </td>
                      <td v-if="payoutType === 'unpaid'">
                        <button class="button is-info" @click.stop="delayFromPayout(s)">Delay</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </template>
          </b-table>
          </center>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { toLocalDateString, toLocalDate } from '@/utils'
import { mixin as BaseMixin } from '@/components/mixin'
export default {
  components: { Datepicker },
  mixins: [BaseMixin],
  data () {
    return {
      payoutType: 'unpaid',
      allPayouts: [],
      searchText: '',
      perPage: 50,
      currentPage: 1,
      defaultSortOrder: 'asc',
      startDate: null,
      endDate: null,
      checkedRows: []
    }
  },
  computed: {
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    totalPayout () {
      let total = 0
      this.checkedRows.forEach((payout) => {
        payout.transactions.forEach((txn) => {
          total += txn.affiliatePortion
        })
      })
      return total
    },
    filteredPayouts () {
      if (!this.allPayouts) {
        return []
      }
      let payouts = this.allPayouts.slice()
      if (this.payoutType === 'unpaid') {
        payouts = payouts.filter((p) => p.status !== 'SUCCESS' && p.status != 'DENIED')
      } else if(this.payoutType === 'paid') {
        payouts = payouts.filter((p) => p.status === 'SUCCESS')
      } else if (this.payoutType === 'denied') {
        payouts = payouts.filter((p) => p.status === 'DENIED')
      }

      if (this.startDate) {
        let startDate = toLocalDate(this.startDate)
        payouts = payouts.filter((p) => {
          let adminNotified = toLocalDate(p.adminNotified)
          let payoutDate = toLocalDate(p.payoutDate)
          return (adminNotified.isValid() && adminNotified >= startDate)
            || (this.payoutType === 'paid' && payoutDate.isValid() && payoutDate >= startDate)
        })
      }

      if (this.endDate) {
        let endDate = toLocalDate(this.endDate)
        payouts = payouts.filter((p) => {
          let adminNotified = toLocalDate(p.adminNotified)
          let payoutDate = toLocalDate(p.payoutDate)
          return (adminNotified.isValid() && adminNotified <= endDate)
            || (this.payoutType === 'paid' && payoutDate.isValid() && payoutDate <= endDate)
        })
      }

      if (this.searchText) {
        let searchText = this.searchText.toLowerCase()
        payouts = payouts.filter((p) => {
          return (p.paypalEmail && p.paypalEmail.toLowerCase().includes(searchText))
          || p.affiliateEmail.toLowerCase().includes(searchText)
        })
      }
      return payouts
    }
  },
  methods: {
    toLocalDateString (timestamp) {
      return toLocalDateString(timestamp)
    },
    submitPayout () {
      console.log(this.checkedRows)
      this.$store.dispatch('showLoading')
      this.$store.dispatch('executePayout', { payouts: this.checkedRows })
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            this.checkedRows = []
            window.alert('Done successfully !!')
            return this.fetchPayouts()
          } else {
            window.alert(`Something wrong!! \n
                        Please do not try again. It could be already performed. \n
                        Just refresh and see whether payout already done. \n
                        Error: ${ JSON.stringify(response.data) }
                        `)
          }
        })
        .catch(error => {
          window.alert(`Something wrong!! \n
                        Please do not try again. It could be already performed. \n
                        Just refresh and see whether payout already done. \n
                        DETAILS FOR DEBUG: \n
                        Error: ${error.toJSON()}`)
          console.log(error)
        })
        .finally(() => {
          this.$store.dispatch('hideLoading')
        })
    },
    removeFromAllPayouts (invoice) {
      this.$store.dispatch('removeFromPayout', { invoiceId: invoice.invoiceId, removeType: 'permanent' })
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            let allPayouts = this.allPayouts.slice()
            let payoutIdx = allPayouts.findIndex((po) => po.id === invoice.affiliatePayoutId)
            allPayouts[payoutIdx].transactions = allPayouts[payoutIdx].transactions.filter((txn) => txn.invoiceId !== invoice.invoiceId)
            let neverPayPayoutIdx = allPayouts.findIndex((po) => po.id === 1)
            allPayouts[neverPayPayoutIdx].transactions.push(invoice)
            this.allPayouts = allPayouts
          }
        })
    },
    delayFromPayout (invoice) {
      this.$store.dispatch('removeFromPayout', { invoiceId: invoice.invoiceId, removeType: 'delay' })
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            let allPayouts = this.allPayouts.slice()
            let payoutIdx = allPayouts.findIndex((po) => po.id === invoice.affiliatePayoutId)
            allPayouts[payoutIdx].transactions = allPayouts[payoutIdx].transactions.filter((txn) => txn.invoiceId !== invoice.invoiceId)
            this.allPayouts = allPayouts
          }
        })
    },
    fetchPayouts () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchAffiliatePayouts')
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.allPayouts = response.data.affiliatePayouts
          }
          return 1
        })
        .catch((err) => {
          console.log('Error fetching affiliate payouts ', err)
        })
        .finally(() => this.$store.commit('hideLoading'))
    }
  },
  beforeMount () {
    return this.fetchPayouts()
  }
}
</script>

<style lang="scss">
.table-mobile-sort {
  display: none;
}
</style>