<template>
<div class="px-2">
  <div class="d-flex align-center mt-3 mb-5">
    <div class="flex-grow-1 report-one-page-name gap10">
      <span>Google entities</span>
      <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
        @click="() => {
          $store.commit('setCurrentVideoTutorialToDisplay', {
            title: 'How to Use the Google Entities Dashboard in POP',
            videoLink: 'https://www.youtube.com/embed/8hQkqTBWS-Y?si=lhm8NM5XZUzSltOU',
            description: 'This video details how to read the PageOptimizer Pro Entities Dashboard.',
            time: '4m 25s'
          });
        }">
        videocam
      </span>
    </div>
    <div class="how-to-use-this-dashboard"
      @click="openHowtoUseGuide">
      <img src="/static/cap.svg" />
      <span>How to use this dashboard?</span>
    </div>
  </div>
  <div class="dashboard-text mb-2">
    <div class="mb-2">
      <span>Whenever Google crawls your page with its NLP, Google assigns your page a category. This category is what Google thinks your page is about. The more certain Google is about what your page is about, the better.</span>
    </div>
    <div class="mb-2">
      <span>You can increase Google's confidence in your category by adding Entities to your page.</span>
    </div>
  </div>

  <div v-if="!_.isEmpty(targetNlpSchemaData)">
    <div class="columns is-desktop">
      <div class="column is-4">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5">
            <h4 class="title01 mb-3">
              <span>Common Categories</span>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  These are the categories Google has identified for your competitors' text. The numbers in parentheses are the number of competitors that share that category.
                </div>
              </v-tooltip>
            </h4>
            <div class="color-explain">
              <div><span class="is-faded-green-bg"></span>Categories that Google has identified for your content</div>
              <div><span class="is-faded-red-bg"></span>Categories that Google has not identified for your content</div>
            </div>
            <div class="tags boxList">
              <div class="tag is-info" :class="{ 'is-selected-category': categoryFilter=='' }" @click="pickFilterCategory('')" style="cursor: pointer;">
                <i v-if="categoryFilter==''" class="fa fa-check-circle mr5" aria-hidden="true"></i>
                All
              </div>
              <div
                v-for="(category, idx) in commons.common_categories_array" :key="idx" 
                :class="{ 'tag': true, 'is-faded-green-bg': category[2], 'is-faded-red-bg': !category[2], 'is-selected-category': categoryFilter==category[0] }"
                @click="pickFilterCategory(category[0])" style="cursor: pointer;">
                <span>
                  <i v-if="categoryFilter==category[0]" class="fa fa-check-circle mr5" aria-hidden="true"></i>
                  {{category[0]}} ({{ category[1] }})
                </span>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div class="column is-8">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5">
            <h4 class="title01 mb-3">
              <span>Common Entities</span>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip small-tooltip">
                  These are the entities Google has identified for your competitors' text. The numbers in parentheses are the number of competitors that have that entity in their text.
                </div>
              </v-tooltip>
            </h4>
            <div class="color-explain">
              <div><span class="is-faded-green-bg"></span>Entities that Google has identified for your content</div>
              <div><span class="is-faded-red-bg"></span>Entities that Google has not identified for your content</div>
            </div>
            <div class="control mb10 flex">
              <div class="flex-grow-1">
                Sort by: 
                <label class="radio">
                  <input type="radio" name="sort_common_entities" value="count" :disabled="isDemo" v-model="sortMethodForCommonEntities">
                  Number of Competitors
                </label>
                <label class="radio">
                  <input type="radio" name="sort_common_entities" value="alphabet" :disabled="isDemo" v-model="sortMethodForCommonEntities">
                  Alphabetical
                </label>
              </div>
              <div class="pr30">
                <div
                  v-if="isDemo"
                  class="download-dropdown grayColor--text"
                  data-tooltip="Download Common Entities"
                  :icon-right="active ? 'angle-up' : 'angle-down'"
                  >
                  <img src="/static/download-icon-yellow.png" />
                  <span>Download</span>
                </div>
                <b-dropdown v-else position="is-bottom-left">
                  <template #trigger="{ active }">
                    <div
                      slot="trigger"
                      class="download-dropdown tooltip is-tooltip-top"
                      data-tooltip="Download Common Entities"
                      :icon-right="active ? 'angle-up' : 'angle-down'"
                      >
                      <img src="/static/download-icon-yellow.png" />
                      <span>Download</span>
                    </div>
                  </template>
                  <b-dropdown-item @click="() => downloadCommonEntities(false)">
                    <i class="fa fa-file-excel-o" aria-hidden="true"></i> &nbsp;
                    Download All Entities
                  </b-dropdown-item>
                  <b-dropdown-item @click="() => downloadCommonEntities(true)">
                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i> &nbsp;
                    Download Missing Entities
                  </b-dropdown-item>
                </b-dropdown> 
              </div>
            </div>
            <div class="tags boxList">
              <div v-for="(entity, idx) in filteredCommonEntities" :key="idx" :class="{ 'tag': true, 'is-faded-green-bg': entity[2], 'is-faded-red-bg': !entity[2] }">
                {{entity[0]}} ({{ entity[1] }})
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <div class="columns is-desktop" v-if="!pageNotBuilt">
      <div class="column is-12">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5">
            <h4 class="title01 mb-3">
              <span class="mr-2">Target Url</span>
              <span class="tag is-light">
                <a :href="targetUrl" target='_blank' class="has-text-black">{{ targetUrl }}</a>
              </span>
            </h4>
            <div class="columns is-desktop">
              <div class="column is-7">
                <div class="box">
                  <h4 class="title02">Entities</h4>
                  <ul class="row boxList" v-if="targetNlpSchemaData.entities && targetNlpSchemaData.entities.length > 0">
                    <li v-for="(entity, idx) in targetNlpSchemaData.entities" :key="idx">
                      <div class="boxPanel">
                        <h5>{{idx+1}}. <strong>{{entity.name}}</strong></h5>
                        <div class="row">
                          <div class="col-sm-12">
                            <strong>
                              <a :href="entity.wiki_url" v-if="entity.wiki_url"
                                  class="linkAnc" target="_blank">
                                  Wikipedia Article</a>
                            </strong>
                            <div class="salience" v-if="entity.salience">
                                <strong>Salience :</strong> {{entity.salience | declimal5}}
                            </div>
                          </div>
                        </div>
                        <a class="btnLink" :class="entity.type+'_COLOR'">{{entity.type}}</a>
                      </div>
                      <!--boxPanel-->
                    </li>
                  </ul>
                  <div v-else>
                    There are no entities
                  </div>
                </div>
              </div>
              <div class="column is-5">
                <div class="box">
                  <h4 class="title02">Categories</h4>
                  <ul class="row boxList">
                    <li v-for="(category, idx) in targetNlpSchemaData.categories" :key="idx">
                      <div class="boxPanel">
                        <h5><strong>{{category.name}}</strong></h5>
                        <div class="salience">
                          <strong>Confidence :</strong>
                          {{category.confidence}}
                        </div>
                      </div>
                    </li>
                    <div v-if="!targetNlpSchemaData.categories.length">There are no categories</div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="columns is-desktop" v-for="(competitor, idx) in competitors" :key="idx">
      <div class="column is-12">
        <v-card class="is-fullheight d-flex flex-column white-common-box">
          <div class="pa-5">
            <h4 class="title01 mb-3">
              <span class="mr-2">Competitor {{ idx + 1 }}</span>
              <span class="tag is-light">
                <a :href="competitor.url" target='_blank' class="has-text-black">{{ competitor.url }}</a>
              </span>
            </h4>
            <div class="columns is-desktop">
              <div class="column is-7">
                <div class="box">
                  <h4 class="title02">Entities</h4>
                  <ul class="row boxList" v-if="competitor.nlpSchemaData.entities && competitor.nlpSchemaData.entities.length > 0">
                    <li v-for="(entity, idx) in competitor.nlpSchemaData.entities" :key="idx">
                      <div class="boxPanel">
                        <h5>{{idx+1}}. <strong>{{entity.name}}</strong></h5>
                        <div class="row">
                          <div class="col-sm-12">
                            <strong>
                              <a :href="entity.wiki_url" v-if="entity.wiki_url"
                                  class="linkAnc" target="_blank">
                                  Wikipedia Article</a>
                            </strong>
                            <div class="salience" v-if="entity.salience">
                                <strong>Salience :</strong> {{entity.salience | declimal5}}
                            </div>
                          </div>
                        </div>
                        <a class="btnLink" :class="entity.type+'_COLOR'">{{entity.type}}</a>
                      </div>
                      <!--boxPanel-->
                    </li>
                  </ul>
                  <div v-else>
                    There are no entities
                  </div>
                </div>
              </div>
              <div class="column is-5">
                <div class="box">
                  <h4 class="title02">Categories</h4>
                  <ul class="row boxList">
                    <li v-for="(category, idx) in competitor.nlpSchemaData.categories" :key="idx">
                      <div class="boxPanel">
                        <h5><strong>{{category.name}}</strong></h5>
                        <div class="salience">
                          <strong>Confidence :</strong>
                          {{category.confidence}}
                        </div>
                      </div>
                    </li>
                    <div v-if="!competitor.nlpSchemaData.categories.length">There are no categories</div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="!hasEatData" class="nlp-or-eeat-is-not-used-box">
      <div class="demo-area">
        <img src="/static/nlp-demo.png" class="demo-img" />
      </div>
      <div class="mainColor--text" style="max-width: 550px;">
        <div class="re-run-for-eeat">Re-run POP to get your Google NLP report</div>
        <div class="mt-5 mainColor--text">
          Please re-run POP and we will create your Google NLP report. This will use 2 POP Credits. You can also purchase more POP Credits on any plan and use them to run various POP features such as Google NLP. 
        </div>
        <div class="mt-5 d-flex align-center gap10">
          <v-btn rounded small color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            v-if="!user.isVendor" @click="rerunAnalysisForThisReport">
            <i class="fa fa-refresh"></i>
            <span class='has-text-centered'>Re-run</span>
          </v-btn>
          <v-btn rounded small outlined color="mainColor" class="button-with-icon mainColor--text text-none"
            :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`">
            <i class="fa fa-money"></i>
            <span class="">Buy credits</span>
          </v-btn>
        </div>
        <!-- <a @click="gotoProfile" class="profileAnchorColor--text">Check out plans here</a> -->
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import _ from 'lodash'
import { mixin } from '@/components/mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { mixin as exportmixin } from '@/components/export_mixin'

export default {
  mixins: [ mixin, ServiceMixin, exportmixin ],
  props: ['targetUrl', 'pageNotBuilt', 'targetNlpSchemaData', 'competitors', 'commons'],
  data () {
    return {
      sortMethodForCommonEntities: 'count',
      categoryFilter: '',
    }
  },
  computed: {
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    _ () {
      return _
    },
    user () {
      return this.$store.state.user
    },
    filteredCommonEntities() {
      // count
      // alphabet
      let commonEntities = []
      if (this.categoryFilter=='') {
        console.log('entities count', this.commons.common_entities_array.length)
        commonEntities = this.commons.common_entities_array
      } else {
        let competitors = this.competitors.filter(c => {
          if (!c.nlpSchemaData.categories || c.nlpSchemaData.categories.length == 0) {
            return false
          }
          return c.nlpSchemaData.categories.map(c => c.name)
                .includes(this.categoryFilter)
          // return c.nlpSchemaData.categories && c.nlpSchemaData.categories.length > 0 && c.nlpSchemaData.categories[0].name.includes(this.categoryFilter)
        })

        competitors.map(c => {
          let entities = c.nlpSchemaData.entities
          entities.map(entity => {
            let idx = commonEntities.findIndex(element => {
              return element[0]==entity.name
            })
            if (idx==-1) {
              let targetHasThis = this.isTargetUrlHasThisEntity(entity.name)
              commonEntities.push([entity.name, 1, targetHasThis ? 1 : 0])
            } else {
              commonEntities[idx][1]+=1
            }
          })
        })
      }
      if (this.sortMethodForCommonEntities == 'count') {
        commonEntities.sort(function(a, b) {
          if (a[1] > b[1])
            return -1
          else if (a[1] < b[1])
            return 1
          else
            return 0
        })
      } else {
        commonEntities.sort(function(a, b) {
          if (a[0] > b[0])
            return 1
          else if (a[0] < b[0])
            return -1
          else
            return 0
        })
      }
      
      return commonEntities
    }
  },

  methods: {
    gotoProfile () {
      const userId = this.user ? this.user.id : '';
      if (userId) {
        this.$router.push(`/useraccount/${userId}`);
      }
    },
    isTargetUrlHasThisEntity(entityName) {
      if (!this.targetNlpSchemaData) return false
      let found = this.targetNlpSchemaData.entities.find(entity => {
        return entity.name==entityName
      })
      if (found) {
        return true
      } else {
        return false
      }
    },

    pickFilterCategory(categoryName) {
      // if (this.categoryFilter==categoryName) {
      //   this.categoryFilter = ''
      // } else {
      //   this.categoryFilter=categoryName
      // }
      this.categoryFilter=categoryName
    },

    downloadCommonEntities(downloadOnlyMissingEntities=false) {
      let data = this.filteredCommonEntities
      let fileName = 'All Common Entities.csv';
      
      if (downloadOnlyMissingEntities) {
        data = data.filter(item => {
          return !item[2]
        })
        fileName = 'Missing Common Entities.csv';
      }

      let exportData = ['Entity Name,Site Count'];
      data.map(item => {
        exportData.push(`${item[0]}, ${item[1]}`);
      })
      exportData = exportData.join('\n');

      this.exportToCSV(exportData, fileName);

    },
    openHowtoUseGuide () {
      window.open('https://help.pageoptimizer.pro/article/pop-google-entities-dashboard', '_blank')
    }
  }
}

</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,400i,500,700&display=swap');

.download-dropdown {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: auto;
    margin-right: 10px;
  }
  span {
    color: var(--v-profileAnchorColor-base);
    font-size: 15px;
    font-weight: 700;
  }
}


.title01 {
  display: flex;
  align-items: center;
  &, & > span {
    font-weight: 700;
    font-size: 20px;
    font-family: 'Ubuntu', sans-serif;
    color: var(--v-mainColor-base);
  }
  i {
    font-size: 14px;
    color: var(--v-mainColor-base);
  }
  
}
.title02 {
  font-weight: 700;
	margin-bottom: 15px;
  font-size: 15px;
  font-family: 'Ubuntu', sans-serif;
	color: #333333;
}
.boxList{
  display: flex;
	flex-wrap: wrap; 
	padding: 5px;
	overflow: auto; 
  height: 300px;
  align-content: flex-start;
}
.fullboxList{display: flex;
	flex-wrap: wrap; 
	padding: 15px 10px 0px; 
	overflow: auto; 
	height: 1000px;
}
.boxList>li{width: 49%; padding: 5px;}
.boxPanel{
  position: relative;
  /* height: 100%; */
  padding: 15px;
  color: #fff;  border: 1px #f7f7f7 solid;
  transition: all ease-in-out 0.5s; border-radius: 4px; overflow: hidden;
}
.boxPanel:hover{box-shadow: 0px 2px 5px rgba(0,0,0,0.05);}
.boxPanel h5{font-size: 15px; color: #000; margin-bottom: 10px;}
.boxPanel strong{font-weight: 600; color: #000;}
.boxPanel .linkAnc{color: blue; font-size: 0.8rem;}
.boxPanel .linkAnc:hover{text-decoration: underline !important;}
.boxPanel .salience{font-size: 14px; margin-top: 5px; color: black;}
.boxPanel .btnLink{
    font-size: 0.8rem;
    padding: 8px 20px;
    display: inline-block;
    /* background: #ff5959; */
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0; padding-left: 25px; overflow: hidden;;
    }
.boxPanel .btnLink:before{position: absolute; content: ''; left: 0; top: 0; width: 0;
	height: 0;
	border-left: 0px solid transparent;
	border-right: 15px solid transparent;
	border-top: 35px solid #fff; transition: all ease-in-out 0.5s; }

.bg1{background-color: #1ee3cf !important;}
.bg1 .btnLink{background-color: #19cab8;}
.bg1 .btnLink:before{border-top-color: #1ee3cf;}
.bg1:hover{background-color: #19cab8 !important;}
.bg1:hover .btnLink{background-color: #1ee3cf;}
.bg1:hover .btnLink:before{border-top-color: #19cab8;}

.bg2{background-color: #36c4dc !important;}
.bg2 .btnLink{background-color: #107595;}
.bg2 .btnLink:before{border-top-color: #36c4dc;}
.bg2:hover{background-color: #107595 !important;}
.bg2:hover .btnLink{background-color: #36c4dc;}
.bg2:hover .btnLink:before{border-top-color: #107595;}

.bg3{background-color: #7b88ff !important;}
.bg3 .btnLink{background-color: #5159a1;}
.bg3 .btnLink:before{border-top-color: #7b88ff;}
.bg3:hover{background-color: #5159a1 !important;}
.bg3:hover .btnLink{background-color: #7b88ff;}
.bg3:hover .btnLink:before{border-top-color: #5159a1;}

.bg4{background-color: #ff6768 !important;}
.bg4 .btnLink{background-color: #ff0b55;}
.bg4 .btnLink:before{border-top-color: #ff6768;}
.bg4:hover{background-color: #ff0b55 !important;}
.bg4:hover .btnLink{background-color: #ff6768;}
.bg4:hover .btnLink:before{border-top-color: #ff0b55;}

.bg5{background-color: #45eba5 !important;}
.bg5 .btnLink{background-color: #21aba5;}
.bg5 .btnLink:before{border-top-color: #45eba5;}
.bg5:hover{background-color: #21aba5 !important;}
.bg5:hover .btnLink{background-color: #45eba5;}
.bg5:hover .btnLink:before{border-top-color: #21aba5;}

.bg6{background-color: #456173 !important;}
.bg6 .btnLink{background-color: #1b3c59;}
.bg6 .btnLink:before{border-top-color: #456173;}
.bg6:hover{background-color: #1b3c59 !important;}
.bg6:hover .btnLink{background-color: #456173;}
.bg6:hover .btnLink:before{border-top-color: #1b3c59;}

.listShow{display: flex; flex-wrap: wrap;}
.listShow>li{position: relative; width: 33.33%; padding: 5px 10px 5px 15px; font-size: 13px; color: #333; font-weight: 600;}
.listShow>li:before{position: absolute; content: ''; width: 7px; height: 7px; left: 0; top: 10px; background-color: transparent;}

.color1{color: #1ee3cf !important; font-weight: 600;}
.color2{color: #36c4dc !important; font-weight: 600;}
.color3{color: #5159a1 !important; font-weight: 600;}
.color4{color: #ff6768 !important; font-weight: 600;}
.color5{color: #45eba5 !important; font-weight: 600;}
.color6{color: #1b3c59 !important; font-weight: 600;}

.links{font-size: 14px; font-weight: 500; color: #6c5ce7;}
.links:hover{color: #ff5959;}
.boxPanel{
    background: #fff !important;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
}
.wordscroll {
    overflow: scroll;
    height: 200px;
    width: 100%;
    margin-bottom: 30px;
}
.UNKNOWN_COLOR{
	color: #2D0E34
}
.LOCATION{
	color: #ea4335
}
.ORGANIZATION{
	color: #34a853
}
.EVENT{
	color: #4285f4
}
.OTHER{
	color: #f29900
}
.PHONE_NUMBER{
	color: #fa7b17
}
.ADDRESS{
	color: #a142f4
}
.DATE{
	color: #24c1e0
}
.NUMBER{
	color: #f538a0
}
.PRICE{
	color: #D5B0DC
}

.UNKNOWN_COLOR{ background:#2D0E34}
.PERSON_COLOR{ background:#ea4335}
.LOCATION_COLOR{ background:#34a853}
.ORGANIZATION_COLOR{ background:#4285f4}
.EVENT_COLOR{ background:#f29900}
.WORK_OF_ART_COLOR{ background:#fa7b17}
.CONSUMER_GOOD_COLOR{ background:#a142f4}
.OTHER_COLOR{ background:#9aa0a6}
.PHONE_NUMBER_COLOR{ background:#24c1e0}
.ADDRESS_COLOR{ background:#f538a0}
.DATE_COLOR{ background:#D5B0DC}
.NUMBER_COLOR{ background:#185abc}
.PRICE_COLOR{ background:#b31412}

.FONT_UNKNOWN_COLOR{ color:#2D0E34}
.FONT_PERSON_COLOR{ color:#ea4335}
.FONT_LOCATION_COLOR{ color:#34a853}
.FONT_ORGANIZATION_COLOR{ color:#4285f4}
.FONT_EVENT_COLOR{ color:#f29900}
.FONT_WORK_OF_ART_COLOR{ color:#fa7b17}
.FONT_CONSUMER_GOOD_COLOR{ color:#a142f4}
.FONT_OTHER_COLOR{ color:#9aa0a6}
.FONT_PHONE_NUMBER_COLOR{ color:#24c1e0}
.FONT_ADDRESS_COLOR{ color:#f538a0}
.FONT_DATE_COLOR{ color:#D5B0DC}
.FONT_NUMBER_COLOR{ color:#185abc}
.FONT_PRICE_COLOR{ color:#b31412}
.wordscroll.span{
	color:#bbb
}

.common-table {
  font-size: 0.9rem;
}

.tag { // Customize bulma tag
  white-space: inherit;
  height: auto;
  min-height: 2em;
}

.is-selected-category {
    box-shadow: 2px 2px rgb(193, 193, 193);
}
.is-faded-green-bg {
  background: #62c57d;
  color: white;
}
.is-faded-red-bg {
  background-color: var(--v-red3Color-base);
  color: white;
}

.demo-img {
  width: 100%;
  height: auto;
}

</style>