<template>
<div class="modal" :class="{'is-active': showModal }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box modal-container d-flex flex-column justify-center">
      <div>
        <div class="title-label">{{ title }}</div>
        <div class="mt-5 mainColor--text">
          <template v-if="shortenKey == 'list'">
            Give the AI information on what you need in your list. You can simply give the topic and the number of list items you would like, or you can get more specific. You can tell the AI context or parameters that will make your list more on point. You can tell the AI to explicitly include or exclude anything from the list. The maximum output for a list is 500 words subject to the mood of the AI.
            <br/> <br/>
            Here's an example:<br/>
            "Please create a list of 10 science fiction books that are essential reads for someone new to the genre. The list should include classics as well as modern works, and each entry should come with a brief description explaining why the book is important or noteworthy."
          </template>
          <template v-if="shortenKey == 'table'">
            Give the AI information on what you need in your table. The more specific you can be, the better. You can tell it how big you want the table to be (e.g. number of columns and rows), or you can let the AI decide. You can tell it what labels you need and the type of content you need filled in. Regardless of the number of columns or rows, the maximum word count per table is 500 words.
            <br/><br/>
            Here's an example:<br/>
            I need a table that lists the top 10 highest-grossing movies of all time. For each movie, the table should include columns for the movie's name, release year, director, and total worldwide box office gross. Arrange the movies in descending order based on their total worldwide box office gross. Also, please include an additional column that shows the main actor or actress in each movie.
          </template>
          <template v-if="shortenKey == 'faq'">
            You have two options for an FAQ. You can provide the questions that you want answered and the AI will answer the questions for you. A good source of questions is Google's People Also Ask. Or, you can have the AI generate both the questions and the answers. If you ask the AI to generate the questions, it will give you 5 questions.
          </template>
        </div>
        <div class="mt-5" v-if="shortenKey == 'faq'">
          <v-select outlined hide-details
            v-model="option"
            :items="faqOptions" item-value="value" item-text="text"
            label="Select method to create"
            color="greenColor">
          </v-select>
        </div>
        <div v-if="!(shortenKey=='faq' && option == 'aiCreateQuestion')">
          <v-textarea outlined hide-details="auto"
            color="greenColor"
            class="mt-5"
            v-model="inspirationText"
            :label="`Add text here to give ai insights (max ${ maxLength } characters.)`">
          </v-textarea>
          <div class="text-right" :class="{ 'greenColor--text': remainLength >= 0, 'redColor--text': remainLength < 0 }">
            {{ inspirationText.length }} / {{ maxLength }}
          </div>
        </div>
        <div class="d-flex justify-end mt-10">
          <v-btn rounded outlined
            color="mainColor"
            class="button-with-icon mainColor--text text-none"
            @click="showModal = false;">
            <span>Close</span>
          </v-btn>
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none ml-2"
            :disabled="!readyToGo"
            @click="execute">
            <span>Generate</span>
          </v-btn>
        </div>
      </div>
    </div>
    <button class="custom-modal-close-btn" aria-label="close" @click="showModal = false"><i class="material-icons">close</i></button>
  </div>
</div>
</template>

<script>
export default {
  name: 'ContentPromptOneSignalGeneratorPopup',
  props: [ 'aiRunId'],
  data () {
    return {
      showModal: false,
      isCalculating: false,
      
      isFirstGenerate: true,
      signalName: '',
      shortenKey: '',
      uniqueIndex: 0,
      inspirationText: '',

      option: '',
      faqOptions: [
        {
          value: 'fromUserQuestion',
          text: 'Add your own questions'
        },
        {
          value: 'aiCreateQuestion',
          text: 'Use AI to generate questions'
        }
      ]
    }
  },
  computed: {
    title: function () {
      if (this.shortenKey == 'table') {
        if (this.isFirstGenerate) {
          return 'Generate a table'
        } else {
          return 'Regenerate a table'
        }
      } else if (this.shortenKey == 'list') {
        if (this.isFirstGenerate) {
          return 'Generate a list'
        } else {
          return 'Regenerate a list'
        }
      } else if (this.shortenKey == 'faq') {
        if (this.isFirstGenerate) {
          return 'Generate an FAQ'
        } else {
          return 'Regenerate an FAQ'
        }
      }
      return '';
    },
    maxLength: function () {
      if (this.shortenKey == 'faq') {
        return 500
      }
      return 250;
      // if (this.shortenKey == 'table') {
      //   return 250;
      // }
      // return 1;
    },
    remainLength () {
      return this.maxLength - this.inspirationText.length
    },
    readyToGo () {
      if (this.shortenKey == 'faq') {
        if (!this.option) {
          return false;
        }
        if (this.option == 'aiCreateQuestion') {
          return true;
        }
      }
      if (!this.inspirationText) return false;
      if (this.remainLength < 0) return false;
      return true;
    },
  },

  methods: {
    showModalAction (isFirstGenerate, signalName, shortenKey, uniqueIndex) {
      this.isFirstGenerate = isFirstGenerate;
      this.signalName = signalName
      this.shortenKey = shortenKey
      this.uniqueIndex = uniqueIndex
      this.inspirationText = '';
      this.showModal = true;
    },
    execute () {
      if (this.inspirationText.length > this.maxLength) {
        this.$notify({
          group: 'info', type: 'warning',
          text: `Too many characters. Available max length is ${ this.maxLength } but your input length is ${ this.inspirationText.length }`
        })
        return;
      }
      let data = {
        aiRunId: this.aiRunId,
        signalName: this.signalName,
        uniqueIndex: this.uniqueIndex,
        inspirationText: this.inspirationText,
        option: this.option, // For now, this is only used for 'faq'
      }

      this.$store.commit('showLoading')
      this.isCalculating = true;
      this.$store.dispatch('getAIAnswerForContentPrompt', data)
      .then((response) => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.showModal = false;
          this.$emit('completed')
        } else {
          this.$notify({
            group: 'info', type: 'error',
            text: `Failed to generate. ${ response.data.msg }`
          })
        }
      })
      .finally(() => {
        this.isCalculating = false;
        this.$store.commit('hideLoading')
      })

      console.log(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.title-label {
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--v-mainColor-base);
}
</style>