<template>
  <div class="tell-us-experience-page mt50">
    <div class="flex flex-column">
      <div class="main-area">
        <div>
          <div class="has-text-weight-bold is-size-4">
            Tell us about your experience
          </div>
          <div class="has-text-grey mt25 mb50">
            We'd love to hear your feedback to provide the best POP experience
            for our existing customers. Please answer the questions below to
            help us improve.
          </div>
        </div>
        <div class="feedbacks-area">
          <div class="font-weight-bold font-medium">
            Please share your reason for leaving:
          </div>
          <div class=" radio-list">
            <label v-for="reason in possibleReasons" :key="reason" class="radio" @click="() => feedback = reason">
              <input type="radio" name="answer" />
              {{ reason }}
            </label>
          </div>
          <v-row 
            align="center" no-gutters>
            <v-col cols="12" sm="8" class="font-weight-bold font-medium">I plan on using the tool again if I need it:</v-col>
            <v-col cols="12" sm="4" class="plan-to-use-later">
              <button :class="{ active: planToUseAgain == 'yes' }" @click="() => (planToUseAgain = 'yes')">
                Yes
              </button>
              <button :class="{ active: planToUseAgain == 'no' }" @click="() => (planToUseAgain = 'no')">
                No
              </button>
            </v-col>
          </v-row>
          <div class="flex align-center feedback_container mt30">
            <textarea class="feedback" type="text" v-model="customFeedBack"
              placeholder="Please write your feedback here if you'd like to do." />
            <div class="feedback_label">Your feedback (optional)</div>
          </div>
        </div>
        <div class="mt20">
          <p class="font-small">
            <input type="checkbox" v-model="acknowleged" class="checkbox mr5" />
            I understand that by proceeding, I won't be able to create new
            projects, pages, and re-run reports once my subscription period ends.
          </p>
          <div class="bottom-buttons mt50 mb50">

            <button class="never-mind-btn" @click="neverMindClick">
              <span class="material-icons-outlined">arrow_back</span>
              <span class="font-weight-bold"> Nevermind</span>
            </button>

            <button :class="{ active: feedback && acknowleged, 'round_btn': true }"
              :disabled="!(feedback && acknowleged)"
              @click="cancelSubscription">
              Cancel my account
            </button>

          </div>
        </div>
      </div>
    </div>




    <div class="modal" :class="{ 'is-active': showSubscriptionCancelledModal }">
      <div class="modal-background" @click="() => {
        showSubscriptionCancelledModal = false;
        goToProfilePage();
      }"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="font-weight-bold is-size-4 mainColor--text">
              Your subscription is successfully canceled.<br />
            </div>
            <div class="text-right mt-5">
              <v-btn rounded
                color="profileAnchorColor"
                class="text-none whiteColor--text"
                @click="goToProfilePage">
                <span>Go to Profile page</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click="showSubscriptionCancelledModal = false"><i
            class="material-icons">close</i></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TellUsYourExperience",
  computed: {
    user() {
      return this.$store.state.user;
    },
    isFilledReasonForm() {
      return this.feedback && this.acknowleged
    }
  },
  data() {
    return {
      possibleReasons: [
        "Bugs or support problems",
        "Missing features we need",
        "Never onboarded or figured it out",
        "Not using it enough (or at all)",
        "Shutting down business",
        "Too expensive",
        "Other",
        "Billing issue / help"
      ],

      feedback: '',
      customFeedBack: '',
      planToUseAgain: null,
      acknowleged: false,


      showSubscriptionCancelledModal: false,
    };
  },
  methods: {
    neverMindClick() {
      this.$store.commit('showLoading');
      this.$store.dispatch('neverMindClicked')
        .then(response => {

        })
        .finally(() => {
          this.$store.commit('hideLoading');
          this.goToProfilePage();
        })
    },
    goBack() {
      this.$router.go(-1);
    },
    goToProfilePage() {
      console.log(this.user);
      this.$router.push(`/useraccount/${this.user.id}`);
    },
    cancelSubscription() {
      if (!this.isFilledReasonForm) {
        this.$notify({
          group: 'info', type: 'warning',
          text: `Please fill out the form to proceed.`
        })
        return;
      }
      this.$store.commit('showLoading');
      this.$store.dispatch("cancelSubscription", {
        feedback: this.feedback,
        customFeedback: this.customFeedBack,
        willUseToolAgain: this.planToUseAgain == 'yes'
      }).then(response => {
        this.showCancelSubscriptionModal = false;
        if (response.data && response.data.status === "SUCCESS") {
          this.$store.commit("setUser", response.data.user);
          this.showSubscriptionCancelledModal = true;
          // this.$router.push('/subscription-cancel-succeed');
        } else {
          alert(
            "Unable to cancel at this time. If you have already cancelled your PayPal payment,  your POP subscription has been cancelled.\nYour dashboard will update in the next 24 hours. If you have not cancelled in PayPal and are still receiving this error message please contact support."
          );
        }
      })
        .finally(() => {
          this.$store.commit('hideLoading');
        });
    }
  }
};
</script>

<style scoped lang="scss">
* {
  font-size: 1rem;
}

.modal-container {
  background-color: white;
  text-align: center;
}

.tell-us-experience-page {
  margin: 0 auto 0 auto;
  max-width: 796px;
  background-color: var(--v-cardBg-base);
  color: var(--v-mainColor-base) !important;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
}

.main-area {
  padding: 2.5rem 2.5rem 1rem 2.5rem;
}

.feedbacks-area {
  .box {
    margin: 1rem 0;
  }

  .plan-to-use-later {
    display: flex;
    gap: 1rem;

    &>button {
      padding: 0.3rem 3rem;
      background-color: var(--v-gray1Color-base);
      color: var(--v-whiteColor-base);
      font-weight: bold;
      padding: 1rem 3rem;
      border-radius: 8px;
      cursor: pointer;

      &.active {
        background: var(--v-profileAnchorColor-base);
        color: var(--v-whiteColor-base);
        border: none;
      }
    }
  }
}

.font-small {
  font-size: 13px;
}

.font-medium {
  font-size: 18px;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.radio-list {
  padding: 1rem 0;

  .radio {
    margin-right: 1rem;
    font-size: 14px;
    display: inline-flex;
    align-items: center;

    input {
      width: 23px;
      height: 23px;
      margin-right: 9px;
    }

    &+.radio {
      margin-left: 0;
      margin-top: 0.5em;
    }
  }
}

.feedback_container {
  position: relative;
}

.feedback {
  box-sizing: border-box;
  height: 150px;
  width: 708px;
  padding: 1rem;
  border: 1px solid #E4E4E4;
  border-radius: 10px;
}

.feedback_label {
  position: absolute;
  left: 1rem;
  top: -0.6rem;
  font-size: 0.8rem;
  background-color: var(--v-cardBg-base);
  padding: 0 0.2rem;
  font-weight: bold;
}

.checkbox {
  width: 19px;
  height: 19px;
  transform: translateY(5px);
}

.bottom-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  .never-mind-btn {
    padding: 1rem 0rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    .material-icons-outlined {
      font-size: 1.2rem;
      color: var(--v-mainColor-base);
    }
  }
}

.round_btn {
  padding: 15px 20px;
  border-radius: 5px;
  &.active {
    background-color: var(--v-profileAnchorColor-base);
    color: var(--v-whiteColor-base);
  }
}

@media only screen and (max-width: 700px) {
  .tell-us-experience-page {
    width: 100vw;
  }

  .title-area {
    padding: 0 0;
  }

  .feedbacks-area {
    .grid-1-2 {
      grid-template-columns: 1fr;
    }
  }

  .bottom-area {
    margin: 1rem 1rem;
  }
}
</style>
