export const mixin = {
  data () {
    return {

    }
  },
  methods: {
    showCanNotCreatePageBecauseYouWatchdogUserNotification () {
      this.$notify({
        group: 'watchdog',
        type: 'error',
        duration: -1
      })
    }
  },
}