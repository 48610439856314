<template>

  <div class='card app-credits-card'>
    <div class="card-header" style="background-color: hsl(204, 86%, 53%) !important;">
      <div class="card-header-title has-text-centered" style="padding: 9px;">
        <h4 class="subtitle-font has-text-white credits-title">{{ serviceLabel(user.accountType) }}</h4>
      </div>
    </div>
    <div class="card-content padd10">
      <p v-if="!isUnlimitedUser" class='has-text-centered subtitle' style="margin: 3px;">
        <span v-if="isCreditsUser">{{ user.accountBalance }}</span>
        <span v-if="isNonUnlimitedSubscription">{{ user.runsBalance }}</span>
        <!-- <span> To Go</span> -->
      </p>

      <div class="field" v-if="user.accountType != 'platinum'" >
        <div class="control has-text-centered">
          <router-link class="button is-primary is-tooltip-bottom tooltip" :to="`/useraccount/${user.id}/purchase`"  data-tooltip="Click to change service">
            <span class="icon">
              <i class="fa fa-credit-card-alt"></i>
            </span>
            <span>Buy More</span>
          </router-link>
        </div>
      </div>
      <p class='has-text-centered' style="margin: 1px;" v-if="subscriptionEndsOn">Ends on {{ subscriptionEndsOn }}</p>

    </div>
  </div>

</template>

<script>
import { mixin as ServiceMixin } from '@/components/service_mixin'
export default {
  mixins: [ServiceMixin],
  data () {
    return {
      currentPage: ''
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isSubscription () {
      // return this.$store.state.user.accountType == 'platinum' || this.$store.state.user.accountType == 'platinum_free'
      return this.isSubscriptionService(this.$store.state.user.accountType)
    },
    subscriptionEndsOn () {
      if (this.user.purchases) {
        let purchases = this.user.purchases.slice().sort((a, b) => b.id - a.id)
        
        if (purchases && purchases.length && purchases[0].periodEnds) {
          let lastPurchase = purchases[0]
          return lastPurchase.cancelAtPeriodEnd ? new Date(lastPurchase.periodEnds).toLocaleDateString() : ''
        }
      }
      return ''
    }
  }
}
</script>

<style>
.margin1 {
  margin: 1px !important;
}

.app-credits-card {
  max-width: 166px !important;
}

.credits-title {
  width: 120px !important;
  margin-left: auto;
  margin-right: auto;
}
</style>