<template>
<div>
  <div class="custom-stack-chart">
    <div class="label-layer">
      <div class="point-label" :style="{ 'left': `${underOptimizedPosition}%`, 'color': underPercentageColor }">{{ underOptimized }}</div>
    </div>
    <div class="bar-layer-under">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>

      <div class="point under-point" :style="{ 'left': `${underOptimizedPosition}%` }"></div>
      <div class="point-tooltip under-point-tooltip" :style="{ 'left': `${underOptimizedPosition}%`}">
        {{ underOptimziedTooltipText }}
      </div>
    </div>
    <div class="label-layer">
      <div>UNDER OPTIMIZED</div>
    </div>
  </div>

  <div class="custom-stack-chart">
    <div class="label-layer">
      <div class="point-label" :style="{ 'left': `${overOptimizedPosition}%`, 'color': overPercentageColor }">{{ overOptimized }}</div>
    </div>
    <div class="bar-layer-over">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>

      <div class="point over-point" :style="{ 'left': `${overOptimizedPosition}%` }"></div>
      <div class="point-tooltip over-point-tooltip" :style="{ 'left': `${overOptimizedPosition}%`}">
        {{ overOptimziedTooltipText }}
      </div>
    </div>
    <div class="label-layer">
      <div>OVER OPTIMIZED</div>
    </div>
  </div>

</div>
</template>


<script>

let signalCount = 29
let colorBarCount = 5
let colorArray = [
  '#22DC7C',
  '#00CD49',
  '#FF9B2D',
  '#FFB95A',
  '#FF9B2D',
  '#FF8F91',
  '#FF6266',
  '#FF1F2D',
]
export default {
  props: [
    'underOptimized', 'overOptimized',
    'underOptimziedTooltipText', 'overOptimziedTooltipText'
  ],
  data () {
    return {
            
    }
  },
  computed: {
    underOptimizedPosition () {
      let pos =  this.getDegreeForValue(this.underOptimized)
      return pos
    },
    overOptimizedPosition () {
      let pos = this.getDegreeForValue(this.overOptimized)
      return pos
    },
    underPercentageColor () {
      // let idx = Math.floor(this.underOptimizedPosition/100/colorBarCount)
      // return colorArray[idx]
      return this.$vuetify.theme.currentTheme['mainColor']
    },
    overPercentageColor () {
      // let idx = Math.floor(this.overOptimizedPosition/100/colorBarCount)
      // return colorArray[idx]
      return this.$vuetify.theme.currentTheme['mainColor']
    }
  },
  methods: {
    getDegreeForValue(value) {

      let oneBlock = 100 / colorBarCount;
      if (value==0) return oneBlock/2
      let rlt = 0;
      if (value>=1 && value<=3) {
        rlt = 100 / colorBarCount + oneBlock / (3 - 1 + 2) * (value-1+1);
      } else if (value >= 4 && value <=10) {
        rlt = 100 / colorBarCount + (100 / colorBarCount) + oneBlock / (10 - 4 + 2) * (value-4+1);
      } else if (value >=11 && value <=20) {
        rlt = 100 / colorBarCount + (100 / colorBarCount) * 2 + oneBlock / (20 - 11 + 2) * (value-11+1);
      } else if (value >= 21 && value <=signalCount) {
        rlt = 100 / colorBarCount + (100 / colorBarCount) * 3 + oneBlock / (signalCount - 21 + 2) * (value-21+1);
      }
      return rlt;
    }
  }
}
</script>
<style lang="scss" scoped>

  $background-1: #00CD49;
  $background-2: #22DC7C;
  $background-3: #FF9B2D;
  $background-4: #FFB95A;
  $background-5: #FF9B2D;
  $background-6: #FF8F91;
  $background-7: #FF6266;
  $background-8: #FF1F2D;

  .custom-stack-chart {
    display: block;
    .bar-layer-over {
      display: grid;
      grid-auto-flow: column;
      position: relative;
      height: 13px;
      div:nth-child(1) {
        background: $background-1;
      }
      div:nth-child(2) {
        background: $background-2;
      }
      div:nth-child(3) {
        background: $background-3;
      }
      div:nth-child(4) {
        background: $background-4;
      }
      div:nth-child(5) {
        background: $background-5;
      }
    }
    .bar-layer-under {
      display: grid;
      grid-auto-flow: column;
      position: relative;
      height: 13px;
      div:nth-child(1) {
        background: $background-1;
      }
      div:nth-child(2) {
        background: $background-2;
      }
      div:nth-child(3) {
        background: $background-6;
      }
      div:nth-child(4) {
        background: $background-7;
      }
      div:nth-child(5) {
        background: $background-8;
      }
    }

    .point {
      background: var(--v-whiteColor-base);
      position: absolute;
      cursor: pointer;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .point:hover {
      background-color: yellow;      
    }
    .under-point:hover ~ .under-point-tooltip,
    .point-tooltip:hover{
        display: block;
    }
    
    .over-point:hover ~ .over-point-tooltip,
    .point-tooltip:hover{
        display: block;
    }

    .point-tooltip {
      position: absolute;
      display: none;
      background: var(--v-cardBg-base);
      border: 1px solid rgb(192, 192, 192);
      border-radius: 5px;
      padding: 10px;
      min-width: 300px;
      top: 70%;
      z-index: 50;
    }
    
    .label-layer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;
      color: var(--v-mainColor-base);
      min-height: 16px;
      div {
        font-size: 0.6rem;
      }
      div:nth-child(1) {
        text-align: left;
      }
      div:nth-child(2) {
        text-align: right;
      }
      
      .point-label {
        position: absolute;
        transform: translateX(-50%);
      }
    }
  }
</style>