<template>
  <div v-if="hasData">
    <div class="d-flex align-center mt-3 mb-5">
      <div class="flex-grow-1 report-one-page-name gap10">
        <span>Content prompts</span>
        <span
          class="material-icons video-tutorial-icon video-tutorial-icon-size"
          @click="
            () => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: 'How to Read the Content Prompts Tab',
                videoLink:
                  'https://www.youtube.com/embed/-jkudQ4zulM?si=lNHrRcuQR2RvECq4',
                description:
                  'This video details where the data in the Content Prompts tab comes from as well as how to best use it to your advantage.',
                time: '1m 26s',
              });
            }
          "
        >
          videocam
        </span>
      </div>
    </div>

    <div class="container-full-width">
      <div class="left-column">
        <div>
          <v-card class="is-fullheight d-flex flex-column white-common-box">
            <div class="pl-6 pb-5 pr-5 pt-5 border-bottom">
              <div class="d-flex is-align-items-center">
                <img
                  src="/static/custom-dashboard.png"
                  alt=""
                  class="mr-6"
                  style="width: 28px; height: 28px"
                />
                <div class="d-flex flex-column">
                  <h3
                    class="has-text-weight-bold is-size-5 mainColor--text mb-2"
                  >
                    Page Structure
                    <v-tooltip top color="transparent">
                      <div class="white-bg-tooltip" style="max-width: 400px">
                        These things probably won't affect rank but will help
                        you create a better page
                      </div>
                    </v-tooltip>
                  </h3>
                  <div class="mainColor--text" style="font-size: 14px">
                    Page structure helps you create a better page but generally
                    doesn’t affect rank.
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 pl-3 pr-3 pb-3 flex">
              <div class="flex flex-column pb-1">
                <div class="flex-grow-1 competitor-titles pl-3 pr-3 pt-1">
                  <table>
                    <tbody>
                      <tr
                        v-for="(
                          item, idx
                        ) in pageStructureRecommendationsForRender"
                        :key="idx"
                        class="bottom-border-custom"
                      >
                        <td style="font-size: 14px; font-weight: 700">
                          {{ item.label }}
                        </td>
                        <td>
                          <span
                            class="current-count"
                            :class="getStatusClassForPageStructureItem(item)"
                          >
                            Current {{ item.current }}
                          </span>
                        </td>
                        <td>
                          {{ item.recommend }}
                          <span
                            v-if="item.label == 'Links'"
                            class="icon tooltip is-tooltip-right is-tooltip-multiline"
                            data-tooltip="These can be  links to another page on you site or links to an authority site.  You could also do jump links on your page or a Table of contents."
                          >
                            <i class="fa fa-info-circle"></i>
                          </span>
                        </td>
                        <td class="text-right">
                          <!-- {{ item }} -->
                          <template v-if="item.tagLabel">
                            <v-btn
                              v-if="item.checklistId > 0"
                              rounded
                              x-small
                              outlined
                              color="greenColor"
                              class="button-with-icon text-none btn-custom-height mb-3 mt-3"
                            >
                              <span class="material-icons mr-1" small
                                >add_task</span
                              >
                              <span>Added</span>
                            </v-btn>
                            <v-btn
                              v-else
                              rounded
                              color="profileAnchorColor"
                              class="button-with-icon text-none whiteColor--text btn-custom-height mb-3 mt-3"
                              :disabled="$store.getters.isDemoUser"
                              @click="addToChecklist(item)"
                            >
                              <span class="material-icons mr-1">add_task</span>
                              <span>Add to to-do list</span>
                            </v-btn>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="has-text-right mt10">
                  <v-btn
                    small
                    style="
                      font-size: 14px;
                      font-weight: 700;
                      background-color: transparent;
                    "
                    class="text-none primary-color"
                    @click.stop="showMorePageStructure = !showMorePageStructure"
                  >
                    {{ showMorePageStructure ? "See less" : "See all" }}
                    <i
                      v-if="showMorePageStructure"
                      class="material-icons primary-color"
                      >arrow_drop_up</i
                    >
                    <i v-else class="material-icons primary-color"
                      >arrow_drop_down</i
                    >
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <div>
          <v-card class="is-fullheight d-flex flex-column white-common-box">
            <div class="pl-6 pb-5 pr-5 pt-5 border-bottom">
              <div class="d-flex is-align-items-flex-start">
                <img
                  src="/static/custom-search.png"
                  alt=""
                  class="mr-3"
                  style="width: 33px; height: 23px"
                />

                <div class="d-flex flex-column">
                  <h3
                    class="has-text-weight-bold is-size-5 mainColor--text flex-grow-1 pb-3"
                  >
                    Related Keywords
                    <v-tooltip top color="transparent">
                      <div class="white-bg-tooltip" style="max-width: 400px">
                        If relevant, use these keywords where you can on your
                        page. If the related keyword is important, consider
                        using it in a subtitle.
                      </div>
                    </v-tooltip>
                  </h3>
                  <div class="mainColor--text">
                    If relevant, use these where you can on your page. If a
                    related keyword is important, consider using it in a
                    sub-title.
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-6 pb-5 pr-5 pt-5">
              <ul class="competitor-titles">
                <li
                  v-for="(relatedSearch, i) in filteredRelatedSearches"
                  :key="`${i}-related-search`"
                >
                  <a :href="relatedSearch.link" target="_blank">{{
                    relatedSearch.query
                  }}</a>
                </li>
                <li
                  v-if="filteredRelatedSearches.length == 0"
                  class="has-text-grey is-italic"
                >
                  We checked but Google didn't give any Related Keywords.
                </li>
              </ul>
            </div>
          </v-card>
        </div>
        <div>
          <v-card class="is-fullheight d-flex flex-column white-common-box">
            <div class="pl-6 pb-5 pr-5 pt-5 border-bottom">
              <div class="d-flex is-align-items-flex-start">
                <img
                  src="/static/custom-question.png"
                  alt=""
                  class="mr-3"
                  style="width: 33px; height: 33px"
                />

                <div class="d-flex flex-column">
                  <h3
                    class="has-text-weight-bold is-size-5 mainColor--text flex-grow-1 pb-3"
                  >
                    Related questions
                  </h3>
                  <div class="mainColor--text">
                    Answer related questions on your page or on another page on
                    your site to build relevancy. Use them if you need to expand
                    your content or create related pages on your site and link
                    those pages to your target page.
                  </div>
                </div>
              </div>
              <!-- <a v-if="!isCollapseRelatedKeywordsArea" @click.stop="isCollapseRelatedKeywordsArea=true"><span class="material-icons fold_icon" >unfold_less</span></a>
            <a v-else @click.stop="isCollapseRelatedKeywordsArea=false"><span class="material-icons fold_icon">unfold_more</span></a> -->
            </div>
            <div class="pr-5 pt-5 pb-5 pl-6">
              <ul class="related-question">
                <li
                  v-for="(relatedQuestion, i) in filteredRelatedQuestions"
                  :key="`${i}-related-question`"
                  class="pb-5 bottom-border-custom"
                  :clas="{ 'pb-5': !!i }"
                >
                  <a
                    v-if="relatedQuestion.snippet"
                    @click.stop="toggleQuestion(relatedQuestion)"
                    style="text-decoration: none"
                  >
                    <h4
                      class="has-text-weight-bold mainColor--text flex-grow-1 pb-2"
                      style="font-size: 16px"
                    >
                      {{ relatedQuestion.question }}
                    </h4>
                  </a>
                  <h4
                    v-else
                    class="has-text-weight-bold mainColor--text flex-grow-1 pb-2"
                    style="font-size: 16px"
                  >
                    {{ relatedQuestion.question }}
                  </h4>
                  <div
                    v-show="expandedQuestion === relatedQuestion.title"
                    class="mainColor--text pb-1"
                  >
                    {{ relatedQuestion.snippet }}
                  </div>
                  <a :href="relatedQuestion.link" target="_blank">{{
                    relatedQuestion.displayed_link
                  }}</a>
                </li>
              </ul>
            </div>
          </v-card>
        </div>
      </div>
      <div class="right-column">
        <div>
          <v-card class="is-fullheight d-flex flex-column white-common-box">
            <div class="pl-6 pb-5 pr-5 pt-5 border-bottom">
              <div class="d-flex is-align-items-center">
                <img
                  src="/static/custom-global-search.png"
                  alt=""
                  class="mr-6"
                  style="width: 28px; height: 28px"
                />
                <div class="d-flex flex-column">
                  <h3
                    class="has-text-weight-bold is-size-5 mainColor--text mb-2"
                  >
                    Competitor headings
                  </h3>
                  <div class="mainColor--text" style="font-size: 14px">
                    Use your competitors titles and sub-headings for
                    inspiration.
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-grow-1 pl-6 pb-5 pr-5 pt-5 flex">
              <div class="flex flex-column">
                <div class="flex-grow-1 competitor-headings">
                  <ul>
                    <li
                      v-for="(c, i) in showMoreCompetitorsForH2
                        ? competitors
                        : competitors.slice(0, 5)"
                      :key="`${i}-related-question`"
                      :class="{ 'active-tab': showH2CompetitorId == c.id }"
                    >
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <div>
                          <h4
                            class="has-text-weight-bold mainColor--text flex-grow-1 pb-2"
                            style="font-size: 16px"
                          >
                            {{ c.title }}
                          </h4>
                          <a target="_blank" :href="c.url">
                            {{ c.url }}
                          </a>
                        </div>
                        <img
                          v-if="showH2CompetitorId === c.id"
                          src="/static/custom-minus-box.png"
                          alt="Minus"
                          @click="showH2CompetitorId = 0"
                          class="cursor-pointer"
                        />
                        <img
                          v-else
                          src="/static/custom-plus-box.png"
                          alt="Plus"
                          @click="showH2CompetitorId = c.id"
                          class="cursor-pointer"
                        />
                      </div>
                      <template v-if="showH2CompetitorId === c.id">
                        <template v-if="c.headingsTree?.length">
                          <div
                            v-for="(tag, tagI) in mapTree(c.headingsTree)"
                            :key="tagI"
                            :style="`display: flex; align-items: center; gap: 12px; border: 0.5px solid #CCCCCC; padding: 12px; margin: 12px 0 8px 0; margin-left: ${
                              16 * tag.depth
                            }px; border-radius: 7px; background-color: white;`"
                          >
                            <div
                              :style="`font-family: Inter, sans-serif; border-radius: 3px; padding: 8px; font-size: 14px; font-weight: 600; color: ${
                                headingColors[tag.tag].color
                              }; background-color: ${
                                headingColors[tag.tag].bgColor
                              };`"
                            >
                              {{ tag.tag }}
                            </div>
                            <span
                              style="
                                color: #014751;
                                font-size: 14px;
                                font-weight: 400;
                              "
                            >
                              {{ tag.text }}
                            </span>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            v-if="
                              filteredGCompetitors.find(
                                (comp) => comp.url === c.url
                              )
                            "
                            style="
                              display: flex;
                              align-items: center;
                              gap: 12px;
                              border: 0.5px solid #cccccc;
                              padding: 12px;
                              margin: 12px 0 8px 0;
                              border-radius: 7px;
                              background-color: white;
                            "
                          >
                            <div
                              style="
                                font-family: Inter, sans-serif;
                                border-radius: 3px;
                                background-color: #d6fbee;
                                padding: 8px;
                                font-size: 14px;
                                font-weight: 600;
                                color: #007438;
                              "
                            >
                              H1
                            </div>
                            <span
                              style="
                                color: #014751;
                                font-size: 14px;
                                font-weight: 400;
                              "
                            >
                              {{
                                filteredGCompetitors.find(
                                  (comp) => comp.url === c.url
                                ).title
                              }}
                            </span>
                          </div>
                          <div
                            v-for="(h2Text, h2i) in c.h2Texts"
                            :key="h2i"
                            style="
                              display: flex;
                              align-items: center;
                              gap: 12px;
                              border: 0.5px solid #cccccc;
                              padding: 12px;
                              margin: 12px 0 8px 16px;
                              border-radius: 7px;
                              background-color: white;
                            "
                          >
                            <div
                              style="
                                font-family: Inter, sans-serif;
                                border-radius: 3px;
                                background-color: #dbf0fc;
                                padding: 8px;
                                font-size: 14px;
                                font-weight: 600;
                                color: #0064ba;
                              "
                            >
                              H2
                            </div>
                            <span
                              style="
                                color: #014751;
                                font-size: 14px;
                                font-weight: 400;
                              "
                            >
                              {{ h2Text }}
                            </span>
                          </div>
                          <div
                            v-for="(h3Text, h3i) in c.h3Texts"
                            :key="h3i"
                            style="
                              display: flex;
                              align-items: center;
                              gap: 12px;
                              border: 0.5px solid #cccccc;
                              padding: 12px;
                              margin: 12px 0 8px 34px;
                              border-radius: 7px;
                              background-color: white;
                            "
                          >
                            <div
                              style="
                                font-family: Inter, sans-serif;
                                border-radius: 3px;
                                background-color: #ffe1f3;
                                padding: 8px;
                                font-size: 14px;
                                font-weight: 600;
                                color: #ef34a4;
                              "
                            >
                              H3
                            </div>
                            <span
                              style="
                                color: #014751;
                                font-size: 14px;
                                font-weight: 400;
                              "
                            >
                              {{ h3Text }}
                            </span>
                          </div>
                        </template>
                      </template>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as cb_mixin } from "./cb_mixin";

export default {
  props: [
    "selectedMainTab",
    "createdAt",
    "url",
    "keyword",
    "pageRunId",
    "pageNotBuilt",
    "page",
    "ignoreSpaces",
    "willUseSecretKey",
    "secretKey",
    "contentBrief",
    "runStrategies",
    "tagTotalData",
    "wordCountData",
    "gCompetitors",
    "relatedSearches",
    "relatedQuestions",
    "contentBriefFile",
    "isFromExtension",
    "showAsMobileView",
    "hideActionButtons",
    "excludedTermsByUser",
    "competitors",
  ],
  mixins: [cb_mixin],
  

  computed: {
    headingColors() {
      return {
        h1: { color: "#007438", bgColor: "#D6FBEE" },
        h2: { color: "#0064BA", bgColor: "#DBF0FC" },
        h3: { color: "#EF34A4", bgColor: "#FFE1F3" },
      };
    },
    hasData() {
      return !!this.gCompetitors || !!this.relatedSearches;
    },
    checkLists() {
      if (this.page && this.page.checkLists) {
        return this.page.checkLists;
      }
      return [];
    },
    unarchivedChecklists() {
      return this.checkLists.filter((item) => !item.isArchived);
    },
    pageStructureRecommendationsForRender() {
      return this.filteredPageStructureRecommendations.map((row) => {
        let found = this.unarchivedChecklists.find((checklist) => {
          return (
            checklist.type == 2 &&
            checklist.signal &&
            checklist.signal == row.tagLabel
          );
        });
        if (found) {
          row.checklistId = found.id;
          row.isCompleted = found.isCompleted;
        } else {
          row.checklistId = 0;
        }
        return row;
      });
    },
    filteredGCompetitors() {
      let count = 0;
      return this.gCompetitors.filter((item) => {
        count++;
        switch (this.showMoreGCompetitors) {
          case true:
            if (count <= 10) return true;
            else return false;
          case false:
            if (count <= 7) return true;
            else return false;
          default:
            break;
        }
      });
    },
    filteredRelatedSearches() {
      return this.relatedSearches.filter((item) => {
        if (this.showMoreRelatedSearches) return true;
        return true;
      });
    },
    filteredRelatedQuestions() {
      return this.relatedQuestions.filter((item, i) => {
        if (this.showMoreRelatedQuestions) return true;
        return i <= 5;
      });
    },
  },

  data() {
    return {
      expandedQuestion: "",
      showMorePageStructure: false,
      showMoreGCompetitors: false,
      showMoreRelatedSearches: false,
      showMoreRelatedQuestions: false,

      isCollapsePageStructureArea: false,
      isCollapseCompetitorTitlesArea: false,
      isCollapseRelatedKeywordsArea: false,
      isCollapseRelatedQuestionsArea: false,

      showH2CompetitorId: 0,
      showMoreCompetitorsForH2: false,

      showH3CompetitorId: 0,
      showMoreCompetitorsForH3: false,
    };
  },
  methods: {
    mapTree(data, depth = 0) {
      let result = [];
      data.forEach((item) => {
        result.push({ ...item, depth });
        if (item.children && item.children.length) {
          result = result.concat(this.mapTree(item.children, depth + 1));
        }
      });
      return result.filter((row) => row.text);
    },
    toggleQuestion(q) {
      this.expandedQuestion = this.expandedQuestion === q.title ? "" : q.title;
    },

    getStatusClassForPageStructureItem(item) {
      if (item.current > item.targetMax) {
        return { over: true };
      } else if (item.current < item.targetMin) {
        return { lower: true };
      } else {
        return { good: true };
      }
    },

    addToChecklist(row) {
      if (!this.page) {
        return;
      }
      let tagLabel = row.tagLabel;
      let data = {
        pageId: this.page.id,
        recommendationType: "pageStructure",
        type: 2,
        dispType: "",
        signal: tagLabel,
      };
      console.log(data);
      this.$store.commit("showLoading");
      return this.$store
        .dispatch("addOneToCheckList", data)
        .then((response) => {
          if (response.data && response.data.status === "SUCCESS") {
            console.log("successfully added!");
          }
          if (response.data.page) {
            this.$store.commit("setPage", response.data.page);
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./content-brief.scss";

.container-full-width {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  max-width: -webkit-fill-available;
}

.left-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left-column .card,
.right-column .card {
  width: 100%;
}
</style>
