<template>
<div class="three-rows-grid pt5">
    <div class="wow_disaled slideInLeft">
        <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="$emit('goprevpage')">keyboard_backspace</i>
    </div>          
    <div>
        <div class="full-width-container">
            <div class="level1-size title-font mb20 wow_disaled slideInLeft" data-wow-delay="0s">
                Add missing LSI terms or remove ones that don't belong
            </div>

            <div class="level2-size my-4 wow_disaled slideInLeft" data-wow-delay="0.3s">
                Type LSI terms you want to add above and press enter.
            </div>

            <div class="wow_disaled slideInLeft" data-wow-delay="0.5s">
                <multiselect
                    v-model="thisPhrases"
                    :options="thisPhrases"
                    :multiple="true"
                    :taggable="true"
                    :hide-selected="true"
                    placeholder=""
                    tag-placeholder="Press enter to add"
                    @tag="addNewPhrase"
                    @remove="handleRemovePhrase"
                    :disabled="runWithoutPhrases"
                    >
                </multiselect>
            </div>
            
            
            <div class="level2-size mb20 mt10 wow_disaled slideInLeft" data-wow-delay="0.3s">
              <button class="button is-pulled-right is-small is-warning" v-show="!phrasesUnchanged" @click="resetToOriginalPhrases">Reset Terms</button>
            </div>
            
            <div class="d-flex align-center mt-2">
                <div class="control">
                    <label for="run-without-variation">
                        <input id="run-without-variation" type="checkbox" v-model="thisRunWithoutPhrases" @change="handleRunWithoutPhrases">
                        <span>&nbsp;Run this report without LSI terms</span> &nbsp;
                    </label>
                </div>
                <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="material-icons info-icon-size ml-1" v-bind="attrs" v-on="on">info</span>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                        Check this box if POP does not find any variations and if there aren't any clear variations to add for your keyword or keyword phrase.
                    </div>
                </v-tooltip>
            </div>

            <div class="q-a-explain-block mt-5">
                <img src="/static/q-a-icon.png" alt="Q/A" />
                <div>
                    <div class="section-title">How to choose LSI terms?</div>
                    <div class="mt-2">
                        LSI, Latent Semantic Indexing, is <b>a general term to mean "contextual words".</b> Contextual words give your content context and meaning.
                    </div>
                    <div class="mt-2">
                        LSI isn't a precise term and can have different labels, for example, NLP, TF-IDF, LSA. All of these terms fall under the umbrella of LSI. Regardless of the preferred label, the point is, LSI are words that provide meaning.
                    </div>
                    <div class="mt-2">
                        As Google gets better at understanding content, LSI becomes more and more important.
                    </div>
                    <div class="mt-2">
                        We recommend removing competitor brand names from this list.
                    </div>
                </div>
            </div>
            
            <div>
                <div class='has-text-centered font-weight-bold profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</div>
                <div class="is-bottom-right">
                    <div class="flex-grow-1">
                        <div class="notify-label align-start mt-3">
                            <i class="material-icons">error_outline</i>
                            <div>
                                <span>Every time you click Submit you use a report credit. This includes re-runs.</span>
                                <div class="explain">
                                    You can check your scores and counts in the POP Chrome Extension or POP Content Editor an unlimited amount of times and no report credits are used. Both the extension and the editor are free to use on any plan.
                                </div>
                            </div>
                        </div>
                    </div>
                    <v-btn rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        ref="nextBtn"
                        :loading="submittingRun"
                        :disabled="submittingRun || !advanceable || needsToDisableSubmitButton"
                        @click="handleSubmit">
                        <i class="material-icons">task</i>
                        <span>Submit</span>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { cleanText } from '@/utils'
import Multiselect from 'vue-multiselect'

export default {
    components: { Multiselect },
    props: {
        keyword: { type: String, default: '' },
        variations: { type: Array, default: () => [] },
        phrases: { type: Array, default: () => [] },
        removedLSITermsByUser: { type: Array, default: () => [] },
        runWithoutPhrases: { type: Boolean, default: false },
        submittingRun: { type: Boolean, default: false },
        isExpressReport: { type: Boolean, default: false },
        advanceable: { type: Boolean, default: false },
        errorMsg: { type: String, default: '' }
    },
    data () {
        return {
            originalPhrases: this.variationsExcludedPhrases(this.phrases),
            thisPhrases: this.getSimplePhrases(),
            thisRemovedLSITermsByUser: this.removedLSITermsByUser,
            thisRunWithoutPhrases: this.runWithoutPhrases,
            variationsToAdd: '',
            phrasesToAdd: [],


            needsToDisableSubmitButton: this.submittingRun, // this is used to prevent rare case of report generated several times.
        }
    },
    watch: {
        'phrases': function(phrases) {
            if (!this.thisPhrases.length) {
                this.originalPhrases = this.variationsExcludedPhrases(phrases)
            }
            this.thisPhrases = this.getSimplePhrases()
        },
        'submittingRun': function(submitting) {
            this.needsToDisableSubmitButton = submitting
        }
    },
    methods: {
        variationsExcludedPhrases (phrases) {
            const lowerVars = this.variations.map(v => v.toLowerCase())
            return phrases.filter(p => {
                if (p instanceof Object) {
                    return !lowerVars.includes(p.phrase.trim().toLowerCase())
                }
                return !lowerVars.includes(p.trim().toLowerCase())
            })
        },
        getSimplePhrases () {
            return this.phrases.map(p => p.phrase.trim().toLowerCase())
        },
        addNewPhrase (newPhraseStr) {
            newPhraseStr = newPhraseStr.trim().toLowerCase()
            if (!newPhraseStr) {
                return
            }
            const variations = this.variations.map(v => v.toLowerCase())
            const phrases = this.thisPhrases.slice()

            let newPhrases = newPhraseStr.split(',').map(item => item.trim())
            
            newPhrases.map(newPhrase => {
                if (!phrases.includes(newPhrase) 
                && !variations.includes(newPhrase)
                && newPhrase !== this.cleanText(this.keyword).toLowerCase()) {
                    phrases.push(newPhrase)
                }
            })
            this.thisPhrases = phrases
            this.$emit('phraseschange', this.mapToPhrasesUpdate(this.thisPhrases))
        },
        handleRemovePhrase (p) {
            if (!this.originalPhrases.length) {
                this.originalPhrases = this.phrases.map(p => p.phrase)
            }
            const phrases = this.thisPhrases.slice()
            const i = phrases.indexOf(p)
            if (i >= 0) {
                phrases.splice(i, 1)
                this.thisPhrases = phrases
            }
            this.$emit('phraseschange', this.mapToPhrasesUpdate(this.thisPhrases))
            this.updateRemovedLSITermsByUser(phrases) // update removed lsi terms

        },
        updateRemovedLSITermsByUser (newPhrases) {
            let removedTermsFromOrigin = this.originalPhrases.map(p => {
                if (p instanceof Object) {
                    return p.phrase
                }
                return p
            }).filter(p => !newPhrases.includes(p))

            this.$emit('removedLSITermsByUserchange', removedTermsFromOrigin)
        },
        handleRunWithoutPhrases (e) {
            this.$emit('runwithoutphrasesschange', this.thisRunWithoutPhrases)
        },
        resetToOriginalPhrases () {
            this.thisPhrases = this.originalPhrases.map(p => p.phrase.trim().toLowerCase())
            this.$emit('phraseschange', this.mapToPhrasesUpdate(this.thisPhrases))
            this.updateRemovedLSITermsByUser(this.thisPhrases) // update removed lsi terms
        },
        mapToPhrasesUpdate (phrases) {
            const phrasesUpdate = phrases.map(p => {
                const origPhrase = this.originalPhrases.find(op => op.phrase.trim().toLowerCase() === p)
                if (origPhrase) {
                    return Object.assign({}, origPhrase)
                }
                return {
                    averageCount: 0,
                    phrase: p,
                    targetCount: 0,
                    weight: 0
                }
            })
            return phrasesUpdate
        },
        handleSubmit () {
            this.needsToDisableSubmitButton = true;
            this.$emit('submitform')
            // this.$emit('phraseschange', this.mapToPhrasesUpdate(this.thisPhrases))
            // this.$nextTick(() => {
            //     this.$emit('submitform')
            // })
        }
    },
    computed: {
        phrasesUnchanged () {
            let allMatch = true
            const original = this.originalPhrases.map(p => p.phrase.toLowerCase().trim())
            const phrases = this.thisPhrases.map(v => v.toLowerCase().trim())
            for (const v of original) {
                if (!phrases.includes(v)) {
                    allMatch = false
                    break
                }
            }
            return allMatch && original.length == phrases.length
        }
    },
    created () {
        this.cleanText = cleanText
    },
    mounted() {
        if (this.advanceable) { this.$refs.nextBtn.$el.focus() }
    }
}
</script>

<style lang="scss" scoped>
.credit-use-message {
    margin-top: 20px;
    padding: 5px 10px;
    border-radius: 7px;
    background: #ECECEC;
    font-size: 0.8rem;
}
</style>