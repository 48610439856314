<template>
<div class="modal" :class="{'is-active': showModal}">
  <div class="modal-background" @click="hideThisModal"></div>
  <div class="modal-content">
    <div class="box modal-container">
      <div class="modal-content-root">
        <div class="title-font is-size-4 mainColor--text mb10">
          Note for
          <span class="body-font is-size-6">[ Pagerun ID: {{ pageRun.id }} ]</span>
        </div>
        <div class="field">
          <div class="control">
            <textarea class="textarea" rows="5" v-model="note"></textarea>
          </div>
        </div>
        <div class="control flex mt20">
          <div class="flex-grow-1">
          </div>
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none" :class="{'is-loading': isLoading}" @click="saveNote">
            <i class="fa fa-trash"></i>
            <span>Save</span>
          </v-btn>
        </div>
      </div>
    </div>
    <button class="custom-modal-close-btn" aria-label="close" @click="hideThisModal"><i class="material-icons">close</i></button>
  </div>
</div>
</template>

<script>
export  default {
  props: ['pageRun', 'showModal'],
  watch: {
    // 'pageRun': function() {
      
    // },
    'showModal': function (newVal, oldVal) {
      if (newVal) {
        this.note = this.pageRun.note
      }
    }
  },
  data () {
    return {
      note: '',
      isLoading: false,

    }
  },
  methods: {
    hideThisModal () {
      this.$parent.hideNoteModalAction()
    },
    saveNote () {
      this.isLoading = true
      const { siteId, pageId } = this.$route.params
      const pageRunId = this.pageRun.id
      this.$store.dispatch('updatePageRunNote', { siteId, pageId, pageRunId, note: this.note })
        .then((response) => {
          this.isLoading = false
          const pageRuns = this.$store.state.pageRuns.slice()
          const idx = pageRuns.findIndex(pageRun => pageRunId == pageRun.id)
          pageRuns[idx] = response.data.pageRun
          this.$store.commit('setPageRuns', pageRuns)
          this.$parent.hideNoteModalAction()
        })
    }
  },
  mounted () {
    
  }
}
</script>

<style>

</style>