<template>
  <div class="pl20 pr20">
    <div class="page-name">Workstation</div>
    <div class="sub-label mt-7">Overview</div>
    <div class="overview-area mt-3" v-if="overviewData">
      <div class="item">
        <div class="icon">
          <span class="material-icons-outlined">source</span>
        </div>
        <div>
          <div class="label">Total projects</div>
          <div class="count">{{ overviewData.projectsCount }}</div>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <span class="material-icons-outlined">description</span>
        </div>
        <div>
          <div class="label">Total pages</div>
          <div class="count">{{ overviewData.pagesCount }}</div>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <span class="material-icons-outlined">verified_user</span>
        </div>
        <div>
          <div class="label">Pages with Watchdog</div>
          <div class="count">{{ overviewData.watchedPagesCount }}</div>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <span class="material-icons-outlined">published_with_changes</span>
        </div>
        <div>
          <div class="label">Total POP runs</div>
          <div class="count">{{ overviewData.totalPOPRuns }}</div>
        </div>
      </div>
    </div>

    <div class="sub-label mt-7">Projects</div>
    <div class="d-flex align-center gap10 mt-3">
      <div style="min-width: 300px">
        <v-text-field
          outlined
          hide-details="auto"
          dense
          label="Search by project name"
          color="greenColor"
          v-model="filterText"
        ></v-text-field>
      </div>
      <v-btn
        rounded
        class="text-none whiteColor--text"
        color="profileAnchorColor"
        @click="
          () => {
            currentPage = 1;
            loadProjects();
          }
        "
      >
        Search
      </v-btn>
      <div class="flex-grow-1"></div>
      <div>
        <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="material-icons-outlined filter-icon"
              v-bind="attrs"
              v-on="on"
              >filter_list</span
            >
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <div class="d-flex align-center gap10">
                    <span class="mainColor--text">Sort&nbsp;</span>
                    <v-select
                      dense
                      hide-details="auto"
                      outlined
                      color="greenColor"
                      v-model="sortField"
                      :items="[
                        {
                          value: 'createdAt',
                          text: 'Created at',
                        },
                        {
                          value: 'name',
                          text: 'Project name',
                        },
                      ]"
                    >
                    </v-select>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="mt-3">
      <div
        v-for="site in sites"
        :key="site.id"
        class="site-item"
        @click="
          () => {
            $router.push(`/workstation/${site.id}/pages/`);
          }
        "
      >
        <div class="icon-part">
          <span class="material-icons-outlined">source</span>
        </div>
        <div class="project-name">
          {{ site.name }}
        </div>
        <div class="page-count">
          {{ site.pagesCount }} {{ site.pagesCount > 1 ? "pages" : "page" }}
        </div>
        <div class="flex-grow-1"></div>
        <div class="progress-area">
          <v-progress-linear
            rounded
            height="10"
            :value="site.progressPercentage"
            style="min-width: 150px; max-height: 150px"
            :color="site.progressPercentage >= 50 ? '#25A97A' : '#FF1F2D'"
          >
          </v-progress-linear>
          <div class="progress-val">
            Progress
            <span class="percentage">{{ site.progressPercentage }}%</span>
          </div>
        </div>
        <div class="action-area">
          <span class="material-icons-outlined">arrow_circle_right</span>
        </div>
        <!-- 
      
      {{ site.progress }} -->
      </div>
    </div>
    <div class="mt-2" v-if="sitesTotal > perPage">
      <paginate
        :value="currentPage"
        :page-count="Math.ceil(sitesTotal / perPage)"
        prev-text="<i class='material-icons'>navigate_before</i>"
        next-text="<i class='material-icons'>navigate_next</i>"
        container-class="card-pagination"
        :click-handler="
          (cPage) => {
            currentPage = cPage;
            loadProjects();
          }
        "
      ></paginate>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  components: {
    Paginate,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filterText: "",
      sortField: "createdAt",
      result: {},
    };
  },
  computed: {
    overviewData() {
      if (this.result.overview) {
        return this.result.overview;
      }
      return null;
    },
    sitesTotal() {
      if (this.result.sitesTotal) {
        return this.result.sitesTotal;
      }
      return 0;
    },
    sites() {
      return this.result.sites || [];
    },
  },
  watch: {
    sortField(newVal, oldVal) {
      this.loadProjects();
    },
  },
  methods: {
    loadProjects() {
      const params = [
        `filterText=${this.filterText}`,
        `perPage=${this.perPage}`,
        `page=${this.currentPage}`,
        `sortField=${this.sortField}`,
      ].join("&");
      this.$store.commit("showLoading");
      this.$store
        .dispatch("getProjectsForWorkstation", params)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.result = response.data.result;
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: response.data.msg || "failed to load",
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
  },
  mounted() {
    this.loadProjects();
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
