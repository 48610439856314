<template>
  <section class="section">
    <div class="container">
      <template v-if="user.isAdmin">
        <div class="columns">
          <div class="column" v-if="user.isAdmin">
            <div class="control">
              <input type="text" class="input" placeholder="Search by affiliate" v-model="searchText">
            </div>
          </div>
          <div class="column">
            <div class="field">
              <div class="control">
                <datepicker v-model="startDate" input-class="input" placeholder="Start Date"></datepicker>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <div class="control">
                <datepicker v-model="endDate" input-class="input" placeholder="End Date"></datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <div class="control"><button @click="exportCsv(-1)" class="button is-link">Export All to CSV</button></div>
            </div>
          </div>
          <div class="column">
            <div class="control is-pulled-right">
              <div class="select is-fullwidth">
                <select v-model="perPage">
                  <option value="10">10 per page</option>
                  <option value="25">25 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <b-table
          :data="filteredSales"
          :paginated="user.isAdmin"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="false"
          :default-sort-direction="defaultSortOrder"
          default-sort="affiliateEmail"
          detailed
          detail-key="affiliateId">
          <template slot-scope="props">
            <b-table-column field="affiliateId" label="Affiliate ID" sortable numeric>{{ props.row.affiliateId }}</b-table-column>
            <b-table-column field="affiliateEmail" label="Affiliate Email" sortable width="300">{{ props.row.affiliateEmail }}</b-table-column>
            <b-table-column field="affiliateEmail" label="Paypal Email" sortable width="300">{{ props.row.paypalEmail }}</b-table-column>
            <b-table-column field="totalSales" label="Total Sales ($)" sortable numeric>{{ props.row.sales.reduce((acc, item) => acc + item.saleAmount, 0) | round(2) }}</b-table-column>
            <b-table-column field="affiliatePercentage" label="Percentage (%)" sortable numeric>{{ props.row.affiliatePercentage }}</b-table-column>
            <b-table-column field="lastPayoutDate" label="Notification sent" width="250">{{ toLocalDateString(props.row.lastPayoutDate) }}</b-table-column>
            <b-table-column field="totalCommission" label="Total Commission ($)" sortable numeric>{{ props.row.sales.reduce((acc, item) => acc + item.affiliatePortion, 0) | round(2) }}</b-table-column>
          </template>
          <template slot="detail" slot-scope="props">
            <!-- <v-card class="white-common-box pa-5">
              <h3 class="subtitle-font mb10">Affiliate Accounts</h3>
              <table>
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(email, i) in props.row.affiliateUsers" :key="i">
                    <td>{{ email }}</td>
                  </tr>
                </tbody>
              </table>
            </v-card> -->
            <!-- <v-card class="white-common-box pa-5 mt-5">
              <div class="d-flex align-center">
                <h3 class="subtitle-font mb10">Purchases</h3>
                <div class="flex-grow-1 text-right">
                  <button @click="exportCsv(props.row.affiliateId)" class="button is-link">Export to CSV</button>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Date</th>
                    <th>Customer Email</th>
                    <th>Product</th>
                    <th>Total Amount ($)</th>
                    <th>Comission ($)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(s, i) in props.row.sales" :key="i">
                    <td>{{ s.userId }}</td>
                    <td>{{ toLocalDateString(s.date) }}</td>
                    <td>{{ s.customerEmail }}</td>
                    <td>{{ s.product }}</td>
                    <td>${{ s.saleAmount | round(2) }}</td>
                    <td>${{ s.affiliatePortion | round(2) }}</td>
                  </tr>
                </tbody>
              </table>
            </v-card> -->
            <!-- <v-card class="white-common-box pa-5 mt-5">
              <div>
                <h3 class="subtitle-font mb10">Payouts received</h3>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Admin Notified</th>
                    <th>Payout Date</th>
                    <th>Payout Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, i) in props.row.recentPayouts" :key="i">
                    <td>{{ toLocalDateString(p.adminNotified) }}</td>
                    <td>{{ toLocalDateString(p.payoutDate) }}</td>
                    <td>{{ p.payoutAmount }}</td>
                    <td>{{ p.status }}</td>
                  </tr>
                </tbody>
              </table>
            </v-card> -->
            <!-- {{ JSON.stringify(props.row) }} -->
            <affiliate-sales-for-user
              :forUserView="false"
              :oneAffiliateData="props.row"
              @reloadData="() => loadData()"
              />
          </template>
        </b-table>
    </template>
    <template v-else>
      <affiliate-sales-for-user
        :forUserView="true"
        :oneAffiliateData = "allAffiliateSales.length > 0 ? allAffiliateSales[0] : {}"
        @reloadData="() => loadData()"
        />
    </template>
    </div>
  </section>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { toLocalDateString, toLocalDate } from '@/utils'
import { mixin as BaseMixin } from '@/components/mixin'
import AffiliateSalesForUser from '@/components/Admin/AffiliateSalesForUser.vue'
export default {
  components: {
    Datepicker,
    AffiliateSalesForUser,
  },
  mixins: [BaseMixin],
  data () {
    return {
      totalSales: 0,
      allAffiliateSales: [],
      searchText: '',
      perPage: 50,
      currentPage: 1,
      defaultSortOrder: 'asc',
      startDate: null,
      endDate: null
    }
  },
  computed: {
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    filteredSales () {
      let affiliateSales = this.allAffiliateSales.slice()

      if (this.startDate) {
        let startDate = toLocalDate(this.startDate)
        let affiliateSalesCopy = affiliateSales.slice()
        affiliateSales = []
        affiliateSalesCopy.forEach((item) => {
          let sales = item.sales.filter((s) => {
            let saleDate = toLocalDate(s.date)
            return saleDate.isValid() && saleDate >= startDate
          })
          if (sales.length) {
            let affiliateSale = { ...item }
            affiliateSale.sales = sales
            affiliateSales.push(affiliateSale)
          }
        })
      }

      if (this.endDate) {
        let endDate = toLocalDate(this.endDate)
        let affiliateSalesCopy = affiliateSales.slice()
        affiliateSales = []
        affiliateSalesCopy.forEach((item) => {
          let sales = item.sales.filter((s) => {
            let saleDate = toLocalDate(s.date)
            return saleDate.isValid() && saleDate <= endDate
          })
          if (sales.length) {
            let affiliateSale = { ...item }
            affiliateSale.sales = sales
            affiliateSales.push(affiliateSale)
          }
        })
      }

      if (this.searchText) {
        let searchText = this.searchText.toLowerCase()
        affiliateSales = affiliateSales.filter((s) => s.affiliateEmail.toLowerCase().includes(searchText))
      }
      return affiliateSales
    }
  },
  methods: {
    toLocalDateString (timestamp) {
      return toLocalDateString(timestamp)
    },
    exportCsv (affiliateId) {
      let affiliateSales = this.filteredSales.slice()
      if (affiliateId !== -1) {
        affiliateSales = affiliateSales.filter((sale) => sale.affiliateId === affiliateId)
      }
      let csvData = ['Sale Date,Affiliate Email,Customer Email,Sale Amount,Affiliate Commission,Product']
      affiliateSales.forEach((affSales) => {
        affSales.sales.forEach((sale) => {
          csvData.push(`${toLocalDateString(sale.date)},${sale.affiliateEmail},${sale.customerEmail},${sale.saleAmount},${sale.affiliatePortion},${sale.product}`)
        })
      })
      csvData = csvData.join('\n')
      let downloadLink;
      const now = new Date()
      const filename = "Affiliate_Sales_" + now.getUTCFullYear() + "_" + (now.getUTCMonth()+1) + "_"+ now.getUTCDate() + ".csv"
      const csvFile = new Blob([csvData], {type: "text/csv"});
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    loadData () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchAffiliateSales')
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.allAffiliateSales = response.data.affiliateSales
            // this.filteredSales = response.data.affiliateSales
            this.totalSales = response.data.totalSales
          }
          return 1
        })
        .catch((err) => {
          console.log('Error fetching admin users ', err)
        })
        .finally(() => this.$store.commit('hideLoading'))
    }
  },
  beforeMount () {
    this.loadData()    
  }
}
</script>

<style lang="scss">
.table-mobile-sort {
  display: none;
}
</style>