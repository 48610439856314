<template>
  <div class="pl20 pr20">
    <div class="bread-crumb">
      <div
        @click="
          () => {
            $router.push('/workstation');
          }
        "
      >
        Workstation
      </div>
      <div
        @click="
          () => {
            $router.push(`/workstation/${site?.id}/pages`);
          }
        "
      >
        <span class="material-icons">chevron_right</span>
        {{ site?.name }}
      </div>
      <div class="font-weight-bold">
        <span class="material-icons">chevron_right</span>
        {{ page?.name }} workstation
      </div>
    </div>
    <div class="mt-10 d-flex align-center">
      <div class="page-name flex-grow-1">
        {{ page?.name | capitalize }} page workstation
      </div>
      <div class="d-flex align-center gap5">
        <v-btn
          outlined
          rounded
          color="mainColor"
          class="button-with-icon text-none"
          @click="
            () => {
              showActivitySidebar = true;
            }
          "
        >
          <span class="material-icons">update</span>
          <span>Show activity</span>
        </v-btn>
        <v-btn
          :disabled="isDemo"
          rounded
          outlined
          color="mainColor"
          class="button-with-icon text-none"
          @click="downloadTasksExcel"
        >
          <span class="material-icons-outlined">download_for_offline</span>
          <span>Download checklist</span>
        </v-btn>
      </div>
    </div>
    <div class="mainColor--text mt-3 section-explain" style="max-width: 800px">
      Autogenerated tasks come from your Content Brief and they directly affect
      your POP score. Tasks that come from other POP tools do not directly
      affect your POP score, but are important for your SEO overall.
    </div>

    <div class="main-layout mt-10">
      <div>
        <div class="pages-overview">
          <div class="stat-by-completion-status" style="flex-basis: 350px">
            <div class="box-label">Total tasks by completion status</div>
            <div class="total-label">Total</div>
            <div class="pages-count">
              {{ tasks.length }} {{ tasks.length > 1 ? "tasks" : "task" }}
            </div>
            <div class="mt-5">
              <apex-chart
                width="325"
                height="250"
                type="donut"
                :options="taskCompletionStatus.chartOptions"
                :series="taskCompletionStatus.series"
              >
              </apex-chart>
            </div>
          </div>

          <div
            class="stat-by-completion-status flex-grow-1"
            style="flex-basis: 550px; overflow: visible; position: relative"
          >
            <div class="d-flex align-center">
              <div class="flex-grow-1 box-label">Your progress over time</div>
              <div>
                <i
                  class="material-icons box-dot-menu"
                  @click="
                    () => {
                      showProgressOvertimeCalendar =
                        !showProgressOvertimeCalendar;
                    }
                  "
                  >more_horiz</i
                >
                <date-picker
                  v-if="showProgressOvertimeCalendar"
                  v-click-outside="
                    () => {
                      showProgressOvertimeCalendar = false;
                    }
                  "
                  v-model="progressOverTimeDateRange"
                  range
                  inline
                  style="
                    position: absolute;
                    right: 20px;
                    top: 50px;
                    z-index: 12;
                  "
                >
                </date-picker>
              </div>
            </div>
            <div>
              <apex-chart
                width="100%"
                height="240"
                type="line"
                :options="progressOverTimeData.chartOptions"
                :series="progressOverTimeData.series"
              ></apex-chart>
            </div>
          </div>
        </div>
        <div class="mt-10">
          <div class="show-all-tasks-toggle">
            <div
              v-for="(tab, idx) in allTabs"
              :key="idx"
              :class="{ 'is-active': currentTab == tab.key }"
              @click="
                () => {
                  currentTab = tab.key;
                }
              "
            >
              {{ tab.label }}
            </div>
          </div>
        </div>
        <template v-if="['all_task', 'my_task'].includes(currentTab)">
          <div class="mt-5">
            <div class="section-label-thick">
              Tasks autogenerated by POP
              <span class="task-count">{{ autoTasks.length }}</span>
            </div>
            <div class="d-flex align-center mt-5">
              <div class="section-explain flex-grow-1">
                These are the tasks from your Content Brief and they directly
                affect your POP score. <br />
                Optimize each section from your Content Brief below to get to
                100.
              </div>
            </div>
            <div class="mt-5 auto-checklist-table-area">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Current count</th>
                    <th>Important terms range</th>
                    <th v-if="isAgencyUnlimitedUser">Assign to</th>
                    <th>Due date</th>
                    <th>Progress</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="task in autoTasks" :key="task.checklistId">
                    <td>
                      <div class="d-flex align-center">
                        <span class="mr-1">
                          <task-status
                            :task="task"
                            @statusChanged="loadAllTasks"
                          ></task-status>
                        </span>
                        {{ getTaskSignalDisplayName(task) }}
                      </div>
                    </td>
                    <td>
                      <span
                        class="stoplights-box"
                        :style="{
                          background: getStopLightBGColorForCBSection(task),
                        }"
                      ></span>
                      {{ task.current }}
                    </td>
                    <td>
                      <template v-if="task.strategyTarget">
                        {{ task.strategyTarget }}
                      </template>
                      <template v-else>
                        {{ task.strategyMin }} - {{ task.strategyMax }}
                      </template>
                    </td>
                    <td v-if="isAgencyUnlimitedUser">
                      <v-select
                        dense
                        hide-details
                        :items="allSubAccounts"
                        class="assignee-select"
                        :value="getSelectedAssignee(task)"
                        @change="
                          (subAccountRelation) =>
                            changeAssigneeForTask(task, subAccountRelation)
                        "
                      >
                        <template v-slot:selection="{ item, index }">
                          <div class="d-flex align-center">
                            <div
                              class="avatar-circle"
                              :style="{
                                background: strToHslColor(task.assigneeEmail),
                              }"
                            >
                              <template v-if="task.assigneeEmail">
                                {{ task.assigneeEmail[0].toUpperCase() }}
                              </template>
                              <template v-else>
                                <span class="material-icons">person</span>
                              </template>
                            </div>
                            <div class="flex-grow-1 ml-2">
                              {{ task.assigneeEmail }}
                            </div>
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <div class="d-flex align-center">
                              <div
                                class="avatar-circle"
                                :style="{
                                  background: strToHslColor(
                                    item.subAccountEmail
                                  ),
                                }"
                              >
                                <template v-if="item.subAccountEmail">
                                  {{ item.subAccountEmail[0].toUpperCase() }}
                                </template>
                                <template v-else>
                                  <span class="material-icons">person</span>
                                </template>
                              </div>
                              <div class="flex-grow-1 ml-2">
                                {{ item.subAccountEmail }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <span
                        v-if="isDemo"
                        class="material-icons-outlined add-icon grayColor--text"
                        >add_circle_outline</span
                      >
                      <div
                        v-else-if="task.dueDate"
                        class="clickable"
                        :class="{
                          'mainColor--text': isFutureDate(task.dueDate),
                          'red3Color--text': !isFutureDate(task.dueDate),
                        }"
                        @click="() => showDueDateSelectionModalAction(task)"
                      >
                        {{ toLocalDateString(task.dueDate) }}
                      </div>
                      <template v-else>
                        <span
                          class="material-icons-outlined add-icon"
                          @click="() => showDueDateSelectionModalAction(task)"
                          >add_circle_outline</span
                        >
                      </template>
                    </td>
                    <td>
                      <v-progress-linear
                        rounded
                        height="10"
                        :color="
                          task.progressPercentage ? 'green7Color' : 'redColor'
                        "
                        :value="task.progressPercentage"
                      ></v-progress-linear>
                    </td>
                    <td>
                      <img
                        src="/static/task-menu.png"
                        :id="`dot-menu-for-task-${task.checklistId}`"
                        class="task-menu-icon"
                      />
                      <v-menu
                        v-if="!isDemo"
                        :activator="`#dot-menu-for-task-${task.checklistId}`"
                        bottom
                        offset-y
                      >
                        <v-list>
                          <v-list-item link @click="() => openTaskAction(task)">
                            <v-list-item-title class="mainColor--text">
                              Open task
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            @click="
                              () => {
                                selectedTask = task;
                                showConfirmDeleteTaskModal = true;
                              }
                            "
                          >
                            <v-list-item-title class="mainColor--text">
                              Delete
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mt-10">
            <div class="section-label-thick">
              Other POP tools
              <span class="task-count">{{
                userAddedTasksExceptWatchdogTask.length
              }}</span>
            </div>
            <div class="section-explain mt-5">
              These tasks do not directly affect your POP score. You can create
              new tasks from LSI & Variations, Page Structure, EEAT, Watchdog,
              Schema, NLP, Content Prompts tabs in POP. <br />
              They are important for your SEO overall and will help you create
              better pages for Google and for users.
            </div>
            <div
              v-if="userAddedTasksExceptWatchdogTask.length == 0"
              class="mt-5 no-user-tasks mb-5"
            >
              You haven't created any tasks yet.
            </div>
            <div v-else class="mt-5 auto-checklist-table-area">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Important terms range</th>
                    <th>Current count</th>
                    <th v-if="isAgencyUnlimitedUser">Assign to</th>
                    <th>Due date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="task in userAddedTasksExceptWatchdogTask"
                    :key="task.checklistId"
                  >
                    <td>
                      <div class="d-flex align-center">
                        <span class="mr-1">
                          <task-status
                            :task="task"
                            @statusChanged="loadAllTasks"
                          ></task-status>
                        </span>
                        {{ getTaskSignalDisplayName(task) }}
                      </div>
                    </td>
                    <td>
                      <template v-if="task.strategyTarget">
                        {{ task.strategyTarget }}
                      </template>
                      <template v-else>
                        {{ task.strategyMin }} - {{ task.strategyMax }}
                      </template>
                    </td>
                    <td>{{ task.current }}</td>
                    <td v-if="isAgencyUnlimitedUser">
                      <v-select
                        dense
                        hide-details
                        :items="allSubAccounts"
                        class="assignee-select"
                        :value="getSelectedAssignee(task)"
                        @change="
                          (subAccountRelation) =>
                            changeAssigneeForTask(task, subAccountRelation)
                        "
                      >
                        <template v-slot:selection="{ item, index }">
                          <div class="d-flex align-center">
                            <div
                              class="avatar-circle"
                              :style="{
                                background: strToHslColor(task.assigneeEmail),
                              }"
                            >
                              <template v-if="task.assigneeEmail">
                                {{ task.assigneeEmail[0].toUpperCase() }}
                              </template>
                              <template v-else>
                                <span class="material-icons">person</span>
                              </template>
                            </div>
                            <div class="flex-grow-1 ml-2">
                              {{ task.assigneeEmail }}
                            </div>
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <div class="d-flex align-center">
                              <div
                                class="avatar-circle"
                                :style="{
                                  background: strToHslColor(
                                    item.subAccountEmail
                                  ),
                                }"
                              >
                                <template v-if="item.subAccountEmail">
                                  {{ item.subAccountEmail[0].toUpperCase() }}
                                </template>
                                <template v-else>
                                  <span class="material-icons">person</span>
                                </template>
                              </div>
                              <div class="flex-grow-1 ml-2">
                                {{ item.subAccountEmail }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <div
                        v-if="task.dueDate"
                        class="clickable"
                        :class="{
                          'mainColor--text': isFutureDate(task.dueDate),
                          'red3Color--text': !isFutureDate(task.dueDate),
                        }"
                        @click="() => showDueDateSelectionModalAction(task)"
                      >
                        {{ toLocalDateString(task.dueDate) }}
                      </div>
                      <template v-else>
                        <span
                          class="material-icons-outlined add-icon"
                          @click="() => showDueDateSelectionModalAction(task)"
                          >add_circle_outline</span
                        >
                      </template>
                    </td>
                    <td>
                      <img
                        src="/static/task-menu.png"
                        :id="`dot-menu-for-task-${task.checklistId}`"
                        class="task-menu-icon"
                      />
                      <v-menu
                        :activator="`#dot-menu-for-task-${task.checklistId}`"
                        bottom
                        offset-y
                      >
                        <v-list>
                          <v-list-item link @click="() => openTaskAction(task)">
                            <v-list-item-title class="mainColor--text">
                              Open task
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            @click="
                              () => {
                                selectedTask = task;
                                showConfirmDeleteTaskModal = true;
                              }
                            "
                          >
                            <v-list-item-title class="mainColor--text">
                              Delete
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mt-10">
            <div class="section-label-thick">
              Watchdog Tasks
              <span class="task-count">{{ watchdogTasks.length }}</span>
            </div>
            <div class="section-explain mt-5">
              These tasks are from the Watchdog report. These tasks are crucial
              for your overall SEO and will help you create better pages for
              Google and users.
            </div>
            <div
              v-if="watchdogTasks.length == 0"
              class="mt-5 no-user-tasks mb-5"
            >
              You haven't created any tasks yet.
            </div>
            <div v-else class="mt-5 auto-checklist-table-area">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th v-if="isAgencyUnlimitedUser">Assign to</th>
                    <th>Due date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="task in watchdogTasks" :key="task.checklistId">
                    <td>
                      <div class="d-flex align-center">
                        <span class="mr-1">
                          <task-status
                            :task="task"
                            @statusChanged="loadAllTasks"
                          ></task-status>
                        </span>
                        <span
                          class="watchdog-recommend-text"
                          v-html="task.customRecommendationText"
                        ></span>
                      </div>
                    </td>
                    <td v-if="isAgencyUnlimitedUser">
                      <v-select
                        dense
                        hide-details
                        :items="allSubAccounts"
                        class="assignee-select"
                        :value="getSelectedAssignee(task)"
                        @change="
                          (subAccountRelation) =>
                            changeAssigneeForTask(task, subAccountRelation)
                        "
                      >
                        <template v-slot:selection="{ item, index }">
                          <div class="d-flex align-center">
                            <div
                              class="avatar-circle"
                              :style="{
                                background: strToHslColor(task.assigneeEmail),
                              }"
                            >
                              <template v-if="task.assigneeEmail">
                                {{ task.assigneeEmail[0].toUpperCase() }}
                              </template>
                              <template v-else>
                                <span class="material-icons">person</span>
                              </template>
                            </div>
                            <div class="flex-grow-1 ml-2">
                              {{ task.assigneeEmail }}
                            </div>
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <div class="d-flex align-center">
                              <div
                                class="avatar-circle"
                                :style="{
                                  background: strToHslColor(
                                    item.subAccountEmail
                                  ),
                                }"
                              >
                                <template v-if="item.subAccountEmail">
                                  {{ item.subAccountEmail[0].toUpperCase() }}
                                </template>
                                <template v-else>
                                  <span class="material-icons">person</span>
                                </template>
                              </div>
                              <div class="flex-grow-1 ml-2">
                                {{ item.subAccountEmail }}
                              </div>
                            </div>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <div
                        v-if="task.dueDate"
                        class="clickable"
                        :class="{
                          'mainColor--text': isFutureDate(task.dueDate),
                          'red3Color--text': !isFutureDate(task.dueDate),
                        }"
                        @click="() => showDueDateSelectionModalAction(task)"
                      >
                        {{ toLocalDateString(task.dueDate) }}
                      </div>
                      <template v-else>
                        <span
                          class="material-icons-outlined add-icon"
                          @click="() => showDueDateSelectionModalAction(task)"
                          >add_circle_outline</span
                        >
                      </template>
                    </td>
                    <td class="status-td">
                      <img
                        src="/static/task-menu.png"
                        :id="`dot-menu-for-task-${task.checklistId}`"
                        class="task-menu-icon"
                      />
                      <v-menu
                        :activator="`#dot-menu-for-task-${task.checklistId}`"
                        bottom
                        offset-y
                      >
                        <v-list>
                          <v-list-item link @click="() => openTaskAction(task)">
                            <v-list-item-title class="mainColor--text">
                              Open task
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            @click="
                              () => {
                                selectedTask = task;
                                showConfirmDeleteTaskModal = true;
                              }
                            "
                          >
                            <v-list-item-title class="mainColor--text">
                              Delete
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <template v-if="currentTab == 'deleted_task'">
          <div class="mt-5">
            <div class="section-label-thick">Deleted tasks</div>
            <div class="d-flex align-center mt-5">
              <div class="section-explain flex-grow-1">
                Here's the list of tasks deleted.
              </div>
            </div>
            <div class="mt-5 auto-checklist-table-area">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Important terms range</th>
                    <th>Current count</th>
                    <!-- <th>Due date</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="task in deletedTasks" :key="task.checklistId">
                    <td>
                      <div class="d-flex align-center">
                        <img
                          src="/static/task-menu.png"
                          :id="`dot-menu-for-task-${task.checklistId}`"
                          class="task-menu-icon"
                        />
                        <v-menu
                          :activator="`#dot-menu-for-task-${task.checklistId}`"
                          bottom
                          offset-y
                        >
                          <v-list>
                            <v-list-item
                              link
                              @click="() => restoreTaskAction(task)"
                            >
                              <v-list-item-title class="mainColor--text">
                                Restore
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              link
                              @click="
                                () => {
                                  selectedTask = task;
                                  showConfirmDeleteTaskModal = true;
                                }
                              "
                            >
                              <v-list-item-title class="mainColor--text">
                                Delete permenantely
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        {{ getTaskSignalDisplayName(task) }}
                      </div>
                    </td>
                    <td>
                      <template v-if="task.strategyTarget">
                        {{ task.strategyTarget }}
                      </template>
                      <template v-else>
                        {{ task.strategyMin }} - {{ task.strategyMax }}
                      </template>
                    </td>
                    <td>
                      <!-- <span
                      class="stoplights-box"
                      :style="{ background: getStopLightBGColorForCBSection(task) }"
                    ></span> -->
                      {{ task.current }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div v-if="showActivitySidebar" class="activity-sidebar">
      <div class="header-part">
        <span
          class="material-icons close-btn"
          @click="
            () => {
              showActivitySidebar = false;
            }
          "
          >close</span
        >
        <span class="title-label">Recent activity</span>
        <div>
          <i
            class="material-icons filter-btn"
            @click="
              () => {
                showRecentActivityCalendar = !showRecentActivityCalendar;
              }
            "
            >filter_list</i
          >

          <date-picker
            v-if="showRecentActivityCalendar"
            @change="
              () => {
                showRecentActivityCalendar = false;
              }
            "
            v-model="recentActivityDateRange"
            range
            style="position: absolute; right: 20px; top: 50px; z-index: 12"
          >
          </date-picker>
        </div>
      </div>
      <div class="mt-5 recent-activities">
        <div
          v-for="note in recentActivities"
          :key="note.id"
          class="activity-item"
        >
          <div
            class="avatar-circle"
            :style="{ background: strToHslColor(note.email) }"
          >
            <template v-if="note.email">
              {{ note.email[0].toUpperCase() }}
            </template>
            <template v-else>
              <span class="material-icons">person</span>
            </template>
          </div>
          <div>
            <div class="note-signal">
              {{ getTaskSignalDisplayName(note) }}
            </div>
            <div class="note-desc">
              <span class="who-made">{{
                note.email ? note.email + " " : ""
              }}</span>
              {{ note.isAutoNote ? note.note : `added comment.` }}
              <div
                v-if="!note.isAutoNote"
                class="profileAnchorColor--text my-1"
              >
                {{
                  `"${note.note.substring(0, 100)}${
                    note.note.length > 100 ? "..." : ""
                  }"`
                }}
              </div>
            </div>
            <div class="note-date">{{ note.createdAt }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showTaskModal }">
      <div class="modal-background" @click="() => {}"></div>
      <div class="modal-content" style="max-width: 600px">
        <div class="box modal-container">
          <div class="modal-content-root" v-if="selectedTask">
            <div class="d-flex align-center">
              <div class="task-modal-title-text flex-grow-1">
                {{ getTaskSignalDisplayName(selectedTask) }}
              </div>
              <div>
                <i class="material-icons box-dot-menu" id="show-task-modal-dot"
                  >more_horiz</i
                >
                <v-menu :activator="`#show-task-modal-dot`" bottom offset-y>
                  <v-list>
                    <v-list-item
                      link
                      @click="() => deleteTaskAction(selectedTask)"
                    >
                      <v-list-item-title class="mainColor--text">
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="mt-5">
              <table class="summary-table">
                <tbody>
                  <tr>
                    <td>Assignee</td>
                    <td colspan="4">
                      <div class="d-flex align-center">
                        <div
                          class="avatar-circle"
                          :style="{ background: strToHslColor(user.email) }"
                        >
                          <template v-if="user.email">
                            {{ user.email[0].toUpperCase() }}
                          </template>
                          <template v-else>
                            <span class="material-icons">person</span>
                          </template>
                        </div>
                        <span class="ml-2 font-weight-bold">
                          {{ user.email }}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Due date</td>
                    <td colspan="4" class="font-weight-bold">
                      {{ toLocalDateString(selectedTask.dueDate) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td colspan="4">
                      <task-status-readonly
                        :task="selectedTask"
                        @statusChanged="loadAllTasks"
                      >
                      </task-status-readonly>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-5">
              <div class="mb-2 notes-label">Notes</div>
              <v-textarea
                outlined
                hide-details
                color="greenColor"
                rows="3"
                :rules="[(v) => v.length <= 80]"
                label="Add your note here"
                v-model="newNote"
              ></v-textarea>
              <div v-if="isNoteEditMode" class="mt-2">
                <v-btn
                  rounded
                  outlined
                  color="mainColor"
                  class="button-with-icon text-none"
                  @click="updateNote"
                  :disabled="newNote.trim() == ''"
                >
                  <span>Update note</span>
                </v-btn>
                <v-btn
                  rounded
                  outlined
                  color="grayColor"
                  class="button-with-icon text-none ml-2"
                  @click="cancelEditNoteMode"
                >
                  Cancel
                </v-btn>
              </div>
              <div v-else class="mt-2">
                <v-btn
                  rounded
                  outlined
                  color="mainColor"
                  class="button-with-icon text-none"
                  @click="addNote"
                  :disabled="!selectedTask || newNote.trim() == ''"
                >
                  Add note
                </v-btn>
              </div>
            </div>
            <div class="mt-7" v-if="selectedTask">
              <article
                v-for="row in currentTaskUserNotes"
                :key="`user_note_${row.id}`"
                class="user-note-item"
              >
                <div class="note-header d-flex align-center">
                  <div class="flex-grow-1">
                    <div class="who">
                      {{ row.email }}
                    </div>
                    <div class="when mt-1">
                      {{ row.createdAt }}
                      <span v-if="row.isEdited" class="is-italic is-size-7">
                        ( edited: {{ row.editedAt }} )</span
                      >
                    </div>
                  </div>
                  <div>
                    <button
                      class="edit-note-button"
                      aria-label="delete"
                      @click="setEditNoteMode(row)"
                      v-if="!row.isAutoNote"
                    >
                      Edit
                    </button>
                    <button
                      class="delete-note-button"
                      aria-label="delete"
                      @click="deleteNote(row.id)"
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div
                  class="note-body"
                  v-html="row.note.replace(/\n/g, '<br/>')"
                ></div>
              </article>
              <div
                v-for="note in currentTaskAutoNotes"
                :key="`auto_note_${note.id}`"
                class="auto-note-item"
              >
                <div
                  class="avatar-circle"
                  :style="{ background: strToHslColor(note.email) }"
                >
                  <template v-if="note.email">
                    {{ note.email[0].toUpperCase() }}
                  </template>
                  <template v-else>
                    <span class="material-icons">person</span>
                  </template>
                </div>
                <div class="who">
                  {{ note.email ? note.email : "" }}
                </div>
                <div class="note-text">
                  {{ note.note }}
                </div>
                <div class="date">
                  {{ toLocalDateString(note.createdAt) }}
                </div>
              </div>
            </div>

            <div class="text-right mt-5 pr-2">
              <v-btn
                rounded
                color="grayColor"
                class="button-with-icon mainColor--text text-none"
                @click="
                  () => {
                    showTaskModal = false;
                  }
                "
              >
                <span>Close</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="showTaskModal = false"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showConfirmDeleteTaskModal }">
      <div class="modal-background" @click="() => {}"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <div class="is-size-4 font-weight-bold mainColor--text">
              Delete this task?
            </div>
            <div class="mt-5 mainColor--text">
              Deleted task will be kept in your recycle bin for 30 days.
            </div>
            <div class="text-right mt-5">
              <v-btn
                rounded
                outlined
                color="mainColor"
                class="button-with-icon text-none"
                @click="
                  () => {
                    showConfirmDeleteTaskModal = false;
                  }
                "
              >
                <span>Cancel</span>
              </v-btn>
              <v-btn
                rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none ml-2"
                @click="deleteTaskAction"
              >
                <span class="material-icons"></span>
                <span>Delete</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="showConfirmDeleteTaskModal = false"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showDueDateSelectionModal }">
      <div class="modal-background" @click="() => {}"></div>
      <div class="modal-content" style="max-width: 350px">
        <div class="box modal-container">
          <div
            class="modal-content-root"
            v-if="selectedTask"
            style="min-height: 350px"
          >
            <div class="is-size-4 font-weight-bold mainColor--text">
              Select due date
            </div>
            <div class="mainColor--text mt-2 mb-5">
              [ {{ getTaskSignalDisplayName(selectedTask) }} ]
            </div>
            <div class="px-1 text-center">
              <date-picker v-model="selectedDueDate" inline></date-picker>
            </div>
            <div class="text-center mt-5">
              <v-btn
                rounded
                block
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                @click="() => saveDueDateAction()"
              >
                <span class="material-icons">save</span>
                <span>Save due date</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="showDueDateSelectionModal = false"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <div
      class="status-auto-updated-notify"
      v-if="shouldDisplayAutoTaskUpdateNotification"
    >
      <div class="title-text">
        We have updated status on some tasks after POP re-run
      </div>
      <div class="mt-5">
        Status was changed to “In review” on signals where your current count is
        less than your new recommended range after POP re-run
      </div>
      <span
        class="material-icons close-btn"
        @click="
          () => {
            markAsReadNotify();
          }
        "
        >close</span
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";

import TaskStatus from "./TaskStatus.vue";
import TaskStatusReadonly from "./TaskStatusReadonly.vue";
import { toLocalDateString, isFutureDate, strToHslColor } from "@/utils";
import {
  dispNames,
  StopLightBGColorOptions,
} from "@/components/PageRun/ContentBrief/content_brief_constants.js";
import { mixin } from "../common_mixin.js";

export default {
  mixins: [mixin],
  components: {
    DatePicker,
    TaskStatus,
    TaskStatusReadonly,
  },
  data() {
    return {
      currentTab: "all_task",
      allTabs: [
        {
          label: "All tasks",
          key: "all_task",
        },
        {
          label: "My tasks",
          key: "my_task",
        },
        {
          label: "Deleted tasks",
          key: "deleted_task",
        },
      ],

      site: null,
      page: null,
      pageRun: null,

      allTasks: [],
      selectedTask: null,

      newNote: "",
      isNoteEditMode: false,
      selectedNoteId: "",
      showTaskModal: false,
      showConfirmDeleteTaskModal: false,

      showProgressOvertimeCalendar: false,
      progressOverTimeDateRange: [
        moment().subtract(3, "months").toDate(),
        moment().add(1, "days").toDate(),
      ],
      showRecentActivityCalendar: false,
      recentActivityDateRange: [
        moment().subtract(3, "months").toDate(),
        moment().add(1, "days").toDate(),
      ],

      selectedDueDate: new Date(),
      showDueDateSelectionModal: false,
      showActivitySidebar: false,
    };
  },
  computed: {
    siteId() {
      return parseInt(this.$route.params.siteId);
    },
    pageId() {
      return parseInt(this.$route.params.pageId);
    },
    isDemo() {
      return this.$store.getters.isDemoUser;
    },
    user() {
      return this.$store.state.user;
    },
    allSubAccounts() {
      let rlt = JSON.parse(JSON.stringify(this.user.subAccounts));
      rlt.push({
        subAccountEmail: "",
        userId: null,
      });
      return rlt;
    },
    isAgencyUnlimitedUser() {
      return this.$store.getters.isAgencyUnlimitedUser;
    },
    selectedSubAccountRelation() {
      return this.$store.state.selectedSubAccountRelation;
    },
    canAssignTaskToSubaccount() {
      return this.isAgencyUnlimitedUser && !this.selectedSubAccountRelation;
    },
    // checkLists () {
    //   if (this.page) {
    //     return this.page.checkLists
    //   }
    //   return []
    // },
    cleanedContentBrief() {
      if (!this.pageRun) {
        return {};
      }
      let found = this.pageRun.runStrategies.find(
        (item) => item.dashboardType == "contentBrief"
      );

      if (found) {
        return found;
      }

      return {};
    },
    tasks() {
      // tasks which is not archived
      return this.allTasks.filter((item) => !item.isArchived);
    },
    deletedTasks() {
      return this.allTasks.filter((item) => item.isArchived);
    },
    autoTasks() {
      let rlt = this.tasks.filter((item) => item.isAutoChecklist);
      if (this.currentTab == "my_task") {
        rlt = rlt.filter((item) => {
          if (this.selectedSubAccountRelation) {
            return item.assignedTo == this.user.id;
          } else {
            return true;
          }
        });
      }
      return rlt;
    },
    userAddedTasks() {
      let rlt = this.tasks.filter((item) => !item.isAutoChecklist);
      if (this.currentTab == "my_task") {
        rlt = rlt.filter((item) => {
          if (this.selectedSubAccountRelation) {
            return item.assignedTo == this.user.id;
          } else {
            return true;
          }
        });
      }
      return rlt;
    },
    userAddedTasksExceptWatchdogTask() {
      return this.userAddedTasks.filter(
        (item) => item.type != this.ALL_TYPES.WATCHDOG_TASK
      );
    },
    watchdogTasks() {
      return this.userAddedTasks.filter(
        (item) => item.type == this.ALL_TYPES.WATCHDOG_TASK
      );
    },
    currentTaskAutoNotes() {
      if (!this.selectedTask) {
        return [];
      }
      return this.selectedTask.notes.filter((item) => item.isAutoNote);
    },
    currentTaskUserNotes() {
      if (!this.selectedTask) {
        return [];
      }
      return this.selectedTask.notes.filter((item) => !item.isAutoNote);
    },

    allNotes() {
      let allNotes = [];
      this.tasks.map((checklist) => {
        let notes = checklist.notes.map((note) => {
          note["signal"] = checklist["signal"];
          note["type"] = checklist["type"];
          return note;
        });
        allNotes = allNotes.concat(notes);
      });
      // sort by descending order
      return allNotes.sort((a, b) => b.id - a.id).slice(0, 20);
    },
    recentActivities() {
      let [start, end] = this.recentActivityDateRange;
      return this.allNotes.filter((note) => {
        let created = moment(note.createdAt).utc();
        if (start <= created && created <= end) {
          return true;
        }
        return false;
      });
    },
    progressOverTimeData() {
      let labels = [];
      let [start, end] = this.progressOverTimeDateRange;
      let filteredNotes = this.allNotes.filter((note) => {
        let created = moment(note.createdAt).utc();
        if (start <= created && created <= end) {
          return true;
        }
        return false;
      });

      filteredNotes.map((note) => {
        labels.push(note.createdAt.split(" ")[0]);
      });
      labels = labels
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
        .reverse();
      let progressHistory = new Array(labels.length).fill(0);

      filteredNotes.map((note) => {
        let idx = labels.indexOf(note.createdAt.split(" ")[0]);
        progressHistory[idx]++;
      });

      return {
        series: [
          {
            name: "progress",
            data: progressHistory,
          },
        ],
        chartOptions: {
          colors: ["#FF5700"],
          xaxis: {
            categories: labels,
            labels: {
              style: {
                colors: "#CCCCCC",
              },
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
        },
      };
    },
    taskCompletionStatus() {
      let count = [0, 0, 0, 0];
      this.tasks.map((task) => {
        count[task.isCompleted] += 1;
      });
      return {
        series: count,
        chartOptions: {
          labels: ["Not started", "Completed", "Working", "In review"],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: "left",
          },
          colors: ["#ccc", "#25A97A", "#316BFF", "#FF3939"],
        },
      };
    },
    shouldDisplayAutoTaskUpdateNotification() {
      let autoUpdateInfo = this.pageRun?.autoTaskUpdateInfo;
      if (autoUpdateInfo) {
        return (
          autoUpdateInfo["auto_updated_status_list"] &&
          !autoUpdateInfo["is_read"]
        );
      }
      return false;
    },
  },
  methods: {
    toLocalDateString(date) {
      return toLocalDateString(date);
    },
    isFutureDate(date) {
      return isFutureDate(date);
    },
    getSelectedAssignee(task) {
      let rlt = this.allSubAccounts.find((s) => {
        return s.userId == task.assignedTo;
      });
      if (rlt) {
        return rlt;
      } else {
        return this.allSubAccounts.find((s) => s.userId == null);
      }
    },
    getStopLightBGColorForCBSection(task) {
      let current = task.current;
      let targetMin = task.strategyMin;
      let targetMax = task.strategyMax;
      if (current >= targetMin && current <= targetMax) {
        if (task.signal == "titleTotal") {
          // if keyword is not fit yet, then we will not display green for page title section
          let titleData = this.cleanedContentBrief["title"];
          let found = titleData.find((item) => item.term.type == "keyword");
          if (found && found.contentBrief.current < found.contentBrief.target) {
            return StopLightBGColorOptions.yellow.color;
          }
        }
        return StopLightBGColorOptions.green.color;
      }
      if (targetMin > 0 && current == 0) {
        return StopLightBGColorOptions.red.color;
      }
      if (current > targetMax) {
        return StopLightBGColorOptions.purple.color;
      }
      if (current >= targetMin && current <= targetMax) {
        return StopLightBGColorOptions.green.color;
      }
      return StopLightBGColorOptions.yellow.color;
    },
    strToHslColor(str) {
      return strToHslColor(str);
    },
    saveDueDateAction() {
      console.log(this.selectedDueDate);
      if (!this.selectedTask) {
        return;
      }
      let data = {
        id: this.selectedTask.checklistId,
        dueDate: this.selectedDueDate.valueOf(),
      };
      this.$store.dispatch("updateChecklistDueDate", data).then((response) => {
        if (response.data && response.data.status == "SUCCESS") {
          this.loadAllTasks();
          this.showDueDateSelectionModal = false;
        }
      });
    },
    showDueDateSelectionModalAction(task) {
      this.selectedTask = task;
      this.showDueDateSelectionModal = true;
    },
    changeAssigneeForTask(task, subAccountRelation) {
      if (!this.canAssignTaskToSubaccount) {
        return;
      }
      let data = {
        id: task.id,
        subAccountId: subAccountRelation.id,
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("updateChecklistAssignee", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.loadAllTasks();
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
      // updateChecklistAssignee
    },

    openTaskAction(task) {
      this.selectedTask = task;
      this.showTaskModal = true;
    },
    addNote() {
      let data = {
        checklistId: this.selectedTask.checklistId,
        note: this.newNote,
      };
      this.$store.commit("showLoading");
      return this.$store
        .dispatch("createChecklistNote", data)
        .then((response) => {
          if (response.data && response.data.status === "SUCCESS") {
            this.newNote = "";
            // call getting api
            return this.loadAllTasks();
          }
        })
        .catch(() => {
          this.$store.commit("hideLoading");
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    updateNote() {
      let data = {
        id: this.selectedNoteId,
        note: this.newNote,
      };
      this.$store.commit("showLoading");
      return this.$store
        .dispatch("updateChecklistNote", data)
        .then((response) => {
          if (response.data && response.data.status === "SUCCESS") {
            this.cancelEditNoteMode();
            // call getting api
            return this.loadAllTasks();
          }
        })
        .catch(() => {
          this.$store.commit("hideLoading");
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    setEditNoteMode(note) {
      this.isNoteEditMode = true;
      this.selectedNoteId = note.id;
      this.newNote = note.note;
    },
    cancelEditNoteMode(note) {
      this.isNoteEditMode = false;
      this.selectedNoteId = 0;
      this.newNote = "";
    },
    deleteNote(noteId) {
      if (!window.confirm("Are you sure you want to delete this note?")) {
        return;
      }
      this.$store.commit("showLoading");
      return this.$store
        .dispatch("deleteChecklistNote", noteId)
        .then((response) => {
          if (response.data && response.data.status === "SUCCESS") {
            // call getting api
            return this.loadAllTasks();
          }
        })
        .catch(() => {
          this.$store.commit("hideLoading");
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    deleteTaskAction() {
      let data = {
        id: this.selectedTask.checklistId,
        isPermanentDelete: this.currentTab == "deleted_task" ? 1 : 0,
      };
      return this.$store
        .dispatch("archiveOneFromCheckList", data)
        .then((response) => {
          this.showTaskModal = false;
          this.showConfirmDeleteTaskModal = false;
          if (response.data && response.data.status == "SUCCESS") {
            return this.loadAllTasks();
          } else {
            this.$store.commit("hideLoading");
          }
        })
        .catch(() => {
          this.$store.commit("hideLoading");
        });
    },
    restoreTaskAction(task) {
      let data = {
        id: task.checklistId,
      };
      return this.$store
        .dispatch("restoreOneFromChecklist", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            return this.loadAllTasks();
          } else {
            this.$store.commit("hideLoading");
          }
        })
        .catch(() => {
          this.$store.commit("hideLoading");
        });
    },
    downloadTasksExcel() {
      // downloadExcelForChecklist
      let data = {
        pageId: this.page.id,
        pageRunId: this.pageRun.id,
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("downloadTasksForProgressNote", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            let downloadAllUserUrl = `${API_URL}/download/?file=${response.data.url}`;
            let fileName = downloadAllUserUrl.split("/").pop();
            let downloadLink;
            downloadLink = document.createElement("a");
            downloadLink.download = fileName;
            downloadLink.href = downloadAllUserUrl;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            setTimeout(() => {
              document.body.removeChild(downloadLink);
            }, 100);
          } else {
            console.log("error");
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    loadAllTasks() {
      if (!this.pageRun) {
        return;
      }
      this.$store
        .dispatch("getAllTasksForPageRun", {
          pageRunId: this.pageRun.id,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.allTasks = response.data.tasks;
            if (this.selectedTask) {
              this.allTasks.map((t) => {
                if (t.checklistId == this.selectedTask.checklistId) {
                  this.selectedTask = t;
                }
              });
            }
          }
        });
    },
    markAsReadNotify() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("markAsReadForRunAutoStatusUpdate", {
          pageRunId: this.pageRun.id,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.loadPageRunForWorkstation();
          } else {
            this.$store.commit("hideLoading");
          }
        });
    },
    loadPageRunForWorkstation() {
      const data = {
        siteId: this.siteId,
        pageId: this.pageId,
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("getPageRunForWorkStation", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.site = response.data.site;
            this.page = response.data.page;
            this.pageRun = response.data.pageRun;
            this.loadAllTasks();
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
  },
  mounted() {
    this.loadPageRunForWorkstation();
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/Workstation/style.scss";
@media screen and (max-width: 1100px) {
  .progress-and-task-status {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 900px) {
  .main-layout {
    grid-template-columns: 1fr;
  }
}

.show-all-tasks-toggle {
  display: inline-flex;
  gap: 5px;
  padding: 5px;
  & > div {
    padding: 5px 10px;
    border-radius: 50vh;
    border: 1px solid var(--v-mainColor-base);
    color: var(--v-mainColor-base);
    cursor: pointer;
    &.is-active {
      background: #ffeede;
      color: var(--v-profileAnchorColor-base);
      border-color: var(--v-profileAnchorColor-base);
      font-weight: 700;
    }
  }
}

.section-label-thick {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
  .task-count {
    min-width: 25px;
    padding: 3px 5px;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--v-whiteColor-base);
    background: var(--v-profileAnchorColor-base);
    border-radius: 5px;
  }
}
.section-explain {
  color: var(--v-mainColor-base);
  font-size: 0.9rem;
}

.edit-note-button {
  color: var(--v-mainColor-base);
  font-size: 0.8rem;
  font-weight: 700;
  background: var(--v-whiteColor-base);
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.delete-note-button {
  color: var(--v-whiteColor-base);
  font-size: 0.8rem;
  font-weight: 700;
  background: var(--v-mainColor-base);
  padding: 5px 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.no-user-tasks {
  font-size: 0.9rem;
  color: var(--v-mainColor-base);
  font-weight: 700;
}
.auto-checklist-table-area {
  max-height: 400px;
  overflow: auto;
  table {
    background: var(--v-whiteColor-base);
    width: 100%;
    border-collapse: separate;
    td,
    th {
      padding: 10px 10px;
      vertical-align: middle;
      color: var(--v-mainColor-base);
      // display: flex;
      // align-items: center;
    }
    td {
      border-top: 0.5px solid var(--v-grayColor-base);
    }
    thead {
      th {
        border-top: 0.5px solid var(--v-grayColor-base);
        background: var(--v-pink1Color-base);
      }
      th:first-child {
        border-top-left-radius: 5px;
      }
      th:last-child {
        border-top-right-radius: 5px;
      }
    }
    tbody {
      tr:last-child {
        td {
          border-bottom: 0.5px solid var(--v-grayColor-base);
        }
        td:first-child {
          border-bottom-left-radius: 5px;
        }
        td:last-child {
          border-bottom-right-radius: 5px;
        }
      }
    }
    th:first-child,
    td:first-child {
      border-left: 0.5px solid var(--v-grayColor-base);
    }
    th:last-child,
    td:last-child {
      border-right: 0.5px solid var(--v-grayColor-base);
    }
    .add-icon {
      color: var(--v-darkGrayColor-base);
      font-size: 1rem;
      cursor: pointer;
    }
    .status-span {
      min-width: 100px;
      padding: 0;
      position: relative;
    }

    .watchdog-recommend-text {
      max-width: 700px;
      ::v-deep strong,
      li {
        color: var(--v-mainColor-base) !important;
      }
    }
  }
  .task-menu-icon {
    width: 15px;
    height: auto;
    margin-right: 7px;
    cursor: pointer;
  }
}

.task-modal-title-text {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 800;
  color: var(--v-mainColor-base);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.summary-table {
  td {
    font-size: 1.1rem;
    color: var(--v-mainColor-base);
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  tr > td:first-child {
    padding-right: 30px;
  }
}
.notes-label {
  font-size: 1.1rem;
  color: var(--v-mainColor-base);
}
.user-note-item {
  border-radius: 7px;
  border: 1px solid var(--v-grayColor-base);
  overflow: hidden;
  margin-top: 15px;
  .note-header {
    background: var(--v-lightYellowColor-base);
    padding: 10px 10px;
    border-bottom: 1px solid var(--v-grayColor-base);
    .who {
      color: var(--v-mainColor-base);
      font-weight: 700;
      font-size: 0.9rem;
    }
    .when {
      font-size: 0.7rem;
      color: var(--v-mainColor-base);
    }
  }
  .note-body {
    padding: 15px 10px;
    color: var(--v-mainColor-base);
    font-size: 0.95rem;
  }
}

.avatar-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 0.85rem;
  color: var(--v-whiteColor-base);
  display: flex;
  align-items: center;
  justify-content: center;
}
.auto-note-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 25px;
  .who {
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--v-mainColor-base);
  }
  .note-text {
    color: var(--v-mainColor-base);
  }
  .date {
    position: relative;
    padding-left: 10px;
    &::before {
      position: absolute;
      left: 0px;
      top: 50%;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background: var(--v-mainColor-base);
      transform: translateY(-50%);
      content: "";
    }
    color: var(--v-mainColor-base);
  }
}
.status-auto-updated-notify {
  position: fixed;
  right: 30px;
  bottom: 20px;
  width: 400px;
  max-width: 90vw;
  border-radius: 6px;
  // border: 1px solid var(--v-grayColor-base);
  box-shadow: 0px 6px 27px 0px rgba(0, 0, 0, 0.17);
  background: var(--v-whiteColor-base);
  color: var(--v-mainColor-base);
  padding: 30px 15px 20px 30px;
  overflow: hidden;
  .title-text {
    font-weight: 700;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    background: var(--v-profileAnchorColor-base);
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.35rem;
    cursor: pointer;
  }
}
</style>
