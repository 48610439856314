<template>
  <div class="pb-15">
    <div class="card-big-title mb-8">Silo</div>
    <div v-if="isEmpty" class="silo-container-empty mb-5">
      <div class="silo-structure-empty-container">
        <img src="/static/silo-structure.svg" class="silo-structure" />
      </div>
      <div class="silo-text-empty-container">
        <div class="step-title">You haven’t created your content silo yet</div>
        <div class="title-text">
          Please add some keywords from Keyword Insight or Keyword Wizard tabs
          to create content silo for this page. You will be redirected to
          Keyword Insight tab by default.
        </div>
        <div class="gap1">
          <v-btn
            @click="gotoInsight"
            rounded
            color="anchor1Color"
            class="text-none whiteColor--text font16 padding8 height38"
            tag="a"
          >
            Go to Keyword Insight
          </v-btn>
          <v-btn
            rounded
            small
            outlined
            color="mainColor"
            class="mainColor--text text-none font16 padding8 height38"
          >
            Launch tutorial
          </v-btn>
        </div>
      </div>
    </div>

    <div v-if="!isEmpty && !user.settings.hideSiloTutorialBox" class="container-launch width82 px-4 py-5 mb-10">
      <div class="flex flex-column justify-space-between width704">
        <div class="font12">GUIDE: CONTENT SILOS</div>
        <div class="font20">Explore the power of content silos</div>
        <div class="font1rem">
          Content silos help your site rank by leveraging its own authority.
          Group similar supporting <br />
          pages and point their links to your main target page using POP’s Silo
          tool.
        </div>
        <div>
          <v-btn
            rounded
            color="anchor1Color"
            class="text-none whiteColor--text font15 padding8 height38"
            tag="a"
          >
            Launch tutorial
          </v-btn>
        </div>
      </div>
      <div>
        <img src="/static/silo-structure.svg" class="silo-structure" />
      </div>
      <div class="cross-container ml-3">
        <img src="/static/cross.svg" class="cross cursor-pointer" @click="closeTutorialBox" />
      </div>
      <div></div>
    </div>

    <section v-if="!isEmpty">
      <div v-if="isWhiteGloveUser" class="sites-or-pages-container silos-container silos-container-white-glove mt-6">
        <div>
          <div class="white-glove-order-label mainColor--text mb-6">
            <i class="material-icons" style="color:#8439FF;">circle</i>
            <div class="type">New</div>
            <div class="count">{{ notOrderedSilos.length }}</div>
          </div>
          <silo-card
            v-for="(silo, idx) in notOrderedSilos"
            :key="idx"
            :silo="silo"
            :refreshData="() => fetchSilos()"
          />
        </div>
        <div>
          <div class="white-glove-order-label mainColor--text mb-6">
            <i class="material-icons" style="color:#F69C40;">circle</i>
            <div class="type">Ordered</div>
            <div class="count">{{ orderedPendingSilos.length }}</div>
          </div>
          <silo-card
            v-for="(silo, idx) in orderedPendingSilos"
            :key="idx"
            :silo="silo"
          />
        </div>
        <div>
          <div class="white-glove-order-label mainColor--text mb-6">
            <i class="material-icons" style="color:#EF34A4;">circle</i>
            <div class="type">In progress</div>
            <div class="count">{{ orderedInProgressSilos.length }}</div>
          </div>
          <silo-card
            v-for="(silo, idx) in orderedInProgressSilos"
            :key="idx"
            :silo="silo"
            :refreshData="() => fetchSilos()"
          />
        </div>
        <div>
          <div class="white-glove-order-label mainColor--text mb-6">
            <i class="material-icons" style="color:#1AB26A;">circle</i>
            <div class="type">Completed</div>
            <div class="count">{{ orderedCompletedSilos.length }}</div>
          </div>
          <silo-card
            v-for="(silo, idx) in orderedCompletedSilos"
            :key="idx"
            :silo="silo"
          />
        </div>
      </div>
      <div v-else class="sites-or-pages-container silos-container mt-6">
        <silo-card
          v-for="(silo, idx) in silos"
          :key="idx"
          :silo="silo"
          :refreshData="() => fetchSilos()"
        />
      </div>
    </section>

  </div>
</template>

<script>
import { getPureServicePkgName } from '@/utils';
import SiloCard from './SiloCard.vue';
import {
  whiteGlovePlans,
} from "@/components/plans_constants"

export default {
  components: { SiloCard },
  props: ['campaignId'],
  data() {
    return {
      silos: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isEmpty() {
      return !this.silos?.length;
    },
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId)) || {};
    },
    siteId () {
      return parseInt(this.$route.params.siteId);
    },
    orderedSilos () {
      return this.silos.filter((silo) => ['paid', 'to_be_paid_by_invoice'].includes(silo?.content_report_order?.order_status));
    },
    orderedPendingSilos () {
      return this.orderedSilos.filter((silo) => silo?.content_report_order?.fulfillement_status === 'pending');
    },
    orderedInProgressSilos () {
      return this.orderedSilos.filter((silo) => silo?.content_report_order?.fulfillement_status === 'in_progress');
    },
    orderedCompletedSilos () {
      return this.orderedSilos.filter((silo) => silo?.content_report_order?.fulfillement_status === 'completed');
    },
    notOrderedSilos () {
      return this.silos.filter((silo) => !this.orderedSilos.includes(silo));
    },
  },
  methods: {
    gotoInsight() {
      const { siteId, campaignId } = this.$route.params;
      this.$router.push(
        `/sites/${siteId}/campaign/${campaignId}/keyword-insight`,
      );
    },
    fetchSilos() {
      this.$store.commit('showLoading');
      this.$store
        .dispatch('getSilos', { campaignId: this.$route.params.campaignId, paginate: 0 })
        .then((response) => {
          this.silos = response.data.data;
        })
        .finally(() => {
          this.$store.commit('hideLoading');
        });
    },
    closeTutorialBox() {
      const updatedSettings = {
        ...this.user.settings,
        hideSiloTutorialBox: true
      }

      // eager update of settings state
      this.$store.commit('setUserSettings', updatedSettings)

      // persist in backend
      this.$store.dispatch('updateUserSettings', updatedSettings)
    }
  },
  mounted() {
    this.fetchSilos();
  },
};

</script>

<style lang="scss" scoped>
@import './silo.scss';

.white-glove-order-label {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  .type {
    font-size: 16px;
    font-weight: 700;
    line-height: 13.86px;
  }

  .count {
    padding: 3px 10px;
    background: #fff;
    border: 1px solid #c7c7c7;
    border-radius: 12px;
    font-weight: 700;
  }
}
</style>
