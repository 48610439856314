<template>
<section class="section main-section" v-if="ready">
  <div class="container is-fluid">
   
    <div v-if="user.isAdmin">
      <div class="columns">
        <div class="column"><h2 class='title'>New Signups Per Day (Total {{ totalNewUsers }})</h2></div>

        <div class="column is-3">
          <div class="field">
            <div class="control">
              <datepicker v-model="newSignUpsStartDate" @selected="onNewUsersDaysChange" input-class="input" placeholder="Start Date"></datepicker>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <datepicker v-model="newSignUpsEndDate" @selected="onNewUsersDaysChange" input-class="input" placeholder="End Date"></datepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-two-thirds">
          <multi-time-series ref="newUsersChart" :labels='newUserDates' :datasets='newUsers'/>
        </div>
        <div class="column">
          <pie-chart ref="newUsersPieChart" :labels="['Trial', 'Credits', 'Starter', 'Basic', 'Premium', 'Unlimited']" :values="[trialNewUsers, creditNewUsers, productANewUsers, productBNewUsers, productCNewUsers, productDNewUsers]"></pie-chart>
        </div>
      </div>
    </div>

    <div v-if="user.isAdmin">
      <div class="columns">
        <div class="column"><h2 class='title'>Runs Per Day (Total {{ totalRuns }})</h2></div>
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <datepicker v-model="runsPerDayStartDate" @selected="onRunsPerDaysChange" input-class="input" placeholder="Start Date"></datepicker>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="field">
            <div class="control">
              <datepicker v-model="runsPerDayEndDate" @selected="onRunsPerDaysChange" input-class="input" placeholder="End Date"></datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
        <multi-time-series ref="runsChart" :labels='runs.labels' :datasets='[runs.total, runs.failed, runs.success, runs.starter, runs.basic, runs.premium, runs.unlimited, runs.credits]'/>
        </div>
        <div class="column">
          <pie-chart ref="runsPieChart" :labels="['Success', 'Failed']" :values="[passedRuns, failedRuns]"></pie-chart>
        </div>
      </div>
    </div>

    <div v-if="user.isAdmin">
      <div class="columns">
        <div class="column"><h2 class='title'>Runs By Location</h2></div>
        <div class="column is-3">

        </div>
        <div class="column is-3">

        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
        <multi-time-series ref="proxyRunsChart" :labels='proxyServerUsePerDayDates' :datasets='proxyServerUsePerDay'/>
        </div>
        <div class="column">
          <pie-chart ref="proxyRunsPieChart" :labels="proxyServerLocations" :values="proxyServerLocationCnts"></pie-chart>
        </div>
      </div>
    </div>

    <div class="columns" v-if="user.isAdmin">
      <div class="column">
        <h2 class='title'>Users By Account Type (Total {{ totalUsersByAccount.reduce((acc, x) => acc + x.count, 0) }})</h2>
        <simple-bar-chart :displayLegend="true" dataSeriesLabel="Total Users" :labels="totalUsersByAccount.map(x => serviceLabel(x.service))" :dataSeries="totalUsersByAccount.map(x => x.count)"/>
      </div>
    </div>

  </div>
</section>
</template>

<script>
import TimeSeries from '@/components/Admin/TimeSeries.vue'
import MultiTimeSeries from '@/components/MultiTimeSeries.vue'
import PieChart from '@/components/Admin/PieChart.vue'
import SimpleBarChart from '@/components/SimpleBarChart.vue'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import Datepicker from 'vuejs-datepicker'
import { toLocalDateString, toLocalDate } from '@/utils'
export default {
  mixins: [ServiceMixin],
  components: { TimeSeries, PieChart, SimpleBarChart, MultiTimeSeries, Datepicker },
  data () {
    return {
      ready: false,

      // new users per day
      newSignUpsStartDate: null,
      newSignUpsEndDate: null,
      newUsersLastXDays: 7,
      newUserDates: [],
      newUsers: [],
      creditNewUsers: 0,
      trialNewUsers: 0,
      productANewUsers: 0,
      productBNewUsers: 0,
      productCNewUsers: 0,
      productDNewUsers: 0,
      totalNewUsers: 0,

      proxyServerLocations: [],
      proxyServerLocationCnts: [],
      proxyServerUsePerDay: [],
      proxyServerUsePerDayDates: [],

      // runs by day
      runsLastXDays: 7,
      runsPerDayStartDate: null,
      runsPerDayEndDate: null,
      runs: [],
      failedRuns: 0,
      passedRuns: 0,
      totalRuns: 0,
      starterRuns: 0,
      basicRuns: 0,
      premiumRuns: 0,
      unlimitedRuns: 0,
      creditRuns: 0,

      // total users
      totalUsersByAccount: [],

      chartColors: {
        blue: "rgb(54, 162, 235)",
        green: "rgb(75, 192, 192)",
        grey: "rgb(201, 203, 207)",
        orange: "rgb(255, 159, 64)",
        purple: "rgb(153, 102, 255)",
        red: "rgb(255, 99, 132)",
        yellow: "rgb(255, 205, 86)",
        black: "rgb(0, 0, 0)",
        navy: "rgb(0, 0, 128)"
      }
    }
  },
  computed: {
    days () {
      let options = [1, 7, 14, 30, 60, 90, 'all']
      return options
    },
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    sum(arr) {
      return arr.reduce((x, acc) => acc + x)
    },
    toLocalDateString (dateStr) {
      return toLocalDateString(dateStr)
    },
    onNewUsersDaysChange (event) {
      this.$store.commit('showLoading')
      this.$nextTick(() => {
        this.getNewUserAccounts()
          .then(() => {
            this.$refs.newUsersChart.update(this.newUserDates, this.newUsers)
            this.$refs.newUsersPieChart.update(['Trial', 'Credits', 'Starter', 'Basic', 'Premium', 'Unlimited'], [this.trialNewUsers, this.creditNewUsers, this.productANewUsers, this.productBNewUsers, this.productCNewUsers, this.productDNewUsers])
            return 1
          })
          .then(() => this.$store.commit('hideLoading'))
      })
    },
    onRunsPerDaysChange (event) {
      this.$store.commit('showLoading')
      this.$nextTick(() => {
        this.getRunsPerDay()
          .then(() => {
            this.$refs.runsChart.update(this.runs.labels, [this.runs.total, this.runs.failed, this.runs.success, this.runs.starter, this.runs.basic, this.runs.premium, this.runs.unlimited, this.runs.credits])
            this.$refs.runsPieChart.update(['Success', 'Failed'], [this.passedRuns, this.failedRuns])

            this.$refs.proxyRunsChart.update(this.proxyServerUsePerDayDates, this.proxyServerUsePerDay)
            this.$refs.proxyRunsPieChart.update(this.proxyServerLocations, this.proxyServerLocationCnts)
            return 1
          })
          .then(() => this.$store.commit('hideLoading'))
      })
    },
    getNewUserAccounts () {
      return this.$store.dispatch('fetchNewUserStats', {
          startDate: this.newSignUpsStartDate ? this.newSignUpsStartDate.valueOf() : '',
          endDate: this.newSignUpsEndDate ? this.newSignUpsEndDate.valueOf() : ''
        })
        .then((response) => {
          const { users, dates, total, trials, credits, productA, productB, productC, productD } = response.data
          this.newUserDates = dates
          this.newUsers = [{ label: 'New users', backgroundColor: this.chartColors.blue, borderColor: this.chartColors.blue, data: users, fill: false}]
          this.creditNewUsers = credits
          this.trialNewUsers = trials
          this.productANewUsers = productA
          this.productBNewUsers = productB
          this.productCNewUsers = productC
          this.productDNewUsers = productD
          this.totalNewUsers = total
          return 1
        })
    },
    getRunsPerDay () {
      return this.$store.dispatch('fetchRunsPerDay', {
          startDate: this.runsPerDayStartDate ? this.runsPerDayStartDate.valueOf() : '',
          endDate: this.runsPerDayEndDate ? this.runsPerDayEndDate.valueOf() : ''
        })
        .then((response) => {
          const { runs, failed, passed, total, starter, basic, premium, unlimited, credits, proxyRuns, proxyLocations } = response.data
          this.runs = runs.reduce((data, day) => {
            data.labels.push(day.date)
            data.total.data.push(day.total)
            data.success.data.push(day.success)
            data.failed.data.push(day.fail)
            data.starter.data.push(day.starter)
            data.basic.data.push(day.basic)
            data.premium.data.push(day.premium)
            data.unlimited.data.push(day.unlimited)
            data.credits.data.push(day.credits)
            return data
          }, {
            labels: [],
            total: {
              label: 'Total',
              backgroundColor: this.chartColors.blue,
              borderColor: this.chartColors.blue,
              data: [],
              fill: false
            },
            failed: {
              label: 'Failed',
              backgroundColor: this.chartColors.red,
              borderColor: this.chartColors.red,
              data: [],
              fill: false
            },
            success: {
              label: 'Successful',
              backgroundColor: this.chartColors.green,
              borderColor: this.chartColors.green,
              data: [],
              fill: false
            },
            starter: {
              label: 'Starter',
              backgroundColor: this.chartColors.gray,
              borderColor: this.chartColors.gray,
              data: [],
              fill: false
            },
            basic: {
              label: 'Basic',
              backgroundColor: this.chartColors.orange,
              borderColor: this.chartColors.orange,
              data: [],
              fill: false
            },
            premium: {
              label: 'Premium',
              backgroundColor: this.chartColors.purple,
              borderColor: this.chartColors.purple,
              data: [],
              fill: false
            },
            unlimited: {
              label: 'Unlimited',
              backgroundColor: this.chartColors.yellow,
              borderColor: this.chartColors.yellow,
              data: [],
              fill: false
            },
            credits: {
              label: 'Credits',
              backgroundColor: this.chartColors.black,
              borderColor: this.chartColors.black,
              data: [],
              fill: false
            }
          })
          this.failedRuns = failed
          this.passedRuns = passed
          this.totalRuns = total
          this.starterRuns = starter
          this.basicRuns = basic
          this.premiumRuns = premium
          this.unlimitedRuns = unlimited
          this.creditRuns = credits

          const proxyServerData = proxyRuns.reduce((data, day) => {
            data.labels.push(day.date)
            data.us.data.push(day.US)
            data.eu.data.push(day.EU)
            data.uk.data.push(day.UK)
            data.au.data.push(day.Australia)
            data.sa.data.push(day['South America'])
            data.asia.data.push(day.Asia)
            data.canada.data.push(day.Canada)
            data.extension_used.data.push(day.extension_used)
            return data
          }, {
            labels: [],
            us: {
              label: 'US',
              backgroundColor: this.chartColors.blue,
              borderColor: this.chartColors.blue,
              data: [],
              fill: false
            },
            eu: {
              label: 'EU',
              backgroundColor: this.chartColors.green,
              borderColor: this.chartColors.green,
              data: [],
              fill: false
            },
            uk: {
              label: 'UK',
              backgroundColor: this.chartColors.orange,
              borderColor: this.chartColors.orange,
              data: [],
              fill: false
            },
            au: {
              label: 'AU',
              backgroundColor: this.chartColors.red,
              borderColor: this.chartColors.red,
              data: [],
              fill: false
            },
            sa: {
              label: 'S America',
              backgroundColor: this.chartColors.purple,
              borderColor: this.chartColors.purple,
              data: [],
              fill: false
            },
            asia: {
              label: 'Asia',
              backgroundColor: this.chartColors.yellow,
              borderColor: this.chartColors.yellow,
              data: [],
              fill: false
            },
            canada: {
              label: 'Canada',
              backgroundColor: this.chartColors.black,
              borderColor: this.chartColors.black,
              data: [],
              fill: false
            },
            extension_used: {
              label: 'Extension',
              backgroundColor: this.chartColors.navy,
              borderColor: this.chartColors.navy,
              data: [],
              fill: false
            }
          })

          this.proxyServerLocations = ['US', 'EU', 'UK', 'AU', 'S America', 'Asia', 'Canada', 'Extension']
          this.proxyServerLocationCnts = [
            this.sum(proxyServerData.us.data),
            this.sum(proxyServerData.eu.data),
            this.sum(proxyServerData.uk.data),
            this.sum(proxyServerData.au.data),
            this.sum(proxyServerData.sa.data),
            this.sum(proxyServerData.asia.data),
            this.sum(proxyServerData.canada.data),
            this.sum(proxyServerData.extension_used.data)
          ]
          this.proxyServerUsePerDay = [proxyServerData.us, proxyServerData.eu, proxyServerData.uk, proxyServerData.au, proxyServerData.sa, proxyServerData.asia, proxyServerData.canada, proxyServerData.extension_used]
          this.proxyServerUsePerDayDates = proxyServerData.labels

          return 1
        })
    },
    getTotalUsers () {
      return this.$store.dispatch('fetchTotalUsersForAdmin')
        .then((response) => {
          this.totalUsersByAccount = response.data 
          return 1
        })
    }
  },
  beforeMount () {
    this.$store.commit('showLoading')
    let now = new Date()
    this.newSignUpsStartDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), 1)
    this.runsPerDayStartDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), 1)
    return this.getNewUserAccounts()
      .then(() => this.getRunsPerDay())
      .then(() => this.getTotalUsers())
      .then(() => {
        this.ready = true
        this.$store.commit('hideLoading')
      })
      .catch((err) => {
        this.$store.commit('hideLoading')
        console.log(err)
      })
  }
}
</script>

<style scoped>

</style>