<template>
<div>
<section>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="container mt20">
        <div class="columns">
          <div class="column is-12-tablet is-6-desktop is-6-widescreen">
            <!-- <div class="custom-notification">
              <div class="icon-wrapper" :style="{ 'background': '#ff9b00' }">
                <i class="material-icons n-icon" :style="{ 'color': '#FFFFFF' }">{{'notification_important'}}</i>
              </div>
              <div class="n-body">
                <b class="n-title">Alert</b>
                <div class="n-content" v-html="'We are experiencing an issue with logging in. We are working on this issue. We apologize for any inconvenience.'"></div>
              </div>
            </div> -->
            
            <div v-if="notifications.length > 0" class="notifications-area mb10">
              <div>
                <div style="width: 100%;">
                  <div v-if="showingNotifications">
                    <div class="custom-notification" v-for="n in notifications" :key="n.id">
                      <div class="icon-wrapper" :style="{ 'background': n.color ? n.color : '#ff9b00' }">
                        <i class="material-icons n-icon" :style="{ 'color': '#FFFFFF' }">{{'notification_important'}}</i>
                      </div>
                      <div class="n-body">
                        <b class="n-title">{{ n.title }}</b>
                        <div class="n-content" v-html="n.message"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form @submit.prevent="authenticate" class="px-lg-15 px-md-12">
              <div class="field has-text-centered">
                <div class="is-size-3 has-text-weight-bold mainColor--text">Log in</div>
                <div class="mt10 darkGrayColor--text">to get access to your projects.</div>
              </div>
              <div class="field has-text-centered mb5">
                <p v-if="globalErrorMsg" class="has-text-danger">{{ globalErrorMsg }}</p>
              </div>
              <div class="field">
                <label class="label mainColor--text">Email</label>
                <div class="control">
                  <input type="email" placeholder="example@mail.com" class='input is-large' v-model="email" required>
                </div>
              </div>
              <div class="field mt20">
                <div class="flex justify-space-between">
                  <label class="label mainColor--text">Password</label>
                  <div>
                    <router-link to="/forgotpassword" class="profileAnchorColor--text font-weight-bold">Forgot your password?</router-link>
                  </div>
                </div>
                <div class="control has-icons-right">
                  <input :type="showPassword ? 'text': 'password'" placeholder="********" class='input is-large' v-model="password" required @keyup.enter="authenticate">
                  <span class="icon is-small is-right pointer" style="pointer-events: initial;" @click="showPassword = !showPassword">
                    <i v-if="!showPassword" class='fa fa-eye mainColor--text'></i>
                    <i v-else class='fa fa-eye-slash mainColor--text'></i>
                  </span>
                </div>
              </div>

              <div class="field button-field">
                <v-btn
                  rounded block large
                  color="profileAnchorColor"
                  class="button-with-icon whiteColor--text text-none mt30"
                  @click.prevent="authenticate">Login</v-btn>
              </div>
              <div v-if="errorMsg" class="has-text-danger">{{ errorMsg }}</div>

              <div class="field">
                <p class='mainColor--text body-font text-center mt25'>Don't have an account?
                  <a href='/#/register?product=basic' class='profileAnchorColor--text font-weight-bold'>Try now</a>
                </p>
              </div>
              <br>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal" :class="{'is-active': showGlobalErrorMessage}">
  <div class="modal-background" @click="showGlobalErrorMessage = false"></div>
  <div class="modal-content">
    <div class="box modal-container">
      <p class="subtitle-font mainColor--text">{{ globalErrorMsg }}</p>
      <div class="field has-addons">
        <div class="control">
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            @click="showGlobalErrorMessage = false">
            Ok
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal" :class="{'is-active': show2FaModal}">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box modal-container" style="overflow: hidden;">
      <div>
        <div class="mainColor--text font-weight-bold is-size-4 mb20">2FA Authentication</div>
        <div class="body-font mainColor--text is-size-6">
          POP sent you login code to your email. <br/>
          Please check your inbox and input login code below.
          
          <div class="control mt20">
            <input type="text" class="input" placeholder="Input your login code." v-model="loginCode"/>
          </div>

          <p class="has-text-danger">{{ loginCodeError }}</p>          
        </div>
        <div class="control flex mt20">
          <div class="flex-grow-1">
          </div>
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            :disabled="loginCode.trim()==''" @click="authenticate">
            Log in
        </v-btn>
        </div>
      </div>
    </div>
    <button class="custom-modal-close-btn" aria-label="close" @click="show2FaModal = false"><i class="material-icons">close</i></button>
  </div>
</div>

<div class="modal" :class="{'is-active': showBlockedByAdminModal}">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box modal-container">
      <div class="modal-content-root">
        <div class="title-font is-size-4 mainColor--text">
          Account Suspended
        </div>
        <div class="subtitle-font mainColor--text mt-5">
          Your account has been temporarily suspended due to suspicious activity. <br />
          Please contact support to reactivate your account.
        </div>
        <div class="d-flex justify-end">
          <v-btn rounded
            color="profileAnchorColor"
            class="text-none whiteColor--text">
            I got it
          </v-btn>
        </div>
      </div>
    </div>
    <button class="custom-modal-close-btn" aria-label="close" @click="showBlockedByAdminModal = false"><i class="material-icons">close</i></button>
  </div>
</div>
</div>
</template>

<script>

import { segmentTrack, sendUserInfoToAppcues } from '@/utils'
import { DEMO_SESSION_KEYS } from "@/components/PageRun/constants";

export default {
  components: {  },
  data () {
    return {
      email: '',
      password: '',
      showPassword: false,

      errorMsg: '',
      showingNotifications: true,
      notifications: [],
      showGlobalErrorMessage: false,
      loginCode: '',
      loginCodeError: '',
      show2FaModal: false,
      showBlockedByAdminModal: false,
    }
  },
  methods: {
    authenticate () {
      this.errorMsg = ''
      this.loginCodeError = ''
      this.$store.commit('showLoading')

      window.profitWellUserEmail = this.email
      if (window.profitwell) {
        window.profitwell('user_email', window.profitWellUserEmail)
      }

      let data = {
        email: this.email,
        password: this.password,
      }
      if (this.loginCode) {
        data['loginCode'] = this.loginCode
      }
      return this.$store.dispatch('login', data)
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {

            if (response.data.action == 'LOGIN_CODE_NEEDED') {
              this.show2FaModal = true;              
              this.$store.commit('hideLoading')
              return;              
            }

            this.$store.commit('setCurrentSubAccount', '')
            this.$store.commit('setJwtToken', { jwt: response.data.token })
            this.$store.commit('setUser', response.data.user)
            this.$store.commit('hideLoading')
            this.$store.dispatch('fetchGoogleSearchLocations')
            segmentTrack('login', {
              email: response.data.user.email,
              userID: response.data.user.id
            })
            sendUserInfoToAppcues(response.data.user);
            console.log(this.$route.query.redirect);
            if (this.$route.query.redirect) {
              return this.$router.push(this.$route.query.redirect)
            } else {
              return this.$router.push('/sites')
            }
          } else {
            if (response.data && response.data.action == 'INVALID_LOGIN_CODE') {
              this.loginCodeError = response.data.msg;
              return;
            } else if (response.data && response.data.action == 'ADMIN_BLOCK') {
              this.showBlockedByAdminModal = true;
              return;
            }
            this.errorMsg = 'Error authenticating. Please verify credentials or register for a free trial.'
            return 1
          }
        })
        .catch(error => {
          console.log("Error authenticating", error)
          this.errorMsg = 'There was an error logging you in.  Please check that your password is correct.  If you haven’t created an account yet, please register.  If you have created an account but haven’t confirmed your email, please look for a registration confirmation email in your inbox and click the confirmation link.';
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    forgotPassword () {
      if (this.email !== '') {
        this.$store.dispatch('resetPassword', { email: this.email })
          .then(() => {
            this.errorMsg = "Check email for link to reset password"
          })
          .catch(error => {
            this.errorMsg = "Email not associated with a user. Verify email entered correctly or register for account"
            console.log("Error changing password", error)
          })
      }
    },
    
  },
  computed: {
    globalErrorMsg () {
      return this.$store.state.globalErrorMsg
    },
    loginOrRegisterView () {
      return this.$store.getters.isLoginOrRegister(this.$route.name)
    }
  },
  beforeMount() {
    if (this.globalErrorMsg) {
      this.showGlobalErrorMessage = true
    }
    console.log(this.globalErrorMsg)
  },
  mounted () {
    Object.values(DEMO_SESSION_KEYS).forEach(key => sessionStorage.removeItem(key))
    this.$store.commit('clearStore')
    this.$store.commit('showLoading')
    this.$store.dispatch('getLoginPageNotifications')
    .then(response => {
      if (response.data && response.data.status === 'SUCCESS') {
        this.notifications = response.data.notifications
      }
    })
    .finally(() => {
      this.$store.commit('hideLoading')
    })
  }
}
</script>

<style scoped>

.hero {
  background-image: url('/static/login-right-bg.png');
  background-position: right 50%;
  background-size: 40% auto;
}

.box {
  padding: 30px 50px;
}
.button-field {
  display: flex;
  align-items: center;
}
.button-field button {
  margin-right: 20px;
}
.button-field p {
  text-align: left !important;
}

.wordpress-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

</style>
