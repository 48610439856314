<template>
<div class="word-count-color-chart">
  <div class="label-layer">
    <div class="point-label" :style="{ 'left': `${minWordCountPercentage}%`, 'color': colorForPercent(minWordCountPercentage) }">MIN</div>
    <div class="point-label" :style="{ 'left': `${maxWordCountPercentage}%`, 'color': colorForPercent(maxWordCountPercentage) }">MAX</div>
    <div class="point-label" :style="{ 'left': `${currentWordCountPercentage}%`, 'color': colorForPercent(currentWordCountPercentage) }" v-if="!pageNotBuilt">YOU</div>
    <div class="point-label" :style="{ 'left': `${50}%`, 'color': colorForPercent(50) }">TARGET</div>
  </div>
  <div class="bar-layer">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>

    <div class="point under-point" :style="{ 'left': `${minWordCountPercentage}%` }"></div>
    <div class="point-tooltip under-point-tooltip" :style="{ 'left': `${minWordCountPercentage}%`}">
      The lowest competitor word count: {{ dispMinWordCount }}
    </div>
    <div class="point over-point" :style="{ 'left': `${maxWordCountPercentage}%` }"></div>
    <div class="point-tooltip over-point-tooltip" :style="{ 'left': `${maxWordCountPercentage}%`}">
      The highest competitor word count: {{ dispMaxWordCount }}
    </div>

    <div class="point target-point" :style="{ 'left': `${50}%` }"></div>
    <div class="point-tooltip target-point-tooltip" :style="{ 'left': `${50}%`}">
      {{ !showWithFeaturedCompetitors ? `Target word count based on all your competitors:` : `Target word count based on only your focus competitors:` }} {{ wordCountTarget }}
      
    </div>
    

    <div class="point me-point" :style="{ 'left': `${currentWordCountPercentage}%` } "  v-if="!pageNotBuilt"></div>
    <div class="point-tooltip me-point-tooltip" :style="{ 'left': `${currentWordCountPercentage}%`}"  v-if="!pageNotBuilt">
      Your current word count: {{ currentWordCount }}
    </div>
  </div>
  <div class="label-layer">
    <!-- <div>UNDER OPTIMIZED</div> -->
    <!-- <div>OVER OPTIMIZED</div> -->
    <div class="point-label" :style="{ 'left': `${50}%`, 'color': colorForPercent(50) }">{{ wordCountTarget }}</div>
    <div class="point-label" :style="{ 'left': `${minWordCountPercentage}%`, 'color': colorForPercent(minWordCountPercentage) }">{{ dispMinWordCount }}</div>
    <div class="point-label" :style="{ 'left': `${maxWordCountPercentage}%`, 'color': colorForPercent(maxWordCountPercentage) }">{{ dispMaxWordCount }}</div>
    <div class="point-label" :style="{ 'left': `${currentWordCountPercentage}%`, 'color': colorForPercent(currentWordCountPercentage) }"  v-if="!pageNotBuilt">{{ currentWordCount }}</div>
  </div>
</div>

</template>


<script>

let signalCount = 29
let colorBarCount = 9
export default {
  props: [
    'showWithFeaturedCompetitors',

    'pageNotBuilt',
    'currentWordCount',
    'minWordCount', 'maxWordCount', 'targetWordCount',
    'featuredMinWordCount', 'featuredMaxWordCount', 'featuredTargetWordCount'
  ],
  data () {
    return {
      pushVal: 50 / 11,            
    }
  },
  computed: {
    axisMax () {
      if (this.showWithFeaturedCompetitors) {
        return this.featuredTargetWordCount * 2
      } else {
        return this.targetWordCount * 2
      }
    },
    dispMinWordCount () {
      if (this.showWithFeaturedCompetitors) {
        return this.featuredMinWordCount
      } else {
        return this.minWordCount
      }
    },
    dispMaxWordCount () {
      if (this.showWithFeaturedCompetitors) {
        return this.featuredMaxWordCount
      } else {
        return this.maxWordCount
      }
    },
    wordCountTarget () {
      if (this.showWithFeaturedCompetitors) {
        return this.featuredTargetWordCount
      } else {
        return this.targetWordCount
      }
    },
    minWordCountPercentage () {
      if (this.showWithFeaturedCompetitors) {
        if (this.featuredMinWordCount > this.axisMax) {
          return 100
        }
        return this.featuredMinWordCount / this.axisMax * 100 + this.getPushVal(this.featuredMinWordCount)
      } else {
        if (this.minWordCount > this.axisMax) {
          return 100
        }
        return this.minWordCount / this.axisMax * 100 + this.getPushVal(this.minWordCount)
      }
    },
    maxWordCountPercentage () {
      if (this.showWithFeaturedCompetitors) {
        if (this.featuredMaxWordCount > this.axisMax) {
          return 100
        }
        return this.featuredMaxWordCount / this.axisMax * 100 + this.getPushVal(this.featuredMaxWordCount)
      } else {
        if (this.maxWordCount > this.axisMax) {
          return 100
        }
        return this.maxWordCount / this.axisMax * 100 + this.getPushVal(this.maxWordCount)
      }
    },
    currentWordCountPercentage () {
      if (this.currentWordCount > this.axisMax) {
        return 100
      }
      return this.currentWordCount / this.axisMax * 100 + this.getPushVal(this.currentWordCount)
    },
  },
  methods: {
    getPushVal (value) {
      let targetCount = 0
      if (this.showWithFeaturedCompetitors) {
        targetCount = this.featuredTargetWordCount
      } else {
        targetCount = this.targetWordCount
      }

      if (value == targetCount) {
        return 0
      } else if (value < targetCount) {
        if (value/this.axisMax * 100 - this.pushVal < 0) {
          return 0
        }
        return -this.pushVal
      } else {
        if (value/this.axisMax * 100 + this.pushVal > this.axisMax) {
          return 0
        }
        return this.pushVal
      }
    },
    colorForPercent(pecent) {
      // return 'grey'
      return this.$vuetify.theme.currentTheme['mainColor']
    }
  }
}
</script>
<style lang="scss" scoped>

  $background-1: #FF6266;
  $background-2: #FF8F91;
  $background-3: #FF9B2D;
  $background-4: #FF9B2D;
  $background-5: #22DC7C;
  $background-6: #00CD49;

  .word-count-color-chart {
    display: block;
    .bar-layer {
      display: grid;
      grid-auto-flow: column;
      position: relative;
      height: 13px;
      div:nth-child(1),
      div:nth-child(11) {
        background: $background-1;
      }
      div:nth-child(2),
      div:nth-child(10) {
        background: $background-2;
      }
      div:nth-child(3),
      div:nth-child(9) {
        background: $background-3;
      }
      div:nth-child(4),
      div:nth-child(8) {
        background: $background-4;
      }
      div:nth-child(5),
      div:nth-child(7) {
        background: $background-5;
      }
      div:nth-child(6) {
        background: $background-6;
      }

      .point {
        background: var(--v-whiteColor-base);
        position: absolute;
        cursor: pointer;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .point:hover {
        background-color: yellow;      
      }
      .under-point:hover ~ .under-point-tooltip,
      .point-tooltip:hover{
         display: block;
      }
      
      .over-point:hover ~ .over-point-tooltip,
      .point-tooltip:hover{
         display: block;
      }

      .me-point:hover ~ .me-point-tooltip,
      .point-tooltip:hover{
         display: block;
      }

      .target-point:hover ~ .target-point-tooltip,
      .point-tooltip:hover{
         display: block;
      }

      .point-tooltip {
        position: absolute;
        display: none;
        background: var(--v-cardBg-base);
        border: 1px solid rgb(192, 192, 192);
        border-radius: 5px;
        padding: 10px;
        min-width: 300px;
        top: 70%;
        z-index: 50;
      }

    }
    
    .label-layer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;
      color: #666;
      min-height: 16px;
      div:nth-child(1) {
        text-align: left;
        font-size: 0.55rem;
      }
      div:nth-child(2) {
        text-align: right;
        font-size: 0.55rem;  
      }
      
      .point-label {
        position: absolute;
        transform: translateX(-50%);
        font-size: 0.55rem;
        font-weight: 700;
      }
    }
  }
</style>