// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from '@/App.vue'
import router from './router'
import store from './store'
import VeeValidate from 'vee-validate'
import Notifications from 'vue-notification'
import Buefy from 'buefy'
import Chart from 'chart.js'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'
import VueClipboard from 'vue-clipboard2'
import VueColumnsResizable from 'vue-columns-resizable'
import vClickOutside from 'v-click-outside'
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";


import { VTooltip, VPopover } from 'v-tooltip'
import VueTabs from 'vue-nav-tabs/dist/vue-tabs.js'

import vuetify from './plugins/vuetify.js' // path to vuetify export


import VueCodemirror from 'vue-codemirror'

import VueEllipseProgress from 'vue-ellipse-progress';
import CodeDiff from 'v-code-diff'

import VueToggles from 'vue-toggles';

Chart.pluginService.unregister(ChartJsPluginDataLabels)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)
 
import './assets/main.scss'

Vue.config.productionTip = false

Vue.use(VeeValidate);
Vue.use(Notifications);
Vue.use(VueClipboard)
Vue.use(VueColumnsResizable)
Vue.use(vClickOutside)
Vue.use(Buefy, {
    defaultIconPack:'fa',
    defaultFirstDayOfWeek:1
  });
Vue.use(VueTabs)
Vue.use(LottieVuePlayer);

Vue.directive('tooltip', VTooltip)
Vue.component('v-popover', VPopover)
Vue.component('VueToggles', VueToggles);

Vue.use(VueEllipseProgress);
Vue.use(CodeDiff);

// Registering custom filters
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value.toUpperCase()
})

Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
})

Vue.filter('round', function(value, decimalPlaces) {
  try {
    const num = parseFloat(value)
    if (isNaN(num)) {
      return '--'
    }
    return num.toFixed(decimalPlaces)
  } catch (err) {
    console.log('Error rounding ', err)
    return value
  }
})

Vue.filter('declimal5', function(value) {
  if (isNaN(value)) { return '' }
  return Math.round(value*10000) / 10000
})

Vue.filter('plus_minus_sign', function(value) {
  if (isNaN(value)) { return '' }
  if (value > 0) return `+${value}`
  return value
})

!function(){
  var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
  analytics.load(SEGMENT_WRITE_KEY);
}}();

/* confit code-mirrow editor */
// you can set default global options and events when Vue.use
Vue.use(VueCodemirror, /* {
  options: { theme: 'base16-dark', ... },
  events: ['scroll', ...]
} */)


window.stripe = Stripe(STRIPE_KEY);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  components: { App },
  watch: {
    "$route": function () {
      if (window.Appcues) {
        window.Appcues.page()
      }
    }
  },
  render: h => h(App),
  // template: '<App/>'
}).$mount('#app')
