<template>
<div class="three-rows-grid pt5">
    <div class="wow_disaled slideInLeft">
        <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="$emit('goprevpage')">keyboard_backspace</i>
    </div>          
    <div>
        <div class="full-width-container">
            <div class="level1-size title-font mb20 wow_disaled slideInLeft" data-wow-delay="0s">
                Do you want to select your own Competitors or have POP do it for you?
                <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
                    @click="() => {
                        $store.commit('setCurrentVideoTutorialToDisplay', {
                        title: 'How to Pick Competitors in POP',
                        videoLink: 'https://www.youtube.com/embed/PgVYaW_GvHM?si=-2Els7fTHwpmY5MR',
                        description: 'This video shows how to pick the best competitors for a PageOptimizer Pro report',
                        time: '1m 17s'
                    });
                }">
                videocam
                </span>
            </div>

            <div class="level2-size mb20 wow_disaled slideInLeft" data-wow-delay="0.3s">
                Select one option (required):
            </div>
            <div class="body-font">
                <div class="custom-radio-button is-size-4" @click="userDoesPickCompetitors">
                <i v-if="userPicksCompetitors" class="material-icons">check_box</i>
                <i v-else class="material-icons">check_box_outline_blank</i>
                <span>I want to choose my own competitors</span>
                </div>
                <div class="custom-radio-button is-size-4" @click="userDoesNotPickCompetitors">
                <i v-if="!userPicksCompetitors" class="material-icons">check_box</i>
                <i v-else class="material-icons">check_box_outline_blank</i>
                <span>I want POP to choose competitors for me</span>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class='has-text-centered font-weight-bold profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</div>
        <div class="is-bottom-right">
            <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                :disabled="!advanceable" @click="$emit('gonextpage')">
                <span>Next</span>
                <i class="material-icons">arrow_forward</i>
            </v-btn>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['isUserWillPickCompetitors', 'advanceable', 'errorMsg'],
    data () {
        return {
            userPicksCompetitors: this.isUserWillPickCompetitors
        }
    },
    watch: {
        'isUserWillPickCompetitors': function(val) {
            this.userPicksCompetitors = val
        }
    },
    methods: {
        handleUserPicksCompetitorsChange () {
            this.$emit('userpickscompetitorschange', this.userPicksCompetitors)
        },
        userDoesPickCompetitors () {
            this.userPicksCompetitors = true
            this.handleUserPicksCompetitorsChange()
        },
        userDoesNotPickCompetitors () {
            this.userPicksCompetitors = false
            this.handleUserPicksCompetitorsChange()
        }
    }
}
</script>

<style lang="scss" scoped>
div {
    color: var(--v-mainColor-base);
}
</style>