<template>
<div class="voltage-result-area mt-5">
  <template v-if="config.wizardResultsHistoryMetadata">
    <div class="header-part d-flex align-center">
      <v-text-field outlined dense hide-details
        label="Search"
        prepend-inner-icon="search"
        style="max-width: 300px;"
        v-model="searchTerm">
      </v-text-field>
      <div class="flex-grow-1"></div>
      <div class="d-flex align-center gap10">
        <b-tooltip
        :label="!config.wizardResultsHistoryMetadata.monthlySearchTaskIsCompleted ? 'You must first get search volume data' : ''"
        type="is-dark"
        >
          <v-btn
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            :disabled="!config.wizardResultsHistoryMetadata.monthlySearchTaskIsCompleted || config.wizardIsCalculating || isAnAsyncTaskRunning"
            @click="getKeywordLabels()">
            <i class="material-icons" style="margin-right: 10px;">auto_awesome</i>
            <span>Analyze with AI</span>
          </v-btn>
        </b-tooltip>
        <v-btn
          outlined
          color="mainColor"
          class="button-with-icon text-none"
          :disabled="config.wizardIsCalculating || isAnAsyncTaskRunning"
          @click="getMonthlySearchAndExtras()">
          <i class="material-icons-outlined" style="margin-right: 10px;">timeline</i>
          <span>Get search volume</span>
        </v-btn>
        <v-btn
          v-if="showResetSortingBtn"
          outlined
          color="mainColor"
          class="button-with-icon text-none"
          @click="resetSorting">
          <span>Reset sorting</span>
        </v-btn>
        <div class="keyword-filter-area">
          <div>FILTERS</div>
          <b-dropdown
            class="filter-popup-dropdown-wrapper"
            append-to-body
            aria-role="menu"
            scrollable
            max-height="200"
            trap-focus
            position="is-bottom-left"
          >
              <template #trigger>
                <div class="filter-btn" role="button" >
                  Apply filters
                  <span class="material-icons-outlined">keyboard_arrow_down</span>
                </div>
              </template>

              <b-dropdown-item custom paddingless aria-role="listitem">
                <div class="filter-popup">
                  <div class="filter-item-label">Clicks</div>
                  <div class="range-wrapper">
                    <v-range-slider hide-details
                      :min="filterClicksMinMax[0]"
                      :max="filterClicksMinMax[1]"
                      v-model="filterClicksRange"
                      color="profileAnchorColor"
                      step="1"
                      stroke="10"
                      thumb-label="always"
                    ></v-range-slider>
                  </div>
                  <div class="filter-item-label">Impressions</div>
                  <div class="range-wrapper">
                    <v-range-slider hide-details
                      :min="filterImpressionsMinMax[0]"
                      :max="filterImpressionsMinMax[1]"
                      v-model="filterImpressionsRange"
                      color="profileAnchorColor"
                      step="5"
                      stroke="10"
                      thumb-label="always"
                    ></v-range-slider>
                  </div>
                  <div class="filter-item-label">Type</div>
                  <b-select
                    v-model="filterType"
                    expanded
                    :style="{'margin-bottom': '20px'}"
                  >
                    <option
                      value=""
                    >
                    </option>
                    <option
                      value="best_opportunity_keywords_only"
                    >
                      Best opportunity keywords only
                    </option>
                    <option
                      value="top_level_keywords_only"
                    >
                      Top-level keywords only
                    </option>
                    <option
                      value="supporting_keywords_only"
                    >
                      Supporting keywords only
                    </option>
                  </b-select>
                  <div class="d-flex align-center">
                    <div class="filter-item-label flex-grow-1">Include keywords</div>
                    <div class="clear-btn" :class="{ 'disabled': filterIncludeKeywords == '' }" @click="() => { filterIncludeKeywords = '' }">
                      <i class="material-icons">close</i>
                      <span>Clear</span>
                    </div>
                  </div>
                  <div>
                    <v-textarea outlined rows="2"
                      v-model="filterIncludeKeywords">
                    </v-textarea>                
                  </div>
                  <div class="d-flex align-center">
                    <div class="filter-item-label flex-grow-1">Exclude keywords</div>
                    <div class="clear-btn" :class="{ 'disabled': filterExcludeKeywords == '' }" @click="() => { filterExcludeKeywords = '' }">
                      <i class="material-icons">close</i>
                      <span>Clear</span>
                    </div>
                  </div>
                  <div>
                    <v-textarea outlined rows="2"
                      v-model="filterExcludeKeywords">
                    </v-textarea>                
                  </div>
                  <div class="d-flex align-center gap10">
                    <v-btn color="profileAnchorColor"
                      class="text-none whiteColor--text flex-grow-1"
                      @click="applyFiltersAction">
                      <span>Apply</span>
                    </v-btn>
                    <v-btn outlined color="mainColor"
                      class="text-none"
                      @click="() => {
                        updateFilterRangeReset()
                      }">
                      <span>Reset All</span>
                    </v-btn>
                  </div>
                </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <table-pdf-csv-download
          btnStyle="min-width: initial; padding: 0 8px;"
          btnLabel="Download"
          tooltipPosition="is-left"  
          :iconOnly="true"
          :tableId="`voltage-table table`"
          fileName="keyword-wizard-keywords"
          :columns="downloadableColumns"
          :rows="sortedTableData"
          ></table-pdf-csv-download>
      </div>
    </div>
    <b-table
      id="voltage-table"
      class="voltage-table"
      ref="voltage-table"
      :data="sortedTableData"
      paginated
      :total="sortedTableData.length"
      :per-page="perPage"
      :default-sort-direction="'desc'"
      :default-sort="[defaultSortField, defaultSortOrder]"
      @sort="onSortChange">
      <template slot-scope="props">
        <b-table-column field="hiddenDefaultSortColumn" label="hiddenDefaultSortColumn" :visible="false" sortable :custom-sort="handleDefaultSort">
          -
        </b-table-column>
        <b-table-column field="track" label="Track">
          <v-checkbox hide-details dense class="mt-0 pt-0" color="#007aff"
            :value="trackedKeywords.includes(props.row.keyword)"
            @change="(shouldAddToTrack) => {
              toggleTrackUntrackKeyword(shouldAddToTrack, props.row)
            }"
          ></v-checkbox>
        </b-table-column>
        <b-table-column v-if="!isForPageLevel"
          field="page" label="Url" sortable class="url-cell">
          <a target="_blank" :href="props.row.page" :title="props.row.page">
            {{ props.row.page }}
          </a>
        </b-table-column>
        <b-table-column field="keyword" label="Keyword" sortable class="keyword-cell">
          {{ props.row.keyword }}
        </b-table-column>
        <b-table-column field="type" label="Type" sortable numeric>
          <div class="d-flex align-center gap5 justify-end" style="width: 80px;">
            <v-tooltip top color="transparent" v-if="props.row.isTopLevelKeyword">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="box-container cursor-pointer">
                  <template>
                      <b-tag type="is-success is-light"><strong class="has-text-success-dark">T</strong></b-tag>
                  </template>
                </span>
              </template>
              <div class="white-bg-tooltip small-tooltip p-3">
                <span class="tooltip-heading">Top-level keyword</span><br /><br />
                <span class="tooltip-discription">
                  This is the main keyword your target page<br />
                  can rank for. The more difficult this keyword<br />
                  is, the more supporting posts you’ll need
                </span>
              </div>
            </v-tooltip>
            <v-tooltip top color="transparent" v-else-if="props.row.isSupportingKeyword">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="box-container cursor-pointer">
                  <template>
                      <b-tag type="is-danger is-light"><strong class="has-text-danger">S</strong></b-tag>
                  </template>
                </span>
              </template>
              <div class="white-bg-tooltip small-tooltip p-3">
                <span class="tooltip-heading">Supporting keyword</span><br /><br/>
                <span class="tooltip-discription">
                  This is a keyword that you can use to<br />
                  create supporting pages and point<br />
                  their links to your main target page.
                </span>
              </div>
            </v-tooltip>

            <v-menu
              offset-y
              :disabled="keywordsOngoingLabelUpdate.indexOf(`page:${props.row.page}-keyword:${props.row.keyword}`) >= 0"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-button
                  class="keyword-label-dropdown-button"
                  :loading="keywordsOngoingLabelUpdate.indexOf(`page:${props.row.page}-keyword:${props.row.keyword}`) >= 0"
                  type="is-ghost"
                  size="is-small"
                  :icon-right="keywordsOngoingLabelUpdate.indexOf(`page:${props.row.page}-keyword:${props.row.keyword}`) >= 0 ? undefined : 'chevron-down'"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-list>
                <v-list-item v-if="!props.row.isTopLevelKeyword" @click="updateKeywordLabel(props.row.page, props.row.keyword, 'T')">Mark as the Top-level Keyword</v-list-item>
                <v-list-item v-if="!props.row.isSupportingKeyword" @click="updateKeywordLabel(props.row.page, props.row.keyword, 'S')">Mark as a Supporting Keyword</v-list-item>
                <v-list-item v-if="props.row.isTopLevelKeyword || props.row.isSupportingKeyword" @click="updateKeywordLabel(props.row.page, props.row.keyword, null)">Unmark</v-list-item>
              </v-list>
            </v-menu>
          </div>
        </b-table-column>
        <b-table-column field="opportunityScore" label="Keyword opportunity score %" sortable numeric>
          <b-tooltip v-if="props.row.isBestOpportunityKeyword" label="Best opportunity keyword" type="is-dark">
            <b-tag type="is-success" style="
              margin-right: 10px;
              padding: 0.25em;
              height: initial;
            ">
              <i class="material-icons-outlined" style="font-size: 1em; line-height: 1em;">grade</i>
            </b-tag>
          </b-tooltip>
          <template v-if="props.row.opportunityScore === undefined">
            -
          </template>
          <span v-else>{{ props.row.opportunityScore }} %</span>
        </b-table-column>
        <b-table-column field="monthlySearch" label="Search volume" sortable numeric>
          <template v-if="props.row.keyword.startsWith('site:')">
            -
          </template>
          <template v-else-if="props.row.monthlySearch === undefined">
            -
          </template>
          <template v-else>
            {{ props.row.monthlySearch }}
          </template>
        </b-table-column>
        <b-table-column field="clicks" label="Clicks" sortable numeric>
          {{ props.row.clicks }}
        </b-table-column>
        <b-table-column field="impressions" label="Impressions" sortable numeric>
          {{ props.row.impressions }}
        </b-table-column>
        <b-table-column field="impressionsShare" label="Search impressions share %" sortable numeric>
          <template v-if="props.row.impressionsShare === undefined">
            -
          </template>
          <template v-else>
            {{ props.row.impressionsShare }} %
          </template>
        </b-table-column>
        <b-table-column field="ctr" label="CTR %" sortable numeric>
          {{ props.row.ctr }} %
        </b-table-column>
        <b-table-column field="position" label="Position" sortable numeric>
          {{ props.row.position }}
        </b-table-column>
        <b-table-column field="keywordCannibalizationText" label="Keyword cannibalization">
          <radial-progress-bar
            v-if="props.row.keywordCannibalizationText"
            :diameter="40"
            :completed-steps="CannibalizationDispOption[props.row.keywordCannibalizationText].progress"
            :total-steps="100"
            :strokeWidth="4"
            :startColor="CannibalizationDispOption[props.row.keywordCannibalizationText].color"
            :stopColor="CannibalizationDispOption[props.row.keywordCannibalizationText].color"
            :innerStrokeColor="'#D9D9D9'"
            strokeLinecap="square">
            <div class="font-weight-bold clickable"
              @click="() => showCannibalizationModalAction(props.row)">
              {{ CannibalizationDispOption[props.row.keywordCannibalizationText].dispLabel }}
            </div>
          </radial-progress-bar>
        </b-table-column>
        <b-table-column field="action" label="Action">
          <div class="flex" style="width: max-content">
            <template v-if="hasSiloPermission">
              <div
                v-if="!props.row.isSupportingKeyword && !props.row.isTopLevelKeyword"
                class="box-wh35 mr-2 cursor-pointer"
              >
                <span>
                  <i class="material-icons-outlined disabled-field"
                    >post_add</i
                  >
                </span>
              </div>
              <div v-else-if="allSiloKeywords.includes(props.row.keyword)" >
                <v-tooltip top color="transparent">
                  <template
                    v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }"
                  >
                    <div class="box-wh35 mr-2 cursor-pointer">
                      <v-menu :close-on-content-click="false" :nudge-width="200">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="tooltipAttrs" v-on="tooltipOn">
                            <i
                              v-bind="attrs"
                              v-on="on"
                              class="material-icons disabled-field"
                              style="background-color: #D6FBEE !important; color: #25A97A !important;"
                            >check_circle</i>
                          </span>
                        </template>

                        <v-card>
                          <div
                            class="silo-popup"
                            style="padding: 15px; width: 21.75rem"
                          >
                            <div>
                              <span
                                >You have already added this keyword to the
                                silo.</span
                              >
                              <v-btn
                                rounded
                                color="profileAnchorColor"
                                class="text-none whiteColor--text mt-5"
                                @click="
                                  applyKeywordSilo(
                                    fetchSiloFromKeyword(props.row.keyword)?.id,
                                  )
                                "
                              >
                                <span>Open Silo</span>
                              </v-btn>
                            </div>
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </template>
                  <div class="white-bg-tooltip small-tooltip p-3">
                    <span class="tooltip-discription">
                      This {{props.row.isTopLevelKeyword?'Top-level':(props.row.isSupportingKeyword?'Supporting':'')}} Keyword was added to Silo <strong>{{fetchSiloFromKeyword(props.row.keyword).name}}</strong>
                    </span>
                  </div>
                </v-tooltip>
              </div>
              <v-tooltip v-else top color="transparent">
                <template
                  v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }"
                >
                  <span class="cursor-pointer box-wh35 mr-2">
                    <div class="box-wh35 cursor-pointer">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-width="200"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="tooltipAttrs" v-on="tooltipOn">
                            <i
                              v-bind="attrs"
                              v-on="on"
                              class="material-icons-outlined"
                              >post_add</i
                            >
                          </span>
                        </template>
                        <v-card>
                          <div class="silo-new-popup" style="padding: 15px">
                            <div class="filter-item-label"></div>
                            <div>
                              <v-text-field
                                outlined
                                dense
                                hide-details
                                label="Search silos"
                                prepend-inner-icon="search"
                                class="search-field"
                                v-model="siloSearch"
                              >
                              </v-text-field>
                              <div
                                class="flex flex-column gap5 pt-2"
                                style="max-height: 200px; overflow-y: auto;"
                              >
                                <div
                                  v-for="(silo, idx) in filteredSilos"
                                  @click="addKeywordToSilo(props.row, silo)"
                                  :key="idx"
                                  class="flex gap5 align-center cursor-pointer"
                                >
                                  <i
                                    v-if="
                                      silo.silo_keywords.some(
                                        (keyword) => keyword.keywordData.isTopLevelKeyword,
                                      )
                                    "
                                    class="material-icons-outlined green-check"
                                    style="color: var(--v-green21Color-base)"
                                  >
                                    check
                                  </i>
                                  <i
                                    v-else
                                    class="material-icons-outlined green-check"
                                  >
                                    check
                                  </i>
                                  <span>{{ silo.name }}</span>
                                </div>
                              </div>
                              <div
                                class="flex gap2 align-center mt-2 cursor-pointer"
                                style="margin-left: 8px"
                                @click="() => openSiloModal(props.row)"
                              >
                                <img
                                  src="/static/add_orange.svg"
                                  style="
                                    background-color: transparent;
                                    width: auto;
                                    height: auto;
                                    margin-left: -4px;
                                  "
                                />
                                <span
                                  class="primary-text"
                                  style="
                                    margin-left: 6px;
                                    color: var(--v-profileAnchorColor-base);
                                    font-size: 0.85rem;
                                    font-weight: 700;
                                  "
                                  >Create new silo</span
                                >
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </span>
                </template>
                <div class="white-bg-tooltip small-tooltip p-3">
                  <span class="tooltip-heading">Add this keyword to a content Silo.</span><br />
                  <span class="tooltip-discription">
                    A Silo can contain up to 3 Top-level Keywords and up to 15 Supporting Keywords.
                  </span>
                </div>
              </v-tooltip>
              <template v-if="showSiloExtraActions">
                <span class="box-wh35 mr-2 cursor-pointer">
                  <i class="material-icons-outlined flip-icon">list</i>
                </span>
                <span class="box-wh35 mr-2 cursor-pointer">
                  <i class="material-icons-outlined">sync</i>
                </span>
                <span class="box-wh35 cursor-pointer">
                  <img src="/static/star_group.svg" />
                </span>
              </template>
            </template>
          </div>
        </b-table-column>
      </template>
    </b-table>
    <silo-pop-up :popUpSilo="popUpSilo" @closeModal="closeSiloModal" @addKeywordToSilo="addKeywordToSilo" />
  </template>
  <template v-else>
    <div>
      <!-- No data to display -->
    </div>
  </template>

  <keyword-cannibalization-popup
    ref="keywordCannibalizationPopupRef"></keyword-cannibalization-popup>
</div>
</template>


<script>
import { wildcardMatchString } from "@/utils";
import { filter } from "lodash"
import RadialProgressBar from 'vue-radial-progress'
import KeywordCannibalizationPopup from './KeywordCannibalizationPopup.vue';
import TablePdfCsvDownload from '@/components/Campaigns/TablePdfCsvDownload.vue'
import SiloPopUp from "@/components/SiloPopUp.vue";
import { getSplittedKeywords } from '@/components/Campaigns/CommonFunctions.js'

export default {
  props: {
    isForPageLevel: {
      default: false,
    },
    isCalculating: {
      default: false,
    },
    resultData: {
      default: []
    },
    showSiloExtraActions: {
      default: false,
    }
  },
  components: {
    RadialProgressBar,
    SiloPopUp,
    KeywordCannibalizationPopup,
    TablePdfCsvDownload,
  },
  data () {
    return {
      tableData: [],
      isModalOpen: false,
      modalData: [],
      avgImpression: 0,

      popUpSilo: null,
      silos: [],
      siloSearch: '',
      voltageImpression: 0,

      searchTerm: '',
      perPage: 100,
      defaultSortOrder: 'desc',
      defaultSortField: 'hiddenDefaultSortColumn',
      showResetSortingBtn: false,

      allAppliedFilters: {},

      filterClicksMinMax: [0, 0],
      filterClicksRange: [0, 0],
      filterImpressionsMinMax: [0, 0],
      filterImpressionsRange: [0, 0],
      filterType: '',
      filterIncludeKeywords: '',
      filterExcludeKeywords: '',

      trackedKeywords: [],

      keywordsOngoingLabelUpdate: [],

      isStartingMonthlySearchAsyncTask: false,
      isAwaitingMonthlySearchAsyncTaskStatus: false,

      isStartingLabelsAsyncTask: false,
      isAwaitingLabelsAsyncTaskStatus: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isSpecialOwnerSelected () {
      return this.$store.getters.isSpecialOwnerSelected
    },
    hasSiloPermission () {
      if (this.selectedSubAccountRelation) {
        if (this.isSpecialOwnerSelected) {
          return true
        } else {
          return this.selectedSubAccountRelation.grantedDevModeTools.includes('silo')
        }
      } else if (this.isSpecialAdminUser) {
        return true
      }
      return this.user.grantedDevModeTools.includes('silo')
    },
    filteredSilos() {
      return this.siloSearch ? this.silos.filter(silo => silo.name?.toLowerCase().includes(this.siloSearch?.toLowerCase())) : this.silos
    },
    allSiloKeywords() {
      return this.silos.flatMap(silo => silo.silo_keywords.map(siloKeyword => siloKeyword.keyword))
    },
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    sortedTableData () {
      let searchTerm = this.searchTerm ? this.searchTerm.toLowerCase() : ''
      let data = [...this.tableData].filter((item) => {
        if (!searchTerm) {
          return true;
        }
        return item.keyword.includes(searchTerm) || ( item.page.includes(searchTerm))
      })

      let {
        filterClicksRange,
        filterImpressionsRange,
        filterType,
        filterIncludeKeywords,
        filterExcludeKeywords
      } = this.allAppliedFilters

      if (filterClicksRange) {
        data = data.filter(item => {
          if (item.clicks >= filterClicksRange[0] && item.clicks <= filterClicksRange[1]) {
            return true;
          }
          return false;
        })
      }
      if (filterImpressionsRange) {
        data = data.filter(item => {
          if (item.impressions >= filterImpressionsRange[0] && item.impressions <= filterImpressionsRange[1]) {
            return true;
          }
          return false;
        })
      }
      if (filterType) {
        data = data.filter(item => {
          if (filterType === 'best_opportunity_keywords_only') {
            return item.isBestOpportunityKeyword;
          } else if (filterType === 'top_level_keywords_only') {
            return item.isTopLevelKeyword;
          } else if (filterType === 'supporting_keywords_only') {
            return item.isSupportingKeyword;
          }
          return false;
        })
      }
      if (filterIncludeKeywords) {
        let includeWords = getSplittedKeywords(filterIncludeKeywords)
        data = data.filter(item => {
          for (const k of includeWords) {
            if (item.keyword.includes(k)) {
              return true;
            }
          }
          return false;
        })
      }
      if (filterExcludeKeywords) {
        let excludeKeywords = getSplittedKeywords(filterExcludeKeywords)
        data = data.filter(item => {
          for (const k of excludeKeywords) {
            if (item.keyword.includes(k)) {
              return false;
            }
          }
          return true;
        })
      }

      // each time we filter data, we also sort with default sort
      // this is so print and download buttons can show conveniently sorted data
      data.sort((a, b) => this.handleDefaultSort(a, b))

      // Filter urls
      const pageUrls = this.config.wizardSelectedPageUrls || []

      return pageUrls.length
        ? data.filter(row => 
          pageUrls.filter(
            pageUrl => pageUrl.includes('*')
              ? wildcardMatchString(row.page, pageUrl)                        // selected url contains *, use wildcard match
              : pageUrl.toLocaleLowerCase() === row.page.toLocaleLowerCase()  // selected url does not contain *, check exact match
          ).length > 0
        )
        : data
    },
    CannibalizationDispOption () {
      return {
        low: {
          color:'#00CD49',
          dispLabel: 'L',
          progress: 35
        },
        medium: {
          color: '#FF9B2D',
          dispLabel: 'M',
          progress: 75
        },
        high: {
          color: '#FF1F2D',
          dispLabel: 'H',
          progress: 100
        }
      }
    },
    downloadableColumns () {
      return [
        ...!this.isForPageLevel
        ?[
          {
            label: 'Url',
            value: 'page',
          },
        ]
        :[],
        ...[
          {
            label: 'Keyword',
            value: 'keyword',
          },
          {
              label: 'Label',
              value: (row) => {
                if (row.isTopLevelKeyword) {
                  return 'Top-Level Keyword'
                }
                if (row.isSupportingKeyword) {
                  return 'Supporting Keyword'
                }
                return ''
              },
          },
          {
              label: 'Opportunity Level',
              value: (row) => {
                if (row.isBestOpportunityKeyword) {
                  return 'Best'
                }
                return ''
              },
          },
          {
              label: 'Keyword Opportunity Score',
              value: 'opportunityScore',
              isPercentage: true,
          },
          {
              label: 'Search Impressions Share',
              value: 'impressionsShare',
              isPercentage: true,
          },
          {
              label: 'Search Volume',
              value: 'monthlySearch',
          },
          {
              label: 'Impressions',
              value: 'impressions',
          },
          {
              label: 'Clicks',
              value: 'clicks',
          },
          {
              label: 'CTR',
              value: 'ctr',
              isPercentage: true,
          },
          {
              label: 'Average Position',
              value: 'position',
          },
        ]
      ]
    },
    asyncTasks() {
      return this.$store.state.asyncTasks
    },
    currentMonthlySearchAsyncTask() {
      return this.asyncTasks.filter(task => task.id === this.config.wizardResultsHistoryMetadata?.monthlySearchTaskId)[0]
    },
    currentLabelsAsyncTask() {
      return this.asyncTasks.filter(task => task.id === this.config.wizardResultsHistoryMetadata?.labelsTaskId)[0]
    },
    isAnAsyncTaskRunning() {
      return (
        (this.isStartingMonthlySearchAsyncTask || this.isAwaitingMonthlySearchAsyncTaskStatus || this.currentMonthlySearchAsyncTask?.is_pending_or_in_progress)
        || (this.isStartingLabelsAsyncTask || this.isAwaitingLabelsAsyncTaskStatus || this.currentLabelsAsyncTask?.is_pending_or_in_progress)
      )
    },
  },
  watch: {
    "config.wizardResults": {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal) {
          // console.log('copy this for test zzzzz', newVal)
          let exportData = newVal.slice(0, 50)
          // download('demo data.txt', JSON.stringify(exportData));
          // download as file to test

          this.tableData = newVal
          const data = newVal;
          if (this.tableData.length > 0) { // to avoid 0 division
            const $avg = data.reduce((acc, d) => acc + d.impressions, 0) / data.length;
            this.avgImpression = $avg;

            const allImpressions = data.map(g => g.impressions)
            const maxImp = Math.max(...allImpressions)
            const minImp = Math.min(...allImpressions)

            const $voltageAvg = (maxImp + minImp) / 2;
            this.voltageImpression = $voltageAvg;
          } else {
            this.avgImpression = 0;
            this.voltageImpression = 0;
          }
        } else {
          this.tableData = [];
        }
        this.updateFilterRangeReset()
      }
    },
    campaignId: function(newVal, oldVal) {
      if (newVal) {
        this.loadKeywordTrack()
      }
    },
    currentMonthlySearchAsyncTask: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.isAwaitingMonthlySearchAsyncTaskStatus = false;
      }
      if (newVal?.is_completed && newVal?.is_completed !== oldVal?.is_completed) {
        this.$emit('refreshData')
      }
    },
    currentLabelsAsyncTask: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.isAwaitingLabelsAsyncTaskStatus = false;
      }
      if (newVal?.is_completed && newVal?.is_completed !== oldVal?.is_completed) {
        this.$emit('refreshData')
      }
    }
  },
  methods: {
    closeSiloModal(refreshData = false) {
      this.popUpSilo = null;
      if (refreshData) this.fetchSilos();
    },
    openSiloModal(row) {
      this.popUpSilo = row;
    },
    fetchSiloFromKeyword(keyword) {
      return this.silos.find(silo => silo.silo_keywords.some(siloKeyword => siloKeyword.keyword === keyword))
    },
    applyKeywordSilo(siloId) {
      if (!siloId) return

      const { siteId, campaignId } = this.$route.params
      this.$router.push(`/sites/${siteId}/campaign/${campaignId}/silo?id=${siloId}`)
    },
    fetchSilos() {
      this.$store.dispatch('getSilos', {
        campaignId: this.$route.params.campaignId,
        paginate: 0,
      }).then(response => {
        this.silos = response.data.data;
      })
    },
    addKeywordToSilo(row, silo, closeModal) {
      if (!row.isTopLevelKeyword && !row.isSupportingKeyword) return;

      const topLevelKeywordsCount = silo.silo_keywords.filter(keyword => keyword.keywordData.isTopLevelKeyword)?.length || 0;
      const supportingKeywordsCount = silo.silo_keywords.filter(keyword => keyword.keywordData.isSupportingKeyword)?.length || 0;

      if (row.isTopLevelKeyword && topLevelKeywordsCount >= 3){
        if (closeModal) this.closeSiloModal(true);
        this.$notify({
          group: 'info', type: 'warning',
          text: 'This silo cannot have more than 3 Top-level keywords.'
        })
        return;
      }
      
      if (row.isSupportingKeyword && supportingKeywordsCount >= 15){
        if (closeModal) this.closeSiloModal(true);
        this.$notify({
          group: 'info', type: 'warning',
          text: 'This silo cannot have more than 15 Supporting keywords.'
        })
        return;
      }

      this.$store.commit('showLoading');

      this.$store
        .dispatch('addKeywordToSilo', {
          siloId: silo.id,
          body: {
            keyword: row.keyword,
            keywordData: {
              ...row,
              locationCode: this.config.wizardLocation,
              languageCode: this.config.wizardLanguage,
            },
            sourceReportType: 'KeywordWizardResearch',
            sourceReportId: this.config.wizardResultsHistoryMetadata.id,
          },
        })
        .then(() => {
          this.$store.commit('setIsNewSiloUpdates', true);
          this.$notify({
            group: 'info', type: 'success',
            text: 'Successfully added keyword to silo.'
          })
          this.fetchSilos();
        })
        .catch(() => this.$notify({
          group: 'info', type: 'danger',
          text: 'Failed to add keyword to silo.'
        }))
        .finally(() => this.$store.commit('hideLoading'));
    },
    setConfig(key, value) {
      this.$store.commit("setInsightKeywordsConfig", { key, value });
    },
    loadKeywordTrack () {
      this.$store.dispatch('getKeywordTrackForCampaign', this.campaignId)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.trackedKeywords = response.data.keywordTrack.trackKeywords
        }
      })
    },
    updateFilterRangeReset () {
      const clicks = this.tableData.map(item => item.clicks)
      let minVal = Math.min(...clicks)
      let maxVal =Math.max(...clicks)
      this.filterClicksMinMax = [minVal, maxVal]
      this.filterClicksRange = [minVal, maxVal]

      const impressions = this.tableData.map(item => item.impressions)
      minVal = Math.min(...impressions)
      maxVal =Math.max(...impressions)
      this.filterImpressionsMinMax = [minVal, maxVal]
      this.filterImpressionsRange = [minVal, maxVal]

      this.filterType = ''

      this.filterIncludeKeywords = ''
      this.filterExcludeKeywords = ''

      this.allAppliedFilters = {}
    },
    applyFiltersAction () {
      this.allAppliedFilters = {
        filterClicksRange: this.filterClicksRange,
        filterImpressionsRange: this.filterImpressionsRange,
        filterType: this.filterType,
        filterIncludeKeywords: this.filterIncludeKeywords,
        filterExcludeKeywords: this.filterExcludeKeywords,
      }
    },
    toggleTrackUntrackKeyword (shouldAddToTrack, item) {
      let data = {}
      if (shouldAddToTrack) {
        data = {
          campaignId: this.campaignId,
          trackKeyword: item.keyword,
          trackData: item,
          languageCode: this.config.wizardLanguage,
          locationCode: this.config.wizardLocation,
          domain: this.config.wizardDomain
        }
        this.$store.commit('showLoading');
        this.$store.dispatch('addKeywordToTrack', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.trackedKeywords = response.data.keywordTrack.trackKeywords
            this.$notify({
              group: 'info', type: 'success',
              text: 'Successfully added keyword to track.'
            })
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: response.data.msg || 'Failed to add keyword to the track.'
            })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      } else {
        data = {
          campaignId: this.campaignId,
          trackKeyword: item.keyword,
        }

        this.$store.commit('showLoading');
        this.$store.dispatch('removeKeywordFromTrack', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.trackedKeywords = response.data.keywordTrack.trackKeywords
            this.$notify({
              group: 'info', type: 'success',
              text: 'Successfully removed keyword from track.'
            })
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: 'Failed to remove keyword from the track.'
            })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      }
      console.log(data);
    },
    getMonthlySearchAndExtras() {
      const formdata = {
        id: this.config.wizardResultsHistoryMetadata.id,
      }
      this.isStartingMonthlySearchAsyncTask = true
      this.$store.dispatch('loadKeywordWizardMonthlySearchAndExtras', formdata)
      .then(response => {
        if (response?.data?.status === 'SUCCESS' && response?.data?.wizardData) {
          this.$store.commit('triggerAsyncTasksRefresh');
          this.setConfig('wizardResultsHistoryMetadata', response.data.wizardData)
          this.isAwaitingMonthlySearchAsyncTaskStatus = true;
          this.isStartingMonthlySearchAsyncTask = false
        } else {
          return Promise.reject()
        }
      })
      .catch(() => {
        this.isStartingMonthlySearchAsyncTask = false
      })
    },
    getKeywordLabels() {
      const formdata = {
        id: this.config.wizardResultsHistoryMetadata.id,
      }
      this.isStartingLabelsAsyncTask = true
      this.$store.dispatch('loadKeywordWizardLabels', formdata)
      .then(response => {
        if (response?.data?.status === 'SUCCESS' && response?.data?.wizardData) {
          this.$store.commit('triggerAsyncTasksRefresh');
          this.setConfig('wizardResultsHistoryMetadata', response.data.wizardData)
          this.isAwaitingLabelsAsyncTaskStatus = true;
          this.isStartingLabelsAsyncTask = false
        } else {
          return Promise.reject()
        }
      })
      .catch(() => {
        this.isStartingLabelsAsyncTask = false
      })
    },
    showCannibalizationModalAction (row) {
      const { cannibalizedKeywords, keywordCannibalizationText } = row
      this.$refs.keywordCannibalizationPopupRef.openCannibalizationPopupAction(cannibalizedKeywords, keywordCannibalizationText)

    },
    handleDefaultSort(a, b, isAsc) {
      // This sorts by the following combination:
      // [desc totalPageImpressions , asc page, desc keyword impressions]
      // basically tries to show keywords of the same pages closer together, items with most impressions first.
      if (a.totalPageImpressions == b.totalPageImpressions) {
        if (a.page == b.page) {
          if (a.impressions == b.impressions) {
            if(a.keyword < b.keyword) return -1;
            if(a.keyword > b.keyword) return 1;
          } else {
            return Number(b.impressions) - Number(a.impressions);
          }
        }  else {
          if (a.page < b.page) {
            return -1;
          }
          if (a.page > b.page) {
            return 1;
          }
        }
      } else {
        return Number(b.totalPageImpressions) - Number(a.totalPageImpressions);
      }
    },
    onSortChange(field, order) {
      this.showResetSortingBtn = (field !== this.defaultSortField)
    },
    resetSorting() {
      this.$refs['voltage-table'].initSort()
    },
    printFew () {
      console.log('printFew', JSON.stringify(this.sortedTableData.slice(0, 50)))
    },
    updateKeywordLabel(page, keyword, label) {
      const formdata = {
        researchId: this.config.wizardResultsHistoryMetadata.id,
        page: page,
        keyword: keyword,
        label: label
      }
      this.keywordsOngoingLabelUpdate = [...this.keywordsOngoingLabelUpdate, `page:${page}-keyword:${keyword}`]
      this.$store.dispatch('wizardUpdateKeywordLabel', formdata)
      .then(response => {
        if (response?.data?.status === 'SUCCESS') {
          this.$emit('onKeywordLabelUpdateSuccess', page, keyword, label)
          return Promise.resolve()
        } else {
          return Promise.reject()
        }
      })
      .finally(() => {
        this.keywordsOngoingLabelUpdate = this.keywordsOngoingLabelUpdate.filter(k => k !== `page:${page}-keyword:${keyword}`)
      })
    },
  },
  mounted () {
    this.fetchSilos()
    this.updateFilterRangeReset()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../keyword-research.scss';

</style>

<style lang="scss">
.filter-popup-dropdown-wrapper {
  .dropdown-menu {
    padding-top: 10px;
  }
  .dropdown-content {
    padding: 0;
  }
}
</style>