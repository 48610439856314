<template>
<section>
  <div class="container is-fluid">
    <div class="page-title-label">
      API Usage
    </div>
    <manage-pop-keys :forAdmin="forAdmin"></manage-pop-keys>
    <div class="d-flex align-center mt-10 mb-5" style="position: relative;">
      <div class="page-title-label">
        API Usage Stats
      </div>
      <div class="mx-5">
        <div class="range-pick-area">
          <div>PERIOD</div>
          <div class="d-flex align-center" @click="() => { showDateRangePickModal = true; }">
            Select Dates
            <span class="material-icons-outlined">keyboard_arrow_down</span>
          </div>

          <div v-if="showDateRangePickModal" v-click-outside="() => { showDateRangePickModal = false; }"
            class="data-range-popup">
            <date-picker
              v-model="dateRangeForFilter" range inline
              class="customized-date-picker">
            </date-picker>
            <div class="d-flex align-center justify-center mt-3 gap10">
              <v-btn color="profileAnchorColor"
                class="text-none whiteColor--text"
                @click="loadData">
                <span>Apply</span>
              </v-btn>
              <v-btn outlined color="mainColor"
                class="text-none mainColor--text">
                <span>Reset</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div v-if="forAdmin">
        <v-text-field outlined dense hide-details="auto"
          color="greenColor"
          label="Search by email, report id, keyword"
          v-model="searchText"
          @keyup.enter="loadData">
        </v-text-field>
      </div>
      <div class="mainColor--text" v-if="apiKeys.length > 1">
        <v-select outlined dense hide-details="auto"
          v-model="selectedApiKeyType"
          label="API key" color="greenColor"
          :items="apiKeys" item-text="keyLabel" item-value="keyType">
        </v-select>
      </div>
      <!-- <div class="mainColor--text mr-2">
        {{ toLocalDateString(dateRangeForFilter[0].valueOf()) }}
        ~
        {{ toLocalDateString(dateRangeForFilter[1].valueOf()) }}
      </div> -->
    </div>

    <div class="usage-stats" v-if="!forAdmin">
      <div>
        <div class="calls-count">{{ selectedApiKeyType == 'PARTNER_KEY' ? 'Unlimited' : totalAvailableAPICalls }}</div>
        <div class="calls-desc">Available API calls</div>
        <div class="calls-count mt-5">{{ totalRows }}</div>
        <div class="calls-desc">API calls you made during the selected period</div>
        <!-- <div class="stats-area">
          <div class="d-flex justify-space-between">
            <div>Your all-time API usage</div>
            <div>600 / 2000</div>
          </div>
          <v-progress-linear rounded height="7"
            background-color="gray7Color"
            color="profileAnchorColor"
            class="mt-2"
            :value="59">
          </v-progress-linear>
        </div> -->
      </div>
      <div v-if="selectedApiKeyType == 'ADD_ON_KEY'">
        <div class="calls-count">{{ selectedApiKeyType == 'PARTNER_KEY' ? 'Unlimited' : totalAvailableCredits }}</div>
        <div class="calls-desc">Available credits</div>
        <div class="calls-count mt-5">{{ totalCreditsUsedInPeriod }}</div>
        <div class="calls-desc">POP credits used in during the selected period</div>
        <!-- <div class="stats-area">
          <div class="d-flex justify-space-between">
            <div>Your POP Credits balance</div>
            <div>100 / 120</div>
          </div>
          <v-progress-linear rounded height="7"
            background-color="gray7Color"
            color="profileAnchorColor"
            class="mt-2"
            :value="59">
          </v-progress-linear>
        </div> -->
      </div>
    </div>

    <div v-if="records.length > 0" class="mt-5">
      <b-table
        :data="records"
        ref="runTable"  
        paginated
        backend-pagination
        :total="totalRows"
        :per-page="perPage"
        :current-page="currentPage"
        @page-change="onPageChange"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
      >
        <template slot-scope="props">
          <b-table-column field="runId" label="Report Id" sortable numeric>{{ props.row.runId }}</b-table-column>
          <b-table-column field="createdAt" label="Created on" sortable>{{ props.row.pageRun.createdAt }}</b-table-column>
          <b-table-column field="keyword" label="Keyword" sortable>{{ props.row.pageRun.keyword }}</b-table-column>
          <b-table-column v-if="forAdmin" field="email" label="User">{{ props.row.email }}</b-table-column>
          <b-table-column field="url" label="Target URL">{{ props.row.pageRun.url }}</b-table-column>
          <b-table-column field="status" label="Status" sortable>
            <span v-if="props.row.pageRun.status == 'SUCCESS'" class="status-success">
              <span class="spot"></span>
              <span>Success</span>
            </span>
            <span v-else class="status-failure">
              <span class="spot"></span>
              <span>Failed</span>
            </span>
          </b-table-column>
          <b-table-column field="score" label="Score">{{ pageScore(props.row) }}</b-table-column>
          <b-table-column field="ip" label="IP address">{{ props.row.pageRun.ipAddress }}</b-table-column>
          <b-table-column v-if="forAdmin" field="apiKeyTypeUsed" label="Api Key used">{{ props.row.apiKeyTypeUsed }}</b-table-column>
          <b-table-column field="isGuardRun" label="Run Type">
            {{ props.row.isGuardRun ? 'Watchdog': 'API' }}
          </b-table-column>
          <b-table-column label="Action">
            <v-btn rounded outlined
              color="mainColor"
              class="text-none"
              @click="() => openAPIReport(props.row)">
              <span>Open report</span>
            </v-btn>
          </b-table-column>
        </template>
      </b-table>

      <div class="font-weight-bold mainColor--text is-size-6">Total Usage in the period: {{ totalRows }}</div>
    </div>
  </div>
</section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { toLocalDateString } from '@/utils'
import moment from 'moment';
import ManagePopKeys from '@/components/ManagePopKeys.vue';


export default {
  props: ['forAdmin',],
  components: {
    DatePicker,
    ManagePopKeys,
  },
  data () {
    return {

      // fromDate: null,
      // toDate: null,
      selectedApiKeyType: '',
      showDateRangePickModal: false,
      dateRangeForFilter: [moment().subtract(1, 'months').toDate(), moment().add(1, 'days').toDate()],

      records: [],
      totalRows: 0,
      isLoading: true,
      sortField: 'runId',
      sortOrder: 'desc',
      defaultSortOrder: 'asc',
      currentPage: 1,
      page: 1,
      perPage: 25,
      searchText: '',
      filterField: 'email',

      // last7DaysReportCount: 0,
      totalAvailableAPICalls: 0,
      totalCreditsUsedInPeriod: 0,
      totalAvailableCredits: 0,
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    hasPOPApiKey () {
      return this.user.addOnApiKey || this.user.isDevApiKeyAllowed || this.user.isPartnershipApiKeyAllowed
    },
    apiKeys () {
      return this.$store.getters.availablePOPKeys
    },
    isPartnershipApiKeyAllowed() {
      return this.$store.getters.isPartnershipApiKeyAllowed
    },
  },
  watch: {
    'selectedApiKeyType': function(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.loadData()
      }
    }

  },
  methods: {
    openAPIReport (data) {
      const { pageRun, apiKeyTypeUsed } = data
      if (pageRun) {
        this.$router.push(`/sites/0/pages/0/pageruns/${pageRun.id}?apiRunView=1`)
      }

    },
    toLocalDateString (date) {
      return toLocalDateString(date)
    },
    onPageChange (page) {
      this.page = page
      this.$nextTick(() => {
        this.loadData()
      })
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadData()
    },
    loadData () {
      let [ fromDate, toDate ] = this.dateRangeForFilter

      if (fromDate && toDate) {
        let diffDays = ( toDate.getTime() - fromDate.getTime() ) / (1000 * 60 * 60 * 24) + 1; // +1 is to include start date
        if (diffDays > 90) {
          this.$notify({
            group: 'info', type: 'warning',
            text: `Maximum date range you can pick is 90 days.`
          })
          return;
        }
      }


      const data = {
        forAdmin: this.forAdmin == true,
        selectedApiKeyType: this.forAdmin ? '' : this.selectedApiKeyType,
        startDate: fromDate ? fromDate.valueOf() : '',
        endDate: toDate ? toDate.valueOf() : '',

        sortField: this.sortField,
        sortOrder: this.sortOrder,
        page: this.page,
        perPage: this.perPage,
        filterField: this.filterField,
        filterText: this.searchText.replace(/\s+/g, ''),
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('loadApiUsageForUser', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.records = response.data.records
          this.totalRows = response.data.totalReportCountInPeriod
          this.totalAvailableAPICalls = response.data.totalAvailableAPICalls
          // this.last7DaysReportCount = response.data.last7DaysReportCount
          this.totalCreditsUsedInPeriod = response.data.totalCreditsUsedInPeriod
          this.totalAvailableCredits = response.data.totalAvailableCredits
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    pageScore (row) {
      try {
        let score = row.pageRun.cleanedContentBrief.pageScore.pageScore
        return score
      } catch (e) {
        return '--'
      }
    }
  },

  mounted () {
    if (!this.forAdmin && this.apiKeys.length > 0) {
      this.selectedApiKeyType = this.apiKeys[0].keyType
    } else { // admin
      this.loadData()
    }
  }
}
</script>

<style scoped lang="scss">
.page-title-label {
  color: var(--v-mainColor-base);
  font-weight: 800;
  font-size: 1.2rem;
}

.range-pick-area {
  display: flex;
  align-items: center;
  border: 1px solid var(--v-mainColor-base);
  border-radius: 7px;
  position: relative;
  overflow: visible;
  cursor: pointer;
  & > div {
    padding: 7px 10px;
    color: var(--v-mainColor-base);
    &:first-child {
      border-right: 1px solid var(--v-mainColor-base);
    }
  }
  span[class^='material-icons'] {
    font-size: 1rem;
  }


  .data-range-popup {
    position: absolute;
    left: 0px;
    top: 50px;
    z-index: 12;
    background: var(--v-whiteColor-base);
    border-radius: 10px;
    box-shadow: 0px 6px 27px 0px rgba(0, 0, 0, 0.17);
    padding: 10px;
  }
}

.usage-stats {
  display: flex;
  gap: 20px;

  & > div {
    position: relative;
    width: 300px;
    border-top: 1px solid var(--v-grayColor-base) !important;
    border-bottom: 1px solid var(--v-grayColor-base) !important;
    border-right: 1px solid var(--v-grayColor-base) !important;
    background-color: var(--v-whiteColor-base);
    border-radius: 10px !important;
    overflow: hidden;
    padding: 20px 20px 20px 25px;
    color: var(--v-mainColor-base);

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      width: 8px;
      background: var(--v-profileAnchorColor-base);
    }

    .calls-count {
      font-size: 2rem;
      font-weight: 800;
      color: var(--v-mainColor-base);
    }
    .calls-desc {
      margin-top: 15px;
      color: var(--v-mainColor-base);
    }
    .stats-area {
      margin-top: 30px;
    }
  }
}

.status-success, .status-failure {
  .spot {
    width: 10px;
    height: 10px;
  }

}


.customized-date-picker::v-deep {
// customize vue2-datepicker
  .mx-datepicker {
    input {
      background-color: var(--v-whiteColor-base) !important;
      color: var(--v-blackColor-base);
    }
  }
  .mx-calendar-header {
    color: var(--v-mainColor-base);
    button {
      font-weight: 700;
    }
  }

  .mx-datepicker-content {
    background-color: var(--v-whiteColor-base);
    table.mx-table-date {
      thead {
        th {
          color: var(--v-gray9Color-base);
        }
      }
      td {
        & > div {
          text-align: center;
          color: var(--v-mainColor-base);
          font-weight: 700;
        }
        &.today > div {
          color: var(--v-profileAnchorColor-base);
        }
        &.cell.active {
          border-radius: 10px;
          background-color: var(--v-profileAnchorColor-base);
          & > div {
            color: var(--v-whiteColor-base);
          }
        }
        &.cell.in-range,
        &.cell.hover-in-range, {
          background-color: var(--v-lightYellowColor-base);
        }
      }
    }
  }
  // end
}

</style>