export const getSplittedKeywords = (keywords) => {
  console.log("getSplittedKeywords input data: ", keywords)
  let splittedKeywords = []
  keywords.split("\n").map((kw) => {
      const keywordLines = kw.trim().split(",").map(k => k.trim());
      splittedKeywords = splittedKeywords.concat(keywordLines)
  });
  return splittedKeywords.filter(k => k.trim());
};


export const getValidKeywords = (keywords) => {
  const MAX_COUNT = 1000
  const MAX_KEYWORD_LETTERS = 80
  const MAX_WORDS_KEYWORD = 10
  let validKeywords = keywords.filter(element => {
      const letters = element.length
      const words = element.split(' ').length
      return !(letters > MAX_KEYWORD_LETTERS || words > MAX_WORDS_KEYWORD)
  })
  return validKeywords.slice(0, MAX_COUNT)
}