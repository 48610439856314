const explainData = {};
  explainData['Keyword in Main URL'] = `
  <span class="wrap-long-tooltip">
    It is recommended that you put your target keyword in your url after the TLD,
    e.g. .com/your-target-keyword. The tool does not look at keywords in the domain.
    If you have an exact match domain, the tool will not consider this as a keyword in the URL.
    If you have an established page we do not recommend changing your URL to include your target keyword.
    If you change a URL you can lose established rank. Only change URLs on very new pages.
  </span>
  `
  explainData['Meta Title'] = `<div>
    <span class="wrap-long-tooltip">
    The meta title is the page title that search engines see.
    The meta title will use the HTML tags: &lt;title&gt; or &lt;meta name="title"&gt;.
    The tool treats both title versions as the same thing.  The meta title is the most important on page signal.
    </span>
  </div>`
  explainData['H1'] = `<div>
      <span class="wrap-long-tooltip">
        The H1 is commonly the page title that visitors to your page will see.
        It is often the largest text on the page.  The H1 is a very important on page signal.
        As a general rule, you will only have one H1 on the page.
        Most websites have a designated font size for the H1.  H1 uses the HTML tag &lt;h1&gt; &lt;/h1&gt;
      </span>
      <div class="html-result-container">
        <div>
          <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
          &lt;h1&gt;Heading Tag 1&lt;/h1&gt;
        </div>
        <div>
          <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
          <h1 class="h1-size">Heading Tag 1</h1>
        </div>          
      </div>
  </div>`
  explainData['H2'] = `<div>
    <span class="wrap-long-tooltip">
      H2, H3, and H4, are typically sub headers that divide content on a page.
      They often descend in font size.
      For example, an H3 is usually smaller in font size than and H2.
      They also descend in signal stregth.
      They use HTML tags &lt;h2&gt; &lt;/h2&gt;, &lt;h3&gt; &lt;/h3&gt;, &lt;h4&gt; &lt;/h4&gt;  
    </span>
      <div class="html-result-container">
        <div>
          <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
          &lt;h2&gt;Heading Tag 2&lt;/h2&gt; <br/>
          &lt;h3&gt;Heading Tag 3&lt;/h3&gt; <br/>
          &lt;h4&gt;Heading Tag 4&lt;/h4&gt; <br/>
        </div>
        <div>
          <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
          <h1 class="h2-size">Heading Tag 2</h1>
          <h1 class="h3-size">Heading Tag 3</h1>
          <h1 class="h4-size">Heading Tag 4</h1>
        </div>          
      </div>
  </div>`
  explainData['H3'] = explainData['H2']
  explainData['H4'] = explainData['H2']
  explainData['H5'] = `<div>
    <span class="wrap-long-tooltip">
      H5 and H6, are typically sub headers that divide content on a page.
      They often descend in font size.
      For example, an H6 is usually smaller in font size than and H5.
      They also descend in signal stregth.
      They use HTML tags &lt;h5&gt; &lt;/h5&gt;, &lt;h6&gt; &lt;/h6&gt;
    </span>
      <div class="html-result-container">
        <div>
          <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
          &lt;h5&gt;Heading Tag 5&lt;/h5&gt; <br/>
          &lt;h6&gt;Heading Tag 6&lt;/h6&gt; <br/>
        </div>
        <div>
          <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
          <h1 class="h5-size">Heading Tag 5</h1>
          <h1 class="h6-size">Heading Tag 6</h1>
        </div>          
      </div>
  </div>`
  explainData['H6'] = explainData['H5']
  explainData['Image alt'] = `
  <span class="wrap-long-tooltip">
    Image Alt is text read by search engines that describes an image.
    The original idea behind Image Alt was to help the visually impared understand
    what was contained in an image when they read a web page.
    Your Image Alts should accurately describe your images.
    If the images are relevant to your target keyword it shouldn't be difficult
    to include target keywords or variations in your Image Alts, when appropriate.
    Image Alt uses the HTML &lt;img src=&quot;name-of-image.jpg&quot; alt=&quot;description of image&quot;&gt;
  </span>
  `
  explainData['Anchor Text'] = `<div>
  <span class="wrap-long-tooltip">
  Anchor text is the clickable text of a link on a page.
  That link could be an internal link or an external link.
  The tool will count both the same.
  The tool is not counting anchor text coming to your page via backlinks.
  </span>
  <div class="html-result-container">
    <div>
      <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
      &lt;a href=&quot;https://pageoptimizer.pro/&quot;&gt;click me&lt;/a&gt;
    </div>
    <div>
      <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
      <a href="https://pageoptimizer.pro/">click me</a>
    </div>          
  </div>
</div>`
  explainData['Bold'] = `<div>
  <span class="wrap-long-tooltip">
    Bold text is text that you can see on the page that is bold.
    The tool will count the HTML tags &lt;b&gt; and &lt;strong&gt; in this signal as bold.
  </span>
  <div class="html-result-container">
    <div>
      <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
      &lt;b&gt;This is bold text&lt;/b&gt; <br/>
      &lt;strong&gt;This is strong text&lt;/strong&gt;
    </div>
    <div>
      <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
      <b>This is bold text</b> <br/>
      <strong>This is strong text</strong>
    </div>          
  </div>
</div>`
  explainData['Italic'] = `<div>
  <span class="wrap-long-tooltip">
    Italic text is text that you can see on the page that is in italics.
    The tool will count the HTML tags &lt;i&gt; and &lt;em&gt; in this signal as italics.
  </span>
  <div class="html-result-container">
    <div>
      <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
      &lt;i&gt;This is italic text&lt;/i&gt; <br/>
      &lt;em&gt;This is emphasis text&lt;/em&gt;
    </div>
    <div>
      <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
      <i>This is italic text</i> <br/>
      <em>This is emphasis text</em>
    </div>          
  </div>
</div>`
  explainData['H2 to H3'] = `<div>
  <span class="wrap-long-tooltip">
    H2-H3; H2-H6; H4-H6: These groupings are generated to provide perspective.
    There is some rounding involved when the tool does the calculations for the recommendations.
    It's possible that the rounding could lead to imprecise optimization.
    This grouping section then provides perspective that you may be leaning toward over or under optimization as the case may be.
    These groupings are not considered for the on page score.
  </span>
</div>`
  explainData['H2 to H6'] = explainData['H2 to H3']
  explainData['H4 to H6'] = explainData['H2 to H3']
  explainData['Paragraph Text'] = `<div>
    <span class="wrap-long-tooltip">
      Paragraph text is usually the main content of a web page.
      This text is commonly referred to as &ldquo;body content.&rdquo;
      Paragraph text uses the HTML tag &lt;p&gt; &lt;/p&gt;.
      Any content on the page that uses &lt;p&gt; tags will be counted by the tool.
      The tool will also count text that is in lists as paragraph text as well even if
      that list in not wrapped in &lt;p&gt; tags.  Lists use the HTML tags, &lt;ul&gt; &lt;/ul&gt;
      or &lt;ol&gt; &lt;/ol&gt;.  If a keyword or variation in paragraph text is also bold &lt;b&gt;,
      strong &lt;strong&gt;, italics &lt;i&gt;, or emphasis &lt;em&gt;, it will be counted as paragraph text.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;p&gt;Lorem ipsum. Lorem ipsum dolor sit amet&lt;/p&gt; <br/>
        &lt;ol&gt; <br/>
        This is an Ordered List <br/>
        &lt;li&gt;First item in this list&lt;/li&gt; <br/>
        &lt;li&gt;Second item in this list&lt;/li&gt; <br/>
        &lt;li&gt;Third item in this list&lt;/li&gt; <br/>
        &lt;/ol&gt; <br/>

        &lt;ul&gt; <br/>
        This is an Unordered List <br/>
        &lt;li&gt;First item in this list&lt;/li&gt; <br/>
        &lt;li&gt;Second item in this list&lt;/li&gt; <br/>
        &lt;li&gt;Third item in this list&lt;/li&gt; <br/>
        &lt;/ul&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong>
        <p>Lorem ipsum. Lorem ipsum dolor sit amet</p>
        <br/>
        <ol>
          This is an Ordered List
          <li>First item in this list</li>
          <li>Second item in this list</li>
          <li>Third item in this list</li>
        </ol>
        <br/>

        <br/>
        <ul style="list-style-type: circle;">
        This is an Unordered List
        <li>First item in this list</li>
        <li>Second item in this list</li>
        <li>Third item in this list</li>
        </ul>
        <br/>
      </div>          
    </div>
  </div>`
  explainData['HTML Head & Body Tags'] = `<div>
    <span class="wrap-long-tooltip">
    HTML Head and Body tags come from your source code and includes things like: schema, open graph, URLs, named HTML classes, etc.
    A good practice is to see if your competitors are using these signals in great quantities and getting a clear advantage over you with them.
    If not, when creating an optimization strategy, this is the signal that you should optimize last.
    In order to see your source code, right click on your page and then select "view source code."
    From there, you can control/command F to look for your target keyword and variations.
    </span>
  </div>`
  explainData['Word Count'] = 'This is the average word count of your competitors. We recommend being within 10%, higher or lower of your competitor average.'

  explainData['Meta Keywords'] = `<div>
    <span class="wrap-long-tooltip">
    Meta keywords are an html tag that tell search engines the main topic of the page.
    It is a very old SEO technique that many feel is no longer a ranking factor.
    The signal is difficult to test in Single Variable Test environments but will often correlate to rank.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;meta name=&quot;keywords&quot; content=&quot;wood, furniture, garden, etc.&quot;&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        Not visible on the page
      </div>          
    </div>
  </div>`

  explainData['Meta Description'] = `<div>
    <span class="wrap-long-tooltip">
    Meta description is an html tag that describes the content of a page.
    The meta description will often appear in Search Engine Result Pages.
    Many in the SEO community do not believe that meta descriptions are a direct ranking factor.
    Using newer testing techniques the meta description has proved to be a weak signal.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;meta name=&rdquo;description&rdquo; content=&rdquo;Here is a precise description of my awesome webpage.&rdquo;&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        Not visible on the page
      </div>          
    </div>
  </div>`

  explainData['Open Graph Title'] = `<div>
    <span class="wrap-long-tooltip">
    Open Graph are a series of html tags that Facebook uses to display links in Facebook posts.
    Open Graph Title is the title that is displayed in the Facebook post along with the link.
    Search engines crawl this code.
    It is possible that Open Graph Title is a weak ranking signal.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;meta property=&rdquo;og:title&rdquo; content=&rdquo;Your eye-catching title here&rdquo; /&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        Not visible on the page
      </div>          
    </div>
  </div>`

  explainData['Open Graph Description'] = `<div>
    <span class="wrap-long-tooltip">
    Open Graph are a series of html tags that Facebook uses to display links in Facebook posts.
    Open Graph Description is the description that is displayed in the Facebook post along with the link.
    Search engines crawl this code.
    The Open Graph Description is a weak ranking signal.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;meta property=&rdquo;og:description&rdquo; content=&rdquo;Your description of the page here.  This is often the meta description.&rdquo; /&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        Not visible on the page
      </div>          
    </div>
  </div>`

  explainData['Twitter Card Title'] = `<div>
    <span class="wrap-long-tooltip">
    Twitter Cards are a series of html tags that Twitter uses to display links in Tweets.
    Twitter Card Title is the title that is displayed in the Tweet along with the link.
    Search engines crawl this code.
    It is possible that Twitter Card Title is a weak ranking signal.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;meta name=&rdquo;twitter:title&rdquo; content=&rdquo;Your Title&rdquo; /&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        Not visible on the page
      </div>          
    </div>
  </div>`

  explainData['Twitter Card Description'] = `<div>
    <span class="wrap-long-tooltip">
    Twitter Cards are a series of html tags that Twitter uses to display links in Tweets.
    Twitter Card Description is the description that is displayed in the Tweet along with the link.
    Search engines crawl this code.
    It is possible that Twitter Card Description is a weak ranking signal.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;meta name=&rdquo;twitter:description&rdquo; content=&rdquo;Your description.&rdquo; /&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        Not visible on the page
      </div>          
    </div>
  </div>`
  
  explainData['Image File Names'] = `<div>
    <span class="wrap-long-tooltip">
    Every image on a site has a file name.
    This file name is crawled by Search Engines in a webpage's source code.
    The image file name is a weak ranking signal.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;img src='file-name.jpg' /&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        The image is shown in the webpage
      </div>          
    </div>
  </div>`

  explainData['Figure Caption'] = `<div>
    <span class="wrap-long-tooltip">
    Figure Caption is an html tag that creates a caption a figure.
    A figure can be many things such as a graph, chart, or image.
    Figure Caption is a weak ranking signal.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;figcaption&gt;Fig. 1 - Graph showing something&lt;/figcaption&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        Fig. 1 - Graph showing something
      </div>          
    </div>
  </div>`

  explainData['Source Code URLs'] = `<div>
    <span class="wrap-long-tooltip">
    These are the URLs that can be seen in a webpage's source code.
    The URLs could be several things, such as the location of an image, a script, or the destination of a backlink.
    These URLs are often only seen in the source code and are not visible to the visitor of the webpage.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;script type='text/javascript' src='https://example.com/wp-includes/js/jquery/jquery.js'&gt;&lt;/script&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        This script is not visible on the webpage
      </div>          
    </div>
  </div>`

  explainData['Microdata (schema format)'] = `<div>
    <span class="wrap-long-tooltip">
    Schema are tags that embedded in web pages that help search engines understand a web page's content.
    Schema tags define things such as: products, images, people, local businesses, etc.
    Schema tags are only seen by search engines in a webpage's source code.
    Microdata is one common form of schema.
    You can see all the different types of schema available for markup at schema.org.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;div itemscope itemtype=&quot;http://schema.org/TelevisionChannel&quot;&gt;<br/>
          &lt;span itemprop=&quot;name&quot;&gt;WAAY 7&lt;/span&gt;<br/>
          &lt;span itemprop=&quot;broadcastChannelId&quot;&gt;7&lt;/span&gt;<br/>
          &lt;span itemprop=&quot;broadcastServiceTier&quot;&gt;Premium&lt;/span&gt;<br/>
          &lt;div itemprop=&quot;inBroadcastLineup&quot; itemscope itemtype=&quot;http://schema.org/CableOrSatelliteProvider&quot;&gt;<br/>
            &lt;span itemprop=&quot;name&quot;&gt;Comcast&lt;/span&gt;<br/>
          &lt;/div&gt;<br/>
          &lt;div itemprop=&quot;providesBroadcastService&quot; itemscope itemtype=&quot;http://schema.org/BroadcastService&quot;&gt;<br/>
            &lt;span itemprop=&quot;name&quot;&gt;WAAY-TV&lt;/span&gt;<br/>
            &lt;span itemprop=&quot;broadcastDisplayName&quot;&gt;ABC&lt;/span&gt;<br/>
            &lt;div itemprop=&quot;broadcastAffiliateOf&quot; itemscope itemtype=&quot;http://schema.org/Organization&quot;&gt;<br/>
              &lt;span itemprop=&quot;name&quot;&gt;ABC&lt;/span&gt;<br/>
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        WAAY is channel 7 on Comcast cable. <br/><br/>
        The schema you see on the left is not visible on the webpage
      </div>          
    </div>
  </div>`

  explainData['RDF (schema format)'] = `<div>
    <span class="wrap-long-tooltip">
    Schema are tags that embedded in web pages that help search engines understand a web page's content.
    Schema tags define things such as: products, images, people, local businesses, etc.
    Schema tags are only seen by search engines in a webpage's source code.
    Rich Data Format (RDF) is one common form of schema.
    You can see all the different types of schema available for markup at schema.org.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;div vocab=&quot;http://schema.org&quot; typeof=&quot;TelevisionChannel&quot;&gt;<br />
          &lt;span property=&quot;name&quot;&gt;WAAY 7&lt;/span&gt;<br />
          &lt;span property=&quot;broadcastChannelId&quot;&gt;7&lt;/span&gt;<br />
          &lt;span property=&quot;broadcastServiceTier&quot;&gt;Premium&lt;/span&gt;<br />
          &lt;div property=&quot;inBroadcastLineup&quot; typeof=&quot;CableOrSatelliteService&quot;&gt;<br />
            &lt;span property=&quot;name&quot;&gt;Comcast&lt;/span&gt;<br />
          &lt;/div&gt;<br />
          &lt;div property=&quot;providesBroadcastService&quot; typeof=&quot;BroadcastService&quot;&gt;<br />
            &lt;span property=&quot;name&quot;&gt;WAAY-TV&lt;/span&gt;<br />
            &lt;span property=&quot;broadcastDisplayName&quot;&gt;ABC&lt;/span&gt;<br />
            &lt;div property=&quot;broadcastAffiliateOf&quot; typeof=&quot;Organization&quot;&gt;<br />
              &lt;span property=&quot;name&quot;&gt;ABC&lt;/span&gt;<br />
            &lt;/div&gt;<br />
          &lt;/div&gt;<br />
        &lt;/div&gt;<br />
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        WAAY is channel 7 on Comcast cable.<br/><br/>
        The schema you see on the left is not visible on the webpage
      </div>          
    </div>
  </div>`

  explainData['JSON LD (schema format)'] = `<div>
    <span class="wrap-long-tooltip">
    Schema are tags that embedded in web pages that help search engines understand a web page's content.
    Schema tags define things such as: products, images, people, local businesses, etc.
    Schema tags are only seen by search engines in a webpage's source code.
    JSON LD is one common form of schema.
    You can see all the different types of schema available for markup at schema.org.
    </span>
    <div class="html-result-container">
      <div>
        <strong style="font-size: 22px; line-height: 25px;">HTML</strong> <br/>
        &lt;script type=&quot;application/ld+json&quot;&gt;<br/>
        {<br/>
          &quot;@context&quot;:&quot;http://schema.org&quot;,<br/>
          &quot;@type&quot;:&quot;TelevisionChannel&quot;,<br/>
          &quot;name&quot;: &quot;WAAY 7&quot;,<br/>
          &quot;broadcastChannelId&quot;:&quot;7&quot;,<br/>
          &quot;broadcastServiceTier&quot;:&quot;Premium&quot;,<br/>
          &quot;inBroadcastLineup&quot;:{<br/>
            &quot;@type&quot;:&quot;CableOrSatelliteService&quot;,<br/>
            &quot;name&quot;: &quot;Comcast&quot;<br/>
          },<br/>
          &quot;providesBroadcastService&quot;:{<br/>
            &quot;@type&quot;:&quot;BroadcastService&quot;,<br/>
            &quot;name&quot;: &quot;WAAY-TV&quot;,<br/>
            &quot;broadcastDisplayName&quot;: &quot;ABC&quot;,<br/>
            &quot;broadcastAffiliateOf&quot;:{<br/>
              &quot;@type&quot;:&quot;Organization&quot;,<br/>
              &quot;name&quot;:&quot;ABC&quot;<br/>
            }<br/>
          }<br/>
        }<br/>
        &lt;/script&gt;<br/>
      </div>
      <div>
        <strong style="font-size: 22px; line-height: 25px;">Result</strong> <br />
        WAAY is channel 7 on Comcast cable.<br/><br/>
        The schema you see on the left is not visible on the webpage
      </div>          
    </div>
  </div>`

  export default explainData;