<template>

<div class="wow fadeIn flex-grow-1 rows-with-two-col" data-wow-duration="1s">
  <div class="main-padding left-main-area">
    <div>
      <img class="report-top-left-icon" src="/static/POP@1.png">
      <i class="is-pulled-right material-icons newpage-close-button darkGrayColor--text" @click="confirmCloseModal=true">close</i>
    </div>
    <div class="three-rows-grid" v-show="currentPage==1">
      <div>
        <i class="material-icons back-button darkGrayColor--text" @click="$router.go(-1)">keyboard_backspace</i>
      </div>          
      <div class="is-vertical-center">
        <div class="full-width-container">
          <div class="level1-size title-font mb-2 wow_disaled slideInLeft mainColor--text d-flex align-center gap10" data-wow-delay="0s">
            Create a new Project
            <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
              @click="() => {
                $store.commit('setCurrentVideoTutorialToDisplay', {
                  title: 'How to Set Up a New Project in POP',
                  videoLink: 'https://www.youtube.com/embed/z_Mu-86IFfc?si=gJXmtnhVcuC6rWDc',
                  description: 'Learn how to set up a new project in POP.',
                  time: '2m 23s'
                });
              }">
              videocam
            </span>
          </div>
          <div class="level2-size mb-7 wow_disaled slideInLeft mainColor--text" data-wow-delay="0.3s">
            Copy & paste the domain of the webpage you want to optimise
          </div>
          <div class="field has-addons " data-wow-delay="0.6s">
            <div class="control is-expanded site-url-input-wrapper" :class="{ 'success': isUrlEnteredAsGood }">
              <input type="text" class="input is-large mainColor--text" id="url" ref="url" v-model="url" placeholder="https://www.yourdomain.com" @keyup.enter="validateDomainProcess">
            </div>
          </div>
          <div class="mt5 level3-size wow_disaled slideInLeft darkGrayColor--text" data-wow-delay="0.8s">
            It's easiest to just <b>copy and paste</b> the homepage URL from your browser.
          </div>
        </div>
      </div>
      <div class="is-bottom-right">
        <v-btn rounded
          color="profileAnchorColor"
          class="button-with-icon wow_disaled fadeInRight whiteColor--text text-none" data-wow-delay="0.9s" 
          :class="{ 'ready-button': isUrlEnteredAsGood}" :disabled="!isUrlEnteredAsGood" @click="validateDomainProcess">
          <span>Next</span>
          <i class="material-icons">arrow_forward</i>
        </v-btn>
      </div>
    </div>

    <div class="three-rows-grid" v-show="currentPage==2">
      <div>
        <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="goPrevPage">keyboard_backspace</i>
      </div>          
      <div class="is-vertical-center">
        <div class="full-width-container">
          <div class="level1-size mb-5 mainColor--text wow_disaled slideInLeft" data-wow-delay="0s">What's the name of the project?</div>
          <div class="level2-size mb20 mainColor--text wow_disaled slideInLeft" data-wow-delay="0.3s">
            <b>Name</b> the project (so you can identify it)
            <span class="is-pulled-right has-text-grey">Required</span>
          </div>
          <div class="field has-addons " data-wow-delay="0.6s">
            <div class="control is-expanded">
              <input type="text" class="input is-large" id="name" ref="projectName" v-model="name" placeholder="Project Name" @keyup.enter="addNewSite">
            </div>
          </div>
          <div class="level3-size darkGrayColor--text mt-6 wow_disaled slideInLeft" data-wow-delay="0.8s">
            If you work on multiple websites, often the project name will be the website name.
            You can re-run & re-edit an existing project and its pages once setup
          </div>
        </div>
      </div>
      <div class="is-bottom-right">
        <v-btn rounded
          color="profileAnchorColor"
          class="button-with-icon text-none whiteColor--text wow_disaled fadeInRight" ref="addNewSiteBtn" data-wow-delay="0.9s"
          :class="{ 'ready-button': name}"
          :disabled="!name" @click="addNewSite">
          <span>Next</span>
          <span><i class="material-icons">arrow_forward</i></span>
      </v-btn>
      </div>
    </div>
  </div>


  <div class="right-main-area main-padding">
    <div style="min-height: 100px;"></div>

    <div class="new-project mb30 mainColor--text">
      <span>New Domain</span>
    </div>
    <div class="mb20 is-size-6 notification is-warning has-text-center" v-if="errorMsg">
      Error: {{ errorMsg }}
    </div>

    <div class="mb20 is-size-6 mainColor--text">
      <b>Project Domain</b>
      <div class="word-break-all">{{ url }}</div>
    </div>
    <div class="is-size-6 mainColor--text">
      <b>Project Name</b> 
      <div class="word-wrap">{{ name }}</div>
    </div>
  </div>



  <div class="modal" :class="{'is-active': showConfirmModal}">
    <div class="modal-background" @click="showConfirmModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div v-if="errorMsg==''" class="title-font is-size-4 mb20 mainColor--text">Confirm domain name</div>
        <div v-if="errorMsg!=''" class="subtitle-font has-text-danger">
          We aren't able to find {{ url }}, it's possible that the site isn't live yet.  Do you still wish to proceed with that domain?
        </div>
        <div v-else class="subtitle-font mainColor--text">
          Please double check that this domain name is correct.  Does the domain use http:// or https://?  Do you see the correct TLD (.com, .io, etc)?
        </div>
        <div class="mt-5 control">
          <input type="text" class="input is-large mainColor--text" id="completeDomain" v-model="completeDomain" />
        </div>
        <div class="mt-5 mainColor--text">
          You can <b>edit the domain name above</b> - we need this to be accurate as we use it to create the project.<br/>
          <div v-if="!isDomainHasProtocol" class="has-text-danger is-size-7">Your project domain must start with either http:// or https://</div>
        </div>
        <div class="mt-5 text-right">
          <v-btn
            rounded
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text" ref="domainConfirmBtn"
            @click="goNextPage" :disabled="!isDomainHasProtocol">
            <span>Confirm</span>
            <i class="material-icons">arrow_forward</i>
          </v-btn>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showConfirmModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>
  <trial-conversion-modal ref='trialConversion' />



  <div class="modal" :class="{'is-active': confirmCloseModal}">
    <div class="modal-background" @click="confirmCloseModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="title-font is-size-4 mainColor--text mb20">Do you want to leave this page?</div>
          <div class="subtitle-font mainColor--text">
            You will lose any of the information that you have entered.
          </div>
          <div class="control d-flex mt-5">
            <div class="flex-grow-1">
            </div>
            <v-btn rounded outlined
              color="mainColor"
              class="button-with-icon text-none" @click="confirmCloseModal = false;">
              <span>No</span>
            </v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click="confirmCloseModal = false; $router.go(-1);">
              <span>Yes</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="confirmCloseModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>


</div>

</template>

<script>
import AppMenu from '@/components/Menu.vue'
import TrialConversionModal from '@/components/TrialConversionModal.vue'
import { mixin } from '@/components/page_analysis_mixin'

export default {
  components: { AppMenu, TrialConversionModal },
  mixins: [mixin],
  data () {
    return {

      currentPage: 1,


      name: '',
      url: '',
      errorMsg: '',

      completeDomain: '',
      useAdvancedStrategyByDefault: false,
      showConfirmModal: false,

      isShowBallon: true,
    }
  },
  computed: {
    isDomainHasProtocol () {
      return this.completeDomain && (this.completeDomain.startsWith('http://') || this.completeDomain.startsWith('https://'))
    },
    isUrlEnteredAsGood () {
      let url = this.url.toLowerCase()
      if (!url) { return false }
      if(!this.isValidUrl ) {
        return false
      }
      return true
    },
    isValidUrl () {
      if (!this.url.includes('.') || this.url.endsWith('.') || this.url.startsWith('.')) {
        return false
      }
      var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      let url = this.url.toLowerCase()
      if (!url.startsWith('http')) {
        url = 'http://' + url
      }
      return regexp.test(url)
    },
    validationIssues () {
      const validationIssues = []
      !this.name ? validationIssues.push('Enter a name for this project') : null
      
      let url = this.url.toLowerCase()
      if (!url) {
        validationIssues.push('Enter a domain')
      } else {
        if(!this.isValidUrl ) {
          validationIssues.push('Please enter the valid domain')
        }
      }
      return validationIssues
    }
  },
  methods: {
    goNextPage () {
      switch(this.currentPage) {
        case 1:
          this.name = this.autoGenerateProjectName()
          setTimeout(() => { this.$refs.projectName.focus() }, 100);
          break;
        case 2:
          break;
        default:
          break;
      }
      this.currentPage = this.currentPage + 1;
      this.showConfirmModal = false
    },
    goPrevPage () {
      this.currentPage = this.currentPage - 1;
    },
    getHostName(url) {
      if (url.indexOf("//") > -1) {
          return url.split('/')[2];
      }
      else {
          return url.split('/')[0];
      }   
    },
    autoGenerateProjectName () {
      // let d_domain = this.getHostName(this.url).split('.')
      // let projectName = d_domain.length>2 ? d_domain[d_domain.length-2] : d_domain[0]
      let projectName = this.getHostName(this.url)
      if (projectName.startsWith('www.')) {
        projectName = projectName.substring(4)
      }

      let isExist = true
      let projectNames = this.$store.state.sites.map(site => site.name)
      let autoNumber = 0
      let tempName = '';
      while(isExist) {
        tempName = autoNumber > 0 ? projectName + autoNumber : projectName
        isExist = projectNames.indexOf(tempName)!=-1
        if (isExist) {
          autoNumber++;
        }
      }
      console.log('Auto generating project name', projectNames, tempName)
      return tempName 


    },
    extractHostnameWithProtocol(url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
        hostname = this.getHostName(url)
        if (!this.url.includes(hostname)) hostname = this.getHostName(this.url)  // this validated domain is different to user inputed, then we will use user inputted
        if (url.toLowerCase().startsWith('https')) {
          return 'https://' + hostname
        } else {
          return 'http://' + hostname
        }
    },
    validateDomainProcess () {
      if (!this.isUrlEnteredAsGood) {
        return
      }
      this.errorMsg = ''
      this.$store.commit('showLoading')
      this.$store.dispatch('validateSiteDomain', this.url)
        .then(response => {
          if (response.data.status && response.data.status === 'SUCCESS') {
            this.completeDomain = this.extractHostnameWithProtocol(response.data.url)
          } else if (response.data.status && response.data.status === 'FAILURE') {
            this.errorMsg = response.data.msg
            this.completeDomain = this.extractHostnameWithProtocol(this.url)
          }
          this.showConfirmModal = true
          setTimeout(() => {
            this.$refs.domainConfirmBtn.$el.focus()
          }, 100)
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    addNewSite () {
      if(!this.name) { return }
      let completeDomain = this.completeDomain
      this.showConfirmModal = false
      this.$store.commit('showLoading')
      return this.$store.dispatch('createSite', {
        name: this.name,
        url: completeDomain,
        useAdvancedStrategyByDefault: this.useAdvancedStrategyByDefault ? 1 : 0
      }).then(response => {
          if (response.data.status && response.data.status === 'FAILURE') {
            this.errorMsg = response.data.msg
            this.showConfirmModal = false
            return 1
          } else if (response.data.status && response.data.status === 'UNAVAILABLE_DOMAIN') {
            this.$refs.trialConversion.show()            
          } else {
            this.$store.commit('addSite', response.data.site)
            // return this.$router.push(`/sites/${response.data.site.id}/pages`)
            return this.$router.push(`/sites/${response.data.site.id}/tools`)
          }
        })
        .catch(err => {
          console.log('error', err)
        })
        .finally(() => this.$store.commit('hideLoading'))
    }
  },
  beforeMount () {
    this.checkExtensionIsInstalled()
  },
  mounted () {
    setTimeout(() => {
      this.$refs.url.focus()      
    }, 100);
  },
  beforeDestroy () {
    try {
      document.removeEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
    } catch (e) {
      console.log('can not remove eventlistener for extension')
    }
  }
}
</script>

<style lang="scss">
.site-url-input-wrapper {
  position: relative;
  input {
    padding-right: 40px;
  }
}
.site-url-input-wrapper.success::before {
  position: absolute;
  content: '\f00c';
  font-family: 'FontAwesome';
  color: #4CFF00;
  font-size: 1.5rem;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

</style>
