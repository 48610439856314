<template>
<div class="pl20 pr20 mt-6">
  <!-- {{ campaigns }} -->
  <div class="sites-or-pages-container">
    <div class='site-page-card' v-for="c in campaigns" :key="c.id">
      <div class="top-part border-bottom d-flex align-center">
        <span class="d-flex align-center flex-gap-10">
          <span class="material-icons pin-icon tooltip" :class="{ 'pinned': c.isPinned }"
            :data-tooltip="c.isPinned ? 'Click to unpin' : 'Pin this Page to the top of your dashboard to find it easily.'"
            @click="() => {
              // pinPageSettingAction(c.id, !c.isPinned)
            }">push_pin</span>
          <span class="pagerun-type">{{ c.isDomainLevelCampaign ? 'Domain level' : 'Page level' }}</span>
        </span>
        <div class="flex-grow-1"></div>
        <div>
          <v-btn icon :id="`dot-menu-for-${c.id}`">
            <span class="material-icons-outlined mainColor--text">more_horiz</span>
          </v-btn>
          <v-menu 
            :activator="`#dot-menu-for-${c.id}`"
            bottom offset-y>
            <v-list>
              <!-- <v-list-item link @click="enterPageSetting(c)">
                <v-list-item-title
                  class="mainColor--text">
                  Page setting
                </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="displayDeletePageConfirmModal(c.id)">
                <v-list-item-title
                  class="mainColor--text">
                  Delete
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="main-part">
        <div class="flex-grow-1">
          <div class="mt-3 mainColor--text font-weight-regular">Date created: {{ c.createdAt }}</div>
          <div class="mt-3 project-name font-weight-bold">{{ c.isDomainLevelCampaign ? 'Domain level' : c.campaignName }}</div>
          <div class="mt-3 mainColor--text font-weight-regular">Recent enquiries</div>
          <div class="pages-wrapper mt-3">
            <div class="page-item" @click="() => {
              $router.push(`/sites/${ siteId }/campaign/${ c.id }`)
            }">
              <div class="flex-grow-1">
                <div class="name">Keyword insight</div>
                <div class="time">{{ toLocalDateTimeString(c.createdAt) }}</div>
              </div>
              <div class="action">
                <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
              </div>
            </div>
            <div class="page-item" @click="() => {}">
              <div class="flex-grow-1">
                <div class="name">Keyword tracking</div>
                <div class="time">{{ '' }}</div>
              </div>
              <div class="action">
                <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
              </div>
            </div>
            <div class="page-item" @click="() => {}">
              <div class="flex-grow-1">
                <div class="name">Keyword wizard</div>
                <div class="time">{{ '' }}</div>
              </div>
              <div class="action">
                <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
              </div>
            </div>
          </div>
        </div>        
      </div>

    </div>
  </div>

</div>
</template>

<script>
import { toLocalDateTimeString } from '@/utils'

export default {
  data () {
    return {
      campaigns: [],
    }
  },
  computed: {
    siteId () {
      return this.$route.params.siteId;
    }
  },
  methods: {
    toLocalDateTimeString (d) {
      return toLocalDateTimeString(d)
    },
    loadCampaigns () {
      this.$store.dispatch('getCampaignsForSite', this.siteId)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.campaigns = response.data.campaigns
        }
      })
    }        
  },
  mounted () {
    this.loadCampaigns() 
  }
}
</script>