<template>
<div>
<section>
  <div class="hero white-background is-fullheight">
    <div class="hero-body">
      <div class="container">
          <div class="columns is-centered">
            <div class="column is-10-tablet is-10-desktop is-10-widescreen">
              <div>
                  <div class="field has-text-centered">
                      <p v-if="globalErrorMsg" class="has-text-danger">{{ globalErrorMsg }}</p>
                  </div>
                  <div class="field">
                      <h1 class="title has-text-black is-size-1">Reset Password</h1>
                  </div>
                  <div class="field">
                      <label class="label has-text-black">Email</label>
                      <div class="control has-icons-left">
                          <input type="email" placeholder="example@mail.com" class='input is-large' v-model="email" required>
                          <span class="icon is-small is-left">
                              <i class='fa fa-envelope'></i>
                          </span>
                      </div>
                  </div>

                  <div class="field">
                      <button class="button is-success" @click.stop="submit">Reset Password</button> &nbsp; &nbsp;
                      <span v-if="message" class="subtitle" :class="{ 'has-text-success': !hasError, 'has-text-danger': hasError }">{{ message }}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</section>

</div>
</template>

<script>
export default {
  data () {
    return {
      email: '',
      message: '',
      hasError: false,
    }
  },
  methods: {
    submit () {
      if (this.email !== '') {
        this.hasError = false
        this.$store.commit('showLoading');
        this.$store.dispatch('resetPassword', { email: this.email })
          .then((response) => {
            if (response.data && response.data.status == 'SUCCESS') {
              this.message = "Please check your email for a link to reset your password"
            } else {
              this.hasError = true
              this.message = 'We\'re sorry, we couldn\'t locate an account with this email.'
            }
          })
          .catch(error => {
            console.log("Error changing password", error)
          })
          .finally(() => {
            this.$store.commit('hideLoading');
          })
      }
    }
  },
  computed: {
    globalErrorMsg () {
      return this.$store.state.globalErrorMsg
    }
  }
}
</script>

<style scoped>
.box {
    padding: 30px 50px;
}
</style>