<template>
<div class="simple-bar-chart">
  <canvas :id="id" style="width: 100%;" height="300"></canvas>
</div>
</template>

<script>
import Chart from 'chart.js'
import { smallUniqueId } from '@/utils'
export default {
  props: ['labels', 'dataSeries', 'dataSeriesLabel', 'displayLegend'],
  data () {
    return {
      id: smallUniqueId(),
      chart: null
    }
  },
  watch: {
    'dataSeries': function(newVal) {
      this.dataSeries = newVal
      this.update()
    }
  },
  methods: {
    update () {

    }
  },
  beforeMount () {
    setTimeout(() => {
      const backgroundColor = [
        "rgb(54, 162, 235)", // blue:
        "rgb(75, 192, 192)", // green: 
        "rgb(255, 159, 64)", // orange: 
        "rgb(201, 203, 207)", // grey: 
        "rgb(153, 102, 255)", // purple: 
        "rgb(255, 99, 132)", // red: 
        "rgb(255, 205, 86)" // yellow: 
      ]

      const el = document.getElementById(this.id)
      let datasets = []
      for (let i=0; i< this.dataSeries.length; i++) {
        datasets.push({
          label: this.labels[i],
          data: [ this.dataSeries[i] ],
          backgroundColor: [ backgroundColor[i] ],
        })
      }
      const options = {
        type: 'bar',
        data: {
          labels: [this.dataSeriesLabel],
          datasets: datasets
        },
        options: {
          responsive: true,
          legend: { possition: 'top', display: !!this.displayLegend },
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
        }
      }
      this.chart = new Chart(el, options)
    }, 300)
  }
}
</script>

<style>
  
</style>