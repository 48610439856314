<template>
<div class="pagerun-view mt-5" v-if="pageRun">
  <div>
    <div class="custom-container">
      <div v-if="isAPIRunViewMode" class="subtitle-font font-weight-bold mainColor--text mb-5">
        API report view        
      </div>
      <!-- <li :class="{'is-active': activeTab === 'report_history'}"><a @click="showTab('report_history')">REPORT HISTORY</a></li> -->
      <div class="pagerun-tabs">
        <ul>
          <li v-if="pageRun.isGuardRun" :class="{'is-active': standardOrAdvanced === 'guardRunView'}">
            <a @click="changeStandardOrAdvanced('guardRunView')">
              <span>WatchDog Report</span>
            </a>
          </li>
          <template v-else>
            <li :class="{'is-active': activeTab == 'report'}">
              <a @click="showTab('report')">
                <span>Content brief</span>
              </a>
            </li>
            <li :class="{'is-active': ['contentPrompt', 'tagTotals'].includes(activeTab) }">
              <a @click="showTab('contentPrompt')">
                <span>Page structure</span>
              </a>
            </li>
            <li :class="{'is-active': activeTab === 'nlpSchemaType'}">
              <a @click="showTab('nlpSchemaType')">
                <span>Google NLP</span>
              </a>
            </li>
            <li :class="{'is-active': activeTab === 'eatSchemaType'}">
              <a @click="showTab('eatSchemaType')">
                <span>EEAT signals</span>
                <flower-spinner
                  style="padding-left: 3px;"
                  v-if="eatDoneStatus === 'PENDING' || eatDoneStatus === 'PROCESSING'"
                  :animation-duration="2000"
                  :size="15"
                  :color="'#FF9B2D'"
                />
                <i v-else-if="eatDoneStatus === 'SUCCESS'" class="material-icons eat-done">check_circle_outline</i>
              </a>
            </li>
            <li :class="{'is-active': activeTab === 'schemaType'}">
              <a @click="showTab('schemaType')">
                <span>Schema</span>
              </a>
            </li>
            <li :class="{ 'is-active': activeTab==='variationAndLSI' }">
              <a @click="showTab('variationAndLSI')">
                <span>
                  LSI & Variations
                </span>
              </a>
            </li>
            <li :class="{'is-active': activeTab === 'to_do'}">
              <a @click="showTab('to_do')">
                <span>Custom strategy</span>
              </a>
            </li>

            <li :class="{'is-active': ['summary', 'keywords', 'adjKeywords'].includes(activeTab) }">
              <a @click="showTab('summary')">
                <span>Advanced analysis</span>
              </a>
            </li>
            <!-- <li
              :class="{'is-active': activeTab === 'workstation'}">
              <a @click="showTab('workstation')">
                <span>
                  Workstation
                </span>
              </a>
            </li> -->
          </template>
          <li class="flex-grow-1"></li>
          <!-- <li :class="{'is-active': activeTab === 'checklist'}"><a @click="showTab('checklist')">Progress Notes</a></li> -->
        </ul>
      </div>

      <template v-if="['contentPrompt', 'tagTotals'].includes(activeTab)">
        <div class="inner-tab mt-5">
          <div :class="{ 'is-active': activeTab == 'contentPrompt' }" @click="showTab('contentPrompt')">
            <span class="material-icons-outlined">list_alt</span>
            Content prompts
          </div>
          <div :class="{ 'is-active': activeTab == 'tagTotals' }" @click="showTab('tagTotals')">
            <span class="material-icons-outlined">dashboard_customize</span>
            Page structure
          </div>
        </div>
      </template>
      <template v-if="['summary', 'keywords', 'adjKeywords'].includes(activeTab)">
        <div class="inner-tab mt-5">
          <div :class="{ 'is-active': activeTab == 'summary' }" @click="showTab('summary')">
            <span class="material-icons-outlined">leaderboard</span>
            Dashboard
          </div>
          <div :class="{ 'is-active': activeTab == 'keywords' }" @click="showTab('keywords')">
            <span class="material-icons-outlined">exposure</span>
            Compare data
          </div>
        </div>
      </template>

      <!-- <div class="columns is-mobile">
        <div class="column"> -->
      <div v-if="standardOrAdvanced == 'standard'">
        <p v-if="errMsg" class='subtitle has-text-centered' style="color: red;">{{ errMsg }}</p>
        <div v-show="activeTab === 'summary' && pageRun.keywordCounts">
          <run-summary 
            :currentPageRun="pageRun"
            :variations="pageRun.variations"
            :lsaVariations="pageRun['lsaVariations']"
            :nlpCommons="pageRun['commonNlpSchemaData']"
            :pageId="page?.id"
            :reportID="pageRun.id"
            :competitors="pageRun.competitors"
            :keyword="pageRun.keyword!=null ? pageRun.keyword : page?.keyword"
            :keywordCounts="pageRun.keywordCounts"
            :adjKeywordCounts="pageRun.adjKeywordCounts"
            :lsiKeywordCounts="pageRun['lsaKeywordCounts']"
            :lsiAdjKeywordCounts="pageRun['lsaAdjKeywordCounts']"
            :excelFile="pageRun.excelFile"
            :targetURL="pageRun.url ? pageRun.url : page?.url"
            :keywordOptimizations="[pageRun.keywordCounts.optimizedKeyword.underOptimized, pageRun.keywordCounts.optimizedKeyword.overOptimized]"
            :variationsOptimizations="[pageRun.keywordCounts.optimizedVariations.underOptimized, pageRun.keywordCounts.optimizedVariations.overOptimize]"
            :note="pageRun.note"
            :mainPageWordCount="mainPageWordCount"
            :avgCompetitorWordCount="avgCompetitorWordCount"
            :avgFeaturedCompetitorWordCount="avgFeaturedCompetitorWordCount"
            :proxy="proxy"
            :isExtensionUsed="pageRun.isExtensionUsed"
            :featuredCompetitors="pageRun.featuredCompetitors"
            :pageNotBuilt="pageRun.pageNotBuilt"
            :lsaPhrases="lsaPhrases"/>
        </div>
        <div v-if="activeTab === 'schemaType'">
          <schema-types
            :pageId="page?.id"
            :targetURL="pageRun.url ? pageRun.url : page?.url"
            :mainPageSchemaTypes="pageRun.schemaTypes ? pageRun.schemaTypes : []"
            :genPageSchemaTypes="pageRun.aiGenSchemaTypes"
            :competitors="pageRun.competitors"
            :pageNotBuilt="pageRun.pageNotBuilt"
            :checkLists="page?.checkLists || []"
            :pageRunId="pageRun.id"
          ></schema-types>
        </div>
        <div v-if="activeTab === 'eatSchemaType'">
          <eat-data-view
            :isEatCalculationUsed="pageRun.isEatCalculationUsed"
            :eatStatus="pageRun.eatStatus"
            :pageId="page?.id"
            :targetURL="pageRun.url ? pageRun.url : page?.url"
            :pageNotBuilt="pageRun.pageNotBuilt"
            :mainPageSchemaTypes="pageRun.eatSchemaTypes ? pageRun.eatSchemaTypes : []"
            :commonEatSchemaTypes="pageRun.commonEatSchemaTypes ? pageRun.commonEatSchemaTypes : []"
            :competitors="pageRun.competitors"
            :checkLists="page?.checkLists || []"
            :nextGenEatSchemas="pageRun.nextGenEatSchemas"
            :devLog="pageRun.devLog"
          ></eat-data-view>
        </div>
        <div v-if="activeTab === 'nlpSchemaType'">
          <nlp-schema-data
            :targetUrl="pageRun.url"
            :pageNotBuilt="pageRun.pageNotBuilt"
            :targetNlpSchemaData="pageRun.nlpSchemaData"
            :competitors="pageRun.competitors"
            :commons="pageRun.commonNlpSchemaData"
          ></nlp-schema-data>
        </div>
        <div v-if="activeTab === 'openAITab'">
          <open-ai-assistance
            :runId="pageRun.id"
            :runStrategies="pageRun.runStrategies"
            :initialData="openAITabData"
            :saveOpenAITabData="saveOpenAITabData"
          ></open-ai-assistance>
        </div>
        <div v-if="activeTab === 'keywords'">
          <signals
            ref="keyword_signals_comp"
            :isFromAdminPage="false"
            :pageRunId="pageRun.id"
            :excelFile="pageRun.excelFile"
            type="Keyword"
            recommendation_type="keyword"
            :signals="pageRun.keywordCounts"
            :lsiSignals="pageRun['lsaKeywordCounts']"
            :adjSignals="pageRun.adjKeywordCounts"
            :adjLsiSignals="pageRun['lsaAdjKeywordCounts']"
            :keyword="pageRun.keyword!=null ? pageRun.keyword : page?.keyword"
            :targetURL="pageRun.url ? pageRun.url : page?.url"
            :variations="pageRun.variations"
            :lsaVariations="pageRun['lsaVariations']"
            :nlpCommons="pageRun['commonNlpSchemaData']"
            :baseURL="site?.baseURL"
            :pageNotBuilt="pageRun.pageNotBuilt"
            :featuredCompetitors="pageRun.featuredCompetitors"
            :pageId="page?.id"
            :checkLists="page?.checkLists || []"
            :lsaPhrases="lsaPhrases" />
        </div>
        <div v-if="!pageRun.pageNotBuilt && activeTab === 'adjKeywords'">
          <signals
            :isFromAdminPage="false"
            :pageRunId="pageRun.id"
            :excelFile="pageRun.excelFile"
            type="Adjusted Keyword"
            recommendation_type="adjKeyword"
            :signals="pageRun.adjKeywordCounts"
            :lsiSignals="pageRun['lsaAdjKeywordCounts']"
            :keyword="pageRun.keyword!=null ? pageRun.keyword : page?.keyword"
            :variations="pageRun.variations"
            :lsaVariations="pageRun['lsaVariations']"
            :nlpCommons="pageRun['commonNlpSchemaData']"
            :baseURL="site?.baseURL"
            :pageNotBuilt="pageRun.pageNotBuilt"
            :featuredCompetitors="pageRun.featuredCompetitors"
            :pageId="page?.id"
            :checkLists="page?.checkLists || []"
            :lsaPhrases="lsaPhrases" />
        </div>
        <div v-show="activeTab === 'tagTotals'">
          <tag-totals 
            :tagCounts="pageRun.tagCounts"
            :featuredTagCounts="pageRun.featuredTagCounts"
            :pageNotBuilt="pageRun.pageNotBuilt"
            :pageId="page?.id"
            :checkLists="page?.checkLists || []"
            />
        </div>
        <div v-show="activeTab === 'variationAndLSI'" class="section pt5">
          <variation-lsi
            :variations="pageRun.variations"
            :secondaryKeywords="pageRun.secondaryKeywords"
            :lsaVariations="pageRun['lsaVariations']"
            :lsaPhrases="lsaPhrases"
            >
          </variation-lsi>
        </div>
        <div v-show="activeTab === 'report_history'">
          <report-history>
            
          </report-history>
        </div>
        <div v-if="activeTab == 'report' || activeTab == 'contentPrompt' || activeTab == 'to_do'">
          <checklist
            ref="checklist_component"
            :selectedMainTab="standardOrAdvanced"
            type="Keyword"
            :signals="pageRun.keywordCounts"
            :adjSignals="pageRun.adjKeywordCounts"
            :lsiSignals="pageRun['lsaKeywordCounts']"
            :lsiAdjSignals="pageRun['lsaAdjKeywordCounts']"
            :keyword="pageRun.keyword!=null ? pageRun.keyword : page?.keyword"
            :targetURL="pageRun.url ? pageRun.url : page?.url"
            :variations="pageRun.variations"
            :lsaVariations="pageRun['lsaVariations']"
            :nlpCommons="pageRun['commonNlpSchemaData']"
            :baseURL="site?.baseURL"
            :pageNotBuilt="pageRun.pageNotBuilt"
            :tagCounts="pageRun.tagCounts"
            :pageId="page?.id"
            :pageRunId="pageRun.id"
            :mainPageSchemaTypes="pageRun.schemaTypes ? pageRun.schemaTypes : []"
            :competitors="pageRun.competitors"
            :checkLists="page?.checkLists || []"
            :lsaPhrases="lsaPhrases"
            :relatedSearches="pageRun.relatedSearches"
            :relatedQuestions="pageRun.relatedQuestions"
            :gCompetitors="pageRun.gCompetitors"
            :defaultTabOfContentBrief="['report', 'contentPrompt'].includes(activeTab) ? activeTab : ''"/>
        </div>
      </div>
        <!-- </div>
      </div> -->

      <div v-if="standardOrAdvanced == 'guardRunView'">
        <guard-run
          :page="page"
          :currentPageRun="pageRun"
          :openPageSettingsModal="enterPageSetting"
          :showContentBrief="() => { changeStandardOrAdvanced('standard') }"></guard-run>
      </div>
    </div>
    <run-best-practices-model ref='bestPractices'/>
  </div>

  <div class="modal" :class="{'is-active': showSchemaExamplePopup}">
    <div class="modal-background" @click="showSchemaExamplePopup = false"></div>
    <div class="modal-content" style="min-width: 70%;">
      <div class="box modal-container">
        <iframe :src="schemaExampleLink" width="100%" style="min-height: 50vh;" frameborder="0"></iframe>
        <div class="field has-addons">
          <div class="control">
            <button class="button ready-button" @click="showSchemaExamplePopup = false">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="modal" :class="{'is-active': showEATDoneModal}">
    <div class="modal-background" @click="showEATDoneModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div v-if="eatDoneStatus=='SUCCESS'" class="mb-5">
            <div class="subtitle-font mainColor--text">EEAT analysis is complete.</div>
          </div>
          <div v-else-if="eatDoneStatus=='FAILURE'" class="mb-5">
            <div class="subtitle">Failed to complete EEAT analysis.</div>
            <div class="level2-size mb-10 wow_disaled slideInLeft" data-wow-delay="0.3s">
              Please give your Report ID Number to POP support to investigate the issue.
            </div>
          </div>
          <div class="text-right">
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whiteColor--text"
              @click="showEATDoneModal = false; showTab('eatSchemaType')">
              Go to EEAT now
              <!-- <i class="fa fa-arrow-right"></i> -->
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showEATDoneModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>


  <div class="modal" :class="{'is-active': showContentBriefScoreNotChangedModal}">
    <div class="modal-background" @click="showContentBriefScoreNotChangedModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div class="modal-content-root">
          <div class="title-font mainColor--text is-size-4 mb20">Your Score Didn't Change</div>
          <div class="body-font mainColor--text is-size-6">
            And that's ok!  The Content Brief score won't change too much until you have gotten each section into the correct range.<br/><br/>
            Just a heads up, you don't need to re-run POP to check your score or important term counts.  Use the POP Chrome Extension and your score and counts will update in real time.<br/>
            <br/>
            <a href="https://pageoptimizer.pro/pageoptimizer-pro-chrome-extension-guide/" class="is-link get-it-here" target="_blank">Click here to get more info.</a>
          </div>
          <div class="d-flex justify-end mt-5">
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon text-none whiteColor--text"
              @click="showHideContentBriefScoreNotChangedModal">
              <i class="fa fa-check" aria-hidden="true"></i>
              I got it
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showContentBriefScoreNotChangedModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>
  <insufficient-fund-modal ref="insufficientFundModal" />
  <page-run-note :pageRun="pageRun" :showModal="showNoteModal"></page-run-note>

  <page-setting-modal ref="pageSettingModal" :disableDeletePage="true" />

</div>

</template>

<script>
import { HollowDotsSpinner , FlowerSpinner } from 'epic-spinners'

import AppMenu from '@/components/Menu.vue'
import RunSummary from '@/components/PageRun/RunSummary.vue'
import TagTotals from '@/components/PageRun/TagTotals.vue'
import Signals from '@/components/PageRun/Signals.vue'
import Checklist from '@/components/PageRun/Checklist.vue'
import SchemaTypes from '@/components/PageRun/SchemaTypes.vue'
import EatDataView from '@/components/PageRun/eat-data/EatDataView.vue'
import NlpSchemaData from '@/components/PageRun/NlpSchemaData.vue'
import AppCredits from '@/components/AppCredits.vue'
import RunBestPracticesModel from '@/components/PageRun/RunBestPracticesModal.vue'
import VariationLsi from '@/components/PageRun/VariationLsi.vue'
import ReportHistory from '@/components/PageRun/ReportHistory.vue'

import InsufficientFundModal from '@/components/InsufficientFundModal.vue'
import PageNoteModal from '@/components/PageNoteModal.vue'
import PageSettingModal from '@/components/PageSettingModal.vue'
import { toLocalDateString } from '@/utils'

import GuardRun from '@/components/PageRun/GuardRun/GuardRun.vue'
import { SCHEMA_TYPE_MAPPER } from "@/components/PageRun/constants";

export default {
  name: 'PageRun',
  components: {
    HollowDotsSpinner, FlowerSpinner,

    AppMenu, RunSummary, Signals, Checklist, TagTotals, AppCredits, RunBestPracticesModel,
    SchemaTypes, EatDataView, NlpSchemaData, VariationLsi, ReportHistory,
    InsufficientFundModal,
    PageRunNote: PageNoteModal,
    PageSettingModal,
    GuardRun,
  },
  data () {
    return {
      standardOrAdvanced: 'standard', // standard/guardRunView
      activeTab: '',
      errMsg: '',
      proxy: {},

      schemaExampleLink: 'https://schema.org',
      showSchemaExamplePopup: false,

      updatePageRunTimer: null,

      showEATDoneModal: false,
      eatDoneStatus: 'FAILURE', // SUCCESS, FAILURE
      showContentBriefScoreNotChangedModal: false,

      showNoteModal: false,

      openAITabData: {},
    }
  },
  watch:  {
    '$route.params.pageRunId': function(newId) {
      this.loadPageRun()
    },
  },
  computed: {
    IS_LIVE_MODE () {
      if (IS_LIVE_MODE) {
        return true;
      }
      return false;
    },
    isProReport () {
      return this.pageRun.pageRunType == 1
    },
    isAPIRunViewMode () {
      if (this.$route.query.apiRunView) {
        return true;
      }
      return false;
    },
    user () {
      return this.$store.state.user;
    },
    isAdmin () {
      if (this.user && this.user.isAdmin) {
        return true;
      }
      return false;
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    availableReportCreditsOfUserOrTeam () {
      return this.$store.getters.availableReportCreditsOfUserOrTeam
    },
    canCreateNewPage () {
      if (this.availableReportCreditsOfUserOrTeam >= 2) {
        return true;
      }
      return false;
    },
    canCreateRerun () {
      if (this.availableReportCreditsOfUserOrTeam >= 1) {
        return true;
      }
      return false;
    },
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId))
    },
    pageId () {
      return parseInt(this.$route.params.pageId)
    },
    page () {
      if (!this.site || !this.site.pages.length) {
        return {}
      }
      return this.site.pages.find((p) => p.id === this.pageId)
    },
    pageRun () {
      if (!this.page) {
        return {}
      }
      const pageRunId = parseInt(this.$route.params.pageRunId)
      return this.$store.state.pageRuns.find((r) => r.id === pageRunId)
    },
    needsToDisplaySameScoreModal () {
      if (this.pageRun.pageNotBuilt) {
        return false
      }
      if (!this.page || !this.page?.strategySetting || this.page?.strategySetting.dashboardType != 'contentBrief') {
        return false
      }
      if (this.pageRun.otherSettings && this.pageRun.otherSettings.acknowlegedForContentBriefScoreNotChanged) {
        return false
      }
      let pageRunsInThisPage = this.$store.state.pageRuns
      if (pageRunsInThisPage?.length > 1) {
        pageRunsInThisPage = pageRunsInThisPage?.sort((a, b) => {
          if (a.id < b.id) return 1;
          if (a.id > b.id) return -1;
          return 0;
        })
        if (pageRunsInThisPage[0].id != this.pageRun.id) { // if current pagerun is not latest
          return false
        }
        let currentScore = this.getContentBriefScore(pageRunsInThisPage[0])
        let previousScore = this.getContentBriefScore(pageRunsInThisPage[1])
        if ( Math.abs(currentScore - previousScore) < 0.001) {
          return true
        }
      }
      return false
    },
    targetWordCount () {
      if (this.pageRun.hasOwnProperty('keywordCounts')) {
        const wordCount = this.pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return wordCount.signalData.targetKeywordCnt
      }
      return 0
    },
    avgCompetitorWordCount () {
      if (this.pageRun.hasOwnProperty('keywordCounts')) {
        const wordCount = this.pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return Math.round(wordCount.signalData.competitorsAvg)
      }
      return 0
    },
    avgFeaturedCompetitorWordCount () {
      if (this.pageRun.hasOwnProperty('keywordCounts')) {
        const wordCount = this.pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return Math.round(wordCount.signalData.featuredCompetitorsKeywordAvg)
      }
      return 0
    },
    targetWordCountComment () {
      if (this.pageRun.hasOwnProperty('keywordCounts')) {
        const wordCount = this.pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
        return wordCount.signalData.comment
      }
      return ""
    },
    mainPageWordCount () {
      const wordCount = this.pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
      return wordCount.signalData.targetKeywordCnt
    },
    lsaPhrases () {
      if(this.pageRun['lsaPhrases'] == undefined || !Array.isArray(this.pageRun['lsaPhrases']))  {
        return []
      } else  {
        return this.pageRun.lsaPhrases
          .map(item => { // round weight as 2 digits
            let newItem = Object.assign({}, item)
            newItem.weight = Math.round(newItem.weight * 100) / 100
            return newItem;
          })
      }
    }
    
  },
  methods: {
    showHideContentBriefScoreNotChangedModal () {
      if (this.showContentBriefScoreNotChangedModal) {
        // add pagerun id to localstorage so that it can't display again automatically if needed.
        // don't need this logic now.
        this.$store.dispatch('acknowledgeForContentBriefScoreNotChanged', {
          pageRunId: this.pageRun.id
        })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            if (response.data.pageRun) {
              this.$store.commit('updatePageRun', response.data.pageRun)
            }
          }
        })
      }
      this.showContentBriefScoreNotChangedModal = !this.showContentBriefScoreNotChangedModal
    },
    getContentBriefScore(pageRun) {
      let dashboardType = 'contentBrief'
      let found = pageRun.runStrategies.find(item => {
        if (dashboardType == 'contentBrief') {
          return item.dashboardType == dashboardType
        } else if (dashboardType == 'signals') {
          return item.approach == approach && item.strategy == strategy
        }
        return false
      })
      if (found) {
        return found.pageScore.pageScore
      } else {
        return -1
      }
    },
    openFullPageEditor() {
      let routeData = this.$router.resolve({name: 'FullPageSourceEditor', query: {pageRunId: this.pageRun.id }});
      window.open(routeData.href, '_blank')
    },
    enterPageSetting () {
      // console.log('page is', this.page);
      this.$refs.pageSettingModal.show(this.page);
    },
    showEditSourceModal (isExpressEditor) {
      this.activeTab = 'keywords'
        setTimeout(() => {
          this.$refs.keyword_signals_comp.showEditSourceModal(isExpressEditor)
        }, 100);
    },
    changeActiveTabOfTodo (todoTabName) {
      setTimeout(() => {
        if (this.$refs.checklist_component) {
          this.$refs.checklist_component.changeTodoTab(todoTabName)
        }
      }, 0);
    },
    toLocalDateString (date) {
      return toLocalDateString(date)
    },
    showExamplePopup (type) {
      let baseUrl = 'https://schema.org/';
      let rowType = SCHEMA_TYPE_MAPPER[type.toLowerCase()] || type;
      this.schemaExampleLink = baseUrl + rowType + '#examples'
      console.log('example link', this.schemaExampleLink)
      this.showSchemaExamplePopup = true
    },
    loadSites() {
      this.$store.dispatch('loadSites')
      .then((response) => {
        if (response.data && response.data.status === 'SUCCESS') {
          this.$store.commit('setSites', response.data.sites)
        }
      })
      .finally(() => this.$store.commit('hideLoading'))
    },
    downloadExcelForChecklist(type, isQuickListDownload=false) {
      // downloadExcelForChecklist
      let data = {
        pageId: this.page?.id,
        pageRunId: this.pageRun.id,
        downloadType: type,
        isQuickListDownload: isQuickListDownload
      }
      this.$store.commit('showLoading')
      this.$store.dispatch('downloadExcelForChecklist', data)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          let downloadAllUserUrl = `${API_URL}/download/?file=${response.data.url}`
          let fileName = downloadAllUserUrl.split('/').pop()
          let downloadLink;
          downloadLink = document.createElement("a");
          downloadLink.download = fileName;
          downloadLink.href = downloadAllUserUrl
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          setTimeout(() => {
            document.body.removeChild(downloadLink)            
          }, 100);
        } else {
          console.log('error')
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    addPage () {
      if (this.canCreateNewPage) {
        this.errMsg = ''
        this.$router.push(`/sites/${this.site.id}/newpage`)
      } else {
        this.errMsg = 'Insufficient report balance to create a page?.  Please purchase more reports in your user profile dashboard.'
      }
    },
    showBestPractices () {
      this.$refs.bestPractices.show()
    },
    changeStandardOrAdvanced (name) {
      if (this.standardOrAdvanced == name) {
        return
      }
      this.standardOrAdvanced = name
      switch (this.standardOrAdvanced) {
        case 'guardRunView':
          this.showTab('guardRunView')
          break;
        case 'standard':
          this.showTab('report')
          break;
      }
    },
    showTab (tab) {
      if (this.activeTab == tab) {
        return
      }

      this.activeTab = tab
      this.$store.commit('setPageRunActiveTab', `${tab}-${this.pageRun.id}`)
      switch (this.activeTab) {
        // case 'eatSchemaType':
        //   this.standardOrAdvanced = 'advanced'
        //   break;
        // case 'contentBrief':
        case 'report':
          this.changeActiveTabOfTodo('content_brief')
          break;
        case 'contentPrompt':
          this.changeActiveTabOfTodo('content_brief')
          break;
        case 'to_do':
          this.changeActiveTabOfTodo('simple_list')
          break;
      }
    },
    loadPageRunTimer () {
      this.clearTimer()
      this.eatDoneStatus = 'PROCESSING'
      this.updatePageRunTimer = setInterval(() => {
        if (!this.$route.params || this.$route.params.pageRunId==undefined) {
          this.clearTimer()
          return;
        }
        this.$store.dispatch('fetchPageRun', this.$route.params)
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            let pRun = response.data.pageRun
            this.$store.commit('setCurrentPageRun', pRun)
            this.$store.commit('updatePageRun', pRun)

            if (pRun.eatStatus != 'PENDING' && pRun.eatStatus != 'PROCESSING') {
              this.clearTimer()
            }
            if (pRun.eatStatus === 'SUCCESS') {
              this.eatDoneStatus = 'SUCCESS'
              this.showEATDoneModal = true
            } else if (pRun.eatStatus === 'FAILURE') {
              this.eatDoneStatus = 'FAILURE'
              this.showEATDoneModal = true
            } else {
              console.log('Current eeat status is', pRun.eatStatus)
            }
          }
        })        
      }, 10000);
    },
    clearTimer () {
      if (this.updatePageRunTimer) {
        clearInterval(this.updatePageRunTimer)
        this.updatePageRunTimer = null
      }
    },
    loadPageRuns () {
      return this.$store.dispatch('loadPageRuns', this.$route.params)
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('setPageRuns', response.data.pageRuns)
            this.$store.commit('setAiRuns', response.data.aiRuns)
          }
          this.$store.commit('hideLoading')
        })
    },
    loadPageRun () {
      // this.activeTab = 'keywords'
      this.activeTab = 'report'
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchPageRun', this.$route.params)
        .then((response) => {
          if (response.data && response.data.status === 'SUCCESS') {
            this.$store.commit('addPageRun', response.data.pageRun)
            this.$store.commit('setCurrentPageRun', response.data.pageRun)
            // update strategy && approach
            if (false) {
              // add it later
            } else {
              this.$store.commit('setStrategy', 'target')
              this.$store.commit('setApproach', 'regular')
            }
            if (this.pageRun.proxyId) {
              this.proxy.id = this.pageRun.proxyId
            }

            if (response.data.pageRun.isGuardRun) {
              this.changeStandardOrAdvanced('guardRunView')
            } else {
              this.changeStandardOrAdvanced('standard');

              let defaultTab = this.$route.query.tab
              console.log('default tab I got is', defaultTab)
              if (defaultTab) {
                if (['eatSchemaType', 'nlpSchemaType'].includes(defaultTab)) {
                  this.showTab(defaultTab)
                }
              }
            }

            this.eatDoneStatus = response.data.pageRun.eatStatus
            if (response.data.pageRun.eatStatus === 'PENDING' || response.data.pageRun.eatStatus === 'PROCESSING') {
              this.loadPageRunTimer()
            }
          }
          return this.$store.dispatch('fetchAvailableProxies')
        })
        .then((response) => {
          if (response.data) {
            const proxies = response.data
            if (this.proxy.id) {
              this.proxy = proxies.find((p) => p.id === this.proxy.id)
              if (!this.proxy) {
                this.proxy = {}
              }
            }
          }
          return 1
        })
        .catch(err => {
          console.log('error fetching page run ', err)
        })
        .finally(() => {
          this.$store.commit('hideLoading')
          this.checkQueryParam()

        })
    },
    checkQueryParam () {
      let activeTab = this.$route.query.activeTab
      switch (activeTab) {
        case 'report_history':
          this.activeTab = activeTab
          break;
        case 'schemaType':
          this.activeTab = activeTab
          break;
        default:
          break;
      }

      let openExpressEditor = this.$route.query.openExpressEditor
      if (openExpressEditor==1) {
        this.showEditSourceModal(true)
      }
      // let todoTabName = this.$route.query.todoTabName
      // if (todoTabName) {
      //   if (todoTabName == 'simple_list') {
      //     this.changeStandardOrAdvanced('advanced')
      //   } else {
      //     this.changeActiveTabOfTodo(todoTabName)
      //   }
      // }
    },
    showNoteModalAction () {
      this.showNoteModal = true
    },
    hideNoteModalAction () {
      this.showNoteModal = false
    },
    
    saveOpenAITabData (data) {
      console.log('page run index', data)
      this.openAITabData = data      
    },
  },
  beforeMount () {
    // if (this.$store.state.pages.length == 0) {
    //   console.log('LOADING PAGES & PAGERUN FOR REFRESH')
    //   this.loadSite()
    //   .then(() => {
    //     this.loadPageRun()
    //   })
    // } else {
    //   console.log('LOADING PAGES ONLY FOR REFRESH')
    // }
    this.loadPageRun()
  },
  mounted () {
    this.$store.commit('setHideSideBar', true)
    // this.$store.commit('setHideTopBar', true)
    if (this.pageRun) {
      if (this.pageRun.isGuardRun) {
        setTimeout(() => {
          this.changeStandardOrAdvanced('guardRunView')
        }, 100);
      } else {
        this.$refs.bestPractices.checkAutoDisplay()
        this.showContentBriefScoreNotChangedModal = this.needsToDisplaySameScoreModal
      }
    }
  },
  beforeDestroy () {
    this.clearTimer()
    // console.log('come back sidebar to open')
    // this.$store.commit('setHideSideBar', false)
    // this.$store.commit('setHideTopBar', false)
  }

}
</script>

<style lang="scss">

.new-symbol {
  background: var(--v-yellow1Color-base);
  padding: 2px 5px;
  border-radius: 2px; 
  margin-left: 2px;
  margin-right: 2px;
  font-weight: 800;
}

.eat-done {
  font-size: 0.75rem;
  color: #FF9B2D;
}
</style>