export const SEO_TIPS = [
  "It's critical to avoid keyword stuffing. Instead, use Natural Language Processing (NLP) terms and various keyword variations.",
  "For supporting pages content, focus on the broader topic area of the site rather than the specific topic of the target page. This can help to avoid cannibalization.",
  "Diversify your keyword strategy: aim to rank for multiple variations of the same query. It's best to create separate pages for different phrasings of the same topic.",
  "Use organization schema to identify your business type. This makes your content more eligible in Google's eyes.",
  "Consider using pillar or hub pages on your site. These may not rank but they serve as useful information resources and points of internal linking to rankable pages.",
  "Not every page on your site needs to rank. Some pages can serve other purposes such as providing information or serving as internal linking points.",
  "Stick to the proper SEO practice of having only one H1 tag per page.",
  "Pay attention to zero search volume terms. Ranking for these could signal to Google that your site has comprehensive coverage of a particular topic.",
  "Title, URL, and H1 should all be matching or similar. Make sure the keyword appears in your title, page title (as seen by search engines), and H1.",
  "If your site is ranking for unusual terms, you can create new pages to build topical authority. Use GSC to find terms that your site is ranking for but don't have a dedicated page.",
  "Create author bio pages for each writer with schema markup. Link to these bio pages from their respective articles. This will help establish author credibility.",
  "If you feel that you've got the on-page right but the page is not ranking as expected, instead of jumping intobacklinking, try building out supporting pages.",
  "Image optimization could play a major role in optimizing for tools like Google Lens. Ensure alt text and related metadata is accurately filled.",
  "Answering \"People Also Ask\" questions on your page not addressed by your competitors makes relevant unique content.",
  "Creating content based on real problems and questions your audience has can lead to increased traffic, even if it doesn'tdirectly improve your main target page's ranking.",
  "When optimizing a page, target one main keyword per page rather than trying to rank for several distinct conceptson a single page.",
  "Understanding how your content appears across different devices (mobile and desktop) can help optimize fordifferent user experiences.",
  "Some indicators for Google that your website is real: local address, several emails listed on your site (not just a blank contact form), team page, schema, refunds policy, cookie notices, limited 404s",
  "When you change the URL of a published page, you're giving Google a completely new page and this may affect rankings. Implement a 301 redirect to redirect prior URL to a new one.",
  "Maintain the relevance of your content: if you're covering a wide range of topics, ensure they all support a particular target page and don't stray too far from the main topic."
]
