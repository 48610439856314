<template>
<div>
</div>
</template>

<script>
export default {

  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
/* @import url('vue-material/dist/vue-material.css'); */
</style>