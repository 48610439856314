
import { segmentTrack } from '@/utils'
import servicePackages from '@/components/service_package_constants'
import { mixin as ServiceMixin } from '@/components/service_mixin'


const STEPS = {
    'ADD_INFO': 1,
    'PAYMENT': 2,
}

export const mixin = {
    mixins: [ServiceMixin],
    data () {
        return {
            STEPS: STEPS,

            email: '',
            password: '',
            verifyPassword: '',
            errorMsg: '',
            inputErrors: {},
            showErrorModal: false,
            stripeToken: '',

            isThisForEndUser: false,
            endUserEmail: '',
            endUserPassword: '',
            endUserVerifyPassword: '',

            showSinglePlan: true, // show/hide single plan setting
            showAgencyPlan: true, // show/hide agency plan setting

            needDisplayValidationMessageForUserInfo: false,
        }
    },
    computed: {
        validForm () {
        return this.passwordMinLength && this.passwordsMatch && this.selectedServicePkg && this.validEmail &&
            ( !this.isThisForEndUser || (this.endUserPasswordMinLength && this.endUserPasswordsMatch && this.validEndUserEmail) )
        },
        loginOrRegisterView () {
            return this.$store.getters.isLoginOrRegister(this.$route.name)
        },
        endUserPasswordMinLength() {
            return this.endUserPassword.length >= 6
        },
        endUserPasswordsMatch () {
            return this.endUserPasswordMinLength && this.endUserPassword === this.endUserVerifyPassword
        },
        validEndUserEmail () {
            return this.endUserEmail && this.endUserEmail.includes('@')
        },
        validEmail () {
            return this.email && this.email.includes('@') && this.email.includes('.')
        },
        passwordMinLength () {
            return this.password.length >= 6
        },
        passwordsMatch () {
            return this.passwordMinLength && this.password === this.verifyPassword
        }
    },
    methods: {
        validateInput () {
            const inputErrors = {}
            if (this.email.length == 0) { inputErrors['email'] = 'Please input an email'; }
            if (this.password.length < 6) { inputErrors['password'] = 'Your password must contain at least 6 characters'; }
            if (this.password !== this.verifyPassword) { inputErrors['verifyPassword'] = 'Your password and password verify do not match'; }

            this.inputErrors = inputErrors
            if (Object.keys(inputErrors).length > 0) return false;
            return true;
        },
        viewItemEventActionForGTag () {
            let data = {
              event: "view_item",
              ecommerce: {
                value: this.serviceFromRoute.price,
                currency: "USD",
                items: [
                    {
                        item_id: this.selectedServicePkg,
                        item_name: this.computedTitle,
                        item_category: this.isYearlyPackage ? 'Yearly' : 'Monthly',
                        price: this.serviceFromRoute.price,
                        discount: 0,
                    }
                ]}
            }
            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push(data);
        },
        addToCartEventActionForGTag () {
            let data = {
              event: "add_to_cart",
              ecommerce: {
                value: this.serviceFromRoute.price,
                currency: "USD",
                items: [
                    {
                        item_id: this.selectedServicePkg,
                        item_name: this.computedTitle,
                        item_category: this.isYearlyPackage ? 'Yearly' : 'Monthly',
                        price: this.purchaseAmt,
                        discount: !this.appliedCoupon ? 0 : this.serviceFromRoute.price - this.purchaseAmt,
                    }
                ]}
            }
            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push(data);
        },
        register (paymentService) {

            this.$store.commit('showLoading')
            this.paymentService = paymentService

            let promise
            // if (paymentService === 'stripe') {
            //     const order = {
            //         description: this.serviceDescription,
            //         servicePackage: this.selectedServicePkg,
            //         amount: this.purchaseAmt
            //     }
            //     promise = doStripeCheckout(this.email, order).then((response) => {
            //         if (response.data && response.data.status === 'CLOSED') {
            //             return new Promise((resolve, reject) => {
            //                 resolve(response)
            //             })
            //         } else {
            //             return this.$store.dispatch('register', {
            //                 email: this.email,
            //                 password: this.password,
            //                 endUserEmail: this.endUserEmail,
            //                 endUserPassword: this.endUserPassword,
            //                 servicePackage: this.selectedServicePkg,
            //                 paymentService: this.paymentService,
            //                 promoCode: this.promoCode.trim(),
            //                 paymentToken: response.stripeToken
            //             })
            //         }
            //     })
            // }
            if (['stripe', 'paypal'].includes(paymentService)) {
                promise = this.$store.dispatch('register', {
                    email: this.email,
                    password: this.password,
                    servicePackage: this.selectedServicePkg,
                    paymentService: this.paymentService,
                    promoCode: this.promoCode.trim(),
                    payViaInvoice: this.isYearlyPackage ? this.payViaInvoice : false,
                })
            } else if (paymentService === 'free') {
                promise = this.$store.dispatch('register', {
                    email: this.email,
                    password: this.password,
                    // servicePackage: this.selectedServicePkg,
                    paymentService: this.paymentService,
                    promoCode: this.promoCode.trim(),
                    periodFreeCouponApplied: true,
                })
            }
            return promise.then((response) => {
                let utm_source = null
                let utm_medium = null
                try {
                    utm_source = this.$route.query.utm_source
                    utm_medium = this.$route.query.utm_medium
                } catch (e) {
                    console.log(e)
                }
                if (response.data.status === 'SUCCESS') {
                    segmentTrack('register_purchase', {
                        paymentService: this.paymentService,
                        email: this.email,
                        servicePackage: this.selectedServicePkg,
                        promoCode: this.promoCode.trim(),
                        utm_source: utm_source,
                        utm_medium: utm_medium
                    })
                    if (paymentService != 'free') {
                        this.addToCartEventActionForGTag();
                    }

                    let createdAt = ''
                    if (response.data.user) {
                        createdAt = response.data.user.createdAt
                    }
                    return this.$router.push(`/register-check-email?createdAt=${createdAt}&email=${this.email}`)
                } else {
                    this.showErrorModal = true
                    this.errorMsg = response.data.errorMsg
                    return 1
                }
            })
            .finally(() => this.$store.commit('hideLoading'))
        },
    }
}
