<template>
<section class="main-section" v-if="ready">
  <div class="container is-fluid">

    <div v-if="user.isAdmin">

      <h2 class='title'>{{ _.startCase(source) }} (Primary Total {{ primaryTotal | round(2) }} vs Secondary Total {{ secondaryTotal | round(2) }}, {{ ((primaryTotal - secondaryTotal) / secondaryTotal * 100) | round(2) }}%)</h2>
      <div class="columns">
        <div class="column">

          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select id="coupon-type" @change="onInputsChange" v-model='source'>
                  <option value="sales">Sales</option>
                  <option value="newUsers">New Users</option>
                  <option value="upgrades">Upgrades</option>
                  <option value="downgrades">Downgrades</option>
                  <option value="cancellations">Cancellations</option>
                  <option value="pageRuns">Page Runs</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column">

          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select id="coupon-type" @change="onInputsChange" v-model='aggregator'>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="columns">

        <div class="column">
          <div class="field">
            <div class="control">
              <datepicker v-model="primaryStartDate" @selected="onInputsChange" input-class="input" placeholder="Primary Start Date"></datepicker>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <div class="control">
              <datepicker v-model="primaryEndDate" @selected="onInputsChange" input-class="input" placeholder="Primary End Date"></datepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <div class="control">
              <datepicker v-model="secondaryStartDate" @selected="onInputsChange" input-class="input" placeholder="Secondary Start Date"></datepicker>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <div class="control">
              <datepicker v-model="secondaryEndDate" @selected="onInputsChange" input-class="input" placeholder="Secondary End Date"></datepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">

        <div class="column">
          <multi-time-series ref="seriesChart" :labels='seriesLabels' :datasets='chartDataSeries'/>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="control is-pulled-right">
            <div class="select is-fullwidth">
              <select v-model="perPage">
                <option value="10">10 per page</option>
                <option value="25">25 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <center class="has-text-centered">
        <b-table
          :data="tabularData"
          :paginated="true"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="false"
          :default-sort-direction="defaultSortOrder"
          default-sort="label"
          detailed
          detail-key="label">
          <template slot-scope="props">
            <b-table-column field="label" label="Label" sortable>{{ props.row.label }}</b-table-column>
            <b-table-column field="primaryValue" label="Primary Aggregate" sortable numeric>{{ props.row.primaryValue | round(2) }}</b-table-column>
            <b-table-column field="secondaryValue" label="Secondary Aggregate" sortable numeric>{{ props.row.secondaryValue | round(2) }}</b-table-column>
          </template>
          <template slot="detail" slot-scope="props">

            <div class="columns">
              <div class="column">
                <h2 class="subtitle">Primary Dataset</h2>
                <table class='table is-fullwidth'>
                  <thead>
                    <tr>
                      <td v-for="field in tabularFields" :key="field">{{ _.startCase(field) }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rec, i) in props.row.primaryData.records" :key="`primary-row-${i}`">
                      <td v-for="field in tabularFields" :key="field">{{ rec[field] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="column">
                <h2 class="subtitle">Secondary Dataset</h2>
                <table class='table is-fullwidth'>
                  <thead>
                    <tr>
                      <td v-for="field in tabularFields" :key="field">{{ _.startCase(field) }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(rec, i) in props.row.secondaryData.records" :key="`secondary-row-${i}`">
                      <td v-for="field in tabularFields" :key="field">{{ rec[field] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </template>
        </b-table>
      </center>


    </div>

  </div>
</section>
</template>

<script>
import _ from 'lodash';
import MultiTimeSeries from '@/components/MultiTimeSeries.vue'
import PieChart from '@/components/Admin/PieChart.vue'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import { mixin as BaseMixin } from '@/components/mixin'
import Datepicker from 'vuejs-datepicker'
import { toLocalDateString, toLocalDate } from '@/utils'
export default {
  mixins: [ServiceMixin, BaseMixin],
  components: { PieChart, MultiTimeSeries, Datepicker },
  data () {
    return {
      ready: false,
      source: 'sales',
      aggregator: 'day',
      perPage: 25,
      defaultSortOrder: 'asc',
      currentPage: 1,
      primaryTotal:0,
      secondaryTotal: 0,
      primaryStartDate: null,
      primaryEndDate: null,
      secondaryStartDate: null,
      secondaryEndDate: null,
      primaryAggregates: [],
      secondaryAggregates: [],
      chartDataSeries: [],
      seriesLabels: [],
      tabularFields: [],
      tabularData: [],

      chartColors: {
        blue: "rgb(54, 162, 235)",
        green: "rgb(75, 192, 192)",
        grey: "rgb(201, 203, 207)",
        orange: "rgb(255, 159, 64)",
        purple: "rgb(153, 102, 255)",
        red: "rgb(255, 99, 132)",
        yellow: "rgb(255, 205, 86)",
        black: "rgb(0, 0, 0)"
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    _() {
      return _
    }
  },
  methods: {
    toLocalDateString (dateStr) {
      return toLocalDateString(dateStr)
    },
    onInputsChange (event) {
      this.$nextTick(() => {
        this.loadData()
          .then(() => {
            this.$refs.seriesChart.update(this.seriesLabels, this.chartDataSeries)
            return 1
          })
      })
    },
    loadData () {
      this.primaryTotal = 0
      this.secondaryTotal = 0
      this.$store.commit('showLoading')
      return this.$store.dispatch('loadAdminComparisonData', {
        primaryStartDate: this.primaryStartDate ? this.primaryStartDate.valueOf() : '',
        primaryEndDate: this.primaryEndDate ? this.primaryEndDate.valueOf() : '',
        secondaryStartDate: this.secondaryStartDate ? this.secondaryStartDate.valueOf() : '',
        secondaryEndDate: this.secondaryEndDate ? this.secondaryEndDate.valueOf() : '',
        source: this.source,
        aggregator: this.aggregator
      })
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          console.log(response.data)
          const { tabularFields, seriesLabels, primarySeries, secondarySeries, primaryAggregates, secondaryAggregates } = response.data
          this.chartDataSeries = [
            {
              label: 'Primary',
              backgroundColor: this.chartColors.blue,
              borderColor: this.chartColors.blue,
              fill: false,
              data: primarySeries
            },
            {
              label: 'Secondary',
              backgroundColor: this.chartColors.red,
              borderColor: this.chartColors.red,
              fill: false,
              data: secondarySeries
            }
          ]
          this.tabularFields = tabularFields
          this.seriesLabels = seriesLabels
          this.primaryAggregates = primaryAggregates
          this.secondaryAggregates = secondaryAggregates

          for (let i = 0; i < 5 && i < this.seriesLabels.length; i++) {
            console.log(this.seriesLabels[i])
            console.log(primarySeries[i])
            console.log(secondarySeries[i])
            console.log('')
          }

          this.tabularData = seriesLabels.map((label, i) => {
            const primaryData = primaryAggregates.length > i ? primaryAggregates[i] : null
            const secondaryData = secondaryAggregates.length > i ? secondaryAggregates[i] : null
            const primaryValue = _.isEmpty(primaryData) ? 0 : primaryData.aggregate
            const secondaryValue = _.isEmpty(secondaryData) ? 0 : secondaryData.aggregate
            const value = primaryValue + secondaryValue
            this.primaryTotal += primaryValue
            this.secondaryTotal += secondaryValue
            return {
              label,
              primaryValue,
              secondaryValue,
              value,
              primaryData,
              secondaryData
            }
          })

          console.log(this.tabularData)
          // console.log(this.chartDataSeries)
          // console.log(this.seriesLabels)
          // console.log(this.primaryAggregates)
          // console.log(this.secondaryAggregates)
        }
        this.ready = true
        return 1
      })
      .finally(() => this.$store.commit('hideLoading'))
    }
  },
  beforeMount () {
    let now = new Date()
    this.primaryStartDate = new Date(now.getUTCFullYear(), now.getUTCMonth() - 1, 1)
    this.primaryEndDate = new Date()
    this.secondaryStartDate = new Date(now.getUTCFullYear(), now.getUTCMonth() - 2, 1)
    this.secondaryEndDate = new Date()
    return this.loadData()
  }
}
</script>

<style lang="scss">
.table-mobile-sort {
  display: none;
}
</style>