<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="d-flex align-center gap10 mb-5">
            <div class="title-font is-size-4">
              Page settings
            </div>
            <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
              @click="() => {
                $store.commit('setCurrentVideoTutorialToDisplay', {
                  title: 'How to Setup Watchdog Inside of POP',
                  videoLink: 'https://www.youtube.com/embed/pUuWmi5836U?si=QIMshkF74GRgjBX2',
                  description: 'This video details how you can set up the Watchdog feature in PageOptimizer Pro',
                  time: '2m 12s'
                });
              }">
              videocam
            </span>
          </div>
          <div>
            <v-text-field outlined dense
              hide-details
              label="Page name"
              color="greenColor"
              v-model="pageName"
            ></v-text-field>

            <div class="check_watchdog_container mt-5">
              <div class="image_container">
                <img src="/static/guard.png" class="watchdog_guard_img"/>
              </div>
              <div class="content">
                <h3>Protect this page with POP Watchdog</h3>
                POP Watchdog alerts you with the <b>exact recommendations</b> to improve your page as it catches changes in your competitors. 
                Watchdog will give you a list of tasks to regain your traffic and positions in SERPs.
                <div class="watchdog_check_btn" :class="{ 'now-enabled': enableGoogleGuard }" @click="toggleEnableGoogleGuard">
                  <img v-if="enableGoogleGuard" src="/static/watchdog-disable-icon.png" width="17" height="17" class="mr-2"/>
                  <img v-else src="/static/shield-check.png" width="17" height="17" class="mr-2"/>
                  
                  <div>{{ enableGoogleGuard ? 'Disable POP Watchdog' : 'Enable POP Watchdog' }}</div>
                </div>
              </div>
            </div>
            <!-- <label class="checkbox enable_watchdog_checkbox">
              <input type="checkbox" v-model="enableGoogleGuard"/>
              Enable watchdog for this page
            </label> -->

            <v-card
              v-if="isAdminSetupTheGuard && page.isGoogleGuardEnabled"
              class="white-common-box pa-5">
              <div class="is-size-5 font-weight-bold">Current watchdog setup</div>
              <div class="mt-3">
                <div><b>Frequency:</b> {{ page.guardRepeat }}</div>
                <div><b>Report:</b> {{ page.guardRunId }}</div>
                <div><b>Next watchdog run at:</b> {{ toLocalDateString(page.guardNextRunAt) }}</div>
              </div>
            </v-card>
            <div class="section-box" v-if="enableGoogleGuard">
              <div v-if="isSpecialAdminUser && page.isGuardSetupByAdmin" class="text-right">
                <v-btn rounded small
                  color="profileAnchorColor"
                  class="button-with-icon whiteColor--text text-none"
                  @click="testWatchdogCampaignSetup"
                  >
                  <span>Test now</span>
                </v-btn>
              </div>
              <div v-if="isAdminSetupTheGuard" class="mb-4 ">
                <div class="item-label"># Campaign duration</div>
                <div class="select is-small is-fullwidth">
                  <select
                    v-model="watchdogAdminSetupDuration">
                    <option v-for="(option, idx) in watchdogAdminSetupDurationOptions"
                      :key="idx"
                      :value="option['duration']">
                      {{ option['label'] }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="section-label">1. Select the frequency</div>
              <div>
                <div class="item-label">Frequency</div>
                <div class="select is-small is-fullwidth">
                  <select v-model="repeat">
                    <option
                      v-for="(option, idx) in repeatOptions"
                      :key="idx"
                      :value="option"
                    >
                      {{ option | capitalize }}
                    </option>
                    <option 
                    v-if="user.isAdmin"
                      value="hourly"
                      >Test Period(ASAP | This will make one guard run
                      asap (within 5 mins) and will disable the
                      guard)</option
                    >
                  </select>
                </div>
              </div>
              <div v-if="repeat == 'weekly'" class="mt10">
                <div class="item-label">Weekday</div>
                <div class="select is-small is-fullwidth">
                  <select v-model="weekday">
                    <option
                      v-for="(day, idx) in weekdays"
                      :key="idx"
                      :value="day.value"
                    >
                      {{ day.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="canPickDateForGuard" class="mt10">
                <div class="item-label">Day</div>
                <date-only-selector
                  :current="dayOfGuard"
                  :from="1"
                  :to="28"
                  :onDateSelected="(date) => {
                    dayOfGuard = date
                  }"></date-only-selector>
              </div>
              <div class="mt10">
                <div class="item-label">Report</div>
                <div class="select is-small is-fullwidth">
                  <select v-model="guardRunId">
                    <option
                      v-for="(pageRun,
                      idx) in pageRunsExceptGuardAutoRun"
                      :key="idx"
                      :value="pageRun.id"
                    >
                      {{ pageRun.id }} | {{ pageRun.keyword }} |
                      {{ getDateAsLocalString(pageRun.createdAt) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="check-boxes">
                <div class="d-flex align-center mt-3" v-if="isUnlimitedUser">
                  <vue-toggles
                    :value="isNotificationEnabled"
                    width="40" height="20"
                    :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                    :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
                    @click="() => toggleEnableGuardNotification()"
                    >
                  </vue-toggles>
                  <div class="ml-2 mainColor--text">
                    Receive detailed page report (email)
                  </div>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      Each time Watchdog checks things, you will get a full report emailed to you.
                    </div>
                  </v-tooltip>
                </div>
                <div class="d-flex align-center mt-3" v-if="isUnlimitedUser">
                  <vue-toggles
                    :value="isIncludeOnSummary"
                    width="40" height="20"
                    :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                    :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
                    @click="() => toggleIncludeOnSummary()"
                    >
                  </vue-toggles>
                  <div class="ml-2 mainColor--text">
                    Add to summary report
                  </div>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      By selecting this option, this page will be added to one email that contains a summary of all pages that are currently being monitored by Watchdog.  The summary email is sent once a month.  You can select pages to be in both the full report or summary report, as needed.
                    </div>
                  </v-tooltip>
                </div>
              </div>
            </div>

            <div class="section-box" v-if="enableGoogleGuard">
              <!-- <div class="notification is-warning">
                Watchdog Limitation For You: {{ googleGuardLimitOptions }}
              </div> -->
              <div class="section-label">2. Add email address</div>
              <div class="mt10">
                <div class="item-label">Watchdog report notification emails</div>
                <multiselect
                  v-model="notificationEmails"
                  :options="notificationEmailsOptions"
                  :multiple="true"
                  :taggable="true"
                  placeholder="Please insert emails."
                  @tag="addEmailsForNotification"
                  @remove="checkForRemoveEmailForNotification"
                />
              </div>
              <div class="d-flex align-center mt-3" v-if="isUnlimitedUser">
                <vue-toggles
                  :value="applyEmailsOnAllPagesInProject"
                  width="40" height="20"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
                  @click="() => toggleApplyEmailsOnAllPagesInProject()"
                  >
                </vue-toggles>
                <div class="ml-2 mainColor--text">
                  Update emails for all other watchdog pages in same project
                </div>
                <v-tooltip top color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    Turn this OFF if you wish to have an email added to only this one Watchdog Report and not all the reports in this project.
                  </div>
                </v-tooltip>
              </div>
              <template v-if="isIncludeOnSummary">
                <div>
                  <div class="item-label">Summary notification emails</div>
                  <multiselect
                    v-model="summaryNotificationEmails"
                    :options="summaryNotificationEmailsOptions"
                    :multiple="true"
                    :taggable="true"
                    placeholder="Please insert emails."
                    @tag="addEmailsForSummaryNotification"
                    @remove="
                      checkForRemoveEmailForSummaryNotification
                    "
                  />
                </div>
                <div v-if="canPickSummaryNotificationDay" class="mt10">
                  <div>
                    <div class="item-label">
                      Summary notification date of the month
                      <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on">info</i>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          Only one summary email is sent per account.  Changing this date will affect all runs that are included in the monthly summary report.
                        </div>
                      </v-tooltip>
                    </div>
                  </div>

                  <div>
                    <date-only-selector
                      :current="dayOfSummary"
                      :from="1"
                      :to="28"
                      :onDateSelected="(date) => {
                        dayOfSummary = date
                      }"></date-only-selector>
                  </div>
                </div>
              </template>
            </div>
            
            <div
              v-if="page.isGoogleGuardEnabled"
              class="nextwatchdog-date-alert"
            >
              <img src="/static/watch-dog-nextrun-icon.png" /> 
              <span>
                Next Watchdog Run will be on
                {{ toLocalDateString(page.guardNextRunAt) }}
              </span>
            </div>

            <div
              v-if="isIncludeOnSummary && page.isIncludeInSummaryEnabled"
              class="nextwatchdog-date-alert"
            >
              <img src="/static/watch-dog-nextrun-icon.png" /> 
              <span>
                Your next summary email will be sent on
                {{ toLocalDateString(user.nextSummaryReportDate) }}
              </span>
              
            </div>

            <div class="d-flex justify-end mt20 mb20">
              <v-btn rounded outlined
                color="mainColor"
                class="button-with-icon mainColor--text text-none"
                v-if="!disableDeletePage"
                @click="deletePageAction">
                <i class="material-icons-outlined">delete</i>
                <span>Delete page</span>
              </v-btn>
              <v-btn rounded
                v-if="enableGoogleGuard || isSettingChanged"
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none ml-2"
                @click="savePageSettingChange"
                :disabled="!canSavePageSetting">
                <i class="material-icons-outlined">save</i>
                <span>Save changes</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="showModal = false">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import validator from "validator";

import { mixin as ServiceMixin } from "@/components/service_mixin";
import { toLocalDateString } from "@/utils";

import DateOnlySelector from '@/components/DateOnlySelector.vue';


const WATCHDOG_ADMIN_SETUP_DURATION_OPTIONS = [
  { duration: '3', label: '3 months', },
  { duration: '6', label: '6 months', },
  { duration: '12', label: '12 months', },
]

export default {
  props: {
    disableDeletePage: {
      type: Boolean,
      default: false
    },
    isAdminSetupTheGuard: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [ServiceMixin],
  components: {
    Multiselect,
    DateOnlySelector
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isAgencyUnlimitedUser() {
      return this.$store.getters.isAgencyUnlimitedUser;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    canPickSummaryNotificationDay() {
      if (this.isAdminSetupTheGuard) {
        return false;
      }
      return this.isAgencyUnlimitedUser || this.isAdmin;
    },
    weekdays() {
      return [
        { label: "Monday", value: 0 },
        { label: "Tuesday", value: 1 },
        { label: "Wednesday", value: 2 },
        { label: "Thursday", value: 3 },
        { label: "Friday", value: 4 },
        { label: "Saturday", value: 5 },
        { label: "Sunday", value: 6 }
      ];
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    googleGuardLimitOptions() {
      if (this.isAdminSetupTheGuard) {
        return {};
      }
      if (this.selectedSubAccountRelation) {
        return this.user.agencyGoogleGuardOptions || {};
      } else {
        return this.user.googleGuardOptions || {};
      }
    },
    repeatOptions() {
      if (this.isAdminSetupTheGuard) {
        return ['monthly']
      }
      if (this.googleGuardLimitOptions) {
        return this.googleGuardLimitOptions.guardRepeatOptions || [];
      }
      return [];
    },
    canPickDateForGuard() {
      if (
        this.googleGuardLimitOptions["canChooseDay"] &&
        ["monthly", "quarterly"].includes(this.repeat)
      ) {
        return true;
      }
      return false;
    },
    canSavePageSetting() {
      if (this.pageName.trim() == "") {
        return false;
      }
      if (this.enableGoogleGuard) {
        if (!this.repeat) {
          return false;
        }
        if (
          ["monthly", "quarterly"].includes(this.repeat) &&
          this.canPickDateForGuard &&
          !this.dayOfGuard
        ) {
          return false;
        }
        if (this.guardRunId <= 0) {
          return false;
        }
      }
      return true;
    },
    pageRunsExceptGuardAutoRun() {
      return (this.pageRuns || []).filter(p => !p.isGuardRun);
    }
  },
  data() {
    return {
      showModal: false,
      isSettingChanged: false,

      page: {},
      pageName: "",
      pageRuns: [],

      enableGoogleGuard: true,
      isNotificationEnabled: true,
      isIncludeOnSummary: true,
      repeat: "",
      dayOfGuard: new Date().getDate(),
      guardRunId: 0,
      weekday: 0, // this will be needed if user picks the 'weekly'
      notificationEmails: [],
      notificationEmailsOptions: [],
      applyEmailsOnAllPagesInProject: false,

      summaryNotificationEmails: [],
      summaryNotificationEmailsOptions: [],
      dayOfSummary: 0,

      errorMsg: "",

      callback: null,

      watchdogAdminSetupDuration: WATCHDOG_ADMIN_SETUP_DURATION_OPTIONS[0]['duration'],
      watchdogAdminSetupDurationOptions: WATCHDOG_ADMIN_SETUP_DURATION_OPTIONS,
    };
  },
  watch: {
    enableGoogleGuard: function(newVal, oldVal) {
      if (newVal) {
        this.isNotificationEnabled = true;
      }
      this.isSettingChanged = true;
    },
    pageName: function(newVal, oldVal) {
      this.isSettingChanged = true;
    },
    showModal: function(newVal, oldVal) {
      if (newVal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
      console.warn(newVal)
      if (newVal == false && this.isSettingChanged && this.callback) {
        console.log("callback needs to be called");
        this.callback();
      }
    }
  },
  methods: {
    // notificationEmail, pageRun parameters are only used in case it's setup in admin page
    show(page, callback = null, notificationEmail='', pageRun=null, enableGoogleGuard=false) { 

      if (page) {
        this.page = page;
        this.pageName = page.name;
        this.showModal = true;

        this.enableGoogleGuard = enableGoogleGuard ? true : page.isGoogleGuardEnabled == true;
        this.isNotificationEnabled = page.isNotificationEnabled == true;
        this.isIncludeOnSummary = page.isIncludeInSummaryEnabled == true;
        this.repeat = page.guardRepeat;
        this.guardRunId = page.guardRunId;
        this.dayOfGuard = page.dayOfGuard;
        this.weekday = page.weekDay;

        let notificationEmails = page.notificationEmails;

        let email = ''
        if (this.isAdminSetupTheGuard) {
          email = notificationEmail
        } else {
          email = this.selectedSubAccountRelation ? this.selectedSubAccountRelation.agencyUserEmail : this.user.email;
        }

        if (notificationEmails.length == 0) {
          notificationEmails = [email];
        }
        this.notificationEmails = notificationEmails;
        this.notificationEmailsOptions = notificationEmails;

        // This will includes user's email if it's empty in backend side.
        let summaryNotificationEmails = [];
        if (this.isAdminSetupTheGuard) {
          summaryNotificationEmails = [ notificationEmail ]
        } else {
          summaryNotificationEmails = this.selectedSubAccountRelation ? this.selectedSubAccountRelation.agencySummaryNotificationEmails : this.user.summaryNotificationEmails; 
        }
        
        this.summaryNotificationEmails = summaryNotificationEmails;
        this.summaryNotificationEmailsOptions = summaryNotificationEmails;

        if (this.isUnlimitedUser) {
          this.applyEmailsOnAllPagesInProject = true;
        } else {
          this.applyEmailsOnAllPagesInProject = false;
        }

        this.dayOfSummary = this.user.summaryDaySetting > 0 ? this.user.summaryDaySetting : 0
        this.callback = callback;

        if (this.isAdminSetupTheGuard) {
          this.pageRuns = [ pageRun ]
          
          this.enableGoogleGuard = true;
          this.applyEmailsOnAllPagesInProject = false;
          this.isNotificationEnabled = true;
          this.isIncludeOnSummary = false;
          this.repeat = 'monthly'
        } else {
          this.loadPageRuns();
        }
        if (pageRun && !this.guardRunId) {
          this.guardRunId = pageRun.id
        }
      }
      setTimeout(() => {
        this.isSettingChanged = false;
      }, 200);
    },
    toggleEnableGuardNotification() {
      this.isNotificationEnabled = !this.isNotificationEnabled;
    },
    toggleEnableGoogleGuard() {
      this.enableGoogleGuard = !this.enableGoogleGuard
    },
    toggleIncludeOnSummary() {
      this.isIncludeOnSummary = !this.isIncludeOnSummary;
    },
    toggleApplyEmailsOnAllPagesInProject() {
      this.applyEmailsOnAllPagesInProject = !this
        .applyEmailsOnAllPagesInProject;
    },
    loadPageRuns() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("loadPageRuns", {
          siteId: this.page.siteId,
          pageId: this.page.id
        })
        .then(response => {
          if (response.data && response.data.status === "SUCCESS") {
            this.pageRuns = response.data.pageRuns;
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    savePageSettingChange() {
      this.$store.commit("showLoading");

      let data = {
          pageId: this.page.id,
          pageName: this.pageName,

          enableGoogleGuard: this.enableGoogleGuard,
          isNotificationEnabled: this.isNotificationEnabled,
          isIncludeOnSummary: this.isIncludeOnSummary,
          repeat: this.repeat,
          dayOfGuard: this.canPickDateForGuard ? this.dayOfGuard : 0,
          guardRunId: this.guardRunId,
          weekday: this.repeat == "weekly" ? this.weekday : 0,
          notificationEmails: this.notificationEmails,
          applyEmailsOnAllPagesInProject:
            this.isUnlimitedUser && this.applyEmailsOnAllPagesInProject,
          summaryNotificationEmails: this.summaryNotificationEmails,
          dayOfSummary: this.canPickSummaryNotificationDay
            ? this.dayOfSummary
            : 0
      }
      if (this.isAdminSetupTheGuard) {
        data = {
          ...data,
          isAdminSetupTheGuard: true,
          watchdogAdminSetupDuration: this.watchdogAdminSetupDuration
        }
      }

      this.$store
        .dispatch("updatePageSettings", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.showModal = false;

            if (!this.isAdminSetupTheGuard) {
              this.$store.commit("setPage", response.data.page);
              this.$store.commit("setUser", response.data.user);
            }

            this.$notify({
              group: 'info',
              type: 'success',
              text: `${this.enableGoogleGuard ? "Watchdog is successfully set up" : "Successfully saved."}`
            });
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: `Error on saving: ${ response.data.msg }`
            })
          }
          if (!this.isAdminSetupTheGuard) {
            return this.loadSites();
          }
        })
        .catch(() => {
          this.$store.commit("hideLoading");
        });
    },
    savePageNameChange() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("changePageName", {
          id: this.page.id,
          pageName: this.pageName
        })
        .then(response => {
          return this.loadSites();
        })
        .catch(() => {
          this.$store.commit("hideLoading");
        });
    },
    deletePageAction() {
      if (window.confirm("Do you want to delete this page?")) {
        this.$store.commit("showLoading");
        console.log(this.page);
        this.$store
          .dispatch("removePage", {
            siteId: this.page.siteId,
            pageId: this.page.id
          })
          .then(response => {
            return this.loadSites();
          })
          .finally(() => {
            this.showModal = false;
            this.$store.commit("hideLoading");
          });
      }
    },
    addEmailsForNotification(emailsStr) {
      emailsStr.split(",").map(item => {
        let email = item.trim();
        if (!validator.isEmail(email)) {
          return;
        }
        if (email && !this.notificationEmails.includes(email)) {
          this.notificationEmails.push(email);
        }
      });
      if (this.notificationEmails.length > 3) {
        this.notificationEmails = this.notificationEmails.slice(0, 3);
      }
    },
    checkForRemoveEmailForNotification(email) {
      if (email == this.user.email) {
        this.notificationEmails.unshift(email);
        window.alert(
          "You cannot remove the main account email from notification emails."
        );
      }
    },
    addEmailsForSummaryNotification(emailsStr) {
      emailsStr.split(",").map(item => {
        let email = item.trim();
        if (!validator.isEmail(email)) {
          return;
        }
        if (email && !this.summaryNotificationEmails.includes(email)) {
          this.summaryNotificationEmails.push(email);
        }
      });
      if (this.summaryNotificationEmails.length > 3) {
        this.summaryNotificationEmails = this.summaryNotificationEmails.slice(
          0,
          3
        );
      }
    },
    checkForRemoveEmailForSummaryNotification(email) {
      if (email == this.user.email) {
        this.summaryNotificationEmails.unshift(email);
        window.alert(
          "You cannot remove the main account email from summary notification emails."
        );
      }
    },
    loadSites() {
      this.$store
        .dispatch("loadSites")
        .then(response => {
          if (response.data && response.data.status === "SUCCESS") {
            this.$store.commit("setSites", response.data.sites);
            this.$store.commit("hideLoading");
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
          this.showModal = false;
        });
    },

    toLocalDateString(data) {
      return toLocalDateString(data);
    },

    testWatchdogCampaignSetup () {
      this.$store.dispatch('testCancelledWatchdogSetup', {
        pageId: this.page.id
      }).then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          alert('done.')
        } else {
          alert(response.data.msg)
        }
      })
    },

    getDateAsLocalString(dateStr) {
      return new Date(dateStr.replace(" ", "T") + "Z").toLocaleString();
    },

  }
};
</script>

<style lang="scss" scoped>
div {
  color: var(--v-mainColor-base);
}

.border-bottom {
  border-bottom: 1.5px solid var(--v-grayColor-base) !important;
}

.section-box {
  background: var(--v-gray10Color-base);
  padding: 25px 21px;
  border-radius: 5px;
  margin-top: 15px;

  .section-label {
    color: var(--v-mainColor-base);
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .item-label {
    color: var(--v-darkGrayColor-base);
    font-size: 13px;
    margin-bottom: 3px;
  }
  .check-boxes {
    display: grid;
    grid-template-columns: 3fr 2fr;

    // .switch[type=checkbox]:checked + label::before, .switch[type=checkbox]:checked + label:before {
    //   background: var(--v-green7Color-base);
    // }
  }

}

.nextwatchdog-date-alert {
  background: var(--v-green3Color-base);
  margin-top: 15px;
  border-radius: 5px;
  
  padding: 10px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 30px;
    height: auto;
  }
  span {
    margin-left: 10px;
    color: var(--v-green7Color-base);
    font-size: 17px;
  }
}
.enable_watchdog_checkbox {
  font-size: 2rem;
  input {
    width: 2rem;
    height: 2rem;
  }
}
.check_watchdog_container {
  border: 1px solid var(--v-grayColor-base);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 20px 25px 20px;
  margin-bottom: 2rem;
  * {
    color: var(--v-mainColor2-base);
  }
  .content {
    /* padding-right: 2rem; */
  }
  .image_container {
    padding-left: 25px;
    flex-shrink: 0;
    img.watchdog_guard_img {
      width: 150px;
      height: 150px;
    }
  }
  .watchdog_check_btn {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--v-green11Color-base);
    padding-left: 15px;
    width: 234px;
    height: 43px;
    margin-top: 1rem;
    cursor: pointer;
    font-weight: 700;
    background-color: var(--v-green13Color-base);

    &.now-enabled {
      border-color: var(--v-red9Color-base);
      background-color: var(--v-red10Color-base);
    }
  }
}

@media screen and (max-width: 950px) {
  .check_watchdog_container {
    flex-wrap: wrap;
  }  
}
.page-label {
  color: var(--v-grayColor-base);
  margin-bottom: 5px;
}
</style>
