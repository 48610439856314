export const mixin = {
  data () {
    return {
      
    }
  },
  methods: {
    calcContentBriefGoalWordCount(currentWordCount, avgCompetitorWordCount) {
      // this is commented out due to our logic change to display real target.
      // if (currentWordCount > avgCompetitorWordCount) {
      //   let overPercentage = (currentWordCount - avgCompetitorWordCount) * 100.0 / avgCompetitorWordCount
      //   if (overPercentage > 20) {
      //     return currentWordCount
      //   }
      // }
      return avgCompetitorWordCount      
    },
    calcWordCountRecommendation(currentWordCount, targetCount) {
      let goalWordCount = targetCount
      if (currentWordCount > targetCount) {
        let overPercentage = (currentWordCount - targetCount) * 100.0 / targetCount
        if (overPercentage > 20) {
          goalWordCount = currentWordCount
        }
      }

      if (currentWordCount < goalWordCount) {
        let underPercent = (goalWordCount - currentWordCount) * 100.0 / goalWordCount
        if (underPercent < 15) {
          return 'Leave as is'
        } else {
          let increaseCount = Math.round(goalWordCount * 0.95) - currentWordCount
          return `Increase your word count by ${increaseCount} words`
        }
      } else {
        let overPercentage = (currentWordCount - goalWordCount) * 100.0 / goalWordCount
        if (overPercentage <= 50) {
          return 'Leave as is'
        } else {
          let reduceCount = currentWordCount - Math.round(goalWordCount * 1.5)
          return `Reduce your word count by ${reduceCount} words`
        }
      }
    }
  }
}