<template>
<div class="three-rows-grid pt5">
    <div class="wow_disaled slideInLeft">
        <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="$emit('goprevpage')">keyboard_backspace</i>
    </div>          
    <div>
        <div class="full-width-container">
            <div class="level1-size title-font mb-3 wow_disaled slideInLeft" data-wow-delay="0s">
                Select the competitors that you want to focus on for more detailed analysis.
            </div>

            <div class="mb-7 wow_disaled slideInLeft" data-wow-delay="0.3s">
                <div class="level2-size mb5">
                    Select focus competitors (Choose at least 1)
                    <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                            <i class="material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                            Select focus competitors are a subset of competitors you would like to evaluate your page against.
                        </div>
                    </v-tooltip>
                </div>
            </div>

            <div v-show="validatedCompetitorUrls.length">
                <div class="pick-focus-competitor">
                    <div v-for="(validUrl, idx) in validatedCompetitorUrls" :key="idx">
                        <label class="checkbox checkbox-container">
                        <a class="word-break-all link15Color--text font-weight-bold" :href="validUrl" target="_blank">{{ validUrl }}</a>
                        <input type="checkbox" :value="validUrl" v-model="thisFocusCompetitorUrls" @change="handleFocusCompetitorUrlsChange" />
                        <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="q-a-explain-block mt-5">
                <img src="/static/q-a-icon.png" alt="Q/A" />
                <div>
                    <div class="section-title">How to select focus competitors</div>
                    <div class="mt-2">
                        Focus competitors provide an additional set of data for you to use.  <b>You will always want to choose a set of focus competitors.</b>
                        You will often want to select competitors that have sites like yours.  <b>Look for 1:1 comparisons.</b>
                        Some possible examples of focus competitors for you to choose:
                    </div>
                    <ul class="my-3">
                        <li>The sites ranking above you.</li>
                        <li>All the local sites from a list of competitors that contains both local and national sites, or vice versa.</li>
                        <li>One competitor that might be different from the rest of your competitors in order to compare and contrast.</li>
                    </ul>
                    <div class="mt-3">
                        <b>If you chose to have POP choose your competitors</b> for you, POP has also selected the top 3 sites in Google for your focus competitors automatically.  You can change that selection here, or continue as is.    
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class='has-text-centered font-weight-bold profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</div>
        <div class="is-bottom-right">
            <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none" ref="nextBtn"
                :class="{ 'ready-button': advanceable}"
                data-wow-delay="0.9s" 
                :disabled="!advanceable" @click="handleDoneSelectingFocusGroup">
                <span>Next</span>
                <i class="material-icons">arrow_forward</i>
            </v-btn>
        </div>
    </div>
    
    <div class="modal" :class="{'is-active': showContinueWithoutFocusGroup}">
        <div class="modal-background" @click="showContinueWithoutFocusGroup = false"></div>
        <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
            <div class="modal-content-root">
                <div class="title-font is-size-5 mainColor--text mb-3">Focus Group Confirmation</div>
                <div class="mainColor--text">
                    Are you sure you don't want to select a focus group? <br/>
                    A focus group is a subset of your competitor list that will be analyzed independently for greater accuracy. 
                </div>
                <div class="d-flex justify-end mt-7">
                    <v-btn rounded outlined
                        color="mainColor"
                        class="button-with-icon text-none"
                        @click.stop="handleConfirmContinueWithoutFocusGroup">
                        <span>Ignore and continue analysis</span>
                    </v-btn>
                    <v-btn rounded
                        color="profileAnchorColor"
                        class="button-with-icon text-none whiteColor--text ml-2"
                        @click.stop="showContinueWithoutFocusGroup = false">
                        <span>Add focus group</span>
                    </v-btn>
                </div>
            </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click="showContinueWithoutFocusGroup = false"><i class="material-icons">close</i></button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['validatedCompetitorUrls', 'focusCompetitorUrls', 'submittingRun', 'advanceable', 'disabled', 'errorMsg'],
    data () {
        return {
            thisFocusCompetitorUrls: this.focusCompetitorUrls,
            showContinueWithoutFocusGroup: false,
            confirmedContinueWithoutFocusGroup: false
        }
    },
    watch: {
        'focusCompetitorUrls': function(urls) {
            this.thisFocusCompetitorUrls = urls
        }
    },
    methods: {
        handleFocusCompetitorUrlsChange (e) {
            this.$emit('focuscompetitorurlschange', this.thisFocusCompetitorUrls.slice())
        },
        handleDoneSelectingFocusGroup () {
            if (!this.thisFocusCompetitorUrls.length && !this.confirmedContinueWithoutFocusGroup) {
                this.showContinueWithoutFocusGroup = true
            } else {
                this.$emit('gonextpage')
            }
        },
        handleConfirmContinueWithoutFocusGroup () {
            this.confirmedContinueWithoutFocusGroup = true
            this.handleDoneSelectingFocusGroup()
        }
    },
    mounted () {
        if (this.advanceable) { this.$refs.nextBtn.$el.focus() }
    }
}
</script>

<style lang="scss" scoped>
div {
    color: var(--v-mainColor-base);
}
</style>